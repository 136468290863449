import React from 'react';
import {compose} from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {withRouter} from "react-router-dom";
import BetaHeader from './BetaHeader';
import {PageTitle} from "../../title";
import ResponsiveGrid from '../layout/ResponsiveGrid';

import {
  CalendarMonthOutline,
  MapClockOutline,
} from 'mdi-material-ui';
import history from "../../history";
import NewTemplates from "./NewTemplates";
import {Hidden} from "@material-ui/core";
import {BetaNews} from "./BetaNews";
import {MobileNews} from "./MobileNews";
// -------------------------------------------------------------------------- //


// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  root: {
    display: 'flex',
    // height: 148,
    // padding: 12,
    width: '100%',
    overflowX: 'hidden',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      // height: 300,
    },
  },
  shortcutBox: {
    width: '50%',
    minWidth: '500px',
    border: '1px solid #b7b2b2',
    marginTop: '48px',
    padding: '16px',
    borderRadius: '8px',
    boxShadow: '0px 0px 6px -4px rgba(84,84,84,1)',
    paddingBottom: '32px'
  },
  button: {
    width: '44%',
    marginRight:'6%',
    padding: '12px',
    border: '1px solid #b7b2b2',
    borderRadius: '4px',
    boxShadow: '0px 0px 6px -4px rgba(84,84,84,1)',
    backgroundColor: '#fff',
    cursor: 'pointer',
    color: '#757171',
    '&:hover': {
      backgroundColor: '#b7b2b2',
      color: '#fff'
    }
  },
  buttonContainer: {
    textAlign: 'left',
    verticalAlign: 'middle'
  },
  buttonText: {
    display: 'inline-block',
    lineHeight: '16px',
    fontSize: '12px',
    marginTop: '8px',
  },
  buttonIconBox: {
    width: '52px',
    height: '48px',
    display: 'inline-box',
    float: 'left',
    borderRight: '1px solid #ccc7c7',
    marginRight: '12px'
  },
  buttonIcon: {
    width: '40px',
    height: '40px',
    marginTop: '4px'
  }
});



const PrestoDashboard = (props) => {
  const { classes } = props;
   return (
    <React.Fragment>
      <PageTitle title="Home"/>
      <BetaHeader/>
      <ResponsiveGrid style={{ padding: 12 }}>
        <div className={classes.shortcutBox}>
          <Typography variant="h5" component="h2" style={{ marginBottom: 12 }}>
            Shortcuts
          </Typography>
          <div>
            <button className={classes.button} onClick={ () => history.push('/create/eventfilter') }>
              <div className={classes.buttonContainer}>
                <div className={classes.buttonIconBox}>
                  <MapClockOutline className={classes.buttonIcon} />
                </div>
                <div className={classes.buttonText}>CREATE GRAPHIC<br/>BY EVENT</div>
              </div>
            </button>
            <button className={classes.button} onClick = { () => history.push('/create/schedulefilter') }>
              <div className={classes.buttonContainer}>
                <div className={classes.buttonIconBox}>
                  <CalendarMonthOutline className={classes.buttonIcon} />
                </div>
                <div className={classes.buttonText}>CREATE GRAPHIC<br/>BY SCHEDULE</div>
              </div>
            </button>
          </div>
        </div>
        <NewTemplates/>
        <Hidden only="xs">
          <BetaNews/>
        </Hidden>
        <Hidden smUp>
          <MobileNews/>
        </Hidden>
      </ResponsiveGrid>
    </React.Fragment>
  );
};

export default compose(
  withRouter,
  withStyles(STYLES),
)(PrestoDashboard);
