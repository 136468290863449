
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Button,
  ButtonBase,
  Typography,
  Divider,
  withStyles, FormControl, InputLabel, Select, MenuItem, TextField, FormControlLabel, Checkbox,
} from '@material-ui/core';

import {
  Play as PlayIcon,
  Stop as StopIcon,
} from 'mdi-material-ui';

import { detect } from 'detect-browser';
import Dropzone from 'react-dropzone';
import Slider from 'rc-slider';

import Tip from './Tip';
import Crumbs from './Crumbs';
import effects from '../dict/effects';
import Animations from "../../../canvas/animations";
import * as MetaData from '../../../canvas/lib/metadata';
import Paper from "@material-ui/core/Paper";

const { createSliderWithTooltip } = Slider;
const TippedSlider = createSliderWithTooltip(Slider);
const TippedRangeSlider = createSliderWithTooltip(Slider.Range);

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  margin_button: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  video_button: {
    borderRadius: 4,
    boxShadow: theme.shadows[1],
    transition: theme.transitions.create('box-shadow'),
    '&:focus': {
      boxShadow: `0 0 0 4px ${theme.palette.primary.main}`,
    },
    '&:hover': {
      boxShadow: theme.shadows[3],
    },
  },
  heading: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  video_preview: {
    borderRadius: 4,
    width: '100%',
  },
  clear_effect_button: {},
  dropzone: {
    border: 0,
    width: '100%',
  },
  backgroundActive: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    display: 'inline-block',
    width: '45%',
    marginTop: 16,
    padding: 8,
    borderRadius: 4,
  },
  backgroundInActive: {
    color: '#4c4c4c',
    display: 'inline-block',
    width: '45%',
    marginTop: 16,
    padding: 8,
    borderRadius: 4,
  },
  animationOptions: {
    margin: theme.spacing(2),
  },
  captionPaper: {
    padding: theme.spacing(1),
    position: 'relative',
    backgroundColor: theme.palette.background.default,
  },

});

// -------------------------------------------------------------------------- //

class Effects extends React.Component {

  state = {
    showingTextures: false,
    audio_state: 'ready',
    audio_playing: false,
    audio_delay: '0',
    duration: '',
    loop: false,
    render_effect_options: false,
    render_animation_options: false,
  }

  constructor() {
    super();
    this.audio = new Audio();

    this.audio.ontimeupdate = (e) => {
      if (this.audio.currentSrc === null) {
        return;
      }

      const end = MetaData.getMetaData(
        this.props.CanvasUtil.canvas, 'audio_end'
      );

      if (this.audio.currentTime >= end) {
        this.audio.pause();
        this.audio.currentTime = 0.0;
        this.setState({ audio_playing: false });
      }
    };

    this.audio.onpause = (e) => {
      this.audio.currentTime = 0.0;
    };
  }

  componentDidMount() {
    let { canvas } = this.props.CanvasUtil;

    const audio_src = MetaData.getMetaData(
      canvas, 'audio_src', null
    );

    if (audio_src !== null) {
      this.audio.onloadeddata = () => {
        this.setState({ audio_state: 'loaded' });
      };

      this.audio.onerror = () => {
        this.setState({ audio_state: 'ready' });
      }

      const audio_delay = String(
        MetaData.getMetaData(canvas, 'audio_delay', 0)
      );

      this.audio.volume = MetaData.getMetaData(canvas, 'audio_volume');
      this.audio.src = audio_src;
      this.setState({ audio_state: 'loading', audio_delay });
    }
  }

  componentWillUnmount() {
    this.audio.pause();
  }

  render() {
    const { canvas, classes, slideshow } = this.props;
    const active_effects = canvas.getObjects('effect');
    let element = null;

    if (!slideshow) {

      if (active_effects.length === 0 || !active_effects[0].effect) {
        element = (
          <React.Fragment>
            <Crumbs title="Add Effects"/>
            <Tip
              heading="Tip:"
              text="In the editor, effects appear low resolution for performance reasons. Hit Share to see the full resolution version!"
            />
            {this.renderEffectList()}
          </React.Fragment>
        );
      } else {
        element = (
          <React.Fragment>
            <Crumbs title="Add Effects"/>
            <Tip
              heading="Tip:"
              text="In the editor, effects appear low resolution for performance reasons. Hit Share to see the full resolution version!"
            />
            {this.renderEffectList()}
            <div style={{
              width: '100%',
              padding: this.props.theme.spacing(1),
            }}>
              <Button
                fullWidth color="primary" variant="contained"
                className={ classes.clear_effect_button }
                onClick={this.removeEffect}
              >
                Clear Effect
              </Button>
            </div>
          </React.Fragment>
        );
      }
    }

    return (
      <React.Fragment>
        {this.renderMusicMenu()}
        {
          (!slideshow) &&
          <>
            {element}
            {this.renderAnimationList()}
          </>
        }
      </React.Fragment>
    );
  }

  renderAnimationOptions = (active_effect, active_animation) => {

    const { classes } = this.props;
    const { options } = active_effect.animation;
    let option_list = [];

    for (let key in active_animation.options) {
      const option = active_animation.options[key];

      switch (option.type) {
        case 'select': {
          option_list.push((
            <FormControl fullWidth>
              <InputLabel htmlFor={`${key}-select`}>
                {option.name}
              </InputLabel>
              <Select
                id={`${key}-select`}
                value={options[key] || option.defaultValue}
                onChange={(e) => {
                  options[key] = e.target.value;
                  this.forceUpdate();
                }}
              >
                {
                  option.values.map((value, index) => (
                    <MenuItem key={index} value={value.value}>
                      {value.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          ));

          break;
        }
        default: {
          break;
        }
      }
    }

    return (
      <React.Fragment>
        <div className={classes.animationOptions}>
          <div>
            <TextField
              label="Duration"
              value={this.state.duration}
              onChange={this.changeEffectDuration}
              margin="normal"
            />
            <FormControlLabel
              label="Loop"
              control={
                <Checkbox
                  color="primary"
                  checked={active_effect.loop}
                  onChange={(e) => {
                    active_effect.loop = e.target.checked;
                    this.forceUpdate();
                  }}
                />
              }
            />
            {option_list}
          </div>
        </div>
        <div style={{
          width: '100%',
          padding: this.props.theme.spacing(1)
        }}>
          <Button
            fullWidth
            className={classes.clear_effect_button}
            color="primary" variant="contained"
            onClick={() => this.unsetAnimation()}
          >
            Clear Animation
          </Button>
        </div>
      </React.Fragment>
    );
  }

  clearAudio = () => {
    let { canvas } = this.props.CanvasUtil;
    MetaData.clearMetaData(canvas, 'audio_src');
    MetaData.clearMetaData(canvas, 'audio_delay');
    MetaData.clearMetaData(canvas, 'audio_start');
    MetaData.clearMetaData(canvas, 'audio_end');
    MetaData.clearMetaData(canvas, 'audio_volume');
    MetaData.clearMetaData(canvas, 'audio_loop');
    this.audio.src = null;
    this.forceUpdate();
  }

  uploadMusic = (e) => {
    if (e.length === 0) {
      return;
    }

    let { canvas } = this.props.CanvasUtil;

    this.audio.onloadeddata = () => {
      MetaData.setMetaData(
        canvas, 'audio_src',
        this.audio.currentSrc
      );

      if (this.props.slideshow) {
        MetaData.setMetaData(
          canvas, 'audio_end',
          this.audio.duration
        );
      } else {
        MetaData.setMetaData(
          canvas, 'audio_end',
          Math.min(this.audio.duration, 30.0)
        );
      }

      MetaData.setMetaData(canvas, 'audio_delay', 0.0);
      MetaData.setMetaData(canvas, 'audio_start', 0.0);
      MetaData.setMetaData(canvas, 'audio_volume', 1.0);
      MetaData.setMetaData(canvas, 'audio_loop', false);
      this.setState({ audio_state: 'loaded' });
    };

    this.audio.onerror = () => {
      this.setState({ audio_state: 'ready' });
    }

    this.audio.volume = 1.0;
    this.audio.src = e[0].preview;
    this.setState({ audio_state: 'loading' });
  }

  renderMusicMenu = () => {
    const { classes } = this.props;
    let audio_start = 0.0, audio_end = 0.0, audio_volume = 0.0;

    if (this.state.audio_state === 'loaded') {
      let { canvas } = this.props.CanvasUtil;
      audio_start = MetaData.getMetaData(canvas, 'audio_start');
      audio_end = MetaData.getMetaData(canvas, 'audio_end');
      audio_volume = MetaData.getMetaData(canvas, 'audio_volume');
    }

    return (
      <>
        <Divider/>
        <Crumbs title="Add Music"/>
        {
          (this.state.audio_state !== 'loaded') &&
          <>
            <Paper className={classes.captionPaper}>
              <Typography variant="caption">
                <div style={{ padding: 8 }}>
                  <Button
                    fullWidth
                    onClick={() => this.dropzone.open()}
                    variant="contained"
                    color="primary"
                  >
                    Upload Music
                  </Button>
                </div>
                <b>Disclaimer:</b> By clicking “Upload Music”, you understand that you own or have been authorized by the owner to use the audio uploaded.
              </Typography>
            </Paper>
          </>
        }
        <Dropzone
          disableClick
          className={classes.dropzone}
          onDrop={(e) => this.uploadMusic(e)}
          accept="audio/mpeg"
          ref={(node) => { this.dropzone = node; }}
        />
        <div style={{ padding: 16 }}>
          {
            (this.state.audio_state === 'loaded') &&
            <>
              <Button
                fullWidth
                onClick={() => this.dropzone.open()}
                variant="contained"
                color="primary"
              >
                Upload Music
              </Button>
              <Button
                fullWidth
                className={classes.margin_button}
                onClick={() => this.clearAudio()}
                variant="contained" color="primary"
              >
                Clear Music
              </Button>
              <Typography variant="h6" className={classes.heading}>
                Music Delay
              </Typography>
              <TextField
                fullWidth type="text" margin="normal"
                pattern={`(\\d+(\\.\\d+)`}
                inputProps={{ min: 0, max: 30 }}
                style={{ marginTop: 0, paddingTop: 0 }}
                value={this.state.audio_delay}
                onChange={(e) => {
                  const { value } = e.target;
                  let { canvas } = this.props.CanvasUtil;

                  MetaData.setMetaData(canvas, 'audio_delay',
                    parseFloat(value)
                  );

                  this.setState({ audio_delay: value });
                }}
                onFocus={e => { e.target.select(); }}
              />
              <Typography variant="h6" className={classes.heading}>
                Music Length
              </Typography>
              <TippedRangeSlider
                value={[ audio_start, audio_end ]}
                onChange={(e) => {
                  let { canvas } = this.props.CanvasUtil;
                  let start = e[0], end = e[1];

                  if (!this.props.slideshow) {
                    const current = MetaData.getMetaData(
                      canvas, 'audio_start'
                    );

                    if (current !== start) {
                      end = Math.min(end, (start + 30.0));
                    } else {
                      start = Math.max(start, (end - 30.0));
                    }
                  }

                  MetaData.setMetaData(canvas, 'audio_start', start);
                  MetaData.setMetaData(canvas, 'audio_end', end);
                  this.forceUpdate();
                }}
                onAfterChange={(e) =>  {
                  let { canvas } = this.props.CanvasUtil;
                  let start = e[0], end = e[1];

                  if (!this.props.slideshow) {
                    const current = MetaData.getMetaData(
                      canvas, 'audio_start'
                    );

                    if (current !== start) {
                      end = Math.min(end, (start + 30.0));
                    } else {
                      start = Math.max(start, (end - 30.0));
                    }
                  }

                  MetaData.setMetaData(canvas, 'audio_start', start);
                  MetaData.setMetaData(canvas, 'audio_end', end);
                  this.forceUpdate();
                }}
                allowCross={false} pushable={1}
                step={0.01} min={0} max={this.audio.duration}
                tipFormatter={(value) => {
                  const minutes = Math.floor(value / 60);
                  const seconds = Math.round(value % 60);
                  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
                }}
                trackStyle={[{
                  backgroundColor: this.props.theme.palette.primary.light,
                }]}
                handleStyle={[{
                  borderColor: this.props.theme.palette.primary.light,
                }]}
                {...this.props}
              />
              <Typography variant="h6" className={classes.heading}>
                Music Volume
              </Typography>
              <TippedSlider
                min={0} max={1} step={0.05}
                value={audio_volume}
                onChange={(x) => {
                  MetaData.setMetaData(
                    this.props.CanvasUtil.canvas,
                    'audio_volume', x
                  );

                  this.audio.volume = x;
                  this.forceUpdate();
                }}
                tipFormatter={(value) => (
                  `${Math.round(value * 100)}%`
                )}
                trackStyle={[{
                  backgroundColor: this.props.theme.palette.primary.light,
                }]}
                handleStyle={[{
                  borderColor: this.props.theme.palette.primary.light,
                }]}
              />
              {
                (this.props.slideshow) &&
                <>
                  <Typography variant="h6" className={classes.heading}>
                    Loop Music
                    <Checkbox
                      color="primary" checked={MetaData.getMetaData(
                        this.props.CanvasUtil.canvas, 'audio_loop'
                      )}
                      onChange={(e) => {
                        let { canvas } = this.props.CanvasUtil;

                        MetaData.setMetaData(
                          canvas, 'audio_loop',
                          !MetaData.getMetaData(canvas, 'audio_loop')
                        );

                        this.forceUpdate();
                      }}
                    />
                  </Typography>
                </>
              }
              <Button
                fullWidth color="primary"
                variant="outlined"
                className={classes.margin_button}
                onClick={() => {
                  const { audio_playing } = this.state;

                  if (audio_playing) {
                    this.audio.pause();
                    this.audio.currentTime = 0.0;
                  } else {
                    this.audio.currentTime = MetaData.getMetaData(
                      this.props.CanvasUtil.canvas, 'audio_start'
                    );

                    this.audio.play();
                  }

                  this.setState({ audio_playing: !audio_playing });
                }}
              >
              {
                this.state.audio_playing
                ? <><StopIcon color="inherit"/> Stop</>
                : <><PlayIcon color="inherit"/> Play</>
              }
              </Button>
            </>
          }
        </div>
      </>
    );
  }

  renderTip = (val) => {

  }

  renderAnimationList = () => {
    const { classes } = this.props;
    const active_animation = this.getActiveAnimation();
    let active_effect = this.getActiveEffect();
    let output = [];

    Animations.forEach((a) => {
      if (active_animation && active_animation.id && active_animation.id === a.id) {
        output.push((
          <div className={classes.backgroundActive} key={`motion`+a.id}>
            <ButtonBase
              key={a.id}
              className={classes.video_button}
              onClick={() => this.setAnimation(a.id)}
            >
              <video
                autoPlay
                crossOrigin="true"
                loop
                muted
                className={classes.video_preview}
                src={a.video}
                key={`animation-button-${a.id}`}
              />
            </ButtonBase>
            <Typography variant="caption" style={{display: 'block', marginTop: 4, textAlign: 'center'}}>
              {a.name}
            </Typography>
          </div>
        ));
      } else {
        output.push((
          <div className={classes.backgroundInActive} key={`motion`+a.id}>
            <ButtonBase
              key={a.id}
              className={classes.video_button}
              onClick={() => this.setAnimation(a.id)}
            >
              <video
                autoPlay
                crossOrigin="true"
                loop
                muted
                className={classes.video_preview}
                src={a.video}
                key={`animation-button-${a.id}`}
              />
            </ButtonBase>
            <Typography variant="caption" style={{display: 'block', marginTop: 4, textAlign: 'center'}}>
              {a.name}
            </Typography>
          </div>
        ));
      }
    });
    let animationOptions = '';
    if (this.state.render_animation_options === true && active_animation) {
      animationOptions = this.renderAnimationOptions(active_effect, active_animation);
    }

    return (
      <React.Fragment>
        <Divider/>
        <Crumbs title="Add Motion"/>
        <Tip
          heading="Tip:"
          text="In the editor, motion will not appear on the canvas. Hit Share to see the motion!"
        />
        <div style={{ width: '100%', textAlign: 'center' }}>
          {output}
        </div>
        {animationOptions}
        <div style={{ clear: 'both', marginBottom: 24 }}>
        </div>
      </React.Fragment>
    );
  }

  renderEffectOptions = () => {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.animationOptions}>
          <div>
            <TextField
              label="Duration"
              value={this.state.duration}
              onChange={this.changeEffectDuration}
              margin="normal"
            />
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderEffectList = () => {
    const { classes } = this.props;
    let output = [];
    let active_effect = this.getActiveEffect();

    Object.keys(effects).forEach((id) => {

      const effect = effects[id];
      let preview = null;

      switch (detect().name) {
        case 'ios': {
          preview = (
            <img
              crossOrigin="anonymous"
              key={effect.name}
              src={effect.still}
              alt={effect.name}
              className={classes.video_preview}
            />
          );

          break;
        }
        default: {
          preview = (
            <video
              autoPlay
              loop
              muted
              crossOrigin="anonymous"
              key={effect.name}
              src={effect.preview}
              className={classes.video_preview}
            />
          );

          break;
        }
      }
      if (active_effect && active_effect.effect && active_effect.effect.id && active_effect.effect.id === id) {
        output.push((
          <div className={classes.backgroundActive} key={`effect`+id}>
            <ButtonBase
              key={id}
              className={classes.video_button}
              onClick={() => this.addEffect(id)}
            >
              {preview}
            </ButtonBase>
            <Typography variant="caption" style={{ display: 'block', marginTop: 4, textAlign: 'center' }}>
              {effect.name}
            </Typography>
          </div>
        ));
      } else {
        output.push((
          <div className={classes.backgroundInActive} key={`effect`+id}>
            <ButtonBase
              key={id}
              className={classes.video_button}
              onClick={() => this.addEffect(id)}
            >
              {preview}
            </ButtonBase>
            <Typography variant="caption" style={{ display: 'block', marginTop: 4, textAlign: 'center' }}>
              {effect.name}
            </Typography>
          </div>
        ));
      }
    });


    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        {output}
        {(this.state.render_effect_options === true)? (this.renderEffectOptions()) : null}
      </div>
    );
  }

  addEffect = (id) => {
    let { CanvasUtil } = this.props;

    this.unsetAnimation()
    this.setState({render_effect_options: true,render_animation_options: false})

    if (CanvasUtil.hasEffect()) {
      let active_effect = this.getActiveEffect();
      active_effect.setEffect(id);
      this.forceUpdate();
    } else {
      CanvasUtil.addEffect(id);
    }
  }

  getActiveEffect = () => {
    const { canvas } = this.props;
    let effects = canvas.getObjects('effect');

    if (effects.length === 0) {
      return null;
    }

    return effects[0];
  }

  removeEffect = () => {
    let active_effect = this.getActiveEffect();
    if (active_effect.canvas) {
      if (!active_effect.animation) {
        let canvas = active_effect.canvas;
        canvas.remove(active_effect);
      } else {
        active_effect.setEffect('none');
        if (this.props.refresh) {
          this.props.refresh();
        }
      }
    }
    this.setState({render_effect_options: false})
    this.forceUpdate();
  }
  getActiveAnimation = () => {
    const active_effect = this.getActiveEffect();

    if (!active_effect || !active_effect.animation) {
      return null;
    }

    const id = active_effect.animation.id;
    const index = Animations.findIndex(a => a.id === id);

    if (index < 0) {
      return null;
    }

    return Animations[index];
  }


  setAnimation = (id) => {
    let active_effect = this.getActiveEffect();

    if (!active_effect) {
      let { CanvasUtil } = this.props;
      active_effect = CanvasUtil.addEffect('none');
    }

    active_effect.animation = {
      id, options: { },
    };
    if (!active_effect.duration) {
      active_effect.duration = 10;
    }

    this.setState({
      duration: this.state.duration,
      render_animation_options: true,
      render_effect_options: false
    });

    if(active_effect){
      this.removeEffect()
    }

  }

  unsetAnimation = () => {
    let active_effect = this.getActiveEffect();

    this.setState({
      render_animation_options: false
    });

    if (active_effect) {
      delete active_effect.animation;

      if (!active_effect.effect) {
        let { canvas } = this.props;
        canvas.remove(active_effect);
      }

      this.forceUpdate();
    }
  }


  changeEffectDuration = (e) => {
    const duration = e.target.value;
    const value = Number(duration);
    if (value) {
      let active_effect = this.getActiveEffect();
      this.setState({duration: value });
      active_effect.set('duration',value)
      this.forceUpdate()
    }
  }
}

// -------------------------------------------------------------------------- //

export default withStyles(STYLES, { withTheme: true })(Effects);

// -------------------------------------------------------------------------- //
