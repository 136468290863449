
// -------------------------------------------------------------------------- //

import * as React from 'react';

import {
  AppBar,
  Button,
  Grid,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';

import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import NewTextureDialog from './NewTextureDialog';
import TextureCard from './TextureCard';

// -------------------------------------------------------------------------- //

const GET_TEXTURES = gql`
  query {
    textureSearch {
      id
      name
      image
    }
  }
`;

// -------------------------------------------------------------------------- //

const STYLES = () => ({
  app_bar: {
    position: 'relative',
    flex: 0,
  },
  flex: {
    flex: 1,
  },
  root: {
    flex: 1,
    overflow: 'auto',
  },
});

// -------------------------------------------------------------------------- //

class Textures extends React.Component {

  state = {
    create_dialog: false,
  }

  render() {
    const { classes } = this.props;
    const { create_dialog } = this.state;

    return (
      <React.Fragment>
        {this.renderToolbar()}
        <div className={classes.root}>
          {this.renderTextureGrid()}
        </div>
        <NewTextureDialog
          open={create_dialog}
          close={() => this.closeCreateDialog()}
        />
      </React.Fragment>
    );
  }

  renderToolbar = () => {
    const { classes } = this.props;

    return (
      <AppBar color="primary" elevation={0} className={classes.app_bar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Textures
          </Typography>
          <Button color="inherit" onClick={() => this.openCreateDialog()}>
            Create
          </Button>
        </Toolbar>
      </AppBar>
    );
  }

  renderTextureGrid = () => {
    return (
      <Grid container spacing={0}>
        <Query query={GET_TEXTURES}>
        {({data}) => (
          ((data && data.textureSearch) &&
          data.textureSearch.map(texture => (
          <Grid key={texture.id} item xs={4}>
            <TextureCard
              textureID={texture.id}
              image={texture.image}
              name={texture.name}
            />
          </Grid>
          ))) || null)}
        </Query>
      </Grid>
    );
  }

  openCreateDialog = () => {
    this.setState({ create_dialog: true });
  }

  closeCreateDialog = () => {
    this.setState({ create_dialog: false });
  }

}

// -------------------------------------------------------------------------- //

export default withStyles(STYLES)(Textures);

// -------------------------------------------------------------------------- //
