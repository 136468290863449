
// -------------------------------------------------------------------------- //

import { CenterHorizontalFlow } from './flows/center-horizontal'
import { CenterVerticalFlow } from './flows/center-vertical'
import { SymmetricalHorizontalFlow } from './flows/symmetrical-horizontal';
import { SymmetricalVerticalFlow } from './flows/symmetrical-vertical';
import { GridHorizontalFlow } from './flows/grid-horizontal';
import { GridVerticalFlow } from './flows/grid-vertical';
import {FillVerticalFlow} from './flows/fill-vertical.js'
import {FillHorizontalFlow} from './flows/fill-horizontal'

// -------------------------------------------------------------------------- //

export const WIDGET_FLOWS = ({
  'center-horizontal': {
    name: 'Center (Horizontal)',
    type: CenterHorizontalFlow,
  },
  'center-vertical': {
    name: 'Center (Vertical)',
    type: CenterVerticalFlow,
  },
  'symmetrical-horizontal': {
    name: 'Symmetrical (Horizontal)',
    type: SymmetricalHorizontalFlow,
  },
  'symmetrical-vertical': {
    name: 'Symmetrical (Vertical)',
    type: SymmetricalVerticalFlow,
  },
  'grid-horizontal': {
    name: 'Grid (Horizontal)',
    type: GridHorizontalFlow,
  },
  'grid-vertical': {
    name: 'Grid (Vertical)',
    type: GridVerticalFlow,
  },
  'fill-vertical': {
    name: 'Fill (Vertical)',
    type: FillVerticalFlow,
  },
  'fill-horizontal': {
    name: 'Fill (Horizontal)',
    type: FillHorizontalFlow,
  }
});

// -------------------------------------------------------------------------- //
