
// -------------------------------------------------------------------------- //
export const TAG_FAVORITE = 'FAVORITE';
export const TAG_ANIMATED      = 'ANIMATED';
export const TAG_WIDGETS       = 'WIDGETS';
export const TAG_STATS         = 'STATS';
export const TAG_ROSTERS       = 'ROSTERS';
export const TAG_FAMILY_PACKS  = 'FAMILY_PACKS';
export const TAG_MOBILE_FRIENDLY  = 'MOBILE_FRIENDLY';
export const TAG_PRESTO         = 'PRESTO';
export const TAG_SCHEDULE         = 'SCHEDULE';

export const TAG_CUSTOM        = 'CUSTOM';
export const TAG_PREMIUM       = 'PREMIUM';
export const TAG_STOCK         = 'STOCK';

export const TAG_QUICK_CREATE = 'QUICK_CREATE';

// -------------------------------------------------------------------------- //

export const ARRAY = [
  { value: TAG_STOCK, name: 'Standard Templates' },
  { value: TAG_CUSTOM, name: 'Custom Templates' },
  { value: TAG_PREMIUM, name: 'Premium Templates' },
  { value: TAG_FAMILY_PACKS, name: 'Family Packs' },
  { value: TAG_MOBILE_FRIENDLY, name: 'Mobile Friendly' },
  { value: TAG_ROSTERS, name: 'Roster Imports' },
  { value: TAG_STATS, name: 'Stats' },
  { value: TAG_ANIMATED, name: 'Slidestory/Animated' },
  { value: TAG_WIDGETS, name: 'Widgets' },
  { value: TAG_PRESTO, name: 'Presto Integrated' },
  { value: TAG_SCHEDULE, name: 'Schedule' },
  { value: TAG_QUICK_CREATE, name: 'Quick Create' },
  { value: TAG_FAVORITE, name: 'Favorite' },
];

// -------------------------------------------------------------------------- //
/**
 * Checks to see if any of a given set of tags are on in a tag bitmask.
 * @param {number} lhs - target tag bitmask
 * @param {number} rhs - bitmask of tags to test
 * @returns {number}
 * true if any of the bits set in rhs are also set in lhs; otherwise, false.
 */
// -------------------------------------------------------------------------- //

export function Test(lhs, rhs) {
  return ((lhs & rhs) !== 0);
}

// -------------------------------------------------------------------------- //
/**
 * Sets certain bits in a tag bitmask.
 * @param {number} lhs - target tag bitmask
 * @param {number} rhs - bitmask of tags to set
 * @returns {number}
 * lhs with the bits set in rhs on.
 */
// -------------------------------------------------------------------------- //

export function Set(lhs, rhs) {
  return (lhs | rhs);
}

// -------------------------------------------------------------------------- //
/**
 * Clears certain bits in a tag bitmask.
 * @param {number} lhs - target tag bitmask
 * @param {number} rhs - bitmask of tags to clear
 * @returns {number}
 * lhs with the bits set in rhs off.
 */
// -------------------------------------------------------------------------- //
export function Unset(lhs, rhs) {
  return (lhs & ~rhs);
}

// -------------------------------------------------------------------------- //
/**
 * Flips certain bits in a tag bitmask.
 * @param {number} lhs - target tag bitmask
 * @param {number} rhs - bitmask of tags to flip
 * @returns {number}
 * lhs with the bits set in rhs flipped.
 */
// -------------------------------------------------------------------------- //

export function Flip(lhs, rhs) {
  return (lhs ^ rhs);
}

// -------------------------------------------------------------------------- //
/**
 * Splits a bitmask into an array containing the individual set bits.
 * @param {number} tags - target tag bitmask
 * @returns {number[]}
 * Sorted (ascending) array of the individual set bits.
 */
// -------------------------------------------------------------------------- //

export function Split(tags) {
  let array = [];

  for (let i = 0; i < ARRAY.length; ++i) {
    const bit = (1 << i);

    if (tags & bit) {
      array.push(bit);
    }
  }

  return array;
}

// -------------------------------------------------------------------------- //
/**
 * Bitwise-ORs all elements of a number array and returns the result.
 * @param {number[]} array - target tag array
 * @returns {number}
 * Array reduced to a single value using bitwise-OR.
 */
// -------------------------------------------------------------------------- //

export function Join(array) {
  return array.reduce((lhs, rhs) => (lhs | rhs), 0);
}

// -------------------------------------------------------------------------- //

export function intToByteArray(int) {
  const byteArray = [];
  for (let index = ARRAY.length;index >= 0;index--) {
    if (int >= (1 << index)) {
      byteArray.push((1 << index));
      int = int - (1 << index);
    }
  }
  return byteArray.sort((a, b) => a - b);
}

export function byteArrayToInt(byteArray) {
  let value = 0;
  for (let i = byteArray.length - 1;i >= 0;i--) {
    value = (value) + byteArray[i];
  }
  return value;
}
// -------------------------------------------------------------------------- //
