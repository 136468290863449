import Animation from './Animation';

class FadeAnimation extends Animation
{
  constructor() {
    super();

    this.setOption('duration', 2);
    this.setOption('color', 'rgb(0, 0, 0)');
  }

  apply({ objects, duration, working_area, loop }) {
    let maxDuration = this.getOption('duration');
    const step = 1.0 / this.getFPS();

    let fader = {
      type: 'rect',
      left: 0,
      top: 0,
      width: (working_area.width * 2.0),
      height: (working_area.height * 2.0),
      fill: this.getOption('color'),
      opacity: 1,
      scaleX: 1,
      scaleY: 1,
    };

    const delay = ((duration + step) * 0.1);

    for (let i = 0; i < (duration + step); i += step) {
      const size = Math.max(
        (fader.width * fader.scaleX),
        (fader.height * fader.scaleY)
      );

      this.setOption('duration', Math.min((size / 16), maxDuration));
      let key_frame = this.getKeyFrame(fader, i);

      if (i < delay) {
        key_frame['opacity'] = 1.0;
      } else {
        key_frame['opacity'] = this.tween((i - delay), (duration - delay), loop);
      }
    }

    objects.push(fader);
    this.setOption('duration', maxDuration);
  }
}

export default FadeAnimation;
