
// -------------------------------------------------------------------------- //

import * as React from 'react';

import {
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  ArrowLeft,
  Folder,
} from 'mdi-material-ui';

import {compose, graphql, Query} from 'react-apollo';
import gql from 'graphql-tag';

// -------------------------------------------------------------------------- //

const styles = (theme) => ({
  flex: {
    flex: 1,
  },
  list: {
    height: 200,
    backgroundColor: '#FAFAFA',
    overflow: 'auto',
    marginTop: 12,
  },
  progress: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
});

// -------------------------------------------------------------------------- //

const GET_FOLDER_CONTENTS = gql`
  query getCustomTemplateFolder($id: ID!) {
    getCustomTemplateFolder(
      id: $id
    ) {
      id
      name
      parent {
        id
      }
      templates {
        id
        name
        template {
          id
          image
          url
        }
      }
      children {
        id
        name
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const GET_ORPHANED_FOLDERS = gql`
  query getOrphanedFolders {
    getOrphanedFolders {
      id
      name
    }
  }
`;

const ROLES_QUERY = gql`
  query {
    me {
      id
      role {
        id
        name
        restrictCategoryFlag
        categories {
          id
          name
        }
        allowCustomFlag
        allowCustomSaveFlag
        allowCustomFolderSaveFlag
        restrictCustomFlag
        customFolders {
          id
          name
        }
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

function renderFolder(folder, changeFolder) {
  return (
    <ListItem dense button key={folder.id} onClick={() => changeFolder(folder.id)}>
      <ListItemIcon>
        <Folder />
      </ListItemIcon>
      <ListItemText primary={folder.name}/>
    </ListItem>
  );
}

const RenderFile = props => (<ListItem dense><ListItemText primary={props.name}/></ListItem>)
// -------------------------------------------------------------------------- //

function renderFolders(folders, files , changeFolder) {
  let _folders = <ListItem dense key="empty"><ListItemText primary="(Empty Folder)"/></ListItem>;
  let _files = null;
  if (folders.length > 0 ) {
    _folders = folders.map((folder) => renderFolder(folder, changeFolder));
  }
  if(files.length > 0){
    _files = files.map( obj => <RenderFile key={obj.id} {...obj}/>);
  }
  return { folders: _folders, files: _files};
}

// -------------------------------------------------------------------------- //

function CustomFolderView(props) {
  const { classes, folderId, changeFolder, rolesQuery } = props;
  const query = (folderId ? GET_FOLDER_CONTENTS : GET_ORPHANED_FOLDERS);
  const variables = { id: folderId };
  const allowedCustomFolders = [];

  return (
    <Query query={query} variables={variables} fetchPolicy="network-only">
    {({ data, loading }) => {
      let allowCustomFlag = true;
      let restrictCustomFlag = false;

      if (!folderId) {
        if (rolesQuery && rolesQuery.me && rolesQuery.me.role) {
          allowCustomFlag = rolesQuery.me.role.allowCustomFlag;
          restrictCustomFlag = rolesQuery.me.role.restrictCustomFlag;
          if (rolesQuery.me.role.customFolders) {
            rolesQuery.me.role.customFolders
              .forEach((cat) => {
                allowedCustomFolders.push(cat.id);
              });
          }
        }
      }

      let folders = [], folder_name, parent;
      let files = [];
      if (data && data.getCustomTemplateFolder) {
        const folder = data.getCustomTemplateFolder;
        folder_name = folder.name;
        folders = folder.children;
        files = folder.templates;
        parent = {
          id: ((folder.parent && folder.parent.id) || null),
        };
      } else if (data && data.getOrphanedFolders) {
        folder_name = 'My Custom Templates';
        let folderlist = [];
        if (allowCustomFlag && restrictCustomFlag) {
          data.getOrphanedFolders
            .forEach((result) => {
              if (allowedCustomFolders.includes(result.id)) {
                folderlist.push(result);
              }
            });
        } else {
          folderlist = data.getOrphanedFolders;
        }
        folders = folderlist;
      }

      let contents = null;

      if (loading) {
        contents = (
          <div className={classes.progress}>
            <CircularProgress size={48}/>
          </div>
        );
      } else {
        contents = renderFolders(folders,files, changeFolder);
      }

      return (
        <List>
          <React.Fragment>
            {(parent) &&
            <IconButton onClick={() => changeFolder(parent.id)}>
              <ArrowLeft/>
            </IconButton>}
            <Typography style={{ display: 'inline' }} variant="subtitle1">
              {folder_name}
            </Typography>
          </React.Fragment>

          <div className={classes.list}>
            {contents.folders}
            {contents.files}
          </div>

        </List>
      );
    }}
    </Query>
  );
}

// -------------------------------------------------------------------------- //

export default compose(
  graphql(ROLES_QUERY, { name: 'rolesQuery' }),
  withStyles(styles),
)(CustomFolderView);

// -------------------------------------------------------------------------- //
