  /* eslint no-redeclare: "off" */

  // -------------------------------------------------------------------------- //

  import React from 'react';

  import {
    createMuiTheme,
    IconButton,
    MuiThemeProvider,
    withStyles,
    withWidth,
    SwipeableDrawer,
    ButtonBase,
    Paper,
    Snackbar,
  } from '@material-ui/core';

  import MuiAlert from '@material-ui/lab/Alert';

  import {
    Close,
    ChevronUp,
    ChevronDown,
  } from 'mdi-material-ui';

  import { isMobileOnly } from 'react-device-detect';
  import { Query } from 'react-apollo';
  import { withRouter } from 'react-router-dom';
  import classNames from 'classnames';
  import gql from 'graphql-tag';

  import { CreateSearchContext } from '../create/search';
  import { Once } from '../Once';
  import { PageTitle } from '../../title';
  import { STEPS } from '../app/Steps';
  import { UserRoleProvider, UserRoleConsumer } from '../user/Permissions';
  import { VirtualTourContext } from '../app/tour';
  import * as Binding from '../../canvas/lib/databinding';
  import * as MetaData from '../../canvas/lib/metadata';
  import * as Sports from '../sport/Sports';
  import Canvas from './Canvas';
  import CanvasUtil from '../../canvas/CanvasUtil';
  import Dict from './dict';
  import DrawerContainer from './DrawerContainer';
  import EditorNav from './EditorNav';
  import FullscreenLoader from '../loading/FullscreenLoader';
  import TimeLine from './TimeLine';
  import Tour from '../../tour/Tour';

  // -------------------------------------------------------------------------- //

  const GET_CLIENT = gql`
    query {
      me {
        id
        firstname
        lastname
        email
        client {
          id
          color1
          color2
          color3
          palette
          name
          nickName
          logoBucket
          logoKey
          disableAutofill
        }
      }
      myFonts {
        id
        fullName
        fontFamily
        type
        bucket
        key
      }
    }
  `;

  // -------------------------------------------------------------------------- //

  export const EditorUnloadCallback = (e) => {
    e.preventDefault();
    e.returnValue = 'Any unsaved changes will be lost.';
  };

  // -------------------------------------------------------------------------- //

  const STYLES = (theme) => ({
    flex: {
      flex: 1,
    },
    tabs: {
      alignSelf: 'stretch',
    },
    flexContainer: {
      height: '100%',
    },
    alert: {
      backgroundColor: '#313131',
      color: theme.palette.common.white,
    },
    tab: {
      height: '100%',
    },
    drawer_affordance: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
    drawer_affordance_mobile: {
      position: 'absolute',
      top: theme.spacing(3),
      right: theme.spacing(1),
    },
    drawer_handle: {
      position: 'absolute',
      top: theme.spacing(1),
      left: '50%',
      transform: 'translateX(-50%)',
      width: theme.spacing(4),
      height: theme.spacing(0.75),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.action.active,
    },
    drawer_handle_button: {
      height: theme.spacing(4),
      left: '50%',
      position: 'absolute',
      top: 0,
      transform: 'translateX(-50%)',
      width: theme.spacing(8),
    },
    drawerPaper: {
      height: 'calc( 100vh - 64px )',
      top: 64,
      left: 80,
      zIndex: -2,
      width: 280,
    },
    drawer_paper_mobile: {
      borderRadius: theme.shape.borderRadius,
      bottom: 0,
      top: '100vh',
      zIndex: 1,
    },
    drawer_paper_mobile_open: {
      top: '60%',
      height: 'calc( 40% - 55px )',
    },
    drawer_paper_mobile_full: {
      top: '10%',
      height: 'calc( 90% - 55px )',
    },
    addButton: {
      width: '90%',
      margin: '0 5%',
    },
    expand_icon: {
      paddingBottom: 0,
      paddingTop: 0,
      marginBottom: 0,
      marginTop: 0,
    },
    expand_box: {
      backgroundColor: theme.palette.background.default,
      height: theme.spacing(3),
      position: 'fixed',
      top: 'calc( 60% + 1px )',
      width: '100%',
      textAlign: 'center',
      zIndex: 100,
    },
    expand_box_full: {
      backgroundColor: theme.palette.background.default,
      height: theme.spacing(3),
      position: 'fixed',
      top: 'calc( 10% + 1px )',
      width: '100%',
      textAlign: 'center',
      zIndex: 100,
    },
    expand_spacer: {
      height: 0,
      width: '100%',
      overflow: 'hidden',
      paddingTop: theme.spacing(2),
    },
    tour: {
      maxWidth: 440,
      outline: 'none',
    },
  });

  const calcViewWidth = () => Math.max(document.documentElement.clientWidth, (window.innerWidth || 0));

  const calcViewHeight = () => Math.max(document.documentElement.clientHeight, (window.innerHeight || 0));

  class Editor extends React.Component {
    state = {
      permissions: null,
      open: !isMobileOnly,
      step: 'background',
      force_step: null,
      drawer_full: false,
      active: null,
      activeGroup: null,
      engineLoaded: false,
      dragging: null,
      width: 0,
      height: 0,
      json: null,
      slide: 0,
      advanced: false,
      fixed: false,
      tourload: false,
      loaded: false
    }

    constructor() {
      super();
      this.sports = {};
      this.importers = {};

      for (const key in Sports.sports) {
        this.sports[key] = Sports.CreateSport(key);
        this.importers[key] = Sports.importers[key];
      }

      this.sports.default = Sports.CreateSport();
      this.grid_object = null;
    }

    getSportCategory = () => {
      const template = this.getTemplate();
      return Sports.GetCategorySport(template && template.categories);
    }

    getTemplate = () => {
      const { draft, template } = this.props;

      if (draft && draft.template) {
        return draft.template;
      } if (template && template.__typename === 'Post') {
        return template.template;
      }

      return template;
    }

    getTemplateSport = () => {
      const key = this.getSportCategory();

      if (!(key in this.sports)) {
        return null;
      }

      return this.sports[key];
    }

    importXmlFile(xml, options = {}) {
      const category = this.getSportCategory();
      const sport = this.sports[category];
      const importers = this.importers[category];
      const players = (options.mapping || []);

      if (sport && importers) {
        importers.forEach((Importer) => {
          const importer = new Importer();
          importer.import(sport, xml, { players });
        });

        if (options.swap) {
          sport.swap();
        }

        this.performBinding();
      }
    }

    componentDidMount() {
      window.Intercom('trackEvent', 'opened-editor');
      this.initCanvas();
      const prestoSite = localStorage.getItem('prestoSite');
      if (!prestoSite) {
        window.addEventListener('beforeunload', EditorUnloadCallback);
      }
      this.setState({loaded: true})
    }


    componentWillUnmount() {
      const prestoSite = localStorage.getItem('prestoSite');
      if (!prestoSite) {
        window.removeEventListener('beforeunload', EditorUnloadCallback);
      }
    }

    setObjectAero = (object, enable_aero) => {
      if (!object) {
        return;
      }

      if (enable_aero) {
        if (!('initial_opacity' in object)) {
          object.initial_opacity = object.opacity;
          object.opacity *= 0.5;
          object.evented = false;
        }
      } else if ('initial_opacity' in object) {
        object.opacity = object.initial_opacity;
        delete object.initial_opacity;
        object.evented = true;
      }

      object.dirty = true;

      if (!object.media) {
        return;
      }

      const { element } = object.media;

      if (!element) {
        return;
      }

      if (enable_aero) {
        if (!('initial_opacity' in element)) {
          element.initial_opacity = element.opacity;
          element.opacity *= 0.5;
          element.evented = false;
        }
      } else if ('initial_opacity' in element) {
        element.opacity = element.initial_opacity;
        delete element.initial_opacity;
        element.evented = true;
      }

      element.dirty = true;
    }

    setObjectEvented = (object, evented) => {
      if (!object) {
        return;
      }

      object.evented = evented;

      if (object.media) {
        const { element } = object.media;

        if (element) {
          element.evented = evented;
        }
      } else {
        const { parent } = object;

        if (parent) {
          parent.evented = evented;
        }
      }
    }

    enterBackgroundStep = (e) => {
      const { canvas } = this.CanvasUtil;

      let above_selected = false;

      canvas.getObjects().forEach((object) => {
        if (object.type === 'backgroundBox') {
          let enable_aero = true;

          if (e.next) {
            if (e.next.uuid === object.uuid) {
              enable_aero = false;
            } else if (e.next.parent && e.next.parent.uuid === object.uuid) {
              enable_aero = false;
            }
          } else {
            enable_aero = false;
          }

          if (object.fillType === 'color') {
            enable_aero = false;
          }
          this.setObjectAero(object, enable_aero);
        }

        this.setObjectEvented(object, true);

        switch (object.type) {
          case 'backgroundBox': {
            if (object.fillType !== 'color') {
              this.setObjectEvented(object, false);
            }

            break;
          }
          case 'polygon':
          case 'rect': {
            this.setObjectEvented(object, false);
            break;
          }
          case 'image': {
            this.setObjectEvented(
              object, object.parent && (
                !above_selected
                || (e.next && !object.intersectsWithObject(e.next))
              ),
            );
            break;
          }
          default: {
            break;
          }
        }

        if (e.next && object.uuid === e.next.uuid) {
          above_selected = true;
        }
      });

      canvas.renderAll();
    }

    changeBackgroundStep = (e) => {
      const { canvas } = this.CanvasUtil;

      let above_selected = false;

      canvas.getObjects().forEach((object) => {
        if (object.type === 'backgroundBox') {
          let enable_aero = true;

          if (e.next) {
            if (e.next.uuid === object.uuid) {
              enable_aero = false;
            } else if (e.next.parent && e.next.parent.uuid === object.uuid) {
              enable_aero = false;
            }
          } else {
            enable_aero = false;
          }

          if (object.fillType === 'color') {
            enable_aero = false;
          }

          this.setObjectAero(object, enable_aero);
        }

        this.setObjectEvented(object, true);

        switch (object.type) {
          case 'backgroundBox': {
            if (object.fillType !== 'color') {
              this.setObjectEvented(object, false);
            }

            break;
          }
          case 'polygon':
          case 'rect': {
            this.setObjectEvented(object, false);
            break;
          }
          case 'image': {
            this.setObjectEvented(
              object, object.parent && (
                !above_selected
                || (e.next && !object.intersectsWithObject(e.next))
              ),
            );
            break;
          }
          default: {
            break;
          }
        }

        if (e.next && object.uuid === e.next.uuid) {
          above_selected = true;
        }
      });

      this.CanvasUtil.canvas.renderAll();
    }

    leaveBackgroundStep = (e) => {
      const { canvas } = this.CanvasUtil;

      canvas.getObjects().forEach((object) => {
        this.setObjectAero(object, false);
        this.setObjectEvented(object, true);
      });

      canvas.renderAll();
    }

    setStep = (self) => (step, keepActiveObject, force) => () => {
      if (this.state.force_step !== null && this.state.force_step !== step && !force) {
        return;
      }

      if (!keepActiveObject && step !== this.state.step) {
        this.setState({
          active: null,
          activeGroup: null,
        });
      } else {
        keepActiveObject = true;
      }

      if (step === 'background' && this.state.step !== 'background') {
        this.enterBackgroundStep({ current: null });
      } else if (step !== 'background' && this.state.step === 'background') {
        this.leaveBackgroundStep({ current: null });
      }

      const state = {};

      if (this.state.step === step) {
        state.open = !this.state.open;
      } else if (step === 'more' || step === 'less') {
        state.advanced = (step === 'more');
        step = this.state.step;
      } else {
        state.step = step;
        state.fixed = (!!keepActiveObject);
        state.open = true;
      }

      this.setState(state, () => {
        if (!keepActiveObject) {
          self.CanvasUtil.canvas.discardActiveObject();
        }

        self.CanvasUtil.canvas.requestRenderAll();
        if (step !== 'background') {
          self.CanvasUtil.setStep(step);
        }
        self.CanvasUtil._resize();
      });
    }

    setDimension = (prop) => (e) => {
      const newState = {};
      newState[prop] = e.target.value;
      this.setState(newState);
    }

    componentDidCatch(error, info) {
      // Display fallback UI
      this.setState({
        step: null,
        active: null,
      });
      // You can also log the error to an error reporting service
      /* global Raven */
      Raven.captureException(error, {
        extra: info,
      });
    }

    updateObjects = (e) => {
      if (e.reason === 'widgets-loaded') {
        this.forceUpdate();
      }

      // const objects = self.CanvasUtil.canvas.getObjects();
      // self.setState({ objects });
      // this.performBinding();
    }

    updateStateRotating = (self) => (e) => {
      let target = null;

      if (e.target && e.target.canvas) {
        target = e.target.canvas.getActiveObjects();
      }

      let targetIndex = 0;

      if (this.state.active) {
        targetIndex = Math.max(target.findIndex((el) => el === this.state.active), 0);
      }
      target[targetIndex].rotate(Math.ceil(target[targetIndex].angle / 5) * 5);
      target[targetIndex].setCoords();

      const state = {
        active: target[targetIndex],
      };

      self.setState(state);
    }

    updateActive = (self) => (e) => {
      if (self.state.fixed) {
        this.forceUpdate();
        return;
      }

      let target = null;

      if (e.target && e.target.canvas) {
        target = e.target.canvas.getActiveObjects();
      }
      if (target && target.length > 0) {
        let step = null;
        let targetIndex = 0;

        if (this.state.active) {
          targetIndex = Math.max(target.findIndex((el) => el === this.state.active), 0);
        }

        switch (target[targetIndex].type) {
          case 'activeSelection': {
            // scary. Ignore for now.
            break;
          }
          case 'backgroundBox': {
            step = 'background';
            break;
          }
          case 'rect':
          case 'line':
          case 'circle':
          case 'triangle':
          case 'polygon':
          case 'polyline':
          case 'barGraph':
          case 'SliderBar':
          case 'QuickCreate':
          case 'SSLogo':
          case 'ssLogo':
          case 'svg':
          case 'path':
          case 'group': {
            step = 'elements';
            break;
          }
          case 'text':
          case 'i-text':
          case 'textbox':
          case 'curvedText':
          case 'ParText': {
            step = 'text';
            break;
          }
          case 'logoBox': {
            step = 'graphicstab';
            break;
          }
          case 'cutoutBox': {
            step = 'graphicstab';
            break;
          }
          case 'image': {
            if (target[targetIndex].parent) {
              switch (target[targetIndex].parent.type) {
                case 'backgroundBox': {
                  step = 'background';
                  break;
                }
                case 'logoBox': {
                  step = 'graphicstab';
                  break;
                }
                case 'cutoutBox': {
                  step = 'graphicstab';
                  break;
                }
                default: {
                  break;
                }
              }
            } else {
              step = 'elements';
            }
            break;
          }
          default:
            break;
        }

        const state = {
          active: target[targetIndex],
          activeGroup: target,
        };

        if (step) {
          state.open = true;

          if (step !== self.state.step) {
            state.step = step;
            this.CanvasUtil.setStep(step);

            if (step === 'background' && this.state.step !== 'background') {
              this.enterBackgroundStep({
                next: state.active,
              });
            } else if (step !== 'background' && this.state.step === 'background') {
              this.leaveBackgroundStep({
                current: this.state.active,
              });
            }
          } else if (step === 'background') {
            this.changeBackgroundStep({
              next: state.active,
              current: this.state.active,
            });
          }
        }

        self.setState(state);
      } else if (self.state.active) {
        if (this.state.step === 'background') {
          this.changeBackgroundStep({
            next: null,
            current: this.state.active,
          });
        }

        self.setState({
          active: null,
          activeGroup: null,
        });
      }
    }

    dragStart = (self) => (event) => {
      self.setState({
        dragging: event.dataTransfer.getData('text'),
      });
    }

    updateDimensions = (width, height) => {
      this.setState({ width, height });
    }

    applyXmlStash = () => {
      const { canvas } = this.CanvasUtil;

      if (!MetaData.hasMetaData(canvas, 'xml_stash')) {
        return;
      }

      const data = MetaData.getMetaData(canvas, 'xml_stash');
      const sport = this.getTemplateSport();

      for (const key in data) {
        const variable = sport.getVariableById(key);

        if (variable === null) {
          continue;
        }

        variable.reset();

        for (let i = 0; i < data[key].length; ++i) {
          variable.setValue(data[key][i], i);
        }
      }

      canvas.getObjects().forEach((object) => {
        object.dirty = true;
      });

      MetaData.clearMetaData(canvas, 'xml_stash');
      this.performBinding();
    }

    lockAnimatedObjects() {
      const { canvas } = this.CanvasUtil;
      canvas.getObjects().filter((o) => o.animation).forEach((o) => {
        o.lockMovementX = true;
        o.lockMovementY = true;
        o.lockScalingX = true;
        o.lockRotation = true;
        o.lockScalingY = true;
      });
    }

    addSSLogo() {
      const clientFlag = localStorage.getItem('clientFlags');
      let flag = null;
      let hasLogo = null;
      if (clientFlag) {
        flag = clientFlag.indexOf('SS_LOGO') >= 0;
        hasLogo = this.CanvasUtil.canvas.getObjects('ssLogo').length <= 0;
      }
      if (flag && hasLogo) {
        const logo = new this.CanvasUtil.fabric.SSLogo();
        this.CanvasUtil.canvas.add(logo);

        logo._render(this.CanvasUtil.canvas.contextContainer);
        logo.initialize();
      }

      if (!flag && !hasLogo) {
        // remove ss logo
        const ssLogo = this.CanvasUtil.canvas.getObjects('ssLogo');
        this.CanvasUtil.canvas.remove(ssLogo);
      }
    }

    performBinding = (image = false) => {
      const sport = this.getTemplateSport();
      const { canvas } = this.CanvasUtil;
      const objects = canvas.getObjects();

      objects.forEach((object) => {
        Binding.Bind(object, sport, image);
      });

      canvas.requestRenderAll();
      this.forceUpdate();
    }

    updateXmlData = () => {
      const sport = this.getTemplateSport();

      this.CanvasUtil.canvas.getObjects().forEach((object) => {
        Binding.UpdateBindings(object);

        if (sport !== null) {
          Sports.UpdateSport(object, sport);
        }
      });
    }

    updateVariables = (e) => {
      const object = e.target;
      const binding = object.dataBinding;
      if (binding) {
        if (object.type === 'i-text') {
          let value = object.text;

          if (value === '') {
            value = null;
          }

          for (let i = 0; i < binding.length; ++i) {
            const sport = this.sports[binding[i].sport];
            if (sport) {
              const variable = sport.getVariableById(binding[i].variable);
              if (variable) {
                variable.setValue(value, binding[i].index);
              }
            }
          }
        }
      }

      this.performBinding();
    }

    bindVariables() {
      this.CanvasUtil.canvas.on('text:changed', this.updateVariables);
    }


    async bindQuickCreateObjects(){
      this.CanvasUtil.canvas.on("object:modified",async (e) => {
        let slides = this.state.json
        const json = await this.CanvasUtil.serializeToJSON()
        let firstSlideBackground = this.state.json.data[0]
             .canvas.objects.filter(ob => ob.type === "backgroundBox")

        if(e.target !== undefined && this.state.loaded === true){
           slidesLoop: for(let i = 0; i<slides.data.length;i++){
             for(let a = 0; a < slides.data[i].canvas.objects.length;a++){
               switch (e.target.type){
                 case "i-text": {
                   if(slides.data[i].canvas.objects[a].name === e.target.name){
                     slides.data[i].canvas.objects[a].text = e.target.text;
                     slides.data[i].canvas.objects[a].fill = e.target.fill;
                     continue slidesLoop;
                   }else{
                     break
                   }
                 }
                 case "backgroundBox": {
                   if (slides.data[i].canvas.objects[a].type === "backgroundBox") {
                     if (slides.data[i].canvas.objects[a].specialObject === e.target.specialObject) {
                       if(e.filter){
                         const newBackground = json.objects.filter(value => value.type === "backgroundBox")[0]
                         slides.data[i].canvas.objects[a].media = {
                           filteredSrc: newBackground.media.filteredSrc,
                           filters: newBackground.media.filters,
                           src: newBackground.media.src
                         }
                       }else{
                         if(firstSlideBackground.length > 1 && slides.data[i].canvas.objects[a].name === e.target.name){

                           slides.data[i].canvas.objects[a].media =
                               this.compareMedia(e,firstSlideBackground[0]) ? null :
                                   { src: e.target.media.element.getSrc() }
                           slides.data[i].canvas.objects[a].fill = e.target.fill === 'transparent'? '#555' : e.target.fill
                           slides.data[i].canvas.objects[a].fillType = e.target.fillType
                           slides.data[i].canvas.objects[a].mode = e.target.mode
                         }else{

                           slides.data[i].canvas.objects[a].media =
                               this.compareMedia(e,firstSlideBackground[0]) ? null :
                                   e.target.media.element?
                                       {
                                         src: e.target.media.element.getSrc(),
                                         filteredSrc: e.target.media.filteredSrc,
                                         filters: e.target.media.element.filters
                                       }
                                       : { src: e.target.media.src }
                           slides.data[i].canvas.objects[a].fill = e.target.fill === 'transparent'? '#555' : e.target.fill
                           slides.data[i].canvas.objects[a].fillType = e.target.fillType
                           slides.data[i].canvas.objects[a].mode = e.target.mode
                         }
                       }
                     }

                   }
                   break
                 }
                 default:
                 case "cutoutBox":
                 case "logoBox": {
                   if(slides.data[i].canvas.objects[a].name === e.target.name && e.target.name !== "Custom Cutout"
                       && e.target.media){
                     if(slides.data[i].canvas.objects[a].media){
                       slides.data[i].canvas.objects[a].media.src = e.target.media.element.getSrc()
                     }else{
                       slides.data[i].canvas.objects[a].media = {src: e.target.media.element.getSrc()}
                     }
                     continue slidesLoop
                   }
                   break
                 }
               }
             }
             await this.saveSlide();
           }
         }
      });
    }

    updateTextWidth() {
      this.CanvasUtil.canvas.on('text:changed', this.limitTextWidth);
    }

    compareMedia(e,background){
      let result = true
      const target = e.target


      if (target.media !== undefined && target.media !== null){
        if(target.media.element !== undefined)
          result = false
        else if (target.media.src !== null)
          result = false
      }

      if(e.color)
        result = true


      return result
    }

    limitTextWidth = (e) => {
      const object = e.target;

      if (object.type === 'i-text') {
        if (object.width * object.scaleX > this.state.width) {
          while (object.width * object.scaleX > this.state.width) {
            object.scaleX *= 0.95;
          }
        }
      }
    }

    setDefaultStep = () => {
      const { advanced } = this.state;
      const slideshow = this.getRouteSlideshow();
      const isQuickCreate = this.getQuickCreate();
      const template = this.getTemplate();

      const filterOptions = {
        canvasUtil: this.CanvasUtil,
        canvas: (this.CanvasUtil && this.CanvasUtil.canvas),
        template,
        advanced,
        slideshow: slideshow || isQuickCreate,
      };
      if (template && template.startingStep && (template.startingStep === 'Text' || template.startingStep === 'Graphics')) {
        if (template.startingStep === 'Text') {
          this.setState({ step: 'text' });
        } else {
          this.setState({ step: 'graphicstab' });
        }
      } else if (Dict.stats.filter(filterOptions)) {
        this.setState({ step: 'stats' });
      } else if (Dict.lineup.filter(filterOptions)) {
        this.setState({ step: 'lineup' });
      } else if (Dict.widgets.filter(filterOptions)) {
        this.setState({ step: 'widgets' });
      } else if (slideshow || isQuickCreate) {
        this.setState({ step: 'background' });
      } else {
        const background_boxes = this.CanvasUtil.canvas.getObjects('backgroundBox');

        if (background_boxes.length > 0) {
          this.setState({ step: 'background' });
          this.enterBackgroundStep({ current: null, next: null });

          if (background_boxes.length === 1) {
            const bg = background_boxes[0];

            if (bg && bg.media && bg.media.element) {
              this.CanvasUtil.canvas.setActiveObject(bg.media.element);
            } else {
              this.CanvasUtil.canvas.setActiveObject(bg);
            }
          }
        }
      }
    }

    getRouteMethod = () => {
      const { match } = this.props;
      const { params } = match;
      return (this.getRouteSlideshow() || this.getQuickCreate() ? params.submethod : params.method);
    }

    getRouteSlideshow = () => {
      const { match } = this.props;
      const { params } = match;

      return (params.method === 'slideshow');
    }

    getQuickCreate = () => {
      const { quickCreate } = this.props;
      return quickCreate;
    }

    initCanvas() {
      this.CanvasUtil = new CanvasUtil();
      this.CanvasUtil.updateDimensions = this.updateDimensions;
      this.CanvasUtil.updateActive = this.updateActive(this);
      this.CanvasUtil.updateStateRotating = this.updateStateRotating(this);
      this.CanvasUtil.updateObjects = (e) => this.updateObjects(e);
      this.CanvasUtil.mediaSend = (e) => this.mediaSend(e);
      this.CanvasUtil.onSwapTemplate = (json) => this.onSwapTemplate(json);
      const { match, template } = this.props;
      const { params } = match;
      const method = this.getRouteMethod();
      const isQuickCreate = this.getQuickCreate();
      const slideshow = this.getRouteSlideshow();
      this.CanvasUtil.onLoad = () => {
        this.CanvasUtil.canvas.on('widget:loaded', () => {
          Binding.UpdateBindings(this.CanvasUtil.canvas.getObjects());
          this.forceUpdate();
        });
        this.CanvasUtil.canvas.on("object:added",() => {
          let objst = this.CanvasUtil.canvas.getObjects()
          objst.forEach(obj => {
            if (obj.parent?.type === 'logoBox' && obj.parent?.shadow !== null) {
              obj.shadow = obj.parent.shadow
            }
          })
        })
        // determine what kind of load this is.
        switch (method) {
          case 'blank': {
            this.CanvasUtil.loadBlank(params.width, params.height);
            this.CanvasUtil.setDirtyEnabled(true);

            const json = {
              data: [{
                canvas: {},
                duration: 5,
                preview: {
                  png: '',
                  jpg: '',
                },
              }],
              transitions: [],
            };

            this.setState({
              engineLoaded: true,
              width: params.width,
              height: params.height,
              json,
            });

            this.CanvasUtil.canvas.setActiveObject(this.CanvasUtil.canvas.getObjects('backgroundBox')[0]);
            this.addSSLogo();
            this.setDefaultStep();
            this.CanvasUtil.canvas.requestRenderAll();
            this.CanvasUtil.setDirtyEnabled(true);
            break;
          }
          case 'template': {
            this.CanvasUtil.loadFromTemplate(template, slideshow || isQuickCreate, (json) => {
              this.addSSLogo();
              this.updateXmlData();
              this.applyXmlStash();
              this.lockAnimatedObjects();
              this.fixBackgroundFillType();
              this.pngOverride();
              this.CanvasUtil.setDirtyEnabled(true);
              const prestoSite = localStorage.getItem('prestoSite');
              if (prestoSite === 'true') {
                this.applyPrestoAPI();
              } else {
                this.applyTeamInfo();
              }

              this.setState({
                engineLoaded: true,
                width: template.width,
                height: template.height,
                json,
              });

              this.setDefaultStep();
              this.CanvasUtil.canvas.requestRenderAll();
            });
            break;
          }
          case 'custom':
          case 'post': {
            this.CanvasUtil.loadFromTemplate(template, slideshow || isQuickCreate, (json) => {
              this.addSSLogo();
              this.updateXmlData();
              this.applyXmlStash();
              this.lockAnimatedObjects();
              this.CanvasUtil.setDirtyEnabled(true);

              this.setState({
                engineLoaded: true,
                width: template.width,
                height: template.height,
                json,
              });

              this.setDefaultStep();
              this.CanvasUtil.canvas.requestRenderAll();
            }, false, true);
            break;
          }
          case 'draft': {
            this.CanvasUtil.loadFromDraft(this.props.draft, slideshow || isQuickCreate, (json) => {
              this.updateXmlData();
              this.applyXmlStash();
              this.lockAnimatedObjects();
              this.CanvasUtil.setDirtyEnabled(true);
              this.setState({
                engineLoaded: true,
                width: this.props.draft.width,
                height: this.props.draft.height,
                json,
              });

              this.setDefaultStep();
              this.CanvasUtil.canvas.requestRenderAll();
            });
            break;
          }
          default: {
            break;
          }
        }

        if(isQuickCreate){
          this.bindQuickCreateObjects();
        }

        this.bindVariables();
        this.updateTextWidth();
      };

      const { data } = this.props;
      const { client } = data.me;
      const options = {
        id: 'c',
        initStep: 'background',
        slideshow: slideshow || isQuickCreate,
        method,
        colors: {
          primary: client.color1,
          secondary: client.color2,
          tertiary: client.color3,
        },
      };
      this.CanvasUtil.init(options);

    }

    addFilter = (filter, variables = {}) => () => {
      // get active object, set output array.
      const { active, activeGroup, CanvasUtil } = this.props;
      const newFilters = [];

      if (filter.filter) {
        // if the filter is simple, work is done.
        newFilters.push(new filter.filter());
      } else if (filter.filters) {
        // if it's not
        filter.filters.forEach((subFilter) => {
          const options = { };

          if (subFilter.options) {
            Object.keys(subFilter.options).forEach((key) => {
              // for each configurable option.
              const option = subFilter.options[key];

              if (option.id) {
                if (variables[option.id]) {
                  options[key] = variables[option.id];
                } else {
                  if (filter.variables[option.id].type === 'color') {
                    switch (filter.variables[option.id].default) {
                      case 'primary':
                      case 'secondary':
                      case 'tertiary': {
                        filter.variables[option.id].default = (
                          CanvasUtil.canvas.colors[filter.variables[option.id].default]
                        );

                        break;
                      }
                      default: {
                        break;
                      }
                    }
                  }

                  options[key] = filter.variables[option.id].default;
                }
              } else {
                options[key] = option;
              }
            });
          }
          newFilters.push(new subFilter.filter(options));
        });
      }
      activeGroup.forEach((obj) => {
        obj.filters = newFilters;
        obj.applyFilters();
        obj.setCoords();
        CanvasUtil.dirtyCanvas();
      });
      active.canvas.requestRenderAll();
      this.setState({
        customFilter: filter,
        filterVariables: variables,
      });
      this.onModified();
    }


    fixBackgroundFillType = () => {
      const { canvas } = this.CanvasUtil;

      canvas.getObjects('backgroundBox').forEach((object) => {
        if (object.fillOverride) {
          object.fillType = 'color';
        }
      });
    }

    pngOverride = () => {
      const { canvas } = this.CanvasUtil;
      canvas.getObjects('image').forEach((object) => {
        if (object.fillOverride) {
          let filter;
          if (object.fillOverride === 'primary') {
            filter = new this.CanvasUtil.fabric.Image.filters.BlendColor({
              color: this.props.theme.palette.primary.main,
              mode: 'multiply',
            });
          }
          if (object.fillOverride === 'secondary') {
            filter = new this.CanvasUtil.fabric.Image.filters.BlendColor({
              color: this.props.theme.palette.secondary.main,
              mode: 'multiply',
            });
          }
          object.filters.push(filter);
          object.applyFilters();
          canvas.renderAll();
        }
      });
    }

    applyTeamInfo = () => {
      const { client } = this.props.data.me;
      const { canvas } = this.CanvasUtil;
      if (client.disableAutofill) {
        return;
      }

      canvas.getObjects().forEach((object) => {
        switch (object.specialObject) {
          case 'userTeamName': {
            if (client && client.nickName && client.nickName !== '') {
              object.set('text', client.nickName);
            } else {
              object.set('text', client.name);
            }
            this.CanvasUtil.updateTextCase(object);
            break;
          }
          case 'userLogo': {
            if (client.logoBucket && client.logoKey) {
              object.setMedia({
                src: (`https://${client.logoBucket}.s3.us-east-2.amazonaws.com/${client.logoKey}?t=${new Date().getTime()}`),
              }, false, true);
            }

            break;
          }
          default: {
            break;
          }
        }
      });
      canvas.requestRenderAll();
    }


    applyPrestoAPI = () => {
      const { client } = this.props.data.me;
      const { canvas } = this.CanvasUtil;
      let backgroundDone = false;
      canvas.getObjects().forEach((object) => {
        if (object.type === 'backgroundBox') {
          if (!backgroundDone) {
            let imageUrl = localStorage.getItem('imageurl');
            if (imageUrl !== '') {
              imageUrl = decodeURIComponent(imageUrl);
              imageUrl = decodeURIComponent(imageUrl);
              object.setMedia({
                src: (`${imageUrl}?t=${new Date().getTime()}`),
              }, false, true);
              canvas.requestRenderAll();
            }
          }
          backgroundDone = true;
        }
      });
      const eventId = localStorage.getItem('eventid');
      const bearer = localStorage.getItem('bearer');
      let prestoAPIEndpoint = process.env.REACT_APP_PS_GAMEDAY_API_ENDPOINT;
      if (eventId && eventId !== '') {
        fetch(`${prestoAPIEndpoint}events/${eventId}`, {
          headers: {
            Authorization: `Bearer ${bearer}`,
          },
        })
          .then((response) => response.json())
          .then((jsonData) => {
            if (jsonData && jsonData.data) {
              canvas.getObjects().forEach((object) => {
                switch (object.specialObject) {
                  case 'userTeamName': {
                    if (jsonData.data.teams && jsonData.data.teams.homeTeam && jsonData.data.teams.homeTeam.teamName) {
                      object.set('text', jsonData.data.teams.homeTeam.teamName);
                      this.CanvasUtil.updateTextCase(object);
                    }
                    break;
                  }
                  case 'oppTeamName': {
                    if (jsonData.data.teams && jsonData.data.teams.awayTeam && jsonData.data.teams.awayTeam.teamName) {
                      object.set('text', jsonData.data.teams.awayTeam.teamName);
                      this.CanvasUtil.updateTextCase(object);
                    }
                    break;
                  }
                  case 'userScore': {
                    if (jsonData.data.score && jsonData.data.score.home) {
                      object.set('text', jsonData.data.score.home);
                      this.CanvasUtil.updateTextCase(object);
                    }
                    break;
                  }
                  case 'oppScore': {
                    if (jsonData.data.score && jsonData.data.score.away) {
                      object.set('text', jsonData.data.score.away);
                      this.CanvasUtil.updateTextCase(object);
                    }
                    break;
                  }
                  case 'dateLocation': {
                    if (jsonData.data.startDateTime) {
                      const date = new Date(jsonData.data.startDateTime);
                      const dd = String(date.getDate()).padStart(2, '0');
                      const mm = String(date.getMonth() + 1);
                      const yyyy = date.getFullYear();
                      object.set('text', `${mm}/${dd}/${yyyy}`);
                      this.CanvasUtil.updateTextCase(object);
                    }
                    break;
                  }
                  case 'userLogo': {
                    if (jsonData.data.teams.homeTeam && jsonData.data.teams.homeTeam.logo) {
                      object.setMedia({
                        src: `${process.env.REACT_APP_SS_API_ENDPOINT.replace('api.php?q=','')}presto/tokens/index.php?url=${jsonData.data.teams.homeTeam.logo}`,
                      }, false, true);
                    }
                    break;
                  }
                  case 'oppLogo': {
                    if (jsonData.data.teams.awayTeam && jsonData.data.teams.awayTeam.logo) {
                      object.setMedia({
                        src: `${process.env.REACT_APP_SS_API_ENDPOINT.replace('api.php?q=','')}presto/tokens/index.php?url=${jsonData.data.teams.awayTeam.logo}`,
                      }, false, true);
                    }
                    break;
                  }
                  default: {
                    break;
                  }
                }
              });
            }
            this.CanvasUtil.canvas.requestRenderAll();
            this.forceUpdate();
          })
          .catch((error) => {
            console.error('error', error);
          });

        if (client.disableAutofill) {

        }
      }
    }

    onSwapTemplate = (json) => {
      const slideshow = this.getRouteSlideshow();
      const isQuickCreate = this.getQuickCreate();

      if ((slideshow || isQuickCreate) && json.duration !== undefined) {
        const slide = this.state.json.data[this.state.slide];
        slide.duration = +json.duration;
        this.forceUpdate();
      }
    }

    toggleAdvanced = (value) => {
      if (value) {
        this.CanvasUtil.fixCanvasOrder();
      }

      this.setState({ advanced: value });
    }

    applyLockPermissions = (permissions) => {
      const locked = permissions.get('editor:lock_movement');
      const { canvas } = this.CanvasUtil;

      canvas.getObjects().forEach((object) => {
        let lock_move = locked;
        let lock_rotate = locked;
        let lock_scale = locked;

        switch (object.type) {
          case 'i-text': {
            return;
          }
          case 'image': {
            if (!object.parent) {
              break;
            }

            switch (object.parent.type) {
              case 'logoBox': {
                lock_rotate = false;
                lock_scale = false;
                break;
              }
              case 'backgroundBox':
              case 'cutoutBox': {
                lock_move = false;
                lock_rotate = false;
                lock_scale = false;
                break;
              }
              default: {
                break;
              }
            }

            break;
          }
          default: {
            break;
          }
        }

        object.lockMovementX = lock_move;
        object.lockMovementY = lock_move;
        object.lockRotation = lock_rotate;
        object.lockScalingX = lock_scale;
        object.lockScalingY = lock_scale;
      });
    }

    loadSlide = (slide, index, reason = '') => {
      const { canvas } = this.CanvasUtil;
      const callback = () => {
        this.CanvasUtil.addEditorObjects();
        this.CanvasUtil.clearStateHistory();
        canvas.discardActiveObject();
        this.lockAnimatedObjects();
        this.CanvasUtil.setDirtyEnabled(true);
        this.setState({ slide: index });

        const { step } = this.state;

        switch (reason) {
          case 'duplicate':
          case 'add': {
            if (step !== 'slideshow' || step !== 'quickcreate') {
              this.setStep(this)('slideshow', false)();
            }

            break;
          }
          case 'remove':
          case 'select': {
            if (step !== 'background') {
              this.setStep(this)('background', false)();
            }

            break;
          }
          default: {
            break;
          }
        }
      };

      if (slide.canvas.objects) {
        this.CanvasUtil.setDirtyEnabled(false);
        this.CanvasUtil.loadFromJSON(slide.canvas, callback);
      } else {
        canvas.clear();
        callback();
      }
    }

    saveSlide = async (slide = null, index = null, reason = null) => {
      this.CanvasUtil.canvas.discardActiveObject();

      if (!this.CanvasUtil.isCanvasDirty() && reason !== 'preview') {
        return;
      }
      if (!slide && index) {
        slide = this.state.json.data[index];
      } else if (!slide) {
        slide = this.state.json.data[this.state.slide];
      }
      const json = await this.CanvasUtil.serializeToJSON();
      const png = this.CanvasUtil.toDataURL();
      const jpg = this.CanvasUtil.toDataURL({ format: 'jpeg' });

      slide.canvas = json;
      slide.preview = { png, jpg };
      this.forceUpdate();
    }

    expandDrawer = () => {
      this.setState({ drawer_full: true });
    }

    retractDrawer = () => {
      this.setState({ drawer_full: false });
    }

    mediaSend = (e) => {
      if (this.state.permissions !== null) {
        this.applyLockPermissions(this.state.permissions);
      }

      if (e.resized_flag) {
        this.setState({ snackbar_text: 'Image is larger than 4000px; it may distort or display poorly.', snackbar_open: true });
      }
    }

    forceStepTour = (step) => {
      const force_step = STEPS[step].forceStep;
      if (force_step) {
        if (force_step === 'background') {
          this.setState({ force_step, tourload: true }, () => {
            // this.setStep(this)(force_step, false, true)();
          });
        }
      }
    }

    setTourStep = (step) => {
      if (!this.CanvasUtil || !this.CanvasUtil.canvas) {
        return;
      }
      const force_step = STEPS[step].forceStep;
      if (force_step) {
        if (force_step === 'background') {
          this.setState({ force_step }, () => {
            this.setStep(this)(force_step, false, true)();
          });
        } else {
          this.setState({ force_step }, () => {
            this.setStep(this)(force_step, false, true)();
          });
        }
      } else {
        this.setState({ force_step: null });
      }

      const path = this.props.history.location.pathname;

      if (STEPS[step].goTo && STEPS[step].goTo !== path) {
        this.props.history.push(STEPS[step].goTo);
      }
    }

    render() {
      const { classes, draft, data } = this.props;
      const {
        open, json, engineLoaded, drawer_full, tourload,
      } = this.state;
      let Sidebar = null;

      if (Dict[this.state.step]) {
        Sidebar = Dict[this.state.step].component;
      }

      if (!localStorage.getItem('isImpersonator')) {
        window.Intercom('update', {
          name: `${data.me.firstname} ${data.me.lastname}`,
          email: data.me.email,
          user_id: data.me.id,
          company: {
            id: data.me.client.id,
            name: data.me.client.name,
          },
        });
      }

      /* global Raven */
      // eslint-disable-next-line
      Raven.setUserContext({
        id: data.me.id,
        email: data.me.email,
      });

      const template = this.getTemplate();
      const method = this.getRouteMethod();
      const mobile = (this.props.width === 'xs');
      const slideshow = this.getRouteSlideshow();
      const quickCreate = this.getQuickCreate();
      const cozy = (calcViewWidth() >= 960 && calcViewHeight() >= 640);
      const fonts = (data.myFonts || []);

      let expandDrawer = (
        <Paper className={classes.expand_box} onClick={this.expandDrawer}>
          <ChevronUp />
        </Paper>
      );

      if (drawer_full) {
        expandDrawer = (
          <Paper className={classes.expand_box_full} onClick={this.retractDrawer}>
            <ChevronDown />
          </Paper>
        );
      }

      return (
        <UserRoleProvider>
          <PageTitle title="Editor" />
          <UserRoleConsumer>
            {(permissions) => (
              <>
                <FullscreenLoader
                  complete={engineLoaded && permissions !== null}
                />
                <Once
                  if={engineLoaded && permissions !== null}
                  do={() => {
                    this.CanvasUtil.permissions = permissions;
                    this.applyLockPermissions(permissions);
                    this.setState({ permissions });
                  }}
                />
              </>
            )}
          </UserRoleConsumer>
          <CreateSearchContext.Consumer>
            {(search_parameters) => (
              <EditorNav
                editor={this}
                search_parameters={search_parameters}
                CanvasUtil={this.CanvasUtil}
                width={this.state.width}
                height={this.state.height}
                setDimension={this.setDimension}
                advanced={this.state.advanced}
                toggleAdvanced={this.toggleAdvanced}
                templateId={!draft && template ? template.id : null}
                draftId={draft ? draft.id : null}
                template={template}
                method={method}
                client={data.me ? data.me.client : null}
                clientId={data.me ? data.me.client.id : null}
                onSlideSave={this.saveSlide}
                fonts={fonts}
                quickcreate={quickCreate}
                slideshow={slideshow}
                json={json}
                style={{ position: 'fixed', top: 0, left: 0 }}
              />
            )}
          </CreateSearchContext.Consumer>
          <DrawerContainer
            advanced={this.state.advanced}
            permissions={this.state.permissions}
            slideshow={slideshow}
            currentStep={this.state.step}
            open={this.state.open}
            setStep={this.setStep(this)}
            CanvasUtil={this.CanvasUtil}
            template={template}
            isMobileOnly={mobile}
          />
          {
              ((slideshow || quickCreate) && json)
              && (
              <TimeLine
                open={open && !mobile}
                json={json}
                variant={cozy ? 'cozy' : 'compact'}
                onSlideLoad={this.loadSlide}
                onSlideSave={this.saveSlide}
                quickcreate={quickCreate}
                client={data.me ? data.me.client : null}
                canvas_util={this.CanvasUtil}
                canvas={this.CanvasUtil && this.CanvasUtil.canvas}
                fonts={fonts}
              />
              )
            }
          <Canvas
            open={open && !mobile}
            variant={(slideshow || quickCreate || isMobileOnly) ? (cozy ? 'cozy' : 'compact') : 'standard'}
            dragging={this.state.dragging}
            CanvasUtil={this.CanvasUtil}
          />
          {
              isMobileOnly
              && (
              <SwipeableDrawer
                disableDiscovery
                variant="persistent"
                anchor="bottom"
                open={this.state.open}
                onClose={() => this.setState({ open: false, drawer_full: false })}
                onOpen={() => this.setState({ drawer_full: true })}
                classes={{
                  paper: classNames(
                    classes.drawer_paper_mobile,
                    { [classes.drawer_paper_mobile_open]: this.state.open },
                    { [classes.drawer_paper_mobile_full]: this.state.drawer_full },
                  ),
                }}
              >
                {expandDrawer}
                {
                  <div className={classes.drawer_affordance_mobile}>
                    <IconButton onClick={() => this.setStep(this)(this.state.step)()}>
                      <Close />
                    </IconButton>
                  </div>
                }
                {
                  <div className={classes.expand_spacer}>&nbsp;</div>
                }
                {
                  Sidebar
                  && (
                  <Sidebar
                    editor={this}
                    sports={this.sports}
                    active={this.state.active}
                    activeGroup={this.state.activeGroup}
                    objects={
                        (this.CanvasUtil && this.CanvasUtil.canvas)
                          ? this.CanvasUtil.canvas.getObjects() : []
                      }
                    CanvasUtil={this.CanvasUtil}
                    sidebar_root={this.sidebar_root}
                    fabric={this.CanvasUtil ? this.CanvasUtil.fabric : null}
                    canvas={this.CanvasUtil ? this.CanvasUtil.canvas : null}
                    client={data.me ? data.me.client : null}
                    onDragStart={this.dragStart(this)}
                    fonts={fonts}
                    advanced={this.state.advanced}
                    template={template}
                    slideshow={slideshow || quickCreate}
                  />
                  )
                }

              </SwipeableDrawer>
              )
            }
          {
              (!isMobileOnly)
              && (
              <SwipeableDrawer
                disableDiscovery
                variant={isMobileOnly ? 'temporary' : 'persistent'}
                anchor={isMobileOnly ? 'bottom' : 'left'}
                open={(isMobileOnly ? this.state.drawer_full : open)}
                onClose={() => this.setState({ open: false, drawer_full: false })}
                onOpen={() => this.setState({ drawer_full: true })}
                classes={{ paper: classes.drawerPaper }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {
                  <div className={classes.drawer_affordance}>
                    <IconButton onClick={() => this.setStep(this)(this.state.step)()}>
                      <Close />
                    </IconButton>
                  </div>
                }
                {
                  (isMobileOnly)
                  && (
                    <>
                      <div className={classes.drawer_handle} />
                      <ButtonBase
                        disableRipple
                        className={classes.drawer_handle_button}
                        onClick={() => this.setState({ drawer_full: !this.state.drawer_full })}
                      />
                    </>
                  )
                }
                {
                  Sidebar
                  && (
                  <Sidebar
                    key={`${this.state.active}.${this.state.active ? this.state.active.name : null}`}
                    editor={this}
                    sports={this.sports}
                    active={this.state.active}
                    activeGroup={this.state.activeGroup}
                    objects={
                        (this.CanvasUtil && this.CanvasUtil.canvas)
                          ? this.CanvasUtil.canvas.getObjects() : []
                      }
                    CanvasUtil={this.CanvasUtil}
                    fabric={this.CanvasUtil ? this.CanvasUtil.fabric : null}
                    canvas={this.CanvasUtil ? this.CanvasUtil.canvas : null}
                    client={data.me ? data.me.client : null}
                    onDragStart={this.dragStart(this)}
                    fonts={fonts}
                    advanced={this.state.advanced}
                    template={template}
                    slideshow={slideshow || quickCreate}
                  />
                  )
                }
              </SwipeableDrawer>
              )
            }
          <Snackbar
            open={Boolean(this.state.snackbar_open)}
            onClose={(_, reason) => {
              if (reason !== 'clickaway') {
                this.setState({ snackbar_open: false });
              }
            }}
            autoHideDuration={null}
            anchorOrigin={{
              horizontal: 'left',
              vertical: 'bottom',
            }}
          >
            <MuiAlert
              severity="warning"
              variant="filled"
              elevation={6}
              classes={{
                filledWarning: this.props.classes.alert,
              }}
              action={(
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={() => this.setState({ snackbar_open: false })}
                >
                  <Close />
                </IconButton>
                  )}
            >
                Image is larger than 4000px; it may distort or display poorly.
            </MuiAlert>
          </Snackbar>
          <div
            style={{
              position: 'absolute',
              top: 64,
              left: 0,
              right: 0,
              bottom: 0,
              pointerEvents: 'none',
            }}
            data-tour="editor"
          />
          <VirtualTourContext.Consumer>
            {(context) => {
              context.set_step = (step) => {
                context.tour_step = step;
                this.setTourStep(step);
              };

              if (STEPS[context.tour_step].condition && engineLoaded) {
                const e = {
                  context,
                  canvas: this.CanvasUtil.canvas,
                  canvas_util: this.CanvasUtil,
                };

                if (STEPS[context.tour_step].condition(e)) {
                  context.set_step(context.tour_step + 1);
                }
              }
              if (engineLoaded && tourload === false) {
                this.forceStepTour(context.tour_step);
              }
              return (
                <Tour
                  isOpen={context.tour_open}
                  current={context.tour_step}
                  steps={STEPS}
                  startAt={7}
                  onStepChange={(step) => {
                    context.set_step(step);
                  }}
                  showNumber={false}
                  showButtons={false}
                  rounded={12}
                  showNavigationNumber={false}
                  showNavigation={false}
                  className={classes.tour}
                  onRequestClose={() => { context.close_tour(); }}
                  disableDotsNavigation
                />
              );

              // return null;

              /* return (
                 <Tour
                   current={context.tour_step}
                   onStepChange={(step) => {
                     context.set_step(step);
                   }}
                   steps={STEPS}
                   isOpen={context.tour_open}
                   showNumber={false}
                   showButtons={false}
                   rounded={12}
                   update={String(+new Date().getSeconds())}
                   updateDelay={1000}
                   showNavigationNumber={false}
                   showNavigation={false}
                   onRequestClose={() => { context.close_tour(); }}
                   disableDotsNavigation
                 />
               ); */
            }}
          </VirtualTourContext.Consumer>
        </UserRoleProvider>
      );
    }
  }

  const StyledEditor = withRouter(withWidth()(withStyles(STYLES, { withTheme: true })(Editor)));

  export default (props) => (
    <Query
      query={GET_CLIENT}
    >
      {({ data }) => {
        const userTheme = {
          typography: {
            fontFamily: 'Ubuntu,Roboto,sans-serif',
          },
          breakpoints: {
            values: {
              xs: 0,
              sm: 960,
              md: 1280,
              lg: 1440,
              xl: 1920,
            },
          },
        };
        let component = <FullscreenLoader />;
        if (data.me) {
          userTheme.palette = {
            primary: {
              main: data.me.client.color1,
            },
            secondary: {
              main: data.me.client.color2,
            },
          };
          component = <StyledEditor data={data} {...props} />;
        }
        const theme = createMuiTheme(userTheme);
        return (
          <MuiThemeProvider theme={theme}>
            {component}
          </MuiThemeProvider>
        );
      }}
    </Query>
  );
