
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Fab,
  withStyles,
} from '@material-ui/core';

import {
  Plus as PlusIcon,
} from 'mdi-material-ui';

import { compose, graphql } from "react-apollo";
import { Link, withRouter } from 'react-router-dom';
import gql from "graphql-tag";
import { VirtualTourContext } from '../app/tour';

// -------------------------------------------------------------------------- //

const ROLES_QUERY = gql`
  query {
    me {
      id
      role {
        id
        name
        restrictCategoryFlag
        categories {
          id
          name
        }
        allowCustomFlag
        allowCustomSaveFlag
        allowCustomFolderSaveFlag
        restrictCustomFlag
        customFolders {
          id
          name
        }
      }
    }
  }
`;

const STYLES = (theme) => ({
  root: {
    minWidth: theme.spacing(24),
    marginBottom: theme.spacing(-3),
    position: 'relative',
    zIndex: 500,
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      bottom: theme.spacing(12),
      left: '50%',
      transform: 'translate(-50%, 0)',
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
});

const ExtendedFab = (props) => {
  return (
    <VirtualTourContext.Consumer>
    {(context) => {
      const { classes, onClick, to } = props;
      const { me } = props.rolesQuery;
      const extraProps = {};
      let restrictCategoryFlag = false;
      let allowCustomFlag = true;
      const allowedCategories = [];

      if (context.tour_open) {
        extraProps.onClick = () => {
          context.set_step(4);
        };
      } else if (to) {
        extraProps.component = Link;
        extraProps.to = to;
        if (me && me.role && me.role.categories) {
          me.role.categories
            .forEach((cat) => {
              allowedCategories.push(cat.id);
            });
          restrictCategoryFlag = me.role.restrictCategoryFlag;
          allowCustomFlag = me.role.allowCustomFlag;
        }
        if (restrictCategoryFlag && allowedCategories.length === 0) {
          extraProps.to = '/create/custom';
          if (allowCustomFlag === false) {
            extraProps.to = '/create/premium';
          }
        }
      } else if (onClick) {
        extraProps.onClick = onClick;
      }
      return (
        <Fab
          id="create"
          data-tour="step-4"
          variant="extended"
          color="secondary"
          className={classes.root}
          {...extraProps}
        >
          <PlusIcon className={classes.icon}/>
          Create a Post
        </Fab>
      );
    }}
    </VirtualTourContext.Consumer>
  );
};

// -------------------------------------------------------------------------- //

export default compose(
  graphql(ROLES_QUERY, { name: 'rolesQuery' }),
  withRouter,
  withStyles(STYLES),
)(ExtendedFab);

// -------------------------------------------------------------------------- //
