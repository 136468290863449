
// -------------------------------------------------------------------------- //

import * as React from 'react';

import {
  AppBar,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';

import FontEditor from '../../components/fonts/FontEditor';
import GoogleFonts from '../../components/fonts/GoogleFonts.js';

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  app_bar: {
    flex: 0,
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  root: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(3),
  }
});

// -------------------------------------------------------------------------- //

const Fonts = (props) => {
  const { classes } = props;

  return (
    <React.Fragment>
      <AppBar color="primary" elevation={0} className={classes.app_bar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Fonts
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.root}>
        <FontEditor/>
        <GoogleFonts/>
      </div>
    </React.Fragment>
  );
};

// -------------------------------------------------------------------------- //

export default withStyles(STYLES)(Fonts);

// -------------------------------------------------------------------------- //
