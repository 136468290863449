import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import { SketchPicker } from 'react-color';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import parseColor from 'parse-color';

const BoxParent2 = ({ prop , value, applyAllFunct }) => {
  return <span style={{display : 'inline-block', textTransform: 'capitalize'}}>
     <Typography variant="subtitle1" gutterBottom>
        <Button
        onClick={applyAllFunct(prop,value)}
        color="primary"
        >{'Apply All'}</Button>

          {prop}
        </Typography>
</span>
}

const WrapperXYZ = props =><tr key={props.prop + "_tr"}>
          <td key={props.prop + "_td1"}><BoxParent2 {...props}/></td>
          <td  key={props.prop + "_td2"} style={{ width : 30}}/>
          <td key={props.prop + "_td3"}>{props.children}</td>
    </tr>

const ColorBox = (props) => {
  const parsed_color = parseColor(
    (typeof props.value === "object" ? props.value.color : props.value)
  );

  if (!parsed_color.rgb) {
    return null;
  }

  const css_rgba = `rgba(${parsed_color.rgba[0]}, ${parsed_color.rgba[1]}, ${parsed_color.rgba[2]}, ${parsed_color.rgba[3]})`;

  if (props.selectedIndex !== props.myIndex) {
    return (
      <div
        onClick={props.colorClickFunct}
        style={{
          width: 25,
          height: 25,
          border: 'solid 1px',
          borderColor: 'black',
          background: `linear-gradient(${css_rgba}, ${css_rgba}), url('https://ss3-assets.s3.us-east-2.amazonaws.com/grid.png')`,
        }}
      />
    );
  }

  return (
    <span>
      <Button fullWidth color="primary" onClick={props.colorCloseFunct} >Close</Button>
      <SketchPicker color={css_rgba} onChangeComplete={props.changeColor}/>
      <div style={{ padding : 15 }}/>
    </span>
  );
};

const NumberBox = (props) => {
  return (
    <TextField
      type="number"
      label={props.array[0].prop}
      onChange={props.onChange}
      value={Number(props.value)}
    />
  );
};


const ShadowBox = (props) => {

  const _myColor = typeof props.value === "object" ? props.value.color : props.value;
  return props.selectedIndex === props.myIndex ? <span><Button fullWidth color="primary" onClick={props.colorCloseFunct} >{'Close'}</Button>
                                                        <SketchPicker color={_myColor} onChangeComplete={props.shadowChangeColor(props.array[0].value)}/>
                                                        <br/><TextField label={'blur'} type="number" onChange={props.shadowChangeNumber('blur',props.array[0].value)} value={props.value.blur}/>
                                                        <br/><TextField label={'offsetX'} type="number" onChange={props.shadowChangeNumber('offsetX',props.array[0].value)} value={props.value.offsetX}/>
                                                        <br/><TextField label={'offsetY'} type="number" onChange={props.shadowChangeNumber('offsetY',props.array[0].value)} value={props.value.offsetY}/>
                                                       <div style={{ padding : 15}}></div></span> : <div onClick={props.colorClickFunct} style={{backgroundColor : _myColor, width : 25, height : 25}}/>;
}
const BooleanBox = props => <span>
                              <Checkbox
                                checked={props.value}
                                onChange={props.booleanChange}
                                value="checkedB"
                                color="primary"
                                /> {props.value ? "Yes" : "No"}
                          </span>

const OverrideBox = props => {
            let value = props.value ? props.value : "NONE";
            return <Select
              value={value}
              onChange={props.selectChange}
            >
              <MenuItem value="NONE">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"PRIMARY"}>PRIMARY</MenuItem>
              <MenuItem value={"SECONDARY"}>SECONDARY</MenuItem>
              <MenuItem value={"TERTIARY"}>TERTIARY</MenuItem>
            </Select>
}
class EditKeyFrameProps extends Component {
  state = {
       keyFrameIndex : -100,
       currentAnimation : {},
       propsArray : [],
       propsArrayIndex : -100,
       incrementBy : .1,
       type : ""
  }
  componentDidMount(){
    const { keyFrameIndex , allProps, currentAnimation } = this.props;
    let propsArray = this.buildPropsArray(allProps,currentAnimation);
    this.setState( { keyFrameIndex , currentAnimation, propsArray });
  }

  componentDidUpdate(){
    const { keyFrameIndex , allProps, currentAnimation,activeObject } = this.props;
    if( keyFrameIndex !== this.state.keyFrameIndex || this.state.type !== activeObject.type){
      let propsArray = this.buildPropsArray(allProps,currentAnimation);
      if(!activeObject.animation){
        this.setState( { keyFrameIndex : -100, currentAnimation, propsArray,type :  activeObject.type});
        return;
      }
      this.setState( { keyFrameIndex, currentAnimation, propsArray,type :  activeObject.type});
    }
  }


  updatePropsArrayIndex = _propsArrayIndex => () => {
    const propsArrayIndex = _propsArrayIndex === this.state.propsArrayIndex ? -100 : _propsArrayIndex;
    this.setState({ propsArrayIndex });
  }

  updateCheckIndex = _index => () => {
    let propsArray = this.state.propsArray;
    this.setState({ propsArray },this.sendKeyFrameToDopeSheet(propsArray));
  }

  updatePropsArray2 = (array) => {
    let mapper = array.map((object, index) => {
      const PROPSZ =  {
        "array"       : [this.state.propsArray[index]],
        "checkFunct"  : this.updateCheckIndex,
        "buttonFunct" : this.updatePropsArrayIndex,
        "value" : this.state.propsArray[index].value,
        "onChange" : this.textBoxOnChange(index),
        "changeColor" : this.handleColorChange(index),
        "booleanChange" : this.booleanChange(this.state.propsArray,index),
        "selectChange" : this.selectChange(this.state.propsArray,index),
        "selectedIndex" : this.state.propsArrayIndex,
        "myIndex" : index,
        "colorClickFunct" : () => this.setState({ propsArrayIndex : index }),
        "colorCloseFunct" : () => this.setState({ propsArrayIndex : -100 }),
        "shadowChangeColor" : this.handleColorChangeShadow(index),
        "shadowChangeNumber" : this.textBoxOnChangeShadow(index)
      };

      const CHILD =  this.getBoxEditor(PROPSZ.array[0].box_type)(PROPSZ);
      return <WrapperXYZ  key={index} applyAllFunct={this.applyAll} {...object}> {CHILD}</WrapperXYZ>}
    );

    return <table><tbody>{mapper}</tbody></table>
  }

  getBoxType = (key) => {
      // H = horizontal, V = vertical, C = color, B = boolean
      let boxType = "H";
      switch(key){
          case "top":
          case "left":
          case "height":
          case "scaleY":
          case "zoomY":
          case "angle":
            boxType = "V";
          break;

          case "fill":
          case "stroke":
            boxType = "C";
          break;

          case "shadow":
            boxType = "S";
          break;

          case "flipX":
          case "flipY":
            boxType = "B";
          break;

          case "fillOverride":
          case "strokeOverride":
            boxType = "O";
          break;

          default:
            boxType = "H";
          break;
      }

      return boxType;
  }

  getBoxEditor = boxType => props => {
      let returnType = null;
      switch(boxType){
          case "C" :
            returnType = <ColorBox {...props} />;
          break;
          case "B" :
            returnType = <BooleanBox {...props} />;
          break;
          case "V" :
          case "H" :
            returnType = <NumberBox {...props} />;
          break;
          case "O" :
            returnType = <OverrideBox  {...props} />;
          break;
          case "S" :
            returnType = <ShadowBox  {...props} />;
          break;
          default:
            returnType = <NumberBox  {...props} />;
          break;
      }
    return returnType;
  }

  buildPropsArray = ( _allAnimations, _animationObj ) => {
    if (!_animationObj) {
      this.props.resetUpdateIndex();
      return;
    }

    let returnArray = [];
    const { activeObject, keyFrameIndex } = this.props;
    let key_frames = [];

    if (activeObject && activeObject.animation && activeObject.animation.animations) {
      key_frames = activeObject.animation.animations;
    }

    for (let i = 0; i < _allAnimations.length; ++i) {
      let key = _allAnimations[i];
      let boxType = this.getBoxType(key);

      if (key === 'shadow') {
        if (key in _animationObj) {
          if (typeof _animationObj[key] === "object") {
            returnArray.push({ prop : key , value : _animationObj[key], keep : true, box_type : boxType, index : i });
          } else {
            let obj = { color : _animationObj[key], blur : 0, offsetX : 0, offsetY : 0};
            returnArray.push({ prop : key , value : obj, keep : true, box_type : boxType, index : i });
          }
        } else {
          let obj = { color : '#000000', blur : 0, offsetX : 0, offsetY : 0};
          returnArray.push({ prop : key , value : obj, keep : true, box_type : boxType, index : i });
        }

        continue;
      }

      let keyframe_value = key_frames[keyFrameIndex][key];

      if (keyframe_value === undefined) {
        for (let j = (keyFrameIndex - 1); j >= 0; --j) {
          keyframe_value = key_frames[j][key];

          if (keyframe_value !== undefined) {
            break;
          }
        }
      }

      if (key in _animationObj) {
        returnArray.push({ prop : key , value : keyframe_value, keep : true, box_type : boxType, index : i });
      } else {
        let _theVal = keyframe_value;

        if (boxType === 'C') {
          _theVal = '#000000'
        }

        returnArray.push({ prop : key , value : _theVal, keep : false, box_type : boxType ,index : i });
      }
    }

    return returnArray;
  }

 sendKeyFrameToDopeSheet = (propsArray) => {
   let keyFrameObject  = {};
   for( let x = 0; x < propsArray.length; x++){
       let current = propsArray[x];
       keyFrameObject[current.prop] = current.value;
   }
   this.props.updateKeyFrameViaProps(this.state.keyFrameIndex,keyFrameObject)
 }

 handleColorChange = (index) => (color) => {
  let rgb="";
  const _color = color.rgb;
  // get rgba
  if( "a" in _color ){
     let r = _color.r;
     let g = _color.g;
     let b = _color.b;
     let a = _color.a;
     rgb=`rgba(${r},${g},${b},${a})`;
  }else{
    let r = _color.r;
    let g = _color.g;
    let b = _color.b;
    rgb=`rgb(${r},${g},${b})`;
  }

  let value = rgb;
  let propsArray = this.state.propsArray;
  let prop = propsArray[index].prop;

  propsArray[index].value = value;
  this.setState({propsArray},this.sendKeyFrameToDopeSheet(propsArray));
  this.props.changeActiveProp(prop,value);
 }


 handleColorChangeShadow = (index) => (shadowObject) => (color) => {
  let rgb="";
  const _color = color.rgb;
  // get rgba
  if( "a" in _color ){
     let r = _color.r;
     let g = _color.g;
     let b = _color.b;
     let a = _color.a;
     rgb=`rgba(${r},${g},${b},${a})`;
  }else{
    let r = _color.r;
    let g = _color.g;
    let b = _color.b;
    rgb=`rgb(${r},${g},${b})`;
  }

  let value = { color : rgb, blur :shadowObject.blur, offsetX : shadowObject.offsetX, offsetY : shadowObject.offsetY  };
  let propsArray = this.state.propsArray;
  let prop = propsArray[index].prop;
  propsArray[index].value = value;
  this.setState({propsArray},this.sendKeyFrameToDopeSheet(propsArray));
  this.props.changeActiveProp(prop,value);
 }

 booleanChange = (propsArray,index) => () =>{
   propsArray[index].value = !propsArray[index].value;
   this.setState({propsArray},this.sendKeyFrameToDopeSheet(propsArray));
   this.props.changeActiveProp(propsArray[index].prop,propsArray[index].value);
 }

 textBoxOnChange = index => (e) => {
   let value = e.target.value;
   if(!Number(value) && value !== '0'){
     return;
   }
   value = value === '0' ? 0 : parseFloat(value);
   let propsArray = this.state.propsArray;
   propsArray[index].value = value;
   this.setState({propsArray},this.sendKeyFrameToDopeSheet(propsArray));
   this.props.changeActiveProp(propsArray[index].prop,value);
 }

 textBoxOnChangeShadow = index => (shadowKey, shadowObject) => (e) => {
   let value = e.target.value;
   let _shadow = {...shadowObject};
   if(!Number(value) && value !== '0'){
     return;
   }

   value = value === '0' ? 0 : parseFloat(value);
   _shadow[shadowKey] = value;
   let propsArray = this.state.propsArray;
   propsArray[index].value = _shadow;
   this.setState({propsArray},this.sendKeyFrameToDopeSheet(propsArray));
   this.props.changeActiveProp(propsArray[index].prop,_shadow);
 }

 selectChange =  (propsArray,index) => (event) => {
       propsArray[index].value = event.target.value;
       this.setState({propsArray},this.sendKeyFrameToDopeSheet(propsArray));
 }

 applyAll = (prop,val) => () =>  {
   const { activeObject } = this.props;
   if( !('animation' in  activeObject)){return;}
   let animations = activeObject.animation.animations
   for( let i = 0; i < animations.length; i++ ){
       let curAnimation = animations[i];
       if( prop in  curAnimation){
         activeObject.animation.animations[i][prop] = val;
       }
   }

 }
  render() {

      const DAN = this.updatePropsArray2(this.state.propsArray);
    return (
      <React.Fragment>
         <div style={{ width : '100%', height : 400, overflow : 'auto'}}>{DAN}</div>
      </React.Fragment>
    );
  }
}

export default EditKeyFrameProps;
