
// -------------------------------------------------------------------------- //

import { IWidgetFlow } from '../flow';
import { SpacingOption } from '../options/spacing';

// -------------------------------------------------------------------------- //

export class SymmetricalHorizontalFlow extends IWidgetFlow {

  _hspacing = null;
  _vspacing = null;
  _map = [];

  constructor(name) {
    super(name);
    this._hspacing = new SpacingOption('hspacing', 'Horizontal Spacing');
    this._vspacing = new SpacingOption('vspacing', 'Vertical Spacing');
  }

  get options() {
    return [ ...super.options, this._hspacing, this._vspacing ];
  }

  get(key) {
    switch (key) {
      case 'hspacing': {
        return this._hspacing.value;
      }
      case 'vspacing': {
        return this._vspacing.value;
      }
      default: {
        return super.get(key);
      }
    }
  }

  set(key, value) {
    switch (key) {
      case 'hspacing': {
        this._hspacing.value = Number(value);
        break;
      }
      case 'vspacing': {
        this._vspacing.value = Number(value);
        break;
      }
      default: {
        super.set(key, value);
        break;
      }
    }
  }

  reset(widgets, size, area) {
    this._hspacing.min = 0.0;
    this._hspacing.max = 0.0;
    this._hspacing.value = 0.0;
    this._vspacing.min = 0.0;
    this._vspacing.max = 0.0;
    this._vspacing.value = 0.0;
    this._map = [];

    if (widgets.length >= 1) {
      let hspacing_max = 0.0;
      let max_rows = null, max_cols = null;
      let cols = 1;
  
      for (; cols <= widgets.length; ++cols) {
        const rows = Math.ceil(widgets.length / cols);
        const fit_x = (area.width >= size.x * cols);
        const fit_y = (area.height >= size.y * rows);
  
        if (!fit_x && !fit_y) {
          break;
        } else if (!fit_x || !fit_y) {
          continue;
        }

        if (max_rows === null || max_rows < rows) {
          max_rows = rows;
        }
  
        if (max_cols === null || max_cols < cols) {
          max_cols = cols;
        }
  
        let hspacing = 0;

        if (cols > 1) {
          hspacing = Math.floor((area.width - size.x * cols) / (cols - 1));
        }
  
        if (hspacing >= size.x) {
          // continue;
        }
  
        const vspacing = Math.floor((area.height - size.y * rows) / (rows - 1));
        this._map.push({ rows, cols, hspacing, vspacing });
        hspacing_max += hspacing;
      }
  
      if (this._map.length !== 0) {
        this._hspacing.max = hspacing_max;
        this._vspacing.max = this._map[0].vspacing;
    
        this._hspacing.value = Math.max(0.0, (
          (this._hspacing.min + this._hspacing.max) * 0.5
        ));
    
        this._vspacing.value = Math.max(0.0, (
          (this._vspacing.min + this._vspacing.max) * 0.5
        ));
      }
    }

    if (this._map.length === 0) {
      const rows = widgets.length;
      this._map.push({ cols: 1, rows, hspacing: 0.0, vspacing: 0.0 });
    }
  }

  apply(widgets, size, area) {
    let index = 0;
    let hspacing = this._hspacing.value;
    let update = false;

    for (; index < this._map.length; ++index) {
      if (hspacing <= this._map[index].hspacing) {
        break;
      }

      hspacing -= this._map[index].hspacing;
    }

    if (index >= this._map.length) {
      index = (this._map.length - 1);
    }

    if (this._vspacing.max !== this._map[index].vspacing) {
      this._vspacing.max = this._map[index].vspacing;
      update = true;
    }

    const rows = this._map[index].rows;
    const cols = this._map[index].cols;

    const top = (area.center.y -
      (this._vspacing.value * (rows - 1) + size.y * rows) * 0.5
    );

    let widget = 0;
    let row = 0;

    while (widget < widgets.length) {
      const y = (top + (size.y + this._vspacing.value) * row);
      const count = Math.min(Math.floor(widgets.length - cols * row), cols);

      if (count === 0) {
        break;
      }

      let x = (area.center.x - (
        size.x * count + // N widgets
        hspacing * (count - 1) // (N-1) spacings
      ) * 0.5);

      for (let i = 0; i < count; ++i) {
        widgets[widget++].move(x, y);
        x += (size.x + hspacing);
      }

      ++row;
    }

    return update;
  }

};

// -------------------------------------------------------------------------- //

