
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles,
} from '@material-ui/core';

import {compose, graphql} from 'react-apollo';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { GetMediaType, MEDIA_TYPES } from './mediatypes';
import gql from "graphql-tag";

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  list_icon: {
    transition: theme.transitions.create('color'),
  },
  list_icon_active: {
    color: theme.palette.secondary.dark,
  },
  root: {
    position: 'sticky',
    top: 128,
  }
});

// -------------------------------------------------------------------------- //

const GET_USER = gql`
  query {
    me {
      id
      firstname,
      lastname,
      client {
        id
        type
        color2
      }
    }
  }
`;

function Render(props) {
  const { classes, location } = props;
  const current_type = GetMediaType(location);

  return (
    <div className={classes.root}>
      <List className={classes.list}>
      {Object.keys(MEDIA_TYPES).map((key, index) => {
        const media_type = MEDIA_TYPES[key];
        const Icon = media_type.icon;
        const active = (
          (current_type !== null) &&
          (current_type.key === media_type.key)
        );

        return (
          <ListItem
            button dense
            key={index}
            selected={active}
            component={Link}
            to={media_type.url}
            className={classNames(
              classes.list_item,
              { [classes.list_item_active]: active },
            )}
          >
            <ListItemIcon>
              <Icon
                className={classNames(
                  classes.list_icon,
                  { [classes.list_icon_active]: active },
                )}
              />
            </ListItemIcon>
            <ListItemText>
              {media_type.pretty_name}
            </ListItemText>
          </ListItem>
        );
      })}
      </List>
    </div>
  );
};

// -------------------------------------------------------------------------- //

export const MediaNav = compose(
  graphql(GET_USER),
  withRouter,
  withStyles(STYLES),
)(Render);

// -------------------------------------------------------------------------- //
