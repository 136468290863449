
import AttributePath from './AttributePath';
import SCSportImporter from './SCSportImporter';
import { SplitPlayerName } from './Sports';

class SCHockeySportImporter extends SCSportImporter {

  constructor() {
    super('hkgame');
  }

  import(sport, xml, options = {}) {
    if (!super.import(sport, xml)) {
      return false;
    }

    this.importTeam('home', this.ids[0]);
    this.importTeam('visiting', this.ids[1]);

    for (let i = 0; i < 2; ++i) {
      this.importTeamStats(i);
    }

    for (let i = 0; i < 2; ++i) {
      this.importTeamStatsAfter(i);
    }

    const faceoffs_home = this.get('team.home.faceoffs.won');
    const faceoffs_visiting = this.get('team.visiting.faceoffs.won');
    const faceoffs_total = (faceoffs_home + faceoffs_visiting);
    this.set('venue.faceoffs', faceoffs_total);

    for (let p = 0; p < 3; ++p) {
      const prd = (p + 1);
      const period = `period${prd}`;
      const faceoffs_home = this.get(`team.home.faceoffs.won.${period}`);
      const faceoffs_visiting = this.get(`team.visiting.faceoffs.won.${period}`);
      const faceoffs_total = (faceoffs_home + faceoffs_visiting);
      this.set(`venue.faceoffs.${period}`, faceoffs_total);
    }

    if (options.players) {
      for (let i = 0; i < options.players.length; ++i) {
        this.importPlayerStats(options.players[i], i);
      }
    }

    return true;
  }

  importTeam(key, id) {
    const team_id = `team.${key}`;
    const team_path = `/${this.game}/team[@id='${id}']`;

    let team = {
      name: this.get(`${team_id}.name`),
      players: [],
      key: id,
    };

    for (let i = 0;; ++i) {
      const player_path = `${team_path}/player[*][@pos!=''][${i + 1}]`;

      if (!this.test(new AttributePath(player_path, 'name'))) {
        break;
      }

      team.players.push({
        name: SplitPlayerName(this.peek(new AttributePath(player_path, 'name'))),
        key: this.peek(new AttributePath(player_path, 'uni'), { type: 'number' }),
        position: '',
      });
    }
  }

  importTeamStats(t) {
    const team = `team.${(t === 0 ? 'home' : 'visiting')}`;
    const team_path = `/${this.game}/team[@id='${this.ids[t]}']`;
    const linescore = `${team_path}/linescore`;
    const totals = `${team_path}/totals`;

    this.select(`${team}.goals`, new AttributePath(linescore, 'score'));
    this.select(`${team}.shots`, new AttributePath(linescore, 'shots'));
    this.select(`${team}.saves`, new AttributePath(`${totals}/goalie`, 'saves'));
    this.select(`${team}.faceoffs.won`, new AttributePath(`${totals}/misc`, 'facewon'));
    this.select(`${team}.faceoffs.lost`, new AttributePath(`${totals}/misc`, 'facelost'));
    this.select(`${team}.powerplays.goals`, new AttributePath(`${totals}/powerplay`, 'ppg'));
    this.select(`${team}.powerplays.attempts`, new AttributePath(`${totals}/powerplay`, 'ppopp'));
    this.select(`${team}.penalties`, new AttributePath(`${totals}/penalty`, 'count'));
    this.select(`${team}.penalties.minutes`, new AttributePath(`${totals}/penalty`, 'minutes'));

    for (let p = 0; p < 3; ++p) {
      const prd = (p + 1);
      const period = `period${prd}`;
      const lineprd = `${linescore}/lineprd[@prd='${prd}']`;

      this.select(`${team}.goals.${period}`, new AttributePath(lineprd, 'score'));
      this.select(`${team}.shots.${period}`, new AttributePath(lineprd, 'shots'));
      this.select(`${team}.saves.${period}`, new AttributePath(lineprd, 'saves'));
      this.select(`${team}.powerplays.goals.${period}`, new AttributePath(lineprd, 'ppg'));
      this.select(`${team}.powerplays.attempts.${period}`, new AttributePath(lineprd, 'ppatt'));
      this.select(`${team}.faceoffs.won.${period}`, new AttributePath(lineprd, 'fowon'));
      this.select(`${team}.penalties.${period}`, new AttributePath(lineprd, 'pen'));
      this.select(`${team}.penalties.minutes.${period}`, new AttributePath(lineprd, 'pmin'));
    }

    return true;
  }

  importTeamStatsAfter(t) {
    const team = `team.${(t === 0 ? 'home' : 'visiting')}`;
    const opp = `team.${(t === 0 ? 'visiting' : 'home')}`;
    const save_made = this.get(`${team}.saves`);
    const save_attempt = this.get(`${opp}.shots`);
    const save_percent = Math.round(save_made / save_attempt * 100);
    this.set(`${team}.saves.percent`, save_percent);

    const powerplay_goal = this.get(`${team}.powerplays.goals`);
    const powerplay_attempt = this.get(`${team}.powerplays.attempts`);
    const powerplay_percent = Math.round(powerplay_goal / powerplay_attempt * 100);
    this.set(`${team}.powerplays.percent`, powerplay_percent);

    const faceoff_won = this.get(`${team}.faceoffs.won`);
    const faceoff_lost = this.get(`${team}.faceoffs.lost`);
    const faceoff_total = (faceoff_won + faceoff_lost);
    const faceoff_percent = Math.round(faceoff_won / faceoff_total * 100);
    this.set(`${team}.faceoffs.percent`, faceoff_percent);

    for (let p = 0; p < 3; ++p) {
      const prd = (p + 1);
      const period = `period${prd}`;

      const save_made = this.get(`${team}.saves.${period}`);
      const save_attempt = this.get(`${opp}.shots.${period}`);
      const save_percent = Math.round(save_made / save_attempt * 100);
      this.set(`${team}.saves.percent.${period}`, save_percent);

      const powerplay_goal = this.get(`${team}.powerplays.goals.${period}`);
      const powerplay_attempt = this.get(`${team}.powerplays.attempts.${period}`);
      const powerplay_percent = Math.round(powerplay_goal / powerplay_attempt * 100);
      this.set(`${team}.powerplays.percent.${period}`, powerplay_percent);

      const faceoff_won = this.get(`${team}.faceoffs.won.${period}`);
      const faceoff_lost = this.get(`${opp}.faceoffs.won.${period}`);
      const faceoff_total = (faceoff_won + faceoff_lost);
      const faceoff_percent = Math.round(faceoff_won / faceoff_total * 100);
      this.set(`${team}.faceoffs.lost.${period}`, faceoff_lost);
      this.set(`${team}.faceoffs.percent.${period}`, faceoff_percent);
    }

    return true;
  }

  importPlayerStats(player, index) {
    const id = this.ids[player.team];
    const team = this.sport.teams[player.team];
    const uni = team.players[player.player].key;
    const path = `/${this.game}/team[@id='${id}']/player[@uni='${uni}']`;

    if (!this.test(new AttributePath(path, 'name'))) {
      return false;
    }

    const player_name = SplitPlayerName(new AttributePath(path, 'name'));
    this.set('player.name.long', player_name.long, index);
    this.set('player.name.first', player_name.first, index);
    this.set('player.name.last', player_name.last, index);
    this.select('player.number', new AttributePath(path, 'uni'), { index });
  }

}

export default SCHockeySportImporter;
