
/* eslint react/jsx-no-duplicate-props: "off" */

// -------------------------------------------------------------------------- //

import React from 'react';

import { Switch, Route } from 'react-router';

import { MEDIA_TYPES } from './mediatypes';

// -------------------------------------------------------------------------- //

function Render(props) {
  return (
    <Switch key="media" {...props}>
    {Object.keys(MEDIA_TYPES).map((key, index) => (
      <Route
        key={index} path={MEDIA_TYPES[key].path}
        component={MEDIA_TYPES[key].component}
      />
    ))}
    </Switch>
  );
}

// -------------------------------------------------------------------------- //

export const Media = Render;

// -------------------------------------------------------------------------- //
