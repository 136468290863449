import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import RenderPlaceholder from './RenderPlaceholder';

const styles = theme => ({
  mediaPreview: {
    maxWidth: '100%',
    maxHeight: '100%',
    boxShadow: theme.shadows[4],
    borderRadius: 2,
  },
  preview: {
    height: '50vh',
    width: '100%',
    marginTop: theme.spacing(2),
  },
});

const isEquivalent = (a, b) => {
  // Create arrays of property names
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length !== bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i += 1) {
    const propName = aProps[i];

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
};

class RenderPreview extends Component {
  componentDidMount() {
    const { isVideo, hasEffect } = this.props;
    if (isVideo || hasEffect) {
      this.renderVideo();
    }
  }

  componentDidUpdate(prevProps) {
    // we only need to rerender if certain things have changed.
    const prevImportantProps = {
      isVideo: prevProps.isVideo,
      duration: prevProps.duration,
      forceVideo: prevProps.forceVideo,
      backgroundEffect: prevProps.backgroundEffect,
      overlayEffect: prevProps.overlayEffect,
    };
    const currentImportantProps = {
      isVideo: this.props.isVideo,
      duration: this.props.duration,
      forceVideo: this.props.forceVideo,
      backgroundEffect: this.props.backgroundEffect,
      overlayEffect: this.props.overlayEffect,
    };
    if (!isEquivalent(prevImportantProps, currentImportantProps)) {
      if (this.props.isVideo || this.props.hasEffect || this.props.forceVideo) {
        this.renderVideo();
      }
    }
  }

  checkVideo = (jobId) => {
    fetch(`https://ss3-video.s3.us-east-2.amazonaws.com/output/${jobId}.mp4`, {
      method: 'HEAD',
    })
    .then(response => {
      if (response.status === 200) {
        this.props.setShareState(
          'videoURL',
          `https://ss3-video.s3.us-east-2.amazonaws.com/output/${jobId}.mp4`,
        );
        this.props.setStepState({
          rendering: false,
          jobId,
        });
      } else if (response.status >= 400) {
        this.props.setStepState({
          rendering: false,
          renderFailed: true,
        });
      }
    })
    .catch(() => {
      this.props.setStepState({
        rendering: false,
        renderFailed: true,
      });
    });
  }

  hasVideo = () => {
    const { CanvasUtil } = this.props;
    const { canvas } = CanvasUtil;
    let objects = canvas.getObjects('backgroundBox');
    for (let i = 0; i < objects.length; ++i) {
      const object = objects[i];
      if (object.media && object.media.type === 'video') {
        return true;
      }
    }

    return false;
  }

  async renderVideo() {
    const self = this;
    const {
      backgroundEffect,
      overlayEffect,
      CanvasUtil,
      duration,
      forceVideo,
      hasEffect,
    } = this.props;
    // initiate the Video Renderer
    const jobDetails = await CanvasUtil.CanvasVideo.createRenderJob(
      backgroundEffect,
      overlayEffect,
      duration,
      forceVideo || hasEffect || this.hasVideo(),
    );
    this.jobId = jobDetails.jobId;
    const postBody = JSON.stringify(jobDetails);
    this.props.setStepState({
      rendering: true,
    });
    fetch('https://video2.scoreshots.com/api/video', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: postBody,
    })
      .then((response) => {
        // if another job has been initiated since we started,
        // we don't do anything
        if (self.jobId === jobDetails.jobId) {
          // check to see if it actually rendered.
          this.checkVideo(jobDetails.jobId);
        }
      });
  }


  render() {
    const {
      classes, forceVideo, hasEffect, rendering,
    } = this.props;
    let preview = null;
    if (!this.props.isVideo && !forceVideo && !hasEffect) {
      preview = (
        <img
          className={classes.mediaPreview}
          alt="preview of graphic"
          src={this.props.image}
        />
      );
    } else if (rendering || !this.props.jobId) {
      preview = (
        <RenderPlaceholder
          width={this.props.width}
          height={this.props.height}
        />
      );
    } else {
      preview = (
        <video
          muted
          loop
          autoPlay
          controls
          className={classes.mediaPreview}
        >
          <source src={`https://ss3-video.s3.us-east-2.amazonaws.com/output/${this.props.jobId}.mp4`} type="video/mp4" />
        </video>
      );
    }
    return (
      <div className={classes.preview}>
        {preview}
      </div>
    );
  }
}

export default withStyles(styles)(RenderPreview);
