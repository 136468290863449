
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Button,
  ButtonBase,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  Collapse,
  Dialog,
  DialogContent,
  Fade,
  FormControl,
  Grid,
  Grow,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
  SwipeableDrawer,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  ChevronLeft as ChevronLeftIcon,
  FilterVariant as FilterVariantIcon,
  FolderSearchOutline as FolderSearchOutlineIcon,
  MenuDown as MenuDownIcon,
  Close as CloseIcon,
} from 'mdi-material-ui';

import { compose, graphql } from 'react-apollo';
import { isMobileOnly } from 'react-device-detect';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import Columns from 'react-columns';
import gql from 'graphql-tag';
import parseColor from 'parse-color';
import posed from 'react-pose';

import { InfiniteScroller } from '../../infinitescroller';
import { PageTitle } from '../../../title';
import { TemplatePreview3 } from '../TemplatePreview3';
import { UserRoleProvider, UserRoleConsumer } from '../../user/Permissions';
import * as Tags from '../templatetags';

// -------------------------------------------------------------------------- //

// the transform prop here is to fix a known chrome issue
// see mui-org/material-ui/commit/9b9421c for details
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      transform: 'translate3D(0,0,0)',
      width: 236,
    },
  },
};

// -------------------------------------------------------------------------- //

const config = {
  pre: { opacity: 0, y: 100 },
  enter: { opacity: 1, y: 0, delay: 500 },
  exit: {
    opacity: 0,
    y: -100,
    transition: {
      y: {
        type: 'tween',
        ease: 'easeIn',
      },
    },
  },
};

// -------------------------------------------------------------------------- //

const COLUMNS_QUERIES = (theme) => [
  {
    columns: 1,
    query: `min-width: ${theme.breakpoints.values.xs}px`,
  }, {
    columns: 2,
    query: `min-width: ${theme.breakpoints.values.sm}px`,
  }, {
    columns: 3,
    query: `min-width: ${theme.breakpoints.values.md}px`,
  }, {
    columns: 4,
    query: `min-width: ${theme.breakpoints.values.lg}px`,
  }, {
    columns: 5,
    query: `min-width: ${theme.breakpoints.values.xl}px`,
  },
];

// -------------------------------------------------------------------------- //

const SORT_TABLE = [
  { title: 'Newest', order_by: 'createdAt_DESC' },
  { title: 'Oldest', order_by: 'createdAt_ASC' },
  { title: 'Most Popular', order_by: 'popularCount_DESC' },
];

// -------------------------------------------------------------------------- //

const GQL_TEMPLATES = gql`
  query (
    $first: Int
    $skip: Int
    $where: TemplateWhereInput
    $orderBy: TemplateOrderByInput
    $filtertags: [TEMPLATE_FILTER_TAGS!]
    $customtag: Boolean
    $premiumtag: Boolean
    $stocktag: Boolean
    $isQuickCreate: Boolean
    $favorite: Boolean
  ) {
    templateCreateSearch(
      first: $first
      skip: $skip
      where: $where
      orderBy: $orderBy
      filtertags: $filtertags
      customtag: $customtag
      premiumtag: $premiumtag
      stocktag: $stocktag
      isQuickCreate: $isQuickCreate
      favorite: $favorite
    ) {
      id
      description
      createdAt
      width
      height
      image
      isSlideShow
      name
      isQuickCreate
      visible
      parent{
        id
      }
      children {
        id
      }
      url
      customDetails {
        id
        name
      }
      favoriteUsers {
        id
      }
      filterTags
      popularCount
    }
  }
`;

// -------------------------------------------------------------------------- //

const GQL_SPORTS_CATEGORIES = gql`
  query {
    types(orderBy: index_ASC) {
      id
      name
    }
    categories {
      id
      name
    }
  }
`;

// -------------------------------------------------------------------------- //

const GQL_CLIENT = gql`
  query {
    me {
      id
    }
  }
`;

// -------------------------------------------------------------------------- //

function CreateMap(items, selector) {
  const map = {};

  for (let i = 0; i < items.length; ++i) {
    map[selector(items[i])] = items[i];
  }

  return map;
}

// -------------------------------------------------------------------------- //

async function LaunchTemplate(template, history) {
  let route = '';

  if (typeof template === 'string') {
    const BLANK_ROUTES = {
      landscape: '/editor/blank/1024/576',
      portrait: '/editor/blank/1080/1920',
      square: '/editor/blank/1024/1024',
    };

    route = BLANK_ROUTES[template];

    if (!route) {
      return false;
    }
  } else if (template.isQuickCreate) {
    route = `/editor/quickcreate/template/${template.id}`;
  } else if (template.customDetails) {
    await fetch(template.url)
      .then((response) => response.json())
      .then((json) => {
        if (json && json.data && json.data.length > 0) {
          route = `/editor/slideshow/custom/${template.customDetails.id}`;
        } else {
          route = `/editor/custom/${template.customDetails.id}`;
        }
      });
  } else if (template.isSlideShow) {
    route = `/editor/slideshow/template/${template.id}`;
  } else {
    route = `/editor/template/${template.id}`;
  }
  history.push(route);
  return true;
}

// -------------------------------------------------------------------------- //

function FadeThemeColor(str, a) {
  const { rgb } = parseColor(str);
  return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${a})`;
}

// -------------------------------------------------------------------------- //

const DRAWER_WIDTH = 320;

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  button: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: '100%', // because we can't use fullWidth on the ButtonGroup
  },
  createButton: {
    marginTop: theme.spacing(3),
    display: 'inline-block',
    width: 100,
    float: 'right',
  },
  feed_header: {
    height: 50,
    textAlign: 'right',
    marginRight: 20,
  },
  feed_root_mobile: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: 'calc(100% - 52px)',
    overflowY: 'auto',
  },
  feed_root_desktop: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: 'calc(100% - 72px)',
    overflowY: 'auto',
  },
  filter_drawer: {
    padding: theme.spacing(2),
    width: DRAWER_WIDTH,
  },
  filter_paper: {
    padding: theme.spacing(2),
  },
  flex: {
    flex: 1,
  },
  form_control: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  grid: {
    height: 'calc(100% - 72px)',
  },
  loading: {
    outline: 'none', // disable keyboard focus outline
    textAlign: 'center',
  },
  modal_button: {
    boxShadow: theme.shadows[3],
    transition: theme.transitions.create('box-shadow'),
    '&:hover': {
      boxShadow: theme.shadows[6],
    },
  },
  modal_image: {
    maxHeight: '65vh',
    maxWidth: '100%',
  },
  preview_image: {
    width: '100%',
    opacity: 0,
  },
  preview_root: {
    position: 'relative',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root_desktop: {
    height: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
  },
  root_mobile: {
    height: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    width: '100%',
  },
  template_button: {
    height: 0,
    paddingBottom: '33%',
    '&:not(:first-child)': {
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      marginLeft: -1,
    },
    '&:not(:last-child)': {
      borderBottomRightRadius: 0,
      borderRightColor: 'transparent',
      borderTopRightRadius: 0,
    },
  },
  template_button_group: {
    borderRadius: 4,
    display: 'inline-flex',
    width: '100%',
  },
  template_button_selected: {
    color: theme.palette.action.active,
    backgroundColor: FadeThemeColor(theme.palette.action.active, 0.12),
    '&:hover': {
      backgroundColor: FadeThemeColor(theme.palette.action.disabled, 0.15),
    },
  },
  template_icon_landscape: {
    borderColor: 'black',
    borderStyle: 'dashed',
    borderWidth: 2,
    height: '31%',
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    transition: theme.transitions.create('border-color'),
    width: '50%',
  },
  template_icon_portrait: {
    borderColor: 'black',
    borderStyle: 'dashed',
    borderWidth: 2,
    height: '50%',
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    transition: theme.transitions.create('border-color'),
    width: '31%',
  },
  template_icon_square: {
    borderColor: 'black',
    borderStyle: 'dashed',
    borderWidth: 2,
    height: '50%',
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    transition: theme.transitions.create('border-color'),
    width: '50%',
  },
  template_icon_active: {
    borderColor: theme.palette.primary.main,
  },
  toolbar: {},
  results_empty: {
    textAlign: 'center',
    paddingTop: theme.spacing(1),
  },
  results_empty_icon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  results_empty_p: {
    maxWidth: '80%',
    paddingTop: theme.spacing(4),
    margin: 'auto',
  },
});

// -------------------------------------------------------------------------- //

class Component extends React.Component {
  state = {
    sports: [],
    sports_map: {},
    sport_selected: null,
    sports_loading: false,
    categories: [],
    categories_map: {},
    categories_selected: [],
    categories_loading: false,
    tags: [],
    tags_selected: ['STOCK'],
    selected_aspect: null,
    templates: [],
    selected_template: null,
    template_modal: false,
    modal_expanded: false,
    feed_more: true,
    feed_page: 0,
    feed_loading: false,
    feed_sort: SORT_TABLE[0].order_by,
    scratch_anchor: React.createRef(),
    scratch_open: false,
    filter_open: false,
    prestoIntegrated: false,
  }

  constructor(props) {
    super(props);
    this.desktopFeed = null;
    this.mobileFeed = null;
  }

  componentDidMount() {
    const { search_parameters, gql_sports_categories } = this.props;
    let categoryid = localStorage.getItem('categoryid');
    const eventid = localStorage.getItem('eventid');
    const teamids = localStorage.getItem('teamids');
    const prestoSite = localStorage.getItem('prestoSite');
    let sportid = localStorage.getItem('sportid');
    if (sportid) {
      if (sportid.includes('fball')) {
        sportid = 'cjgl71ehn029s0944sbtnfb38'; // Football
      } else if (sportid.includes('vball')) {
        sportid = 'cjgl71fbn02aa0944n59s5zgs'; // Volleyball
      } else if (sportid.includes('soc')) {
        sportid = 'cjgl71eyd02a2094419l1sc45'; // Soccer
      } else if (sportid.includes('bsb')) {
        sportid = 'cjgl71e7m029m094454xoxq1p'; // Baseball
      } else if (sportid.includes('bkb')) {
        sportid = 'cjgl71eax029o0944wn7gow9c'; // Basketball
      } else if (sportid.includes('fh')) {
        sportid = 'cjgl71eeb029q0944xsliiqck'; // Field Hockey
      } else if (sportid.includes('ice')) {
        sportid = 'cjgl71eo9029w0944m4jcup4i'; // Ice Hockey
      } else if (sportid.includes('lax')) {
        sportid = 'cjgl71ern029y09441xr7ofo6'; // Lacrosse
      } else if (sportid.includes('sb')) {
        sportid = 'cjgl71f1p02a40944554z9kcg'; // Softball
      }
    }
    let prestoIntegrated = false;
    if (prestoSite === 'true') {
      prestoIntegrated = true;
    }
    if (categoryid === 'none' || categoryid === '' || categoryid === undefined || categoryid === 'undefined') {
      categoryid = null;
    }
    if (search_parameters) {
      if (prestoIntegrated) {
        search_parameters.tags = [...search_parameters.tags, 'PRESTO'];
      }
      let category_seach = [...search_parameters.categories];
      if (categoryid) {
        category_seach = [...category_seach];
        if (!category_seach.includes(categoryid)) {
          category_seach.push(categoryid);
        }
      } else if (eventid) {
        category_seach = [...category_seach];
        if (!category_seach.includes("cjgl71d6h02900944bwuppzs9")) category_seach.push("cjgl71d6h02900944bwuppzs9");
        if (!category_seach.includes("cjgl71dgt02960944n106c9bd")) category_seach.push("cjgl71dgt02960944n106c9bd");
        if (!category_seach.includes("cjgl71d9y02920944g0mi2svi")) category_seach.push("cjgl71d9y02920944g0mi2svi");
        if (!category_seach.includes("cjgl71cyy028w0944tczmgxam")) category_seach.push("cjgl71cyy028w0944tczmgxam");
        if (!search_parameters.tags.includes("CUSTOM")) search_parameters.tags.push("CUSTOM");
      } else if (teamids) {
        category_seach = [...category_seach];
        if (!category_seach.includes("cjgl71du7029e0944j5dga1xu")) category_seach.push("cjgl71du7029e0944j5dga1xu");
        if (!search_parameters.tags.includes("CUSTOM")) search_parameters.tags.push("CUSTOM");
      }
      let sports_seach = search_parameters.sport;
      if (sportid) {
        sports_seach = sportid;
      }
      this.setState({
        prestoIntegrated,
        feed_sort: search_parameters.sort,
        selected_aspect: search_parameters.orientation,
        sport_selected: sports_seach,
        categories_selected: [...category_seach],
        tags_selected: [...search_parameters.tags],
      }, () => {
        this.refetchTemplates(0);
      });
    } else if (categoryid && sportid) {
      this.setState({
        prestoIntegrated,
        categories_selected: [categoryid],
        sport_selected: [sportid],
      }, () => {
        this.refetchTemplates(0);
      });
    } else if (sportid) {
      this.setState({
        prestoIntegrated,
        sport_selected: [sportid],
      }, () => {
        this.refetchTemplates(0);
      });
    } else if (categoryid) {
      this.setState({
        prestoIntegrated,
        categories_selected: [categoryid],
      }, () => {
        this.refetchTemplates(0);
      });
    } else {
      this.refetchTemplates(0);
    }

    if (gql_sports_categories) {
      this.setState({
        sports_loading: true,
        categories_loading: true,
      }, () => {
        gql_sports_categories
          .refetch()
          .then((response) => {
            const state = {
              sports_loading: false,
              categories_loading: false,
            };

            if (!response.error && response.data) {
              state.sports = [...response.data.categories].sort((lhs, rhs) => {
                if (lhs.name < rhs.name) {
                  return -1;
                } if (lhs.name === rhs.name) {
                  return 0;
                }
                return 1;
              });

              state.categories = [...response.data.types];

              state.sports_map = CreateMap(
                state.sports, ((sport) => sport.id),
              );

              state.categories_map = CreateMap(
                state.categories, ((category) => category.id),
              );
            }

            this.setState(state);
          });
      });
    }
  }

  prestoCancel = async () => {
    const { history } = this.props;
    if(window.location.pathname === '/create/dashboard'){
      let cancelurl = localStorage.getItem('cancelurl');
      const eventid = localStorage.getItem('eventid');

      cancelurl = decodeURIComponent(cancelurl);
      cancelurl = cancelurl.replace('{event_id}', eventid);

      window.parent.postMessage({ url: cancelurl, id: 'scoreshot' }, '*');
    } else {
      localStorage.setItem('eventid', '');
      localStorage.setItem('sportid', '');
      localStorage.setItem('teamids', '');
      localStorage.setItem('seasonid', '');
      history.push('/')
    }
  }

  searchPrestoIntegratedTag = () => {
    const { prestoIntegrated, tags_selected } = this.state;
    let prevTagsSelected = tags_selected;
    if (prestoIntegrated === true) {
      prevTagsSelected = tags_selected.filter((tag) => (tag !== 'PRESTO'));
    } else {
      prevTagsSelected = [...prevTagsSelected, 'PRESTO'];
    }

    this.setState({
      prestoIntegrated: !prestoIntegrated,
      tags_selected: [...prevTagsSelected],
      templates: [],
      feed_more: true,
      feed_page: 0,
    });
  }

  render() {
    const { classes, hostRef: host_ref } = this.props;
    if (isMobileOnly) {
      return (
        <div className={classes.root_mobile} ref={host_ref}>
          <PageTitle title="Create" />
          {this.renderToolbar()}
          {this.renderFilterMenu()}
          {this.renderTemplateFeed()}
          {this.renderTemplateModal()}
        </div>
      );
    }
    return (
      <div className={classes.root_desktop} ref={host_ref} style={{ height: '100%' }}>
        <PageTitle title="Create New" />
        {this.renderToolbar()}
        <Grid container className={classes.grid} style={{ height: '100%' }}>
          <Grid item xs={12} sm={3} md={3} lg={2} xl={2} style={{ height: '100%' }}>
            {this.renderFilterMenu()}
          </Grid>
          <Grid item xs={12} sm={9} md={9} lg={10} xl={10} style={{ height: '100%' }}>
            {this.renderTemplateFeed()}
          </Grid>
        </Grid>
        {this.renderTemplateModal()}
      </div>
    );
  }

  renderToolbar = () => {
    const { classes } = this.props;
    const { feed_sort } = this.state;
    const prestoSite = localStorage.getItem('prestoSite');
    return (
      <Toolbar
        disableGutters={isMobileOnly}
        className={classes.toolbar}
      >
        {
          (!isMobileOnly) && (prestoSite !== 'true')
          && (
          <Button onClick={() => this.cancel()} aria-label="Back to home">
            <ChevronLeftIcon />
            Cancel
          </Button>
          )
        }
        {
          (!isMobileOnly) && (prestoSite === 'true')
          && (
          <Button onClick={() => this.prestoCancel()} aria-label="Back to home">
            <ChevronLeftIcon />
            Cancel
          </Button>
          )
        }
        <div className={classes.flex} />
        {
          (!isMobileOnly)
          && (
          <FormControl className={classes.form_control}>
            <Select
              MenuProps={MenuProps}
              value={feed_sort || ''}
              onChange={(e) => this.setFilters({ feed_sort: e.target.value })}
              inputProps={{ id: 'sort-input' }}
              style={{ display: 'inline-block', textAlign: 'left', width: 150 }}
            >
              {SORT_TABLE.map((sort, index) => (
                <MenuItem key={index} value={sort.order_by}>
                  {sort.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          )
        }
        {
          (isMobileOnly)
          && (
          <Button onClick={() => this.setState({ filter_open: !this.state.filter_open })}>
            <FilterVariantIcon />
            Change Filters
          </Button>
          )
        }
      </Toolbar>
    );
  }

  renderFilterMenu = () => {
    const { classes, history } = this.props;
    const {
      selected_aspect,
      sports,
      sports_loading,
      sport_selected,
      categories,
      categories_map,
      categories_loading,
      categories_selected,
      tags_selected,
      scratch_anchor,
      scratch_open,
      prestoIntegrated,
    } = this.state;
    let prestoIntegratedBlock = null;
    const prestoSite = localStorage.getItem('prestoSite');
    if (prestoSite === 'true') {
      prestoIntegratedBlock = (
        <>
          <Typography variant="body1">
            <Checkbox
              checked={prestoIntegrated}
              onChange={() => this.searchPrestoIntegratedTag()}
            />
              PrestoSports Integrated
          </Typography>
        </>
      );
    }
    const components = (
      <>
        <div className={classes.template_button_group}>
          {['Square', 'Landscape', 'Portrait'].map((title, index) => {
            const type = title.toLowerCase();
            const selected = (selected_aspect === type);

            return (
              <Tooltip key={index} title={title} enterDelay={700}>
                <Button
                  fullWidth
                  variant="outlined"
                  className={classNames(
                    classes.template_button,
                    { [classes.template_button_selected]: selected },
                  )}
                  onClick={() => this.selectAspect(type)}
                >
                  <div
                    className={classNames(
                      classes[`template_icon_${type}`],
                      { [classes.template_icon_active]: selected },
                    )}
                  />
                </Button>
              </Tooltip>
            );
          })}
        </div>
        <FormControl fullWidth className={classes.form_control}>
          <InputLabel htmlFor="sport-input">Sport</InputLabel>
          <Select
            MenuProps={MenuProps}
            value={sport_selected || ''}
            onChange={(e) => {
              this.setFilters({ sport_selected: (e.target.value !== '' ? e.target.value : null) });
            }}
            aria-label="Sports filter dropdown"
            inputProps={{ id: 'sport-input' }}
          >
            <MenuItem value="">
              <em>All Sports</em>
            </MenuItem>
            {
              sports.filter(
                (sport) => (sport.name !== 'Family Packs'),
              ).map(
                (sport) => (
                  <MenuItem key={sport.id} value={sport.id}>
                    {sport.name}
                  </MenuItem>
                ),
              )
            }
            {
              (sports_loading)
              && (
              <div className={classes.loading}>
                <CircularProgress size={48} />
              </div>
              )
            }
          </Select>
        </FormControl>
        <FormControl fullWidth className={classes.form_control}>
          <InputLabel htmlFor="category-input">Categories</InputLabel>
          <Select
            multiple
            MenuProps={MenuProps}
            value={categories_selected}
            onChange={(e) => this.setFilters({ categories_selected: e.target.value })}
            inputProps={{ id: 'category-input' }}
            aria-label="Categories filter dropdown"
            renderValue={
              (selected) => selected.map((type) => (
                categories_map[type]
                && categories_map[type].name
              )).join(', ')
            }
          >
            {categories.map(({ id, name }) => (
              <MenuItem dense key={id} value={id}>
                <Checkbox
                  color="primary"
                  checked={
                  categories_selected.indexOf(id) >= 0
                }
                />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
            {
            (categories_loading)
            && (
            <div className={classes.loading}>
              <CircularProgress size={48} />
            </div>
            )
          }
          </Select>
        </FormControl>
        {
          (!isMobileOnly)
          && (
          <FormControl fullWidth className={classes.form_control}>
            <InputLabel htmlFor="tags-input">More</InputLabel>
            <Select
              multiple
              MenuProps={MenuProps}
              inputProps={{ id: 'tags-input' }}
              value={tags_selected}
              onChange={(e) => this.selectTags(e.target.value)}
              aria-label="Templates type filter dropdown"
              renderValue={
                (selected) => (
                  Tags.ARRAY
                    .filter(({ value, name }) => selected.indexOf(value) >= 0 && name !== 'Presto Integrated' && name !== 'Schedule')
                    .map(({ name }) => name)
                    .join(', ')
                )
              }
            >
              {
                Tags.ARRAY.map(({ name, value }, index) => {
                  if (name !== 'Mobile Friendly' && name !== 'Presto Integrated') {
                    return (
                      <MenuItem dense key={index} value={value}>
                        <Checkbox
                          color="primary"
                          checked={
                            tags_selected.findIndex(
                              (selected) => (selected === value),
                            ) >= 0
                          }
                        />
                        <ListItemText primary={name} />
                      </MenuItem>
                    );
                  }
                  return null;
                })
}
            </Select>
          </FormControl>
          )
        }
        <FormControl>
          {prestoIntegratedBlock}
        </FormControl>
        <Button
          fullWidth
          color="primary"
          className={classes.button}
          onClick={() => this.clearFilters()}
        >
          Clear Filters
        </Button>
        <UserRoleProvider>
          <UserRoleConsumer>
            {(permissions) => (
              (!isMobileOnly)
            && (permissions !== null)
            && (!permissions.get('editor:lock_movement'))
            && (
              <>
                <ButtonGroup
                  color="primary"
                  variant="outlined"
                  className={classes.button}
                >
                  <Tooltip
                    enterDelay={700}
                    title="Create a template starting from a blank canvas."
                  >
                    <Button
                      color="primary"
                      className={classes.flex}
                      onClick={() => LaunchTemplate('landscape', history)}
                    >
                    Start From Scratch
                    </Button>
                  </Tooltip>
                  <Button
                    size="small"
                    color="primary"
                    ref={scratch_anchor}
                    onClick={() => this.setState({ scratch_open: !scratch_open })}
                  >
                    <MenuDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  transition
                  disablePortal
                  open={scratch_open}
                  anchorEl={scratch_anchor.current}
                  placement="bottom-end"
                >
                  {({ TransitionProps }) => (
                    <Grow
                      {...TransitionProps}
                      style={{ transformOrigin: 'right top' }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={(e) => {
                          if (
                            scratch_anchor.current
                        && scratch_anchor.current.contains(e.target)
                          ) {
                            return;
                          }

                          this.setState({ scratch_open: false });
                        }}
                        >
                          <MenuList>
                            {['Square', 'Landscape', 'Portrait'].map((title, index) => (
                              <MenuItem
                                key={index}
                                onClick={() => {
                                  LaunchTemplate(title.toLowerCase(), history);
                                }}
                              >
                          Create a
                                {' '}
                                {title}
                                {' '}
Template
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            )
            )}
          </UserRoleConsumer>
        </UserRoleProvider>
        {
        }
      </>
    );

    if (isMobileOnly) {
      return (
        <SwipeableDrawer
          anchor="right"
          open={this.state.filter_open}
          onClose={() => this.setState({ filter_open: false })}
          onOpen={() => this.setState({ filter_open: true })}
        >
          <div className={classes.filter_drawer}>
            <Toolbar disableGutters>
              <Typography variant="h5" className={classes.flex}>
                Filters
              </Typography>
              <IconButton onClick={() => this.setState({ filter_open: false })}>
                <CloseIcon />
              </IconButton>
            </Toolbar>
            {components}
          </div>
        </SwipeableDrawer>
      );
    }

    return (
      <Paper className={classes.filter_paper} data-tour="step-5">
        {components}
      </Paper>
    );
  }

  cancel = () => {
      this.props.history.push('/create');
  }

  renderTemplateFeed = () => {
    const { classes, theme, data } = this.props;
    let userID = '';
    if (data && data.me && data.me.id) {
      userID = data.me.id;
    }
    window.dispatchEvent(new Event('resize'));
    return (
      <div
        ref={this.desktopFeed}
        className={
        isMobileOnly ? classes.feed_root_mobile : classes.feed_root_desktop
      }
        data-tour="step-6"
      >
        {
        (this.state.templates.length === 0 && !this.state.feed_more)
        && (
        <div className={classes.results_empty}>
          <FolderSearchOutlineIcon color="disabled" className={classes.results_empty_icon} />
          <Typography color="textPrimary">No results found.</Typography>
          {
            (this.state.tags_selected.indexOf('CUSTOM') >= 0)
            && (
            <Typography color="textPrimary" component="p" className={classes.results_empty_p}>
              Once you save a post during the creation process as a Custom Template,
              that template (and all others) will appear here for further
              modification and posting.
            </Typography>
            )
          }
          {
            (this.state.tags_selected.indexOf('PREMIUM') >= 0)
            && (
            <Typography color="textPrimary" component="p" className={classes.results_empty_p}>
              Premium templates are unique templates that we create just for you,
              and there is a separate fee for their development. Click on the Chat
              button to inquire further and we'll be happy to help.
            </Typography>
            )
          }
        </div>
        )
      }
        <InfiniteScroller
          useWindow={isMobileOnly}
          page={this.state.feed_page}
          more={this.state.feed_more}
          onLoadPage={(page) => this.refetchTemplates(page)}
          getScrollParent={() => (isMobileOnly ? this.mobileFeed : this.desktopFeed)}
          ref={this.mobileFeed}
        >
          <Columns
            rootStyles={{ padding: theme.spacing(1), height: '100%' }}
            queries={COLUMNS_QUERIES(theme)}
            gap={`${Math.round(theme.spacing(1))}px`}
            dimensions={this.state.templates.map((template) => ({
              width: template.width,
              height: template.height,
            }))}
          >
            {this.state.templates.map((template, index) => (
              <TemplatePreview3
                fullWidth
                key={index}
                template={template}
                onClick={() => this.selectTemplate(template)}
                favorited={(template.favoriteUsers.some((favoriteUser) => (favoriteUser.id === userID)))}
                presto={(template.filterTags.some((filter) => (filter === 'PRESTO')))}
              />
            ))}
          </Columns>
          {
            (this.state.feed_loading)
            && (
            <div style={{ textAlign: 'center' }}>
              <CircularProgress size={48} key="circleProgress" />
            </div>
            )
          }
        </InfiniteScroller>
      </div>
    );
  }

  renderTemplateModal = () => {
    const { classes } = this.props;
    const {
      selected_template, template_modal, modal_expanded, modal_hide_video,
    } = this.state;
    let src = ''; let description = ''; let
      title = 'New Template';
    let component = null;

    if (selected_template !== null) {
      if (selected_template.customDetails) {
        const clientId = localStorage.getItem('client_id');
        title = `Custom Template: ${selected_template.customDetails.name}`;
        src = `https://ss3-clientmedia.s3.us-east-2.amazonaws.com/${clientId}/templates/${selected_template.customDetails.id}.png`;
      } else {
        src = `https://s3.us-east-2.amazonaws.com/ss3-templates/${selected_template.id}.jpg`;
      }

      if (selected_template.isSlideShow && !modal_hide_video && !selected_template.customDetails) {
        component = (
          <video
            muted
            autoPlay
            loop
            crossOrigin="anonymous"
            className={classes.modal_image}
            src={`https://s3.us-east-2.amazonaws.com/ss3-templates/${selected_template.id}.mp4`}
            onLoadedData={() => this.setState({ modal_loaded: true })}
            onError={() => this.setState({ modal_hide_video: true })}
          />
        );
      } else {
        component = (
          <img
            onLoad={() => this.setState({ modal_loaded: true })}
            className={classes.modal_image}
            src={src}
            alt={title}
          />
        );
      }

      description = selected_template.description || 'No description available.';
    }

    description = ''; // Remove line to add descriptions back to templates

    return (
      <Dialog
        open={template_modal}
        onClose={() => this.unselectTemplate()}
        TransitionComponent={Grow}
      >
        <DialogContent style={{ position: 'relative', marginBottom: 18 }}>
          <ButtonBase
            className={classes.modal_button}
            onClick={() => this.launchTemplate()}
          >
            {component}
          </ButtonBase>
          <Collapse
            in={modal_expanded && Boolean(description)}
            timeout={{ enter: 550 }}
          >
            <DialogContent>
              <Fade
                in={modal_expanded && Boolean(description)}
                timeout={{ enter: 700 }}
              >
                <Typography variant="subtitle1">{description}</Typography>
              </Fade>
            </DialogContent>
          </Collapse>
          <Button
            color="primary"
            variant="contained"
            className={classes.createButton}
            onClick={() => this.launchTemplate()}
          >
            Create
          </Button>
        </DialogContent>
      </Dialog>
    );
  }

  launchTemplate = () => {
    const { selected_template } = this.state;
    const { history, search_parameters } = this.props;

    const {
      feed_sort,
      selected_aspect,
      sport_selected,
      categories_selected,
      tags_selected,
    } = this.state;

    search_parameters.sort = feed_sort;
    search_parameters.orientation = selected_aspect;
    search_parameters.sport = sport_selected;
    search_parameters.categories = categories_selected;
    search_parameters.tags = tags_selected;
    LaunchTemplate(selected_template, history);
  }

  selectAspect = (aspect) => {
    const state = {
      templates: [],
      feed_more: true,
      feed_page: 0,
    };

    if (this.state.selected_aspect === aspect) {
      state.selected_aspect = null;
    } else {
      state.selected_aspect = aspect;
    }

    this.setState(state);
  }

  selectTags = (tags) => {
    this.setState({
      tags_selected: tags,
      templates: [],
      feed_more: true,
      feed_page: 0,
    });
  }

  setFilters = (state) => {
    this.setState({
      ...state,
      templates: [],
      feed_more: true,
      feed_page: 0,
    });
  }

  clearFilters = () => {
    this.setState({
      selected_aspect: null,
      sport_selected: null,
      categories_selected: [],
      tags_selected: ['STOCK'],
      templates: [],
      feed_more: true,
      feed_page: 0,
    });
  }

  selectTemplate = (template) => {
    this.setState({
      selected_template: template,
      template_modal: true,
      modal_expanded: false,
    });
  }

  unselectTemplate = () => {
    this.setState({ template_modal: false });
  }

  refetchTemplates = (page) => {
    const { gql_templates, data } = this.props;
    const {
      selected_aspect,
      sport_selected,
      categories_selected,
      tags_selected,
      feed_loading,
      feed_sort,
      feed_more,
      prestoIntegrated,
    } = this.state;

    if (!feed_more || feed_loading || !data || !data.me) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      this.setState({ feed_loading: true }, () => {
        const where = { visible: 'VISIBLE' };

        if (selected_aspect) {
          where.orientation = selected_aspect.toUpperCase();
        }

        if (categories_selected.length > 0) {
          where.types_some = { id_in: categories_selected };
        }

        if (sport_selected !== null) {
          where.categories_some = { id: sport_selected };
        }
        let quickCreate = tags_selected.includes('QUICK_CREATE')


        let filtertags = tags_selected.filter((tag) => (tag !== 'CUSTOM' && tag !== 'PREMIUM' && tag !== 'STOCK' && tag !== 'FAVORITE'));

        if (prestoIntegrated) {
          filtertags = [...filtertags, 'PRESTO'];
        }
        if (isMobileOnly) {
          filtertags.push('MOBILE_FRIENDLY');
        }

        gql_templates.refetch({
          skip: (20 * page),
          first: 19,
          where,
          orderBy: feed_sort,
          customtag: tags_selected.some((tag) => (tag === 'CUSTOM')),
          premiumtag: tags_selected.some((tag) => (tag === 'PREMIUM')),
          stocktag: tags_selected.some((tag) => (tag === 'STOCK')),
          isQuickCreate: quickCreate,
          favorite: tags_selected.some((tag) => (tag === 'FAVORITE')),
          filtertags,
        }).then((response) => {
          this.setState({
            templates: [
              ...this.state.templates,
              ...response.data.templateCreateSearch,
            ],
            feed_more: (response.data.templateCreateSearch.length === 20),
            feed_loading: false,
            feed_page: (page + 1),
          }, () => resolve());
        }).catch(reject);
      });
    });
  }
}

// -------------------------------------------------------------------------- //

export const TemplateSelect = posed(
  compose(
    graphql(GQL_CLIENT),
    graphql(GQL_SPORTS_CATEGORIES, {
      name: 'gql_sports_categories',
      options: { fetchPolicy: 'cache-and-network' },
    }),
    graphql(GQL_TEMPLATES, {
      name: 'gql_templates',
      options: {
        fetchPolicy: 'no-cache',
        variables: { first: 1 },
      },
    }),
    withRouter,
    withStyles(STYLES, { withTheme: true }),
  )(Component),
)(config);

// -------------------------------------------------------------------------- //
