import React, { Component } from 'react';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

const styles = theme => ({
  grid: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    position: 'relative',
  },
  disablePaddingTop: {
    paddingTop: 0,
  },
  rgrid: {
    paddingLeft: 0,
  },
  rgridFallback: {
    paddingLeft: 16,
    [theme.breakpoints.down('xs')]: {
      marginTop: 56,
    },
  },
});

class SidebarGrid extends Component {
  render() {
    const { classes, xsFallback, disablePaddingTop, ...rest } = this.props;
    return (
      <Grid container spacing={0} {...rest} className={classnames(
        classes.grid,
        { [classes.disablePaddingTop]: disablePaddingTop },
      )}>
        <Hidden xsDown>
          <Grid
            item
            sm={4}
            md={3}
          >
            {this.props.sidebar}
          </Grid>
        </Hidden>
        {
          xsFallback ?
            <Hidden smUp>
              {xsFallback}
            </Hidden> :
          null
        }

        <Grid
          item
          xs={12}
          sm={8}
          md={9}
          className={
            xsFallback ?
            classes.rgridFallback :
            classes.rgrid
          }
        >
          {this.props.children}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(SidebarGrid);
