
// -------------------------------------------------------------------------- //

import React from 'react';

import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import { PageTitle } from '../title';
import CustomTemplateContents from '../components/create/CustomTemplateContents';

// -------------------------------------------------------------------------- //

const GET_FOLDER_CONTENTS = gql`
  query getFolderContents($folderId: ID!) {
    getCustomTemplateFolder(
      id: $folderId
    ) {
      id
      name
      children {
        id
        name
      }
      parent {
        id
        name
      }
      templates {
        id
        name
        template {
          id
          image
          url
          isQuickCreate
        }
      }
    }
  }
`;

const GET_ORPHAN_CONTENT = gql`
  query getOrphanContent {
    getOrphanedFolders {
      id
      name
    }
    getOrphanedTemplates {
      id
      name
      template {
        id
        image
        url
        isQuickCreate
      }
    }
  }
`;

class CustomTemplates_ extends React.Component {

  state = {
    folderId: null,
  }

  eventStateChange = (prop, value) => () => {
    this.setState({ [prop]: value });
  }

  render() {
    const homeParent = {
      id: null,
      name: 'My Custom Templates',
    };

    if (this.state.folderId) {
      return (
          <React.Fragment>
            <PageTitle title="Custom Templates"/>
            <Query
                query={GET_FOLDER_CONTENTS}
                variables={{ folderId: this.state.folderId }}
                fetchPolicy="network-only"
            >
              {({ data, networkStatus, refetch }) => (
                  (networkStatus === 7) &&
                  <CustomTemplateContents
                      folders={data.getCustomTemplateFolder.children}
                      templates={data.getCustomTemplateFolder.templates}
                      eventStateChange={this.eventStateChange}
                      name={data.getCustomTemplateFolder.name}
                      parent={data.getCustomTemplateFolder.parent || homeParent}
                      folderId={this.state.folderId}
                      refresh={refetch}
                  />
              )}
            </Query>
          </React.Fragment>
      );
    }

    return (
        <React.Fragment>
          <PageTitle title="Custom Templates"/>
          <Query query={GET_ORPHAN_CONTENT} fetchPolicy="network-only">
            {({ data, networkStatus }) => (
                networkStatus === 7 ?
                    <CustomTemplateContents
                        folders={data.getOrphanedFolders}
                        templates={data.getOrphanedTemplates}
                        eventStateChange={this.eventStateChange}
                    /> :
                    null
            )}
          </Query>
        </React.Fragment>
    );
  }
}

// -------------------------------------------------------------------------- //

export const CustomTemplates = CustomTemplates_;

// -------------------------------------------------------------------------- //
