
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  withStyles,
} from '@material-ui/core/styles';

import {PageTitle} from '../../title';
import ResourcesNav from './ResourcesNav';
import ResourcesVideo from './ResourcesVideo';
import ResponsiveGrid from '../layout/ResponsiveGrid';
import SidebarGrid from '../layout/SidebarGrid';
import ResourcesFAQ from './ResourcesFAQ';

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  grid: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  spacer: {
    paddingTop: 64,
  },
  superSpacer: {
    paddingTop: 64,
    marginTop: -64,
  },
});

// -------------------------------------------------------------------------- //

const Resources = (props) => {
  const {classes} = props;
  window.scrollTo(0, 0);
  return (
    <React.Fragment>
      <PageTitle title="Resources"/>
        <ResponsiveGrid className={classes.grid}>
          <SidebarGrid
            sidebar={(
              <ResourcesNav/>
            )}
          >
            <div id="FAQ" className={classes.superSpacer}>
              <ResourcesFAQ/>
            </div>
            <div id="video" className={classes.spacer}>
              <ResourcesVideo/>
            </div>
          </SidebarGrid>
        </ResponsiveGrid>
    </React.Fragment>
  );
};

// -------------------------------------------------------------------------- //

export default withStyles(STYLES)(Resources);

// -------------------------------------------------------------------------- //
