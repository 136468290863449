import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import File from './File';

const styles = theme => ({
  grid: {
    marginTop: theme.spacing(1),
  },
});

class FileList extends Component {
  state = {
    imageAdded: false,
  }
  getFiles() {
    const {
      files, setSelected, drag,setSelectedFiles, dragEnd,folder_id
    } = this.props;


    const output = [];
    files.forEach((file) => {
      
      const active = this.props.selected.indexOf(file.id) !== -1;
      output.push((
        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          lg={3}
          xl={3}
          key={file.id}
        >
          <File file={file} drag={drag} dragEnd={dragEnd} folderId={folder_id} setSelected={setSelected} setSelectedFiles={setSelectedFiles} active={active} added={this.state.imageAdded} />
        </Grid>
      ));
    });
    return output;
  }
  render() {
    const { classes } = this.props;
    
    return ([
      <Typography id="main-content" key="header" variant="caption" gutterBottom>
        Files
      </Typography>,
      <Grid spacing={2} container key="files" className={classes.grid}>
        {this.getFiles()}
      </Grid>,
    ]);
  }
}

export default withStyles(styles)(FileList);
