import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  button: {
    marginRight: 8,
  },
  card: {
  	margin: theme.spacing(1)
  },
  cardImage: {
    backgroundImage: "url('https://ss3-assets.s3.us-east-2.amazonaws.com/grid.png')",
    backgroundRepeat: 'repeat',
  	width: '90%',
  	margin: theme.spacing(1)
  }
});

class GraphicCard extends Component {
	render() {
		const {
			classes, graphicID, image, name
		} = this.props;

		return (
			<Card className={classes.card}>
				<CardHeader
					title={name || "Unnamed Graphic"}
					subheader={false} />
				{
					image ?
						<CardMedia
							className={classes.cardImage}
							component="img"
							image={image}
							title={name} /> :
						<CardMedia
							className={classes.cardImage}
							component="img"
							image="https://ss3-assets.s3.us-east-2.amazonaws.com/graphics/empty.png"
							title="Empty graphic" />
				}

        <CardActions>
        	<Button size="small" component={Link} to={`/admin/graphics/${graphicID}`}>
      			Edit
      		</Button>
        </CardActions>
			</Card>
		);
	}
}

export default withStyles(styles)(GraphicCard);
