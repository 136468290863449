class Animation
{
  constructor() {
    this._options = {};
  }

  getFPS() {
    return 24;
  }

  tween(t, duration, loop) {
    const animationDuration = this.getOption('duration') || duration;

    let mu;
    let isDone = false;
    if (t > animationDuration && t < duration - animationDuration) {
      isDone = true;
    } else {
      if (t < animationDuration) {
        mu = 1 - t / animationDuration;
      } else if (loop) {
        let difference = duration - animationDuration;
        difference = t - difference;
        mu = difference / animationDuration;
      } else {
        isDone = true;
      }
    }

    if (isDone) {
      return 0.0;
    }

    let result = 1.0;
    switch (this.getOption('tween')) {
      case 'sine':
      default:
        result = 1 - (Math.sin(mu * Math.PI + Math.PI / 2) + 1) / 2;
    }

    return result;
  }

  getKeyFrame(object, time) {
    if (!object.animation) {
      object.animation = {
        animations: []
      }
    }

    if (!object.animation.animations) {
      object.animation.animations = [];
    }

    const keyFrames = object.animation.animations;
    for (let i = 0; i < keyFrames.length; ++i) {
      let difference = Math.abs(keyFrames[i].startTime - time);

      if (difference < 1 / this.getFPS()) {
        return keyFrames[i];
      }
    }

    const keyFrame = { startTime: time };
    keyFrames.push(keyFrame);

    keyFrames.sort((a, b) => {
      return a.startTime - b.startTime;
    });

    return keyFrame;
  }

  apply() {
    // Nothing.
  }

  setOption(key, value) {
    this._options[key] = value;
  }

  getOption(key) {
    return this._options[key];
  }
}

export default Animation;
