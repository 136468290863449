import React from 'react';

import { Icon } from '@material-ui/core';



function AddHostedSvg(url, fabric, canvas, colors, scale, name) {
    return new Promise((resolve) => {
        fabric.loadSVGFromURL(url,
            (objects, options) => {
                let object = fabric.util.groupSVGElements(objects, options);

                object.set({
                    name, left: 10, top: 10,
                    scaleX: scale, scaleY: scale,
                    fill: colors.primary,
                });

                if (canvas.step === 'admin') {
                    object.set('fillOverride', 'primary');
                }

                canvas.add(object).renderAll();
                resolve(object);
            }
        );
    });
}

const states_data = [
    {
        name: 'Mississippi',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-01.svg'
    },
    {
        name: 'Washington',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-03.svg'
    },
    {
        name: 'Idaho',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-05.svg'
    },
    {
        name: 'Montana',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-07.svg'
    },
    {
        name: 'North Dakota',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-09.svg'
    },
    {
        name: 'Arizona',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-11.svg'
    },
    {
        name: 'Pennsylvania',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-13.svg'
    },{
        name: 'Oregon',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-15.svg'
    },{
        name: 'South Dakota',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-17.svg'
    },{
        name: 'Minnesota',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-19.svg'
    },{
        name: 'Alabama',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-21.svg'
    },{
        name: 'North Carolina',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-23.svg'
    },{
        name: 'Missouri',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-25.svg'
    },{
        name: 'Wisconsin',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-27.svg'
    },{
        name: 'Wyoming',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-29.svg'
    },{
        name: 'West Virgina',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-31.svg'
    },{
        name: 'Ohio',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-33.svg'
    },{
        name: 'Iowa',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-35.svg'
    },{
        name: 'Nebraska',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-37.svg'
    },{
        name: 'Alaska',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-39.svg'
    },{
        name: 'New York',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-41.svg'
    },{
        name: 'Hawai',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-43.svg'
    },{
        name: 'Michigan',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-45.svg'
    },{
        name: 'Florida',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-47.svg'
    },{
        name: 'Texas',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-49.svg'
    },{
        name: 'Kansas',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-51.svg'
    },{
        name: 'Oklahoma',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-53.svg'
    },{
        name: 'Indiana',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-55.svg'
    },{
        name: 'Arkansas',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-57.svg'
    },{
        name: 'Illinois',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-59.svg'
    },{
        name: 'Kentucky',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-61.svg'
    },{
        name: 'Massachusets',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-63.svg'
    },{
        name: 'Nevada',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-65.svg'
    },{
        name: 'California',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-67.svg'
    },{
        name: 'Delaware',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-69.svg'
    },{
        name: 'Georgia',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-71.svg'
    },{
        name: 'New Hampshire',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-73.svg'
    },{
        name: 'Rhode Island',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-75.svg'
    },{
        name: 'Colorado',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-77.svg'
    },{
        name: 'Maine',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-79.svg'
    },{
        name: 'Maryland',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-81.svg'
    },{
        name: 'Tennessee',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-83.svg'
    },{
        name: 'Louisiana',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-85.svg'
    },{
        name: 'Vermont',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-87.svg'
    },{
        name: 'New Mexico',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-89.svg'
    },{
        name: 'Utah',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-91.svg'
    },{
        name: 'Connecticut',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-93.svg'
    },{
        name: 'Virginia',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-95.svg'
    },{
        name: 'South Carolina',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-97.svg'
    },{
        name: 'New Jersey',
        url: 'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/states/50+States+Map-99.svg'
    }
]

class Svg extends React.Component {
    state = {
        svg: null,
    }

    componentDidMount() {
        fetch(this.props.url)
            .then(res => res.text())
            .then(text => this.setState({ svg: text }));
    }

    render() {
        const { svg } = this.state;

        return <div style={{width:'50px',height:'50px'}} {...this.props} dangerouslySetInnerHTML={{ __html: svg}}/>;
    }
}


const STATES = {}

states_data.forEach( state => {
    STATES[state.name.toLowerCase()] = {
            name: state.name,
            icon: (props) => (
                <Icon {...props} >
                    {console.log(props)}
                    <Svg {...props} url={`${state.url}?${new Date().getTime()}`}/>
                </Icon>
            ),
            adder: async ({ canvas, fabric, colors }) => {
                return await AddHostedSvg(
                    `${state.url}?${new Date().getTime()}`,
                    fabric, canvas, colors, 0.5, state.name
                );
            }
    }
})

export {STATES}
