import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import {TwitterRetweet, Heart, Comment, Share, EmoticonHappy} from 'mdi-material-ui'

const iconSpace = {paddingLeft: 10};
const iconFont = {fontSize: 15, color: '#0000008a'};
const textFont = {fontSize: 15, padding: 5, color: '#0000008a'};

class SocialMetrics extends Component {
  state = {
    facebook_stats: [],
    facebook_initialized: false,
    twitter_stats: [],
    twitter_initialized: false
  }

  componentDidMount() {
    const { facebook_initialized, twitter_initialized } = this.state;
    if (!facebook_initialized) {
      this.getFacebooks();
    }
    if (!twitter_initialized) {
      this.getTwitters();
    }
  }

  getFacebooks() {
    this.setState({ facebook_initialized: true });
    let self = this;
    let added_facebooks = {};
    this.props.post.facebookPosts.forEach((post) => {
      const page = post.page;
      let page_id = post.page.fbId;
      if (post.postId) {
        let post_id = post.postId;
        let access_token = post.page.accessToken;
        let endpoint_stat_data = [];
        endpoint_stat_data.push({
          "__key": "fb_stats",
          "service": "facebook",
          "service_id": page_id,
          "access_token": access_token,
          "postId": post_id
        })
        let endpoint_data = new FormData();
        endpoint_data.append("body", JSON.stringify(endpoint_stat_data));
        const backend_endpoint = `${process.env.REACT_APP_SS_API_ENDPOINT}v1/__Social_Stats__c/populate/prod/`;
        fetch(backend_endpoint, {
          //credentials : 'include',
          method: 'POST',
          body: JSON.stringify({"body": endpoint_stat_data}),
          mode: 'cors'
        }).then(response => {
          return response.json();
        }).then(json => {
          let __data = {likes: 0, comments: 0, shares: 0};
          //  return json;
          let basic_stats = json.objects[0].data.basic_stats;
          if (basic_stats.reactions) {
            __data.likes = basic_stats.reactions.summary.total_count;
          }
          if (basic_stats.comments) {
            __data.comments = basic_stats.comments.summary.total_count;
          }
          if (basic_stats.sharedposts) {
            __data.shares = basic_stats.sharedposts.summary.total_count;
          }
          let this_output = self.state.facebook_stats;
          if (!added_facebooks[page_id]) {
            added_facebooks[page_id] = true;
            this_output.push(<ListItem
              key={page.id}
              dense
            >
              <ListItemAvatar>
                <Avatar src={`https://graph.facebook.com/v2.12/${page.fbId}/picture?height=40`}/>
              </ListItemAvatar>
              <ListItemText
                primary={page.name}
              />
              <table>
                <tbody>
                <tr>
                  <td style={iconSpace}><EmoticonHappy style={iconFont}/><span
                    style={textFont}>{`${__data.likes}`}</span></td>
                  <td style={iconSpace}><Comment style={iconFont}/><span style={textFont}>{`${__data.comments}`}</span>
                  </td>
                  <td style={iconSpace}><Share style={iconFont}/><span style={textFont}>{`${__data.shares}`}</span></td>
                </tr>
                </tbody>
              </table>
            </ListItem>);
            this.setState({ facebook_stats: this_output });
          }
        }).catch(e => {
          let this_output = self.state.facebook_stats;
          if (!added_facebooks[page_id]) {
            added_facebooks[page_id] = true;
            this_output.push(<ListItem
              key={page.id}
              dense
            >
              <ListItemAvatar>
                <Avatar src={`https://graph.facebook.com/v2.12/${page.fbId}/picture?height=40`}/>
              </ListItemAvatar>
              <ListItemText
                primary={page.name}
              />
              <table>
                <tbody>
                <tr>
                  <td style={iconSpace}><EmoticonHappy style={iconFont}/><span style={textFont}>0</span></td>
                  <td style={iconSpace}><Comment style={iconFont}/><span style={textFont}>0</span></td>
                  <td style={iconSpace}><Share style={iconFont}/><span style={textFont}>0</span></td>
                </tr>
                </tbody>
              </table>
            </ListItem>);
            this.setState({ facebook_stats: this_output });
          }
        });
      } else {
        let this_output = self.state.facebook_stats;
        if (!added_facebooks[page_id]) {
          added_facebooks[page_id] = true;
          this_output.push(<ListItem
            key={page.id}
            dense
          >
            <ListItemAvatar>
              <Avatar src={`https://graph.facebook.com/v2.12/${page.fbId}/picture?height=40`}/>
            </ListItemAvatar>
            <ListItemText
              primary={page.name}
            />
            <table>
              <tbody>
              <tr>
                <td style={iconSpace}><EmoticonHappy style={iconFont}/><span style={textFont}>0</span></td>
                <td style={iconSpace}><Comment style={iconFont}/><span style={textFont}>0</span></td>
                <td style={iconSpace}><Share style={iconFont}/><span style={textFont}>0</span></td>
              </tr>
              </tbody>
            </table>
          </ListItem>);
          this.setState({ facebook_stats: this_output });
        }
      }
    });
  }

  getTwitters() {
    this.setState({ twitter_initialized: true });
    let self = this;
    let added_twitters = {};
    this.props.post.twitterPosts.forEach((post) => {
      const account = post.account;
      let post_id = post.postId;
      let access_token = account.oauthToken; //or oauthTokenSecret ?
      let account_id = account.twitterId;
      let endpoint_stat_data = [];
      endpoint_stat_data.push({
        "__key": "twitter_stats",
        "service": "twitter",
        "service_id": account_id,
        "access_token": access_token,
        "postId": post_id
      })
      let endpoint_data = new FormData();
      endpoint_data.append("body", JSON.stringify(endpoint_stat_data));
      const backend_endpoint = `${process.env.REACT_APP_SS_API_ENDPOINT}v1/__Social_Stats__c/populate/prod/`;
      fetch(backend_endpoint, {
        //credentials : 'include',
        method: 'POST',
        body: JSON.stringify({"body": endpoint_stat_data}),
        mode: 'cors'
      }).then(response => {
        return response.json();
      }).then(json => {
        let __data = {favorite_count: 0, retweet_count: 0, replies_count: 0};
        if (json.objects[0].data["favorite_count"]) {
          __data = {
            favorite_count: json.objects[0].data.favorite_count,
            retweet_count: json.objects[0].data.retweet_count,
            replies_count: 0
          };
        }
        let this_output = self.state.twitter_stats;
        if (!added_twitters[account_id]) {
          added_twitters[account_id] = true;
          this_output.push(<ListItem
            key={`retweet${account.twitterId}`}
            dense
          >
            <ListItemAvatar>
              <Avatar src={`https://twitter-avatar.now.sh/${account.screen_name}`}/>
            </ListItemAvatar>
            <ListItemText
              primary={account.name}
              secondary={`@${account.screen_name}`}
            />
            <table>
              <tbody>
              <tr>
                <td style={iconSpace}><Heart style={iconFont}/><span
                  style={textFont}>{`${__data.favorite_count}`}</span></td>
                <td style={iconSpace}><Comment style={iconFont}/><span
                  style={textFont}>{`${__data.replies_count}`}</span></td>
                <td style={iconSpace}><TwitterRetweet style={iconFont}/><span
                  style={textFont}>{`${__data.retweet_count}`}</span></td>
              </tr>
              </tbody>
            </table>
          </ListItem>);
          self.setState({ twitter_stats: this_output });
        }
      }).catch(e => {
        let this_output = self.state.twitter_stats;
        if (!added_twitters[account_id]) {
          added_twitters[account_id] = true;
          this_output.push(<ListItem
            key={`retweet${account.twitterId}`}
            dense
          >
            <ListItemAvatar>
              <Avatar src={`https://twitter-avatar.now.sh/${account.screen_name}`}/>
            </ListItemAvatar>
            <ListItemText
              primary={account.name}
              secondary={`@${account.screen_name}`}
            />

            <table>
              <tbody>
              <tr>
                <td style={iconSpace}><Heart style={iconFont}/><span style={textFont}>0</span></td>
                <td style={iconSpace}><Comment style={iconFont}/><span style={textFont}>0</span></td>
                <td style={iconSpace}><TwitterRetweet style={iconFont}/><span style={textFont}>0</span></td>
              </tr>
              </tbody>
            </table>
          </ListItem>);
          self.setState({ twitter_stats : this_output});
        }
      });
    });
  }

  render() {
    const { facebook_stats, twitter_stats } = this.state;
    return (
      <React.Fragment>
        <Typography variant="h6">
          Posted To:
        </Typography>
        <List>
          {facebook_stats.map(facebook_stat => <div key={facebook_stat.key}>{facebook_stat}</div>)}
          {twitter_stats.map(twitter_stat => <div key={twitter_stat.key}>{twitter_stat}</div>)}
        </List>
      </React.Fragment>
    );
  }
}

export default SocialMetrics;
