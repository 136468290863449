import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ChevronRight from 'mdi-material-ui/ChevronRight';
import dict from './dict';

const styles = {
  crumbs: {
    flex: 1,
  },
  crumbSelf: {
    display: 'inline',
  },
  crumbParent: {
    display: 'inline',
    color: 'rgba(255,255,255,.3)',
    cursor: 'pointer',
    userSelect: 'none',

  },
  crumbDivider: {
    display: 'inline',
    paddingLeft: 8,
    paddingRight: 8,
  },
};

const GET_FOLDER = gql`
  query ($folderId: ID) {
    folder(
      folderId: $folderId
    ) {
      id
      parent {
        name
        id
      }
      name
      type
    }
  }
`;


class Crumbs extends Component {
  parentLink = (self, id = '') => () => {
    self.props.history.push(`/media/${dict[self.props.type].urlName}/${id}`);
  }

  getCrumbs() {
    const {
      classes, data, type, drop, dragOver,
    } = this.props;
    const output = [];
    // component has loaded
    if (data.folder.parent === null) {
      output.push((
        <Typography
          variant="h5"
          color="inherit"
          component="h1"
          noWrap
          key="parent"
          className={classes.crumbParent}
          onClick={this.parentLink(this)}
          onDrop={drop(null, type)}
          onDragOver={dragOver}
        >
          {dict[type].prettyName}
        </Typography>
      ));
    } else {
      output.push(<Typography
        variant="h5"
        color="inherit"
        component="h1"
        noWrap
        key="parent"
        className={classes.crumbParent}
        onClick={this.parentLink(this, data.folder.parent.id)}
        onDrop={drop(data.folder.parent.id)}
        onDragOver={dragOver}
      >
        {data.folder.parent.name}
                  </Typography>);
    }

    output.push(
      <ChevronRight style={{ marginBottom: -5 }} key="divider" />,
      <Typography variant="h5" color="inherit" noWrap key="self" className={classes.crumbSelf}>
        {data.folder.name}
      </Typography>,
    );
    return output;
  }
  render() {
    const { classes, data } = this.props;
    if (!data.loading && data.folder) {
      return (
        <div className={classes.crumbs}>
          {this.getCrumbs()}
        </div>
      );
    }
    return (
      <div className={classes.crumbs}>
        <Typography variant="h5" color="inherit" component="h1" noWrap key="self" className={classes.crumbSelf}>
          {dict[this.props.type].prettyName}
        </Typography>
      </div>
    );
  }
}

export default graphql(GET_FOLDER)(withStyles(styles)(Crumbs));
