import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { Subscribe } from 'unstated';
import ResponsiveGrid from '../components/layout/ResponsiveGrid';
import SidebarGrid from '../components/layout/SidebarGrid';
import SidebarNav from '../components/nav/SidebarNav';
import userSidebarConfig from '../dict/userSidebar';
import General from '../components/user/General';
import Password from '../components/user/Password';
import MessageContainer from '../unstated/MessageContainer';
import { PageTitle } from '../title';


const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
});

const User = (props) => {
  const { classes } = props;
  const prestoSite = localStorage.getItem('prestoSite');

  return (
    <React.Fragment>
    <PageTitle title="User Settings"/>
    <ResponsiveGrid>
      {
        prestoSite !== 'true' ? (
            <Subscribe to={[MessageContainer]}>
              {messages => (
                  <SidebarGrid sidebar={<SidebarNav config={userSidebarConfig} />}>
                    <General className={classes.paper} sendMessage={messages.setMessage} />
                    <Password className={classes.paper} sendMessage={messages.setMessage} />
                  </SidebarGrid>
              )}
            </Subscribe>
        ) : (
            <Container style={{textAlign:'center', marginTop: '15vh'}}>
              <Typography variant="h6">You can change your user setting on your CMS profile</Typography>
            </Container>
        )
      }

      </ResponsiveGrid>
    </React.Fragment>
  );
};

export default withStyles(styles)(User);
