
// -------------------------------------------------------------------------- //

import * as React from 'react';

import {
  AppBar,
  Hidden,
  Tab,
  Tabs,
  withStyles,
} from '@material-ui/core';

import {
  FolderImage as FolderImageIcon,
  FolderStar as FolderStarIcon,
  PencilBox as PencilBoxIcon,
  Timetable as TimetableIcon,
  ViewList as ViewListIcon,
} from 'mdi-material-ui';

import CreateNav from './CreateNav';
import { CustomTemplates } from '../../views/CustomTemplates';
import { Drafts } from './views/Drafts';
import Posts from './views/Posts';
import { PremiumTemplates } from '../../views/PremiumTemplates';
import ResponsiveGrid from '../layout/ResponsiveGrid';
import { Scheduled } from './views/Scheduled';
import SidebarGrid from '../layout/SidebarGrid';
import { PageTitle } from '../../title';

// -------------------------------------------------------------------------- //

const ROUTES_LIST = [
  {
    pretty_name: 'Published',
    url: '/create/posts',
    icon: <ViewListIcon/>,
    component: <Posts/>,
  },
  {
    pretty_name: 'Scheduled',
    url: '/create/scheduled',
    icon: <TimetableIcon/>,
    component: <Scheduled/>,
  },
  {
    pretty_name: 'Drafts',
    url: '/create/drafts',
    icon: <PencilBoxIcon/>,
    component: <Drafts/>,
  },
  {
    pretty_name: 'Custom',
    url: '/create/custom',
    icon: <FolderImageIcon/>,
    component: <CustomTemplates/>,
  },
  {
    pretty_name: 'Premium',
    url: '/create/premium',
    icon: <FolderStarIcon/>,
    component: <PremiumTemplates/>,
  },
];

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  app_bar: {
    backgroundColor: theme.palette.primary.light,
    top: theme.spacing(8),
  },
  grid: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
});

// -------------------------------------------------------------------------- //

class Main extends React.Component {

  state = {
    selected: 0,
  }

  render() {
    return (
      <React.Fragment>
        <Hidden only="xs">
          <PageTitle title="Feed"/>
          {this.renderDesktop()}
        </Hidden>
        <Hidden smUp>
          <PageTitle title="Feed"/>
          {this.renderMobile()}
        </Hidden>
      </React.Fragment>
    );
  }

  renderDesktop = () => {
    const { type = 'posts' } = this.props.match.params;
    const MainComponent = this.getMainComponent();
    return (
      <ResponsiveGrid>
        <SidebarGrid sidebar={<CreateNav type={type}/>}>
          <MainComponent/>
        </SidebarGrid>
      </ResponsiveGrid>
    );
  }

  renderMobile = () => {
    const { classes } = this.props;
    const { selected } = this.state;

    return (
      <React.Fragment>
        <AppBar color="primary" position="sticky" className={classes.app_bar}>
          <Tabs value={selected} onChange={(_, tab) => this.changeTab(tab)}>
          {ROUTES_LIST.map((route) => (
            <Tab label={route.pretty_name} key={route.pretty_name}/>
          ))}
          </Tabs>
        </AppBar>
        <div className={classes.grid}>
          {ROUTES_LIST[selected].component}
        </div>
      </React.Fragment>
    );
  }

  getMainComponent = () => {
    const { match } = this.props;
    const { type = 'posts' } = match.params;

    switch (type) {
      case 'custom': return CustomTemplates;
      case 'posts': return Posts;
      case 'drafts': return Drafts;
      case 'scheduled': return Scheduled;
      case 'premium': return PremiumTemplates;
      default: return Posts;
    }
  }

  changeTab = (index) => {
    this.setState({ selected: index });
  }

}

// -------------------------------------------------------------------------- //

export default withStyles(STYLES)(Main);

// -------------------------------------------------------------------------- //
