import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import { Link, Redirect } from 'react-router-dom';

import gql from 'graphql-tag';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Check from 'mdi-material-ui/Check';
import AlertCircle from 'mdi-material-ui/AlertCircle';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import Facebook from './Facebook';
import ScoreshotsPut from '../../scoreshotsapi/Put';
import { EditorUnloadCallback } from '../editor/Editor';

const bucket = 'ss3-posts';

const POST = gql`
  mutation createPost (
    $scheduled: Boolean!,
    $postTime: DateTime,
    $templateId: ID,
    $width: Int,
    $height: Int,
    $bucket: String,
    $job: Json
  ) {
    createPost(
      scheduled: $scheduled,
      postTime: $postTime,
      templateId: $templateId,
      width: $width,
      height: $height,
      bucket: $bucket,
      job: $job
    ) {
      id
    }
  }
`;

const ADD_FACEBOOK_POST = gql`
  mutation addFacebookPost (
    $parentPostId: ID!,
    $message: String,
    $postId: String,
    $fbId: ID!,
    $media: Json!
  ) {
    addFacebookToPost(
      parentPostId: $parentPostId,
      postId: $postId,
      message: $message,
      fbId: $fbId,
      media: $media
    ) {
      id
      postId
    }
  }
`;

const ADD_TWITTER_POST = gql`
  mutation addTwitterPost (
    $parentPostId: ID!,
    $message: String,
    $retweet: Boolean!,
    $twitterId: ID!,
    $media: Json!
  ) {
    addTwitterToPost(
      parentPostId: $parentPostId,
      message: $message,
      retweet: $retweet,
      twitterId: $twitterId,
      media: $media
    ) {
      id
      postId
      account {
        id
        screen_name
      }

    }
  }
`;

const GQL_ADD_MEDIA = gql`
mutation ($id: ID!, $media: Json!) {
  addMediaToPost(postId: $id, media: $media) {
    id
    media
  }
}
`;

const ADD_JOB = gql`
  mutation addJobToPost(
    $postId: ID!,
    $job: Json!
  ) {
    addJobToPost(
      job: $job,
      postId: $postId
    ) {
      id
    }
  }
`;

const getSecondaryIcon = (data) => {
  if (data) {
    if (data.loading) {
      return <CircularProgress size={24} />;
    } if (data.success) {
      return <Check />;
    } if (data.message) {
      return <AlertCircle />;
    }
    return null;
  }
  return null;
};

class Sharer extends Component {
  state = {
    success: false,
    loading: false,
    loadingData: {},
    redirect: false,
  }

  async uploadTwitterMedia(access_token, access_token_secret, account, media) {
    const endpoint_body_data = {
      access_token_key: access_token, access_token_secret, media, account,
    };
    const endpoint_data = new FormData();
    endpoint_data.append('body', JSON.stringify(endpoint_body_data));

    const backend_endpoint = 'https://api.scoreshots.com/v1/Twitter/upload-twitter-media/prod/';

    return new Promise((resolve, reject) => {
      fetch(backend_endpoint, {
        // credentials : 'include',
        method: 'POST',
        body: endpoint_data,
        mode: 'cors',
      }).then((response) => response.json()).then((json) => {
        resolve(json);
      }).catch((e) => {
        reject(e);
      });
    });
  }

  async scheduleTweetAPI(tweet_data, timestamp, endpoint = 'dev') {
    const endpoint_tweet_data = { timestamp: moment(timestamp).unix(), mode: endpoint, tweet: tweet_data };
    const endpoint_data = new FormData();
    endpoint_data.append('body', JSON.stringify(endpoint_tweet_data));

    const backend_endpoint = `https://api.scoreshots.com/v1/__Twitter_Scheduling__c/schedule/${endpoint}/`;
    fetch(backend_endpoint, {
      // credentials : 'include',
      method: 'POST',
      body: endpoint_data,
      mode: 'cors',
    }).then((response) => response.json()).then((json) => json);
  }

  getMessage(acct, placeholder) {
    let message = '';
    if (this.state.loadingData[acct.id]) {
      const load = this.state.loadingData[acct.id];
      if (load.loading) {
        return 'Loading';
      }
      if (!load.success) {
        return (
          <span style={{ color: 'red' }}>{load.message}</span>
        );
      } if (load.success && load.message) {
        return (
          <span style={{ color: 'green' }}>{load.message}</span>
        );
      }
      if (acct.fbId) {
        message = 'facebook';
      } else {
        message = 'twitter';
      }
      return (
        <a
          href={load.url ? load.url : 'https://google.com'}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'green' }}
        >
            view on
          {' '}
          {message}
        </a>
      );
    }
    return placeholder;
  }

  getFacebooks() {
    const output = [];
    this.props.fbPages.forEach((page) => {
      output.push((
        <ListItem
          key={page.id}
          dense
        >
          <ListItemAvatar>
            <Avatar src={`https://graph.facebook.com/v2.12/${page.fbId}/picture?height=40`} />
          </ListItemAvatar>
          <ListItemText
            primary={page.name}
            secondary={this.getMessage(page, 'facebook')}
          />
          <ListItemSecondaryAction style={{ marginTop: -12 }}>
            {getSecondaryIcon(this.state.loadingData[page.id])}
          </ListItemSecondaryAction>
        </ListItem>));
    });
    return output;
  }

  getTwitters() {
    const output = [];
    const account = this.props.twitterAccount;
    if (account) {
      output.push((
        <ListItem
          key={account.id}
          dense
        >
          <ListItemAvatar>
            <Avatar src={`https://twitter-avatar.now.sh/${account.screen_name}`} />
          </ListItemAvatar>
          <ListItemText
            primary={account.name}
            secondary={this.getMessage(account, 'twitter (post)')}
          />
          <ListItemSecondaryAction style={{ marginTop: -12 }}>
            {getSecondaryIcon(this.state.loadingData[account.id])}
          </ListItemSecondaryAction>
        </ListItem>));
      this.props.twitterAccounts.forEach((eachAccount) => {
        output.push((
          <ListItem
            key={eachAccount.id}
            dense
          >
            <ListItemAvatar>
              <Avatar src={`https://twitter-avatar.now.sh/${eachAccount.screen_name}`} />
            </ListItemAvatar>
            <ListItemText
              primary={eachAccount.name}
              secondary={this.getMessage(eachAccount, 'twitter (retweet)')}
            />
            <ListItemSecondaryAction style={{ marginTop: -12 }}>
              {getSecondaryIcon(this.state.loadingData[eachAccount.id])}
            </ListItemSecondaryAction>
          </ListItem>));
      });
    }
    return output;
  }

  getQuickCreateIcons(array){
    return array.map(value => {
      if(value.__typename === 'FacebookPage'){
        return (
            <ListItem
              key={value.id}
              dense
            >
              <ListItemAvatar>
                <Avatar src={`https://graph.facebook.com/v2.12/${value.fbId}/picture?height=40`} />
              </ListItemAvatar>
              <ListItemText
                  primary={value.name}
                  secondary={this.getMessage(value, 'facebook')}
              />
            </ListItem>
        )
      }else {
        return (
            <ListItem
                key={value.id}
                dense
            >
              <ListItemAvatar>
                <Avatar src={`https://twitter-avatar.now.sh/${value.screen_name}`} />
              </ListItemAvatar>
              <ListItemText
                  primary={value.name}
                  secondary={this.getMessage(value, 'twitter (retweet)')}
              />
            </ListItem>
        )
      }
    })
  }
  async postFacebook(page, media, postId, addPost, postLater, message = null) {
    const { loadingData } = this.state;
    if (loadingData[page.id]) {
      loadingData[page.id].loading = true;
    } else {
      loadingData[page.id] = {
        loading: true,
        message: null,
        success: false,
      };
    }
    this.setState({
      loadingData,
    });
    let id = null;
    if (!postLater) {
      if (!media.mp4) {
        const url = media.pngSize < 1048576 ? media.png : media.jpg;
        const photo = await Facebook.postPhoto(page.accessToken, page.fbId, url, message);
        // ({ id } = photo);
        loadingData[page.id].loading = false;
        loadingData[page.id].success = true;
        loadingData[page.id].url = photo.link;
        id = photo.id;
      } else {
        // video time.
        const video = await Facebook.postVideo(page.accessToken, page.fbId, media.mp4, message);
        loadingData[page.id].loading = false;
        loadingData[page.id].success = true;
        loadingData[page.id].message = 'Video Processing';
        id = video.id;
      }
    } else if (!media.mp4) {
      const url = media.pngSize < 1048576 ? media.png : media.jpg;
      const this_fb_id = await Facebook.schedulePost(page.accessToken, page.fbId, url, null, page.selectedDate, message, 'photos');
      id = this_fb_id.id;
      loadingData[page.id].loading = false;
      loadingData[page.id].success = true;
      loadingData[page.id].message = 'Processing';
    } else {
      const this_fb_id = await Facebook.schedulePost(page.accessToken, page.fbId, null, media.mp4, page.selectedDate, message, 'videos');
      id = this_fb_id.id;
      loadingData[page.id].loading = false;
      loadingData[page.id].success = true;
      loadingData[page.id].message = 'Processing';
    }

    await addPost({
      variables: {
        media: JSON.stringify(media),
        fbId: page.fbId,
        parentPostId: postId,
        postId: id,
        message,
      },
    });
    this.setState({
      loadingData,
    });
  }

  async postTwitter(account, media, postId, addPost, message = null) {
    const { loadingData } = this.state;
    if (loadingData[account.id]) {
      loadingData[account.id].loading = true;
    } else {
      loadingData[account.id] = {
        loading: true,
        message: null,
        success: false,
      };
    }
    this.setState({
      loadingData,
    });

    const media_upload = await this.uploadTwitterMedia(account.oauthToken, account.oauthTokenSecret, account.twitterId, media);
    let post = await addPost({
      variables: {
        media: JSON.stringify({ id: media_upload.media_id.media_id_string }),
        twitterId: account.twitterId,
        parentPostId: postId,
        retweet: false,
        message,
      },
    });
    post = post.data.addTwitterToPost;
    loadingData[account.id].loading = false;
    loadingData[account.id].success = true;
    loadingData[account.id].url = `https://twitter.com/${post.account.screen_name}/status/${post.postId}`;
    this.setState({
      loadingData,
    });
  }

  async retweetTwitter(account, media, postId, addPost) {
    const { loadingData } = this.state;
    if (loadingData[account.id]) {
      loadingData[account.id].loading = true;
    } else {
      loadingData[account.id] = {
        loading: true,
        message: null,
        success: false,
      };
    }
    this.setState({
      loadingData,
    });
    let post = await addPost({
      variables: {
        media: JSON.stringify(media),
        twitterId: account.twitterId,
        parentPostId: postId,
        retweet: true,
      },
    });
    post = post.data.addTwitterToPost;
    loadingData[account.id].loading = false;
    loadingData[account.id].success = true;
    loadingData[account.id].url = `https://twitter.com/${post.account.screen_name}/status/${post.postId}`;
    this.setState({
      loadingData,
    });
  }

  post = (createPost, addTwitterPost, addFacebookPost, addJob, add_media) => async () => {
    const {
      clientId,
      selectedDate,
      postLater,
      slideState,
      json,
    } = this.props;
    let quickCreate = false;
    if (this.props.quickCreate) {
      quickCreate = this.props.quickCreate;
    }
    if (quickCreate === false || quickCreate === null) {
      const {
        clientId,
        fbPages,
        twitterAccounts,
        twitterAccount,
        image,
        selectedDate,
        templateId,
        videoURL,
        CanvasUtil,
        width,
        height,
        postLater,
        uniformMessage,
        uniformTextMessage,
        textMessages,
      } = this.props;

      this.setState({
        loading: true,
      });

      // graph query to create the post.
      let final_selected_date = selectedDate;
      if (!postLater) {
        final_selected_date = moment().format();
      }
      const response = await createPost({
        variables: {
          scheduled: postLater,
          templateId,
          width,
          height,
          postTime: final_selected_date,
          bucket,
        },
        refetchQueries: [
          'getPosts',
        ],
      });
      const postId = response.data.createPost.id;

      // upload to posts bucket using above id.
      const pngRes = await fetch(image.png);
      const blobPreviewPNG = await pngRes.blob();
      const jpgRes = await fetch(image.jpg);
      const blobPreviewJPG = await jpgRes.blob();
      const mp4Url = ((this.props.slideshow || this.props.video) ? this.props.video : videoURL);


      const params = {
        Body: blobPreviewPNG,
        Bucket: bucket,
        Key: `${postId}.png`,
        ACL: 'public-read',
      };
      const action = new ScoreshotsPut(bucket);
      await action.put(params.Body, 'image/png', null, params.Key);
      params.Body = blobPreviewJPG;
      params.Key = `${postId}.jpg`;
      await action.put(params.Body, 'image/jpg', null, params.Key);
      // put post json to posts folder.
      const blobJSON = (this.props.slideshow ? this.props.json : CanvasUtil.toJSON());
      const paramsJSON = {
        Body: new Blob([JSON.stringify(blobJSON)], { type: 'application/json' }),
        Bucket: bucket,
        Key: `${postId}.json`,
        ACL: 'public-read',
      };
      await action.put(paramsJSON.Body, 'application/json', null, paramsJSON.Key);

      // create media object.
      const media = {
        type: 'image',
        jpg: `https://${bucket}.s3.us-east-2.amazonaws.com/${clientId}/${postId}.jpg`,
        jpgSize: blobPreviewJPG.size,
        png: `https://${bucket}.s3.us-east-2.amazonaws.com/${clientId}/${postId}.png`,
        pngSize: blobPreviewPNG.size,
        mp4: mp4Url,
      };

      // communicate to intercom that the user has posted.
      window.Intercom('trackEvent', 'make-post', { scheduled: postLater, media });

      await add_media({
        variables: {
          id: postId,
          media: {
            jpg: media.jpg,
            png: media.png,
            mp4: media.mp4,
          },
        },
      });

      if (!postLater) {
        const tasks = [];

        for (let i = 0; i < fbPages.length; i += 1) {
          const page = fbPages[i];
          let message = '';
          if (uniformMessage) {
            message = uniformTextMessage;
          } else if (textMessages.facebook && textMessages.facebook[page.fbId]) {
            message = textMessages.facebook[page.fbId];
          }
          tasks.push(this.postFacebook(page, media, postId, addFacebookPost, postLater, message));
        }

        if (twitterAccount) {
          let message = '';
          if (uniformMessage) {
            message = uniformTextMessage;
          } else if (textMessages.twitter && textMessages.twitter[twitterAccount.twitterId]) {
            message = textMessages.twitter[twitterAccount.twitterId];
          }
          tasks.push(this.postTwitter(
            twitterAccount,
            media,
            postId,
            addTwitterPost,
            message,
          ));
        }
        await Promise.all(tasks);
        const retweetTasks = [];
        for (let i = 0; i < twitterAccounts.length; i += 1) {
          const account = twitterAccounts[i];
          retweetTasks.push(this.retweetTwitter(account, media, postId, addTwitterPost));
        }
        await Promise.all(retweetTasks);
      } else {
        let message = '';
        if (twitterAccount) {
          if (uniformMessage) {
            message = uniformTextMessage;
          } else if (textMessages.twitter && textMessages.twitter[twitterAccount.twitterId]) {
            message = textMessages.twitter[twitterAccount.twitterId];
          }
        }

        const job = {
          media,
          fbPages,
          twitterAccount,
          twitterRetweet: twitterAccounts,
          message,
        };
        await addJob({
          variables: {
            postId,
            job,
          },
        })

        const tasks = [];

        for (let i = 0; i < fbPages.length; i += 1) {
          const page = fbPages[i];
          message = '';
          if (uniformMessage) {
            message = uniformTextMessage;
          } else if (textMessages.facebook && textMessages.facebook[page.fbId]) {
            message = textMessages.facebook[page.fbId];
          }


          const page_ret = JSON.parse(JSON.stringify(page));
          page_ret.selectedDate = selectedDate;

          tasks.push(this.postFacebook(page_ret, media, postId, addFacebookPost, postLater, message));
        }


        if (twitterAccount) {
          message = '';
          if (uniformMessage) {
            message = uniformTextMessage;
          } else if (textMessages.twitter && textMessages.twitter[twitterAccount.twitterId]) {
            message = textMessages.twitter[twitterAccount.twitterId];
          }


          const schedule_data = {
            media: JSON.stringify(media),
            twitterId: twitterAccount.twitterId,
            parentPostId: postId,
            retweet: false,
            message,
          };

          tasks.push(this.scheduleTweetAPI(schedule_data, selectedDate));
        }


        await Promise.all(tasks);
        const retweetTasks = [];
        for (let i = 0; i < twitterAccounts.length; i += 1) {
          const account = twitterAccounts[i];
          // retweetTasks.push(this.retweetTwitter(account, media, postId, addTwitterPost));


          const schedule_retweet_data = {
            media: JSON.stringify(media),
            twitterId: account.twitterId,
            parentPostId: postId,
            retweet: true,
          };

          retweetTasks.push(this.scheduleTweetAPI(schedule_retweet_data, selectedDate));
        }
        await Promise.all(retweetTasks);
      }

      this.setState({
        success: true,
        redirect: postLater,
      }, () => {
        window.removeEventListener('beforeunload', EditorUnloadCallback);
      });
      // eslint-disable-next-line no-empty
    } else {
      this.setState({
        loading: true,
      });

      // graph query to create the post.
      let final_selected_date = selectedDate;
      if (!postLater) {
        final_selected_date = moment().format();
      }

      for (let i = 0;i < slideState.length;i++) {
        const slide = slideState[i]
        const selectedSocialsFiltered = slide.selectedSocial.filter(social => social !== 'All Templates')
        if(selectedSocialsFiltered.length === 0) {
          continue;
        }
        else {
          const img = json.data[i].preview
          const templateId = json.slides[i]
          const width = json.data[i].template.data.template.width
          const height = json.data[i].template.data.template.height

          const response = await createPost({
            variables: {
              scheduled: postLater,
              templateId,
              width,
              height,
              postTime: final_selected_date,
              bucket,
            },
            refetchQueries: [
              'getPosts',
            ],
          });

          const postId = response.data.createPost.id;

          // upload to posts bucket using above id.
          const pngRes = await fetch(img.png);
          const blobPreviewPNG = await pngRes.blob();
          const jpgRes = await fetch(img.jpg);
          const blobPreviewJPG = await jpgRes.blob();

          const params = {
            Body: blobPreviewPNG,
            Bucket: bucket,
            Key: `${postId}.png`,
            ACL: 'public-read',
          };

          const action = new ScoreshotsPut(bucket);
          await action.put(params.Body, 'image/png', null, params.Key);
          params.Body = blobPreviewJPG;
          params.Key = `${postId}.jpg`;
          await action.put(params.Body, 'image/jpg', null, params.Key);
          // put post json to posts folder.
          const blobJSON = (this.props.json);
          const paramsJSON = {
            Body: new Blob([JSON.stringify(blobJSON)], { type: 'application/json' }),
            Bucket: bucket,
            Key: `${postId}.json`,
            ACL: 'public-read',
          };
          await action.put(paramsJSON.Body, 'application/json', null, paramsJSON.Key);

          // create media object.
          const media = {
            type: 'image',
            jpg: `https://${bucket}.s3.us-east-2.amazonaws.com/${clientId}/${postId}.jpg`,
            jpgSize: blobPreviewJPG.size,
            png: `https://${bucket}.s3.us-east-2.amazonaws.com/${clientId}/${postId}.png`,
            pngSize: blobPreviewPNG.size,
          };

          window.Intercom('trackEvent', 'make-post', { scheduled: postLater, media });

          await add_media({
            variables: {
              id: postId,
              media: {
                jpg: media.jpg,
                png: media.png,
              },
            },
          });

          const tasks = [];
          let twitterAccounts = 0
          for(let a = 0;a<selectedSocialsFiltered.length;a++){
            const social = selectedSocialsFiltered[a]

            if(social.__typename === 'TwitterAccount' && twitterAccounts === 0){


              let  message = slide.captionText;

              tasks.push(this.postTwitter(
                  social,
                  media,
                  postId,
                  addTwitterPost,
                  message,
              ));

              twitterAccounts += 1
            }
            else if(social.__typename === 'FacebookPage') {

              let message = slide.captionText;
              tasks.push(this.postFacebook(social, media, postId, addFacebookPost, postLater, message));
            }
          }

          await Promise.all(tasks);
          const retweetsPromises = []
          if(twitterAccounts > 0 ){
            const twitterAccounts = selectedSocialsFiltered.filter(social => social.__typename === 'TwitterAccount')
            for(let c = 0;c<twitterAccounts.length;c++)
              retweetsPromises.push(this.retweetTwitter(twitterAccounts[c], media, postId, addTwitterPost))
          }

          await Promise.all(retweetsPromises)
        }
      }
      this.setState({
        success: true,
        redirect: postLater,
      }, () => {
        window.removeEventListener('beforeunload', EditorUnloadCallback);
      })
    }
  }

  renderRedirect = (redirect) => {
    if (redirect) {
      return <Redirect to="/create/scheduled" />;
    }
    return null;
  }

  render() {
    const {slideState, quickCreate} = this.props
    let socials = []
    if(quickCreate){

      slideState.map(state => state.selectedSocial).reduce((prev,next)=>{
           return prev.concat(next)
        }).filter(value => value !== 'All Templates').forEach(value => {
        if(socials.filter(el => el.id === value.id).length === 0)
          socials.push(value)
      })
    }


    return (
      <>
        {this.renderRedirect(this.state.redirect)}
        <Typography variant="h6">
          Sharing To:
        </Typography>
        <List
          style={{
            maxWidth: 350,
          }}
        >
          {
            quickCreate ? (
                <>
                  {this.getQuickCreateIcons(socials)}
                </>
            ) : (
                <>
                  {this.getTwitters()}
                  {this.getFacebooks()}
                </>
            )

          }
        </List>
        <Button
          variant="contained"
          color="primary"
          size="large"
          disabled={this.state.loading}
          onClick={this.props.onPrev}
          style={{ marginRight: 10 }}
        >
          Back
        </Button>
        <Mutation mutation={GQL_ADD_MEDIA}>
          {(add_media) => (
            <Mutation mutation={ADD_JOB}>
              {(addJob) => (
                <Mutation mutation={ADD_FACEBOOK_POST}>
                  {(addFacebookPost) => (
                    <Mutation mutation={ADD_TWITTER_POST}>
                      {(addTwitterPost) => (
                        <Mutation mutation={POST}>
                          {(createPost) => (
                            this.state.success
                              ? (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="large"
                                  component={Link}
                                  to="/create"
                                >
                              Return to Dashboard
                                </Button>
                              )
                              : (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="large"
                                  onClick={
                                this.post(
                                  createPost,
                                  addTwitterPost,
                                  addFacebookPost,
                                  addJob,
                                  add_media,
                                )
                              }
                                  disabled={this.state.loading}
                                >
                                  {this.state.loading ? 'Sharing' : 'Share'}
                                </Button>
                              )
                          )}
                        </Mutation>
                      )}
                    </Mutation>
                  )}
                </Mutation>
              )}
            </Mutation>
          )}
        </Mutation>
        <div style={{ height: 72 }} />
      </>
    );
  }
}

export default Sharer;
