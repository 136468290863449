
// -------------------------------------------------------------------------- //

import React from 'react';

// -------------------------------------------------------------------------- //

export const ClientSearchContext = React.createContext();

// -------------------------------------------------------------------------- //
