import React from 'react';
import {compose, graphql, Query} from 'react-apollo';
import gql from 'graphql-tag';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TemplatePreview } from './TemplatePreview';
import {withRouter} from "react-router-dom";

// -------------------------------------------------------------------------- //

const GET_TEMPLATES = gql`
  query {
    templateCreateSearch (
      where: {
        customDetails: null
        visible: VISIBLE
        isSlide : false
      }
      orderBy: createdAt_DESC
      first: 10
    ) {
      id
      image
      isSlideShow
      isQuickCreate
      categories {
        id
      }
    }
  }
`;

const ROLES_QUERY = gql`
  query {
    me {
      id
      role {
        id
        name
        restrictCategoryFlag
        categories {
          id
          name
        }
        allowCustomFlag
        allowCustomSaveFlag
        allowCustomFolderSaveFlag
        restrictCustomFlag
        customFolders {
          id
          name
        }
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  root: {
    display: 'flex',
    // height: 148,
    // padding: 12,
    width: '100%',
    overflowX: 'hidden',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      // height: 300,
    },
  },
});

const NewTemplates = (props) => {
  const { classes } = props;
  const { me } = props.rolesQuery;
  let restrictCategoryFlag = false;
  const allowedCategories = [];
  let showTemplate = false;
  if (me && me.role && me.role.categories) {
    me.role.categories
      .forEach((cat) => {
        allowedCategories.push(cat.id);
      });
    restrictCategoryFlag = me.role.restrictCategoryFlag;
  }

  if (restrictCategoryFlag && allowedCategories.length === 0) {
    return null;
  } else {
    return (
      <React.Fragment>
        <Typography variant="h5" component="h2" style={{ marginTop: 32, marginBottom: 18 }}>
          Newest Templates
        </Typography>
        <div className={classes.root}>
          <Query query={GET_TEMPLATES}>
            {({data}) => (
              data.templateCreateSearch ?
                data.templateCreateSearch.map((template) => {
                  if (restrictCategoryFlag) {
                    showTemplate = false;

                    if (template.categories) {
                      template.categories
                        .forEach((cat) => {
                          if (allowedCategories.includes(cat.id)) {
                            showTemplate = true;
                          }
                        });
                    }

                    if (showTemplate) {
                      return (
                        <TemplatePreview
                          style={{height: 160}}
                          key={template.id}
                          template={template}
                        />
                      );
                    }

                    return null;
                  }

                  return (
                    <TemplatePreview
                      key={template.id}
                      template={template}
                      isSlideShow={template.isSlideShow}
                    />
                  );
                }) :
                <CircularProgress style={{padding: 8}}/>
            )}
          </Query>
        </div>
      </React.Fragment>
    );
  }
};

export default compose(
  graphql(ROLES_QUERY, { name: 'rolesQuery' }),
  withRouter,
  withStyles(STYLES),
)(NewTemplates);
