
// -------------------------------------------------------------------------- //

import * as React from 'react';

import {
  AppBar,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';

import { withRouter } from 'react-router';
import { compose, graphql, Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import TemplateGrid from './TemplateGrid';

// -------------------------------------------------------------------------- //

const CREATE_TEMPLATE = gql`
  mutation ($width: Int, $height: Int, $name: String!, $visibility: TEMPLATE_VISIBILITY) {
    updateTemplate(
      name: $name
      width: $width
      height: $height
      visible: $visibility
    ) {
      id
    }
  }
`;

// -------------------------------------------------------------------------- //

const GET_SPORTS_AND_CATEGORIES = gql`
  query {
    categories {
      id,
      name
    }
    types {
      id,
      name
    }
  }
`;

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  app_bar: {
    position: 'relative',
    flex: 0,
  },
  button_progress: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
  },
  filter_select: {
    width: theme.spacing(20),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  flex: {
    flex: 1,
  },
  root: {
    flex: 1,
    overflow: 'auto',
  },
});

// -------------------------------------------------------------------------- //

class Templates extends React.Component {

  state = {
    loading: true,
    error: false,
    sports: [],
    categories: [],
    sport_filter: 0,
    category_filter: 0,
    show_disabled: false,
    mutation_busy: false,
  }

  componentDidMount() {
    const { fetch } = this.props;

    fetch.refetch().then(response => {
      const { data } = response;

      if (data === null) {
        this.setState({ loading: false, error: true });
        return;
      }
  
      const sports = data.categories.map(category => ({
        name: category.name,
        value: category.id,
      }));

      const categories = data.types.map(type => ({
        name: type.name,
        value: type.id,
      }));

      this.setState({
        sports,
        categories,
        loading: false,
      });
    });
  }

  renderToolbar = () => {
    const { classes, premium, widgets } = this.props;
    const { mutation_busy, sports, sport_filter, categories, category_filter } = this.state;
    let title = '';

    if (premium) {
      title = 'Premium Templates';
    } else if (widgets) {
      title = 'Widgets';
    } else {
      title = 'Templates';
    }

    return (
      <AppBar color="primary" elevation={0} className={classes.app_bar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            {title}
          </Typography>
          <FormControl className={classes.filter_select}>
            <InputLabel style={{ color: 'inherit' }} htmlFor="sport-filter">
              Sports
            </InputLabel>
            <Select
              value={sport_filter}
              style={{ color: 'inherit' }}
              onChange={(e) => this.setState({ sport_filter : e.target.value})}
              inputProps={{
                name: 'sport',
                id: 'sport-filter',
              }}
            >
              <MenuItem value={0}>All</MenuItem>
              {sports.map(sport => (
                <MenuItem key={sport.value} value={sport.value}>
                  {sport.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.filter_select}>
            <InputLabel style={{ color: 'inherit' }} htmlFor="category-filter">
              Categories
            </InputLabel>
            <Select
              value={category_filter}
              style={{ color: 'inherit' }}
              onChange={e => this.setState({ category_filter: e.target.value })}
              inputProps={{
                name: 'category',
                id: 'category-filter',
              }}
            >
              <MenuItem value={0}>All</MenuItem>
              {categories.map(category => (
                <MenuItem key={category.value} value={category.value}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Mutation mutation={CREATE_TEMPLATE}>
          {(mutation) => (
            <Button
              color="inherit"
              disabled={mutation_busy}
              onClick={() => this.createTemplate(mutation)}
            >
              Create
              {
                mutation_busy &&
                <div className={classes.button_progress}>
                  <CircularProgress size={48}/>
                </div>
              }
            </Button>
          )}
          </Mutation>
        </Toolbar>
      </AppBar>
    );
  }

  renderTemplateGrid = () => {
    const { premium, widgets } = this.props;
    const { sport_filter, category_filter } = this.state;

    return (
      <TemplateGrid
        isWidget={widgets}
        isPremium={premium}
        catFilter={sport_filter}
        typeFilter={category_filter}
      />
    );
  }

  createTemplate = (mutation) => {
    const { history, widgets } = this.props;
    this.setState({ mutation_busy: true }, () => {
      mutation({ variables: {
        name: 'New Template',
        width: 1024,
        height: 1024,
        visibility: (widgets ? 'WIDGET' : null),
      }}).then(response => {
        history.push(`/admin/templates/${response.data.updateTemplate.id}`);
      });
    });
  }



  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        {this.renderToolbar()}
        <div className={classes.root}>
          {this.renderTemplateGrid()}
        </div>
      </React.Fragment>
    );
  }

}





// -------------------------------------------------------------------------- //

export default compose(
  graphql(GET_SPORTS_AND_CATEGORIES, { name: 'fetch' }),
  withStyles(STYLES),
  withRouter,
)(Templates);

// -------------------------------------------------------------------------- //
