
import * as React from 'react';

import {
  Redirect,
  Route,
  Switch
} from 'react-router-dom';

import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import Main from './Main';
import CanvasEditor from './CanvasEditor';
import CanvasEditor2 from './CanvasEditor';
import FullscreenLoader from '../loading/FullscreenLoader';

const GET_USER_INFO = gql`
  query getUserInfo {
    me {
      id
      client {
        id
        type
      }
    }
  }
`;

export default () => (
  <Query query={GET_USER_INFO} fetchPolicy="network-only">
  {({ loading, error, data }) => {
    if (loading) {
      return <FullscreenLoader />;
    }

    if (error || !(data && data.me && data.me.client && data.me.client.type === 'ADMIN')) {
      return <Redirect to="/" />;
    }

    return (
      <Switch>
        <Route exact path="/admin/templates/editor/:template" component={CanvasEditor} />
        <Route exact path="/admin/templates/editor/:template/:s3slideshowid" component={CanvasEditor2} />
        <Route component={Main} />
      </Switch>
    );
  }}
  </Query>
);
