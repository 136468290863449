import React from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

export default ({
  input: {
    name, onChange, value, ...restInput
  },
  meta,
  className,
  fullWidth = true,
  type = 'text',
  ...rest
}) => {
  const style = {};
  if (!fullWidth) {
    style.width = '50%';
  }
  return (
    <FormControl fullWidth={fullWidth} style={style} className={className}>
      <TextField
        {...rest}
        name={name}
        helperText={meta.touched ? meta.error : undefined}
        error={meta.error && meta.touched}
        inputProps={restInput}
        onChange={onChange}
        value={value}
        type={type}
      />
    </FormControl>
  );
};
