import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { compose } from "react-apollo";

const styles = theme => ({
  controls: {
    position: 'sticky',
    top: 80,
  },
  activeLink: {
    backgroundColor: '#EEE',
  },
  activeIcon: {
    color: theme.palette.secondary.dark,
  },
  listItem: {
    whiteSpace: 'nowrap',
  },
});

const ResourcesNav = (props) => {
  const { classes } = props;
  return (
    <div
      variant="permanent"
      className={classes.controls}
    >
      <List>
      <ListItem
          button
          dense
          className={classes.listItem}
          component="a"
          href="#FAQ"
        >
          <ListItemText primary="FAQ" />
          </ListItem>
        <ListItem
          button
          dense
          className={classes.listItem}
          component="a"
          href="#video"
        >
          <ListItemText primary="Video Tutorials" />
        </ListItem>
      </List>
    </div>
  );
};

export default compose(
  withStyles(styles),
)(ResourcesNav);
