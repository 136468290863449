import * as React from 'react';
import {DotsVertical} from 'mdi-material-ui'; 
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Divider,
  Menu,
  IconButton,
  TableHead,
  MenuItem,
  Paper,
  Table,
  CircularProgress,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  TablePagination,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';

import { graphql, compose} from 'react-apollo';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';

const CREATE_TAG = gql`
  mutation createTag($name: String!){
    createSystemTag(name: $name){
      name
    }
  }
`

const GET_TAGS = gql`
  query{
    tags{
      name
      id
      templates{
        name
      }
    }
  }
`
const DELETE_TAG = gql`
  mutation deleteTag($id: ID!){
    deleteSystemTag(id: $id){
      id
    }
  }
`

const UPDATE_TAG = gql`
  mutation updateTag($id: ID!,$name: String!){
    editSystemTag(
      id: $id,
      name: $name
    ){
      name
    }
  }
`

const STYLES = (theme) => ({
  app_bar: {
    flex: 0,
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  root: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(3),
  },
  tableRow:{
    cursor: 'pointer'
  },
  heading: {
    flexGrow: 1,
  }
});

class TagManager extends React.Component{

  state={
    create_dialog: false,
    newTagName: '',
    optionsAnchor: null,
    options: false,
    tagId: null,
    update_dialog: false,
    currentTagName: '',
    dialogError: false,
    dialogErrorText: '',
    delete_confirmation_dialog: false,
    rowsPerPage: 10,
    page: 0,
    currentPageTags: [],
    loading: false
  }

  openCreateDialog = () => {
    this.setState({ create_dialog: true });
  }

  closeCreateDialog = () => {
    this.setState({ create_dialog: false });
  }

  componentDidUpdate(){
    const {getTags} = this.props
    
    if(getTags.tags){
      
      let sortedTags = getTags.tags.sort((t1,t2) => {
        if(t1.name > t2.name){
          return 1
        }if(t2.name > t1.name){
          return -1
        }
        return 0
      }).slice(
        this.state.page * this.state.rowsPerPage, (this.state.page + 1) * this.state.rowsPerPage)

      if(!this.arrayComparer(this.state.currentPageTags,sortedTags)){
        
        this.setState({currentPageTags: [...sortedTags]})
      }
    }
  }

  /**
   * 
   * @param {*} Array A
   * @param {*} Array B
   * @returns true if the arrays are equal and false if they are not.
   */
  arrayComparer = (arr1,arr2) => {

    if(arr1 === undefined || arr2 === undefined) return false

    if(arr1.length !== arr2.length) return false
    
    for(let i = 0;i<arr1.length;i++){
      if(arr1[i].name !== arr2[i].name ){
        return false
      }
    }

    return true
  }
  renderToolbar = () => {
    const { classes } = this.props;

    return (
      <AppBar color="primary" elevation={0} className={classes.app_bar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Tag Manager
          </Typography>
          <Button color="inherit" onClick={this.openCreateDialog}>
            Create
          </Button>
        </Toolbar>
      </AppBar>
    );
  }

  renderCreateDialog = () => {
    return(
      <Dialog
      open={this.state.create_dialog}
      close={this.closeCreateDialog}
      onClose={this.closeCreateDialog}>
        <DialogTitle>
          Add Tag
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Create a new Tag
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            error={this.state.dialogError}
            helperText={this.state.dialogErrorText}
            id="tag"
            label="Tag Name"
            value={this.state.newTagName}
            onChange={e => this.setState({newTagName: e.target.value})}
            fullWidth
          />
          <DialogActions>
            <Button onClick={this.closeCreateDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={() => this.create()} color="primary">
              {this.state.loading? <div><CircularProgress size={32}/></div> : <>Create</>}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    )
  }

  closeUpdateDialog = () => {
    this.setState({update_dialog: false,currentTagName: ''})
    this.closeOptions()
  }

  renderUpdateDialog = () => {
    return(
      <Dialog
      open={this.state.update_dialog}
      close={this.closeUpdateDialog}
      onClose={this.closeUpdateDialog}>
        <DialogTitle>
          Update Tag Dialog
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
             Update {this.state.currentTagName}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            error={this.state.dialogError}
            helperText={this.state.dialogErrorText}
            id="tag"
            label="Tag Name"
            value={this.state.currentTagName}
            onChange={e => this.setState({currentTagName: e.target.value})}
            fullWidth
          />
          <DialogActions>
            <Button onClick={this.closeUpdateDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={() => this.update()} color="primary">
              {this.state.loading? <div><CircularProgress size={32}/></div> : <>Update</>}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    )
  }

  update = () => {
    const {getTags} = this.props
    this.setState({loading: true})
    let tagName = this.state.currentTagName.toLowerCase()
    const tagNames = getTags.tags.map(el => el.name)

    if(this.validation(tagName)){
      if(tagNames.includes(tagName)){
        
        this.setState({dialogError: true,dialogErrorText: 'tag already exist',loading: false})
  
      }else{
        this.props.updateTag({
          variables: {
            id: this.state.tagId,
            name: tagName
          }
        }).then(() => {
    
          getTags.refetch().then(() => {
            this.forceUpdate()
          })
          this.setState({dialogError: false,dialogErrorText: '',loading: false})
          this.closeUpdateDialog()
          this.closeOptions()
        })
      }
    }else{
      this.setState({dialogError: true,dialogErrorText: 'The value provided for the tag name does not meet the length required ',loading:false})
    }

  }

  closeDeleteConfirmationDialog = () => {
    this.setState({delete_confirmation_dialog: false,options: false})
  }
  renderRemoveDialog(){
    return(
        <Dialog
        open={this.state.delete_confirmation_dialog}
        close={this.closeDeleteConfirmationDialog}
        onClose={this.closeDeleteConfirmationDialog}>
          <DialogTitle>
            Delete Tag Dialog
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
               Are you sure you want to delete {this.state.currentTagName}?
            </DialogContentText>
            <DialogActions>
              <Button onClick={this.closeDeleteConfirmationDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={() => this.deleteTag(this.state.tagId)} color="primary">
                {this.state.loading? <div><CircularProgress size={32}/></div> : <>Confirm</>}
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
    )
  }

  create = () => {
    this.setState({loading: true})
    let tagName = this.state.newTagName
    const {getTags} = this.props
    const tagNames = getTags.tags.map(el => el.name)
    if(this.validation(tagName)){
      if(tagNames.includes(tagName.toLowerCase())){
        this.setState({dialogError: true,dialogErrorText: 'tag already exist',loading:false})
      }else{
  
        this.props.createSystemTag({
          variables: {
            name: tagName.toLowerCase()
          }
        }).then(() => {
          this.setState({dialogError: false,dialogErrorText: '',newTagName: '',loading: false})
          this.closeCreateDialog()
          this.props.getTags.refetch().then(() => {
  
            this.forceUpdate()
          })
          
        }).catch( err => {
    
          this.closeCreateDialog()
          this.setState({loading: false})
        })
      }
    }else{
      this.setState({dialogError: true,dialogErrorText: 'The value provided for the tag name does not meet the length required ',loading:false})
    }
  }

  openOptions = (e,tag) => {
    this.setState({options: true,optionsAnchor: e,tagId: tag.id,currentTagName: tag.name})
  }
  closeOptions = () => {
    this.setState({options:false,optionsAnchor: null})
  }

  deleteTag = (id) => {
    this.setState({loading: true})
    this.props.deleteSystemTag({
      variables: {
        id: id
      }
    }).then(() => {
      this.closeOptions()
      this.setState({loading: false})
      this.closeDeleteConfirmationDialog()
      this.props.getTags.refetch().then(() => {

        this.forceUpdate()
      })
    }).catch(() => {
      this.setState({loading: false})
    })
  }
  renderOptionsMenu = () => {
    
    return(
        <Menu
          id="long-menu"
          anchorEl={this.state.optionsAnchor}
          open={this.state.options}
          onClose={this.closeOptions}  
        >
          <MenuItem onClick={() => this.setState({delete_confirmation_dialog: true})}>
             Remove
          </MenuItem>
          <MenuItem onClick={() => this.setState({update_dialog: true})}>
             Edit
          </MenuItem>
        </Menu>
    )
  }

  validation = str => {
    return str.length > 0? true : false
  }

  renderTagsTable(){
    
    const{getTags,classes} = this.props
    let sortedArray = this.state.currentPageTags.slice()
    return(
      <Paper>
        <Toolbar>
          <Typography variant="h6" className={classes.heading}>
            Tags
          </Typography>
        </Toolbar>    
        <Divider/>
            <Table>
              <TableHead className={classes.tableRow} >
                <TableRow className={classes.tableRow}>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">No. of templates associated</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  getTags.tags !== undefined? (
                    
                    sortedArray.map(tag => {
                      return(
                        <TableRow hover key={tag.id} className={classes.tableRow}>
                          <TableCell>
                            <Typography variant="body1" color="inherit">
                              {tag.name}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body1" color="inherit">
                              {tag.templates.length}
                            </Typography>
                          </TableCell>
                          <TableCell align='right'>
                              <IconButton onClick={e => this.openOptions(e.currentTarget,tag)}>
                                <DotsVertical />
                              </IconButton>
                          </TableCell>
                        </TableRow>
                      )
                    })
                  ) : null
                }
              </TableBody>
              {getTags.tags !== undefined? (

                <TablePagination
                  component="div"
                  count={getTags.tags.length}
                  rowsPerPage={this.state.rowsPerPage}
                  rowsPerPageOptions={[ 5,10, 15, 20, 50 ]}
                  page={this.state.page}
                  backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                  nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              ) : <CircularProgress />}
            </Table>
      </Paper>
    )
  }

  handleChangePage = (e, page) => {
    this.setState({ page });
  }

  handleChangeRowsPerPage = (e) => {
    let rowsPerPage = e.target.value;

    this.setState({ rowsPerPage });
  }
  render(){
    const {classes} = this.props
    return(
      <React.Fragment>
        {this.renderToolbar()}
        {this.renderUpdateDialog()}
        {this.renderOptionsMenu()}
        {this.renderCreateDialog()}
        {this.renderRemoveDialog()}
        <div className={classes.root}>
          {this.renderTagsTable()}
        </div>
      </React.Fragment>
    )
  }
}

export default compose(
  withRouter,
  graphql(CREATE_TAG,{name: 'createSystemTag'}),
  graphql(DELETE_TAG,{name: 'deleteSystemTag'}),
  graphql(UPDATE_TAG,{name: 'updateTag'}),
  graphql(GET_TAGS,{name: 'getTags'}),
  withStyles(STYLES),
)(TagManager);