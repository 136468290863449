
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Paper,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  Plus as PlusIcon,
} from 'mdi-material-ui';

import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';

import { ColorButton } from '../color/ColorButton';
import { Permissible } from '../user/Permissions';
import ColorPicker from '../color/ColorPicker';

// -------------------------------------------------------------------------- //

const GQL_GET_COLORS = gql`
  query {
    me {
      id
      client {
        id
        color1
        color2
        color3
        palette
      }
    }
  }
`;

const GQL_SET_COLORS = gql`
  mutation($color1: String, $color2: String, $color3: String) {
    setMyColors(
      color1: $color1
      color2: $color2
      color3: $color3
    ) {
      id
      color1
      color2
      color3
    }
  }
`;

const GQL_SET_PALETTE = gql`
  mutation($colors: Json!) {
    setMyPalette(colors: $colors) {
      id
      palette
    }
  }
`;

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  paper: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  spacer: {
    paddingTop: theme.spacing(8),
  },
  palette: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
});

// -------------------------------------------------------------------------- //

class SettingPalette extends React.Component {

  state = {
    anchor_el: null,
    active_color: null,
    picker_open: false,
  }

  render() {
    const { classes, data } = this.props;
    const { active_color, picker_open, anchor_el } = this.state;

    if (!data.me || !data.me.client) {
      return <Typography>Loading</Typography>;
    }

    const { client } = data.me;
    let team = [], palette = [];

    if (client.palette && client.palette.colors) {
      palette = client.palette.colors;
    }

    team.push(client.color1, client.color2, client.color3);

    return (
      <Permissible condition={(permissions) => (
        permissions.get('team_palette:write')
      )}>
        <div id="colors" className={classes.spacer}>
          <Paper className={classes.paper} id="paletteSettings" data-tour="step-3">
            <Typography variant="h4" component="h2">
              Team Colors
            </Typography>
            <Typography variant="subtitle1" component="h3" gutterBottom>
              We use your first three team colors to customize the interace,
              and give you a head start when creating graphics.
            </Typography>
            <Paper className={classes.palette}>
            {team.map((color, index) => (
              <ColorButton
                key={index}
                color={color}
                ariaLabel={`Color ${color}`}
                onClick={(e) => {
                  this.setState({
                    anchor_el: e.target,
                    active_color: index,
                    picker_open: true,
                  });
                }}
              />
            ))}
            </Paper>
            <Typography variant="subtitle1" component="h3" gutterBottom>
              You can add more colors, and they'll be available in your
              quick palette.
            </Typography>
            <Paper className={classes.palette}>
            {palette.map((color, index) => (
              <ColorButton
                key={index}
                color={color}
                onDelete={() => {
                  let new_palette = [...palette];
                  new_palette.splice(index, 1);

                  this.props.gql_set_palette({
                    variables: { colors: { colors: new_palette } }
                  });
                }}
                ariaLabel={`Color ${color}`}
                onClick={(e) => {
                  this.setState({
                    anchor_el: e.target,
                    active_color: (3 + index),
                    picker_open: true,
                  });
                }}
              />
            ))}
              <ColorButton
                icon={PlusIcon}
                onClick={() => {
                  let new_palette = [...palette];
                  new_palette.push('#555');
                  this.props.gql_set_palette({
                    variables: { colors: { colors: new_palette } }
                  });
                }}
                ariaLabel="Add new color"
              />
            </Paper>
            <ColorPicker
              disablePalette disableAlpha
              open={picker_open}
              anchorEl={anchor_el}
              color={(
                (active_color >= 3)
                ? palette[active_color - 3]
                : team[active_color]
              )}
              onClose={({ reason, color }) => {
                if (reason === 'okay') {
                  if (active_color >= 3) {
                    let new_palette = [...palette];
                    new_palette[active_color - 3] = color.hex;

                    this.props.gql_set_palette({
                      variables: { colors: { colors: new_palette } }
                    });
                  } else {
                    let new_team = [...team];
                    new_team[active_color] = color.hex;

                    this.props.gql_set_colors({
                      variables: {
                        color1: new_team[0],
                        color2: new_team[1],
                        color3: new_team[2],
                      }
                    });
                  }
                }

                this.setState({ picker_open: false });
              }}
            />
          </Paper>
        </div>
      </Permissible>
    );
  }

};

// -------------------------------------------------------------------------- //

export default compose(
  graphql(GQL_GET_COLORS),
  graphql(GQL_SET_COLORS, { name: 'gql_set_colors' }),
  graphql(GQL_SET_PALETTE, { name: 'gql_set_palette' }),
  withStyles(STYLES),
)(SettingPalette);

// -------------------------------------------------------------------------- //
