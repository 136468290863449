import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const styles = theme => ({
  controls: {
    position: 'sticky',
    top: 72,
  },
  activeLink: {
    backgroundColor: '#EEE',
  },
  activeIcon: {
    color: theme.palette.secondary.dark,
  },
  listItem: {
    whiteSpace: 'nowrap',
  },
});

const SidebarNav = (props) => {
  const { classes, config } = props;
  const output = [];
  config.links.forEach((link) => {
    const restProps = {};
    if (link.type === 'href') {
      restProps.component = 'a';
      restProps.href = link.to;
    } else if (link.type === 'router') {
      restProps.component = Link;
      restProps.to = link.to;
    }
    output.push((
      <ListItem
        button
        dense
        key={`Item${link.text}`}
        className={classes.listItem}
        {...restProps}
      >
        <ListItemText primary={link.text} />
      </ListItem>));
  });
  return (
    <List
      className={classes.controls}
    >
      {output}
    </List>
  );
};

export default withStyles(styles)(SidebarNav);
