import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  cover: {
    position: 'absolute',
    zIndex: 999999,
    left: 0,
    top: 0,
    backgroundColor: '#FFF',
    height: '100vh',
    width: '100vw',
    textAlign: 'center',
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.short,
    }),
  },
  progress: {
    margin: 'auto',
    marginTop: '12%',
  },
  hidden: {
    display: 'none',
  },
});

class FullscreenLoader extends Component {
  constructor() {
    super();
    this.state = {
      gone: false,
    };
  }
  componentDidUpdate(newProps) {
    if (newProps.complete) {
      setTimeout(() => {
        this.setState({
          gone: true,
        });
      }, 250);
    }
  }
  render() {
    const { forwardedRef, classes, complete } = this.props;
    return (
      <div className={this.state.gone ? classes.hidden : classes.cover} style={{ opacity: complete ? 0 : 1 }} ref={forwardedRef}>
        <CircularProgress
          className={classes.progress}
          variant="indeterminate"
          size={180}
        />
        <Typography variant="h2">
          Loading...
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(FullscreenLoader);
