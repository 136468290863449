// -------------------------------------------------------------------------- //
import React from 'react';
import {
  ButtonBase,
  Fade,
  IconButton,
  withStyles,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import classNames from 'classnames';
import { HeartOutline, Heart } from 'mdi-material-ui';
import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import prestologo from '../../assets/presto_logo.png';

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  button: {
    borderRadius: 4,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  full_width: {
    position: 'relative',
    width: '100%',
  },
  preview: {
    borderRadius: 4,
  },
  topright: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  topleft: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  controls: {
    margin: theme.spacing(2),
  },
  controlButton: {
    backgroundColor: theme.palette.common.white,
    opacity: '87%',
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.short,
    }),
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      opacity: '100%',
    },
    '&:disabled': {
      backgroundColor: theme.palette.common.white,
      opacity: '87%',
    },
  },
  controllogo: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(2),
    width: theme.spacing(4),
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    borderWidth: '1px',
  },
  controlButtonHeart: {
    position: 'relative',
    width: theme.spacing(3),
    height: theme.spacing(3),
    bottom: 7,
  },
});

// -------------------------------------------------------------------------- //

const FAVORITE_TEMPLATE = gql`
  mutation favoriteTemplate($id: ID!, $userId: ID!) {
    favoriteTemplate(
      id: $id
      userId: $userId
    ) {
      id
    }
  }
`;

const UNFAVORITE_TEMPLATE = gql`
  mutation unFavoriteTemplate($id: ID!, $userId: ID!) {
    unFavoriteTemplate(
      id: $id
      userId: $userId
    ) {
      id
    }
  }
`;

const GQL_CLIENT = gql`
  query {
    me {
      id
    }
  }
`;

class Component extends React.Component {
  state = {
    loaded: false,
    hide_video: false,
    stateFavorited: false,
    prestoIntegrated: false,
  }

  componentWillMount() {
    const { favorited, presto } = this.props;
    const prestoSite = localStorage.getItem('prestoSite');
    this.setState({
      stateFavorited: favorited,
      prestoIntegrated: (presto && prestoSite === 'true'),
    });
  }


  render() {
    const {
      classes, template, fullWidth, onClick,
    } = this.props;
    const { hide_video, stateFavorited, prestoIntegrated } = this.state;
    let component = null; let display = 'none'; let src = ''; let
      title = 'New Template';
    if (template) {
      display = 'block';
      const clientId = localStorage.getItem('client_id');

      if (template.customDetails) {
        title = `Custom Template: ${template.customDetails.name}`;
        src = `https://ss3-clientmedia.s3.us-east-2.amazonaws.com/${clientId}/templates/${template.customDetails.id}.png`;
      } else {
        src = `https://s3.us-east-2.amazonaws.com/ss3-templates/${template.id}.jpg`;
      }


      if (template.isSlideShow && !hide_video && !template.customDetails) {
        component = (
          <video
            muted
            autoPlay
            loop
            crossOrigin="anonymous"
            className={classNames(
              classes.preview,
              { [classes.full_width]: fullWidth },
            )}
            src={`https://s3.us-east-2.amazonaws.com/ss3-templates/${template.id}.mp4`}
            onLoadedData={() => this.setState({ loaded: true })}
            alt={`Template ${template.name}`}
            onError={() => this.setState({ hide_video: true })}
          />
        );
      } else {
        component = (
          <img
            onLoad={() => this.setState({ loaded: true })}
            className={classNames(
              classes.preview,
              { [classes.full_width]: fullWidth },
            )}
            alt={`Template ${template.name}`}
            src={src}
          />
        );
      }
    }

    let ButtonTooltip = Tooltip;

    if (this.state.favorite_busy) {
      ButtonTooltip = React.Fragment;
    }

    let prestoIcon = null;
    // eslint-disable-next-line react/jsx-no-undef
    if (prestoIntegrated) {
      prestoIcon = (
        <div className={classNames(classes.topleft, classes.controls)}>
          <img src={prestologo} className={classes.controllogo} alt="PrestoSports Logo" />
        </div>
      );
    }
    return (
      <Fade in={this.state.loaded && display !== 'none'}>
        <div style={{ position: 'relative' }}>
          {' '}
          {/* keep for <Fade> */}
          <ButtonBase
            className={classNames(
              classes.button,
              { [classes.full_width]: fullWidth },
            )}
            onClick={() => (onClick && onClick())}
            tabIndex={display !== 'none' ? 0 : -1}
            title={title}
          >
            {component}
          </ButtonBase>
          {prestoIcon}
          <div className={classNames(classes.topright, classes.controls)}>
            <ButtonTooltip aria-label={stateFavorited ? `Delete from favorites ${template.name}` : `Add to favorite ${template.name}`} enterDelay={500} title={stateFavorited ? 'Unfavorite' : 'Favorite'}>
              <IconButton
                color="primary"
                size="small"
                className={classes.controlButton}
                disabled={this.state.favorite_busy}
                onClick={
                  stateFavorited
                    ? () => this.unFavoriteTemplate()
                    : () => this.favoriteTemplate()
                }
              >
                {
                this.state.favorite_busy
                  ? <CircularProgress color="primary" size={24} />
                  : (stateFavorited ? <Heart /> :
                    <HeartOutline />)
              }
              </IconButton>
            </ButtonTooltip>
          </div>
        </div>
      </Fade>
    );
  }

  favoriteTemplate = () => {
    const { template, data } = this.props;

    if (data && data.me) {
      this.setState({ favorite_busy: true }, () => {
        this.props.favoriteTemplate({
          variables: {
            id: template.id,
            userId: data.me.id,
          },
        }).then(() => {
          this.setState({ stateFavorited: true, favorite_busy: false });
        });
      });
    }
  }

  unFavoriteTemplate = () => {
    const { template, data } = this.props;

    if (data && data.me) {
      this.setState({ favorite_busy: true }, () => {
        this.props.unFavoriteTemplate({
          variables: {
            id: template.id,
            userId: data.me.id,
          },
        }).then(() => {
          this.setState({ stateFavorited: false, favorite_busy: false });
        });
      });
    }
  }
}

// -------------------------------------------------------------------------- //

export const TemplatePreview3 = compose(
  graphql(GQL_CLIENT),
  graphql(FAVORITE_TEMPLATE, { name: 'favoriteTemplate' }),
  graphql(UNFAVORITE_TEMPLATE, { name: 'unFavoriteTemplate' }),
  withStyles(STYLES),
)(Component);

// -------------------------------------------------------------------------- //
