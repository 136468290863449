
import EmptyAnimation from './animations/Empty';
import PanAnimation from './animations/Pan';
import FadeAnimation from './animations/Fade';

export default [
	{
		id: 'empty',
		name: 'Still Video',
		type: EmptyAnimation,
		video: 'https://ss3-video.s3.us-east-2.amazonaws.com/effects/motion-empty.mp4',
		options: {},
	},
	{
		id: 'simple-pan',
		name: 'Pan',
		type: PanAnimation,
		video: 'https://ss3-video.s3.us-east-2.amazonaws.com/effects/motion-pan.mp4',
		options: {
			'direction': {
				key: 'direction',
				name: 'Direction',
				type: 'select',
				defaultValue: 'left',
				values: [
					{ name: 'Left', value: 'left' },
					{ name: 'Right', value: 'right' },
					{ name: 'Top', value: 'top' },
					{ name: 'Bottom', value: 'bottom' }
				]
			}
		}
	},
	{
		id: 'simple-fade',
		name: 'Fade',
		type: FadeAnimation,
		options: {},
		video: 'https://ss3-video.s3.us-east-2.amazonaws.com/effects/motion-fade.mp4',
	}
];
