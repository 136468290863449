import React, { Component } from 'react';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {List, ListItem} from "@material-ui/core";

const styles = theme => ({
  paper: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  item: {
    display: 'block',
  },

});

const RenderQuestions = withStyles(styles)(({
    classes, question, ...props
  }) => (
  <ListItem className={classes.item} {...props}>
    <Typography color="primary" variant="h6" component="h2" gutterBottom>
      {question.question}
    </Typography>

      {question.answer}
  </ListItem>
));


// -------------------------------------------------------------------------- //

const QUESTIONS = [
  {
    question: 'I clicked “Forgot My Password" but I haven’t received an email.',
    answer: (
      <Typography variant="body1" component="h3" paragraph>
        If the email is not in your inbox, please check your spam folder. If it is not there, then you can send us an email or message us using the “Support/Feedback” button on our website and we will reset your password manually.
      </Typography>
    )
  },
  {
    question: 'How do I copy and paste on the template?',
    answer: (
      <Typography variant="body1" component="h3" paragraph>
        If you are using a PC, hit the Control button and the letter “C” on your keyboard to copy. To paste, hit the control button and the letter “V.” If you are using a Mac, hit the Command button and the letter “C” to copy. To paste, hit the Command button and the letter “V” on your keyboard.
      </Typography>
    )
  },
  {
    question: 'How can I change the colors of any item on the template?',
    answer: (
      <>
        <Typography variant="body1" component="h3" paragraph>
          A quick solution is clicking directly on the shape or text that you want to change and the editing options will pop up. Click on the color box with the current color of the text/object and select a new color.
        </Typography>
        <Typography variant="body1" component="h3" paragraph>
          If you want to quickly change ALL of the text colors on the template, go to the “Text” step on the menu. You will see “Text Colors.” These are all of the colors of the text in the template. To change all of the text with a particular color, select the box with the current color and then adjust to the color you want.
        </Typography>
        <Typography variant="body1" component="h3" paragraph>
          If you want to change the color of ALL shapes and objects on the template, select the “More” button and you will see a few more steps listed. Under “Elements” you will see the “Color Palette.” Again, all of the colors of the objects in the template will be listed. Select the box with the current color and adjust to the color you want.
        </Typography>
      </>
    )
  },
  {
    question: 'Why is my uploaded background picture showing up faded or another color?',
    answer: (
      <Typography variant="body1"  component="h3" paragraph>
        There is probably a layer on top of the image that was added by our designers to soften the photo. If you would like to keep the layer, but you want to change the color, select “More” on the left, go to the “Elements” step and click the current color box to select a different color. If you do not want to keep the layer, simply click on it and hit the “Delete" button on your keyboard.
      </Typography>
    )
  },
  {
    question: 'I have something covering my image, how do I fix this?',
    answer: (
      <>
      <Typography variant="body1" component="h3" paragraph>
        If you are editing and notice that an object or text box is covering something that you would like to be in the front of your template then choose the “More” button on the menu on the left. If you select the “Layers” button you will then be able to order of the objects on the template to move items to the front or back.
      </Typography>
      <Typography variant="body1" component="h3" paragraph>
        Click the up and down arrows or click and drag to rearrange. The items at the top of the list are furthest back and those at the bottom of the list are the furthest forward.
      </Typography>
      </>
    )
  },
  {
    question: 'Where can I find the "Textures" feature?',
    answer: (
      <Typography variant="body1" component="h3" paragraph>
        Select the “More” step and select “Elements.” Then, click the drop down arrow next to “Shapes” and select “Textures." Click on a texture to select and click again to remove it.
      </Typography>
    )
  },
  {
    question: 'How do I add motion effects to my template?',
    answer: (
      <Typography variant="body1" component="h3" paragraph>
        Select "More" on the left menu and then select "Effects." From there, you can select any effect or motion that you would like. To  delete the effect, click the “Clear Effect” button.
      </Typography>
    )
  },
  {
    question: 'How do I add other Facebook and/or Twitter accounts?',
    answer: (
      <Typography variant="body1" component="h3" paragraph>
        First, make sure that you are logged into the Facebook or Twitter account that you would like to add. Then, go into the “Team” tab in ScoreShots and scroll down to “Social Accounts.” Click the “Add Pages” button to add the Facebook account and hit “Add Account” to add your Twitter account.
      </Typography>
    )
  },
  {
    question: 'How do I add someone to my account?',
    answer: (
      <Typography variant="body1" component="h3" paragraph>
        If you are a Superuser or primary account holder, you will be able to add other users to your account by going to the “Team” tab and scrolling down to “User Accounts.” Hit the “Add New User” button, input the proper name and email address and that person  will receive  an email with login information. If the email does not show up in their inbox, please have them check their spam folder.
      </Typography>
    )
  },
  {
    question: 'Can I set restrictions on other users?',
    answer: (
      <Typography variant="body1" component="h3" paragraph>
        If you are a Superuser, then you will be able to assign “User Roles” at the bottom of the “Team” tab. You can create a new user role by hitting the “Add Role” button. You then will be able to restrict their ability to select certain templates, post to social accounts, and more. Then you can assign that User Role to new users on your account if you would like.
      </Typography>
    )
  },



];

// -------------------------------------------------------------------------- //

class ResourcesFAQ extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.paper}>
        <Typography id="faq-content" variant="h4" component="h1" gutterBottom>
          Frequently Asked Questions
        </Typography>
        <List>
          {QUESTIONS.map((question, index) => (
            <RenderQuestions key={index} question={question}/>
          ))}
        </List>
      </Paper>
    );
  }
}

export default compose(
)(withStyles(styles)(ResourcesFAQ));
