
// -------------------------------------------------------------------------- //

import AttributePath from './AttributePath';
import SCSportImporter from './SCSportImporter';
import { SplitPlayerName } from './Sports';

// -------------------------------------------------------------------------- //

class SCVolleyballImporter extends SCSportImporter {

  constructor() {
    super('vbgame');
  }

  import(sport, xml, options = {}) {
    if (!super.import(sport, xml, options)) {
      return false;
    }

    this.importTeam('home', this.ids[0]);
    this.importTeam('visiting', this.ids[1]);

    for (let i = 0; i < 2; ++i) {
      this.importTeamStats(i);
    }

    if (options.players) {
      for (let i = 0; i < options.players.length; ++i) {
        this.importPlayerStats(options.players[i], i);
      }
    }

    return true;
  }

  importTeam(key, id) {
    const team_id = `team.${key}`;
    const team_path = `/${this.game}/team[@id='${id}']`;

    let team = {
      name: this.get(`${team_id}.name`),
      players: [],
      key: id,
    };

    for (let i = 0;; ++i) {
      const player_path = `${team_path}/player[*][@name!='TEAM'][${i + 1}]`;

      if (!this.test(new AttributePath(player_path, 'name'))) {
        break;
      }

      team.players.push({
        name: SplitPlayerName(this.peek(new AttributePath(player_path, 'name'), { type: 'string' })),
        position: '',
        key: this.peek(new AttributePath(player_path, 'uni')),
      });
    }

    this.sport.teams.push(team);
  }

  importTeamStats(team) {
    // const vh = (team === 0 ? 'H' : 'V');
    const path = `/${this.game}/team[@id='${this.ids[team]}']`;
    const linescore = `${path}/linescore`;
    const totals_attack = `${path}/totals/attack`;
    const totals_set = `${path}/totals/set`;
    const totals_serve = `${path}/totals/serve`;
    const totals_defense = `${path}/totals/defense`;
    const totals_block = `${path}/totals/block`;
    // const totals_misc = `${path}/totals/misc`;
    const team_id = `team.${team === 0 ? 'home' : 'visiting'}`;
    let k, e, ta;

    this.select(`${team_id}.score`, new AttributePath(linescore, 'score'));

    k = this.select(
      `${team_id}.kills`, new AttributePath(totals_attack, 'k')
    );

    e = this.select(
      `${team_id}.attacks.errors`, new AttributePath(totals_attack, 'e')
    );

    ta = this.select(
      `${team_id}.attacks.total`, new AttributePath(totals_attack, 'ta')
    );

    this.set(`${team_id}.attacks.percent`, ((k - e) / ta));
    this.select(`${team_id}.assists`, new AttributePath(totals_set, 'a'));
    this.select(`${team_id}.serve.aces`, new AttributePath(totals_serve, 'sa'));
    this.select(`${team_id}.serve.errors`, new AttributePath(totals_serve, 'se'));
    this.select(`${team_id}.digs`, new AttributePath(totals_defense, 'dig'));
    this.select(`${team_id}.blocks`, new AttributePath(totals_block, 'tb'));
    this.select(`${team_id}.blocks.solos`, new AttributePath(totals_block, 'bs'));
    this.select(`${team_id}.blocks.assists`, new AttributePath(totals_block, 'ba'));
    this.select(`${team_id}.blocks.errors`, new AttributePath(totals_block, 'be'));

    for (let g = 0; g < 5; ++g) {
      const game = (g + 1);
      const game_id = `game${game}`;
      const linegame = `${linescore}/linegame[@game='${game}']`;
      const attackgame = `${linescore}/attackgame[@game='${game}']`;

      if (!this.test(new AttributePath(linegame, 'game'))) {
        continue;
      }

      this.select(`${team_id}.score.${game_id}`, new AttributePath(linegame, 'points'));

      k = this.select(
        `${team_id}.kills.${game_id}`, new AttributePath(attackgame, 'k')
      );
  
      e = this.select(
        `${team_id}.attacks.errors.${game_id}`, new AttributePath(attackgame, 'e')
      );
  
      ta = this.select(
        `${team_id}.attacks.total.${game_id}`, new AttributePath(attackgame, 'ta')
      );

      this.set(`${team_id}.attacks.percent.${game_id}`, ((k - e) / ta));
    }
  }

  importPlayerStats(player, index) {
    const id = this.ids[player.team];
    const team = this.sport.teams[player.team];
    const uni = team.players[player.player].key;
    const path = `/${this.game}/team[@id='${id}']/player[@uni='${uni}']`;

    if (!this.test(new AttributePath(path, 'name'))) {
      return false;
    }

    const player_name = SplitPlayerName(
      this.peek(new AttributePath(path, 'name'))
    );

    this.set('player.name.long', player_name.long);
    this.set('player.name.first', player_name.first);
    this.set('player.name.last', player_name.last);
    this.select('player.position', new AttributePath(path, 'pos'), { index });
    this.select('player.number', new AttributePath(path, 'uni'), { index });
    this.select('player.kills', new AttributePath(`${path}/attack`, 'k'), { index });
    this.select('player.aces', new AttributePath(`${path}/serve`, 'sa'), { index });
    this.select('player.digs', new AttributePath(`${path}/defense`, 'dig'), { index });
    this.select('player.assists', new AttributePath(`${path}/set`, 'a'), { index });
    this.select('player.blocks', new AttributePath(`${path}/block`, 'tb'), { index });
    return true;
  }

}

// -------------------------------------------------------------------------- //

export default SCVolleyballImporter;

// -------------------------------------------------------------------------- //
