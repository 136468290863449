import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dropzone from 'react-dropzone';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ChevronDown from 'mdi-material-ui/ChevronDown';
import Delete from 'mdi-material-ui/Delete';
import { Subscribe } from 'unstated';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import { parse } from '../team/opentype';
import MessageContainer from '../../unstated/MessageContainer';
import ScoreshotsPut from '../../scoreshotsapi/Put.js'

const styles = theme => ({
  paper: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  dropzone: {
    width: '100%',
    marginTop: theme.spacing(2),
    height: 'auto',
    border: 0,
    textAlign: 'right',
  },
  details: {
    display: 'block',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  column: {
    flexBasis: '33.33%',
  },
  fontList: {
    paddingTop: theme.spacing(2),
  },
  primaryText: {
    fontFamily: 'inherit',
    fontWeight: 'inherit',
    fontStyle: 'inherit',
  },
});

const GET_GLOBAL_FONTS = gql`
  query getGlobalFonts {
    fonts {
      id
      fullName
      fontFamily
      type
      bucket
      key
    }
  }
`;

const ADD_FONT = gql`
  mutation addGlobalFont($bucket: String!, $key: String!, $fontFamily: String!, $fullName: String!, $type: FONT_TYPE!) {
    addGlobalFont(
      bucket: $bucket,
      key: $key,
      fontFamily: $fontFamily,
      fullName: $fullName,
      type: $type
    ) {
      id
      fullName
      fontFamily
      type
      bucket
      key
    }
  }
`;

const DELETE_FONT = gql`
  mutation deleteGlobalFont($fontId: ID!) {
    removeGlobalFont(
      id: $fontId
    ) {
      id
      fullName
      fontFamily
      type
      bucket
      key
    }
  }
`;

class FontEditor extends Component {
  onDrop = self => (files) => {
    const file = files[0];
    const fileReader = new FileReader();
    fileReader.onload = (response) => {
      try {
        const font = parse(response.target.result);
        if (font.supported) {
          const fontFamily = font.names.fontFamily.en ?
            font.names.fontFamily.en :
            font.names.fontFamily[Object.keys(font.names.fontFamily)[0]];

          const fullName = font.names.fullName.en ?
            font.names.fullName.en :
            font.names.fullName[Object.keys(font.names.fullName)[0]];

          const fontSubfamily = font.names.fontSubfamily.en ?
            font.names.fontSubfamily.en :
            font.names.fontSubfamily[Object.keys(font.names.fontSubfamily)[0]];

          let type;
          switch (fontSubfamily.toLowerCase()) {
            case 'bold':
              type = 'BOLD';
              break;
            case 'italic':
              type = 'ITALIC';
              break;
            case 'bold italic':
            case 'italic bold':
              type = 'BOLDITALIC';
              break;
            case 'regular':
            default:
              type = 'REGULAR';
              break;
          }

          self.uploadFont(file, fontFamily, fullName, type, self);
        } else {
          self.props.setMessage('Your font could not be recognized.', 5000);
        }
      } catch (error) {
        self.props.setMessage('Your font could not be recognized.', 5000);
      }
    };
    fileReader.readAsArrayBuffer(file);
  }

  getFonts() {
    const typeDict = {
      REGULAR: {
        weight: 400,
        style: 'normal',
        pretty: 'Regular',
      },
      BOLD: {
        weight: 700,
        style: 'normal',
        pretty: 'Bold',
      },
      ITALIC: {
        weight: 400,
        style: 'italic',
        pretty: 'Italic',
      },
      BOLDITALIC: {
        weight: 700,
        style: 'italic',
        pretty: 'Bold Italic',
      },
    };
    const output = [];
    const { data, classes } = this.props;
    if (data && data.fonts) {
      const families = {};
      // for each client font, put them into a family group.
      data.fonts.forEach((font) => {
        if (!families[font.fontFamily]) {
          families[font.fontFamily] = [font];
        } else {
          families[font.fontFamily].push(font);
        }
      });
      Object.keys(families).forEach((key) => {
        const familyOutput = [];
        const family = families[key];
        family.forEach((font) => {
          familyOutput.push((
            <ListItem key={font.id}>
              <ListItemText
                primary={font.fullName}
                secondary={typeDict[font.type].pretty}
                style={{
                  fontFamily: ('"' + font.fontFamily.replace('"', '\\"') + '"'),

                  fontWeight: typeDict[font.type].weight,
                  fontStyle: typeDict[font.type].style,
                }}
                classes={{
                  primary: classes.primaryText,
                }}
              />
              <ListItemSecondaryAction>
                <IconButton aria-label="Delete" onClick={this.deleteFont(this, font.id, font.fullName)}>
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>));
        });
        output.push((
          <ExpansionPanel key={key}>
            <ExpansionPanelSummary expandIcon={<ChevronDown />}>
              <div className={classes.column}>
                <Typography
                  className={classes.heading}
                  style={{ fontFamily: ('"' + key.replace('"', '\\"') + '"') }}
                >
                  {key}
                </Typography>
              </div>
              <div className={classes.column}>
                <Typography className={classes.secondaryHeading}>{`${familyOutput.length} Style${familyOutput.length > 1 ? 's' : ''}`}</Typography>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
              <List>
                {familyOutput}
              </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>));
      });
      return output;
    }
    return output;
  }

  uploadFont(file, fontFamily, fullName, type, self) {
    const { addFont } = self.props;
    const time = Math.round((new Date()).getTime() / 1000);
    const key = `${time}.ttf`;
    const action = new ScoreshotsPut('ss3-clientfonts', key, 'global');
    action.put(file, 'application/x-font-ttf', () => {
      addFont({
        variables: {
          fullName,
          fontFamily,
          type,
          bucket: 'ss3-clientfonts',
          key: `global/${key}`,
        },
      })
        .then(() => {
          self.props.setMessage('Font Added', 2500);
        });
    });
  }


  deleteFont = (self, fontId) => () => {
    self.props.deleteFont({
      variables: {
        fontId,
      },
    })
      .then(() => {
        self.props.setMessage('Font deleted', 2000);
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.paper}>
        <Typography variant="h4" >
          Global Fonts
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {
            'Uploading a font here makes it visible to all clients. Make sure that we have the rights to \
            the font you\'re uploading before doing so. Make sure you upload as many styles as possible \
            of the font. Missing styles like bold will lead to it working poorly in engine.'
          }
        </Typography>
        <div className={classes.fontList}>
          {this.getFonts()}
        </div>
        <Dropzone
          multiple={false}
          className={classes.dropzone}
          onDrop={this.onDrop(this)}
        >
          <Button variant="raised" color="primary">
            Upload New Font
          </Button>
        </Dropzone>
      </Paper>
    );
  }
}

const MessagedFontEditor = (props) => (
  <Subscribe to={[MessageContainer]}>
    {messages => (
      <FontEditor setMessage={messages.setMessage} {...props} />
    )}
  </Subscribe>
)

export default compose(
  graphql(GET_GLOBAL_FONTS),
  graphql(ADD_FONT, { name: 'addFont', options: {refetchQueries: ['getGlobalFonts']} }),
  graphql(DELETE_FONT, { name: 'deleteFont' , options: {refetchQueries: ['getGlobalFonts']} }),
)(withStyles(styles)(MessagedFontEditor));
