import React, { Component } from 'react';
import Transitions from './Transitions'

class TransitionsContainer extends Component {
  state = {
    show : true,
    duration : 0,
    selected : "None",
    min : 0,
    max : 5,
    step : .1
  }

changeDuration = e => this.setState({ duration : e },this.props.setTransitionFunct({ "type" : this.state.selected, "duration" : e}))
getType = type => () => this.setState({ selected : type },this.props.setTransitionFunct({ "type" : type, "duration" : this.state.duration}));

render() {
   const { duration, min, max, step } = this.state;
   const { show } = this.props;
   const changeDuration = this.changeDuration;
   const getType = this.getType
   const _duration = duration + ' secs'
   const tipProps = {
     placement: 'top',
     prefixCls: 'rc-slider-tooltip',
     overlay: _duration,
     zIndex : 1000
   };

   const _props = {
       duration,
       min,
       max,
       changeDuration,
       tipProps,
       getType,
       step
   };
  const _component = show ? <Transitions {..._props}/> : null;
  return (
    <React.Fragment>
      {_component}
    </React.Fragment>
  );
}
}

export default TransitionsContainer;
