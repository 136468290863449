
// -------------------------------------------------------------------------- //

import Sport from './Sport';

// -------------------------------------------------------------------------- //

const TEAMS = [
  'Home',
  'Visiting',
];

// -------------------------------------------------------------------------- //

class FootballSport extends Sport {

  constructor() {
    super();

    for (let t = 0; t < 2; ++t) {
      const team = `[${TEAMS[t]}]`;
      const team_id = `team.${TEAMS[t].toLowerCase()}`;
      this.push(`${team_id}.id`, `${team} Team ID`, { type: 'string' });
      this.push(`${team_id}.name`, `${team} Team Name`, { type: 'string' });
      this.push(`${team_id}.logo`, `${team} Team Logo`, { type: 'image' });
      this.push(`${team_id}.record`, `${team} Team Record`, { type: 'string' });
      this.push(`${team_id}.score`, `${team} Total Score`);
      this.push(`${team_id}.yards`, `${team} Total Yards`);
      this.push(`${team_id}.yards.rush`, `${team} Rushing Yards`);
      this.push(`${team_id}.yards.pass`, `${team} Passing Yards`);
      this.push(`${team_id}.turnovers`, `${team} Total Turnovers`);
      this.push(`${team_id}.fumbles`, `${team} Total Fumbles`);
      this.push(`${team_id}.fumbles.lost`, `${team} Total Fumbles Lost`);
      this.push(`${team_id}.touchdowns`, `${team} Total Touchdowns`);
      this.push(`${team_id}.firstdowns`, `${team} Total First Downs`);
      this.push(`${team_id}.possession`, `${team} Total Possession Time`, { type: 'string' });
      this.push(`${team_id}.possession.seconds`, `${team} Total Possession Seconds`);
      this.push(`${team_id}.penalties`, `${team} Total Penalties`);
      this.push(`${team_id}.passes.complete`, `${team} Passes Completed`);
      this.push(`${team_id}.passes.attempts`, `${team} Passes Attempted`);
      this.push(`${team_id}.passes.percent`, `${team} Completion Percentage`);
      this.push(`${team_id}.passes.intercepted`, `${team} Passes Intercepted`);
      this.push(`${team_id}.receptions`, `${team} Total Receptions`);
      this.push(`${team_id}.kickoffs`, `${team} Total Kickoffs`);
      this.push(`${team_id}.fieldgoals.made`, `${team} Total Field Goals`);
      this.push(`${team_id}.fieldgoals.attempts`, `${team} Total Field Goal Attempts`);
      this.push(`${team_id}.fieldgoals.percent`, `${team} Total Field Goal %`);
      this.push(`${team_id}.drive.plays`, `${team} Plays [Drive]`);
      this.push(`${team_id}.drive.yards.rush`, `${team} Rushing Yards [Drive]`);
      this.push(`${team_id}.drive.yards.pass`, `${team} Passing Yards [Drive]`);
      this.push(`${team_id}.drive.yards`, `${team} Total Yards [Drive]`);
      this.push(`${team_id}.drive.time`, `${team} Time [Drive]`, { type: 'string' });

      for (let p = 0; p < 4; ++p) {
        const period = `period${p + 1}`;
        this.push(`${team_id}.score.${period}`, `${team} Score`);
      }
    }

    const player = `[Player]`;
    this.push('player.touchdowns', `${player} Touchdowns`);
    this.push('player.touchdowns.pass', `${player} Passing TDs`);
    this.push('player.touchdowns.rush', `${player} Rushing TDs`);
    this.push('player.passes.completed', `${player} Passes Completed`);
    this.push('player.passes.attempts', `${player} Passes Attempted`);
    this.push('player.passes.percent', `${player} Completion Percentage`);
    this.push('player.passes.intercepts', `${player} Interceptions`);
    this.push('player.passes.longest', `${player} Longest Pass`);
    this.push('player.yards.pass', `${player} Passing Yards`);
  }

}

// -------------------------------------------------------------------------- //

export default FootballSport;

// -------------------------------------------------------------------------- //
