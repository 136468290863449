import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import AccountStar from 'mdi-material-ui/AccountStar';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  paper: {
    padding: 16,
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  selectedChip: {
    backgroundColor: '#c8f5c8',
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  }
});

const GET_CLIENTS_BY_NAME = gql`
  query clientsByName($name: String) {
    clientsByName(
      name: $name
    ) {
      id
      name
    }
  }
`;

const renderInputComponent = (inputProps) => {
  const { classes, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: ref,
        ...other,
      }}
    />
  );
};

const renderSuggestion = (suggestion, { query, isHighlighted }) => {
  const matches = match(suggestion.name, query);
  const parts = parse(suggestion.name, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <React.Fragment>
        {parts.map((part, index) => (part.highlight ? (
          <span key={String(index)} style={{ fontWeight: 300 }}>
            {part.text}
          </span>
        ) : (
          <strong key={String(index)} style={{ fontWeight: 500 }}>
            {part.text}
          </strong>
        )))}
      </React.Fragment>
    </MenuItem>
  );
};

const renderSuggestionsContainer = (options) => {
  const { containerProps, children } = options;

  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
};

class ClientsSelector extends Component {
	state = {
		value: ''
	}

	handleValueChange = (e, { newValue }) => {
    this.setState({
      value: newValue,
    });
	}

  onSuggestionsFetchRequested = refetch => ({ value }) => {
    refetch({
      name: value,
    });
  }

  onSuggestionsClearRequested = refetch => () => {
    refetch({
      name: null,
    });
  }

	handleSuggestionSelect = (e, { suggestion }) => {
		let { clients } = this.props;
  	for (let i = 0; i < clients.length; ++i) {
  		if (clients[i].id === suggestion.id) {
  			return;
  		}
  	}

		this.props.addClient(suggestion);
		this.setState({
			value: ''
		});
	}

	getSuggestionValue = (suggestion) => {
		return suggestion.name
	}

	render() {
		const { classes, clients } = this.props;

		let selectedChips = [];
		if (clients.length > 0) {
      selectedChips = clients.map((client, index) => (
        <Chip
          avatar={<Avatar><AccountStar /></Avatar>}
          key={client.id}
          label={client.name}
          style={{ margin: '20px 5px 0 0' }}
          placeholder="Premium Client"
          onDelete={() => this.props.removeClient(index)}
        />
      ));
    }

		return (
			<Query query={GET_CLIENTS_BY_NAME}>
			  {({ data, refetch }) => {
			    const inputProps = {
			      placeholder: 'Type Client Name',
			      value: this.state.value,
			      onChange: this.handleValueChange,
			      label: 'Client Name',
			      id: 'clientName',
			    };
			    return (
			    	<React.Fragment>
			        <Autosuggest
			          theme={{
			            container: classes.container,
			            suggestionsContainerOpen: classes.suggestionsContainerOpen,
			            suggestionsList: classes.suggestionsList,
			            suggestion: classes.suggestion,
			          }}
			          suggestions={data && data.clientsByName ? data.clientsByName : []}
			          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested(refetch)}
			          onSuggestionsClearRequested={this.onSuggestionsClearRequested(refetch)}
			          getSuggestionValue={this.getSuggestionValue}
			          onSuggestionSelected={this.handleSuggestionSelect}
			          renderSuggestionsContainer={renderSuggestionsContainer}
			          renderSuggestion={renderSuggestion}
			          inputProps={inputProps}
			          renderInputComponent={renderInputComponent}
			        />
				      {selectedChips}
				    </React.Fragment>
					);
				}}
			</Query>
		);
	}
}

export default withStyles(styles, { withTheme: true })(ClientsSelector);
