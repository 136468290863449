
// ------------------------------------------------------------------------- //

import AttributePath from './AttributePath';
import SCSportImporter from './SCSportImporter';
import { SplitPlayerName } from './Sports';

// ------------------------------------------------------------------------- //

class SCBaseballSportImporter extends SCSportImporter {

  constructor() {
    super('bsgame');
  }

  import(sport, xml, options = {}) {
    if (!super.import(sport, xml, options)) {
      return false;
    }

    const venue_path = `/${this.game}/venue`;
    this.select('venue.stadium', new AttributePath(venue_path, 'stadium'));
    this.select('venue.weather', new AttributePath(venue_path, 'weather'));

    this.importTeam('home', this.ids[0]);
    this.importTeam('visiting', this.ids[1]);

    for (let i = 0; i < 2; ++i) {
      this.importTeamStats(i);
    }

    if (options.players) {
      for (let i = 0; i < options.players.length; ++i) {
        this.importPlayerStats(options.players[i], i);
      }
    }

    return true;
  }

  importTeam(key, id) {
    const team_id = `team.${key}`;
    const team_path = `/${this.game}/team[@id='${id}']`;

    let team = {};
    team.name = this.get(`${team_id}.name`);
    team.players = [];
    team.key = id;

    for (let i = 0;; ++i) {
      const player_path = `${team_path}/player[*][@pos!=''][${i + 1}]`;

      if (!this.test(new AttributePath(player_path, 'name'))) {
        break;
      }

      team.players.push({
        name: SplitPlayerName(this.peek(new AttributePath(player_path, 'name'))),
        position: this.peek(new AttributePath(player_path, 'pos'), { type: 'string', text_transform: 'upper' }),
        key: this.peek(new AttributePath(player_path, 'uni'), { type: 'number' }),
      });
    }

    this.sport.teams.push(team);
  }

  importTeamStats(team) {
    const path = `/${this.game}/team[@id='${this.ids[team]}']`;
    const linescore = `${path}/linescore`;
    const totals = `${path}/totals`;
    const team_id = `team.${(team === 0 ? 'home' : 'visiting')}`;
    this.select(`${team_id}.runs`, new AttributePath(`${totals}/hitting`, 'r'));
    this.select(`${team_id}.rbis`, new AttributePath(`${totals}/hitting`, 'rbi'));
    this.select(`${team_id}.strikeouts`, new AttributePath(`${totals}/pitching`, 'so'));
    this.select(`${team_id}.lob`, new AttributePath(`${totals}/hsitsummary`, 'lob'));
    this.select(`${team_id}.errors`, new AttributePath(`${totals}/fielding`, 'e'));
    this.select(`${team_id}.ip`, new AttributePath(`${totals}/pitching`, 'ip'), { round: false });
    this.select(`${team_id}.walks`, new AttributePath(`${totals}/pitching`, 'bb'));
    const hits = this.select(`${team_id}.hits`, new AttributePath(`${totals}/hitting`, 'h'));
    const atbats = this.select(`${team_id}.atbats`, new AttributePath(`${totals}/hitting`, 'ab'));
    this.set(`${team_id}.ba`, (hits / atbats));

    // calculate team OBP for the game
    const h = this.peek(new AttributePath(`${totals}/hitting`, 'h'), { type: 'number', default: 0 });
    const ab = this.peek(new AttributePath(`${totals}/hitting`, 'ab'), { type: 'number', default: 0 });
    const bb = this.peek(new AttributePath(`${totals}/hitting`, 'bb'), { type: 'number', default: 0 });
    const hbp = this.peek(new AttributePath(`${totals}/pitching`, 'hbp'), { type: 'number', default: 0 });
    const sf = this.peek(new AttributePath(`${totals}/hitting`, 'sf'), { type: 'number', default: 0 });
    const obp = ((h + bb + hbp) / (ab + bb + hbp + sf));

    if (!isNaN(obp)) {
      this.set(`${team_id}.obp`, obp);
    } else {
      this.set(`${team_id}.obp`, null);
    }

    for (let i = 0; i < 11; ++i) {
      const inning = (i + 1);
      const lineinn = `${linescore}/lineinn[@inn='${inning}']`;
      const path = new AttributePath(lineinn, 'score');

      if (this.test(path)) {
        this.select(`${team_id}.score.inning${inning}`, path);
      } else {
        this.set(`${team_id}.score.inning${inning}`, null);
      }
    }
  }

  importPlayerStats(player, index) {
    const id = this.ids[player.team];
    const team = this.sport.teams[player.team];
    const uni = team.players[player.player].key;
    const path = `/${this.game}/team[@id='${id}']/player[@uni='${uni}']`;

    if (!this.test(new AttributePath(path, 'name'))) {
      return false;
    }

    const player_name = SplitPlayerName(new AttributePath(path, 'name'));
    this.set('player.name.long', player_name.long, index);
    this.set('player.name.first', player_name.first, index);
    this.set('player.name.last', player_name.last, index);
    this.select('player.position', new AttributePath(path, 'pos'), { index, text_transform: 'upper' });
    this.select('player.number', new AttributePath(path, 'uni'), { index });
    this.select('player.errors', new AttributePath(`${path}/fielding`, 'e'), { index });
    const player_position = this.get('player.position', index);

    if (player_position === 'P') {
      // pitcher stats
      this.select('player.pitches', new AttributePath(`${path}/pitching`, 'pitches'), { index });
      this.select('player.strikes', new AttributePath(`${path}/pitching`, 'strikes'), { index, round: false });
      this.select('player.atbats', new AttributePath(`${path}/pitching`, 'ab'), { index });
      this.select('player.tbf', new AttributePath(`${path}/pitching`, 'bf'), { index });
      const k = this.select('player.strikeouts', new AttributePath(`${path}/pitching`, 'so'), { index });
      const h = this.select('player.hits', new AttributePath(`${path}/pitching`, 'h'), { index });
      const bb = this.select('player.walks', new AttributePath(`${path}/pitching`, 'bb'), { index });
      const r = this.select('player.runs', new AttributePath(`${path}/pitching`, 'r'), { index });
      const er = this.select('player.runs.earned', new AttributePath(`${path}/pitching`, 'er'), { index });

      // IP is in I.O format where I = whole innings and O = outs
      // we need to convert it to a true, real number before dividing
      let ip = this.select('player.ip', new AttributePath(`${path}/pitching`, 'ip'), { index, round: false });
      ip = (Math.trunc(ip) + (ip - Math.trunc(ip)) / 0.3);

      this.set('player.k9', (k * 9 / ip), index);
      this.set('player.era', (er * 9 / ip), index);
      this.set('player.whip', ((bb + h) / ip), index);
      this.set('player.runs.unearned', Math.round(r - er), index);
    } else if (player_position !== '') {
      // hitter stats
      this.select('player.rbis', new AttributePath(`${path}/hitting`, 'rbi'), { index });
      this.select('player.runs', new AttributePath(`${path}/hitting`, 'r'), { index });
      this.select('player.lobs', new AttributePath(`${path}/hsitsummary`, 'lob'), { index });
      this.select('player.strikeouts', new AttributePath(`${path}/hitting`, 'so'), { index });
      const hits = this.select('player.hits', new AttributePath(`${path}/hitting`, 'h'), { index });
      const atbats = this.select('player.atbats', new AttributePath(`${path}/hitting`, 'ab'), { index });
      this.set('player.ba', (hits / atbats), index);

      // OBP is a seasonal statistic
      const h = this.peek(new AttributePath(`${path}/hitseason`, 'h'), { type: 'number', default: 0 });
      const ab = this.peek(new AttributePath(`${path}/hitseason`, 'ab'), { type: 'number', default: 0 });
      const bb = this.peek(new AttributePath(`${path}/hitseason`, 'bb'), { type: 'number', default: 0 });
      const hbp = this.peek(new AttributePath(`${path}/hitseason`, 'hbp'), { type: 'number', default: 0 });
      const sf = this.peek(new AttributePath(`${path}/hitseason`, 'sf'), { type: 'number', default: 0 });
      const obp = ((h + bb + hbp) / (ab + bb + hbp + sf));

      if (!isNaN(obp)) {
        this.set('player.obp', obp);
      } else {
        this.set('player.obp', null);
      }
    }

    return true;
  }

}

// ------------------------------------------------------------------------- //

export default SCBaseballSportImporter;

// ------------------------------------------------------------------------- //
