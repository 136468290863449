import React from 'react';
import {
  Card, CardContent, Typography, Divider,
} from '@material-ui/core';
import useStyles from './Subscription.style';
import Stripe from '../../stripe/Stripe.js';
import { gql, useQuery } from '@apollo/client';
const GET_CLIENT = gql`
    query getClient {
        me {
            client {
                name
                phone
                state
                zip
                city
                address
                id
                customer_id
            }
            email
            id
            firstname
            lastname
        }
    }
`;
const Subscription = ({
  name, price, icon, content, priceID, period,
}) => {
  const classes = useStyles();
  const data = useQuery(GET_CLIENT);

  return (
    <>
      <div style={{ position: 'relative', top: '3.5vmin' }}>
        <img src={icon} alt={name} style={{ width: '13vmin' }} />
      </div>
      <Card variant="outlined" className={classes.suscriptionContainer}>
        <CardContent style={{ height: '60%' }}>
          <Typography className={classes.suscriptionTitle} align="center">
            {name}
          </Typography>
          <Divider style={{ backgroundColor: '#592570', marginBottom: '3vmin' }} />
          <Typography variant="subtitle2" align="center" className={classes.content}>
            {content}
          </Typography>
        </CardContent>
        <div className={classes.priceContainer}>
          <p style={{ fontWeight: 600 }}>
$
            {price}
          </p>
          {period === 'm' ? (<p>/monthly</p>) : (<p>/annually</p>)}
        </div>
      </Card>
      <Stripe buttonLabel="CHOOSE PLAN" priceID={priceID} price={price} plan={name} type="RENEW" fetchData data={data}/>
    </>
  );
};

export default Subscription;
