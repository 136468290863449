
import AttributePath from './AttributePath';
import SportImporter from './SportImporter';

class SCSportImporter extends SportImporter {

  constructor(game) {
    super();
    this.game = game;
    this.ids = [];
  }

  import(sport, xml, options = {}) {
    if (!super.import(sport, xml, options)) {
      return false;
    }

    const venue_path = `/${this.game}/venue`;

    if (!this.select('team.home.name', new AttributePath(venue_path, 'homename'))) {
      return false;
    }

    if (!this.select('team.visiting.name', new AttributePath(venue_path, 'visname'))) {
      return false;
    }

    this.ids = [
      this.select('team.home.id', new AttributePath(venue_path, 'homeid')),
      this.select('team.visiting.id', new AttributePath(venue_path, 'visid')),
    ];

    this.select('venue.date', new AttributePath(venue_path, 'date'));
    this.select('venue.time', new AttributePath(venue_path, 'time'));
    this.select('venue.location', new AttributePath(venue_path, 'location'));
    this.select('venue.attendance', new AttributePath(venue_path, 'attend'));
    return true;
  }

}

export default SCSportImporter;
