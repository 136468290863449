
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  IconButton,
  Toolbar,
  Tooltip,
  withStyles,
} from '@material-ui/core';

import {
  ChevronDown as ChevronDownIcon,
  ContentDuplicate,
  Download,
  FolderDownload,
  ShareVariant as ShareVariantIcon,
} from 'mdi-material-ui';

import { compose, graphql, Query } from 'react-apollo';
import { formatRelative } from 'date-fns';
import { withRouter } from 'react-router';
import classnames from 'classnames';
import gql from 'graphql-tag';
import parseColor from 'parse-color';

import { Share } from '../../../scoreshotsapi/Share';
import { ShareDialog } from './ShareDialog';
import { PostProgress } from './PostProgress';
import SocialMetrics from './SocialMetrics';
import SendToPhoneDialog from '../../share/SendToPhoneDialog';

import * as Scoreshots from '../../../scoreshotsapi/v1';

// -------------------------------------------------------------------------- //

function FadeRGB(input, a) {
  const parsed = parseColor(input);
  return `rgba(${parsed.rgb[0]}, ${parsed.rgb[1]}, ${parsed.rgb[2]}, ${a})`;
}

// -------------------------------------------------------------------------- //

const GET_POST = gql`
  query getPost($id: ID!) {
    getPost(id: $id) {
      id
      client {
        id
      }
      facebookPosts {
        id
        postId
        message
        page {
          id
          fbId
          name
          accessToken
        }
      }
      twitterPosts {
        id
        postId
        message
        account {
          id
          name
          twitterId
          screen_name
          oauthToken
          oauthTokenSecret
        }
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const ROLES_QUERY = gql`
  query {
    me {
      id
      role {
        id
        name
        restrictCategoryFlag
        categories {
          id
          name
        }
        allowCustomFlag
        allowCustomSaveFlag
        allowCustomFolderSaveFlag
        restrictCustomFlag
        customFolders {
          id
          name
        }
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  root: {
  },
  media: {
    width: '100%',
  },
  actions: {
    display: 'flex',
  },
  expander: {
    marginLeft: 'auto',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  expand_button: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  expand_fade_out: {
    position: 'absolute',
    pointerEvents: 'none',
    left: 0,
    right: 0,
    bottom: 0,
    height: theme.spacing(4),
    background: `linear-gradient(to top, ${FadeRGB(
        theme.palette.background.paper, 1.0,
    )}, ${FadeRGB(
        theme.palette.background.paper, 0.0,
    )})`,
    opacity: 1,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expand_fade_in: {
    opacity: 0,
  },
  expand_icon_out: {
    transform: 'rotate(0)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expand_icon_in: {
    transform: 'rotate(180deg)',
  },
});

// -------------------------------------------------------------------------- //

class Post extends React.Component {
  state = {
    expanded: false,
    share_dialog: false,
    share_scheduled: false,
    share_json: null,
    share_media: null,
    progress_complete: false,
    progress_dialog: false,
    progress_data: null,
    list_expanded: false,
    launching: -1,
    phone: false,
    image: null,
  }

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  }

  launchTemplate = (post, num) => {
    // with no data on the post object to indicate slideshow, we have to load
    // the json blob attached to the post and detect which type it is from that
    this.setState({ launching: num }, () => {
      fetch(`https://ss3-posts.s3.us-east-2.amazonaws.com/${post.client.id}/${post.id}.json`)
          .then((response) => response.json())
          .then((json) => {
            if (json && json.data && json.data.length > 0) {
              this.props.history.push(`/editor/slideshow/post/${post.id}/${num}`);
            } else {
              this.props.history.push(`/editor/post/${post.id}/${num}`);
            }
          });
    });
  }

  /**
   * set phone state to true and loads the img
   * opens the dialog
   */
  openPhoneDialog = async (post) => {
    const json = await this.getPostJson(post);
    const cacheBuster = (+new Date());

    this.setState({
      share_media: post.media,
      share_json: json,
      phone: true,
    });

    fetch(`${post.media.png}?${cacheBuster}`)
        .then((resp) => resp.blob())
        .then((blob) => Scoreshots.ToDataURL(blob))
        .then((img) => this.setState({ image: img }));
  }

  /**
   * set phone state to false
   * closes the dialog
   */
  closePhoneDialog = () => {
    this.setState({ phone: false });
  }

  getPostJson = async (post) => fetch(
      `https://ss3-posts.s3.us-east-2.amazonaws.com/${post.client.id}/${post.id}.json`,
  ).then((response) => response.json())

  getPostDetails() {
    // if we're not expanded, don't bother.
    if (!this.state.expanded) {
      return null;
    }
    return (
        <Query
            query={GET_POST}
            variables={{
              id: this.props.post.id,
            }}
        >
          {
            ({ data }) => {
              if (data.getPost) {
                return (
                    <SocialMetrics post={data.getPost} />
                );
              }
              return <CircularProgress />;
            }
          }
        </Query>
    );
  }

  render() {
    const {
      post, classes, theme, data,
    } = this.props;
    const { launching } = this.state;
    const date = new Date(post.postTime);
    let dateString = formatRelative(date, new Date());
    dateString = dateString.charAt(0).toUpperCase() + dateString.slice(1);
    let addCustomIcon = null;
    let allowCustomFlag = true;
    let allowCustomSaveFlag = true;

    if (data && data.me && data.me.role && data.me.role) {
      allowCustomFlag = data.me.role.allowCustomFlag;
      allowCustomSaveFlag = data.me.role.allowCustomSaveFlag;
    }

    if (allowCustomFlag && allowCustomSaveFlag) {
      addCustomIcon = (
          <Tooltip id="custom-icon" title="Add to Custom Templates">
            <IconButton onClick={() => this.launchTemplate(post, 1)} disabled={launching >= 0}>
              <FolderDownload />
            </IconButton>
          </Tooltip>
      );
    }

    const initials = post.client.name.charAt(0);
    let avatar = (
        <Avatar className={classes.avatar}>
          {initials}
        </Avatar>
    );
    if (post.client.logoBucket && post.client.logoKey) {
      const { logoBucket, logoKey } = post.client;
      avatar = (
          <Avatar
              alt={post.client.name}
              src={`https://${logoBucket}.s3.us-east-2.amazonaws.com/${logoKey}`}
              className={classes.avatar}
          />
      );
    }

    const {
      progress_data,
      progress_dialog,
      share_scheduled,
      list_expanded,
    } = this.state;

    let progress = null;

    if (!share_scheduled) {
      progress = (
          <PostProgress data={progress_data} />
      );
    }

    return (
        <Card className={classes.root}>
          <CardHeader
              avatar={avatar}
              title={post.client.name}
              subheader={`Posted ${dateString}`}
          />
          <img
              src={`https://ss3-posts.s3.us-east-2.amazonaws.com/${post.client.id}/${post.id}.png`}
              alt="Social"
              className={classes.media}
          />
          {/*
        <CardContent>
          <Typography component="p">
            This impressive paella is a perfect party dish and a fun meal to cook together with
            your guests. Add 1 cup of frozen peas along with the mussels, if you like.
          </Typography>
        </CardContent>
        */}
          <CardActions className={classes.actions}>

            <Tooltip id="download-icon" title="Download Image">
              <a href={`https://ss3-posts.s3.us-east-2.amazonaws.com/${post.client.id}/${post.id}.png`}>
                <IconButton>
                  <Download />
                </IconButton>
              </a>
            </Tooltip>

            {
              launching !== 1
                  ? addCustomIcon
                  : <CircularProgress size={48} />
            }

            {
              launching !== 0
                  ? (
                      <Tooltip id="edit-icon" title="Edit a Copy">
                        <IconButton onClick={() => this.launchTemplate(post, 0)} disabled={launching >= 0}>
                          <ContentDuplicate />
                        </IconButton>
                      </Tooltip>
                  )
                  : <CircularProgress size={48} />
            }
            {
              (post.media !== null)
              && (
                  <Tooltip title="Share Again">
                    <IconButton
                        onClick={async () => {
                          const json = await this.getPostJson(post);
                          this.setState({
                            share_dialog: true,
                            share_media: post.media,
                            share_json: json,
                          });
                        }}
                        disabled={launching >= 0}
                    >
                      <ShareVariantIcon />
                    </IconButton>
                  </Tooltip>
              )
            }
            <Share
                onShareStart={(e) => this.handleShareStart(e)}
                onShareUpdate={(e) => this.handleShareUpdate(e)}
                onShareComplete={(e) => this.handleShareComplete(e)}
            >
              {(callback) => (
                  <ShareDialog
                      post={post}
                      media={this.state.share_media}
                      json={this.state.share_json}
                      open={this.state.share_dialog}
                      onClose={(e) => this.share(e, callback)}
                  />
              )}
            </Share>
            {
              (this.state.share_media !== null) ? (

                  <SendToPhoneDialog
                      open={this.state.phone}
                      video={this.state.share_media.mp4}
                      image={this.state.image}
                      onCancel={this.closePhoneDialog}
                      key="send-to-phone"
                      mode="image"
                  />
              ) : null
            }
            <Dialog fullWidth maxWidth="sm" open={progress_dialog}>
              <DialogTitle>
                {this.state.share_scheduled ? 'Scheduling Post' : 'Sharing Post'}
              </DialogTitle>
              <DialogContent>
                {
                  (this.state.share_scheduled)
                  && (
                      <DialogContentText>
                        {
                          (this.state.progress_complete)
                              ? 'Your post has been successfully scheduled. You can view it on the Scheduled tab.'
                              : 'Your post is being scheduled. This should only take a moment.'
                        }
                      </DialogContentText>
                  )
                }
                {
                  (progress_data)
                  && (progress_data.length > 4)
                  && (progress !== null)
                      ? (
                          <>
                            <Collapse
                                collapsedHeight={theme.spacing(32)}
                                style={{ position: 'relative' }}
                                in={list_expanded}
                            >
                              {progress}
                              <div className={classnames(
                                  classes.expand_fade_out,
                                  { [classes.expand_fade_in]: list_expanded },
                              )}
                              />
                            </Collapse>
                            <Toolbar>
                              <Button
                                  className={classes.expand_button}
                                  onClick={() => this.setState({
                                    list_expanded: !list_expanded,
                                  })}
                              >
                                <ChevronDownIcon
                                    color="inherit"
                                    className={classnames(
                                        classes.expand_icon_out,
                                        { [classes.expand_icon_in]: list_expanded },
                                    )}
                                />
                                {list_expanded ? 'Show Less' : 'Show More'}
                              </Button>
                            </Toolbar>
                          </>
                      ) : progress
                }
                {
                  (this.state.share_scheduled)
                  && (!this.state.progress_complete)
                  && (
                      <div style={{ textAlign: 'center' }}>
                        <CircularProgress size={theme.spacing(6)} />
                      </div>
                  )
                }
              </DialogContent>
              <DialogActions>
                {
                  (this.state.share_scheduled)
                  && (
                      <>
                        <Fade in={this.state.progress_complete}>
                          <Button
                              color="primary"
                              onClick={() => (
                                  this.props.history.push('/create/scheduled')
                              )}
                          >
                            See Scheduled Posts
                          </Button>
                        </Fade>
                        <Fade in={this.state.progress_complete}>
                          <Button
                              color="primary"
                              onClick={() => this.setState({
                                progress_dialog: false,
                              })}
                          >
                            Return to Feed
                          </Button>
                        </Fade>
                      </>
                  )
                }
                {
                  (!this.state.share_scheduled)
                  && (
                      <Fade in={this.state.progress_complete}>
                        <Button
                            color="primary"
                            onClick={() => {
                              window.location.reload();
                            }}
                        >
                          Continue
                        </Button>
                      </Fade>
                  )
                }
              </DialogActions>
            </Dialog>
            <div style={{ flex: 1 }} />
            <Tooltip
                id="edit-icon"
                title={this.state.expanded ? 'Less Details' : 'More Details'}
                className={classes.expander}
            >
              <IconButton
                  onClick={this.handleExpandClick}
                  className={classnames(classes.expand, {
                    [classes.expandOpen]: this.state.expanded,
                  })}
              >
                <ChevronDownIcon />
              </IconButton>
            </Tooltip>
          </CardActions>
          <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
            <CardContent>
              {this.getPostDetails()}
            </CardContent>
          </Collapse>
        </Card>
    );
  }

  share = (e, callback = null) => {
    if (!e.sharing) {
      this.setState({ share_dialog: false });
      return;
    }

    const progress_data = [];

    progress_data.push(...e.facebook_pages.map((page) => ({
      type: 'facebook',
      id: page.id,
      facebook_id: page.facebook_id,
      name: page.name,
      status: 'standby',
      post: null,
    })));

    if (e.twitter_post !== null) {
      progress_data.push({
        type: 'twitter',
        id: e.twitter_post.id,
        twitter_id: e.twitter_post.twitter_id,
        name: e.twitter_post.name,
        username: e.twitter_post.username,
        status: 'standby',
        post: null,
      });

      progress_data.push(...e.twitter_retweets.map((retweet) => ({
        type: 'twitter',
        id: retweet.id,
        name: retweet.name,
        username: retweet.username,
        status: 'standby',
        post: null,
      })));
    }

    this.setState({
      share_dialog: false,
      progress_dialog: true,
      share_scheduled: (e.eta !== null),
      progress_data,
    });

    if (callback) {
      callback(e);
    }
  }

  handleShareStart = (e) => {
    // console.log('>>> handleShareStart:', e);
  }

  handleShareUpdate = (e) => {
    // console.log('>>> handleShareUpdate:', e);
    const { progress_data } = this.state;
    const { id, type } = e;

    const index = progress_data.findIndex((data) => (
        data.id === id && data.type === type
    ));

    if (index >= 0) {
      progress_data[index].status = e.status;

      if (e.status === 'delivered') {
        progress_data[index].post = e.post;
      }

      this.forceUpdate();
    }
  }

  handleShareComplete = (e) => {
    // console.log('>>> handleShareComplete:', e);
    this.setState({ progress_complete: true });
  }
}

// -------------------------------------------------------------------------- //

export default compose(
    graphql(ROLES_QUERY),
    withRouter,
    withStyles(STYLES, { withTheme: true }),
)(Post);

// -------------------------------------------------------------------------- //
