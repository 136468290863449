
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Badge,
  ButtonBase,
  Tooltip,
  withStyles,
  Button,
} from '@material-ui/core';
import { ParseColor } from './parse';

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  badge: {
    backgroundColor: theme.palette.error.light,
    height: theme.spacing(2.5),
    position: 'absolute',
    right: 0,
    top: 0,
    transform: 'translate(-25%, 25%)',
    userSelect: 'none',
    width: theme.spacing(2.5),
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  button: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    display: 'inline-flex',
    height: theme.spacing(5),
    margin: theme.spacing(2),
    position: 'relative',
    transition: theme.transitions.create('box-shadow'),
    width: theme.spacing(5),
    '&:focus': {
      boxShadow: `0 0 0 4px ${theme.palette.primary.main}`,
    },
    '&:hover': {
      boxShadow: theme.shadows[4],
    },
  },
  icon: {
    color: theme.palette.grey[700],
    display: 'block',
    height: '100%',
    width: '100%',
  },
});

// -------------------------------------------------------------------------- //

function MakeColorStyle(color) {
  const GRID = 'https://ss3-assets.s3.us-east-2.amazonaws.com/grid.png';
  return `linear-gradient(${color}, ${color}), url('${GRID}')`;
}

// -------------------------------------------------------------------------- //

const Component = (props) => {
  const {
    classes,
    disabled = false,
    opaque = false,
    color = null,
    key = null,
    icon = null,
    tip = null,
    onClick = null,
    onMouseOver = null,
    onDelete = null,
    ariaLabel = ''
  } = props;

  let Icon = null;

  if (icon) {
    Icon = icon;
  }

  let background = null;

  if (color) {
    if (opaque) {
      const { rgb } = ParseColor(color);

      background = MakeColorStyle(
        `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`,
      );
    } else {
      background = MakeColorStyle(color);
    }
  }

  let button = (
    <ButtonBase
      className={classes.button}
      disabled={Boolean(disabled)}
      style={{ background }}
      onMouseOver={(e) => (onMouseOver && onMouseOver(e))}
      onClick={(e) => (onClick && onClick(e))}
      aria-label={ariaLabel}
    >
      {
      (Icon !== null)
      && <Icon className={classes.icon} />
    }
    </ButtonBase>
  );

  if (onDelete) {
    button = (
      <Badge
        color="primary"
        classes={{ badge: classes.badge }}
        onClick={(e) => {
          if (e.target && e.target.nodeName === 'SPAN') {
            onDelete(e);
          }
        }}
        component={Button}
        badgeContent={(
          <Button aria-label={`Delete color ${color}`} style={{ backgroundColor: 'transparent' }}>
            X
          </Button>
        )}
      >
        {button}
      </Badge>
    );
  }

  if (tip) {
    button = (
      <Tooltip title={tip}>
        {button}
      </Tooltip>
    );
  }

  if (key) {
    button = (
      <React.Fragment key={key}>
        {button}
      </React.Fragment>
    );
  }

  return button;
};

// -------------------------------------------------------------------------- //

export const ColorButton = withStyles(STYLES)(Component);

// -------------------------------------------------------------------------- //
