
// -------------------------------------------------------------------------- //

import {
  Account as AccountIcon,
  AccountMultiple as AccountMultipleIcon,
  DotsVerticalCircle  as DotsVerticalCircleIcon,
  Image as ImageIcon,
  StarCircle as StarCircleIcon,
  Video as VideoIcon,
  ImageAlbum as ImageAlbumIcon,
} from 'mdi-material-ui';

import { matchPath } from 'react-router-dom';
import { MediaAssets } from './MediaAssets';
import { StockAssets } from './StockAssets';
import { MediaRosters } from './MediaRosters';

// -------------------------------------------------------------------------- //

// upper_type: used
// url: url for the MediaNav
// path: router path for Media; allows matching params
// icon: icon for the MediaNav
// component: target component for the router

export const MEDIA_TYPES = {
  'photo': {
    key: 'photo',
    pretty_name: 'Photos',
    upper_type: 'PHOTO',
    url: '/media/photo',
    path: '/media/:type(photo)/:directory?',
    icon: ImageIcon,
    component: MediaAssets,
  },
  'video': {
    key: 'video',
    pretty_name: 'Videos',
    upper_type: 'VIDEO',
    url: '/media/video',
    path: '/media/:type(video)/:directory?',
    icon: VideoIcon,
    component: MediaAssets,
  },
  'logo': {
    key: 'logo',
    pretty_name: 'Logos',
    upper_type: 'LOGO',
    url: '/media/logo',
    path: '/media/:type(logo)/:directory?',
    icon: StarCircleIcon,
    component: MediaAssets,
  },
  'cutout': {
    key: 'cutout',
    pretty_name: 'Cutouts',
    upper_type: 'CUTOUT',
    url: '/media/cutout',
    path: '/media/:type(cutout)/:directory?',
    icon: AccountIcon,
    component: MediaAssets,
  },
  'roster': {
    key: 'roster',
    pretty_name: 'Rosters',
    upper_type: 'ROSTER',
    url: '/media/roster',
    path: '/media/:type(roster)/:roster?/:member?',
    icon: AccountMultipleIcon,
    component: MediaRosters,
  },
  'stock': {
    key: 'stock',
    pretty_name: 'Stock Library',
    upper_type: 'STOCK',
    url: '/media/stock',
    path: '/media/:type(stock)/:directory?',
    icon: ImageAlbumIcon,
    component: StockAssets,
  },
  'other': {
    key: 'other',
    pretty_name: 'Other',
    upper_type: 'OTHER',
    url: '/media/other',
    path: '/media/:type(other)/:directory?',
    icon: DotsVerticalCircleIcon,
    component: MediaAssets,
  },
};

// -------------------------------------------------------------------------- //

export function GetMediaType(location) {
  const match = matchPath(
    location.pathname,
    { path: '/media/:type?' }
  );

  if (match === null) {
    return null;
  }

  const { type } = match.params;

  if (type === undefined || !(type in MEDIA_TYPES)) {
    return MEDIA_TYPES.photo;
  }

  return MEDIA_TYPES[type];
}

// -------------------------------------------------------------------------- //
