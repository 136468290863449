
// -------------------------------------------------------------------------- //

import * as React from 'react';

import {
  AppBar,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';

// -------------------------------------------------------------------------- //

const STYLES = () => ({
  app_bar: {
    position: 'relative',
    flex: 0,
  },
  flex: {
    flex: 1,
  },
  root: {
    flex: 1,
    overflow: 'auto',
  },
});

// -------------------------------------------------------------------------- //

class MainNav extends React.Component {

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <AppBar color="primary" elevation={0} className={classes.app_bar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              Admin Dashboard
            </Typography>
          </Toolbar>
        </AppBar>
        <iframe
          title="ScoreShots Dashboard"
          src="https://api.scoreshots.com/reports/dashboard.php"
          className={classes.root}
        />
      </React.Fragment>
    );
  }
}

// -------------------------------------------------------------------------- //

export default withStyles(STYLES)(MainNav);

// -------------------------------------------------------------------------- //
