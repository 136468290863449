import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

export default ({
  input: {
    name, onChange, value, ...restInput
  },
  meta,
  id,
  label,
  options,
  fullWidth = true,
  ...rest
}) => (
  <FormControl fullWidth={fullWidth} {...rest}>
    <InputLabel htmlFor={id}>{label}</InputLabel>
    <Select
      value={value}
      onChange={onChange}
    >
      {
          options.map(option => (
            <MenuItem
              key={option.value}
              value={option.value}
            >
              {option.name}
            </MenuItem>
          ))
        }
    </Select>
  </FormControl>
);
