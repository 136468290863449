
// -------------------------------------------------------------------------- //

export class IWidgetOption {

  _key = '';
  _name = '';
  _value = null;

  constructor(key, name) {
    this._key = key;
    this._name = name;
  }

  get key() { return this._key; }
  get name() { return this._name; }

  get value() { return this._value; }
  set value(x) { this._value = x; }

  render(props) { return null; }

};

// -------------------------------------------------------------------------- //

export const OptionControl = (props) => {
  let { option = null, ...other_props } = props;
  let component = null;

  if (option !== null) {
    component = option.render(other_props);
  }

  return component;
}

// -------------------------------------------------------------------------- //
