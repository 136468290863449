export default {
  Steriliser: 'Squada One',
  Ailerons: 'Six Caps',
  Vikings: 'foreland',
  'Big-Noodle': 'Teko',
  LEVIBRUSH: 'LeviReBrushed',
  Esportica: 'Teko',
  RIVERHACK: 'Rustico',
  'Hudson NY Serif': 'Quantico',
  Hudson: 'Quantico',
  Cintra: 'Doppio One',
  Violenta: 'Teko',
  'Agency FB': 'Wire One',
  'Agency FB Bold': 'Wire One',
  unitedsansrgbd: 'Electrolize',
  unitedsansrghv: 'Electrolize',
  unitedsansrgmd: 'Electrolize',
  'United Sans SemiCond Bold': 'Electrolize',
  'United Sans SemiCond Black': 'Electrolize',
  'United Sans SemiCond Medium': 'Electrolize',
  Brushability: 'Mr Dafoe',
  Emerald: 'Yesteryear',
  Moonstruck: 'Wild Zova Free',
  'Rusty Cola Pen': 'The Dolbak Brush',
  'SuperFly One': 'Wild Youth',
  Pantel: 'kb SickBoy',
  'Nexa Slab': 'Josefin Slab',
  'Dead Stock': 'Trade Winds',
  'Rhinos Rocks': 'Rustico',
  Gin: 'Black Mask',
  'Hand of Sean': 'Sloppy Hand',
  Rockwell: 'Rokkitt',
  'Rockwell Bold': 'Rokkitt',
  Lakeshore: 'Bruss',
  'Tungsten Black': 'Share Tech',
  'Tungsten Condensed': 'Share Tech',
  Stratum1: 'Armata',
  'Gibson Bold': 'Hind',
  'Gibson Semi-Bold': 'Hind',
  'Meta Serif Bold': 'Crimson Text',
  'Meta Serif Bold Italic': 'Crimson Text',
  Fargo: 'Fargo Faro NF',
  'Open Sans Regular': 'Open Sans',
  Disclaimer: 'Disclaimer Plain',
  Rex: 'Rex Bold',
  'Prime-Regular': 'Prime',
  againts_regular: 'Againts',
  BebasNeue_Thin: 'Bebas Neue Thin',
  BebasNeue_Bold: 'Bebas Neue Bold',
  BebasNeue_Book: 'Bebas Neue Book',
  BebasNeue_Regular: 'Bebas Neue Regular',
  BebasNeue_Light: 'Bebas Neue Light',
  'Ruda Solid': 'Ruda',
};
