
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Hidden,
  IconButton,
  InputAdornment,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Snackbar,
  TextField,
  Toolbar,
  Typography,
  withStyles,
  Grow,
} from '@material-ui/core';

import {
  ArrowLeft as ArrowLeftIcon,
  Close as CloseIcon,
  Magnify as MagnifyIcon,
  MenuDown as MenuDownIcon,
} from 'mdi-material-ui';

import gql from 'graphql-tag';
import { compose, graphql, Query, Mutation } from 'react-apollo';
import { withRouter } from 'react-router';
import classnames from 'classnames';
import Dropzone from 'react-dropzone';

import { MEDIA_TYPES } from './mediatypes';
import { MediaNav } from './MediaNav';
import Crumbs from './Crumbs';
import dict from './dict';
import FolderViewer from './FolderViewer';
import ItemControls from './ItemControls';
import ResponsiveGrid from '../layout/ResponsiveGrid';
import ScoreshotsPut from '../../scoreshotsapi/Put';
import SidebarGrid from '../layout/SidebarGrid';

// -------------------------------------------------------------------------- //

const fileBucket = 'ss3-clientmedia';

// -------------------------------------------------------------------------- //

const GET_CATEGORIES = gql`
  query {
    categories {
      id
      name
    }
  }
`;

// -------------------------------------------------------------------------- //

const GET_CLIENT = gql`
  query {
    me {
      id
      firstname
      lastname
      email
      client {
        id
        total_storage
        total_allowed_storage
        subscription_end
        subscription_start
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const FILE_MOVE = gql`
  mutation (
    $files: [ID!]!,
    $folderId: ID,
    $type: MEDIA_TYPE
  ) {
    moveFiles(
      files: $files
      folderId: $folderId
      type: $type
    ) {
      count
    }
  }
`;

// -------------------------------------------------------------------------- //

const FOLDER_MOVE = gql`
  mutation ($folders: [ID!]!, $folderId: ID, $type: MEDIA_TYPE) {
    moveFolders(
      folders: $folders
      folderId: $folderId
      type: $type
    ) {
      count
    }
  }
`;

// -------------------------------------------------------------------------- //

const UPLOAD_FILE = gql`
  mutation (
    $contentType: String!,
    $name: String!,
    $size: Int!,
    $bucket: String!,
    $type: MEDIA_TYPE!,
    $extension: String!,
    $folderId: ID,
    $parentId: ID
  ) {
    createFile(
      contentType: $contentType
      name: $name
      size: $size
      bucket: $bucket
      extension: $extension
      type: $type
      folderId: $folderId
      parentId: $parentId
    ) {
      id
      client {
        id
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const ADD_LABELS = gql`
  mutation (
    $bucket: String!
    $key: String!
    $fileId: ID!
  ) {
    addLabels(
      bucket: $bucket
      key: $key
      fileId: $fileId
    ) {
      name
      confidence
    }
  }
`;

// -------------------------------------------------------------------------- //

const FILE_SEARCH = gql`
  query file_search($type: MEDIA_TYPE, $query: String) {
    fileSearch(
      type: $type,
      name: $query
    ) {
      id
      name
      bucket
      extension
      type
      size
      folder {
        id
      }
      client {
        id
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const GET_CONTENTS = gql`
  query getContents($type: MEDIA_TYPE, $folderId: ID) {
    folders(
      type: $type
      folderId: $folderId
    ) {
      id
      name
      parent {
        name
        id
      }
    }
    files(
      type: $type
      folderId: $folderId
    ) {
      id
      name
      bucket
      extension
      type
      size
      folder {
        id
      }
      client {
        id
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const UPDATE_STORAGE = gql`
 mutation updateStorageData($id: ID!, $total_storage : Float!,$total_allowed_storage : Float!) {
   updateStorageData (
     id: $id
     total_storage : $total_storage
     total_allowed_storage : $total_allowed_storage
   ) {
     id
     total_storage
     total_allowed_storage
   }
 }
`;

// -------------------------------------------------------------------------- //

function GetHumanFileSize(bytes) {
  const threshold = 1024;
  let fixed_point = false;
  let index = 0;

  const units = [
    'B', 'kB', 'MB',
    'GB', 'TB', 'PB',
    'EB', 'ZB', 'YB',
  ];

  while (bytes >= threshold && index < units.length) {
    bytes /= threshold;
    fixed_point = true;
    ++index;
  }

  if (fixed_point) {
    bytes = bytes.toFixed(1);
  }

  return `${bytes} ${units[index]}`;
}

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  app_bar: {
    backgroundColor: theme.palette.primary.light,
    top: 64,
  },
  dropzone: {
    border: 0,
    width: '100%',
    paddingTop: theme.spacing(3),
    minHeight: 'calc(100vh - 261px)',
  },
  flex: {
    flex: 1,
  },
  search_bar: {
    color: 'inherit',
    backgroundColor: 'rgba(255, 255, 255, 0.09)',
    borderRadius: (theme.spacing(0.5)),
    paddingLeft: theme.spacing(1),
    transition: theme.transitions.create('width', {
      duration: theme.transitions.short,
    }),
  },
  search_bar_open: {
    width: theme.spacing(36),
  },
  search_bar_closed: {
    width: theme.spacing(14),
  },
});

// -------------------------------------------------------------------------- //

class Component extends React.Component {

  state = {
    search_query: '',
    search_query_last: null, // last search query made
    search_open: false,
    search_focused: false,
    search_loading: false,
    search_active: false,
    search_files: [],
    selected: [],
    selectedFiles : { },
    selectedFilesSize : 0,
    disableUpload: false,
    uploadFlag: false,
    snackbarOpen: false,
    snackbarMessage: '',
    files: [],
    folders: [],
    type: false,
    uploadDialog: false,
    uploadData : {},
  }

  submitSearchQuery = (unfocus = false, force = false) => {
    const search_query = this.state.search_query.trim();

    if (search_query === this.state.search_query_last && !force) {
      if (unfocus) {
        this.setState({ search_focused: false });
      }

      return;
    }

    if (search_query) {
      let state = {
        search_query_last: search_query,
        search_loading: true,
        search_active: true,
        search_files: [],
        selected: [],
        selectedFiles : { },
        selectedFilesSize : 0,
      };

      if (unfocus) {
        state.search_focused = false;
      }

      this.setState(state, () => {
        let { search_files } = this.props;

        search_files.refetch({
          type: this.getMediaType(),
          query: search_query,
        }).then((result) => {
          this.setState({
            search_loading: false,
            search_files: result.data.fileSearch,
          });
        }).catch(() => {
          this.setState({
            search_loading: false,
            search_files: [],
          });
        });
      });
    } else {
      let state = {
        search_query_last: null,
        search_loading: false,
        search_active: false,
        search_files: [],
      };

      if (unfocus) {
        state.search_focused = false;
      }

      this.setState(state);
    }
  }

  cancelSearch = (keep_selected = false, callback = null) => {
    let state = {
      search_query: '',
      search_query_last: null,
      search_focused: false,
      search_active: false,
      search_loading: false,
      search_files: [],
    };
    if (!keep_selected) {
      state = {
        ...state,
        selected: [],
        selectedFiles : { },
        selectedFilesSize : 0,
      };
    }
    this.setState(state, () => (callback && callback()));
  }

  openSearchBar = () => {
    this.setState({ search_open: true }, () => {
      let search_bar = document.getElementById('mobile-media-search');
      if (search_bar) {
        search_bar.focus();
      }
    });
  }

  closeSearchBar = () => {
    this.setState({ search_open: false });
  }

  render() {
    return (
      <>
        {this.renderAppBar()}
        {this.renderSnackbar()}
        <ResponsiveGrid key="nav">
          <SidebarGrid
            sidebar={(
              <MediaNav drop={this.dropFolder(this)}/>
            )}
            xsFallback={null}
          >
            {this.renderMedia()}
          </SidebarGrid>
        </ResponsiveGrid>
      </>
    );
  }

  renderSnackbar = () => {
    const { classes } = this.props;
    const { snackbarOpen, snackbarMessage } = this.state;

    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={this.handleSnackbarClose}
        message={<span>{snackbarMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={this.handleSnackbarClose}
          >
            <CloseIcon/>
          </IconButton>
        ]}
      />
    );
  }

  renderAppBar = () => {
    const { classes, match } = this.props;
    const { accountExpired } = this.state;
    let { type = 'photo', directory = null } = match.params;
    type = type.toUpperCase();

    const { uploadData } = this.state;
    let total_storage = 0, total_allowed_storage = 0;

    if (uploadData.total_storage) {
      total_storage = GetHumanFileSize(uploadData.total_storage);
    }

    if (uploadData.total_allowed_storage) {
      total_allowed_storage = GetHumanFileSize(uploadData.total_allowed_storage);
    }

    const changeUserStorage = this.setFileSize();

    return (
      <AppBar
        color="primary"
        position="sticky"
        className={classes.app_bar}
      >
        <Hidden only="xs"> {/* desktop */}
          <ResponsiveGrid>
            <Toolbar disableGutters>
              <SidebarGrid disablePaddingTop style={{ alignItems: 'center' }}>
                <div style={{ display: 'flex', height: 48, alignItems: 'center' }}>
                  <Crumbs
                    type={type}
                    folderId={directory}
                    history={this.props.history}
                    drop={this.dropFolder(this)}
                    dragOver={this.dragOver(this)}
                  />
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      this.submitSearchQuery(false);
                    }}
                  >
                    <TextField
                      placeholder="Search"
                      type="text"
                      value={this.state.search_query}
                      onChange={(e) => this.setState({ search_query: e.target.value })}
                      onFocus={(e) => { e.target.select(); this.setState({ search_focused: true }); }}
                      onBlur={() => this.submitSearchQuery(true)}
                      InputProps={{
                        disableUnderline: true,
                        className: classnames(
                          classes.search_bar,
                          (this.state.search_focused || this.state.search_query.trim())
                          ? classes.search_bar_open : classes.search_bar_closed
                        ),
                        startAdornment: (
                          <InputAdornment position="start">
                            <MagnifyIcon color="inherit"/>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          this.state.search_query &&
                          <InputAdornment position="end">
                            <IconButton
                              color="inherit"
                              aria-label="Clear Search"
                              onClick={() => this.cancelSearch()}
                            >
                              <CloseIcon/>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </form>
                  <Button color="inherit" aria-label="File Size">
                    {total_storage} / {total_allowed_storage}
                  </Button>
                  {
                    !accountExpired &&
                    <ItemControls
                      key={'fullscreen'}
                      type={this.state.type}
                      setSelected={this.setSelected(this)}
                      selected={this.state.selected}
                      files={this.state.files}
                      mediaType={type}
                      parentFolder={directory}
                      uploader={() => { this.dropzone.open(); }}
                      changeUserStorage={ () => this.updateUserStorage(changeUserStorage)}
                      searching={this.state.search_active}
                      onGoToFolder={() => this.goToFolder()}
                    />
                  }
                </div>
              </SidebarGrid>
            </Toolbar>
          </ResponsiveGrid>
        </Hidden>
        <Hidden smUp> {/* mobile */}
          <Grow in={this.state.search_open}>
            <Toolbar>
              <IconButton color="inherit" onClick={() => this.closeSearchBar()}>
                <ArrowLeftIcon/>
              </IconButton>
              <form
                style={{ width: '100%' }}
                onSubmit={(e) => {
                  e.preventDefault();
                  this.submitSearchQuery(false);
                }}
              >
                <TextField
                  fullWidth
                  placeholder="Search"
                  inputProps={{ id: 'mobile-media-search' }}
                  value={this.state.search_query}
                  onChange={(e) => this.setState({ search_query: e.target.value })}
                  onBlur={() => this.submitSearchQuery(true)}
                  InputProps={{
                    disableUnderline: true,
                    className: classes.search_bar,
                    startAdornment: (
                      <InputAdornment position="start">
                        <MagnifyIcon color="inherit"/>
                      </InputAdornment>
                    )
                  }}
                />
              </form>
            </Toolbar>
          </Grow>
          <Grow in={!this.state.search_open}>
            <Toolbar style={{ top: 0, position: 'absolute', width: '100%' }}>
              <Button color="inherit" onClick={(e) => this.openMobileMenu(e.currentTarget)}>
                {dict[type].prettyName}
                <MenuDownIcon/>
              </Button>
              <div className={classes.flex}/>
              <IconButton color="inherit" onClick={() => this.openSearchBar()}>
                <MagnifyIcon/>
              </IconButton>
              {
                !accountExpired &&
                <ItemControls
                  type={this.state.type}
                  setSelected={this.setSelected(this)}
                  selected={this.state.selected}
                  files={this.state.files}
                  mediaType={type}
                  parentFolder={directory}
                  uploader={() => { this.dropzone.open(); }}
                />
              }
            </Toolbar>
          </Grow>
          <Menu
            anchorEl={this.state.anchor_el}
            open={Boolean(this.state.anchor_el) && this.state.anchor_menu === 'mobile'}
            onClose={() => this.closeMobileMenu()}
          >
          {Object.keys(MEDIA_TYPES).map((key, index) => {
            const Icon = MEDIA_TYPES[key].icon;

            return (
              <MenuItem
                key={index}
                selected={MEDIA_TYPES[key].upper_type === type}
                onClick={() => this.closeMobileMenu(MEDIA_TYPES[key].url)}
              >
                <ListItemIcon>
                  <Icon/>
                </ListItemIcon>
                <ListItemText>
                  {MEDIA_TYPES[key].pretty_name}
                </ListItemText>
              </MenuItem>
            );
          })}
          </Menu>
        </Hidden>
        {this.state.uploadFlag && <LinearProgress color="secondary"/>}
        <Dialog
          onClose={() => this.setState({uploadDialog : false})}
          aria-labelledby="customized-dialog-title"
          open={this.state.uploadDialog}
        >
          <DialogTitle id="customized-dialog-title" onClose={() => this.setState({uploadDialog : false})}>
            Out of storage...
          </DialogTitle>
          <DialogContent>
            <Typography gutterBottom>
              This account has exceeded its storage limits 0f { (this.state.uploadData.total_allowed_storage / 1000000) } GB.
              Please contact our team to increase your data limit.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({uploadDialog : false})} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </AppBar>
    );
  }

  goToFolder = () => {
    const { selected, search_files } = this.state;

    if (selected && selected.length === 1) {
      const files = (
        search_files.filter(file => (file.id === selected[0]))
      );

      if (files && files.length === 1) {
        this.cancelSearch(true, () => {
          let { history } = this.props;
          const { type } = this.props.match.params;

          if (files[0].folder) {
            history.push(`/media/${type}/${files[0].folder.id}`);
          } else {
            history.push(`/media/${type}`);
          }
        });
      }
    }
  }

  renderMedia = () => {
    const { classes, match } = this.props;
    const { selected, disableUpload } = this.state;
    const { directory, type } = match.params;
    const cleanType = (type || 'photo');
    const upperType = cleanType.toUpperCase();
    const cleanDirectory = (directory || null);
    let variables = {};

    if (cleanDirectory) {
      variables.folderId = cleanDirectory;
    } else {
      variables.type = upperType;
    }

    if (this.props.data && this.props.data.refetch) {
      this.props.data.refetch(variables);
    }

    if (this.state.search_active) {
      return (
        <React.Fragment>
          <FolderViewer
            files={this.state.search_files}
            folders={[]}
            setSelected={this.setSelected(this)}
            setSelectedFiles={this.setSelectedFiles}
            selected={selected}
          />
          {
            this.state.search_loading &&
            <CircularProgress size={64} style={{ marginTop: '30px' }}/>
          }
        </React.Fragment>
      )
    } else {
      return (
        <Query
          query={GET_CONTENTS}
          variables={variables}
          fetchPolicy="cache-and-network"
        >
          {({ data, refetch }) => (
            <Mutation mutation={ADD_LABELS}>
              {addLabels => (
                <Mutation mutation={UPLOAD_FILE}>
                  {mutation => (
                    <Dropzone
                      disableClick
                      disabled={disableUpload}
                      className={classes.dropzone}
                      onDrop={(e) => this.onDrop(mutation, addLabels, refetch, e)}
                      accept={dict[upperType].allowedTypes.join(',')}
                      ref={(node) => { this.dropzone = node; }}
                    >
                      <FolderViewer
                        files={data.files}
                        folders={data.folders}
                        type={type}
                        current_folder={this.getCurrFolder()}
                        drag={this.drag(this)}
                        dragEnd={this.dragEnd(this)}
                        drop={this.dropFolder(this)}
                        dragOver={this.dragOver(this)}
                        setSelected={this.setSelected(this)}
                        setSelectedFiles={this.setSelectedFiles}
                        selected={selected}
                        paramDir={match.params.directory}
                      />
                    </Dropzone>
                  )}
                </Mutation>
              )}
            </Mutation>
          )}
        </Query>
      );
    }

  }

  submitSearchQuery = (unfocus = false, force = false) => {
    const search_query = this.state.search_query.trim();

    if (search_query === this.state.search_query_last && !force) {
      if (unfocus) {
        this.setState({ search_focused: false });
      }

      return;
    }

    if (search_query) {
      let state = {
        search_query_last: search_query,
        search_loading: true,
        search_active: true,
        search_files: [],
        selected: [],
        selectedFiles : { },
        selectedFilesSize : 0,
      };

      if (unfocus) {
        state.search_focused = false;
      }

      this.setState(state, () => {
        let { search_files } = this.props;

        search_files.refetch({
          type: this.getMediaType(),
          query: search_query,
        }).then((result) => {
          this.setState({
            search_loading: false,
            search_files: result.data.fileSearch,
          });
        }).catch(() => {
          this.setState({
            search_loading: false,
            search_files: [],
          });
        });
      });
    } else {
      let state = {
        search_query_last: null,
        search_loading: false,
        search_active: false,
        search_files: [],
      };

      if (unfocus) {
        state.search_focused = false;
      }

      this.setState(state);
    }
  }

  cancelSearch = (keep_selected = false, callback = null) => {
    let state = {
      search_query: '',
      search_query_last: null,
      search_focused: false,
      search_active: false,
      search_loading: false,
      search_files: [],
    };

    if (!keep_selected) {
      state = {
        ...state,
        selected: [],
        selectedFiles : { },
        selectedFilesSize : 0,
      };
    }

    this.setState(state, () => (callback && callback()));
  }

  openSearchBar = () => {
    this.setState({ search_open: true }, () => {
      let search_bar = document.getElementById('mobile-media-search');

      if (search_bar) {
        search_bar.focus();
      }
    });
  }

  closeSearchBar = () => {
    this.setState({ search_open: false });
  }

  getMediaType = (props = null) => {
    if (props === null) {
      props = this.props;
    }

    const { type } = props.match.params;
    return (type || 'photo').toUpperCase();
  }

  getCurrFolder = () => {
    return (this.props.match.params.directory || null);
  }

  componentDidUpdate(previous_props) {
    const media_type = this.getMediaType();

    if (media_type !== this.getMediaType(previous_props)) {
      if (media_type !== 'ROSTER') {
        this.submitSearchQuery(true, true);
      } else {
        this.cancelSearch(false);
      }
    }
  }

  componentDidMount() {
    this.initialize();
  }
  expirationDateCheck = ( expireDate, hrsInMillis ) => {
    // const currentDate = new Date(); // now
    const expirationDate = new Date(expireDate); // expiration date YYYY-MM-DD
    expirationDate.setTime(expirationDate.getTime() + hrsInMillis);
    // return currentDate > expirationDate;
    return false;
  }

  initialize = () => {
    if (this.props && this.props.getClient) {
      this.props.getClient.refetch().then(r => {
        const accountExpired = this.expirationDateCheck(r.data.me.client.subscription_end, 20 * 1000 * 60 * 60); // account expires at 8pm
        let uploadData = {
          id: r.data.me.client.id,
          total_allowed_storage: r.data.me.client.total_allowed_storage,
          total_storage: r.data.me.client.total_storage
        };

        if (!uploadData.total_allowed_storage) {
          uploadData.total_allowed_storage = 5000000; // max storage for this user 5GB.
        }

        if (!uploadData.total_storage) {
          uploadData.total_storage = 0; // storage the user has used.
        }

        this.setState({uploadData, accountExpired});
      });
    }
  }

    getYear() {
        return new Date().getFullYear();
    }

  onDrop = (createFile, addLabels, callback, accepted) => {
    if (accepted.length > 0) {
      const { type } = this.props.match.params;
      const cleanType = type || 'photo';
      const upperType = cleanType.toUpperCase();
      this.fileIterator(accepted, 0, upperType, createFile, addLabels, callback);
    }
  }

  setSelectedFiles = (fileObject) => {
     let selectedFiles = this.state.selectedFiles;
     selectedFiles[fileObject.id] = {size : fileObject.size};
     this.setState({selectedFiles});
  }

  setFileSize = () => {
    let selectedFiles = this.state.selectedFiles;
    let selected = this.state.selected;
    let uploadData = {...this.state.uploadData};
    let fileSize = 0;

    for (let i = 0 ; i < selected.length; ++i) {
      let key = selected[i];

      if (key in selectedFiles) {
        fileSize += Number(selectedFiles[key].size);
      }
    }

    if('__typename' in uploadData) {
      delete uploadData['__typename'];
    }

    let number = uploadData['total_storage'] - fileSize;
    uploadData['total_storage'] = number > 0 ? number : 0;
    return uploadData;
  }

  setSelected = self => (selected, type) => (e) => {
    let shifting = 0;
    let controlling = 0;

    if (e) {
      shifting = e.shiftKey;
      controlling = e.ctrlKey || e.metaKey;
      e.stopPropagation();
    }

    // shift and control only have an effect if the types match
    // special Actions checks to see if anything special should be done.
    const specialActions = (shifting || controlling) && type === self.state.type;
    // if selected is false, this is easy.
    if (selected === false) {
      self.setState({
        selected: [],
        type: false,
        selectedFiles : {}
      });
    } else if (!specialActions && self.state.selected.indexOf(selected.id) === -1) {
      // if no special actions, this is easy.
      self.setState({
        selected: [selected.id],
        type,
      });
    } else if (
      !shifting &&
      !controlling &&
      self.state.selected.length === 1 &&
      self.state.selected.indexOf(selected.id) !== -1 &&
      type === 'folder'
    ) {
      // if there's no shift key or control key and we're clicking on a selected
      // folder, (and there's only one selected folder), directory traversal
      let mediaType = self.props.match.params.type;
      mediaType = mediaType || 'photo';
      this.props.history.push(`/media/${mediaType}/${selected.id}`);
      self.setState({
        selected: [],
        type: false,
        selectedFiles : {}
      });
    } else if (controlling) {
      // control key adds to selections, or removes
      if (self.state.selected.indexOf(selected.id) === -1) {
        self.state.selected.push(selected.id);
        self.setState({
          selected: self.state.selected,
        });
      } else {
        self.state.selected.splice(self.state.selected.indexOf(selected.id), 1);
        self.setState({
          selected: self.state.selected,
        });
      }
    } else if (shifting) {
      // shift key expands selection in that direction.
      let minSelected;
      let maxSelected;
      let selectionIndex;
      const typeKey = `${type}s`;
      let items;

      if (self.props.data && self.props.data[typeKey]) {
        items = self.props.data[typeKey];

        items.forEach((val, index) => {
          // if we find the index of our new selection
          if (selected.id === val.id) {
            selectionIndex = index;
          }

          if (self.state.selected.indexOf(val.id) !== -1) {
            if (!minSelected) {
              minSelected = index;
            }
            maxSelected = index;
          }
        });
      }

      if (selectionIndex !== null) {
        // we now have the min selected, the max selected, and the current selection.
        if (maxSelected < selectionIndex) {
          // select up.
          for (let index = maxSelected + 1; index <= selectionIndex; index += 1) {
            self.state.selected.push(items[index].id);
          }
        }

        if (minSelected > selectionIndex) {
          // select down.
          for (let index = minSelected - 1; index >= selectionIndex; index -= 1) {
            self.state.selected.push(items[index].id);
          }
        }

        self.setState({
          selected: self.state.selected,
        });
      }
    }
  }


  updateArrays = () => (files, folders) => {
    this.setState({
      files,
      folders,
    });
  }

  drag = () => (entity, type) => (e) => {
    let { selected } = this.state;

    if (this.state.selected.indexOf(entity.id) === -1) {
      selected = [entity.id];
    }

    let mediaType = this.props.match.params.type || 'photo';

    e.dataTransfer.setData('text/plain', JSON.stringify({
      selected,
      type,
      oldType: mediaType.toUpperCase(),
      parent_folder: e.parent_folder,
    }));

    this.setState({ disableUpload: true });
  }

  dragEnd = self => () => {
    self.setState({ disableUpload: false });
  }

  dragOver = () => (e) => {
    e.preventDefault();
  }

  dropFolder = self => (folderId = null, type = null) => (e) => {
    const { directory } = this.props.match.params;
    e.preventDefault();
    self.setState({ search_active: true, search_loading: true });
    const data = JSON.parse(e.dataTransfer.getData('text/plain'));

    if (type !== null) {
      type = type.toUpperCase();
    }

    if (data.type === 'folder') {
      let folders = [...data.selected];

      if (data.parent_folder !== null) {
        folders = folders.filter(id => id !== data.parent_folder);
      }

       self.props.moveFolders({
        variables: {
          folderId,
          type,
          folders,
        },
        refetchQueries: ['getContents', {
          query: gql`
            query ($folderId: ID,$type: MEDIA_TYPE!){
              folders(
                type: $type
                folderId: $folderId
              ) {
                id
                name
              }
            }
          `,
          variables: {
            directory,
            type: (type || 'photo').toUpperCase(),
          },
        }],
      }).then(r => {
         self.setState({ search_active: false, search_loading: false });
      });
    } else if (data.type === 'file') {
      self.props.moveFiles({
        variables: {
          folderId,
          type,
          files: data.selected,
        },
        refetchQueries: ['getContents', {
          query: gql`
            query ($folderId: ID,$type: MEDIA_TYPE!){
              files(
                type: $type
                folderId: $folderId
              ) {
                id
                name
              }
            }
          `,
          variables: {
            directory,
            type: (type || 'photo').toUpperCase(),
          },
        }],
      }).then(r => {
        self.setState({ search_active: false, search_loading: false });
      });
    }
  }

  canUpload = (uploadData,file) => {
    let _obj = {...uploadData};
    _obj.total_storage += file.size;

    if( _obj.total_storage > _obj.total_allowed_storage) {
      return {error : ""}
    }

    if( '__typename' in _obj) {
      delete _obj['__typename'];
    }

    return _obj;
  }

  fileIterator(files, index, type, createFile, addLabels, callback) {
    const self = this;
    const file = files[index];
    const splitFileName = file.name.split('.');
    const folderId = this.props.match.params.directory;
    this.setState({ uploadFlag: true });
    let canUpload = this.canUpload(this.state.uploadData,file);

    if( 'error' in canUpload ){
      // out of space
      this.setState({uploadDialog : true});
      return;
    }
    const parentId = null;

    const _myVariables = {
      contentType: file.type,
      name: splitFileName[0],
      size: file.size,
      bucket: fileBucket,
      type,
      extension: splitFileName[splitFileName.length - 1],
      folderId,
      parentId,
    }

    createFile({
      variables: _myVariables,
    }).then((response) => {
      const newFile = response.data.createFile;
      let action = new ScoreshotsPut(
        fileBucket,
        `${newFile.id}.${splitFileName[splitFileName.length - 1]}`
      );

      action.put(file, file.type, () => {
        // also, need to get label recognition data
        if (type !== 'VIDEO') {
          addLabels({
            variables: {
              bucket: fileBucket,
              key: `${newFile.client.id}/${newFile.id}.${splitFileName[splitFileName.length - 1]}`,
              fileId: newFile.id,
            },
          });
        }
        if (index === files.length - 1) {
          window.Intercom('trackEvent','add-files');
          callback();
        } else {
          self.fileIterator(files, index + 1, type, createFile, addLabels, callback);
        }
      })
        .then(() => {
          const state = { uploadFlag: false, snackbarOpen: true };
          state.snackbarMessage = 'Uploaded media successfully';
          this.setState(state);
        });

      // change user limits
      this.updateUserStorage(canUpload);
    })
    .catch(() => {
      const state = { uploadFlag: false, snackbarOpen: true };
      state.snackbarMessage = 'Failed to upload media';
      this.setState(state);
    });
  }

  updateUserStorage = _variables => this.props.updateStorage({variables : _variables })
                                      .then( r => this.setState({uploadData : r.data.updateStorageData}));

  handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ snackbarOpen: false });
  }

  openMobileMenu = (anchor_el) => {
    this.setState({ anchor_el, anchor_menu: 'mobile' });
  };

  closeMobileMenu = (url) => {
    const { history } = this.props;

    this.setState({ anchor_el: null }, () => {
      if (url) {
        history.push(url);
      }
    });
  }

};

// -------------------------------------------------------------------------- //

export const MediaAssets = compose(
  graphql(GET_CLIENT, { name: 'getClient' }),
  graphql(UPDATE_STORAGE, { name: 'updateStorage' }),
  graphql(FILE_MOVE, { name: 'moveFiles' }),
  graphql(FOLDER_MOVE, { name: 'moveFolders' }),
  graphql(GET_CATEGORIES, { name: 'category_list' }),
  graphql(FILE_SEARCH, { name: 'search_files' }),
  withRouter,
  withStyles(STYLES),
)(Component);

// -------------------------------------------------------------------------- //
