import parseColor from 'parse-color';

class SliderBar {
  constructor(fabric) {
    this.class = fabric.util.createClass(fabric.Object, {
      type: 'SliderBar',
      fontFamily: 'Roboto',
      fontColor: '#000',
      sliderBarValue: 0,
      sliderBarValueMax: 0,
      sliderBarDirection: 'ltr',

      stateProperties: fabric.Object.prototype.stateProperties.concat(
        'fontFamily',
        'fontColor',
        'sliderBarValue',
        'sliderBarValueMax',
        'sliderBarDirection',
      ),

      cacheProperties: fabric.Object.prototype.cacheProperties.concat(
        'angle',
        'fontFamily',
        'fontColor',
        'sliderBarValue',
        'sliderBarValueMax',
        'sliderBarDirection',
      ),

      initialize(options = {}) {
        this.callSuper('initialize', options);
        this.on('scaled', (ev) => {
          // as per lily's request: the line width should scale down
          // when resized via the corners as opposed to the edges
          switch (ev.transform.corner) {
            case 'tl':
            case 'tr':
            case 'bl':
            case 'br': {
              const scalar = (ev.transform.scaleY / this.scaleY);
              this.set('strokeWidth', (this.strokeWidth / scalar));
            }
              break;
            default:
              break;
          }
        });
        this.on('rotated', () => {
          // FIXME adding 'angle' to 'cacheProperties' doesn't dirty
          this.dirty = true;
        });
        this.perPixelTargetFind = false;
      },

      getColors() {
        const output = [];

        const method = (key, value) => {
          const { rgb } = parseColor(value);
          const a = parseColor(this.get(key)).rgba[3];
          this.set(key, `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${a})`);
        };

        if (this.stroke && this.strokeWidth > 0) {
          output.push({
            type: this.type,
            color: this.stroke,
            object: this,
            property: 'stroke',
            method,
          });
        }

        if (this.fill && this.fill !== 'transparent') {
          output.push({
            type: this.type,
            color: this.fill,
            object: this,
            property: 'fill',
            method,
          });
        }

        if (this.fontColor && this.fontColor !== 'transparent') {
          output.push({
            type: this.type,
            color: this.fontColor,
            object: this,
            property: 'fontColor',
            method,
          });
        }

        return output;
      },

      toObject(propertiesToInclude) {
        propertiesToInclude = [
          ...propertiesToInclude,
          'fontFamily',
          'fontColor',
          'sliderBarValue',
          'sliderBarValueMax',
          'sliderBarDirection',
        ];

        return fabric.util.object.extend(
          this.callSuper('toObject', propertiesToInclude), {
            fontFamily: this.fontFamily,
            fontColor: this.fontColor,
            sliderBarValue: this.sliderBarValue,
            sliderBarValueMax: this.sliderBarValueMax,
            sliderBarDirection: this.sliderBarDirection,
          },
        );
      },

      _render(ctx) {
        ctx.save();
        const scaledWidth = (this.width * this.scaleX);
        const scaledHeight = (this.height * this.scaleY);
        ctx.scale((1.0 / this.scaleX), (1.0 / this.scaleY));
        ctx.beginPath();
        ctx.moveTo(-(scaledWidth / 2), 0);
        ctx.lineTo((scaledWidth / 2), 0);
        ctx.closePath();
        ctx.stroke();

        let value = 0.5;

        if (
          (this.sliderBarValue !== null)
          && (this.sliderBarValueMax !== null)
          && (this.sliderBarValueMax > 0)
        ) {
          value = (this.sliderBarValue / this.sliderBarValueMax);
        }

        const factor = (this.sliderBarDirection === 'rtl' ? (1.0 - value) : value);
        const translateX = (factor * 2.0 - 1.0);
        ctx.beginPath();
        const circleRadius = (scaledHeight / 2);
        const circleSpan = ((scaledWidth - circleRadius * 2) / 2);
        ctx.arc((translateX * circleSpan), 0, circleRadius, 0, (2 * Math.PI));
        ctx.closePath();
        ctx.fill();

        ctx.font = [
          '30px',
          (
            this.fontFamily === undefined
            || this.fontFamily.indexOf('\'') >= 0
            || this.fontFamily.indexOf('"') >= 0
              ? this.fontFamily
              : `"${this.fontFamily}"`
          ),
        ].join(' ');

        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = this.fontColor;
        ctx.scale(this.scaleY, this.scaleY);
        ctx.translate((translateX * circleSpan / this.scaleY), 0);
        ctx.rotate(-this.angle * Math.PI / 180);
        ctx.fillText((this.sliderBarValue || 0).toString(), 0, 0);
        ctx.restore();
      },
    });

    this.fromObject = (object, callback) => fabric.Object._fromObject('SliderBar', object, callback);
  }
}

export default SliderBar;
