
// -------------------------------------------------------------------------- //

import React from 'react';

import { withStyles } from '@material-ui/core';

// -------------------------------------------------------------------------- //

const DOT_SIZE           = 6;
const DOT_COLOR          = '#888';

const RING_SIZE          = 48;
const RING_WIDTH         = 6;
const RING_BORDER_SIZE   = 1;
const RING_BORDER_COLOR  = '#FFF';

// -------------------------------------------------------------------------- //

const STYLES = () => ({
  dot: {
    borderRadius: '50%',
    boxShadow: (
      `0 0 0 1.5px ${DOT_COLOR},`              +
      `inset 0 0 1px 1px rgba(0, 0, 0, 0.3),`  +
      `0 0 1px 2px rgba(0, 0, 0, 0.4)`
    ),
    height: DOT_SIZE,
    pointerEvents: 'none',
    position: 'fixed',
    transform: 'translate(-50%, -50%)',
    width: DOT_SIZE,
  },
  ring_outline: {
    border: `8px solid ${RING_BORDER_COLOR}`,
    borderRadius: '100%',
    height: (RING_SIZE + RING_BORDER_SIZE * 2.0),
    left: (DOT_SIZE / 2.0),
    pointerEvents: 'none',
    position: 'fixed',
    top: (DOT_SIZE / 2.0),
    transform: 'translate(-50%, -50%)',
    width: (RING_SIZE + RING_BORDER_SIZE * 2.0),
  },
  ring_top: {
    borderTopLeftRadius: (RING_SIZE / 2.0 + RING_WIDTH),
    borderTopRightRadius: (RING_SIZE / 2.0 + RING_WIDTH),
    borderStyle: 'solid solid none solid',
    borderWidth: RING_WIDTH,
    height: (RING_SIZE / 2.0),
    left: (DOT_SIZE / 2.0),
    pointerEvents: 'none',
    position: 'fixed',
    top: (DOT_SIZE / 2.0),
    transform: 'translate(-50%, -100%)',
    width: RING_SIZE,
  },
  ring_bottom: {
    borderBottomLeftRadius: (RING_SIZE / 2.0 + RING_WIDTH),
    borderBottomRightRadius: (RING_SIZE / 2.0 + RING_WIDTH),
    borderStyle: 'none solid solid solid',
    borderWidth: RING_WIDTH,
    height: (RING_SIZE / 2.0),
    left: (DOT_SIZE / 2.0),
    pointerEvents: 'none',
    position: 'fixed',
    top: (DOT_SIZE / 2.0),
    transform: 'translate(-50%, 0%)',
    width: RING_SIZE,
  },
});

// -------------------------------------------------------------------------- //

const Component = (props) => {
  const { classes, disableCursor } = props;

  if (disableCursor) {
    return null;
  }

  const { left, top } = props;
  let { topColor, bottomColor, disableRing } = props;

  if (topColor && !bottomColor) {
    bottomColor = topColor;
  } else if (!topColor && bottomColor) {
    topColor = bottomColor;
  } else if (!topColor && !bottomColor) {
    disableRing = true;
  }

  if (!disableRing) {
    if (typeof topColor === 'function') {
      topColor = topColor();
    }

    if (typeof bottomColor === 'function') {
      bottomColor = bottomColor();
    }
  }

  return (
    <div
      className={classes.dot}
      style={{ left, top }}
    >
    {
      (!disableRing) &&
      <>
        <div className={classes.ring_outline}/>
        <div className={classes.ring_top}
          style={{ borderColor: topColor }}
        />
        <div className={classes.ring_bottom}
          style={{ borderColor: bottomColor }}
        />
      </>
    }
    </div>
  );
};

// -------------------------------------------------------------------------- //

export const ColorCursor = withStyles(STYLES)(Component);

// -------------------------------------------------------------------------- //
