import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Crumbs from './Crumbs';
import Slider from 'rc-slider';
import { OptionControl } from '../../../canvas/widgets/option';
import { Grid } from '@material-ui/core';

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
  },
  heading: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    fontSize: '12px',
    textTransform: 'uppercase',
  }
});

class Widgets extends Component {

  state = {
    page: 'picker',
    widget: null,
    widget_count: null,
  }

  setPage = (self, page) => () => {
    self.setState({ page });
  }

  setWidget = (widget) => {
    this.setState({ page: 'widget', widget });
  }

  setNumWidgets = (e) => {
    let { widget } = this.state;
    let updowncount = 'down';
    if (widget.count < parseInt(e.target.value)) {
      updowncount = 'up';
    }
    widget.count = parseInt(e.target.value);
    if (widget.widgetFlow !== 'fill-vertical' && widget.widgetFlow !== 'fill-horizontal' && widget.widgetFlow !== 'horizontal' && widget.widgetFlow !== 'vertical' && widget.widgetFlow !== 'triangle-horizontal') {
      let spacing = widget.spacing;
      let fractionalNumColumns = 0;
      let numColumns = 0;
      let numWidgetsPerColumnFractional = 0;
      let numWidgetsPerColumn = 0;
      let newLayoutHeight = 0;
      let spacingminus = 0;

      if (spacing > 64) { spacing = 64; }
      if (spacing < -3) { spacing = -2; }

      if (updowncount === 'up') {
        do {
          spacing = spacing - spacingminus;
          fractionalNumColumns = Math.max(widget.width / (widget.widgetWidth + spacing), 1);
          numColumns = Math.max(1, Math.floor(fractionalNumColumns));
          numWidgetsPerColumnFractional = widget.count / numColumns;
          numWidgetsPerColumn = Math.max(Math.ceil(numWidgetsPerColumnFractional), 1);
          newLayoutHeight = (spacing * numWidgetsPerColumn) + (widget.widgetWidth * numWidgetsPerColumn);
          spacingminus = 1;
        } while ((newLayoutHeight > widget.height) && spacing >= -3 && spacing <= 64);
        this.setWidgetSpacing(spacing);
        this.setWidgetHSpacing(spacing);
        this.setWidgetVSpacing(spacing);
      } else {
        do {
          spacing = spacing + spacingminus;
          fractionalNumColumns = Math.max(widget.width / (widget.widgetWidth + spacing), 1);
          numColumns = Math.max(1, Math.floor(fractionalNumColumns));
          numWidgetsPerColumnFractional = widget.count / numColumns;
          numWidgetsPerColumn = Math.max(Math.ceil(numWidgetsPerColumnFractional), 1);
          newLayoutHeight = (spacing * numWidgetsPerColumn) + (widget.widgetWidth * numWidgetsPerColumn);
          spacingminus = 1;
        } while ((newLayoutHeight < widget.height) && spacing >= -3 && spacing <= 64);
        this.setWidgetSpacing(spacing);
        this.setWidgetHSpacing(spacing);
        this.setWidgetVSpacing(spacing);
      }
    }
    widget.resize();
    this.forceUpdate();
  }

  setWidgetSpacing = (e) => {
    let { widget } = this.state
    widget.spacing = e;
    widget.hspacing = e;
    widget.vspacing = e;
    widget.layout();
    this.forceUpdate();
  }

  setWidgetHSpacing = (e) => {
    let { widget } = this.state
    widget.hspacing = e;
    widget.layout();
    this.forceUpdate();
  }

  setWidgetVSpacing = (e) => {
    let { widget } = this.state
    widget.vspacing = e;
    widget.layout();
    this.forceUpdate();
  }

  getWidgets = () => {
    let { canvas } = this.props;

    if (!canvas) {
      return [];
    }

    return [
      ...canvas.getObjects('widget-box'),
      ...canvas.getObjects('widgetLayout'),
    ];
  }

  renderWidgets = () => {
    let widgets = this.getWidgets();

    return {
      content: (
        <List>
        {widgets.map((widget, index) => (
          <ListItem button key={index} onClick={() => this.setWidget(widget)}>
            <ListItemText primary={widget.name || `Widget ${index + 1}` } />
          </ListItem>
        ))}
        </List>
      ),
      title: 'Widgets',
      back: null
    }
  }

  resizeWidget = (widget_box, count) => {
    widget_box.resize(count).then(() => {
      widget_box.canvas.renderAll();
      this.setState({ widget_count: null });
    });
  }

  renderWidgetEditor = () => {
    let { classes } = this.props;
    let { widget } = this.state;

    if (widget.type === 'widget-box') {
      let options = null;

      if (widget.loaded) {
        options = (
          <>
          {widget.widget_flow.options.map((option, index) => (
            <OptionControl
              key={index} option={option}
              onValueChanged={() => {
                widget.layout();
                this.forceUpdate();
                widget.canvas.renderAll();
              }}
              onAfterChange={() => {
                widget.layout();
                this.forceUpdate();
                widget.canvas.renderAll();
              }}
            />
          ))}
          </>
        );
      }

      return {
        content: (
          <div className={classes.root}>
            <Typography variant="h6" gutterBottom>
              {widget.name || 'Widget'}
            </Typography>
            <Grid
              container spacing={2}
              alignItems="center"
            >
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  label="Count"
                  size="small"
                  InputProps={{ readOnly: true }}
                  value={
                    (this.state.widget_count !== null)
                    ? this.state.widget_count
                    : widget.widget_pieces.length
                  }
                />
              </Grid>
              <Grid item xs={9}>
                <Slider
                  min={widget.widget_min_count}
                  max={widget.widget_max_count}
                  value={
                    (this.state.widget_count !== null)
                    ? this.state.widget_count
                    : widget.widget_pieces.length
                  }
                  style={{
                    width: 'auto',
                    marginLeft: this.props.theme.spacing(1),
                  }}
                  onBeforeChange={() => {
                    this.setState({
                      widget_count: widget.widget_pieces.length
                    });
                  }}
                  onChange={(value) => {
                    this.setState({ widget_count: value });
                  }}
                  onAfterChange={(value) => {
                    this.resizeWidget(widget, value);
                  }}
                  trackStyle={[{
                    backgroundColor: this.props.theme.palette.primary.light,
                  }]}
                  handleStyle={[{
                    borderColor: this.props.theme.palette.primary.light,
                  }]}
                />
              </Grid>
            </Grid>
            {options}
          </div>
         ),
        title: 'Edit Widget',
        back: this.setPage(this, 'picker')
      };
    } else if (widget.type === 'widgetLayout') {
      return {
        content: (
          <div className={classes.root}>
            <Typography variant="h6" gutterBottom>
              {widget.name || 'Widget'}
            </Typography>
            <TextField
              fullWidth
              label="Count"
              type="number"
              value={widget.count}
              onChange={this.setNumWidgets}
            />
            {
              widget.widgetFlow !== 'fill-vertical' && widget.widgetFlow !== 'fill-horizontal' ?
                <React.Fragment>
                  <Typography variant="h6" className={classes.heading}>
                    Horizontal Spacing
                  </Typography>
                  <Slider
                    min={-8}
                    max={120}
                    value={widget.hspacing}
                    onChange={this.setWidgetHSpacing}
                    trackStyle={[{
                      backgroundColor: this.props.theme.palette.primary.light,
                    }]}
                    handleStyle={[{
                      borderColor: this.props.theme.palette.primary.light,
                    }]}
                  />
                  <Typography variant="h6" className={classes.heading}>
                    Vertical Spacing
                  </Typography>
                  <Slider
                    min={-8}
                    max={120}
                    value={widget.vspacing}
                    onChange={this.setWidgetVSpacing}
                    trackStyle={[{
                      backgroundColor: this.props.theme.palette.primary.light,
                    }]}
                    handleStyle={[{
                      borderColor: this.props.theme.palette.primary.light,
                    }]}
                  />
                </React.Fragment> : null
             }
          </div>
         ),
        title: 'Edit Widget',
        back: this.setPage(this, 'picker')
      };
    }

    return null;
  }

  componentDidMount() {
    let widgets = this.props.canvas.getObjects().filter((object) => (
      object.type === 'widgetLayout' || object.type === 'widget-box'
    ));

    if (widgets.length === 1) {
      this.setState({ widget: widgets[0], page: 'widget' });
    }
  }

  render() {
    let contentBlock = null;
    switch (this.state.page) {
      case 'picker':
      default:
        contentBlock = this.renderWidgets();
        break;
      case 'widget':
        contentBlock = this.renderWidgetEditor();
        break;
    }

    const { title, back, content } = contentBlock;

    return (
      <React.Fragment>
          <Crumbs title={title} onBack={back} />
          { content }
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Widgets);