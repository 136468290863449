import Animation from './Animation';

class PanAnimation extends Animation
{
  constructor() {
    super();

    this.setOption('duration', 2);
    this.setOption('fade', 0.5)
  }

  apply({ objects, duration, loop }) {
    const option = this.getOption('direction') || 'left';

    let x, y;
    switch (option) {
      case 'left':
        x = -1;
        y = 0;
        break;
      case 'right':
        x = 1;
        y = 0;
        break;
      case 'top':
        x = 0;
        y = -1;
        break;
      case 'bottom':
        x = 0;
        y = 1;
        break;
      default:
        x = 0;
        y = 0;
        break;
    }

    for (let i = 0; i < objects.length; ++i) {
      const object = objects[i];

      switch (object.type) {
        case 'logoBox':
        case 'cutoutBox': {
          if (object.opacity === 0.2) {
            object.opacity = 1.0;
          }

          break;
        }
        default: {
          break;
        }
      }

      if (!object.excludeFromExport) {
        const keyFrame1 = this.getKeyFrame(object, 0);
        keyFrame1['opacity'] = 0.0;

        const keyFrame2 = this.getKeyFrame(object, this.getOption('fade'));
        keyFrame2['opacity'] = object.opacity;

        if (loop) {
          const keyFrame3 = this.getKeyFrame(object, duration - this.getOption('fade'));
          keyFrame3['opacity'] = object.opacity;

          const keyFrame4 = this.getKeyFrame(object, duration);
          keyFrame4['opacity'] = 0.0;
        }
      }
    }

    const step = 1.0 / this.getFPS();
    for (let j = 0; j < duration + step; j += step) {
      for (let i = 0; i < objects.length; ++i) {
        const object = objects[i];

        if (!object.excludeFromExport) {
          let distance = Math.max(object.width * object.scaleX, object.height * object.scaleY) / 4;
          let dx = object.left + x * distance * this.tween(j, duration, loop);
          let dy = object.top + y * distance * this.tween(j, duration, loop);

          const keyFrame = this.getKeyFrame(object, j);
          if (x) {
            keyFrame['left'] = dx;
          }

          if (y) {
            keyFrame['top'] = dy;
          }

          if (object.type === 'backgroundBox' && distance > 0) {
            let scale = 1 + Math.max(Math.abs(dx - object.left) / distance, Math.abs(dy - object.top) / distance);
            keyFrame['scaleX'] = scale;
            keyFrame['scaleY'] = scale;
          }
        }
      }
    }
  }
}

export default PanAnimation;
