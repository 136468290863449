import React, { Component } from 'react';

class Texture extends Component
{
	componentDidMount() {
		this.updateCanvas();
	}

	updateCanvas() {
		const ctx = this.refs.canvas.getContext('2d');

		let texture = new Image();
		texture.onload = () => {
			let background = new Image();
			background.onload = () => {
				let { width, height } = this.props;

				ctx.drawImage(
					background,
					0, 0,
					background.width, background.height,
					0, 0,
					width, height);
				ctx.globalCompositeOperation = 'overlay';
				ctx.drawImage(
					texture,
					0, 0,
					texture.width / 8, texture.height / 8,
					0, 0,
					width, height);
			}
			background.crossOrigin = 'Anonymous';
			background.src = '/images/texture.jpg';
		}
		texture.crossOrigin = 'Anonymous';
		texture.src = this.props.texture;
	}

	render() {
		const { width, height } = this.props;
		return (
			<canvas
				ref="canvas"
				width={width || 300}
				height={height || 300}
				{...this.props}
			/>
		);
	}
}

export default Texture;
