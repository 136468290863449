
// -------------------------------------------------------------------------- //

import {
  Animation as AnimationIcon,
  AutoFix as AutoFixIcon,
  ChartBar as ChartBarIcon,
  FormatColorText as FormatColorTextIcon,
  Image as ImageIcon,
  FileImage as FileImageIcon,
  Layers as LayersIcon,
  Widgets as WidgetsIcon,
  ViewList as ViewListIcon,
  DotsHorizontal as DotsHorizontalIcon,
  Shape as ShapeIcon,
} from 'mdi-material-ui';

import * as Binding from './../../canvas/lib/databinding';
import * as Sports from '../sport/Sports';
import Background from './sidebar/Background';
import Effects from './sidebar/Effects';
import Layers from './sidebar/Layers';
import Lineup from './sidebar/Lineup';
import Schedule from './sidebar/Schedule';
import GraphicsTab from './sidebar/GraphicsTab';
import Stats from './sidebar/Stats';
import Templates from './sidebar/Templates';
import Text from './sidebar/Text';
import Widgets from './sidebar/Widgets';
import Elements from './sidebar/Elements';

export default {

  /* templates: {
    name: 'Templates',
    component: Templates,
    icon: ImageMultipleIcon,
    enabled: true,
    filter: (({ slideshow }) => !slideshow),
  }, */

  slideshow: {
    name: 'Slideshow',
    component: Templates,
    icon: AnimationIcon,
    enabled: true,
    filter: (({ slideshow }) => slideshow)
  },

  widgets: {
    name: 'Widgets',
    component: Widgets,
    icon: WidgetsIcon,
    enabled: true,
    fixed: true,
    filter: (({ canvas }) => (
      (canvas) && (
        (canvas.getObjects('widgetLayout').length > 0) ||
        (canvas.getObjects('widget-box').length > 0)
      )
    )),
  },

  stats: {
    name: 'Stats',
    component: Stats,
    icon: ChartBarIcon,
    enabled: true,
    filter: ({ canvas, template }) => {
      if (!canvas) {
        return false;
      }

      const sport = Sports.CreateTemplateSport(template);

      if (!sport) {
        return false;
      }

      const variables = (
        Binding
        .GatherStats(canvas.getObjects())
        .map((id) => sport.getVariableById(id))
        .filter(Boolean)
      );

      if (variables.length === 0) {
        return false;
      }

      if (
        template &&
        template.types &&
        template.types.findIndex(e => e.name === 'Starting Lineup') >= 0 &&
        variables.every((variable) => variable.lineup)
      ) {
        return false;
      }

      if (
        template &&
        template.types &&
        template.types.findIndex(e => e.name === 'Schedule Showcase') >= 0 &&
        variables.every((variable) => variable.schedule)
      ) {
        return false;
      }

      return true;
    },
  },

  lineup: {
    name: 'Lineup',
    component: Lineup,
    icon: ViewListIcon,
    enabled: true,
    filter: ({ canvas, template }) => {
      if (!canvas || !template) {
        return false;
      }

      const objects = canvas.getObjects();
      const { types } = template;

      if (!objects.some(Binding.HasBindings)) {
        return false;
      }

      if (types.findIndex(e => e.name === 'Starting Lineup') < 0) {
        return false;
      }

      const sport = Sports.CreateTemplateSport(template);

      if (sport) {
        const bound_objects = canvas.getObjects().filter(Binding.HasBindings);

        const variables = (
          Binding.GatherStats(bound_objects).map(id => sport.getVariableById(id)).filter(Boolean)
        );

        if (!variables.some(variable => variable.lineup)) {
          return false;
        }
      }

      return true;
    },
  },

  schedule: {
    name: 'Schedule',
    component: Schedule,
    icon: ViewListIcon,
    enabled: true,
    filter: ({ canvas, template }) => {
      if (!canvas || !template) {
        return false;
      }

      const objects = canvas.getObjects();
      const { types } = template;

      if (!objects.some(Binding.HasBindings)) {
        return false;
      }

      if (types.findIndex(e => e.name === 'Schedule Showcase') < 0) {
        return false;
      }

      const sport = Sports.CreateTemplateSport(template);

      if (sport) {
        const bound_objects = canvas.getObjects().filter(Binding.HasBindings);

        const variables = (
          Binding.GatherStats(bound_objects).map(id => sport.getVariableById(id)).filter(Boolean)
        );

        if (!variables.some(variable => variable.schedule)) {
          return false;
        }
      }

      return true;
    },
  },

  background: {
    name: 'Background',
    component: Background,
    icon: ImageIcon,
    enabled: true,
  },

  graphicstab: {
    name: 'Graphics',
    component: GraphicsTab,
    icon: FileImageIcon,
    enabled: false,
  },

  text: {
    name: 'Text',
    component: Text,
    icon: FormatColorTextIcon,
    enabled: true,
  },

  more: {
    name: 'More',
    icon: DotsHorizontalIcon,
    props: { 'data-tour': 'more-tab' },
    enabled: true,
    filter: (({ advanced, mobile }) => (!mobile && !advanced)),
  },

  less: {
    name: 'Less',
    icon: DotsHorizontalIcon,
    props: { 'data-tour': 'more-tab' },
    enabled: true,
    filter: (({ advanced, mobile }) => (!mobile && advanced)),
  },

  elements: {
    name: 'Elements',
    component: Elements,
    icon: ShapeIcon,
    enabled: true,
    filter: (({ advanced, mobile }) => (mobile || advanced))
  },

  effects: {
    name: 'Effects',
    component: Effects,
    icon: AutoFixIcon,
    enabled: true,
    filter: (({ advanced, mobile }) => (!mobile && advanced))
  },

  layers: {
    name: 'Layers',
    component: Layers,
    enabled: true,
    fixed: true,
    icon: LayersIcon,
    filter: (({ advanced, permissions, mobile }) => (
      (!mobile && advanced && permissions !== null && !permissions.get('editor:lock_movement'))
    )),
  },

};

// -------------------------------------------------------------------------- //
