import React, {Component} from 'react';
import Button from '@material-ui/core/Button';

import {injectStripe} from 'react-stripe-elements';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import PlanSelectCard from './PlanSelectCard';
import CardSection from './CardSection';
import StripeDict from './StripeDict';

const GET_CLIENT = gql`
  query getClient {
    me {
      client {
        name
        phone
        state
        zip
        city
        address
        id
        color1
        color2
        color3
        logoBucket
        logoKey
        customer_id
      }
      id
    }
  }
`;

const UPDATE_STRIPE_INFO = gql`
  mutation updateStipeInfo( $id : ID!, $customer_id : String!, $subscription_end : String!, $subscription_id : String!,
                            $subscription_item_id : String!,$subscription_start : String!){
                            updateClientStripeInfo(id : $id, customer_id : $customer_id, subscription_end : $subscription_end, subscription_id : $subscription_id, subscription_item_id : $subscription_item_id, subscription_start : $subscription_start){
                                  name
                                  phone
                                  state
                                  zip
                                  city
                                  address
                                  id
                                  color1
                                  color2
                                  color3
                                  logoBucket
                                  logoKey
                                  customer_id
                            }

  }
`



class CheckoutForm extends Component {
  state = {
      planId : "",
      clientId : "",
      stripeCustomerId : "",
      selectedIndex :-100
  }

   handleSubmit = async () => {
    const {updateStripe} = this.props;
    const { stripeCustomerId , clientId, planId } = this.state;
    let {token} = await this.props.stripe.createToken({name: clientId});
    if(!token){
      // there was an error.
      return;
    }

    // send to DEELO API.
    let _obj = {
        card_data : token,
        plan : planId,
        client_id : clientId,
        stripe_customer_id : stripeCustomerId
    }

    const deeloInfo = await fetch(`${process.env.REACT_APP_SS_API_ENDPOINT}v1/__Stripe__c/charge-card/prod`,{
      method : 'POST',
      body : JSON.stringify({ body : _obj })
    }).then( j => j.json());

    let toServer = {...deeloInfo, ...{ id : clientId}};
    updateStripe( { variables : toServer } ); // send to our db
    if(deeloInfo.success){
      alert("Good job");
    }else{
      alert("Bad job");
    }
    this.initialize();
  };

  componentDidMount(){
    this.initialize();
  }

  initialize = async () => {
      let information = await this.props.data.refetch();
      let cid = information.data.me.client.customer_id === null ? "" : information.data.me.client.customer_id;
      this.setState({ clientId : information.data.me.client.id, stripeCustomerId : cid });
  }
  selectIndexFunct = (index) => () => {
    if(index === this.state.selectedIndex){return;}
    this.setState({ selectedIndex : index, planId : "" });
  }

  selectPlanIdFunct = (planId) => () => {
    if( planId === this.state.planId){return;}
    this.setState({ planId });
  }

  render() {
    const { selectedIndex, planId } = this.state;
    const _selectIndexFunct = this.selectIndexFunct;
    const _selectPlanIdFunct = this.selectPlanIdFunct;

    return (

        <React.Fragment>
        {StripeDict.proplans.map( (object, index ) => <PlanSelectCard
                                                        key={index}
                                                        {...object}
                                                        index={index}
                                                        selectedIndex={selectedIndex}
                                                        selectIndexFunct={_selectIndexFunct(index)}
                                                        selectPlanIdFunct={_selectPlanIdFunct}
                                                        />)}
        { planId !== "" ? <CardSection /> : null }
        { planId !== "" ? <Button onClick={this.handleSubmit} variant="contained" color="primary">Confirm order</Button> : null }
       </React.Fragment>

    );
  }
}
export default compose(
  graphql(UPDATE_STRIPE_INFO,{"name" : "updateStripe"}),
  graphql(GET_CLIENT),
)(injectStripe(CheckoutForm));
