
export const STROKE_SYMBOLS = [
  'Double Bogey (or above)',
  'Bogey',
  'Par',
  'Birdie',
  'Eagle',
  'Albatross (or below)',
];

class ParText {

  constructor(fabric) {
    this.class = fabric.util.createClass(fabric.IText, {
      type: 'ParText',
      strokeSymbol: 2,

      stateProperties: fabric.IText.prototype.stateProperties.concat(
        'strokeSymbol',
      ),

      cacheProperties: fabric.IText.prototype.cacheProperties.concat(
        'strokeSymbol',
      ),

      initialize(options = {}) {
        this.callSuper('initialize', '0', options);
      },

      toObject(propertiesToInclude) {
        return fabric.util.object.extend(
          this.callSuper('toObject', propertiesToInclude), {
            strokeSymbol: this.strokeSymbol,
          }
        );
      },

      _render(ctx) {
        ctx.save();
        const original_fill = this.fill;
        const original_stroke = this.stroke;
        const stroke_symbol = (this.strokeSymbol - 2);

        switch (stroke_symbol) {
          case -1:
          case +0:
          case +1: {
            this.stroke = '';
            this.fill = original_stroke;
            ctx.fillStyle = original_fill;
            ctx.strokeStyle = original_stroke;
            break;
          }
          case -3:
          case +3: {
            this.stroke = '';
            this.fill = original_fill;
            ctx.fillStyle = original_stroke;
            ctx.strokeStyle = original_stroke;
            break;
          }
          default: {
            this.stroke = '';
            this.fill = original_fill;
            ctx.fillStyle = original_stroke;
            ctx.strokeStyle = original_fill;
            break;
          }
        }

        if (stroke_symbol !== 0) {
          const inner_radius = (this.height * this.scaleY);
          ctx.scale((1.0 / this.scaleX), (1.0 / this.scaleY));
          ctx.beginPath();

          if (stroke_symbol > 0) {
            ctx.arc(0, 0, (inner_radius / 2), 0, (2 * Math.PI));
          } else { // if (stroke_symbol < 0)
            ctx.rect(-(inner_radius / 2), -(inner_radius / 2), inner_radius, inner_radius);
          }

          ctx.closePath();

          if (Math.abs(stroke_symbol) >= 2) {
            ctx.fill();

            if (Math.abs(stroke_symbol) >= 3) {
              const outer_radius = (inner_radius + this.strokeWidth * 2);
              ctx.beginPath();

              if (stroke_symbol > 0) {
                ctx.arc(0, 0, (outer_radius / 2), 0, (2 * Math.PI));
              } else { // if (stroke_symbol < 0)
                ctx.rect(-(outer_radius / 2), -(outer_radius / 2), outer_radius, outer_radius);
              }

              ctx.closePath();
              ctx.stroke();
            }
          } else {
            ctx.stroke();
          }
        }

        ctx.restore();
        this.callSuper('_render', ctx);
        this.fill = original_fill;
        this.stroke = original_stroke;
      },

    });

    this.fromObject = (object, callback) => fabric.Object._fromObject('ParText', object, callback);

  }

}

export default ParText;
