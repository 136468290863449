import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {compose, graphql} from "react-apollo";
import gql from "graphql-tag";
import { ROLES_QUERY, UserPermissions } from '../user/Permissions';

const styles = theme => ({
  controls: {
    position: 'sticky',
    top: 80,
  },
  activeLink: {
    backgroundColor: '#EEE',
  },
  activeIcon: {
    color: theme.palette.secondary.dark,
  },
  listItem: {
    whiteSpace: 'nowrap',
  },
});

const USERS_QUERY = gql`
  query {
    me {
      id
      isActive
      type
      client {
        id
        users {
          id
          email
          firstname
          lastname
          type
          isActive
          role {
            id
          }
        }
      }
      role {
        ${ROLES_QUERY}
      }
    }
  }
`;

const TeamNav = (props) => {
  const { classes, data, loading } = props;
  let superuserMenuItems = null;
  let social_menu_items = null;
  let colors_menu_items = null;
  let fonts_menu_items = null;

  const prestoSite = localStorage.getItem('prestoSite');

  if (data && data.me && data.me.role && !loading) {
    const user_permissions = new UserPermissions(data.me.role);

    if (user_permissions.get('social_posting:write')) {
      social_menu_items = (
        <React.Fragment>
          <ListItem
            button
            dense
            className={classes.listItem}
            component="a"
            href="#social"
          >
            <ListItemText primary="Social Accounts"/>
          </ListItem>
        </React.Fragment>
      );
    }

    if (user_permissions.get('team_palette:write')) {
      colors_menu_items = (
        <React.Fragment>
          <ListItem
            button
            dense
            className={classes.listItem}
            component="a"
            href="#colors"
          >
            <ListItemText primary="Team Colors" />
          </ListItem>
        </React.Fragment>
      );
    }

    if (user_permissions.get('fonts:write')) {
      fonts_menu_items = (
        <React.Fragment>
          <ListItem
            button
            dense
            className={classes.listItem}
            component="a"
            href="#fonts"
          >
            <ListItemText primary="Custom Fonts" />
          </ListItem>
        </React.Fragment>
      );
    }
  }

  superuserMenuItems = (
      <React.Fragment>
        {((data?.me?.role?.name === 'Superuser') || prestoSite === 'true') && (
            <ListItem
                button
                dense
                className={classes.listItem}
                component="a"
                href="#accounts"
            >
              <ListItemText primary="User Accounts"/>
            </ListItem>
        )}
        {((data?.me?.role?.name === 'Superuser') && prestoSite !== 'true') && (
            <ListItem
                button
                dense
                className={classes.listItem}
                component="a"
                href="#roles"
            >
              <ListItemText primary="User Permissions" />
            </ListItem>
        )}
      </React.Fragment>
  );

  React.useEffect(() => {
    // dispatch it once mounted
    window.dispatchEvent(new Event('resize'));
  }, []);
  return (
    <div
      variant="permanent"
      className={classes.controls}
    >
      <List>
        <ListItem
          button
          dense
          className={classes.listItem}
          component="a"
          href="#general"
        >
          <ListItemText primary="General Info" />
        </ListItem>
        {social_menu_items}
        {colors_menu_items}
        {fonts_menu_items}
        {superuserMenuItems}
      </List>
    </div>
  );
};


export default compose(
  graphql(USERS_QUERY),
  withStyles(styles),
)(TeamNav);
