
// -------------------------------------------------------------------------- //

import uuid from 'uuid4';

import * as MetaData from '../lib/metadata';

// -------------------------------------------------------------------------- //

const FLOWS = [
  'center-horizontal',
  'center-vertical',
  'symmetrical-horizontal',
  'symmetrical-vertical',
  'horizontal',
  'vertical',
  'fill-horizontal',
  'fill-vertical'
];

// -------------------------------------------------------------------------- //

export function ProcessJson(json) {
  let widget_boxes = {};
  let box_index = 1;

  json.objects.filter(
    (object) => (object.type === 'widgetLayout')
  ).forEach(((object) => {
    if (FLOWS.indexOf(object.widgetFlow) < 0) {
      return;
    }

    object.type = 'widget-box';
    object.name = `Widget ${box_index++}`;
    object.widget_uuid = uuid();
    let widget_flow = object.widgetFlow;

    switch (widget_flow) {
      case 'horizontal': {
        widget_flow = 'grid-horizontal';
        break;
      }
      case 'vertical': {
        widget_flow = 'grid-vertical';
        break;
      }
      default: {
        break;
      }
    }

    object.widget_flow = widget_flow;

    if (object.widgetMinCount < 0) {
      object.widget_min_count = null;
    } else {
      object.widget_min_count = object.widgetMinCount;
    }

    if (object.widgetMaxCount < 0) {
      object.widget_max_count = null;
    } else {
      object.widget_max_count = object.widgetMaxCount;
    }

    if ('count' in object) {
      object.widget_count = object.count;
    }

    if (object.widget) {
      const regex = /\b(\w+)\.json/;
      let matches = object.widget.match(regex);

      if (matches !== null) {
        object.widget_template = matches[1];
      }

      widget_boxes[object.widget] = object;
      delete object.widget;
    }

    delete object.count;
    delete object.spacing;
    delete object.hspacing;
    delete object.vspacing;
    delete object.widgetFlow;
    delete object.widgetWidth;
    delete object.widgetHeight;
    delete object.widgetMinCount;
    delete object.widgetMaxCount;
    delete object.widgetScaleX;
    delete object.widgetScaleY;
  }));

  json.objects.filter(
    (object) => (object.type === 'widget')
  ).forEach((object) => {
    let widget_box = widget_boxes[object.widget];

    if (!widget_box) {
      return;
    }

    object.type = 'widget-piece';
    object.widget_box = widget_box.widget_uuid;
    object.widget_index = object.index;

    delete object.widget;
    delete object.index;
  });

  json.objects.filter(
    (object) => (!object.type.startsWith('widget'))
  ).forEach((object) => {
    const key = MetaData.getMetaData(object, 'widget');
    let widget_box = widget_boxes[key];

    if (!widget_box) {
      return;
    }

    MetaData.setMetaData(object, 'widget_uuid', widget_box.widget_uuid);
    MetaData.clearMetaData(object, 'widget');
  });
}

// -------------------------------------------------------------------------- //

export async function ProcessCanvas(canvas) {
  return Promise.all(
    canvas.getObjects('widget-box').map((box) => box.load())
  );
}

// -------------------------------------------------------------------------- //
