// -------------------------------------------------------------------------- //

import * as React from 'react';
import {
  AppBar,
  Avatar,
  Button,
  ButtonBase,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Switch,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';
import {
  AccountStar,
  ArrowLeft,
  ChevronUp,
  Close,
  Eye,
  ImageSearchOutline,
  Import,
} from 'mdi-material-ui';

import { fade } from '@material-ui/core/styles/colorManipulator';
import {
  graphql, compose, Query, Mutation,
} from 'react-apollo';
import { withRouter, Link } from 'react-router-dom';
import Autosuggest from 'react-autosuggest';
import classnames from 'classnames';
import gql from 'graphql-tag';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TagComponent from './TagManager/TagComponent';
import * as Tags from '../create/templatetags';
import ScoreshotsPut from '../../scoreshotsapi/Put';

// -------------------------------------------------------------------------- //

const GET_TEMPLATE = gql`
  query getTemplate($id: ID) {
    template(id: $id) {
      id
      name
      width
      height
      description
      categories {
        id
        name
      }
      types {
        id
        name
      }
      visible
      parent {
        id
      }
      isSlideShow
      isQuickCreate
      isSlide
      children {
        id
      }
      filterTags
      tags {
        id
        name
      }
      disableXMLImport
      clients {
        id
        name
      }
      startingStep
    }
    categories {
      id
      name
    }
    types(orderBy: index_ASC) {
      id
      name
    }
  }
`;

// -------------------------------------------------------------------------- //

const DUPLICATE_TEMPLATE = gql`
  mutation duplicateTemplate($id: ID!) {
    duplicateTemplate(
      id: $id
    ) {
      id
    }
  }
`;

// -------------------------------------------------------------------------- //

const DELETE_TEMPLATE = gql`
  mutation deleteTemplate($id: ID!) {
    deleteTemplate(
      id: $id
    ) {
      id
    }
  }
`;

// -------------------------------------------------------------------------- //

const SAVE_TEMPLATE = gql`
  mutation saveTemplate($id: ID, $name: String, $width: Int, $height: Int, $categories: [ID!], $types: [ID!], $client: [ID!],  $generalUse: Boolean, $visible: TEMPLATE_VISIBILITY,$isSlideShow : Boolean, $isSlide : Boolean, $desc: String, $disableXMLImport: Boolean, $startingStep: String, $filterTags: [TEMPLATE_FILTER_TAGS!],$isQuickCreate: Boolean) {
    updateTemplate(
      id: $id
      name: $name
      generalUse: $generalUse
      width: $width
      height: $height
      categoryIds: $categories
      typeIds: $types
      client: $client
      visible: $visible
      isSlideShow : $isSlideShow
      isSlide : $isSlide
      desc : $desc
      disableXMLImport: $disableXMLImport
      startingStep: $startingStep
      filterTags: $filterTags
      isQuickCreate: $isQuickCreate
    ) {
      id
      isQuickCreate
      categories {
        id
        name
      }
      types {
        id
        name
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const GET_CLIENTS_BY_NAME = gql`
  query clientsByName($name: String) {
    clientsByName(
      name: $name
    ) {
      id
      name
    }
  }
`;

const ADD_EXISTING_TAG_TO_TEMPLATE = gql`
  mutation addTagToTemplate($tagId: ID!,$templates: [String!]){
    addExistingTagToTemplate(tagId: $tagId,templates: $templates){
      templates{
        name
      }
    }
  }
`;

const ADD_CHILD_TO_TEMPLATE = gql`
  mutation addChildToTemplate($tagId: ID!, $templates: String!){
    addChildToTemplate(id: $tagId,child: $templates){
      children {
        id
      }
    }
  }
`;

const REMOVE_CHILD_FROM_TEMPLATE = gql`
  mutation removeChildFromTemplate($tagId: ID!, $templates: String!){
    removeChildFromTemplate(id: $tagId,child: $templates){
      children {
        id
      }
    }
  }
`;
const REMOVE_TAG_FROM_TEMPLATE = gql`
  mutation removeTagFromTemplate($tagId: ID!,$template: String!){
    removeTagFromTemplate(tagId: $tagId,template: $template){
      templates{
        name
      }
    }
  }
`;
const CREATE_TAG_WITH_TEMPLATE = gql`
  mutation createTagWithTemplate($name: String!,$template: String!){
    createTagWithTemplate(name: $name,template: $template){
      name
      id
    }
  }
`;

const GET_TAGS = gql`
  query{
    tags{
      name
      id
    }
  }
`;
const GET_QUICK_CREATE_TEMPLATES = gql`
  query{
    getQuickCreateTemplates{
      id
      name
      children {
        id
      }
      parent {
        id
      }
    }
  }
`;
// -------------------------------------------------------------------------- //

const TYPES = [
  { value: 'NONE', name: 'ScoreShot' },
  { value: 'SLIDESHOW', name: 'SlideStory' },
  { value: 'QUICKCREATE', name: 'Quick Create' },
  { value: 'SLIDE', name: 'Slide' },
  { value: 'WIDGET', name: 'Widget' },
];

const STARTING_STEPS = [
  { value: 'Background', name: 'Default (Stats, Lineup, Widget, Background)' },
  { value: 'Graphics', name: 'Graphics' },
  { value: 'Text', name: 'Text' },
];

// -------------------------------------------------------------------------- //

// the transform prop here is to fix a known chrome issue
// see mui-org/material-ui/commit/9b9421c for details
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MENU_PROPS = {
  PaperProps: {
    style: {
      maxHeight: (ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP),
      transform: 'translate3D(0,0,0)',
    },
  },
};

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  app_bar: {
    position: 'relative',
    flex: 0,
  },
  button_progress: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
  },
  checkbox_control: {
    userSelect: 'none',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  clients_chip: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  clients_container: {
    flex: 1,
    position: 'relative',
  },
  clients_suggestion: {
    display: 'block',
  },
  clients_suggestions_container_open: {
    position: 'absolute',
    left: 0,
    marginTop: theme.spacing(1),
    right: 0,
    zIndex: 1,
  },
  clients_suggestions_list: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
  description: {
    padding: theme.spacing(2),
    zIndex: 1,
  },
  description_button: {
    transition: theme.transitions.create('transform'),
  },
  description_button_in: {
    transform: 'rotate(180deg)',
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
  editor: {
    display: 'flex',
    flex: 1,
    overflowY: 'scroll',
  },
  flex: {
    flex: 1,
  },
  input: {
    display: 'none',
  },
  preview: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'auto',
  },
  preview_button: {
    width: '100%',
    height: '100%',
    display: 'block',
    textAlign: 'inherit',
  },
  preview_container: {
    display: 'block',
    position: 'relative',
    boxShadow: 'inset 0px 2px 10px 0px rgba(0, 0, 0, 0.2)',
    width: '100%',
    height: '100%',
  },
  preview_error: {
    color: theme.palette.text.secondary,
    fontSize: '6rem',
  },
  preview_image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    opacity: 0,
    objectFit: 'contain',
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.short,
    }),
  },
  preview_indicator: {
    left: '50%',
    pointerEvents: 'none',
    position: 'absolute',
    textAlign: 'center',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  preview_loaded: {
    opacity: 1,
  },
  preview_missing_icon: {
    color: theme.palette.text.secondary,
    fontSize: '8rem',
  },
  preview_missing_text: {
    userSelect: 'none',
  },
  preview_overlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.short,
    }),
    '&:hover': {
      backgroundColor: fade(theme.palette.text.primary, theme.palette.action.hoverOpacity),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  preview_mode_button: {
    margin: theme.spacing(1),
  },
  preview_mode_toolbar: {
    bottom: 0,
    color: theme.palette.common.white,
    left: '50%',
    marginBottom: theme.spacing(2),
    position: 'absolute',
    transform: 'translate(-50%, 150%)',
    transition: theme.transitions.create('transform'),
  },
  preview_mode_toolbar_hover: {
    transform: 'translate(-50%, 0%)',
  },
  preview_mode_fade: {
    bottom: 0,
    height: theme.spacing(9),
    left: 0,
    background: 'linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0))',
    position: 'absolute',
    transform: 'translate(0%, 100%)',
    transition: theme.transitions.create('transform'),
    width: '100%',
  },
  preview_mode_fade_on: {
    transform: 'translate(0%, 0%)',
  },
  save_button: {
    marginTop: theme.spacing(2),
  },
  select: {
    marginTop: theme.spacing(2),
  },
  sidebar: {
    padding: theme.spacing(2),
    width: theme.spacing(50),
    height: '100%',
    zIndex: 1,
    overflow: 'scroll',
  },
  size_cross: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  size_toolbar: {
    alignItems: 'flex-end',
  },
  toolbar_button: {
    opacity: 0.4,
    transitions: theme.transitions.create('opacity'),
  },
  toolbar_button_loaded: {
    opacity: 1,
  },
  upload_progress: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 48, // match CircularProgress::size
  },
});

// -------------------------------------------------------------------------- //

class ImageLoader extends React.Component {
  state = {
    mode: 'loading',
    image: null,
  }

  componentDidMount() {
    this.reloadImage();
  }

  componentDidUpdate(previous_props) {
    if (this.props.src !== previous_props.src) {
      this.reloadImage();
    }
  }

  render() {
    const { mode, image } = this.state;
    const { render } = this.props;

    if (render) {
      return render({
        loading: (mode === 'loading'),
        loaded: (mode === 'loaded'),
        error: (mode === 'error'),
        image,
      });
    }
    const renderer = this.props[mode];
    return ((renderer && renderer(image)) || null);
  }

  reloadImage = () => {
    const { src } = this.props;

    this.setState({ mode: 'loading' }, () => {
      const image = new Image();

      image.onload = () => {
        this.setState({ mode: 'loaded' }, () => {
          const { onLoad } = this.props;
          (onLoad && onLoad());
        });
      };

      image.onerror = (error) => {
        this.setState({ mode: 'error' }, () => {
          const { onError } = this.props;
          (onError && onError(error));
        });
      };

      image.src = src;
      this.setState({ image });
    });
  }
}

// -------------------------------------------------------------------------- //

class VideoLoader extends React.Component {
  state = {
    mode: 'loading',
    video: null,
  }

  componentDidMount() {
    this.reloadVideo();
  }

  componentDidUpdate(previous_props) {
    if (this.props.src !== previous_props.src) {
      this.reloadVideo();
    }
  }

  render() {
    const { mode, video } = this.state;
    const { render } = this.props;

    if (render) {
      return render({
        loading: (mode === 'loading'),
        loaded: (mode === 'loaded'),
        error: (mode === 'error'),
        video,
      });
    }
    const renderer = this.props[mode];
    return ((renderer && renderer(video)) || null);
  }

  reloadVideo = () => {
    const { src } = this.props;

    this.setState({ mode: 'loading' }, () => {
      const video = document.createElement('video');

      video.onloadeddata = () => {
        this.setState({ mode: 'loaded' }, () => {
          const { onLoad } = this.props;
          (onLoad && onLoad());
        });
      };

      video.onerror = (error) => {
        this.setState({ mode: 'error' }, () => {
          const { onError } = this.props;
          (onError && onError(error));
        });
      };

      video.src = src;
      video.load();
      this.setState({ video });
    });
  }
}

// -------------------------------------------------------------------------- //

class TemplateEditor extends React.Component {
  state = {
    loading: true,
    error: false,
    dirty: false,
    uploading: false,
    saving: false,
    sports: [],
    categories: [],
    client_name: '',
    template_name: '',
    template_width: 0,
    template_height: 0,
    template_sports: [],
    template_categories: [],
    template_type: TYPES[0].value,
    template_visible: false,
    template_disable_xml: false,
    template_clients: [],
    template_description: '',
    template_starting_step: '',
    template_tab: 0,
    cache_buster: null,
    description_expanded: false,
    delete_dialog: false,
    delete_type: 'template', // 'template' | 'preview'
    duplicate_dialog: false,
    mutation_busy: false,
    preview_mode: 'image', // 'image' | 'video'
    preview_hover: false,
    tags_selected: [],
    template_filterTags: [],
    selected_tags: [], // tag componenet
    template_id: '',
    child_templated: [],
    starting_tags: [],
    starting_childs: [],
    template: null,
    child_templates_updated: false,
  }

  constructor(props) {
    super(props);
    this.action = new ScoreshotsPut('ss3-templates');
    this.tagsHandler.bind(this);
    this.childTemplateHandler.bind(this);
  }

  tagsHandler = (tags) => {
    this.setState({ selected_tags: tags });
  }

  childTemplateHandler = (templates) => {
    this.setState({ child_templated: templates, child_templates_updated: true, dirty: true });
  }

  componentDidMount() {
    const { getTemplate: fetch, match } = this.props;
    const { template } = match.params;

    this.setState({ template_id: template });

    fetch.refetch({ id: template }).then((response) => {
      const { data } = response;
      if (data === null) {
        this.setState({ loading: false, error: true });
        return;
      }
      this.setState({
        starting_tags: data.template.tags,
        template: data.template,
        starting_childs: data.template.children,
      });
      const { template } = data;
      const visible = (template.visible !== 'HIDDEN');
      let type = 'NONE';
      if (template.isQuickCreate) {
        type = 'QUICKCREATE';
      }
      if (template.isSlideShow) {
        type = 'SLIDESHOW';
      } else if (template.isSlide) {
        type = 'SLIDE';
      } else if (template.visible === 'WIDGET') {
        type = 'WIDGET';
      }

      let startingStep = 'Background';
      if (template && template.startingStep && template.startingStep !== '') {
        startingStep = template.startingStep;
      }

      const sports = data.categories.map((category) => ({
        name: category.name,
        value: category.id,
      }));

      const categories = data.types.map((type) => ({
        name: type.name,
        value: type.id,
      }));

      this.setState({
        sports,
        categories,
        loading: false,
        template_name: template.name,
        template_width: +template.width,
        template_height: +template.height,
        template_sports: template.categories.map((category) => category.id),
        template_categories: template.types.map((type) => type.id),
        template_clients: template.clients.map((client) => ({ id: client.id, name: client.name })),
        template_description: template.description,
        template_visible: visible,
        template_disable_xml: Boolean(template.disableXMLImport),
        template_type: type,
        template_starting_step: startingStep,
        template_filterTags: template.filterTags,
        tags_selected: template.filterTags,
        cache_buster: Math.floor(Date.now() / 1000),
      });
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        {this.renderToolbar()}
        <div className={classes.editor} ref={this.messagesEnd}>
          {this.renderSidebar()}
          {this.renderPreview()}
        </div>
        {this.renderDeleteDialog()}
        {this.renderDuplicateDialog()}
        {this.renderUploadDialog()}
      </>
    );
  }

  renderToolbar = () => {
    const { classes, history, match } = this.props;
    const { loading, saving, error } = this.state;
    const { template: template_id } = match.params;
    const disabled = (loading || saving || error);

    return (
      <AppBar color="primary" elevation={0} className={classes.app_bar}>
        <Toolbar>
          <IconButton color="inherit" onClick={() => history.goBack()}>
            <ArrowLeft />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Edit Template
          </Typography>
          <Button
            color="inherit"
            disabled={disabled}
            onClick={() => (!disabled && this.openDeleteDialog('template'))}
          >
            Delete
          </Button>
          <Button
            color="inherit"
            disabled={disabled}
            onClick={() => (!disabled && this.openDuplicateDialog())}
          >
            Duplicate
          </Button>
          <Button
            color="inherit"
            component={Link}
            to={`editor/${template_id}`}
            disabled={disabled}
          >
            Edit
          </Button>
        </Toolbar>
      </AppBar>
    );
  }

  renderSelectSingle = (label, options, selection, on_change) => {
    const { classes } = this.props;
    const { saving } = this.state;

    return (
      <FormControl fullWidth id={label} className={classes.select}>
        <InputLabel htmlFor={label}>{label}</InputLabel>
        <Select
          disabled={saving}
          value={selection}
          onChange={(e) => on_change(e.target.value)}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  saveChildTemplates() {

  }

  renderSelectMultiple = (label, options, selection, on_change) => {
    const { classes } = this.props;
    const { saving } = this.state;
    let button_text = ''; let
      button_handler = null;

    if (selection.length === options.length) {
      button_text = '(Select None)';
      button_handler = () => on_change([]);
    } else {
      button_text = '(Select All)';
      button_handler = () => on_change(options.map((option) => option.value));
    }

    return (
      <FormControl fullWidth id={label} className={classes.select}>
        <InputLabel htmlFor={label}>{label}</InputLabel>
        <Select
          multiple
          disabled={saving}
          value={selection}
          MenuProps={MENU_PROPS}
          onChange={(e) => {
            const values = e.target.value;

            if (values.includes('(select toggle)')) {
              button_handler();
            } else {
              on_change(values);
            }
          }}
          renderValue={(selected) => (
            options
              .filter((option) => selected.includes(option.value))
              .map((option) => option.name)
              .join(', ')
          )}
        >
          <MenuItem value="(select toggle)">
            <Checkbox color="primary" checked={selection.length === options.length} />
            <ListItemText primary={button_text} />
          </MenuItem>
          {options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              <Checkbox color="primary" checked={selection.includes(option.value)} />
              <ListItemText primary={option.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  renderSidebar = () => {
    const { classes } = this.props;
    const { sports, categories } = this.state;
    const {
      loading, error, dirty, saving,
    } = this.state;

    const {
      client_name,
      template_name,
      template_sports,
      template_categories,
      template_type,
      template_width,
      template_height,
      template_visible,
      template_disable_xml,
      template_starting_step,
      template_clients,
      tags_selected,
    } = this.state;

    return (
      <Paper className={classes.sidebar} elevation={0}>
        {!(loading || error)
        && (
          <>
            <TextField
              fullWidth
              label="Name"
              placeholder="Type template name..."
              disabled={saving}
              value={template_name}
              onChange={(e) => {
                this.setState({ dirty: true, template_name: e.target.value });
              }}
            />
            {this.renderSelectSingle(
              'Type', TYPES, template_type, ((type) => {
                const state = { dirty: true, template_type: type };

                if (template_type === 'SLIDESHOW' || template_type === 'SLIDE' || template_type === 'QUICKCREATE') {
                  if (type !== 'SLIDESHOW' && type !== 'SLIDE' && type === 'QUICKCREATE') {
                    state.preview_mode = 'image';
                  }
                }

                this.setState(state);
              }),
            )}
            <Toolbar disableGutters className={classes.size_toolbar}>
              <TextField
                fullWidth
                label="Width"
                disabled={saving}
                value={template_width}
                onChange={(e) => {
                  this.setState({ dirty: true, template_width: +e.target.value });
                }}
              />
              <div className={classes.size_cross}>
                <Close color="disabled" />
              </div>
              <TextField
                fullWidth
                label="Height"
                disabled={saving}
                value={template_height}
                onChange={(e) => {
                  this.setState({ dirty: true, template_height: +e.target.value });
                }}
              />
            </Toolbar>
            <Query query={GET_QUICK_CREATE_TEMPLATES}>
              {(data, error) => (data.loading === false
                ? (
                  <TagComponent
                    startingTags={this.state.starting_childs}
                    tags={data.data.getQuickCreateTemplates.filter((template) => (template.children.length === 0))}
                    type="childTemplate"
                    customPlaceholder="Add child template"
                    addTagToTemplate={this.props.addChildToTemplate}
                    createTagWithTemplate={this.props.createTagWithTemplate}
                    removeTagFromTemplate={this.props.removeChildFromTemplate}
                    templateId={this.state.template_id}
                    valid={this.state.template.parent !== null}
                    updateTags={this.childTemplateHandler}
                  />
                )
                : <CircularProgress />)}
            </Query>
            <Query query={GET_TAGS}>
              {(data) => (
                data.loading === false
                  ? (
                    <TagComponent
                      startingTags={this.state.starting_tags}
                      tags={data}
                      type="tags"
                      addTagToTemplate={this.props.addTagToTemplate}
                      createTagWithTemplate={this.props.createTagWithTemplate}
                      removeTagFromTemplate={this.props.removeTagFromTemplate}
                      valid={false}
                      templateId={this.state.template_id}
                      updateTags={this.tagsHandler}
                    />
                  )
                  : <CircularProgress />
              )}
            </Query>
            {this.renderSelectMultiple(
              'Sports', sports, template_sports, ((sports) => {
                let { template_tab } = this.state;

                if (template_tab > 0) {
                  const id = template_sports[template_tab - 1];
                  template_tab = (sports.findIndex((sport) => sport.value === id) + 1);
                }

                this.setState({ dirty: true, template_tab, template_sports: sports });
              }),
            )}
            {this.renderSelectMultiple(
              'Categories', categories, template_categories,
              ((categories) => this.setState({ dirty: true, template_categories: categories })),
            )}
            <FormControl fullWidth id="Tags" className={classes.select}>
              <InputLabel htmlFor="Tags">Tags</InputLabel>
              <Select
                multiple
                MenuProps={MENU_PROPS}
                disabled={saving}
                inputProps={{ id: 'tags-input' }}
                value={tags_selected}
                onChange={(e) => this.selectTags(e.target.value)}
                renderValue={
                  (selected) => (
                    Tags.ARRAY
                      .filter(({ value }) => selected.indexOf(value) >= 0)
                      .map(({ name }) => name)
                      .join(', ')
                  )
                }
              >
                {
                  Tags.ARRAY.map(({ name, value }, index) => (
                    <MenuItem key={index} value={value}>
                      <Checkbox
                        color="primary"
                        checked={tags_selected.findIndex((selected) => selected === value) >= 0}
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            {this.renderSelectSingle(
              'Starting Step', STARTING_STEPS, template_starting_step, ((startingStep) => {
                const state = { dirty: true, template_starting_step: startingStep };
                this.setState(state);
              }),
            )}
            <List>
              <ListItem>
                <ListItemIcon>
                  <Eye />
                </ListItemIcon>
                <ListItemText primary="Visible" />
                <ListItemSecondaryAction>
                  <Switch
                    disabled={saving}
                    checked={template_visible}
                    onChange={(e) => this.setState({ dirty: true, template_visible: e.target.checked })}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Import />
                </ListItemIcon>
                <ListItemText primary="Disable XML Import" />
                <ListItemSecondaryAction>
                  <Switch
                    disabled={saving}
                    checked={template_disable_xml}
                    onChange={(e) => this.setState({ dirty: true, template_disable_xml: e.target.checked })}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
            <Divider className={classes.divider} />
            <Query query={GET_CLIENTS_BY_NAME}>
              {({ data, refetch }) => (
                <>
                  <Autosuggest
                    disabled={saving}
                    suggestions={(data && data.clientsByName) || []}
                    onSuggestionsFetchRequested={(e) => refetch({ name: e.value })}
                    onSuggestionsClearRequested={() => refetch({ name: null })}
                    getSuggestionValue={(client) => client.name}
                    onSuggestionSelected={(_, { suggestion }) => this.addTemplateClient(suggestion)}
                    renderSuggestion={(s, p) => this.renderClientSuggestion(s, p)}
                    renderSuggestionsContainer={(options) => this.renderClientContainer(options)}
                    renderInputComponent={(props) => this.renderClientInput(props)}
                    inputProps={{
                      value: client_name,
                      onChange: ((e) => this.setState({ client_name: e.target.value })),
                    }}
                    theme={{
                      container: classes.clients_container,
                      suggestionsContainerOpen: classes.clients_suggestions_container_open,
                      suggestionsList: classes.clients_suggestions_list,
                      suggestion: classes.clients_suggestion,
                    }}
                  />
                  {template_clients.map((client, index) => (
                    <Chip
                      key={client.id}
                      label={client.name}
                      className={classes.clients_chip}
                      avatar={<Avatar><AccountStar /></Avatar>}
                      onDelete={() => this.removeTemplateClient(index)}
                    />
                  ))}
                </>
              )}
            </Query>
            <Mutation mutation={SAVE_TEMPLATE}>
              {(mutation) => (
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  disabled={!dirty || saving}
                  className={classes.save_button}
                  onClick={() => this.saveTemplate(mutation)}
                >
                  Save Changes
                  {
                    (saving)
                    && (
                      <div className={classes.button_progress}>
                        <CircularProgress size={24} />
                      </div>
                    )
                  }
                </Button>
              )}
            </Mutation>
          </>
        )}
      </Paper>
    );
  }

  selectTags = (tags) => {
    this.setState({ dirty: true, tags_selected: tags, template_filterTags: tags });
  }

  renderClientInput = (props) => (
    <TextField
      fullWidth
      label="Premium Client"
      placeholder="Type client name..."
      {...props}
    />
  )

  renderClientContainer = (options) => {
    const { containerProps, children } = options;
    return <Paper {...containerProps}>{children}</Paper>;
  }

  renderClientSuggestion = (suggestion, { query, isHighlighted }) => {
    const matches = match(suggestion.name, query);
    const parts = parse(suggestion.name, matches);

    return (
      <MenuItem selected={isHighlighted} component="div">
        <>
          {parts.map((part, index) => (part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </strong>
          )))}
        </>
      </MenuItem>
    );
  }

  renderPreview = () => {
    const { classes, match, theme } = this.props;
    const { template_type, preview_mode } = this.state;
    let { template_width, template_height } = this.state;
    const { template: template_id } = match.params;
    let image_url = null; let
      video_url = null;

    const {
      sports,
      template_description,
      description_expanded,
      template_sports,
      template_tab,
      saving,
      cache_buster,
      preview_hover,
    } = this.state;

    if (template_tab === 0) {
      image_url = `https://ss3-templates.s3.us-east-2.amazonaws.com/${template_id}.jpg?${cache_buster}`;
      video_url = `https://ss3-templates.s3.us-east-2.amazonaws.com/${template_id}.mp4?${cache_buster}`;
    } else {
      const sport_id = template_sports[template_tab - 1];
      image_url = `https://ss3-templates.s3.us-east-2.amazonaws.com/${template_id}_${sport_id}.jpg?${cache_buster}`;
      video_url = `https://ss3-templates.s3.us-east-2.amazonaws.com/${template_id}_${sport_id}.mp4?${cache_buster}`;
    }

    if (template_width === 0 || template_height === 0) {
      template_width = 1;
      template_height = 1;
    }

    const description_length = (template_description ? template_description.length : 0);

    const on_change = (_, value) => {
      this.setState({ template_tab: value });
    };

    return (
      <div className={classes.preview}>
        <AppBar
          position="static"
          elevation={0}
          style={{ backgroundColor: theme.palette.primary.light }}
        >
          <Tabs
            scrollable
            variant="scrollable"
            scrollButtons="on"
            value={template_tab}
            onChange={on_change}
            className={classes.flex}
          >

            <Tab key={-1} label="Default" />
            {template_sports.map((sport, index) => (
              <Tab key={index} label={sports.find((i) => i.value === sport).name} />
            ))}
          </Tabs>
        </AppBar>
        <input
          type="file"
          accept={preview_mode === 'image' ? 'image/jpeg,image/png' : 'video/mp4'}
          id="upload-file-input"
          className={classes.input}
          onChange={(e) => this.uploadTemplatePreview(e.target)}
        />
        <label htmlFor="upload-file-input" className={classes.flex}>
          <ButtonBase component="span" className={classes.preview_button}>
            <div
              className={classes.preview_container}
              onMouseOver={() => this.setState({ preview_hover: true })}
              onMouseOut={() => this.setState({ preview_hover: false })}
            >
              {(preview_mode === 'video') ? (
                <VideoLoader
                  src={video_url}
                  render={({ loading, loaded, error }) => (
                    <>
                      <video
                        loop
                        muted
                        autoPlay
                        src={video_url}
                        className={classnames(
                          classes.preview_image,
                          { [classes.preview_loaded]: loaded },
                        )}
                      />
                      <div className={classes.preview_overlay} />
                      {
                        (loading)
                        && (
                          <div className={classes.preview_indicator}>
                            <CircularProgress size={80} />
                          </div>
                        )
                      }
                      {
                        (error)
                        && (
                          <div className={classes.preview_indicator}>
                            <ImageSearchOutline className={classes.preview_missing_icon} />
                            <Typography variant="h6" color="textSecondary" className={classes.preview_missing_text}>
                              Upload Preview
                            </Typography>
                          </div>
                        )
                      }
                    </>
                  )}
                />
              ) : (
                <ImageLoader
                  src={image_url}
                  render={({ loading, loaded, error }) => (
                    <>
                      <img
                        src={image_url}
                        alt="Template Preview"
                        className={classnames(
                          classes.preview_image,
                          { [classes.preview_loaded]: loaded },
                        )}
                      />
                      <div className={classes.preview_overlay} />
                      {
                        (loading)
                        && (
                          <div className={classes.preview_indicator}>
                            <CircularProgress size={80} />
                          </div>
                        )
                      }
                      {
                        (error)
                        && (
                          <div className={classes.preview_indicator}>
                            <ImageSearchOutline className={classes.preview_missing_icon} />
                            <Typography variant="h6" color="textSecondary" className={classes.preview_missing_text}>
                              Upload Preview
                            </Typography>
                          </div>
                        )
                      }
                    </>
                  )}
                />
              )}
              <div
                className={classnames(
                  classes.preview_mode_fade,
                  { [classes.preview_mode_fade_on]: preview_hover },
                )}
              />
              <div className={classnames(
                classes.preview_mode_toolbar,
                { [classes.preview_mode_toolbar_hover]: preview_hover },
              )}
              >
                {
                  (template_type === 'SLIDESHOW' || template_type === 'SLIDE' || template_type === 'QUICKCREATE')
                  && (
                    <Button
                      color="inherit"
                      variant="outlined"
                      className={classes.preview_mode_button}
                      onClick={() => this.togglePreviewMode()}
                    >
                      {
                        (preview_mode === 'image')
                          ? 'Switch to Video'
                          : 'Switch to Image'
                      }
                    </Button>
                  )
                }
                <Button
                  color="inherit"
                  variant="outlined"
                  className={classes.preview_mode_button}
                  onClick={() => this.openDeleteDialog('preview')}
                >
                  Delete Preview
                </Button>
              </div>
            </div>
          </ButtonBase>
        </label>
        <Paper elevation={0} className={classes.description}>
          <Toolbar disableGutters>
            <IconButton
              className={classnames(
                classes.description_button,
                { [classes.description_button_in]: description_expanded },
              )}
              onClick={() => this.setState({ description_expanded: !description_expanded })}
            >
              <ChevronUp />
            </IconButton>
            <Typography variant="h6" color="textSecondary">Edit Description</Typography>
          </Toolbar>
          <Collapse in={description_expanded}>
            <TextField
              fullWidth
              multiline
              rows={4}
              placeholder="Type template description..."
              helperText={`${255 - description_length} character(s) remaining`}
              inputProps={{ maxLength: 255 }}
              disabled={saving}
              value={template_description}
              onChange={(e) => this.setState({ dirty: true, template_description: e.target.value })}
            />
          </Collapse>
        </Paper>
      </div>
    );
  }

  togglePreviewMode = () => {
    let { preview_mode } = this.state;

    switch (preview_mode) {
      case 'video': {
        preview_mode = 'image';
        break;
      }
      case 'image': {
        preview_mode = 'video';
        break;
      }
      default: {
        break;
      }
    }

    this.setState({ preview_mode });
  }

  openDeleteDialog = (type = 'template') => {
    this.setState({
      delete_dialog: true,
      delete_type: type,
    });
  }

  closeDeleteDialog = () => {
    const { mutation_busy } = this.state;

    if (mutation_busy) {
      return;
    }

    this.setState({ delete_dialog: false });
  }

  renderDeleteDialog = () => {
    const { classes } = this.props;
    const { delete_dialog, delete_type, mutation_busy } = this.state;
    let title = ''; let
      content = '';

    switch (delete_type) {
      case 'template': {
        title = 'Permanently delete this template?';
        content = 'The template will be permanently removed. This action cannot be undone.';
        break;
      }
      case 'preview': {
        title = 'Permanently delete this preview?';
        content = 'The preview will be permanently removed. This action cannot be undone.';
        break;
      }
      default: {
        title = '(null)';
        content = '(null)';
        break;
      }
    }

    return (
      <Dialog open={delete_dialog} onClose={() => this.closeDeleteDialog()}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={mutation_busy}
            onClick={() => this.closeDeleteDialog()}
          >
            Cancel
          </Button>
          {
            (delete_type === 'template')
            && (
              <Mutation mutation={DELETE_TEMPLATE}>
                {(mutation) => (
                  <Button
                    color="primary"
                    disabled={mutation_busy}
                    onClick={() => this.deleteTemplate(mutation)}
                  >
                    Delete
                    {
                      (mutation_busy)
                      && (
                        <div className={classes.button_progress}>
                          <CircularProgress size={24} />
                        </div>
                      )
                    }
                  </Button>
                )}
              </Mutation>
            )
          }
          {
            (delete_type === 'preview')
            && (
              <Button
                color="primary"
                disabled={mutation_busy}
                onClick={() => this.deleteTemplatePreview()}
              >
                Delete
                {
                  (mutation_busy)
                  && (
                    <div className={classes.button_progress}>
                      <CircularProgress size={24} />
                    </div>
                  )
                }
              </Button>
            )
          }
        </DialogActions>
      </Dialog>
    );
  }

  deleteTemplate = (mutation) => {
    const { history, match } = this.props;
    const { template: template_id } = match.params;
    this.setState({ mutation_busy: true }, () => {
      mutation({ variables: { id: template_id } }).then(() => {
        history.replace('/admin/templates');
      });
    });
  }

  openDuplicateDialog = () => {
    this.setState({ duplicate_dialog: true });
  }

  closeDuplicateDialog = () => {
    const { mutation_busy } = this.state;

    if (mutation_busy) {
      return;
    }

    this.setState({ duplicate_dialog: false });
  }

  renderDuplicateDialog = () => {
    const { classes } = this.props;
    const { duplicate_dialog, mutation_busy } = this.state;

    return (
      <Dialog open={duplicate_dialog} onClose={() => this.closeDuplicateDialog()}>
        <DialogTitle>Duplicate this template?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The page will redirect once the template has finished duplicating.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={mutation_busy}
            onClick={() => this.closeDuplicateDialog()}
          >
            Cancel
          </Button>
          <Mutation mutation={DUPLICATE_TEMPLATE}>
            {(mutation) => (
              <Button
                color="primary"
                disabled={mutation_busy}
                onClick={() => this.duplicateTemplate(mutation)}
              >
                Duplicate
                {
                  (mutation_busy)
                  && (
                    <div className={classes.button_progress}>
                      <CircularProgress size={24} />
                    </div>
                  )
                }
              </Button>
            )}
          </Mutation>
        </DialogActions>
      </Dialog>
    );
  }

  duplicateTemplate = (mutation) => {
    const { history, match } = this.props;
    const { template: template_id } = match.params;
    this.setState({ mutation_busy: true }, () => {
      mutation({ variables: { id: template_id } }).then((response) => {
        // TODO check for duplication failure
        history.replace(`/admin/templates/${response.data.duplicateTemplate.id}`);
      });
    });
  }

  renderUploadDialog = () => {
    const { classes } = this.props;
    const { uploading } = this.state;

    return (
      <Dialog open={uploading}>
        <DialogTitle>Uploading your preview...</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your template preview is uploading and will be available shortly.
          </DialogContentText>
          <div className={classes.upload_progress}>
            <CircularProgress size={48} />
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  uploadTemplatePreview = (input) => {
    const { match } = this.props;
    const { template_sports, template_tab } = this.state;
    const { template: template_id } = match.params;
    const file = input.files[0];
    let key = ''; let content_type = ''; let
      extension = '';

    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      content_type = file.type;
      extension = '.jpg';
    } else if (file.type === 'video/mp4') {
      content_type = 'video/mp4';
      extension = '.mp4';
    } else {
      input.value = null;
      return;
    }

    if (template_tab > 0) {
      const sport_id = template_sports[template_tab - 1];
      key = `${template_id}_${sport_id}${extension}`;
    } else {
      key = `${template_id}${extension}`;
    }

    this.setState({ uploading: true }, () => {
      this.action.put(file, content_type, () => {}, key, '').then(() => {
        this.setState({ uploading: false, cache_buster: Math.floor(Date.now() / 1000) });
      });

      input.value = null;
    });
  }

  deleteTemplatePreview = () => {
    const { match } = this.props;
    const { preview_mode, template_sports, template_tab } = this.state;
    const { template: template_id } = match.params;
    let key = ''; let
      extension = '';

    if (preview_mode === 'image') {
      extension = '.jpg';
    } else if (preview_mode === 'video') {
      extension = '.mp4';
    } else {
      return;
    }

    if (template_tab > 0) {
      const sport_id = template_sports[template_tab - 1];
      key = `${template_id}_${sport_id}${extension}`;
    } else {
      key = `${template_id}${extension}`;
    }

    this.setState({ mutation_busy: true }, () => {
      this.action.remove(key).then(() => {
        this.setState({
          delete_dialog: false,
          mutation_busy: false,
        });
      });
    });
  }

  addTemplateClient = (client) => {
    const { template_clients } = this.state;

    if (!template_clients.includes(client)) {
      template_clients.push(client);
    }

    this.setState({ dirty: true, client_name: '' });
  }

  removeTemplateClient = (index) => {
    const { template_clients } = this.state;
    template_clients.splice(index, 1);
    this.setState({ dirty: true });
  }

  GET_TEMPLATE_S3 = (templateId) => {
    const cache_buster = `${+new Date()}`; return fetch(`https://s3.us-east-2.amazonaws.com/ss3-templates/${templateId}.json?${cache_buster}`)
      .then((res) => res.json());
  };

  saveQuickCreateSlides = async () => {
    const { getTemplate: fetch } = this.props;
    const template_id = this.props.match.params.template;
    const ids = [template_id,...this.state.child_templated.map((template) => template.id)]

    const data = [];

    if (ids.length > 0 && this.state.child_templates_updated === true) {

      for(const id of ids){

        const canvas = await this.GET_TEMPLATE_S3(id)
        const template = await fetch.refetch({ id:id })

        data.push({canvas,template})
      }

      const file = new Blob([JSON.stringify({ slides: ids, data })],
        { type: 'application/json' });

      const action = new ScoreshotsPut('ss3-slides', `${template_id}.json`, '');

      action.put(file, 'application/json', () => {});
    }

  }

  saveTemplate = (mutation) => {
    const { match } = this.props;
    const { template: template_id } = match.params;

    const {
      template_name,
      template_width,
      template_height,
      template_sports,
      template_categories,
      template_type,
      template_visible,
      template_disable_xml,
      template_starting_step,
      template_clients,
      template_description,
      template_filterTags,
    } = this.state;

    let visible = '';
    let isSlideShow = false;
    let isSlide = false;
    let isQuickCreate = false;

    if (template_type === 'WIDGET') {
      visible = 'WIDGET';
    } else if (template_visible) {
      visible = 'VISIBLE';
    } else {
      visible = 'HIDDEN';
    }
    if (template_type === 'QUICKCREATE') {
      isQuickCreate = true;
      this.saveQuickCreateSlides();
    }
    if (template_type === 'SLIDESHOW') {
      isSlideShow = true;
    } else if (template_type === 'SLIDE') {
      isSlide = true;
    }
    // this will make the template premium.
    if (template_clients.length > 0) {
      visible = 'CLIENT';
    }
    const variables = {
      id: template_id,
      name: template_name,
      width: template_width,
      height: template_height,
      categories: template_sports,
      types: template_categories,
      client: template_clients.map((client) => client.id),
      desc: template_description,
      generalUse: false,
      visible,
      isSlideShow,
      isQuickCreate,
      isSlide,
      disableXMLImport: template_disable_xml,
      startingStep: template_starting_step,
      filterTags: template_filterTags,
    };

    this.setState({ saving: true }, () => {
      mutation({
        variables,
      }).then((response) => {
        this.setState({
          dirty: false,
          saving: false,
        });
      });
    });
  }
}

// -------------------------------------------------------------------------- //

export default compose(
  graphql(GET_TEMPLATE, { name: 'getTemplate' }),
  graphql(GET_QUICK_CREATE_TEMPLATES, { name: 'getQuickCreateTemplates' }),
  graphql(GET_TAGS, { name: 'getTags' }),
  graphql(ADD_CHILD_TO_TEMPLATE, { name: 'addChildToTemplate' }),
  graphql(REMOVE_CHILD_FROM_TEMPLATE, { name: 'removeChildFromTemplate' }),
  graphql(ADD_EXISTING_TAG_TO_TEMPLATE, { name: 'addTagToTemplate' }),
  graphql(CREATE_TAG_WITH_TEMPLATE, { name: 'createTagWithTemplate' }),
  graphql(REMOVE_TAG_FROM_TEMPLATE, { name: 'removeTagFromTemplate' }),
  withStyles(STYLES, { withTheme: true }),
  withRouter,
)(TemplateEditor);

// -------------------------------------------------------------------------- //
