
// -------------------------------------------------------------------------- //

import React from 'react';

import { DraftsList } from '../components/DraftsList';
import { PageTitle } from '../../../title';

// -------------------------------------------------------------------------- //

const Drafts = (props) => (
  <React.Fragment>
    <PageTitle title="Drafts"/>
    <DraftsList {...props}/>
  </React.Fragment>
);

// -------------------------------------------------------------------------- //

export { Drafts };

// -------------------------------------------------------------------------- //
