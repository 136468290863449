
// -------------------------------------------------------------------------- //

import Sport from './Sport';

// -------------------------------------------------------------------------- //

const TEAMS = [
  'Home',
  'Visiting',
];

// -------------------------------------------------------------------------- //

class VolleyballSport extends Sport {

  constructor() {
    super();

    for (let t = 0; t < 2; ++t) {
      const team = `[${TEAMS[t]}]`;
      const team_id = `team.${TEAMS[t].toLowerCase()}`;
      this.push(`${team_id}.id`, `${team} Team ID`, { type: 'string' });
      this.push(`${team_id}.name`, `${team} Team Name`, { type: 'string' });
      this.push(`${team_id}.score`, `${team} Total Score`);
      this.push(`${team_id}.kills`, `${team} Total Kills`);
      this.push(`${team_id}.attacks.errors`, `${team} Hitting Errors`);
      this.push(`${team_id}.attacks.total`, `${team} Total Attempts`);
      this.push(`${team_id}.attacks.percent`, `${team} Hitting %`);
      this.push(`${team_id}.assists`, `${team} Total Assists`);
      this.push(`${team_id}.serve.aces`, `${team} Total Serve Aces`);
      this.push(`${team_id}.serve.errors`, `${team} Total Serve Errors`);
      this.push(`${team_id}.digs`, `${team} Total Digs`);
      this.push(`${team_id}.blocks`, `${team} Total Blocks`);
      this.push(`${team_id}.blocks.solos`, `${team} Total Block Solos`);
      this.push(`${team_id}.blocks.assists`, `${team} Total Block Assists`);
      this.push(`${team_id}.blocks.errors`, `${team} Total Block Errors`);

      for (let g = 0; g < 5; ++g) {
        const game = `(Game ${g + 1})`;
        const game_id = `game${g + 1}`;
        this.push(`${team_id}.score.${game_id}`, `${team} Score ${game}`);
        this.push(`${team_id}.kills.${game_id}`, `${team} Kills ${game}`);
        this.push(`${team_id}.attacks.errors.${game_id}`, `${team} Errors ${game}`);
        this.push(`${team_id}.attacks.total.${game_id}`, `${team} Attacks ${game}`);
        this.push(`${team_id}.attacks.percent.${game_id}`, `${team} Attack % ${game}`);
      }
    }

    const player = `[Player]`;
    this.push('player.kills', `${player} Kills`);
    this.push('player.aces', `${player} Aces`);
    this.push('player.digs', `${player} Digs`);
    this.push('player.assists', `${player} Assists`);
    this.push('player.blocks', `${player} Blocks`);
  }

};

// -------------------------------------------------------------------------- //

export default VolleyballSport;

// -------------------------------------------------------------------------- //
