import parseColor from "parse-color";

/* eslint no-underscore-dangle: "off" */
class BackgroundBox {
  constructor(fabric) {
    this.class = fabric.util.createClass(fabric.ClippingBox, {
      type: 'backgroundBox',
      constrainScaling: true,
      constrainMovement: true,
      stepMatrix: {
        default: {
          selectable: false,
          lockMovementX: true,
          lockMovementY: true,
          lockScalingX: true,
          lockScalingY: true,
          lockRotation: true,
          hasControls: false,
        },
        background: {
          selectable: true,
          lockMovementX: true,
          lockMovementY: true,
          lockScalingX: true,
          lockScalingY: true,
          lockRotation: true,
          hasControls: false,
        },
        admin: {
          selectable: true,
          lockMovementX: false,
          lockMovementY: false,
          lockScalingX: false,
          lockScalingY: false,
          lockRotation: false,
          hasControls: true,
        },
      },
      fill: '#555',
      clipMedia: true,
      fillType: 'color',
      setNone() {
        this.fillType = 'none';
        // TODO undo patterns and gradients
        this.set('fill', '#555');
      },
      setPattern(fill) {
        this.fillType = 'pattern';
        this.setPatternFill({
          source: fill,
          repeat: 'repeat',
        });
      },
      setColor(color) {
        this.fillType = 'color';
        this.set('fill', color);
      },
      setGradient(gradientOpts) {
        this.fillType = 'gradient';
        this.setGradient('fill', gradientOpts);
      },
      getColors() {
        if (this.fillType !== 'color') {
          return [];
        }

        return [{
          type: this.type,
          color: this.fill,
          object: this,
          property: 'fill',
          method: (_, value) => {
            const rgb = parseColor(value).rgb;
            const a = parseColor(this.get('fill')).rgba[3];
            this.set('fill', `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${a})`);
          },
        }];
      },
      setVideo(media, ignoreUndo = false) {
        const { attributes, src } = media;
        const oldMedia = this.media;
        this.clearMedia(oldMedia);
        if (this.videoEl) {
          document.body.removeChild(this.videoEl);
        }
        const videoEl = document.createElement('VIDEO');
        this.media = media;
        videoEl.style.display = 'none';
        videoEl.setAttribute('loop', 'loop');
        videoEl.setAttribute('crossOrigin', 'crossOrigin');
        videoEl.setAttribute('autoplay', 'autoplay');
        let load = () => {
          videoEl.removeEventListener('loadedmetadata',load)
          videoEl.setAttribute('width', videoEl.videoWidth);
          videoEl.setAttribute('height', videoEl.videoHeight);
          const video = new fabric.Image(videoEl, {});
          this.media = {
            type: 'video',
            element: video,
          };
          this.videoEl = videoEl;
          this.videoEl.muted = true;
          const minScale = this._determineMinScale();
          video.set({
            parent: this,
            // selectable: false,
            originX: 'center',
            originY: 'center',
            left: this.left + ((this.width * this.scaleX) / 2),
            top: this.top + ((this.height * this.scaleY) / 2),
            lockUniScaling: true,
            centeredScaling: true,
            lockRotation: true,
            scaleX: minScale,
            scaleY: minScale,
            videoEl,
            maxDuration: videoEl.duration,
            currentDuration: Math.min(videoEl.duration, 30),
            startTime: 0,
            isVideo: true,
            muted: true,
            excludeFromExport: true,
            minScale,
            minimumScaleTrigger: 1,
            clipTo: this._clipTo,
            lockScalingFlip: true,
            ...attributes,
          });
          // prevents crashes.
          video.getSrc = () => videoEl.getAttribute('src');

          videoEl.addEventListener('timeupdate', () => {
            const time = videoEl.currentTime;
            if (time > video.currentDuration + video.startTime) {
              videoEl.currentTime = video.startTime;
            }
          });
          videoEl.addEventListener('canplay', () => {
            videoEl.play();
          });
          this._setChildEventHandlers();
          // gotta mark us for rerender.
          this.dirty = true;
          if (this.canvas) {
            this.canvas.insertAt(
              video,
              this.canvas.getObjects().indexOf(this) + 1,
            );
            if (!ignoreUndo) {
              this.canvas.setActiveObject(video);
            }
          }
          const render = () => {
            this.canvas.requestRenderAll();
            this.request = fabric.util.requestAnimFrame(render);
          };
          fabric.util.requestAnimFrame(render);
          this.canvas.trigger('object:modified', { target: this, ignoreUndo });
        }

        videoEl.addEventListener('loadedmetadata',load);

        if (videoEl.canPlayType('video/mp4')) {
          videoEl.setAttribute('src', src);
        }
        document.body.appendChild(videoEl);
      },
      _handleStepChange() {
        this.callSuper('_handleStepChange');
        if (!this.canvas) {
          return;
        }

        if (this.canvas.step !== 'admin' && this.canvas.step !== 'background') {
          if (this.media && this.media.element) {
            this.media.element.set({
              evented: false
            })
          }
          if (this.fillType === 'none') {
            this.set({
              fill: 'transparent',
            });
          }
        } else if (this.fill === 'transparent') {
          this.set({
            fill: '#555',
          });
          if (this.media && this.media.element) {
            this.media.element.set({
              evented: true
            })
          }
        } else {
          if (this.media && this.media.element) {
            this.media.element.set({
              evented: true
            })
          }
        }

      },
      getCorners() {
        let top = null;
        let bottom = null;
        let left = null;
        let right = null;

        this.points.forEach((point) => {
          top = Math.min(top === null ? point.y : top, point.y);
          bottom = Math.max(bottom === null ? point.y : bottom, point.y);
          left = Math.min(left === null ? point.x : left, point.x);
          right = Math.max(right === null ? point.x : right, point.x);
        });

        return { top, bottom, left, right };
      },
      _determineMinScale() {
        const parentCoords = this.calcCoords(true);
        const minX = parentCoords.tr.x - parentCoords.tl.x;
        const minY = parentCoords.br.y - parentCoords.tr.y;
        const { element } = this.media;
        const minXScale = minX / element.width;
        const minYScale = minY / element.height;
        return (minXScale > minYScale ? minXScale : minYScale);
      },
    });

    this.fromObject = (object, callback) => fabric.Object._fromObject('BackgroundBox', object, callback, 'points');
  }
}


export default BackgroundBox;
