
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Avatar,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemAvatar,
  MenuItem,
  Select,
  TextField,
  Typography,
  withStyles,
  Fade,
} from '@material-ui/core';

import {
  Account as AccountIcon,
  Close as CloseIcon,
  DotsVerticalCircle as DotsVerticalCircleIcon,
  Folder as FolderIcon,
  FolderSearchOutline as FolderSearchOutlineIcon,
  Image as ImageIcon,
  ImageSearchOutline as ImageSearchOutlineIcon,
  Magnify as MagnifyIcon,
  StarCircle as StarCircleIcon,
  SubdirectoryArrowLeft as SubdirectoryArrowLeftIcon,
  Video as VideoIcon,
} from 'mdi-material-ui';

import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { InfiniteScroller } from '../../infinitescroller';

// -------------------------------------------------------------------------- //

const GET_FILES_BY_NAME = gql`
  query get_files_by_name(
    $type: MEDIA_TYPE,
    $query: String,
    $first: Int,
    $skip: Int
  ) {
    fileSearch(
      type: $type,
      name: $query,
      first: $first,
      skip: $skip
    ) {
      id
      name
      extension
      bucket
      client {
        id
      }
    }
  }
`;

const GET_FILES_BY_FOLDER = gql`
  query get_files_by_folder($type: MEDIA_TYPE, $folder: ID) {
    files(
      type: $type,
      folderId: $folder
    ) {
      id
      name
      extension
      bucket
      client {
        id
      }
    }
    folders(
      type: $type,
      folderId: $folder
    ) {
      id
      name
      parent {
        id
        name
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const MEDIA_TYPES = [
  {
    key: 'PHOTO',
    title: 'Photos',
    icon: <ImageIcon color="action"/>,
  },
  {
    key: 'VIDEO',
    title: 'Videos',
    icon: <VideoIcon color="action"/>,
  },
  {
    key: 'LOGO',
    title: 'Logos',
    icon: <StarCircleIcon color="action"/>,
  },
  {
    key: 'CUTOUT',
    title: 'Cutouts',
    icon: <AccountIcon color="action"/>,
  },
  {
    key: 'OTHER',
    title: 'Other',
    icon: <DotsVerticalCircleIcon color="action"/>,
  },
];

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  filter_menu: {
    padding: theme.spacing(1),
  },
  filter_field: {
    padding: theme.spacing(1),
    '&::-ms-clear': {
      display: 'none',
    },
  },
  results_empty: {
    textAlign: 'center',
    paddingTop: theme.spacing(1),
  },
  results_empty_icon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  loading_progress: {
    margin: '0 auto',
    width: theme.spacing(6),
  },
  media_file: {
    cursor: 'pointer',
    lineHeight: 0,
    position: 'relative',
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create(['box-shadow'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.short,
    }),
    boxShadow: theme.shadows[1],
    '&:hover': {
      boxShadow: theme.shadows[3],
    },
  },
  media_file_overlay: {
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.5)',
    bottom: 0,
    color: 'white',
    display: 'flex',
    height: theme.spacing(5),
    left: 0,
    padding: theme.spacing(2),
    pointerEvents: 'none',
    position: 'absolute',
    right: 0,
  },
  media_file_overlay_text: {
    overflow: 'hidden',
    pointerEvents: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

// -------------------------------------------------------------------------- //

class ImageLoader extends React.Component {

  state = {
    mode: 'loading',
    image: null,
  }

  componentDidMount() {
    this.reloadImage();
  }

  componentDidUpdate(previous_props) {
    if (this.props.src !== previous_props.src) {
      this.reloadImage();
    }
  }

  render() {
    const { mode, image } = this.state;
    const { render } = this.props;

    if (render) {
      return render({
        loading: (mode === 'loading'),
        loaded: (mode === 'loaded'),
        error: (mode === 'error'),
        image,
      });
    } else {
      const renderer = this.props[mode];
      return ((renderer && renderer(image)) || null);
    }
  }

  reloadImage = () => {
    const { src } = this.props;

    this.setState({ mode: 'loading' }, () => {
      let image = new Image();

      image.onload = () => {
        this.setState({ mode: 'loaded' }, () => {
          const { onLoad } = this.props;
          (onLoad && onLoad());
        });
      };

      image.onerror = (error) => {
        this.setState({ mode: 'error' }, () => {
          const { onError } = this.props;
          (onError && onError(error));
        });
      };

      image.src = src;
      this.setState({ image });
    });
  }

};

// -------------------------------------------------------------------------- //

class VideoLoader extends React.Component {

  state = {
    mode: 'loading',
    video: null,
  }

  componentDidMount() {
    this.reloadVideo();
  }

  componentDidUpdate(previous_props) {
    if (this.props.src !== previous_props.src) {
      this.reloadVideo();
    }
  }

  render() {
    const { mode, video } = this.state;
    const { render } = this.props;

    if (render) {
      return render({
        loading: (mode === 'loading'),
        loaded: (mode === 'loaded'),
        error: (mode === 'error'),
        video,
      });
    } else {
      const renderer = this.props[mode];
      return ((renderer && renderer(video)) || null);
    }
  }

  reloadVideo = () => {
    const { src } = this.props;

    this.setState({ mode: 'loading' }, () => {
      let video = document.createElement('video');

      video.oncanplaythrough = () => {
        this.setState({ mode: 'loaded' }, () => {
          const { onLoad } = this.props;
          (onLoad && onLoad());
        });
      };

      video.onerror = (error) => {
        this.setState({ mode: 'error' }, () => {
          const { onError } = this.props;
          (onError && onError(error));
        });
      };

      video.src = src;
      video.load();
      this.setState({ video });
    });
  }

};

// -------------------------------------------------------------------------- //

class MediaLibrary2 extends React.Component {

  state = {
    media_type: MEDIA_TYPES[0].key,
    media_folder: [], // media_folder[-1] is current folder (empty for root)
    result_folders: [],
    result_files: [],
    loading: true,
    loaded_files: 0,
    searching: false,
    search_query: '',
    search_query_last: null,
    search_page: 0,
    search_more: false,
  }

  componentDidMount() {
    const { media_type = null } = this.props;
    let refetch_immediate = true;
  
    if (media_type) {
      if (
        (media_type === 'STOCK') ||
        MEDIA_TYPES.find(i => (i.key === media_type))
      ) {
        refetch_immediate = false;

        this.setState({ media_type }, () => {
          this.refetchFolderContents();
        });
      }
    }

    if (refetch_immediate) {
      this.refetchFolderContents();
    }
  }

  render() {
    return (
      <div data-tour="step-7">
        {this.renderFilterMenu()}
        <Divider/>
        {this.renderMediaList()}
      </div>
    );
  }

  renderFilterMenu = () => {
    const { classes } = this.props;
    const { search_query, media_type } = this.state;

    const type_info = MEDIA_TYPES.find(t => t.key === media_type);

    return (
      <div className={classes.filter_menu}>
        <form onSubmit={(e) => { e.preventDefault(); this.submitSearchQuery(); }}>
          <TextField
            fullWidth
            placeholder="Search"
            className={classes.filter_field}
            value={search_query}
            onChange={(e) => this.setState({ search_query: e.target.value })}
            onBlur={() => this.submitSearchQuery()}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MagnifyIcon color="action"/>
                </InputAdornment>
              ),
              endAdornment: (
                (search_query) &&
                <InputAdornment position="end">
                  <IconButton onClick={() => this.cancelSearch()}>
                    <CloseIcon/>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </form>
        {
          (media_type !== 'STOCK') &&
          <FormControl fullWidth className={classes.filter_field}>
            <InputLabel htmlFor="media-type">Filter</InputLabel>
            <Select
              value={media_type}
              onChange={(e) => this.changeFilter(e.target.value)}
              renderValue={(value) => MEDIA_TYPES.find(i => i.key === value).title}
              startAdornment={(
                <InputAdornment position="start">
                  {type_info.icon}
                </InputAdornment>
              )}
              inputProps={{
                name: 'type',
                id: 'media-type',
              }}
            >
            {
              MEDIA_TYPES.map(i => (
                <MenuItem key={i.key} value={i.key}>
                  <ListItemIcon>{i.icon}</ListItemIcon>
                  <ListItemText>{i.title}</ListItemText>
                </MenuItem>
              ))
            }
            </Select>
          </FormControl>
        }
      </div>
    );
  }

  renderMediaListProgress = () => {
    const { classes } = this.props;
    const { loading, loaded_files, result_files } = this.state;

    // TODO: figure out how to fade these
    if (!(loading || loaded_files < result_files.length)) {
      return null;
    }

    return (
      <div className={classes.loading_progress}>
        <CircularProgress size={48}/>
      </div>
    );
  }

  renderMediaList = () => {
    const { classes } = this.props;
    const { loading, searching, media_type } = this.state;

    if (searching) {
      const { result_files } = this.state;

      return (
        <>
          <InfiniteScroller
            threshold={600}
            className={classes.filter_menu}
            page={this.state.search_page}
            more={this.state.search_more && (this.state.loaded_files === this.state.result_files.length)}
            onLoadPage={(page) => this.refetchSearchContents(page)}
            getScrollElement={() => this.props.scroll_element}
          >
            {
              result_files.map((file) => (
                this.renderMediaListFile(file)
              ))
            }
            {this.renderMediaListProgress()}
            {
              (result_files.length === 0 && !loading) &&
              <div className={classes.results_empty}>
                <FolderSearchOutlineIcon color="disabled" className={classes.results_empty_icon}/>
                <Typography color="action">No results found.</Typography>
              </div>
            }
          </InfiniteScroller>
        </>
      );
    } else {
      const { result_folders, result_files } = this.state;

      return (
        <>
          <List>
          {
            (this.getCurrentFolderID() !== null) &&
            <ListItem dense button onClick={() => this.popMediaFolder()}>
              <ListItemAvatar>
                <Avatar>
                  <SubdirectoryArrowLeftIcon/>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={`${
                this.getParentFolder() !== null
                ? this.getParentFolder().name
                : 'Back'
              }`}/>
            </ListItem>
          }
          {
            result_folders.filter((folder) => (
              this.getCurrentFolderID() !== null || !folder.parent
            )).map((folder) => (
              this.renderMediaListFolder(folder)
            ))
          }
          </List>
          <div className={classes.filter_menu}>
            {
              result_files.map((file) => (
                this.renderMediaListFile(file)
              ))
            }
            {this.renderMediaListProgress()}
            {
              (result_files.length === 0 && !loading && media_type !== 'STOCK') &&
              <div className={classes.results_empty}>
                <ImageSearchOutlineIcon color="disabled" className={classes.results_empty_icon}/>
                <Typography color="action">This folder has no files.</Typography>
              </div>
            }
          </div>
        </>
      );
    }
  }

  renderMediaListFolder = (folder) => {
    return (
      <Fade in key={folder.id}>
        <ListItem
          dense
          button
          key={folder.id}
          onClick={() => this.changeFolder(folder)}
        >
          <ListItemAvatar>
            <Avatar>
              <FolderIcon/>
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={folder.name}/>
        </ListItem>
      </Fade>
    );
  }

  renderMediaListFile = (file) => {
    const { classes } = this.props;

    const overlay = (
      <div className={classes.media_file_overlay}>
        <Typography
          className={classes.media_file_overlay_text}
          color="inherit"
          variant="caption"
        >
          {file.name}
        </Typography>
      </div>
    );

    if (file.extension === 'mp4') {
      const src = (
        `https://${file.bucket}.s3.us-east-2.amazonaws.com/${file.client.id}/${file.id}.${file.extension}`
      );

      return (
        <VideoLoader
          src={src}
          onLoad={() => this.setState({ loaded_files: (this.state.loaded_files + 1) })}
          onError={() => this.setState({ loaded_files: (this.state.loaded_files + 1) })}
          render={({ loaded, video }) => (
            <Fade key={file.id} in={loaded}>
              <div
                className={classes.media_file}
                onClick={() => this.selectMediaFile(file)}
              >
                {
                  (loaded && video.src) &&
                  <video muted autoPlay style={{ width: '100%' }}>
                    <source src={video.src}/>
                  </video>
                }
                {overlay}
              </div>
            </Fade>
          )}
        />
      );
    } else {
      const src = (
        `https://${file.bucket}.s3.us-east-2.amazonaws.com/${file.client.id}/${file.id}.${file.extension}?1`
      );

      return (
        <ImageLoader
          key={file.id}
          src={src}
          onLoad={() => this.setState({ loaded_files: (this.state.loaded_files + 1) })}
          onError={() => this.setState({ loaded_files: (this.state.loaded_files + 1) })}
          render={({ loaded, image }) => (
            <Fade key={file.id} in={loaded}>
              <div
                className={classes.media_file}
                onClick={() => this.selectMediaFile(file)}
              >
                {
                  (loaded && image.src) &&
                  <img src={image.src} alt={file.name} style={{ width: '100%' }}/>
                }
                {overlay}
              </div>
            </Fade>
          )}
        />
      );

      //content = (
      //  <ImageLoader
      //    src={src}
      //    loading={(image) => (
      //      <div style={{ width: '100%', height: 0, paddingBottom: getImageAspectRatio(image) }}>
      //        <CircularProgress size={48}/>
      //      </div>
      //    )}
      //    loaded={(image) => (
      //      <img src={image} alt={file.name} style={{ width: '100%' }}/>
      //    )}
      //    error={(image) => (
      //      <img
      //        src={
      //          (image.naturalWidth && image.naturalHeight)
      //          ? `https://via.placeholder/${image.naturalWidth}/${image.naturalHeight}.png`
      //          : `https://via.placeholder/300.png`
      //        }
      //        alt={file.name}
      //        style={{ width: '100%' }}
      //      />
      //    )}
      //    alt={file.name}
      //  />
      //);
    }
  }

  selectMediaFile = (file) => {
    const { onSelect } = this.props;

    if (onSelect) {
      onSelect(file);
    }
  }

  getParentFolder = () => {
    const { media_folder } = this.state;

    if (media_folder.length < 2) {
      return null;
    }

    return media_folder[media_folder.length - 2];
  }

  getParentFolderID = () => {
    const folder = this.getParentFolder();

    if (folder === null) {
      return null;
    }

    return folder.id;
  }

  getCurrentFolder = () => {
    const { media_folder } = this.state;

    if (media_folder.length === 0) {
      return null;
    }

    return media_folder[media_folder.length - 1];
  }

  getCurrentFolderID = () => {
    const folder = this.getCurrentFolder();

    if (folder === null) {
      return null;
    }

    return folder.id;
  }

  changeFolder = (folder) => {
    let { media_folder } = this.state;
    media_folder.push({ id: folder.id, name: folder.name });

    this.setState({
      result_folders: [],
      result_files: [],
      search_page: 0,
      loading: true,
      loaded_files: 0,
    }, () => {
      this.refetchFolderContents();
    });
  }

  refetchFolderContents = () => {
    const { get_files_by_folder } = this.props;
    const { media_type } = this.state;
    if (media_type === null) {
      return null;
    }
    get_files_by_folder.refetch({
      type: media_type,
      folder: this.getCurrentFolderID(),
    }).then((response) => {
      get_files_by_folder.refetch({
        type: media_type,
        folder: this.getCurrentFolderID(),
      }).then((response) => {
        if (response && response.data) {
          this.setState({
            loading: false,
            loaded_files: 0,
            result_files: (response.data.files || []),
            result_folders: (response.data.folders || []),
            search_page: 0,
          });
        } else if (this.props && this.props.get_files_by_folder) {
          this.setState({
            loading: false,
            loaded_files: 0,
            result_files: (this.props.get_files_by_folder.files || []),
            result_folders: (this.props.get_files_by_folder.folders || []),
            search_page: 0,
          });
        }
      });
    });
  }

  refetchSearchContents = (page) => {
    const { get_files_by_name } = this.props;
    const { media_type, search_query } = this.state;

    return get_files_by_name.refetch({
      type: media_type,
      query: search_query,
      first: 20,
      skip: (20 * page),
    }).then((result) => {
      let files = [...result.data.fileSearch];
      const search_more = (files.length === 20);

      if (page > 0) {
        files = [...this.state.result_files, ...files];
      }

      this.setState({
        loading: false,
        loaded_files: this.state.result_files.length,
        result_files: files,
        search_page: (page + 1),
        search_more,
      });
    }).catch(() => {
      this.setState({
        loading: false,
        loaded_files: 0,
        result_files: [],
        search_page: 0,
        search_more: false,
      });
    });
  }

  setSearching = (searching) => {
    if (searching === this.state.searching) {
      return;
    }

    if (searching) {
      this.setState({
        searching,
        search_query: '',
        search_query_last: this.state.search_query,
        result_folders: [],
        result_files: [],
        loading: false,
        loaded_files: 0,
      });
    } else {
      this.setState({
        searching,
        search_query: '',
        search_query_last: this.state.search_query,
        media_folder: [],
        result_folders: [],
        result_files: [],
        loading: true,
        loaded_files: 0,
      }, () => {
        this.refetchFolderContents();
      });
    }
  }

  cancelSearch = () => {
    this.setState({
      search_query: '',
      search_query_last: null,
      media_folder: [],
      result_folders: [],
      result_files: [],
      searching: false,
      loading: true,
      loaded_files: 0,
    }, () => {
      this.refetchFolderContents();
    });
  }

  submitSearchQuery = () => {
    const search_query = this.state.search_query.trim();

    if (search_query === this.state.search_query_last) {
      return;
    }

    if (search_query) {
      let state = {
        search_query_last: search_query,
        loading: true,
        loaded_files: 0,
        searching: true,
        result_files: [],
        search_page: 0,
        search_more: false,
      };

      this.setState(state, () => {
        this.refetchSearchContents(0);
      });
    }
  }

  popMediaFolder = () => {
    let { media_folder } = this.state;

    if (media_folder.length > 0) {
      media_folder.pop();
    }

    this.setState({
      result_folders: [],
      result_files: [],
      loading: true,
    }, () => {
      this.refetchFolderContents();
    });
  }

  changeFilter = (type) => {
    if (type === this.state.media_type) {
      return;
    }

    this.setState({
      media_type: type,
      media_folder: [],
      result_folders: [],
      result_files: [],
      search_page: 0,
      search_more: false,
      loading: true,
    }, () => {
      const { searching } = this.state;

      if (searching) {
        this.refetchSearchContents(0);
      } else {
        this.refetchFolderContents();
      }
    });
  }

};

// -------------------------------------------------------------------------- //

export default compose(
  graphql(GET_FILES_BY_FOLDER, { name: 'get_files_by_folder',
    options: { fetchPolicy: 'cache-and-network' } }),
  graphql(GET_FILES_BY_NAME, { name: 'get_files_by_name',
    options: { fetchPolicy: 'cache-and-network' } }),
  withStyles(STYLES),
)(MediaLibrary2);

// -------------------------------------------------------------------------- //
