
// -------------------------------------------------------------------------- //

import * as React from 'react';

import {
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  ClockOutline as ClockOutlineIcon,
  ChevronDown as ChevronDownIcon,
} from 'mdi-material-ui';

import classnames from 'classnames';

import ColorPicker from '../color/ColorPicker';
import { ColorButton } from '../color/ColorButton';

// -------------------------------------------------------------------------- //

export const ANIMATION_KEYS = [
  "top", "left", "width", "height", "scaleX",
  "scaleY", "angle", "opacity", "fillOverride",
  "strokeOverride", "fill", "stroke", "shadow",
  "stat_factor",
];

// -------------------------------------------------------------------------- //

export const createStateForChosenIndex = () => {
  let stateObj = {};
  for( let x = 0; x < ANIMATION_KEYS.length; x++ ){
    stateObj[ANIMATION_KEYS[x]] = -100;
  }
  return stateObj
}

// -------------------------------------------------------------------------- //

export const handleColorChangeAnimation = (onChange, canvas, canvasUtil, index, prop, color) => {
  let active_object = canvas.getActiveObject();
  let { animations } = active_object.animation;
  animations[index][prop] = color.rgba;
  let apply_all = true;

  for (let i = 0; i < animations.length; ++i) {
    if (i === index) {
      continue;
    }

    if (animations[i][prop] !== animations[index][prop]) {
      apply_all = false;
      break;
    }
  }

  if (apply_all) {
    switch (prop) {
      case 'fill':
      case 'stroke': {
        active_object.set(prop, color.rgba);
        break;
      }
      default: {
        break;
      }
    }
  }

  canvasUtil.dirtyCanvas();
  canvas.requestRenderAll();
  (onChange && onChange(color.rgba, index));
}

// -------------------------------------------------------------------------- //

const applyToAll = (onChange, canvas, canvasUtil, prop, value ) => () => {
  let active_object = canvas.getActiveObject();
  let length = active_object.animation.animations.length;

  for( let i = 0; i < length; i++ ){
     if(!( prop in active_object.animation.animations[i])){continue;}
     active_object.animation.animations[i][prop] = value;
  }

  switch (prop) {
    case 'fill':
    case 'stroke': {
      active_object.set(prop, value);
      break;
    }
    default: {
      break;
    }
  }

  canvasUtil.dirtyCanvas();
  canvas.requestRenderAll();
  (onChange && onChange(value, null));
}

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  summary: {
    alignItems: 'end',
    display: 'flex',
  },
  swatch: {
    boxShadow: theme.shadows[1],
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
    width: theme.spacing(3),
  },
  time_icon: {
    marginRight: theme.spacing(1),
  },
});

// -------------------------------------------------------------------------- //

const ColorAnimation = (props) => {
  const {
    classes,
    canvas,
    canvasUtil,
    prop,
    expanded: selectIndex,
    onExpandChange: funct,
    onChange,
  } = props;

  const active = canvas.getActiveObject();

  let [ state, setState ] = React.useState({
    picker_open: false,
    anchor_el: null,
    active_picker: null,
  });

  let { animation } = active;

  if (!animation) {
    return null;
  }

  let { animations } = animation;

  if (!animations) {
    return null;
  }

  return (
    <div className={classes.root}>
      {active.animation.animations.map((_, index) => {
        if (active.animation.animations[index] === null) {
          return null;
        }

        if (!(prop in active.animation.animations[index])) {
          return null;
        }

        const duration = active.animation.animations[index]['startTime'];
        const minutes = Math.floor(duration / 60);
        const seconds = (duration % 60);

        return (
          <ExpansionPanel
            key={index}
            elevation={1}
            expanded={selectIndex === index}
            onChange={() => funct(index)}
          >
            <ExpansionPanelSummary
              expandIcon={
                <ChevronDownIcon
                  className={classnames(
                    classes.expand_icon_collapsed,
                    { [classes.expand_icon_expanded]: (selectIndex === index) },
                  )}
                />
              }
            >
              <div className={classes.summary}>
                <div
                  className={classes.swatch}
                  style={{
                    backgroundColor: active.animation.animations[index][prop]
                  }}
                />
                <ClockOutlineIcon className={classes.time_icon}/>
                <Typography variant="subtitle1">
                  {minutes}:{seconds < 10 ? '0' : ''}{seconds.toFixed(1)}
                </Typography>
              </div>
            </ExpansionPanelSummary>
            <ColorButton
              color={active.animation.animations[index][prop]}
              onClick={(e) => {
                setState({
                  picker_open: true,
                  anchor_el: e.target,
                  active_picker: index,
                });
              }}
            />
            <ColorPicker
              open={
                Boolean(state.picker_open) &&
                (state.active_picker === index)
              }
              onClose={() => setState((old) => ({
                ...old, picker_open: false
              }))}
              anchorEl={state.anchor_el}
              canvas={canvas}
              color={active.animation.animations[index][prop]}
              onChangeComplete={(color) => (
                handleColorChangeAnimation(onChange,canvas,canvasUtil,index,prop, color)
              )}
            />
            {
              (active.animation.animations.length > 1) &&
              <Button
                variant="outlined"
                color="primary"
                onClick={applyToAll(onChange,canvas,canvasUtil,prop, active.animation.animations[index][prop])}
              >
                Apply to all
              </Button>
            }
          </ExpansionPanel>
      );
    })}
    </div>
  );
}

// -------------------------------------------------------------------------- //

export default withStyles(STYLES)(ColorAnimation);

// -------------------------------------------------------------------------- //
