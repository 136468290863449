
// -------------------------------------------------------------------------- //

import * as React from 'react';

import {
  Button,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';

import classnames from 'classnames';

import './selectplan.css';
import history from '../../history';
import StripeDict from './StripeDict';

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  app_bar: {
    position: 'relative',
    flex: 0,
  },
  border: {
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
  },
  center: {
    textAlign: 'center',
  },
  flex: {
    flex: 1,
  },
  root: {
    flex: 1,
    overflow: 'auto',
  },
  paragraph_2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paragraph_3: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  paragraph_4: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  paragraph_6: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  top_margin_5: {
    marginTop: theme.spacing(5),
  },
  plan_card: {
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 2,
  },
  plan_card_border: {
    borderColor: theme.palette.primary.main,
  },
  plan_card_header: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    display: 'flex',
    height: theme.spacing(5),
    justifyContent: 'center',
    textAlign: 'center',
  },
  plan_card_header_empty: {
    height: theme.spacing(5),
  },
});

// -------------------------------------------------------------------------- //

class SelectPlan extends React.Component {

  state = {
    tier: 0,  // 0 = annually, 1 = monthly
  }

  render() {
    const { classes } = this.props;
    const { tier } = this.state;
    const epoch = (tier === 0 ? 'yr' : 'mo');
    const best_value = 1; // all-star

    return (
      <div className={classes.center}>
        <div className={classes.paragraph_6}>
          <Typography variant="h5">Pick The Plan That</Typography>
          <Typography variant="h5"><strong>Works Best For Your Team!</strong></Typography>
        </div>
        <div className={classes.border}>
          <Button onClick={() => this.setState({ tier: 0 })}>
            Annual
          </Button>
          <Button onClick={() => this.setState({ tier: 1 })}>
            Monthly
          </Button>
        </div>
        <div className={classes.paragraph_4}>
          <Typography>
            <strong>14-Day Risk-Free Trial. No Hidden Fees.</strong>
          </Typography>
        </div>
        <Grid container>
          {StripeDict.proplans.map((plan, index) => (
          <Grid
            item
            xs={12}
            sm={4}
            key={index}
            className={classnames(
              classes.plan_card,
              { [classes.plan_card_border]: (index === best_value) },
            )}
          >
            {
              (index === best_value) &&
              <div className={classes.plan_card_header}>
                <Typography color="inherit">Best Value</Typography>
              </div>
            }
            {
              (index !== best_value) &&
              <div className={classes.plan_card_header_empty}/>
            }
            <div className={classes.top_margin_5}>
              <Typography variant="h6"><strong>{plan.plan}</strong></Typography>
            </div>
            <div className={classes.paragraph_2}>
              <Typography variant="h6"><strong>${plan.tiers[tier].price}</strong>/{epoch}</Typography>
            </div>
            <Button color="primary" variant="contained" size="small">Try for free</Button>
            <div className={classes.paragraph_4}>
              {plan.description.map((e, i) => (
              <div key={i}>
                {e}
              </div>
              ))}
            </div>
          </Grid>
          ))}
        </Grid>
        <div className={classes.paragraph_6}>
          <Typography>Don't see a pricing that fits your budget?</Typography>
          <Typography>Reach out to see how we can customize a package just for your team or organization.</Typography>
        </div>
        <div className={classes.paragraph_6}>
          <Typography variant="subtitle1"><strong>Are you a High School looking to utilize ScoreShots?</strong></Typography>
          <Typography variant="subtitle1"><strong>View our High School pricing here.</strong></Typography>
        </div>
      </div>
    );
  }

  goToPlan = (plan) => {
    const method =  (plan.type === 0 ? 'annually' : 'monthly');
    history.push(`/pay/${method}/${plan.type}/${plan.id}`)
  }

}

// -------------------------------------------------------------------------- //

export default withStyles(STYLES)(SelectPlan);

// -------------------------------------------------------------------------- //
