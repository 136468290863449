import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({

  suscriptionContainer: {
    width: '75%',
    border: '3px solid #592570',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '@media(min-width: 700px)': {
      width: '75%',
      height: '60vmin',
    },
    '@media(max-width: 700px)': {
      width: '90%',
      height: '70vmin',
    },
  },
  suscriptionTitle: {
    fontSize: '4vmin',
    fontWeight: 600,
    color: '#592570',
    '@media(min-width: 1024px)': {
      fontSize: '4vmin',
    },
    '@media(max-width: 1023px)': {
      fontSize: '3vmin',
    },
  },
  priceContainer: {
    width: '100%',
    height: '20%',
    backgroundColor: '#592570',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '3vmin',
    color: '#FFF',
  },
  choosePlanButton: {
    marginTop: '10%',
    border: '1px solid #592570',
    backgroundColor: '#592570',
    color: '#FFF',
    fontSize: '1.5vmin',
    borderRadius: '50px',
    height: '4vmin',
    width: '15vmin',
    '&:hover': {
      backgroundColor: '#592570',
    },
  },
  content: {
    whiteSpace: 'pre-line',
    fontSize: '2vmin',
    '@media(min-width: 700px)': {
      lineHeight: '2.5vmin',
    },
    '@media(max-width: 700px)': {
      fontSize: '2.3vmin',
      lineHeight: '2vmin',
      height: '100%',
    },
  },

}));

export default useStyles;
