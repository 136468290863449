
// -------------------------------------------------------------------------- //

import React from 'react';

import * as Binding from '../../../canvas/lib/databinding';
import * as Sports from '../../sport/Sports';
import Crumbs from './Crumbs';
import Tip from './Tip';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  Import,
  Refresh,
} from 'mdi-material-ui';

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  root: { },
  content: {
    margin: theme.spacing(2),
  },
  button: {
    marginBottom: theme.spacing(1),
  },
  buttonIcon: {
    position: 'absolute',
    left: theme.spacing(2),
    opacity: 0.87,
  },
  teamGrid: { },
  teamCell: {
    padding: theme.spacing(1),
  },
  switchLabel: {
    userSelect: 'none',
  },
  stat: {
    margin: `${theme.spacing(1)}px 0px`,
  },
  statTitle: {
    marginTop: theme.spacing(1),
  },
  menuSubheader: {
    height: theme.spacing(6),
  },
});

// -------------------------------------------------------------------------- //

// the transform prop here is to fix a known chrome issue
// see mui-org/material-ui/commit/9b9421c for details
const MENU_PROPS = {
  PaperProps: {
    style: {
      transform: 'translate3D(0,0,0)',
    },
  },
};

// -------------------------------------------------------------------------- //

function updateBoundObjects(objects, sport) {
  objects.forEach(object => Binding.UpdateBindings(object, sport));
  return objects;
};

// -------------------------------------------------------------------------- //

function getVariableText(variable, index = 0) {
  const value = variable.getValue(index);

  if (value === undefined || value === null) {
    return '';
  }

  if (variable.type === 'number' && isNaN(value)) {
    return '';
  }

  return String(value);
}

// -------------------------------------------------------------------------- //

function getPlayerText(player) {
  let value = '';

  if (player.name.first && player.name.last) {
    value = `${player.name.first} ${player.name.last}`;
  } else {
    value = `${player.name.long}`;
  }

  if (player.position) {
    value = `${value} (${player.position})`;
  }

  return value;
}

// -------------------------------------------------------------------------- //

class Stat extends React.Component {

  state = {
    text: '',
    focused: false,
  }

  handleTyping = (e) => {
    const { variable, index = 0, onChange } = this.props;
    const text = e.target.value;
    const value = variable.convertValue(text);
    this.setState({ text, value });
    (onChange && onChange(variable, value, index));
  }

  handleFocusChange = (focused) => {
    if (focused) {
      const { variable, index = 0 } = this.props;
      const text = getVariableText(variable, index);
      this.setState({ text, focused });
    } else {
      this.setState({ focused });
    }
  }

  render() {
    const { classes, variable, periods = 0, index = 0 } = this.props;
    const { text, focused } = this.state;
    const value = (focused ? text : getVariableText(variable, index));
    const error = (variable.type === 'number' && isNaN(value.trim()));
    let label = variable.name;

    if (variable.period !== null && periods > 0) {
      let period_type = '';

      if (variable.sport instanceof Sports.sports.Baseball) {
        period_type = 'Inning';
      } else {
        switch (periods) {
          case 2: {
            period_type = 'Half';
            break;
          }
          case 4: {
            period_type = 'Quarter';
            break;
          }
          default: {
            period_type = 'Period';
            break;
          }
        }
      }


      label = variable.getDisplayName(period_type);
    }

    label = label.replace(/\[[^\]]*\]/g, '').trim();

    return (
      <TextField
        fullWidth
        className={classes.stat}
        label={label}
        value={value}
        error={error}
        onChange={this.handleTyping}
        onFocus={() => this.handleFocusChange(true)}
        onBlur={() => this.handleFocusChange(false)}
      />
    );
  }

}

// -------------------------------------------------------------------------- //

class Stats extends React.Component {

  state = {
    xml: null,
    swap: false,
    mapping: [],
    importDialogOpen: false,
    prestoImport: false,
    prestoSite: false,
    prestoJsonData: null,
    prestoXML: null,
  }

  constructor() {
    super();
    this.sports = {};

    for (let key in Sports.sports) {
      this.sports[key] = Sports.CreateSport(key);
    }

    this.sports.default = Sports.CreateSport();
  }

  componentDidMount() {
    const prestoSite = localStorage.getItem('prestoSite');
    const eventId = localStorage.getItem('eventid');
    const bearer = localStorage.getItem('bearer');
    let prestoXML;
    if (prestoSite === 'true') {
      let prestoAPIEndpoint = process.env.REACT_APP_PS_GAMEDAY_API_ENDPOINT;
      if (eventId && eventId !== '') {
        fetch(prestoAPIEndpoint + 'events/' + eventId + '/stats', {
          headers: {
            'Authorization': 'Bearer ' + bearer
          }
        })
          .then((response) => response.json())
          .then((jsonData) => {
            if (jsonData && jsonData.data && jsonData.data.xml) {
              prestoXML = (new DOMParser()).parseFromString(jsonData.data.xml, 'text/xml');
              this.setState({
                prestoSite: true,
                prestoJsonData: jsonData,
                prestoXML
              })
            }
          })
          .catch((error) => {
            console.error('error', error);
            this.setState({
              prestoSite: true,
            })
          });
      }
    }
  }

  render() {
    const { classes, canvas, template } = this.props;
    const { prestoSite, prestoImport } = this.state;
    const sport = this.getCanvasSport();

    if (!sport) {
      return null;
    }
    if (prestoSite && prestoImport === false) {
      this.prestoXMLFile();
    }

    let template_disable_xml = false;

    if (template && template.disableXMLImport) {
      template_disable_xml = true;
    }

    const periods = Binding.CountPeriods(canvas.getObjects(), sport);
    const venue = this.renderVenueStats(periods);
    const home = this.renderHomeStats(periods);
    const visiting = this.renderVisitingStats(periods);

    const players = this.getCanvasPlayers().map((player, index) => ({
      title: player.name,
      stats: this.renderPlayerStats(player, periods, index)
    }));

    return (
      <div className={classes.root}>
        <Crumbs title="Stats" />
        {
          (template_disable_xml)
          ? <Tip heading="Note:" text="Importing XML files is currently disabled for this template."/>
          : <Tip heading="Tip:" text="You can automatically fill out your stats by importing an XML file."/>
        }
        <div className={classes.content}>
          {
            (!template_disable_xml) &&
            <React.Fragment>
              <input
                style={{ display: 'none' }}
                accept="text/xml"
                id="xmlUploader"
                type="file"
                name="file"
                onChange={this.uploadXmlFile}
              />
              <label htmlFor="xmlUploader">
                <Button className={classes.button} component="span" fullWidth>
                  <Import className={classes.buttonIcon} />
                  Import Stats
                </Button>
              </label>
            </React.Fragment>
          }
          <Button className={classes.button} onClick={this.resetStats} fullWidth>
            <Refresh className={classes.buttonIcon}/>
            Reset Stats
          </Button>
          <Divider />
          {venue}
          {
            home.length > 0 &&
            <Typography key="Home" className={classes.statTitle} variant="h6">Home</Typography>
          }
          {home}
          {
            visiting.length > 0 &&
            <Typography key="Visiting" className={classes.statTitle} variant="h6">Visiting</Typography>
          }
          {visiting}
          {players.filter(player => player.stats.length > 0).map(player => [
            <Typography key={player.title} className={classes.statTitle} variant="h6">{player.title}</Typography>,
            ...player.stats,
          ])}
        </div>
        {this.renderImportDialog()}
      </div>
    );
  }

  getSportCategory = () => {
    const { template } = this.props;
    return Sports.GetCategorySport(template && template.categories);
  }

  getTemplateSport = () => {
    return this.sports[this.getSportCategory()];
  }

  isStartingLineup = () => {
    const { template } = this.props;

    return (
      template &&
      template.types &&
      template.types.findIndex(type => type.name === 'Starting Lineup') >= 0
    );
  }

  getCanvasSport = () => {
    const { editor } = this.props;
    return editor.sports[this.getSportCategory()];
  }

  gatherVariables = (player = null) => {
    const { CanvasUtil } = this.props;
    const { canvas } = CanvasUtil;
    const objects = canvas.getObjects().filter(Binding.HasBindings);
    const sport = this.getCanvasSport();

    return Binding.GatherStats(
      updateBoundObjects(objects, sport), player
    );
  }

  getTemplateVariable = (id) => {
    const sport = this.getTemplateSport();

    if (!sport) {
      return null;
    }

    return sport.getVariableById(id);
  }

  getCanvasVariable = (id) => {
    const sport = this.getCanvasSport();

    if (!sport) {
      return null;
    }

    return sport.getVariableById(id);
  }

  setCanvasVariable = (id, value, index = 0) => {
    const { editor, CanvasUtil } = this.props;
    const sport = this.getCanvasSport();

    if (!sport) {
      return;
    }

    const variable = sport.getVariableById(id);

    if (!variable) {
      return;
    }

    variable.setValue(value, index);
    CanvasUtil.dirtyCanvas();
    editor.performBinding();
    this.forceUpdate();
  }

  getCanvasPlayers = () => {
    const { CanvasUtil } = this.props;
    const { canvas } = CanvasUtil;
    return ((canvas && canvas.scoreshots && canvas.scoreshots.players) || []);
  }

  resetStats = () => {
    const { editor } = this.props;
    const sport = this.getCanvasSport();

    if (sport) {
      sport.reset(variable => (
        !(this.isStartingLineup() && variable.lineup)
      ));
      editor.performBinding();
    }
  }

  prestoXMLFile = () => {
    const { prestoXML } = this.state;
    if (prestoXML) {
      this.openImportDialog(prestoXML);
    }
    this.setState({
      prestoImport: true,
    })
  }

  uploadXmlFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      let xml;

      if (window.ActiveXObject) {
        xml = new window.ActiveXObject('Microsoft.XMLDOM');
        xml.loadXML(reader.result);
      } else {
        xml = (new DOMParser()).parseFromString(reader.result, 'text/xml');
      }

      this.openImportDialog(xml);
    }

    reader.readAsText(file);
    e.target.value = null;
  }

  importXmlFile = () => {
    const { editor } = this.props;
    const { xml, mapping, swap } = this.state;

    editor.importXmlFile(xml, {
      mapping,
      swap,
    });

    this.closeImportDialog();
  }

  openImportDialog = (xml) => {
    const category = this.getSportCategory();
    const sport = this.getTemplateSport();

    Sports.importers[category].forEach(Importer => {
      const importer = new Importer();
      importer.import(sport, xml);
    });

    const players = this.getCanvasPlayers();
    const mapping = players.map(() => null);

    this.setState({
      importDialogOpen: true,
      mapping,
      xml,
    });
  }

  closeImportDialog = () => {
    this.setState({
      xml: null,
      mapping: [],
      swap: false,
      importDialogOpen: false,
    }, () => {
      const sport = this.getTemplateSport();

      if (sport) {
        sport.reset();
      }
    });
  }

  renderStat = (variable, key, periods, index = 0) => {
    const { classes } = this.props;

    if (!variable) {
      return null;
    }

    return (
      <Stat
        key={key}
        classes={classes}
        variable={variable}
        periods={periods}
        index={index}
        onChange={(_, v, i) => this.setCanvasVariable(variable.id, v, i)}
      />
    );
  }

  renderVenueStats = (periods = null) => {
    if (periods === null) {
      const { CanvasUtil } = this.props;
      const { canvas } = CanvasUtil;

      periods = Binding.CountPeriods(
        canvas.getObjects(), this.getCanvasSport()
      );
    }

    return this.gatherVariables()
    .filter(variable => /\bvenue\b/i.test(variable))
    .map(variable => this.getCanvasVariable(variable))
    .filter(Boolean)
    .sort((lhs, rhs) => {
      if (lhs.sort !== rhs.sort) {
        return (rhs.sort - lhs.sort);
      }

      const lhs_name = lhs.getDisplayName();
      const rhs_name = rhs.getDisplayName();

      if (lhs_name.length !== rhs_name.length) {
        return (lhs_name.length - rhs_name.length);
      }

      if (lhs_name < rhs_name) {
        return -1;
      } else if (lhs_name > rhs_name) {
        return 1;
      } else {
        return 0;
      }
    })
    .map((variable, key) => this.renderStat(variable, `Venue-${key}`, periods));
  }

  renderHomeStats = (periods = null) => {
    if (periods === null) {
      const { CanvasUtil } = this.props;
      const { canvas } = CanvasUtil;

      periods = Binding.CountPeriods(
        canvas.getObjects(), this.getCanvasSport()
      );
    }

    return this.gatherVariables()
    .filter(variable => /\bhome\b/i.test(variable))
    .map(variable => this.getCanvasVariable(variable))
    .filter(Boolean)
    .sort((lhs, rhs) => {
      if (lhs.sort !== rhs.sort) {
        return (rhs.sort - lhs.sort);
      }

      const lhs_name = lhs.getDisplayName();
      const rhs_name = rhs.getDisplayName();

      if (lhs_name.length !== rhs_name.length) {
        return (lhs_name.length - rhs_name.length);
      }

      if (lhs_name < rhs_name) {
        return -1;
      } else if (lhs_name > rhs_name) {
        return 1;
      } else {
        return 0;
      }
    })
    .map((variable, key) => this.renderStat(variable, `Home-${key}`, periods));
  }

  renderVisitingStats = (periods = null) => {
    if (periods === null) {
      const { CanvasUtil } = this.props;
      const { canvas } = CanvasUtil;

      periods = Binding.CountPeriods(
        canvas.getObjects(), this.getCanvasSport()
      );
    }

    return this.gatherVariables()
    .filter(variable => /\bvisiting\b/i.test(variable))
    .map(variable => this.getCanvasVariable(variable))
    .filter(Boolean)
    .sort((lhs, rhs) => {
      if (lhs.sort !== rhs.sort) {
        return (rhs.sort - lhs.sort);
      }

      const lhs_name = lhs.getDisplayName();
      const rhs_name = rhs.getDisplayName();

      if (lhs_name.length !== rhs_name.length) {
        return (lhs_name.length - rhs_name.length);
      }

      if (lhs_name < rhs_name) {
        return -1;
      } else if (lhs_name > rhs_name) {
        return 1;
      } else {
        return 0;
      }
    })
    .map((variable, key) => this.renderStat(variable, `Visiting-${key}`, periods));
  }

  renderPlayerStats = (player, periods, index) => {
    return this.gatherVariables(index)
    .filter(variable => /\bplayer\b/i.test(variable))
    .map(variable => this.getCanvasVariable(variable))
    .filter(Boolean)
    .filter(variable => !(this.isStartingLineup() && variable.lineup))
    .sort((lhs, rhs) => {
      if (lhs.sort !== rhs.sort) {
        return (rhs.sort - lhs.sort);
      }

      const lhs_name = lhs.getDisplayName();
      const rhs_name = rhs.getDisplayName();

      if (lhs_name.length !== rhs_name.length) {
        return (lhs_name.length - rhs_name.length);
      }

      if (lhs_name < rhs_name) {
        return -1;
      } else if (lhs_name > rhs_name) {
        return 1;
      } else {
        return 0;
      }
    })
    .map((variable, key) => this.renderStat(variable, `${player.name}-${key}`, periods, index));
  }

  renderImportTeam = (heading, index) => {
    const { classes } = this.props;
    const sport = this.getTemplateSport();

    if (!sport) {
      return null;
    }

    const id = `team.${index === 0 ? 'home' : 'visiting'}.name`;
    const variable = sport.getVariableById(id);

    if (!variable) {
      return null;
    }

    const teamName = variable.getValue();

    return (
      <Grid item xs={6} className={classes.teamCell}>
        <Typography variant="caption">{heading} Team</Typography>
        <Typography variant="subtitle1">{teamName}</Typography>
      </Grid>
    );
  }

  changePlayerMapping = (index, value, forceUpdate = true) => {
    let { mapping } = this.state;
    mapping[index] = value;

    if (forceUpdate) {
      this.forceUpdate();
    }
  }

  renderImportDialog = () => {
    const { classes } = this.props;
    const { importDialogOpen, mapping, swap } = this.state;
    const players = this.getCanvasPlayers();
    const sport = this.getTemplateSport();
    const teams = sport.teams;

    const getSelectValue = (index) => {
      let value = '';

      if (mapping[index]) {
        value = `${mapping[index].team}-${mapping[index].player}`;
      }

      return value;
    }

    const getRenderValue = (index) => {
      if (mapping[index]) {
        const player = (
          teams[mapping[index].team].players[mapping[index].player]
        );

        if (player) {
          return getPlayerText(player);
        }
      }

      return '';
    };

    const onSelectChange = (index, value) => {
      const values = value.split('-', 2);
      this.changePlayerMapping(index, { team: values[0], player: values[1] });
    };

    const disabled = mapping.some(i => i === null);

    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={importDialogOpen}
        onClose={this.closeImportDialog}
       >
        <DialogTitle>Import Stats</DialogTitle>
        <DialogContent>
          <Grid
            container
            className={classes.teamGrid}
            justify="space-between"
          >
            {this.renderImportTeam('Home', swap ? 1 : 0)}
            {this.renderImportTeam('Visiting', swap ? 0 : 1)}
          </Grid>
          {players.map((slot, index) => (
            <FormControl fullWidth key={slot.name} className={classes.operation}>
              <InputLabel>{slot.name}</InputLabel>
              <Select
                value={getSelectValue(index)}
                renderValue={() => getRenderValue(index)}
                onChange={e => onSelectChange(index, e.target.value)}
                MenuProps={MENU_PROPS}
              >
              {teams.map((team, t) => [
                <MenuItem disabled className={classes.menuSubheader} key={team.key}>{team.name}</MenuItem>,
                ...team.players.map((player, p) => (
                  <MenuItem
                    key={`${team.key}-${player.key}`}
                    value={`${t}-${p}`}
                  >
                    {getPlayerText(player)}
                  </MenuItem>
                ))
              ])}
              </Select>
            </FormControl>
          ))}
          <FormControlLabel
            className={classes.switchLabel}
            label="Swap Teams"
            control={
              <Switch
                color="primary"
                checked={swap}
                onChange={(e) => this.setState({ swap: e.target.checked })}
              />
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeImportDialog}>Cancel</Button>
          <Button onClick={this.importXmlFile} disabled={disabled}>Import</Button>
        </DialogActions>
      </Dialog>
    );
  }

}

// -------------------------------------------------------------------------- //

export default withStyles(STYLES, { withTheme: true })(Stats);

// -------------------------------------------------------------------------- //
