class CanvasResize {
  constructor(CanvasUtil) {
    this.CanvasUtil = CanvasUtil;
  }
  resizeByMethod(method, width, height) {
    const { CanvasUtil } = this;
    const { canvas } = CanvasUtil;
    const objects = canvas.getObjects();

    canvas.ignoreUndo = true;
    canvas.getObjects('widgetLayout').forEach((o) => {
      // o.remove();
    });

    canvas.ignoreUndo = false;
    objects.forEach((object) => {
        switch (object.type) {
          case 'text':
          case 'i-text':
            this.resizeText(object, method, width, height);
            break;
          case 'rect':
          case 'circle':
          case 'line':
          case 'triangle':
            this.resizeShape(object, method, width, height);
            break;
          case 'image':
            this.resizeImage(object, method, width, height);
            break;
          case 'path-group':
            this.resizeImage(object, method, width, height);
            break;
          case 'polygon':
          case 'polyline':
          case 'logoBox':
          case 'cutoutBox':
            this.resizePoly(object, method, width, height);
            break;
          case 'backgroundBox':
            this.resizeBackgroundBox(object, method, width, height);
            break;
          case 'widget':
            this.resizeWidget(object, method, width, height);
            break;
          case 'widgetLayout':
            this.resizeWidgetLayout(object, method, width, height);
            break;
          default:
            this.resizeImage(object, method, width, height);
            break;
        }
        object.setCoords();
    });
  }

  resizeWidgetLayout(object, method, width, height) {
    const { CanvasUtil } = this;
    const { canvas } = CanvasUtil;
    const currentWidth = canvas.workingArea.width;
    const currentHeight = canvas.workingArea.height;
    const newScaleX = width / currentWidth;
    const newScaleY = height / currentHeight;
    const newLeft = (object.left * newScaleX);
    const newTop = (object.top * newScaleY);

    object.set({
      left: newLeft,
      top: newTop,
      width: newScaleX * object.width,
      height: newScaleY * object.height,
      widgetWidth: newScaleX * object.widgetWidth,
      widgetHeight: newScaleY * object.widgetHeight,
    });

  }

  resizeWidget(object, method, width, height) {
    const { CanvasUtil } = this;
    const { canvas } = CanvasUtil;
    const currentWidth = canvas.workingArea.width;
    const currentHeight = canvas.workingArea.height;
    const newScaleX = width / currentWidth;
    const newScaleY = height / currentHeight;
    const newLeft = object.left * newScaleX;
    const newTop = object.top * newScaleY;

    object.set({
      left: newLeft,
      top: newTop,
      width: newScaleX * object.width,
      height: newScaleY * object.height,
    });
  }

  resizeBackgroundBox(object, method, width, height) {
    const { CanvasUtil } = this;
    const { canvas } = CanvasUtil;
    const currentWidth = canvas.workingArea.width;
    const currentHeight = canvas.workingArea.height;
    const newScaleX = (width / currentWidth) * object.scaleX;
    const newScaleY = (height / currentHeight) * object.scaleY;
    const newLeft = object.left * (width / currentWidth);
    const newTop = object.top * (height / currentHeight);

    object.set({
      lockMovementX: false,
      lockMovementY: false,
      lockRotation: false,
      lockScalingX: false,
      lockScalingY: false,
      hasControls: false,
    });

    object.set({
      top: newTop,
      left: newLeft,
      width: object.width,
      height: object.height,
      scaleX: newScaleX,
      scaleY: newScaleY,
    });
  }

  resizePoly(object, method, width, height) {
    const { CanvasUtil } = this;
    const { canvas } = CanvasUtil;
    const currentWidth = canvas.workingArea.width;
    const currentHeight = canvas.workingArea.height;

    /* attempted point transformation, bad idea. */
    /*
    for (let i = 0; i < object.points.length; i += 1) {
      const point = object.points[i];
      point.x = (point.x / currentWidth) * width;
      point.y = (point.y / currentHeight) * height;
    }
    */
    object.set({
      top: (object.top / currentHeight) * height,
      left: (object.left / currentWidth) * width,
      scaleX: (object.scaleX / currentWidth) * width,
      scaleY: (object.scaleY / currentHeight) * height,
    });
  }


  resizeText(object, method, width, height) {
    const { CanvasUtil } = this;
    const { canvas } = CanvasUtil;
    const currentWidth = canvas.workingArea.width;
    const currentHeight = canvas.workingArea.height;
    const scaleFactor = (object.get('scaleY') / currentHeight) * height;
    object.set({
      top: (object.top / currentHeight) * height,
      left: (object.left / currentWidth) * width,
      scaleX: scaleFactor,
      scaleY: scaleFactor,
    });
  }

  resizeShape(object, method, width, height) {
    const { CanvasUtil } = this;
    const { canvas } = CanvasUtil;
    const currentWidth = canvas.workingArea.width;
    const currentHeight = canvas.workingArea.height;
    const scaleFactorX = (object.get('scaleX') / currentWidth) * width;
    const scaleFactorY = (object.get('scaleY') / currentHeight) * height;
    object.set({
      top: (object.top / currentHeight) * height,
      left: (object.left / currentWidth) * width,
      scaleX: scaleFactorX,
      scaleY: scaleFactorY,
    });
  }

  resizeImage(object, method, width, height) {
    const { CanvasUtil } = this;
    const { canvas } = CanvasUtil;
    const currentWidth = canvas.workingArea.width;
    const currentHeight = canvas.workingArea.height;
    const newScaleX = width / currentWidth;
    const newScaleY = height / currentHeight;
    const newScale = Math.max(newScaleX, newScaleY);

    object.set({
      top: (object.top / currentHeight) * height,
      left: (object.left / currentWidth) * width,
      width: object.width,
      height: object.height,
      scaleX: newScale * object.scaleX,
      scaleY: newScale * object.scaleY,
    });
  }
}

export default CanvasResize;
