import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import atlas from '../../canvas/dict/atlas';
import common from '../../canvas/dict/common';
import data from '../../canvas/dict/data';
import TextureSelect from '../editor/sidebar/TextureSelect';
import WidgetSelect from '../editor/sidebar/WidgetSelect';
import DataBinder from '../editor/sidebar/DataBinder';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Menu } from '@material-ui/core';

// -------------------------------------------------------------------------- //

// the transform prop here is to fix a known chrome issue
// see mui-org/material-ui/commit/9b9421c for details
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MENU_PROPS = {
  PaperProps: {
    style: {
      maxHeight: (ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP),
      transform: 'translate3D(0,0,0)',
    },
  },
};

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  paperSelector: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    flexShrink: 1,
    flexGrow: 0,
    overflow: 'auto',
  },
  prop: {
    width: '48%',
    marginRight: '1%',
    float: 'left',
    minHeight: 48,
    marginTop: 4,
  },
  fontPicker: {
    height: 48,
  },
  button: {
    width: 30,
    height: 36,
  },
});

// -------------------------------------------------------------------------- //

class QuickLayerEdit extends Component {
  state = {
    textureSelectOpen: false,
    widgetSelectOpen: false,
    dataBindingWindowOpen: false,
    backgroundValue: ''
  }

  componentDidMount() {
  }

  setTexture = () => {
    this.setState({
      textureSelectOpen: true
    });
  }

  performSetTexture = (id, image) => {
    const { active } = this.props;

    active.textureSource = image || "";

    this.setState({
      textureSelectOpen: false
    });
  }

  setWidget = () => {
    this.setState({
      widgetSelectOpen: true
    });
  }

  performSetWidget = (id, widget) => {
    const { active } = this.props;
    active.name = widget.name;

    active.widget = widget.url;
    active.widgetWidth = widget.width;
    active.widgetHeight = widget.height;

    this.setState({
      widgetSelectOpen: false
    })
  }

  getControlsByType() {
    const { active, fonts, classes } = this.props;

    const controls = [];
    active.stateProperties.forEach((prop) => {
      if (atlas.props[prop]) {
        let newControl;
        switch (atlas.props[prop].type) {
          case 'bool':

          newControl = (
            <div style={{ marginTop: -1 }}>
            <FormControlLabel control={<Switch
              checked={active[prop]}

              onChange={this.changeBoolean(prop)}
              value={'Visible'}
            />}
            label="Visible" />
            </div>
          );
          break;
          case 'font': {
            newControl = (
              <div style={{ marginTop: -1 }}>
                <TextField
                  fullWidth
                  label="Font Family"
                  value={active[prop]}
                  onChange={(e) => this.activeChange(this, prop)(e.target.value)}
                  onFocus={(e) => e.target.select()}
                  onDoubleClick={(e) => this.setState({ font_menu_anchor: e.currentTarget })}
                />
                <Menu
                  {...MENU_PROPS}
                  anchorEl={this.state.font_menu_anchor}
                  open={Boolean(this.state.font_menu_anchor)}
                  onClose={() => this.setState({ font_menu_anchor: null })}
                >
                {fonts.map((font, index) => (
                  <MenuItem
                    key={index}
                    value={font.fontFamily}
                    selected={font.fontFamily === active[prop]}
                    onClick={() => {
                      this.activeChange(this, prop)(font.fontFamily);
                      this.setState({ font_menu_anchor: null });
                    }}
                  >
                    <span style={{ fontFamily: font.fontFamily }}>
                      {font.fontFamily}
                    </span>
                  </MenuItem>
                ))}
                </Menu>
              </div>
            );
            break;
          }
          case 'shadow': {
            newControl = (
              <div style={{ marginTop: 17 }}>
                <TextField
                  fullWidth
                  label="Shadow X"
                  value={active.shadow ? active.shadow.offsetX : 0}
                  onChange={this.changeShadow('offsetX')}
                  type="number"
                />
                <TextField
                  fullWidth
                  label="Shadow Y"
                  value={active.shadow ? active.shadow.offsetY : 0}
                  onChange={this.changeShadow('offsetY')}
                  type="number"
                />
                <TextField
                  fullWidth
                  label="Shadow Blur"
                  value={active.shadow ? active.shadow.blur : 0}
                  onChange={this.changeShadow('blur')}
                  type="number"
                />
                <TextField
                  fullWidth
                  label="Shadow Color"
                  value={active.shadow ? active.shadow.color : '#000000'}
                  onChange={this.changeShadow('color')}
                  type="text"
                />
              </div>
            );
            break;
          }
          case 'number': {
            newControl = (
              <TextField
                fullWidth
                label={atlas.props[prop].name}
                value={active[prop]}
                onChange={this.activeChange(this, prop)}
                type="number"
              />
            );
            break;
          }
          case 'text': {
            newControl = (
              <TextField
                fullWidth
                label={atlas.props[prop].name}
                value={active[prop] ? active[prop] : ''}
                onChange={this.activeChange(this, prop)}
                type="text"
              />
            );
            break;
          }
          case 'color': {

            let displayVal = this.setColor(prop);
            newControl = (

              <TextField
                fullWidth
                label={atlas.props[prop].name}
                value={displayVal}
                onChange={this.changeColor(prop)}
                type="text"
              />
            );
            break;
          }
          case 'select': {
            newControl = <FormControl>
              <InputLabel htmlFor={`${prop}Select`}>
                {atlas.props[prop].name}
              </InputLabel>
              <Select
                value={active[prop]}
                onChange={this.activeChange(this, prop)}
                inputProps={{
                  name: `${prop}Select`,
                  id: `${prop}Select`
                }}
              >
                {
                  atlas.props[prop].values.map((value) => {
                    return <MenuItem key={`${prop}-${value.val}`} value={value.val}>
                      {value.name}
                    </MenuItem>
                  })
                }
              </Select>
            </FormControl>;
            break;
          }
          case 'buttonGroup': {
            newControl = [];
            atlas.props[prop].with.forEach((propProps) => {
              const CustomButton = propProps.icon;
              let color = 'default';

              let clickHandler;

              if (propProps.type === 'custom') {
                const { active, canvasUtil } = this.props;

                let e = {
                  target: active,
                  canvas_util: canvasUtil,
                };

                clickHandler = () => {
                  propProps.onClick(e);
                  canvasUtil.canvas.requestRenderAll();
                  this.forceUpdate();
                };

                if (propProps.isActive !== null && propProps.isActive(e)) {
                  color = 'primary';
                }
              } else if (propProps.type === 'set') {
                clickHandler = this.activeChangeSet(this, propProps.prop, propProps.val);
                if (active[propProps.prop] === propProps.val) {
                  color = 'primary';
                }
              } else if (propProps.type === 'toggle') {
                // if val is not set, use true/false
                let val = [true, false];
                if (propProps.val) {
                  (({ val } = propProps));
                }
                if (active[propProps.prop] === propProps.val[1]) {
                  color = 'primary';
                }
                clickHandler = this.activeToggle(this, propProps.prop, val);
              }
              newControl.push((
                <Tooltip
                  key={propProps.val + propProps.prop}
                  title={propProps.name ? propProps.name : ''}
                >
                  <IconButton
                    className={classes.button}
                    color={color}
                    onClick={clickHandler}
                  >
                    <CustomButton />
                  </IconButton>
                </Tooltip>

              ));
            });
            newControl = (
              <div className={classes.buttonGroup}>
                <InputLabel shrink style={{ display: 'block' }}>
                  {atlas.props[prop].name}
                </InputLabel>
                {newControl}
              </div>
            );
            break;
          }
          case 'texture': {
            newControl = (
              <Button
                variant="raised"
                color="primary"
                onClick={this.setTexture}
              >
                Set Texture
              </Button>
            );
            break;
          }
          case 'widget': {
            newControl = (
              <Button
                variant="raised"
                color="primary"
                onClick={this.setWidget}
              >
                Set Widget
              </Button>
            );
            break;
          }
          default:
            break;
        }
        controls.push(<div className={classes.prop} key={prop}>
          {newControl}
        </div>);

        // also add an override for colors
        if (atlas.props[prop].type === 'color') {
          controls.push(<FormControl
            className={classes.prop}
            key={`${prop}Override`}
          >
            <InputLabel htmlFor={`${prop}Override`}>
              {`${atlas.props[prop].name} Override`}
            </InputLabel>
            <Select
              value={
                  active[`${prop}Override`] ?
                  active[`${prop}Override`] :
                  ''
              }
              onChange={this.propOverride(prop)}
              inputProps={{
                    name: `${prop}Override`,
                    id: `${prop}Override`,
                }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="primary">Primary</MenuItem>
              <MenuItem value="secondary">Secondary</MenuItem>
              <MenuItem value="tertiary">Tertiary</MenuItem>
            </Select>
          </FormControl>);
        }
      }
    });
    // add in the dynamic object selection.
    if (common[active.type]) {
      const options = [];
      Object.keys(common[active.type]).forEach((key) => {
        const item = common[active.type][key];
        options.push(<MenuItem key={key} value={key}>{item.name}</MenuItem>);
      });
      console.log(active.specialObject)
      console.log(common)
      controls.push(<FormControl
        className={classes.prop}
        key="special"
      >
        <InputLabel htmlFor="special">Object Type</InputLabel>
        <Select
          value={active.specialObject ? active.specialObject : ''}
          onChange={this.activeChange(this, 'specialObject')}
          inputProps={{
              name: 'special',
              id: 'special',
            }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {options}
        </Select>
                    </FormControl>);
    }

    // const handleBackgroundChange = (active,name) => {
    //   this.setState({backgroundValue:name})
    //   active.set('name',name)
    //   console.log(active)
    // }

    // if (active.type === 'backgroundBox') {
    //   let options = []
    //   let backgroundTypes = [{name:'Background 1'},{name:'Background 2'},{name:'Background 3'}]
    //   backgroundTypes.forEach(type => {
    //     options.push(<MenuItem key={type.name} value={type.name}>{type.name}</MenuItem>)
    //   })
    //   controls.push((
    //       <FormControl
    //           className={classes.prop}
    //           key="special"
    //       >
    //         <InputLabel htmlFor="special">Object Type</InputLabel>
    //         <Select
    //             value={this.state.backgroundValue}
    //             onChange={(e) => handleBackgroundChange(active,e.target.value)}
    //             inputProps={{
    //               name: 'special',
    //               id: 'special',
    //             }}
    //         >
    //           <MenuItem value="">
    //             <em>None</em>
    //           </MenuItem>
    //           {options}
    //         </Select>
    //       </FormControl>
    //   ))
    // }
    if(active.type === 'curvedText'){
      controls.push((
        <FormControl
        className={classes.prop}
        key="curvedText"
      >
        <TextField
        fullWidth
        value={this.state.text}
        onChange={(e) => this.setCurvedText(this.props)(e.target.value)}
        label='curved text'
        type="text"
        />
        <Typography>
          Curved text diameter
        </Typography>
        <Slider
          defaultValue={5}
          onChange={(event,newvalue) => this.setCurvedDiameter(this.props)(event,newvalue)}
          min={5}
          max={720}
          step={5}
        />
      </FormControl>
      ))

      return controls
    }

    if (active.type === 'i-text') {
      let count_up_start = '';
      let count_up_time = '';

      if (active.animation && active.animation.text && active.animation.text.type === 'CountUp') {
        if (active.animation.text.startTime !== undefined) {
          count_up_start = String(active.animation.text.startTime);
        }

        if (active.animation.text.duration !== undefined) {
          count_up_time = String(active.animation.text.duration);
        }
      }


      controls.push((
        <FormControl
          className={classes.prop}
          key="count_up_start"
        >
          <TextField
            fullWidth
            label="Count-up Start"
            value={count_up_start}
            onChange={(e) => {
              if (!active.animation) {
                active.animation = { };
              }

              if (e.target.value === '' && count_up_time === '') {
                active.animation.text = null;
              } else {
                active.animation.text = {
                  type: 'CountUp',
                  startTime: e.target.value !== '' ? Number(e.target.value) : undefined,
                  duration: Number(count_up_time),
                };
              }

              this.forceUpdate();
            }}
          />
        </FormControl>
      ));

      controls.push((
        <FormControl
          className={classes.prop}
          key="count_up_time"
        >
          <TextField
            fullWidth
            label="Count-up Duration"
            value={count_up_time}
            onChange={(e) => {
              if (!active.animation) {
                active.animation = { };
              }

              if (e.target.value === '' && count_up_start === '') {
                active.animation.text = null;
              } else {
                active.animation.text = {
                  type: 'CountUp',
                  startTime: Number(count_up_start),
                  duration: e.target.value !== '' ? Number(e.target.value) : undefined,
                };
              }

              this.forceUpdate();
            }}
          />
        </FormControl>
      ));
    }

    if (data[active.type]) {
      controls.push(
        <FormControl
          className={classes.prop}
          key="variables"
        >
          <Button
            onClick={() => this.setState({ dataBindingWindowOpen: true })}
          >
            Edit Stats
          </Button>
        </FormControl>
      );
    }
    return controls;
  }

  changeShadow = prop => (e) => {
    const { active } = this.props;
    const defaults = {
      color: '#000000', blur: 4, offsetX: 2, offsetY: 2,
    };
    const shadow = { ...defaults, ...active.shadow };
    if (Number.isNaN(parseInt(e.target.value, 10))) {
      shadow[prop] = e.target.value;
    } else {
      shadow[prop] = parseInt(e.target.value, 10);
    }
    active.setShadow(shadow);
    active.canvas.requestRenderAll();
    this.forceUpdate();
  }
  changeBoolean = (prop) =>() => {
     const { active, canvasUtil } = this.props;
     const newProp = !active[prop];
     active.set(prop, newProp);
     canvasUtil.canvas.requestRenderAll();
     this.forceUpdate();
  }

  _startsWith = (_needle,_hayStack) => {
        let needle = _needle.length > _hayStack.length ? _hayStack : _needle;
        let hayStack = _hayStack.length > _needle.length   ? _hayStack : _needle;

        for (let i = 0; i < needle.length; i++){
            let _needleChar = needle.charAt(i);
            let _hayStackChar = hayStack.charAt(i);
            if(_needleChar !== _hayStackChar){
              return false
            }
        }

        return true;
  }

  setBackgroundObjectName = prop => (value) => {

    const {canvasUtil} =this.props

    const actives = canvasUtil.canvas.getActiveObjects()

    actives.forEach(obj => {
      if (obj.type === 'backgroundBox') {
        obj.set('name',value)
        obj.dirty = true;
        obj.setCoords();
        canvasUtil.dirtyCanvas();
      }
    })
    canvasUtil.canvas.requestRenderAll();
  }

  setCurvedText = prop => (value) => {

    const {canvasUtil} =this.props

    const actives = canvasUtil.canvas.getActiveObjects()

    actives.forEach((obj) => {
      obj.set('text', value.lenght === 0? this.state.text : value);
      obj.dirty = true;
      obj.setCoords();
      if (obj.type === 'curvedText') obj.getCircularText();
      canvasUtil.dirtyCanvas();
    });
    canvasUtil.canvas.requestRenderAll();

    this.setState({text: value})


  }
  /**
   *
   * sets the diameter of the curved text
   */
  setCurvedDiameter = prop => (event,newvalue) => {
    const {canvasUtil} =this.props

    const activeObject = canvasUtil.canvas.getActiveObjects()

    activeObject.forEach( obj => {

      if (newvalue < 5 || newvalue === undefined) newvalue = 5;
      obj.set('diameter',newvalue)
      obj.getCircularText()
    })

    canvasUtil.canvas.requestRenderAll()

  }

  setColor = ( prop ) => {
      const { active } = this.props;
      let newProp = "#000000";
      if(active[prop]){
        if(this._startsWith('rgb(',active[prop]) || this._startsWith('rgba(',active[prop])){
           newProp = active[prop];
        }else{
          let hashIndex = active[prop].indexOf("#");
          hashIndex = active[prop].slice(hashIndex);
          let regex = /[^0-9a-f]/gi;
    	     newProp = '#'+ hashIndex.replace(regex,'');
       }
      }
      return newProp;
  }
  changeColor = ( prop ) => e => {
      const { active, canvasUtil } = this.props;
      let newProp = e.target.value;
      active.set(prop, newProp);
      canvasUtil.canvas.requestRenderAll();
      this.forceUpdate();
  }
  activeChange = (self, prop) => (e) => {
    const { active, canvasUtil } = this.props;
    let newProp;
    if (prop === 'fontFamily') {
      newProp = e;
    } else {
      newProp = e.target.value;
    }
    console.log(active,prop)
    // For stroke width, if greater than zero, set default stroke color
    if (prop === 'strokeWidth') {
      if (Number(e.target.value) > 0 && active.get('stroke') === null) {
        active.set('stroke', '#000');
      }
    }

    if (!Number.isNaN(Number(newProp))) {
      newProp = parseFloat(newProp);
    }

    active.set(prop, newProp);
    console.log(prop,newProp,e)

    const SETCOORDS_PROPS = [
      'left', 'top', 'width', 'height',
      'scaleX', 'scaleY', 'strokeWidth',
      'angle',
    ];

    if (SETCOORDS_PROPS.find(i => i === prop)) {
      active.setCoords();
    }

    canvasUtil.canvas.requestRenderAll();
    self.forceUpdate();
  }

  propOverride = prop => (e) => {
    const { active } = this.props;
    const colors = {
      primary: '#6b2587',
      secondary: '#4fd4c4',
      tertiary: '#e8e7e5',
    };
    const value = colors[e.target.value] ? colors[e.target.value] : active[prop];
    active.set(prop, value);
    this.activeChange(this, `${prop}Override`)(e);
  }

  activeChangeSet = (self, prop, val) => () => {
    const { active, canvasUtil } = this.props;
    active.set(prop, val);
    canvasUtil.canvas.requestRenderAll();
    self.forceUpdate();
  }

  activeToggle = (self, prop, val) => () => {
    const { active, canvasUtil } = this.props;
    if (active[prop] === val[0]) {
      active.set(prop, val[1]);
    } else {
      active.set(prop, val[0]);
    }
    canvasUtil.canvas.requestRenderAll();
    self.forceUpdate();
  }


  render() {
    const { classes, active, template } = this.props;

    return (
      <Paper className={classes.paperSelector} elevation={4}>
        <Typography variant="h5" component="h3">
          {active.name}
        </Typography>
        <Typography variant="caption">
          ({active.type})
        </Typography>
        <React.Fragment>
          {this.getControlsByType()}
        </React.Fragment>

        <Dialog open={this.state.textureSelectOpen}>
          <DialogTitle id="form-select-texture-title">Add Texture</DialogTitle>
          <DialogContent>
            <TextureSelect onSelect={this.performSetTexture} />
            <Button
              variant="raised"
              color="primary"
              onClick={() => this.performSetTexture(null, null)}
            >
              Remove Texture
            </Button>
          </DialogContent>
          <DialogActions>
            <Button
              variant="raised"
              color="primary"
              onClick={() => this.setState({ textureSelectOpen: false })}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.widgetSelectOpen}>
          <DialogTitle id="form-select-widget-title">Add Widget</DialogTitle>
          <DialogContent>
            <WidgetSelect onSelect={this.performSetWidget} />
          </DialogContent>
          <DialogActions>
            <Button
              variant="raised"
              color="primary"
              onClick={() => this.setState({ widgetSelectOpen: false })}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {
          template.categories.length > 0 &&
          <DataBinder
            open={this.state.dataBindingWindowOpen}
            onClose={() => this.setState({ dataBindingWindowOpen: false })}
            active={active}
            template={template}
          />
        }
      </Paper>
    );
  }
}

// -------------------------------------------------------------------------- //

QuickLayerEdit.propTypes = {
  active: PropTypes.shape({
    type: PropTypes.string,
  }).isRequired,
  canvasUtil: PropTypes.shape({
    viewPadding: PropTypes.number,
  }).isRequired,
};

// -------------------------------------------------------------------------- //

export default withStyles(STYLES)(QuickLayerEdit);

// -------------------------------------------------------------------------- //
