/* eslint no-underscore-dangle: "off" */
/* eslint no-lone-blocks: "off" */
class PolygonTexture {
  constructor(fabric) {
    this.class = fabric.util.createClass(fabric.Polygon, {
      type: 'polygonTexture',
      stepMatrix: {
        default: {
          selectable: true,
          lockMovementX: false,
          lockMovementY: false,
          lockScalingX: false,
          lockScalingY: false,
          lockRotation: false,
          hasControls: false,
          fill: 'transparent',
        },
        admin: {
          selectable: true,
          lockMovementX: false,
          lockMovementY: false,
          lockScalingX: false,
          lockScalingY: false,
          lockRotation: false,
          hasControls: true,
          fill: '#555',
        },
      },
      stateProperties: [
        ...fabric.Polygon.prototype.stateProperties,
        'circle',
        'rect'
      ],

      initialize(points, options = {}) {
        this.callSuper(
          'initialize',
          points,
          {
            ...this.stepMatrix.default,
            ...options,
            type: 'polygonTexture',
          },
        );

        this.width = options.width;
        this.height = options.height;

        if (options.media) {
          this.setMedia(options.media);
        }
      },

      setMedia(media) {
        this.media = {
          ...media,
          texture: media.texture,
          blendMode: media.blendMode || 'multiply',
          scaleX: media.scaleX === undefined ? 1 : media.scaleX,
          scaleY: media.scaleY === undefined ? 1 : media.scaleY
        };

        if (this.media.texture) {
          let texture = new Image();
          texture.onload = () => {
            this.media.textureElement = texture;
            this.dirty = true;
            this.canvas.requestRenderAll();
          }
          texture.crossOrigin = 'Anonymous';
          texture.src = media.texture + '?1';
        }
      },

      _renderPath(ctx) {
        if (this.circle) {
          ctx.beginPath();
          ctx.arc(
            0,
            0,
            this.circle,
            0,
            Math.PI * 2);
        } else if (this.rect) {
          const w = this.width;
          const h = this.height;
          const x = -(w / 2);
          const y = -(h / 2);
          ctx.beginPath();
          ctx.moveTo(x, y);
          ctx.lineTo(x + w, y);
          ctx.lineTo(x + w, y + h);
          ctx.lineTo(x, y + h);
        } else if (!this.commonRender(ctx)) {
          return false;
        }
        return true;
      },

      _renderTexture(ctx) {
        let maxScale = Math.max(
          this.width / this.media.textureElement.width,
          this.height / this.media.textureElement.height);
        if (this.circle) {
          let widthSquared = this.width * this.width;
          let heightSquared = this.height * this.height;
          let diagonal = Math.max(widthSquared + heightSquared);
          maxScale = Math.max(this.circle / diagonal, maxScale);
        }
        let w = this.media.textureElement.width * this.media.scaleX * maxScale;
        let h = this.media.textureElement.height * this.media.scaleY * maxScale;
        ctx.drawImage(
          this.media.textureElement,
          0, 0,
          this.media.textureElement.width,
          this.media.textureElement.height,
          -w / 2, -h / 2,
          w, h);
      },

      _render(ctx) {
        ctx.save();
        {
          ctx.globalCompositeOperation = 'source-over';

          if (this.media && this.media.blendMode === 'multiply') {
            ctx.fillStyle = 'black';
            this._renderPath(ctx);
          }
          ctx.fill();

          this._renderPath(ctx);
          ctx.closePath();

          ctx.clip();

          if (this.media.blendMode !== 'multiply') {
            ctx.globalCompositeOperation = 'copy';
          }
          if (this.media && this.media.textureElement) {
            this._renderTexture(ctx);
          }
        }
        ctx.restore();

        ctx.globalCompositeOperation = this.media ? this.media.blendMode : 'source-over';

        this._renderPath(ctx);
        ctx.closePath();
        this._renderPaintInOrder(ctx);
      },

      toObject(propertiesToInclude) {
        let newPropertiesToInclude = [
          ...propertiesToInclude,
          'circle',
          'rect'];
        if (this.media) {
          return fabric.util.object.extend(
            this.callSuper('toObject', newPropertiesToInclude),
            {
              media: {
                texture: this.media.texture,
                blendMode: this.media.blendMode,
                scaleX: this.media.scaleX,
                scaleY: this.media.scaleY
              }
            }
          );
        }
      }
    });

    this.fromObject = (object, callback) => fabric.Object._fromObject('PolygonTexture', object, callback, 'points');
  }
}

export default PolygonTexture;
