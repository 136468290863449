
export const fileTypes = {
  mp4: {
    type: 'video',
  },
  jpg: {
    type: 'image',
  },
  jpeg: {
    type: 'image',
  },
  png: {
    type: 'image',
  },
};

export default {
  PHOTO: {
    prettyName: 'Photos',
    urlName: 'photo',
    allowedTypes: [
      'image/jpeg',
      'image/png',
    ],
  },
  LOGO: {
    prettyName: 'Logos',
    urlName: 'logo',
    allowedTypes: [
      'image/jpeg',
      'image/png',
    ],
  },
  VIDEO: {
    prettyName: 'Videos',
    urlName: 'video',
    allowedTypes: [
      'video/mp4',
    ],
  },
  CUTOUT: {
    prettyName: 'Cutouts',
    urlName: 'cutout',
    allowedTypes: [
      'image/jpeg',
      'image/png',
    ],
  },
  ROSTER: {
    prettyName: 'Rosters',
    urlName: 'roster',
    allowedTypes: [],
  },
  STANDINGS: {
    prettyName: 'Standings',
    urlName: 'standings',
    allowedTypes: [],
  },
  STOCK: {
    prettyName: 'Stock Library',
    urlName: 'stock',
    allowedTypes: [
      'image/jpeg',
      'image/png',
    ],
  },
  OTHER: {
    prettyName: 'Other',
    urlName: 'other',
    allowedTypes: [],
  },
};
