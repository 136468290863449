import React, { Component } from 'react';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { withStyles } from '@material-ui/core/styles';
import Draggable from 'react-draggable';
import arrayMove from 'array-move';
import { Import, TransitionMasked } from 'mdi-material-ui';
import Tooltip from '@material-ui/core/Tooltip';
import { TransitionsContainer } from '../animations_views/index';

const styles = (theme) => ({
  holder: {
    padding: '1%',
    width: '15%',
    marginTop: '4%',
    marginLeft: '2%',
    position: 'absolute',
    zIndex: 500,
    backgroundColor: '#fff',
  },
  holder2: {
    padding: '1%',
    width: '100%',
    marginTop: '4%',
    marginLeft: '2%',
    zIndex: 501,
  },

});
const containerContents = (props) => {
  if (!props.items) { return null; }
  const _templateIndex = props.items.length - 1;


  return props.items.map((value, index) => (
    <SortableItem key={`item-${index}`} index={index}>
      <DragHandle imgId={value} />
      <br />
      <Tooltip title="Click to swap template">
        <span onClick={() => { props.refetch(value)(); props.setIndex(-100); }}><Import /></span>
      </Tooltip>

      { index < _templateIndex ? (
        <Tooltip title="Click to add a Transition.">
          <span onClick={props.changeselectedIndex(index)}><TransitionMasked /></span>
        </Tooltip>
      ) : null}

      <TransitionsContainer show={index === props.selectedIndex} setTransitionFunct={props.setTransitionsFunct(index)} />

    </SortableItem>
  ));
};
const DragHandle = sortableHandle((props) => <img style={{ cursor: 'grab' }} src={`https://s3.us-east-2.amazonaws.com/ss3-templates/${props.imgId}.jpg`} alt="Smiley face" height="42" width="42" />);
const SortableItem = sortableElement((props) => <li style={{ zIndex: 1000, listStyleType: 'none' }}>{props.children}</li>);

const SortableContainer = sortableContainer((props) => <ul className={props.classes.holder2}>{props.children}</ul>);

const SortList = (props) => (
  <SortableContainer {...props} useDragHandle onSortEnd={props.onSortEnd}>
    {containerContents(props)}
  </SortableContainer>
);

// class starts
class TemplateSwapper extends Component {
  state = {
    showList: true,
    selectedIndex: -100,
  };

  onSortEnd = (items) => ({ oldIndex, newIndex }) => {
    const data = arrayMove(items, oldIndex, newIndex);
    this.props.setSlidesFunct(data);
    this.props.saveSelectedSlidesFunct(data);
    this.props.swapTransitionIndexFunct(oldIndex, newIndex);
    this.setState({ selectedIndex: -100 });
  }

  changeselectedIndex = (_selectedIndex) => () => {
    const selectedIndex = this.state.selectedIndex === _selectedIndex ? -100 : _selectedIndex;
    this.setState({ selectedIndex });
  };

  openCloseList = () => this.setState({ showList: !this.state.showList, selectedIndex: -100 })

  setIndex = (selectedIndex) => {
    this.setState({ selectedIndex });
  }

  render() {
    const { showList, selectedIndex } = this.state;
    const { classes, list, setTransitionsFunct } = this.props;
    const msg = showList ? 'Template Swap: Close List' : 'Template Swap: Open List';
    const _list = showList ? list : [];
    const props = {
      items: _list,
      onSortEnd: this.onSortEnd(_list),
      refetch: this.props.swapTemplateFunct,
      classes,
      selectedIndex,
      changeselectedIndex: this.changeselectedIndex,
      setTransitionsFunct,
      setIndex: this.setIndex,
    };

    return (
      <Draggable handle="strong">
        <div className={classes.holder}>
          <div className={classes.holder2}>
            <strong style={{ cursor: 'grab', paddingLeft: '1%' }}>::::</strong>
            <h4 style={{ cursor: 'pointer', paddingLeft: '1%' }} onClick={this.openCloseList}>{msg}</h4>
          </div>
          <SortList {...props} />
        </div>
      </Draggable>

    );
  }
}
export default withStyles(styles)(TemplateSwapper);
