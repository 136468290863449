import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
  },
  heading: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  filterPreview: {
    flexBasis: '33%',
    textAlign: 'center',
  },
  filterHolder: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    width: '100%',
    marginTop: 15,
    marginBottom: 15,
  },
});

class VideoEditor extends Component {
  state = {
    mute : true
  }
  scale = (e) => {
    const { active } = this.props;
    const minScale = active.minScale ? active.minScale : 1;
    const scale = ((e / 50) + 1) * minScale;
    active.set({
      scaleX: scale,
      scaleY: scale,
    });
    active.canvas.requestRenderAll();
    this.forceUpdate();
  }

  handleChange = (val) => {
    this.props.active.set({
      startTime: val[0],
      currentDuration: val[1] - val[0],
    });
    this.forceUpdate();
  }

  renderTip = (val) => {
    const minutes = Math.floor(val / 60);
    let seconds = val - (60 * minutes);
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    return (
      <Typography variant="caption" color="inherit" style={{ color: '#FFF' }}>
        {`${minutes}:${seconds}`}
      </Typography>
    );
  }

  muteUnmuteVideo = () => {
    const { active } = this.props;

    if (active) {
      active.muted = !active.muted;
      active.videoEl.muted = active.muted;
      this.forceUpdate();
    }
  }

  render() {
    const { classes, active } = this.props;
    const minScale = active.minScale ? active.minScale : 1;

    const rangeValue = [
      active.startTime,
      active.startTime + active.currentDuration,
    ];

    return (
      <div className={classes.root}>
        <Typography variant="h6" className={classes.heading}>
          Scale
        </Typography>
        <Slider
          value={((active.scaleX / minScale) - 1) * 50}
          onChange={this.scale}
          trackStyle={[{
            backgroundColor: this.props.theme.palette.primary.light,
          }]}
          handleStyle={[{
            borderColor: this.props.theme.palette.primary.light,
          }]}
        />
        <Typography variant="h6" className={classes.heading}>
          Video Length
        </Typography>
        <Range
          value={rangeValue}
          onChange={this.handleChange}
          allowCross={false}
          min={0}
          pushable={1}
          max={+active.maxDuration.toFixed(1)}
          tipFormatter={this.renderTip}
          step={0.1}
          trackStyle={[{
            backgroundColor: this.props.theme.palette.primary.light,
          }]}
          handleStyle={[{
            borderColor: this.props.theme.palette.primary.light,
          }]}
          {...this.props}
        />


        <Typography variant="h6" className={classes.heading}>
          Mute Video
          <Checkbox color="primary" checked={active.muted} onChange={this.muteUnmuteVideo}/>
        </Typography>

      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(VideoEditor);