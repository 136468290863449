import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

const styles = ({
  appBar: {
    position: 'relative',

  },
  flex: {
    flex: 1,
  },
});

const TopBar = (props) => {
  const { classes } = props;
  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <Typography variant="h6" color="inherit" noWrap className={classes.flex}>
          {props.title}
        </Typography>
        {props.rightComponent}
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  title: PropTypes.string,
  rightComponent: PropTypes.element,
};

TopBar.defaultProps = {
  title: '',
  rightComponent: <div />,
};

export default withStyles(styles)(TopBar);
