import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Editor from './Editor';

const GET_POST_INFO = gql`
  query getPost($id: ID!){
    getPost (
      id: $id
    ) {
      id
      width
      height
      url
      template {
        id
        isQuickCreate
        disableXMLImport
        categories {
            id
            name
        }
        types {
            id
            name
        }
      }
    }
  }
`;

export default (props) => (
  <Query
    query={GET_POST_INFO}
    key="getInfo"
    variables={{
      id: props.match.params.id,
    }}
  >
    {({ data }) => (
      data && data.getPost
        ? (
          <Editor
            template={data.getPost}
            match={props.match}
            quickCreate={data.getPost.template ? data.getPost.template.isQuickCreate : false}
          />
        )
        : <div />
    )}
  </Query>
);
