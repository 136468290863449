
import React from 'react';

import {
  AppBar,
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  FormControlLabel,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  isWidthUp,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Radio,
  RadioGroup,
  Slide,
  Snackbar,
  SvgIcon,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
  withWidth,
} from '@material-ui/core';

import {
  Alert,
} from '@material-ui/lab';

import {
  AlertOutline as AlertOutlineIcon,
  CalendarRange as CalendarRangeIcon,
  ChevronDown as ChevronDownIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Clock as ClockIcon,
  Close as CloseIcon,
  HelpCircleOutline as HelpCircleOutlineIcon,
  Share as ShareIcon,
  Twitter as TwitterIcon,
  TwitterRetweet as TwitterRetweetIcon,
} from 'mdi-material-ui';

import { graphql, compose } from 'react-apollo';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import { TransitionGroup, SwitchTransition } from 'react-transition-group';
import classnames from 'classnames';
import DateFnsUtils from '@date-io/date-fns';
import gql from 'graphql-tag';
import parseColor from 'parse-color';
import SwipeableViews from 'react-swipeable-views';
import twitter from 'twitter-text';

// -------------------------------------------------------------------------- //

const FacebookIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z"/>
  </SvgIcon>
);

// -------------------------------------------------------------------------- //

function FadeRGB(input, a) {
  const parsed = parseColor(input);
  return `rgba(${parsed.rgb[0]}, ${parsed.rgb[1]}, ${parsed.rgb[2]}, ${a})`;
}

// -------------------------------------------------------------------------- //

function SlideTransition(props) {
  return <Slide direction="up" {...props}/>;
}

// -------------------------------------------------------------------------- //

const GQL_GET_FACEBOOK_PAGES = gql`
  query {
    me {
      id
      client {
        id
        fbPages {
          id
          name
          accessToken
          fbId
        }
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const GQL_GET_TWITTER_ACCOUNTS = gql`
  query {
    me {
      id
      client {
        id
        twitterAccounts {
          id
          name
          screen_name
          twitterId
          oauthToken
          oauthTokenSecret
        }
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  app_bar: {
    position: 'relative',
  },
  app_bar_light: {
    backgroundColor: theme.palette.primary.light,
    position: 'relative',
  },
  dialog_title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  dialog: {
    maxWidth: 800,
  },
  error_icon: {
    marginRight: theme.spacing(1),
  },
  error_message: {
    flex: 1,
  },
  expand_button: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  expand_fade_out: {
    position: 'absolute',
    pointerEvents: 'none',
    left: 0,
    right: 0,
    bottom: 0,
    height: theme.spacing(4),
    background: `linear-gradient(to top, ${FadeRGB(
      theme.palette.background.paper, 1.0
    )}, ${FadeRGB(
      theme.palette.background.paper, 0.0
    )})`,
    opacity: 1,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expand_fade_in: {
    opacity: 0,
  },
  expand_icon_out: {
    transform: 'rotate(0)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expand_icon_in: {
    transform: 'rotate(180deg)',
  },
  page_header: {
    // marginLeft: theme.spacing(4),
  },
  list_loader: {
    textAlign: 'center',
    display: 'inherit',
  },
  step_circle: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
    textAlign: 'center',
    width: theme.spacing(3),
  },
  step_content: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.only('xs')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      height: 'calc(vh-64px*3)',
    },
  },
  message_avatar: {
    marginRight: theme.spacing(1),
  },
  message_textfield: {
    flex: 1,
  },
});

// -------------------------------------------------------------------------- //

class ShareDialog_ extends React.Component {

  state = {
    tab_index: 0,
    facebook_loading: false,
    facebook_pages: [], // for the query
    facebook_list: [], // for the selection
    twitter_loading: false,
    twitter_accounts: [],
    twitter_list: [],
    twitter_primary: null,
    post_mode: 'now',
    post_eta: Date.now(),
    message_mode: 'none',
    post_message: '',
    message_list: [],
    error: null,
    list_expanded: false,
    facebook_expanded: false,
    twitter_expanded: false,
  }

  componentDidUpdate(old_props) {
    if (!old_props.open && this.props.open) {
      this.setState({
        tab_index: 0,
        facebook_loading: true,
        facebook_pages: [],
        facebook_list: [],
        twitter_loading: true,
        twitter_accounts: [],
        twitter_list: [],
        twitter_primary: null,
        post_mode: 'now',
        post_eta: Date.now(),
        message_mode: 'none',
        post_message: '',
        message_list: [],
        error: null,
        list_expanded: false,
        facebook_expanded: false,
        twitter_expanded: false,
      }, () => {
        const {
          gql_get_facebook_pages,
          gql_get_twitter_accounts,
        } = this.props;

        gql_get_facebook_pages.refetch().then((response) => {
          if (response.error) {
            this.setState({ facebook_loading: false });
            return;
          }

          let pages = [];

          if (
            response.data &&
            response.data.me &&
            response.data.me.client &&
            response.data.me.client.fbPages
          ) {
            pages = response.data.me.client.fbPages;
          }

          this.setState({
            facebook_loading: response.loading,
            facebook_pages: pages,
          });
        });

        gql_get_twitter_accounts.refetch().then((response) => {
          if (response.error) {
            this.setState({ twitter_loading: false });
            return;
          }

          let accounts = [];

          if (
            response.data &&
            response.data.me &&
            response.data.me.client &&
            response.data.me.client.twitterAccounts
          ) {
            accounts = response.data.me.client.twitterAccounts;
          }

          this.setState({
            twitter_loading: response.loading,
            twitter_accounts: accounts,
          });
        });
      });
    }
  }

  render() {
    const { classes, width, open } = this.props;

    return (
      <Dialog
        open={open}
        fullWidth
        fullScreen={width === 'xs'}
        classes={{ paper: classes.dialog }}
        onClose={() => this.close(false)}
        TransitionComponent={width === 'xs' ? SlideTransition : Fade}
      >
        {
          (width === 'xs')
          ? this.renderMobile()
          : this.renderDesktop()
        }
        <DialogActions>
        {
          (width === 'xs')
          ? this.renderMobileActions()
          : this.renderDesktopActions()
        }
        </DialogActions>
      </Dialog>
    );
  }

  renderDesktop = () => {
    return (
      <>
        <DialogTitle disableTypography>
          <Typography variant="h4">
            Share Post
          </Typography>
        </DialogTitle>
        <DialogContent>
          {this.renderWhereToPost()}
          {this.renderWhenToPost()}
          {this.renderYourMessage()}
        </DialogContent>
      </>
    );
  }

  renderDesktopActions = () => {
    const { classes } = this.props;
    const { error } = this.state;

    return (
      <>
        <Grow in={error !== null}>
          <AlertOutlineIcon
            color="error"
            className={classes.error_icon}
          />
        </Grow>
        <Fade in={error !== null}>
          <Typography color="error" className={classes.error_message}>
            {error}
          </Typography>
        </Fade>
        <Button
          color="primary"
          variant="contained"
          onClick={() => this.close(true)}
        >
          Share
        </Button>
      </>
    );
  }

  renderMobile = () => {
    const { classes } = this.props;

    return (
      <>
        <AppBar className={classes.app_bar}>
          <Toolbar>
            <Typography variant="h6" className={classes.dialog_title}>
              Share Post
            </Typography>
            <IconButton
              edge="end" color="inherit"
              onClick={() => this.close(false)}
            >
              <CloseIcon/>
            </IconButton>
          </Toolbar>
        </AppBar>
        <AppBar
          className={classes.app_bar_light}
        >
          <Tabs
            value={this.state.tab_index}
            onChange={(_, index) => this.setState({ tab_index: index })}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
          >
            <Tab label="Where to Share"/>
            <Tab label="When to Share"/>
            <Tab label="Your Message"/>
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis="x" index={this.state.tab_index} style={{ height: '100%' }}
          onChangeIndex={(index) => this.setState({ tab_index: index })}
        >
          {[
            this.renderWhereToPost(),
            this.renderWhenToPost(),
            this.renderYourMessage(),
          ].map((panel, index) => (
            <div
              key={index} role="tabpanel"
              value={index} index={index} dir="ltr"
            >
              {panel}
            </div>
          ))}
        </SwipeableViews>
      </>
    );
  }

  renderMobileActions = () => {
    const { snackbar_open, snackbar_text, tab_index } = this.state;

    return (
      <>
        <Button
          disabled={tab_index === 0}
          color="primary" variant="outlined"
          onClick={() => this.setState({ tab_index: (tab_index - 1) })}
          startIcon={<ChevronLeftIcon color="inherit"/>}
        >
          Back
        </Button>
        <div style={{ flex: 1 }}/>
        <SwitchTransition mode="out-in">
          <SlideTransition key={tab_index === 2 ? 'share' : 'next'}>
            <Button
              color="primary"
              variant={tab_index === 2 ? 'contained' : 'outlined'}
              endIcon={
                (tab_index === 2)
                ? <ShareIcon color="inherit"/>
                : <ChevronRightIcon color="inherit"/>
              }
              onClick={() => {
                if (tab_index === 2) {
                  this.close(true);
                } else {
                  this.setState({ tab_index: (tab_index + 1) });
                }
              }}
            >
              {tab_index === 2 ? 'Share' : 'Next'}
            </Button>
          </SlideTransition>
        </SwitchTransition>
        <Snackbar
          open={snackbar_open} autoHideDuration={6000}
          onClose={() => this.setState({ snackbar_open: false })}
          TransitionComponent={SlideTransition}
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'bottom',
          }}
        >
          <Alert
            severity="error"
            elevation={6} variant="filled"
            onClose={() => this.setState({ snackbar_open: false })}
          >
            {snackbar_text}
          </Alert>
        </Snackbar>
      </>
    );
  }

  renderWhereToPost = () => {
    const { classes, width } = this.props;
    const { facebook_pages, facebook_loading } = this.state;
    const { twitter_accounts, twitter_loading } = this.state;

    const account_grid = (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {this.renderFacebookList(facebook_pages, facebook_loading)}
        </Grid>
        <Grid item xs={12} sm={6}>
          {this.renderTwitterList(twitter_accounts, twitter_loading)}
        </Grid>
      </Grid>
    );

    const accounts = Math.max(
      facebook_pages.length,
      twitter_accounts.length
    );

    return (
      <>
        <Toolbar
          variant={width === 'xs' ? 'regular' : 'dense'}
          disableGutters={isWidthUp('sm', width)}
        >
          <div className={classes.step_circle}>
            <Typography>1</Typography>
          </div>
          <Typography variant="h5">
            Where to Share
          </Typography>
        </Toolbar>
        <div className={classes.step_content}>
        {this.renderAccordion(
          account_grid, (accounts > 4), 'list_expanded', 32
        )}
        </div>
      </>
    );
  }

  renderAccordion = (children, condition, expanded_key, height) => {
    if (!condition) {
      return children;
    }

    const { classes, theme } = this.props;
    const expanded = this.state[expanded_key];

    return (
      <>
        <Collapse
          collapsedHeight={theme.spacing(height)}
          style={{ position: 'relative' }}
          in={expanded}
        >
          {children}
          <div className={classnames(
            classes.expand_fade_out,
            { [classes.expand_fade_in]: expanded },
          )}/>
        </Collapse>
        <Toolbar>
          <Button
            className={classes.expand_button}
            onClick={() => this.setState({
              [expanded_key]: !expanded
            })}
          >
            <ChevronDownIcon
              color="inherit"
              className={classnames(
                classes.expand_icon_out,
                { [classes.expand_icon_in]: expanded },
              )}
            />
            {expanded ? 'Show Less' : 'Show More'}
          </Button>
        </Toolbar>
      </>
    )
  }

  renderWhenToPost = () => {
    const { classes, theme, width } = this.props;
    const { post_mode } = this.state;

    return (
      <>
        <Toolbar
          variant={width === 'xs' ? 'regular' : 'dense'}
          disableGutters={isWidthUp('sm', width)}
        >
          <div className={classes.step_circle}>
            <Typography>2</Typography>
          </div>
          <Typography variant="h5">
            When to Share
          </Typography>
        </Toolbar>
        <div className={classes.step_content}>
          <RadioGroup
            row={isWidthUp('sm', width)}
            value={post_mode}
            onChange={(e) => this.setState({ post_mode: e.target.value })}
          >
            <FormControlLabel
              control={<Radio color="primary"/>}
              label="Immediately"
              value="now"
            />
            <FormControlLabel
              control={<Radio color="primary"/>}
              label="Scheduled"
              value="later"
            />
          </RadioGroup>
          <Collapse in={post_mode === 'later'}>
            <Toolbar>
              <CalendarRangeIcon style={{ marginRight: theme.spacing(1) }} color="primary"/>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  label="Post On"
                  value={this.state.post_eta}
                  onChange={(value) => this.setState({ post_eta: value })}
                  dateRangeIcon={<CalendarRangeIcon/>}
                  timeIcon={<ClockIcon/>}
                  leftArrowIcon={<ChevronLeftIcon/>}
                  rightArrowIcon={<ChevronRightIcon/>}
                />
              </MuiPickersUtilsProvider>
            </Toolbar>
          </Collapse>
        </div>
      </>
    );
  }

  renderYourMessage = () => {
    const { classes, theme, width } = this.props;

    const {
      post_message,
      facebook_list,
      twitter_list,
      twitter_primary,
    } = this.state;

    const message_count = this.getPostCount();
    let { message_mode } = this.state;

    if (message_count === 0) {
      message_mode = 'none';
    } else if (message_count === 1 && message_mode === 'multi') {
      message_mode = 'single';
    }

    let twitter_account = null;
    let twitter_check = null;

    if (twitter_primary !== null) {
      twitter_account = twitter_list[twitter_primary];

      twitter_check = this.checkTweetMessage(
        this.getMessage('twitter', twitter_account.twitterId)
      );
    }

    return (
      <>
        <Toolbar
          variant={width === 'xs' ? 'regular' : 'dense'}
          disableGutters={isWidthUp('sm', width)}
        >
          <div className={classes.step_circle}>
            <Typography>3</Typography>
          </div> 
          <Typography variant="h5">
            Your Message
          </Typography>
        </Toolbar>
        <div className={classes.step_content}>
          <RadioGroup
            row={isWidthUp('sm', width)}
            value={message_mode}
            style={{ marginBottom: theme.spacing(2) }}
            onChange={(e) => this.setState({ message_mode: e.target.value })}
          >
            <FormControlLabel
              control={<Radio color="primary"/>}
              label="None"
              value="none"
            />
            <FormControlLabel
              disabled={message_count === 0}
              control={<Radio color="primary"/>}
              label="Use the same message"
              value="single"
            />
            <FormControlLabel
              disabled={message_count <= 1}
              control={<Radio color="primary"/>}
              label="Use separate messages"
              value="multi"
            />
          </RadioGroup>
          <Collapse in={message_mode !== 'none'}>
            <Grid container spacing={2}>
              <TransitionGroup component={null}>
                {
                  (message_mode === 'single' || message_count <= 1) &&
                  <Grow key="single-0">
                    <Grid item xs={12}>
                      <TextField
                        fullWidth multiline variant="outlined"
                        placeholder="Type the message here..."
                        helperText={twitter_check !== null ? `(${twitter_check.length}/280)` : null}
                        error={twitter_check !== null && twitter_check.error}
                        value={post_message}
                        onChange={(e) => this.setState({ post_message: e.target.value })}
                      />
                    </Grid>
                  </Grow>
                }
                {
                  (message_mode === 'multi') &&
                  facebook_list.map((page, index) => (
                    <Grow key={`facebook-${index}`}>
                      <Grid item xs={12}>
                      <Toolbar disableGutters style={{ alignItems: 'flex-start' }}>
                          <Avatar className={classes.message_avatar} src={
                            `https://graph.facebook.com/v2.12/${page.fbId}/picture?height=40`
                          }/>
                          <TextField
                            multiline variant="outlined" label={page.name}
                            InputLabelProps={{ style: { whiteSpace: 'nowrap' } }}
                            className={classes.message_textfield}
                            value={this.getMessage('facebook', page.fbId)}
                            onChange={(e) => this.setMessage('facebook', page.fbId, e.target.value)}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <FacebookIcon style={{ color: '#1778F2' }}/>
                                </InputAdornment>
                              )
                            }}
                          />
                        </Toolbar>
                      </Grid>
                    </Grow>
                  ))
                }
                {
                  (message_mode === 'multi') &&
                  (twitter_account !== null) &&
                  <Grow key="twitter-0">
                    <Grid item xs={12}>
                      <Toolbar disableGutters style={{ alignItems: 'flex-start' }}>
                        <Avatar className={classes.message_avatar} src={
                          `https://twitter-avatar.now.sh/${twitter_account.screen_name}`
                        }/>
                        <TextField
                          multiline variant="outlined" label={`@${twitter_account.screen_name}`}
                          InputLabelProps={{ style: { whiteSpace: 'nowrap' } }}
                          className={classes.message_textfield}
                          helperText={`(${twitter_check.length}/280)`}
                          error={twitter_check.error}
                          value={this.getMessage('twitter', twitter_account.twitterId)}
                          onChange={(e) => this.setMessage('twitter', twitter_account.twitterId, e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <TwitterIcon style={{ color: '#1DA1F2' }}/>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Toolbar>
                    </Grid>
                  </Grow>
                }
              </TransitionGroup>
            </Grid>
          </Collapse>
        </div>
      </>
    );
  }

  renderFacebookList = (pages, loading) => {
    const { classes, width } = this.props;
    const { facebook_list } = this.state;

    let list = (
      <List>
        <TransitionGroup>
          {pages.map((page, index) => (
            <Fade key={index}>
              <ListItem
                button dense onClick={() => this.toggleFacebook(page)}
              >
                <Checkbox
                  disableRipple tabIndex={-1} color="primary"
                  checked={facebook_list.findIndex(e => e.fbId === page.fbId) >= 0}
                />
                <ListItemAvatar>
                  <Avatar
                    src={`https://graph.facebook.com/v2.12/${page.fbId}/picture?height=40`}
                  />
                </ListItemAvatar>
                <ListItemText>
                  {page.name}
                </ListItemText>
              </ListItem>
            </Fade>
          ))}
        </TransitionGroup>
        {(loading) && (
          <ListItem className={classes.list_loader}>
            <CircularProgress size={24}/>
          </ListItem>
        )}
      </List>
    );

    if (width === 'xs') {
      list = this.renderAccordion(
        list, (pages.length > 3), 'facebook_expanded', 22
      );
    }

    return (
      <>
        <Typography
          variant="h6" gutterBottom
          className={classes.page_header}
        >
          Facebook Pages
        </Typography>
        {list}
      </>
    );
  }

  renderTwitterList = (accounts, loading) => {
    const { classes, width, theme } = this.props;
    const { twitter_list, twitter_primary } = this.state;

    let list = (
      <List>
          <TransitionGroup>
          {accounts.map((account, index) => (
            <Fade key={index}>
              <ListItem
                button dense onClick={() => this.toggleTwitter(account)}
              >
                <Checkbox
                  disableRipple tabIndex={-1} color="primary"
                  checked={twitter_list.findIndex(e => e.twitterId === account.twitterId) >= 0}
                />
                <ListItemAvatar>
                  <Avatar
                    src={`https://twitter-avatar.now.sh/${account.screen_name}`}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={account.name}
                  secondary={`@${account.screen_name}`}
                />
                {
                  twitter_primary !== null &&
                  (twitter_list.length > 1) &&
                  (twitter_list.findIndex(e => e.twitterId === account.twitterId) >= 0) &&
                  (twitter_list[twitter_primary].twitterId !== account.twitterId) &&
                  <ListItemSecondaryAction>
                    <Tooltip title="This account will retweet your post.">
                      <TwitterRetweetIcon color="inherit"/>
                    </Tooltip>
                  </ListItemSecondaryAction>
                }
              </ListItem>
            </Fade>
          ))}
          </TransitionGroup>
        {(loading) && (
          <ListItem className={classes.list_loader}>
            <CircularProgress size={24}/>
          </ListItem>
        )}
      </List>
    );

    if (width === 'xs') {
      list = this.renderAccordion(
        list, (accounts.length > 3), 'twitter_expanded', 22
      );
    }

    return (
      <>
        <Typography
          variant="h6" gutterBottom
          className={classes.page_header}
        >
          Twitter Accounts
          <Tooltip
            enterDelay={500}
            title="Twitter allows you to post identical content to only one account; the other accounts will retweet your post."
          >
            <HelpCircleOutlineIcon style={{ marginLeft: theme.spacing(1) }} fontSize="small" color="inherit"/>
          </Tooltip>
        </Typography>
        {list}
      </>
    );
  }

  close = (share_flag) => {
    if (share_flag) {
      const check = this.checkCanShare();
  
      if (check.error) {
        this.setState({
          snackbar_open: true,
          snackbar_text: check.message,
        });

        return;
      }
    }

    const { onClose } = this.props;
    let e = { sharing: share_flag };

    if (share_flag) {
      const { post, media, json } = this.props;
      const { facebook_list, twitter_list, twitter_primary } = this.state;
      const { post_mode, post_eta } = this.state;

      e.jpg = media.jpg;
      e.png = media.png;
      e.mp4 = media.mp4;
      e.json = JSON.stringify(json);
      e.width = post.width;
      e.height = post.height;
      e.template = (post.template ? post.template.id : null);

      e.facebook_pages = facebook_list.map((page) => ({
        id: page.id,
        facebook_id: page.fbId,
        name: page.name,
        token: page.accessToken,
        message: this.getMessage('facebook', page.fbId),
      }));

      if (twitter_primary !== null) {
        e.twitter_post = {
          id: twitter_list[twitter_primary].id,
          twitter_id: twitter_list[twitter_primary].twitterId,
          name: twitter_list[twitter_primary].name,
          username: twitter_list[twitter_primary].screen_name,
          token: twitter_list[twitter_primary].oauthToken,
          secret: twitter_list[twitter_primary].oauthTokenSecret,
          message: this.getMessage('twitter', twitter_list[twitter_primary].twitterId),
        };

        e.twitter_retweets = twitter_list.filter(
          (_, index) => index !== twitter_primary
        ).map((account) => ({
          id: account.id,
          twitter_id: account.twitterId,
          name: account.name,
          username: account.screen_name,
          token: account.oauthToken,
          secret: account.oauthTokenSecret,
        }));
      } else {
        e.twitter_post = null;
        e.twitter_retweets = [];
      }

      e.eta = (post_mode === 'later' ? post_eta : null);
    }

    (onClose && onClose(e));
  }

  toggleFacebook = (page) => {
    let { facebook_list } = this.state;

    const index = facebook_list.findIndex(
      e => e.fbId === page.fbId
    );

    if (index >= 0) {
      facebook_list.splice(index, 1);
      this.setMessage('facebook', page.fbId, null, false);
    } else {
      facebook_list.push(page);
    }

    this.forceUpdate();
  }

  toggleTwitter = (account) => {
    let { twitter_list, twitter_primary } = this.state;

    const index = twitter_list.findIndex(
      e => e.twitterId === account.twitterId
    );

    if (index >= 0) {
      twitter_list.splice(index, 1);
      this.setMessage('twitter', account.twitterId, null, false);
    } else {
      twitter_list.push(account);
    }

    if (twitter_list.length === 1) {
      this.setState({ twitter_primary: 0 });
    } else if (index === twitter_primary) {
      this.setState({
        twitter_primary: (twitter_list.length !== 0 ? 0 : null)
      });
    } else {
      this.forceUpdate();
    }
  }

  getMessage = (type, id) => {
    switch (this.state.message_mode) {
      default:
      case 'none': {
        return '';
      }
      case 'single': {
        return this.state.post_message;
      }
      case 'multi': {
        const { message_list } = this.state;

        const index = message_list.findIndex((message) => (
          message.type === type && message.id === id
        ));
    
        if (index < 0) {
          return '';
        }
    
        return message_list[index].content;
      }
    }
  }

  setMessage = (type, id, content, force_update = true) => {
    let { message_list } = this.state;

    const index = message_list.findIndex((message) => (
      message.type === type && message.id === id
    ));

    if (content !== null) {
      if (index >= 0) {
        message_list[index].content = content;
      } else {
        message_list.push({ type, id, content });
      }
    } else if (index >= 0) {
      message_list.splice(index, 1);
    }

    if (force_update) {
      this.forceUpdate();
    }
  }

  getPostCount = () => {
    const { facebook_list, twitter_list } = this.state;
    return (Math.min(1, twitter_list.length) + facebook_list.length);
  }

  checkTweetMessage = (message) => {
    const result = twitter.parseTweet(message);

    if (message.length !== 0) {
      return {
        error: (!result.valid),
        length: result.weightedLength,
      };
    } else {
      return { error: false, length: 0 };
    }
  }

  checkCanShare = () => {
    const post_count = this.getPostCount();

    if (post_count === 0) {
      return { error: true, message: 'Please select an account.' };
    }

    const { message_mode, post_message } = this.state;

    if (message_mode === 'single' && post_message.length === 0) {
      return { error: true, message: 'Please enter a message.' };
    }

    const { twitter_list, twitter_primary } = this.state;
    
    if (twitter_primary !== null) {
      const twitter_check = this.checkTweetMessage(
        this.getMessage('twitter', twitter_list[twitter_primary].twitterId)
      );

      if (twitter_check.error) {
        return { error: true, message: 'Post message is too long.' };
      }
    }

    return { error: false, message: '' };
  }

};

// -------------------------------------------------------------------------- //

export const ShareDialog = compose(
  graphql(GQL_GET_FACEBOOK_PAGES, {
    name: 'gql_get_facebook_pages',
    options: { fetchPolicy: 'network-only' },
  }),
  graphql(GQL_GET_TWITTER_ACCOUNTS, {
    name: 'gql_get_twitter_accounts',
    options: { fetchPolicy: 'network-only' },
  }),
  withStyles(STYLES, { withTheme: true }),
  withWidth(),
)(ShareDialog_);

// -------------------------------------------------------------------------- //
