
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Button,
  CircularProgress,
  Collapse,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  Magnify as MagnifyIcon,
  FilterVariant as FilterVariantIcon,
  Clock as ClockIcon,
  CalendarRange as CalendarRangeIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from 'mdi-material-ui';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { Query, compose, graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import gql from 'graphql-tag';

// -------------------------------------------------------------------------- //

const STATES = [
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL',
  'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT',
  'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI',
  'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY',
]

const DIVISIONS = [
  {
    name : 'Division 1',
  },
  {
    name : 'Division 2',
  },
  {
    name : 'Division 3',
  },
  {
    name : 'NAIA',
  },
  {
    name : 'Junior College',
  },
  {
    name : 'High School',
  },
  {
    name : 'Pro',
  },
  {
    name : 'Other',
  }
];

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  heading: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  tableRow: {
    cursor: 'pointer',
  },
  filter_control: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: theme.spacing(20),
  },
});

// -------------------------------------------------------------------------- //

const GET_ALL_CLIENTS = gql`
  query($where: ClientWhereInput) {
    clients(where: $where) {
      id
    }
  }
`;

// -------------------------------------------------------------------------- //

const GET_CLIENTS = gql`
  query getClients(
    $where: ClientWhereInput
    $orderBy: ClientOrderByInput 
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    clients (
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      id
      name
      state
    }
  }
`;

// -------------------------------------------------------------------------- //

class ClientTable extends React.Component {

  state = {
    rowsPerPage: 15,
    search: false,
    searchString: "",
    filter_open: false,
    filter_user: '',
    filter_email: '',
    filter_state: '',
    filter_status: '',
    filter_division: '',
    filter_expire_after: null,
    filter_expire_before: null,
    page: 0,
  }

  componentDidMount() {
    const { search_parameters } = this.props;
    
    if (search_parameters) {
      this.setState(search_parameters);
    }
  }

  componentWillUnmount() {
    const { search_parameters } = this.props;

    if (search_parameters) {
      Object.keys(search_parameters).forEach((key) => {
        search_parameters[key] = this.state[key];
      });
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleToggle = name => () => {
    this.setState({ [name]: !this.state[name] });
  }

  renderToolbar = () => {
    const { classes } = this.props;

    return (
      <>
        <Toolbar>
          <Typography variant="h6" className={classes.heading}>
            Clients
          </Typography>
          <Tooltip id="tooltip-search" title="Search">
            <IconButton onClick={this.handleToggle('search')}>
              <MagnifyIcon/>
            </IconButton>
          </Tooltip>
          {
            (!!this.state.search) &&
            <TextField
              id="search"
              placeholder="Search by name, email, etc"
              autoComplete="off"
              className={classes.textField}
              value={this.state.searchString}
              onChange={(e) => this.setState({ searchString: e.target.value })}
              margin="dense"
            />
          }
          <Tooltip id="tooltip-filter" title="Filter Clients">
            <React.Fragment>
              <IconButton onClick={() => this.toggleFilter()}>
                <FilterVariantIcon/>
              </IconButton>
            </React.Fragment>
          </Tooltip>
        </Toolbar>
        <Collapse appear in={this.state.filter_open}>
          <Toolbar>
            <TextField
              id="name"
              label="Name"
              autoComplete="off"
              className={classes.filter_control}
              value={this.state.filter_name}
              onChange={(e) => this.setState({ filter_name: e.target.value })}
            />
            <TextField
              id="email"
              label="Email"
              autoComplete="off"
              className={classes.filter_control}
              value={this.state.filter_email}
              onChange={(e) => this.setState({ filter_email: e.target.value })}
            />
            <FormControl className={classes.filter_control}>
              <InputLabel htmlFor="state">State</InputLabel>
              <Select
                id="state"
                value={this.state.filter_state}
                onChange={(e) => this.setState({ filter_state: e.target.value })}
                inputProps={{ id: 'state' }}
              >
                <MenuItem value=""><em>None</em></MenuItem>
                {STATES.map((state, index) => (
                  <MenuItem key={state} value={state}>{state}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.filter_control}>
              <InputLabel htmlFor="division">Division</InputLabel>
              <Select
                id="division"
                value={this.state.filter_division}
                onChange={(e) => this.setState({ filter_division: e.target.value })}
                inputProps={{ id: 'division' }}
              >
                <MenuItem value=""><em>None</em></MenuItem>
                {DIVISIONS.map((state, index) => (
                  <MenuItem key={state.name} value={state.name}>{state.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.filter_control}>
              <InputLabel htmlFor="status">Account Status</InputLabel>
              <Select
                id="status"
                value={this.state.filter_status}
                onChange={(e) => this.setState({ filter_status: e.target.value })}
                inputProps={{ id: 'status' }}
              >
                <MenuItem value=""><em>None</em></MenuItem>
                <MenuItem value="ACTIVE">Active</MenuItem>
                <MenuItem value="INACTIVE">Inactive</MenuItem>
                <MenuItem value="CANCELLED">Cancelled</MenuItem>
              </Select>
            </FormControl>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="Expires After"
                  className={classes.filter_control}
                  value={this.state.filter_expire_after}
                  onChange={(e) => this.setState({ filter_expire_after: e })}
                  dateRangeIcon={<CalendarRangeIcon/>}
                  timeIcon={<ClockIcon/>}
                  leftArrowIcon={<ChevronLeftIcon/>}
                  rightArrowIcon={<ChevronRightIcon/>}
                />
                <DatePicker
                  label="Expires Before"
                  className={classes.filter_control}
                  value={this.state.filter_expire_before}
                  onChange={(e) => this.setState({ filter_expire_before: e })}
                  dateRangeIcon={<CalendarRangeIcon/>}
                  timeIcon={<ClockIcon/>}
                  leftArrowIcon={<ChevronLeftIcon/>}
                  rightArrowIcon={<ChevronRightIcon/>}
                />
              </MuiPickersUtilsProvider>
          </Toolbar>
          <Toolbar>
            <div className={classes.flex}/>
            <Button color="primary" onClick={() => this.resetFilters()}>
              Reset Filters
            </Button>
          </Toolbar>
        </Collapse>
      </>
    );
  }

  resetFilters = () => {
    this.setState({
      filter_user: '',
      filter_email: '',
      filter_state: '',
      filter_status: '',
      filter_division: '',
      filter_expire_after: null,
      filter_expire_before: null,
    });
  }

  toggleFilter = () => {
    this.setState({ filter_open: !this.state.filter_open });
  }

  handleChangePage = (e, page) => {
    this.setState({ page });
  }

  handleChangeRowsPerPage = (e) => {
    let rowsPerPage = e.target.value;

    this.setState({ rowsPerPage });
  }

  calculateWhereInput = (filters) => {
    let where = {};

    if (filters.query !== '') {
      const names = filters.query.split(' ');
      const first_name = names[0];
      let last_name = null;
  
      if (names.length > 1) {
        last_name = names[1];
      }

      where.OR = [
        { name_contains: filters.query },
        { address_contains: filters.query },
        { state_contains: filters.query },
        { city_contains: filters.query },
        { notes_contains: filters.query },
        { phone_contains: filters.query },
        {
          users_some: {
            OR: [
              { firstname_contains: first_name },
              { lastname_contains: last_name ? last_name : first_name },
              { email_contains: filters.query },
            ]
          }
        }
      ];
    }

    if (filters.name !== '') {
      if (!('users_some' in where)) {
        where.users_some = {};
      }

      where.users_some.OR = [
        { firstname_contains: filters.name },
        { lastname_contains: filters.name },
      ];
    }

    if (filters.email !== '') {
      if (!('users_some' in where)) {
        where.users_some = {};
      }

      where.users_some.email_contains = filters.email;
    }

    if (filters.state !== '') {
      where.state = filters.state;
    }

    if (filters.status !== '') {
      where.account_status = filters.status;
    }

    if (filters.division !== '') {
      where.division = filters.division;
    }

    if (filters.expire_after !== null) {
      where.subscription_end_gte = filters.expire_after.toISOString();
    }

    if (filters.expire_before !== null) {
      where.subscription_end_lte = filters.expire_before.toISOString();
    }

    return where;
  }

  render() {
    const { classes, client_info, history } = this.props;
    const { rowsPerPage, searchString, page } = this.state;

    let variables = {
      first: rowsPerPage,
      orderBy: "name_ASC",
      where: {},
      skip: (page * rowsPerPage)
    }

    let client_count = 0;
    variables.where = this.calculateWhereInput({
      query: searchString,
      name: this.state.filter_name,
      email: this.state.filter_email,
      status: this.state.filter_status,
      state: this.state.filter_state,
      division: this.state.filter_division,
      expire_before: this.state.filter_expire_before,
      expire_after: this.state.filter_expire_after,
    });

    if (client_info && client_info.clients && !client_info.loading) {
      client_count = client_info.clients.length;
    }

    return (
      <Query
        query={GET_CLIENTS}
        networkPolicy="network-only"
        variables={variables}
      >
        {({ data, networkStatus }) => (
          <Paper>
            {this.renderToolbar()}
            <Divider/>
            <Table>
              {
                networkStatus === 7 ?
                  <TableBody>
                  {
                    data.clients.map((client) => (
                      <TableRow
                        hover key={client.id}
                        className={classes.tableRow}
                      >
                        <TableCell
                          onClick={() => history.push(`/admin/clients/${client.id}`)}
                        >
                          {client.name}
                        </TableCell>
                      </TableRow>
                    ))
                  }
                  </TableBody> :
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <CircularProgress/>
                      </TableCell>
                    </TableRow>
                  </TableBody>
              }
            </Table>
            {
              networkStatus === 7 ?
                <TablePagination
                  component="div"
                  count={client_count}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[ 10, 15, 20, 50 ]}
                  page={page}
                  backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                  nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                /> :
                null
            }

          </Paper>
        )}
      </Query>
    );
  }
}

// -------------------------------------------------------------------------- //

export default compose(
  graphql(GET_ALL_CLIENTS, {
    name: 'client_info',
    options: { fetchPolicy: 'network-only' }
  }),
  withRouter,
  withStyles(STYLES),
)(ClientTable);

// -------------------------------------------------------------------------- //
