
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Avatar,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  withStyles,
} from '@material-ui/core';

import {
  Check as CheckIcon,
} from 'mdi-material-ui';

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({

});

// -------------------------------------------------------------------------- //

function PostProgress_(props) {
  const { data, ...list_props } = props;

  if (!data) {
    return null;
  }

  return (
    <List {...list_props}>
    {data.map((post, index) => {
      let src = '', secondary = '', action = null, handler = null;

      switch (post.type) {
        case 'facebook': {
          src = `https://graph.facebook.com/v2.12/${post.facebook_id}/picture?height=40`;
          break;
        }
        case 'twitter': {
          src = `https://twitter-avatar.now.sh/${post.username}`;
          break;
        }
        default: {
          break;
        }
      }

      switch (post.status) {
        case 'standby': {
          secondary = 'Waiting to share...';
          break;
        }
        case 'inflight': {
          action = <CircularProgress/>;
          secondary = 'Sharing...';
          break;
        }
        case 'delivered': {
          action = <CheckIcon color="primary"/>
          secondary = `View on ${post.type === 'facebook' ? 'Facebook' : 'Twitter'}`;
          handler = () => window.open(post.post.url, '_blank');
          break;
        }
        default: {
          break;
        }
      }

      return (
        <ListItem
          key={index}
          button={handler !== null}
          onClick={handler}
        >
          <ListItemAvatar>
            <Avatar src={src}/>
          </ListItemAvatar>
          <ListItemText primary={post.name} secondary={secondary}/>
          <ListItemSecondaryAction>
            {action}
          </ListItemSecondaryAction>
        </ListItem>
      );
    })}
    </List>
  );
}

// -------------------------------------------------------------------------- //

export const PostProgress = withStyles(STYLES)(PostProgress_);

// -------------------------------------------------------------------------- //
