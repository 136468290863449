import React from 'react';
//import Transitions from '../editor/dict/transitions';
import './TransitionsCSS.css';
import Slider from  'rc-slider';
const ToolTip = Slider.createSliderWithTooltip
const ESlider = ToolTip(Slider);

const transitions = {

  None: {
    type: 'None',
    name: 'None'
  },
  FadeOut: {
    type: 'FadeOut',
    name: 'Fade Out'
  },
  PushUp: {
    type: 'PushUp',
    name: 'Push Up'
  },
  Split: {
    type: 'Split',
    name: 'Split'
  },
  WipeLeft: {
    type: 'WipeLeft',
    name: 'Wipe Left'
  }
};

const GridSquares = (props) => {
  let gridSquares = [];
  for(let key in transitions){
    let name = transitions[key].name;
    let type = transitions[key].type;
    let Gs = key !== "Split" ? GridSquare({type:type}) : GridSquareSplit();
    let Gsc = GridSquareContainer(Gs);
    let GSC = <Gsc key={key} name={name} type={type} getType={props.getType(type)}/>;
    gridSquares.push(GSC);
  }
  return gridSquares;
}

const GridSquare = (props) => <div className={"GridSquare " + props.type}/>
const GridSquareSplit = () => <div className={"GridSquareSplit"} >
                                  <GridSquare type={"Split SplitLeft"}/>
                                  <GridSquare type={"Split SplitRight"}/>
                                  </div>
const GridSquareContainer = (WrappedComponent)  => (props) => <div
                                          onClick={props.getType}
                                          className="GridSquareContainer"
                                          >
                                            {WrappedComponent}
                                            {props.name}
                                        </div>

const FlexContainer = (props) => <div className="Container">{props.children}</div>

const Transitions = (props) =>  <FlexContainer>
                                      {GridSquares(props)}
                                      <ESlider
                                      dots={true}
                                      tipProps={props.tipProps}
                                      min={props.min}
                                      max={props.max}
                                      value={props.duration}
                                      step={props.step}
                                      onChange={props.changeDuration} />
                                    </FlexContainer>


export default Transitions;
