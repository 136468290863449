import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  InputBase,
  Grid,
  CircularProgress,
  IconButton,
  Checkbox
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { gql, useMutation } from '@apollo/client';
import useStyles from './Stripe.style';

const CREATE_CLIENT = gql`
  mutation createClient(
    $name: String!
    $firstname: String!
    $lastname: String!
    $email: String!
  ) {
    createClientWithCc(
      name: $name
      firstname: $firstname
      lastname: $lastname
      email: $email
    ) {
      id
    }
  }
`;

const UPDATE_CLIENT = gql`
  mutation updateClient($id: ID!, $name: String, $nickName: String, $address: String, $city: String, $zip: String, $state: String, $phone: String, $notes: String, $division: String, $prestosportsId: String) {
    updateClient(
      id: $id,
      name: $name
      nickName: $nickName
      address: $address,
      city: $city,
      zip: $zip,
      state: $state,
      phone: $phone,
      notes: $notes, 
      division: $division, 
      prestosportsId: $prestosportsId
    ) {
      id,
      zip
    }
  }
`;

const UPDATE_STRIPE_INFO = gql`
  mutation updateStipeInfo( $id : ID!, $customer_id : String!, $subscription_end : String!, $subscription_id : String!,
    $subscription_item_id : String!,$subscription_start : String!){
    updateClientStripeInfo(id : $id, customer_id : $customer_id, subscription_end : $subscription_end, subscription_id : $subscription_id, subscription_item_id : $subscription_item_id, subscription_start : $subscription_start){
      name
      phone
      state
      zip
      city
      address
      id
      color1
      color2
      color3
      logoBucket
      logoKey
      customer_id
    }

  }
`;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET_KEY);

const StripeCheckoutButton = ({
                                buttonLabel, priceID, plan, price,type, fetchData,data
                              }) => {
  const [checkout, setCheckout] = useState(false);
  const classes = useStyles();
  const openCheckout = () => {
    setCheckout(true);
  };
  const closeCheckout = () => {
    setCheckout(false);
  };
  return (
      <>
        <Button onClick={openCheckout} className={classes.StripeButton}>
          {buttonLabel}
        </Button>
        <Dialog open={checkout} onClose={closeCheckout}>
          <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={closeCheckout}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers className={classes.checkout}>
            <Elements stripe={stripePromise}>
              <StripeCheckout priceID={priceID} price={price} plan={plan} type={type} fetchData data={data}/>
            </Elements>
          </DialogContent>
        </Dialog>
      </>
  );
};

export default StripeCheckoutButton;

const StripeCheckout = ({ priceID, plan, price, type, fetchData,data }) => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const [createClient] = useMutation(CREATE_CLIENT);
  const [updateClient, { data: updateClientDt}] = useMutation(UPDATE_CLIENT);
  const [updateStipeInfo, { data: updateClientData}] = useMutation(UPDATE_STRIPE_INFO);
  const [emailField, setEmailField] = useState('');
  const [emailValidation, setEmailValidation] = useState(false);
  const [nameField, setNameField] = useState('');
  const [namedValidation, setNameValidation] = useState(false);
  const [lastNameField, setLastNameField] = useState('');
  const [lastNamedValidation, setLastNameValidation] = useState(false);
  const [organizationField, setOrganizationField] = useState('');
  const [organizationValidation, setOrganizationValidation] = useState(false);
  const [phoneField, setPhoneField] = useState('');
  const [phoneValidation, setPhoneValidation] = useState(false);
  const [addressField, setAddressField] = useState('');
  const [addressValidation, setAddressValidation] = useState(false);
  const [stateField, setStateField] = useState('');
  const [stateValidation, setStateValidation] = useState(false);
  const [cityField, setCityField] = useState('');
  const [cityValidation, setCityValidation] = useState(false);
  const [zipCodeField, setZipCodeField] = useState('');
  const [zipCodeValidation, setZipCodeValidation] = useState(false);
  const [cardValidation, setCardValidation] = useState(false);
  const [cardValidationError, setCardValidationError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [acceptTCStatus,setAcceptTCStatus] = useState(false)
  const [isFormValid,setIsFormValid] = useState(false)
  const [autoFillStatus,setAutoFillStatus] = useState(false)
  const [dirtyField,setDirtyField] = useState('')
  const [isCardDirty,setIscardDirty] = useState(false)

  const handleCardValidation = (e) => {
    setDirtyField('card')
    setIscardDirty(true)
    setCardValidationError(false);

    e.complete === true ? setCardValidation(true) : setCardValidation(false);

    e.error ? setCardValidationError(true) : setCardValidationError(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch(
        `${process.env.REACT_APP_SS_API_ENDPOINT}v1/create-subscription/dev/`,
        {
          method: 'POST',
          body: JSON.stringify({
            body: {
              email: emailField,
              name: `${nameField} ${lastNameField}`,
              organization: organizationField,
              plan,
              price: priceID,
              dev: "true"
            },
          }),
        },
    )
        .then((resp) => resp.json())
        .then(async (response) => {
          const { paymentIntent, error } = await stripe.confirmCardPayment(
              response.data.client_secret,
              {
                payment_method: {
                  card: elements.getElement(CardElement),
                  billing_details: {
                    address: {
                      line1: addressField,
                      state: stateField,
                      city: cityField,
                      postal_code: zipCodeField,
                    },
                    phone: phoneField,
                    name: `${nameField} ${lastNameField}`,
                  },
                },
                receipt_email: emailField,
              },
          );
          if (error) {
            setLoading(false);

            if (error.type === 'card_error') setCardValidationError(true);

            alert(`${error.message}: ${error.decline_code}`);
          }
          if (paymentIntent.status === 'succeeded') {
            if (type === 'CREATE') {
              await createClient({
                variables: {
                  name: organizationField,
                  firstname: nameField,
                  lastname: lastNameField,
                  email: emailField,
                },
              })
            } else if (type === 'RENEW') {
              await updateStipeInfo({
                variables: {
                  id: data.data.me.client.id,
                  customer_id: response.data.customer.response.id,
                  subscription_end: new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 365)).toDateString(),
                  subscription_start: new Date(new Date().getTime()).toDateString(),
                  subscription_id: response.data.subscription.response.id,
                  subscription_item_id: priceID
                },
              })

              await updateClient({
                variables: {
                  id: data.data.me.client.id,
                  email: emailField,
                  name: organizationField,
                  city: cityField,
                  zip: zipCodeField,
                  state: stateField,
                  phone: phoneField
                }
              })
              console.log(updateClientDt)
            }
            console.log(updateClientData)
            console.log(response)
            setSuccess(true);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err)
          setLoading(false);
        });

  };

  useEffect(() => {

    let isValid = true;

    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const phoneRegex = /^[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/im;

    const alphaNumericRegex = /^[a-z0-9]+$/i

    const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;

    if (emailRegex.test(String(emailField.replace(/ /g,'')).toLocaleLowerCase()) === true) {
      setEmailValidation(false);
    } else if (dirtyField !== 'email' && emailField.length !== 0) {
      setEmailValidation(true);
      isValid = false
    } else {
      isValid = false
    }

    if (nameField.replace(/ /g,'').length >= 1 &&
        alphaNumericRegex.test(nameField.replace(/ /g,'').toLocaleLowerCase())
        && nameField.replace(/ /g,'').length <= 100) {
      setNameValidation(false);
    } else if (dirtyField !== 'name' && nameField.length !== 0){
      setNameValidation(true);
      isValid = false;
    } else {
      isValid = false
    }

    if (lastNameField.replace(/ /g,'').length >= 1
        && alphaNumericRegex.test(lastNameField.replace(/ /g,'').toLocaleLowerCase())
        && lastNameField.replace(/ /g,'').length <= 100) {
      setLastNameValidation(false);
    } else if (dirtyField !== 'lastName' && lastNameField.length !== 0) {
      setLastNameValidation(true);
      isValid = false;
    }else {
      isValid = false
    }

    if (organizationField.length > 2) {
      setOrganizationValidation(false);
    } else  if (dirtyField !== 'organization' && organizationField.length !== 0){
      setOrganizationValidation(true);
      isValid = false;
    } else {
      isValid = false
    }

    if (phoneRegex.test(String(phoneField).toLocaleLowerCase())) {
      setPhoneValidation(false);
    } else if (dirtyField !== 'phone' && phoneField.length !== 0) {
      setPhoneValidation(true);
      isValid = false;
    } else {
      isValid = false;
    }

    if (addressField.length > 6) {
      setAddressValidation(false);
    } else if (dirtyField !== 'adress' && addressField.length !== 0) {
      setAddressValidation(true);
      isValid = false;
    } else {
      isValid = false
    }

    if (stateField.length > 1) {
      setStateValidation(false);
    } else if (dirtyField !== 'state' && stateField.length !== 0) {
      setStateValidation(true);
      isValid = false;
    } else {
      isValid = false
    }

    if (cityField.length > 2) {
      setCityValidation(false);
    } else if (dirtyField !== 'city' && cityField.length !== 0) {
      setCityValidation(true);
      isValid = false;
    } else {
      isValid = false
    }

    if (zipCodeRegex.test(String(zipCodeField))) {
      setZipCodeValidation(false);
    } else if (dirtyField !== 'zip' && zipCodeField.length !== 0) {
      setZipCodeValidation(true);
      isValid = false;
    } else {
      isValid = false
    }

    if (cardValidation === true && isCardDirty === true) {
      setCardValidationError(false);
    } else if (isCardDirty === true){
      setCardValidationError(true);
      isValid = false;
    } else {
      isValid = false
    }
    if(acceptTCStatus === false) {
      isValid = false;
    }

    setIsFormValid(isValid)


    if (data.data !== undefined && fetchData === true && autoFillStatus === false){
      if (emailField === '') setEmailField(data.data.me.email);
      if (nameField === '') setNameField(data.data.me.firstname);
      if (lastNameField === '') setLastNameField(data.data.me.lastname);
      if (addressField === '') setAddressField(data.data.me.client.address);
      if (cityField === '') setCityField(data.data.me.client.city);
      if (zipCodeField === '') setZipCodeField(String(data.data.me.client.zip));
      if (organizationField === '') setOrganizationField(data.data.me.client.name);
      if (stateField === '') setStateField(data.data.me.client.state)
      if (phoneField === '') setPhoneField(data.data.me.client.phone)
      setAutoFillStatus(true)
      setDirtyField('')
    }
  }, [emailField, nameField, lastNameField,
    addressField, cityField, zipCodeField, organizationField, data,
    dirtyField, cardValidation, acceptTCStatus, stateField, autoFillStatus,
    isCardDirty, phoneField, fetchData]);



  return (
      <form onSubmit={(e) => handleSubmit(e)} className={classes.checkoutForm}>
        {success === false ? (
            <>
              <Typography className={classes.billingTitle}>
                Billing Information
              </Typography>
              <div className={classes.billingInformation}>
                <Grid container direction="column">
                  <Grid
                      item
                      className={
                        emailValidation === false
                            ? classes.checkoutField
                            : classes.checkoutFieldError
                      }
                  >
                    <Typography className={classes.label}>Email</Typography>
                    <InputBase
                        fullWidth
                        required
                        value={emailField}
                        onChange={(e) => {
                          setEmailField(e.target.value)
                        }}
                        onFocus={
                          () => setDirtyField('email')
                        }
                        placeholder="Email"
                        disabled={loading}
                    />
                  </Grid>
                  <Grid
                      item
                      className={
                        namedValidation === false
                            ? classes.checkoutField
                            : classes.checkoutFieldError
                      }
                  >
                    <Typography className={classes.label}>First name</Typography>
                    <InputBase
                        fullWidth
                        required
                        value={nameField}
                        onChange={(e) => {
                          setNameField(e.target.value)
                        }}
                        onFocus={
                          () => setDirtyField('name')
                        }
                        placeholder="First Name"
                        disabled={loading}
                    />
                  </Grid>
                  <Grid
                      item
                      className={
                        lastNamedValidation === false
                            ? classes.checkoutField
                            : classes.checkoutFieldError
                      }
                  >
                    <Typography className={classes.label}>Last name</Typography>
                    <InputBase
                        fullWidth
                        required
                        value={lastNameField}
                        onChange={(e) => {
                          setLastNameField(e.target.value)
                        }}
                        onFocus={
                          () => setDirtyField('lastName')
                        }
                        placeholder="Last Name"
                        disabled={loading}
                    />
                  </Grid>
                  <Grid
                      item
                      className={
                        organizationValidation === false
                            ? classes.checkoutField
                            : classes.checkoutFieldError
                      }
                  >
                    <Typography className={classes.label}>Organization</Typography>
                    <InputBase
                        fullWidth
                        required
                        value={organizationField}
                        onChange={(e) => {
                          setOrganizationField(e.target.value)
                        }}
                        onFocus={
                          () => setDirtyField('organization')
                        }
                        placeholder="Organization"
                        disabled={loading}
                    />
                  </Grid>
                  <Grid
                      item
                      className={
                        phoneValidation === false
                            ? classes.checkoutField
                            : classes.checkoutFieldError
                      }
                  >
                    <Typography className={classes.label}>Phone</Typography>
                    <InputBase
                        fullWidth
                        required
                        value={phoneField}
                        onChange={(e) => {
                          setPhoneField(e.target.value)
                        }}
                        onFocus={
                          () => setDirtyField('phone')
                        }
                        placeholder="Phone"
                        disabled={loading}
                    />
                  </Grid>
                  <Grid
                      item
                      className={
                        stateValidation === false
                            ? classes.checkoutField
                            : classes.checkoutFieldError
                      }
                  >
                    <Typography className={classes.label}>State</Typography>
                    <InputBase
                        fullWidth
                        required
                        value={stateField}
                        onChange={(e) => {
                          setStateField(e.target.value)
                        }}
                        onFocus={
                          () => setDirtyField('state')
                        }
                        placeholder="State"
                        disabled={loading}
                    />
                  </Grid>
                  <Grid
                      item
                      className={
                        addressValidation === false
                            ? classes.checkoutField
                            : classes.checkoutFieldError
                      }
                  >
                    <Typography className={classes.label}>Address</Typography>
                    <InputBase
                        fullWidth
                        required
                        value={addressField}
                        onChange={(e) => {
                          setAddressField(e.target.value)
                        }}
                        onFocus={
                          () => setDirtyField('adress')
                        }
                        placeholder="Address"
                        disabled={loading}
                    />
                  </Grid>
                  <Grid
                      item
                      className={
                        cityValidation === false
                            ? classes.checkoutField
                            : classes.checkoutFieldError
                      }
                  >
                    <Typography className={classes.label}>City</Typography>
                    <InputBase
                        fullWidth
                        required
                        value={cityField}
                        onChange={(e) => {
                          setCityField(e.target.value)
                        }}
                        onFocus={
                          () => setDirtyField('city')
                        }
                        placeholder="City"
                        disabled={loading}
                    />
                  </Grid>
                  <Grid
                      item
                      className={
                        zipCodeValidation === false
                            ? classes.checkoutFieldLast
                            : classes.checkoutFieldError
                      }
                  >
                    <Typography className={classes.label}>Zip</Typography>
                    <InputBase
                        fullWidth
                        required
                        value={zipCodeField}
                        onChange={(e) => {
                          setZipCodeField(e.target.value)
                        }}
                        onFocus={
                          () => setDirtyField('zip')
                        }
                        placeholder="Zip"
                        disabled={loading}
                    />
                  </Grid>
                </Grid>
              </div>
              <Typography className={classes.billingTitle}>Card Details</Typography>
              <div
                  className={
                    cardValidationError === false
                        ? classes.cardElement
                        : classes.cardElementError
                  }
              >
                <CardElement onChange={(event) => handleCardValidation(event)} />
              </div>
              <div className={classes.termsAndCondition}>
                <Checkbox onChange={() => setAcceptTCStatus(!acceptTCStatus)}/>
                <Typography className={classes.termsAndConditionsText}>
                  I agree to the <a target="_blank" rel="noopener noreferrer" href="https://scoreshots.com/terms">terms and conditions</a>
                </Typography>
              </div>
              <Button
                  type="submit"
                  className={ isFormValid === true ? classes.payButton : classes.payButtonDisabled}
                  disabled={loading === true || isFormValid === false}
              >
                {loading === false ? (
                    <>
                      Buy for $
                      {price}
                    </>
                ) : (
                    <CircularProgress color="secondary" size={25} />
                )}
              </Button>
            </>
        ) : (
            <div className={classes.successForm}>
              <CheckCircleOutlineIcon
                  color="secondary"
                  fontSize="large"
                  className={classes.successIncon}
              />
              <Typography
                  style={{ fontSize: '3vmin', marginTop: '3vmin', fontWeight: 600 }}
              >
                Payment successful
              </Typography>
              <Typography
                  style={{ fontSize: '3vmin', marginTop: '3vmin', fontWeight: 600 }}
              >
                Your subscription has been successfully purchased, you will receive
                an email with your login information
              </Typography>
            </div>
        )}
      </form>
  );
};
