
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Paper,
  Typography,
  withStyles,
} from '@material-ui/core';

import ResponsiveGrid from '../components/layout/ResponsiveGrid';
import { ReactComponent } from '../assets/curve.svg';
import { Route, Switch } from 'react-router';

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  root: {
    position: 'relative',
  },
  curve: {
    position: 'absolute',
    left: 0,
    bottom: -1,
    width: '100%',
    zIndex: 0,
    fill: theme.palette.background.default,
    '&:path': {
      fill: theme.palette.background.default,
    },
  },
  paper: {
    padding: theme.spacing(3),
  },
  paragraph: {
    paddingBottom: theme.spacing(5),
  },
});

// -------------------------------------------------------------------------- //

export default withStyles(STYLES)(({ classes }) => (
  <div className={classes.root}>
    <ResponsiveGrid style={{ height: '100%' }}>
      <Paper className={classes.paper}>
        <Switch>
          <Route path="/legal/privacy">
            <Typography
              id="privacy" variant="h4"
              component="h1" gutterBottom
            >
              Privacy Policy
            </Typography>
            <div className={classes.paragraph}>
            <Typography variant="body2" gutterBottom>
              This privacy policy has been compiled to better serve those who are concerned with how their 'Personally identifiable information' (PII) is being used online. PII, as used in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.
            </Typography>
            </div>
            <div className={classes.paragraph}>
              <Typography variant="h6" gutterBottom>
                What personal information do we collect from the people that visit our blog, website or app?
              </Typography>
              <Typography variant="body2" gutterBottom>
                When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address, mailing address, phone number, credit card information or other details to help you with your experience.
              </Typography>
            </div>
            <div className={classes.paragraph}>
              <Typography variant="h6" gutterBottom>
                When do we collect information?
              </Typography>
              <Typography variant="body2" gutterBottom>
                We collect information from you when you place an order, fill out a form or enter information on our site.
              </Typography>
            </div>
            <div className={classes.paragraph}>
              <Typography variant="h6" gutterBottom>
                How do we use your information?
              </Typography>
              <Typography variant="body2" gutterBottom>
                We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:
                <ul>
                  <li>
                    To personalize user's experience and to allow us to deliver the type of content and product offerings in which you are most interested.
                  </li>
                  <li>To quickly process your transactions.</li>
                  <li>
                    To send periodic emails regarding your order or other products and services.
                  </li>
                </ul>
              </Typography>
            </div>
            <div className={classes.paragraph}>
              <Typography variant="h6" gutterBottom>
                How do we protect visitor information?
              </Typography>
              <Typography variant="body2" gutterBottom>
                Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make your visit to our site as safe as possible.
              </Typography>
              <Typography variant="body2" gutterBottom>
                We use regular Malware Scanning.
              </Typography>
              <Typography variant="body2" gutterBottom>
                Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. In addition, all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology.
              </Typography>
              <Typography variant="body2" gutterBottom>
                We implement a variety of security measures when a user places an order enters, submits, or accesses their information to maintain the safety of your personal information.
              </Typography>
              <Typography variant="body2" gutterBottom>
                All transactions are processed through a gateway provider and are not stored or processed on our servers.
              </Typography>
            </div>
            <div className={classes.paragraph}>
              <Typography variant="h6" gutterBottom>
                Do we use &quot;cookies?&quot;
              </Typography>
              <Typography variant="body2" gutterBottom>
                Yes. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your Web browser (if you allow) that enables the site's or service provider's systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us remember and process the items in your shopping cart. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.
              </Typography>
            </div>
            <div className={classes.paragraph}>
              <Typography variant="h6" gutterBottom>
                We use cookies to:
              </Typography>
              <Typography variant="body2" gutterBottom>
                <ul>
                  <li>
                    Understand and save user's preferences for future visits.
                  </li>
                </ul>
              </Typography>
              <Typography variant="body2" gutterBottom>
                You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser (like Internet Explorer) settings. Each browser is a little different, so look at your browser's Help menu to learn the correct way to modify your cookies.
              </Typography>
              <Typography variant="body2" gutterBottom>
                  If you disable cookies off, some features will be disabled. It won't affect the user’s experience that makes your site experience more efficient and some of our services will not function properly.
              </Typography>
              <Typography variant="body2" gutterBottom>
                  However, you can still place orders.
              </Typography>
            </div>
            <div className={classes.paragraph}>
              <Typography variant="h6" gutterBottom>
                Third Party Disclosure
              </Typography>
              <Typography variant="body2" gutterBottom>
                We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless we provide you with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety.
              </Typography>
              <Typography variant="body2" gutterBottom>
                However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.
              </Typography>
            </div>
            <div className={classes.paragraph}>
              <Typography variant="h6" gutterBottom>
                Third party links
              </Typography>
              <Typography variant="body2" gutterBottom>
                We do not include or offer third party products or services on our website.
              </Typography>
            </div>
            <div className={classes.paragraph}>
              <Typography variant="h6" gutterBottom>
                Google
              </Typography>
              <Typography variant="body2" gutterBottom>
                Google's advertising requirements can be summed up by <a href="https://support.google.com/adwordspolicy/answer/1316548?hl=en">Google's Advertising Principles</a>. They are put in place to provide a positive experience for users.
              </Typography>
              <Typography variant="body2" gutterBottom>
                We have not enabled Google AdSense on our site but we may do so
                in the future.
              </Typography>
            </div>
            <div className={classes.paragraph}>
              <Typography variant="h6" gutterBottom>
                California Online Privacy Protection Act
              </Typography>
              <Typography variant="body2" gutterBottom>
                CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law's reach stretches well beyond California to require a person or company in the United States (and conceivably the world) that operates websites collecting personally identifiable information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals with whom it is being shared, and to comply with this policy. - See more at: <a href="http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf">http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf</a>
              </Typography>
            </div>
            <div className={classes.paragraph}>
              <Typography variant="h6" gutterBottom>
                According to CalOPPA we agree to the following:
              </Typography>
              <Typography variant="body2" gutterBottom>
                Users can visit our site anonymously.
              </Typography>
              <Typography variant="body2" gutterBottom>
                Once this privacy policy is created, we will add a link to it on our home page, or as a minimum on the first significant page after entering our website.
              </Typography>
              <Typography variant="body2" gutterBottom>
                Our Privacy Policy link includes the word 'Privacy', and can be easily be found on the page specified above.
              </Typography>
              <Typography variant="body2" gutterBottom>
                Users will be notified of any privacy policy changes:
                <ul>
                  <li>On our Privacy Policy Page</li>
                </ul>
                Users are able to change their personal information:
                <ul>
                  <li>By logging in to their account</li>
                </ul>
              </Typography>
            </div>
            <div className={classes.paragraph}>
              <Typography variant="h6" gutterBottom>
                How does our site handle do not track signals?
              </Typography>
              <Typography variant="body2" gutterBottom>
                We honor do not track signals and do not track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism is in place.
              </Typography>
            </div>
            <div className={classes.paragraph}>
              <Typography variant="h6" gutterBottom>
                Does our site allow third party behavioral tracking?
              </Typography>
              <Typography variant="body2" gutterBottom>
                It's also important to note that we do not allow third party behavioral tracking.
              </Typography>
            </div>
            <div className={classes.paragraph}>
              <Typography variant="h6" gutterBottom>
                COPPA (Children Online Privacy Protection Act)
              </Typography>
              <Typography variant="body2" gutterBottom>
                When it comes to the collection of personal information from children under 13, the Children's Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, the nation's consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children's privacy and safety online.
              </Typography>
              <Typography variant="body2" gutterBottom>
                We do not specifically market to children under the age of 13.
              </Typography>
            </div>
            <div className={classes.paragraph}>
              <Typography variant="h6" gutterBottom>
                Fair Information Practices
              </Typography>
              <Typography variant="body2" gutterBottom>
                The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information.
              </Typography>
            </div>
            <div className={classes.paragraph}>
              <Typography variant="h6" gutterBottom>
                In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:
              </Typography>
              <Typography variant="body2" gutterBottom>
                <ul>
                  <li>We will notify the users via email</li>
                  <li>We will notify the users via in site notification</li>
                  <ul>
                    <li>Within 7 business days</li>
                  </ul>
                </ul>
                We also agree to the individual redress principle, which requires that individuals have a right to pursue legally enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that individuals have recourse to courts or a government agency to investigate and/or prosecute non-compliance by data processors.
              </Typography>
            </div>
            <div className={classes.paragraph}>
              <Typography variant="h6" gutterBottom>CAN-SPAM Act</Typography>
              <Typography variant="body2" gutterBottom>
                The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations.
              </Typography>
            </div>
            <div className={classes.paragraph}>
              <Typography variant="h6" gutterBottom>
                We collect your email address in order to:
              </Typography>
              <Typography variant="body2" gutterBottom>
                We collect your email address to perform those functions as spelled out above in this document.
              </Typography>
            </div>
            <div className={classes.paragraph}>
              <Typography variant="h6" gutterBottom>
                To be in accordance with CAN-SPAM we agree to the following:
              </Typography>
              <Typography variant="body2" gutterBottom>
                We operate in accordance with the CAN-SPAM act in all manners.
              </Typography>
            </div>
            <div className={classes.paragraph}>
              <Typography variant="h6" gutterBottom>
                If at any time you would like to unsubscribe from receiving future emails, you can email us
              </Typography>
              <Typography variant="body2" gutterBottom>
                and we will promptly remove you from <strong>ALL</strong> correspondence.
              </Typography>
            </div>
          </Route>
          <Route path="/legal/terms">
            <Typography
              id="terms" variant="h4"
              component="h1" gutterBottom
            >
              Terms and Conditions
            </Typography>
            <Typography variant="body2" gutterBottom>
              EFFECTIVE DATE: February 9, 2016
            </Typography>
            <Typography variant="body2" gutterBottom>
              THE WEBSITE [ScoreShots.com] (THE “SITE”) AND RELATED PLATFORMS, PRODUCTS, AND SERVICES AVAILABLE ON OR FROM THE SITE (THE “SERVICES”) ARE OWNED AND OPERATED BY IMMERSION MEDIA, INC., d/b/a SCORESHOTS (TOGETHER WITH ITS AFFILIATES, SUCCESSORS, AND ASSIGNS HEREINAFTER REFERRED TO AS “<i>OUR</i>”, “<i>WE</i>,” OR “<i>US</i>”). BY ACCESSING AND USING THE SITE AND/OR THE SERVICES, YOU ACKNOWLEDGE AND AGREE TO BE BOUND BY THESE TERMS OF SERVICE (THE “<i>TERMS OF SERVICE</i>”). THESE TERMS OF SERVICE ARE A LEGALLY BINDING AGREEMENT. IF YOU USE THE SITE AND/OR THE SERVICES ACTING AS THE AGENT FOR A COMPANY, OR ENTITY, YOU REPRESENT THAT YOU HAVE THE LEGAL AUTHORITY TO BIND SUCH COMPANY OR ENTITY AND YOU GUARANTEE COMPLIANCE BY SUCH COMPANY OR ENTITY WITH THESE TERMS OF SERVICE. WE RESERVE THE SOLE RIGHT AT ANY TIME TO MODIFY, DISCONTINUE, OR TERMINATE ANY SERVICE OR THE SITE, OR CHANGE, ADD, OR DELETE PORTIONS OF THESE TERMS OF SERVICE WITHOUT NOTICE. WE WILL POST CHANGES TO THESE TERMS OF SERVICE, IF ANY, TO THE SITE AND UPDATE THE EFFECTIVE DATE SET FORTH ABOVE. IT IS YOUR RESPONSIBILITY TO CHECK THE SITE PERIODICALLY FOR CHANGES. YOUR CONTINUED USE OF THE SITE AND SERVICES AFTER ANY CHANGES CONSTITUTES YOUR ACCEPTANCE OF THE REVISED TERMS OF SERVICE. IF YOU DO NOT AGREE TO THESE TERMS OF SERVICE, DO NOT USE THE SITE OR SERVICES.
            </Typography>
            <Typography variant="body2" gutterBottom><Typography variant="h6" gutterBottom>1. REGISTRATION.</Typography>You represent that all information you provide during the registration process and at any time thereafter (“<i>Registration Information</i>”) will be true, accurate, complete, and current and that you will promptly update your Registration Information as necessary such that it is, at all times, true, accurate, complete, and current. We may use all Registration Information, subject to our compliance with our Privacy Policy. The Terms of Service incorporate the terms and conditions set forth in the Privacy Policy. By accessing the Site and using the Services, you are consenting to have your personal data used by us as set forth in the Privacy Policy. You alone are responsible for maintaining the security of your Registration Information and for all uses of the Site and Services in the name of your account. You are responsible for maintaining access to the email address associated with your account. </Typography>
            <Typography variant="body2" gutterBottom><Typography variant="h6" gutterBottom>2. AGE RESTRICTIONS.</Typography> You hereby represent and warrant that you are 18 years of age or older. The Site and Services are not intended for, or offered to, users under the age of 18. </Typography>
            <Typography variant="body2" gutterBottom><Typography variant="h6" gutterBottom>3. USE OF SITE CONTENT.</Typography> All content on the Site and Services that is not User Content (as defined in Section 4 below) (the “<i>Site Content</i>”), is the proprietary property of us or our licensors. Subject to the terms of the Terms of Service, no Site Content or User Content may be modified, copied, distributed, framed, reproduced, republished, downloaded, displayed, posted, transmitted, or sold in any form or by any means, in whole or in part, without the prior written permission of us or the owner of such content. Except as otherwise set forth in these Terms of Service, any unauthorized use of the Site Content is strictly prohibited. All trademarks, logos, trade dress and service marks on the Site are trademarks or registered trademarks of us or our licensors and may not be copied, imitated, or used, in whole or in part, without the prior written permission of us or the owner of such content.</Typography>
            <Typography variant="body2" gutterBottom><Typography variant="h6" gutterBottom>4. PUBLISHING OF CONTENT.</Typography> You hereby acknowledge and agree that you are solely responsible for all materials that you approve for publishing, display, and distribution by us in connection with the Services, or that you post, publish or distribute in connection with the Services, including without limitation, information, data, text, software, links, photographs, pictures, graphics, video, messages, files and any other materials (“<i>User Content</i>”). You represent, warrant and agree that no User Content that submitted by you or through your account, will: </Typography>
            <Typography variant="body2" gutterBottom>
              <ol>
                <li>violate or infringe upon the rights of any third party, including copyright, trademark, privacy, publicity or other personal or proprietary rights;</li>
                <li>contain libelous or defamatory material; or</li>
                <li>violate or encourage violation of any applicable laws, rules or regulations.</li>
              </ol>
            </Typography>
            <Typography variant="body2" gutterBottom>You represent and warrant that you have all legal rights necessary to publish and distribute any User Content or that you own such User Content. You hereby acknowledge and agree that we shall, at all times, possess the right to cause the removal of any or all of your User Content that you may publish on the Site for any reason and at our sole discretion. You hereby acknowledge and agree that we may, at our sole discretion, disclose your User Content in order to: (i) comply with law enforcement, court orders, or the legal process; (ii) protect the rights and safety of individuals; and/or (iii) settle disputes over intellectual property ownership.</Typography>
            <Typography variant="body2" gutterBottom><Typography variant="h6" gutterBottom>5. DMCA COPYRIGHT NOTICE AND REPEAT INFRINGER POLICY.</Typography> We own, protect and enforce copyrights in our own creative material and respect the copyright properties of others. Materials may be made available on the Site, or through the Site or Services, by third parties not within our control. It is our policy not to permit materials known by us to be infringing to remain on the Site or Services. You should notify us promptly if you believe any materials on the Site or published or distributed by us infringe a third party copyright. Upon our receipt of a proper notice of claimed infringement under the Digital Millennium Copyright Act (“DMCA”), we will respond expeditiously to remove, or disable access to, the material claimed to be infringing and will follow the procedures specified in the DMCA to resolve the claim between the notifying party and the alleged infringer who provided the content in issue. Pursuant to the DMCA, written notification of claimed copyright infringement must be submitted in writing to the Designated Agent for this Site. Contact information for the Designated Agent may be found at the <a href="http://www.copyright.gov/onlinesp/list/r_agents.html">U.S. Copyright Office’s Directory of Service Provider Agents for Notification of Claims of Infringement</a>.</Typography>
            <Typography variant="body2" gutterBottom>Please be advised that we may provide an alleged infringer with any notice of claimed infringement, including notices of claimed infringement submitted by you under the DMCA, and any communications related thereto. We may also provide any counter notifications received under the DMCA, and communication related thereto, to the provider of the original notice of claimed infringement. All notices and communications provided by you to us become our property and you hereby grant us the right to provide such notices and communications to any third party. </Typography>
            <Typography variant="body2" gutterBottom>If you engage in “repeat infringement” we may immediately terminate your registration and account without notice, and you will no longer be permitted access to the Site or the Services. “<i>Repeat infringement</i>” shall be defined as two (2) or more instances, as determined by us in our reasonable discretion, where you have infringed the copyright rights of another person. </Typography>
            <Typography variant="body2" gutterBottom>In the event a user’s materials are removed due to a DMCA notice and then subsequently restored in accordance with the DMCA, we will treat the underlying DMCA notice as withdrawn. </Typography>
            <Typography variant="body2" gutterBottom>We reserve the right to terminate Site accounts that are the subject of fewer than two (2) instances of infringement of the copyright rights of another person in appropriate circumstances, such as when the user has a history of violating or willfully disregarding the Terms of Service.</Typography>
            <Typography variant="body2" gutterBottom><Typography variant="h6" gutterBottom>6. LINKS TO OTHER WEB SITES.</Typography> The Site may contain links to other web sites. We are not responsible for the content, accuracy or opinions expressed on such web sites, and such web sites are not investigated, monitored or checked for accuracy or completeness by us. Inclusion of any linked web site on or through the Site or the Services does not imply approval or endorsement of the linked web site by us. If you decide to leave the Site and access these third-party sites, you do so at your own risk.</Typography>
            <Typography variant="body2" gutterBottom><Typography variant="h6" gutterBottom>7. SERVICE FEES.</Typography> For use of certain Services, you are required to pay applicable fees which may change from time to time subject only to your right to terminate such Services in accordance with the terms thereof. Fees vary by Service. Current fee schedules are available on the Site. You are responsible for payment of all applicable fees incurred by your Site account. All fees due from you to us hereunder, including to the extent applicable, quarterly subscription fees, annual subscription fees, one-time product purchase fees, recurring fees, quarterly and annual renewal fees, third-party fees, applicable taxes, and charge-back fees are referred to herein as “<i>Service Fees</i>”. At this time, we do not collect a sales and or use tax on the sale of Services to you. If any tax is to be paid to state or local governments by a purchaser of our Services, it is your responsibility to report and make such tax payments in compliance with all applicable laws. In the event that we collect any taxes on the sale and or use of Services, such taxes will be itemized separately on your invoice.   </Typography>
            <Typography variant="body2" gutterBottom>You agree that we may charge all Service Fees to the payment method (e.g., credit card, PayPal account, debit card, etc.) you have provided in your Registration Information. You are responsible for the timely payment of all Service Fees at the time the Services are ordered by you. It is your responsibility to notify us if your payment method has changed by making the appropriate changes to your account settings. If you do not provide a valid payment method, or if your designated payment method is determined by us to be inactive for any reason, we may immediately discontinue providing Services to you. You agree not to cause your credit or debit card company to reverse or “chargeback” any Service Fees charged in accordance with these Terms of Service; and in the event you do so, we may terminate your use of the Site and Services, and you agree to reimburse us for any costs incurred in responding to such chargeback, including, without limitation, our actual costs paid to the credit or debit card company, including chargeback fees, and the value of the time our employees spend on the matter as determined in our discretion in good faith. </Typography>
            <Typography variant="body2" gutterBottom>All fees relate to the specific license you have purchased or been granted. A license granted for one category of service (e.g., a particular sport) may not be utilized to publish a ScoreShot that falls into a different category (e.g., a different sport) or license area.  In such a case, we reserve the right to modify, restrict or cancel such license.</Typography>
            <Typography variant="body2" gutterBottom><Typography variant="h6" gutterBottom>8. REFUND POLICY.</Typography> We want you to be satisfied with the Services. However, <span class="emphasize">we do not offer refunds for the Services once you have purchased them.</span> However, in two limited situations we may provide a refund: </Typography>
            <Typography variant="body2" gutterBottom>
              <ol>
                <li><span class="emphasize">Product malfunction.</span> In order to be considered a “product malfunction” the user must report the malfunction to [<a href="sssupport@imsports.com">sssupport@imsports.com</a>] while the Services are still active, <span class="emphasize">and</span> the product malfunction must be verified by us. The final determination as to whether a report qualifies as a product malfunction is at our sole and absolute discretion. </li>
                <li><span class="emphasize">Billing error.</span> In order to be considered a “billing error” the user must provide billing documentation and evidence showing that the credit card charge from us was in error.</li>
              </ol>
            </Typography>
            <Typography variant="body2" gutterBottom><b>If you elect to cancel the Services early, we do not provide a refund for the remainder of the billing period for the canceled Service.</b>
            </Typography>
            <div className={classes.paragraph}><Typography variant="h6" gutterBottom>9. TERMINATION.</Typography> </div>
            <Typography variant="body2" gutterBottom>
              <ol>
                <li>You may terminate your use of the Site at any time. You may terminate these Terms of Service with respect to any Service provided you have paid all applicable Service Fees by effecting a termination from your account. </li>
                <li>We may cancel your user registration and terminate your use of the Site or Services at any time with or without cause, in our sole discretion, and with or without notice, including for your failure to make any payment when due or your breach of your representations, warranties, and covenants in these Terms of Service; provided, however, that we have no obligation to monitor your use of the Site or Services. </li>
                <li>We may delete any of your User Content, information, or other data from the Site or Services at any time, and we have no obligation to maintain copies of any deleted information. </li>
                <li>Any provisions of these Terms of Service that by their nature should continue after termination of your use of the Site or any Services will continue to apply even after the expiration or termination of these Terms of Service or your use of the Site or any Services. </li>
              </ol>
            </Typography>
            <Typography variant="body2" gutterBottom><Typography variant="h6" gutterBottom>10. LIMITATION OF LIABILITY.</Typography> IN NO EVENT WILL WE BE LIABLE TO YOU OR ANY THIRD PERSON FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING DAMAGES FOR ANY LOST PROFITS OR LOST DATA ARISING FROM YOUR USE OF THE SITE OR THE SERVICES, EVEN IF WE ARE AWARE OR HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ANY WARRANTIES FOR THIRD PARTY SERVICES OR GOODS RECEIVED THROUGH OR ADVERTISED ON THE SITE OR THE SITES OR SERVICE, OR ACCESSED THROUGH ANY LINKS ON THE SITE. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE LESSER OF: (A) THE AMOUNT PAID, IF ANY, BY YOU TO US FOR THE SERVICES; AND (B) TEN DOLLARS ($10). CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.</Typography>
            <Typography variant="body2" gutterBottom><Typography variant="h6" gutterBottom>11. INDEMNITY.</Typography> You agree to indemnify and hold us and our directors, officers, agents, contractors, affiliates, partners and employees, harmless from and against any loss, liability, claim, or demand, including reasonable attorneys’ fees, arising out of any claim, action, investigation or proceeding made or instituted by any third party due to or arising out of:</Typography>
            <Typography variant="body2" gutterBottom>
              <ol>
                <li>your breach of any representations or warranties made by you hereunder or your breach of the Terms of Service;</li>
                <li>your use of the Services or the Site in violation of the Terms of Service; or</li>
                <li>your violation of any law or the rights of a third party.</li>
              </ol>
            </Typography>
            <Typography variant="body2" gutterBottom>You hereby agree not to sue, assist in or be a voluntary party to assist in or be a voluntary party to, except as required by law, any action, suit, or proceeding against us for any claims, actions, suits, damages, liability, losses or expenses of whatever kind or however arising out of or relating to your use of the Site or the Services.</Typography>
            <Typography variant="body2" gutterBottom><Typography variant="h6" gutterBottom>12. DISCLAIMER OF WARRANTIES.</Typography> We are not responsible for any incorrect or inaccurate content published on the Site or in connection with the Services, including User Content published by users (or published by us on behalf of users) of the Site or the Services. We are not responsible for the conduct, whether online or offline, of any user of the Site. The Site and the Services may be temporarily unavailable from time to time for maintenance or other reasons. We assume no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of, the Site or the Services. We are not responsible for any problems or technical malfunction of any telephone network or lines, computer online systems, servers or providers, computer equipment or software, or the failure of email on account of technical problems or traffic congestion on the Internet or at any web site, including injury or damage to any person’s computer related to or resulting from participating or downloading materials in connection with the Site or the Services. Under no circumstances will we be responsible for any loss or damage, including personal injury or death, resulting from anyone’s use of the Services, the Site or any content published on the Site (whether or not Site Content, User Content, or other content). THE SITE, THE SERVICES AND ALL CONTENT ON THE WEBSITE ARE PROVIDED “AS-IS” AND WE DISCLAIM ANY AND ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON- INFRINGEMENT. WE CANNOT GUARANTEE AND DO NOT PROMISE ANY SPECIFIC RESULTS FROM USE OF THE SITE AND/OR THE SERVICES. </Typography>
            <Typography variant="body2" gutterBottom><Typography variant="h6" gutterBottom>13. GOVERNING LAW.</Typography> The Terms of Service shall be governed by and construed in accordance with the laws of the State of North Carolina, U.S.A., without reference to conflicts of laws provisions and, as to matters affecting copyrights, trademarks and patents, by U.S. federal law. Any dispute or claim arising out of, or in connection with, the Terms of Service shall be finally settled by binding arbitration in Raleigh, North Carolina, in accordance with N.C. Gen. Stat. § 1-567.1 et seq. (the “(<i>Uniform Arbitration Act</i>”) and the then-current rules and procedures of the American Arbitration Association by one (1) arbitrator appointed by the American Arbitration Association. The arbitrator shall apply the law of the State of North Carolina, without reference to rules of conflict of law or statutory rules of arbitration, to the merits of any dispute or claim. Judgment on the award rendered by the arbitrator may be confirmed, reduced to judgment and entered in any court of competent jurisdiction. You agree that, any provision of applicable law notwithstanding, the arbitrator shall have the authority to award the prevailing party its costs and reasonable attorneys’ fees. In the event that the above arbitration provision is held invalid or unenforceable, then any dispute with respect to the Terms of Service shall be brought and heard either in the North Carolina state courts located in Wake County, North Carolina, or the federal district court for the Eastern District of North Carolina located in Raleigh, North Carolina. In such event, you consent to the in personam jurisdiction and venue of such courts. You agree that service of process upon you in any such action may be made if delivered in person, by courier service, by telefacsimile or by first class mail, and shall be deemed effectively given upon receipt. </Typography>
            <Typography variant="body2" gutterBottom><Typography variant="h6" gutterBottom>14. MISCELLANEOUS.</Typography> The Terms of Service set forth the entire agreement between you and us pertaining to your use of the Site and the Services. If any provision of this Agreement is held invalid or unenforceable, such provision shall be revised to the extent necessary to cure the invalidity or unenforceability, and the remainder of this Agreement shall continue in full force and effect. Our failure to exercise any right or provision of this Agreement shall not constitute a waiver of such right or provision. Nothing in these Terms of Service shall be deemed to confer any third-party rights or benefits. These Terms of Service, and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by us without restriction. Our delay or failure to exercise any right or provision of these Terms of Service shall not constitute a waiver of such right or provision. These Terms of Service do not, and shall not be deemed to, constitute a partnership or joint venture between you and us, and neither you nor we, nor your or our respective directors, officers, employees, or agents shall, by virtue of the performance of the obligations under these Terms of Service, be deemed to be an agent or employee of the other, and neither you nor we has the authority to bind the other under any contract, agreement, or otherwise. </Typography>
          </Route>
        </Switch>
        <Typography variant="h6" gutterBottom>
          Contacting Us
        </Typography>
        <Typography variant="body2" gutterBottom>
          If there are any questions regarding this privacy policy you may
          contact us using the information below.
        </Typography>
        <Typography variant="body2">www.scoreshots.com</Typography>
        <Typography variant="body2">1922 Ft. Bragg Road</Typography>
        <Typography variant="body2">Fayetteville, NC 28303</Typography>
        <Typography variant="body2">USA</Typography>
        <Typography variant="body2">rickp@imsports.com</Typography>
      </Paper>
    </ResponsiveGrid>
    <ReactComponent className={classes.curve} alt="Contact Information"/>
  </div>
));
