import React, { Component } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Lottie from 'react-lottie';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import S3 from 'aws-sdk/clients/s3';
import FullscreenLoader from '../loading/FullscreenLoader';
import Controls from './Controls';

const GET_CLIENT = gql`
  query getFullMotionTemplate(
    $id: ID!
  ) {
    me {
      id
      firstname
      lastname
      email
      client {
        id
        color1
        color2
        color3
        palette
      }
    }
    myFonts {
      id
      fullName
      fontFamily
      type
      bucket
      key
    }
    fullMotionTemplate (
      id: $id
    ) {
      id
      schemaURL
    }
  }
`;


const styles = theme => ({
  lottie: {
    padding: theme.spacing(2),
  },
  lottieContainer: {
    paddingLeft: 220,
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
  },
  drawer: {
    width: 200,
  },
});

class FullMotionEditor extends Component {
  constructor() {
    super();
    this.s3 = new S3({
      accessKeyId: localStorage.getItem('aws_id'),
      secretAccessKey: localStorage.getItem('aws_secret'),
      region: 'us-east-2',
    });
    this.state = {
      variant: 0,
    };
  }

  componentDidMount() {
    this.loadSchema();
  }

  handleVariableChange = variableName => (e) => {
    const variables = { ...this.state.variables };
    if (variables[variableName]) {
      variables[variableName].value = e.target.value;
    }
    this.setState({
      variables,
    });
  }

  processLottie = (lottie) => {
    const { variables } = this.state;
    const lottieCopy = { ...lottie };

    lottieCopy.assets.forEach((asset) => {
      if (asset.layers) {
        asset.layers.forEach((layer) => {
          if (layer.nm) {
            const match = layer.nm.match(/{(.+)}/);
            if (match && variables[match[1]] && variables[match[1]].value) {
              const variable = variables[match[1]];
              switch (variable.type) {
                case 'number':
                case 'text':
                  layer.t.d.k[0].s.t = variables[match[1]].value;
                  break;
                default:
                  break;
              }
            }
          }
        });
      }
    });

    /*
    Object.keys(variables).forEach((variableName) => {
      let variable = variables[variableName];
    })
    */

    return (
      lottieCopy
    );
  }

  loadSchema() {
    fetch('https://s3.us-east-2.amazonaws.com/ss3-templates/fullmotion/test.json')
    // fetch(this.props.template.schemaURL)
      .then(response => response.json())
      .then((json) => {
        this.loadVariants(json);
      });
  }

  async loadVariants(schema) {
    const fetches = [];
    for (let i = 0; i < schema.variants.length; i += 1) {
      const variant = schema.variants[i];
      const request = fetch(variant.url)
        .then(response => response.json())
        .then((json) => {
          variant.json = json;
        });
      fetches.push(request);
    }
    await Promise.all(fetches);
    this.setState(schema);
  }


  render() {
    const { classes } = this.props;
    const { variants, variant } = this.state;
    let defaultOptions;
    if (variants && variants[variant]) {
      defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: this.processLottie(variants[variant].json),
        rendererSettings: {},
      };
    }
    return (
      <React.Fragment>
        <div className={classes.lottieContainer}>
          {
            defaultOptions ?
              <React.Fragment>
                <Controls
                  {...this.state}
                  classes={classes}
                  handleVariableChange={this.handleVariableChange}
                />
                <Lottie
                  className={classes.lottie}
                  options={defaultOptions}
                  style={{
                    flexBasis: '85%',
                  }}
                />
              </React.Fragment> :
            null
          }
        </div>
      </React.Fragment>
    );
  }
}
const StyledFullMotion = (withStyles(styles, { withTheme: true })(FullMotionEditor));

export default props => (
  <Query
    query={GET_CLIENT}
    variables={{
      id: props.match.params.id,
    }}
  >
    {({ data }) => {
      const userTheme = {
        typography: {
          fontFamily: 'Ubuntu,Roboto,sans-serif',
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 960,
            md: 1280,
            lg: 1440,
            xl: 1920,
          },
        },
      };
      let component = <FullscreenLoader />;
      if (data.me) {
        userTheme.palette = {
          primary: {
            main: data.me.client.color1,
          },
          secondary: {
            main: data.me.client.color2,
          },
        };
        component = (
          <StyledFullMotion
            template={data.fullMotionTemplate}
            data={data}
            {...props}
          />
        );
      }
      const theme = createMuiTheme(userTheme);
      return (
        <MuiThemeProvider theme={theme}>
          {component}
        </MuiThemeProvider>
      );
    }}
  </Query>
);
