export default {
  fireworks: {
    id: 'fireworks',
    name: 'Fireworks',
    size: {
      width: 1080,
      height: 808,
    },
    url: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/fireworks.mp4',
    preview: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/fireworks_preview.mp4',
    full: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/fireworks_full.mov',
    still: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/fireworks.jpg',
  },
  smoke_ground: {
    id: 'smoke_ground',
    name: 'Smoke',
    size: {
      width: 360,
      height: 404,
    },
    url: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/smoke.mp4',
    preview: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/smoke_preview.mp4',
    full: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/smoke_full.mov',
    still: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/smoke.jpg',
    alphaOnly: true,
  },
  sparks_embers: {
    id: 'sparks_embers',
    name: 'Sparks or Embers',
    size: {
      width: 360,
      height: 404,
    },
    url: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/sparks_preview_stacked.mp4',
    preview: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/sparks_preview.mp4',
    full: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/sparks.mov',
    still: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/sparks.jpg'
  },
  glitch_one: {
    id: 'glitch_one',
    name: 'Glitch Effect',
    size: {
      width: 360,
      height: 404,
    },
    url: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/glitch_stacked.mp4',
    preview: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/glitch_preview.mp4',
    full: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/glitch.mov',
    still: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/glitch.jpg'
  },
  fire_flames: {
    id: 'fire_flames',
    name: 'Fire or Flames',
    size: {
      width: 360,
      height: 404,
    },
    url: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/flames.mp4',
    preview: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/flames_preview.mp4',
    full: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/flames_full.mov',
    still: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/flames.jpg',
  },
  lightning: {
    id: 'lightning',
    name: 'Lightning',
    size: {
      width: 360,
      height: 404,
    },
    url: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/lightning_stacked.mp4',
    preview: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/lightning_preview.mp4',
    full: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/lightning_full.mov',
    still: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/lightning.jpg',
  },
  glass: {
    id: 'glass',
    name: 'Glass',
    size: {
      width: 360,
      height: 404,
    },
    url: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/glass_stacked.mp4',
    preview: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/glass_preview.mp4',
    full: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/glass_full.mov',
    still: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/glass.jpg'
  },
  confetti: {
    id: 'confetti',
    name: 'Confetti',
    size: {
      width: 360,
      height: 404,
    },
    url: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/confetti_stacked.mp4',
    preview: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/confetti_preview.mp4',
    full: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/confetti_full.mov',
    still: 'https://s3.us-east-2.amazonaws.com/ss3-video/effects/confetti.jpg'
  }
};
