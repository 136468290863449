import React, { Component } from 'react';
import WebFont from 'webfontloader';
import Helmet from 'react-helmet';
import FullscreenLoader from '../loading/FullscreenLoader';

const parseFonts = (fonts) => {
  const output = {
    google: [],
    custom: [],
  };
  if (fonts) {
    fonts.forEach((font) => {
      if (font.type === 'WEBFONT') {
        output.google.push(font.fontFamily);
      } else {
        output.custom.push(font);
      }
    });
  }
  return output;
};

const getFontFace = (fonts) => {
  let output = '';
  const typeDict = {
    REGULAR: {
      weight: 400,
      style: 'normal',
    },
    BOLD: {
      weight: 700,
      style: 'normal',
    },
    ITALIC: {
      weight: 400,
      style: 'italic',
    },
    BOLDITALIC: {
      weight: 700,
      style: 'italic',
    },
  };
  if (fonts) {
    fonts.forEach((font) => {
      output += `
        @font-face {
          font-family: "${font.fontFamily.replace('"', '\\"')}";
          src: url('https://${font.bucket}.s3.us-east-2.amazonaws.com/${font.key}');
          font-weight: ${typeDict[font.type].weight};
          font-style: ${typeDict[font.type].style};
        }
      `;
    });
  }
  return output;
};

class FontLoader extends Component {
  state = {
    firstLoad: true,
    loading: true,
  }
  componentDidMount() {
    this.loadFonts(this.props.fonts);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.fonts !== this.props.fonts) {
      this.loadFonts(this.props.fonts);
    }
  }

  loadFonts = (fonts) => {
    const parsedFonts = parseFonts(fonts);
    const webFontSettings = {
      loading: this.handleFontLoad(true),
      active: this.handleFontLoad(false),
    };
    if (parsedFonts.custom.length > 0) {
      const families = {};
      parsedFonts.custom.forEach((font) => {
        if (families[font.fontFamily]) {
          families[font.fontFamily].push(font);
        } else {
          families[font.fontFamily] = [font];
        }
      });
      const familyArray = [];
      Object.keys(families).forEach((family) => {
        const fontVariants = families[family];
        let output = `${family}:`;
        fontVariants.forEach((font) => {
          let fvd = '';
          switch (font.type) {
            case 'REGULAR':
              fvd = 'n4';
              break;
            case 'ITALIC':
              fvd = 'i4';
              break;
            case 'BOLD':
              fvd = 'n7';
              break;
            case 'BOLDITALIC':
              fvd = 'i7';
              break;
            default:
              break;
          }
          output += `${fvd},`;
        });
        familyArray.push(output);
      });
      webFontSettings.custom = {
        families: familyArray,
      };
    }

    if (parsedFonts.google.length > 0) {
      webFontSettings.google = {
        families: parsedFonts.google.map(font => (`${font}:n,b,i,bi`)),
      };
    }
    if (parsedFonts.custom.length > 0 || parsedFonts.google.length > 0) {
      WebFont.load(webFontSettings);
    } else {
      this.setState({
        firstLoad: false,
        loading: false,
      });
    }
  }

  handleFontLoad = loading => () => {
    const state = {
      loading,
    };
    if (!loading) {
      state.firstLoad = false;
    }
    this.setState(state);
  }

  render() {
    const customFonts = parseFonts(this.props.fonts).custom;
    let component = this.props.children;
    if (this.state.loading && this.state.firstLoad) {
      component = <FullscreenLoader />;
    }
    return (
      <React.Fragment>
        <Helmet>
          <style type="text/css">
            {getFontFace(customFonts)}
          </style>
        </Helmet>
        {component}
      </React.Fragment>
    );
  }
}

export default FontLoader;
