import React from 'react';
import Button from '@material-ui/core/Button';

export default ({
  hidePrev,
  hideNext,
  disablePrev,
  disableNext,
  customNextText,
  onPrev,
  onNext,
}) => (
  <div style={{ marginTop: 18 }}>
    {
      hidePrev ? null :
      <Button
        variant="contained"
        color="primary"
        disabled={disablePrev}
        onClick={onPrev}
        style={{marginRight:10}}
      >
        Back
      </Button>
    }
    {
      hideNext ? null :
      <Button
        disabled={disableNext}
        variant="contained"
        color="primary"
        onClick={onNext}
      >
        {customNextText || 'Next'}
      </Button>
    }
  </div>
);
