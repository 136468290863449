
// -------------------------------------------------------------------------- //

export const META_DATA_KEY = 'scoreshots';

// -------------------------------------------------------------------------- //

export function hasMetaData(object, key = null) {
  if (!object || !(META_DATA_KEY in object)) {
    return false;
  }

  return (key === null || (key in object[META_DATA_KEY]));
}

// -------------------------------------------------------------------------- //

export function clearMetaData(object, key = null) {
  if (!object || !(META_DATA_KEY in object)) {
    return;
  }

  if (key !== null) {
    delete object[META_DATA_KEY][key];
  } else {
    delete object[META_DATA_KEY];
  }
}

// -------------------------------------------------------------------------- //

export function getMetaData(object, key, value = null) {
  if (!object) {
    return value;
  }

  if (!(META_DATA_KEY in object)) {
    return value;
  }

  if (!(key in object[META_DATA_KEY])) {
    return value;
  }

  return object[META_DATA_KEY][key];
}

// -------------------------------------------------------------------------- //

export function setMetaData(object, key, value) {
  if (!object) {
    if (this.canvas === null) {
      return false;
    }

    object = this.canvas;
  }

  if (!(META_DATA_KEY in object)) {
    object[META_DATA_KEY] = { };
  }

  object[META_DATA_KEY][key] = value;
  return true;
}

// -------------------------------------------------------------------------- //
