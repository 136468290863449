
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Button,
  Checkbox,
  CircularProgress, Collapse, FormControl,
  Grid,
  Hidden, IconButton, InputLabel, ListItemText, MenuItem, Paper, Select, Tooltip,
  Typography,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';

import { compose, graphql, Query } from 'react-apollo';
import gql from 'graphql-tag';
import InfiniteScroll from 'react-infinite-scroller';

import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { FilterVariant as FilterVariantIcon } from 'mdi-material-ui';
import Post from './Post';

// -------------------------------------------------------------------------- //

const GQL_SPORTS_CATEGORIES = gql`
  query {
    types(orderBy: index_ASC) {
      id
      name
    }
    categories {
      id
      name
    }
  }
`;

const GET_USER_INFO = gql`
  query getUserInfo {
    me {
      id
      client {
        id
        subscription_start
      }
    }
  }
`;

const GET_POSTS = gql`
  query getPosts($where: PostWhereInput, $count: Int!, $after: String, $mode: String) {
    getPosts(
      where: $where
      count: $count
      after: $after
      mode: $mode
    ) {
      id
      width
      height
      url
      media
      postTime
      template {
        id
        isQuickCreate
      }
      client {
        id
        name
        logoBucket
        logoKey
      }
    }
  }
`;

const styles = (theme) => ({
  main: {
    marginTop: theme.spacing(1),
  },
  filter_paper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  form_control: {
    marginBottom: theme.spacing(2),
    width: '48%',
    marginLeft: '1%',
    marginRight: '1%',
  },
  form_label: {
    marginTop: theme.spacing(1),
    width: '48%',
    marginLeft: '1%',
    marginRight: '1%',
    display: 'inline-block',
    fontSize: 11,
  },
  button: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: '100%', // because we can't use fullWidth on the ButtonGroup
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      transform: 'translate3D(0,0,0)',
      width: 236,
    },
  },
};

// -------------------------------------------------------------------------- //

function CreateMap(items, selector) {
  const map = {};

  for (let i = 0; i < items.length; ++i) {
    map[selector(items[i])] = items[i];
  }

  return map;
}


class PostsFeed extends React.Component {
  state = {
    sports: [],
    sports_map: {},
    sport_selected: null,
    sports_loading: false,
    categories: [],
    categories_map: {},
    categories_selected: [],
    categories_loading: false,
    date: Date.now(),
    date_sub: null,
    filter_open: false,
    new_search: false,
  }


  componentDidMount() {
    const { gql_sports_categories, data } = this.props;

    if (data && data.me && data.me.client && data.me.client.subscription_start) {
      this.setState({
        date_sub: new Date(data.me.client.subscription_start).toISOString(),
      });
    }

    if (gql_sports_categories) {
      this.setState({
        sports_loading: true,
        categories_loading: true,
      }, () => {
        gql_sports_categories
          .refetch()
          .then((response) => {
            const state = {
              sports_loading: false,
              categories_loading: false,
            };
            if (!response.error && response.data) {
              state.sports = [...response.data.categories].sort((lhs, rhs) => {
                if (lhs.name < rhs.name) {
                  return -1;
                } if (lhs.name === rhs.name) {
                  return 0;
                }
                return 1;
              });
              state.categories = [...response.data.types];
              state.sports_map = CreateMap(
                state.sports, ((sport) => sport.id),
              );
              state.categories_map = CreateMap(
                state.categories, ((category) => category.id),
              );
            }
            this.setState(state);
          });
      });
    }
  }

  clearFilters = () => {
    const { data } = this.props;
    this.setState({
      date: Date.now(),
      date_sub: new Date(data.me.client.subscription_start).toISOString(),
      categories_selected: [],
      sport_selected: null,
    });
  };

  handleDateChangeStart = (date_sub) => {
    this.setState({ date_sub, new_search: true });
  };

  handleDateChangeEnd = (date) => {
    this.setState({ date, new_search: true });
  };

  toggleFilter = () => {
    this.setState({ filter_open: !this.state.filter_open });
  }


  infiniteLoad = (fetchMore, after, where) => () => {
    fetchMore({
      variables: {
        where,
        mode: this.props.mode,
        count: 8,
        after,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return { ...prev, getPosts: [...prev.getPosts, ...fetchMoreResult.getPosts] };
      },
    });
  }

  getPosts(data, fetchMore, where) {
    const { classes } = this.props;
    const output = [];
    const {
      sports,
      sports_loading,
      sport_selected,
      categories,
      categories_map,
      categories_loading,
      categories_selected,
      date_sub,
      date,
    } = this.state;

    if (this.state.new_search === true) {
      this.setState({ new_search: false });
      data = null;
    }
    if (date_sub && this.state.new_search === false && data && data.getPosts && data.getPosts.length > 0) {
      let i = 0;
      data.getPosts.forEach((post) => {
        output.push([
          <Hidden xsDown key={`${i}SpacerL`}>
            <Grid item md={1} sm={1} />
          </Hidden>,
          <Grid item md={7} sm={9} xs={12} key={`${i}Main`}>
            <Post post={post} />
          </Grid>,
          <Hidden xsDown key={`${i}SpacerR`}>
            <Grid item md={4} sm={2} />
          </Hidden>,
        ]);
        i += 1;
      });
      const after = data.getPosts[data.getPosts.length - 1].id;

      return (
        <InfiniteScroll
          pageStart={0}
          loadMore={this.infiniteLoad(fetchMore, after, where)}
          initialLoad={false}
          hasMore
        >
          <Grid container spacing={2}>
            <Grid item md={7} sm={9} xs={12} key={`${i}Main`}>
              <Paper className={classes.filter_paper}>
                <div style={{ width: '100%', textAlign: 'right' }}>
                  <Tooltip id="tooltip-filter" title="Filter Posts" style={{ padding: 6 }}>
                    <IconButton onClick={() => this.toggleFilter()}>
                      <FilterVariantIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                <Collapse in={this.state.filter_open}>
                  <div>
                    <InputLabel htmlFor="start-date" className={classes.form_label}>Start Date</InputLabel>
                    <InputLabel htmlFor="end-date" className={classes.form_label}>End Date</InputLabel>
                  </div>
                  <FormControl className={classes.form_control}>
                    <DatePicker
                      format="MMM d yyyy"
                      autoOk
                      value={date_sub}
                      onChange={this.handleDateChangeStart}
                      animateYearScrolling={false}
                      inputProps={{ id: 'start-date' }}
                    />
                  </FormControl>
                  <FormControl className={classes.form_control}>
                    <DatePicker
                      format="MMM d yyyy"
                      autoOk
                      value={date}
                      onChange={this.handleDateChangeEnd}
                      animateYearScrolling={false}
                      inputProps={{ id: 'end-date' }}
                    />
                  </FormControl>
                  <FormControl className={classes.form_control}>
                    <InputLabel htmlFor="sport-input">Sport</InputLabel>
                    <Select
                      MenuProps={MenuProps}
                      value={sport_selected || ''}
                      onChange={(e) => {
                        this.setFilters({ sport_selected: e.target.value });
                      }}
                      inputProps={{ id: 'sport-input' }}
                    >
                      <MenuItem value="">
                        <em>All Sports</em>
                      </MenuItem>
                      {
                          sports.filter(
                            (sport) => sport.name !== 'Family Packs',
                          ).map(
                            (sport) => (
                              <MenuItem key={sport.id} value={sport.id}>
                                {sport.name}
                              </MenuItem>
                            ),
                          )
                        }
                      {
                          (sports_loading)
                          && (
                          <div className={classes.loading}>
                            <CircularProgress size={48} />
                          </div>
                          )
                        }
                    </Select>
                  </FormControl>
                  <FormControl className={classes.form_control}>
                    <InputLabel htmlFor="category-input">Categories</InputLabel>
                    <Select
                      multiple
                      MenuProps={MenuProps}
                      value={categories_selected}
                      onChange={(e) => this.setFilters({ categories_selected: e.target.value })}
                      inputProps={{ id: 'category-input' }}
                      renderValue={
                            (selected) => selected.map((type) => (
                              categories_map[type]
                                && categories_map[type].name
                            )).join(', ')
                          }
                    >
                      {categories.map(({ id, name }) => (
                        <MenuItem dense key={id} value={id}>
                            <Checkbox
                              color="primary"
                              checked={
                                    categories_selected.indexOf(id) >= 0
                                  }
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                      ))}
                      {
                          (categories_loading)
                          && (
                          <div className={classes.loading}>
                            <CircularProgress size={48} />
                          </div>
                          )
                        }
                    </Select>
                  </FormControl>
                  <Button
                    color="primary"
                    className={classes.button}
                    onClick={() => this.clearFilters()}
                  >
                      Clear Filters
                  </Button>
                </Collapse>
              </Paper>
            </Grid>
            {output}
          </Grid>
        </InfiniteScroll>
      );
    } if (data && data.getPosts && data.getPosts.length === 0) {
      return (
        <Grid container spacing={2}>
          <Grid item md={7} sm={9} xs={12} key="Main">
            <Paper className={classes.filter_paper}>
              <div style={{ width: '100%', textAlign: 'right' }}>
                <Tooltip id="tooltip-filter" title="Filter Posts" style={{ padding: 6 }}>
                  <IconButton onClick={() => this.toggleFilter()}>
                    <FilterVariantIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <Collapse in={this.state.filter_open}>
                <div>
                  <InputLabel htmlFor="start-date" className={classes.form_label}>Start Date</InputLabel>
                  <InputLabel htmlFor="end-date" className={classes.form_label}>End Date</InputLabel>
                </div>
                <FormControl className={classes.form_control}>
                  <DatePicker
                    format="MMM d yyyy"
                    autoOk
                    value={date_sub}
                    onChange={this.handleDateChangeStart}
                    animateYearScrolling={false}
                    inputProps={{ id: 'start-date' }}
                  />
                </FormControl>
                <FormControl className={classes.form_control}>
                  <DatePicker
                    format="MMM d yyyy"
                    autoOk
                    value={date}
                    onChange={this.handleDateChangeEnd}
                    animateYearScrolling={false}
                    inputProps={{ id: 'end-date' }}
                  />
                </FormControl>
                <FormControl className={classes.form_control}>
                  <InputLabel htmlFor="sport-input">Sport</InputLabel>
                  <Select
                    MenuProps={MenuProps}
                    value={sport_selected || ''}
                    onChange={(e) => {
                      this.setFilters({ sport_selected: e.target.value });
                    }}
                    inputProps={{ id: 'sport-input' }}
                  >
                    <MenuItem value="">
                      <em>All Sports</em>
                    </MenuItem>
                    {
                        sports.filter(
                          (sport) => sport.name !== 'Family Packs',
                        ).map(
                          (sport) => (
                            <MenuItem key={sport.id} value={sport.id}>
                              {sport.name}
                            </MenuItem>
                          ),
                        )
                      }
                    {
                        (sports_loading)
                        && (
                        <div className={classes.loading}>
                          <CircularProgress size={48} />
                        </div>
                        )
                      }
                  </Select>
                </FormControl>
                <FormControl className={classes.form_control}>
                  <InputLabel htmlFor="category-input">Categories</InputLabel>
                  <Select
                    multiple
                    MenuProps={MenuProps}
                    value={categories_selected}
                    onChange={(e) => this.setFilters({ categories_selected: e.target.value })}
                    inputProps={{ id: 'category-input' }}
                    renderValue={
                          (selected) => selected.map((type) => (
                            categories_map[type]
                              && categories_map[type].name
                          )).join(', ')
                        }
                  >
                    {categories.map(({ id, name }) => (
                      <MenuItem dense key={id} value={id}>
                        <Checkbox
                            color="primary"
                            checked={
                                  categories_selected.indexOf(id) >= 0
                                }
                          />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                    {
                        (categories_loading)
                        && (
                        <div className={classes.loading}>
                          <CircularProgress size={48} />
                        </div>
                        )
                      }
                  </Select>
                </FormControl>
                <Button
                  color="primary"
                  className={classes.button}
                  onClick={() => this.clearFilters()}
                >
                    Clear Filters
                </Button>
              </Collapse>
            </Paper>
            <Typography>
                No posts found.
            </Typography>
          </Grid>
        </Grid>
      );
    }
    return <CircularProgress key={0} />;
  }

  setFilters = (state) => {
    this.setState({
      new_search: true,
      ...state,
    });
  }

  render() {
    const { classes } = this.props;
    const {
      sport_selected,
      categories_selected,
      date_sub,
      date,
    } = this.state;

    let where; let
      template;

    if (categories_selected.length > 0) {
      template = {
        types_some: { id_in: categories_selected },
      };
    }

    if (sport_selected) {
      template = {
        ...template,
        categories_some: { id: sport_selected },
      };
    }

    if (date_sub) {
      where = {
        postTime_lte: new Date(date).toISOString(),
        postTime_gte: new Date(date_sub).toISOString(),
        template,
      };
    }
    return (
      <div id="main-content" className={classes.main}>
        <Query
          query={GET_POSTS}
          fetchPolicy="cache-and-network"
          variables={{
            where,
            mode: this.props.mode,
            count: 8,
          }}
        >
          {({ data, fetchMore }) => (
            this.getPosts(data, fetchMore, where)
          )}
        </Query>
      </div>
    );
  }
}

// -------------------------------------------------------------------------- //

export default compose(
  graphql(GET_USER_INFO),
  graphql(GQL_SPORTS_CATEGORIES, {
    name: 'gql_sports_categories',
    options: { fetchPolicy: 'cache-and-network' },
  }),
  graphql(GET_POSTS, {
    name: 'getPosts',
    options: {
      fetchPolicy: 'no-cache',
      variables: { count: 0 },
    },
  }),
  withRouter,
  withStyles(styles),
)(PostsFeed);

// -------------------------------------------------------------------------- //
