
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Typography,
  withStyles,
  Paper,
  CircularProgress,
  MobileStepper,
  Button,
  Fade,
  CardMedia,
  ButtonBase,
  CardContent,
  Collapse,
} from '@material-ui/core';

import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from 'mdi-material-ui';

import { formatDistance } from 'date-fns';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';

import { autoPlay, virtualize } from 'react-swipeable-views-utils';
import { mod } from 'react-swipeable-views-core';
import SwipeableViews from 'react-swipeable-views';

// -------------------------------------------------------------------------- //

const AutoPlaySwipeableViews = virtualize(autoPlay(SwipeableViews));

// -------------------------------------------------------------------------- //

const GET_NEWS = gql`
  query {
    getNews {
      imageId
      published
      subtitle
      tags
      title
      url
    }
  }
`;

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  card_action_area: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    position: 'relative',
    textAlign: 'inherit',
    width: '100%',
  },
  card_header_root: {
    alignItems: 'center',
    display: 'flex',
    padding: theme.spacing(2),
  },
  card_header_content: {
    flex: '1 1 auto',
  },
  card_media: {
    backgroundPosition: 'top',
    height: theme.spacing(32),
  },
  card_title: {
    display: 'block',
  },
  loading: {
    bottom: 0,
    left: '50%',
    position: 'absolute',
    transform: 'translate(-50%, 0%)',
  },
  paper: {
    position: 'relative',
  },
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
});

// -------------------------------------------------------------------------- //

class Component extends React.Component {

  state = {
    step: 0,
  }

  render() {
    const { classes, theme, data } = this.props;
    const { step } = this.state;
    const news = this.getNews();
    const disabled = (news.length === 0);

    return (
      <React.Fragment>
        <Typography variant="h5" className={classes.title}>
          Latest News
        </Typography>
        <Paper className={classes.paper} elevation={4}>
          <Collapse in={!disabled}>
            <AutoPlaySwipeableViews
              enableMouseEvents
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={step}
              onChangeIndex={(step) => this.setState({ step })}
              slideRenderer={(params) => this.renderStory(params, disabled)}
            />
          </Collapse>
          <MobileStepper
            position="static"
            steps={news.length}
            activeStep={mod(step, news.length)}
            nextButton={
              <Button
                size="small"
                disabled={disabled}
                onClick={() => this.increment()}
              >
                Next
                {
                  (theme.direction === 'rtl')
                  ? <ChevronLeftIcon/>
                  : <ChevronRightIcon/>
                }
              </Button>
            }
            backButton={
              <Button
                size="small"
                disabled={disabled}
                onClick={() => this.decrement()}
              >
                {
                  (theme.direction === 'rtl')
                  ? <ChevronRightIcon/>
                  : <ChevronLeftIcon/>
                }
                Back
              </Button>
            }
          />
          {
            (data.loading) &&
            <div className={classes.loading}>
              <CircularProgress size={48}/>
            </div>
          }
        </Paper>
      </React.Fragment>
    );
  }

  renderStory = (params, disabled = false) => {
    const { key, index } = params;
    const { classes } = this.props;
    const news = this.getNews();

    if (news.length === 0) {
      return null;
    }

    const story = news[mod(index, news.length)];

    const time = formatDistance(
      new Date(parseInt(story.published, 10)), new Date()
    );

    // TODO update this when updating MUI
    return (
      <ButtonBase
        disableRipple
        key={key}
        className={classes.card_action_area}
        onClick={() => window.open(story.url, '_newtab')}
      >
        <div className={classes.card_header_root}>
          <div className={classes.card_header_content}>
            <Fade in={!disabled} timeout={1200}>
              <Typography
                variant="h6"
                component="span"
                className={classes.card_title}
              >
                {story.title}
              </Typography>
            </Fade>
            <Fade in={!disabled} timeout={1200}>
              <Typography
                variant="body1"
                component="span"
                color="textSecondary"
                className={classes.card_title}
              >
                {time} ago
              </Typography>
            </Fade>
          </div>
        </div>
        <Fade in={!disabled} timeout={1200}>
          <CardMedia
            className={classes.card_media}
            image={`https://cdn-images-1.medium.com/max/325/${story.imageId}`}
            title="Story Preview"
          />
        </Fade>
        <Fade in={!disabled} timeout={1200}>
          <CardContent>
            <Typography
              gutterBottom
              component="p"
              variant="body1"
              color="textSecondary"
            >
              {story.subtitle}
            </Typography>
          </CardContent>
        </Fade>
      </ButtonBase>
    );
  }

  getNews = () => {
    const { data } = this.props;
    return ((!data.loading && data.getNews) || []);
  }

  decrement = () => {
    const { step } = this.state;
    this.setState({ step: (step - 1) });
  }

  increment = () => {
    const { step } = this.state;
    this.setState({ step: (step + 1) });
  }

}

// -------------------------------------------------------------------------- //

export const MobileNews = compose(
  graphql(GET_NEWS, { options: { fetchPolicy: 'cache-and-network' } }),
  withStyles(STYLES, { withTheme: true }),
)(Component);

// -------------------------------------------------------------------------- //
