import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Logo from '../components/svg/Logo';

const CREATE_USER = gql`
  mutation createUser(
    $email: String!
  ) {
    createUserFromLegacy(
      email: $email
    )
  }
`;

const myTheme = createMuiTheme({
  palette: {
    primary: { main: '#6B2587' }, // Purple and green play nicely together.
    secondary: { main: '#4FD4C4' }, // This is just green.A700 as hex.
  },
  typography: {
    fontFamily: '"Ubuntu", "Helvetica", "Arial", sans-serif',
  },
});

const styles = theme => ({
  root: {
    maxWidth: 500,
    margin: 'auto',
    marginTop: theme.spacing(3),
  },
  heading: {
    backgroundColor: '#6b2587',
    textAlign: 'center',
  },
  logo: {
    height: 160,
    fill: theme.palette.primary.contrastText,
    padding: 24,
    paddingLeft: theme.spacing(2),
  },
  main: {
    padding: theme.spacing(3),
    minHeight: 400,
  },
});

class OptIn extends Component {
  state = {
    nag: true,
    email: '',
    loading: false,
  }

  getUser = () => {
    let text = '?';
    if (this.state.user.firstname && this.state.user.lastname) {
      text = `${this.state.user.firstname.substring(0, 1)}${this.state.user.lastname.substring(0, 1)}`;
    }
    const style = {
      backgroundColor: '#6B2587',
      color: '#4FD4C4',
    };
    if (this.state.user.color_1) {
      style.backgroundColor = `#${this.state.user.color_1}`;
    }
    if (this.state.user.color_2) {
      style.color = `#${this.state.user.color_2}`;
    }
    return (
      <Card>
        <CardHeader
          avatar={
            <Avatar style={style}>
              {text}
            </Avatar>
          }
          title={`${this.state.user.firstname} ${this.state.user.lastname}`}
          subheader={this.state.user.client}
        />
      </Card>
    );
  }

  getForm = () => (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Account Verification
      </Typography>
      <Typography gutterBottom>
        Please provide your
        <strong> ScoreShots Legacy </strong>
        account email.
      </Typography>
      {!this.state.user ?
        <TextField
          label="Email Address"
          type="email"
          name="email"
          fullWidth
          value={this.state.email}
          onChange={this.handleChange('email')}
        /> :
      null}
      {this.state.error ?
        <Typography color="error" gutterBottom>
          {this.state.error}
        </Typography> :
        null
      }
      {this.state.user ?
        this.getUser() :
        null
      }
      {this.state.user && !this.state.complete ?
        <Mutation mutation={CREATE_USER}>
          {createUser => (
            <Button
              fullWidth
              variant="raised"
              size="large"
              color="primary"
              style={{ marginTop: 20 }}
              onClick={this.createAccount(createUser)}
              disabled={this.state.loading || this.state.error}
            >
              Create Account
            </Button>
          )}
        </Mutation> :
      null}
      {!this.state.user ?
        <Button
          fullWidth
          variant="raised"
          size="large"
          color="primary"
          style={{ marginTop: 20 }}
          onClick={this.verify}
          disabled={this.state.loading}
        >
          Verify Information
        </Button> :
      null}
      {this.state.complete ?
        <React.Fragment>
          <Typography style={{ marginTop: 22 }} gutterBottom>
            {'🎉! Your account has been created. An email has been sent to the address you provided. If you have any issues getting set up, please let us know.'}
          </Typography>
          <Typography>
            {'You may now close this window.'}
          </Typography>
        </React.Fragment> :
        null
      }

    </React.Fragment>
  )

  getNag = () => (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Opt-In to ScoreShots Next
      </Typography>
      <Typography gutterBottom>
        {
        `
          Welcome to ScoreShots Next, we're happy to have you on board for our Open Beta!
        `
        }
      </Typography>
      <Typography gutterBottom>
        This beta is open to
        <strong> Clients </strong>
        only.
      </Typography>
      <Typography gutterBottom>
        {`
        If you aren't a current client, request a demo today, and get immediate access to Next upon signup.
      `}
      </Typography>
      <Typography variant="h6" gutterBottom style={{ marginTop: 12, fontSize: 18 }}>
        ABOUT SCORESHOTS NEXT
      </Typography>
      <Typography gutterBottom>
        {`
          Signing up for Next will not remove your ability to use ScoreShots Legacy. You will continue to be able to use both products.
        `}
      </Typography>
      <Typography gutterBottom>
        {`
          ScoreShots Next remains in Beta. There may be some rough edges, and the product will receive frequent updates.
        `}
      </Typography>
      <Typography gutterBottom>
        {`
          Certain templates, including dynamic and private templates, are not available in ScoreShots Next. All will be moved over in the coming weeks.
        `}
      </Typography>

      <Typography variant="h6" gutterBottom style={{ marginTop: 12, fontSize: 18 }}>
        IMPORTANT!
      </Typography>
      <Typography gutterBottom>
        {`
          In ScoreShots Legacy, some of our partners were given multiple accounts. In Next, all users are part of a single account.
        `}
      </Typography>
      <Typography gutterBottom>
        <strong>ONLY ONE </strong>
        {`
          user, the primary contact for your team, should use this form and register your team for Next. You may then invite your teammates. If you are not the primary user, please ask them to register your team for Next, or get in touch with us.
        `}
      </Typography>
      <Button
        fullWidth
        variant="raised"
        size="large"
        color="primary"
        style={{ marginTop: 20 }}
        onClick={this.closeNag}
      >
        I am the authorized user for my team
      </Button>
    </React.Fragment>
  )

  verify = () => {
    this.setState({
      loading: true,
      error: null,
      user: null,
    });
    const XHR = new XMLHttpRequest();
    let urlEncodedData = '';
    const urlEncodedDataPairs = [];
    urlEncodedDataPairs.push(`${encodeURIComponent('email')}=${encodeURIComponent(this.state.email)}`);
    urlEncodedData = urlEncodedDataPairs.join('&').replace(/%20/g, '+');
    XHR.addEventListener('load', (event) => {
      const user = JSON.parse(event.target.response);
      const state = {
        loading: false,
      };
      if (!user || !user.id) {
        state.error = 'No user with that email found. Please contact support.';
      } else {
        state.user = user;
      }
      this.setState(state);
    });
    XHR.addEventListener('error', () => {
      this.setState({
        loading: false,
        error: 'No user with that email found. Please contact support.',
      });
    });
    XHR.open('POST', 'https://legacy.scoreshots.com/index.php/Client/get_client');
    XHR.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    XHR.send(urlEncodedData);
  }

  closeNag = () => {
    this.setState({
      nag: false,
      loading: false,
    });
  }

  createAccount = create => () => {
    this.setState({
      loading: true,
    });
    create({
      variables: {
        email: this.state.email.toLowerCase(),
      },
    })
      .catch(() => {
        this.setState({
          loading: false,
          error: 'An error occurred creating your account. Please contact Support',
        });
      })
      .then((response) => {
        if (response.data) {
          this.setState({
            complete: true,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
            error: 'An error occurred creating your account. Please contact Support',
          });
        }
      });
  }

  handleChange = prop => (e) => {
    const state = {};
    state[prop] = e.target.value;
    this.setState(state);
  }

  render() {
    const { classes } = this.props;
    return (
      <MuiThemeProvider theme={myTheme}>
        <Paper className={classes.root}>
          <div className={classes.heading}>
            <Logo className={classes.logo} />
          </div>
          <div className={classes.main}>
            {this.state.nag ? this.getNag() : this.getForm() }
          </div>
        </Paper>
      </MuiThemeProvider>

    );
  }
}

export default withStyles(styles)(OptIn);
