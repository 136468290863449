export default {
  links: [
    {
      type: 'href',
      to: '#profile',
      text: 'User Profile',
    },
    {
      type: 'href',
      to: '#password',
      text: 'Change Password',
    },
  ],
};
