import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import ResponsiveGrid from '../layout/ResponsiveGrid';
import ExtendedFab from '../button/ExtendedFab';

const styles = theme => ({
  header: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    paddingLeft: theme.spacing(2),
  },
  heading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});


const BetaHeader = (props) => {
  const { classes } = props;
  return (
      <div className={classes.header}>
      <ResponsiveGrid id="main-content">
        <Hidden xsDown>
          <Typography
            className={classes.heading}
            variant="h2"
            color="inherit"
            component="h1"
          >
            Dashboard
          </Typography>
        </Hidden>
        <ExtendedFab id="create" to="/create/new"/>
      </ResponsiveGrid>
    </div>
  );
};

export default withStyles(styles)(BetaHeader);
