export default {
  'i-text': {
    dateLocation: {
      name: 'Date/Location',
      props: [
        'text',
      ],
    },
    hashtag: {
      name: 'Hashtag',
      props: [
        'text',
      ],
    },
    headline: {
      name: 'Headline',
      props: [
        'text',
      ],
    },
    userScore: {
      name: 'Your Score',
      props: [
        'text',
      ],
    },
    userTeamName: {
      name: 'Your Team Name',
      props: [
        'text',
      ],
    },
    oppTeamName: {
      name: 'Opponent\'s Team Name',
      props: [
        'text',
      ],
    },
    oppScore: {
      name: 'Opponent\'s Score',
      props: [
        'text',
      ],
    },
    userRecord: {
      name: 'Your Record',
      props: [
        'text',
      ],
    },
    oppRecord: {
      name: 'Opponent\'s Record',
      props: [
        'text',
      ],
    },
    playerName: {
      name: 'Player Name',
      props: [
        'text',
      ],
    },
    sport: {
      name: 'Sport Name',
      props: [
        'text',
      ],
    },
    gameSection: {
      name: 'Quarter/Half/Inning',
      props: [
        'text',
      ],
    },
  },
  cutoutBox: {
    firstCutout: {
      name: 'First Cutout',
      props: [
        'image',
      ],
    },
  },
  logoBox: {
    userLogo: {
      name: 'Your Logo',
      props: [
        'image',
      ],
    },
    oppLogo: {
      name: 'Opponent Logo',
      props: [
        'image',
      ],
    },
    sponsorLogo1: {
      name: 'Sponsor Logo 1',
      props: [
        'image',
      ],
    },
    sponsorLogo2: {
      name: 'Sponsor Logo 2',
      props: [
        'image',
      ],
    },
    sponsorLogo3: {
      name: 'Sponsor Logo 3',
      props: [
        'image',
      ],
    },
    mediaLogo1: {
      name: 'Media Logo 1',
      props: [
        'image',
      ],
    },
    mediaLogo2: {
      name: 'Media Logo 2',
      props: [
        'image',
      ],
    },
  },
  backgroundBox: {
    background1: {
      name: 'Background 1',
      props: [
        'image',
      ],
    },
    background2: {
      name: 'Background 2',
      props: [
        'image',
      ],
    },
    background3: {
      name: 'Background 3',
      props: [
        'image',
      ],
    },
  },
};
