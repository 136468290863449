
// -------------------------------------------------------------------------- //

import * as React from 'react';

import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import {
  CircularProgress,
  FormControlLabel,
  IconButton,
  Switch,
  Toolbar,
  Typography,
  Button,
  withStyles,
  LinearProgress, Select, MenuItem, FormControl,
} from '@material-ui/core';

import {
  Download,
} from 'mdi-material-ui';

import classNames from 'classnames';
import download from 'downloadjs';
import format from 'date-fns/format';
import Lottie from 'react-lottie';
import animationData from '../../lottie/sslogo.json';
import * as Scoreshots from '../../scoreshotsapi/v1';
import AspectContainer from './AspectContainer';
import Progress from './Progress';
import JSZip from "jszip";
import { saveAs } from 'file-saver';

// -------------------------------------------------------------------------- //


const GET_CUSTOM_TEMPLATE = gql`
  query customTemplate($id:ID) {
    customTemplate(id: $id) {
      id
      name
    }
  }
`
const styles = (theme) => ({
  flex: {
    flex: 1,
  },
  noselect: {
    userSelect: 'none',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  description: {
    padding: theme.spacing(1),
  },
  preview: {
    textAlign: 'center',
    flex: 1,
  },
  placeholder: {
    boxShadow: theme.shadows[4],
    maxWidth: '100%',
    borderRadius: 2,
  },
  video: {
    boxShadow: theme.shadows[4],
    maxWidth: '100%',
  },
  image: {
    boxShadow: theme.shadows[4],
    maxWidth: '100%',
    borderRadius: 2,
  },
  quickCreateToolbar: {
    display: 'flex',
    paddingTop: theme.spacing(1),
    flexDirection: 'row-reverse'
  },
  quickCreateSelect: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginLeft: '-23px',
    minWidth: 120,
  },
  toolbar: {
    paddingTop: theme.spacing(1),
  },
  progress: {
    position: 'absolute',
    bottom: theme.spacing(4),
    left: theme.spacing(3),
    right: theme.spacing(3),
  },
  progress_eta: {
    bottom: theme.spacing(6),
    left: '50%',
    position: 'absolute',
    transform: 'translateX(-50%)',
  },
  // 6B2587 / 9C53B7 / CF82EA
  progress_root: {
    backgroundColor: '#CF82EA',
  },
  progress_bar: {
    backgroundColor: '#6B2587',
  },
});

// -------------------------------------------------------------------------- //

function downloadImage(image,name) {

  const filename = name === '' ? `${format(new Date(), 'yyyy-MM-ddTHH:mm:ss.SSS')} Graphic.png` : name;

  return (
    Scoreshots.S3Download(image, 'image/png')
    .then(response => response.blob())
    .then(blob => download(blob, filename, 'image/png'))
  );
}

// -------------------------------------------------------------------------- //

function downloadVideo(url) {
  const filename = `${format(new Date(), 'yyyy-MM-ddTHH:mm:ss.SSS')} Graphic.mp4`;

  return (
    fetch(url)
    .then(response => response.blob())
    .then(blob => download(blob, filename, 'video/mp4'))
  );
}

// -------------------------------------------------------------------------- //

function printTime(secs) {
  let minutes = String(Math.floor(secs / 60));
  let seconds = String(Math.floor(secs % 60));

  if (minutes.length === 1) {
    minutes = `0${minutes}`;
  }

  if (seconds.length === 1) {
    seconds = `0${seconds}`;
  }

  return `${minutes}:${seconds}`;
}

// -------------------------------------------------------------------------- //

class GraphicPreview extends React.Component {

  state = {
    mode: '',
    downloading: false,
    template: 'AllTemplates',
    templateName: ''
  }

  componentDidMount() {
    this.eta = new Progress(100);
  }

  componentDidUpdate(prev_props) {
    const previous = prev_props.progress;
    const current = this.props.progress;

    if (current > previous) {
      this.eta.numerator = current;
    }
  }

  onModeChange = (mode) => {
    const { onModeChange } = this.props;

    if (onModeChange) {
      onModeChange(mode);
    } else {
      this.setState({ mode });
    }
  }

  onDownloadClick = () => {
    const { video, image } = this.props;
    const { templateName } = this.state
    const mode = this.getRenderMode();
    this.setState({ downloading: true }, () => {
      const promise = (
        mode === 'video'
        ? downloadVideo(video)
        : downloadImage(image,templateName)
      );

      promise.then(() => {
        this.setState({ downloading: false });
      }).catch(() => {
        this.setState({ downlaoding: false });
      });
    });
  }

  getRenderMode() {
    if (this.props.mode) {
      return (
        (this.props.onChangeMode || this.state.mode === '')
        ? this.props.mode // mode is externally controlled
        : this.state.mode // props.mode is initial value
      );
    } else {
      if (this.state.mode !== '') {
        return this.state.mode;
      } else if (this.props.slideshow) {
        return 'video';
      } else {
        return 'image';
      }
    }
  }

  renderDescription() {
    const { classes } = this.props;
    let { description } = this.props;

    if (!description) {
      const { slideshow, rendering } = this.props;
      const creation = (slideshow ? 'slideshow' : 'graphic');

      description = (rendering) ? (
        `Your ${creation} is rendering and will be ready to go soon.`
      ) : (
        `You can preview and download your finished ${creation} below.`
      );
    }

    return (
      <Typography className={classes.description}>
        {description}
      </Typography>
    );
  }
  fetchTemplateName = (id) => {
    return new Promise (resolve => {
      fetch('https://o8kvjjiwfj.execute-api.us-east-2.amazonaws.com/staging/',{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `
          query template($id:ID) {
            template(id: $id) {
              name
            }
          }
        `,
          variables: {
            id
          }
        })
      }).then(r => r.json()).then(response => {
        resolve(response)
      })
    })
  }
  renderPreview() {
    const { classes, progress, maxHeight } = this.props;
    const mode = this.getRenderMode();
    let preview = null;

    if (mode === 'video') {
      const { video, rendering } = this.props;

      if (video && !rendering) {
        preview = (
          <video className={classes.video} crossorigin="anonymous" style={{ maxHeight }} muted loop autoPlay controls>
            <source crossorigin="anonymous" src={video} type="video/mp4"/>
          </video>
        );
      } else {
        preview = (
          <div className={classes.placeholder} style={{ maxHeight }}>
            <AspectContainer aspect={0.75}>
              <Lottie
                isClickToPauseDisabled
                options={{
                  loop: true,
                  autoplay: true,
                  animationData,
                }}
              />
              <div className={classes.progress_eta}>
                <Typography>
                  {
                    (!this.eta || !this.eta.started || this.eta.elapsed === 0) ?
                    `Gathering your assets...`
                    : (!this.eta.done || this.eta.eta_seconds > 0) ?
                    `Time remaining: ${printTime(this.eta.eta_seconds)}`
                    : `We're almost there...`
                  }
                </Typography>
              </div>
              {(progress !== undefined && progress !== null) &&
              <LinearProgress
              classes={{
                root: classes.progress_root,
                bar: classes.progress_bar,
              }}
              className={classes.progress}
                variant={(0 < progress && progress < 100) ? 'determinate' : 'indeterminate'}
                value={progress}
              />}
            </AspectContainer>
          </div>
        );
      }
    }else if(this.props.quickCreate) {
      const { json } = this.props;
      const slideWidth = ['16vmax','12vmax','6vmax']
      let images = json.data
          .map((data,i) => <img className={classes.image}
                                style={{ width: slideWidth[i],height: '10vmax',marginLeft: i === 0? 0 : '1vmax' }}
                                src={data.preview.jpg}
                                alt="preview"
                          />
          )
      preview = (
          <div style={{display: 'flex', maxWidth:'35vmax'}}>
            {images}
          </div>
      )
    } else {
      const { image } = this.props;

      preview = (
        <img className={classes.image} style={{ maxHeight }} src={image} alt="preview"/>
      );
    }

    return (
      <div className={classes.preview}>
        {preview}
      </div>
    );
  }

  quickCreateDownload = async () => {
    switch (this.props.template){
      case 'Twitter': {
        downloadImage(this.props.json.data[0].preview.jpg,this.state.templateName !== '' ? `${this.state.templateName}-1.png` : '')
        break
      }
      case 'InstagramStory': {
        downloadImage(this.props.json.data[2].preview.jpg,this.state.templateName !== '' ? `${this.state.templateName}-3.png` : '')
        break
      }
      case 'AllTemplates': {
        const zip = new JSZip()
        for (let i = 0; i < this.props.json.data.length; i++) {
          let blob = await fetch(this.props.json.data[i].preview.png).then(resp => resp.blob())
          await zip.file(this.state.templateName !== '' ? `${this.state.templateName}-${i}.png` : `file-${i}.png`, blob)
        }
        const filename = this.state.templateName !== '' ? `${this.state.templateName}-${format(new Date(), 'yyyy-MM-ddTHH:mm:ss.SSS')} Graphic.zip`
            : `${format(new Date(), 'yyyy-MM-ddTHH:mm:ss.SSS')} Graphic.zip`;
        zip.generateAsync({type: "blob"})
            .then(blob => {
              saveAs(blob, filename)
            });
        break
      }
      case 'Instagram':
      default: {
        // const name = await this.fetchTemplateName(this.props.json.slides[1])
        downloadImage(this.props.json.data[1].preview.jpg,this.state.templateName !== '' ? `${this.state.templateName}-1.png` : '')
        break
      }
    }
  }

  renderQuickCreateToolbar(data) {
    const { classes, hideMode, disableMode, disableDownload, rendering } = this.props;
    const { downloading } = this.state;
    const mode = this.getRenderMode();
    const busy = (downloading || (mode === 'video' && rendering));
    if (data?.data?.customTemplate?.name && this.state.templateName === '') {
      this.setState({
        templateName: data.data.customTemplate.name
      })
    }
    return (
        <Toolbar className={classes.quickCreateToolbar}>
          {
            (!hideMode && !disableMode) &&
            <FormControlLabel
                className={classNames(classes.flex, classes.noselect)}
                control={
                  <Switch
                      color="primary"
                      disabled={disableMode || downloading}
                      checked={mode === 'video'}
                      onChange={(e) => this.onModeChange(e.target.checked ? 'video' : 'image')}
                  />
                }
                label={`Render as ${mode === 'video' ? 'Video' : 'Image'}`}
            />
          }
          {
            (hideMode || disableMode) &&
            <div className={classes.flex}/>
          }
          {
            this.props.onPhoneClick &&
            <div>
              <FormControl variant="outlined" className={classes.quickCreateSelect}>
                <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    style={{width: '200px',height: '40px'}}
                    value={this.props.template}
                    onChange={e => this.props.selectDownload( e.target.value)}
                >
                  <MenuItem value="AllTemplates">All Templates (3)</MenuItem>
                  <MenuItem value="Twitter">Landscape</MenuItem>
                  <MenuItem value="Instagram">Square</MenuItem>
                  <MenuItem value="InstagramStory">Portrait</MenuItem>
                </Select>
              </FormControl>
              <Button variant="contained" color="secondary"
                      style={{backgroundColor: '#6B2587',color: 'white',marginTop:'5px',height: '40px'}}
                      onClick={this.quickCreateDownload}
              >
                {
                  !disableDownload && (downloading ? (
                      <CircularProgress color="primary" size={18} disabled={busy}/>
                  ) : (
                      <>DOWNLOAD</>
                  ))
                }
              </Button>
            </div>
          }
        </Toolbar>
    );
  }

  renderToolbar(data) {

    const { classes, hideMode, disableMode, disableDownload, rendering } = this.props;
    const { downloading } = this.state;
    const mode = this.getRenderMode();
    const busy = (downloading || (mode === 'video' && rendering));
    if (data.data?.customTemplate?.name && this.state.templateName === '') {
      this.setState({
        templateName: data.data.customTemplate.name
      })
    }
    return (
      <Toolbar className={classes.toolbar}>
        {
          (!hideMode && !disableMode) &&
          <FormControlLabel
            className={classNames(classes.flex, classes.noselect)}
            control={
              <Switch
                color="primary"
                disabled={disableMode || downloading}
                checked={mode === 'video'}
                onChange={(e) => this.onModeChange(e.target.checked ? 'video' : 'image')}
              />
            }
            label={`Render as ${mode === 'video' ? 'Video' : 'Image'}`}
          />
        }
        {
          (hideMode || disableMode) &&
          <div className={classes.flex}/>
        }
        {
          !disableDownload && (downloading ? (
            <CircularProgress color="primary" size={48}/>
          ) : (
            <IconButton disabled={busy} onClick={this.onDownloadClick}>
              <Download />
            </IconButton>
          ))
        }
      </Toolbar>
    );
  }

  render() {
    const { classes } = this.props;

    return (
        <Query query={GET_CUSTOM_TEMPLATE} variables={{ id:this.props.templateId }}>
          {
            (data) => (
              <div className={classes.root} data-tour="download">
                {this.renderDescription()}
                {this.renderPreview()}
                {this.props.quickCreate === true? this.renderQuickCreateToolbar(data) : this.renderToolbar(data)}
              </div>
            )
          }
        </Query>
    );
  }

}

// -------------------------------------------------------------------------- //

export default withStyles(styles)(GraphicPreview)

// -------------------------------------------------------------------------- //
