// array of web safe fonts to use.
export default [
  {
    family: 'Georgia, serif',
    name: 'Georgia',
  },
  {
    family: '"Palatino Linotype", "Book Antiqua", Palatino, serif',
    name: 'Palatino',
  },
  {
    family: '"Times New Roman", Times, serif',
    name: 'Times New Roman',
  },
  {
    family: 'Arial, Helvetica, sans-serif',
    name: 'Arial',
  },
  {
    family: 'Helvetica, Arial, sans-serif',
    name: 'Helvetica',
  },
  {
    family: '"Arial Black", Gadget, sans-serif',
    name: 'Arial Black',
  },
  {
    family: 'Impact, Charcoal, sans-serif',
    name: 'Impact',
  },
  {
    family: '"Lucida Sans Unicode", "Lucida Grande", sans-serif',
    name: 'Lucida Sans Unicode',
  },
  {
    family: 'Tahoma, Geneva, sans-serif',
    name: 'Tahoma',
  },
  {
    family: '"Trebuchet MS", Helvetica, sans-serif',
    name: 'Trebuchet MS',
  },
  {
    family: 'Verdana, Geneva, sans-serif',
    name: 'Verdana',
  },
  {
    family: '"Courier New", Courier, monospace',
    name: 'Courier New',
  },
  {
    family: '"Lucida Console", Monaco, monospace',
    name: 'Lucida Console',
  },
];
