
// -------------------------------------------------------------------------- //

import Variable from './Variable';

/* -------------------------------------------------------------------------- *\
  Sports have virtual team information.
  Each team object is of the following structure:

  {
    name: string,
    players: player[],
    key: string, // sport-dependent unique key (useful for component keys)
  }

  Each player is of the following structure:

  {
    name: {
      long: string, // long name of the player
      short: string, // (possibly) shorter form of the long name
      first: string, // [WIP] first name (if applicable)
      last: string, // [WIP] last name (if applicable)
    },
    position: string, // player position (see Sport::getPlayerPositions)
    key: string, // sport-dependent unique key (useful for component keys)
  }
\* -------------------------------------------------------------------------- */

// -------------------------------------------------------------------------- //

const TEAMS = [
  'Home',
  'Visiting',
];


class Sport {

  constructor() {
    this.teams = [];
    this.variables = [];

    for (let t = 0; t < 2; ++t) {
      const team = `[${TEAMS[t]}]`;
      const team_id = `team.${TEAMS[t].toLowerCase()}`;

      this.push(`${team_id}.schedule.name`, `${team} Team Schedule Name`, { type: 'string', schedule: true });
      this.push(`${team_id}.schedule.logo`, `${team} Team Schedule Logo`, { type: 'image', schedule: true });

    }

    this.push('venue.date', 'Date', { type: 'string' });
    this.push('venue.time', 'Time', { type: 'string' });
    this.push('venue.location', 'Venue', { type: 'string' });
    this.push('venue.attendance', 'Attendance', { type: 'string' });
    this.push('venue.stadium', 'Stadium', { type: 'string' });
    this.push('venue.weather', 'Weather', { type: 'string' });

    const player = '[Player]';

    this.push(
      'player.name.long', `${player} Name (full)`, {
        type: 'string',
        short_name: 'Name',
        lineup: true,
      }
    );

    this.push(
      'player.name.short', `${player} Name (short)`, {
        type: 'string',
        short_name: 'Name',
        lineup: true,
      }
    );

    this.push(
      'player.name.first', `${player} Name (first)`, {
        type: 'string',
        short_name: 'Name',
        lineup: true,
      }
    );

    this.push(
      'player.name.last', `${player} Name (last)`, {
        type: 'string',
        short_name: 'Name',
        lineup: true,
      }
    );

    this.push(
      'player.position', `${player} Position`, {
        type: 'string',
        short_name: 'Pos.',
        lineup: true,
      }
    );

    this.push(
      'player.number', `${player} Number`, {
        short_name: 'No.',
        lineup: true,
      }
    );

    this.push(
      'player.height', `${player} Height`, {
        type: 'string',
        short_name: 'Ht.',
        lineup: true,
      }
    );

    this.push(
      'player.weight', `${player} Weight`, {
        type: 'string',
        short_name: 'Wt.',
        lineup: true,
      }
    );

    this.push(
      'player.year', `${player} Year`, {
        type: 'string',
        short_name: 'Yr.',
        lineup: true,
      }
    );

    this.push(
      'player.image.head', `${player} Headshot`, {
        type: 'image',
        lineup: true,
      }
    );



  }

  getVariableByName(name) {
    let variable = this.variables.find(
      ((variable) => variable.name === name)
    );

    if (variable === null || variable === undefined) {
      return null;
    }

    return variable;
  }

  getVariableById(id) {
    let variable = this.variables.find(
      ((variable) => variable.id === id)
    );

    if (variable === null || variable === undefined) {
      return null;
    }

    return variable;
  }

  getPlayerPositions() {
    return [];
  }

  reset(predicate = null) {
    this.teams = [];
    let variables = this.variables;

    if (predicate) {
      variables = variables.filter(predicate);
    }

    variables.forEach(variable => {
      variable.reset();
    });
  }

  swap() {
    for (let i = 0; i < this.variables.length; ++i) {
      let home = this.variables[i];
      const home_id = home.id;
      const home_values = home.values;

      if (!/^team\.home\b/.test(home_id)) {
        continue;
      }

      const visiting_id = home_id.replace(/^team\.home\b/, 'team.visiting');
      let visiting = this.getVariableById(visiting_id);

      if (visiting) {
        const visiting_values = visiting.values;
        home.values = [...visiting_values];
        visiting.values = [...home_values];
      }
    }
  }

  push(id, name, ...args) {
    let variable = new Variable(this, id, name, ...args);
    this.variables.push(variable);
    return variable;
  }

  update(id, version) {
    if (this.getVariableById(id) === null) {
      return { type: 'remove' };
    }

    return { type: null };
  }

}

// -------------------------------------------------------------------------- //

export default Sport;

// -------------------------------------------------------------------------- //
