
// -------------------------------------------------------------------------- //

import * as Scoreshots from './v1';

// -------------------------------------------------------------------------- //

const remapColors = (object, palette, transparent, delete_key = false) => {
  const KEYS = [
    { key: 'fill', override: 'fillOverride' },
    { key: 'stroke', override: 'strokeOverride' },
  ];

  let dirty = false;

  for (let i = 0; i < KEYS.length; ++i) {
    const { key, override } = KEYS[i];

    if (!object[override]) {
      continue;
    }

    if (palette) {
      const color = (
        palette[object[override].toLowerCase()] || transparent
      );

      if (color) {
        object[key] = color;
        dirty = true;
      }
    }

    if (delete_key) {
      delete object[override];
      dirty = true;
    }
  }

  return dirty;
}

// -------------------------------------------------------------------------- //

class RenderJob {

  constructor(width, height, duration) {
    this.width = +width;
    this.height = +height;
    this.duration = +duration;
    this.fonts = [];
    this.scenes = [];
    this.url = null;
  }

  addScenes(scenes) {
    this.scenes = [...this.scenes, ...scenes];
  }

  addFonts(fonts) {
    this.fonts = [...this.fonts, ...fonts];
  }

  setMotionGraphic(url, id) {
    this.motion = {
      url,
      id
    };
  }

  applyBackgroundEffect(effect) {
    let backgrounds = [];
    for (let i = 0; i < this.scenes; ++i) {
      let objects = this.scenes[i].objects;
      for (let j = 0; j < objects.length; ++j) {
        if (objects[j].type === 'backgroundBox') {
          backgrounds = [...backgrounds, objects[j]];
        }
      }
    }

    for (let i = 0; i < this.backgrounds; ++i) {
      effect.apply(backgrounds[i], this.duration);
    }
  }

  build() {
    // <<< OPACITY HACK >>>
    if (this.scenes) {
      this.scenes.forEach((scene) => {
        scene.data.objects.forEach((object) => {
          if (object.type === 'logoBox') {
            if (object.opacity === 0.2 || object.opacity === 0.0) {
              object.opacity = 1.0;
            }
          }

          if (object.type === 'cutoutBox') {
            if (object.opacity === 0.2 || object.opacity === 0.0) {
              object.opacity = 1.0;
            }
          }
        });
      });
    }

    // nab the audio data from the first slide and pull it out to the front
    let audio = [];

    if (this.scenes) {
      let audio_src = null;
      let audio_delay = null;
      let audio_start = null;
      let audio_end = null;
      let audio_volume = null;
      let audio_loop = null;

      for (let i = 0; i < this.scenes.length; ++i) {
        if (
          this.scenes[i].data &&
          this.scenes[i].data.scoreshots &&
          this.scenes[i].data.scoreshots.audio_src
        ) {
          let { scoreshots } = this.scenes[i].data;

          if (audio_src === null) {
            audio_src = scoreshots.audio_src;
            audio_delay = scoreshots.audio_delay;
            audio_start = scoreshots.audio_start;
            audio_end = scoreshots.audio_end;
            audio_volume = scoreshots.audio_volume;
            audio_loop = scoreshots.audio_loop;
          }

          delete scoreshots.audio_src;
          delete scoreshots.audio_delay;
          delete scoreshots.audio_start;
          delete scoreshots.audio_end;
          delete scoreshots.audio_volume;
          delete scoreshots.audio_loop;
        }
      }

      if (audio_src !== null) {
        audio.push({
          src: audio_src,
          offset: audio_delay,
          start: audio_start,
          end: (audio_end - audio_start),
          volume: audio_volume,
          loop: audio_loop,
          index: 0,
        });
      }
    }

    return JSON.parse(JSON.stringify({
      width: this.width,
      height: this.height,
      duration: this.duration,
      transitions: this.transitions,
      scenes: this.scenes,
      fonts: this.fonts,
      motion: this.motion,
      audio,
    }));
  }

  set postProcess(func) {
    if (!func) {
      delete this.postProcessFunc;
    } else {
      this.postProcessFunc = func;
    }
  }

  render(progress, complete) {
    const job = this.build();

    if (this.postProcessFunc) {
      this.postProcessFunc(job);
    }

    return Scoreshots.SubmitRender(job)
      .then((url) => {
        this.url = url;

        return Scoreshots.PollRender(url, {
          period: 5000, progress
        });
      })
      .then(() => {
        if (complete) {
          complete(this.url);
        }
      });
  }

  static remapJobColors(job, palette, transparent = null, delete_key = false) {
    if (!job) {
      return;
    }

    let { scenes } = job;

    if (!scenes) {
      return;
    }

    for (let i = 0; i < scenes.length; ++i) {
      let { scene } = scenes[i];

      if (!scene) {
        continue;
      }

      let { data } = scene;

      if (!data) {
        continue;
      }

      this.remapCanvasColors(data, palette, transparent, delete_key);
    }
  }

  static remapCanvasColors(canvas, palette, transparent = null, delete_key = false) {
    if (!canvas) {
      return;
    }

    let { objects } = canvas;

    if (!objects) {
      return;
    }

    for (let i = 0; i < objects.length; ++i) {
      let object = objects[i];

      if (!object) {
        continue;
      }

      this.remapObjectColors(object, palette, transparent, delete_key);
    }
  }

  static remapObjectColors(object, palette, transparent = null, delete_key = false) {
    if (!object) {
      return;
    }

    if (remapColors(object, palette, transparent, delete_key)) {
      object.dirty = true;
    }

    if (!object.animation) {
      return;
    }

    let { animations } = object.animation;

    if (!animations) {
      return;
    }

    for (let i = 0; i < animations.length; ++i) {
      if (!animations[i]) {
        continue;
      }

      remapColors(animations[i], palette, transparent, delete_key);
    }
  }

}

// -------------------------------------------------------------------------- //

export default RenderJob;

// -------------------------------------------------------------------------- //
