
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Divider,
  Typography,
  withStyles,
} from '@material-ui/core';

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

// -------------------------------------------------------------------------- //

const DraftHint_ = (props) => (
  <div className={props.classes.root}>
    <Typography id="main-content" variant="h4" gutterBottom>
      Drafts
    </Typography>
    <Typography variant="body2" gutterBottom>
      Drafts aren't shared with your team, and expire after 45 days. For anything more permanent, save a custom template.
    </Typography>
    <Divider/>
  </div>
);

// -------------------------------------------------------------------------- //

export const DraftHint = withStyles(STYLES)(DraftHint_);

// -------------------------------------------------------------------------- //
