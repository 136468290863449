
// -------------------------------------------------------------------------- //

import * as React from 'react';

import { isMobileOnly } from 'react-device-detect';
import classNames from 'classnames';
import parseColor from 'parse-color';
import Slider from 'rc-slider';

import {
  Button,
  Grid,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Snackbar,
  SvgIcon,
  Tooltip,
  Typography,
  withStyles,
  CircularProgress,
} from '@material-ui/core';
import {
  ArrowLeft,
  ArrowRight,
  ClockOutline,
  Close,
  ContentCopy,
  Delete,
  DotsVertical,
  Download,
  Plus,
  TransitionMasked,
} from 'mdi-material-ui';
import RenderJob from '../../scoreshotsapi/RenderJob';
import * as Scoreshots from '../../scoreshotsapi/v1';
import SlidePreviewDialog from '../share/SlidePreviewDialog';

import SliderWithInput from './SliderWithInput';
import TransitionsDict from './dict/transitions';


import UploadingDialog from './UploadingDialog';

// -------------------------------------------------------------------------- //

const TOOLBAR_WIDTH = 80;
const SIDEBAR_WIDTH = 280;
const SLIDE_HEIGHT = 96;
const INBETWEEN_WIDTH = 32;
const TRANSITION_WIDTH = 64;
const TRANSITION_HEIGHT = 64;
const TINT_ALPHA = 120;

const INSET_BOX_SHADOW = 'inset 0px 2px 10px 0px rgba(0, 0, 0, 0.2)';

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  padding: {
    padding: theme.spacing(1),
  },
  topleft: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  top: {
    position: 'absolute',
    transform: 'translateX(-50%)',
    left: '50%',
    top: 0,
  },
  topright: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  right: {
    position: 'absolute',
    transform: 'translateY(-50%)',
    top: '50%',
    right: 0,
  },
  bottomleft: {
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  bottom: {
    position: 'absolute',
    transform: 'translateX(-50%)',
    left: '50%',
    bottom: 0,
  },
  bottomright: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  left: {
    position: 'absolute',
    transform: 'translateY(-50%)',
    top: '50%',
    left: 0,
  },
  center: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
  },
  clickthrough: {
    pointerEvents: 'none',
  },
  root: {
    background: '#f5f5f5',
    bottom: 0,
    left: (TOOLBAR_WIDTH + SIDEBAR_WIDTH),
    display: 'flex',
    overflowX: 'scroll',
    overflowY: 'hidden',
    paddingBottom: theme.spacing(2),
    position: 'absolute',
    right: 0,
    zIndex: -10,
  },
  root_shift: {
    left: (TOOLBAR_WIDTH + 1),
  },
  aside: {
    flex: 'none',
    width: theme.spacing(6),
  },
  leftButton: {
    transform: 'translateY(-50%)',
    top: '50%',
    float: 'right',
  },
  rightButton: {
    transform: 'translateY(-50%)',
    position: 'absolute',
    top: '50%',
  },
  slide: {
    flex: 'none',
    position: 'relative',
    margin: `${theme.spacing(2.5)}px ${theme.spacing(0.5)}px`,
  },
  controls: {
    margin: theme.spacing(1),
  },
  controlButton: {
    color: '#f9f9f9',
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: '1rem',
  },
  controlText: {
    color: 'rgba(0, 0, 0, 0.25)',
    pointerEvents: 'none',
  },
  preview: {
    position: 'relative',
    boxShadow: theme.shadows[3],
    minWidth: theme.spacing(6),
    height: SLIDE_HEIGHT,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    userDrag: 'none',
    userSelect: 'none',
    zIndex: 1,
  },
  previewButton: {
    minWidth: 0,
    height: SLIDE_HEIGHT,
    position: 'absolute',
    left: 0,
    top: 0,
  },
  placeholder: {
    boxShadow: theme.shadows[3],
    background: '#f9f9f9',
    height: SLIDE_HEIGHT,
  },
  placeholderButton: {
    color: '#bdbdbd',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    height: SLIDE_HEIGHT,
  },
  empty: {
    boxShadow: INSET_BOX_SHADOW,
    background: '#c7c7c7',
    height: SLIDE_HEIGHT,
  },
  inbetween: {
    flex: 'none',
    padding: `${theme.spacing(2.5)}px ${theme.spacing(0.5)}px`,
    minWidth: theme.spacing(4),
    maxWidth: theme.spacing(5),
  },
  inbetween_button: {
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: '1rem',
  },
  stepper: {
    width: '100%',
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'stretch',
  },
  stepperDots: {
    flexDirection: 'row',
    position: 'relative',
    textAlign: 'center',
    flex: 1,
  },
  stepperDot: {
    width: theme.spacing(1),
    height: theme.spacing(1),
    margin: `0 ${theme.spacing(0.25)}px`,
    backgroundColor: 'rgba(0, 0, 0, 0.26)',
    display: 'inline-block',
    borderRadius: '50%',
  },
  stepperDotActive: {
    backgroundColor: theme.palette.primary.main,
  },
  stepperProgress: {
    margin: theme.spacing(1),
    width: '100%',
    flex: 1,
  },
  stepperText: {
    padding: theme.spacing(1),
  },
  popover: {
    padding: theme.spacing(2),
    backgroundColor: 'transparent',
    overflow: 'hidden',
  },
  popoverContent: {
    width: theme.spacing(36),
    padding: theme.spacing(1),
    position: 'relative',
  },
  popoverArrow: {
    position: 'absolute',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderTop: `10px solid ${theme.palette.background.paper}`,
    width: 0,
    height: 0,
    bottom: -20,
    left: '50%',
    transform: 'translate(-50%, -100%)',
  },
  transitionGrid: { },
  transitionCell: {
    padding: theme.spacing(1),
  },
  transitionPreview: {
    boxShadow: theme.shadows[1],
    width: TRANSITION_WIDTH,
    height: TRANSITION_HEIGHT,
    backgroundColor: 'black',
    userDrag: 'none',
    userSelect: 'none',
    position: 'relative',
    transform: 'translate(-50%, 0%)',
    left: '50%',
  },
  transitionButton: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    minWidth: 0,
    left: 0,
    top: 0,
  },
  transitionCaption: {
    margin: theme.spacing(1),
    textAlign: 'center',
  },
  transitionControls: { },
  transitionActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: '0 0 auto',
  },
  transitionAction: {
    margin: theme.spacing(1),
  },
  duration: {
    width: '100%',
    position: 'absolute',
    transform: 'translateX(-50%)',
    transition: theme.transitions.create('top'),
    left: '50%',
    top: 0,
  },
  durationButton: {
    // yup this is terrible
    height: 16,
    padding: 0,
  },
  durationIcon: {
    transform: 'scale(0.9)', // this too
  },
  portrait_duration_icon: {
    position: 'absolute',
    transform: 'translateX(-50%) scale(0.8)', // it doesn't stop
    left: '50%',
    top: '15%',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shorter,
    }),
  },
  portrait_duration_icon_hidden: {
    transform: 'translateX(-50%) scale(0)',
  },
  portrait_duration_text: {
    position: 'absolute',
    transform: 'translateX(-50%)',
    left: '50%',
    top: '25%',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shorter,
    }),
  },
  portrait_duration_text_hidden: {
    transform: 'translateX(-50%)  scale(0)',
  },
  duration_content: {
    width: theme.spacing(30),
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    position: 'relative',
  },
});

// -------------------------------------------------------------------------- //

const ChevronDoubleLeft = (props) => (
  <SvgIcon {...props}>
    <path d="M18.41,7.41L17,6L11,12L17,18L18.41,16.59L13.83,12L18.41,7.41M12.41,7.41L11,6L5,12L11,18L12.41,16.59L7.83,12L12.41,7.41Z" />
  </SvgIcon>
);

// -------------------------------------------------------------------------- //

const calcViewWidth = () => Math.max(document.documentElement.clientWidth, (window.innerWidth || 0));

// -------------------------------------------------------------------------- //

function calcSlideWidth(width, height) {
  if (height === 0) {
    return 0;
  }

  const aspect = (width / height);
  return (SLIDE_HEIGHT * aspect);
}

// -------------------------------------------------------------------------- //

function clampNumber(x, lo, hi) {
  return Math.min(Math.max(x, lo), hi);
}

// -------------------------------------------------------------------------- //

// result: [ r, g, b, a ] component: (0 - 255)
const myParseColor = (color) => {
  const parsed_color = parseColor(color);

  if (parsed_color.rgb === undefined || parsed_color.rgba === undefined) {
    return [0, 0, 0, 0];
  }

  return [...parsed_color.rgb, Math.floor(parsed_color.rgba[3] * 255)];
};

// -------------------------------------------------------------------------- //

function printColor(color, format) {
  switch (format) {
    case '###': {
      const hex = color.map((e) => e.toString(16)[0]);
      return `#${hex[0]}${hex[1]}${hex[2]}`;
    }
    case '######': {
      const hex = color.map((e) => {
        let digit = e.toString(16);

        if (digit.length === 1) {
          digit = (`0${digit}`);
        }

        return digit;
      });

      return `#${hex[0]}${hex[1]}${hex[2]}`;
    }
    case '########': {
      const hex = color.map((e) => {
        let digit = e.toString(16);

        if (digit.length === 1) {
          digit = (`0${digit}`);
        }

        return digit;
      });

      return `#${hex[0]}${hex[1]}${hex[2]}${hex[3]}`;
    }
    case 'rgb': {
      return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
    }
    case 'rgba': {
      return `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3] / 255})`;
    }
    default: {
      return 'transparent';
    }
  }
}

// -------------------------------------------------------------------------- //

function calcTintColor(color, alpha) {
  const rgba = myParseColor(color);
  rgba[3] = alpha;
  return printColor(rgba, 'rgba');
}

// -------------------------------------------------------------------------- //

function tintImage(image, tint, active) {
  const gradient = `linear-gradient(${tint}, ${tint})`;
  return (active ? `${gradient}, ${image}` : image);
}

// -------------------------------------------------------------------------- //

export function createRenderJob(working_area, slides, transitions, fonts) {
  const used_fonts = [];

  for (let i = 0; i < slides.length; ++i) {
    const { canvas } = slides[i];

    const texts = (canvas.objects.filter((object) => (
      object.type === 'i-text' && object.fontFamily
    )));

    for (let j = 0; j < texts.length; ++j) {
      const { fontFamily } = texts[j];

      if (used_fonts.indexOf(fontFamily) < 0) {
        used_fonts.push(fontFamily);
      }
    }
  }

  // force integers to be integers
  return {
    width: +working_area.width,
    height: +working_area.height,
    duration: (
      slides.map((i) => +i.duration || 5).reduce((x, y) => (x + y), 0)
      + transitions.map((i) => +i.duration || 1).reduce((x, y) => (x + y), 0)
    ),
    transitions: transitions.map((transition) => ({
      type: transition.type,
      duration: +transition.duration,
    })),
    scenes: slides.map((slide) => ({
      data: slide.canvas,
      duration: +slide.duration,
    })),
    fonts: fonts.filter((i) => used_fonts.includes(i.fontFamily)).map((font) => ({
      name: font.fontFamily,
      type: font.type.toLowerCase(),
      id: font.id,
      key: font.key,
      bucket: font.bucket,
    })),
  };
}


// -------------------------------------------------------------------------- //

class TimeLine extends React.Component {
  state = {

    hovered: -1,
    selected: 0,
    scroll: 0,
    transition: -1,
    preview: -1,
    busy_index: -1,
    overflowIndex: -1,
    overflowAnchor: null,
    overflowOpen: false,
    transitionAnchor: null,
    durationAnchor: null,
    rendering: false,
    progress: 0,
    url: null,
    snackbar: '',
  }

  constructor(props) {
    super(props);
    this.root_element = React.createRef();

  }

  componentDidMount() {
    const { canvas_util, quickcreate, json } = this.props;

    if(quickcreate === true){

      for(let i = 0;i<json.data.length;i++){
        this.loadSlide(i,'select')
        canvas_util.resize(json.data[i].template.data.template.width,
            json.data[i].template.data.template.height,false)
        canvas_util.generateSlidePreview(json.data[i])
      }

      canvas_util.resize(json.data[0].template.data.template.width,
          json.data[0].template.data.template.height)
      this.loadSlide(0,'select')

    }
  }

  getSlides = () => {
    const { json } = this.props;
    return ((json && json.data) || []);
  }

  getSlide = (index) => {
    const { quickcreate } = this.props;
    if (index === undefined || index === null) {
      const { selected } = this.state;
      index = selected;
    }

    const slides = this.getSlides();
    if(quickcreate === true) {
      slides.sort((firstEl,secondEl) => {
        return firstEl.template.data.template.width < secondEl.template.data.template.width ? -1 : 1
      })
    }
    return slides[index];
  }

  addSlide = (index, release = false) => () => {
    const { selected } = this.state;
    const slides = this.getSlides();
    const transitions = this.getTransitions();
    const state = { busy_index: index };

    if (release) {
      state.overflowOpen = false;
      state.hovered = -1;
    }

    this.setState(state, () => {
      this.saveSlide(selected, 'add').then(() => {
        slides.splice(index, 0, { canvas: {}, duration: 5, preview: { png: '', jpg: '' } })
        transitions.splice(index, 0, { type: 'None', duration: 0 })

        this.setState({ selected: index, busy_index: -1 }, () => {
          const root_element = this.root_element.current;

          if (root_element) {
            root_element.scrollLeft = root_element.scrollWidth;
          }

          this.loadSlide(index, 'add')
        })
      });
    });
  }

  setSlideDuration = (index, value, dirty = true) => {
    const slide = this.getSlide(index);
    const { canvas } = this.props;

    if (canvas && slide.duration) {
      // update all animations in the current slide so that
      // their keyframe times scale to the new slide duration
      const objects = canvas.getObjects();
      const duration = (slide.duration || 5);

      for (let i = 0; i < objects.length; ++i) {
        const object = objects[i];

        if (!object.animation || !object.animation.animations) {
          continue;
        }

        const keyframes = object.animation.animations;

        for (let j = 0; j < keyframes.length; ++j) {
          const time = (keyframes[j].startTime / duration);
          keyframes[j].startTime = (time * value);
        }
      }
    }

    slide.duration = value;

    if (dirty) {
      this.forceUpdate();
    }
  }

  duplicateSlide = (index, release = false) => () => {
    const { selected } = this.state;
    const slides = this.getSlides();
    const transitions = this.getTransitions();
    const state = { busy_index: index };

    if (release) {
      state.overflowOpen = false;
      state.hovered = -1;
    }

    this.setState(state, () => {
      this.saveSlide(selected, 'duplicate').then(() => {
        const new_slide = JSON.parse(JSON.stringify(slides[index]));
        new_slide.duplicate = true;
        slides.splice((index + 1), 0, new_slide);
        transitions.splice((index + 1), 0, { type: 'None', duration: 0 });

        this.setState({ selected: (index + 1), busy_index: -1 }, () => {
          const root_element = this.root_element.current;

          if (root_element) {
            root_element.scrollLeft = root_element.scrollWidth;
          }

          this.loadSlide((index + 1), 'duplicate');
        });
      });
    });
  }

  swapSlides = (lhs, rhs, release = false) => () => {
    const { selected } = this.state;
    const slides = this.getSlides();
    const first = slides[lhs];
    const second = slides[rhs];
    const state = {};

    if (selected === lhs || selected === rhs) {
      state.selected = (selected === lhs ? rhs : lhs);
    }

    slides[lhs] = {
      canvas: second.canvas,

      duration: second.duration,
      preview: {
        png: second.preview.png,
        jpg: second.preview.jpg,
      },

    }

    slides[rhs] = {
      canvas: first.canvas,

      duration: first.duration,
      preview: {
        png: first.preview.png,
        jpg: first.preview.jpg,
      },

    };

    if (release) {
      state.overflowOpen = false;
    }

    this.setState(state);
  }

  removeSlide = (index, release = false) => () => {
    const { selected } = this.state;
    const slides = this.getSlides();
    const transitions = this.getTransitions();

    if (slides.length <= 1) {
      return;
    }

    slides.splice(index, 1);
    transitions.splice(Math.max((index - 1), 0), 1); // remove transition to the left
    const state = { };

    if (selected >= slides.length) {
      state.selected = (slides.length - 1);
    }

    if (release) {
      state.overflowOpen = false;
    }

    this.setState(state, () => {
      if (state.selected !== undefined) {
        this.loadSlide(state.selected, 'remove');
      }
    });
  }

  loadSlide = (index, reason = '') => {
    const { onSlideLoad } = this.props;
    const slide = this.getSlide(index);

    if (onSlideLoad) {
      onSlideLoad(slide, index, reason);
    }
  }

  saveSlide = async (index, reason = '') => {
    const { onSlideSave } = this.props;
    const slide = this.getSlide(index);

    return new Promise(async (resolve) => {
      await onSlideSave(slide, index, reason);
      resolve();
    });
  }

  prepareJob = (job) => {
    const { client } = this.props;

    const colors = {
      primary: client.color1,
      secondary: client.color2,
      tertiary: client.color3,
    };

    RenderJob.remapJobColors(job, colors, 'rgba(0, 0, 0, 0.0)');
  }

  previewSlide = (index) => () => {
    const { canvas, fonts } = this.props;

    if (!canvas) {
      return;
    }

    this.setState({ overflowOpen: false }, () => {
      const { selected } = this.state;
      this.saveSlide(selected, 'preview')
        .then(() => {
          const slide = this.getSlide(index);
          const job = createRenderJob(canvas.workingArea, [slide], [], fonts);
          this.prepareJob(job);
          return Scoreshots.SubmitRender(job);
        }).then((url) => {
          this.setState({
            url,
            progress: 0,
            rendering: true,
          });

          this.openPreviewDialog(index);
          return url;
        }).then((url) => {
          const options = {
            period: 2000,
            progress: (progress) => {
              this.setState({ progress });
            },
          };

          return Scoreshots.PollRender(url, options);
        })
        .then(() => {
          this.setState({ rendering: false });
        })
        .catch(() => {
          this.setState({
            url: null,
            progress: 0,
            rendering: false,
            snackbar: 'Failed to create preview',
          });
        });
    });
  }

  getTransitions = () => {
    const { json } = this.props;
    return ((json && json.transitions) || []);
  }

  getTransition = (index) => {
    if (index === undefined || index === null) {
      const { transition } = this.state;
      index = transition;
    }

    const transitions = this.getTransitions();
    return transitions[index];
  }

  changeTransition = (index, prop, value, dirty = true) => {
    const transition = this.getTransition(index);

    if (prop === 'type' && (!transition[prop] || transition[prop] === 'None')) {
      transition.duration = 1;
    }

    transition[prop] = value;

    if (dirty) {
      this.forceUpdate();
    }
  }

  applyTransition = (index, dirty = true) => {
    const transitions = this.getTransitions();

    for (let i = 0; i < transitions.length; ++i) {
      this.duplicateTransition(index, i, false);
    }

    if (dirty) {
      this.forceUpdate();
    }
  }

  duplicateTransition = (src, dst, dirty = true) => {
    if (src !== dst) {
      const transitions = this.getTransitions();
      transitions[dst] = {};

      for (const key in transitions[src]) {
        transitions[dst][key] = transitions[src][key];
      }
    }

    if (dirty) {
      this.forceUpdate();
    }
  }

  hoverSlide = (index) => () => {
    const { hovered } = this.state;

    if (hovered === index) {
      return;
    }

    this.setState({ hovered: index });
  }

  unhoverSlide = (index) => () => {
    const { hovered } = this.state;

    if (hovered !== index) {
      return;
    }

    this.setState({ hovered: -1 });
  }

  selectSlide = (index,quickcreate) => () => {
    const { selected } = this.state;
    const{ canvas_util } = this.props;
    if (selected === index) {
      return;
    }
    let viewport = []
    if(quickcreate === true){

      viewport = this.props.json.data.map(slide => {

        return {
          height:slide.template.data.template.height,
          width:slide.template.data.template.width
        }

      }).sort((firstEl,secondEl) => {
        return firstEl.width < secondEl.width ? -1 : 1
      })
      const png = canvas_util.toDataURL();
      const jpg = canvas_util.toDataURL({ format: 'jpeg' })
      this.props.json.data[selected].preview = {png,jpg}
    }


    this.setState({ busy_index: index }, () => {
      this.saveSlide(selected, 'select').then(() => {
        this.setState({ selected: index, busy_index: -1 }, async () => {
          this.loadSlide(index, 'select');

           if(quickcreate === true){
              canvas_util.resize(viewport[index].width, viewport[index].height)
           }
        })
      })
    })
  }

  compareBackgroundMediaAndColor = (backgroundA,backgroundB) => {

    let result = false
    if(backgroundA.fill === backgroundB.fill && backgroundA.fill !== 'transparent') result = true

    if(backgroundA.hasOwnProperty('media') && backgroundB.hasOwnProperty('media')){
      if(backgroundA.media.src === backgroundB.media.src) result = true
      else result = false
    }

    return result

  }
  isTheBackgroundAnImage = (background) => {
    if(!background.hasOwnProperty('media')) return false

    if(!background.media.hasOwnProperty('src')) return false

    if(background.media.src === null) return false

    return true
  }

  scrollSlides = (offset) => () => {
    const { variant } = this.props;
    const slides = this.getSlides();

    if (variant !== 'cozy') {
      const { selected } = this.state;
      const value = clampNumber((selected + offset), 0, slides.length);
      this.setState({
        selected: value,
        scroll: value,
      }, () => {
        this.loadSlide(value, 'select');
      });
    }
  }

  openSlideMenu = (index, anchor) => {
    this.setState({
      overflowIndex: index,
      overflowAnchor: anchor,
      overflowOpen: true,
    });
  }

  closeSlideMenu = () => {
    // don't reset overflowIndex to -1 because you can
    // still click menu items while the menu is closing
    this.setState({ overflowOpen: false });
  }

  openTransitionMenu = (index) => (e) => {
    this.setState({
      transition: index,
      transitionAnchor: e.currentTarget,
    });
  }

  closeTransitionMenu = () => {
    // don't reset transition to -1 because you can
    // still click menu items while the menu is closing
    this.setState({ transitionAnchor: null });
  }

  openPreviewDialog = (index) => {
    this.setState({
      preview: index,
      overflowAnchor: null,
    });
  }

  closePreviewDialog = () => {
    this.setState({ preview: -1 });
  }

  renderPreviewDialog = () => {
    const {
      preview, url, rendering, progress,
    } = this.state;
    const open = (preview >= 0);
    const slide = (open && this.getSlide(preview));
    const image = ((slide && slide.preview && slide.preview.png) || '');

    return (
      <SlidePreviewDialog
        open={open}
        video={url}
        image={image}
        rendering={rendering}
        progress={progress}
        onClose={this.closePreviewDialog}
      />
    );
  }

  renderEmptySlide(index) {
    const { classes, canvas } = this.props;

    const slide_width = calcSlideWidth(
      +canvas.workingArea.width,
      +canvas.workingArea.height,
    );

    return (
      <div className={classes.slide} key={index}>
        <div className={classes.empty} style={{ width: slide_width }} />
      </div>
    );
  }

  renderAddSlide(index) {
    const { classes, canvas } = this.props;
    const { busy_index } = this.state;

    const slide_width = calcSlideWidth(
      +canvas.workingArea.width,
      +canvas.workingArea.height,
    )

    return (
      <div className={classes.slide} key={index}>
        <div className={classes.placeholder} style={{ width: slide_width }}>
          <Button
            size="small"
            disabled={busy_index >= 0}
            className={classes.placeholderButton}
            style={{ width: slide_width }}
            onClick={this.addSlide(index)}
          >
            <Plus />
          </Button>
        </div>
      </div>
    );
  }

  renderQuickCreatePreviewSlide = (index) => {
    const {
      classes,theme,json
    } = this.props;
    const {
      selected, busy_index,
    } = this.state;
    const viewport = json.data.map(slide => {
      return {
        height:slide.template.data.template.height,
        width: slide.template.data.template.height
      }
    }).sort((firstEl,secondEl) => {
      return firstEl.width < secondEl.width ? -1 : 1
    }).reverse()
    const slides = this.getSlides();
    const selecting = (selected === index);
    const tint = calcTintColor(theme.palette.primary.main, TINT_ALPHA);
    const image = `url('${slides[index].preview.png}')`;
    const background = `${tintImage(image, tint, selecting)}, linear-gradient(#555, #555)`;


    this.selectSlide(index)

    return (
        <div
            key={index}
            className={classes.slide}
            onMouseOver={this.hoverSlide(index)}
            onMouseLeave={this.unhoverSlide(index)}
            style={{marginLeft:'20px',marginRight:'20px'}}
        >
          <div className={classes.preview} style={{ backgroundImage: background,width: viewport[index].width / 10}}>
            <Button
                disabled={busy_index >= 0}
                className={classes.previewButton}
                style={{ width: viewport[index].width / 10 }}
                onClick={this.selectSlide(index,true)}
            />
          </div>
        </div>
    );
  }

  renderPreviewSlide = (index) => {
    const {
      classes, theme, canvas, canvas_util,
    } = this.props;
    const {
      selected, hovered, overflowOpen, overflowIndex, busy_index,
    } = this.state;
    const slides = this.getSlides();
    const selecting = (selected === index);
    const hovering = (hovered === index && !isMobileOnly);
    const contexting = (overflowIndex === index && overflowOpen);
    const tint = calcTintColor(theme.palette.primary.main, TINT_ALPHA);
    const image = `url('${slides[index].preview.png}')`;
    const background = `${tintImage(image, tint, selecting)}, linear-gradient(#555, #555)`;
    const portrait = (canvas_util.getCanvasAspect() < 1.0);

    const slide_width = calcSlideWidth(
      +canvas.workingArea.width,
      +canvas.workingArea.height,
    );

    return (
      <div
        key={index}
        className={classes.slide}
        onMouseOver={this.hoverSlide(index)}
        onMouseLeave={this.unhoverSlide(index)}
      >
        {this.renderDuration(index)}
        <div className={classes.preview} style={{ backgroundImage: background, width: slide_width }}>
          <Button
            disabled={busy_index >= 0}
            className={classes.previewButton}
            style={{ width: slide_width }}
            onClick={this.selectSlide(index)}
          />
          {((selecting || hovering || contexting) && busy_index < 0)
          && (
          <div className={classNames(classes.topright, classes.controls)}>
            {(index > 0 && !portrait)
            && (
            <Tooltip title="Move Left" PopperProps={{ style: { position: 'fixed' } }}>
              <IconButton className={classes.controlButton} onClick={this.swapSlides(index, (index - 1))}>
                <ChevronDoubleLeft />
              </IconButton>
            </Tooltip>
            )}
            <IconButton className={classes.controlButton} onClick={(e) => this.openSlideMenu(index, e.currentTarget)}>
              <DotsVertical />
            </IconButton>
          </div>
          )}
          {(selecting || hovering)
          && (
          <div className={classNames(classes.center, classes.clickthrough)}>
            <Typography
              className={classNames(classes.controlText, classes.clickthrough)}
              variant="h6"
            >
              {index + 1}
            </Typography>
          </div>
          )}
          {(busy_index === index)
          && (
          <div className={classNames(classes.center, classes.clickthrough)}>
            <CircularProgress />
          </div>
          )}
        </div>
      </div>
    );
  }

  renderOverflowMenu() {
    const { variant, canvas_util } = this.props;
    const { overflowIndex, overflowOpen, overflowAnchor } = this.state;
    const portrait = (canvas_util.getCanvasAspect() < 1.0);
    const slides = this.getSlides();

    return (
      <Menu
        open={overflowOpen}
        anchorEl={overflowAnchor}
        onClose={() => this.closeSlideMenu()}
      >
        <MenuItem onClick={this.addSlide(overflowIndex, true)}>
          <ListItemIcon><Plus /></ListItemIcon>
          <ListItemText>Insert</ListItemText>
        </MenuItem>
        {
          ((variant === 'compact' || portrait) && overflowIndex > 0)
          && (
          <MenuItem onClick={this.swapSlides(overflowIndex, (overflowIndex - 1), true)}>
            <ListItemIcon><ChevronDoubleLeft /></ListItemIcon>
            <ListItemText>Move Left</ListItemText>
          </MenuItem>
          )
        }
        <MenuItem onClick={this.duplicateSlide(overflowIndex, true)}>
          <ListItemIcon><ContentCopy /></ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
        </MenuItem>
        <MenuItem onClick={this.previewSlide(overflowIndex)}>
          <ListItemIcon><Download /></ListItemIcon>
          <ListItemText>Preview</ListItemText>
        </MenuItem>
        {(slides.length > 1)
        && (
        <MenuItem onClick={this.removeSlide(overflowIndex, true)}>
          <ListItemIcon><Delete /></ListItemIcon>
          <ListItemText>Remove</ListItemText>
        </MenuItem>
        )}
      </Menu>
    );
  }

  renderEmptyInbetween(index) {
    const { classes, canvas_util } = this.props;
    let aspect = canvas_util.getCanvasAspect();

    if (aspect === 0.0) {
      aspect = 1.0;
    }

    return (
      <div
        key={index + 0.5}
        className={classes.inbetween}
        style={{ width: (INBETWEEN_WIDTH / aspect) }}
      />
    );
  }

  renderFullInbetween(index) {
    const { classes, canvas_util } = this.props;
    const { busy_index } = this.state;
    const transition = (this.getTransition(index) || { });
    const type = (transition.type !== undefined ? transition.type : 'None');
    const time = (transition.duration !== undefined ? +transition.duration : 1);
    const { name } = TransitionsDict[type];
    let aspect = canvas_util.getCanvasAspect();

    if (aspect === 0.0) {
      aspect = 1.0;
    }

    const title = (
      type !== 'None'
        ? `${name} for ${time} second${time !== 1 ? 's' : ''}`
        : ''
    );

    return (
      <div
        key={index + 0.5}
        className={classes.inbetween}
        style={{ width: (INBETWEEN_WIDTH / aspect) }}
      >
        <Tooltip hidden={title.length === 0} title={title} PopperProps={{ style: { position: 'fixed' } }}>
          <IconButton
            disabled={busy_index >= 0}
            className={classes.inbetween_button}
            onClick={this.openTransitionMenu(index)}
          >
            <TransitionMasked />
          </IconButton>
        </Tooltip>
      </div>
    );
  }

  renderSlides() {
    const slides = this.getSlides();
    const elements = [];

    for (let i = 0; i < slides.length; ++i) {
      elements.push(this.renderPreviewSlide(i));

      if ((i + 1) < slides.length) {
        elements.push(this.renderFullInbetween(i));
      }
    }

    elements.push(this.renderEmptyInbetween(slides.length));
    elements.push(this.renderAddSlide(slides.length));

    for (let i = (slides.length + 1); i < 5; ++i) {
      elements.push(this.renderEmptyInbetween(i));
      elements.push(this.renderEmptySlide(i));
    }

    return elements;
  }

  renderQuickCreateSlides = () => {

    const slides = this.getSlides();
    const elements = [];


    for (let i = 0; i < 3; ++i) {

      elements.push(this.renderQuickCreatePreviewSlide(i));

      // if ((i + 1) < slides.length) {
      //   elements.push(this.renderFullInbetween(i));
      // }
    }

    // elements.push(this.renderEmptyInbetween(slides.length));

    for (let i = (slides.length + 1); i < 4; ++i) {
      // elements.push(this.renderEmptyInbetween(i));
      elements.push(this.renderEmptySlide(i));
    }

    return elements;
  }

  renderTransitionMenu() {
    const { classes, theme, variant } = this.props;
    const { transition, transitionAnchor } = this.state;
    const sliderColor = theme.palette.primary.light;

    if (transitionAnchor === null || transition < 0) {
      return null;
    }

    const data = this.getTransition(transition);
    const type = (data.type !== undefined ? data.type : 'None');
    const duration = (data.duration !== undefined ? data.duration : 0);
    const tint = calcTintColor(theme.palette.primary.main, TINT_ALPHA);
    const transitions = Object.keys(TransitionsDict);

    const heading = <Typography variant="subtitle1" className={classes.margin}>Transition</Typography>;

    const grid = (
      <Grid container className={classes.transitionGrid}>
        {transitions.map((e, i) => (
          <Grid item className={classes.transitionCell} key={i} xs={4}>
            <div
              className={classes.transitionPreview}
              style={{ backgroundImage: tintImage(`url('${TransitionsDict[e].preview}')`, tint, (e === type)) }}
            >
              <Button className={classes.transitionButton} onClick={() => this.changeTransition(transition, 'type', e)} />
            </div>
            <Typography variant="caption" className={classes.transitionCaption}>{TransitionsDict[e].name}</Typography>
          </Grid>
        ))}
      </Grid>
    );

    const controls = (
      type !== 'None'
      && (
      <div className={classes.transitionControls}>
        <Typography variant="subtitle1" className={classes.margin}>Duration</Typography>
        <div className={classes.margin}>
          <SliderWithInput
            trackStyle={[{ backgroundColor: sliderColor }]}
            handleStyle={[{ borderColor: sliderColor }]}
            onChange={(value) => this.changeTransition(transition, 'duration', value)}
            value={duration}
            min={1}
            max={10}
          />
        </div>
      </div>
      )
    );

    const actions = (
      <div className={classes.transitionActions}>
        <Button className={classes.transitionAction} onClick={() => this.applyTransition(transition)}>
          Apply to All
        </Button>
      </div>
    );

    switch (variant) {
      case 'cozy': {
        return (
          <Popover
            open={Boolean(transitionAnchor)}
            anchorEl={transitionAnchor}
            classes={{ paper: classes.popover }}
            onClose={this.closeTransitionMenu}
            elevation={0}
            anchorOrigin={{
              horizontal: 'center',
              vertical: 'center',
            }}
            transformOrigin={{
              horizontal: 'center',
              vertical: 'bottom',
            }}
          >
            <Paper className={classes.popoverContent} elevation={8}>
              <div className={classes.popoverArrow} />
              {heading}
              {grid}
              {controls}
              {actions}
            </Paper>
          </Popover>
        );
      }
      case 'compact': {
        return (
          <Popover
            open={Boolean(transitionAnchor)}
            anchorEl={transitionAnchor}
            onClose={this.closeTransitionMenu}
            elevation={8}
            anchorOrigin={{
              horizontal: 'center',
              vertical: 'center',
            }}
            transformOrigin={{
              horizontal: 'center',
              vertical: 'bottom',
            }}
          >
            <div className={classes.popoverContent}>
              {heading}
              {grid}
              {controls}
              {actions}
            </div>
          </Popover>
        );
      }
      default: {
        return null;
      }
    }
  }

  openDurationMenu = (e) => {
    this.setState({ durationAnchor: e.currentTarget });
  }

  closeDurationMenu = () => {
    this.setState({ durationAnchor: null });
  }

  renderDurationMenu() {
    const { classes, theme } = this.props;
    const { selected, durationAnchor } = this.state;
    const slide = this.getSlide();
    const sliderColor = theme.palette.primary.light;

    if (!slide || !durationAnchor) {
      return null;
    }

    const duration = (slide.duration || 5);

    return (
      <Popover
        open={Boolean(durationAnchor)}
        anchorEl={durationAnchor}
        classes={{ paper: classes.popover }}
        onClose={this.closeDurationMenu}
        elevation={0}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'center',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
      >
        <Paper className={classes.duration_content} elevation={8}>
          <div className={classes.popoverArrow} />
          <Slider
            autoFocus
            trackStyle={[{ backgroundColor: sliderColor }]}
            handleStyle={[{ borderColor: sliderColor }]}
            onChange={(e) => this.setSlideDuration(selected, e)}
            value={duration}
            min={1}
            max={30}
          />
        </Paper>
      </Popover>
    );
  }

  renderDuration(index) {
    const { classes, canvas_util } = this.props;
    const { selected, durationAnchor, busy_index } = this.state;
    const slide = this.getSlide(index);

    if (!slide) {
      return null;
    }

    const portrait = (canvas_util.getCanvasAspect() < 1.0);
    const duration = (slide.duration || 5);
    const minutes = Math.floor(duration / 60);
    const seconds = (duration % 60);
    const pad = (seconds < 10 ? '0' : '');
    const top = (index === selected ? '100%' : 0);
    const time_string = `${minutes}:${pad}${seconds}`;

    return (
      <div className={classes.duration} style={{ top }} tabIndex={-1}>
        {portrait ? (
          <Tooltip
            PopperProps={{ style: { position: 'fixed' } }}
            title={time_string}
            placement="top"
          >
            <Button
              fullWidth
              disableRipple
              disableFocusRipple
              disabled={busy_index >= 0}
              style={{ minWidth: 0 }}
              className={classes.durationButton}
              onClick={this.openDurationMenu}
            >
              <span
                className={classNames(
                  classes.portrait_duration_icon,
                  { [classes.portrait_duration_icon_hidden]: Boolean(durationAnchor) },
                )}
              >
                <ClockOutline />
              </span>
              <span className={classNames(
                classes.portrait_duration_text,
                { [classes.portrait_duration_text_hidden]: !durationAnchor },
              )}
              >
                {time_string}
              </span>
            </Button>
          </Tooltip>
        ) : (
          <Button
            fullWidth
            disableRipple
            disableFocusRipple
            className={classes.durationButton}
            onClick={this.openDurationMenu}
          >
            <ClockOutline className={classes.durationIcon} />
            {time_string}
          </Button>
        )}
      </div>
    );
  }

  closeSnackbar = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ snackbar: '' });
  }

  renderSnackbar() {
    const { classes } = this.props;
    const { snackbar } = this.state;
    const open = (snackbar !== '');

    return (
      <Snackbar
        open={open}
        onClose={this.closeSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={6000}
        message={snackbar}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={this.closeSnackbar}
          >
            <Close />
          </IconButton>,
        ]}
      />
    );
  }

  renderQuickCreateViews = () => {

    const { classes, open } = this.props;


    return (
        <div
            ref={this.root_element}
            className={classNames(classes.root, {
              [classes.root_shift]: (!open),
            })}
        >
          <div style={{ flex: 1, minWidth: 80 }} />
          <div style={{ display: 'flex' }}>
            {this.renderQuickCreateSlides()}
          </div>
          <div style={{ flex: 1, minWidth: 80 }} />
        </div>
    );
  }

  renderCozy() {
    const { classes, open } = this.props;
    const { busy_index } = this.state;

    return (
      <div
        ref={this.root_element}
        className={classNames(classes.root, {
          [classes.root_shift]: (!open),
        })}
      >
        <div style={{ flex: 1, minWidth: 80 }} />
        <div style={{ display: 'flex' }}>
          {this.renderSlides()}
        </div>
        <div style={{ flex: 1, minWidth: 80 }} />
        {this.renderOverflowMenu()}
        {this.renderTransitionMenu()}
        {this.renderPreviewDialog()}
        {this.renderDurationMenu()}
        {this.renderSnackbar()}
        <UploadingDialog open={busy_index >= 0} />
      </div>
    );
  }

  renderCompact() {
    const { classes, open } = this.props;
    const { selected } = this.state;
    const slides = this.getSlides();
    const variant = (calcViewWidth() >= 600 ? 'cozy' : 'compact');

    const leftButtons = (
      <>
        <IconButton
          disabled={selected === 0}
          onClick={this.scrollSlides(-1)}
        >
          <ArrowLeft />
        </IconButton>
        <IconButton
          disabled={selected === 0}
          onClick={this.openTransitionMenu(selected - 1)}
        >
          <TransitionMasked />
        </IconButton>
        <IconButton onClick={(e) => this.openSlideMenu(selected, e.currentTarget)}>
          <DotsVertical />
        </IconButton>
      </>
    );

    const stepperDots = (
      variant === 'compact' ? (
        <div className={classes.stepperDots}>
          <Typography className={classes.stepperText} variant="caption">
            {`${selected + 1}/${slides.length}`}
          </Typography>
        </div>
      ) : slides.length <= 15 ? (
        <div className={classes.stepperDots}>
          {slides.map((_, i) => (
            <div
              key={i}
              className={classNames(classes.stepperDot, {
                [classes.stepperDotActive]: (i === selected),
              })}
            />
          ))}
        </div>
      ) : (
        <LinearProgress
          className={classes.stepperProgress}
          value={((selected + 1) / slides.length) * 100}
          variant="determinate"
        />
      )
    );

    const rightButtons = (
      <>
        <IconButton onClick={this.addSlide(selected + 1)}>
          <Plus />
        </IconButton>
        <IconButton
          disabled={(selected + 1) >= slides.length}
          onClick={this.openTransitionMenu(selected)}
        >
          <TransitionMasked />
        </IconButton>
        <IconButton
          disabled={(selected + 1) >= slides.length}
          onClick={this.scrollSlides(1)}
        >
          <ArrowRight />
        </IconButton>
      </>
    );

    const stepperText = (
      variant === 'cozy'
      && (
      <Typography className={classNames(classes.stepperText, classes.bottom)} variant="caption" style={{ paddingRight: '80px' }}>
        {`Slide ${selected + 1} of ${slides.length}`}
      </Typography>
      )
    );

    return (
      <div className={classNames(classes.root, { [classes.root_shift]: !open })} style={{ paddingRight: '80px' }}>
        <div className={classes.stepper}>
          {leftButtons}
          {stepperDots}
          {rightButtons}
          {stepperText}
        </div>
        {this.renderOverflowMenu()}
        {this.renderTransitionMenu()}
        {this.renderPreviewDialog()}
        {this.renderDurationMenu()}
        {this.renderSnackbar()}
      </div>
    );
  }

  render() {
    const {quickcreate} = this.props
    return quickcreate? this.renderQuickCreateViews() : this.renderCozy();
  }
}

// -------------------------------------------------------------------------- //

export default withStyles(STYLES, { withTheme: true })(TimeLine);

// -------------------------------------------------------------------------- //
