
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Paper,
  Typography,
  withStyles,
  List,
  ListItem,
} from '@material-ui/core';

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  paper: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  item: {
    display: 'block',
  },
  video: {
    height: theme.spacing(40),
  },
  videoContainerContainer: {
    width: '400px',
  },
  videoContainer: {
    width: '100%',
    maxWidth: '400px',
    height: '0px',
    paddingBottom: '56.25%',
    position: 'relative',
  },
  videoPlayer: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
  }
});

// -------------------------------------------------------------------------- //

const RenderVideo = withStyles(STYLES)(({
  classes, video, ...props
}) => (
  <ListItem className={classes.item} {...props}>
    <Typography color="primary" variant="h6" component="h2" gutterBottom>
      {video.title}
    </Typography>
    <div className={classes.videoContainerContainer}>
      <div className={classes.videoContainer}>
        <iframe className={classes.videoPlayer}
                type="text/html"
                title={video.title}
                src={`https://www.youtube.com/embed/${video.src}?rel=0&modestbranding=1`}
                allowFullScreen
                frameBorder="0"/>
      </div>
    </div>
  </ListItem>
));
// https://ss3-assets.s3.us-east-2.amazonaws.com/tutorials/${video.src}.mp4
// -------------------------------------------------------------------------- //

const VIDEOS = [
  { title: 'Team Account Setup', src: 'q3JYh9s1DtQ' },
  { title: 'Media Cloud Storage', src: 'kAY5-q_MHuI' },
  { title: 'Template Creation', src: 'tBOGZPRUDF8' },
  { title: 'Adding Team Rosters', src: 'FlJPQls8u8c' },
  { title: 'Changing User Settings', src: '6DFq81h360E' },
];

// -------------------------------------------------------------------------- //

class ResourcesVideo extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.paper}>
        <Typography id="video-content" component="h1" variant="h4" gutterBottom>
          Video Tutorials
        </Typography>
        <List>
        {VIDEOS.map((video, index) => (
          <RenderVideo key={index} video={video}/>
        ))}
        </List>
      </Paper>
    );
  }
}

// -------------------------------------------------------------------------- //

export default withStyles(STYLES)(ResourcesVideo);

// -------------------------------------------------------------------------- //
