
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Button,
  IconButton,
  Popover,
  withStyles,
  withTheme,
} from '@material-ui/core';

import {
  Eyedropper as EyedropperIcon,
} from 'mdi-material-ui';

import { CustomPicker } from 'react-color';
import { graphql, compose } from 'react-apollo';
import { Saturation, Hue, Alpha, EditableInput } from 'react-color/lib/components/common';
import gql from 'graphql-tag';
import parseColor from 'parse-color';

import { ColorCursor } from './ColorCursor';
import { ColorSwatch } from './ColorSwatch';
import { ParseColor } from './parse';
import { Permissible, UserRoleConsumer } from '../user/Permissions';

// -------------------------------------------------------------------------- //

const GQL_GET_COLORS = gql`
  query gatherPaletteColors {
    me {
      id
      client {
        id
        color1
        color2
        color3
        palette
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  dropper_backdrop: {
    cursor: 'crosshair',
  },
  margin_below: {
    marginBottom: theme.spacing(1)
  },
});

// -------------------------------------------------------------------------- //

function ToHexRgb(rgb) {
  const r = rgb[0].toString(16).toUpperCase().padStart(2, '0');
  const g = rgb[1].toString(16).toUpperCase().padStart(2, '0');
  const b = rgb[2].toString(16).toUpperCase().padStart(2, '0');
  return `#${r}${g}${b}`;
}

// -------------------------------------------------------------------------- //

function ToHexRgba(rgba) {
  const r = rgba[0].toString(16).toUpperCase().padStart(2, '0');
  const g = rgba[1].toString(16).toUpperCase().padStart(2, '0');
  const b = rgba[2].toString(16).toUpperCase().padStart(2, '0');
  const a = (rgba[3] * 255).toString(16).toUpperCase().padStart(2, '0');
  return `#${r}${g}${b}${a}`;
}

// -------------------------------------------------------------------------- //

function ToCssRgb(rgb) {
  return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
}

// -------------------------------------------------------------------------- //

function ToCssRgba(rgba) {
  return `rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${rgba[3]})`;
}

// -------------------------------------------------------------------------- //

function CreateColorObj(color) {
  let array = [ color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a ];

  return {
    color: array,
    hex: ToHexRgb(array),
    hexa: ToHexRgba(array),
    rgb: ToCssRgb(array),
    rgba: ToCssRgba(array),
  };
}

// -------------------------------------------------------------------------- //

const FuzzyPumperPaletteShop = withTheme(CustomPicker((props) => {
  const {
    rgb, theme, palette, disableAlpha,
    onChange, onChangeComplete, onColorDropper,
  } = props;

  const Pointer = () => (
    <div style={{
      backgroundColor: 'rgb(248, 248, 248)',
      borderRadius: 6,
      boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.37)',
      height: 12,
      transform: 'translate(-6px, -1px)',
      width: 12,
    }}/>
  );

  const hex = ToHexRgb([ rgb.r, rgb.g, rgb.b, rgb.a ]);

  return (
    <>
      <Permissible condition={(permissions) => (
        permissions.get('team_palette:read') === 'ALL'
      )}>
        <div
          style={{
            width: '100%',
            paddingBottom: '75%',
            position: 'relative',
          }}
        >
          <Saturation {...props} style={{
            circle: {
              width: 8, height: 8, transform: 'translate(-4px, -4px)',
              cursor: 'crosshair',
            },
            pointer: { pointerEvents: 'none' },
            color: { cursor: 'crosshair' }
          }}/>
        </div>
        <div style={{ padding: theme.spacing(1) }}>
          <div style={{ display: 'flex', alignItems: 'end' }}>
            {
              (onColorDropper) &&
              <IconButton
                color={props.dropperColor || 'default'}
                size="small" style={{ marginRight: 8 }}
                onClick={() => onColorDropper()}
              >
                <EyedropperIcon/>
              </IconButton>
            }
            <div style={{ width: theme.spacing(4) }}>
              <ColorSwatch
                size={16} variant="circle" elevation={1}
                color={[ rgb.r, rgb.g, rgb.b, rgb.a ]}
                style={{ marginTop: 6 }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <div style={{ width: '100%', height: 10, marginBottom: theme.spacing(1), position: 'relative' }}>
                <Hue {...props} pointer={Pointer}/>
              </div>
              {
                (!disableAlpha) &&
                <div style={{ width: '100%', height: 10, marginBottom: theme.spacing(1), position: 'relative' }}>
                  <Alpha {...props} pointer={Pointer}/>
                </div>
              }
            </div>
          </div>
        </div>
      </Permissible>
      <div style={{
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        width: '100%', display: 'flex',
        marginBottom: theme.spacing(2),
      }}>
        <div style={{
          background: '#F0F0F0',
          height: '30px',
          width: '30px',
          borderRadius: '4px 0 0 4px',
          color: '#98A1A4',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>#</div>
        <EditableInput
          label={null}
          value={hex.substr(1)}
          onChange={(color) => {
            const { rgba } = ParseColor(`#${color}`);

            if (onChangeComplete) {
              onChangeComplete({
                rgb: { r: rgba[0], g: rgba[1], b: rgba[2], a: rgba[3] },
                hex: `#${color}`,
              });
            } else if (onChange) {
              onChange({
                rgb: { r: rgba[0], g: rgba[1], b: rgba[2], a: rgba[3] },
                hex: `#${color}`,
              });
            }
          }}
          style={{
            wrap: { flex: 1 },
            input: {
              width: '100%',
              fontSize: '14px',
              color: '#666',
              border: '0px',
              outline: 'none',
              height: '30px',
              boxShadow: 'inset 0 0 0 1px #F0F0F0',
              borderRadius: '0 4px 4px 0',
              paddingLeft: '8px',
            }
          }}
        />
      </div>
      {
        (!props.disablePalette) &&
        <div style={{
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          display: 'flex', flexWrap: 'wrap',
        }}>
        <ColorSwatch
          divProps={{
            onClick: () => onChange('rgba(0, 0, 0, 0)')
          }}
          size={16} variant="squircle"
          color={[ 0, 0, 0, 0 ]}
          style={{
            margin: '0 10px 10px 0',
            boxShadow: (
              (rgb.r === 0.0 && rgb.g === 0.0 && rgb.b === 0.0 && rgb.a === 0.0)
              ? 'inset 0 0 0 1px rgba(0, 0, 0, 0.15)'
              : theme.shadows[1]
            ),
            cursor: 'pointer',
        }}
        />
        {palette.map((color, index) => (
          <ColorSwatch
            divProps={{
              key: index,
              onClick: () => onChange(color)
            }}
            size={16} variant="squircle"
            color={parseColor(color).rgba}
            style={{
              margin: '0 10px 10px 0',
              boxShadow: (
                (color.toLowerCase() === hex)
                ? 'inset 0 0 0 1px rgba(0, 0, 0, 0.15)'
                : theme.shadows[1]
              ),
              cursor: 'pointer',
          }}
          />
        ))}
        </div>
      }
    </>
  );
}));

// -------------------------------------------------------------------------- //

class ColorPicker extends React.Component {

  state = {
    color_dropper: false,
    mouse_color: null,
    mouse_x: null,
    mouse_y: null,
    new_color: [ 0, 0, 0, 0 ],
    old_color: [ 0, 0, 0, 0 ],
    open: false,
  }

  pending_update = null

  componentDidUpdate(old_props) {
    let state = {}, update = false;

    if (!old_props.open && this.props.open) {
      state.new_color = parseColor(this.props.color);

      if (state.new_color) {
        state.new_color = state.new_color.rgba;
      } else {
        state.new_color = [ 0, 0, 0, 0 ];
      }

      state.old_color = state.new_color;
      state.color_dropper = false;
      state.mouse_x = null;
      state.mouse_y = null;
      state.mouse_color = null;
      update = true;
    }

    if (update) {
      this.setState(state);
    }
  }

  componentWillUnmount() {
    if (this.pending_update !== null) {
      window.cancelAnimationFrame(this.pending_update);
    }
  }

  render() {
    return (
      <UserRoleConsumer>
        {(permissions) => this.renderPicker(permissions)}
      </UserRoleConsumer>
    );
  }

  renderPicker = (permissions) => {
    const {
      classes,
      disableAlpha,
      disablePalette,
      hideClientColors
    } = this.props;

    let palette = [];

    if (!disablePalette) {
      palette = this.gatherPaletteColors(
        !hideClientColors, permissions
      );
    }

    let bottom_color = '', top_color = '';

    if (this.state.new_color) {
      bottom_color = ToCssRgba(this.state.new_color);
    }

    if (this.state.mouse_color !== null) {
      top_color = ToCssRgb(this.state.mouse_color);
    }

    return (
      <Popover
        open={this.props.open}
        anchorEl={this.props.anchorEl}
        anchorPosition={this.props.anchorPosition}
        anchorReference={this.props.anchorReference}
        marginThreshold={this.props.marginThreshold || 80.0}
        BackdropProps={{ style: {
          cursor: (this.state.color_dropper ? 'none' : 'default'),
          boxShadow: (this.state.color_dropper
            ? 'inset 360px 64px 0px 0 rgba(255, 255, 255, 0.5)'
            : ''
          )
        }, invisible: true, ref: (ref) => { this.backdrop_ref = ref; } }}
        onMouseMove={(e) => {
          this.showColorCursor(e);
        }}
        onMouseOut={() => {
          this.hideColorCursor();
        }}
        onClose={(e) => this.closePicker(e, 'okay')}
        PaperProps={{ style: { width: 200 } }}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'center',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'center',
        }}
        style={{zIndex: 9999991}}
      >
        <ColorCursor
          disableCursor={
            !(this.state.color_dropper) ||
            (this.state.mouse_x === null) ||
            (this.state.mouse_y === null)
          }
          disableRing={
            (this.state.mouse_color === null) ||
            (this.state.mouse_color[3] === 0.0)
          }
          left={this.state.mouse_x} top={this.state.mouse_y}
          topColor={top_color}
          bottomColor={bottom_color}
        />
        <FuzzyPumperPaletteShop
          disableAlpha={disableAlpha}
          disablePalette={disablePalette}
          palette={palette}
          color={bottom_color}
          dropperColor={this.state.color_dropper ? 'primary' : 'default'}
          onChange={(color) => {
            this.closeDropper();
            this.onChange(color);
          }}
          onChangeComplete={(color) => {
            this.closeDropper();
            this.onChangeComplete(color);
          }}
          onColorDropper={
            (!this.props.disableDropper) &&
            (this.props.canvas) &&
            (() => this.toggleDropper())
          }
        />
        <div style={{ padding: 8 }}>
          <Button
          onClick={() => this.cancelPicker()}
            fullWidth className={classes.margin_below}
            variant="outlined" color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => this.closePicker()}
            fullWidth variant="contained" color="primary"
          >
            Okay
          </Button>
        </div>
      </Popover>
    );
  }

  gatherPaletteColors = (include_client_colors, permissions) => {
    let palette = [];

    if (include_client_colors) {
      const { data } = this.props;

      if (data && data.me && data.me.client) {
        const { client } = data.me;
        const permission = permissions.get('team_palette:read');

        palette.push(client.color1, client.color2, client.color3);

        if (client.palette && client.palette.colors) {
          if (permission !== 'TEAM') {
            palette.push(...client.palette.colors);
          }
        }
      }
    }

    let unique = [];

    palette.forEach((color) => {
      let parsed = ParseColor(color).rgba;

      if (parsed[3] === 0.0) {
        return; // skip transparent colors
      }

      const index = unique.findIndex((existing) => {
        // don't consider alpha when comparing duplicates
        let match = true;

        for (let i = 0; i < 3; ++i) {
          if (parsed[i] !== existing[i]) {
            match = false;
            break;
          }
        }

        return match;
      });

      if (index < 0) {
        unique.push(parsed);
      }
    });

    return unique.map(ToHexRgb);
  }

  pickCanvasColor = (e) => {
    let { canvas } = this.props;
    const pointer = canvas.getPointer(e, true);
    const x = Math.round(pointer.x * canvas.getRetinaScaling());
    const y = Math.round(pointer.y * canvas.getRetinaScaling());
    return canvas.getContext().getImageData(x, y, 1, 1).data;
  }

  openPicker = (index, anchor_el) => {
    this.setState({
      open: true, index, anchor_el, color_dropper: false,
      mouse_x: null, mouse_y: null, mouse_color: null,
      old_color: parseColor(this.props.colors[index]).rgba,
    });
  }

  cancelPicker = () => {
    if (this.state.old_color !== null) {
      const color = this.state.old_color;

      this.onChangeComplete({
        rgb: { r: color[0], g: color[1], b: color[2], a: color[3] },
        hex: ToHexRgba(color),
      }, this.state.index);
    }

    this.closeDropper();

    if (this.props.onClose) {
      (this.props.onClose)({ reason: 'cancel' });
    }
  }

  closePicker = (e) => {
    if (this.state.color_dropper && this.props.canvas && e) {
      const color = this.pickCanvasColor(e);

      if (color.some(o => o !== 0)) {
        this.onChangeComplete({
          rgb: { r: color[0], g: color[1], b: color[2], a: color[3] },
          hex: ToHexRgba(color),
        });

        this.forceUpdate();
      } else {
        this.props.canvas.requestRenderAll();
      }
    } else {
      this.closeDropper();

      if (this.props.onClose) {
        const color = this.state.new_color;

        (this.props.onClose)({
          reason: 'okay', color: CreateColorObj({
            rgb: { r: color[0], g: color[1], b: color[2], a: color[3] },
            hex: ToHexRgba(color),
          }),
        });
      }
    }
  }

  toggleDropper = () => {
    if (this.state.color_dropper) {
      this.closeDropper();
    } else {
      this.openDropper();
    }
  }

  openDropper = () => {
    if (this.props.disableDropper || this.state.color_dropper) {
      return;
    }

    let { canvas } = this.props;

    if (canvas) {
      let active = canvas.getActiveObject();

      if (active) {
        active.hasControls = false;
        active.hasBorders = false;
        canvas.requestRenderAll();
      }
    }

    this.setState({
      color_dropper: true,
      mouse_x: null,
      mouse_y: null,
      mouse_color: null,
    });
  }

  closeDropper = () => {
    if (this.props.disableDropper || !this.state.color_dropper) {
      return;
    }

    let { canvas } = this.props;

    if (canvas) {
      let active = canvas.getActiveObject();

      if (active) {
        active.hasControls = true;
        active.hasBorders = true;
      }
    }

    this.setState({ color_dropper: false });
  }

  showColorCursor = (e) => {
    if (!this.state.color_dropper || e.target !== this.backdrop_ref) {
      return;
    }

    e.persist();

    if (this.pending_update !== null) {
      window.cancelAnimationFrame(this.pending_update);
    }

    this.pending_update = window.requestAnimationFrame(() => {
      this.setState({
        mouse_x: e.clientX, mouse_y: e.clientY,
        mouse_color: this.pickCanvasColor(e),
      });
    });
  }

  hideColorCursor = () => {
    if (!this.state.color_dropper) {
      return;
    }

    this.setState({ mouse_x: null, mouse_y: null, mouse_color: null });
  }

  onChange = (color) => {
    const { onChange } = this.props;
    let value = CreateColorObj(color);

    if (onChange) {
      onChange(value);
    }

    this.setState({ new_color: value.color });
  }

  onChangeComplete = (color) => {
    const { onChangeComplete } = this.props;
    let value = CreateColorObj(color);

    if (onChangeComplete) {
      onChangeComplete(value);
    }

    this.setState({ new_color: value.color });
  }

}

// -------------------------------------------------------------------------- //

export default compose(
  graphql(GQL_GET_COLORS),
  withStyles(STYLES),
)(ColorPicker);

// -------------------------------------------------------------------------- //
