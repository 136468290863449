import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const styles = {
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  listStyling : {
    listStyleType : "none"
  }
};

const PlanSelectCard = (props) => {
  const { classes, plan, description, tiers, selectedIndex,index,selectIndexFunct,selectPlanIdFunct  } = props;
  const selected = selectedIndex === index;
  const list = selected ? description.map(( o, i ) => <li key={i}>{o}</li>) : null;
  const _butBtn = selected ? tiers.map( ( o, i ) => <Button key={i} onClick={selectPlanIdFunct(o.id)} variant="contained" color="primary" >{o.name + "/" + o.price}</Button>) : null;
  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h4">
          {plan}
        </Typography>
        <ul className={classes.listStyling}>{list}</ul>
      </CardContent>
      <CardActions>
        {_butBtn}
        <Button size="small" onClick={selectIndexFunct}>Learn More</Button>
      </CardActions>
    </Card>
  );
}

PlanSelectCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PlanSelectCard);
