// -------------------------------------------------------------------------- //

import * as DataBinding from '../../canvas/lib/databinding';
import * as MetaData from '../../canvas/lib/metadata';
import RenderJob from "../../scoreshotsapi/RenderJob";

// -------------------------------------------------------------------------- //

export class WidgetPiece {

  constructor(fabric) {
    this.class = fabric.util.createClass(fabric.Object, {
      type: 'widget-piece',
      originX: 'left',
      originY: 'top',
      selectable: false,
      widget_box: null,
      widget_index: null,

      initialize(options = {}) {
        this.callSuper('initialize', options);
        this.loaded = false;
      },

      async load() {
        if (this.loaded) {
          return Promise.resolve(this);
        }

        return new Promise((resolve) => {
          let objects = this.canvas.getObjects();
          this.widget_children = [];

          for (let i = 0; i < objects.length; ++i) {
            const uuid = MetaData.getMetaData(objects[i], 'widget_uuid');
            const index = MetaData.getMetaData(objects[i], 'widget_index');

            if (uuid !== this.widget_box.widget_uuid) {
              continue;
            }

            if (index !== this.widget_index) {
              continue;
            }

            objects[i].widget_piece = this;
            this.widget_children.push(objects[i]);
          }
          RenderJob.remapCanvasColors(this.widget_json, this.canvas.colors);

          if (this.widget_children.length === 0) {
            fabric.util.enlivenObjects(
              this.widget_json.objects, (objects) => {
                this.widget_children = [...objects];
                const insertion = this.canvas.getObjects().indexOf(this);

                objects.forEach((object, index) => {
                  object.widget_piece = this;

                  MetaData.setMetaData(
                    object, 'widget_uuid', this.widget_box.widget_uuid
                  );

                  MetaData.setMetaData(
                    object, 'widget_index', this.widget_index
                  );

                  DataBinding.SetPlayerIndex(object, this.widget_index);

                  this.canvas.ignoreUndo = true;
                  this.canvas.insertAt(object, (insertion + index + 1));
                  this.canvas.ignoreUndo = false;
                });

                this.loaded = true;
                resolve(this);
              }
            );
          } else {
            this.loaded = true;
            resolve(this);
          }
        });
      },

      unload() {
        if (!this.loaded) {
          return;
        }

        this.widget_children.forEach((child) => {
          child.widget_piece = null;
          MetaData.clearMetaData(child, 'widget_uuid');
          MetaData.clearMetaData(child, 'widget_index');

          if (child.media && child.media.element) {
            this.canvas.ignoreUndo = true;
            this.canvas.remove(child.media.element);
            this.canvas.ignoreUndo = false;
          }

          this.canvas.ignoreUndo = true;
          this.canvas.remove(child);
          this.canvas.ignoreUndo = false;
        });

        this.widget_json = null;
        this.widget_box = null;
        this.widget_index = null;
        this.widget_children = [];
        this.loaded = false;
      },
      /**
       *
       * @param {*} width width of the element (required)
       * @param {*} height height of the element (required)
       * @param {*} orientation true if is vertical | false horizontal (optional)
       * @param {*} n number of items to be resized (optional)
       */
      resize(width, height, orientation, n) {

        if (!this.widget_children) {
          this.pendingResize = {
            width, height
          }

          return;
        }

        width = Math.floor(width);
        height = Math.floor(height)



        const scaledWidth = Math.floor(this.width * this.scaleX);
        const scaledHeight = Math.floor(this.height * this.scaleY);

        const scaleX = width / scaledWidth;
        const scaleY = height / scaledHeight;

        if (this.canvas && this.widget_children) {
          this.widget_children.forEach(child => {

            const relativeY = (child.top - this.top) / scaledHeight;
            const relativeX = (child.left - this.left) / scaledWidth;


            let childMediaScaleX = 0,childMediaScaleY = 0

            if(child.media && child.media.element){
              if(child.scaleX * scaleX > child.scaleX){
                childMediaScaleX = child.media.element.scaleX * 1
                childMediaScaleY = child.media.element.scaleY * 1
              }else{
                childMediaScaleX = child.media.element.scaleX * 1
                childMediaScaleY = child.media.element.scaleY * 1
              }
            }


            let left = this.left + (relativeX * width);
            let top = this.top + (relativeY * height);
            if (n) {
              if (orientation === false) {
                if ((child.hasOwnProperty('_text') || child.hasOwnProperty('_textLines'))
                  && n !== undefined) {
                  child.set({left: left, top: top})
                } else {
                  if ((child.angle > 45 && child.angle < 135) || (child.angle > 225 && child.angle < 315)) {
                    child.set({scaleX: child.scaleX * scaleY, scaleY: child.scaleY * scaleX, left: left, top: top})
                    if (child.media && child.media.element){
                      child.media.element.set({scaleX: child.scaleX * scaleY, scaleY: child.scaleY * scaleX})
                    }
                  } else {
                    child.set({scaleX: child.scaleX * scaleX, scaleY: child.scaleY * scaleY, left: left, top: top});
                    if (child.media && child.media.element){
                      child.media.element.set({scaleX: childMediaScaleX,scaleY: childMediaScaleY})
                    }
                  }
                }
              } else {
                if ((child.hasOwnProperty('_text') || child.hasOwnProperty('_textLines'))
                  && n !== undefined) {
                  child.set({left: left, top: top})
                } else {
                  if ((child.angle > 45 && child.angle < 135) || (child.angle > 225 && child.angle < 315)){
                    child.set({scaleX: child.scaleX * scaleY, scaleY: child.scaleY * scaleX, left: left, top: top});
                  }
                  else if(child.type === 'polygon'){
                    child.set({scaleX: child.scaleX * scaleX ,scaleY: child.scaleY * scaleY, left: left});
                  }
                  else {
                    child.set({scaleX: child.scaleX * scaleX ,scaleY: child.scaleY * scaleY, left: left, top: top});
                  }
                }
              }
            } else {
              child.set({scaleX: child.scaleX * scaleX, scaleY: child.scaleY * scaleY, left: left, top: top})
            } 
          })
        }


        this.set({width: width, height: height})
        this.setCoords()

      },
      move(new_x, new_y, new_w, new_h) {
        const old_x = this.left, old_y = this.top;
        const old_w = this.width, old_h = this.height;
        if (!new_w) { new_w = old_w; }
        if (!new_h) { new_h = old_h; }
        if (this.widget_children.length === 0) {
          return;
        }

        this.widget_children.forEach((child) => {
          let x = (child.left - old_x);
          let y = (child.top - old_y);
          child.set({left: (new_x + x), top: (new_y + y)});

          if (child.media && child.media.element) {
            x = (child.media.element.left - old_x) * new_w/old_w
            y = (child.media.element.top - old_y) * new_h/old_h
            child.media.element.set({left: (new_x + x), top: (new_y + y)})
          } 
        });
        this.set({
          left: new_x, top: new_y,
        });
        this.setCoords()
      },

      _render(ctx) {
        // if (this.visible) {
        //   ctx.rect(0, 0, this.width, this.height);
        //   ctx.stroke();
        // }
      },

      toObject(props) {
        props.push('widget_box', 'widget_index');

        let widget_box = null;
        let widget_index = null;

        if (this.widget_box !== null) {
          widget_box = this.widget_box.widget_uuid;
          widget_index = this.widget_index;
        }

        return fabric.util.object.extend(
          this.callSuper('toObject', props), {
            widget_box, widget_index,
          }
        );
      }

    });

    this.fromObject = (object, callback) => (
      fabric.Object._fromObject('WidgetPiece', object, callback)
    );
  }

};

// -------------------------------------------------------------------------- //
