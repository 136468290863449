
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Hidden,
  Paper,
  Typography,
  withStyles,
} from '@material-ui/core';

import classnames from 'classnames';

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  paper: {
    backgroundColor: theme.palette.error.main,
    borderRadius: 8,
    color: theme.palette.error.contrastText,
    left: 0,
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    position: 'fixed',
    top: 0,
    transform: 'translate(0%, -100px)',
    transition: theme.transitions.create('transform'),
    zIndex: 99999,
  },
  paper_focused: {
    transform: 'translate(0%, 0%)',
  },
  typography: {
    textDecoration: 'none',
  },
});

// -------------------------------------------------------------------------- //

class Component extends React.Component {

  state = {
    focused: false,
    hidden: false,
  }

  componentDidMount() {
    if (!document.getElementById('main-content')) {
      // this.setState({ hidden: true });
    }
  }

  render() {
    const { classes } = this.props;
    const { focused, hidden } = this.state;
  
    return (
      <Hidden xsUp={hidden}>
        <Paper
          elevation={6}
          className={classnames(
            classes.paper,
            { [classes.paper_focused]: focused },
          )}
        >
          <Typography
            color="inherit"
            variant="button"
            component="a"
            href="#main-content"
            title="Skip to main content"
            className={classes.typography}
            onFocus={() => this.setState({ focused: true })}
            onBlur={() => this.setState({ focused: false })}
          >
            Skip to main content
          </Typography>
        </Paper>
      </Hidden>
    );
  }

};

// -------------------------------------------------------------------------- //

export const Skipper = withStyles(STYLES)(Component);

// -------------------------------------------------------------------------- //
