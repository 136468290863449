
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  Magnify as MagnifyIcon,
} from 'mdi-material-ui';

import { Query, compose, graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';

// -------------------------------------------------------------------------- //

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  heading: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  tableRow: {
    cursor: 'pointer',
  },
  filter_control: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: theme.spacing(20),
  },
});

// -------------------------------------------------------------------------- //

const GET_ALL_TIERS = gql`
  query($where: AccountTierWhereInput) {
    accountTiers(where: $where) {
      id
    }
  }
`;

// -------------------------------------------------------------------------- //

const GET_TIERS = gql`
  query getTiers(
    $where: AccountTierWhereInput
    $orderBy: AccountTierOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    accountTiers (
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      id
      name
    }
  }
`;

// -------------------------------------------------------------------------- //

class TierTable extends React.Component {

  state = {
    rowsPerPage: 15,
    search: false,
    searchString: "",
    page: 0,
  }

  componentDidMount() {
    const { search_parameters } = this.props;

    if (search_parameters) {
      this.setState(search_parameters);
    }
  }

  componentWillUnmount() {
    const { search_parameters } = this.props;

    if (search_parameters) {
      Object.keys(search_parameters).forEach((key) => {
        search_parameters[key] = this.state[key];
      });
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleToggle = name => () => {
    this.setState({ [name]: !this.state[name] });
  }

  renderToolbar = () => {
    const { classes } = this.props;

    return (
      <>
        <Toolbar>
          <Typography variant="h6" className={classes.heading}>
            Tiers
          </Typography>
          <Tooltip id="tooltip-search" title="Search">
            <IconButton onClick={this.handleToggle('search')}>
              <MagnifyIcon/>
            </IconButton>
          </Tooltip>
          {
            (!!this.state.search) &&
            <TextField
              id="search"
              placeholder="Search by name"
              autoComplete="off"
              className={classes.textField}
              value={this.state.searchString}
              onChange={(e) => this.setState({ searchString: e.target.value })}
              margin="dense"
            />
          }
        </Toolbar>
      </>
    );
  }

  handleChangePage = (e, page) => {
    this.setState({ page });
  }

  handleChangeRowsPerPage = (e) => {
    let rowsPerPage = e.target.value;

    this.setState({ rowsPerPage });
  }

  calculateWhereInput = (filters) => {
    let where = {};

    if (filters.query !== '') {
      where.OR = [
        { name_contains: filters.query },
      ];
    }

    return where;
  }

  render() {
    const { classes, accountTier_info, history } = this.props;
    const { rowsPerPage, searchString, page } = this.state;

    let variables = {
      first: rowsPerPage,
      orderBy: "name_ASC",
      where: {},
      skip: (page * rowsPerPage)
    }

    variables.where = this.calculateWhereInput({
      query: searchString,
    });

    let accountTiers_count = 0;

    if (accountTier_info && accountTier_info.accountTiers && !accountTier_info.loading) {
      accountTiers_count = accountTier_info.accountTiers.length;
    }

    return (
      <Query
        query={GET_TIERS}
        networkPolicy="network-only"
        variables={variables}
      >
        {({ data, networkStatus }) => (
          <Paper>
            {this.renderToolbar()}
            <Divider/>
            <Table>
              {
                networkStatus === 7 ?
                  <TableBody>
                    {
                      data.accountTiers.map((accountTier) => (
                        <TableRow
                          hover key={accountTier.id}
                          className={classes.tableRow}
                        >
                          <TableCell
                            onClick={() => history.push(`/admin/tiers/${accountTier.id}`)}
                          >
                            {accountTier.name}
                          </TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody> :
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <CircularProgress/>
                      </TableCell>
                    </TableRow>
                  </TableBody>
              }
            </Table>
            {
              networkStatus === 7 ?
                <TablePagination
                  component="div"
                  count={accountTiers_count}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[ 10, 15, 20, 50 ]}
                  page={page}
                  backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                  nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                /> :
                null
            }

          </Paper>
        )}
      </Query>
    );
  }
}

// -------------------------------------------------------------------------- //

export default compose(
  graphql(GET_ALL_TIERS, {
    name: 'accountTier_info',
    options: { fetchPolicy: 'network-only' }
  }),
  withRouter,
  withStyles(STYLES),
)(TierTable);

// -------------------------------------------------------------------------- //
