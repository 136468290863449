
// -------------------------------------------------------------------------- //

import * as React from 'react';

import {
  Drawer,
  withStyles,
} from '@material-ui/core';

import { Route, Switch } from 'react-router-dom';

import { ClientSearchContext } from './Clients/search';
import ClientEditor from './Clients/ClientEditor';
import Clients from './Clients/Clients';
import TagManager from './TagManager/TagManager'
import { TierSearchContext } from './Tiers/search';
import TierEditor from './Tiers/TierEditor';
import Tiers from './Tiers/Tiers';
import Dashboard from './Dashboard';
import Fonts from '../../views/admin/Fonts';
import GraphicEditor from './GraphicEditor';
import Graphics from './Graphics';
import Logo from '../svg/Logo';
import Navigation from './Navigation';
import TemplateEditor from './TemplateEditor';
import Templates from './Templates';
import TextureEditor from './TextureEditor';
import Textures from './Textures';

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  content: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    position: 'relative',
    width: 1,
  },
  drawer: {
    position: 'relative',
  },
  drawer_paper: {
    position: 'relative',
    width: theme.spacing(30),
    height: '100%',
  },
  logo: {
    fill: 'rgb(107, 37, 135)',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    height: theme.spacing(7),
    left: '50%',
    top: '50%',
  },
  logo_container: {
    position: 'relative',
    height: theme.spacing(8),
  },
  root: {
    overflow: 'hidden',
    display: 'flex',
    height: '100vh', // FIXME parent divs aren't 100% view height
  },
});

// -------------------------------------------------------------------------- //

const ClientsBase = () => {
  let [ state ] = React.useState({
    rowsPerPage: 15,
    search: false,
    searchString: '',
    filter_open: false,
    filter_user: '',
    filter_email: '',
    filter_state: '',
    filter_status: '',
    filter_division: '',
    filter_expire_after: null,
    filter_expire_before: null,
    page: 0,
  });

  return (
    <ClientSearchContext.Provider value={state}>
      <Switch>
        <Route exact path="/admin/clients" render={(props) => (
          <Clients {...props}/>
        )}/>
        <Route path="/admin/clients/:client" render={(props) => (
          <ClientEditor
            mode="exists"
            id={props.match.params.client} {...props}
          />
        )}/>
      </Switch>
    </ClientSearchContext.Provider>
  );
}

const TiersBase = () => {
  let [ state ] = React.useState({
    rowsPerPage: 15,
    search: false,
    searchString: '',
    page: 0,
  });

  return (
    <TierSearchContext.Provider value={state}>
      <Switch>
        <Route exact path="/admin/tiers" render={(props) => (
          <Tiers {...props}/>
        )}/>
        <Route path="/admin/tiers/:tier" render={(props) => (
          <TierEditor
            mode="exists"
            id={props.match.params.tier} {...props}
          />
        )}/>
      </Switch>
    </TierSearchContext.Provider>
  );
}

// -------------------------------------------------------------------------- //

const Main = (props) => {
  const { classes } = props;
  const { location } = props;
  const divStatus = document.getElementById('intercom-container');
  if (location.pathname.match(/admin/)) {
    if (divStatus) {
      divStatus.style.display = 'none';
    }
  }

  return (
    <div className={classes.root}>
      <Drawer
        anchor="left"
        variant="permanent"
        className={classes.drawer}
        classes={{ paper: classes.drawer_paper }}
      >
        <div className={classes.logo_container}>
          <Logo className={classes.logo}/>
        </div>
        <Navigation />
      </Drawer>
      <div className={classes.content}>
        <Switch>
          <Route exact path="/admin/tagmanager" component={() => <TagManager/>}/>
          <Route exact path="/admin/templates" component={() => <Templates/>}/>
          <Route exact path="/admin/premium" component={() => <Templates premium/>}/>
          <Route exact path="/admin/widgets" component={() => <Templates widgets/>}/>
          <Route path="/admin/graphics/:graphic" render={(props) => (
            <GraphicEditor
              key={props.match.params.graphic}
              id={props.match.params.graphic} {...props }
            />
          )}/>
          <Route path="/admin/graphics" component={Graphics} />
          <Route path="/admin/textures/:texture" render={(props) => (
            <TextureEditor
              key={props.match.params.texture}
              id={props.match.params.texture} {...props }
            />
          )}/>
          <Route path="/admin/textures" component={Textures} />
          <Route path="/admin/templates/:template" render={(props) => (
            <TemplateEditor
              mode="exists"
              key={props.match.params.template}
              id={props.match.params.template} {...props}
            />
          )}/>
          <Route path="/admin/clients" component={ClientsBase}/>
          <Route path="/admin/tiers" component={TiersBase}/>
          <Route path="/admin/fonts" component={Fonts}/>
          <Route component={Dashboard}/>
        </Switch>
      </div>
    </div>
  );
};

// -------------------------------------------------------------------------- //

export default withStyles(STYLES)(Main);

// -------------------------------------------------------------------------- //
