
// -------------------------------------------------------------------------- //

import AttributePath from './AttributePath';
import SCSportImporter from './SCSportImporter';
import { SplitPlayerName } from './Sports';

// -------------------------------------------------------------------------- //

class SCSoccerImporter extends SCSportImporter {

  constructor() {
    super('sogame');
  }

  import(sport, xml, options = {}) {
    if (!super.import(sport, xml, options)) {
      return false;
    }

    this.importTeam('home', this.ids[0]);
    this.importTeam('visiting', this.ids[1]);

    for (let i = 0; i < 2; ++i) {
      this.importTeamStats(i);
    }
  }

  importTeam(key, id) {
    const team_id = `team.${key}`;
    const team_path = `/${this.game}/team[@id='${id}']`;

    let team = {
      name: this.get(`${team_id}.name`),
      players: [],
      key: id,
    };

    for (let i = 0;; ++i) {
      const player_path = `${team_path}/player[*][@name!='TEAM'][${i + 1}]`;

      if (!this.test(new AttributePath(player_path, 'name'))) {
        break;
      }

      team.players.push({
        name: SplitPlayerName(this.peek(new AttributePath(player_path, 'name'), { type: 'string' })),
        position: '',
        key: this.peek(new AttributePath(player_path, 'uni')),
      });
    }

    this.sport.teams.push(team);
  }

  importTeamStats(team) {
    // const vh = (team === 0 ? 'H' : 'V');
    const path = `/${this.game}/team[@id='${this.ids[team]}']`;
    const linescore = `${path}/linescore`;
    const totals_shots = `${path}/totals/shots`;
    // const totals_goaltype = `${path}/totals/goaltype`;
    const totals_penalty = `${path}/totals/penalty`;
    // const totals_misc = `${path}/totals/misc`;
    const totals_goalie = `${path}/totals/goalie`;
    const team_id = `team.${team === 0 ? 'home' : 'visiting'}`;

    const team_record = this.peek(new AttributePath(path, 'record'), { default: '' });
    const team_records = team_record.match(/\d+(-\d+)+/g);

    if (team_records && team_records.length > 0) {
      this.set(`${team_id}.record`, team_records[0]);
    }

    this.select(`${team_id}.score`, new AttributePath(totals_shots, 'g'));
    this.select(`${team_id}.assists`, new AttributePath(totals_shots, 'a'));
    this.select(`${team_id}.shots`, new AttributePath(totals_shots, 'sh'));
    this.select(`${team_id}.shots.goal`, new AttributePath(totals_shots, 'sog'));
    this.select(`${team_id}.fouls`, new AttributePath(totals_penalty, 'fouls'));
    this.select(`${team_id}.penalty.goal`, new AttributePath(totals_penalty, 'ps'));
    this.select(`${team_id}.penalty.attempts`, new AttributePath(totals_penalty, 'psatt'));

    const saves = this.select(
      `${team_id}.saves`, new AttributePath(totals_goalie, 'saves')
    );

    const goals_allowed = this.select(
      `${team_id}.goals.allowed`, new AttributePath(totals_goalie, 'ga')
    );

    this.set(
      `${team_id}.saves.percent`,
      saves / (saves + goals_allowed)
    );

    this.setPercent(
      `${team_id}.shots.percent`,
      `${team_id}.score`,
      `${team_id}.shots.goal`,
    );

    this.setPercent(
      `${team_id}.penalty.percent`,
      `${team_id}.penalty.goal`,
      `${team_id}.penalty.attempts`
    );

    let corners = 0, corner_goals = 0, offsides = 0;

    for (let period = 0; period < 8; ++period) {
      const prd = (period + 1);
      const period_id = `period${prd}`;
      const lineprd = `${linescore}/lineprd[@prd='${prd}']`;
      this.select(`${team_id}.score.${period_id}`, new AttributePath(lineprd, 'score'));
      this.select(`${team_id}.shots.${period_id}`, new AttributePath(lineprd, 'shots'));
      this.select(`${team_id}.saves.${period_id}`, new AttributePath(lineprd, 'saves'));
      this.select(`${team_id}.fouls.${period_id}`, new AttributePath(lineprd, 'fouls'));

      corners += this.select(
        `${team_id}.corners.${period_id}`,
        new AttributePath(lineprd, 'corners')
      );

      offsides += this.select(
        `${team_id}.offsides.${period_id}`,
        new AttributePath(lineprd, 'offsides')
      );

      const plays_path = `/${this.game}/plays/period[@number='${prd}']`;

      if (this.test(new AttributePath(plays_path, 'number'))) {
        let goal_time = null, count = 0;

        for (let play = 0;; ++play) {
          const play_path = `${plays_path}/play[@team='${this.ids[team]}'][${play + 1}]`;

          if (!this.test(new AttributePath(play_path, 'team'))) {
            break;
          }

          const action = this.peek(new AttributePath(play_path, 'action'));
          const clock = this.peek(new AttributePath(play_path, 'clock'));
          let timestamp = null;

          if (clock) {
            const clock_minutes = Number(clock.substr(0, 2));
            const clock_seconds = Number(clock.substr(3, 2));
            timestamp = (60 * clock_minutes + clock_seconds);
          }

          if (action === 'CORNER') {
            goal_time = timestamp;
          } else if (action === 'GOAL') {
            if (goal_time !== null && (timestamp - goal_time) <= 8) {
              ++corner_goals;
            }
          }
        }

        this.set(`${team_id}.corners.goals.${period_id}`, count);
        this.set(`${team_id}.penalty.corners.${period_id}`, count);
        corner_goals += count;
      }
    }

    this.set(`${team_id}.corners`, corners);
    this.set(`${team_id}.corners.goals`, corner_goals);
    this.set(`${team_id}.penalty.corners`, corners);
    this.set(`${team_id}.offsides`, offsides);
  }

}

// -------------------------------------------------------------------------- //

export default SCSoccerImporter;

// -------------------------------------------------------------------------- //
