
// -------------------------------------------------------------------------- //

import React from 'react';

import { compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment';

import Facebook from '../components/share/Facebook';
import ScoreshotsPut from './Put';

// -------------------------------------------------------------------------- //

const GQL_CREATE_POST = gql`
mutation (
  $scheduled: Boolean!,
  $post_time: DateTime,
  $template_id: ID,
  $width: Int,
  $height: Int,
  $bucket: String,
  $media: Json,
  $job: Json
) {
  createPost(
    scheduled: $scheduled,
    postTime: $post_time,
    templateId: $template_id,
    width: $width,
    height: $height,
    bucket: $bucket,
    media: $media,
    job: $job
  ) {
    id
    client {
      id
    }
  }
}
`;

// -------------------------------------------------------------------------- //

const GQL_ADD_MEDIA = gql`
mutation ($id: ID!, $media: Json!) {
  addMediaToPost(postId: $id, media: $media) {
    id
    media
  }
}
`;

// -------------------------------------------------------------------------- //

const GQL_ADD_FACEBOOK = gql`
mutation(
  $parent_id: ID!,
  $message: String,
  $post_id: String,
  $page_id: ID!,
  $media: Json!
) {
  addFacebookToPost(
    parentPostId: $parent_id,
    postId: $post_id,
    message: $message,
    fbId: $page_id,
    media: $media
  ) {
    id
    postId
  }
}
`;

// -------------------------------------------------------------------------- //

const GQL_ADD_TWITTER = gql`
  mutation(
    $parent_id: ID!,
    $message: String,
    $retweet: Boolean!,
    $twitter_id: ID!,
    $media: Json!
  ) {
    addTwitterToPost(
      parentPostId: $parent_id,
      message: $message,
      retweet: $retweet,
      twitterId: $twitter_id,
      media: $media
    ) {
      id
      postId
      account {
        id
        screen_name
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const GQL_ADD_JOB = gql`
  mutation($post_id: ID!, $job: Json!) {
    addJobToPost(job: $job, postId: $post_id) {
      id
    }
  }
`;

// -------------------------------------------------------------------------- //

const PNG_SIZE_THRESHOLD = (1024 * 1024); // 1MB

// -------------------------------------------------------------------------- //

/*

  Job format:

  {

    facebook_pages: [
      {
        id: ID, // id of page in our database
        facebook_id: ID, // id of page in facebook
        token: string, // authentication token
        message: string, // message contents
      }, ...
    ],

    twitter_post: {
      id: ID, // id of account in our database
      twitter_id: ID, // id of account in twitter
      token: string, // oauth token
      secret: string, // oauth token secret
      message: string, // message contents
    } || null,

    twitter_retweets: [
      {
        id: ID, // id of account in our database
        twitter_id: ID, // id of account in twitter
        token: string, // oauth token
        secret: string, // oauth token secret
      }, ...
    ],

    eta: Date || null, // post date/time for scheduled or null for immediate

  }

*/

// -------------------------------------------------------------------------- //

class Share_ extends React.Component {

  render() {
    const { children } = this.props;

    if (children) {
      return children(async (job) => this.share(job));
    }

    return null;
  }

  share = async (job) => {
    let post = null, media = null;
    let action = new ScoreshotsPut('ss3-posts');
    let png_size = 0, jpg_size = 0;

    this.callStart(job);

    return this.props.gql_create_post({
      variables: {
        scheduled: (job.eta !== null),
        template_id: job.template,
        width: job.width,
        height: job.height,
        post_time: (job.eta !== null ? job.eta : moment().format()),
        bucket: 'ss3-posts',
      }
    }).then((response) => {
      post = response.data.createPost;
      return fetch(`${job.png}?t=${new Date().getTime()}`);
    }).then((response) => (
      response.blob()
    )).then((blob) => {
      png_size = blob.size;
      return action.put(blob, 'image/png', null, `${post.id}.png`);
    }).then(() => (
      fetch(`${job.jpg}?t=${new Date().getTime()}`)
    )).then((response) => (
      response.blob()
    )).then((blob) => {
      jpg_size = blob.size;
      return action.put(blob, 'image/jpg', null, `${post.id}.jpg`);
    }).then(() => {
      action.put(
        new Blob([ job.json ], { type: 'application/json' }),
        'application/json', null, `${post.id}.json`
      )
    }).then(() => {
      media = {
        type: 'image',
        png: `https://ss3-posts.s3.us-east-2.amazonaws.com/${post.client.id}/${post.id}.png`,
        pngSize: png_size,
        jpg: `https://ss3-posts.s3.us-east-2.amazonaws.com/${post.client.id}/${post.id}.jpg`,
        jpgSize: jpg_size,
        mp4: job.mp4,
      };

      window.Intercom('trackEvent', 'make-post', {
        media, scheduled: (job.eta !== null),
      });

      return this.props.gql_add_media({
        variables: {
          id: post.id,
          media: {
            jpg: media.jpg,
            png: media.png,
            mp4: media.mp4,
          },
        }
      });
    }).then(() => {
      if (job.eta !== null) {
        let twitter_message = '';
        let twitter_account = null;

        if (job.twitter_post !== null) {
          twitter_message = job.twitter_post.message;

          twitter_account = {
            id: job.twitter_post.id,
            name: job.twitter_post.name,
            screen_name: job.twitter_post.username,
            twitterId: job.twitter_post.twitter_id,
          };
        }

        return this.props.gql_add_job({
          variables: {
            post_id: post.id,
            job: {
              media,
              fbPages: job.facebook_pages.map((page) => ({
                id: page.id,
                fbId: page.facebook_id,
                name: page.name,
                accessToken: page.token,
              })),
              twitterAccount: twitter_account,
              twitterRetweet: job.twitter_retweets.map((retweet) => ({
                id: retweet.id,
                name: retweet.name,
                screen_name: retweet.username,
                twitterId: retweet.twitter_id,
              })),
              message: twitter_message,
            },
          }
        });
      }

      return Promise.resolve();
    }).then(() => {
      let promises = [];

      let media_url = null, facebook_url = null;
      const is_video = Boolean(media.mp4);

      if (is_video) {
        media_url = media.mp4;
      } else if (media.pngSize < PNG_SIZE_THRESHOLD) {
        media_url = media.png;
      } else {
        media_url = media.jpg;
      }

      if (job.facebook_pages.length !== 0) {
        if (job.eta !== null) {
          promises.push(
            ...job.facebook_pages.map((page) => (
              new Promise((resolve) => {
                this.callUpdate({
                  type: 'facebook', id: page.id,
                  page, status: 'inflight',
                  post: null,
                });

                resolve();
              }).then(() => (
                Facebook.schedulePost(
                  page.token, page.facebook_id,
                  (is_video ? null : media_url),
                  (is_video ? media_url : null),
                  job.eta, page.message,
                  (is_video ? 'videos' : 'photos')
                )
              )).then((response) => (
                this.props.gql_add_facebook({
                  variables: {
                    media: JSON.stringify(media),
                    parent_id: post.id,
                    page_id: page.facebook_id,
                    post_id: response.id,
                    message: page.message,
                  }
                })
              )).then((response) => {
                this.callUpdate({
                  type: 'facebook', id: page.id,
                  page, status: 'delivered',
                  post: null,
                });

                return response;
              })
            ))
          );
        } else {
          promises.push(
            ...job.facebook_pages.map((page) => (
              new Promise((resolve) => {
                this.callUpdate({
                  type: 'facebook', id: page.id,
                  page, status: 'inflight',
                  post: null, url: null,
                });

                resolve();
              }).then(() => (
                (media.mp4 ? Facebook.postVideo(
                  page.token, page.facebook_id, media.mp4, page.message
                ) : Facebook.postPhoto(
                  page.token, page.facebook_id, (
                    (media.pngSize < PNG_SIZE_THRESHOLD)
                    ? media.png : media.jpg
                  ), page.message
                ))
              )).then((response) => {
                if (response.link) {
                  facebook_url = response.link;
                }

                return (
                  this.props.gql_add_facebook({
                    variables: {
                      media: JSON.stringify(media),
                      parent_id: post.id,
                      page_id: page.facebook_id,
                      post_id: response.id,
                      message: page.message,
                    }
                  })
                );
              }).then((response) => {
                this.callUpdate({
                  type: 'facebook', id: page.id,
                  page, status: 'delivered',
                  post: {
                    id: response.data.addFacebookToPost.postId,
                  url: facebook_url,
                  },
                });

                return response;
              })
            ))
          );
        }
      }

      if (job.twitter_post !== null) {
        if (job.eta !== null) {
          let data = new FormData();

          data.append('body', JSON.stringify({
            timestamp: moment(job.eta).unix(),
            mode: 'dev', tweet: {
              media: JSON.stringify(media),
              twitterId: job.twitter_post.twitter_id,
              parentPostId: post.id,
              message: job.twitter_post.message,
              retweet: false,
            },
          }));

          promises.push(
            new Promise((resolve) => {
              this.callUpdate({
                type: 'twitter',
                id: job.twitter_post.id,
                account: job.twitter_post,
                status: 'inflight',
                post: null,
              });

              resolve();
            }).then(() => fetch(
              `${process.env.REACT_APP_SS_API_ENDPOINT}v1/__Twitter_Scheduling__c/schedule/dev/`, {
                method: 'POST',
                mode: 'cors',
                body: data,
              }
            )).then(() => {
              this.callUpdate({
                type: 'twitter',
                id: job.twitter_post.id,
                account: job.twitter_post,
                status: 'delivered',
                post: null,
              });
            }).then(() => Promise.all(
              job.twitter_retweets.map((retweet) => {
                let data = new FormData();

                data.append('body', JSON.stringify({
                  timestamp: moment(job.eta).unix(),
                  mode: 'dev', tweet: {
                    media: JSON.stringify(media),
                    twitterId: retweet.id,
                    parentPostId: post.id,
                    retweet: true,
                  },
                }));

                this.callUpdate({
                  type: 'twitter',
                  id: retweet.id,
                  account: retweet,
                  status: 'inflight',
                  post: null,
                });

                return fetch(
                  `${process.env.REACT_APP_SS_API_ENDPOINT}v1/__Twitter_Scheduling__c/schedule/dev/`, {
                    method: 'POST',
                    mode: 'cors',
                    body: data,
                  }
                ).then(() => {
                  this.callUpdate({
                    type: 'twitter',
                    id: retweet.id,
                    account: retweet,
                    status: 'delivered',
                    post: null,
                  });
                })
              })
            ))
          );
        } else {
          let data = new FormData();
  
          data.append('body', JSON.stringify({
            access_token_key: job.twitter_post.token,
            access_token_secret: job.twitter_post.secret,
            account: job.twitter_post.twitter_id, media
          }));
  
          promises.push(
            new Promise((resolve) => {
              this.callUpdate({
                type: 'twitter',
                id: job.twitter_post.id,
                account: job.twitter_post,
                status: 'inflight',
                post: null,
              });
              resolve();
            }).then(() => (
                fetch(
                  `${process.env.REACT_APP_SS_API_ENDPOINT}v1/Twitter/upload-twitter-media/prod/`, {
                  method: 'POST',
                  body: data,
                  mode: 'cors'
                }
              )
            )).then((response) => (
              response.json()
            )).then((json) => (
              this.props.gql_add_twitter({
                variables: {
                  media: JSON.stringify({
                    id: json.media_id.media_id_string
                  }),
                  twitter_id: job.twitter_post.twitter_id,
                  parent_id: post.id,
                  message: job.twitter_post.message,
                  retweet: false,
                }
              })
            )).then((response) => {
              const id = response.data.addTwitterToPost.postId;

              this.callUpdate({
                type: 'twitter',
                id: job.twitter_post.id,
                account: job.twitter_post,
                status: 'delivered',
                post: {
                  id, url: `https://twitter.com/${job.twitter_post.username}/status/${id}`
                },
              });

              return response;
            }).then(() => (
              Promise.all(job.twitter_retweets.map((retweet) => (
                new Promise((resolve) => {
                  this.callUpdate({
                    type: 'twitter',
                    id: retweet.id,
                    account: retweet,
                    status: 'inflight',
                    post: null,
                  });

                  resolve();
                }).then(() => (
                  this.props.gql_add_twitter({
                    variables: {
                      media: JSON.stringify(media),
                      twitter_id: retweet.twitter_id,
                      parent_id: post.id,
                      retweet: true,
                    }
                  })
                )).then((response) => {
                  const id = response.data.addTwitterToPost.postId;

                  this.callUpdate({
                    type: 'twitter',
                    id: retweet.id,
                    account: retweet,
                    status: 'delivered',
                    post: {
                      id, url: `https://twitter.com/${retweet.username}/status/${id}`
                    },
                  });

                  return response;
                })
              ))
            )))
          );
        }
      }

      return Promise.all(promises);
    }).then(() => {
      this.callComplete({});
    });
  }

  callStart = (e) => {
    const { onShareStart } = this.props;
    (onShareStart && onShareStart(e));
  }

  callUpdate = (e) => {
    const { onShareUpdate } = this.props;
    (onShareUpdate && onShareUpdate(e));
  }

  callComplete = (e) => {
    const { onShareComplete } = this.props;
    (onShareComplete && onShareComplete(e));
  }

}

// -------------------------------------------------------------------------- //

export const Share = compose(
  graphql(GQL_CREATE_POST, { name: 'gql_create_post' }),
  graphql(GQL_ADD_MEDIA, { name: 'gql_add_media' }),
  graphql(GQL_ADD_JOB, { name: 'gql_add_job' }),
  graphql(GQL_ADD_FACEBOOK, { name: 'gql_add_facebook' }),
  graphql(GQL_ADD_TWITTER, { name: 'gql_add_twitter' }),
)(Share_);

// -------------------------------------------------------------------------- //
