import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Form, Field } from 'react-final-form';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dropzone from 'react-dropzone';
import TextField from '../material/TextField';
import ScoreshotsPut from '../../scoreshotsapi/Put';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { ROLES_QUERY, UserPermissions } from '../user/Permissions';


const styles = theme => ({
  paper: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  dropzone: {
    width: '100%',
    height: 200,
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    border: '1px dashed #DDD',
    cursor: 'pointer',
    '&:hover': {
      border: '1px dashed #333',
    },
  },
  autofill: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

const GET_CLIENT = gql`
  query getClient {
    me {
      client {
        name
        nickName
        phone
        state
        zip
        city
        address
        id
        color1
        color2
        color3
        logoBucket
        logoKey
        disableAutofill
      }
      role {
        ${ROLES_QUERY}
      }
      id
    }
  }
`;

const UPDATE_CLIENT = gql`
  mutation updateClient($name: String, $nickName: String, $address: String, $city: String, $zip: String, $state: String, $phone: String, $logoBucket: String, $logoKey: String, $disableAutofill: Boolean) {
    setMyClient(
      name: $name
      nickName: $nickName
      address: $address,
      city: $city,
      zip: $zip,
      state: $state,
      phone: $phone,
      logoBucket: $logoBucket,
      logoKey: $logoKey,
      disableAutofill: $disableAutofill
    ) {
      id
      name
      nickName
      address
      city
      zip
      state
      phone
      logoBucket
      logoKey
      disableAutofill
    }
  }
`;

class SettingGeneral extends Component {

  constructor() {
    super();

    this.state = {
      autofill: false,
      autofillLoaded: false,
    }
  }

  componentDidMount() {
    const { data } = this.props;
    data.refetch().then((response) => {
      if (response.data && response.data.me && response.data.me.client) {
        this.setState({
          autofill: Boolean(response.data.me.client.disableAutofill),
        });
      }
    });
  }

  onDrop = self => (event) => {
    const { data, updateClient } = self.props;
    if (data && data.me.client) {
      const time = Math.round((new Date()).getTime() / 1000);
      const params = {
        Body: event[0],
        Key: `${time}.png`,
      };
      const action = new ScoreshotsPut("ss3-clientmedia", params.Key);
      action.put(params.Body, "image/png", () => {
        updateClient({
          variables: {
            logoBucket: 'ss3-clientmedia',
            logoKey: `${data.me.client.id}/${time}.png`,
          },
        })
          .then(() => {
            self.props.setMessage('Updated Team Logo', 2500);
          });
      });
    }
  }
  saveSettings = self => (settings) => {
    settings = {
      ...settings,
      disableAutofill: this.state.autofill,
    };
    self.props.updateClient({
      variables: settings,
    })
      .then(() => {
        self.props.setMessage('Saved General Settings', 2500);
        window.Intercom("trackEvent","save-team-info");
      });
  }

  render() {
    const { classes, data } = this.props;
    let initialData = {};
    let saveDisabled = false;
    if (data.me) {
      const { client } = data.me;
      initialData = {
        name: client.name,
        nickName: client.nickName,
        phone: client.phone,
        state: client.state,
        zip: client.zip,
        city: client.city,
        address: client.address,
      };
      const user_permissions = new UserPermissions(data.me.role);

      if (user_permissions.get('team:remove_general_settings')) {
        saveDisabled = true;
      }
    }


    return (
      <Paper id="generalSettings" className={classes.paper} data-tour="step-2">
        <Typography id="main-content" variant="h4" component="h2" gutterBottom>
          General Team Information
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={5} md={6} lg={7}>
            <Typography variant="h6" component="h3" gutterBottom>
              Your Team Logo
            </Typography>
            <Typography variant="subtitle1" component="h4" gutterBottom>
              {'We use your team logo to customize your graphics. Drop a PNG or click below. You can add more variant versions in your media library.'}
            </Typography>
            <Dropzone
              multiple={false}
              className={classes.dropzone}
              onDrop={this.onDrop(this)}
              accept="image/png"
              disabled={saveDisabled}
              style={
                  {
                    backgroundImage: (
                      data &&
                      data.me &&
                      data.me.client.logoKey &&
                      data.me.client.logoBucket ?
                      `url('https://${data.me.client.logoBucket}.s3.us-east-2.amazonaws.com/${data.me.client.logoKey}')` :
                      'none'
                    ),
                  }
                }
            />
            <FormControlLabel
              control={
                <Checkbox
                  disabled={saveDisabled}
                  checked={this.state.autofill}
                  onChange={(e) => this.setState({ autofill: e.target.checked })}
                  color="primary"
                  style={{ verticalAlign: 'top', textAlign: 'top' }}
                />
              }
              label={
                <Typography variant="subtitle1" component="h5">
                  Disable auto-fill of team logo and team name on templates.
                </Typography>
              }
              className={classes.autofill}
              style={{ verticalAlign: 'top', textAlign: 'top' }}
            />
          </Grid>
          <Grid item xs={12} sm={7} md={6} lg={5}>
            <Typography variant="h6" component="h3" gutterBottom>
                Team Info
            </Typography>
            <Typography variant="subtitle1" component="h4">
                We use this information for billing and contact.
            </Typography>
            <Form
              onSubmit={this.saveSettings(this)}
              initialValues={initialData}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    name="name"
                    className={classes.formField}
                    component={TextField}
                    id="name"
                    label="Organization"
                    style={{ marginTop: 10 }}
                    disabled={saveDisabled}

                  />
                  <Field
                    name="nickName"
                    className={classes.formField}
                    component={TextField}
                    id="nickName"
                    label="Team Name"
                    style={{ marginTop: 10 }}
                    disabled={saveDisabled}
                  />
                  <Field
                    name="address"
                    className={classes.formField}
                    component={TextField}
                    id="address"
                    label="Address"
                    style={{ marginTop: 10 }}
                    disabled={saveDisabled}

                  />
                  <Field
                    name="city"
                    className={classes.formField}
                    component={TextField}
                    id="city"
                    label="City"
                    style={{ marginTop: 10 }}
                    disabled={saveDisabled}

                  />
                  <Field
                    name="zip"
                    className={classes.formField}
                    component={TextField}
                    id="zip"
                    label="Zip"
                    style={{ marginTop: 10 }}
                    disabled={saveDisabled}

                  />
                  <Field
                    name="state"
                    className={classes.formField}
                    component={TextField}
                    id="state"
                    label="State"
                    style={{ marginTop: 10 }}
                    disabled={saveDisabled}

                  />
                  <Field
                    name="phone"
                    className={classes.formField}
                    component={TextField}
                    id="phone"
                    label="Phone"
                    style={{ marginTop: 10 }}
                    type="tel"
                    disabled={saveDisabled}

                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    fullWidth
                    style={{ marginTop: 10 }}
                    disabled={saveDisabled}
                    aria-label="Save"
                  >
                      Save
                  </Button>
                </form>
                )}
            />
          </Grid>

        </Grid>

      </Paper>
    );
  }
}


export default compose(
  graphql(GET_CLIENT),
  graphql(UPDATE_CLIENT, { name: 'updateClient' }),
)(withStyles(styles)(SettingGeneral));
