
// -------------------------------------------------------------------------- //

import Sport from './Sport';

// -------------------------------------------------------------------------- //

const TEAMS = [
  'Home',
  'Visiting',
];

// -------------------------------------------------------------------------- //

class SoccerSport extends Sport {

  constructor() {
    super();

    for (let t = 0; t < 2; ++t) {
      const team = `[${TEAMS[t]}]`;
      const team_id = `team.${TEAMS[t].toLowerCase()}`;
      this.push(`${team_id}.id`, `${team} Team ID`, { type: 'string' });
      this.push(`${team_id}.name`, `${team} Team Name`, { type: 'string' });
      this.push(`${team_id}.record`, `${team} Team Record`, { type: 'string' });
      this.push(`${team_id}.score`, `${team} Total Score`);
      this.push(`${team_id}.saves`, `${team} Total Saves`);
      this.push(`${team_id}.shots`, `${team} Total Shots`);
      this.push(`${team_id}.shots.goal`, `${team} Total Shots on Goal`);
      this.push(`${team_id}.shots.percent`, `${team} Total Shot %`);
      this.push(`${team_id}.assists`, `${team} Total Assists`);
      this.push(`${team_id}.fouls`, `${team} Total Fouls`);
      this.push(`${team_id}.corners`, `${team} Total Corners`);
      this.push(`${team_id}.corners.goals`, `${team} Total Corner Goals`);
      this.push(`${team_id}.offsides`, `${team} Total Offsides`);
      this.push(`${team_id}.penalty.goals`, `${team} Total Penalty Shot Goals`);
      this.push(`${team_id}.penalty.attempts`, `${team} Total Penalty Shot Attempts`);
      this.push(`${team_id}.penalty.percent`, `${team} Total Penalty Shot %`);
      this.push(`${team_id}.penalty.corners`, `${team} Penalty Corners`);
      this.push(`${team_id}.goals.allowed`, `${team} Total Goals Allowed`);
      this.push(`${team_id}.saves.percent`, `${team} Total Save %`);
      
      for (let p = 0; p < 8; ++p) {
        const period = `period${p + 1}`;
        this.push(`${team_id}.score.${period}`, `${team} Score`);
        this.push(`${team_id}.shots.${period}`, `${team} Shots`);
        this.push(`${team_id}.saves.${period}`, `${team} Saves`);
        this.push(`${team_id}.fouls.${period}`, `${team} Fouls`);
        this.push(`${team_id}.corners.${period}`, `${team} Corners`);
        this.push(`${team_id}.corners.goals.${period}`, `${team} Corner Goals`);
        this.push(`${team_id}.penalty.corners.${period}`, `${team} Penalty Corners`);
        this.push(`${team_id}.offsides.${period}`, `${team} Offsides`);
      }
    }
  }

};

// -------------------------------------------------------------------------- //

export default SoccerSport;

// -------------------------------------------------------------------------- //
