
import AttributePath from './AttributePath';
import SCSportImporter from './SCSportImporter';

class SCLacrosseSportImporter extends SCSportImporter {

  constructor() {
    super('lcgame');
  }

  import(sport, xml) {
    if (!super.import(sport, xml)) {
      return false;
    }

    for (let t = 0; t < 2; ++t) {
      const team = `team.${(t === 0 ? 'home' : 'visiting')}`;
      const team_path = `/${this.game}/team[@id='${this.ids[t]}']`;
      const linescore = `${team_path}/linescore`;
      const totals = `${team_path}/totals`;
      this.select(`${team}.score`, new AttributePath(linescore, 'score'));
      this.select(`${team}.shots`, new AttributePath(`${totals}/shots`, 'sh'));
      this.select(`${team}.shots.ongoal`, new AttributePath(`${totals}/shots`, 'sog'));
      this.select(`${team}.saves`, new AttributePath(`${totals}/goalie`, 'saves'));
      const facewon = this.select(`${team}.faceoffs.won`, new AttributePath(`${totals}/misc`, 'facewon'));
      /*const facelost = */ this.select(`${team}.faceoffs.lost`, new AttributePath(`${totals}/misc`, 'facelost'));
      this.select(`${team}.penalties`, new AttributePath(`${totals}/penalty`, 'count'));
      this.select(`${team}.penalties.seconds`, new AttributePath(`${totals}/penalty`, 'seconds'));
      const ppg = this.select(`${team}.powerplays.goals`, new AttributePath(`${totals}/powerplay`, 'ppg'));
      const ppopp = this.select(`${team}.powerplays.attempts`, new AttributePath(`${totals}/powerplay`, 'ppopp'));
      this.select(`${team}.groundballs`, new AttributePath(`${totals}/misc`, 'gb'));

      this.set(`${team}.drawcontrols.won`, facewon);
      // this.set(`${team}.drawcontrols.lost`, facelost);
      this.set(`${team}.freepos.goals`, ppg);
      this.set(`${team}.freepos.attempts`, ppopp);

      for (let p = 0; p < 4; ++p) {
        const prd = (p + 1);
        const period = `period${prd}`;
        const lineprd = `${linescore}/lineprd[@prd='${prd}']`;
        this.select(`${team}.score.${period}`, new AttributePath(lineprd, 'score'));
        this.select(`${team}.shots.${period}`, new AttributePath(lineprd, 'shots'));
        this.select(`${team}.shots.ongoal.${period}`, new AttributePath(lineprd, 'sog'));
        this.select(`${team}.saves.${period}`, new AttributePath(lineprd, 'saves'));
        const fowon = this.select(`${team}.faceoffs.won.${period}`, new AttributePath(lineprd, 'fowon'));
        // TODO missing per-period faceoffs lost?
        this.select(`${team}.penalties.${period}`, new AttributePath(lineprd, 'pen'));
        this.select(`${team}.penalties.seconds.${period}`, new AttributePath(lineprd, 'pmin'));
        const ppg = this.select(`${team}.powerplays.goals.${period}`, new AttributePath(lineprd, 'ppg'));
        const ppatt = this.select(`${team}.powerplays.attempts.${period}`, new AttributePath(lineprd, 'ppatt'));
        this.select(`${team}.groundballs.${period}`, new AttributePath(lineprd, 'gb'));

        this.set(`${team}.drawcontrols.won.${period}`, fowon);
        this.set(`${team}.freepos.goals.${period}`, ppg);
        this.set(`${team}.freepos.attempts.${period}`, ppatt);
      }
    }

    return true;
  }

}

export default SCLacrosseSportImporter;
