
// -------------------------------------------------------------------------- //

import * as React from 'react';

import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';

import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Typography,
  withStyles,
} from '@material-ui/core';
import {
  Close,
} from 'mdi-material-ui';
import * as Scoreshots from '../../scoreshotsapi/v1';
import ChipInput from './ChipInput';


// -------------------------------------------------------------------------- //

const FETCH_PHONE_BOOK = gql`
  query fetchPhoneBook {
    getClientPhoneNumbers {
      id
      name
      phone
    }
  }
`;

// -------------------------------------------------------------------------- //

const styles = (theme) => ({
  close: {
    padding: (theme.spacing(1) / 2),
  },
});

// -------------------------------------------------------------------------- //

class SendToPhoneDialog extends React.Component {
  state = {
    chips: [],
    numbers: [],
    fetching: false,
    sending: false,
    snackbarOpen: false,
    snackbarMessage: '',
  }

  addChip = (chip) => {
    const { chips } = this.state;
    chips.push(chip);
    this.setState({ chips });
  }

  removeChip = (index) => {
    const { chips } = this.state;
    chips.splice(index, 1);
    this.setState({ chips });
  }

  togglePhoneBook = () => {
    const { chips, numbers } = this.state;
    const extras = chips.filter((i) => !numbers.some((j) => j.phone === i.number));

    if (extras.length < chips.length) {
      this.setState({ chips: extras });
    } else {
      const array = numbers.map((i) => ({ name: i.name, number: i.phone }));
      this.setState({ chips: [...extras, ...array] });
    }
  }

  handlePhoneBook = (name, number) => {
    const { chips } = this.state;
    const index = chips.findIndex((e) => e.number === number);

    if (index < 0) {
      this.addChip({ name, number });
    } else {
      this.removeChip(index);
    }
  }

  renderPhoneBook(numbers) {
    const { chips } = this.state;
    return (
      <List style={{ maxHeight: 300 }} dense>
        {numbers.map((e) => (
          <ListItem key={e.id} role={undefined} button onClick={() => this.handlePhoneBook(e.name, e.phone)} style={{ padding: 2 }}>
            <Checkbox color="primary" checked={chips.findIndex((c) => c.number === e.phone) >= 0} tabIndex={-1} disableRipple />
            <ListItemText primary={e.name} secondary={e.phone} />
          </ListItem>
        ))}
      </List>
    );
  }

  cancel = () => {
    const { onCancel } = this.props;
    this.setState({ chips: [], sending: false });

    if (onCancel) {
      onCancel();
    }
  }

  send = () => {
    const { video, image, mode, quickCreate } = this.props;
    const { chips } = this.state;
    const numbers = chips.map((e) => e.number);

    if (!video && !image && !quickCreate) {
      return;
    }

    const resolve = () => {
      this.openSnackbar('Sent your graphic successfully');
      this.cancel();
      window.Intercom('trackEvent', 'send-to-phone');
    };

    const reject = () => {
      this.openSnackbar('An error occured while sending your graphic');
      this.cancel();
    };

    this.setState({ sending: true }, async () => {
      if (video && mode === 'video') {
        fetch(video)
          .then((response) => response.blob())
          .then((blob) => Scoreshots.ToDataURL(blob))
          .then((data_url) => Scoreshots.SendTwilio(numbers, 'video/mp4', data_url))
          .then(resolve, reject);
      } else {
        if (this.props.quickCreate) {
          const twilioArray = []

          switch (this.props.template) {
            case 'Instagram':
              twilioArray.push(Scoreshots.SendTwilio(numbers, 'image/png', this.props.json.data[1].preview.png))
              break
            case 'InstagramStory':
              twilioArray.push(Scoreshots.SendTwilio(numbers, 'image/png', this.props.json.data[2].preview.png))
              break
            case 'AllTemplates':
              for(const value of this.props.json.data)
                twilioArray.push(Scoreshots.SendTwilio(numbers, 'image/png', value.preview.png))
              break
            default:
            case 'Twitter':
              twilioArray.push(Scoreshots.SendTwilio(numbers, 'image/png', this.props.json.data[0].preview.png))
              break
          }
          await Promise.all(twilioArray).then(resolve,reject)

        } else {
          Scoreshots.SendTwilio(numbers, 'image/png', image)
            .then(resolve, reject);
        }
      }
    });
  }

  openSnackbar = (message) => {
    this.setState({ snackbarOpen: true, snackbarMessage: message });
  }

  closeSnackbar = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ snackbarOpen: false });
  }

  componentDidMount() {
    this.getPhoneBook();
  }

  getPhoneBook() {
    const { fetchPhoneBook } = this.props;
    this.setState({ fetching: true }, () => {
      fetchPhoneBook
        .refetch((response) => response.json())
        .then((json) => {
          this.setState({
            fetching: false,
            numbers: json.data.getClientPhoneNumbers,
          });
        });
    });
  }

  render() {
    const { open, classes } = this.props;
    const {
      chips, fetching, sending, numbers,
    } = this.state;
    const { snackbarOpen, snackbarMessage } = this.state;
    const extras = chips.filter((i) => !numbers.some((j) => j.phone === i.number));
    const buttonText = (`Select ${extras.length < chips.length ? 'None' : 'All'}`);

    return (
      <>
        <Dialog open={open} maxWidth="xs" fullWidth>
          <DialogTitle>Send to Phone</DialogTitle>
          <DialogContent>
            <Typography>We can send your graphic to mobile devices. Add them below and our wizards will handle the rest.</Typography>
            <ChipInput
              disabled={sending}
              style={{ marginTop: 8 }}
              dataSource={numbers}
              dataSourceConfig={{ text: 'name', value: 'number' }}
              onAdd={(chip) => this.addChip(chip)}
              onDelete={(_, index) => this.removeChip(index)}
              value={chips}
              placeholder="Recipients..."
              fullWidth
            />
            {
            !sending && !fetching && numbers.length > 0
              ? <Button fullWidth style={{ marginTop: 4 }} onClick={this.togglePhoneBook}>{buttonText}</Button>
              : null
          }
            {
            fetching || sending
              ? <div style={{ marginTop: 16, textAlign: 'center' }}><CircularProgress /></div>
              : this.renderPhoneBook(numbers)
          }
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.cancel}>Cancel</Button>
            <Button disabled={sending || chips.length === 0} color="primary" onClick={this.send}>Send</Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={this.closeSnackbar}
          message={<span>{snackbarMessage}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.closeSnackbar}
            >
              <Close />
            </IconButton>,
          ]}
        />
      </>
    );
  }
}

// -------------------------------------------------------------------------- //

export default compose(
  graphql(FETCH_PHONE_BOOK, { name: 'fetchPhoneBook' }),
)(withStyles(styles)(SendToPhoneDialog));

// -------------------------------------------------------------------------- //
