
import Sport from './Sport';

const TEAMS = [
  'Home',
  'Visiting',
];

class LacrosseSport extends Sport {

  constructor() {
    super();

    for (let t = 0; t < 2; ++t) {
      const team = `[${TEAMS[t]}]`;
      const team_id = `team.${TEAMS[t].toLowerCase()}`;
      this.push(`${team_id}.id`, `${team} Team ID`, { type: 'string' });
      this.push(`${team_id}.name`, `${team} Team Name`, { type: 'string' });
      this.push(`${team_id}.score`, `${team} Total Score`);
      this.push(`${team_id}.shots`, `${team} Total Shots`);
      this.push(`${team_id}.shots.ongoal`, `${team} Total Shots on Goal`);
      this.push(`${team_id}.saves`, `${team} Total Saves`);
      this.push(`${team_id}.faceoffs.won`, `${team} Total Face Offs Won`); // men's
      this.push(`${team_id}.faceoffs.lost`, `${team} Total Face Offs Lost`); // men's
      this.push(`${team_id}.drawcontrols.won`, `${team} Total Draw Control Wins`); // women's
      // this.push(`${team_id}.drawcontrols.lost`, `${team} Total Draw Control Losses`); // women's
      this.push(`${team_id}.penalties`, `${team} Total Penalties`);
      this.push(`${team_id}.penalties.seconds`, `${team} Total Penalty Seconds`);
      this.push(`${team_id}.powerplays.goals`, `${team} Total Extra-Man Goals`); // men's
      this.push(`${team_id}.powerplays.attempts`, `${team} Total Extra-Man Attempts`); // men's
      this.push(`${team_id}.freepos.goals`, `${team} Total Free-Position Shots`); // women's
      this.push(`${team_id}.freepos.attempts`, `${team} Total Free-Position Attempts`); // women's
      this.push(`${team_id}.groundballs`, `${team} Total Groundballs`);

      for (let p = 0; p < 4; ++p) {
        const period = `period${p + 1}`;
        this.push(`${team_id}.score.${period}`, `${team} Score`);
        this.push(`${team_id}.shots.${period}`, `${team} Shots`);
        this.push(`${team_id}.shots.ongoal.${period}`, `${team} Shots on Goal`);
        this.push(`${team_id}.saves.${period}`, `${team} Saves`);
        this.push(`${team_id}.faceoffs.won.${period}`, `${team} Face Offs Won`); // men's
        this.push(`${team_id}.faceoffs.lost.${period}`, `${team} Face Offs Lost`); // men's
        this.push(`${team_id}.drawcontrols.won.${period}`, `${team} Draw Control Wins`); // women's
        // this.push(`${team_id}.drawcontrols.lost.${period}`, `${team} Draw Control Losses`); // women's
        this.push(`${team_id}.penalties.${period}`, `${team} Penalties`);
        this.push(`${team_id}.penalties.seconds.${period}`, `${team} Penalty Seconds`);
        this.push(`${team_id}.powerplays.goals.${period}`, `${team} Extra-Man Goals`); // men's
        this.push(`${team_id}.powerplays.attempts.${period}`, `${team} Extra-Man Attempts`); // men's
        this.push(`${team_id}.freepos.goals.${period}`, `${team} Free-Position Shots`); // women's
        this.push(`${team_id}.freepos.attempts.${period}`, `${team} Free-Position Attempts`); // women's
        this.push(`${team_id}.groundballs.${period}`, `${team} Groundballs`);
      }
    }
  }

  update(id, version) {
    if (version < 3) {
      // venue-scope stats are now in 'venue.' rather than 'location.'
      if (/^location\./.test(id)) {
        return { type: 'rename', value: id.replace(/^location\./, 'venue.') };
      }

      // these remaps use 'visit' for the opposing team, not 'visiting'
      let remaps = {
        'team1': 'team.home.name',
        'team2': 'team.visiting.name',
        'team.home': 'team.home.name',
        'team.home_id': 'team.home.id',
        'team.visit': 'team.visiting.name',
        'team.visit_id': 'team.visiting.id',
      };

      if (remaps[id]) {
        return { type: 'rename', value: remaps[id] };
      }

      for (let t = 0; t < 2; ++t) {
        const team =  TEAMS[t].toLowerCase();
        const old_id = `${team}`;
        const new_id = `team.${team}`;

        remaps = {
          [`score.${old_id}.total`]: `${new_id}.score`,
          [`score.${old_id}.period1`]: `${new_id}score.period1`,
          [`score.${old_id}.period2`]: `${new_id}score.period2`,
          [`score.${old_id}.period3`]: `${new_id}score.period3`,
          [`score.${old_id}.period4`]: `${new_id}score.period4`,

          [`shots.${old_id}.total`]: `${new_id}.shots`,
          [`shots.${old_id}.period1`]: `${new_id}shots.period1`,
          [`shots.${old_id}.period2`]: `${new_id}shots.period2`,
          [`shots.${old_id}.period3`]: `${new_id}shots.period3`,
          [`shots.${old_id}.period4`]: `${new_id}shots.period4`,

          [`shots_on_goal.${old_id}.total`]: `${new_id}.shots.ongoal`,
          [`shots_on_goal.${old_id}.period1`]: `${new_id}shots.ongoal.period1`,
          [`shots_on_goal.${old_id}.period2`]: `${new_id}shots.ongoal.period2`,
          [`shots_on_goal.${old_id}.period3`]: `${new_id}shots.ongoal.period3`,
          [`shots_on_goal.${old_id}.period4`]: `${new_id}shots.ongoal.period4`,

          [`saves.${old_id}.total`]: `${new_id}.saves`,
          [`saves.${old_id}.period1`]: `${new_id}saves.period1`,
          [`saves.${old_id}.period2`]: `${new_id}saves.period2`,
          [`saves.${old_id}.period3`]: `${new_id}saves.period3`,
          [`saves.${old_id}.period4`]: `${new_id}saves.period4`,

          [`face_offs_won.${old_id}.total`]: `${new_id}.faceoffs.won`,
          [`face_offs_won.${old_id}.period1`]: `${new_id}faceoffs.won.period1`,
          [`face_offs_won.${old_id}.period2`]: `${new_id}faceoffs.won.period2`,
          [`face_offs_won.${old_id}.period3`]: `${new_id}faceoffs.won.period3`,
          [`face_offs_won.${old_id}.period4`]: `${new_id}faceoffs.won.period4`,

          [`penalties.${old_id}.total`]: `${new_id}.penalties`,
          [`penalties.${old_id}.period1`]: `${new_id}penalties.period1`,
          [`penalties.${old_id}.period2`]: `${new_id}penalties.period2`,
          [`penalties.${old_id}.period3`]: `${new_id}penalties.period3`,
          [`penalties.${old_id}.period4`]: `${new_id}penalties.period4`,

          [`penalty_seconds.${old_id}.total`]: `${new_id}.penalties.seconds`,
          [`penalty_seconds.${old_id}.period1`]: `${new_id}penalties.seconds.period1`,
          [`penalty_seconds.${old_id}.period2`]: `${new_id}penalties.seconds.period2`,
          [`penalty_seconds.${old_id}.period3`]: `${new_id}penalties.seconds.period3`,
          [`penalty_seconds.${old_id}.period4`]: `${new_id}penalties.seconds.period4`,

          [`extra_man_goals.${old_id}.total`]: `${new_id}.powerplays.goals`,
          [`extra_man_goals.${old_id}.period1`]: `${new_id}powerplays.goals.period1`,
          [`extra_man_goals.${old_id}.period2`]: `${new_id}powerplays.goals.period2`,
          [`extra_man_goals.${old_id}.period3`]: `${new_id}powerplays.goals.period3`,
          [`extra_man_goals.${old_id}.period4`]: `${new_id}powerplays.goals.period4`,

          [`extra_man_attempts.${old_id}.total`]: `${new_id}.powerplays.attempts`,
          [`extra_man_attempts.${old_id}.period1`]: `${new_id}powerplays.attempts.period1`,
          [`extra_man_attempts.${old_id}.period2`]: `${new_id}powerplays.attempts.period2`,
          [`extra_man_attempts.${old_id}.period3`]: `${new_id}powerplays.attempts.period3`,
          [`extra_man_attempts.${old_id}.period4`]: `${new_id}powerplays.attempts.period4`,

          [`groundballs.${old_id}.total`]: `${new_id}.groundballs`,
          [`groundballs.${old_id}.period1`]: `${new_id}groundballs.period1`,
          [`groundballs.${old_id}.period2`]: `${new_id}groundballs.period2`,
          [`groundballs.${old_id}.period3`]: `${new_id}groundballs.period3`,
          [`groundballs.${old_id}.period4`]: `${new_id}groundballs.period4`,
        };

        if (remaps[id]) {
          return { type: 'rename', value: remaps[id] };
        }
      }
    }

    return super.update(id, version);
  }

}

export default LacrosseSport;
