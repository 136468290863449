
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Dialog,
  Fade,
  withStyles,
} from '@material-ui/core';

import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import { PoseGroup } from 'react-pose';
import { withRouter } from 'react-router-dom'
import 'whatwg-fetch';

import PrestoEvent from '../components/create/steps/PrestoEvent';
import { CreateSearchContext } from '../components/create/search';
import { isMobileOnly } from 'react-device-detect';
// import ViewGraphic from '../components/create/ViewGraphic';

// -------------------------------------------------------------------------- //

const GQL_CLIENT = gql`
  query {
    me {
      client {
        id
        logoBucket
        logoKey
        subscription_start
        subscription_end
      }
      role {
        id
        name
        restrictCategoryFlag
        categories {
          id
          name
        }
        allowCustomFlag
        allowCustomSaveFlag
        allowCustomFolderSaveFlag
        restrictCustomFlag
        customFolders {
          id
          name
        }
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

function expirationDateCheck(expireDate, hrsInMillis) {
  // const currentDate = new Date(); // now
  const expirationDate = new Date(expireDate); // expiration date YYYY-MM-DD
  expirationDate.setTime(expirationDate.getTime() + hrsInMillis);
  // return currentDate > expirationDate;
  return false;
}

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  root_mobile: {
    backgroundColor: theme.palette.background.default,
    alignItems: 'center',
  },
  root_desktop: {
    backgroundColor: theme.palette.background.default,
    alignItems: 'center',
    overflowY: 'hidden',
  },
  description: {
    maxWidth: 600,
  },
  card: {
    width: 220,
    height: 300,
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(2),
    cursor: 'pointer',
    transition: 'box-shadow 200ms, opacity 200ms',
    boxSizing: 'content-box',
    '&:hover': {
      boxShadow: theme.shadows[10],
    },
    [theme.breakpoints.down('xs')]: {
      flexBasis: '100%',
      marginRight: 0,
    },
  },
  unselected: {
    opacity: 0.4,
  },
  cancelButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  disabled: {
    opacity: 0.3,
    cursor: 'not-allowed',
    transition: 'none',
    '&:hover': {
      boxShadow: 'inherit',
    },
  },
  toolbar: {
    width: '100%',
  },
  cardCollection: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2),
  },
  media: {
    flexGrow: 1,
  },
  content: {
    padding: theme.spacing(2),
  },
  img: {
    display: 'block',
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: 2,
    cursor: 'pointer',
    boxShadow: theme.shadows[2],
    transition: theme.transitions.create(['box-shadow'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.short,
    }),
    '&:hover': {
      boxShadow: theme.shadows[5],
    },
  },
  typeSelect: {
    width: 800,
    margin: 'auto',
    marginTop: '7vh',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: theme.spacing(2),
    },
  },
  templateSelect: {
  },
  barFlex: {
    justifyContent: 'space-around',
  },
  formControl: {
    marginBottom: theme.spacing(1),
  },
  selectPaper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  imageDesc : {
    width : '25%',
    height: 100,
    '-ms-transform': 'translateY(50px)', /* IE 9 */
    '-webkit-transform': 'translateY(50px)', /* Safari */
    transform: 'translateY(50px)',
    '&:hover' : {
      height : 'translateY(0px)',
    }
  }
});

// -------------------------------------------------------------------------- //

class CreateEvent extends React.Component {

  state = {
    type: '',
    category: '',
    types: [],
    showFullMotion: false,
    templateImage : "",
    templatePopupOpen : false,
    template : {}
  }

  render() {
    const { classes } = this.props;

    const {
      type, step, category, types, showFullMotion,
    } = this.state;

    const feed = (
      (this.props.me) &&
      <CreateSearchContext.Consumer
        key="CreateSearchContext.Consumer"
      >
        {(search_parameters) => (
          <PrestoEvent
            key='prestoEvent'
            search_parameters={search_parameters}
            logo={`https://${this.props.me.client.logoBucket}.s3.us-east-2.amazonaws.com/${this.props.me.client.logoKey}`}
            classes={(step === 0 && classes) || undefined}
            setParentState={this.setState}
            handleChange={this.handleChange}
            handleClear={this.handleClear}
            handleToggle={this.handleToggle}
            showFullMotion={showFullMotion}
            category={category}
            types={types}
            type={type}
            setType={this.setType}
            client={this.props.data ? this.props.me.client.id : null}
            role={this.props.data ? this.props.me.role : null}
            accountExpired={this.props.data ? expirationDateCheck(this.props.me.client.subscription_end,20*1000*60*60) : false}
            openCloseTemplateView={this.setTemplateImage}
          />
        )}
      </CreateSearchContext.Consumer>
    );

    return (
      <Dialog
        open
        fullScreen
        classes={{ paper: isMobileOnly ? classes.root_mobile : classes.root_desktop }}
        onClose={this.handleClose}
        TransitionComponent={Fade}
        transitionDuration={500}
      >
        <PoseGroup animateOnMount preEnterPose="pre">
          {feed}
        </PoseGroup>
      </Dialog>
    );
  }

  cancel = () => {
    this.props.history.goBack();
  }

}

// -------------------------------------------------------------------------- //

export default compose(
  graphql(GQL_CLIENT),
  withRouter,
  withStyles(STYLES),
)(CreateEvent);

// -------------------------------------------------------------------------- //
