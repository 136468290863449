
// -------------------------------------------------------------------------- //

import * as React from 'react';

import {
  AppBar,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  Close,
} from 'mdi-material-ui';

// -------------------------------------------------------------------------- //

const styles = (theme) => ({
  appBar: {
    position: 'static',
  },
  page: {
    padding: theme.spacing(1),
    position: 'relative',
    overflowY: 'auto',
  },
  flex: {
    flex: 1,
  },
});

// -------------------------------------------------------------------------- //

const Transition = (props) => (
  <Slide direction="up" {...props} />
);

// -------------------------------------------------------------------------- //

export default withStyles(styles)(props => {
  const { classes, open, title, onClose } = props;

  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            {title}
          </Typography>
          <IconButton color="inherit" onClick={() => (onClose && onClose())}>
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={classes.page}>
        {props.children}
      </div>
    </Dialog>
  );
});

// -------------------------------------------------------------------------- //
