/* eslint no-underscore-dangle: "off" */
/* eslint no-loop-func: "off" */

class WidgetLayout {
  constructor(fabric) {
    this.class = fabric.util.createClass(fabric.Object, {
      type: 'widgetLayout',
      selectable: true,
      originX: 'center',
      originY: 'center',
      visible: true,
      widget: '',
      spacing: 0,
      hspacing: -10,
      vspacing: -10,
      widgetWidth: 0,
      widgetHeight: 0,
      flow: 'horizontal',
      count: 0,
      widgetMinCount: -1,
      widgetMaxCount: -1,
      widgetScaleX: 1,
      widgetScaleY: 1,

      stateProperties: [
        ...fabric.Object.prototype.stateProperties,
        'widget',
        'spacing',
        'hspacing',
        'vspacing',
        'widgetWidth',
        'widgetHeight',
        'flow',
        'count',
        'widgetFlow',
        'widgetMinCount',
        'widgetMaxCount',
        'widgetScaleX',
        'widgetScaleY',
      ],

      initialize(options = {}) {
        this.callSuper('initialize', options);

        this.on('added', () => {
          this.off('added');

          this.canvas.on('stepChange', () => {
            this._handleStepChange();
          });

          this._handleStepChange(this.canvas.step);
        });

        this.on('modified', () => {
          this.width = this.getScaledWidth();
          this.height = this.getScaledHeight();
          this.scaleX = 1;
          this.scaleY = 1;
          this.dirty = true;
          this.setCoords();
          this.canvas.trigger('object:modified');
        });
      },

      _handleStepChange(step) {
        if (this.canvas) {
          step = (step || this.canvas.step);

          if (step === 'widgets' || step === 'admin') {
            this.set('visible', true);
            this.set('dirty', true);
          } else {
            this.set('visible', false);
            this.set('dirty', true);
          }

          this.canvas.requestRenderAll();
        }
      },

      instantiate() {
        if (this.widget && this.canvas) {
          this.resize();
        }
      },

      remove() {
        if (!this.canvas) {
          return;
        }

        this.canvas
        .getObjects('widget')
        .map(object => object.widget === this.widget)
        .forEach((object) => {
          object.remove();
        });

        this.canvas.requestRenderAll();
      },

      calculateBracketLength() {
        return (2 * (this.count - 1));
      },

      getCount() {
        if (this.widgetFlow === 'triangle-horizontal') {
          return this.calculateBracketLength();
        } else {
          return this.count;
        }
      },

      gatherWidgets() {
        return (
          this.canvas
          .getObjects('widget')
          .filter((widget) => (widget.widget === this.widget))
          .sort((a, b) => {
            if (a.index < b.index) {
              return -1;
            } else if (a.index > b.index) {
              return 1;
            } else {
              return 0;
            }
          })
        );
      },

      resize() {
        let widgets = this.gatherWidgets();

        if (this.count < 0) {
          this.count = 0;
        }

        if (this.widgetMinCount > 0) {
          this.count = Math.max(this.count, this.widgetMinCount);
        }

        if (this.widgetMaxCount > 0) {
          this.count = Math.min(this.count, this.widgetMaxCount);
        }

        const count = this.getCount();
        this.canvas.ignoreUndo = true;

        if (widgets.length > count) {
          for (let i = count; i < widgets.length; ++i) {
            widgets[i].remove();
            this.canvas.remove(widgets[i]);
          }

          this.layout();
        } else if (widgets.length < count) {
          for (let i = widgets.length; i < count; ++i) {
            let widget = new fabric.Widget({
              widget: this.widget,
              index: i,
              width: this.widgetWidth,
              height: this.widgetHeight,
            });

            this.canvas.insertAt(
              widget, this.canvas.getObjects().indexOf(this)
            );

            widget.instantiate();
          }

          this.layout();
        }

        this.canvas.ignoreUndo = false;
        this.canvas.requestRenderAll();
      },

      layout() {
        switch (this.widgetFlow) {
          case 'fill-horizontal':
          case 'fill-vertical':
          case 'center-horizontal':
          case 'center-vertical':
          case 'symmetrical-horizontal':
          case 'symmetrical-vertical':
          case 'horizontal':
          case 'vertical':
          default:
            this.gridLayout();
            break;
          case 'triangle-horizontal':
            this.triangleLayout();
            break;
        }
      },

      triangleLayout() {
        let widgets = this.gatherWidgets();
        const origin = this.getCenterPoint();

        if (this.hspacing === -10) {
          this.hspacing = (this.spacing + 20);
        }

        if (this.vspacing === -10) {
          this.vspacing = (this.spacing - 10);
        }

        const x_spacing = this.hspacing;
        const y_spacing = this.vspacing;

        let fifo = [
          { x: -x_spacing, y: 0, rows: 1 },
          { x: x_spacing, y: 0, rows: 1 },
        ];

        const NOT_A_SWITCH = [
          0, 0,
          0, 0, 0, 0,
          0, 0, 2, 2, 0, 0, 0, 0,
          0, 0, 2, 2, 4, 4, 6, 6, 0, 0, 0, 0, 0, 0, 0, 0,
        ];

        let widget_index = 0;

        while (widget_index < widgets.length) {
          const index = widget_index++;
          const point = fifo.splice(NOT_A_SWITCH[index], 1)[0];
          let widget = widgets[index];

          widget.move(
            (origin.x + point.x - this.widgetWidth * 0.5),
            (origin.y + point.y - this.widgetHeight * 0.5)
          );

          const x = (point.x + x_spacing * Math.sign(point.x));
          const rows = (point.rows * 2);

          fifo.push({ x, y: (point.y + y_spacing / point.rows), rows });
          fifo.push({ x, y: (point.y - y_spacing / point.rows), rows });
        }

        this.canvas.requestRenderAll();
      },

      gridLayout() {
        let widgets = this.gatherWidgets();

        if (this.hspacing === -10) { this.hspacing = this.spacing; }
        if (this.vspacing === -10) { this.vspacing = this.spacing; }
        let left = this.left - this.getScaledWidth() / 2;
        let top = this.top - (this.getScaledHeight() / 2);
        const horizontalSpacing = this.hspacing * this.scaleX;
        const verticalSpacing = this.vspacing * this.scaleY;

        let elementWidth = this.widgetWidth * this.scaleX;
        let elementHeight = this.widgetHeight * this.scaleY;
        let layoutWidth = this.getScaledWidth();
        let layoutHeight = this.getScaledHeight();

        let startX = 0;
        let startY = 0;
        let flow = this.widgetFlow;
        if (this.widgetFlow === 'center-horizontal' ||
            this.widgetFlow === 'center-vertical')
        {
          const w = elementWidth;
          const h = elementHeight;
          const maxPerRow = Math.min(Math.floor(this.getScaledWidth() / w) || 1, widgets.length);
          const maxPerColumn = Math.min(Math.floor(this.getScaledHeight() / h) || 1, widgets.length);

          startX = (this.getScaledWidth() - (maxPerRow * w + (maxPerRow - 1) * horizontalSpacing)) / 2;
          startY = (this.getScaledHeight() - (maxPerColumn * h + (maxPerColumn - 1) * verticalSpacing)) / 2;

          if (this.widgetFlow === 'center-horizontal') {
            flow = 'horizontal';
          } else if (this.widgetFlow === 'center-vertical') {
            flow = 'vertical';
          }
        } else if (this.widgetFlow === 'fill-horizontal' || this.widgetFlow === 'fill-vertical') {
          if (this.widgetFlow === 'fill-horizontal') {
            elementWidth = Math.max(0, (this.getScaledWidth() / this.widgetScaleX) / widgets.length);
            elementHeight = this.widgetHeight;
            flow = 'horizontal';
          } else if (this.widgetFlow === 'fill-vertical') {
            elementWidth = this.widgetWidth;
            elementHeight = Math.max(0, ((this.getScaledHeight() / this.widgetScaleY) - (verticalSpacing * (widgets.length - 1))) / widgets.length);
            flow = 'vertical';
          }

          elementWidth *= this.widgetScaleX;
          elementHeight *= this.widgetScaleY;
        } else if (this.widgetFlow === 'symmetrical-horizontal' || this.widgetFlow === 'symmetrical-vertical') {
          if (this.widgetFlow === 'symmetrical-vertical') {
            let numRows = Math.max(1, Math.floor(layoutHeight / elementHeight));
            let numWidgetsPerRow = Math.max(Math.floor(widgets.length / numRows - 0.5), 1);
            numWidgetsPerRow = Math.min(Math.floor(layoutWidth / (elementWidth + this.spacing)) || 1, numWidgetsPerRow);
            let newLayoutWidth = (this.spacing * numWidgetsPerRow) + (elementWidth * (numWidgetsPerRow + 1));

            left = this.left - (newLayoutWidth) / 2;
            layoutWidth = newLayoutWidth;

            flow = 'horizontal';
          } else if (this.widgetFlow === 'symmetrical-horizontal') {
            let fractionalNumColumns = Math.max(layoutWidth / (elementWidth + horizontalSpacing), 1);
            let numColumns = Math.max(1, Math.floor(fractionalNumColumns));
            let numWidgetsPerColumnFractional = widgets.length / numColumns;
            let numWidgetsPerColumn = Math.max(Math.ceil(numWidgetsPerColumnFractional), 1); 
            let newLayoutHeight = (this.spacing * numWidgetsPerColumn) + (elementHeight * numWidgetsPerColumn);
            let numWidgetsPerRow = Math.floor(widgets.length / Math.floor(numWidgetsPerColumnFractional));
            let newLayoutWidth = horizontalSpacing * numWidgetsPerRow + numWidgetsPerRow * elementWidth;
            let remainderElementWidth = (fractionalNumColumns - numColumns) * elementWidth;

            top = this.top - (newLayoutHeight / 2);
            startX = layoutWidth / 2 - newLayoutWidth / 2 + horizontalSpacing / 2;
            layoutHeight = newLayoutHeight;
            layoutWidth = newLayoutWidth + remainderElementWidth;

            flow = 'horizontal';
          }
        }

        let x = startX;
        let y = startY;

        for (let i = 0; i < widgets.length; ++i) {
          const widget = widgets[i];
          widget.move(
            x + left,
            y + top);
          let w, h;
          if (this.widgetFlow === 'fill-horizontal' || this.widgetFlow === 'fill-vertical') {
            w = 0;
            h = 0;
            widget.resize(elementWidth, elementHeight);
          } else {
            w = elementWidth;
            h = elementHeight;
            if (this.widgetScaleX !== 1 || this.widgetScaleY !== 1) {
              widget.resize(
                widget.getScaledWidth() * this.widgetScaleX,
                widget.getScaledHeight() * this.widgetScaleY);
            }
          }
          if (flow === 'horizontal') {
            x += elementWidth;
            if (x + w > layoutWidth) {
              x = startX;
              y += elementHeight + verticalSpacing;
            } else {
              x += horizontalSpacing;
            }
          } else if (flow === 'vertical') {
            y += elementHeight;
            if (y + h > layoutHeight) {
              y = startY;
              x += elementWidth + horizontalSpacing;
            } else {
              y += verticalSpacing;
            }
          }
        }
        this.canvas.requestRenderAll();
      },
      toObject(propertiesToInclude) {
        propertiesToInclude = [
          ...propertiesToInclude,
          'widget',
          'spacing',
          'hspacing',
          'vspacing',
          'widgetWidth',
          'widgetHeight',
          'widgetFlow',
          'count',
          'widgetMinCount',
          'widgetMaxCount',
          'widgetScaleX',
          'widgetScaleY'
        ];

        return fabric.util.object.extend(
          this.callSuper('toObject', propertiesToInclude, {
            widget: this.widget,
            spacing: this.spacing,
            hspacing: this.hspacing,
            vspacing: this.vspacing,
            widgetWidth: this.widgetWidth,
            widgetHeight: this.widgetHeight,
            widgetFlow: this.widgetFlow,
            count: this.count,
            widgetMinCount: this.widgetMinCount,
            widgetMaxCount: this.widgetMaxCount,
            widgetScaleX: this.widgetScaleX,
            widgetScaleY: this.widgetScaleY
          })
        );
      },

      _render(ctx) {
        if (this.visible && this.canvas && this.canvas.step === 'admin') {
          if (this.canvas.getActiveObject() === this) {
            ctx.fillStyle = 'grey';
            ctx.fillRect(-this.getScaledWidth() / 2, -this.getScaledHeight() / 2, this.getScaledWidth(), this.getScaledHeight());
          }

          ctx.lineWidth = 4.0;
          ctx.strokeStyle = 'black';
          ctx.rect(-this.getScaledWidth() / 2, -this.getScaledHeight() / 2, this.getScaledWidth(), this.getScaledHeight());
          ctx.stroke();
        }
      },

    });

    this.fromObject = (object, callback) => fabric.Object._fromObject('WidgetLayout', object, callback);
  }
}

export default WidgetLayout;
