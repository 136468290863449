
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  ButtonBase,
  CircularProgress,
  Grid,
  Typography,
  withStyles,
  Button,
} from '@material-ui/core';

import { Query } from 'react-apollo';
import classNames from 'classnames';
import gql from 'graphql-tag';

// -------------------------------------------------------------------------- //

const GQL_TEXTURES = gql`
  query ($client: ID!) {
    textureSearch(
      where: {
        OR: [
          {
            visible: PREMIUM
            clients_some: {
              id: $client
            }
          },
          {
            visible: GLOBAL
          }
        ]
      }) {
      id
      name
      image
    }
  }
`;

// -------------------------------------------------------------------------- //

function Conditional(props) {
  return (props.in ? props.children : null);
};

// -------------------------------------------------------------------------- //

function GetCanvasTexture(canvas) {
  const canvas_textures = canvas.getObjects('canvasTexture');

  if (canvas_textures.length === 0) {
    return null;
  }

  const match = canvas_textures[0].src.match(
    /textures\/([0-9a-z]+)\.(png|jpg)/i
  );

  return match[1];
}

// -------------------------------------------------------------------------- //

function SetCanvasTexture(canvas, fabric, texture) {
  const canvas_textures = canvas.getObjects('canvasTexture');
  canvas.remove(...canvas_textures);

  if (texture !== null) {
    const src = `${texture.image}?${new Date()}`;
    canvas.add(new fabric.CanvasTexture(src));
  }

  canvas.requestRenderAll();
}

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  button: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    transition: theme.transitions.create('box-shadow'),
    '&:focus': {
      boxShadow: `0 0 0 4px ${theme.palette.primary.light}`,
    },
    '&:hover': {
      boxShadow: theme.shadows[3],
    },
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  clear_button: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  caption: {
    display: 'block',
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  grid_container: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  grid_item: {
    backgroundColor: 'inherit',
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
    transition: theme.transitions.create([ 'background-color', 'color' ]),
    textAlign: 'center',
  },
  grid_item_active: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
  },
  image: {
    borderRadius: theme.shape.borderRadius,
    height: theme.spacing(10),
    objectFit: 'cover',
    width: '100%',
  },
  loading: {
    margin: theme.spacing(2),
    textAlign: 'center',
  },
  root: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
});

// -------------------------------------------------------------------------- //

function Component(props) {
  const { classes, canvas, fabric } = props;
  const client_id = localStorage.getItem('client_id');
  const active_texture = GetCanvasTexture(canvas);
  let [ , setState ] = React.useState({});

  return (
    <div className={classes.root}>
      <Query
        query={GQL_TEXTURES}
        variables={{ client: client_id }}
        fetchPolicy="cache-and-network"
      >
      {({ data, loading }) => (
        <>
          <Conditional in={loading}>
            <div className={classes.loading}>
              <CircularProgress size={48}/>
            </div>
          </Conditional>
          <Grid
            container columns="2" spacing="1"
            className={classes.grid_container}
          >
          {
            !!(data !== null && !loading) &&
            data.textureSearch.map((texture, index) => (
              <Grid
                item xs={6} key={index}
                className={classNames(
                  classes.grid_item,
                  { [classes.grid_item_active]: (active_texture === texture.id) },
                )}
              >
                <ButtonBase
                  key={texture.id}
                  className={classes.button}
                  onClick={() => {
                    SetCanvasTexture(
                      canvas, fabric,
                      (texture.id !== active_texture ? texture : null)
                    );

                    setState({});
                  }}
                >
                  <img
                    className={classes.image}
                    src={`https://ss3-assets.s3.us-east-2.amazonaws.com/textures/${texture.id}_preview.png`}
                    alt="Texture preview"
                  />
                </ButtonBase>
                <Typography
                  variant="caption"
                  color="inherit"
                  className={classes.caption}
                >
                  {texture.name}
                </Typography>
              </Grid>
            ))
          }
          </Grid>
        </>
      )}
      </Query>
      <Button
        color="primary"
        variant="contained"
        className={classes.clear_button}
        onClick={() => {
          SetCanvasTexture(canvas, fabric, null);
          setState({});
        }}
      >
        Clear Texture
      </Button>
    </div>
  );
}

// -------------------------------------------------------------------------- //

export const ElementsTexture = withStyles(STYLES)(Component);

// -------------------------------------------------------------------------- //
