
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  AppBar,
  Avatar,
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  ButtonBase,
  CircularProgress, Dialog, DialogContent, DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  Account as AccountIcon,
  AccountMultiple as AccountMultipleIcon,
  FileDocumentEditOutline as FileDocumentEditOutlineIcon,
  FolderMultipleImage as FolderMultipleImageIcon,
  Logout as LogoutIcon,
  Menu as MenuIcon,
  MessageReplyText as MessageReplyTextIcon,
  MessageText as MessageTextIcon,
  Pencil as PencilIcon,
  PlayCircleOutline as PlayCircleOutlineIcon,
  Security as SecurityIcon,
  ViewDashboard as ViewDashboardIcon,
} from 'mdi-material-ui';

import { graphql, compose } from 'react-apollo';
import { isMobileOnly } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { withRouter, matchPath } from 'react-router';
import classnames from 'classnames';
import gql from 'graphql-tag';

import LoginPopup from './LoginPopup';
import Auth from '../auth/auth';
import Logo from '../svg/Logo';
import CloseIcon from "@material-ui/icons/Close";

// -------------------------------------------------------------------------- //

const auth = new Auth();

// -------------------------------------------------------------------------- //

const GET_USER_INFO = gql`
  query getUserInfo {
    me {
      id
      firstname,
      lastname,
      client {
        id
        name
        type
        color2
        logoBucket
        logoKey
      }
      mobileLoginMessageSend
    }
  }
`;

// -------------------------------------------------------------------------- //

const DRAWER_WIDTH = 320;

const STYLES = (theme) => ({
  badge: {
    width: 18,
    height: 18,
    paddingBottom: 2,
    right: -8,
    top: -8,
    transition: theme.transitions.create('transform'),
  },
  badge_hidden: {
    transform: 'scale(0)',
  },
  bottom_app_bar: {
    position: 'relative',
  },
  bottom_navigation: {
    bottom: 0,
    boxShadow: theme.shadows[4],
    position: 'fixed',
    width: '100%',
    maxHeight: '8vh',
    '& .MuiBottomNavigationAction-label': {
      marginBottom: '5px',
      fontSize: '0.75rem',
    },
    '& .MuiSvgIcon-root': {
      marginTop: '10px',
    },
  },
  drawer: {
    width: DRAWER_WIDTH,
  },
  flex: {
    flex: 1,
  },
  root: { },
  scoreshots: {
    height: theme.spacing(7),
    fill: theme.palette.primary.contrastText,
    padding: theme.spacing(1),
  },
  toolbar: {
    height: 64,
  },
  user_avatar: {
    marginRight: theme.spacing(1),
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  user_button: {
    justifyContent: 'normal',
    width: '100%',
  },
  user_text: {
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  user_text_root: {
    alignItems: 'flex-start',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  user_info: {
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  bottom_nav_create: {
    '& span': {
      // marginBottom: '100px',
      backgroundColor: 'black'
    },
    // backgroundColor: 'black'
  },
});

// -------------------------------------------------------------------------- //

class BetaNavMobile extends React.Component {

  state = {
    drawer_open: false,
    badge_count: 0,
    user : {},
    release: null,
    releaseShow: false,

  };

  componentDidMount() {
    this.initialize();
    const getLatestRelease = localStorage.getItem('scoreshots_latest_release')
    fetch('https://api.releasenotes.io/api/v1/projects/3862/releases?api_token=Z0eHF28AhXaQywPVpkCjY2v0lg5LG1hLBnMLc6KwCUGX9akx7dARtWLagaAD',)
      .then(resp => resp.json())
      .then(response => {
        if(response.data.length > 0){
          const lastRelease = response.data[response.data.length - 1]

          if ( getLatestRelease === null || getLatestRelease !== lastRelease.title) {

            this.setState({ release: lastRelease.description, releaseShow: true});

            localStorage.setItem('scoreshots_latest_release',lastRelease.title)
          }
        }
      });
    window.Intercom('onUnreadCountChange', (count) => {
      this.setState({ badge_count: count });
    });
  }

  render() {
    return (
      <>
        {this.renderAppBar()}
        {this.renderDrawer()}
        {this.renderBottomNav()}
        {this.renderLoginPopup()}
        {this.renderReleaseNotesDialog()}
      </>
    );
  }

  renderLoginPopup = () => {
    const { getUserInfo } = this.props;
    let user = null;

    if (getUserInfo && getUserInfo.me) {
      user = getUserInfo.me;
    }

    if (!isMobileOnly) {
      return null;
    }

    if (user === null) {
      return null;
    }

    if (user.mobileLoginMessageSend === null || user.mobileLoginMessageSend === false) {
      return <LoginPopup/>;
    }
    return null;

  }

  initialize = async () => {
    const { getUserInfo } = this.props;
    const data = await getUserInfo.refetch();
    let user = null;

    if (data && data.data && data.data.me) {
      user = data.data.me;
    }

    this.setState({ user });
  }

  renderAppBar = () => {
    const { classes } = this.props;
    const { badge_count } = this.state;

    return (
      <AppBar position="fixed" color="primary" className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <IconButton color="inherit" aria-label="menu" onClick={() => this.setDrawerOpen(true)}>
            <MenuIcon/>
          </IconButton>
          <div className={classes.flex}/>
          <Logo
            className={classes.scoreshots}
            onClick={() => this.gotoRoute('/')}
          />
          <div className={classes.flex}/>
          <IconButton color="inherit" aria-label="Support / Feedback" onClick={() => this.openIntercom(true)}>
            <Badge
              color="error"
              classes={{ badge: classnames(classes.badge, { [classes.badge_hidden]: (badge_count === 0) }) }}
              badgeContent={badge_count}
              max={9}
            >
              <MessageTextIcon/>
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
    );
  }

  renderDrawer = () => {
    const { classes } = this.props;
    const { user } = this.state;
    let client = null, avatar_src = null;

    if (user !== null) {
      client = (user.client || null);

      if (client !== null) {
        avatar_src = `https://${client.logoBucket}.s3.us-east-2.amazonaws.com/${client.logoKey}`;
      }
    }

    return (
      <SwipeableDrawer
        open={this.state.drawer_open}
        onClose={() => this.setDrawerOpen(false)}
        onOpen={() => this.setDrawerOpen(true)}
      >
        <div className={classes.drawer}>
          <ButtonBase className={classes.user_button} onClick={() => this.gotoRoute('/user')}>
            <div className={classes.user_info}>
              <Avatar className={classes.user_avatar} src={avatar_src} aria-hidden="true">
              </Avatar>
              <div className={classes.user_text_root}>
                {(user && client) ? (
                  <>
                    <Typography variant="h6" className={classes.user_text}>
                      {user.firstname} {user.lastname}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.user_text}>
                      {client.name}
                    </Typography>
                  </>
                ) : (
                  <CircularProgress size={48}/>
                )}
              </div>
            </div>
          </ButtonBase>
          <Divider/>
          <List>
            <ListItem button onClick={() => this.gotoRoute('/')}>
              <ListItemIcon><PencilIcon/></ListItemIcon>
              <ListItemText>Create</ListItemText>
            </ListItem>
            <ListItem button onClick={() => this.gotoRoute('/create/posts')}>
              <ListItemIcon><ViewDashboardIcon/></ListItemIcon>
              <ListItemText>Feed</ListItemText>
            </ListItem>
            <ListItem button onClick={() => this.gotoRoute('/media/photo')}>
              <ListItemIcon><FolderMultipleImageIcon/></ListItemIcon>
              <ListItemText>Media</ListItemText>
            </ListItem>
            <ListItem button onClick={() => this.gotoRoute('/team')}>
              <ListItemIcon><AccountMultipleIcon/></ListItemIcon>
              <ListItemText>Team</ListItemText>
            </ListItem>
          </List>
          <Divider/>
          <List>
            <ListItem button onClick={() => this.showTour()}>
              <ListItemIcon><PlayCircleOutlineIcon/></ListItemIcon>
              <ListItemText>Tutorial</ListItemText>
            </ListItem>
            <ListItem button onClick={() => this.openIntercom(false)}>
              <ListItemIcon><MessageReplyTextIcon/></ListItemIcon>
              <ListItemText>Support</ListItemText>
            </ListItem>
            <ListItem button onClick={() => this.gotoRoute('/legal/privacy')}>
              <ListItemIcon><SecurityIcon/></ListItemIcon>
              <ListItemText>Privacy</ListItemText>
            </ListItem>
            <ListItem button onClick={() => this.gotoRoute('/legal/terms')}>
              <ListItemIcon><FileDocumentEditOutlineIcon/></ListItemIcon>
              <ListItemText>Terms</ListItemText>
            </ListItem>
          </List>
          <Divider/>
          <List>
            <ListItem button onClick={() => this.gotoRoute('/user')}>
              <ListItemIcon><AccountIcon/></ListItemIcon>
              <ListItemText>Profile</ListItemText>
            </ListItem>
            <ListItem button onClick={() => auth.logout()}>
              <ListItemIcon><LogoutIcon/></ListItemIcon>
              <ListItemText>Log out</ListItemText>
            </ListItem>
          </List>
        </div>
      </SwipeableDrawer>
    );
  }

  getNavValue = () => {
    const { location } = this.props;
    const path = location.pathname;
    let key = 'create';

    if (matchPath(path, '/create/:type?') !== null) {
      key = 'feed';
    } else if (matchPath(path, '/media/:type?/:folder?') != null) {
      key = 'media';
    } else if (matchPath(path, '/team') !== null) {
      key = 'team';
    } else if (matchPath(path, '/user') !== null) {
      key = '';
    }

    return key;
  }

  renderBottomNav = () => {
    const { classes } = this.props;
    const key = this.getNavValue();
    return (
      <BottomNavigation value={key} className={classes.bottom_navigation}>
        <BottomNavigationAction
          label="Create" value="create"
          component={Link} to="/"
          icon={<PencilIcon fontSize="small" style={{marginTop: '10px',marginBottom: '-5px'}}
          />}
        />
        <BottomNavigationAction
          label="Feed" value="feed"
          component={Link} to="/create/posts"
          icon={<ViewDashboardIcon fontSize="small" />}
        />
        <BottomNavigationAction
          label="Media" value="media"
          component={Link} to="/media/photo"
          icon={<FolderMultipleImageIcon fontSize="small"/>}
        />
        <BottomNavigationAction
          label="Team" value="team"
          component={Link} to="/team"
          icon={<AccountMultipleIcon fontSize="small"/>}
        />
      </BottomNavigation>
    );
  }

  setDrawerOpen = (open) => {
    this.setState({ drawer_open: open });
  };

  showTour = () => {
    const { showTour } = this.props;

    if (showTour) {
      this.setState({ drawer_open: false }, showTour);
    }
  }

  gotoRoute = (path) => {
    const { history } = this.props;
    const { drawer_open } = this.state;

    if (drawer_open) {
      this.setState({ drawer_open: false }, () => {
        history.push(path);
      });
    } else {
      history.push(path);
    }
  }



  renderReleaseNotesDialog = () => {

    const styles = (theme) => ({
      root: {
        margin: 0,
        padding: theme.spacing(2),
      },
      closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
      },
    });

    const CustomDialogTitle = withStyles(styles)((props) => {
      const { children, classes, onClose, ...other } = props;
      return (
          <DialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                  <CloseIcon />
                </IconButton>
            ) : null}
          </DialogTitle>
      );
    });

    return (
        <Dialog aria-labelledby="customized-dialog-title"
                open={this.state.releaseShow}
                onClose={() => this.setState({releaseShow: false})}
        >
          <CustomDialogTitle id="simple-dialog-title" onClose={() => this.setState({releaseShow: false}) }>Latest Updates</CustomDialogTitle>
          <DialogContent dividers>
            <div dangerouslySetInnerHTML={{ __html: this.state.release }} />
          </DialogContent>
        </Dialog>
    );
  }
  openIntercom = (messages) => {
    const { drawer_open } = this.state;

    if (drawer_open) {
      this.setState({ drawer_open: false}, () => {
        window.Intercom(messages ? 'showMessages' : 'show');
      });
    } else {
      window.Intercom(messages ? 'showMessages' : 'show');
    }
  }

}

// -------------------------------------------------------------------------- //

export default compose(
  graphql(GET_USER_INFO, { name: 'getUserInfo' }),
  withRouter,
  withStyles(STYLES),
)(BetaNavMobile);

// -------------------------------------------------------------------------- //
