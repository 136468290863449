
// -------------------------------------------------------------------------- //

import { IWidgetFlow } from '../flow';
import { SpacingOption } from '../options/spacing';

// -------------------------------------------------------------------------- //

export class CenterHorizontalFlow extends IWidgetFlow {

  _spacing = null;

  constructor(name) {
    super(name);
    this._spacing = new SpacingOption('spacing', 'Spacing');
  }

  get options() {
    return [ ...super.options, this._spacing ];
  }

  get(key) {
    switch (key) {
      case 'spacing': {
        return this._spacing.value;
      }
      default: {
        return super.get(key);
      }
    }
  }

  set(key, value) {
    switch (key) {
      case 'spacing': {
        this._spacing.value = Number(value);
        break;
      }
      default: {
        super.set(key, value);
        break;
      }
    }
  }

  reset(widgets, size, area) {
    if (widgets.length <= 1) {
      this._spacing.min = 0;
      this._spacing.max = 0;
      this._spacing.value = 0;
    } else {
      this._spacing.min = -Math.ceil(size.x * 0.5);

      this._spacing.max = Math.floor(
        (area.width - size.x * widgets.length) / (widgets.length - 1)
      );

      this._spacing.value = Math.floor(
        (area.width - size.x * widgets.length) / (widgets.length + 1)
      );
    }
  }

  apply(widgets, size, area) {
    let x = (area.center.x - (
      size.x * widgets.length + // N widgets
      this._spacing.value * (widgets.length - 1) // (N-1) spacings
    ) * 0.5);

    const y = (area.center.y - size.y * 0.5);

    for (let i = 0; i < widgets.length; ++i) {
      widgets[i].move(x, y);
      x += (size.x + this._spacing.value);
    }

    return false;
  }

};

// -------------------------------------------------------------------------- //

