
// -------------------------------------------------------------------------- //

import gql from 'graphql-tag';
import uuid from 'uuid4';

import { WIDGET_FLOWS } from './dict.js';
import APOLLO_CLIENT from '../../client';

// -------------------------------------------------------------------------- //

const GQL_GET_TEMPLATE = gql`
  query($id: ID!) {
    template(id: $id) {
      id
      width
      height
      url
    }
  }
`;

// -------------------------------------------------------------------------- //

export class WidgetBox {

  constructor(fabric) {
    this.class = fabric.util.createClass(fabric.Object, {
      type: 'widget-box',
      selectable: true,
      originX: 'center',
      originY: 'center',
      visible: true,
      widget_template: null,
      widget_flow: null,
      widget_width: null,
      widget_height: null,
      widget_min_count: null,
      widget_max_count: null,
      widget_uuid: null,

      initialize(options = {}) {
        this.callSuper('initialize', options);

        // we create and store this lambda once
        // so it retains identity for 'off'
        this.on_step_change = () => {
          this.onStepChange(this.canvas.step);
        };

        this.widget_pieces = [];
        this.loaded = false;
      },

      async load() {
        if (this.loaded || this.canvas.step === 'admin') {
          return Promise.resolve();
        }

        if (this.widget_uuid === null) {
          this.widget_uuid = uuid();
        }

        this.canvas.on('stepChange', this.on_step_change);

        let promise = Promise.resolve();

        if (typeof this.widget_template === 'string') {
          promise =  APOLLO_CLIENT.query({
            query: GQL_GET_TEMPLATE,
            fetchPolicy: 'network-only',
            variables: { id: this.widget_template },
          }).then((response) => {
            const { width, height, url } = response.data.template;
            this.widget_width = width;
            this.widget_height = height;
            return fetch(url, { cache: 'no-store' });
          }).then((response) => (
            response.json()
          )).then((json) => {
            this.widget_template = json;
          });
        }

        return promise.then(() => {
          let pieces = this.canvas.getObjects('widget-piece');
          this.widget_pieces = [];

          for (let i = 0; i < pieces.length; ++i) {
            if (pieces[i].widget_box !== this.widget_uuid) {
              continue;
            }

            pieces[i].widget_box = this;
            pieces[i].widget_json = this.widget_template;
            this.widget_pieces.push(pieces[i]);
          }

          this.widget_pieces.sort((a, b) => (
            a.widget_index - b.widget_index
          ));

          this.canvas.renderOnAddRemove = false;

          this.widget_pieces.forEach((piece) => {
            piece.load();
          });

          let count = 0;

          if (this.widget_pieces.length !== 0) {
            count = this.widget_pieces.length;
          } else if ('widget_count' in this) {
            count = Number(this.widget_count);
            delete this.widget_count;
          } else if (this.widget_min_count !== null) {
            count = this.widget_min_count;
          }

          // we load the widget flow after the pieces so we can
          // have all the data present to call reset().
          if (typeof this.widget_flow === 'string') {
            const key = this.widget_flow;
            this.widget_flow = null;
    
            if (key in WIDGET_FLOWS) {
              this.widget_flow = new WIDGET_FLOWS[key].type(key);
            }
          }

          if (this.widget_flow !== null) {
            const size = this.calcLayoutSize();
            const area = this.calcLayoutArea();
            this.widget_flow.reset(this.widget_pieces, size, area);

            if (this.widget_options) {
              this.widget_options.forEach((option) => {
                this.widget_flow.set(option.key, option.value);
              });
    
              delete this.widget_options;
            }
          }

          this.loaded = true;
          return this.resize(count);
        });
      },

      unload() {
        if (!this.loaded) {
          return;
        }

        this.widget_pieces.forEach((piece) => {
          piece.widget_box = null;
          piece.unload();
          this.canvas.remove(piece);
        });

        this.widget_uuid = null;
        this.widget_template = null;
        this.widget_flow = null;
        this.widget_width = null;
        this.widget_height = null;
        this.widget_min_count = null;
        this.widget_max_count = null;
        this.widget_pieces = null;
        this.canvas.off('stepChanged', this.on_step_change);
        this.loaded = false;
      },

      async resize(count) {
        if (!this.loaded) {
          return Promise.resolve();
        }

        if (this.widget_min_count >= 0 && count < this.widget_min_count) {
          count = this.widget_min_count;
        } else if (this.widget_max_count >= 0 && count > this.widget_max_count) {
          count = this.widget_max_count;
        }

        if (this.widget_pieces.length === count) {
          return Promise.resolve();
        }

        this.canvas.renderOnAddRemove = false;
        let promises = [];

        if (this.widget_pieces.length > count) {
          const difference = (this.widget_pieces.length - count);

          this.widget_pieces.splice(
            -difference, difference
          ).forEach((piece) => {
            piece.unload();

            this.canvas.ignoreUndo = true;
            this.canvas.remove(piece);
            this.canvas.ignoreUndo = false;
          });
        } else { // (this.widget_pieces.length < count)
          while (this.widget_pieces.length < count) {
            let piece = new fabric.WidgetPiece({
              widget_box: this,
              widget_json: this.widget_template,
              widget_index: this.widget_pieces.length,
              width: this.widget_width,
              height: this.widget_height,
            });

            const insertion = this.canvas.getObjects().indexOf(this);

            this.canvas.ignoreUndo = true;
            this.canvas.insertAt(piece, insertion);
            this.canvas.ignoreUndo = false;

            this.widget_pieces.push(piece);
            promises.push(piece.load());
          }
        }

        return Promise.all(promises).then(() => {
          this.layout(true);
          this.canvas.renderOnAddRemove = true;
          this.canvas.requestRenderAll();
        });
      },

      calcLayoutSize() {
        return  {
          x: this.widget_width,
          y: this.widget_height,
        };
      },

      calcLayoutArea() {
        // calculate absolute coords without the cache
        const bounds = this.getBoundingRect(true, true);

        return {
          // x / y / width / height
          x: bounds.left, y: bounds.top,
          width: bounds.width, height: bounds.height,

          // top / left / bottom / right
          left: bounds.left, top: bounds.top,
          right: (bounds.left + bounds.width),
          bottom: (bounds.right + bounds.height),

          // center x / y
          center: {
            x: (bounds.left + bounds.width * 0.5),
            y: (bounds.top + bounds.height * 0.5),
          },
        };
      },

      layout(reset = false) {
        if (!this.loaded) {
          return false;
        }

        if (this.widget_flow === null) {
          return false;
        }

        const size = this.calcLayoutSize();
        const area = this.calcLayoutArea();

        if (reset) {
          this.widget_flow.reset(this.widget_pieces, size, area);
        }

        return this.widget_flow.apply(this.widget_pieces, size, area);
      },

      onStepChange(step) {
        if (!this.canvas) {
          return;
        }

        switch (step) {
          case 'widgets':
          case 'admin': {
            this.set('visible', true);
            break;
          }
          default: {
            this.set('visible', false);
            break;
          }
        }
      },

      _render(ctx) {
        if (this.visible && this.canvas && this.canvas.step === 'admin') {
          if (this.canvas.getActiveObject() === this) {
            ctx.fillStyle = 'grey';
            ctx.fillRect(-this.getScaledWidth() / 2, -this.getScaledHeight() / 2, this.getScaledWidth(), this.getScaledHeight());
          }

          ctx.lineWidth = 4.0;
          ctx.strokeStyle = 'black';
          ctx.rect(-this.getScaledWidth() / 2, -this.getScaledHeight() / 2, this.getScaledWidth(), this.getScaledHeight());
          ctx.stroke();
        }
      },

      toObject(props) {
        props.push(
          'widget_uuid', 'widget_template',
          'widget_flow', 'widget_options',
          'widget_width', 'widget_height',
          'widget_min_count', 'widget_max_count',
        );

        let widget_flow = null;
        let widget_options = null;

        if (this.loaded) {
          if (this.widget_flow !== null) {
            widget_flow = this.widget_flow.name;
  
            widget_options = this.widget_flow.options.map((option) => ({
              key: option.key, value: option.value
            }));
          }
        }

        return fabric.util.object.extend(
          this.callSuper('toObject', props), {
            widget_uuid: this.widget_uuid,
            widget_template: this.widget_template,
            widget_flow, widget_options,
            widget_width: this.widget_width,
            widget_height: this.widget_height,
            widget_min_count: this.widget_min_count,
            widget_max_count: this.widget_max_count,
          }
        );
      }
    });

    this.fromObject = (object, callback) => (
      fabric.Object._fromObject('WidgetBox', object, callback)
    );
  }

};

// -------------------------------------------------------------------------- //
