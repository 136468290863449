import React, { Component } from 'react';
import { graphql, compose, Query } from 'react-apollo'
import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';

const styles = theme => ({
  img: {
  	width: '100%',
    backgroundImage: "url('https://ss3-assets.s3.us-east-2.amazonaws.com/grid.png')",
    backgroundRepeat: 'repeat',
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
  }
});

const GET_CLIENT = gql`
  query {
  	me {
  		client {
  			id
  		}
  	}
  }
`;

const GET_GRAPHICS = gql`
	query (
		$client: ID!
	) {
		graphicSearch(
			where: {
				OR: [
					{
						visible: PREMIUM
						clients_some: {
							id: $client
						}
					},
					{
						visible: GLOBAL
					}
				]
			}) {
			id
			name
			image
		}
	}
`;

class GraphicSelect extends Component {
	select = (id, image, name) => (e) => {
		const { onSelect } = this.props;

		if (onSelect) {
			onSelect(id, image, name);
		}
	}

	render() {
		const { classes, data } = this.props;
		return data && data.me ? (
			<Query query={GET_GRAPHICS} variables={{client: data.me.client.id}}>
				{
					({ data }) => {
						return data && data.graphicSearch ?
							data.graphicSearch.map((graphic, index) => (
								<div key={graphic.id} style={{padding: 16 }}>
									<img
										alt={graphic.name}
										className={classes.img}
										crossOrigin="anonymous"
										onClick={this.select(graphic.id, graphic.image, graphic.name)}
										src={`${graphic.image}`} />
								</div>
							)) : null
					}
				}
			</Query>
		) : null;
	}
}

export default compose(
	graphql(GET_CLIENT)
)(withStyles(styles)(GraphicSelect));
