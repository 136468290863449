
// -------------------------------------------------------------------------- //

import ScoreshotsAction from './Action';

// -------------------------------------------------------------------------- //

class ScoreshotsPut extends ScoreshotsAction {

  constructor(bucket, filename = '', folder = null) {
    super(bucket, filename);
    this.folder = folder;
  }

  remove = (key) => {
    const request = {
      token: this.token,
      bucket: this.bucket,
      object: key,
      folder : '',
    };

    return fetch(`${process.env.REACT_APP_SS_API_ENDPOINT}v1/S3/delete-object/dev`, {
      mode: 'cors',
      method: 'POST',
      body: JSON.stringify({ body: request }),
    });
  }

  put = (file, type, callback, filename = null, folder = null) => {
    let self = this;
    filename = filename === null ? this.filename : filename;
    folder = folder === null ? this.folder : folder;

    let request = {
      token: this.token,
      bucket: this.bucket,
      object: filename,
      chunked : true,
      type
    };

    if (folder !== null) {
      request.folder = folder;
    }

    return new Promise((resolve, reject) => {
    let step = 5242880; //5MB chunks
    let start = 0;
    let size = file.size;
    if(step > size){
      step = size - 1;
    }
    let blob = file.slice(start,step);
    let file_token = Math.random().toString(36).substring(7);
    let reader = new FileReader();
    reader.onload = function (e) {

      let data = btoa(this.result);

      request.file = data;
      request.size = size;
      request.chunk_size = start;
      request.step = step;
      request.file_token = file_token;
    

      fetch(`${process.env.REACT_APP_SS_API_ENDPOINT}v1/S3/put-object/dev`, {
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify({ body: request })
      }).then(response => {
        if (!response.ok) {
          throw new Error(response);
        }

        return response;
      }).then(response => {

        //start += step;

        if (start <= size) {
         let start_incr = start + step;
         let loaded_difference = size - start;
         if(start_incr > size){
           start += loaded_difference
         }else{
           start += step;
         }

         loaded_difference = size - start;


          if (loaded_difference > step) {
            let end_offset = start + step;
            if(end_offset > size){
              end_offset = size - 1;
            }else{
              //end_offset = end_offset + 1;
            }
            blob = file.slice(start, end_offset);


          } else {
            let end_offset = start + loaded_difference;
            if(end_offset > size){
              end_offset = size - 1;
            }else{
            //  end_offset = end_offset + 1;
            }
            blob = file.slice(start, end_offset);

          }


          if(size===start){
            if (callback) {
              callback({ response, action: self });
            }

            resolve({ response, action: self });
          }
          else{
            reader.readAsBinaryString(blob);
          }


        } else {

          if (callback) {
            callback({ response, action: self });
          }

          resolve({ response, action: self });
        }
      }).catch((error) => {
        reject(error);
      });


    }

    reader.readAsBinaryString(blob);

    });
  }

}

// -------------------------------------------------------------------------- //

export default ScoreshotsPut;

// -------------------------------------------------------------------------- //
