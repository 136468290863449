import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import InformationOutline from 'mdi-material-ui/InformationOutline';

const styles = theme => ({
  paper: {
    padding: theme.spacing(1),
    position: 'relative',
    backgroundColor: theme.palette.background.default,
  },
  info: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.7)',
  },
  caption: {
    paddingLeft: 32,
  },
});

class Tip extends Component {
  render() {
    const { classes, text, heading } = this.props;
    return (
      <Paper className={classes.paper}>
        <InformationOutline className={classes.info} />
        <Typography variant="caption" className={classes.caption}>
          {heading ? <b>{heading} </b> : null }
          {text}
        </Typography>
      </Paper>
    );
  }
}

export default withStyles(styles)(Tip);
