
// -------------------------------------------------------------------------- //

import React, {Component} from 'react';

import PostsFeed from '../components/PostsFeed';
import { PageTitle } from '../../../title';
import {compose} from "react-apollo";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
  root: {
  },
});

// -------------------------------------------------------------------------- //

class Posts extends Component {
  render() {
    return (
      <React.Fragment>
        <PageTitle title="Published"/>
        <PostsFeed mode="selfClient"/>
      </React.Fragment>
    )
  }
}

// -------------------------------------------------------------------------- //

export default compose(
  withRouter,
  withStyles(styles),
)(Posts);
