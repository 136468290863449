import * as React from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import { withStyles } from '@material-ui/core';
import { compose } from 'react-apollo';


const KeyCodes = {
  comma: 188,
  enter: 13,
};

const classes = {
  container: {
    marginTop: '15px',
    '& .ReactTags__tags': {
      position: 'relative',
    },
    '&  .ReactTags__tagInput': {
      width: '100%',
      border: 'none',
      display: 'inline-block',
      '& input.ReactTags__tagInputField': {
        height: '40px',
        margin: '0',
        fontSize: '16px',
        borderBottom: '1px solid gray',
        fontWeight: '500',
        width: '100%',
        border: 'none',
        outline: 'none',
      },
    },
    '& div.ReactTags__selected': {
      '& span.ReactTags__tag': {
        border: '1px solid #ddd',
        background: '#eee',
        fontSize: '12px',
        display: 'inline-block',
        padding: '5px',
        margin: '5px 5px',
        cursor: 'move',
        borderRadius: '2px',
      },
      '& a.ReactTags__remove': {
        color: '#aaa',
        marginLeft: '5px',
        cursor: 'pointer',
      },
    },
    '& button.ReactTags__remove': {
      border: 'none',
    },
    '& div.ReactTags__suggestions': {
      '& ul': {
        'list-style-type': 'none',
        boxShadow: '.05em .01em .5em white',
        backgroundColor: 'white !important',
        position: 'relative',
        left: '-40px',
        width: '415px',
      },
      '& li.ReactTags__activeSuggestion': {
        background: '#b7cfe0',
        cursor: 'pointer',
      },
      '& li': {
        borderBottom: '1px solid #ddd',
        height: '30px',
        backgroundColor: '#FFFFFF',
        width: '100%',
        padding: '5px 10px',
        '& mark': {
          textDecoration: 'underline',
          background: 'white',
          fontWeight: '600',
        },
      },
    },

  },

};


const delimiters = [KeyCodes.comma, KeyCodes.enter];

class TagComponent extends React.Component {
  state = {
    tags: [],
    suggestions: [],
    sortedTags: [],
    errorMEssage: null,
  }

  constructor(props) {
    super(props);

    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
  }

  componentDidMount() {
    const { tags } = this.props;
    if (this.props.startingTags.length > 0) {
      const newTags = this.formatArray(this.props.startingTags);
      this.setState({ tags: newTags });
    }

    const tagArray = [];
    if (this.props.type === 'tags') {
      if (tags.data.tags) {
        if (tags.data.tags.length > 0) {
          tags.data.tags.map((tag) => {
            tagArray.push({ id: tag.id, text: tag.name });
            return tag;
          });

          if (this.state.suggestions !== tagArray) this.setState({ suggestions: tagArray });
        }
      }
    } else if (this.props.type === 'childTemplate') {
      if (tags.length > 0) {
        tags.forEach((tag) => {
          tagArray.push({ id: tag.id, text: tag.id });
        });
        if (this.state.suggestions !== tagArray) this.setState({ suggestions: tagArray });
      }
    }
  }

  formatArray = (arr) => {
    const newArray = [];
    if (this.props.type === 'childTemplate') {
      arr.map((el) => {
        newArray.push({ id: el.id, text: el.id });
        return el;
      });
    } else if (this.props.type === 'tags') {
      arr.map((el) => {
        newArray.push({ id: el.id, text: el.name });
        return el;
      });
    }
    return newArray;
  }

  handleDelete(i) {
    const { tags } = this.state;

    const sortedTags = tags.slice();
    sortedTags.sort((t1, t2) => {
      if (t1.text > t2.text) {
        return 1;
      } if (t2.text > t1.text) {
        return -1;
      }
      return 0;
    });

    if (this.props.type === 'tags') {
      this.props.removeTagFromTemplate({
        variables: {
          tagId: sortedTags[i].id,
          template: this.props.templateId,
        },
      });
    } else {
      this.props.removeTagFromTemplate({
        variables: {
          tagId: this.props.templateId,
          templates: sortedTags[i].id,
        },
      });
    }
    this.setState({
      tags: sortedTags.filter((tag, index) => index !== i),
    }, () => {
      this.props.updateTags(this.state.tags);
    });
  }

  handleAddition(tag) {
    switch (this.props.type) {
      case 'tags': {
        const currentTagsName = this.props.tags.data.tags.map((tg) => tg.name);
        if (currentTagsName.includes(tag.text)) {
          this.props.addTagToTemplate({
            variables: {
              tagId: tag.id,
              templates: [this.props.templateId],
            },
          });
          this.setState((state) => ({ tags: [...state.tags, tag] }), () => {
            this.props.updateTags(this.state.tags);
          });
          break;
        } else {
          this.props.createTagWithTemplate({
            variables: {
              name: tag.text.toLowerCase(),
              template: this.props.templateId,
            },
          }).then((data) => {
            this.setState((state) => ({
              // eslint-disable-next-line max-len
              tags: [...state.tags, { id: data.data.createTagWithTemplate.id, text: tag.text.toLowerCase() }],
              // eslint-disable-next-line max-len
              suggestions: [...state.suggestions, { id: data.data.createTagWithTemplate.id, text: tag.text.toLowerCase() }],
            }), () => {
              // eslint-disable-next-line react/destructuring-assignment
              this.props.updateTags(this.state.tags);
            });
          });
          break;
        }
      }
      case 'childTemplate':
      default: {
        if (this.state.suggestions.map((template) => template.id).includes(tag.id)) {
          this.setState({ errorMessage: null });
          if (tag.id !== this.props.templateId) {
            this.props.addTagToTemplate({
              variables: {
                tagId: this.props.templateId,
                templates: tag.id,
              },
            });
            this.setState((state) => ({tags: [...state.tags, tag]}), () => {
              this.props.updateTags(this.state.tags);
            });
          }
        } else {
          this.setState({ errorMessage: <p style={{ fontSize: '10px', color: 'red' }}>Invalid template</p> });
          break;
        }
      }
    }
  }

  render() {
    const { classes } = this.props;
    const sortedTags = this.state.tags.slice();
    sortedTags.sort((t1, t2) => {
      if (t1.text > t2.text) {
        return 1;
      } if (t2.text > t1.text) {
        return -1;
      }
      return 0;
    });

    return (
      <>
        {/* eslint-disable-next-line max-len */}
        <div className={this.props.customStyle ? this.props.customStyle.container : classes.container}>
          <ReactTags
            tags={sortedTags}
            placeholder={this.props.customPlaceholder ? this.props.customPlaceholder : 'Add a new tag'}
            allowDragDrop={false}
            suggestions={this.state.suggestions}
            handleDelete={this.handleDelete}
            handleAddition={this.handleAddition}
            delimiters={this.props.customDelimeters ? this.props.customDelimeters : delimiters}
            readOnly={this.props.valid}
          />
          {this.state.errorMessage}
        </div>
      </>
    );
  }
}

export default compose(

  withStyles(classes),
)(TagComponent);
