import React, { Component } from 'react';
import Slider from 'rc-slider';

class SliderNoInput extends Component {
  state = {}

  textInputChange = (e) => {
    const { disableRounding = false } = this.props;
    let value;

    if (disableRounding) {
      value = parseFloat(e.target.value);
    } else {
      value = parseInt(e.target.value);
    }

    if (e.target.value && !isNaN(value) && value) {
      if (this.props.min && value < this.props.min) {
        value = this.props.min;
      }

      if (this.props.max && value > this.props.max) {
        value = this.props.max;
      }

      this.props.onChange(value);
    }

    this.setState({ value: e.target.value });
  }

  sliderValueChanged = (e) => {
    const { disableRounding = false } = this.props;

    if (disableRounding) {
      this.setState({ value: Number(e).toString() });
    } else {
      this.setState({ value: Math.floor(e).toString() });
    }

    this.props.onChange(e);
  }

  componentDidMount() {
    const { disableRounding = false } = this.props;

    if (disableRounding) {
      this.setState({ value: Number(this.props.value).toString() });
    } else {
      this.setState({ value: Math.floor(this.props.value).toString() });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      const { disableRounding = false } = this.props;

      if (disableRounding) {
        this.setState({ value: Number(this.props.value).toString() });
      } else {
        this.setState({ value: Math.floor(this.props.value).toString() });
      }
    }
  }

  render() {
    return (
      <div style={{display: 'flex'}}>
        <div style={{paddingTop: '24px', paddingBottom: '12px', width:'100%'}}>
          <Slider {...this.props} onChange={this.sliderValueChanged}/>
        </div>
      </div>
    );
  }
}

export default SliderNoInput;
