
import {
  Crop,
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatItalic,
  FormatVerticalAlignBottom,
  FormatVerticalAlignCenter,
  FormatVerticalAlignTop,
  SwapHorizontal,
  SwapVertical,
} from 'mdi-material-ui';

export default {
  props: {
    fontFamily: {
      type: 'font',
      name: 'Font Family',
    },
    visible : {
      type : 'bool',
      name : 'Visible',
    },
    top: {
      type: 'number',
      name: 'Dist from Top',
    },
    left: {
      type: 'number',
      name: 'Dist from Left',
    },
    width: {
      type: 'number',
      name: 'Width',
    },
    height: {
      type: 'number',
      name: 'Height',
    },
    scaleX: {
      type: 'number',
      name: 'Scale X',
    },
    scaleY: {
      type: 'number',
      name: 'Scale Y',
    },
    opacity: {
      type: 'number',
      name: 'Opacity',
    },
    originX: {
      type: 'buttonGroup',
      name: 'Horizontal Align',
      with: [
        {
          icon: FormatAlignLeft,
          type: 'custom',
          isActive: (({ target }) => (target.get('originX') === 'left')),
          onClick: ({ target }) => {
            target.set('originX', 'left');

            if (target.type === 'i-text') {
              target.set('textAlign', 'left');
            }
          },
        },
        {
          icon: FormatAlignCenter,
          type: 'custom',
          isActive: (({ target }) => (target.get('originX') === 'center')),
          onClick: ({ target }) => {
            target.set('originX', 'center');

            if (target.type === 'i-text') {
              target.set('textAlign', 'center');
            }
          },
        },
        {
          icon: FormatAlignRight,
          type: 'custom',
          isActive: (({ target }) => (target.get('originX') === 'right')),
          onClick: ({ target }) => {
            target.set('originX', 'right');

            if (target.type === 'i-text') {
              target.set('textAlign', 'right');
            }
          },
        },
      ],
    },
    originY: {
      type: 'buttonGroup',
      name: 'Vertical Align',
      with: [
        {
          icon: FormatVerticalAlignTop,
          type: 'set',
          prop: 'originY',
          val: 'top',
        },
        {
          icon: FormatVerticalAlignCenter,
          type: 'set',
          prop: 'originY',
          val: 'center',
        },
        {
          icon: FormatVerticalAlignBottom,
          type: 'set',
          prop: 'originY',
          val: 'bottom',
        },
      ],
    },
    fill: {
      type: 'color',
      name: 'Fill',
    },
    stroke: {
      type: 'color',
      name: 'Stroke Color',
    },
    fontColor: {
      type: 'color',
      name: 'Text Color',
    },
    angle: {
      type: 'number',
      name: 'Angle Rotation',
    },
    strokeWidth: {
      type: 'number',
      name: 'Stroke Width',
    },
    clipMedia: {
      type: 'buttonGroup',
      name: 'Logo Properties',
      with: [
        {
          icon: Crop,
          type: 'toggle',
          name: 'Clip Media',
          prop: 'clipMedia',
          val: [
            false,
            true,
          ],
        },
      ],
    },
    fontWeight: {
      type: 'buttonGroup',
      name: 'Text Style',
      with: [
        {
          icon: FormatBold,
          type: 'toggle',
          prop: 'fontWeight',
          val: [
            'normal',
            'bold',
          ],
        },
        {
          icon: FormatItalic,
          type: 'toggle',
          prop: 'fontStyle',
          val: [
            'normal',
            'italic',
          ],
        },
      ],
    },
    flipX: {
      type: 'buttonGroup',
      name: 'Mirror & Flip',
      with: [
        {
          icon: SwapHorizontal,
          type: 'toggle',
          prop: 'flipX',
          val: [
            false,
            true,
          ],
        },
        {
          icon: SwapVertical,
          type: 'toggle',
          prop: 'flipY',
          val: [
            false,
            true,
          ],
        },
      ],
    },
    charSpacing: {
      type: 'number',
      name: 'Tracking',
    },
    lineHeight: {
      type: 'number',
      name: 'Leading',
    },
    shadow: {
      type: 'shadow',
      name: 'Box Shadow',
    },
    textureBlendMode: {
      type: 'select',
      name: 'Texture Blend',
      values: [
        {
          name: 'Blend',
          val: 'multiply'
        },
        {
          name: 'Fade In',
          val: 'source-out',
        },
        {
          name: 'Fade Out',
          val: 'source-in'
        }
      ]
    },
    textureSource: {
      type: 'texture',
      name: 'Texture'
    },
    textureScaleX: {
      type: 'number',
      name: 'Texture Scale X'
    },
    textureScaleY: {
      type: 'number',
      name: 'Texture Scale Y'
    },
    widget: {
      type: 'widget',
      name: 'Widget'
    },
    widgetMinCount: {
      type: 'number',
      name: 'Min # Widgets'
    },
    widgetMaxCount: {
      type: 'number',
      name: 'Max # Widgets'
    },
    count: {
      type: 'number',
      name: 'Default # Widgets'
    },
    spacing: {
      type: 'number',
      name: 'Widget Spacing'
    },
    widgetFlow: {
      type: 'select',
      name: 'Widget Flow',
      values: [
        {
          name: 'Fill (Horizontal)',
          val: 'fill-horizontal'
        },
        {
          name: 'Fill (Vertical)',
          val: 'fill-vertical'
        },
        {
          name: 'Center (Horizontal)',
          val: 'center-horizontal'
        },
        {
          name: 'Center (Vertical)',
          val: 'center-vertical'
        },
        {
          name: 'Symmetrical (Horizontal)',
          val: 'symmetrical-horizontal'
        },
        {
          name: 'Symmetrical (Vertical)',
          val: 'symmetrical-vertical'
        },
        {
          name: 'Grid (Horizontal)',
          val: 'horizontal'
        },
        {
          name: 'Grid (Vertical)',
          val: 'vertical'
        },
        {
          name: 'Triangle (Horizontal)',
          val: 'triangle-horizontal'
        }
      ]
    },
    barGraphLayout: {
      type: 'select',
      name: 'Bar Graph Layout',
      values: [
        {
          name: 'Vertical',
          val: 'vertical'
        },
        {
          name: 'Horizontal',
          val: 'horizontal'
        }
      ]
    },
    sliderBarDirection: {
      type: 'select',
      name: 'Slider Bar Direction',
      values: [
        {
          name: 'Left-to-right',
          val: 'ltr',
        },
        {
          name: 'Right-to-left',
          val: 'rtl',
        },
      ],
    },
    quickCreateDirections: {
      type: 'select',
      name: 'Quick Create Direction',
      values: [
        {
          name: 'Left-to-right',
          val: 'ltr',
        },
        {
          name: 'Right-to-left',
          val: 'rtl',
        },
      ],
    },
    strokeSymbol: {
      type: 'select',
      name: 'Stroke Symbol',
      values: [
        { name: 'Double Bogey', val: 0 },
        { name: 'Bogey', val: 1 },
        { name: 'Par', val: 2 },
        { name: 'Birdie', val: 3 },
        { name: 'Eagle', val: 4 },
        { name: 'Albatross', val: 5 },
      ],
    }
  },
  editable: {

  },
};
