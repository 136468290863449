
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Button,
  Select,
  MenuItem,
  Typography,
  withStyles,
  Grid, Divider, TextField, IconButton,
} from '@material-ui/core';

import 'rc-slider/assets/index.css';

import { AnimationHelpers, ColorAnimation } from '../../animations_views/index';
import { ShadowEditor } from './ShadowEditor';
import ColorPicker from '../../color/ColorPicker';
import { ColorButton } from '../../color/ColorButton';
import Crumbs from './Crumbs';
import FontPicker from './FontPicker';
import SliderWithInput from '../SliderWithInput';
import TextureSelect from './TextureSelect';
import websafefonts from '../../../font/websafe';
import {RotateLeft, RotateRight} from "mdi-material-ui";

// -------------------------------------------------------------------------- //

const PROPERTY_TABLE = [
  {
    key: 'fill',
    name: 'Fill',
    type: 'color',
    get: (object) => {
      if (object.fill === null) {
        return 'rgba(0, 0, 0, 0)';
      }

      return object.fill;
    },
    set: (object, value) => object.set('fill', value),
    filters: [
      'rect', 'circle', 'triangle',
      'polygon', 'barGraph', 'path', 'svg'
    ],
  },{
    key: 'fill',
    name: 'Fill',
    type: 'color',
    get: (object) => {
      if (object.fill === null) {
        return 'rgba(0, 0, 0, 0)';
      }

      return object.fill;
    },
    set: (object, value) => object.set('fill', value),
    filters: [
      'image'
    ],
  },
  {
    key: 'stroke',
    name: 'Stroke',
    type: 'color',
    get: (object) => {
      if (object.stroke === null) {
        return 'rgba(0, 0, 0, 0)';
      }

      return object.stroke;
    },
    set: (object, value) => object.set('stroke', value),
    filters: [
      'rect', 'line', 'circle', 'triangle',
      'polygon', 'polyline', 'barGraph','path', 'svg',
    ],
  },
  {
    key: 'fontColor',
    name: 'Text',
    type: 'color',
    get: (object) => (object.fontColor),
    set: (object, value) => object.set('fontColor', value),
    filters: [ 'SliderBar' ],
  },
  {
    key: 'fill',
    name: 'Slider',
    type: 'color',
    get: (object) => (object.fill),
    set: (object, value) => object.set('fill', value),
    filters: [ 'SliderBar' ],
  },
  {
    key: 'stroke',
    name: 'Bar',
    type: 'color',
    get: (object) => {
      if (object.stroke === null) {
        return 'rgba(0, 0, 0, 0)';
      }

      return object.stroke;
    },
    set: (object, value) => object.set('stroke', value),
    filters: [ 'SliderBar' ],
  },
  {
    key: 'strokeWidth',
    name: 'Stroke Width',
    type: 'stroke',
    get: (object) => (object.strokeWidth),
    set: (object, value) => object.set('strokeWidth', value),
    filters: [
      'rect', 'line', 'circle', 'triangle',
      'polygon', 'polyline', 'barGraph', 'path', 'svg',
    ],
  },
  {
    key:'opacity',
    name: 'Opacity',
    type: 'opacity',
    get: (object) => (object.opacity * 100),
    set: (object, value) => object.set('opacity', (value / 100)),
    filters: [
      'rect', 'line', 'circle', 'triangle',
      'polygon', 'polyline', 'barGraph', 'path', 'svg', 'image',
    ],
  },
  {
    key: 'strokeWidth',
    name: 'Bar Width',
    type: 'stroke',
    get: (object) => (object.strokeWidth),
    set: (object, value) => object.set('strokeWidth', value),
    filters: [ 'SliderBar' ],
  },
  {
    key: 'fontFamily',
    name: 'Font Family',
    type: 'font',
    get: (object) => (object.fontFamily),
    set: (object, value) => object.set('fontFamily', value),
    filters: [ 'SliderBar' ],
  },
];

// -------------------------------------------------------------------------- //

function GatherUsedFonts(objects) {
  let used_fonts = {};

  objects.filter((object) => (
    object.type === 'i-text' ||
    object.type === 'SliderBar'
  )).filter((object) => (
    object.fontFamily !== ''
  )).forEach((object) => {
    if (!(object.fontFamily in used_fonts)) {
      used_fonts[object.fontFamily] = 0;
    }

    ++used_fonts[object.fontFamily];
  });

  return used_fonts;
}

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  button: {
    marginTop: theme.spacing(1),
  },
  color_text: {
    marginLeft: theme.spacing(2),
  },
  img: {
    display: 'block',
    backgroundColor: 'black',
    width: '90%',
    margin: 'auto',
    cursor: 'pointer',
  },
  root: {
    padding: theme.spacing(2),
  },
  shadow_editor: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  stroke: {
    display: 'flex',
  },
  stroke_slider: {
    // marginTop: theme.spacing(4),
  },
});

// -------------------------------------------------------------------------- //

class Component extends React.Component {

  state = {
    texture_select: false,
    picker_open: false,
    anchor_position: null,
    active_property: null,
  }

  componentDidMount() {
    this.setState({
      ...AnimationHelpers.createStateForChosenIndex()
    });
  }

  setPngColor = (object, value) => {
    if (object.type === 'image') {
      const filter = new this.props.CanvasUtil.fabric.Image.filters.BlendColor({
        color: value,
        mode: 'multiply',
      });
      object.filters = [];
      object.filters.push(filter);
      object.applyFilters();
    }
  }


  setIndex = (prop, index) => {
    let stateObj = this.state;
    stateObj[prop] = index;
    this.setState(stateObj);
  }

  colorAnimation = (prop) => {
    const { canvas, CanvasUtil } = this.props;
    let obj = this.state;

    const expand_change_handler = ( index ) => {
      let m = index ===  obj[prop] ? -100 : index
      this.setIndex(prop,m);
    };

    return (
      <ColorAnimation
        prop={prop}
        canvas={canvas}
        canvasUtil={CanvasUtil}
        expanded={obj[prop]}
        onExpandChange={expand_change_handler}
        onChange={() => this.forceUpdate()}
      />
    );
  }

  handleColorChange = (self, method, prop, oldColor) => (colors) => {
    this.changeObjectColor(self, method, prop, oldColor, colors[0], 180);
  }

  changeObjectColor(self, method, prop, from, to, duration, callback = () => {}) {
    self.props.fabric.util.animateColor(from, to, duration, {
      onChange: (val) => {
        method(prop, val);
        self.props.canvas.requestRenderAll();
      },
      onComplete: () => {
        method(prop, to);
        self.forceUpdate();
        callback();
      },
    });
  }

  setGroupProp = (group) => (prop, val) => {
    group.forEach((obj) => {
      obj.set(prop, val);
      obj.setCoords();
    });
  }

  setStroke = (width) => {
    const { active, activeGroup } = this.props;
    activeGroup.forEach((obj) => {
      const stroke = ({
        strokeWidth: width,
      });

      if (!obj.stroke) {
        stroke.stroke = '#000';
      }

      obj.set(stroke);
      obj.setCoords();
    });
    active.canvas.requestRenderAll();
    this.forceUpdate();
  }

  mutateToPolygonTexture() {
    let { active, fabric, canvas } = this.props;
    const index = canvas.getObjects().indexOf(active);
    let newPolygon = null;

    if (active.type === 'polygon') {
      newPolygon = new fabric.PolygonTexture(active.points, active);
    } else if (active.type === 'circle') {
      newPolygon = new fabric.PolygonTexture([], { ...active, circle: active.radius });
    } else if (active.type === 'rect') {
      newPolygon = new fabric.PolygonTexture([], { ...active, 'rect': true });
    } else {
      return active;
    }

    canvas.insertAt(newPolygon, index);
    canvas.remove(active);

    canvas.setActiveObject(newPolygon);
    return newPolygon;
  }

  mutateToPolygon() {
    let { active, fabric, canvas } = this.props;

    let newPolygon;
    if (active.type === 'polygonTexture') {
      if (active.circle) {
        newPolygon = new fabric.Circle({
          ...active,
          type: 'circle',
          radius: active.circle
        });
      } else if (active.rect) {
        newPolygon = new fabric.Rect({
          ...active,
          type: 'rect',
          points: []
        });
      } else {
        newPolygon = new fabric.Polygon(active.points, {
          ...active,
          type: 'polygon'
        });
      }
    }

    const index = canvas.getObjects().indexOf(active);
    canvas.insertAt(newPolygon, index);
    canvas.remove(active);
    canvas.setActiveObject(newPolygon);

    newPolygon.dirty = true;
    canvas.requestRenderAll();

    return newPolygon;
  }

  setTexture = (id, image) => {
    if (!image) {
      this.mutateToPolygon();
    } else {
      let active = this.mutateToPolygonTexture();

      if (active.type === 'polygonTexture') {
        let media = {};

        media.texture = image;

        if (!media.scaleX && media.scaleX !== 0) {
          media.scaleX = 1.0;
        }

        if (!media.scaleY && media.scaleY !== 0) {
          media.scaleY = 1.0;
        }

        active.setMedia(media);
        active.dirty = true;
        active.canvas.requestRenderAll();
      }
    }

    this.setState({
      texture_select: false
    });
  }

  setTextureBlendMode = (e) => {
    const { active, activeGroup } = this.props;
    activeGroup.forEach((obj) => {
      if (obj.type === 'polygonTexture') {
        obj.media.blendMode = e.target.value;
        obj.dirty = true;
      }
    });

    active.canvas.requestRenderAll();
    this.setState({
      textureBlendMode: e.target.value
    });
  }

  scaleTexture = (e) => {
    const { active, activeGroup } = this.props;
    activeGroup.forEach((obj) => {
      const minScale = obj.media && obj.media.element ? obj.media.element.minScale : 1;
      const scale = ((e / 50) + 1) * minScale;

      if (obj.type === 'polygonTexture') {
        const { media } = obj;
        media.scaleX = scale;
        media.scaleY = scale;
        obj.dirty = true;
      }

      this.setState({
        textureScale: scale
      });
    });
    active.canvas.requestRenderAll();
  }

  render() {
    const { classes } = this.props;
    const back =() => {
      this.props.canvas.discardActiveObject();
      this.setState({
        texture_select: false
      });
      this.props.canvas.requestRenderAll();
    }
    if (this.state.texture_select) {
      return this.renderTextureSelect();
    } else {
      return (
        <>
          <Crumbs title="Editing Element" onBack={back} />
          <div className={classes.root}>
            {this.renderPaletteEditor()}
            {this.renderShadowEditor()}
            {this.renderTextureEditor()}
          </div>
        </>
      );
    }
  }

  renderTextureSelect = () => {
    return (
      <div style={{ padding: 8 }}>
        <Button
          variant="raised" color="secondary"
          onClick={() => this.setTexture(null, null)}
        >
          Remove
        </Button>
        <TextureSelect onSelect={this.setTexture}/>
      </div>
    );
  }

  setIndex = (prop,index) => {
    let stateObj = this.state;
    stateObj[prop] = index;
    this.setState(stateObj);
  }

  setAngle = (angle) => {
    const { canvas, activeGroup } = this.props;

    activeGroup.forEach((object) => {
      object.rotate(angle);
      object.setCoords();
    });

    canvas.requestRenderAll();
    this.forceUpdate();
  }

  setAngleLeft = () => {
    const { canvas, activeGroup } = this.props;

    activeGroup.forEach((object) => {
      object.rotate((object.angle + (360.0 - 45.0)) % 360.0);
      object.setCoords();
    });

    canvas.requestRenderAll();
    this.forceUpdate();
  }

  setAngleRight = () => {
    const { canvas, activeGroup } = this.props;

    activeGroup.forEach((object) => {
      object.rotate((object.angle + 45.0) % 360.0);
      object.setCoords();
    });

    canvas.requestRenderAll();
    this.forceUpdate();
  }

  colorAnimation = (prop) => {
    const { canvas, CanvasUtil } = this.props;
    let obj = this.state;

    const expand_change_handler = (index) => {
      let m = (index === obj[prop] ? -100 : index);
      this.setIndex(prop, m);
    };

    return (
      <ColorAnimation
        prop={prop}
        canvas={canvas}
        canvasUtil={CanvasUtil}
        expanded={obj[prop]}
        onExpandChange={expand_change_handler}
        onChange={() => this.forceUpdate()}
      />
    );
  }

  renderPaletteEditor = () => {
    const { active, canvas, classes, theme } = this.props;
    const primary_color = theme.palette.primary.light;
    let component = null;

    let properties = PROPERTY_TABLE.filter((property) => (
      (property.type === 'color') &&
      property.filters.some((type) => type === active.type)
    ));

    const key_frames = (
      (active && active.animation && active.animation.animations) || []
    );

    if (properties.length > 0) {
      component = (
        <>
          {component}
          <Grid
            container
            className={classes.grid_container}
            spacing={key_frames.length > 1 ? '0' : '1'}
          >
            {
              properties.map((property, index) => (
                <Grid
                  item key={index}
                  className={classes.grid_item}
                  xs={key_frames.length > 1 ? '12' : '4'}
                >
                  <Typography
                    variant="subtitle1"
                    className={classes.color_text}
                  >
                    {property.name}
                  </Typography>
                  {
                    (key_frames.length > 1) ? (
                      this.colorAnimation(property.key)
                    ) : (
                    <div className={classes.stroke}>
                      <ColorButton
                        color={property.get(active) || 'rgba(0, 0, 0, 0)'}
                        onClick={(e) => {
                          const x = 218.0; // (218.0 * ratio);
                          const y = e.clientY; // (e.clientY * ratio);

                          this.setState({
                            picker_open: true,
                            active_property: property.key,
                            anchor_position: { left: x, top: y },
                          });
                        }}
                      />
                      <ColorPicker
                        open={
                          Boolean(this.state.picker_open) &&
                          (this.state.active_property === property.key)
                        }
                        onClose={() => {
                          this.setState({ picker_open: false });
                        }}
                        canvas={this.props.canvas}
                        anchorReference="anchorPosition"
                        anchorPosition={this.state.anchor_position}
                        color={property.get(active) || 'rgba(0, 0, 0, 0)'}
                        onChangeComplete={(color) => {
                          property.set(active, color.rgba);
                          this.setPngColor(active, color.hex);
                          canvas.requestRenderAll();
                          this.forceUpdate();
                        }}
                        onChange={(color) => {
                          this.setPngColor(active, color.hex);
                          canvas.requestRenderAll();
                          this.forceUpdate();
                        }}
                      />
                    </div>
                  )}
                </Grid>
              ))
            }
          </Grid>
        </>
      );
    }

    properties = PROPERTY_TABLE.filter((property) => (
      (property.type === 'stroke') &&
      property.filters.some((type) => type === active.type)
    ));

    if (properties.length > 0) {
      component = (
        <>
          {component}
          {
            properties.map((property, index) => (
              <React.Fragment key={index}>
                <Typography variant="caption">
                  {property.name}
                </Typography>
                <SliderWithInput
                  className={classes.stroke_slider}
                  trackStyle={[{ backgroundColor: primary_color }]}
                  handleStyle={[{ borderColor: primary_color }]}
                  min={0} max={10} step={0.1}
                  value={property.get(active)}
                  onChange={(value) => {
                    property.set(active, value);
                    active.setCoords();
                    canvas.requestRenderAll();
                    this.forceUpdate();
                  }}
                />
              </React.Fragment>
            ))
          }
        </>
      );
    }

    properties = PROPERTY_TABLE.filter((property) => (
      (property.type === 'opacity') &&
      property.filters.some((type) => type === active.type)
    ));

    if (properties.length > 0) {
      component = (
        <>
          {component}
          {
            properties.map((property, index) => (
              <React.Fragment key={index}>
                <Typography variant="caption">
                  {property.name}
                </Typography>
                <SliderWithInput
                  className={classes.stroke_slider}
                  trackStyle={[{ backgroundColor: primary_color }]}
                  handleStyle={[{ borderColor: primary_color }]}
                  min={0} max={100} step={1}
                  value={property.get(active)}
                  onChange={(value) => {
                    property.set(active, value);
                    canvas.requestRenderAll();
                    this.forceUpdate();
                  }}
                />
              </React.Fragment>
            ))
          }
        </>
      );
    }

    properties = PROPERTY_TABLE.filter((property) => (
      (property.type === 'font') &&
      property.filters.some((type) => type === active.type)
    ));

    if (properties.length > 0) {
      const { fonts } = this.props;
      const gather = GatherUsedFonts(canvas.getObjects());

      const used_fonts = Object.keys(gather).sort(
        (lhs, rhs) => (gather[lhs] - gather[rhs])
      );

      const unused_fonts = [
        ...new Set(
          fonts
            .map((font) => font.fontFamily)
            .filter((font) => (
              font !== '' &&
              (used_fonts.indexOf(font) < 0)
            ))
        ),
        ...new Set(
          ...websafefonts
            .map((font) => font.fontFamily)
            .filter((font) => (
              font !== '' &&
              (used_fonts.indexOf(font) < 0)
            ))
        )
      ].sort();

      component = (
        <>
          {component}
          {
            properties.map((property, index) => (
              <React.Fragment key={index}>
                <Typography
                  variant="caption"
                  className={classes.font_caption}
                >
                  {property.name}
                </Typography>
                <FontPicker
                  previews
                  fonts={unused_fonts}
                  usedfonts={used_fonts}
                  activeColor={primary_color}
                  value={property.get(active)}
                  onChange={(value) => {
                    property.set(active, value);
                    canvas.requestRenderAll();
                    this.forceUpdate();
                  }}
                />
              </React.Fragment>
            ))
          }
        </>
      );
    }

    return component;
  }

  renderShadowEditor = () => {
    const { active, activeGroup, classes } = this.props;

    return (
      <div className={classes.shadow_editor}>
        <Divider/>

        <Typography
          variant="caption"
          style={{ display: 'block', marginTop: 8 }}
        >
          Rotate (Degrees)
        </Typography>
        <TextField
          type="number"
          margin="normal"
          inputProps={{ min: 0 }}
          value={Math.round(active.angle)}
          onChange={(e) => this.setAngle(+e.target.value)}
          onBlur={(e) => this.setAngle(+e.target.value)}
          style={{ marginTop: 0, paddingTop: 0, width: "50%" }}
        />
        <IconButton
          color={'primary'}
          onClick={() => this.setAngleLeft()}
        >
          <RotateLeft/>
        </IconButton>
        <IconButton
          color={'primary'}
          onClick={() => this.setAngleRight()}
        >
          <RotateRight/>
        </IconButton>
        <Divider/>

        <Typography variant="h6">
          Drop Shadow
        </Typography>
        <ShadowEditor
          active={active}
          activeGroup={activeGroup}
        />
      </div>
    );
  }

  renderTextureEditor = () => {
    let { active, classes } = this.props;

    if (!this.props.advanced) {
      return null;
    }

    let children = [];

    if (active.type === 'polygonTexture') {
      const { media } = active;

      if (media.texture) {
        children.push(
          <img
            className={classes.img}
            alt="Current Texture"
            crossOrigin="anonymous"
            onClick={() => this.setState({ texture_select: true })}
            src={`${media.texture}`}
          />
        );

        const minScale = active.media && active.media.element ? active.media.element.minScale : 1;
        children.push(
          <SliderWithInput
            value={(media.scaleX / minScale - 1) * 50}
            onChange={this.scaleTexture}
            trackStyle={[{
              backgroundColor: this.props.theme.palette.primary.light,
            }]}
            handleStyle={[{
              borderColor: this.props.theme.palette.primary.light,
            }]}
          />
        );

        children.push(
          <div style={{display: 'block', margin: 8}}>
            <Select
              value={active.media.blendMode}
              onChange={this.setTextureBlendMode}
              inputProps={{
                name: 'blend-mode',
                id: 'blend-mode'
              }}
            >
              <MenuItem value='multiply'>Blend</MenuItem>
              <MenuItem value='source-out'>Fade In</MenuItem>
              <MenuItem value='source-in'>Fade Out</MenuItem>
            </Select>
          </div>
        );
      }

      children.push(
        <React.Fragment>
          <Button className={classes.button} variant="raised" onClick={() => this.setState({ texture_select: true })}>
            Set Texture
          </Button>
        </React.Fragment>
      );

      if (media.texture) {
        children.push(
          <React.Fragment>
            <Button className={classes.button} variant="raised" color="secondary" onClick={() => this.setTexture(null, null) }>
              Remove Texture
            </Button>
          </React.Fragment>
        );
      }
    } else {
      children.push(
        <React.Fragment>
          <Button className={classes.button} variant="raised" onClick={() => this.setState({ texture_select: true })}>
            Set Texture
          </Button>
        </React.Fragment>
      );
    }

    if (children.length === 0) {
      return null;
    }

    return (
      <div style={{ padding: 8 }}>
        <Typography gutterBottom variant="h6">
          Texture
        </Typography>
        {children}
      </div>
    );
  }

}

// -------------------------------------------------------------------------- //

export const ShapeEditor = withStyles(
  STYLES, { withTheme: true }
)(Component);

// -------------------------------------------------------------------------- //
