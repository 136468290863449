
// -------------------------------------------------------------------------- //

/*

  Version 1:
  dataBinding: [
    { sport: string, variable: string },
    [...]
  ]{

  Version 2:
  dataBinding: {
    version: number,
    [point.key]: {
      format: string,
      operation: string,
      values: [
        number | string | { variable: string, player: number },
        [...]
      ],
    },
    [...]
  }

  Version 5+:
  xml: {
    version: number,
    [sport_version]: number, // version 6+ only
    [point.key]: {
      format: string | null,
      terms: [
        {
          type: 'number' | 'string' | 'stat' | 'operator',
          value: number | string,
          player?: number,
        },
      ],
    }
  },

*/

// -------------------------------------------------------------------------- //

import * as MetaData from './metadata';
import * as Sports from '../../components/sport/Sports';

// -------------------------------------------------------------------------- //

export const TYPE_MAP = {
  'i-text': {
    type: 'i-text',
    points: [
      {
        key: 'text',
        name: 'Text',
        type: 'string',
      },
    ],
    binder: (object, points) => {
      const original_text = (
        MetaData.getMetaData(object, 'original_text', '')
      );

      object.text = (points.text || original_text);

      const text_transform = (
        MetaData.getMetaData(object, 'text_transform')
      );

      if (text_transform !== null) {
        switch (text_transform) {
          case 'upper': {
            object.text = object.text.toUpperCase();
            break;
          }
          case 'lower': {
            object.text = object.text.toLowerCase();
            break;
          }
          default: {
            break;
          }
        }
      }
    },
  },
  'circle': {
    type: 'circle',
    points: [
      {
        key: 'value',
        name: 'Circle Value',
        type: 'number',
      },
      {
        key: 'maximum',
        name: 'Circle Limit',
        type: 'number',
      },
    ],
    binder: (object, points) => {
      const value = (points.value || 0);
      const maximum = (points.maximum || 0);
      let mu = 1.0;

      if (maximum > 0 && points.value !== null) {
        mu = (value / maximum);
      }

      object.set({
        startAngle: 0,
        endAngle: (Math.PI * 2 * mu),
      });
    },
  },
  'barGraph': {
    type: 'barGraph',
    points: [
      {
        key: 'value',
        name: 'Bar Value',
        type: 'number',
      },
      {
        key: 'maximum',
        name: 'Bar Limit',
        type: 'number',
      },
    ],
    binder: (object, points) => {
      const value = (points.value || 0);
      const maximum = (points.maximum || 0);
      let mu = 1.0;

      if (maximum > 0 && points.value !== null) {
        mu = Math.min((value / maximum), 1.0);
      }

      switch (object.barGraphLayout) {
        case 'horizontal': {
          object.width = (object.originalWidth * mu);
          break;
        }
        case 'vertical': {
          object.height = (object.originalHeight * mu);
          break;
        }
        default: {
          break;
        }
      }

      object.setCoords();
    },
  },
  'SliderBar': {
    type: 'SliderBar',
    points: [
      {
        key: 'value',
        name: 'Slider Value',
        type: 'number',
      },
      {
        key: 'maximum',
        name: 'Slider Limit',
        type: 'number',
      },
    ],
    binder: (object, points) => {
      object.sliderBarValue = points.value;
      object.sliderBarValueMax = points.maximum;
    },
  },
  'QuickCreate': {
    type: 'QuickCreate',
    points: [
      {
        key: 'value',
        name: 'Quick Create Value',
        type: 'number',
      },
      {
        key: 'maximum',
        name: 'Quick Create Limit',
        type: 'number',
      },
    ],
    binder: (object, points) => {
      object.quickCreateValue = points.value;
      object.quickCreateValueMax = points.maximum;
    },
  },
  'logoBox': {
    type: 'logoBox',
    points: [
      {
        key: 'image',
        name: 'Image',
        type: 'image',
      },
    ],
    binder: (object, points) => {
      object.setMedia({ src: points.image }, false, true);
    }
  },
  'cutoutBox': {
    type: 'cutoutBox',
    points: [
      {
        key: 'image',
        name: 'Image',
        type: 'image',
      },
    ],
    binder: (object, points) => {
      object.setMedia({ src: points.image }, false, true);
    }
  },
  'backgroundBox': {
    type: 'backgroundBox',
    points: [
      {
        key: 'image',
        name: 'Image',
        type: 'image',
      },
    ],
    binder: (object, points) => {
      object.setMedia({ src: points.image }, false, true);
    }
  },
};

// -------------------------------------------------------------------------- //

export const OPERATOR_MAP = {
  sum: {
    name: 'Add',
    evaluator: (stack) => {
      let result = 0;

      while (stack.length > 0) {
        result += Number(stack.pop());
      }

      stack.push(result);
    },
  },
  difference: {
    name: 'Subtract',
    evaluator: (stack) => {
      let result = 0;

      if (stack.length > 0) {
        stack.reverse();
        result = Number(stack.pop());

        while (stack.length > 0) {
          result -= Number(stack.pop());
        }
      }

      stack.push(result);
    },
  },
  product: {
    name: 'Multiply',
    evaluator: (stack) => {
      let result = 0;

      if (stack.length > 0) {
        result = 1;

        while (stack.length > 0) {
          result *= Number(stack.pop());
        }
      }

      stack.push(result);
    },
  },
  quotient: {
    name: 'Divide',
    evaluator: (stack) => {
      let result = 0;

      if (stack.length > 0) {
        stack.reverse();
        result = Number(stack.pop());

        while (stack.length > 0) {
          result /= Number(stack.pop());
        }
      }

      stack.push(result);
    }
  },
  minimum: {
    name: 'Lowest',
    evaluator: (stack) => {
      let result = 0;

      if (stack.length > 0) {
        result = Number(stack.pop());

        while (stack.length > 0) {
          result = Math.min(result, Number(stack.pop()));
        }
      }

      stack.push(result);
    },
  },
  maximum: {
    name: 'Highest',
    evaluator: (stack) => {
      let result = 0;

      if (stack.length > 0) {
        result = Number(stack.pop());

        while (stack.length > 0) {
          result = Math.max(result, Number(stack.pop()));
        }
      }

      stack.push(result);
    }
  },
  average: {
    name: 'Average',
    evaluator: (stack) => {
      let result = 0;

      if (stack.length > 0) {
        const divider = stack.length;

        while (stack.length > 0) {
          result += Number(stack.pop());
        }

        result /= divider;
      }

      stack.push(result);
    },
  },
};

// -------------------------------------------------------------------------- //

const META_DATA_KEY = 'xml';

// -------------------------------------------------------------------------- //
/**
 * @constant
 * Represents the latest version of the XML API.
 * If {@link GetBindingsVersion} returns a number less than this,
 * you should update it with {@link UpdateBindings} before accessing.
*/
// -------------------------------------------------------------------------- //

export const LATEST_VERSION = 6;

// -------------------------------------------------------------------------- //
/**
  * Calculates the version of the XML data on the canvas object.
  * @param {any} object - target canvas object
  * @returns {number}
  * XML version (1 - {@link LATEST_VERSION}), or
  * null if no XML data is stored on the object.
*/
// -------------------------------------------------------------------------- //

export function GetBindingsVersion(object) {
  if (!object) {
    return null;
  }

  if (MetaData.hasMetaData(object, META_DATA_KEY)) {
    return MetaData.getMetaData(object, META_DATA_KEY).version;
  }

  if (object.dataBinding) {
    if (Array.isArray(object.dataBinding)) {
      return 1;
    }

    return object.dataBinding.version;
  }

  return null;
}

// -------------------------------------------------------------------------- //
/**
 * Updates any out-of-date XML data associated with the given canvas object.
 * @param {any} object - target canvas object
 * @returns {boolean}
 * If the data was updated successfully, true. If an error occured while
 * updating, false is returned. If XML data was found but was already the
 * latest version, true is returned. If the object was null, or no binding
 * points are associated with the object's type, false is returned.
 */
// -------------------------------------------------------------------------- //

export function UpdateBindings(object) {
  const points = GetBindingPoints(object);

  if (points.length === 0) {
    return false;
  }

  const version = GetBindingsVersion(object);

  if (version === null || version === LATEST_VERSION) {
    return true;
  }

  let new_bindings = { version: LATEST_VERSION };
  let old_bindings = null;

  switch (version) {
    case 1: {
      old_bindings = object.dataBinding;
      const count = Math.min(old_bindings.length, points.length);

      for (let i = 0; i < count; ++i) {
        new_bindings[points[i].key] = {
          format: '',
          terms: [{
            type: 'stat', value: old_bindings[i].variable,
          }],
        };
      }

      break;
    }
    case 2:
    case 3:
    case 4: {
      old_bindings = object.dataBinding;

      for (let i = 0; i < points.length; ++i) {
        const old_binding = old_bindings[points[i].key];

        if (!old_binding) {
          continue;
        }

        let player = null;

        if ('player' in old_binding) {
          player = Number(old_binding.player);
        }

        new_bindings[points[i].key] = {
          format: (old_binding.format || null),
          terms: old_binding.values.map((value) => {
            switch (typeof value) {
              case 'number': {
                return {
                  type: 'number',
                  value: Number(value),
                };
              }
              case 'string': {
                return {
                  type: 'string',
                  value: String(value),
                };
              }
              default: {
                let result = {
                  type: 'stat',
                  value: String(value.variable),
                };

                if (player !== null && /^player\./i.test(result.value)) {
                  result.player = player;
                }

                return result;
              }
            }
          }),
        };

        if (old_binding.values.length > 1) {
          new_bindings[points[i].key].terms.push({
            type: 'operator',
            value: (old_binding.operation || 'sum'),
          });
        }
      }

      break;
    }
    default: {
      break;
    }
  }

  if (version < 6) {
    new_bindings.sport_version = version;
  } else {
    new_bindings.sport_version = old_bindings.sport_version;
  }

  MetaData.setMetaData(object, META_DATA_KEY, new_bindings);

  if ('dataBinding' in object) {
    delete object.dataBinding;
  }

  return true;
}

// -------------------------------------------------------------------------- //
/**
 * Gets the array of available binding points for a given canvas
 * object. The returned array is considered immutable.
 * @param {any} object - target canvas object
 * @returns {any[]}
 * Array of binding-point objects, or an empty array of there are no points
 * associated with the object's type (or the object was null).
 */
// -------------------------------------------------------------------------- //

export function GetBindingPoints(object) {
  if (!object) {
    return [];
  }

  const table = TYPE_MAP[object.type || ''];

  if (!table) {
    return [];
  }

  return table.points;
}

// -------------------------------------------------------------------------- //
/**
 * Gets the XML data object stored on the object. If none is found, one will
 * be created depending on whether create_flag is true. Calling this function
 * automatically updates any out-of-date XML data via {@link UpdateBindings}.
 * If no binding points are available for the target object's type, this
 * function automatically fails.
 *
 * @param {any} object - target canvas object
 * @param {boolean} create_flag - if true, data will be reserved if not found
 * @returns {any}
 * The XML data object stored on the object, or null if none was found.
 */
// -------------------------------------------------------------------------- //

export function GetBindings(object, create_flag = false) {
  if (GetBindingPoints(object).length === 0) {
    return null;
  }

  const version = GetBindingsVersion(object);

  if (version === null) {
    if (!create_flag) {
      return null;
    }

    if (!CreateBindings(object)) {
      return null;
    }
  } else if (version < LATEST_VERSION) {
    UpdateBindings(object);
  }

  return MetaData.getMetaData(object, META_DATA_KEY);
}

// -------------------------------------------------------------------------- //
/**
 * Retrieves the binding point in the XML data stored on the canvas object.
 * If none is found and create_flag is true, it will be created. Upon failure,
 * null will be returned. Any out-of-date XML data will be automatically
 * updated via {@link UpdateBindings} by calling this function.
 * @param {any} object - target canvas object
 * @param {any} point - target binding point
 * @param {boolean} create_flag - if true, data will be reserved if not found
 * @returns {any}
 * The XML binding-point data, or null if none was found.
 */
// -------------------------------------------------------------------------- //

export function GetBinding(object, point, create_flag = false) {
  if (!CheckBindingPoint(object, point)) {
    return null;
  }

  let bindings = GetBindings(object, create_flag);

  if (bindings === null) {
    return null;
  }

  if (!(point.key in bindings)) {
    if (!create_flag) {
      return null;
    }

    if (!CreateBinding(object, point)) {
      return null;
    }
  }

  return bindings[point.key];
}

// -------------------------------------------------------------------------- //

export function Format(format, ...values) {
  let output = '';
  let value = 0;

  for (let i = 0; i < format.length;) {
    if (format[i] === '%') {
      ++i;

      if (format[i] === '%') {
        output += '%';
        ++i;
        continue;
      }

      if (value >= values.length) {
        return null;
      }

      let i_pad_zero = false;
      let f_pad_zero = false;
      let i_digits = 0;
      let f_digits = 0;

      if (format[i] === '0') {
        i_pad_zero = true;
        ++i;
      }

      while ('0' <= format[i] && format[i] <= '9') {
        i_digits = Math.round(i_digits * 10 + Number(format[i]));
        ++i;
      }

      if (format[i] === '.') {
        ++i;
      }

      if (format[i] === '0') {
        f_pad_zero = true;
        ++i;
      }

      while ('0' <= format[i] && format[i] <= '9') {
        f_digits = Math.round(f_digits * 10 + Number(format[i]));
        ++i;
      }

      switch (format[i]) {
        case 's': {
          output += String(values[value]);
          ++i;
          break;
        }
        case 'd': {
          const number = Math.round(Number(values[value]));
          let i_string = String(Math.abs(number));

          if (i_digits > 0) {
            i_string = i_string.padStart(i_digits, (i_pad_zero ? '0' : ' '));
          }

          if (number < 0) {
            i_string = `-${i_string}`;
          }

          output += i_string;
          ++i;
          break;
        }
        case 'f': {
          const number = Number(values[value]);
          let string = String(Math.abs(number));

          if (string.indexOf('.') < 0) {
            string += '.0';
          }

          const splits = string.split('.', 2);

          if (i_digits > 0) {
            splits[0] = splits[0].padStart(i_digits, (i_pad_zero ? '0' : ' '));
          } else if (i_digits === 0 && splits[0] === '0') {
            splits[0] = '';
          }

          if (f_digits > 0) {
            if (f_pad_zero) {
              splits[1] = splits[1].padEnd(f_digits, '0');
            }

            while (splits[1].length > f_digits || (!f_pad_zero && splits[1].length > 1 && splits[1].endsWith('0'))) {
              splits[1] = splits[1].substring(0, (splits[1].length - 1));
            }
          }

          if (number < 0) {
            splits[0] = `-${splits[0]}`;
          }

          output += splits.join('.');
          ++i;
          break;
        }
        default: {
          return null;
        }
      }

      ++value;
    } else {
      output += format[i];
      ++i;
    }
  }

  return output;
}

// -------------------------------------------------------------------------- //
/**
 * Evaluates the value of the given XML binding point on the canvas object.
 * If an error occurs during evaluation, null is returned. The return value
 * will be casted to the expected value type of the given point (either number
 * or string).
 * @param {*} object - target canvas object
 * @param {*} point - target binding point
 * @param {Sport} sport - active canvas sport instance, or null
 * @returns {null|number|string}
 * The evaluated result type-casted to the expected alue type of the point,
 * or null if an error occurred.
 */
// -------------------------------------------------------------------------- //

export function Evaluate(object, point, sport = null) {
  if (!CheckBindingPoint(object, point)) {
    return null;
  }

  const binding = GetBinding(object, point);

  if (binding === null) {
    return null;
  }

  if (sport === null && IsBindingDynamic(binding)) {
    return null;
  }

  let stack = [];

  for (let i = 0; i < binding.terms.length; ++i) {
    switch (binding.terms[i].type) {
      case 'number':
      case 'string':
      case 'image': {
        stack.push(binding.terms[i].value);
        break;
      }
      case 'stat': {
        const variable = sport.getVariableById(binding.terms[i].value);

        if (variable === null) {
          return null;
        }

        let result;

        if (binding.terms[i].player !== null) {
          result = variable.getValue(binding.terms[i].player);
        } else {
          result = variable.getValue();
        }

        stack.push(result);
        break;
      }
      case 'operator': {
        const operator = OPERATOR_MAP[binding.terms[i].value];

        if (!operator) {
          return null;
        }

        operator.evaluator(stack);
        break;
      }
      default: {
        break;
      }
    }
  }

  switch (point.type) {
    case 'number': {
      if (stack.length !== 1 || stack[0] === null) {
        return null;
      }

      if (typeof stack[0] !== 'number' || isNaN(stack[0])) {
        return null;
      }

      return Number(stack[0]);
    }
    case 'string':
    case 'image': {
      if (stack.length === 0) {
        return null;
      }

      stack = stack.map((item) => {
        if (item === null) {
          return null;
        } else if (typeof item === 'number') {
          if (isNaN(item)) {
            return null;
          } else if (!isFinite(item)) {
            return '∞';
          }
        }

        return String(item);
      });

      if (stack.some((item) => item === null)) {
        return null;
      }

      if (binding.format) {
        return Format(binding.format, ...stack);
      } else {
        if (stack.length > 1) {
          return null;
        }

        return String(stack[0]);
      }
    }
    default: {
      return null;
    }
  }
}

// -------------------------------------------------------------------------- //
/**
 * Iterates over the available binding points for the given canvas object,
 * evaluates their values, and binds them to the object.
 * @param {any} object - target canvas object
 * @param {*} sport - active canvas sport instance, or null
 * @param {RegExp} type - bind only points of this type; null for all points
 * @returns {boolean}
 * Whether all the data was succesfully bound to the object. If the object
 * is null or its type has no binding points, false is returned.
 */
// -------------------------------------------------------------------------- //

export function Bind(object, sport = null, image = false) {
  if (IsBindingEmpty(object)) {
    return false;
  }

  const points = GetBindingPoints(object);
  let values = {};

  if (!points) {
    return false;
  }

  let dirty = false;

  points.forEach((point) => {
    if (point.type === 'image' && !image) {
      return;
    }

    values[point.key] = Evaluate(object, point, sport);
    dirty = true;
  });

  if (dirty) {
    TYPE_MAP[object.type].binder(object, values);
    object.dirty = true;
  }

  return true;
}

// -------------------------------------------------------------------------- //
/**
 * Utility function to detect the presence of non-empty XML data on a given
 * canvas object.
 * @param {any} object - target canvas object
 * @returns {boolean}
 * true if the given object has non-empty XML data stored on it;
 * otherwise, false.
 */
// -------------------------------------------------------------------------- //

export function HasBindings(object) {
  return (GetBindingPoints(object).length > 0 && !IsBindingEmpty(object));
}

// -------------------------------------------------------------------------- //
/**
 * Utility function to gather all the XML stat IDs on a collection of canvas
 * objects. This data may optionally be filtered by a specific player index
 * via player; otherwise, all data is considered.
 * @param {any[]} objects - array of canvas objects
 * @param {number} player - player index filter, or null
 * @returns {string[]}
 * Collection of all found XML stat IDs referenced in the input data. The IDs
 * may be passed to the active canvas sport instance for further processing.
 */
// -------------------------------------------------------------------------- //

export function GatherStats(objects, player = null) {
  let stats = {};

  objects
  .filter(HasBindings)
  .forEach((object) => {
    GetBindingPoints(object).forEach((point) => {
      const binding = GetBinding(object, point);

      if (!IsBindingDynamic(binding)) {
        return;
      }

      binding.terms.forEach((term) => {
        if (term.type !== 'stat') {
          return;
        }

        if (player !== null && term.schedule === player) {
          stats[term.value] = term.value;
        } else {
          if (player !== null && term.player !== player) {
            return;
          }
        }

        stats[term.value] = term.value;
      });
    });
  });
  return Object.keys(stats);
}

// -------------------------------------------------------------------------- //
/**
 * Utility function to find whether a given XML stat ID is used by any in a
 * collection of canvas objects. This search may optionally be further filtered
 * by a player-index comparison.
 * @param {any[]} objects - array of canvas objects
 * @param {string} id - target XML stat ID
 * @param {number} player - player index filter, or null
 * @returns {boolean}
 * Whether the given XML stat ID (and optional player index) was found in the
 * collection.
 */
// -------------------------------------------------------------------------- //

export function FindStat(objects, id, player = null) {
  for (let i = 0; i < objects.length; ++i) {
    const bindings = GetBindings(objects[i]);
    const points = GetBindingPoints(objects[i]);

    if (bindings === null || points.length === 0) {
      continue;
    }

    for (let j = 0; j < points.length; ++j) {
      const binding = GetBinding(objects[i], points[j]);

      if (!IsBindingDynamic(binding)) {
        continue;
      }

      for (let k = 0; k < binding.terms.length; ++k) {
        if (binding.terms[k].type !== 'stat') {
          continue;
        }

        if (player !== null && binding.terms[k].player !== player) {
          continue;
        }

        if (binding.terms[k].value === id) {
          return true;
        }
      }
    }
  }

  return false;
}

// -------------------------------------------------------------------------- //
/**
 * Utility function to call a visitor function over each binding point in a
 * given canvas object. All data is considered immutable within the function,
 * although the visitor callback may modify the binding's data. Everything is
 * validated before and during enumeration and any errors will silently return.
 * @param {*} object - target canvas object
 * @param {(e:{binding:any,point:any,index:number})=>void} f - visitor callback
 */
// -------------------------------------------------------------------------- //

export function ForEachBinding(object, f) {
  if (!object || !f) {
    return;
  }

  let bindings = GetBindings(object);
  const points = GetBindingPoints(object);

  if (bindings === null || points.length === 0) {
    return;
  }

  points
  .filter(({ key }) => key in bindings)
  .forEach((point, index) => {
    f({
      binding: bindings[point.key],
      point,
      index,
    });
  });
}

// -------------------------------------------------------------------------- //
/**
 * @deprecated Trying to remove this function.
 *
 * Utility function to generate a brief textual representation of the XML data
 * in the given canvas object's binding point.
 * @param {any} object - target canvas object
 * @param {any} point - target binding point
 * @param {any} sport - active canvas sport instance, or null
 * @returns {string}
 * Textual description of the given binding point's data.
 */
// -------------------------------------------------------------------------- //

export function ToString(object, point, sport = null) {
  const binding = GetBinding(object, point);

  if (!binding) {
    return '';
  }

  return binding.terms.map((term) => {
    switch (term.type) {
      case 'stat': {
        if (sport === null) {
          return term.value;
        }

        return Sports.getVarNameFromId(sport, term.value);
      }
      case 'number':
      case 'string': {
        return String(term.value);
      }
      case 'operator': {
        return OPERATOR_MAP[term.value].name;
      }
      default: {
        return '(null)';
      }
    }
  }).join(', ');
}

// -------------------------------------------------------------------------- //
/**
 * @deprecated
 * This is a legacy API intended to be phased out with further UI updates.
 *
 * Utility function to get the aggregate player index of the XML data on the
 * given canvas object. The "aggregate" is calculated as the player index used
 * by the player stat appearing latest in XML data. If point is null, all
 * existing binding points are checked (in order); otherwise, only the given
 * point is considered.
 * @param {*} object - target canvas object
 * @param {*} point - target binding point, or null
 * @returns {number}
 * The player index associated with the XML data, or null if none was found.
 */
// -------------------------------------------------------------------------- //

export function GetPlayerIndex(object, point = null) {
  let player = null;

  if (point !== null) {
    if (!CheckBindingPoint(object, point)) {
      return null;
    }

    let binding = GetBinding(object, point);

    if (!binding) {
      return null;
    }

    binding.terms.forEach((term) => {
      if (term.type === 'stat' && 'player' in term) {
        player = term.player;
      }
    });
    binding.terms.forEach((term) => {
      if (term.type === 'stat' && 'schedule' in term) {
        player = term.schedule;
      }
    })
  } else {
    ForEachBinding(object, ({ binding }) => {
      binding.terms.forEach((term) => {
        if (term.type === 'stat' && 'player' in term) {
          player = term.player;
        }
      })
      binding.terms.forEach((term) => {
        if (term.type === 'stat' && 'schedule' in term) {
          player = term.schedule;
        }
      })
    });
  }

  return player;
}

// -------------------------------------------------------------------------- //
/**
 * @deprecated
 * This is a legacy API intended to be phased out with further UI updates.
 *
 * Utility function to change all player stats in the given XML data to a
 * certain index. If point is null, all existing binding points are checked;
 * otherwise, only the given point is affected.
 * @param {any} object - target canvas object
 * @param {number} player - player index
 * @param {*} point - target binding point, or null
 * @returns {boolean}
 * Whether the player index was successfully set.
 */
// -------------------------------------------------------------------------- //

export function SetPlayerIndex(object, player, point = null) {
  if (point !== null) {
    if (!CheckBindingPoint(object, point)) {
      return false;
    }

    let binding = GetBinding(object, point);

    if (binding === null) {
      return false;
    }

    binding.terms.forEach((term) => {
      if (term.type === 'stat' && 'player' in term) {
        term.player = player;
      }
    });
    binding.terms.forEach((term) => {
      if (term.type === 'stat' && term.value.includes('schedule')) {
        term.schedule = player;
      }
    });

    return true;
  } else {
    ForEachBinding(object, ({ binding }) => {
      binding.terms.forEach((term) => {
        if (term.type === 'stat' && 'player' in term) {
          term.player = player;
        }
      });
      binding.terms.forEach((term) => {
        if (term.type === 'stat' && term.value.includes('schedule')) {
          term.schedule = player;
        }
      });

    });

    return true;
  }
}

// -------------------------------------------------------------------------- //
/**
 * Utility function to calculate the maximum number of periods referenced in
 * a collection of canvas objects.
 * @param {any[]} objects - array of canvas objects
 * @param {any} sport - active canvas sport instance
 * @returns {number}
 * The number of periods, or zero if non were found. If sport is null, or
 * none of the XML data references period stats, zero is returned.
 */
// -------------------------------------------------------------------------- //

export function CountPeriods(objects, sport) {
  if (sport === null) {
    return 0;
  }

  let periods = 0;

  GatherStats(objects).forEach((id) => {
    const variable = sport.getVariableById(id);

    if (variable && variable.period !== null) {
      periods = Math.max(periods, (variable.period + 1));
    }
  });

  return periods;
}

// -------------------------------------------------------------------------- //
/**
 * Utility function to check whether or not a given binding point uses stats.
 * @param {any} binding - target binding point
 * @returns {boolean}
 * If any of the terms on the binding point are stats, true; otherwise, false.
 * If the binding is null or has no terms, false is returned.
 */
// -------------------------------------------------------------------------- //

export function IsBindingDynamic(binding) {
  if (binding === null) {
    return false;
  }

  return binding.terms.some(({ type }) => (type === 'stat'));
}

// -------------------------------------------------------------------------- //

// ========================================================================== //

// -------------------------------------------------------------------------- //

function IsBindingEmpty(object) {
  const bindings = GetBindings(object);
  const points = GetBindingPoints(object);

  if (bindings === null || points.length === 0) {
    return true;
  }

  return (
    points
    .map((point) => GetBinding(object, point))
    .filter((binding) => binding !== null)
    .every((binding) => binding.terms.length === 0)
  );
}

// -------------------------------------------------------------------------- //

function CheckBindingPoint(object, point) {
  const points = GetBindingPoints(object);
  return (points.indexOf(point) >= 0);
}

// -------------------------------------------------------------------------- //

function CreateBindings(object) {
  const points = GetBindingPoints(object);

  if (points.length === 0) {
    return false;
  }

  if (GetBindings(object) !== null) {
    return true;
  }

  if (!MetaData.hasMetaData(object, META_DATA_KEY)) {
    MetaData.setMetaData(object, META_DATA_KEY, {
      version: LATEST_VERSION,
    });
  }

  return true;
}

// -------------------------------------------------------------------------- //

function CreateBinding(object, point) {
  if (!CheckBindingPoint(object, point)) {
    return null;
  }

  let bindings = GetBindings(object);

  if (bindings === null) {
    if (!CreateBindings(object)) {
      return null;
    }
  }

  const { key } = point;

  if (!(key in bindings)) {
    bindings[key] = { format: null, terms: [] };
  }

  return bindings[key];
}

// -------------------------------------------------------------------------- //

// ========================================================================== //
