import React, { Component } from 'react';
import { Mutation, Query } from 'react-apollo';
import gql from 'graphql-tag';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import S3 from 'aws-sdk/clients/s3';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Logo from '../components/svg/Logo';

const TRANSFER_FILE = gql`
  mutation transferFile(
    $key: String!
    $size: Int!
    $folderId: ID
  ) {
    moveFileFromLegacy(
      key: $key
      size: $size
      folderId: $folderId
    ) {
      id
      extension
      client {
        id
      }
    }
  }
`;

const ADD_LABELS = gql`
  mutation (
    $bucket: String!
    $key: String!
    $fileId: ID!
  ) {
    addLabels(
      bucket: $bucket
      key: $key
      fileId: $fileId
    ) {
      name
      confidence
    }
  }
`;

const ADD_FOLDER = gql`
  mutation ($name: String!, $folderId: ID, $type: MEDIA_TYPE!){
    createFolder(
      name: $name,
      folderId: $folderId,
      type: $type
    ) {
      id
      name
    }
  }
`;

const GET_USER = gql`
  query determineIfLegacy {
    me {
      id
      email
      onboardingFlags
    }
  }
`;

const MARK_COMPLETE = gql`
  mutation {
    addUserFlag (
      flag: IMPORTED_MEDIA
    )
  }
`;

const myTheme = createMuiTheme({
  palette: {
    primary: { main: '#6B2587' }, // Purple and green play nicely together.
    secondary: { main: '#4FD4C4' }, // This is just green.A700 as hex.
  },
  typography: {
    fontFamily: '"Ubuntu", "Helvetica", "Arial", sans-serif',
  },
});

const styles = theme => ({
  root: {
    maxWidth: 500,
    margin: 'auto',
    marginTop: theme.spacing(3),
  },
  heading: {
    backgroundColor: '#6b2587',
    textAlign: 'center',
  },
  logo: {
    height: 160,
    fill: theme.palette.primary.contrastText,
    padding: 24,
    paddingLeft: theme.spacing(2),
  },
  main: {
    padding: theme.spacing(3),
    minHeight: 400,
  },
});

class OptIn extends Component {
  state = {
    loading: false,
    user: null,
    error: null,
    transferStep: 'finding',
    foundFiles: 0,
    transferredFiles: 0,
  }

  getUser = () => {
    let text = '?';
    if (this.state.user.firstname && this.state.user.lastname) {
      text = `${this.state.user.firstname.substring(0, 1)}${this.state.user.lastname.substring(0, 1)}`;
    }
    const style = {
      backgroundColor: '#6B2587',
      color: '#4FD4C4',
    };
    if (this.state.user.color_1) {
      style.backgroundColor = `#${this.state.user.color_1}`;
    }
    if (this.state.user.color_2) {
      style.color = `#${this.state.user.color_2}`;
    }
    return (
      <Card>
        <CardHeader
          avatar={
            <Avatar style={style}>
              {text}
            </Avatar>
          }
          title={`${this.state.user.firstname} ${this.state.user.lastname}`}
          subheader={this.state.user.client}
        />
      </Card>
    );
  }

  getNag = (me) => {
    if (!this.state.user && !this.state.error && me.email) {
      if (me.onboardingFlags.indexOf('IMPORTED_MEDIA') !== -1) {
        return (
          <React.Fragment>
            <Typography variant="h5" gutterBottom>
              Transfer your Media Library to ScoreShots Next
            </Typography>
            <Typography gutterBottom>
              {
              `
                This tool allows you to perform a one-time transfer of your ScoreShots Legacy Media Manager to the ScoreShots Next Media Library.
              `
              }
            </Typography>
            <Typography gutterBottom>
              {`
              This imports your files and folders into your client account. Be mindful that the media manager is now shared with all other users on your client.
            `}
            </Typography>
            <Typography color="error">
              You have already imported your media library. Please contact support for help with any issues.
            </Typography>
          </React.Fragment>
        );
      }
      if (!this.state.loading) {
        this.verify(me.email);
      }
      return (
        <React.Fragment>
          <Typography variant="h5" gutterBottom>
            Transfer your Media Library to ScoreShots Next
          </Typography>
          <Typography gutterBottom>
            {
            `
              This tool allows you to perform a one-time transfer of your ScoreShots Legacy Media Manager to the ScoreShots Next Media Library.
            `
            }
          </Typography>
          <Typography gutterBottom>
            {`
            This imports your files and folders into your client account. Be mindful that the media manager is now shared with all other users on your client.
          `}
          </Typography>
          <CircularProgress />
        </React.Fragment>
      );
    } else if (this.state.error && !this.state.loading) {
      return (
        <React.Fragment>
          <Typography variant="h5" gutterBottom>
            Transfer your Media Library to ScoreShots Next
          </Typography>
          <Typography gutterBottom>
            {
            `
              This tool allows you to perform a one-time transfer of your ScoreShots Legacy Media Manager to the ScoreShots Next Media Library.
            `
            }
          </Typography>
          <Typography gutterBottom>
            {`
            This imports your files and folders into your client account. Be mindful that the media manager is now shared with all other users on your client.
          `}
          </Typography>
          <Typography color="error">
            {this.state.error}
          </Typography>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Typography variant="h5" gutterBottom>
          Transfer your Media Library to ScoreShots Next
        </Typography>
        <Typography gutterBottom>
          {
          `
            This tool allows you to perform a one-time transfer of your ScoreShots Legacy Media Manager to the ScoreShots Next Media Library.
          `
          }
        </Typography>
        <Typography gutterBottom>
          {`
          This imports your files and folders into your client account. Be mindful that the media manager is now shared with all other users on your client.
        `}
        </Typography>
        <Typography gutterBottom>
          {`
          By clicking below, we will transfer the media from your ScoreShots Legacy Account (${me.email}) into ScoreShots Next. This may take several minutes.
        `}
        </Typography>
        <Mutation mutation={ADD_LABELS}>
          {addLabels => (
            <Mutation mutation={ADD_FOLDER}>
              {addFolder => (
                <Mutation mutation={TRANSFER_FILE}>
                  {transferFile => (
                    <Mutation mutation={MARK_COMPLETE}>
                      {markComplete => (
                        this.state.loading ?
                          this.getProgress() :
                          <Button
                            fullWidth
                            variant="raised"
                            size="large"
                            color="primary"
                            style={{ marginTop: 20 }}
                            onClick={this.transferMedia(transferFile, addFolder, addLabels, markComplete)}
                          >
                        Transfer My Media
                          </Button>
                        )}
                    </Mutation>
                  )}
                </Mutation>
              )}
            </Mutation>
          )}
        </Mutation>


      </React.Fragment>
    );
  }

  getProgress = () => {
    let text = '';
    if (this.state.transferStep === 'finding') {
      text = `Currently Finding Files. Found: ${this.state.foundFiles}`;
    } else if (this.state.transferStep === 'transferring') {
      text = `Currently Transferring Files. Transferred ${this.state.transferredFiles}/${this.state.foundFiles}`;
    } else if (this.state.transferStep === 'complete') {
      text = <a href="/">Transfer Complete! Click here to Return to Next.</a>;
    }
    return (
      <Typography>
        {text}
      </Typography>
    );
  }

  transferMedia = (addFile, addFolder, addLabels, markComplete) => () => {
    this.setState({
      loading: true,
    });
    const s3 = new S3({
      accessKeyId: localStorage.getItem('aws_id'),
      secretAccessKey: localStorage.getItem('aws_secret'),
      region: 'us-east-1',
      params: {
        Bucket: 'scoreshotsmedia',
      },
    });
    const managerState = {
      files: [],
      folders: {},
    };
    const params = {
      Prefix: `${this.state.user.id}/`,
    };
    const checkDirectory = (path) => {
      let currentMarker = managerState;
      for (let i = 1; i < path.length - 1; i += 1) {
        const directory = path[i];
        if (!currentMarker.folders[directory]) {
          // directory doesn't exist, create it.
          currentMarker.folders[directory] = {
            files: [],
            folders: {},
          };
        }
        currentMarker = currentMarker.folders[directory];
      }
      return currentMarker;
    };
    const processData = async (data) => {
      if (data.Contents) {
        let lastKey = null;
        let actualFiles = 0;
        data.Contents.forEach((file) => {
          lastKey = file.Key;
          const path = file.Key.split('/');
          if (path[path.length - 1] !== '') {
            actualFiles += 1;
            // for each directory, determine if we already know about it.
            checkDirectory(path).files.push({
              name: path[path.length - 1],
              key: file.Key,
              size: file.Size,
              url: `https://s3.amazonaws.com/scoreshotsmedia/${file.Key}`,
            });
          }
        });
        this.setState({
          foundFiles: this.state.foundFiles + actualFiles,
        });
        if (data.IsTruncated) {
          s3.listObjectsV2({ ...params, StartAfter: lastKey }, (err, newData) => {
            processData(newData);
          });
        } else {
          // we've constructed the manager state, we can now start making files and directories.
          this.setState({
            transferStep: 'transferring',
          });
          await this.transferFolder(null, managerState, addFile, addFolder, addLabels);
          markComplete()
            .then(() => {
              this.setState({
                transferStep: 'complete',
              });
            });
        }
      }
    };
    s3.listObjectsV2(params, (err, data) => {
      if (data.Contents) {
        processData(data);
      }
    });
  }

  transferFolder = async (folderId, media, addFile, addFolder, addLabels) => {
    const promises = [];
    media.files.forEach((file) => {
      // createFile(file);
      promises.push(addFile({
        variables: {
          key: file.key,
          size: file.size,
          folderId,
        },
      })
        .then((response) => {
          if (response.data.moveFileFromLegacy && response.data.moveFileFromLegacy.id) {
            const mffl = response.data.moveFileFromLegacy;
            promises.push(addLabels({
              variables: {
                bucket: 'ss3-clientmedia',
                key: `${mffl.client.id}/${mffl.id}.${mffl.extension}`,
                fileId: mffl.id,
              },
            }));
          }
          this.setState({
            transferredFiles: this.state.transferredFiles + 1,
          });
        }));
    });
    Object.keys(media.folders).forEach((folderName) => {
      const folder = media.folders[folderName];
      promises.push(addFolder({
        variables: {
          name: folderName,
          folderId,
          type: 'PHOTO',
        },
      })
        .then((response) => {
          if (response.data.createFolder.id) {
            this.transferFolder(
              response.data.createFolder.id,
              folder,
              addFile,
              addFolder,
              addLabels,
            );
          }
        }));
    });
    await Promise.all(promises);
  }

  verify = (email) => {
    this.setState({
      loading: true,
      error: null,
      user: null,
    });
    const XHR = new XMLHttpRequest();
    let urlEncodedData = '';
    const urlEncodedDataPairs = [];
    urlEncodedDataPairs.push(`${encodeURIComponent('email')}=${encodeURIComponent(email)}`);
    urlEncodedData = urlEncodedDataPairs.join('&').replace(/%20/g, '+');
    XHR.addEventListener('load', (event) => {
      const user = JSON.parse(event.target.response);
      const state = {
        loading: false,
      };
      if (!user || !user.id) {
        state.error = `No ScoreShots Legacy user with your current email address (${email}). Please contact support.`;
      } else {
        state.user = user;
      }
      this.setState(state);
    });
    XHR.addEventListener('error', () => {
      this.setState({
        loading: false,
        error: 'An error has occurred. Please contact support.',
      });
    });
    XHR.open('POST', 'https://legacy.scoreshots.com/index.php/Client/get_client');
    XHR.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    XHR.send(urlEncodedData);
  }

  render() {
    const { classes } = this.props;
    return (
      <MuiThemeProvider theme={myTheme}>
        <Paper className={classes.root}>
          <div className={classes.heading}>
            <Logo className={classes.logo} />
          </div>
          <Query query={GET_USER}>
            {({ data }) => (
              data.me ?
                <div className={classes.main}>
                  {this.getNag(data.me)}
                </div> :
                <CircularProgress />
            )}
          </Query>

        </Paper>
      </MuiThemeProvider>

    );
  }
}

export default withStyles(styles)(OptIn);
