
// -------------------------------------------------------------------------- //

export function convertValue(value, type) {
  if (value === null || value === undefined || value === '') {
    return null;
  }

  switch (type) {
    case 'number': {
      value = Number(value);

      if (isNaN(value) || !isFinite(value)) {
        return 0;
      }

      return Number(value.toFixed(3));
    }
    case 'image':
    case 'string': {
      return String(value);
    }
    default: {
      return null;
    }
  }
}

// -------------------------------------------------------------------------- //

class Variable {

  constructor(sport, id, name, options = { }) {
    this.sport = sport;
    this.name = name;
    this.id = id;
    this.type = (options.type || 'number');
    this.short_name = (options.short_name || this.name);
    this.lineup = (options.lineup || false);
    this.schedule = (options.schedule || false);
    this.sort = (options.sort || 0);
    this.values = [];
    this.period = null;

    let period = this.id.match(/\.period(\d+)$/i);

    if (period) {
      this.period = (Number(period[1]) - 1);
    } else {
      period = this.id.match(/\.inning(\d+)$/i);

      if (period) {
        this.period = (Number(period[1]) - 1);
      }
    }

    this.player = Boolean(this.id.match(/^player\./i));
  }

  getDisplayName(period_type = 'Period') {
    if (period_type === null || this.period === null) {
      return this.name;
    }

    let ordinal = '';

    if (10 <= this.period && this.period < 20) {
      ordinal = 'th';
    } else {
      switch (this.period % 10) {
        case 0: {
          ordinal = 'st';
          break;
        }
        case 1: {
          ordinal = 'nd';
          break;
        }
        case 2: {
          ordinal = 'rd';
          break;
        }
        default: {
          ordinal = 'th';
          break;
        }
      }
    }

    return `${this.name} (${this.period + 1}${ordinal} ${period_type})`;
  }

  convertValue(value) {
    if (value === null || value === undefined || value === '') {
      return null;
    }

    return convertValue(value, this.type);
  }

  getValue(index = 0) {
    if (index < 0 || index >= this.values.length) {
      return null;
    }

    return this.values[index];
  }

  setValue(value, index = 0) {
    if (index < 0) {
      return false;
    }

    while (this.values.length < index) {
      this.values.push(null);
    }

    this.values[index] = this.convertValue(value);
    return true;
  }

  getValueCount() {
    return this.values.length;
  }

  reset() {
    this.values = [];
  }

}

// -------------------------------------------------------------------------- //

export default Variable;

// -------------------------------------------------------------------------- //
