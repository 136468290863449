
// -------------------------------------------------------------------------- //

import React from 'react';

// -------------------------------------------------------------------------- //

export const VirtualTourContext = React.createContext({
  tour_open: false,
  tour_step: 0,
  start_tour: () => {},
  close_tour: () => {},
  set_step: () => {},
});

// -------------------------------------------------------------------------- //
