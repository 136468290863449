import React, { Component } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import twitter from 'twitter-text';
import StepNavigator from './StepNavigator';

const styles = theme => ({

  messageListItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  multiTextField: {
    width: 350,
    maxWidth: '100%',
    marginTop: 0,
    marginBottom: theme.spacing(2),
    marginLeft: 58,
  },
});

class MessageEditor extends Component {
  state = {
    textMessages: {
      facebook: {},
      twitter: {},
    },
  }

  getPostMessageBoxes() {
    const { classes } = this.props;
    const output = [];
    if (this.props.selectedFb) {
      this.props.selectedFb.forEach((page) => {
        output.push(
          <ListItem
            key={page.id}
            className={classes.messageListItem}
            dense
          >
            <ListItemAvatar>
              <Avatar src={`https://graph.facebook.com/v2.12/${page.fbId}/picture?height=40`} />
            </ListItemAvatar>
            <ListItemText
              primary={page.name}
            />
          </ListItem>,
          <TextField
            key={`${page.fbId}field`}
            id={`${page.fbId}field`}
            label="Your Message"
            multiline
            value={this.state.textMessages.facebook[page.fbId]}
            onChange={this.textMessageChanged('facebook', page.fbId)}
            className={classes.multiTextField}
            margin="dense"
          />,
        );
      });
    }

    if (this.props.selectedTwitter) {
      const account = this.props.selectedTwitter;
      output.push(
        <ListItem
          key={account.twitterId}
          className={classes.messageListItem}
          dense
        >
          <ListItemAvatar>
            <Avatar src={`https://twitter-avatar.now.sh/${account.screen_name}`} />
          </ListItemAvatar>
          <ListItemText
            primary={account.name}
            secondary={`@${account.screen_name}`}
          />
        </ListItem>,
        <TextField
          error={!this.checkTwitterError(this.state.textMessages.twitter[account.twitterId]).valid}
          id={`${account.twitterId}field`}
          label={this.checkTwitterError(this.state.textMessages.twitter[account.twitterId]).message}
          key={`${account.twitterId}field`}
          multiline
          value={this.state.textMessages.twitter[account.twitterId]}
          onChange={this.textMessageChanged('twitter', account.twitterId)}
          className={classes.multiTextField}
          margin="dense"
        />,
      );
    }

    return (
      <List>
        {output}
      </List>
    );
  }
  textMessageChanged = (type, entityId) => (e) => {
    this.props.textMessageChange(type, entityId, e.target.value);
    const newState = {
      textMessages: this.state.textMessages,
    };
    newState.textMessages[type][entityId] = e.target.value;
    this.setState(newState);
  }
  checkTwitterError = (value = '', checkPresent = false) => {
    if (
      checkPresent &&
      (!this.props.selectedTwitter)
    ) {
      // no twitter accounts, all clear.
      return {
        valid: true,
        message: 'Your Message',
      };
    }
    const response = twitter.parseTweet(value);
    if (!response.valid && value.length !== 0) {
      response.message = `Too Long For Twitter (${response.weightedLength}/280)`;
    } else {
      response.valid = true;
      response.message = `Your Message (${response.weightedLength}/280)`;
    }
    return response;
  }
  render() {
    return (
      <React.Fragment>
        <FormControlLabel
          style={{ display: 'flex' }}
          control={
            <Checkbox
              checked={this.props.message}
              onChange={this.props.toggleState('message')}
              value="message"
              color="primary"
            />
          }
          label="Add A Message To My Post"
        />
        {this.props.message ?
          <FormControlLabel
            style={{ display: 'flex' }}
            control={
              <Checkbox
                checked={this.props.uniformMessage}
                onChange={this.props.toggleState('uniformMessage')}
                value="uniformMessage"
                color="primary"
              />
            }
            label="Use the Same Message for All Posts"
          /> :
          null
        }
        {this.props.message && this.props.uniformMessage ?
          <TextField
            error={!this.checkTwitterError(this.props.uniformTextMessage, true).valid}
            id="uniformTextMessage"
            label={this.checkTwitterError(this.props.uniformTextMessage, true).message}
            multiline
            value={this.props.uniformTextMessage}
            onChange={this.props.handleChange('uniformTextMessage')}
            style={{
              width: 350,
              maxWidth: '100%',
            }}
            margin="normal"
          />
        : null
        }
        {
          this.props.message && !this.props.uniformMessage ?
            this.getPostMessageBoxes() :
          null
        }
        <StepNavigator
          onNext={this.props.onNext}
          onPrev={this.props.onPrev}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(MessageEditor);
