import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Form, Field } from 'react-final-form';
import Button from '@material-ui/core/Button';
import TextField from '../material/TextField';

const UPDATE_PASSWORD = gql`
  mutation ($oldPass: String!, $newPass: String!) {
    updatePassword(
      oldPass: $oldPass,
      newPass: $newPass
    ) {
      id
    }
  }
`;

const validate = (val) => {
  const output = {};
  if (!val.oldPass) {
    output.oldPass = 'Old Password Required';
  }
  if (val.newPass !== val.newPass_confirm) {
    output.newPass_confirm = "Passwords Don't Match";
  }
  if (!val.newPass) {
    output.newPass = 'New Password Required';
  }
  return output;
};

class Password extends Component {
  updatePassword = updatePassword => (val) => {
    updatePassword({
      variables: val,
    }).then((response) => {
      if (response.errors) {
        this.props.sendMessage(response.errors[0].message);
      } else {
        this.props.sendMessage('Changed Successfully');
      }
    }).catch((error) => {
      this.props.sendMessage(error.toString());
    });
  }
  render() {
    const classes = {};
    const { sendMessage, ...restProps } = this.props;
    return (
      <Paper {...restProps}>
        <Typography variant="h4" component="h1" gutterBottom>
          Change Password
        </Typography>
        <Mutation mutation={UPDATE_PASSWORD}>
          {updatePassword => (
            <Form
              onSubmit={this.updatePassword(updatePassword)}
              validate={validate}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} style={{ maxWidth: 400 }}>
                  <Field
                    name="oldPass"
                    className={classes.formField}
                    component={TextField}
                    id="oldPass"
                    label="Current Password"
                    style={{ marginTop: 10 }}
                    type="password"
                  />
                  <Field
                    name="newPass"
                    className={classes.formField}
                    component={TextField}
                    id="newPass"
                    label="New Password"
                    style={{ marginTop: 10 }}
                    type="password"
                  />
                  <Field
                    name="newPass_confirm"
                    className={classes.formField}
                    component={TextField}
                    id="newPass_confirm"
                    label="Confirm New Password"
                    style={{ marginTop: 10 }}
                    type="password"
                  />


                  <Button
                    variant="raised"
                    color="primary"
                    onClick={handleSubmit}
                    style={{ marginTop: 10 }}
                  >
                    Update Password
                  </Button>
                </form>
              )}
            />
          )}
        </Mutation>


      </Paper>
    );
  }
}

export default Password;
