import React from 'react';
import Typography from '@material-ui/core/Typography';
/*
class FilterPreview extends Component {

  componentDidMount() {
    const { fabric, active, filter } = this.props;
    const url = active.getSrc();
    const canvas = new fabric.StaticCanvas(filter.name);
    this.canvas = canvas;
    if (url.substring(0, 4) === 'blob') {
      this.createImage(url);
    } else {
      this.createImage(`https://scoreshots-images.herokuapp.com/72x72/${url}`);
    }
  }
  createImage(url) {
    const { fabric, filter } = this.props;
    const canvas = this.canvas;
    new fabric.Image.fromURL(url, (image) => {
      const hScale = canvas.width / image.width;
      const wScale = canvas.height / image.height;
      const scale = Math.max(hScale, wScale);
      if (filter.filter) {
        image.filters.push(new filter.filter());
      } else if (filter.filters) {
        filter.filters.forEach((subFilter) => {
          const options = {};
          if (subFilter.options) {
            Object.keys(subFilter.options).forEach((optionName) => {
              const optionVal = subFilter.options[optionName];
              if (optionVal.id) {
                options[optionName] = filter.variables[optionVal.id].default;
              } else {
                options[optionName] = optionVal;
              }
            });
          }
          image.filters.push(new subFilter.filter(options));
        });
      }

      image.applyFilters();
      canvas.setBackgroundImage(image, canvas.requestRenderAll.bind(canvas), {
        originX: 'center',
        originY: 'center',
        scaleX: scale,
        scaleY: scale,
        left: canvas.width / 2,
        top: canvas.height / 2,
      });
    }, { crossOrigin: 'anonymous' });
  }
  componentWillUnmount() {
    this.canvas.dispose();
  }
  render() {
    console.log(this.props.filter.name);
    return (
      <div
        style={{
          cursor: 'pointer',
        }}
        className={this.props.className}
        onClick={this.props.onClick}
      >
        <canvas id={this.props.filter.name} width={72} height={72} />
        <Typography variant="caption">
          {this.props.filter.name}
        </Typography>
      </div>
    );
  }
}
*/

const FilterPreview = props => (
  <div
    style={{
      cursor: 'pointer',
    }}
    className={props.className}
    onClick={props.onClick}
  >
    <img
      alt={`Filter: ${props.filter.name}`}
      src={`/images/${props.filter.name.replace('/', '')}.jpg`}
    />
    <Typography variant="caption" style={{display: 'block', marginBottom: 7, marginTop: 0}}>
      {props.filter.name}
    </Typography>
  </div>
);

export default FilterPreview;
