import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TablePagination from '@material-ui/core/TablePagination';
import Checkbox from '@material-ui/core/Checkbox';

const GET_GOOGLE_FONTS = gql`
  query getGoogleFonts {
    googleFonts {
      id
      fontFamily
    }
  }
`;

const ADD_GOOGLE_FONT = gql`
  mutation addGoogleFont($fontFamily: String!) {
    addGoogleFont(
      fontFamily: $fontFamily
    ) {
      id
    }
  }
`;

const REMOVE_GOOGLE_FONT = gql`
  mutation removeGoogleFont($fontFamily: String!) {
    removeGoogleFont(
      fontFamily: $fontFamily
    ) {
      id
    }
  }
`;

const styles = theme => ({
  listText: {
    fontFamily: 'inherit',
  },
  listTextRoot: {
    fontSize: theme.typography.fontSize * 2,
  },
  paper: {
    marginTop: theme.spacing(2),
  },
  paperTitle: {
    padding: theme.spacing(4),
  },
});

class GoogleFonts extends Component {
  state = {
    fonts: [],
    page: 0,
    perPage: 10,
  }

  componentDidMount() {
    this.getFonts();
  }

  onChangePage = (e, page) => {
    this.setState({
      page,
    });
  }
  onChangePerPage = (e) => {
    const perPage = e.target.value;
    this.setState({
      perPage,
    });
  }

  getFonts() {
    fetch('https://www.googleapis.com/webfonts/v1/webfonts?sort=alpha&key=AIzaSyCkWrMsj_drIdNCrLRO4jeUfcdsg-fc8rw')
      .then(response => response.json())
      .then((json) => {
        this.setState({
          fonts: json.items,
        });
      });
  }

  handleToggle = (fontFamily, googleFonts, add, remove) => () => {
    if (googleFonts) {
      const contains = (
        googleFonts.map(gFont => (
          gFont.fontFamily
        )).indexOf(fontFamily) !== -1
      );
      let method;
      if (!contains) {
        method = add;
      } else {
        method = remove;
      }
      method({
        variables: {
          fontFamily,
        },
        refetchQueries: ['getGoogleFonts', 'myFonts'],
      });
    }
  }


  render() {
    const { classes } = this.props;
    const { fonts, perPage, page } = this.state;
    const start = (page * perPage);
    const end = Math.min(start + perPage, fonts.length - 1);
    const subFonts = fonts.slice(start, end);
    return (
      <Paper className={classes.paper}>
        <Typography variant="h4" className={classes.paperTitle}>
          Google Web Fonts
        </Typography>
        <List>
          <Mutation mutation={ADD_GOOGLE_FONT}>
            {addGoogleFont => (
              <Mutation mutation={REMOVE_GOOGLE_FONT}>
                {removeGoogleFont => (
                  <Query query={GET_GOOGLE_FONTS}>
                    {({ data }) => (
                      subFonts ?
                        subFonts.map(font => (
                          <ListItem
                            key={font.family}
                            role={undefined}
                            dense
                            button
                            onClick={
                              this.handleToggle(
                                font.family,
                                data ? data.googleFonts : null,
                                addGoogleFont,
                                removeGoogleFont,
                              )
                            }
                            className={classes.listItem}
                          >
                            <Checkbox
                              checked={
                                data && data.googleFonts ?
                                  data.googleFonts.map(gFont => (
                                    gFont.fontFamily
                                  )).indexOf(font.family) !== -1 :
                                false
                              }
                              tabIndex={-1}
                              disableRipple
                            />
                            <ListItemText
                              primary={font.family}
                              style={{ fontFamily: font.family }}
                              classes={{
                                primary: classes.listText,
                                root: classes.listTextRoot,
                              }}
                            />
                          </ListItem>
                        )) :
                        null
                    )}
                  </Query>
                )}
              </Mutation>
            )}
          </Mutation>

        </List>

        <TablePagination
          component="div"
          count={fonts.length}
          rowsPerPage={perPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangePerPage}
        />
      </Paper>
    );
  }
}

export default withStyles(styles)(GoogleFonts);
