
// -------------------------------------------------------------------------- //

import React from 'react';

import {Icon, SvgIcon} from '@material-ui/core';

import {
  Square as SquareIcon,
  Circle as CircleIcon,
  Triangle as TriangleIcon,
  Minus as LineIcon,
  Star as StarIcon,
  Hexagon as HexagonIcon,
  Heart as HeartIcon,
  Octagon as OctagonIcon,
} from 'mdi-material-ui';

// -------------------------------------------------------------------------- //

function CreatePolygon(sides, radius) {
  const sweep = (Math.PI * 2 / sides);
  let points = [];

  for (let i = 0; i < sides; ++i) {
    points.push({
      x: (radius + radius * Math.cos(i * sweep)),
      y: (radius + radius * Math.sin(i * sweep)),
    });
  }

  return points;
}

class Svg extends React.Component {
  state = {
    svg: null,
  }

  componentDidMount() {
    fetch(this.props.url)
        .then(res => res.text())
        .then(text => this.setState({ svg: text }));
  }

  render() {
    const { svg } = this.state;

    const style = {
      width: '50px',
      height: '50px',
    };


    return <div style={style} {...this.props} dangerouslySetInnerHTML={{ __html: String(svg).replace('cls-1','')}}/>;
  }
}
// -------------------------------------------------------------------------- //

function CreateStar(spikes, outer_radius, inner_radius) {
  const sweep = (Math.PI / spikes);
  let points = [], angle = 0;

  for (let i = 0; i < spikes; ++i) {
    points.push({
      x: (outer_radius + outer_radius * Math.cos(angle)),
      y: (outer_radius + outer_radius * Math.sin(angle)),
    });

    angle += sweep;

    points.push({
      x: (outer_radius + inner_radius * Math.cos(angle)),
      y: (outer_radius + inner_radius * Math.sin(angle)),
    });

    angle += sweep;
  }

  return points;
}

// -------------------------------------------------------------------------- //

function AddHostedSvg(url, fabric, canvas, colors, scale, name) {
  return new Promise((resolve) => {
    fabric.loadSVGFromURL(url,
      (objects, options) => {
        let object = fabric.util.groupSVGElements(objects, options);

        object.set({
          name, left: 10, top: 10,
          scaleX: scale, scaleY: scale,
          fill: colors.primary,
        });

        if (canvas.step === 'admin') {
          object.set('fillOverride', 'primary');
        }

        canvas.add(object).renderAll();
        resolve(object);
      }
    );
  });
}

// -------------------------------------------------------------------------- //

export const SHAPES = ({
  'square': {
    name: 'Square',
    icon: (props) => <SquareIcon {...props}/>,
    adder: ({ canvas, fabric, colors }) => {
      let object = new fabric.Rect({
        left: 10.0, top: 10.0,
        width: 120.0, height: 120.0,
        fill: colors.primary, opacity: 1.0,
      });

      if (canvas.step === 'admin') {
        object.set('fillOverride', 'primary');
      }

      canvas.add(object);
      canvas.requestRenderAll();
      return object;
    }
  },
  'circle': {
    name: 'Circle',
    icon: (props) => <CircleIcon {...props}/>,
    adder: ({ canvas, fabric, colors }) => {
      let object = new fabric.Circle({
        left: 10.0, top: 10.0, radius: 60.0,
        fill: colors.primary,
      });

      if (canvas.step === 'admin') {
        object.set('fillOverride', 'primary');
      }

      canvas.add(object);
      canvas.requestRenderAll();
      return object;
    }
  },
  'triangle': {
    name: 'Triangle',
    icon: (props) => <TriangleIcon {...props}/>,
    adder: ({ canvas, fabric, colors }) => {
      let object = new fabric.Triangle({
        left: 10.0, top: 10.0,
        width: 120.0, height: 120.0,
        fill: colors.primary,
      });

      if (canvas.step === 'admin') {
        object.set('fillOverride', 'primary');
      }

      canvas.add(object);
      canvas.requestRenderAll();
      return object;
    }
  },
  'line': {
    name: 'Line',
    icon: (props) => <LineIcon {...props}/>,
    adder: ({ canvas, fabric, colors }) => {
      let object = new fabric.Line([ 10.0, 10.0, 130.0, 10.0 ], {
        fill: colors.primary,
        stroke: colors.primary,
        strokeWidth: 5.0,
      });

      if (canvas.step === 'admin') {
        object.set('fillOverride', 'primary');
        object.set('strokeOverride', 'primary');
      }

      canvas.add(object);
      canvas.requestRenderAll();
      return object;
    }
  },
  'star': {
    name: 'Star',
    icon: (props) => <StarIcon {...props}/>,
    adder: ({ canvas, fabric, colors }) => {
      let points = CreateStar(5, 50.0, 20.0);

      let object = new fabric.Polygon(points, {
        left: 10.0, top: 10.0,
        strokeLineJoin: 'bevil',
        fill: colors.primary,
      }, false);

      if (canvas.step === 'admin') {
        object.set('fillOverride', 'primary');
      }

      canvas.add(object);
      canvas.requestRenderAll();
      return object;
    }
  },
  'hexagon': {
    name: 'Hexagon',
    icon: (props) => <HexagonIcon {...props}/>,
    adder: ({ canvas, fabric, colors }) => {
      let points = CreatePolygon(6, 50.0);

      let object = new fabric.Polygon(points, {
        left: 100.0, top: 10.0, angle: 90.0,
        strokeLineJoin: 'bevil',
        fill: colors.primary,
      }, false);

      canvas.add(object);
      canvas.requestRenderAll();
      return object;
    }
  },
  'octagon': {
    name: 'Octagon',
    icon: (props) => <OctagonIcon {...props}/>,
    adder: ({ canvas, fabric, colors }) => {
      let points = CreatePolygon(8, 50.0);

      let object = new fabric.Polygon(points, {
        left: 100.0, top: 10.0, angle: 22.5,
        strokeLineJoin: 'bevil',
        fill: colors.primary,
      }, false);


      canvas.add(object);
      canvas.requestRenderAll();
      return object;
    }
  },
  'rounded-rectangle': {
    name: 'Rounded Rectangle',
    icon: (props) => (
      <SvgIcon {...props} viewBox="0 0 297 297">
        <path d="M294.088,99.52c-1.865-1.865-4.395-2.913-7.032-2.913L9.955,96.62c-5.491,0-9.942,4.451-9.943,9.943L0,190.448
		c0,2.638,1.047,5.168,2.912,7.032c1.865,1.865,4.394,2.913,7.033,2.913l277.1-0.016c5.491,0,9.942-4.451,9.943-9.943L297,106.552
		C297,103.914,295.953,101.384,294.088,99.52z"/>
      </SvgIcon>
    ),
    adder: async ({ canvas, fabric, colors }) => {
      return await AddHostedSvg(
        'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/rectangle.svg',
        fabric, canvas, colors, 0.6, 'Rounded Rectangle'
      );
    }
  },
  'heart': {
    name: 'Heart',
    icon: (props) => <HeartIcon {...props}/>,
    adder: async ({ canvas, fabric, colors }) => {
      return await AddHostedSvg(
        'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/heart.svg',
        fabric, canvas, colors, 0.3, 'Heart'
      );
    }
  },
  'arrow': {
    name: 'Arrow',
    icon: (props) => (
      <SvgIcon {...props} viewBox="0 0 306 306">
        <polygon points="94.35,0 58.65,35.7 175.95,153 58.65,270.3 94.35,306 247.35,153"/>
      </SvgIcon>
    ),
    adder: async ({ canvas, fabric, colors }) => {
      return await AddHostedSvg(
        'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/arrow.svg',
        fabric, canvas, colors, 0.5, 'Arrow'
      );
    }
  },
  'trophy': {
    name: 'Trophy',
    icon: (props) => (
      <SvgIcon {...props} viewBox="0 0 481.882 481.882">
        <path d="
        M329.256,209.796c38.917-12.802,110.205-64.102,110.205-132.358c0-33.41-28.561-60.504-62.347-57.796 c0.081-2.285,0.202-4.551,0.236-6.842c0-3.388-1.28-6.626-3.689-9.035C371.252,1.279,368.014,0,364.626,0H117.285 c-3.388,0-6.626,1.279-9.035,3.765c-2.409,2.409-3.689,5.647-3.689,9.035c0.033,2.259,0.089,4.532,0.158,6.843 c-33.776-2.665-62.297,24.4-62.297,57.795c0,68.272,71.322,119.581,110.233,132.366c14.62,24.177,31.308,41.664,49.562,51.918 c-1.431,5.421-1.656,10.315-1.656,10.39c0,0.226,0.828,13.252,6.776,19.728v18.748c-17.035,4.695-28.402,12.643-32.331,22.287 h-22.634c-4.216,0-7.529,3.388-7.529,7.529v106.09h-5.497c-4.216,0-7.529,3.387-7.529,7.529v20.329 c0,4.142,3.313,7.529,7.529,7.529h203.219c4.141,0,7.529-3.387,7.529-7.529v-20.329c0-4.142-3.388-7.529-7.529-7.529h-5.496 v-106.09c0-4.142-3.388-7.529-7.529-7.529h-22.701c-3.909-9.644-15.229-17.592-32.264-22.287v-18.598 c5.873-6.4,6.776-19.652,6.776-19.878c0-0.075-0.301-4.969-1.656-10.315C297.95,251.469,314.571,233.976,329.256,209.796z M57.48,77.438c0-23.713,19.294-43.007,43.007-43.007c1.611,0,3.259,0.113,4.927,0.318c0.143,2.597,0.255,5.195,0.425,7.791 c3.646,55.075,15.929,105.474,35.264,145.899C107.019,170.932,57.48,129.943,57.48,77.438z M376.07,42.54 c0.167-2.548,0.327-5.131,0.468-7.796c1.644-0.2,3.269-0.314,4.857-0.314c23.713,0,43.007,19.294,43.007,43.007 c0,52.487-49.504,93.466-83.589,110.985C360.142,148.002,372.35,97.608,376.07,42.54z M299.417,370.548v53.375H182.465v-53.375 H299.417z M273.63,223.114l-8.735-12.265c9.988-7.118,19.982-18.882,28.901-34.029c2.595-4.411,5.051-9,7.297-13.618 c16.139-33.199,26.757-77.169,29.901-123.823c0.143-2.213,0.279-4.441,0.404-6.794l15.037,0.779 c-0.125,2.426-0.269,4.713-0.416,7.007c-3.272,48.559-14.416,94.515-31.383,129.412c-2.415,4.971-5.062,9.912-7.86,14.677 C296.789,201.416,285.329,214.783,273.63,223.114z"/>
      </SvgIcon>
    ),
    adder: async ({ canvas, fabric, colors }) => {
      return await AddHostedSvg(
        'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/trophy.svg',
        fabric, canvas, colors, 0.3, 'Trophy'
      );
    }
  },
  'map-pin': {
    name: 'Map Pin',
    icon: (props) => (
      <SvgIcon {...props} viewBox="0 0 512 512">
        <path d="M256,0c-58.407,0-105.925,47.518-105.925,105.925c0,53.316,39.597,97.55,90.925,104.858V512h30V210.784
			c51.329-7.309,90.925-51.543,90.925-104.859C361.925,47.518,314.407,0,256,0z"/>
      </SvgIcon>
    ),
    adder: async ({ canvas, fabric, colors }) => {
      return await AddHostedSvg(
        'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/mappin.svg',
        fabric, canvas, colors, 0.2, 'Map Pin'
      );
    }
  },
  'checkmark': {
    name: 'Checkmark',
    icon: (props) => (
      <SvgIcon {...props} viewBox="0 0 97.619 97.618">
        <path d="M96.939,17.358L83.968,5.959c-0.398-0.352-0.927-0.531-1.449-0.494C81.99,5.5,81.496,5.743,81.146,6.142L34.1,59.688L17.372,37.547c-0.319-0.422-0.794-0.701-1.319-0.773c-0.524-0.078-1.059,0.064-1.481,0.385L0.794,47.567c-0.881,0.666-1.056,1.92-0.39,2.801l30.974,40.996c0.362,0.479,0.922,0.771,1.522,0.793c0.024,0,0.049,0,0.073,0c0.574,0,1.122-0.246,1.503-0.68l62.644-71.297C97.85,19.351,97.769,18.086,96.939,17.358z"/>
      </SvgIcon>
    ),
    adder: async ({ canvas, fabric, colors }) => {
      return await AddHostedSvg(
        'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/checkmark.svg',
        fabric, canvas, colors, 1.0, 'Checkmark'
      );
    }
  },
  'location': {
    name: 'Location',
    icon: (props) => (
      <SvgIcon {...props} viewBox="0 0 510.993 510.993">
        <path d="m255.497 0c-96.853 0-175.649 78.796-175.649 175.649v.667c0 34.718 9.311 68.882 26.926 98.799l134.535 228.488c2.687 4.563 7.58 7.371 12.874 7.389h.052c5.275 0 10.164-2.771 12.873-7.3l136.427-228.089c18.063-30.2 27.611-64.763 27.611-99.955-.001-96.852-78.797-175.648-175.649-175.648zm-1.263 255.496c-42.245 0-76.613-34.369-76.613-76.613s34.369-76.613 76.613-76.613 76.613 34.369 76.613 76.613-34.369 76.613-76.613 76.613z"/>
      </SvgIcon>
    ),
    adder: async ({ canvas, fabric, colors }) => {
      return await AddHostedSvg(
        'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/location.svg',
        fabric, canvas, colors, 0.2, 'Location'
      );
    }
  },
  'music': {
    name: 'Music',
    icon: (props) => (
      <SvgIcon {...props} viewBox="0 0 489.164 489.164">
        <path d="M159.582,75.459v285.32c-14.274-10.374-32.573-16.616-52.5-16.616c-45.491,0-82.5,32.523-82.5,72.5s37.009,72.5,82.5,72.5s82.5-32.523,82.5-72.5V168.942l245-60.615v184.416c-14.274-10.374-32.573-16.616-52.5-16.616c-45.491,0-82.5,32.523-82.5,72.5s37.009,72.5,82.5,72.5s82.5-32.523,82.5-72.5V0L159.582,75.459z"/>
      </SvgIcon>
    ),
    adder: async ({ canvas, fabric, colors }) => {
      return await AddHostedSvg(
        'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/music.svg',
        fabric, canvas, colors, 0.3, 'Music'
      );
    }
  },
  'angled_rectangle': {
    name: 'Angled Rectangle',
    icon: (props) => (
        <Icon {...props} >
          <Svg {...props} url={`https://ss3-assets.s3.us-east-2.amazonaws.com/svg/angled_rectangle.svg?${new Date().getTime()}`}/>
        </Icon>
    ),
    adder: async ({ canvas, fabric, colors }) => {
      return await AddHostedSvg(
          'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/angled_rectangle.svg',
          fabric, canvas, colors, 1, 'Angled Rectangle'
      );
    },
  },
  'Arch': {
    name: 'Arch',
    icon: (props) => (
        <Icon {...props} >
          <Svg {...props} url={`https://ss3-assets.s3.us-east-2.amazonaws.com/svg/arch.svg?${new Date().getTime()}`}/>
        </Icon>
    ),
    adder: async ({ canvas, fabric, colors }) => {
      return await AddHostedSvg(
          'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/arch.svg',
          fabric, canvas, colors, 1, 'Arch'
      );
    }
  },
  'arched_square': {
    name: 'Arched Square',
    icon: (props) => (
        <Icon {...props} >
          <Svg {...props} url={`https://ss3-assets.s3.us-east-2.amazonaws.com/svg/arched_square.svg?${new Date().getTime()}`}/>
        </Icon>
    ),
    adder: async ({ canvas, fabric, colors }) => {
      return await AddHostedSvg(
          'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/arched_square.svg',
          fabric, canvas, colors, 1, 'Arched Square'
      );
    }
  },
  'curved_square': {
    name: 'Curved Square',
    icon: (props) => (
        <Icon {...props} >
          <Svg {...props} url={`https://ss3-assets.s3.us-east-2.amazonaws.com/svg/curved_square.svg?${new Date().getTime()}`}/>
        </Icon>
    ),
    adder: async ({ canvas, fabric, colors }) => {
      return await AddHostedSvg(
          'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/curved_square.svg',
          fabric, canvas, colors, 1, 'Curved Square'
      );
    }
  },
  'curved_tab': {
    name: 'Curved Tab',
    icon: (props) => (
        <Icon {...props} >
          {console.log(props)}
          <Svg {...props} url={`https://ss3-assets.s3.us-east-2.amazonaws.com/svg/curved_tab.svg?${new Date().getTime()}`}/>
        </Icon>
    ),
    adder: async ({ canvas, fabric, colors }) => {
      return await AddHostedSvg(
          'https://ss3-assets.s3.us-east-2.amazonaws.com/svg/curved_tab.svg',
          fabric, canvas, colors, 1, 'Curved Tab'
      );
    }
  },
});

// -------------------------------------------------------------------------- //
