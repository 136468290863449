
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Fade,
  Typography,
  withStyles,
} from '@material-ui/core';

import { formatDistance } from 'date-fns';
import parseColor from 'parse-color';

// -------------------------------------------------------------------------- //

function rgba(color, alpha) {
  color = parseColor(color);
  return `rgba(${color.rgb[0]}, ${color.rgb[1]}, ${color.rgb[2]}, ${alpha})`;
}

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  card: {
    borderRadius: 4,
  },
  card_action_area: {
    display: 'flex',
    flexDirection: 'column',
    height: theme.spacing(51),
    justifyContent: 'flex-start',
    overflowY: 'hidden',
    position: 'relative',
  },
  card_content: { },
  card_gradient: {
    background: `linear-gradient(0deg, ${rgba(theme.palette.background.paper, 1.0)} 0%, ${rgba(theme.palette.background.paper, 0.0)} 48px)`,
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  card_media: {
    backgroundPosition: 'top',
    height: theme.spacing(22),
    width: '100%',
  },
});

// -------------------------------------------------------------------------- //

const BetaNewsCardComponent = (props) => {
  const { classes, story } = props;

  const time = formatDistance(
    new Date(parseInt(story.published, 10)), new Date()
  );

  return (
    <Fade in>
      <Card className={classes.card} aria-label={`${story.title} article`}>
        <CardActionArea
          tabIndex={-1}
          className={classes.card_action_area}
          onClick={() => window.open(story.url, '_newtab')}
        >
          <CardMedia
            className={classes.card_media}
            image={`https://cdn-images-1.medium.com/max/325/${story.imageId}`}
            title="Story Preview"
          />
          <CardContent className={classes.card_content}>
            <Typography variant="h6" aria-label={`${story.title}`}>
              {story.title}
            </Typography>
            <Typography gutterBottom variant="caption" component="p">
              {time} ago
            </Typography>
            <Typography variant="body2" color="textSecondary" aria-label={`${story.subtitle}`}>
              {story.subtitle}
            </Typography>
            <div className={classes.card_gradient}/>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button
            size="small"
            color="primary"
            component="a"
            target="_BLANK"
            href={story.url}
            aria-label={`Learn more ${story.title}`}
            ref={props.inputRef}
          >
            Learn More
          </Button>
        </CardActions>
      </Card>
    </Fade>
  );
};

// -------------------------------------------------------------------------- //

export const BetaNewsCard = withStyles(STYLES)(BetaNewsCardComponent);

// -------------------------------------------------------------------------- //
