
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Grid,
  TextField,
  Typography,
  withMobileDialog,
  withStyles,
  Popper,
  Paper,
  Grow,
} from '@material-ui/core';

import {
  ImageSizeSelectLarge as ImageSizeSelectLargeIcon,
  Close as CloseIcon,
} from 'mdi-material-ui';

import { compose } from 'react-apollo';
import classNames from 'classnames';
import parseColor from 'parse-color';

// -------------------------------------------------------------------------- //

export const MINIMUM_SIZE = 128;
export const MAXIMUM_SIZE = 4096;

// -------------------------------------------------------------------------- //

const PRESETS = [
  {
    name: 'Twitter Post',
    width: 1024,
    height: 576,
  },
  {
    name: 'Twitter Header',
    width: 1500,
    height: 500,
  },
  {
    name: 'Facebook Cover',
    width: 820,
    height: 312,
  },
  {
    name: 'Instagram Post',
    width: 1024,
    height: 1024,
  },
  {
    name: 'Instagram Story',
    width: 1080,
    height: 1920,
  },
  {
    name: 'Social Media',
    width: 800,
    height: 800,
  },
];

// -------------------------------------------------------------------------- //

function FadeThemeColor(str, a) {
  const { rgb } = parseColor(str);
  return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${a})`;
}

// -------------------------------------------------------------------------- //

function FindCanvasPreset(dimensions) {
  const width = Number(dimensions.width);
  const height = Number(dimensions.height);

  for (let i = 0; i < PRESETS.length; ++i) {
    if (
      (width === PRESETS[i].width)
      && (height === PRESETS[i].height)
    ) {
      return i;
    }
  }

  return null;
}

// -------------------------------------------------------------------------- //

function CalculatePresetIcon(dimensions) {
  let width = 75; let
    height = 75;

  if (dimensions.width < dimensions.height) {
    width *= (dimensions.width / dimensions.height);
  } else if (dimensions.width > dimensions.height) {
    height *= (dimensions.height / dimensions.width);
  }

  return { width, height };
}

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  caption: {
    display: 'block',
  },
  custom_cross: {
    width: '100%',
  },
  custom_grid: {
    alignItems: 'flex-end',
  },
  custom_icon: {
    pointerEvents: 'none',
    transform: 'scale(2)',
    color: FadeThemeColor(theme.palette.text.primary, 0.87),
    transition: theme.transitions.create(['color']),
  },
  custom_icon_selected: {
    color: FadeThemeColor(theme.palette.primary.contrastText, 0.87),
  },
  custom_menu: {
    padding: theme.spacing(2),
    transformOrigin: 'top center',
    width: theme.spacing(19),
  },
  preset_button: {
    height: theme.spacing(13),
  },
  preset_button_selected: {
    // color: theme.palette.action.active,
  },
  preset_grid: {
    paddingBottom: theme.spacing(2),
  },
  preset_item: {
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
    transition: theme.transitions.create(['background-color', 'color']),
  },
  preset_item_selected: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  preset_icon: {
    borderColor: FadeThemeColor(theme.palette.text.primary, 0.87),
    borderStyle: 'dashed',
    borderWidth: 2,
    left: '50%',
    pointerEvents: 'none',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    transition: theme.transitions.create(['border-color']),
  },
  preset_icon_selected: {
    borderColor: FadeThemeColor(theme.palette.primary.contrastText, 0.87),
  },
  preset_popper: {
    anchorOrigin: 'initial',
  },
  preset_tooltip: {
    transformOrigin: 'initial',
  },
  root: {
    minWidth: theme.spacing(64),
    maxWidth: theme.spacing(64),
  },
});

// -------------------------------------------------------------------------- //

class Component extends React.Component {
  state = {
    custom_menu_open: false,
    custom_menu_anchor: null,
    selected_preset: null,
    width: '0',
    height: '0',
  }

  render() {
    const { classes, open, onClose } = this.props;
    const { selected_preset, width, height } = this.state;
    const resize_disabled = !(this.checkCanResize());
    const custom_flag = (selected_preset === null);
    let custom_icon = null;

    if (custom_flag) {
      const dimensions = CalculatePresetIcon(
        this.fetchOutgoingDimensions(),
      );

      custom_icon = (
        <div
          className={classNames(
            classes.preset_icon,
            classes.preset_icon_selected,
          )}
          style={{
            width: `${Math.round(dimensions.width)}%`,
            height: `${Math.round(dimensions.height)}%`,
          }}
        />
      );
    } else {
      custom_icon = (
        <ImageSizeSelectLargeIcon
          className={classNames(
            classes.custom_icon,
            { [classes.custom_icon_selected]: custom_flag },
          )}
        />
      );
    }
    return (
      <Dialog
        open={open}
        onEnter={() => this.initDialog()}
        onClose={onClose}
        classes={{ paper: classes.root }}
      >
        <form onSubmit={(e) => {
          e.preventDefault();
        }}
        >
          <Popper
            disablePortal
            transition
            placement="bottom"
            open={this.state.custom_menu_open && custom_flag}
            onClose
            anchorEl={this.state.custom_menu_anchor}
            modifiers={{
              preventOverflow: {
                enabled: false,
                boundariesElement: 'scrollParent',
              },
            }}
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper elevation={6} className={classes.custom_menu}>
                  <Grid container className={classes.custom_grid}>
                    <Grid item xs={5}>
                      <TextField
                        autoFocus
                        type="text"
                        placeholder="Width"
                        value={width}
                        onFocus={(e) => e.target.select()}
                        onBlur={() => this.validateDimension('width')}
                        onChange={(e) => this.setState({
                          width: e.target.value,
                        })}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <CloseIcon
                        color="inherit"
                        className={classes.custom_cross}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        type="text"
                        placeholder="Height"
                        value={height}
                        onFocus={(e) => e.target.select()}
                        onBlur={() => this.validateDimension('height')}
                        onChange={(e) => this.setState({
                          height: e.target.value,
                        })}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grow>
            )}
          </Popper>
          <DialogTitle>
            Resize Graphic
          </DialogTitle>
          <DialogContent>
            <Grid container spacing="2" className={classes.preset_grid}>
              {
                PRESETS.map((preset, index) => {
                  const dimensions = CalculatePresetIcon(preset);
                  const selected = (selected_preset === index);

                  return (
                    <Grid
                      item
                      xs={3}
                      key={index}
                      className={classNames(
                        classes.preset_item,
                        { [classes.preset_item_selected]: selected },
                      )}
                    >
                      <Button
                        fullWidth
                        variant="outlined"
                        className={classNames(
                          classes.preset_button,
                          { [classes.preset_button_selected]: selected },
                        )}
                        onClick={() => this.setState({ selected_preset: index })}
                      >
                        <div
                          className={classNames(
                            classes.preset_icon,
                            { [classes.preset_icon_selected]: selected },
                          )}
                          style={{
                            width: `${Math.round(dimensions.width)}%`,
                            height: `${Math.round(dimensions.height)}%`,
                          }}
                        />
                      </Button>
                      <Typography
                        align="center"
                        variant="caption"
                        className={classes.caption}
                      >
                        {preset.name}
                      </Typography>
                      <Fade in={selected}>
                        <Typography
                          align="center"
                          variant="caption"
                          className={classes.caption}
                        >
                          {preset.width}
                          {' '}
✕
                          {preset.height}
                        </Typography>
                      </Fade>
                    </Grid>
                  );
                })
              }
              <Grid
                item
                xs={3}
                className={classNames(
                  classes.preset_item,
                  { [classes.preset_item_selected]: custom_flag },
                )}
              >
                <Button
                  fullWidth
                  variant="outlined"
                  className={classNames(
                    classes.preset_button,
                    { [classes.preset_button_selected]: custom_flag },
                  )}
                  onClick={(e) => this.selectCustom(e.currentTarget)}
                >
                  {custom_icon}
                </Button>
                <Typography
                  align="center"
                  variant="caption"
                  className={classes.caption}
                >
                  Custom
                </Typography>
                <Fade in={custom_flag}>
                  <Typography
                    align="center"
                    variant="caption"
                    className={classes.caption}
                  >
                    {width}
                    {' '}
✕
                    {height}
                  </Typography>
                </Fade>
              </Grid>
            </Grid>
            <div />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={resize_disabled}
              onClick={() => this.performResize()}
            >
              Resize
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }

  initDialog = () => {
    const working_area = this.fetchIncomingDimensions();

    if (working_area !== null) {
      this.setState({
        selected_preset: FindCanvasPreset(working_area),
        width: String(working_area.width),
        height: String(working_area.height),
        custom_menu_open: false,
      });
    }
  }

  fetchIncomingDimensions = () => {
    const { canvas_util } = this.props;

    if (
      !canvas_util
      || !canvas_util.canvas
      || !canvas_util.canvas.workingArea
    ) {
      return null;
    }

    return {
      width: Number(canvas_util.canvas.workingArea.width),
      height: Number(canvas_util.canvas.workingArea.height),
    };
  }

  fetchOutgoingDimensions = () => {
    const { selected_preset } = this.state;

    if (selected_preset !== null) {
      const preset = PRESETS[selected_preset];

      return {
        width: preset.width,
        height: preset.height,
      };
    }
    const { width, height } = this.state;

    return {
      width: Number(width),
      height: Number(height),
    };
  }

  checkCanResize = () => {
    const incoming = this.fetchIncomingDimensions();
    const outgoing = this.fetchOutgoingDimensions();

    if (incoming === null) {
      return false;
    }

    return (
      (incoming.width !== outgoing.width)
      || (incoming.height !== outgoing.height)
    );
  }

  validateDimension = (key) => {
    const string = this.state[key];
    const value = parseInt(string, 10);

    if (value < MINIMUM_SIZE) {
      this.setState({ [key]: MINIMUM_SIZE });
    } else if (value > MAXIMUM_SIZE) {
      this.setState({ [key]: MAXIMUM_SIZE });
    }
  }

  selectCustom = (anchor) => {
    const { custom_menu_open, selected_preset } = this.state;

    if (selected_preset !== null) {
      this.setState({
        custom_menu_anchor: anchor,
        custom_menu_open: true,
        selected_preset: null,
      });
    } else {
      this.setState({ custom_menu_open: (!custom_menu_open) });
    }
  }

  performResize = () => {
    const { canvas_util, onClose } = this.props;

    if (!canvas_util) {
      return;
    }

    const { width, height } = this.fetchOutgoingDimensions();
    canvas_util.resizeGraphic(0, width, height);
    (onClose && onClose());
  }
}

// -------------------------------------------------------------------------- //

export const ResizeDialog = compose(
  withMobileDialog(),
  withStyles(STYLES),
)(Component);

// -------------------------------------------------------------------------- //
