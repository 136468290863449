
// -------------------------------------------------------------------------- //

import React from 'react';

import { Query } from 'react-apollo';
import Columns from 'react-columns';
import gql from 'graphql-tag';

import { PageTitle } from '../../../title';
import { ScheduledHint } from '../components/ScheduledHint';
import { ScheduledPost } from '../components/ScheduledPost';

// -------------------------------------------------------------------------- //

const GET_SCHEDULED = gql`
  query getScheduled($now: DateTime!, $client: ID!) {
    posts (
      where: {
        scheduled: true,
        postTime_gte: $now,
        client: { id: $client }
      }
      orderBy: postTime_ASC
    ) {
      id
      width
      height
      client {
        id
        name
        logoBucket
        logoKey
      }
      user {
        id
        firstname
        lastname
      }
      job
      postTime
      facebookPosts {
        id
        postId
        message
        page {
          id
          name
          accessToken
          fbId
        }
      }
      twitterPosts {
        id
        message
        account {
          name
        }
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const QUERIES = [{
  columns: 1,
  query: 'min-width: 450px',
}, {
  columns: 2,
  query: 'min-width: 600px',
}, {
  columns: 3,
  query: 'min-width: 900px',
}, {
  columns: 3,
  query: 'min-width: 1200px',
}];

// -------------------------------------------------------------------------- //

const now = new Date().toISOString();
const client = localStorage.getItem('client_id');

// -------------------------------------------------------------------------- //

const Scheduled_ = (props) => (
  <Query
    query={GET_SCHEDULED}
    fetchPolicy="cache-and-network"
    variables={{ now, client }}
  >
    {({ data }) => (
      ((data && data.posts) || null) &&
      <React.Fragment>
        <PageTitle title="Scheduled"/>
        <ScheduledHint/>
        <Columns
          dimensions={
            data.posts.map((post) => ({
              width: post.width,
              height: (post.height + 48),
            }))
          }
          gap="4px"
          queries={QUERIES}
        >
        {
          data.posts.map((post) => (
            <ScheduledPost key={post.id} post={post}/>
          ))
        }
        </Columns>
      </React.Fragment>
    )}
  </Query>
);

// -------------------------------------------------------------------------- //

export const Scheduled = Scheduled_;

// -------------------------------------------------------------------------- //
