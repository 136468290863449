
// -------------------------------------------------------------------------- //

import React, { Component } from 'react';

import {
  CircularProgress,
  withStyles,
} from '@material-ui/core';

import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import EnhancedTable from './PrestoEventTable';
import { PageTitle } from '../../../title';

// -------------------------------------------------------------------------- //


const STYLES = (theme) => ({
  button: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: '100%', // because we can't use fullWidth on the ButtonGroup
  },
  flex: {
    flex: 1,
  },
  grid: {
    height: 'calc(100% - 72px)',
    textAlign: 'center',
  },
  loading: {
    outline: 'none', // disable keyboard focus outline
    textAlign: 'center',
  },
  modal_button: {
    boxShadow: theme.shadows[3],
    transition: theme.transitions.create('box-shadow'),
    '&:hover': {
      boxShadow: theme.shadows[6],
    },
  },
  root_desktop: {
    height: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
  },
  root_mobile: {
    height: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    width: '100%',
  },
  card: {
    width: '30%',
    backgroundColor: '#dbdbdb',
    textAlign: 'center',
    marginTop: theme.spacing(1),
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  cardHeader: {
    fontSize: '12px',
    backgroundColor: '#c9c9c9',
    textAlign: 'center',
  },
  loading_icon: {
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

// -------------------------------------------------------------------------- //


class PrestoEvent extends Component {
  constructor() {
    super();

    const oneMonthAgoDate = new Date();
    oneMonthAgoDate.setMonth(oneMonthAgoDate.getMonth() - 1);
    const oneMonthAgo = this.formatDate(oneMonthAgoDate);

    const monthsAheadDate = new Date();
    monthsAheadDate.setMonth(monthsAheadDate.getMonth() + 12);
    const monthsAhead = this.formatDate(monthsAheadDate);

    this.state = {
      prestoApiBearer: localStorage.getItem('bearer'),
      prestoEvents: null,
      filteredPrestoEvents: null,
      loading: true,
      filtersArray: [
        { id: 'startDate', value: `${oneMonthAgo}` },
        { id: 'endDate', value: `${monthsAhead}` },
        { id: 'sport', value: 'none' },
        { id: 'teams', value: [] },
      ],
    };
  }

  componentDidMount() {
    this.getEvents();
  }

  async getEvents() {
    const { filtersArray } = this.state;
    const startDate = filtersArray.find((x) => x.id === 'startDate').value;
    const endDate = filtersArray.find((x) => x.id === 'endDate').value;
    this.setState({
      loading: true,
      prestoEvents: null,
    });
    const searchStartDate = new Date(startDate);
    let searchEndDate = new Date(endDate);
    const orgEndDate = new Date(endDate);
    let differenceInTime = searchEndDate.getTime() - searchStartDate.getTime();
    let differenceInDays = differenceInTime / (1000 * 3600 * 24);
    const promises = [];
    if (differenceInDays > 30) {
      searchEndDate = new Date(startDate);
      searchEndDate.setDate(searchEndDate.getDate() + 30);
      while (differenceInDays > 30) {
        const promise = this.eventAPIPull(
          this.formatDate(searchStartDate),
          this.formatDate(searchEndDate),
        );
        promises.push(promise);
        differenceInTime = orgEndDate.getTime() - searchEndDate.getTime();
        differenceInDays = differenceInTime / (1000 * 3600 * 24);
        searchEndDate.setDate(searchEndDate.getDate() + 31);
        searchStartDate.setDate(searchStartDate.getDate() + 31);
      }
      Promise.all(promises).then((arrOfResults) => {
        let finalResult = [];
        arrOfResults.forEach((result) => {
          if (result) {
            const tempArray = [];
            result.forEach((row) => {
              if (row && row.teams && row.teams.homeTeam && row.teams.homeTeam.season) {
                let homeScore = 0;
                let awayScore = 0;
                if (row.score && row.score.home) {
                  homeScore = row.score.home;
                }
                if (row.score && row.score.away) {
                  awayScore = row.score.away;
                }
                tempArray.push(
                  {
                    eventId: row.eventId,
                    sportName: row.teams.homeTeam.season.sport.sportName,
                    sportId: row.teams.homeTeam.sportId,
                    homeTeamLogo: row.teams.homeTeam.logo,
                    homeTeamName: row.teams.homeTeam.teamName,
                    teamid: row.teams.homeTeam.teamId,
                    awayTeamLogo: row.teams.awayTeam.logo,
                    awayTeamName: row.teams.awayTeam.teamName,
                    seasonName: row.teams.homeTeam.season.seasonName,
                    startDateTime: row.startDateTime,
                    status: (`${homeScore}-${awayScore} - ${row.status}`),
                  },
                );
              }
            });
            finalResult = [
              ...finalResult,
              ...tempArray,
            ];
          }
        });
        this.setState({
          prestoEvents: finalResult,
        });
        this.filterEvents();
      });
    } else {
      promises.push(this.eventAPIPull(
        this.formatDate(searchStartDate),
        this.formatDate(searchEndDate),
      ));
      Promise.all(promises).then((arrOfResults) => {
        let finalResult = [];
        arrOfResults.forEach((result) => {
          if (result) {
            const tempArray = [];
            result.forEach((row) => {
              if (row && row.teams && row.teams.homeTeam && row.teams.homeTeam.season) {
                let homeScore = 0;
                let awayScore = 0;
                if (row.score && row.score.home) {
                  homeScore = row.score.home;
                }
                if (row.score && row.score.away) {
                  awayScore = row.score.away;
                }
                tempArray.push(
                  {
                    eventId: row.eventId,
                    sportName: row.teams.homeTeam.season.sport.sportName,
                    sportId: row.teams.homeTeam.sportId,
                    homeTeamLogo: row.teams.homeTeam.logo,
                    homeTeamName: row.teams.homeTeam.teamName,
                    teamid: row.teams.homeTeam.teamId,
                    awayTeamLogo: row.teams.awayTeam.logo,
                    awayTeamName: row.teams.awayTeam.teamName,
                    seasonName: row.teams.homeTeam.season.seasonName,
                    startDateTime: row.startDateTime,
                    status: (`${homeScore}-${awayScore} - ${row.status}`),
                  },
                );
              }
            });
            finalResult = [
              ...finalResult,
              ...tempArray,
            ];
          }
        });
        this.setState({
          prestoEvents: finalResult,
        });
        this.filterEvents();
      });
    }
  }

  eventAPIPull = async (startDate, endDate) => {
    const { prestoApiBearer } = this.state;
    const prestoAPIEndpoint = process.env.REACT_APP_PS_GAMEDAY_API_ENDPOINT;
    return new Promise((resolve) => {
      fetch(`${prestoAPIEndpoint}me/events?pageSize=100&startDate=${startDate}&endDate=${endDate}`, {
        headers: {
          Authorization: `Bearer ${prestoApiBearer}`,
        },
      })
        .then((response) => response.json())
        .then((jsonData) => {
          if (jsonData && jsonData.data && jsonData.data.length >= 1) {
            return resolve(jsonData.data);
          }
          return resolve();
        })
        .catch((error) => {
          console.error('error', error);
          return resolve();
        });
    });
  }

  handleOnFilterChange = (filterId, value) => {
    this.setState({
      loading: true,
    });

    let { filtersArray } = this.state;
    filtersArray = this.changeFilterValue(filtersArray, filterId, value);
    this.setState({
      filtersArray,
    });

    if (filterId === 'startDate' || filterId === 'endDate') {
      this.getEvents();
    } else {
      this.filterEvents();
    }
  }

  changeFilterValue = (array, id, value) => {
    array.forEach((row) => {
      if (row.id === id) {
        row.value = value;
      }
    });
    return array;
  }

  formatDate = (date) => {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return `${ye}-${mo}-${da}`;
  }

  filterEvents() {
    const { prestoEvents, filtersArray } = this.state;
    let filteredPrestoEvents = prestoEvents;
    const sportValue = filtersArray.find((x) => x.id === 'sport').value;
    const teamsValue = filtersArray.find((x) => x.id === 'teams').value;
    if (sportValue !== 'none') {
      const tempFilterArray = [];
      filteredPrestoEvents.forEach((row) => {
        if (row.sportName === sportValue) {
          tempFilterArray.push(row);
        }
      });
      filteredPrestoEvents = tempFilterArray;
    }
    if (teamsValue && teamsValue.length >= 1) {
      const tempFilterArray = [];
      filteredPrestoEvents.forEach((row) => {
        if (teamsValue.includes(row.homeTeamName) || teamsValue.includes(row.awayTeamName)) {
          tempFilterArray.push(row);
        }
      });
      filteredPrestoEvents = tempFilterArray;
    }
    this.setState({
      loading: false,
      filteredPrestoEvents,
    });
  }

  render() {
    const { classes } = this.props;
    const {
      loading, filteredPrestoEvents, filtersArray, prestoEvents,
    } = this.state;
    if (loading) {
      return (
        <div className={classes.root_desktop} style={{ height: '100%' }}>
          <PageTitle title="Create Dashboard" />
          <div className={classes.loading_icon}>
            <CircularProgress size={48} />
          </div>
        </div>
      );
    }
    return (
      <div className={classes.root_desktop} style={{ height: '100%' }}>
        <PageTitle title="Create Dashboard" />
        <div className={classes.grid}>
          <EnhancedTable
            data={filteredPrestoEvents}
            orgData={prestoEvents}
            onFilterChange={this.handleOnFilterChange}
            filtersArray={filtersArray}
          />
        </div>
      </div>
    );
  }
}

// -------------------------------------------------------------------------- //


export default compose(
  withRouter,
  withStyles(STYLES, { withTheme: true }),
)(PrestoEvent);


// -------------------------------------------------------------------------- //
