import React from 'react';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ImageMultiple from 'mdi-material-ui/ImageMultiple';
import FolderStar from 'mdi-material-ui/FolderStar';
import AccountMultiple from 'mdi-material-ui/AccountMultiple';
import FileChart from 'mdi-material-ui/FileChart';
import FileImage from 'mdi-material-ui/FileImage';
import Home from 'mdi-material-ui/Home';
import Texture from 'mdi-material-ui/Texture';
import ExitToApp from 'mdi-material-ui/ExitToApp';
import Widgets from 'mdi-material-ui/Widgets';
import FormatText from 'mdi-material-ui/FormatText';
import LayersTripleOutline from 'mdi-material-ui/LayersTripleOutline';

const Navigation = () => (
  <React.Fragment>
    <Divider />
    <List component="nav">
      <ListItem component={Link} to="/admin" button>
        <ListItemIcon>
          <Home />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
      <ListItem component={Link} to="/admin/tagmanager" button>
        <ListItemIcon>
          <Home />
        </ListItemIcon>
        <ListItemText primary="Tag Manager" />
      </ListItem>
      <ListItem component={Link} to="/admin/templates" button>
        <ListItemIcon>
          <ImageMultiple />
        </ListItemIcon>
        <ListItemText primary="Templates" />
      </ListItem>
      <ListItem component={Link} to="/admin/textures" button>
        <ListItemIcon>
          <Texture />
        </ListItemIcon>
        <ListItemText primary="Textures" />
      </ListItem>
      <ListItem component={Link} to="/admin/graphics" button>
        <ListItemIcon>
          <FileImage />
        </ListItemIcon>
        <ListItemText primary="Graphics" />
      </ListItem>
      <ListItem component={Link} to="/admin/premium" button>
        <ListItemIcon>
          <FolderStar />
        </ListItemIcon>
        <ListItemText primary="Premium" />
      </ListItem>
      <ListItem component={Link} to="/admin/widgets" button>
        <ListItemIcon>
          <Widgets />
        </ListItemIcon>
        <ListItemText primary="Widgets" />
      </ListItem>
      <ListItem component={Link} to="/admin/clients" button>
        <ListItemIcon>
          <AccountMultiple />
        </ListItemIcon>
        <ListItemText primary="Clients" />
      </ListItem>
      <ListItem component={Link} to="/admin/tiers" button>
        <ListItemIcon>
          <LayersTripleOutline />
        </ListItemIcon>
        <ListItemText primary="Account Tiers" />
      </ListItem>
      <ListItem component={Link} to="/admin/fonts" button>
        <ListItemIcon>
          <FormatText />
        </ListItemIcon>
        <ListItemText primary="Fonts" />
      </ListItem>
      <ListItem component={Link} disabled to="/admin/reports" button>
        <ListItemIcon>
          <FileChart />
        </ListItemIcon>
        <ListItemText primary="Reports" />
      </ListItem>
      <ListItem component={Link} to="/" button>
        <ListItemIcon>
          <ExitToApp />
        </ListItemIcon>
        <ListItemText primary="Client Section" />
      </ListItem>
    </List>
  </React.Fragment>
);
export default Navigation;
