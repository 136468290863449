
// -------------------------------------------------------------------------- //

export class IWidgetFlow {

  _name = '';

  constructor(name) {
    this._name = name;
  }

  get name() { return this._name; }
  get options() { return []; }

  get(key) { return null; }
  set(key, value) {}

  reset(widgets, size, area) {}
  apply(widgets, size, area) {}

};

// -------------------------------------------------------------------------- //
