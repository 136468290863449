class AttributePath {
	constructor(xPathQuery, attribute) {
		this.xPathQuery = xPathQuery;
		this.attribute = attribute;
	}

	get(xml) {
		let nodes;
		if (xml.evaluate) {
			nodes = xml.evaluate(this.xPathQuery, xml, null, XPathResult.ANY_TYPE, null);
		} else if (window.ActiveXObject) {
			xml.setProperty('SelectionLanguage', 'XPath');
			nodes = xml.selectNodes(this.xPathQuery);
		}

		if (nodes) {
			const node = nodes.iterateNext();
			if (node) {
				const attribute = node.attributes[this.attribute];
				if (attribute) {
					return attribute.value;
				}
			}
		}

		return null;
	}
}

export default AttributePath;
