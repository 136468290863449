
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  withStyles,
  List,
  Grow,
} from '@material-ui/core';

import {
  ChevronDown as ChevronDownIcon,
  ChevronUp as ChevronUpIcon,
} from 'mdi-material-ui';

import {
  DragDropContext,
  Draggable,
  Droppable,
} from 'react-beautiful-dnd';

import * as Object2 from '../../canvas/lib/object';

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  button: {},
  list_item_secondary_action: {
    top: 'auto',
  },
  list_item_text_layer: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.palette.primary.main
  },
  list_item_text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

// -------------------------------------------------------------------------- //

function isObjectLayer(object) {
  if (!object) {
    return false;
  }

  if (object.parent) {
    return false;
  }

  if (object.widget_piece) {
    return false;
  }

  switch (object.type) {
    case 'effect':
    case 'canvasBackground': {
      return false;
    }
    default: {
      break;
    }
  }

  return true;
}

// -------------------------------------------------------------------------- //

class LayersEditor extends React.Component {

  state = {
    dragging: false
  }
  
  render() {
    const { classes, canvasUtil } = this.props;
    let layers = [];

    if (canvasUtil) {
      let { canvas } = canvasUtil;

      if (canvas) {
        let objects = canvas.getObjects();
        let key = 0;

        for (let i = 0; i < objects.length; ++i) {
          const index = (objects.length - i - 1);
          let object = objects[index];

          if (!isObjectLayer(object)) {
            continue;
          }

          let object_name = object.name;
    
          if (!object_name) {
            object_name = (
              <em>{Object2.getNiceName(object)}</em>
            );
          }

          const active_object = canvas.getActiveObject();

          const is_active = (
            active_object && (
            object === active_object ||
            (active_object.parent && object === active_object.parent) ||
            (active_object.media && object === active_object.media.element)
          ));
    
          let element = (
            <Draggable key={key} draggableId={key} index={key}>
            {(provided) => (
              <ListItem
                button
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={provided.draggableProps.style}
                onClick={() => this.invoke(object, this.props.setActiveLayer)}
                onDoubleClick={() => this.invoke(object, this.props.modifyLayer)}
                selected={is_active}
              >
                <ListItemText
                  primary={object_name}
                  primaryTypographyProps={{ className: classes.list_item_text }}
                />
                <Grow in={!this.state.dragging}>
                  <ListItemSecondaryAction
                    className={classes.list_item_secondary_action}
                  >
                    <IconButton
                      size="small"
                      onClick={(e) => this.shiftLayerUp(object, e)}
                    >
                      <ChevronUpIcon/>
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={(e) => this.shiftLayerDown(object, e)}
                    >
                      <ChevronDownIcon/>
                    </IconButton>
                  </ListItemSecondaryAction>
                </Grow>
              </ListItem>
            )}
            </Draggable>
          );
          layers.push(element)
          ++key;
        }
      }
    }
   
    
    
    return (
      <DragDropContext
        onDragStart={() => this.startDrag()}
        onDragEnd={(e) => this.stopDrag(e)}
      >
        <Droppable droppableId="layers">
          {(provided) => (
            <List ref={provided.innerRef}>
              {layers}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  moveChildren = (object) => {
    const canvas = this.props.canvasUtil.canvas;
    const objects = canvas._objects;
    const children = [];

    for (let i = 0; i < objects.length; ++i) {
      if (objects[i].parent === object) {
        children.push(objects[i]);
      }
    }

    canvas._objects = objects.filter((o) => {
      return children.indexOf(o) < 0;
    });

    for (var child of children) {
      canvas.insertAt(child, objects.indexOf(object) + 1);
    }

    canvas.requestRenderAll();
    canvas.trigger('object:modified');
  }

  shiftLayerDown = (layer, e) => {
    e.preventDefault();
    const { canvas } = this.props.canvasUtil;
    const objects = canvas.getObjects();
    const layer_index = objects.indexOf(layer);

    if (layer_index === 0) {
      return;
    }

    const step_count = (objects[layer_index - 1].parent ? 2 : 1);

    for (let i = 0; i < step_count; ++i) {
      layer.sendBackwards();

      if (layer.media) {
        let { element } = layer.media;

        if (element) {
          element.sendBackwards();
        }
      }
    }

    canvas.requestRenderAll();
    canvas.trigger('object:modified');
    this.forceUpdate();
  }

  shiftLayerUp = (layer, e) => {
    e.preventDefault();
    const { canvas } = this.props.canvasUtil;
    const objects = canvas.getObjects();
    const layer_index = objects.indexOf(layer);
    const lookahead = (layer.media && layer.media.element ? 2 : 1);
    let step_count = 1;

    if ((layer_index + lookahead) < objects.length) {
      const object = objects[layer_index + lookahead];

      if (object.media && object.media.element) {
        ++step_count;
      }
    }

    if (layer.media) {
      let { element } = layer.media;

      if (element) {
        for (let i = 0; i < step_count; ++i) {
          element.bringForward();
        }
      }
    }

    for (let i = 0; i < step_count; ++i) {
      layer.bringForward();
    }

    canvas.requestRenderAll();
    canvas.trigger('object:modified');
    this.forceUpdate();
  }

  startDrag = () => {
    this.setState({ dragging: true });
  }

  stopDrag = (e) => {
    this.setState({ dragging: false });

    if (!e.source || !e.destination) {
      return;
    }

    if (e.source.index === e.destination.index) {
      return;
    }

    let { canvas } = this.props.canvasUtil;
    let objects = canvas.getObjects();
    const filtered = objects.filter(isObjectLayer);
    let source_index = (filtered.length - e.source.index - 1);
    let destination_index = (filtered.length - e.destination.index - 1);
    source_index = objects.indexOf(filtered[source_index]);
    destination_index = objects.indexOf(filtered[destination_index]);

    let object = objects[source_index];

    objects.splice(source_index, 1);
    objects.splice(destination_index, 0, object);
    canvas.requestRenderAll();
    this.fixMediaObjects();
    this.forceUpdate();
  }

  fixMediaObjects = () => {
    let { canvas } = this.props.canvasUtil;
    let objects = canvas.getObjects();

    for (let i = 0; i < objects.length;) {
      if (!objects[i].parent) {
        ++i;
        continue;
      }

      const dst = (objects.indexOf(objects[i].parent) + 1);

      if (dst === i) {
        ++i;
        continue;
      }

      let object = objects[i];
      objects.splice(i, 1);
      objects.splice(dst, 0, object);
    }
  }

  getListItemStyle = (isDragging, activeStyle, dragStyle) => {
    if (activeStyle && isDragging) {
      return { ...activeStyle, ...dragStyle }
    }
    else {
      return dragStyle;
    }
  }

  invoke = (object, func) => {
    if (func) {
      func(object);
    }
  }

}

// -------------------------------------------------------------------------- //

export default withStyles(STYLES)(LayersEditor);

// -------------------------------------------------------------------------- //