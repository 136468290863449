class ScoreshotsAction {
	constructor(bucket, filename) {
  	this.token = localStorage.getItem('s3_token');
  	this.bucket = bucket;
    this.filename = filename;
	}

  getUrl(filename) {
    let folder = this.folder;
    if (folder !== "" && !folder) {
      folder = localStorage.getItem('client_id');
    }

    let key = filename || this.filename;

    if (folder !== "") {
      return `https://${this.bucket}.s3.us-east-2.amazonaws.com/${folder}/${key}`
    } else {
      return `https://${this.bucket}.s3.us-east-2.amazonaws.com/${key}`
    }
  }

  stringifyFile = (file, callback) => {
    var reader = new FileReader();
    
    reader.onload = () => {
      if (callback) {
        callback(reader.result);
      }
    };

    reader.readAsDataURL(file);
  }
}

export default ScoreshotsAction;
