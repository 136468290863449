/* eslint no-underscore-dangle: "off" */
class LogoBox {
  constructor(fabric) {
    this.class = fabric.util.createClass(fabric.ClippingBox, {
      type: 'logoBox',
      opacity: 0.2,
      visibleBox: true,
      stepMatrix: {
        ...fabric.ClippingBox.prototype.stepMatrix,
        logos: {
          fill: '#555',
          selectable: true,
        },
      },
      inheritAttributes(element) {
        this.left = element.left - element.getScaledWidth() / 2;
        this.top = element.top - element.getScaledHeight() / 2;
        this.scaleX = element.scaleX;
        this.scaleY = element.scaleY;
        this.width = element.width;
        this.height = element.height;
        this.angle = element.angle;
        this.setCoords();
      },
      notifyMediaSet(element) {
        this.callSuper('notifyMediaSet', element);
        if (!this.clipMedia) {
          this.inheritAttributes(element);
          element.on('modified', () => {
            this.inheritAttributes(element);
          });
        }
      }
    });

    this.fromObject = (object, callback) => fabric.Object._fromObject('LogoBox', object, callback, 'points');
  }
}


export default LogoBox;
