import React from 'react';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import TrialWrapper from './TrialWrapper';
import {withStyles ,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Button,
    Typography
} from '@material-ui/core';

const ADD_CLIENT = gql`
    mutation addClient($name: String!, $firstname: String!, $lastname: String!, $email: String!) {
        createClient (
            name: $name
            firstname: $firstname
            lastname: $lastname
            email: $email
        ) {
            id
            users {
                id
            }
        }
    }
`;

const ADD_TRIAL = gql`
    mutation addClientTrial($id: ID!, $subscription_start: String!, $subscription_end: String!, $is_trial: Boolean!, $total_storage : Float!,$total_allowed_storage : Float!, $notes : String) {
        addClientTrial (
            id: $id
            subscription_start: $subscription_start
            subscription_end: $subscription_end
            is_trial: $is_trial
            total_storage : $total_storage
            total_allowed_storage : $total_allowed_storage
            notes: $notes
        ) {
            id
        }
    }
`;

const GET_ALL_CLIENTS = gql`
    query($where: ClientWhereInput) {
        clients(where: $where) {
            id
        }
    }
`;

const FIELDS = [
    {
        name : "name",
        pretty : "Organization",
        value : ""
    },
    {
        name : "firstname",
        pretty : "First Name",
        value : ""
    },
    {
        name : "lastname",
        pretty : "Last Name",
        value : ""
    },
    {
        name : "email",
        pretty : "Email",
        value : ""
    }
];

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%',
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(2),
    },
});

class Trial extends React.Component {
    state = {
        fields : [],
        openMsgDialog : false,
        titleMessageForDialog : '',
        messageForDialog : '',
        sucessFulSignUp : false,
        isLoading: false,
    }
    componentDidMount(){
        this.setState({fields : FIELDS});
    }

    handleChange = index => event => {
        let _fields = this.state.fields;
        _fields[index].value = event.target.value;
        this.setState({ fields : _fields });
    };

    getFields = (list) => {
        const { classes } = this.props;
        return list.map( ( o, i ) => {
            return <div key={`d_${i}`}><TextField
                required
                fullWidth
                key={`t_${i}`}
                id={o.name}
                label={o.pretty}
                onChange={this.handleChange(i)}
                value={o.value}
                className={classes.textField}
                margin="normal"
            /></div>
        });
    }

    clearFields = () => {
        let _fields = this.state.fields;
        for( let i = 0; i < _fields.length; i++){
            _fields[i].value = '';
        }
        return _fields;
    }
    myHandleClose = (funct) => () => {
        let _fields = this.clearFields();
        this.setState({fields : _fields, sucessFulSignUp : false});
        if(funct){
            funct();
        }
    }

    addUser = () => {
        this.setState({isLoading : true});
        let notes = '';
        if (this.props && this.props.match && this.props.match.params && this.props.match.params.notes) {
            notes = this.props.match.params.notes;
        }
        let _variables = {};
        const _fields = this.state.fields;
        let isBad = false;
        let email = "";
        let client_count = 0;
        for(let i = 0; i < _fields.length; i++){

            let name = _fields[i].name;
            if (name === 'email') {
                email = _fields[i].value;
            }
            _variables[name] = _fields[i].value;
            if(_fields[i].value.trim() === ""){
                isBad = true;
                break;
            }
        }


        if(isBad){
            this.setState({openMsgDialog : true, titleMessageForDialog: 'Forget to fill a field?',messageForDialog : 'All fields are required...', isLoading : false});
            return;
        }

        this.props.clientInfo.refetch({
            where: {
                users_some: {
                    email_contains: email,
                },
            },
        })
            .then((response) => {
                if (response && response.data && response.data.clients) {
                    client_count = response.data.clients.length;
                }

                if (client_count > 0) {
                    this.setState({openMsgDialog : true, titleMessageForDialog: 'Email already in use',messageForDialog : 'That email address is already in our system, plaese use another email address or contact support.', isLoading : false});
                    return;
                }

                this.props.addClient({
                    variables: _variables,
                })
                    .then((response2) => {
                        let date = new Date();
                        let startDate = date.getTime();
                        let endDate = startDate + (1000 * 60 * 60 * 24 * 14);
                        this.props.addTrial({
                            variables: {
                                id : response2.data.createClient.id,
                                subscription_start : new Date(startDate).toISOString().split("T")[0],
                                subscription_end : new Date(endDate).toISOString().split("T")[0],
                                is_trial : true,
                                total_storage : 0,
                                total_allowed_storage : 10737418240,
                                notes,
                            },
                        }).then((response) => {
                            this.setState({openMsgDialog : true, titleMessageForDialog: 'Success',messageForDialog : 'An email has been sent with login information.', isLoading : false});
                            window.Intercom("trackEvent","register");
                        })
                    });
            });
    }
    render() {
        const {  handleClose } = this.props;
        const {fields, openMsgDialog, titleMessageForDialog, messageForDialog, sucessFulSignUp, isLoading} = this.state;
        const handlMyClose = this.myHandleClose(handleClose);
        const dialogFunct = sucessFulSignUp ? handlMyClose : ()=> this.setState({ openMsgDialog : false});
        const addUser = this.addUser;

        let dialogButton = (
            <Button onClick={dialogFunct} color="primary" autoFocus>
                Ok
            </Button>
        );

        if (titleMessageForDialog === 'Success') {
            dialogButton = (
                <Button onClick={handlMyClose} color="primary" autoFocus>
                    Go to Login
                </Button>
            );
        }

        let signUpButton = (
            <Button color="primary" variant="contained" className={this.props.classes.button} onClick={addUser}>
                Sign Me Up!
            </Button>
        );

        if (isLoading === true) {
            signUpButton = (
                <Button color="primary" variant="contained" className={this.props.classes.button} onClick={null}>
                    Please wait...
                </Button>
            );
        }

        return (
            <TrialWrapper>
                <Typography variant="h2">Trial</Typography>
                <Typography variant="body1" gutterBottom>
                    {'Don\'t have an account? Sign up for a free trial today.'}
                </Typography>
                {this.getFields(fields)}
                <Button color="primary" variant="contained" className={this.props.classes.button} onClick={handlMyClose}>
                    Cancel
                </Button>
                {signUpButton}
                <Dialog
                    open={openMsgDialog}
                    onClose={dialogFunct}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{titleMessageForDialog}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {messageForDialog}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {dialogButton}
                    </DialogActions>
                </Dialog>
            </TrialWrapper>
        )
    }
}


export default compose(
    graphql(ADD_CLIENT, { name: 'addClient' }),
    graphql(ADD_TRIAL, { name: 'addTrial' }),
    graphql(GET_ALL_CLIENTS, { name: 'clientInfo' }),
)(withStyles(styles)(Trial));
