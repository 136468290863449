import { Container } from 'unstated';

export default class MessageContainer extends Container {
  state = {
    message: false,
    duration: 5000,
  }
  setMessage = (message, duration = 5000) => {
    this.setState({
      message,
      duration,
    });
  }
  unsetMessage = () => {
    this.setState({
      message: false,
    });
  }
}
