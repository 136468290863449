
// -------------------------------------------------------------------------- //

import React from 'react';

// -------------------------------------------------------------------------- //

export function CreateSearchParameters() {
  return {
    sort: 'createdAt_DESC',
    orientation: null,
    sport: null,
    categories: [],
    tags: ['STOCK'],
  };
}

// -------------------------------------------------------------------------- //

export const CreateSearchContext = React.createContext();

// -------------------------------------------------------------------------- //
