
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  CircularProgress,
  IconButton,
  Typography,
  withStyles,
  Grid,
  Toolbar,
  Tooltip
} from '@material-ui/core';

import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from 'mdi-material-ui';

import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';

import { BetaNewsCard } from './BetaNewsCard';
import './BetaNewsCard.css';

// -------------------------------------------------------------------------- //

const GET_NEWS = gql`
  query {
    getNews {
      published
      url
      tags
      imageId
      title
      subtitle
    }
  }
`;

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  shift_button: {
    margin: theme.spacing(1),
  },
  news_card: {
    [theme.breakpoints.down('sm')]: {
      width: '20vw',
    },
    paddingRight: '5px',
  },
  list_style: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '0px',
    alignItems: 'self-end',
    listStyle: 'none',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-15px',
    },
  },
  shift_button_one: {
    [theme.breakpoints.down('sm')]: {
      marginRight: '25px',
      marginLeft: '-15px',
    },
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(1),
    },
  }
});

// -------------------------------------------------------------------------- //

class Component extends React.Component {
  state = {
    index: 0,
    focus: false
  }

  firstItemRef = React.createRef()

  componentDidUpdate(a,b,c) {
    if (this.state.focus === true) {
      this.firstItemRef.current.focus()
      this.setState({focus : false})
    }
  }

  render() {
    const { classes, data, loading } = this.props;

    const min_index = this.state.index;
    let max_index = min_index;
    let news_array = [];

    if (data && data.getNews && !loading) {
      news_array = data.getNews;
      max_index = Math.min((min_index + 4), news_array.length);
    }

    window.dispatchEvent(new Event('resize'));

    return (
      <>
        <Typography variant="h5" component="h2" className={classes.title}>
          Latest News
        </Typography>
        <Toolbar>
          <Tooltip title="Previous slide">
            <IconButton
              className={classes.shift_button_one}
              onClick={() => this.shiftNews(-1)}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Tooltip>
          {
            (loading)
            && (
            <Grid item xs={12}>
              <CircularProgress size={48} />
            </Grid>
            )
          }
          <ul
            className={classes.list_style}
            role="feed"
          >
            {news_array
              .slice(min_index, max_index)
              .map((news, index) => (
                <li
                  className={classes.news_card}
                  style={{
                    paddingLeft: index === 0 ? '0px' : '10px',
                  }}
                  role="article"
                >
                  <BetaNewsCard key={news.url} story={news} inputRef={index === 0 ? this.firstItemRef : null}/>
                </li>
              ))}
          </ul>
          <Tooltip title="Next slide">
            <IconButton
              className={classes.shift_button}
              onClick={() => this.shiftNews(1)}
            >
              <ChevronRightIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </>
    );
  }

  shiftNews = (direction) => {
    const { data, loading } = this.props;
    let { index } = this.state;

    if (data && data.getNews && !loading) {
      index += (4 * direction);

      if (index > (data.getNews.length - 4)) {
        index = (data.getNews.length - 4);
      }

      if (index < 0) {
        index = 0;
      }

      this.setState({ index, focus: true });
    }
  }
}

// -------------------------------------------------------------------------- //

export const BetaNews = compose(
  graphql(GET_NEWS),
  withStyles(STYLES),
)(Component);

// -------------------------------------------------------------------------- //
