import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ImageMultiple from 'mdi-material-ui/ImageMultiple';
import Upload from 'mdi-material-ui/Upload';
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { Star as StarIcon } from 'mdi-material-ui';
import Crumbs from './Crumbs';
import Tip from './Tip';
import ImageEditor from './ImageEditor';
import VideoEditor from './VideoEditor';
import ColorPicker from '../../color/ColorPicker';
import { ColorButton } from '../../color/ColorButton';
import TextureSelect from './TextureSelect';
import MediaLibrary2 from './MediaLibrary2';
import * as Object from '../../../canvas/lib/object';
import * as MetaData from '../../../canvas/lib/metadata';

const styles = (theme) => ({
  radioButtons: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  radioHeader: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  img: {
    display: 'block',
    backgroundColor: 'black',
    width: '90%',
    margin: 'auto',
    cursor: 'pointer',
  },
  button: {
    marginTop: theme.spacing(1),
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
});

const blobToDataURL = (blob, callback) => {
  const a = new FileReader();
  a.onload = function (e) { callback(e.target.result); };
  a.readAsDataURL(blob);
};

class Background extends Component {
  state = {
    page: 'picker',
    mediaFolder: null,
    mediaType: 'PHOTO',
    mediaParent: null,
    forcePick: false,
    textureScale: 1,
    textureBlendMode: '',
    showVideoWarningDialog: false,
    picker_open: false,
    anchor_position: null,
  }

  componentDidMount() {
    const { canvas, active } = this.props;

    if (!canvas || active) {
      return;
    }

    const background_boxes = canvas.getObjects('backgroundBox');

    if (background_boxes.length > 1) {
      return;
    }

    const bg = background_boxes[0];

    if (bg && bg.media && bg.media.element) {
      canvas.setActiveObject(bg.media.element);
    } else {
      canvas.setActiveObject(bg);
    }
  }

  setPage = (self, page, forcePick = false) => () => {
    self.setState({
      page,
      forcePick,
    });
  }

  setTexture = (id, image) => {
    let { active } = this.props;

    if (active.type !== 'backgroundBox' && active.parent) {
      active = active.parent;
    }

    if (active.type === 'backgroundBox') {
      const { media } = active;

      if (!image) {
        delete media.alphaMask;
      } else {
        media.alphaMask = image;
      }

      media.src = media.src || media.element.getSrc();
      active.setMedia(media);
      active.canvas.requestRenderAll();

      this.setState({
        forcePick: false,
      });
    }
  }

  setTextureBlendMode = (e) => {
    let { active } = this.props;

    if (active.type !== 'backgroundBox' && active.parent) {
      active = active.parent;
    }

    if (active.type === 'backgroundBox') {
      const { media } = active;

      media.alphaMaskBlendMode = e.target.value;
      active.canvas.requestRenderAll();

      this.setState({
        textureBlendMode: e.target.value,
      });
    }
  }

  scaleTexture = (e) => {
    let { active } = this.props;

    if (active.type !== 'backgroundBox' && active.parent) {
      active = active.parent;
    }

    const scale = ((e / 50) + 1);
    if (active.type === 'backgroundBox') {
      const { media } = active;

      media.alphaMaskScaleX = scale;
      media.alphaMaskScaleY = scale;
      active.canvas.requestRenderAll();

      this.setState({
        textureScale: scale,
      });
    }
  }

  getEditor() {
    let {
      active,
      activeGroup,
    } = this.props;
    const {
      CanvasUtil,
      fabric,
    } = this.props;

    if (active.media) {
      active = active.media.element;
      activeGroup = activeGroup.map((i) => i.media.element);
    }

    if (active && active.parent && active.parent.media && active.parent.media.type === 'video') {
      return {
        content: (
          <VideoEditor
            active={active}
            fabric={fabric}
          />
        ),
        title: 'Editing Video',
        back: () => {
          this.setState({
            page: 'picker',
            mediaFolder: null,
            mediaType: 'VIDEO',
            mediaParent: null,
            forcePick: true,
          });
        },
      };
    }

    return {
      content: (
        <>
          <List>
            { this.getMediaButtons() }
          </List>
          <ImageEditor
            active={active}
            activeGroup={activeGroup}
            CanvasUtil={CanvasUtil}
            fabric={fabric}
          />
        </>),
      title: 'Editing Image',
      back: () => {
        this.setState({
          page: 'picker',
          mediaFolder: null,
          mediaType: 'PHOTO',
          mediaParent: null,
          forcePick: true,
        });
      },
    };
  }

  getMedia(type) {
    const { mediaFolder, mediaParent } = this.state;
    return {
      content: (
        <>
          <MediaLibrary2 media_type={type} onSelect={(file) => this.mediaSelect(file)} />
        </>
      ),
      title: (type !== 'STOCK' ? 'Media Library' : 'Stock Library'),
      back: mediaFolder ? this.mediaNavigate(this)(mediaParent) : this.setPage(this, 'picker'),
    };
  }

  getTextures() {
    return {
      content: (
        <>
          <div style={{ padding: 8 }}>
            <Button variant="contained" color="secondary" onClick={() => this.setTexture(null, null)}>
              Remove
            </Button>
            <TextureSelect onSelect={this.setTexture} />
          </div>
        </>
      ),
    };
  }

  setActive = (object) => {
    const { canvas } = this.props;

    if (object && object.media && object.media.element) {
      canvas.setActiveObject(object.media.element);
    } else {
      canvas.setActiveObject(object);
    }

    canvas.requestRenderAll();
    this.forceUpdate();
  }

  handleColorChange = (object, color) => {


    this.setActive(object);
    object.set({
      fill: color.rgba,
      mode: 'color',
    });

    this.props.canvas.fire('object:modified', { target: object, ignoreUndo: false, color: true });
    object.canvas.requestRenderAll();
    this.forceUpdate();
  }

  handleFillTypeChange = (object) => (e) => {
    switch (e.target.value) {
      case 'none':
        object.setNone();
        break;
      case 'color':
        if (object.parent) {
          object.canvas.setActiveObject(object.parent);
        }
        if (e.target.type){
          this.props.canvas.fire('object:modified', { target: object, ignoreUndo: false, color: true });
          object.setMedia({ src: null });
          object.set('fillType', 'color');
          if(object.fill === 'transparent'){
            object.set({fill: '#555'})
          }
        }
        break;
      default:
        break;
    }
    object.canvas.requestRenderAll();
    this.forceUpdate();
  }

  getMediaButtons() {
    return (
      <>
        <ListItem button onClick={this.setPage(this, 'media', true)}>
          <ListItemAvatar>
            <Avatar>
              <ImageMultiple />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Browse Media Library" />
        </ListItem>
        <ListItem button onClick={this.setPage(this, 'stock', true)}>
          <ListItemAvatar>
            <Avatar>
              <StarIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Browse Stock Library" />
        </ListItem>
        <ListItem
          component="label"
          button
          htmlFor="bgUploader"
        >
          <input
            accept="image/jpeg,image/png,video/mp4"
            style={{ display: 'none' }}
            id="bgUploader"
            type="file"
            name="file"
            onChange={this.uploadFile(this)}
          />
          <ListItemAvatar>
            <Avatar>
              <Upload />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Upload from Device" />
        </ListItem>
        <Divider />
      </>
    );
  }

  getPicker() {
    const { classes, active, canvas, template, objects } = this.props;
    const { forcePick, picker_open, anchor_position } = this.state;
    let backgroundBox;

    if(template !== undefined){
      backgroundBox = template?.isQuickCreate === true ? objects.filter(obj => obj.type === 'backgroundBox')[0]
        : active.parent ? active.parent : active;
    }else{
      backgroundBox = active.parent ? active.parent : active
    }
    const activeMedia = backgroundBox.media ? backgroundBox.media.element : null;
    const boxes = canvas ? canvas.getObjects('backgroundBox') : [];
    let src = '';
    let fill_type = 'none';

    if (activeMedia) {
      src = activeMedia.getSrc();
    } else {
      fill_type = backgroundBox.fillType;
    }

    return {
      content: (
        <>
          <Tip
            heading="Tip:"
            text="Only backgrounds are selectable in the background tab. Choose another menu tab to adjust other items on the template."
          />
          <List>
            {
              forcePick
                ? (
                  <ListItem
                    button
                    onClick={() => { this.setState({ forcePick: false }); }}
                  >
                    <ListItemAvatar>
                      <Avatar src={src} />
                    </ListItemAvatar>
                    <ListItemText primary="Edit Existing" />
                  </ListItem>
                )
                : null
            }
            { this.getMediaButtons() }
          </List>
          <Typography className={classes.radioHeader} variant="h6" gutterBottom>
            Background Color Fill
          </Typography>
          <RadioGroup
            aria-label="backgroundColor"
            name="backgroundColor"
            value={fill_type}
            className={classes.radioButtons}
            onChange={this.handleFillTypeChange(backgroundBox)}
          >
            <FormControlLabel value="none" control={<Radio />} label="None" />
            <FormControlLabel value="color" control={<Radio />} label="Solid Color" />
            {
              fill_type === 'color' && (
                <>
                  <ColorButton
                    color={backgroundBox.fill}
                    onClick={(e) => {
                      const x = 218.0; // (218.0 * ratio);
                      const y = e.clientY; // (e.clientY * ratio);

                      this.setState({
                        picker_open: true,
                        anchor_position: { left: x, top: y },
                      });
                    }}
                  />
                  <ColorPicker
                    open={picker_open}
                    anchorReference="anchorPosition"
                    anchorPosition={anchor_position}
                    canvas={canvas}
                    color={backgroundBox.fill}
                    onClose={() => this.setState({ picker_open: false })}
                    onChangeComplete={(color) => {
                      this.handleColorChange(backgroundBox, color);
                    }}
                  />
                </>
              )
            }
          </RadioGroup>
        </>
      ),
      title: 'Choose  Background',
      back: boxes.length > 1 ? () => { canvas.discardActiveObject(); } : null,
    };
  }

  handleChange = (e) => {
    this.setState({
      mediaType: e.target.value,
      mediaFolder: null,
    });
  }

  mediaNavigate = (self) => (mediaFolder = null) => () => {
    self.setState({
      mediaFolder,
    });
  }

  mediaSelect = (file) => {
    const is_video = (file.extension === 'mp4');
    this.mediaSend(`https://${file.bucket}.s3.us-east-2.amazonaws.com/${file.client.id}/${file.id}.${file.extension}`, is_video);
  }

  mediaSend(url, video = false) {
    const self = this;
    const isURL = /^(f|ht)tps?:\/\//i.test(url);
    let cacheBuster = '';
    if (isURL) {
      cacheBuster = `?t=${new Date().getTime()}`;
    }
    const media = {
      src: url + cacheBuster,
      type: video ? 'video' : 'image',
    };
    if (self.props.active && self.props.active.setMedia) {
      MetaData.clearMetaData(self.props.active, 'cropper_src');
      self.props.active.setMedia(media);
    } else if (self.props.active && self.props.active.parent) {
      MetaData.clearMetaData(self.props.active.parent, 'cropper_src');
      self.props.active.parent.setMedia(media);
    }
    self.setState({
      forcePick: false,
    });
    this.props.canvas.fire('object:modified', { target: this.props.active, ignoreUndo: false, color: true });
  }

  uploadFile = (self) => (e) => {
    const video = e.target.files[0].type === 'video/mp4' || e.target.files[0].type === 'video/quicktime';
    if (!video) {
      blobToDataURL(e.target.files[0], (finalUrl) => {
        self.mediaSend(finalUrl, video);
      });
    } else {
      self.mediaSend(URL.createObjectURL(e.target.files[0]), video);
    }

    e.target.value = null;
  }

  render() {
    let content = null;
    let title = 'Background';
    let back = null;
    let contentBlock = {};
    const { active, canvas } = this.props;
    const { forcePick, page } = this.state;
    // if there's an active background box, enable picker.
    if (active && ((active.type === 'backgroundBox' && (!active.media || !active.media.element)) || forcePick)
    ) {
      switch (page) {
        case 'picker':
          contentBlock = this.getPicker();
          break;
        case 'media':
          contentBlock = this.getMedia('PHOTO');
          break;
        case 'stock':
          contentBlock = this.getMedia('STOCK');
          break;
        case 'texture':
          contentBlock = this.getTextures();
          break;
        default:
          break;
      }
    } else if (active) {
      contentBlock = this.getEditor();
    }
    (({ content, title, back } = contentBlock));

    if (active) {
      return (
        <>
          <Crumbs
            title={title}
            onBack={back}
          />
          {content}
        </>
      );
    }

    const output = [];
    const boxes = canvas ? canvas.getObjects('backgroundBox') : [];

    // Sorting the boxes to show the backgrounds last
    boxes.sort((a, b) => {
      // Background objects have no name set here send them back
      if (!a.name) {
        return 1;
      }
      // sort by name
      if (a.name === b.name) {
        return 0;
      }
      return a.name > b.name ? 1 : -1;
    });
    const namedOnes = boxes.filter((box) => !!box.name).length;

    boxes.forEach((box, index) => {
      let backgrounder = null;

      if (
        (box.media)
        && (box.media.type === 'image')
        && (box.media.element)
        && (box.media.element.getSrc)
      ) {
        backgrounder = (
          <ListItemAvatar>
            <Avatar src={box.media.element.getSrc()} />
          </ListItemAvatar>
        );
      } else {
        const { theme } = this.props;
        const style = {};
        if (box.fillType === 'color') {
          style.backgroundColor = box.fill;
          style.color = theme.palette.getContrastText(style.backgroundColor);
          style.border = '1px solid #bdbdbd';
        }

        backgrounder = (
          <ListItemAvatar>
            <Avatar style={style}>
              {
                box.fillType !== 'color' && <ImageMultiple />
              }
            </Avatar>
          </ListItemAvatar>
        );
      }

      output.push((
        <ListItem key={`box${index}`} button onClick={() => this.setActive(box)}>
          {backgrounder}
          <ListItemText primary={Object.getNiceName(box, index - namedOnes)} />
        </ListItem>
      ));
    });
    return (
      <>
        <Crumbs title="Available Backgrounds" />
        <List>
          {output}
        </List>
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Background);
