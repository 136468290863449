
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Button,
  Typography,
  withStyles,
} from '@material-ui/core';

import 'rc-slider/assets/index.css';

import SliderWithInput from '../SliderWithInput';

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  clear_button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

// -------------------------------------------------------------------------- //

function ClearShadow({ active, activeGroup }) {
  activeGroup.forEach((object) => {
    object.setShadow(null);
  });

  active.canvas.requestRenderAll();
}

// -------------------------------------------------------------------------- //

function SetShadow({ active, activeGroup }, key, value) {
  activeGroup.forEach((object) => {
    let new_shadow = {};

    if (object.shadow) {
      new_shadow = { ...object.shadow };
    }

    new_shadow[key] = value;
    object.setShadow(new_shadow);
  });

    if(active.parent?.type === 'logoBox') {
        active.parent.shadow = active.shadow
    }

  active.canvas.requestRenderAll();
}

// -------------------------------------------------------------------------- //

function Render(props) {
  let [, setState] = React.useState();
  const { active, classes, theme } = props;
  const color = theme.palette.primary.light;
  let shadow_blur = 0;
  let shadow_offset_x = 0;
  let shadow_offset_y = 0;

  if (active.shadow) {
    shadow_blur = (active.shadow.blur || 0);
    shadow_offset_x = (active.shadow.offsetX || 0);
    shadow_offset_y = (active.shadow.offsetY || 0);

      if(active.parent?.type === 'logoBox') {
          active.parent.shadow = active.shadow
      }
  }

  return (
    <>
      <Typography variant="caption">
        Blur
      </Typography>
      <SliderWithInput
        trackStyle={[{ backgroundColor: color }]}
        handleStyle={[{ borderColor: color }]}
        min={0} step={0.1} max={30}
        value={shadow_blur}
        onChange={(value) => {
          SetShadow(props, 'blur', value);
          setState({});
        }}
      />
      <Typography variant="caption">
        X Offset
      </Typography>
      <SliderWithInput
        trackStyle={[{ backgroundColor: color }]}
        handleStyle={[{ borderColor: color }]}
        min={-20} step={0.1} max={20}
        value={shadow_offset_x}
        onChange={(value) => {
          SetShadow(props, 'offsetX', value);
          setState({});
        }}
      />
      <Typography variant="caption">
        Y Offset
      </Typography>
      <SliderWithInput
        trackStyle={[{ backgroundColor: color }]}
        handleStyle={[{ borderColor: color }]}
        min={-20} step={0.1} max={20}
        value={shadow_offset_y}
        onChange={(value) => {
          SetShadow(props, 'offsetY', value);
          setState({});
        }}
      />
      <Button
        fullWidth
        color="primary"
        variant="contained"
        className={classes.clear_button}
        onClick={() => {
          ClearShadow(props);
          setState({});
        }}
      >
        Clear Shadow
      </Button>
    </>
  );
}


// -------------------------------------------------------------------------- //

export const ShadowEditor = withStyles(STYLES, { withTheme: true })(Render);

// -------------------------------------------------------------------------- //
