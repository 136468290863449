
/* eslint no-underscore-dangle: "off" */
/* eslint-disable no-param-reassign */

// -------------------------------------------------------------------------- //

import pica from 'pica';
import uuid from 'uuid4';
import Canvg from 'canvg';
import parseColor from 'parse-color';
import * as MetaData from './lib/metadata';
import * as WidgetUtil from './widgets/process';
import Adders from './adders';
import canvasExtensions, { Filters as CustomFilters } from './extensions';
import CanvasResize from './CanvasResize';
import CanvasVideo from './CanvasVideo';
import common from './dict/common';
import fonts from './dict/type';
import RenderJob from '../scoreshotsapi/RenderJob';
import ScoreShotsPut from '../scoreshotsapi/Put';


const MAX_DIMENSION = 4096;

// -------------------------------------------------------------------------- //

const fetchTemplate = (template, custom) => {
  const cache_buster = `${+new Date()}`;
  let { url } = template;

  if (template.isSlideShow) url = `https://s3.us-east-2.amazonaws.com/ss3-slides/${template.id}.json`;

  if (template.isQuickCreate) {
    url = custom ? template.url
      : `https://s3.us-east-2.amazonaws.com/ss3-slides/${template.id}.json`;
    //TODO hardfix making sure that the url is from an quickcreate template..
    return new Promise((resolve) => {
      fetch(`${url}?${cache_buster}`)
        .then((response) => response.json())
        .then(json => {
          return resolve(json)
        })
        .catch(err => {
          if(!custom){
            fetch(`${template.url}?${cache_buster}`)
              .then((response) => response.json())
              .then(json => {
                return resolve(json)
              })
          }
        })
    })


  }else{

    return (
      fetch(`${url}?${cache_buster}`)
        .then((response) => response.json())
    );
  }

};

// -------------------------------------------------------------------------- //

const fetchOldTemplate = (id) => {
  const cache_buster = `${+new Date()}`;
  const url = `https://scoreshotstemplates.s3.us-east-2.amazonaws.com/${id}.json`;

  return (
    fetch(`${url}?${cache_buster}`, { mode: 'cors' })
      .then((response) => response.json())
  );
};

// -------------------------------------------------------------------------- //

class CanvasUtil {
  constructor() {
    // lets set some inital attributes.
    // the amount of viewPadding around the canvas.
    this.mouseIsOver = false;
    this.viewPadding = 60;
    this.initCanvas = {
      preserveObjectStacking: true,
      workingArea: {
        width: 100,
        height: 100,
      },
      canvasState: [],
    };
    this.CanvasResize = new CanvasResize(this);
    this.CanvasVideo = new CanvasVideo(this);
    this.dirty = false;
    this.dirtyEnabled = false;
    this.grid_enable = false;
    this.grid_snap = false;
    this.grid_object = null;
    this.permissions = null;
  }

  init(config = {}) {
    const {
      id,
      initStep,
      admin_editor = false,
      colors = {
        primary: '#6b2587',
        secondary: '#4fd4c4',
        tertiary: '#e8e7e5',
      },
      method = 'none',
    } = config;

    this.admin_editor = Boolean(admin_editor);

    const self = this;
    // make a fabric js instance on the page.
    (((d, scriptTag) => {
      let script = scriptTag;
      script = d.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.onload = () => {
        /* global fabric */
        self.fabric = fabric;
        self.fabric.textureSize = 4096;
        self.canvas = new fabric.Canvas(id);
        self.canvas.controlsAboveOverlay = true;
        self.canvas.setBackgroundColor('rgba(0, 0, 0, 0)');
        self.canvas.selectionKey = ['ctrlKey', 'shiftKey', 'metaKey'];
        // add functions with good defaults.
        self.adders = new Adders(fabric, self.canvas);

        // internal property set.
        self.canvas.set(self.initCanvas);

        // set current step
        self.canvas.currentStep = initStep;

        // add the client colors
        self.canvas.colors = colors;

        // instantiate pica.
        self.canvas.pica = pica();
        self.method = method;

        // if we're in development, why not put the canvas in the window context.
        if (process.env.NODE_ENV === 'development') {
          window.canvas = self.canvas;
          window.CanvasUtil = self;
        }
        // load the fabric extensions.
        self.loadExtensions();
        // style the controls.
        self.setupControls();
        // provide inital sizing
        self._resize();
        // bind events.
        self.bindEvents();
        self._calcGridLines();
        // self.init();
        if (self.onLoad) {
          self.onLoad();
          self.setStep(initStep);
        }
      };
      if (process.env.NODE_ENV === 'development') {
        script.src = 'https://s3.us-east-2.amazonaws.com/scoreshots.com/static/js/fabric.js';
      } else {
        script.src = 'https://s3.us-east-2.amazonaws.com/scoreshots.com/static/js/fabric.min.js';
      }

      d.getElementsByTagName('head')[0].appendChild(script);
    })(document));
  }


  _groupGetColors() {
    let output = [];
    if (this.getObjects().length) {
      const objects = this.getObjects();
      objects.forEach((object) => {
        if (object.getColors) {
          output = output.concat(object.getColors());
        }
      });
    }
    return output;
  }

  // simplification method for all objects to return their colors in a standardized way
  // with an emphasis on quick setting later.
  _getColors() {
    const output = [];

    const method = (key, value) => {
      const { rgb } = parseColor(value);
      const a = parseColor(this.get(key)).rgba[3];
      this.set(key, `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${a})`);

      if (this.animation && this.animation.animations) {
        const { animations } = this.animation;

        for (let i = 0; i < animations.length; ++i) {
          if (animations[i][key]) {
            animations[i][key] = value;
          }
        }
      }
    };

    if (this.stroke && this.strokeWidth > 0) {
      output.push({
        type: this.type,
        color: this.stroke,
        object: this,
        property: 'stroke',
        method,
      });
    }

    if (this.fill && this.fill !== 'transparent' && typeof (this.fill) === 'string') {
      output.push({
        type: this.type,
        color: this.fill,
        object: this,
        property: 'fill',
        method,
      });
    }

    return output;
  }

  setDirtyEnabled = (enabled) => {
    this.dirtyEnabled = enabled;
    this.dirty = false;
  }

  dirtyCanvas = (e = {}) => {
    if (this.dirtyEnabled && !e.ignoreUndo && !this.canvas.addingImageEl) {
      this.dirty = true;
    }
  }

  isCanvasDirty = () => this.dirty

  copy = () => {
    if (this && this.canvas && this.canvas.getActiveObject()) {
      if (this.canvas.step !== 'admin') {
        if (
          (this.permissions === null)
          || (this.permissions.get('editor:lock_movement'))
        ) {
          return;
        }
      }

      this.canvas.getActiveObject().clone((cloned) => {
        const disallowed = [
          'backgroundBox',
          'logoBox',
          'cutoutBox',
          'widget',
        ];
        if (disallowed.indexOf(cloned.type) !== -1) {
          return;
        }
        cloned.clipTo = null;
        if (cloned.type === 'activeSelection') {
          const newObjects = [];
          cloned._objects.forEach((obj) => {
            obj.clipTo = null;
            if (disallowed.indexOf(obj.type) === -1) {
              newObjects.push(obj);
            }
          });
          cloned._objects = newObjects;
        }
        this.canvas._clipboard = cloned;
      });
    }
  }

  paste = () => {
    const { canvas } = this;
    if (canvas._clipboard) {
      canvas._clipboard.clone((clonedObj) => {
        canvas.discardActiveObject();
        clonedObj.set({
          left: clonedObj.left + 10,
          top: clonedObj.top + 10,
          evented: true,
        });
        if (clonedObj.type === 'activeSelection') {
          // active selection needs a reference to the canvas.
          clonedObj.canvas = canvas;
          clonedObj.forEachObject((obj) => {
            canvas.add(obj);
          });
          // this should solve the unselectability
          clonedObj.setCoords();
        } else {
          canvas.add(clonedObj);
        }
        canvas._clipboard.top += 10;
        canvas._clipboard.left += 10;
        canvas.setActiveObject(clonedObj);
        canvas.requestRenderAll();
      });
    }
  }

  loadExtensions() {
    const { fabric } = this;
    Object.keys(canvasExtensions).forEach((name) => {
      const extension = new canvasExtensions[name](fabric);
      fabric[name] = extension.class;
      const minos = 'fromObject' in extension;
      if (extension.fromObject && minos) {
        fabric[name].fromObject = extension.fromObject;
      }
    });

    Object.keys(CustomFilters).forEach((name) => {
      const extension = new CustomFilters[name](fabric);
      fabric.Image.filters[name] = extension.class;
      fabric.Image.filters[name].fromObject = fabric.Image.filters.BaseFilter.fromObject;
    });

    // this creates a getColors method on all object types, for easy palette step later
    fabric.Circle.prototype.getColors = this._getColors;
    fabric.Rect.prototype.getColors = this._getColors;
    fabric.Triangle.prototype.getColors = this._getColors;
    fabric.Polygon.prototype.getColors = this._getColors;
    fabric.Text.prototype.getColors = this._getColors;
    fabric.Polyline.prototype.getColors = this._getColors;
    fabric.Path.prototype.getColors = this._getColors;
    fabric.Line.prototype.getColors = this._getColors;
    fabric.Group.prototype.getColors = this._groupGetColors;

    fabric.CurvedText = fabric.util.createClass(fabric.Object, {
      type: 'curvedText',
      name: 'curvedText',
      diameter: 250,
      charSpacing: 0,
      text: '',
      flipped: false,
      fill: '#000',
      fontFamily: 'Times New Roman',
      fontSize: 24, // in px
      fontWeight: 'normal',
      fontStyle: '', // "normal", "italic" or "oblique".
      cacheProperties: fabric.Object.prototype.cacheProperties.concat('diameter', 'charSpacing', 'flipped', 'fill', 'fontFamily', 'fontSize', 'fontWeight', 'fontStyle', 'stroke', 'strokeWidth'),
      stroke: null,
      strokeWidth: 0,
      scaleX: 1,
      scaleY: 1,


      initialize(text, options) {
        options || (options = {});

        if (typeof text === 'object') {
          this.text = text.text
          if (Object.keys(options).length === 0) {
            options = text
          }
        }else {
          this.text = text
        }
        this.callSuper('initialize', options);
        this.set('lockUniScaling', true);

        // Draw curved text here initially too, while we need to know the width and height.
        const canvas = this.getCircularText();
        this.cropCanvas(canvas);
        this.set('width', canvas.width);
        this.set('height', canvas.height);
      },

      _getFontDeclaration() {
        return [
          // node-canvas needs "weight style", while browsers need "style weight"
          (fabric.isLikelyNode ? this.fontWeight : this.fontStyle),
          (fabric.isLikelyNode ? this.fontStyle : this.fontWeight),
          `${this.fontSize}px`,
          (fabric.isLikelyNode ? (`"${this.fontFamily}"`) : this.fontFamily),
        ].join(' ');
      },

      cropCanvas(canvas) {
        try {
          const ctx = canvas.getContext('2d');
          let w = canvas.width;
          let h = canvas.height;
          const pix = { x: [], y: [] }; let n;
          const imageData = ctx.getImageData(0, 0, w, h);
          const fn = function (a, b) { return a - b; };

          for (let y = 0; y < h; y++) {
            for (let x = 0; x < w; x++) {
              if (imageData.data[((y * w + x) * 4) + 3] > 0) {
                pix.x.push(x);
                pix.y.push(y);
              }
            }
          }
          pix.x.sort(fn);
          pix.y.sort(fn);
          n = pix.x.length - 1;

          w = pix.x[n] - pix.x[0];
          h = pix.y[n] - pix.y[0];
          const cut = ctx.getImageData(pix.x[0], pix.y[0], w, h);

          canvas.width = w;
          canvas.height = h;
          ctx.putImageData(cut, 0, 0);
        } catch (ex) {
          console.log(ex);
        }
      },

      getCircularText() {
        let { text } = this;
        let { diameter } = this;
        const { flipped } = this;
        const { charSpacing } = this;
        const { fill } = this;
        let inwardFacing = true;
        let startAngle = 0;
        const canvas = fabric.util.createCanvasElement();
        const ctx = canvas.getContext('2d');
        let cw; // character-width
        let x; // iterator
        const clockwise = -1; // draw clockwise for aligned right. Else Anticlockwise

        if (typeof text === 'object') {
          text = text.text
        }

        if (flipped) {
          startAngle = 180;
          inwardFacing = false;
        }

        if (diameter < 20) diameter = 20;

        startAngle *= Math.PI / 180; // convert to radians

        // Calc heigt of text in selected font:
        const d = document.createElement('div');
        d.style.fontFamily = this.fontFamily;
        d.style.fontSize = `${this.fontSize}px`;
        d.style.fontWeight = this.fontWeight;
        d.style.fontStyle = this.fontStyle;
        d.textContent = text;
        document.body.appendChild(d);
        const textHeight = d.offsetHeight;
        document.body.removeChild(d);

        canvas.width = canvas.height = diameter * 1.2;
        ctx.font = this._getFontDeclaration();

        if (text.length === 0) {
          text = ' ';
        }

        // Reverse letters for center inward.
        if (inwardFacing) { text = text.split('').reverse().join(''); }

        // Setup letters and positioning
        ctx.translate((diameter * 1.2) / 2, (diameter * 1.2) / 2); // Move to center
        startAngle += (Math.PI * !inwardFacing); // Rotate 180 if outward
        ctx.textBaseline = 'middle'; // Ensure we draw in exact center
        ctx.textAlign = 'center'; // Ensure we draw in exact center

        // rotate 50% of total angle for center alignment
        for (x = 0; x < text.length; x++) {
          cw = ctx.measureText(text[x]).width;
          startAngle += ((cw + (x === text.length - 1 ? 0 : charSpacing)) / (diameter / 2 - textHeight)) / 2 * -clockwise;
        }

        // Phew... now rotate into final start position
        ctx.rotate(startAngle);

        // Now for the fun bit: draw, rotate, and repeat
        for (x = 0; x < text.length; x++) {
          cw = ctx.measureText(text[x]).width; // half letter
          // rotate half letter
          ctx.rotate((cw / 2) / (diameter / 2 - textHeight) * clockwise);
          // draw the character at "top" or "bottom"
          // depending on inward or outward facing

          // Stroke
          if (this.stroke && this.strokeWidth) {
            ctx.strokeStyle = this.stroke;
            ctx.lineWidth = this.strokeWidth;
            ctx.miterLimit = 2;
            ctx.strokeText(text[x], 0, (inwardFacing ? 1 : -1) * (0 - diameter / 2 + textHeight / 2));
          }

          // Actual text
          ctx.fillStyle = fill;
          ctx.fillText(text[x], 0, (inwardFacing ? 1 : -1) * (0 - diameter / 2 + textHeight / 2));

          ctx.rotate((cw / 2 + charSpacing) / (diameter / 2 - textHeight) * clockwise); // rotate half letter
        }
        return canvas;
      },

      _set(key, value) {
        switch (key) {
          case 'scaleX':
            this.fontSize *= value;
            this.diameter *= value;
            this.width *= value;
            this.scaleX = 1;
            if (this.width < 1) { this.width = 1; }
            this.diameter = Math.ceil(this.diameter / 5) * 5;
            break;

          case 'scaleY':
            this.height *= value;
            this.scaleY = 1;
            if (this.height < 1) { this.height = 1; }
            break;

          default:
            this.callSuper('_set', key, value);
            break;
        }
      },

      _render(ctx) {
        const canvas = this.getCircularText();
        this.cropCanvas(canvas);

        this.set('width', canvas.width);
        this.set('height', canvas.height);

        ctx.drawImage(canvas, -this.width / 2, -this.height / 2, this.width, this.height);

        this.setCoords();
      },

      toObject(propertiesToInclude) {
        return this.callSuper('toObject', ['text', 'diameter', 'charSpacing', 'flipped', 'fill', 'fontFamily', 'fontSize', 'fontWeight', 'fontStyle', 'stroke', 'strokeWidth'].concat(propertiesToInclude));
      },
    });

    fabric.CurvedText.fromObject = function (object, callback, forceAsync) {
      return fabric.Object._fromObject('CurvedText', object, callback, forceAsync, 'curvedText');
    };

    fabric.Image.prototype.applyResizeFilters = function () {
      const elementToFilter = this._originalElement;
      let w; let
        h;

      if ((this.getScaledWidth() > MAX_DIMENSION || this.getScaledHeight() > MAX_DIMENSION)) {
        if (this.getScaledWidth() > this.getScaledHeight()) {
          w = MAX_DIMENSION;
          h = MAX_DIMENSION / this.getScaledWidth() * this.getScaledHeight();
        } else {
          w = MAX_DIMENSION / this.getScaledHeight() * this.getScaledWidth();
          h = MAX_DIMENSION;
        }
      } else {
        w = this.getScaledWidth();
        h = this.getScaledHeight();
      }

      w = Math.max(Math.floor(w), 15);
      h = Math.max(Math.floor(h), 15);
      const canvasEl = fabric.util.createCanvasElement();
      canvasEl.width = w;
      canvasEl.height = h;
      this.canvas.pica.resize(elementToFilter, canvasEl, {
        alpha: true,
        unsharpAmount: 0,
        unsharpRadius: 0,
        unsharpThreshold: 0,
        quality: 3,
      })
        .then((result) => {
          this._element = canvasEl;
          this._resizedElement = canvasEl;
          this._filterScalingX = canvasEl.width / this._originalElement.width;
          this._filterScalingY = canvasEl.height / this._originalElement.height;
          if (this.filters && this.filters.length > 0) {
            this.applyFilters();
          } else {
            this.canvas.requestRenderAll();
          }
        });
    };
    fabric.Image.prototype.applyFilters = function (filters) {
      filters = filters || this.filters || [];
      filters = filters.filter((filter) => filter);
      if (this.group) {
        this.set('dirty', true);
      }
      if (filters.length === 0) {
        this._element = this._originalElement;
        this._filteredEl = null;
        this._filterScalingX = 1;
        this._filterScalingY = 1;
        return this;
      }

      const imgElement = this._resizedElement || this._originalElement;
      const sourceWidth = imgElement.naturalWidth || imgElement.width;
      const sourceHeight = imgElement.naturalHeight || imgElement.height;
      const canvasEl = fabric.util.createCanvasElement();
      canvasEl.width = sourceWidth;
      canvasEl.height = sourceHeight;
      this._element = canvasEl;
      this._filteredEl = canvasEl;
      if (!fabric.filterBackend) {
        fabric.filterBackend = fabric.initFilterBackend();
      }
      fabric.filterBackend.applyFilters(filters, imgElement, sourceWidth, sourceHeight, this._element, this.cacheKey);
      if (this._originalElement.width !== this._element.width
        || this._originalElement.height !== this._element.height) {
        this._filterScalingX = this._element.width / this._originalElement.width;
        this._filterScalingY = this._element.height / this._originalElement.height;
      }
      return this;
    };

    // enable per pixel target find for every object, then disable it for types that dont
    fabric.Object.prototype.perPixelTargetFind = true;
    fabric.BackgroundBox.prototype.perPixelTargetFind = false;
    fabric.Text.prototype.perPixelTargetFind = false;

    fabric.PathGroup = { };
    fabric.PathGroup.fromObject = (fromObject, callback) => {
      const object = fromObject;
      const originalPaths = object.paths;
      delete object.paths;
      if (typeof originalPaths === 'string') {
        fabric.loadSVGFromURL(originalPaths, (elements) => {
          const pathUrl = originalPaths;
          const group = fabric.util.groupSVGElements(elements, object, pathUrl);
          group.type = 'group';
          object.paths = originalPaths;
          callback(group);
        });
      } else {
        fabric.util.enlivenObjects(originalPaths, (enlivenedObjects) => {
          enlivenedObjects.forEach((obj) => {
            obj._removeTransformMatrix();
          });
          const group = new fabric.Group(enlivenedObjects, object);
          group.type = 'group';
          object.paths = originalPaths;
          callback(group);
        });
      }
    };
  }

  /**
   * Customizes the fabric object prototype to provide sweet looking controls
   * @return {null}
   */
  setupControls() {
    const { fabric } = this;
    const customControls = {
      transparentCorners: false,
      cornerColor: 'white',
      cornerStrokeColor: 'black',
      borderColor: 'black',
      cornerSize: 12,
      padding: 10,
      cornerStyle: 'circle',
      borderDashArray: [3, 3],
    };
    Object.keys(customControls).forEach((property) => {
      fabric.Object.prototype[property] = customControls[property];
    });
  }

  loadBlank(width, height) {
    this.resize(width, height);
    this.addEditorObjects();
  }

  getCanvasAspect() {
    if (!this.canvas || !this.canvas.workingArea) {
      return 1.0;
    }

    const width = +this.canvas.workingArea.width;
    const height = +this.canvas.workingArea.height;

    if (height === 0.0) {
      return 1.0;
    }

    return (width / height);
  }

  getMetaData(object, key, value = null) {
    if (object === null) {
      object = this.canvas;
    }

    return MetaData.getMetaData(object, key, value);
  }

  setMetaData(object, key, value) {
    if (object === null) {
      object = this.canvas;
    }

    return MetaData.setMetaData(object, key, value);
  }

  getCanvasMetadata(key, value = null) {
    return this.getMetaData(null, key, value);
  }

  setCanvasMetadata(key, value) {
    return this.setMetaData(null, key, value);
  }

  getCanvasSport() {
    const categories = this.getMetaData(null, 'categories');

    return (
      categories
      && categories.length > 0
      && categories[0].name
    );
  }

  /**
   set working area.
   * */
  resize(width, height) {
    this.canvas.set({
      workingArea: {
        width,
        height,
      },
    });
    this._resize();
    this.canvas.renderAll();
  }

  /**
   * Resize the canvas to fill its parent.
   * @return {null}
   */
  _resize() {
    const { canvas } = this;
    if (canvas && canvas.wrapperEl && canvas.wrapperEl.parentNode) {
      canvas.setDimensions({
        width: canvas.wrapperEl.parentNode.clientWidth,
        height: canvas.wrapperEl.parentNode.clientHeight,
      });

      if (this.updateDimensions) {
        this.updateDimensions(
          canvas.workingArea.width,
          canvas.workingArea.height,
        );
      }
    }

    this.setOffsetAndZoom();
  }

  // set the current engine step.
  setStep = (step) => {
    this.canvas.step = step;
    this.canvas.trigger('stepChange');
  }

  /**
   * Sets the offset and draft vars of the canvas, used by custom elements to
   * create and position the interface.
   * @return {null}
   */
  setOffsetAndZoom() {
    const { canvas, viewPadding } = this;
    // need to get dom elements because zoom makes this suuuuuper weird
    const canvasWidth = canvas.upperCanvasEl.offsetWidth;
    const canvasHeight = canvas.upperCanvasEl.offsetHeight;
    let finalViewPadding = viewPadding;
    if (canvasWidth < 540 || canvasHeight < 400) {
      finalViewPadding = 12;
    }
    const { width, height } = canvas.workingArea;
    // determine base zoom level.
    const hMax = canvasWidth - (finalViewPadding * 2);
    const vMax = canvasHeight - (finalViewPadding * 2);
    const hScale = hMax / width;
    const vScale = vMax / height;
    canvas.baseZoom = (hScale < vScale ? hMax / width : vMax / height);

    // determine offsets
    canvas.horizOffset = (canvasWidth - (width * canvas.baseZoom)) / 2;
    canvas.vertOffset = (canvasHeight - (height * canvas.baseZoom)) / 2;
    canvas.scaledHorizOffset = ((canvasWidth / canvas.baseZoom) - width) / 2;
    canvas.scaledVertOffset = ((canvasHeight / canvas.baseZoom) - height) / 2;

    canvas.setViewportTransform([
      canvas.baseZoom,
      0,
      0,
      canvas.baseZoom,
      canvas.horizOffset,
      canvas.vertOffset,
    ]);
    canvas.trigger('scaled');
  }

  zoomIn = () => {
    let xMouse = 0;
    let yMouse = 0;
    const objects = this.canvas.getObjects();
    const activeObjects = this.canvas.getActiveObjects();

    // set the x/y axis of the object
    if (activeObjects) {
      if (activeObjects[0]) {
        if ((activeObjects[0].angle > 45 && activeObjects[0].angle < 135) || (activeObjects[0].angle > 225 && activeObjects[0].angle < 315)) {
          xMouse = (activeObjects[0].left + (activeObjects[0].height * activeObjects[0].scaleY)) * this.canvas.baseZoom;
          yMouse = (activeObjects[0].top + (activeObjects[0].width * activeObjects[0].scaleX)) * this.canvas.baseZoom;
        } else {
          xMouse = (activeObjects[0].left + (activeObjects[0].width * activeObjects[0].scaleX));
          yMouse = (activeObjects[0].top + (activeObjects[0].height * activeObjects[0].scaleY));
        }
      }
    }

    const zoom = this.canvas.getZoom();

    if (xMouse > this.canvas.width) {
      xMouse = this.canvas.width / 2;
    }
    if (yMouse > this.canvas.height) {
      yMouse = this.canvas.height / 2;
    }

    if (zoom < 1.8) {
      // create the coordinate point
      const zoomCenter = new fabric.Point(xMouse, yMouse);


      // set the coordinates on which we are going to zoom in
      this.canvas.absolutePan(zoomCenter);

      // zoom
      this.canvas.setZoom(zoom + 0.1);

      // manually setCoords for each object in the canvas
      objects.forEach((object) => {
        object.setCoords();
      });

      this._constrainViewport();
    }
  }

  zoomOut = () => {
    let xMouse = 0;
    let yMouse = 0;
    const activeObjects = this.canvas.getActiveObjects();

    if (activeObjects) {
      if (activeObjects[0]) {
        if ((activeObjects[0].angle > 45 && activeObjects[0].angle < 135) ||
          (activeObjects[0].angle > 225 && activeObjects[0].angle < 315)) {
          xMouse = (activeObjects[0].left
            + (activeObjects[0].height * activeObjects[0].scaleY)) * this.canvas.baseZoom;
          yMouse = (activeObjects[0].top
            + (activeObjects[0].width * activeObjects[0].scaleX)) * this.canvas.baseZoom;
        } else {
          xMouse = (activeObjects[0].left
            + (activeObjects[0].width * activeObjects[0].scaleX)) * this.canvas.baseZoom;
          yMouse = (activeObjects[0].top
            + (activeObjects[0].height * activeObjects[0].scaleY)) * this.canvas.baseZoom;
        }
      }
    }

    if (xMouse > this.canvas.width) {
      xMouse = this.canvas.width / 2;
    }
    if (yMouse > this.canvas.height) {
      yMouse = this.canvas.height / 2;
    }
    this.canvas.mouse = { x: xMouse, y: yMouse };

    if (this.canvas.getZoom() > this.canvas.baseZoom) {
      this.canvas.zoomToPoint(
        this.canvas.mouse,
        Math.max(
          this.canvas.getZoom() - 0.1,
          this.canvas.baseZoom,
        ),
      );
      this._constrainViewport();
    }
  }

  resetZoom = () => {
    this.canvas.setZoom(this.canvas.baseZoom);
    this._constrainViewport();
    this._resize();
  }

  bindEvents() {
    const self = this;
    // when the window resizes, we make sure that the canvas updates it's size to match.
    // your responsibility to keep the parent element the responsive size.
    window.onresize = () => {
      this._resize();
    };

    this.canvas.on('after:render', () => {
      // console.log('>>> canvas rendered');
    });

    this.canvas.on('widgets:loaded', () => {
      if (!this.updateObjects) {
        return;
      }

      this.updateObjects({ reason: 'widgets-loaded' });
    });

    this.canvas.on('object:added', (e) => {
      const { target } = e;

      if (target) {
        target.uuid = uuid();

        if (target.type === 'i-text') {
          target.lockUniScaling = true;
          const original_text = target.get('text');

          if (!MetaData.hasMetaData(target, 'original_text')) {
            this.setMetaData(target, 'original_text', original_text);
          }

          if (original_text.match(/[A-Z]/i) !== null) {
            if (original_text === original_text.toUpperCase()) {
              this.setMetaData(target, 'text_transform', 'upper');
            } else if (original_text === original_text.toLowerCase()) {
              this.setMetaData(target, 'text_transform', 'lower');
            }
          }
        }
      }
    });

    // this should not have to be done like this but it absolutely refuses
    // to stay "false" otherwise. some fairy is setting it to true somewhere
    // and I can't fucking find it. too bad!
    this.canvas.on('after:render', () => {
      this.canvas.getObjects('canvasTexture').forEach((texture) => {
        texture.evented = false;
      });
    });


    this.canvas.on('media:send', this.mediaSend);
    this.canvas.on('after:render', this.updateKeyFrames);
    this.canvas.on('selection:created', this.groupReduction);
    this.canvas.on('selection:created', this.updateActive);
    this.canvas.on('selection:created', this.unstickTextEdit);
    this.canvas.on('selection:updated', this.groupReduction);
    this.canvas.on('selection:updated', this.updateActive);
    this.canvas.on('selection:updated', this.unstickTextEdit);
    this.canvas.on('selection:cleared', this.updateActive);
    this.canvas.on('selection:cleared', this.unstickTextEdit);
    this.canvas.on('object:removed', this.updateActive);
    this.canvas.on('object:rotating', this.updateStateRotating);
    this.canvas.on('path:created', this.updateObjects);
    this.canvas.on('object:added', this.updateObjects);
    this.canvas.on('object:removed', this.updateObjects);
    this.canvas.on('object:modified', this.updateObjects);
    this.canvas.on('text:changed', this.updateTypes);
    this.canvas.on('text:changed', (e) => this.updateTextCase(e.target));
    this.canvas.on('object:modified', this.updateTypes);
    this.canvas.on('mouse:move', this._mouseMove);
    this.canvas.on('mouse:down', this._mouseDown);
    this.canvas.on('mouse:up', this._mouseUp);
    this.canvas.on('mouse:over', () => { this.mouseIsOver = true; });
    this.canvas.on('mouse:out', () => { this.mouseIsOver = false; });
    this.canvas.on('mouse:wheel', (e) => {
      if (!this.canvas.mouse) {
        this.canvas.mouse = { x: 0, y: 0 };
      }
      const delta = e.e.deltaY;
      let zoom = this.canvas.getZoom();
      zoom += delta / 2000;
      if (zoom > 5) zoom = 5;
      if (zoom < this.canvas.baseZoom) zoom = this.canvas.baseZoom;

      // we get all of the objects in the canvas
      const objects = this.canvas.getObjects();


      // zoom
      this.canvas.zoomToPoint(this.canvas.mouse, zoom);

      // manually set coordinates of each object after zoom in/out
      objects.forEach((object) => {
        object.setCoords();
      });

      // set viewport
      this._constrainViewport();
    });
    this.canvas.on('touch:gesture', (e) => {
      if (e.e.touches && e.e.touches.length >= 2) {
        e.e.preventDefault();
        e.e.stopPropagation();
        this._constrainViewport();
      }
    });
    this.canvas.on('after:render', this._afterRender);
    this.canvas.on('object:modified', this.handleModification);
    this.canvas.on('object:added', this.handleAddition(this));
    this.canvas.on('object:removed', (e) => { this.handleModification(e, true); });
    this.canvas.on('object:modified', this._calcGridLines);
    this.canvas.on('object:moving', this._objectMoving);
    this.canvas.on('object:added', () => this.moveGridObject());
    this.canvas.on('object:added', (e) => this.dirtyCanvas(e));
    this.canvas.on('object:removed', (e) => this.dirtyCanvas(e));
    this.canvas.on('object:modified', (e) => this.dirtyCanvas(e));
    this.canvas.on('before:render', this._drawFoulBoxes);

    document.onkeydown = (evt) => {
      self._handleKeys(self, evt);
    };
  }

  groupReduction = (e) => {
    const atlas = {
      templates: [],
      background: [],
      palette: ['rect', 'polygon', 'polyline', 'circle'],
      logos: ['image'],
      cutouts: ['cutouts', 'image'],
      text: ['i-text'],
      effects: [],
    };

    if (e.target && e.target.type === 'activeSelection') {
      const oldObjects = [...e.target.getObjects()];
      for (let i = 0; i < oldObjects.length; i++) {
        const object = oldObjects[i];

        if (atlas[this.canvas.step] && atlas[this.canvas.step].indexOf(object.type) === -1) {
          e.target.removeWithUpdate(object);
        } else if (object.type === 'image' && object.parent && object.parent.type === 'backgroundBox') {
          e.target.removeWithUpdate(object);
        }
      }

      if (e.target.getObjects().length === 0) {
        this.canvas.discardActiveObject();
        this.canvas.trigger('selection:cleared');
      }
    }
  }

  unstickTextEdit = (e) => {
    this.canvas.getObjects('i-text').forEach((obj) => {
      if (obj.isEditing) {
        obj.exitEditing();
      }
    });
  }

  _calcGridLines = () => {
    this._clearGridLines();
    // objects excluded from grid lines.
    const hideTypes = [
      'logoBox',
      'cutoutBox',
      'backgroundBox',
    ];
    // run through all objects
    const gridCoords = {
      x: {},
      y: {},
    };

    gridCoords.x[0] = [this.canvas];
    gridCoords.x[this.canvas.workingArea.width / 2] = [this.canvas];
    gridCoords.x[this.canvas.workingArea.width] = [this.canvas];
    gridCoords.y[0] = [this.canvas];
    gridCoords.y[this.canvas.workingArea.height / 2] = [this.canvas];
    gridCoords.y[this.canvas.workingArea.height] = [this.canvas];

    this.canvas.getObjects().forEach((object) => {
      if (hideTypes.indexOf(object.type) === -1) {
        const bound = object.getBoundingRect(true);
        let xCoords = [
          bound.left + (bound.width / 2),
        ];
        if (bound.width > 30) {
          xCoords = [
            bound.left,
            bound.left + (bound.width / 2),
            bound.left + bound.width,
          ];
        }
        let yCoords = [
          bound.top + (bound.height / 2),
        ];
        if (bound.height > 30) {
          yCoords = [
            bound.top,
            bound.top + (bound.height / 2),
            bound.top + bound.height,
          ];
        }
        xCoords.forEach((coord) => {
          if (gridCoords.x[coord]) {
            gridCoords.x[coord].push(object);
          } else {
            gridCoords.x[coord] = [
              object,
            ];
          }
        });
        yCoords.forEach((coord) => {
          if (gridCoords.y[coord]) {
            gridCoords.y[coord].push(object);
          } else {
            gridCoords.y[coord] = [
              object,
            ];
          }
        });
      }
    });
    this.canvas.gridCoords = gridCoords;
  }

  _objectMoving = (e) => {
    const { canvas } = this;
    let leniency = 12;
    if (e.e.ctrlKey) {
      leniency = 0;
    }
    const object = e.target;
    // ignore all of this if the object isn't ready.
    if (object.gridLineStatus === 1) {
      return;
    } if (!object.gridLineStatus || object.gridLineStatus === 0) {
      object.gridLineStatus = 1;
      setTimeout(() => {
        object.gridLineStatus = 2;
      }, 500);
      return;
    }
    object.setCoords();
    const bound = object.getBoundingRect(true);
    const xCoords = [
      bound.left,
      bound.left + (bound.width / 2),
      bound.left + bound.width,
    ];
    const yCoords = [
      bound.top,
      bound.top + (bound.height / 2),
      bound.top + bound.height,
    ];
    // look through all x coords for leniency.
    let snappedX = false;
    let snappedY = false;
    this._clearGridLines();

    if (this.grid_snap) {
      this._snapGrid(object, (Math.round(xCoords[0] / 8.0) * 8.0), true, 0);
      this._snapGrid(object, (Math.round(yCoords[0] / 8.0) * 8.0), false, 0);
    } else {
      xCoords.forEach((coord, index) => {
        const keys = Object.keys(canvas.gridCoords.x);
        keys.forEach((keyCoord) => {
          const indexOf = canvas.gridCoords.x[keyCoord].indexOf(object);
          if (
            Math.abs(coord - keyCoord) < leniency
            && (
              indexOf === -1
              || canvas.gridCoords.x[keyCoord].length > 1
            )
          ) {
            if (!snappedX) {
              this._snapGrid(
                object,
                keyCoord,
                true,
                index,
              );
              snappedX = true;
              this._drawGridLine(
                object,
                keyCoord,
                true,
                this.canvas.gridCoords.x[keyCoord],
              );
            }
          }
        });
      });

      yCoords.forEach((coord, index) => {
        const keys = Object.keys(canvas.gridCoords.y);
        keys.forEach((keyCoord) => {
          const indexOf = canvas.gridCoords.y[keyCoord].indexOf(object);
          if (
            Math.abs(coord - keyCoord) < leniency
            && (
              indexOf === -1
              || canvas.gridCoords.y[keyCoord].length > 1
            )
          ) {
            if (!snappedY) {
              this._snapGrid(
                object,
                keyCoord,
                false,
                index,
              );
              snappedY = true;
              this._drawGridLine(
                object,
                keyCoord,
                false,
                this.canvas.gridCoords.y[keyCoord],
              );
            }
          }
        });
      });
    }
  }

  /**
   * transform the svg object in each canvas to a png
   *
   * @param {*} json not a json (?
   * @returns
   */
  convertSlideshowSVGToPng = async (json) => {
    const { fabric } = this;
    try {
      let uploading = false;
      return new Promise((resolve) => {
        if (json.data.length === 1) {
          resolve();
        }
        json.data.forEach((slide, index) => {
          if (slide.canvas.objects) {
            slide.canvas.objects.forEach(async (object, objectIndex) => {
              if (object.type === 'path') {

                const canvas = new fabric.Canvas('c');

                let pathString = '';
                object.path.forEach((path) => {
                  path.forEach((p) => {
                    pathString += `${p} `;
                  });
                });


                let vbValues = pathString.split(/[a-zA-Z]/).join(' ').split(' ')

                let min = Math.min.apply( Math, vbValues );

                const offScrenCanvas = new OffscreenCanvas(this.canvas.width, this.canvas.height);
                const offScreenCtx = offScrenCanvas.getContext('2d');

                let x = (object.height/object.width) >= 1.5 ? min : min * object.scaleX

                if (object.name === "Checkmark")
                  x = 0



                const str = `<svg style="fill: ${object.fill}; stroke: ${object.stroke}; stroke-width: ${object.strokeWidth};" 
                      preserveAspectRatio="xMidYMid meet" x="${x}" y="0">
                        <path d="${pathString}"/>
                    </svg>`;
                // we convert the svg to png in the new canvas
                const png = await Canvg.from(offScreenCtx, str);
                // we render the canvas

                png.start();
                uploading = true;
                offScrenCanvas.convertToBlob().then((blob) => {
                  const reader = new FileReader();
                  reader.readAsDataURL(blob);
                  reader.onload = () => {
                    fabric.Image.fromURL(reader.result, (newImg) => {
                      canvas.add(newImg);
                      const newObjects = canvas.getObjects();

                      // set position of the img
                      newObjects.forEach((obj) => {
                        if (obj === newImg) {
                          obj.set({
                            ...object,
                            stroke: null,
                            strokeWidth: null,
                            crossOrigin: 'anonymous',
                            height: object.height * 1.3,
                            width: object.width * 1.3,
                            scaleX: object.scaleX,
                            scaleY: object.scaleY
                          });

                          obj.setCoords();
                        }
                        json.data[index].canvas.objects[objectIndex] = canvas.toJSON().objects[0];
                        uploading = false;
                        if (index === json.data.length - 2 && objectIndex === slide.canvas.objects.length - 1) {
                          resolve();
                        }
                      });
                    }, { crossOrigin: 'anonymous' });
                  };
                });
              } else if (index === json.data.length - 2 && objectIndex === slide.canvas.objects.length - 1 && uploading === false) {
                resolve();
              }
            });
          } else {
            let hasPath = 0;

            json.data.forEach((obj) => {
              if (obj.canvas.objects !== undefined) {
                const pathArray = obj.canvas.objects.filter((el) => el.type === 'path');
                if (pathArray.length === 0) {
                  hasPath++;
                }
              } else {
                hasPath++;
              }
            });

            if (hasPath === json.data.length) {
              resolve();
            }
          }
        });
      });
    } catch (ex) {
      console.log(ex);
    }
  }

  /**
   *
   * convert all svg paths in the canvas to img
   */
  convertSvgToPng = async () => new Promise((resolve, reject) => {
    try {
      const { canvas } = this;
      const objects = canvas.getObjects();
      const pathObjects = objects.filter((el) => el.type === 'path');
      if (pathObjects.length < 1) {
        return resolve();
      }
      pathObjects.forEach(async (el, i) => {
        let pathstring = '';

        canvas.remove(el);

        el.path.forEach((path) => {
          // let tempPathString = ''
          path.forEach((p) => {
            pathstring += `${p} `;
          });
        });


        let vbValues = pathstring.split(/[a-zA-Z]/).join(' ').split(' ')

        let min = Math.min.apply( Math, vbValues );

        const offScrenCanvas = new OffscreenCanvas(this.canvas.width, this.canvas.height);
        const offScreenCtx = offScrenCanvas.getContext('2d');

        let x = (el.height/el.width) >= 1.5 ? min : min * el.scaleX

        if (el.name === "Checkmark")
          x = 0

        const str = `<svg style="fill: ${el.fill}; stroke: ${el.stroke}; stroke-width: ${el.strokeWidth};" 
                      preserveAspectRatio="xMidYMid meet" x="${x}" y="0">
                        <path d="${pathstring}"/>
                    </svg>`;
        // we convert the svg to png in the new canvas
        const png = await Canvg.from(offScreenCtx, str);
        // we render the canvas

        offScreenCtx.scale(1, 1);

        png.start();

        // we transform the new canvas to a fabric img, and then we add it to the original canvas

        offScrenCanvas.convertToBlob().then((blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = () => {
            fabric.Image.fromURL(reader.result, (newImg) => {

              canvas.add(newImg);
              const newObjects = canvas.getObjects();

              // set position of the img
              newObjects.forEach((obj) => {
                if (obj === newImg) {
                  obj.set({
                    ...el,
                    stroke: null,
                    strokeWidth: null,
                    crossOrigin: 'anonymous',
                    height: el.height * 1.3,
                    width: el.width * 1.3,
                    scaleX: el.scaleX,
                    scaleY: el.scaleY
                  });

                  obj.setCoords();
                }
                if (i === pathObjects.length - 1) {
                  canvas.requestRenderAll();
                  return resolve();
                }
              });
            }, { crossOrigin: 'anonymous' });
          };
        });
      });
    } catch (ex) {
      reject(ex);
    }
  })

  _clearGridLines = () => {
    this.canvas.clearContext(this.canvas.getSelectionContext());
  }

  _drawGridLine = (object, coordinate, vertical) => {
    const { canvas } = this;
    const ctx = canvas.getSelectionContext();
    let start;
    let end;
    if (vertical) {
      coordinate = (coordinate * canvas.getZoom()) + canvas.viewportTransform[4];
      start = [coordinate, 0];
      end = [coordinate, ctx.canvas.height];
    } else {
      coordinate = (coordinate * canvas.getZoom()) + canvas.viewportTransform[5];
      start = [0, coordinate];
      end = [ctx.canvas.width, coordinate];
    }
    ctx.save();
    ctx.strokeStyle = '#ff48ff';
    ctx.beginPath();
    ctx.moveTo(start[0], start[1]);
    ctx.lineTo(end[0], end[1]);
    ctx.stroke();
    ctx.restore();
  }

  _drawFoulBoxes = () => {
    const objects = this.canvas.getObjects();

    if (objects.some((object) => object.isMoving)) {
      return;
    }

    this._clearGridLines();

    objects.filter((object) => {
      if (!this.canvas.workingArea.width) {
        return false;
      }

      if (!this.canvas.workingArea.height) {
        return false;
      }

      const rect = object.getBoundingRect(true);

      if ((rect.left + rect.width) <= 0.0) {
        return true;
      }

      if ((rect.top + rect.height) <= 0.0) {
        return true;
      }

      if (rect.left >= +this.canvas.workingArea.width) {
        return true;
      }

      if (rect.top >= +this.canvas.workingArea.height) {
        return true;
      }

      return false;
    }).forEach((object) => {
      this._drawFoulBox(object);
    });
  }

  _drawFoulBox = (object) => {
    const rect = object.getBoundingRect(false);
    const ctx = this.canvas.getSelectionContext();

    ctx.save();
    ctx.strokeStyle = '#ff0000';
    ctx.setLineDash([3, 3]);
    ctx.beginPath();
    ctx.rect(rect.left, rect.top, rect.width, rect.height);
    ctx.stroke();
    ctx.restore();
  }

  _snapGrid = (object, coordinate, vertical, index) => {
    let keyProp = 'top';
    let keyAttribute = 'height';
    if (vertical) {
      keyProp = 'left';
      keyAttribute = 'width';
    }
    const bound = object.getBoundingRect(true);
    const coords = [
      bound[keyProp],
      bound[keyProp] + (bound[keyAttribute] / 2),
      bound[keyProp] + bound[keyAttribute],
    ];
    const diff = coords[index] - coordinate;
    object.set(keyProp, object[keyProp] - diff);
  }

  updateKeyFrames = (e) => {
    if (this.canvas.step !== 'admin') {
      if (this && this.canvas && this.canvas.canvasState && this.canvas.canvasState[0] && this.canvas.canvasState[0].objects) {
        if (this.canvas && this.canvas.getActiveObject() && !this.canvas.originalState) {
          this.canvas.originalState = JSON.parse(JSON.stringify(this.canvas.canvasState[this.canvas.canvasState.length - 1].objects));
        }
        if (this.canvas && this.canvas.getActiveObject() && this.canvas.getActiveObject().animation && this.canvas.getActiveObject().animation.animations) {
          const { originalState } = this.canvas;
          const activeObject = this.canvas.getActiveObject();
          originalState.forEach((orgObj) => {
            if (orgObj === activeObject) {
              const topDiff = orgObj.top - activeObject.top;
              const leftDiff = orgObj.left - activeObject.left;
              let fontSizeNew;
              if (activeObject.fontSize === orgObj.fontSize) {
                fontSizeNew = activeObject.fontSize;
              } else {
                fontSizeNew = activeObject.fontSize;
              }
              let widthDiff;
              if (activeObject.width === orgObj.width) {
                widthDiff = (activeObject.width / orgObj.width);
              } else {
                widthDiff = (activeObject.width / orgObj.width);
              }
              const scaleXDiff = (activeObject.scaleX / orgObj.scaleX);
              const scaleYDiff = (activeObject.scaleY / orgObj.scaleY);
              if (activeObject && activeObject.animation && activeObject.animation.animations) {
                if (orgObj && orgObj.animation && orgObj.animation.animations) {
                  let animCount = 0;
                  let wtfIsThisNumber;

                  activeObject.animation.animations.forEach((actAnim) => {
                    actAnim.fontSize = fontSizeNew;
                    actAnim.scaleY = orgObj.animation.animations[animCount].scaleY * scaleYDiff;
                    wtfIsThisNumber = ((actAnim.scaleY * actAnim.fontSize * 1.13) / (orgObj.animation.animations[animCount].scaleY * 40 * 1.13));
                    actAnim.top = orgObj.animation.animations[animCount].top - (topDiff * (1 - wtfIsThisNumber));
                    actAnim.scaleX = orgObj.animation.animations[animCount].scaleX * scaleXDiff;
                    actAnim.left = orgObj.animation.animations[animCount].left - leftDiff;
                    actAnim.width = orgObj.animation.animations[animCount].width * widthDiff;
                    actAnim.height = actAnim.fontSize * 1.13;
                    animCount++;
                  });
                }
              }
            }
          });
        }
      }
    }
  }

  updateTextCase = (object) => {
    if (!object) {
      return;
    }

    const text_transform = this.getMetaData(object, 'text_transform');

    if (text_transform !== null) {
      switch (text_transform) {
        case 'upper': {
          object.set('text', object.get('text').toUpperCase());
          break;
        }
        case 'lower': {
          object.set('text', object.get('text').toLowerCase());
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  updateTypes = (e) => {
    const object = e.target;
    if (object && object.specialObject) {
      if (!this.specialObject) {
        this.specialObject = {};
      }
      if (common[object.type][object.specialObject]) {
        const props = {};
        const objectInfo = common[object.type][object.specialObject];
        objectInfo.props.forEach((prop) => {
          if (prop === 'image' && object.media) {
            if (object.media.src) {
              props[prop] = object.media.src;
            } else if (object.media.element) {
              props[prop] = object.media.element.getSrc();
            }
          } else {
            props[prop] = object[prop];
          }
        });
        this.specialObject[object.specialObject] = props;
      }
    }
  }


  _mouseDown = (e) => {
    const evt = e.e;
    const { canvas } = this;
    if (evt.altKey === true) {
      canvas.isDragging = true;
      canvas.selection = false;
      canvas.lastPosX = evt.clientX;
      canvas.lastPosY = evt.clientY;
    }
  }

  _mouseUp = () => {
    this.canvas.isDragging = false;
    this.canvas.selection = true;
  }

  _mouseMove = (e) => {
    const { canvas } = this;
    if (canvas.isDragging) {
      const evt = e.e;
      canvas.viewportTransform[4] += evt.clientX - canvas.lastPosX;
      canvas.viewportTransform[5] += evt.clientY - canvas.lastPosY;
      this._constrainViewport();
      canvas.requestRenderAll();
      canvas.lastPosX = evt.clientX;
      canvas.lastPosY = evt.clientY;
    }
    canvas.mouse = { x: e.e.offsetX, y: e.e.offsetY };
  }

  _constrainViewport = () => {
    const { canvas } = this;
    if (canvas.viewportTransform[4] > canvas.horizOffset) {
      canvas.viewportTransform[4] = canvas.horizOffset;
    }
    if (canvas.viewportTransform[5] > canvas.vertOffset) {
      canvas.viewportTransform[5] = canvas.vertOffset;
    }
    if (
      ((canvas.workingArea.width * canvas.getZoom())
        + canvas.viewportTransform[4]
        + canvas.horizOffset)
      < canvas.getWidth()
    ) {
      canvas.viewportTransform[4] = (
        canvas.getWidth() - canvas.horizOffset
        - (canvas.workingArea.width * canvas.getZoom())
      );
    }
    if (
      ((canvas.workingArea.height * canvas.getZoom())
        + canvas.viewportTransform[5]
        + canvas.vertOffset)
      < canvas.getHeight()
    ) {
      canvas.viewportTransform[5] = (
        canvas.getHeight() - canvas.vertOffset
        - (canvas.workingArea.height * canvas.getZoom())
      );
    }
    canvas.setViewportTransform(canvas.viewportTransform);
  }

  _handleKeys = (self, e) => {
    if (document.activeElement && document.activeElement !== document.getElementsByTagName('body')[0]) {
      return;
    }

    switch (e.keyCode) {
      case 187:
        if (e.shiftKey) {
          this.zoomIn();
        }
        break;
      case 189:
        this.zoomOut();
        break;
      case 48:
        if (e.metaKey || e.ctrlKey) {
          this.resetZoom();
        }
        break;
      case 37:
        // left
        this.nudge(e.shiftKey ? -10 : -1, 0);
        break;
      case 38:
        // up
        this.nudge(0, e.shiftKey ? -10 : -1);
        break;
      case 39:
        // right
        this.nudge(e.shiftKey ? 10 : 1, 0);
        break;
      case 40:
        // down
        this.nudge(0, e.shiftKey ? 10 : 1);
        break;
      case 8: // delete key on mac / backspace on windows
      case 46:
        // delete
        // TODO: Make sure they're not deleting anything important.
        const deletingItems = this.canvas.getActiveObjects();

        if (deletingItems === null || deletingItems.length === 0) {
          break;
        }

        if (deletingItems.length === 1) {
          const deletingItem = deletingItems[0];

          if ((deletingItem.type === 'i-text' || deletingItem.type === 'text') && deletingItem.isEditing) {
            break;
          }
        }

        e.preventDefault();
        deletingItems.forEach((obj) => this.canvas.remove(obj));
        this.canvas.discardActiveObject();
        break;
      case 89:
        if (e.metaKey || e.ctrlKey) {
          e.preventDefault();
          self.redo();
        }
        break;
      case 90:
        if (e.metaKey || e.ctrlKey) {
          e.preventDefault();
          self.undo();
        }
        break;
      case 67:
        if (e.metaKey || e.ctrlKey) {
          e.preventDefault();
          self.copy();
        }
        break;
      case 86:
        if (e.metaKey || e.ctrlKey) {
          e.preventDefault();
          self.paste();
        }
        break;
      default:
        break;
    }
  }

  _undoSnapshot = () => {
    const { canvas } = this;
    if (canvas.canvasState.length > 10) {
      canvas.canvasState.shift();
    }
    canvas.redoState = [];
    canvas.canvasState.push(this.toJSON());
  }

  undo = () => {
    const self = this;
    const { canvasState } = this.canvas;
    if (canvasState.length > 1) {
      const currentState = canvasState.pop();
      this._addToRedo(currentState);
      const newState = canvasState[canvasState.length - 1];
      this.loadFromJSON(newState, () => {
        self.canvas.renderAll();
      }, () => {}, true, false);
    }
  }

  redo = () => {
    const self = this;
    const { redoState, canvasState } = this.canvas;
    if (redoState.length > 0) {
      const newState = redoState.pop();
      canvasState.push(newState);
      this.loadFromJSON(newState, () => {
        self.canvas.renderAll();
      }, () => {}, true, false);
    }
  }

  clearStateHistory = () => {
    let { redoState, canvasState } = this.canvas;
    if(redoState === undefined)
      redoState = []
    else
      redoState.length = 0;
    canvasState.length = 0;
  }

  _addToRedo = (state) => {
    if (!this.canvas.redoState) {
      this.canvas.redoState = [
        state,
      ];
    } else {
      this.canvas.redoState.push(state);
    }
  }

  _afterRender = () => {
    if (this.canvas.renderMode !== true) {
      // draw the background that surrounds the working area.
      const { canvas } = this;
      const ctx = canvas.getContext();
      if (this.canvas.preview === true) {
        ctx.fillStyle = 'rgba(245,245,245,1)';
      } else {
        ctx.fillStyle = 'rgba(245,245,245,.9)';
      }
      // force opaque for now
      ctx.fillStyle = 'rgba(245,245,245,1)';
      const xInnerMin = Math.max(0, canvas.viewportTransform[4] + 1);
      const yInnerMin = Math.max(0, canvas.viewportTransform[5] + 1);
      const xInnerMax = Math.min(
        canvas.width,
        (canvas.workingArea.width * canvas.getZoom())
        + canvas.viewportTransform[4],
      );
      const yInnerMax = Math.min(
        canvas.height,
        (canvas.workingArea.height * canvas.getZoom())
        + canvas.viewportTransform[5],
      );
      const coords = {
        tol: { x: 0, y: 0 },
        tor: { x: canvas.width, y: 0 },
        bor: { x: canvas.width, y: canvas.height },
        bol: { x: 0, y: canvas.height },
        til: { x: xInnerMin, y: yInnerMin },
        tir: { x: xInnerMax, y: yInnerMin },
        bir: { x: xInnerMax, y: yInnerMax },
        bil: { x: xInnerMin, y: yInnerMax },
      };


      ctx.beginPath();
      // draw the outside rectangle
      ctx.moveTo(coords.tol.x, coords.tol.y);
      ctx.lineTo(coords.tor.x, coords.tor.y);
      ctx.lineTo(coords.bor.x, coords.bor.y);
      ctx.lineTo(coords.bol.x, coords.bol.y);
      ctx.closePath();
      // draw the inside visible area.
      ctx.moveTo(coords.til.x, coords.til.y);
      ctx.lineTo(coords.tir.x, coords.tir.y);
      ctx.lineTo(coords.bir.x, coords.bir.y);
      ctx.lineTo(coords.bil.x, coords.bil.y);
      ctx.closePath();
      ctx.mozFillRule = 'evenodd'; // for old firefox 1~30
      ctx.fill('evenodd'); // for firefox 31+, IE 11+, chrome
      canvas.drawControls(ctx);
    }
  }

  nudge = (left, top) => {
    if (this.canvas && this.canvas.step !== 'admin') {
      if (
        (this.permissions === null)
        || (this.permissions.get('editor:lock_movement'))
      ) {
        return;
      }
    }

    if (this.mouseIsOver && this.canvas.getActiveObject()) {
      const active = this.canvas.getActiveObject();
      if (left !== 0) {
        active.set('left', Math.floor(active.left + left));
      }
      if (top !== 0) {
        active.set('top', Math.floor(active.top + top));
      }
      this.canvas.trigger('object:modified');
      this.canvas.requestRenderAll();
    }
  }

  handleAddition = (self) => (e) => {
    const object = e.target;
    const { canvas } = self;
    // add to undo redo history
    if (!canvas.ignoreUndo && !object.parent) {
      this._undoSnapshot();
    }
  }

  handleModification = (e, fromRemove = false) => {
    const self = this;
    const object = e.target;
    let eventIgnoreUndo = false;
    if (e.ignoreUndo) {
      eventIgnoreUndo = e.ignoreUndo;
    }
    // for grid lines, we say that modification is done (resetting a timer)
    if (object) {
      object.gridLineStatus = 0;
    }

    const { canvas } = self;
    if (object && object.ss_special_object !== 'polygon_box' && !fromRemove) {
      // don't scale shapes, for stroke reasons.
      // boost their size internally.
      if (object.type === 'rect' || object.type === 'triangle') {
        const center = object.getCenterPoint();
        object.set({
          width: object.width * object.scaleX,
          height: object.height * object.scaleY,
          scaleX: 1,
          scaleY: 1,
        });
        object.setPositionByOrigin(center, 'center', 'center');
        object.setCoords();
      }
      if (object.type === 'polygon' && (object.scaleX !== 1 || object.scaleY !== 1)) {
        const new_points = object.points.map((point) => ({
          x: (point.x * object.scaleX),
          y: (point.y * object.scaleY),
        }));

        object.points = new_points;
        object.scaleX = 1.0;
        object.scaleY = 1.0;

        const aabb = object._calcDimensions();
        object.width = aabb.width;
        object.height = aabb.height;
        object.pathOffset = {
          x: (aabb.left + object.width / 2),
          y: (aabb.top + object.height / 2),
        };

        object.dirty = true;
        canvas.requestRenderAll();
      }
    }

    // add to undo redo history
    if (!canvas.ignoreUndo && !eventIgnoreUndo) {
      self._undoSnapshot();
    }
  }

  getWorkingArea = () => this.canvas.workingArea

  addEditorObjects() {
    const { canvas } = this;
    if (canvas.getObjects('backgroundBox').length === 0) {
      canvas.add(new fabric.BackgroundBox([
        { x: 0, y: 0 },
        { x: canvas.workingArea.width, y: 0 },
        { x: canvas.workingArea.width, y: canvas.workingArea.height },
        { x: 0, y: canvas.workingArea.height },
      ]));
    }
  }

  refreshBackgrounds() {
    const { canvas } = this;
    canvas.getObjects('backgroundBox').forEach((bg) => {
      bg.refreshMedia(canvas);
    });
  }

  getGridSnap = () => this.grid_snap

  setGridSnap = (snap) => {
    this.grid_snap = snap;

    if (snap && !this.grid_enable) {
      this.setGridEnable(true);
    }
  }

  getGridEnable = () => this.grid_enable

  setGridEnable = (enable) => {
    this.grid_enable = enable;

    if (!enable) {
      this.grid_snap = false;
    }

    if (this.grid_object === null) {
      this.loadGridObject();
    } else {
      this.moveGridObject();
    }
  }

  loadGridObject = () => {
    const { fabric, canvas } = this;

    const fill = new fabric.Pattern({
      source: 'https://ss3-assets.s3.us-east-2.amazonaws.com/graphics/grid_8px.png',
      crossOrigin: 'anonymous',
      repeat: 'repeat',
    }, () => {
      this.grid_object = new fabric.Rect({
        left: 0,
        top: 0,
        visible: this.grid_enable,
        selectable: false,
        opacity: 0.5,
        width: +canvas.workingArea.width,
        height: +canvas.workingArea.height,
        fill,
      });

      this.grid_object.evented = false;
      canvas.add(this.grid_object);
      this.moveGridObject();
    });
  }

  moveGridObject = () => {
    if (this.grid_object !== null) {
      this.canvas.moveTo(this.grid_object, this.canvas.getObjects().length);
      this.grid_object.set('visible', this.grid_enable);
      this.canvas.renderAll();
    }
  }

  showEditorObjects(flag) {
    const { canvas } = this;

    const hideTypes = [
      'logoBox',
      'cutoutBox',
      'backgroundBox',
      'widget',
      'widgetLayout',
      'widget-box',
      'widget-piece',
    ];

    canvas.getObjects().forEach((object) => {
      if (
        hideTypes.indexOf(object.type) !== -1
      ) {
        if (object.type !== 'backgroundBox' || object.fillType === 'none') {
          object.set('visible', flag);
        }
      }

      // also hide canvas videos.
      if (
        object.type === 'backgroundBox'
        && object.media
        && object.media.type === 'video'
        && object.media.element
      ) {
        object.media.element.set('visible', flag);
      }
    });

    if (this.grid_object !== null) {
      this.grid_object.set('visible', (flag && this.grid_enable));
    }

    canvas.requestRenderAll();
  }

  generateSlidePreview = (slide, colorReplacement = true) => new Promise((resolve, reject) => {
    this.loadFromJSON(slide.canvas, async () => {
      this._resize();
      this.canvas.renderAll();
      const png = this.toDataURL();
      const jpg = this.toDataURL({ format: 'jpeg' });
      slide.preview = { png, jpg };

      resolve();
    }, () => {}, false, colorReplacement);
  })

  getImgSize = (img) => {
    return new Promise ((resolved) => {
      let image = new Image()
      image.onload = function(){
        resolved({w: image.width, h: image.height})
      };
      image.src = img
    })
  }

  loadFromTemplate(template, slideshow, callback, colorReplacement = true, custom) {
    const self = this;
    const cacheBuster = (+new Date());
    self.canvas.set({
      workingArea: {
        width: template.width,
        height: template.height,
      },
    });

    const delete_key = !self.admin_editor;
    fetchTemplate(template, custom).then((json) => {
      const canvas = json;
      if (slideshow) {
        const promises = [];

        json.data.forEach((canvas) => {
          canvas.canvas.objects.forEach((object) => {
            if (object.media !== null && object.media !== undefined) {
              if(object.media.src !== null){
                object.media.src += `?${cacheBuster}`;
              }
            }
          })
        })

        for (let i = (json.data.length - 1); i >= 0; --i) {
          RenderJob.remapCanvasColors(
            json.data[i].canvas,
            (colorReplacement ? this.canvas.colors : null),
            null, delete_key,
          );
          if(template.isQuickCreate !== true || !json.data[i].preview){
            promises.push(
              self.generateSlidePreview(json.data[i], colorReplacement),
            )
          }

        }

        Promise.all(promises).then(() => {
          self.loadFromJSON(json.data[0].canvas, () => {
            self._resize();
            self.canvas.renderAll();
            (callback && callback(json));
          }, () => {}, false, colorReplacement);
        });
      } else {
        if (json.objects) {
          json.objects.forEach((object) => {
            if (object.hasOwnProperty('media')) {
              object.media.src += `?${cacheBuster}`;
            }
          });
        }

        if (canvas && canvas.objects) {
          canvas.objects.forEach((object) => {
            if (object.type === 'i-text' && object.fontFamily === null) {
              object.fontFamily = '';
            }
          })
        }

        self.loadFromJSON(canvas, () => {
          self._resize();

          if (callback) {
            callback(json);
          }

          self.canvas.renderAll();
        }, () => {}, false, colorReplacement);
      }
    });
  }

  loadOldTemplate = (id) => {
    const self = this;

    fetchOldTemplate(id).then((json) => {
      self.loadFromJSON(json, () => {
        this.necromancer();
        self.canvas.renderAll();
      });
    });
  }



  doLoadFromDraft = (json, draft, slideshow, callback) => {
    if (slideshow) {
      let skip = false;

      for (let i = (json.data.length - 1); i >= 0; --i) {
        if (!json.data[i].canvas.objects) {
          json.data[i].canvas.objects = [];
        }

        if (json.data[i].preview) {
          continue;
        }

        this.generateSlidePreview(json.data[i], false);

        if (i === 0) {
          skip = true
        }
      }

      if (!skip) {
        this.loadFromJSON(json.data[0].canvas, () => {
          this._resize();
          this.canvas.renderAll();
          (callback && callback(json));
        }, () => {}, false, false);
      } else {
        (callback && callback(json));
      }
    } else {
      this.loadFromJSON(json, () => {
        this._resize();
        this.canvas.renderAll();
        (callback && callback(json));
      }, () => {}, false, false);
    }
  }

  loadFromDraft(draft, slideshow, callback = () => {}) {
    const self = this;

    self.canvas.set({
      workingArea: {
        width: draft.width,
        height: draft.height,
      },
    });

    const cacheBuster = (+new Date());
    const clientId = localStorage.getItem('client_id');

    const new_url = (
      slideshow
        ? `https://s3.us-east-2.amazonaws.com/${draft.bucket}/${clientId}/${draft.id}.json?${cacheBuster}`
        : `https://${draft.bucket}.s3.us-east-2.amazonaws.com/${clientId}/${draft.id}.json?${cacheBuster}`
    )

    const old_url = (
      slideshow
        ? `https://s3.us-east-2.amazonaws.com/${draft.bucket}/${draft.id}.json?${cacheBuster}`
        : `https://${draft.bucket}.s3.us-east-2.amazonaws.com/${draft.id}.json?${cacheBuster}`
    )

    fetch(new_url).then((response) => response.json()).then((json) => {
      if (json.objects !== undefined) {
        json.objects.forEach((object) => {
          if (object.hasOwnProperty('media')) {
            object.media.src += `?${cacheBuster}`;
          }
        });
      } else {
        json.data.forEach((canvas) => {
          canvas.canvas.objects.forEach((object) => {
            if (object.media !== null && object.media !== undefined) {
              if(object.media.src !== null){
                object.media.src += `?${cacheBuster}`;
              }
            }
          });
        });
      }
      this.doLoadFromDraft(json, draft, slideshow, callback);
    }, () => {
      fetch(old_url).then((response) => response.json())
        .then((json) => {
          this.doLoadFromDraft(json, draft, slideshow, callback);
        }, () => {});
    });
  }

  serializeObjects() {
    return new Promise((resolve, reject) => {
      const images = this.canvas.getObjects('image');
      const results = [];

      for (let i = 0; i < images.length; i += 1) {
        const image = images[i];
        const src = image.getSrc();
        let video = false;
        if (image.parent && image.parent.media && image.parent.media.type === 'video') {
          video = true;
        }
        if (!video && image.filters && image.filters.length > 0 && image._filteredEl) {
          // put a version of the image to s3 that represents it with filters.
          results.push((
            new Promise((complete, reject) => {
              fetch(image._filteredEl.toDataURL('png'))
                .then((response) => response.blob())
                .then((blob) => {
                  const key = `input/${uuid()}_filtered`;
                  const action = new ScoreShotsPut('ss3-clientassets', key);
                  action.put(blob, 'image/png').then(() => {
                    if (image.parent && image.parent.media) {
                      image.parent.media.filteredSrc = action.getUrl();
                    }

                    image.filteredSrc = action.getUrl();
                    complete(action.getUrl());
                  });
                }).catch(reject);
            })
          ));
        }
        const protocol = src.split(':')[0];
        if (protocol === 'data' || protocol === 'blob') {
          results.push((
            new Promise((resolveFetch, rejectFetch) => {
              fetch(src)
                .then((response) => response.blob())
                .then(this._processBlob(video, src))
                .then((data) => {
                  if (video) {
                    image.parent.videoEl.setAttribute(
                      'src',
                      data.action.getUrl(),
                    );
                    resolveFetch();
                  } else {
                    image._originalElement.setAttribute('src', data.action.getUrl());
                    resolveFetch();
                  }
                })
                .catch(rejectFetch);
            })
          ));
        }
      }

      const audio_src = MetaData.getMetaData(this.canvas, 'audio_src', null);

      if (audio_src !== null) {
        if (audio_src.startsWith('blob:') || audio_src.startsWith('data:')) {
          results.push(new Promise((resolve, reject) => {
            fetch(audio_src).then((response) => response.blob())
              .then((blob) => {
                const action = new ScoreShotsPut(
                  'ss3-clientassets', `input/${uuid()}.mp3`,
                );

                action.put(blob, 'audio/mpeg').then(() => {
                  const url = action.getUrl();
                  MetaData.setMetaData(this.canvas, 'audio_src', url);
                  resolve(url);
                });
              }).catch(reject);
          }));
        }
      }

      Promise.all(results).then(() => {
        resolve();
      }, reject);
    });
  }

  _processBlob = (video, src) => (blob) => {
    let contentType;
    const protocol = src.split(':')[0];
    if (protocol === 'blob') {
      if (video) {
        contentType = 'video/mp4';
      } else {
        contentType = blob.type;
      }
    } else {
      contentType = src.match(/data:(\w*\/\w*)/)[1];
    }

    let suffix = '';
    if (video) {
      suffix = '.mp4';
    }
    const key = `input/${uuid()}${suffix}`;
    const action = new ScoreShotsPut('ss3-clientassets', key);
    return action.put(blob, contentType);
  }

  async serializeToJSON(show_editor_objects = false) {
    // this makes all blob urls into Amazon urls.
    await this.serializeObjects();

    if (!show_editor_objects) {
      this.showEditorObjects(false);
    }


    const output = this.canvas.toJSON([
      'name',
      'fillOverride',
      'strokeOverride',
      'clipMedia',
      'specialObject',
      'dataBinding',
      'animation',
      'duration',
      'defaultview',
      'clipPath',
      'scoreshots',
      'filteredSrc',
    ]);

    if (!show_editor_objects) {
      this.showEditorObjects(true);
    }

    return output;
  }

  toJSON(show_editor_objects = false) {
    if (!show_editor_objects) {
      this.showEditorObjects(false);
    }
    const output = this.canvas.toJSON([
      'name',
      'fillOverride',
      'strokeOverride',
      'clipMedia',
      'specialObject',
      'dataBinding',
      'animation',
      'duration',
      'defaultview',
      'clipPath',
      'scoreshots',
    ]);

    if (!show_editor_objects) {
      this.showEditorObjects(true);
    }

    return output;
  }

  toDataURL(options = {}, hideBackgrounds, keepActiveObject = false) {
    const optionsCopy = { ...options };
    this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    this.canvas.renderMode = true;

    if (!keepActiveObject) {
      this.canvas.discardActiveObject();
    }

    if (hideBackgrounds) {
      this.showBackgroundImages(false);
    }

    if (!optionsCopy.width) {
      optionsCopy.width = this.canvas.workingArea.width;
    }

    if (!optionsCopy.height) {
      optionsCopy.height = this.canvas.workingArea.height;
    }

    this.canvas.renderAll();
    const image = this.canvas.toDataURL(optionsCopy);
    this.canvas.renderMode = false;
    this.setOffsetAndZoom();
    this.showEditorObjects(true);
    this.showBackgroundImages(true);
    return image;
  }

  showBackgroundImages = (val) => {
    this.canvas.getObjects('backgroundBox').forEach((box) => {
      if (box.media && (box.media.type === 'image' || box.media.type === 'video') && box.media.element) {
        box.media.element.visible = val;
        box.media.element.dirty = true;
      }
    });
    this.canvas.getObjects('effect').forEach((effect) => {
      effect.visible = val;
      effect.dirty = true;
    });
  }

  swapTemplate(template) {
    const self = this;
    self.canvas.set({
      workingArea: {
        width: template.width,
        height: template.height,
      },
    });
    const firstBackground = self.canvas.getObjects('backgroundBox')[0];
    if (firstBackground && firstBackground.media) {
      self.canvas.backgroundMedia = {
        type: firstBackground.media.type,
        src: firstBackground.media.element.getSrc(),
      };
    }
    const images = self.canvas.getObjects('image');
    for (let i = 0; i < images.length; i += 1) {
      const image = images[i];
      self.canvas.remove(image);
    }

    fetch(`${template.url}?${+new Date()}`)
      .then((response) => response.json()).then((json) => {
      self.loadFromJSON(json, () => {
        self._resize();
        self._populateSpecialObjects();
        self.canvas.renderAll();

        if (this.onSwapTemplate) {
          this.onSwapTemplate(json);
        }
      });
    }).catch((err) => {
      alert(err);
    });
  }

  _populateSpecialObjects() {
    if (this.specialObject) {
      const objects = [...this.canvas.getObjects()];
      objects.forEach((object) => {
        if (object.specialObject && this.specialObject[object.specialObject]) {
          Object.keys(this.specialObject[object.specialObject]).forEach((key) => {
            const value = this.specialObject[object.specialObject][key];
            if (key === 'image' && object.setImage) {
              object.setImage({
                src: value,
              });
            } else {
              object.set(key, value);
            }
          });
        }
      });
    }
    if (this.canvas.backgroundMedia) {
      const firstBackground = this.canvas.getObjects('backgroundBox')[0];
      firstBackground.setMedia(this.canvas.backgroundMedia);
    }
  }

  // reorganize images and backgrounds so that
  // media elements come directly after their parent
  fixCanvasOrder = () => {
    const objects = this.canvas.getObjects();

    objects.filter((object) => (
      object.type === 'image' && object.parent
    )).forEach((object) => {
      objects.splice(objects.indexOf(object), 1);
      objects.splice((objects.indexOf(object.parent) + 1), 0, object);
    });
  }

  loadFromJSON(
    json,
    callback = () => {},
    reviver = () => {},
    undo = false,
    colorReplacement = true,
  ) {
    const self = this;

    // enforce editor background color
    delete json.background;

    // list of methods to load template switcher data for
    const switchMethods = [
      'draft',
      'custom',
      'post',
    ];
    // turn off undo so these modifications don't get caught.
    self.canvas.ignoreUndo = true;

    // handle any blob urls in the json.
    if (json && json.objects) {
      json.objects.forEach((object) => {
        if (object && object.media && object.media.src && object.media.src.split(':')[0] === 'blob') {
          object.media = null;
        }
        if (object && object.media && object.media.src && object.media.src.includes('resizer.scoreshots.com')) {
          object.media.src = `${object.media.src.split('fit/')[1]}?t=${uuid()}`;
        }

        if (object.clipTo) {
          delete object.clipTo;
        }

        // <<< OPACITY HACK >>>
        switch (object.type) {
          case 'logoBox':
          case 'cutoutBox': {
            if (object.opacity === 0.2 || object.opacity === 0.0) {
              object.opacity = 1.0;
            }

            break;
          }
          default: {
            break;
          }
        }
      });
    }

    if (json && !this.admin_editor) {
      WidgetUtil.ProcessJson(json);
    }

    self.canvas.loadFromJSON(
      json,
      () => {
        const objects = this.canvas.getObjects();

        for(let i = 0;i < objects.length;i++){
          objects[i].uuid = uuid();
        };

        self.fixCanvasOrder();
        self.addEditorObjects();
        self.refreshBackgrounds();

        if (!this.admin_editor) {
          self.instantiateWidgets();
        }

        callback();

        if (colorReplacement) {
          self.colorReplacement('default');
        } else {
          self.colorReplacement('delete_only');
        }

        if (switchMethods.indexOf(self.method) !== -1) {
          // we need to populate the switcher objects.
          self.canvas.getObjects().forEach((obj) => {
            this.updateTypes({
              target: obj,
            });
          });
        }

        // Update widgets.
        self.canvas.getObjects('widget').forEach((w) => w.reinstantiate());

        if (!this.admin_editor) {
          WidgetUtil.ProcessCanvas(this.canvas).then(() => {
            this.canvas.fire('widgets:loaded');
          });
        }

        self.canvas.ignoreUndo = false;
        if (!undo) {
          self._undoSnapshot();
        }
      },
      (obj, options) => {
        if (options) {
          obj.dataBinding = options.dataBinding;
        }

        if (reviver) {
          reviver(obj, options);
        }
      },
    );
  }

  instantiateWidgets = () => {
    this.canvas.getObjects('widgetLayout').forEach((object) => {
      object.resize();
    });
  }

  colorReplacement = (mode = 'default') => {
    let palette = this.canvas.colors;
    let delete_key = !this.admin_editor;

    if (mode === 'delete_only') {
      palette = null;
    } else if (mode === 'override_only') {
      delete_key = false;
    }

    const objects = this.canvas.getObjects();

    objects.forEach((object) => {
      RenderJob.remapObjectColors(
        object, palette, null, delete_key,
      );
    });

    this.canvas.requestRenderAll();
  }

  /**
   * Create preview of resize operation
   * @return {Image blob}
   */
  getResizePreview = (method, width, height) => {
    const initial = this.toJSON();
    const { canvas } = this;
    this.CanvasResize.resizeByMethod(method, width, height);
    const initialWorkingArea = { ...canvas.workingArea };
    canvas.workingArea.width = width;
    canvas.workingArea.height = height;
    canvas.requestRenderAll();
    const image = this.toDataURL({ format: 'png' });
    canvas.workingArea = initialWorkingArea;
    this.loadFromJSON(initial, () => {}, () => {}, false, true);
    this.setOffsetAndZoom();
    return image;
  }

  resizeGraphic = (method, width, height) => {
    const { canvas } = this;
    this.CanvasResize.resizeByMethod(method, width, height);
    canvas.workingArea.width = width;
    canvas.workingArea.height = height;
    this.setOffsetAndZoom();
    // const initial = this.toJSON();
    // this.loadFromJSON(initial, () => {}, () => {}, false, false);
    canvas.getObjects('widgetLayout').forEach((w) => {
      // w.instantiate();
    });
    canvas.renderAll();
  }

  /**
   * Revives old ScoreShots objects from old templates into new ones.
   * @return {null}
   */
  necromancer(callback = () => {}) {
    const { canvas, fabric } = this;
    const foundBackground = false;
    const objects = [...canvas.getObjects()];
    objects.forEach((object, index) => {
      if (object.type === 'backgroundBox') {
        // currently load from json makes a bad backgroundBox
        canvas.remove(object);
      }
      if (object.ss_special_preColor) {
        const override = object.ss_special_preColor.toLowerCase();
        object.set('fillOverride', override);

        const color = this.canvas.colors[override]
          ? this.canvas.colors[override]
          : null;
        if (color) {
          object.set('fill', color);
        }
      }

      if (object.type === 'text') {
        if (fonts[object.fontFamily]) {
          object.set('fontFamily', fonts[object.fontFamily]);
        }
        const options = {
          ...object,
        };
        delete options.type;
        delete options.text;
        const newIText = new fabric.IText(object.text, options);
        canvas.remove(object);
        canvas.insertAt(newIText, index);
      }

      if (object.type === 'i-text') {
        if (fonts[object.fontFamily]) {
          object.set('fontFamily', fonts[object.fontFamily]);
        }
      }

      // rect to polyline conversion tool
      if (object.type === 'rect') {
        if (
          object.scaleX / object.scaleY > 10
          || object.scaleX / object.scaleY < 0.1
        ) {
          let width;
          let startPoint;
          let endPoint;
          if (object.scaleX / object.scaleY > 10) {
            width = object.height * object.scaleY;
            startPoint = {
              x: (object.aCoords.tl.x + object.aCoords.bl.x) / 2,
              y: (object.aCoords.tl.y + object.aCoords.bl.y) / 2,
            };
            endPoint = {
              x: (object.aCoords.tr.x + object.aCoords.br.x) / 2,
              y: (object.aCoords.tr.y + object.aCoords.br.y) / 2,
            };
          } else {
            width = object.width * object.scaleX;
            startPoint = {
              x: (object.aCoords.tl.x + object.aCoords.tr.x) / 2,
              y: (object.aCoords.tl.y + object.aCoords.tr.y) / 2,
            };
            endPoint = {
              x: (object.aCoords.bl.x + object.aCoords.br.x) / 2,
              y: (object.aCoords.bl.y + object.aCoords.br.y) / 2,
            };
          }
          const options = {
            stroke: object.fill,
            strokeWidth: 1,
          };
          if (options.stroke === '#6b2587') {
            options.strokeOverride = 'primary';
          } else if (options.stroke === '#4fd4c4') {
            options.strokeOverride = 'secondary';
          } else if (options.stroke === '#e8e7e5') {
            options.strokeOverride = 'tertiary';
          }
          const newPoly = new fabric.Polyline([
            startPoint,
            endPoint,
          ], options);
          canvas.remove(object);
          canvas.insertAt(newPoly, index);
          // the poly is in, but we need to reposition it.
          const newCoords = newPoly.translateToGivenOrigin(
            { x: newPoly.left, y: newPoly.top },
            'left',
            'top',
            'center',
            'center',
          );
          newPoly.set({
            left: newCoords.x,
            top: newCoords.y,
            originX: 'center',
            originY: 'center',
            strokeWidth: width,
          });
        } else {
          // still want to convert these to not be 100x100
          this.handleModification({
            target: object,
          });
        }
      }

      switch (object.ss_special_object) {
        case 'background_box': {
          const coords = object.aCoords;
          const newCoords = [];
          Object.keys(coords).forEach((coord) => {
            newCoords.push(coords[coord]);
          });
          canvas.remove(object);
          canvas.insertAt(new fabric.BackgroundBox(newCoords, { name: object.name }), index);
          break;
        }
        case 'bounding_box': {
          const coords = object.aCoords;
          const newCoords = [];
          Object.keys(coords).forEach((coord) => {
            newCoords.push(coords[coord]);
          });
          canvas.remove(object);
          canvas.insertAt(new fabric.LogoBox(newCoords, { name: object.name }), index);
          break;
        }
        case 'polygon_box': {
          canvas.remove(object);
          const lowerName = object.name.toLowerCase();
          if (lowerName.includes('player') || lowerName.includes('cutout')) {
            canvas.insertAt(new fabric.CutoutBox(object.points, {
              name: object.name,
              clipMedia: true,
            }), index);
          } else {
            canvas.insertAt(new fabric.LogoBox(object.points, {
              name: object.name,
              clipMedia: true,
            }), index);
          }
          break;
        }
        default:
          break;
      }
      if (object.type === 'group') {
        // it's an SS logo! GET 'ER
        canvas.remove(object);
        canvas.requestRenderAll();
      }
    });
    // if there wasn't a background box, lets add one.
    if (!foundBackground) {
      const background = new fabric.BackgroundBox([
        { x: 0, y: 0 },
        { x: canvas.workingArea.width, y: 0 },
        { x: canvas.workingArea.width, y: canvas.workingArea.height },
        { x: 0, y: canvas.workingArea.height },
      ]);
      canvas.insertAt(background, 0);
    }
    callback();
  }

  addEffect(id) {
    const effect = new fabric.Effect({
      effect: id,
    });

    this.canvas.add(effect);
    return effect;
  }

  isAnimated() {
    const has_video = this.canvas.getObjects('backgroundBox')
      .filter((o) => (
        o.media && o.media.type === 'video'
      )).length > 0;
    const has_animations = has_video || this.canvas.getObjects().filter((o) => (
      o.animation
    )).length > 0;

    return has_video || has_animations;
  }

  hasBgm() {
    return MetaData.hasMetaData(this.canvas, 'audio_src');
  }

  isDynamic() {
    return this.canvas && this.canvas.getObjects('widgetLayout').length > 0;
  }

  hasEffect() {
    return (this.canvas.getObjects('effect').length > 0);
  }
}

// -------------------------------------------------------------------------- //

export default CanvasUtil;

// -------------------------------------------------------------------------- //
