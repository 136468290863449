/* eslint-disable jsx-a11y/media-has-caption */
import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { fileTypes } from './dict';
import ResizedImage from './ResizedImage';
import video from '../../assets/video.png';
import {Close as CloseIcon, ImagePlus} from "mdi-material-ui";
import {CheckBold} from "mdi-material-ui";
import { Snackbar, IconButton } from "@material-ui/core";
import gql from "graphql-tag";
import {compose, graphql} from "react-apollo";
import {withRouter} from "react-router";

const COPY_LOGO = gql`
  mutation($id: ID!,$fileFolderId: ID) {
    copyStockLogo(
      id: $id,
      fileFolderId: $fileFolderId
    ) {
      id
    }
  }
`;

const GET_FOLDER = gql`
  query ($folderId: ID) {
    folder(
      folderId: $folderId
    ) {
      parent {
        name
        id
      }
    }
  }
`;


const styles = theme => ({
  filePaper: {
    position: 'relative',
    cursor: 'cursor',
    userSelect: 'none',
    textAlign: 'center',
    paddingTop: theme.spacing(2),
    height: 250,
  },
  imageHolder: {
    paddingTop: 'calc( 100% - 52px )',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
  },
  videoHolder: {
    backgroundColor: '#000',
    height: 'calc( 100% - 52px )',
    width: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
  },
  activeFile: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    height: 250,
  },
  fileLabel: {
    lineHeight: '52px',
    textIndent: theme.spacing(1),
    paddingRight: theme.spacing(1),
    color: 'inherit',
    fontSize: 12,
    textAlign: 'left',
  },
  filePlus: {
    float: 'right',
    position: 'relative',
  },
});

const VideoItem = ( { classes , file }) => <div
  className={classes.imageHolder}
>
  <video controls muted  className={classes.videoHolder}>
    <source type="video/mp4" src={`https://${file.bucket}.s3.us-east-2.amazonaws.com/${file.client.id}/${file.id}.mp4`} />
  </video>
</div>
class File extends Component {

  state = {
    imageAdded: false,
    snackbarOpen: false,
    snackbarMessage: '',
  }

  addImage = () => {
    const {
      file
    } = this.props;

    if(file.id) {
      this.props.copyStockLogo({
        variables: {
          id: file.id,
          fileFolderId: file.folder.id
        },
      }).then(() => {
        let location = ''
        if(this.props.folder.folder){
          location = this.props.folder.folder.parent.name
        }
        this.setState({
          imageAdded: true,
          snackbarMessage: location === 'Backgrounds'? 'Image added to your Photo Library' : 'Image added to your Logo Library',
          snackbarOpen: true,
        });
      });

    } else{
      this.setState({
        snackbarMessage: 'There was an error, please try again',
        snackbarOpen: true,
      });
    }
  }

  renderSnackbar = () => {
    const { classes } = this.props;
    const { snackbarOpen, snackbarMessage } = this.state;

    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={this.handleSnackbarClose}
        message={<span>{snackbarMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={this.handleSnackbarClose}
          >
            <CloseIcon/>
          </IconButton>
        ]}
      />
    );
  }

  handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ snackbarOpen: false });
  }

  render() {
    const {
      file, setSelected, active, classes, setSelectedFiles
    } = this.props;

    let {
      drag, dragEnd,
    } = this.props;
    const { imageAdded } = this.state;
    let type = 'other';
    if (fileTypes[file.extension.toLowerCase()]) {
      ({type} = fileTypes[file.extension.toLowerCase()]);
    }
    const cacheBuster = (+new Date());
    const preview = (type !== 'video' ?
        `https://${file.bucket}.s3.us-east-2.amazonaws.com/${file.client.id}/${file.id}.${file.extension}?${cacheBuster}` :
        `https://${file.bucket}thumb.s3.us-east-2.amazonaws.com/${file.client.id}/${file.id}.png?${cacheBuster}`
    );

    let classname;
    if (!active) {
      classname = classes.filePaper;
    } else {
      classname = classNames(classes.filePaper, classes.activeFile);
    }

    const name = type === 'other' ? `${file.name}.${file.extension}` : file.name;

    let previewElement = null;
    if (active && type === 'video') {
      previewElement = <VideoItem
          {...this.props}
          aria-label={name}
          tabIndex={0}/>
    } else if (type !== 'other') {
      previewElement = file.type === 'VIDEO' ? <VideoItem {...this.props}/> :
        <ResizedImage aria-label={name}
                      tabIndex={0} src={preview} style={{width: 'auto', maxWidth: '100%', height: 'auto', maxHeight: 175}}/>
    }

    let iconElement = <IconButton className={classes.filePlus} onClick={this.addImage}>
        <ImagePlus />
      </IconButton>;
    if (imageAdded) {
      iconElement = <IconButton className={classes.filePlus}>
        <CheckBold style={{color: 'green'}} />
      </IconButton>;
    }

    let copyLogo = null;
    if (file && file.type && file.type === 'STOCK') {
      copyLogo = iconElement;
      drag = null;
      dragEnd = null;
    }

    return (
      <>
        {this.renderSnackbar()}
        <Paper
          className={classname}
          onDragStart={drag && drag(file, 'file')}
          onDragEnd={dragEnd}
          draggable={Boolean(drag)}
        >
          <div
            style={{height: 180, verticalAlign: 'middle'}}
            onClick={(e) => {
              setSelected(file, 'file')(e);
              setSelectedFiles(file);
            }}
          >{previewElement}</div>
          <div>
            {copyLogo}
            <Typography variant="body1" noWrap className={classes.fileLabel}>
              {name}
            </Typography>
          </div>
        </Paper>
      </>
    );
  }
};
export default compose(
  graphql(GET_FOLDER, {name: 'folder'}),
  graphql(COPY_LOGO, { name: 'copyStockLogo' }),
  withRouter,
  withStyles(styles),
)(File);
