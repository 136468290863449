
function getTime() {
	return (Date.now() / 1000);
}

class Progress {

	constructor(denominator = 0, scope = 60) {
		this.denominator = denominator;
		this.scope = scope;
		this.eta_epoch = null;
		this.rate = 0.0;
		this._start_time = getTime();
		this._timing_data = [];
	}

	get numerator() {
		if (this._timing_data.length === 0) {
			return 0.0;
		}

		return this._timing_data[this._timing_data.length - 1].numerator;
	}

	set numerator(value) {
		this.push(value, true);
	}

	get stalled() {
		return (this.rate === 0.0);
	}

	get started() {
		return (this._timing_data.length > 1);
	}

	get undefined() {
		return (this.denominator === null || this.denominator <= 0.0);
	}

	get done() {
		return (this.undefined || this.numerator === this.denominator);
	}

	get eta_seconds() {
		if (this.eta_epoch === null) {
			return null;
		}

		return Math.max(0, (this.eta_epoch - getTime()));
	}

	get percent() {
		if (this.undefined) {
			return 0.0;
		}

		return (this.numerator / this.denominator * 100);
	}

	get elapsed() {
		if (!this.started) {
			return 0.0;
		}

		return (this._timing_data[this._timing_data.length - 1].time - this._start_time);
	}

	get rate_unstable() {
		if (!this.started || this.stalled) {
			return 0.0;
		}

		const last = this._timing_data[this._timing_data.length - 1];
		const penultimate = this._timing_data[this._timing_data.length - 2];

		return (
			(last.numerator - penultimate.numerator) / (last.time - penultimate.time)
		);
	}

	get rate_overall() {
		const elapsed = this.elapsed;
		return (elapsed === 0.0 ? this.rate : (this.numerator / elapsed));
	}

	push(value, calculate = true) {
		if (this._timing_data.length > 0){
			value = Math.max(this._timing_data[this._timing_data.length - 1].numerator, value);
		}

		const now = getTime();

		if (this._timing_data.length > 0 && this._timing_data[this._timing_data.length - 1].time === now) {
			this._timing_data[this._timing_data.length - 1].numerator = value;
		} else {
			this._timing_data.push({
				time: now,
				numerator: value,
			});

			if (this._timing_data.length > this.scope) {
				this._timing_data.splice(0, (this._timing_data.length - this.scope));
			}
		}

		if (!this.done && calculate && this.started) {
			this._calculate();
		}
	}

	_calculate() {
		const mean_x = (this._timing_data.map(i => i.time).reduce((lhs, rhs) => (lhs + rhs)) / this._timing_data.length);
		const mean_y = (this._timing_data.map(i => i.numerator).reduce((lhs, rhs) => (lhs + rhs)) / this._timing_data.length);
		const std_x = Math.sqrt(this._timing_data.map(i => Math.pow((i.time - mean_x), 2.0)).reduce((lhs, rhs) => (lhs + rhs)) / (this._timing_data.length - 1));
		const std_y = Math.sqrt(this._timing_data.map(i => Math.pow((i.numerator - mean_y), 2.0)).reduce((lhs, rhs) => (lhs + rhs)) / (this._timing_data.length - 1));
		let sum_xy = 0.0, sum_sq_v_x = 0.0, sum_sq_v_y = 0.0;

		for (const data of this._timing_data) {
			const x = (data.time - mean_x);
			const y = (data.numerator - mean_y);
			sum_xy += (x * y);
			sum_sq_v_x += Math.pow(x, 2.0);
			sum_sq_v_y += Math.pow(y, 2.0);
		}

		const pearson_r = (sum_xy / Math.sqrt(sum_sq_v_x * sum_sq_v_y));
		const m = (pearson_r * (std_y / std_x));
		this.rate = m;

		if (this.undefined) {
			return;
		}

		const y = this.denominator;
		const b = (mean_y - m * mean_x);
		const x = ((y - b) / m);
		const seconds = this._timing_data[this._timing_data.length - 1].time;
		const fitted_b = (this._timing_data[this._timing_data.length - 1].numerator - (m * seconds));
		const fitted_x = ((y - fitted_b) / m);
		const adjusted_x = (((fitted_x - x) * (this.numerator / this.denominator)) + x);
		this.eta_epoch = adjusted_x;
	}

}

export default Progress;
