
// -------------------------------------------------------------------------- //

import React, { useState } from "react"

import {
  Button,
  Grid,
  Typography,
  ButtonGroup,
} from '@material-ui/core';
import { ApolloProvider,ApolloClient,InMemoryCache } from '@apollo/client';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink, concat } from 'apollo-link';
import starterIcon from '../../assets/Starter_Icon.png'
import proIcon from '../../assets/Pro_Icon.png'
import allStar from '../../assets/All-Star.png'
import { makeStyles } from "@material-ui/styles"
import Subscription from './Subscriptions/Subscription'
import decode from "jwt-decode";
// -------------------------------------------------------------------------- //
const useStyles = makeStyles((theme) => ({

  activeButton: {
    backgroundColor: '#592570',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#592570'
    },
    fontSize:'2vmin'
  },
  paragraph_6: {
    textAlign: 'center',
    marginTop: '7vmax',
    fontFamily: 'Ubuntu !important'
  },
  paragraph_1: {
    textAlign: 'center',
    marginTop: '3vmax',
    marginBottom: '5vmax',
    fontFamily: 'Ubuntu !important'
  },
  header: {
    fontFamily: 'Ubuntu'
  },
  inactiveButton: {
    backgroundColor: '#FFF',
    color: '#592570',
    border: '1px solid #592570',
    '&:hover': {
      backgroundColor: '#FFF'
    },
    fontSize:'2vmin'
  },
  suscriptionContainer: {
    display: 'flex',
    justifyContent:'center',
    justifySelf:'center',
    alignContent:'center',
    alignSelf:'center',
    marginTop: '3vmax'
  }

}))

const monthlySuscriptions = [
  {
    name: "STARTER",
    content: `5 User Accounts\n\n100 Pre-Selected Templates\n\nUnlimited Posts\n\nAnnual license \n\n 5GB Storage `,
    price: "99",
    priceID: process.env.REACT_APP_STRIPE_STARTER_MONTHLY,
    icon: starterIcon,
  },
  {
    name: "PRO",
    content: (
        <>
          {" "}
          <p>10 User Accounts</p> <p>50+ Image Based Templates</p>{" "}
          <p>
            Background Removal Tool (<strike>50</strike> 100 Credits)
          </p>{" "}
          <p>Unlimited Posts</p> <p>Annual license</p> <p>5GB Storage</p>{" "}
        </>
    ),
    price: "149",
    priceID: process.env.REACT_APP_STRIPE_PRO_MONTHLY,
    icon: proIcon,
  },
  {
    name: "ALL-STAR",
    content: (
        <>
          {" "}
          <p>Unlimited User Accounts </p>{" "}
          <p>500+ Image,Video and Animated Templates</p>{" "}
          <p>
            Background Removal Tool (<strike>500</strike> Unlimited Credits)
          </p>{" "}
          <p>Unlimited Posts</p> <p>Annual license</p> <p>10GB Storage</p>{" "}
        </>
    ),
    price: "199",
    priceID: process.env.REACT_APP_STRIPE_ALL_STAR_MONTHLY,
    icon: allStar,
  },
]

const anualSubscription = [
  {
    name: "STARTER",
    content: `5 User Accounts\n\n100 Pre-Selected Templates\n\nUnlimited Posts\n\nAnnual license \n\n 5GB Storage `,
    price: "595",
    priceID: process.env.REACT_APP_STRIPE_STARTER_ANNUAL,
    icon: starterIcon,
  },
  {
    name: "PRO",
    content: (
        <>
          {" "}
          <p>10 User Accounts</p> <p>50+ Image Based Templates</p>{" "}
          <p>
            Background Removal Tool (<strike>50</strike> 100 Credits)
          </p>{" "}
          <p>Unlimited Posts</p> <p>Annual license</p> <p>5GB Storage</p>{" "}
        </>
    ),
    price: "1500",
    priceID: process.env.REACT_APP_STRIPE_PRO_ANNUAL,
    icon: proIcon,
  },
  {
    name: "ALL-STAR",
    content: (
        <>
          {" "}
          <p>Unlimited User Accounts </p>{" "}
          <p>500+ Image,Video and Animated Templates</p>{" "}
          <p>
            Background Removal Tool (<strike>500</strike> Unlimited Credits)
          </p>{" "}
          <p>Unlimited Posts</p> <p>Annual license</p> <p>10GB Storage</p>{" "}
        </>
    ),
    price: "2400",
    priceID: process.env.REACT_APP_STRIPE_ALL_STAR_ANNUAL,
    icon: allStar,
  },
]
let subscriptions = monthlySuscriptions

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_ENDPOINT });

const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    const token = localStorage.getItem('access_token');
    operation.setContext({
        headers: {
            authorization: token ? `Bearer ${token}` : null,
        },
    });
    return forward(operation).map((response) => {
        // if a refresh token is sent, update the access token.
        const context = operation.getContext();
        const refresh = context.response.headers.get('x-refresh-token');
        if (refresh) {
            const token = decode(refresh);
            localStorage.setItem('access_token', refresh);
            localStorage.setItem('expires_at', token.exp);
        }
        return response;
    });
});

const link = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`));
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
});

const ExpiredAccount = () => {
  const classes = useStyles()

  const [subscriptionType, setSuscriptionType] = useState("m")

  const setSuscriptionToMonthly = () => {
    setSuscriptionType("m")
    subscriptions = monthlySuscriptions
  }

  const setSuscriptionToYearly = () => {
    setSuscriptionType("y")
    subscriptions = anualSubscription
  }

  const client = new ApolloClient({
    link: concat(authMiddleware, httpLink, link),
    cache: new InMemoryCache(),
    defaultOptions: {
        mutate: {
            errorPolicy: 'all',
        },
    },
  });

  return (
      <ApolloProvider client={client}>
        <div className={classes.paragraph_6}>
          <Typography variant="h4" className={classes.header}>Your account has expired</Typography>
          <Typography variant="h6" className={classes.header} style={{marginTop: '10px'}}><strong>Select one of our existing plans to continue</strong></Typography>
        </div>
        <div className={classes.suscriptionContainer}>
          <ButtonGroup disableElevation variant="contained" color="primary">
            <Button
                className={
                  subscriptionType === "m"
                      ? classes.activeButton
                      : classes.inactiveButton
                }
                style={{
                  borderRadius: "200px 0 0 200px",
                  width: "25vmin",
                  height: "4vmin",
                }}
                onClick={setSuscriptionToMonthly}
            >
              PAY MONTHLY
            </Button>
            <Button
                className={
                  subscriptionType === "y"
                      ? classes.activeButton
                      : classes.inactiveButton
                }
                style={{
                  borderRadius: "0 200px 200px 0",
                  width: "25vmin",
                  height: "4vmin",
                }}
                onClick={setSuscriptionToYearly}
            >
              PAY YEARLY
            </Button>
          </ButtonGroup>
        </div>
        <Grid
            container
            xs={12}
            align="center"
            direction="row"
            justify="center"
            style={{
              width: "100%",
              marginTop: "15vmin",
              marginBottom: "10vmin",
              alignItems: "center",
              paddingLeft: "3vmin",
              paddingRight: "3vmin",
            }}
        >
          {subscriptions.map((el) => (
              <Grid item xs={4} style={{ marginTop: "-15vmin" }}>
                <Subscription
                    name={el.name}
                    icon={el.icon}
                    price={el.price}
                    priceID={el.priceID}
                    period={subscriptionType}
                    content={el.content}
                />
              </Grid>
          ))}
        </Grid>
      <div className={classes.paragraph_1}>
          <Typography variant="h5" className={classes.header}>Don’t see your plan or have further questions?</Typography>
          <Typography variant="h6" className={classes.header} style={{marginTop: '10px'}}>Contact our <a href = "https://clubessential.my.salesforce-sites.com/contactus" rel="noopener noreferrer" target="_blank">Client Care team</a></Typography>
      </div>
      </ApolloProvider>
  )

}

export default ExpiredAccount

