import AddExtrasPreview from '../AddExtrasPreview';
import DownloadSelector from '../DownloadSelector';
import Scheduler from '../Scheduler';
import MessageEditor from '../MessageEditor';
import Sharer from '../Sharer';

export default [
  {
    name: 'Preview / Download',
    id: 'preview',
    component: AddExtrasPreview,
  },
  {
    name: 'When To Share',
    id: 'schedule',
    component: Scheduler,
  },
  {
    name: 'Where to Share',
    id: 'share',
    component: DownloadSelector,
  },
  {
    name: 'Add a Message',
    id: 'message',
    component: MessageEditor,
  },
  {
    name: 'Share!',
    id: 'share',
    component: Sharer,
  },
];
