
// -------------------------------------------------------------------------- //

import * as React from 'react';

import {
  Button,
  ButtonBase,
  CircularProgress,
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  IconButton,
  Paper,
  Typography,
  withStyles,
} from '@material-ui/core';

import FolderStar from 'mdi-material-ui/FolderStar';

import {
  Delete as DeleteIcon,
  DotsVertical,
} from 'mdi-material-ui';

import { formatRelative, addDays } from 'date-fns';
import { withRouter } from 'react-router-dom';
import { Mutation, compose, graphql } from 'react-apollo';
import gql from 'graphql-tag';

// -------------------------------------------------------------------------- //

const DELETE_DRAFT = gql`
  mutation deleteDraft(
    $id: ID!
  ) {
    deleteDraft (
      id: $id
    ) {
      id
    }
  }
`;

const CREATE_CUSTOM_TEMPLATE = gql`
  mutation createCustomTemplate(
    $name: String!,
    $folderId:ID,
    $width: Int!,
    $height: Int!,
    $categoryIds: [ID!],
    $typeIds: [ID!],
    $isQuickCreate: Boolean
  ) {
    createCustomTemplate(
      name : $name,
      folderId: $folderId,
      width: $width,
      height : $height,
      categoryIds: $categoryIds,
      typeIds: $typeIds
      isQuickCreate: $isQuickCreate
    ) {
      id
      template {
        id
      }
    }
  }
`;

const STYLES = (theme) => ({
  paper: {
    overflow: 'hidden',
    position: 'relative',
    cursor: 'pointer',
    marginBottom: theme.spacing(2),
  },
  preview: {
    width: '100%',
    display: 'block',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  expiration: {
    flex: 1,
  },
});

// -------------------------------------------------------------------------- //


class Draft_ extends React.Component {
  state = {
    open: false,
    deleting: false,
    launching: false,
    menuAnchor: null,
    menu: false,
    menuDeleteFunction: null,
    openConvertToCustomDialog: false,
    templateName: 'New Custom Template',
    uploading: false,
    renameValidation: '',
  }

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.paper}>
        {this.renderMenuDialog()}
        {this.renderConvertToCustomDialog()}
        {this.renderPreview()}
        {this.renderActions()}
        {this.renderDialog()}
      </Paper>
    );
  }

  closeConvertToCustomDialog = () => {
    this.setState({ openConvertToCustomDialog: false, menu: false });
  }

  ascendToCustom = (deleteDraft) => {
    const { draft, ascension, createCustomTemplate } = this.props;

    let categorylist = null;

    // eslint-disable-next-line max-len
    if (this.props.draftData.data.draft && this.props.draftData.data.draft.template && this.props.draftData.data.draft.template.categories) {
      categorylist = this.props.draftData.data.draft.template.categories.map((cat) => cat.id);
    }

    let typelist = null;
    // eslint-disable-next-line max-len
    if (this.props.draftData.data.draft && this.props.draftData.data.draft.template && this.props.draftData.data.draft.template.types) {
      typelist = this.props.draftData.data.draft.template.types.map((cat) => cat.id);
    }

    if (this.state.templateName !== '') {
      this.setState({ uploading: true, deleting: true });
      this.closeConvertToCustomDialog();
      ascension(draft, createCustomTemplate, this.state.templateName, () => {
        deleteDraft({
          variables: { id: draft.id },
          refetchQueries: ['get_drafts'],
        });
        this.setState({ uploading: false, deleting: false });
      }, categorylist, typelist);
    } else {
      this.setState({ renameValidation: 'Name cannot be empty' });
    }
  }

  renderConvertToCustomDialog = () => (
    <Dialog open={this.state.openConvertToCustomDialog} onClose={() => this.closeConvertToCustomDialog()}>
      <DialogTitle>Convert to Custom template</DialogTitle>
      <DialogContent>
        <TextField
          id="name"
          label="Template name"
          margin="normal"
          value={this.state.templateName}
          error={this.state.renameValidation.length !== 0}
          helperText={this.state.renameValidation}
          onChange={(e) => this.setState({ templateName: e.target.value })}
          style={{ width: '100%' }}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => this.closeConvertToCustomDialog()}>
            Cancel
        </Button>
        {this.state.uploading === true ? (
          <CircularProgress size={48} />
        ) : (
          <Mutation mutation={DELETE_DRAFT}>
            {(deleteDraft) => (
              <Button color="primary" autoFocus onClick={() => this.ascendToCustom(deleteDraft)}>
                      Save
              </Button>
            )}
          </Mutation>
        )}
      </DialogActions>
    </Dialog>
  )

  renderPreview = () => {
    const { classes, draft } = this.props;
    const { deleting, launching } = this.state;
    const client_id = localStorage.getItem('client_id');

    const image = (
      <img
        className={classes.preview}
        src={`https://${draft.bucket}.s3.us-east-2.amazonaws.com/${client_id}/${draft.id}.png`}
        alt="ScoreShots Draft"
        onError={(e) => {
          // This is due to a change in how drafts are saved.
          // We need to support old draft savings which were just a flat structure.
          // The new structure puts drafts under client folders.
          const src = `https://${draft.bucket}.s3.us-east-2.amazonaws.com/${draft.id}.png`;

          if (e.target.src !== src) {
            e.target.src = src;
          }
        }}
      />
    );

    return (
      <ButtonBase
        disabled={deleting || launching}
        onClick={() => this.launchDraft()}
      >
        {image}
      </ButtonBase>
    );
  }

  renderActions = () => {
    const { classes, draft } = this.props;
    const { deleting } = this.state;
    const future = addDays(new Date(draft.updatedAt), 45);

    return (
      <div className={classes.actions}>
        <Typography variant="caption" className={classes.expiration} noWrap>
            Expires
          {' '}
          {formatRelative(future, new Date())}
        </Typography>
        {!deleting ? (
          <IconButton onClick={(e) => this.openMenu(e.currentTarget, this.openDeleteDraft)}>
            <DotsVertical />
          </IconButton>
        ) : (
          <CircularProgress size={48} />
        )}
      </div>
    );
  }

  closeMenu = () => {
    this.setState({ menuAnchor: null, menu: false });
  }

  renderMenuDialog = () => (
    <Menu
      id="long-menu"
      anchorEl={this.state.menuAnchor}
      open={this.state.menu}
      onClose={this.closeMenu}
    >
      <MenuItem onClick={this.state.menuDeleteFunction}>
        <DeleteIcon />
          Remove
      </MenuItem>
      <MenuItem onClick={() => this.setState({ openConvertToCustomDialog: true })}>
        <FolderStar />
          Convert to Custom
      </MenuItem>
    </Menu>
  )

  openMenu = (e, f) => {
    this.setState({ menu: true, menuAnchor: e, menuDeleteFunction: f });
  }


  renderDialog = () => {
    const { open } = this.state;

    return (
      <Dialog open={open}>
        <DialogTitle>Delete draft?</DialogTitle>
        <DialogContent>
          <DialogContentText>
              You are about to delete a draft. You cannot undo this action.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => this.closeDeleteDraft(null)}>Cancel</Button>
          <Mutation mutation={DELETE_DRAFT}>
            {(deleteDraft) => (
              <Button color="primary" onClick={() => this.closeDeleteDraft(deleteDraft)}>Continue</Button>
            )}
          </Mutation>
        </DialogActions>
      </Dialog>
    );
  }

  openDeleteDraft = () => {
    this.setState({ open: true });
  }

  closeDeleteDraft = (deleteDraft = null) => {
    this.setState({ open: false, menu: false });
    if (deleteDraft) {
      const { draft } = this.props;
      this.setState({ deleting: true });
      deleteDraft({
        variables: { id: draft.id },
        refetchQueries: ['get_drafts'],
      });
    }
  }

  launchDraft = () => {
    const { draft, history } = this.props;
    this.setState({ launching: true }, () => {
      switch (draft.bucket) {
        case 'ss3-slides-drafts': {
          // eslint-disable-next-line no-unused-expressions
          draft.template.isQuickCreate === true ? history.push(`/editor/quickcreate/draft/${draft.id}`)
            : history.push(`/editor/slideshow/draft/${draft.id}`);
          break;
        }
        case 'ss3-drafts': {
          history.push(`/editor/draft/${draft.id}`);
          break;
        }
        default: {
          break;
        }
      }
    });
  }
}

// -------------------------------------------------------------------------- //

export const Draft = compose(
  graphql(CREATE_CUSTOM_TEMPLATE, { name: 'createCustomTemplate' }),
  withRouter,
  withStyles(STYLES),
)(Draft_);

// -------------------------------------------------------------------------- //
