/* eslint-disable no-underscore-dangle */

// -------------------------------------------------------------------------- //

import * as React from 'react';

import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Snackbar,
  Switch,
  Toolbar,
  Tooltip,
  TextField,
  withStyles,
  Hidden,
} from '@material-ui/core';

import {
  ArrowLeft,
  Close,
  MessageText,
  MagnifyPlus,
  MagnifyMinus,
  Magnify,
  UndoVariant,
  RedoVariant,
  Grid as GridIcon,
  GridOff as GridOffIcon,
  Magnet as MagnetIcon,
  MagnetOn as MagnetOnIcon,
} from 'mdi-material-ui';

import { Manager, Target, Popper } from 'react-popper';
import { Mutation, graphql, compose } from 'react-apollo';
import { Subscribe } from 'unstated';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import gql from 'graphql-tag';
import { isMobileOnly } from 'react-device-detect';

import { CreateSearchParameters } from '../create/search';
import { ResizeDialog } from './ResizeDialog';
import * as Binding from '../../canvas/lib/databinding';
import * as Scoreshots from '../../scoreshotsapi/v1';
import Animations from '../../canvas/animations.js';
import CustomFolderDialog from '../customTemplates/CustomFolderDialog';
import Logo from '../svg/Logo';
import MessageContainer from '../../unstated/MessageContainer';
import RenderJob from '../../scoreshotsapi/RenderJob';
import ScoreshotsPut from '../../scoreshotsapi/Put.js';
import ShareDialog from '../share/ShareDialog';
import UploadingDialog from './UploadingDialog';
import * as MetaData from '../../canvas/lib/metadata';
import { EditorUnloadCallback } from './Editor';
import { VirtualTourContext } from '../app/tour';

// -------------------------------------------------------------------------- //

const SimpleDialog = (props) => (
  <Dialog open={props.open}>
    <DialogTitle>{props.title}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {props.alert_text}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button color="primary" onClick={props.cancelFunct}>
        {props.cancel_text}
      </Button>
      <Button color="primary" onClick={props.okFunct}>
        {props.continue_text}
      </Button>
    </DialogActions>
  </Dialog>
);

// ----------------------------------------------------------------------------//

const STYLES = (theme) => ({
  spacerBar: {
    height: 0,
  },
  fullNavBar: {
    width: '100%',
  },
  appbarPresto: {
    backgroundColor: '#ffffff',
  },
  logo: {
    width: 120,
    height: 'auto',
    paddingLeft: theme.spacing(2),
    fill: theme.palette.primary.contrastText,
  },
  logoPresto: {
    width: 120,
    height: 'auto',
    paddingLeft: theme.spacing(2),
    fill: theme.palette.primary.main,
  },
  toolbar: {
    paddingLeft: 0,
  },
  backButton: {
    width: 80,
    color: theme.palette.secondary.contrastText,
    height: 64,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 0,
  },
  cancelButton: {
    width: 80,
    color: theme.palette.secondary.contrastText,
    height: 64,
    backgroundColor: '#b2b2b2',
    borderRadius: 0,
  },
  menus: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    display: 'flex',
  },
  menuHeading: {
    color: theme.palette.primary.contrastText,
    marginTop: theme.spacing(1),
  },
  menuHeadingPresto: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
  },
  menuToolTip: {
    color: theme.palette.primary.contrastText,
  },
  menuToolTipPresto: {
    color: theme.palette.primary.main,
  },
  menuList: {
    padding: 0,
  },
  schedule: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.contrastText,
  },
  popperClose: {
    pointerEvents: 'none',
  },
  prop: {
    width: '100%',
    margin: theme.spacing(1),
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
});

// -------------------------------------------------------------------------- //

const CANCEL_SHARE_TITLE = 'Cancel the render?';

const CANCEL_SHARE_TEXT = `
The current render progress will be canceled
and you will be brought back to the canvas.
`;

// -------------------------------------------------------------------------- //

const SAVE_DRAFT = gql`
  mutation saveDraft(
    $id: ID,
    $templateId: ID,
    $bucket: String!,
    $height: Int!,
    $width: Int!
  ) {
    draft(
      id: $id,
      templateId: $templateId,
      bucket: $bucket,
      height: $height,
      width: $width
    ) {
      id
    }
  }
`;

const CREATE_CUSTOM_TEMPLATE = gql`
  mutation createCustomTemplate(
    $name: String!,
    $folderId:ID,
    $width: Int!,
    $height: Int!,
    $categoryIds: [ID!],
    $typeIds: [ID!]
    $isQuickCreate: Boolean
  ) {
    createCustomTemplate(
      name : $name,
      folderId: $folderId,
      width: $width,
      height : $height,
      categoryIds: $categoryIds,
      typeIds: $typeIds
      isQuickCreate: $isQuickCreate
    ) {
      id
      template {
        id
      }
    }
  }
`;

const OVERWRITE_CUSTOM = gql`
  mutation overwriteCustom(
    $id: ID!,
    $width: Int!,
    $height: Int!
  ) {
    overwriteCustom(
      id: $id,
      width: $width,
      height: $height
    ) {
      id
    }
  }
`;

const POPULAR_COUNT = gql`
  mutation popularCount($id: ID!) {
    popularCount(id: $id) {
      id
      popularCount
    }
  }
`;

const ROLES_QUERY = gql`
  query {
    me {
      id
      role {
        id
        name
        restrictCategoryFlag
        categories {
          id
          name
        }
        allowCustomFlag
        allowCustomSaveFlag
        allowCustomFolderSaveFlag
        restrictCustomFlag
        customFolders {
          id
          name
        }
      }
      client {
        id
        prestosportsId
      }
    }
  }
`;


// -------------------------------------------------------------------------- //

function dataURItoBlob(data_url) {
  const arr = data_url.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

// -------------------------------------------------------------------------- //

export function createRenderJob(working_area, slides, transitions, fonts) {
  if (!transitions || transitions.some((i) => i === null)) {
    transitions = []; // slides.map(i => ({})).slice(1);
  }

  const used_fonts = [];

  for (let i = 0; i < slides.length; ++i) {
    const { canvas } = slides[i];

    if (canvas.objects) {
      const texts = (canvas.objects.filter((object) => (
        object.type === 'i-text' && object.fontFamily
      )));

      for (let j = 0; j < texts.length; ++j) {
        const { fontFamily } = texts[j];

        if (used_fonts.indexOf(fontFamily) < 0) {
          used_fonts.push(fontFamily);
        }
      }

      const textboxes = (canvas.objects.filter((object) => (
        object.type === 'textbox' && object.fontFamily
      )));

      for (let j = 0; j < textboxes.length; ++j) {
        const { fontFamily } = textboxes[j];

        if (used_fonts.indexOf(fontFamily) < 0) {
          used_fonts.push(fontFamily);
        }
      }
    }
  }

  // nab the audio data from the first slide and pull it out to the front
  const audio = [];

  if (slides) {
    let audio_src = null;
    let audio_delay = null;
    let audio_start = null;
    let audio_end = null;
    let audio_volume = null;
    let audio_loop = null;

    for (let i = 0; i < slides.length; ++i) {
      if (
        slides[i].canvas
        && slides[i].canvas.scoreshots
        && slides[i].canvas.scoreshots.audio_src
      ) {
        const { scoreshots } = slides[i].canvas;
        audio_src = scoreshots.audio_src;
        audio_delay = scoreshots.audio_delay;
        audio_start = scoreshots.audio_start;
        audio_end = scoreshots.audio_end;
        audio_volume = scoreshots.audio_volume;
        audio_loop = scoreshots.audio_loop;
        break;
      }
    }

    if (audio_src !== null) {
      audio.push({
        src: audio_src,
        offset: audio_delay,
        seconds: audio_start,
        duration: (audio_end - audio_start),
        volume: audio_volume,
        loop: audio_loop,
        index: 0,
      });
    }
  }

  return {
    // force integers to be integers
    width: +working_area.width,
    height: +working_area.height,
    duration: (
      slides.map((i) => +i.duration || 5).reduce((x, y) => (x + y), 0)
      + transitions.map((i) => +i.duration || 1).reduce((x, y) => (x + y), 0)
    ),
    transitions: transitions.map((transition) => ({
      type: transition.type,
      duration: +transition.duration,
    })),
    scenes: slides.map((slide) => ({
      data: slide.canvas,
      duration: +slide.duration,
    })),
    fonts: fonts.filter((i) => used_fonts.includes(i.fontFamily)).map((font) => ({
      name: font.fontFamily,
      type: font.type.toLowerCase(),
      id: font.id,
      key: font.key,
      bucket: font.bucket,
    })),
    audio,
  };
}

// -------------------------------------------------------------------------- //

class EditorNav extends React.Component {
  state = {
    width: 0,
    height: 0,
    menu: null,
    sharing: false,
    uploading: false,
    upload_failure: false,
    alert_title: '',
    alert_text: '',
    alert_type: 'continue', // 'continue' | 'agree'
    alert_callback: null,
    custom_template_dialog: false, // open a dialog to create a custom template
    custom_template_folder: null, // id of folder where custom template located
    custom_template_name: '', // name of custom template
    resize_dialog: false,
    video: null,
    image: null,
    progress: 0,
    rendering: false,
    cancelShareDialog: false,
    snackbar: '',
    exit_search: true,
    exit_dialog: false,
    anchorEl: null,

    // start mobile menu state items.
    mobile_menu_popup: false, // outer menu bool.
    anchorEl_submenu: null, // menu will anchor to clicked nod.
    open_mobile_submenu: false, // submenu bool
    mobile_menu_items: [], // outer menu items (File, Edit, view) see initialization function.
    mobile_menu_subitems: [], // submenu items  see initialization function.
    json_png: null,
    json_jpg: null,
    disabled_cancel: false,
    openExportToWebDialog: false,
    uploadingToWeb: false,
    exportToWebText: false,
    exportToWebDialogType: '',
    hasEffects: false,
    imageName: ''
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);

    const { match } = this.props;

    if (match.params.bool === '1') {
      this.openTemplateDialog();
    }

    if (match.params.sharing && !this.state.sharing) {
      this.props.history.replace(this.generateSharePath(false));
    }
    this.initialization();
  }

  mobileItemClicked = (index) => (e) => {
    const mobile_items = this.state.mobile_menu_items;
    this.setState({
      anchorEl_submenu: e.currentTarget,
      open_mobile_submenu: true,
      mobile_menu_subitems: mobile_items[index].sub_items,
    });
  }

  canvasFunctions = (key) => () => {
    const { CanvasUtil } = this.props;
    if (CanvasUtil) {
      const { canvas } = CanvasUtil;
      if (canvas) {
        const canvas_object = {
          redo: CanvasUtil.redo,
          undo: CanvasUtil.undo,
          zoomin: CanvasUtil.zoomIn,
          zoomout: CanvasUtil.zoomOut,
          resetzoom: CanvasUtil.resetZoom,
          copy: CanvasUtil.copy,
          paste: CanvasUtil.paste,
          resize: this.openResizeDialog,
        };

        if (key in canvas_object) {
          canvas_object[key]();
        }
      }
    }
  }

  initialization = () => {
    const {
      draftId, method, saveDraft, overwriteCustomTemplate, rolesQuery,
    } = this.props;
    let me = null;
    rolesQuery.refetch().then((r) => {
      me = r.data.me;


      // setting custom save message.
      const saveMsg = draftId ? 'Save As New Draft' : 'Save As Draft';
      const _message = MessageContainer.message;

      // set a save type for custom templates or regular drafts.
      const _saveType = method === 'custom' ? () => this.updateCustomTemplate(overwriteCustomTemplate, _message) : () => this.updateDraft(saveDraft, _message);

      let allowCustomFlag = true;
      let allowCustomSaveFlag = true;

      if (me && me.role) {
        allowCustomFlag = me.role.allowCustomFlag;
        allowCustomSaveFlag = me.role.allowCustomSaveFlag;
      }

      // create an array based on the menu needs.
      let _saveDraftArray = null;
      if (draftId) {
        if (allowCustomFlag && allowCustomSaveFlag) {
          _saveDraftArray = [
            {
              action: _saveType,
              name: 'Save',
            },
            {
              action: () => this.createDraft(saveDraft),
              name: saveMsg,
            },
            {
              action: this.openTemplateDialog,
              name: 'Save As custom',
            },
          ];
        } else {
          _saveDraftArray = [
            {
              action: _saveType,
              name: 'Save',
            },
            {
              action: () => this.createDraft(saveDraft),
              name: saveMsg,
            },
          ];
        }
      } else if (method === 'custom') {
        if (allowCustomFlag && allowCustomSaveFlag) {
          _saveDraftArray = [
            {
              action: _saveType,
              name: 'Save',
            },
            {
              action: () => this.createDraft(saveDraft),
              name: saveMsg,
            },
            {
              action: this.openTemplateDialog,
              name: 'Save As custom',
            },
          ];
        } else {
          _saveDraftArray = [
            {
              action: () => this.createDraft(saveDraft),
              name: saveMsg,
            },
          ];
        }
      } else if (allowCustomFlag && allowCustomSaveFlag) {
        _saveDraftArray = [{
          action: () => this.createDraft(saveDraft),
          name: saveMsg,
        },
          {
            action: this.openTemplateDialog,
            name: 'Save As custom',
          },
        ];
      } else {
        _saveDraftArray = [{
          action: () => this.createDraft(saveDraft),
          name: saveMsg,
        },
        ];
      }


      // items array contains the menu items data for the mobile hamburger menu.
      const items = [

        {
          action: this.mobileItemClicked(0),
          name: 'File',

          sub_items: _saveDraftArray,
        },
        {
          action: this.mobileItemClicked(1),
          name: 'Edit',

          sub_items: [
            {
              action: this.canvasFunctions('undo'),
              name: 'Undo Changes',
            },
            {
              action: this.canvasFunctions('redo'),
              name: 'Redo Changes',
            },
            {
              action: this.canvasFunctions('copy'),
              name: 'Copy',
            },
            {
              action: this.canvasFunctions('paste'),
              name: 'Paste',
            },
            {
              action: this.canvasFunctions('resize'),
              name: 'Resize Graphic',
            },
          ],
        },
        {
          action: this.mobileItemClicked(2),
          name: 'View',

          sub_items: [

            {
              action: this.canvasFunctions('zoomin'),
              name: 'Zoom In',
            },
            {
              action: this.canvasFunctions('zoomout'),
              name: 'Zoom Out',
            },
            {
              action: this.canvasFunctions('resetzoom'),
              name: 'Reset Zoom',
            },
          ],
        },
        {
          action: this.tryExit,
          name: 'Exit Editor',
        },
      ];

      this.setState({ mobile_menu_items: items });
    });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }


  render() {
    const { classes, slideshow, quickcreate ,CanvasUtil } = this.props;
    const { menu, exit_search } = this.state;
    const prestoSite = localStorage.getItem('prestoSite');

    const _props = {
      open: this.state.exit_dialog,
      title: 'Are you sure that you want to leave the editor?',
      alert_text: 'Any unsaved data will be lost.',
      cancelFunct: () => this.setState({ exit_dialog: !this.state.exit_dialog }),
      cancel_text: 'Cancel',
      okFunct: () => {
        const { search_parameters } = this.props;
        const default_parameters = CreateSearchParameters();

        if (exit_search) {
          this.props.history.push('/create/new');
        } else {
          Object.keys(default_parameters).forEach((key) => {
            search_parameters[key] = default_parameters[key];
          });

          this.props.history.push('/create');
        }
      },
      continue_text: 'Exit Anyway?',
    };

    let logoFragment = (
      <Logo className={classes.logo} />
    );
    if (prestoSite === 'true') {
      logoFragment = (
        <Logo className={classes.logoPresto} />
      );
    }

    let menuHeadingClass = classes.menuHeading;
    if (prestoSite === 'true') {
      menuHeadingClass = classes.menuHeadingPresto;
    }

    let menuToolTipClass = classes.menuToolTip;
    if (prestoSite === 'true') {
      menuToolTipClass = classes.menuToolTipPresto;
    }

    let appbarClass = null;
    if (prestoSite === 'true') {
      appbarClass = classes.appbarPresto;
    }

    return (
      <>
        <SimpleDialog {..._props} />
        <div className={classes.spacerBar} />
        <div
          ref={(node) => {
            this.node = node;
          }}
          className={classes.fullNavBar}
        >
          <AppBar position="static" className={appbarClass}>
            <Toolbar className={classes.toolbar}>
              <Hidden xsDown>
                <IconButton className={classes.backButton} onClick={this.tryExitArrow}>
                  <ArrowLeft />
                </IconButton>
                {logoFragment}
              </Hidden>
              <div className={classes.menus}>
                <Manager>
                  <Target>
                    <div ref={(node) => {
                      this.fileTarget = node;
                    }}
                    >
                      <Button className={menuHeadingClass} onClick={() => this.toggleMenu('file')}>
                        File
                      </Button>
                    </div>
                  </Target>
                  <Popper
                    placement="bottom-start"
                    eventsEnabled={menu === 'file'}
                    className={classNames({ [classes.popperClose]: menu !== 'file' })}
                  >
                    <Grow in={menu === 'file'} id="menu-list-grow" style={{ transformOrigin: '0 0 0' }}>
                      <Paper>
                        <Subscribe to={[MessageContainer]}>
                          {(message) => (
                            <MenuList role="menu" className={classes.menuList}>
                              {this.renderFileMenu(message)}
                            </MenuList>
                          )}
                        </Subscribe>
                      </Paper>
                    </Grow>
                  </Popper>
                </Manager>
                {
                  ((!slideshow || !quickcreate) && !isMobileOnly && !this.isWidgetTemplate())
                  && (
                    <Manager>
                      <Target>
                        <div ref={(node) => {
                          this.fileTarget = node;
                        }}
                        >
                          <Button
                            className={menuHeadingClass}
                            onClick={() => this.openResizeDialog()}
                          >
                            Resize
                          </Button>
                        </div>
                      </Target>
                    </Manager>
                  )
                }
                <Manager>
                  <Target>
                    <div ref={(node) => {
                      this.fileTarget = node;
                    }}
                    >
                      <Tooltip id="undoButton" title="Undo">
                        <IconButton
                          onClick={() => CanvasUtil.undo()}
                          className={menuToolTipClass}
                        >
                          <UndoVariant />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Target>
                </Manager>
                <Manager>
                  <Target>
                    <div ref={(node) => {
                      this.fileTarget = node;
                    }}
                    >
                      <Tooltip id="redoButton" title="Redo">
                        <IconButton
                          onClick={() => CanvasUtil.redo()}
                          className={menuToolTipClass}
                        >
                          <RedoVariant />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Target>
                </Manager>
                <Tooltip title="Toggle Grid">
                  <IconButton
                    onClick={() => {
                      this.props.CanvasUtil.setGridEnable(
                        !this.props.CanvasUtil.getGridEnable(),
                      );

                      this.forceUpdate();
                    }}
                    className={menuToolTipClass}
                  >
                    {
                      this.props.CanvasUtil
                      && this.props.CanvasUtil.getGridEnable()
                        ? <GridOffIcon />
                        : <GridIcon />
                    }
                  </IconButton>
                </Tooltip>
                <Tooltip title="Snap to Grid">
                  <IconButton
                    onClick={() => {
                      this.props.CanvasUtil.setGridSnap(
                        !this.props.CanvasUtil.getGridSnap(),
                      );

                      this.props.CanvasUtil.canvas.renderAll();
                      this.forceUpdate();
                    }}
                    className={menuToolTipClass}
                  >
                    {
                      this.props.CanvasUtil
                      && this.props.CanvasUtil.getGridSnap()
                        ? <MagnetOnIcon />
                        : <MagnetIcon />
                    }
                  </IconButton>
                </Tooltip>
              </div>
              {
                (prestoSite === 'false')
                && (
                  <Tooltip id="supportButton" aria-label="Support/Feedback" title="Support/Feedback">
                    <IconButton
                      onClick={() => { window.open("https://clubessential.my.salesforce-sites.com/contactus", "_blank"); }}
                      color="inherit"
                      aria-label="Support/Feedback"
                    >
                      <MessageText />
                    </IconButton>
                  </Tooltip>
                )
              }
              {/*{*/}
              {/*  (isMobileOnly) ? (*/}

              {/*      (prestoSite === 'false')*/}
              {/*      && (*/}
              {/*        <VirtualTourContext.Consumer>*/}
              {/*          {({ tour_open, set_step, tour_step }) => (*/}
              {/*            <Button*/}
              {/*              data-tour="share"*/}
              {/*              style={{ marginLeft: -11 }}*/}
              {/*              variant="contained"*/}
              {/*              color="secondary"*/}
              {/*              onClick={() => {*/}
              {/*                if (tour_open) {*/}
              {/*                  set_step(tour_step + 1);*/}
              {/*                }*/}

              {/*                this.openShare();*/}
              {/*              }}*/}
              {/*            >*/}
              {/*              Share*/}
              {/*            </Button>*/}
              {/*          )}*/}
              {/*        </VirtualTourContext.Consumer>*/}
              {/*      )*/}
              {/*    )*/}
              {/*    : ((prestoSite !== 'true')*/}
              {/*    && (*/}
              {/*      <VirtualTourContext.Consumer>*/}
              {/*        {({ tour_open, set_step, tour_step }) => (*/}
              {/*          <Button*/}
              {/*            data-tour="share"*/}
              {/*            style={{ marginLeft: 16 }}*/}
              {/*            variant="contained"*/}
              {/*            color="secondary"*/}
              {/*            onClick={() => {*/}
              {/*              if (tour_open) {*/}
              {/*                set_step(tour_step + 1);*/}
              {/*              }*/}

              {/*              this.openShare();*/}
              {/*            }}*/}
              {/*          >*/}
              {/*            Share*/}
              {/*          </Button>*/}
              {/*        )}*/}
              {/*      </VirtualTourContext.Consumer>*/}
              {/*    ))*/}

              {/*}*/}
              {/*{*/}
              {/*  (prestoSite === 'true')*/}
              {/*  && (*/}
              {/*    <Button style={{ marginLeft: 8 }} variant="contained" color="#b2b2b2" onClick={this.prestoCancel}>*/}
              {/*      Cancel*/}
              {/*    </Button>*/}
              {/*  )*/}
              {/*}*/}

              {/*  (prestoSite === 'true')*/}
              {/*  && (*/}
              {/*    <Button style={{ marginLeft: 8 }} variant="contained" color="secondary" onClick={this.prestoShare}>*/}
              {/*      Done*/}
              {/*    </Button>*/}
              {/*  )*/}
              {/*}*/}
              {
                (isMobileOnly) ? (
                      <VirtualTourContext.Consumer>
                        {({ tour_open, set_step, tour_step }) => (
                            <Button
                                data-tour="share"
                                style={{ marginLeft: -11 }}
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                  if (tour_open) {
                                    set_step(tour_step + 1);
                                  }

                                  this.openShare();
                                }}
                            >
                              Share
                            </Button>
                        )}
                      </VirtualTourContext.Consumer>
                    )
                    : (
                        <VirtualTourContext.Consumer>
                          {({ tour_open, set_step, tour_step }) => (
                              <Button
                                  data-tour="share"
                                  style={{ marginLeft: 16 }}
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => {
                                    if (tour_open) {
                                      set_step(tour_step + 1);
                                    }

                                    this.openShare();
                                  }}
                              >
                                Share
                              </Button>
                          )}
                        </VirtualTourContext.Consumer>
                    )
              }
            </Toolbar>
          </AppBar>
          <div style={{
            display: 'inline-block', float: 'right', zindex: '1000', top: '90px',
          }}
          >
            <Tooltip id="zoomresetButton" title="Reset Zoom" style={{ float: 'right' }}>
              <IconButton
                onClick={this.canvasFunctions('resetzoom')}
                color="primary"
              >
                <Magnify />
              </IconButton>
            </Tooltip>
            <Tooltip id="zoomoutButton" title="Zoom Out" style={{ float: 'right' }}>
              <IconButton
                onClick={this.canvasFunctions('zoomout')}
                color="primary"
              >
                <MagnifyMinus />
              </IconButton>
            </Tooltip>
            <Tooltip id="zoominButton" title="Zoom In" style={{ float: 'right' }}>
              <IconButton
                onClick={this.canvasFunctions('zoomin')}
                color="primary"
              >
                <MagnifyPlus />
              </IconButton>
            </Tooltip>
          </div>
          {this.renderAlertDialog()}
          {this.renderExportToWebDialog(this.state.exportToWebText)}
          {this.renderUploadDialog()}
          {this.renderShareDialog()}
          {this.renderTemplateDialog()}
          {this.renderResizeDialog()}
          {this.renderSnackbar()}
        </div>
      </>
    );
  }

  isWidgetTemplate = () => {
    const { CanvasUtil } = this.props;

    if (CanvasUtil) {
      const { canvas } = CanvasUtil;

      if (canvas) {
        return canvas.getObjects().some((object) => (
          (object.type === 'widgetLayout' || object.type === 'widget-box')
        ));
      }
    }

    return true;
  }

  renderFileMenu = (message) => {
    const { draftId, method, rolesQuery, slideshow, quickcreate } = this.props;
    let allowCustomFlag = true;
    const prestoSite = localStorage.getItem('prestoSite');
    let allowCustomSaveFlag = true;
    let saveAsCustomText = null;
    if (rolesQuery && rolesQuery.me && rolesQuery.me.role) {
      allowCustomFlag = rolesQuery.me.role.allowCustomFlag;
      allowCustomSaveFlag = rolesQuery.me.role.allowCustomSaveFlag;
    }

    if (allowCustomFlag && allowCustomSaveFlag) {
      saveAsCustomText = (
        <MenuItem dense onClick={() => this.openTemplateDialog()}>
          Save As Custom Template...
        </MenuItem>
      );
    }


    return (
      <div>
        <Mutation mutation={OVERWRITE_CUSTOM}>
          {(mutation) => (
            (method === 'custom' && allowCustomFlag && allowCustomSaveFlag)
            && (
              <MenuItem dense onClick={() => this.updateCustomTemplate(mutation, message)}>
                Save
              </MenuItem>
            )
          )}
        </Mutation>
        <Mutation mutation={SAVE_DRAFT}>
          {(mutation) => (
            <div>
              {(draftId)
              && (
                <MenuItem dense onClick={() => this.updateDraft(mutation, message)}>
                  Save
                </MenuItem>
              )}
              <MenuItem dense onClick={() => this.createDraft(mutation)}>
                {draftId ? 'Save As New Draft...' : 'Save As Draft...'}
              </MenuItem>
            </div>
          )}
        </Mutation>
        {saveAsCustomText}
        <Divider />
        {
          (!slideshow && !quickcreate && prestoSite === 'true') && (
              <div>
                <MenuItem dense onClick={() => this.exportToWeb()}>
                  Export to Web
                </MenuItem>
              </div>
          )
        }
        <MenuItem dense onClick={() => this.tryExit()}>
          Close Editor
        </MenuItem>
      </div>
    );
  }

  exportToWeb = async () => {
    const {
      CanvasUtil
    } = this.props;
    const json = await CanvasUtil.serializeToJSON()
    const hasEffect = json.objects.filter(obj => obj.type === 'effect')
    this.toggleMenu(null)

    this.setState({disabled_cancel: false, hasEffects: hasEffect.length !== 0 ,exportToWebDialogType: 'nameIt', exportToWebText: '' , imageName: '',openExportToWebDialog: true, uploadingToWeb: false})
  }

  uploadToWeb = async () => {

    const {
      CanvasUtil, clientId
    } = this.props;

    const token = localStorage.getItem('bearer')
    const siteId = localStorage.getItem('siteId')

    return new Promise((resp,rej) => {
      try{
        if (token && siteId) {
          CanvasUtil.serializeToJSON().then(async () => {

            const n = new Date().getTime();

            const bucket = 'ss3-clientmedia';
            const image_url = `https://${bucket}.s3.us-east-2.amazonaws.com/${clientId}/templates/${n}.png`;
            const png_key = `templates/${n}.png`;
            const png_blob = dataURItoBlob(CanvasUtil.toDataURL());
            new ScoreshotsPut(bucket, png_key).put(png_blob, 'image/png', () => {

              fetch(process.env.REACT_APP_SS_API_ENDPOINT + `v1/proxy-request/dev/`,{
                method: 'POST',
                body: JSON.stringify({
                  body: {
                    url: process.env.REACT_APP_PS_WEB_API_ENDPOINT + `action/infographics/saveInfographic.jsp`,
                    token,
                    query_parameters: `image=${image_url}&siteId=${siteId}&fileName=${this.state.imageName}`
                  }
                })
              }).then(resp => resp.json()).then( r => {
                if(r.result.response?.fileName){
                  this.setState({imageName: r.result.response.fileName})
                  resp()
                }
                else
                  rej()
              }).catch((err) => {
                console.log(err)
                rej()
              })
            })
          })
        } else {
          rej()
        }
      } catch(e) {
          rej(e)
      }
    })
  }

  renderEditMenu = () => {
    const { CanvasUtil, slideshow, quickcreate } = this.props;
    let canvas_items = null;
    let slide = slideshow || quickcreate
    if (CanvasUtil) {
      const { canvas } = CanvasUtil;

      if (canvas) {
        canvas_items = (
          <div>
            <MenuItem dense onClick={() => CanvasUtil.undo()}>
              Undo (Ctrl/âŒ˜ + Z)
            </MenuItem>
            <MenuItem dense onClick={() => CanvasUtil.redo()}>
              Redo (Ctrl/âŒ˜ + Y)
            </MenuItem>
            <Divider />
            <MenuItem dense onClick={() => CanvasUtil.copy()}>
              Copy (Ctrl/âŒ˜ + C)
            </MenuItem>
            <MenuItem dense onClick={() => CanvasUtil.paste()}>
              Paste (Ctrl/âŒ˜ + V)
            </MenuItem>
            <Divider />
          </div>
        );
      }
    }

    return (
      <div>
        {canvas_items}
        {
          !slide
          && (
            <MenuItem dense onClick={() => this.openResizeDialog()}>
              Resize Graphic...
            </MenuItem>
          )
        }
      </div>
    );
  }

  renderViewMenu = () => {
    const { CanvasUtil, advanced } = this.props;

    if (!CanvasUtil) {
      return null;
    }

    return (
      <div>
        <MenuItem dense onClick={() => CanvasUtil.zoomIn()}>
          Zoom In (Shift + "+")
        </MenuItem>
        <MenuItem dense onClick={() => CanvasUtil.zoomOut()}>
          'Zoom Out (Shift + "-")
        </MenuItem>
        <MenuItem dense onClick={() => CanvasUtil.resetZoom()}>
          Reset Zoom (Ctrl + 0)
        </MenuItem>
        <Divider />
        <MenuItem dense disabled>
          While Zoomed: (Alt + drag to Pan)
        </MenuItem>
        <Divider />
        <MenuItem dense>
          <FormControlLabel
            label="Advanced Mode"
            control={(
              <Switch
                color="primary"
                checked={advanced}
                onChange={(e) => this.changeAdvancedMode(e.target.checked)}
              />
            )}
          />
        </MenuItem>
      </div>
    );
  }

  renderAlertDialog = () => {
    const {
      alert_title, alert_text, alert_type, alert_callback,disabled_cancel
    } = this.state;
    let continue_text = ''; let
      cancel_text = '';

    switch (alert_type) {
      case 'continue': {
        continue_text = 'Continue';
        cancel_text = 'Cancel';
        break;
      }
      case 'agree': {
        continue_text = 'Agree';
        cancel_text = 'Disagree';
        break;
      }
      default: {
        continue_text = '(null)';
        cancel_text = '(null)';
        break;
      }
    }

    return (
      <Dialog open={Boolean(alert_callback)}>
        <DialogTitle>{alert_title}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{textAlign: 'center'}}>
            {alert_text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {(!disabled_cancel) && (
            <Button color="primary" onClick={() => this.closeAlertDialog(true)}>{cancel_text}</Button>
          )}
          <Button color="primary" onClick={() => this.closeAlertDialog(false)}>{continue_text}</Button>
        </DialogActions>
      </Dialog>
    );
  }


  renderExportToWebDialog = (text) => {
    const {openExportToWebDialog, uploadingToWeb, hasEffects , imageName ,disabled_cancel, exportToWebDialogType} = this.state

    return (
        <Dialog open={openExportToWebDialog} fullWidth maxWidth="xs" onClose={() => this.setState({openExportToWebDialog: false})}>
          <DialogTitle style={{textAlign: 'center'}}>Export to Web</DialogTitle>
          { uploadingToWeb === false ? (<>
            <DialogContent>
              <DialogContentText style={{textAlign: 'center'}}>
                <>
                  {text}
                </>
                {
                  (exportToWebDialogType === 'nameIt') && (
                      <div style={{marginTop: '10px'}}>
                        <TextField
                            fullWidth
                            label="File Name"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={e => this.setState({imageName: e.target.value})}
                        />
                      </div>
                  )
                }
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {(!disabled_cancel) && (
                  <Button color="primary" onClick={() => this.setState({openExportToWebDialog: false})}>Cancel</Button>
              )}
              <Button color="primary" disabled={exportToWebDialogType === 'nameIt' ? !(imageName.replace(/ /g,'').length > 3) : false} onClick={() => {
                  if (exportToWebDialogType === 'nameIt') {
                    if (hasEffects === true) {
                      this.setState({disabled_cancel: false,exportToWebDialogType: 'upload', exportToWebText: 'The image will be exported to your website, however, keep in mind that motion effects will not be included.' , openExportToWebDialog: true, uploadingToWeb: false})
                    } else {
                      this.setState({disabled_cancel: true,uploadingToWeb: true,exportToWebDialogType: 'upload', exportToWebText: '' , openExportToWebDialog: true})
                      this.uploadToWeb().then(() => {
                        this.setState({exportToWebText: `Success! ${this.state.imageName} has been saved in the Infographics folder on your site.`,exportToWebDialogType: '',uploadingToWeb: false, disabled_cancel: true})
                      }).catch(() => {
                        let failureText = <>Export failed. Please contact our <a href="https://clubessential.my.salesforce-sites.com/contactus" rel="noopener noreferrer" target="_blank">Client Care Team</a> for further assistance.</>
                        this.setState({exportToWebText: failureText,exportToWebDialogType: '',uploadingToWeb: false})
                      })
                    }
                  } else if (exportToWebDialogType === 'upload') {
                    this.setState({uploadingToWeb: true})
                    this.uploadToWeb().then(() => {
                      this.setState({exportToWebText: `Success! ${this.state.imageName} has been saved in the Infographics folder on your site.`,exportToWebDialogType: '',uploadingToWeb: false, disabled_cancel: true})
                    }).catch(() => {
                      let failureText = <>Export failed. Please contact our <a href="https://clubessential.my.salesforce-sites.com/contactus" rel="noopener noreferrer" target="_blank">Client Care Team</a> for further assistance.</>
                      this.setState({exportToWebText: failureText,exportToWebDialogType: '',uploadingToWeb: false})
                    })
                  } else {
                    this.setState({openExportToWebDialog: false})
                  }
                }
              }> {exportToWebDialogType === 'upload' ? 'Confirm' : 'Continue'}</Button>
            </DialogActions>
          </>) : (<>
            <DialogContent style={{textAlign: 'center'}}>
              <CircularProgress />
            </DialogContent>
          </>)}
        </Dialog>
    );
  }


  renderUploadDialog = () => {
    const { uploading, upload_failure } = this.state;

    return (
      <UploadingDialog
        open={uploading}
        failure={upload_failure}
        onClose={() => this.closeUploadDialog()}
      />
    );
  }

  renderShareDialog = () => {
    const {
      CanvasUtil, templateId, clientId, slideshow ,json, match
    } = this.props;
    let {
      sharing, width, height, video, rendering, progress,
    } = this.state;
    const preview = this.getPreviewImage();
    sharing = (sharing && match.params.sharing);

    const close_callback = () => {
      if (rendering) {
        this.openAlertDialog(
          CANCEL_SHARE_TITLE,
          CANCEL_SHARE_TEXT,
          (canceled) => {
            if (!canceled) {
              this.closeModals();
            }
          }, 'agree',
        );
      } else {
        this.closeModals();
      }
    };

    return (
      <ShareDialog
        open={sharing}
        width={width}
        height={height}
        clientId={clientId}
        templateId={templateId}
        CanvasUtil={CanvasUtil}
        onClose={close_callback}
        method={this.props.method}
        image={preview}
        video={video}
        rendering={rendering}
        progress={progress}
        slideshow={slideshow}
        json={json}
        quickCreate={this.props.quickcreate}
      />
    );
  }

  renderTemplateDialog = () => (
    <Mutation mutation={CREATE_CUSTOM_TEMPLATE}>
      {(mutation) => (
        <CustomFolderDialog
          open={this.state.custom_template_dialog}
          onClose={() => this.setState({ custom_template_dialog: false })}
          onSave={(e) => this.createCustomTemplate(e, mutation)}
        />
      )}
    </Mutation>
  )

  renderResizeDialog = () => {
    const { resize_dialog } = this.state;
    const { CanvasUtil } = this.props;

    return (
      <ResizeDialog
        open={resize_dialog}
        onClose={() => this.closeResizeDialog()}
        canvas_util={CanvasUtil}
      />
    );
  }

  renderSnackbar = () => {
    const { classes } = this.props;
    const { snackbar } = this.state;
    const open = (snackbar !== '');

    return (
      <Snackbar
        open={open}
        onClose={this.closeSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={6000}
        message={snackbar}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={this.closeSnackbar}
          >
            <Close />
          </IconButton>,
        ]}
      />
    );
  }

  tryExit = () => {
    this.closeModals();
    // TODO: confirmation dialogue here.
    this.setState({
      exit_dialog: true, mobile_menu_popup: false, open_mobile_submenu: false, uploading: false, exit_search: false,
    });
  }

  tryExitArrow = () => {
    this.closeModals();
    // TODO: confirmation dialogue here.
    this.setState({
      exit_dialog: true, mobile_menu_popup: false, open_mobile_submenu: false, uploading: false, exit_search: true,
    });
  }

  openResizeDialog = () => {
    this.setState({
      menu: null,
      resize_dialog: true,
    });
  }

  closeResizeDialog = () => {
    this.setState({
      resize_dialog: false,
    });
  }

  closeModals = () => {
    const { sharing } = this.state;

    this.setState({
      menu: null,
      sharing: false,
      mobile_menu_popup: false,
      open_mobile_submenu: false,
      uploading: false,
    }, () => {
      if (sharing) {
        this.props.history.go(-1);
      }
    });
    this.setState({disabled_cancel: true})
  }

  generateSharePath = (sharing = true) => {
    const { match } = this.props;
    let { method } = match.params;
    let path = `/editor/${method}`;
    if (method === 'slideshow' || method === 'quickcreate') {
      method = match.params.submethod;
      path = `${path}/${method}`;
    }

    if (method !== 'blank') {
      path = `${path}/${match.params.id}`;
    }

    switch (method) {
      case 'blank': {
        const { width, height } = match.params;
        path = `${path}/${width}/${height}`;
        break;
      }
      case 'post': {
        path = `${path}/${match.params.bool}`;
        break;
      }
      default: {
        break;
      }
    }

    if (sharing) {
      path = `${path}/share`;
    }

    return path;
  }

  prepareJob = (job) => {
    const { client } = this.props;

    const colors = {
      primary: client.color1,
      secondary: client.color2,
      tertiary: client.color3,
    };

    RenderJob.remapJobColors(job, colors, 'rgba(0, 0, 0, 0.0)');
  }

  // addClientColors = (data) => {
  //    // get slides and user palette.
  //    let _canvases = data.canvases;
  //    let _palette = data.palette;
  //    // loop through the slides.
  //    for( let index = 0; index < _canvases.length; index++){
  //        // get the current canvas.
  //        let currentCanvas = _canvases[index].canvas;
  //
  //        // loop through current canvas objects.
  //        for( let x = 0; x < currentCanvas.objects.length; x++){
  //            // get the current object.
  //            let currentObject = currentCanvas.objects[x];
  //
  //            // check to see if currentObject has animation key.
  //            if( "animation" in currentObject ){
  //               // get the currentObject's animations.
  //               let animations = currentObject.animation.animations;
  //
  //               //loop over animations.
  //               for( let z = 0; z < animations.length; z++){
  //                   // get the current animation.
  //                   let currentAnimation = animations[z];
  //                   if(!("fillOverride" in currentAnimation) || !("strokeOverride" in currentAnimation)){continue;}
  //
  //                   if( "fillOverride" in currentAnimation && "fill" in currentAnimation){
  //                       let _fillOverride = _palette[currentAnimation['fillOverride']];
  //                       currentAnimation["fill"] = _fillOverride;
  //                   }
  //
  //                   if( "strokeOverride" in currentAnimation && "stroke" in currentAnimation ){
  //                       let _strokeOverride = _palette[currentAnimation['strokeOverride']];
  //                       currentAnimation["stroke"] = _strokeOverride;
  //                   }
  //               }
  //            }
  //        }
  //    }
  //    return _canvases;
  // }

  openShare = async () => {
    const { CanvasUtil, slideshow, templateId } = this.props;
    const { canvas } = CanvasUtil;
    let quickCreate = false;
    if (this.props && this.props.template && this.props.template.isQuickCreate) {
      quickCreate = this.props.template.isQuickCreate;
    }
    if (templateId) {
      const variables = { id: templateId };
      this.props.popularCount({ variables }).then((j) => { });
    }
    if (quickCreate) {
      this.setState({ uploading: true });
      await CanvasUtil.serializeToJSON().then(() => {
        const { onSlideSave, quickcreate } = this.props;

        if (quickcreate && onSlideSave) {
          return onSlideSave();
        }

        return Promise.resolve();
      });
      this.setState({ uploading: false });
    }
    if (slideshow) {
      const { onSlideSave, json, fonts } = this.props;

      this.setState({ uploading: true });

      await CanvasUtil.convertSvgToPng();

      CanvasUtil.convertSlideshowSVGToPng(json).then(async () => {
        if (json.data.length > 1) {
          this.setState({ json_jpg: json.data[0].preview.jpg, json_png: json.data[0].preview.png });
        } else {
          this.setState({ json_jpg: CanvasUtil.toDataURL({ format: 'jpeg' }), json_png: CanvasUtil.toDataURL() });
        }


        if (onSlideSave) {
          await onSlideSave(null, null, 'preview');
        }


        const job = createRenderJob(canvas.workingArea, json.data, json.transitions, fonts);
        this.prepareJob(job);

        CanvasUtil._resize();
        // CanvasUtil._populateSpecialObjects();

        Scoreshots
          .SubmitRender(job)
          .then((url) => {
            const { workingArea } = canvas;
            this.setState({
              video: `${url}?t=${new Date().getTime()}`,
              progress: 0,
              uploading: false,
              sharing: true,
              rendering: true,
              width: +workingArea.width,
              height: +workingArea.height,
            }, () => {
              this.props.history.push(this.generateSharePath());
            });

            return url;
          }).then((url) => {
          const options = {
            period: 5000,
            progress: (progress) => {
              this.setState({ progress });
            },
          };
          return Scoreshots.PollRender(url, options);
        }).then(() => {
          this.setState({ rendering: false });
        })
          .catch(() => {
            this.setState({
              video: null,
              rendering: false,
              snackbar: 'Failed to share',
            });
          });
      });
    } else if (
      CanvasUtil.hasEffect()
      || CanvasUtil.isAnimated()
      || CanvasUtil.hasBgm()
    ) {
      const { fonts } = this.props;
      const { workingArea } = canvas;
      let duration = 10;

      if (CanvasUtil.hasEffect()) {
        const effect = canvas.getObjects('effect')[0];
        duration = effect.duration;
      } else if (CanvasUtil.hasBgm()) {
        duration = (
          MetaData.getMetaData(canvas, 'audio_end')
          - MetaData.getMetaData(canvas, 'audio_start')
        );
      } else {
        const backgroundBoxes = canvas.getObjects('backgroundBox')
          .filter((o) => (
            o.media && o.media.type === 'video'
          ));

        backgroundBoxes.forEach((o) => {
          duration = Math.max(o.media.element.currentDuration, duration);
        });
      }
      const job = new RenderJob(
        workingArea.width,
        workingArea.height,
        duration || 10,
      );

      let jpg; let png;

      await CanvasUtil.convertSvgToPng().then(() => {
        png = CanvasUtil.toDataURL();
        jpg = CanvasUtil.toDataURL({ format: 'jpeg' });
      });

      job.postProcess = this.prepareJob;
      this.setState({ uploading: true });
      const scene = await CanvasUtil.serializeToJSON();

      if (CanvasUtil.hasEffect()) {
        const effect = canvas.getObjects('effect')[0];
        if (effect.effect) {
          job.setMotionGraphic(
            effect.effect.full,
            effect.effect.id,
          );
        }

        if (effect.animation) {
          const animation = Animations.filter((a) => a.id === effect.animation.id)[0];

          if (animation) {
            const a = new animation.type();

            for (const option in effect.animation.options) {
              a.setOption(option, effect.animation.options[option]);
            }

            a.apply({
              objects: scene.objects,
              duration: Math.max(effect.duration || 10, a.getOption('duration') * (effect.loop ? 2 : 1)),
              loop: effect.loop,
              working_area: { width: +workingArea.width, height: +workingArea.height },
            });
          }
        }
      }

      const texts = canvas.getObjects('i-text');
      const used_fonts = [...new Set(texts.map((text) => text.fontFamily))];
      const textboxes = canvas.getObjects('textbox');
      if (textboxes && textboxes.length > 0) {
        for (let j = 0; j < textboxes.length; ++j) {
          const { fontFamily } = textboxes[j];
          if (used_fonts.indexOf(fontFamily) < 0) {
            used_fonts.push(fontFamily);
          }
        }
      }
      job.addFonts(
        fonts.filter((i) => used_fonts.includes(i.fontFamily))
          .map((font) => ({
            name: font.fontFamily,
            type: font.type.toLowerCase(),
            id: font.id,
            key: font.key,
            bucket: font.bucket,
          })),
      );

      job.addScenes([{
        data: scene,
        duration: duration || 10,
      }]);


      this.setState({
        video: '#',
        progress: 0,
        rendering: true,
        uploading: false,
        sharing: true,
        image: { png, jpg },
        width: +workingArea.width,
        height: +workingArea.height,
      }, () => {
        this.props.history.push(this.generateSharePath());
      });

      let current_video = null;

      job.render(
        (progress) => {
          //current_video = job.url
          if (current_video === null) {
            current_video = job.url;
          } else if (job.url !== current_video) {
            return;
          }

          this.setState({ progress });
        },
        (url) => {

          if (url !== current_video) {
             console.log('return')
             return;
           }

          this.setState({ rendering: false, video: url });
        },
      ).catch(() => {
        if (job.url !== current_video) {
          return;
        }

        this.setState({
          video: null,
          rendering: false,
          snackbar: 'Failed to share',
        });
      });
    }else if(quickCreate === true) {
      const { workingArea } = canvas;
      this.setState({
        uploading: false,
        sharing: true,
        width: +workingArea.width,
        height: +workingArea.height,
      }, () => {
        this.props.history.push(this.generateSharePath());
      })
    } else {
      const { workingArea } = canvas;
      this.openUploadDialog();
      CanvasUtil.serializeToJSON().then(() => {
        const png = CanvasUtil.toDataURL();
        const jpg = CanvasUtil.toDataURL({ format: 'jpeg' });

        this.setState({
          uploading: false,
          sharing: true,
          width: +workingArea.width,
          height: +workingArea.height,
          image: { png, jpg },
        }, () => {
          this.props.history.push(this.generateSharePath());
        });
      }).catch(() => {
        this.setUploadFailure(true);
      });
    }
  }

  prestoCancel = async () => {
    let cancelurl = localStorage.getItem('cancelurl');
    const eventid = localStorage.getItem('eventid');

    cancelurl = decodeURIComponent(cancelurl);
    cancelurl = cancelurl.replace('{event_id}', eventid);
    window.parent.postMessage({ url: cancelurl, id: 'scoreshot' }, '*');
  }

  prestoShare = async () => {
    const { CanvasUtil, clientId } = this.props;
    let image;
    const bucket = 'ss3-clientmedia';
    const eventid = localStorage.getItem('eventid');
    let saveurl = localStorage.getItem('saveurl');
    saveurl = decodeURIComponent(saveurl);

    saveurl = saveurl.replace('{event_id}', eventid);

    this.openUploadDialog();

    CanvasUtil.serializeToJSON().then(async () => {
      image = CanvasUtil.toDataURL();
      const blobPreviewPNG = await (await fetch(image)).blob();
      const d = new Date();
      const n = d.getTime();
      const params = {
        Body: blobPreviewPNG,
        Bucket: bucket,
        Key: `${n}.png`,
        ACL: 'public-read',
      };
      const action = new ScoreshotsPut(bucket);
      await action.put(params.Body, 'image/png', null, params.Key);
      this.setState({
        uploading: false,
      });
      const image_url = `https://${bucket}.s3.us-east-2.amazonaws.com/${clientId}/${n}.png`;
      saveurl = saveurl.replace('{image}', image_url);
      window.parent.postMessage({ url: saveurl, id: 'scoreshot' }, '*');
    }).catch(() => {
      this.setUploadFailure(true);
    });
  }

  checkStats = () => {
    const { CanvasUtil } = this.props;
    const { canvas } = CanvasUtil;
    return canvas.getObjects().some(Binding.HasBindings);
  }

  resetStats = () => {
    const { editor } = this.props;
    const sport = editor.getTemplateSport();

    if (sport) {
      sport.reset();
      editor.performBinding();
    }
  }

  saveSportMetadata = () => {
    const { CanvasUtil, template } = this.props;

    if (template && template.categories && template.categories.length > 0) {
      CanvasUtil.setCanvasMetadata('categories', template.categories);
    }
  }

  stashStats = () => {
    const { CanvasUtil, editor } = this.props;
    const { canvas } = CanvasUtil;
    const sport = editor.getTemplateSport();
    const keys = Binding.GatherStats(canvas.getObjects());

    if (keys.length === 0) {
      return;
    }

    const data = {};

    keys.forEach((key) => {
      const variable = sport.getVariableById(key);

      if (!variable) {
        return;
      }

      data[key] = variable.values.map(String);
    });

    if (Object.keys(data).length === 0) {
      return;
    }

    MetaData.setMetaData(canvas, 'xml_stash', data);
  }

  openUploadDialog = () => {
    this.setState({
      uploading: true,
      upload_failure: false,
    });
  }

  setUploadFailure = (failure) => {
    this.setState({
      upload_failure: failure,
    });
  }

  closeUploadDialog = () => {
    this.setState({
      uploading: false,
    });
  }

  openAlertDialog = (title, text, callback, type = 'continue') => {
    this.setState({
      alert_title: title,
      alert_text: text,
      alert_type: type,
      alert_callback: callback,
    });
  }

  closeAlertDialog = (canceled) => {
    let callback = null;

    if (!canceled) {
      const { alert_callback } = this.state;
      callback = alert_callback;
    }

    this.setState({ alert_callback: null }, () => {
      (callback && callback(canceled));
    });
  }

  createDraft = (mutation) => {
    const {
      CanvasUtil, templateId, method, slideshow, quickcreate , onSlideSave, json: outer_json,
    } = this.props;
    const bucket = (slideshow || quickcreate ? 'ss3-slides-drafts' : 'ss3-drafts');
    let draft_id = null; // HACK to pass response draft id among thenables

    this.closeModals();
    this.stashStats();
    this.resetStats();
    this.saveSportMetadata();
    this.openUploadDialog();

    CanvasUtil.serializeToJSON().then(() => {
      if ((slideshow || quickcreate) && onSlideSave ) {
        return onSlideSave();
      }

      return Promise.resolve();
    }).then(() => {
      const { workingArea } = CanvasUtil.canvas;

      const variables = {
        bucket,
        width: workingArea.width,
        height: workingArea.height,
      };

      if (templateId && method !== 'post') {
        variables.templateId = templateId;
      } else if (method === 'draft') {
        if (this.props && this.props.template && this.props.template.id) {
          variables.templateId = this.props.template.id;
        }
      }

      return mutation({ variables });
    }).then((response) => {
      draft_id = response.data.draft.id;
      const body = dataURItoBlob(CanvasUtil.toDataURL());
      const key = `${draft_id}.png`;
      return new ScoreshotsPut(bucket, key).put(body, 'image/png');
    })
      .then(() => {
        const json = ((slideshow || quickcreate) ? outer_json : CanvasUtil.toJSON());
        const body = new Blob([JSON.stringify(json)], { type: 'application/json' });
        const key = `${draft_id}.json`;
        window.Intercom('trackEvent', 'create-draft');
        return new ScoreshotsPut(bucket, key).put(body, 'application/json');
      })
      .then(() => {
        const { history } = this.props;
        const prestoSite = localStorage.getItem('prestoSite');
        if (!prestoSite) {
          window.removeEventListener('beforeunload', EditorUnloadCallback);
        }


        // we do push + replace to perform a full remounting of <Editor>
        // while still keeping the previous route in the history
        if (slideshow) {
          history.push('/');
          history.replace(`/editor/slideshow/draft/${draft_id}`);
        } else if(quickcreate){
          history.push('/');
          history.replace(`/editor/quickcreate/draft/${draft_id}`);
        }
        else {
          history.push('/');
          history.replace(`/editor/draft/${draft_id}`);
        }
      })
      .catch(() => {
        const { uploading } = this.state;

        if (uploading) {
          this.setUploadFailure(true);
        }
      });
  }

  updateDraft = (mutation, message) => {
    const {
      CanvasUtil, draftId, slideshow, quickcreate ,onSlideSave, json: outer_json,
    } = this.props;
    const bucket = ((slideshow || quickcreate) ? 'ss3-slides-drafts' : 'ss3-drafts');

    this.closeModals();
    this.stashStats();
    // this.resetStats();
    this.saveSportMetadata();
    this.openUploadDialog();

    CanvasUtil.serializeToJSON().then(() => {
      if ((slideshow || quickcreate) && onSlideSave) {
        return onSlideSave();
      }

      return Promise.resolve();
    }).then(() => {
      const { workingArea } = CanvasUtil.canvas;

      const variables = {
        bucket,
        id: draftId,
        width: workingArea.width,
        height: workingArea.height,
      };

      return mutation({ variables });
    }).then(() => {
      const body = dataURItoBlob(CanvasUtil.toDataURL());
      const key = `${draftId}.png`;
      return new ScoreshotsPut(bucket, key).put(body, 'image/png');
    })
      .then(() => {
        const json = ((slideshow || quickcreate) ? outer_json : CanvasUtil.toJSON());
        const body = new Blob([JSON.stringify(json)], { type: 'application/json' });
        const key = `${draftId}.json`;
        return new ScoreshotsPut(bucket, key).put(body, 'application/json');
      })
      .then(() => {
        message.setMessage('Saved draft');
        this.closeUploadDialog();
      })
      .catch(() => {
        const { uploading } = this.state;

        if (uploading) {
          this.setUploadFailure(true);
        }
      });
  }

  createCustomTemplate = (e, mutation) => {
    const {
      folder: custom_template_folder,
      name: custom_template_name,
    } = e;

    if (custom_template_name.trim() === '') {
      return;
    }

    const { CanvasUtil, quickcreate } = this.props;

    this.stashStats();
    this.resetStats();
    this.saveSportMetadata();
    this.closeTemplateDialog();
    this.openUploadDialog();


    CanvasUtil.serializeToJSON().then(() => {
      const { slideshow, onSlideSave } = this.props;

      if ((quickcreate || slideshow) && onSlideSave) {
        return onSlideSave();
      }

      return Promise.resolve();
    }).then(() => {
      const { workingArea } = CanvasUtil.canvas;
      let categorylist = null;
      if (this.props && this.props.template && this.props.template.categories) {
        categorylist = this.props.template.categories.map((cat) => cat.id);
      }
      let typelist = null;
      if (this.props && this.props.template && this.props.template.types) {
        typelist = this.props.template.types.map((cat) => cat.id);
      }
      const variables = {
        name: custom_template_name,
        folderId: custom_template_folder,
        width: workingArea.width,
        height: workingArea.height,
        categoryIds: categorylist,
        typeIds: typelist,
        isQuickCreate: quickcreate
      };

      return mutation({ variables });
    }).then((response) => {
      const { id } = response.data.createCustomTemplate;
      return this.sendTemplateToS3(id);
    })
      .then((id) => {
        const { history, slideshow } = this.props;
        const prestoSite = localStorage.getItem('prestoSite');
        if (!prestoSite) {
          window.removeEventListener('beforeunload', EditorUnloadCallback);
        }

        // we do push + replace to perform a full remounting of <Editor>
        // while still keeping the previous route in the history
        if(quickcreate) {
          history.push('/');
          history.replace(`/editor/quickcreate/custom/${id}`)
        }
        else if (slideshow) {
          history.push('/');
          history.replace(`/editor/slideshow/custom/${id}`);
        }
        else {
          history.push('/');
          history.replace(`/editor/custom/${id}`);
        }
      })
      .catch((error) => {
        const { uploading } = this.state;

        if (uploading) {
          this.setUploadFailure(true);
        }
      });
  }

  updateCustomTemplate = (mutation, message) => {
    const { CanvasUtil, match } = this.props;
    const template_id = match.params.id;

    this.stashStats();
    // this.resetStats();
    this.saveSportMetadata();
    this.openUploadDialog();

    CanvasUtil.serializeToJSON().then(() => {
      const { slideshow, onSlideSave, quickcreate } = this.props;

      if ((quickcreate ||slideshow) && onSlideSave) {
        return onSlideSave();
      }

      return Promise.resolve();
    }).then(() => {
      const { workingArea } = CanvasUtil.canvas;

      const variables = {
        id: template_id,
        width: workingArea.width,
        height: workingArea.height,
      };

      return mutation({ variables });
    }).then(() => this.sendTemplateToS3(template_id))
      .then(() => {
        message.setMessage('Saved custom template');
        this.closeUploadDialog();
      })
      .catch(() => {
        const { uploading } = this.state;

        if (uploading) {
          this.setUploadFailure(true);
        }
      });
  }

  sendTemplateToS3 = async (id) => {
    const { CanvasUtil } = this.props;
    const bucket = 'ss3-clientmedia';
    const png_key = `templates/${id}.png`;
    const png_blob = dataURItoBlob(CanvasUtil.toDataURL());
    return (
      new ScoreshotsPut(bucket, png_key).put(png_blob, 'image/png')
        .then(() => {
          const { slideshow, json: outer_json, quickcreate } = this.props;
          const json = ((slideshow || quickcreate) ? outer_json : CanvasUtil.toJSON());
          const json_key = `templates/${id}.json`;
          const json_blob = new Blob([JSON.stringify(json)], { type: 'application/json' });
          return new ScoreshotsPut(bucket, json_key).put(json_blob, 'application/json');
        })
        .then(() => id) // pass on id to next thenable
    );
  }

  changeAdvancedMode = (advanced) => {
    const { toggleAdvanced } = this.props;
    (toggleAdvanced && toggleAdvanced(advanced));
  }

  toggleMenu = (value) => {
    const { menu } = this.state;

    if (!menu || menu !== value) {
      this.setState({ menu: value });
    } else if (menu) {
      this.setState({ menu: null });
    }
  }

  openTemplateDialog = () => {
    this.setState({
      menu: null,
      custom_template_dialog: true,
      custom_template_folder: null,
      custom_template_name: 'My Custom Template',
      mobile_menu_popup: false,
      open_mobile_submenu: false,
    });
  }

  closeTemplateDialog = () => {
    this.setState({
      custom_template_dialog: false,
    });
  }

  changeTemplateName = (name) => {
    this.setState({
      custom_template_name: name,
    });
  }

  changeTemplateFolder = (folder) => {
    this.setState({
      custom_template_folder: folder,
    });
  }

  handleClick = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }

    if (this.state.menu !== null && this.state.menu !== 'resize') {
      this.setState({ menu: null });
    }
  }

  handleClickMobile = (e) => {
    if (this.node2.contains(e.target)) {
      return;
    }

    if (this.state.menu !== null && this.state.menu !== 'resize') {
      this.setState({ menu: null });
    }
  }

  getPreviewImage = () => {
    const { json, slideshow } = this.props;
    const { image } = this.state;
    const preview = {};
    if (slideshow && json && json.data[0].preview) {
      preview.png = this.state.json_png ? this.state.json_png : json.data[0].preview.png;
      preview.jpg = this.state.json_jpg ? this.state.json_jpg : json.data[0].preview.jpg;
    } else if (image) {
      preview.png = image.png;
      preview.jpg = image.jpg;
    } else {
      preview.png = '';
      preview.jpg = '';
    }

    return preview;
  }

  closeSnackbar = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ snackbar: '' });
  }
}

// -------------------------------------------------------------------------- //

export default compose(
  withStyles(STYLES),
  withRouter,
  graphql(ROLES_QUERY, { name: 'rolesQuery' }),
  graphql(CREATE_CUSTOM_TEMPLATE, { name: 'createCustomTemplate' }),
  graphql(SAVE_DRAFT, { name: 'saveDraft' }),
  graphql(OVERWRITE_CUSTOM, { name: 'overwriteCustomTemplate' }),
  graphql(POPULAR_COUNT, { name: 'popularCount' }),
)(EditorNav);

// -------------------------------------------------------------------------- //
