import React, { Component } from 'react';
import { graphql, compose, Query } from 'react-apollo'
import { withStyles } from '@material-ui/core/styles';
import Texture from './Texture';
import gql from 'graphql-tag';

const GET_CLIENT = gql`
  query {
  	me {
  		client {
  			id
  		}
  	}
  }
`;

const styles = theme => ({
  img: {
  	display: 'block',
    backgroundColor: 'black',
    width: '200px',
    margin: 'auto',
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
  }
});

const GET_TEXTURES = gql`
	query (
		$client: ID!
	) {
		textureSearch(
			where: {
				OR: [
					{
						visible: PREMIUM
						clients_some: {
							id: $client
						}
					},
					{
						visible: GLOBAL
					}
				]
			}) {
			id
			name
			image
		}
	}
`;

class TextureSelect extends Component {
	select = (id, image) => (e) => {
		const { onSelect } = this.props;

		if (onSelect) {
			onSelect(id, image);
		}
	}

	render() {
		const { classes, data } = this.props;
		return data && data.me ? (
			<Query query={GET_TEXTURES} variables={{client: data.me.client.id}}>
				{
					({ data }) => {
						return data && data.textureSearch ?
							data.textureSearch.map((texture) => (
								<Texture
									key={texture.id}
									className={classes.img}
									width={200}
									height={200}
									texture={texture.image}
									onClick={this.select(texture.id, texture.image)}
								/>
							)) : null;
					}
				}
			</Query>
		) : null;
	}
}

export default compose(
	graphql(GET_CLIENT)
)(withStyles(styles)(TextureSelect));
