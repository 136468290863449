
// -------------------------------------------------------------------------- //

import { IWidgetFlow } from '../flow';
import { SpacingOption } from '../options/spacing';

// -------------------------------------------------------------------------- //

export class SymmetricalVerticalFlow extends IWidgetFlow {

  _hspacing = null;
  _vspacing = null;
  _map = [];

  constructor(name) {
    super(name);
    this._hspacing = new SpacingOption('hspacing', 'Horizontal Spacing');
    this._vspacing = new SpacingOption('vspacing', 'Vertical Spacing');
  }

  get options() {
    return [ ...super.options, this._hspacing, this._vspacing ];
  }

  get(key) {
    switch (key) {
      case 'hspacing': {
        return this._hspacing.value;
      }
      case 'vspacing': {
        return this._vspacing.value;
      }
      default: {
        return super.get(key);
      }
    }
  }

  set(key, value) {
    switch (key) {
      case 'hspacing': {
        this._hspacing.value = Number(value);
        break;
      }
      case 'vspacing': {
        this._vspacing.value = Number(value);
        break;
      }
      default: {
        super.set(key, value);
        break;
      }
    }
  }

  reset(widgets, size, area) {
    this._hspacing.min = 0.0;
    this._hspacing.max = 0.0;
    this._hspacing.value = 0.0;
    this._vspacing.min = 0.0;
    this._vspacing.max = 0.0;
    this._vspacing.value = 0.0;
    this._map = [];

    if (widgets.length >= 1) {
      let vspacing_max = 0.0;
      let max_rows = null, max_cols = null;
      let rows = 1;
  
      for (; rows <= widgets.length; ++rows) {
        const cols = Math.ceil(widgets.length / rows);
        const fit_x = (area.width >= size.x * cols);
        const fit_y = (area.height >= size.y * rows);
  
        if (!fit_x && !fit_y) {
          break;
        } else if (!fit_x || !fit_y) {
          continue;
        }

        if (max_rows === null || max_rows < rows) {
          max_rows = rows;
        }
  
        if (max_cols === null || max_cols < cols) {
          max_cols = cols;
        }
  
        let vspacing = 0;

        if (rows > 1) {
          vspacing = Math.floor((area.height - size.y * rows) / (rows - 1));
        }
  
        if (vspacing >= size.y) {
          // continue;
        }
  
        const hspacing = Math.floor((area.width - size.x * cols) / (cols - 1));
        this._map.push({ cols, rows, hspacing, vspacing });
        vspacing_max += vspacing;
      }
  
      if (this._map.length !== 0) {
        this._hspacing.max = this._map[0].hspacing;
        this._vspacing.max = vspacing_max;
    
        this._hspacing.value = Math.max(0.0, (
          (this._hspacing.min + this._hspacing.max) * 0.5
        ));
    
        this._vspacing.value = Math.max(0.0, (
          (this._vspacing.min + this._vspacing.max) * 0.5
        ));
      }
    }

    if (this._map.length === 0) {
      const cols = widgets.length;
      this._map.push({ cols, rows: 1, hspacing: 0.0, vspacing: 0.0 });
    }
  }

  apply(widgets, size, area) {
    let index = 0;
    let vspacing = this._vspacing.value;
    let update = false;

    for (; index < this._map.length; ++index) {
      if (vspacing <= this._map[index].vspacing) {
        break;
      }

      vspacing -= this._map[index].vspacing;
    }

    if (index >= this._map.length) {
      index = (this._map.length - 1);
    }

    if (this._hspacing.max !== this._map[index].hspacing) {
      this._hspacing.max = this._map[index].hspacing;
      update = true;
    }

    const rows = this._map[index].rows;
    const cols = this._map[index].cols;

    const left = (area.center.x -
      (this._hspacing.value * (cols - 1) + size.x * cols) * 0.5
    );

    let widget = 0;
    let col = 0;

    while (widget < widgets.length) {
      const x = (left + (size.x + this._hspacing.value) * col);
      const count = Math.min(Math.floor(widgets.length - rows * col), rows);

      if (count === 0) {
        break;
      }

      let y = (area.center.y - (
        size.y * count + // N widgets
        vspacing * (count - 1) // (N-1) spacings
      ) * 0.5);

      for (let i = 0; i < count; ++i) {
        widgets[widget++].move(x, y);
        y += (size.y + vspacing);
      }

      ++col;
    }

    return update;
  }

};

// -------------------------------------------------------------------------- //

