import S3 from 'aws-sdk/clients/s3';
import Rekognition from 'aws-sdk/clients/rekognition';

export const s3factory = () => new S3({
  accessKeyId: localStorage.getItem('aws_id'),
  secretAccessKey: localStorage.getItem('aws_secret'),
  region: 'us-east-2',
});

export const rekognitionFactory = () => new Rekognition({
  accessKeyId: localStorage.getItem('aws_id'),
  secretAccessKey: localStorage.getItem('aws_secret'),
  region: 'us-east-2',
});
