import React, { Component } from 'react';
import { graphql, compose, Query } from 'react-apollo';
import gql from 'graphql-tag';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Delete from 'mdi-material-ui/Delete';
import FacebookBox from 'mdi-material-ui/FacebookBox';
import TwitterBox from 'mdi-material-ui/TwitterBox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { ROLES_QUERY, UserPermissions } from '../user/Permissions';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
  paper: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  socialTitle: {
    flex: 1,
  },
  facebookButton: {
    backgroundColor: '#3b5998',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#20345C',
    },
  },
  socialIcon: {
    marginRight: theme.spacing(1),
    fontSize: 20,
  },
  twitterButton: {
    backgroundColor: '#1DA1F2',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#1271AB',
    },
  },
  socialsContainer: {
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'black',
      flexBasis: '100%',
      maxWidth: '100%',
      flexGrow: 0
    },
    [theme.breakpoints.up('md')]: {
      // backgroundColor: 'black',
      flexBasis: '50%',
      maxWidth: '50%',
      flexGrow: 0
    },
    // backgroundColor: 'black',
  },
  buttonContainer: {
    width: '100%',
    paddingTop: theme.spacing(2),
    textAlign: 'right',
  },
  spacer: {
    paddingTop: theme.spacing(8),
  },
});

const GET_ACCOUNTS = gql`
  query getAccounts {
    me {
      id
      client {
        id
        name
        fbPages {
          id
          name
          fbId
        }
        twitterAccounts {
          id
          name
          twitterId
          screen_name
        }
      }
      role {
        ${ROLES_QUERY}
      }
    }
  }
`;

const DELETE_ACCOUNT = gql`
  mutation deletePage($id: ID!) {
    deleteFromMyAccounts(
      id: $id
    ) {
      id
      twitterAccounts {
        id
        name
        twitterId
        screen_name
      }
    }
  }
`;

const DELETE_PAGE = gql`
  mutation deletePage($id: ID!) {
    deleteFromMyPages(
      id: $id
    ) {
      id
      fbPages {
        id
        name
        fbId
      }
    }
  }
`;

const GET_OAUTH_TOKEN = gql`
  mutation getOauthToken {
    getOauthToken
  }
`;


const startFacebookFlow = () => {
  window.location.href = `https://www.facebook.com/v2.12/dialog/oauth?` +
                         `client_id=${process.env.REACT_APP_FACEBOOK_APP_ID}` +
                         `&redirect_uri=${process.env.REACT_APP_DOMAIN}fbcallback` +
                         `&scope=read_insights,pages_show_list,pages_manage_posts`;
};

class SettingSocial extends Component {

  getTwitters(twitterAccounts) {
    const output = [];
    twitterAccounts.forEach((account) => {
      output.push((
        <ListItem key={account.twitterId}>
          <ListItemAvatar>
            <Avatar src={`https://twitter-avatar.now.sh/${account.screen_name}`} />
          </ListItemAvatar>
          <ListItemText
            primary={account.name}
            secondary={account.screen_name}
          />
          <ListItemSecondaryAction>
            <Tooltip title="Delete">

            <IconButton aria-label={`Delete Twitter Account ${account.name}`} onClick={this.deleteAccount(this, account.id)}>
              <Delete />
            </IconButton>
            </Tooltip>
          </ListItemSecondaryAction>
        </ListItem>));
    });
    return output;
  }

  getFacebooks(fbPages) {
    const output = [];
    fbPages.forEach((page) => {
      output.push((
        <ListItem key={page.id}>
          <ListItemAvatar>
            <Avatar src={`https://graph.facebook.com/v2.12/${page.fbId}/picture?height=40`} />
          </ListItemAvatar>
          <ListItemText
            primary={page.name}
          />
          <ListItemSecondaryAction>
            <Tooltip title="Delete">

            <IconButton onClick={this.deletePage(this, page.id)} aria-label={`Delete Facebook Page ${page.name}`}>
              <Delete />
            </IconButton>
            </Tooltip>
          </ListItemSecondaryAction>
        </ListItem>));
    });
    return output;
  }

    startTwitterFlow = self => () => {
      self.props.getOauthToken()
        .then((response) => {
          window.location.href = response.data.getOauthToken;
        });
    }

    deletePage = (self, id) => () => {
      self.props.deletePage({
        variables: {
          id,
        },
      });
    }

    deleteAccount = (self, id) => () => {
      self.props.deleteAccount({
        variables: {
          id,
        },
      });
    }


    render() {
      const { classes } = this.props;
      return (
        <div id="social" className={classes.spacer}>
          <Query query={GET_ACCOUNTS}>
          {({ data, loading }) => {
            if (!data || loading) {
              return null;
            }

            const user_permissions = new UserPermissions(data.me.role);

            if (!user_permissions.get('social_posting:write')) {
              return null;
            }

            return (
                <Paper className={classes.paper}>
                  <Typography variant="h4" gutterBottom>
                    Social Accounts
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={8}>
                      <Typography variant="subtitle1" gutterBottom>
                        By logging into your social accounts, we can automatically
                        post and schedule graphics for you (giving you one less
                        thing to do). We also use this to track your graphics'
                        performance, and provide detailed metrics.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h5" gutterBottom className={classes.socialTitle}>
                        Facebook
                      </Typography>
                      <List>
                        {this.getFacebooks(data.me.client.fbPages)}
                      </List>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography variant="h5" gutterBottom className={classes.socialTitle}>
                        Twitter
                      </Typography>
                      <List>
                        {
                          this.getTwitters(data.me.client.twitterAccounts)
                        }
                      </List>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className={classes.buttonContainer}>
                        <Button variant="contained" className={classes.facebookButton} onClick={startFacebookFlow}>
                          <FacebookBox className={classes.socialIcon} />
                          Add Pages
                        </Button>
                      </div>

                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className={classes.buttonContainer}>
                        <Button variant="contained" className={classes.twitterButton} onClick={this.startTwitterFlow(this)}>
                          <TwitterBox className={classes.socialIcon} />
                          Add Account
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
            );
          }}
          </Query>
        </div>
      );
    }
}

export default compose(
  graphql(DELETE_PAGE, { name: 'deletePage' }),
  graphql(DELETE_ACCOUNT, { name: 'deleteAccount' }),
  graphql(GET_OAUTH_TOKEN, { name: 'getOauthToken' }),
)(withStyles(styles)(SettingSocial));
