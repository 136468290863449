import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Editor from './Editor';

const GET_CUSTOM_TEMPLATE_INFO = gql`
  query getCustomTemplate($id: ID!){
    customTemplate(
      id: $id
    ) {
      id
      template {
        id
        width
        height
        isQuickCreate
        disableXMLImport
        categories {
          id
          name
        }
        types {
          id
          name
        }
        url
      }
    }
  }
`;

export default (props) => (
    <Query
        query={GET_CUSTOM_TEMPLATE_INFO}
        key="getInfo"
        variables={{
          id: props.match.params.id,
        }}
    >
      {({ data }) => (data && data.customTemplate
          ? (
              <Editor
                  template={data.customTemplate.template}
                  match={props.match}
                  quickCreate={props.match.params.method === 'quickcreate' || data.customTemplate.template.isQuickCreate}
              />
          )
          : <div />)}
    </Query>
);
