import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Link, withRouter} from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import FolderImage from 'mdi-material-ui/FolderImage';
import FolderStar from 'mdi-material-ui/FolderStar';
import ViewList from 'mdi-material-ui/ViewList';
import PencilBox from 'mdi-material-ui/PencilBox';
import Timetable from 'mdi-material-ui/Timetable';
import ExtendedFab from '../button/ExtendedFab';
import {compose, graphql} from "react-apollo";
import gql from "graphql-tag";

const ROLES_QUERY = gql`
  query {
    me {
      id
      role {
        id
        name
        restrictCategoryFlag
        categories {
          id
          name
        }
        allowCustomFlag
        allowCustomSaveFlag
        allowCustomFolderSaveFlag
        restrictCustomFlag
        customFolders {
          id
          name
        }
      }
    }
  }
`;

const styles = theme => ({
  controls: {
    position: 'sticky',
    top: 80,
    width: '95%',
  },
  activeLink: {
    backgroundColor: '#EEE',
  },
  activeIcon: {
    color: theme.palette.secondary.dark,
  },
  list: {
    marginTop: 32,
  },
});

class CreateNav extends Component {
  render() {
    const { classes, type, rolesQuery } = this.props;
    let allowCustomFlag = true;
    let customTemplateLink = null;
    if (rolesQuery && rolesQuery.me && rolesQuery.me.role) {
      allowCustomFlag = rolesQuery.me.role.allowCustomFlag;
    }

    if (allowCustomFlag) {
      customTemplateLink = (
        <ListItem
          button
          dense
          component={Link}
          to="/create/custom"
          className={type === 'custom' ? classes.activeLink : null}
        >
          <ListItemIcon>
            <FolderImage
              className={type === 'custom' ? classes.activeIcon : null}
            />
          </ListItemIcon>
          <ListItemText primary="Custom" />
        </ListItem>
      );
    }

    return (
      <div
        variant="permanent"
        className={classes.controls}
      >
        <ExtendedFab to="/create/new" />
        <List className={classes.list}>
          <ListItem
            button
            dense
            id="publishedLink"
            alt="published"
            component={Link}
            to="/create/posts"
            className={type === 'posts' ? classes.activeLink : null}
          >
            <ListItemIcon>
              <ViewList
                className={type === 'posts' ? classes.activeIcon : null}
              />
            </ListItemIcon>
            <ListItemText primary="Published" />
          </ListItem>
          <ListItem
            button
            dense
            id="scheduled"
            alt="scheduled"
            component={Link}
            to="/create/scheduled"
            className={type === 'scheduled' ? classes.activeLink : null}
          >
            <ListItemIcon>
              <Timetable
                className={type === 'scheduled' ? classes.activeIcon : null}
              />
            </ListItemIcon>
            <ListItemText primary="Scheduled" />
          </ListItem>
          <ListItem
            button
            dense
            id="draftsLink"
            component={Link}
            to="/create/drafts"
            className={type === 'drafts' ? classes.activeLink : null}
          >
            <ListItemIcon>
              <PencilBox
                className={type === 'drafts' ? classes.activeIcon : null}
              />
            </ListItemIcon>
            <ListItemText primary="My Drafts" />
          </ListItem>
          <ListSubheader>Team Templates</ListSubheader>
          {customTemplateLink}
          <ListItem
            button dense
            component={Link}
            to="/create/premium"
            className={type === 'premium' ? classes.activeLink : null}
          >
            <ListItemIcon>
              <FolderStar
                className={type === 'premium' ? classes.activeIcon : null}
              />
            </ListItemIcon>
            <ListItemText primary="Premium"/>
          </ListItem>
        </List>
      </div>
    );
  }

}

// -------------------------------------------------------------------------- //

export default compose(
  graphql(ROLES_QUERY, { name: 'rolesQuery' }),
  withRouter,
  withStyles(styles),
)(CreateNav);

// -------------------------------------------------------------------------- //
