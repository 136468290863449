
// -------------------------------------------------------------------------- //

import * as React from 'react';

import FullscreenDialog from './FullscreenDialog';
import GraphicPreview from './GraphicPreview';
import SendToPhoneDialog from './SendToPhoneDialog';

import {
  withStyles,
} from '@material-ui/core';

// -------------------------------------------------------------------------- //

const styles = () => ({

  root: {
    display: 'flex',
  },

  flex: {
    flex: 1,
  },

});

// -------------------------------------------------------------------------- //

class SlidePreviewDialog extends React.Component {

  state = {
    mode: 'video',
    phone: false,
  }

  openPhoneDialog = () => {
    this.setState({ phone: true });
  }

  closePhoneDialog = () => {
    this.setState({ phone: false });
  }

  onModeChange = (mode) => {
    this.setState({ mode });
  }

  componentDidUpdate(previous) {
    const { open } = this.props;

    if (open && !previous.open) {
      this.setState({
        mode: 'video',
        phone: false,
      });
    }
  }

  renderDescription() {
    const { rendering } = this.props;
    const { mode } = this.state;

    return (
      (mode === 'video' && rendering)
      ? 'Your slide is rendering and will be ready for preview soon.'
      : 'You can preview and download your current slide below.'
    );
  }

  render() {
    const { classes, open, video, image, rendering, progress, onClose } = this.props;
    const { mode, phone } = this.state;

    // tried doing it the 'margin: 0 auto' way but I need the description
    // and toolbar to match the width of the preview at all times.
    return (
      <FullscreenDialog open={open} title="Preview" onClose={onClose}>
        <div className={classes.root}>
          <div className={classes.flex}/>
          <GraphicPreview
            slideshow
            mode={mode}
            video={video}
            image={image}
            rendering={rendering}
            progress={progress}
            maxHeight="calc(100vh - 180px)"
            description={this.renderDescription()}
            onModeChange={this.onModeChange}
            onPhoneClick={this.openPhoneDialog}
          />
          <div className={classes.flex}/>
        </div>
        <SendToPhoneDialog
          open={phone}
          video={mode === 'video' && video}
          image={mode === 'image' && image}
          onCancel={this.closePhoneDialog}
        />
      </FullscreenDialog>
    )
  }

}

// -------------------------------------------------------------------------- //

export default withStyles(styles)(SlidePreviewDialog);

// -------------------------------------------------------------------------- //
