import React, { Component } from 'react';
import { Query, graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import InfiniteScroll from 'react-infinite-scroller';

import Tip from './Tip';
import Crumbs from './Crumbs';
import DropDowns from './DropDowns';
import TemplatePreview from '../../create/TemplatePreview2';

const styles = (theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '20%',
  },
  img: {
    display: 'block',
    width: '100%',
    marginBottom: theme.spacing(2),
    borderRadius: 2,
    cursor: 'pointer',
    boxShadow: theme.shadows[2],
    transition: theme.transitions.create(['box-shadow'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.short,
    }),
    '&:hover': {
      boxShadow: theme.shadows[5],
    },
  },
  templates: {
    overflow: 'auto',
    flexGrow: 1,
    flexShrink: 1,
    padding: theme.spacing(1),
  },
});

const GET_ALL_TEMPLATES = gql`
query getTemplates {
  templateSearch(
    where: {
      customDetails: null
      visible : VISIBLE
    }
    orderBy: visible_DESC
  ) {
    id
    image
    width
    height
    isSlideShow
    description
    categories{
      id
    }
    types{
      id
    }
  }
}
`;

const FILTER_TEMPLATES = gql`
query templateSearch(
  $where: TemplateWhereInput,
  $orderBy: TemplateOrderByInput,
  $skip: Int,
  $after: String,
  $before: String,
  $first: Int,
  $last: Int
) {
  templateSearch(
    where: $where,
    orderBy: $orderBy,
    skip: $skip,
    after: $after,
    before: $before,
    first: $first,
    last: $last
  ) {
      id
      name
      width
      height
      image
      url
      categories {
        id
      }
    }
  }
`;

const ROLES_QUERY = gql`
  query {
    me {
      id
      role {
        id
        name
        restrictCategoryFlag
        categories {
          id
          name
        }
        allowCustomFlag
        allowCustomSaveFlag
        allowCustomFolderSaveFlag
        restrictCustomFlag
        customFolders {
          id
          name
        }
      }
    }
  }
`;

const GET_TEMPLATE_S3 = (templateId) => { const cache_buster = `${+new Date()}`; return fetch(`https://s3.us-east-2.amazonaws.com/ss3-templates/${templateId}.json?${cache_buster}`).then((res) => res.json()); };
class Templates extends Component {
  constructor() {
    super();

    this.state = {
      types: [],
      category: '',
      templates: [],
      whereObject: {
        customDetails: null,
        visible: 'VISIBLE',
      },
      dynamicTemplates: {},
      dynamicTemplatesHash: {},
      loading: true,
    };
  }

  UNSAFE_componentWillMount() {
    const { canvas, slideshow } = this.props;
    const { whereObject } = this.state;
    whereObject.isSlide = slideshow;
    whereObject.isSlideShow = false;

    if (slideshow && canvas) {
      whereObject.width = +canvas.workingArea.width;
      whereObject.height = +canvas.workingArea.height;
    }
    this.initilizeMe();
  }

  initilizeMe = () => {
    this.props.getAllTemplates
      .refetch((res) => res.json())
      .then((j) => j.data.templateSearch)
      .then((data) => {
        const promises = [];
        for (let i = 0; i < data.length; i++) {
          const { id } = data[i];
          const promise = GET_TEMPLATE_S3(id).then((mad) => {
            for (let o = 0; o < mad.objects.length; o++) {
              if ('dataBinding' in mad.objects[o] || mad.objects[o].type === 'widgetLayout') {
                return data[i];
              }
            }
          }).catch((err) => console.log('error: ', err));
          promises.push(promise);
        }

        Promise.all(promises).then((j) => {
          const dynamics = j.filter((k) => k);
          const dynamicTemplates = [];

          for (let xx = 0; xx < dynamics.length; xx++) {
            const catObj = {};
            const typeObj = {};
            for (let zz = 0; zz < dynamics[xx].categories.length; zz++) {
              const xID = dynamics[xx].categories[zz].id;
              catObj[xID] = xID;
            }

            for (let zz = 0; zz < dynamics[xx].types.length; zz++) {
              const xID = dynamics[xx].types[zz].id;
              typeObj[xID] = xID;
            }
            const __obj = { ...dynamics[xx], _catObj: catObj, _typeObj: typeObj };
            dynamicTemplates.push(__obj);
          }

          const dynamicTemplatesHash = {}; // create hash
          for (let x = 0; x < dynamicTemplates.length; x++) {
            dynamicTemplatesHash[dynamicTemplates[x].id] = ''; // create hash
          }

          this.setState({ dynamicTemplates, dynamicTemplatesHash, loading: false });
        });
      }).catch((err) => console.log('error'));
  }

  handleChange = (event) => {
    const { canvas, slideshow } = this.props;

    this.setState({ [event.target.name]: event.target.value }, () => {
      const where = {
        customDetails: null,
        visible: 'VISIBLE',
        isSlide: slideshow,
        isSlideShow: false,
      };
      const _or = [];
      let _type_array = this.state.types;

      if (_type_array.length === 1 && _type_array[0] === -1) {
        _type_array = [];
      }

      if (_type_array.length > 0) {
        _or.push({ types_some: { id_in: _type_array } });
      }

      if (this.state.category !== -1) {
        _or.push({ categories_some: { id_in: this.state.category } });
      }

      if (slideshow && canvas) {
        where.width = +canvas.workingArea.width;
        where.height = +canvas.workingArea.height;
      }

      if (_or.length > 0) {
        where.OR = _or;
      }

      this.setState({ whereObject: where });
    });
  };

  swapTemplate = (template) => () => {
    this.props.CanvasUtil.swapTemplate(template);
  }

  loadMore = (fetchMore, data, afterId) => () => {
    fetchMore({
      variables: {
        after: afterId,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return { ...prev, templateSearch: [...prev.templateSearch, ...fetchMoreResult.templateSearch] };
      },
    });
  }

  render() {
    const { classes, slideshow } = this.props;
    return (
      <div className={classes.root}>
        <Crumbs
          title={slideshow ? 'Swap Slides' : 'Swap Templates'}
        />
        <Hidden xsDown>
          <Tip
            heading="Warning:"
            text={slideshow ? 'Selecting a template below will replace your current slide.' : 'Changing templates will reset much of your graphic.'}
          />
        </Hidden>

        <DropDowns
          types={this.state.types}
          category={this.state.category}
          handleChange={this.handleChange}
        />

        <Query
          query={FILTER_TEMPLATES}
          variables={{
            where: this.state.whereObject,
            first: 15,
            orderBy: 'createdAt_DESC',
          }}
        >
          { ({ data, fetchMore }) => {
            const output = [];
            let afterId = null;
            if (data.templateSearch) {
              data.templateSearch.forEach((template) => {
                afterId = template.id;
                const allowedCategories = [];
                const { me } = this.props.rolesQuery;
                let restrictCategoryFlag = false;
                let showTemplate = false;
                if (me && me.role && me.role.categories) {
                  me.role.categories
                    .forEach((cat) => {
                      allowedCategories.push(cat.id);
                    });
                  restrictCategoryFlag = me.role.restrictCategoryFlag;
                }
                if (restrictCategoryFlag) {
                  showTemplate = false;
                  if (template.categories) {
                    template.categories
                      .forEach((cat) => {
                        if (allowedCategories.includes(cat.id)) {
                          showTemplate = true;
                        }
                      });
                  }
                  if (showTemplate) {
                    output.push((
                      <TemplatePreview
                        onClick={this.swapTemplate(template)}
                        key={template.id}
                        template={template}
                        className={classes.img}
                        category={this.state.category}
                        dynamicTemplatesHash={this.state.dynamicTemplatesHash}
                        alternateOnClick
                      />
                    ));
                  }
                } else {
                  output.push((
                    <TemplatePreview
                      onClick={this.swapTemplate(template)}
                      key={template.id}
                      template={template}
                      className={classes.img}
                      category={this.state.category}
                      dynamicTemplatesHash={this.state.dynamicTemplatesHash}
                      alternateOnClick
                    />
                  ));
                }
              });
            }
            return (
              <div className={classes.templates}>
                <InfiniteScroll
                  pageStart={0}
                  initialLoad={false}
                  loadMore={this.loadMore(fetchMore, data, afterId)}
                  hasMore
                  useWindow={false}
                >
                  {output}
                </InfiniteScroll>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default compose(
  graphql(ROLES_QUERY, { name: 'rolesQuery' }),
  graphql(GET_ALL_TEMPLATES, { name: 'getAllTemplates' }),
)(withStyles(styles)(Templates));
