import React, {Component} from 'react';
import {withStyles, Divider, Typography} from "@material-ui/core";

const STYLES = (theme) => ({
  autotext: {
    fontFamily: 'inherit',
    color: 'inherit',
  },
  highlightedAutotext: {
    color: theme.palette.primary.light,
    fontFamily: 'inherit',
  },
  subheading: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  shadowEditor: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  highlight: {
    color: theme.palette.primary.light,
  },
  selected: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  stroke: {
    display: 'flex',
  },
  strokeSlider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  stroke_symbol_select: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  ReactFontPicker_Wrapper: {
    position: 'relative',
    display: 'block',
    height: '35px',
    borderBottom: '1px #000000 solid',
  },
});

class FontPicker extends Component {
  constructor(props) {
    super(props);

    // Bind component methods to this context
    this.onWrapperClick = this.onWrapperClick.bind(this);
    this.onOptionClick = this.onOptionClick.bind(this);

    this.state = {
      isOptionsVisible: false,
      selectedFont: this.props.value
    }
  }

  componentDidMount() {


    // Create stylesheet on the fly
    var styles = document.createElement("style");

    styles.innerHTML = `
			.ReactFontPicker_Wrapper:hover {
				cursor: pointer;
			}
			.ReactFontPicker_SelectedOption {
				position: absolute;
				height: 25px;
				line-height: 35px;
				font-size: 15px;
			}
			.ReactFontPicker_Button {
				position: absolute;
				right: 5px;
				top: 12px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 5px 6px 0 6px;
				border-color: #000000 transparent transparent transparent;
			}
			.ReactFontPicker_Button:hover {
				cursor: pointer;
			}
			.ReactFontPicker_Options {
				position: absolute;
				top: -7.5px;
				width: 100%;
				height: auto;
				max-height: 200px;
				overflow-y: scroll;
				padding-bottom: 15px;
				padding-top: 15px;
				float: left;
				background-color: #fff;

				box-shadow: 0px 0px 6px #ddd;
				z-index: 999;

				-webkit-transition: .15s all ease-in-out;
				-moz-transition: .15s all ease-in-out;
				-ms-transition: .15s all ease-in-out;
				-o-transition: .15s all ease-in-out;
				transition: .15s all ease-in-out;
			}
			.ReactFontPicker_OptionsHidden {
				position: absolute;
				top: -7.5px;
				width: 100%;
				height: 0px;
				padding-bottom: 0;
				padding-top: 0;
				background-color: #fff;
				overflow: hidden;

				box-shadow: 0px 0px 6px #ddd;

				-webkit-transition: .15s all ease-in-out;
				-moz-transition: .15s all ease-in-out;
				-ms-transition: .15s all ease-in-out;
				-o-transition: .15s all ease-in-out;
				transition: .15s all ease-in-out;
			}
			.ReactFontPicker_Option {
				display: block;
				width: calc(100% - 20px);
				padding-left: 10px;
				padding-right: 10px;
				height: 30px;
				line-height: 30px;
				float: left;
        margin-left:8px;


				-webkit-transition: .1s all ease-in-out;
				-moz-transition: .1s all ease-in-out;
				-ms-transition: .1s all ease-in-out;
				-o-transition: .1s all ease-in-out;
				transition: .1s all ease-in-out;
			}
			.ReactFontPicker_Option:hover {
				background-color: #eee;
				margin-left:8px;
			}
			div.ReactFontPicker_Wrapper .ripple {
				position: relative;
				overflow: hidden;
			}
			div.ReactFontPicker_Wrapper .ripple-effect{
				position: absolute;
				border-radius: 50%;
				width: 50px;
				height: 50px;
				background: white;
				animation: ripple-animation 1.8s;
			}
			@keyframes ripple-animation {
				from {
					transform: scale(1);
					opacity: 0.4;
				}
				to {
					transform: scale(100);
					opacity: 0;
				}
			}
			.ReactFontPicker {
				display: block;
			}
		`;
    document.getElementsByTagName("head")[0].appendChild(styles);
  }

  onWrapperClick() {
    this.setState({isOptionsVisible: !this.state.isOptionsVisible});
  }

  onOptionClick(e, font) {
    e.stopPropagation();

    if (this.state.isOptionsVisible === false)
      return;

    if (typeof this.props.onChange == "function")
      this.props.onChange(font);

    this.setState({isOptionsVisible: false, selectedFont: font});
  }

  render() {

    const {fonts, previews, activeColor, classes, usedfonts} = this.props;
    const value = this.props.value || this.state.selectedFont;
    return (
        <div className={classes.ReactFontPicker_Wrapper} onClick={this.onWrapperClick}>

          {/* Selected option */}
          <div className="ReactFontPicker_SelectedOption">
            {value}
          </div>

          {/* Downdown button */}
          <div className="ReactFontPicker_Button"></div>

          {/* Options list */}
          <div className={this.state.isOptionsVisible ? "ReactFontPicker_Options" : "ReactFontPicker_OptionsHidden"}>
            <div style={{ clear:'both' }}>
              <Typography variant="caption" style={{ color: '#8E8E8E', display: 'block', marginLeft: 8 }}>
                Used Fonts
              </Typography>
            </div>

            {usedfonts.map((n, i) => {

              var style = {};

              if (value === n)
                style.color = activeColor;

              if (previews)
                style.fontFamily = n;

              return (
                <div className="ReactFontPicker_Option ripple"
                     style={style}
                     key={i}
                     onMouseUp={(e) => this.onOptionClick(e, n)}
                     onClick={(e) => this.onOptionClick(e, n)}
                >
                  {n}
                </div>
              );
            })}
            <div style={{ clear:'both' }}>
            <Divider/>
              <Typography variant="caption" style={{ color: '#8E8E8E', display: 'block', marginLeft: 8, marginTop: 8 }}>
                Other Fonts
              </Typography>

            </div>
            {fonts.map((n, i) => {

              var style = {};

              if (value === n)
                style.color = activeColor;

              if (previews)
                style.fontFamily = n;

              return (
                <div className="ReactFontPicker_Option ripple"
                     style={style}
                     key={i}
                     onMouseUp={(e) => this.onOptionClick(e, n)}
                     onClick={(e) => this.onOptionClick(e, n)}
                >
                  {n}
                </div>
              );
            })}
          </div>
        </div>
    );
  }
}

FontPicker.defaultProps = {
  label: "",
  previews: true,
  fonts: [
    "Arial",
    "Arial Narrow",
    "Arial Black",
    "Courier New",
    "Georgia",
    "Lucida Console",
    "Lucida Sans Unicode",
    "Tahoma",
    "Times New Roman",
    "Verdana"
  ],
  activeColor: "#64B5F6",
  value: ""
}

// -------------------------------------------------------------------------- //

export default withStyles(STYLES, {withTheme: true})(FontPicker);

// -------------------------------------------------------------------------- //
