import React, { Component } from 'react';
import { graphql, compose, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ScoreshotsPut from '../../scoreshotsapi/Put';

const ADD_TEXTURE = gql`
	mutation {
		addTexture {
			id
		}
	}
`;

const DELETE_TEXTURE = gql`
	mutation ($id: ID!) {
		removeTexture(id: $id) {
			id
		}
	}
`;

const UPDATE_TEXTURE = gql`
	mutation (
		$id: ID!
		$name: String
		$image: String
	) {
		updateTexture(
			id: $id
			name: $name
			image: $image) {
				id
		}
	}
`;

class NewTextureDialog extends Component {
	constructor() {
		super();

		this.state = {
			newTextureName: "New Texture",
			newTextureID: null,
			newTextureImageURL: null
		};

		this.action = new ScoreshotsPut("ss3-assets");
	}

	closeNewTextureDialog = () => {
		this.setState({
			newTextureName: "New Texture",
			newTextureID: null,
			newTextureImageURL: null
		});

		if (this.props.close) {
			this.props.close();
		}
	}

	cancelNewTextureDialog = () => {
		if (this.state.newTextureID) {
			this.props.deleteTexture({
				id: this.state.newTextureID
			});
		}

		if (this.state.newTextureImageURL) {
			/* TODO: Delete object. */
			this.closeNewTextureDialog();
		} else {
			this.closeNewTextureDialog();
		}
	}

	updateTextureName = (e) => {
		this.setState({
			newTextureName: e.target.value
		});
	}

	addTexture = (func) => {
		if (!this.state.newTextureID) {
			this.props.addTexture({
				data: { name: this.state.newTextureName }
			}).then(({data}) => {
				if (data && data.addTexture) {
					this.setState({
						newTextureID: data.addTexture.id
					});

					func(true, data.addTexture.id);
				} else {
					func(false);
				}
			});
		} else {
			func(true, this.state.newTextureID);
		}
	}

	uploadTexture = (e) => {
		let file = e.target.files[0];
		this.addTexture((success, newTextureID) => {
			if (success) {
				const params = {
					Body: file,
					Key: `textures/${newTextureID}.png`,
					ACL: 'public-read'
				};

				this.action.put(params.Body, "image/png", () => {
					const image = `https://ss3-assets.s3.us-east-2.amazonaws.com/${params.Key}`
					this.props.updateTexture({
						variables: { id: newTextureID, image }
					});
				}, params.Key, "");
			}
		});
	}

	createTexture = () => {
		this.addTexture((success, newTextureID) => {
			if (success) {
				this.props.updateTexture({
					variables: { id: newTextureID, name: this.state.newTextureName }
				});
			}

			this.closeNewTextureDialog();
		});
	}

	render() {
		return (
			<Dialog
				open={this.props.open}
				onClose={this.closeNewTextureDialog}>
				<Mutation mutation={ADD_TEXTURE}>
					{
						(addTexture) => (
							<React.Fragment>
								<DialogTitle>New Texture</DialogTitle>
								<DialogContent>
									<TextField
										autoFocus
										margin="dense"
										label="Name"
										type="text"
										fullWidth
										onChange={this.updateTextureName}
										value={this.state.newTextureName} />
			            <input
			              accept="image/png"
			              style={{display:'none'}}
			              id="upload-texture"
			              type="file"
			              name="file"
			              onChange={this.uploadTexture}
			            />
	                <label htmlFor="upload-texture">
	                  <Button component="span" size="small">
	                    Upload Texture
	                  </Button>
	                </label>
	                <DialogActions>
		                <Button onClick={this.closeNewTextureDialog} color="primary">
		                  Cancel
		                </Button>
		                <Button onClick={this.createTexture} color="primary">
		                  Create
		                </Button>
		               </DialogActions>
								</DialogContent>
							</React.Fragment>
						)
					}
				</Mutation>
			</Dialog>
		);
	}
}

export default compose(
	graphql(ADD_TEXTURE, { name: 'addTexture' }),
	graphql(UPDATE_TEXTURE, { name: 'updateTexture' }),
	graphql(DELETE_TEXTURE, { name: 'deleteTexture' })
)(NewTextureDialog);
