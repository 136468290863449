
// -------------------------------------------------------------------------- //

import * as MetaData from './metadata';

// -------------------------------------------------------------------------- //

const NICE_NAMES = {
  'backgroundBox': 'Background',
  'logoBox': 'Logo',
  'cutoutBox': 'Cutout',
  'i-text': 'Text',
  'rect': 'Rectangle',
  'circle': 'Circle',
  'polygon': 'Polygon',
  'polyline': 'Line',
  'effect': 'Effect',
  'barGraph': 'Bar Graph',
  'sliderBar': 'Slider Bar',
  'quickCreate': 'Quick Create',
  'widget': 'Widget',
};

// -------------------------------------------------------------------------- //

export function getNiceName(object, index = null) {
  if (!object) {
    return null;
  }

  if (object.name) {
    let name = object.name;
    const widget_index = MetaData.getMetaData(object, 'widget_index');

    if (widget_index !== null) {
      name = `${name} #${widget_index + 1}`;
    }

    return name;
  } else {
    let object_type = object.type;

    if (object_type in NICE_NAMES) {
      object_type = NICE_NAMES[object_type];
    }

    if (index !== null && index > 0) {
      return `${object_type} ${index + 1}`;
    } else {
      return object_type;
    }
  }
}

// -------------------------------------------------------------------------- //
