import effects from '../../../components/editor/dict/effects';
import { detect } from 'detect-browser';

class Effect {
  constructor(fabric) {
    this.class = fabric.util.createClass(fabric.Object, {
      selectable: false,
      type: 'effect',
      evented: false,
      originX: 'center',
      originY: 'center',
      renderBelow: false,
      stateProperties: fabric.Object.prototype.stateProperties.concat('renderBelow', 'effect'),
      visible: true,
      initialize(options = {}) {
        if (!options.effect) {
          options.effect = 'none';
        }

        // expected options:
        // effect: 'name of effect from components/editor/dict/effects'
        this.callSuper('initialize', options);

        this.animation = options.animation;
        this.duration = options.duration;
        this.loop = options.loop;

        this.setEffect(options.effect);

        this.on('added', () => {
          this.off('added');
          this.position();
        });
        this.on('modified', this.position);
      },
      setEffect(id) {
        this.effect = effects[id];

        if (this.effect) {
          this.width = this.effect.size.width;
          this.height = this.effect.size.height / 2;
        }

        const { name } = detect();
        if (name !== 'ios' && this.effect) {
          // create some dom elements to do the rendering with
          this.videoEl = document.createElement('video');
          this.buffer = document.createElement('canvas');
          this.output = document.createElement('canvas');
          this.image = new Image();
          if (this.effect) {
            this.buffer.width = this.effect.size.width;
            this.buffer.height = this.effect.size.height;
            this.output.width = this.effect.size.width;
            this.output.height = this.effect.size.height / 2;
            this.loaded = false;
            this.videoEl.addEventListener('play', () => {
              this.visible = true;
              this.loaded = true;
              this.dirty = true;
              if (this.canvas) {
                this.canvas.requestRenderAll();
                const render = () => {
                  if (this.canvas && this.visible && this.loaded) {
                    this.canvas.requestRenderAll();
                    this.dirty = true;
                    this.request = fabric.util.requestAnimFrame(render);
                  }
                };
                fabric.util.requestAnimFrame(render);
              }
            });
            this.videoEl.setAttribute('loop', 'loop');
            this.videoEl.setAttribute('crossOrigin', 'crossOrigin');
            this.videoEl.setAttribute('autoplay', 'autoplay');
            this.videoEl.setAttribute('src', effects[id].url);

            if (this.canvas) {
              this.position();
            }
          }
        } else {
          this.loaded = false;
          this.visible = false;
        }
      },
      position() {
        this.left = this.canvas.workingArea.width / 2;
        this.top = this.canvas.workingArea.height / 2;

        // determine minimum scale
        const scale = Math.max(
          this.canvas.workingArea.height / this.height,
          this.canvas.workingArea.width / this.width,
        );
        this.scaleX = scale;
        this.scaleY = scale;

        // determine where to place the object.
        if (this.renderBelow) {
          let i = 0;
          this.canvas.getObjects().forEach((obj, index) => {
            if (
              obj.type === 'backgroundBox' ||
              (
                obj.parent &&
                obj.parent.type === 'backgroundBox'
              )
            ) {
              i = index;
            }
          });
          this.canvas.moveTo(this, i + 1);
        } else {
          this.canvas.moveTo(this, this.canvas.getObjects().length - 1);
        }
      },
      _render(ctx) {
        if (this.loaded && this.visible) {
          const { buffer, videoEl, output } = this;
          const { width, height } = this.effect.size;
          const buffCtx = buffer.getContext('2d');
          buffCtx.drawImage(videoEl, 0, 0);
          let alphaData;
          let image;
          if (this.effect.alphaOnly) {
            image = buffCtx.getImageData(0, 0, width, height / 2);
            alphaData = buffCtx.getImageData(0, height / 2, width, height / 2).data;
          } else {
            alphaData = buffCtx.getImageData(0, 0, width, height / 2).data;
            image = buffCtx.getImageData(0, height / 2, width, height / 2);
          }

          for (let i = 3, len = image.data.length; i < len; i += 4) {
            let r = alphaData[i - 3];
            let g = alphaData[i - 2];
            let b = alphaData[i - 1];
            image.data[i] = (r + r + b + g + g + g) / 6;
          }
          output.getContext('2d').putImageData(image, 0, 0);
          ctx.drawImage(this.image, -this.width / 2, -this.height / 2);
          this.image.src = output.toDataURL();
        }
      },
      toObject(propertiesToInclude) {
        return fabric.util.object.extend(
          this.callSuper('toObject', propertiesToInclude),
          {
            effect: this.effect ? this.effect.id : 'none',
            animation: this.animation,
            duration: this.duration,
            loop: this.loop
          },
        );
      },
    });
    this.fromObject = (object, callback) => fabric.Object._fromObject('Effect', object, callback);
  }
}
export default Effect;
