
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Typography,
  withStyles,
} from '@material-ui/core';

import Slider from 'rc-slider';

import { IWidgetOption } from '../option';

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  heading: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    fontSize: '12px',
    textTransform: 'uppercase',
  },
});

// -------------------------------------------------------------------------- //

const Render = withStyles(STYLES, { withTheme: true })((props) => {
  const { classes, theme, option, onValueChanged, onAfterChange } = props;

  return (
    <>
      <Typography variant="h6" className={classes.heading}>
        {option.name}
      </Typography>
      <Slider
        min={option.min} max={option.max} value={option.value}
        onChange={(value) => {
          option.value = value;
          (onValueChanged && onValueChanged(value));
        }}
        onAfterChange={(value) => {
          option.value = value;
          (onAfterChange && onAfterChange(value));
        }}
        trackStyle={[{
          backgroundColor: theme.palette.primary.light,
        }]}
        handleStyle={[{
          borderColor: theme.palette.primary.light,
        }]}
      />
    </>
  );
});

// -------------------------------------------------------------------------- //

export class SpacingOption extends IWidgetOption {

  _minValue = null;
  _maxValue = null;

  constructor(key, name) {
    super(key, name);
    this._value = 0.0;
  }

  get min() { return this._minValue; }
  set min(x) { this._minValue = x; }

  get max() { return this._maxValue; }
  set max(x) { this._maxValue = x; }

  get value() {
    if (this._minValue !== null && this._value < this._minValue) {
      return this._minValue;
    } else if (this._maxValue !== null && this._value > this._maxValue) {
      return this._maxValue;
    } else {
      return this._value;
    }
  }

  set value(x) {
    this._value = Number(x);
  }

  render(props) {
    if (this._minValue !== null && this._minValue === this._maxValue) {
      return null;
    }

    return <Render {...props} option={this}/>;
  }

};

// -------------------------------------------------------------------------- //
