
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Divider,
  Typography,
  withStyles,
} from '@material-ui/core';

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
});

// -------------------------------------------------------------------------- //

const ScheduledHint_ = (props) => (
  <div className={props.classes.root}>
    <Typography id="main-content" variant="h4" gutterBottom>
      Scheduled Posts
    </Typography>
    <Typography variant="body2" gutterBottom>
      Scheduled posts are set to go out at some point in the future.
      You can view, delete, and edit them here.
    </Typography>
    <Divider/>
  </div>
);

// -------------------------------------------------------------------------- //

export const ScheduledHint = withStyles(STYLES)(ScheduledHint_);

// -------------------------------------------------------------------------- //
