
class canvasExtensions {
  constructor(fabric, canvas) {
    this.fabric = fabric;
    this.canvas = canvas;
  }

  addRect = () => {
    const rect = new this.fabric.Rect({
      left: 100,
      top: 100,
      fill: '#CCC',
      width: 60,
      height: 60,
      strokeWidth: 0,
    });
    this.canvas.add(rect);
    return rect;
  }

  addBarGraph = () => {
    const barGraph = new this.fabric.BarGraph({
      left: 100,
      top: 100,
      fill: '#CCC',
      width: 60,
      height: 60,
      strokeWidth: 0,
    });
    this.canvas.add(barGraph);
    return barGraph;
  }

  addSliderBar = () => {
    const sliderBar = new this.fabric.SliderBar({
      left: 270,
      top: 120,
      width: 250,
      height: 50,
      fontFamily: 'Squada One',
      fill: '#3f51b5',
      stroke: '#f0f0f0',
      fontColor: '#f0f0f0',
      strokeWidth: 3,
      sliderBarValue: 0,
      sliderBarMin: 0,
      sliderBarMax: 10,
    });
    this.canvas.add(sliderBar);
    return sliderBar;
  }

  addQuickCreateBar = () => {
    const quickCreate = new this.fabric.QuickCreate({
      left: 270,
      top: 120,
      width: 250,
      height: 50,
      fontFamily: 'Squada One',
      fill: '#3f51b5',
      stroke: '#f0f0f0',
      fontColor: '#f0f0f0',
      strokeWidth: 3,
      sliderBarValue: 0,
      sliderBarMin: 0,
      sliderBarMax: 10,
    });
    this.canvas.add(quickCreate);
    return quickCreate;
  }

  addParText = () => {
    const parText = new this.fabric.ParText({
      left: 270,
      top: 120,
      width: 150,
      height: 150,
      fontFamily: 'Roboto',
      fill: '#111',
      stroke: '#eee',
      strokeWidth: 3,
      strokeSymbol: 2,
    });
    this.canvas.add(parText);
    return parText;
  }

  addCircle = () => {
    const circle = new this.fabric.Circle({
      radius: 30,
      fill: '#CCC',
      left: 100,
      top: 100,
      strokeWidth: 0,
    });
    this.canvas.add(circle);
    return circle;
  }

  addTriangle = () => {
    const triangle = new this.fabric.Triangle({
      width: 60,
      height: 60,
      fill: '#CCC',
      left: 100,
      top: 100,
      strokeWidth: 0,
    });
    this.canvas.add(triangle);
    return triangle;
  }

  addText = () => {
    let text;
    const active = this.canvas.getActiveObject();
    const points = this.getPoints();
    if (active && points) {
      this.makePointsRelative(points, active);

      text = new this.fabric.IText('Your Text Here', {
        left: active.left,
        top: active.top,
        clipPath: points,
        fontFamily: 'Roboto',
        fill: '#000',
        strokeWidth: 0,
      });
    } else {
      text = new this.fabric.IText('Your Text Here', {
        left: 100,
        top: 100,
        fontFamily: 'Roboto',
        fill: '#000',
        strokeWidth: 0,
      });
    }

    this.canvas.add(text);
    return text;
  }

  addTextarea = () => {
    const text = new this.fabric.Textbox('Your Text Here', {
      left: 100,
      top: 100,
      width: 100,
      fontFamily: 'Roboto',
      fill: '#000',
      strokeWidth: 0,
    });
    this.canvas.add(text);
    return text;
  }

  addTextarea = () => {
    const text = new this.fabric.Textbox('Your Text Here', {
      left: 100,
      top: 100,
      width: 100,
      fontFamily: 'Roboto',
      fill: '#000',
      strokeWidth: 0,
    });
    this.canvas.add(text);
    return text;
  }

  addBackground = () => {
    const { canvas } = this;

    let background;
    const active = canvas.getActiveObject();
    const points = this.getPoints();
    if (active && points) {
      background = new this.fabric.BackgroundBox(
          points,
          {
            left: active.left,
            top: active.top,
            scaleX: active.scaleX,
            scaleY: active.scaleY,
            points,
            strokeWidth: 0,
          },
      );
    } else {
      background = background = new this.fabric.BackgroundBox([
        { x: 0, y: 0 },
        { x: canvas.workingArea.width, y: 0 },
        { x: canvas.workingArea.width, y: canvas.workingArea.height },
        { x: 0, y: canvas.workingArea.height },
      ], { strokeWidth: 0 });
    }
    canvas.insertAt(background, 0);
    return background;
  }

  addLogo = () => {
    let logo;
    const active = this.canvas.getActiveObject();
    const points = this.getPoints();
    if (active && points) {
      logo = new this.fabric.LogoBox(
          points,
          {
            left: active.left,
            top: active.top,
            scaleX: active.scaleX,
            scaleY: active.scaleY,
            points,
            strokeWidth: 0,
          },
      );
    } else {
      logo = new this.fabric.LogoBox([
        { x: 100, y: 100 },
        { x: 200, y: 100 },
        { x: 200, y: 200 },
        { x: 100, y: 200 },
      ], { strokeWidth: 0 });
    }

    this.canvas.add(logo);
    return logo;
  }

  addWidget = () => {
    const widget = new this.fabric.WidgetLayout({
      top: 100,
      left: 100,
      width: 200,
      height: 200,
      strokeWidth: 0,
    });
    this.canvas.add(widget);
    return widget;
  }

  makePointsRelative = (points, active) => {
    for (let i = 0; i < points.length; ++i) {
      points[i].x -= active.left;
      points[i].y -= active.top;
    }
  }

  getPoints = () => {
    const active = this.canvas.getActiveObject();

    if (!active) {
      return null;
    }

    if (active.type === 'polygon') {
      return [...active.points];
    } if (active.type === 'rect') {
      return [
        { x: active.left, y: active.top },
        { x: active.left + active.width, y: active.top },
        { x: active.left + active.width, y: active.top + active.height },
        { x: active.left, y: active.top + active.height },
      ];
    } if (active.type === 'triangle') {
      const halfWidth = active.width / 2;
      const halfHeight = active.height / 2;

      return [
        { x: -halfWidth + active.left, y: halfHeight + active.top },
        { x: active.left, y: -halfHeight + active.top },
        { x: halfWidth + active.left, y: halfHeight + active.top },
      ];
    } if (active.type === 'circle') {
      const { radius } = active;
      const segments = Math.floor(Math.max((radius * Math.max(active.scaleX, active.scaleY)) / 2, 16));
      const angleWidth = active.endAngle - active.startAngle;

      const points = [];
      for (let i = 0; i < segments; ++i) {
        const angle = angleWidth * (i / (segments - 1)) + active.startAngle;
        const x = Math.cos(angle) * radius + active.left;
        const y = Math.sin(angle) * radius + active.top;
        points.push({ x, y });
      }

      return points;
    }
  }

  addCutout = () => {
    let cutout;
    const active = this.canvas.getActiveObject();
    const points = this.getPoints();
    if (active && points) {
      cutout = new this.fabric.CutoutBox(
          points,
          {
            left: active.left,
            top: active.top,
            scaleX: active.scaleX,
            scaleY: active.scaleY,
            points,
            strokeWidth: 0,
          },
      );
    } else {
      cutout = new this.fabric.CutoutBox([
        { x: 100, y: 100 },
        { x: 200, y: 100 },
        { x: 200, y: 200 },
        { x: 100, y: 200 },
      ], { strokeWidth: 0 });
    }

    this.canvas.add(cutout);
    return cutout;
  }

  addPoly = () => {
    const { canvas } = this;
    if (!canvas.polyDrawing) {
      canvas.polyDrawing = true;
      canvas.on('mouse:down', this.drawPoint(this));
    } else {
      canvas.polyDrawing = false;
      delete canvas.polyPoints;
      canvas.off('mouse:down');
    }
  }

  addPolyLine = () => {
    const { canvas } = this;
    if (!canvas.polyLineDrawing) {
      canvas.polyLineDrawing = true;
      canvas.on('mouse:down', this.drawLinePoint(this));
    } else {
      canvas.polyLineDrawing = false;
      delete canvas.polyLinePoints;
      canvas.off('mouse:down');
    }
  }

  drawLinePoint = (self) => (e) => {
    const { canvas } = self;
    const mouseX = (e.e.offsetX - canvas.horizOffset) / canvas.baseZoom;
    const mouseY = (e.e.offsetY - canvas.vertOffset) / canvas.baseZoom;
    if (!canvas.polyLinePoints) {
      canvas.polyLinePoints = [
        {
          x: mouseX,
          y: mouseY,
        },
      ];
    } else {
      const lastPoint = canvas.polyLinePoints[canvas.polyLinePoints.length - 1];
      // shift key is being held down.
      if (e.e.shiftKey) {
        const drawHorizontal = Math.abs(mouseX - lastPoint.x) > Math.abs(mouseY - lastPoint.y);

        if (drawHorizontal) {
          // draw along x axis
          canvas.polyLinePoints.push({
            x: mouseX,
            y: lastPoint.y,
          });
        } else {
          // draw along y axis
          canvas.polyLinePoints.push({
            x: lastPoint.x,
            y: mouseY,
          });
        }
      } else {
        canvas.polyLinePoints.push({
          x: mouseX,
          y: mouseY,
        });
      }
      canvas.remove(canvas.polyLineShape);
    }
    const poly = new this.fabric.Polyline(
        canvas.polyLinePoints,
        {
          stroke: 'red',
          fill: 'transparent',
        },
    );
    canvas.add(poly);
    canvas.setActiveObject(poly);
    canvas.polyLineShape = poly;
    canvas.requestRenderAll();
  }

  drawPoint = (self) => (e) => {
    const { canvas } = self;
    const mouseX = (e.e.offsetX - canvas.horizOffset) / canvas.baseZoom;
    const mouseY = (e.e.offsetY - canvas.vertOffset) / canvas.baseZoom;
    if (!canvas.polyPoints) {
      canvas.polyPoints = [
        {
          x: mouseX,
          y: mouseY,
        },
      ];
    } else {
      if (e.e.shiftKey) {
        const lastPoint = canvas.polyPoints[canvas.polyPoints.length - 1];
        const drawHorizontal = Math.abs(mouseX - lastPoint.x) > Math.abs(mouseY - lastPoint.y);

        if (drawHorizontal) {
          // draw along x axis
          canvas.polyPoints.push({
            x: mouseX,
            y: lastPoint.y,
          });
        } else {
          // draw along y axis
          canvas.polyPoints.push({
            x: lastPoint.x,
            y: mouseY,
          });
        }
      } else {
        canvas.polyPoints.push({
          x: mouseX,
          y: mouseY,
        });
      }
      canvas.remove(canvas.polyShape);
    }

    const poly = new this.fabric.Polygon(
        canvas.polyPoints,
        {
          fill: 'red',
        },
    );
    canvas.add(poly);
    canvas.setActiveObject(poly);
    canvas.polyShape = poly;
    canvas.requestRenderAll();
  }

  groupObjects = () => {
    const { canvas } = this;
    if (!canvas.getActiveObject()) {
      return;
    }
    if (canvas.getActiveObject().type !== 'activeSelection') {
      return;
    }
    canvas.getActiveObject().toGroup();
    canvas.requestRenderAll();
  }

  ungroupObjects = () => {
    const { canvas } = this;
    if (!canvas.getActiveObject()) {
      return;
    }
    if (canvas.getActiveObject().type !== 'group') {
      return;
    }
    canvas.getActiveObject().toActiveSelection();
    canvas.requestRenderAll();
  }

  addLine = () => {
    const { canvas } = this;
    const line = new this.fabric.Line([20, 50, 250, 50], {
      fill: 'red',
      stroke: 'red',
      strokeWidth: 5,
    });

    canvas.add(line);
  }
}

export default canvasExtensions;
