import { Component } from 'react';
import history from '../../history';
//import FullscreenLoader from "../loading/FullscreenLoader";

class Presto extends Component {
    constructor() {
        super();
        this.state = {
            id: '',
            dashboard: false,
            loading: true,
        };
    }

    componentDidMount() {
        const cacheBuster = `?t=${new Date().getTime()}`;
        let APIEndpoint = process.env.REACT_APP_SS_API_ENDPOINT;
        let dashboardState = false;
        APIEndpoint = APIEndpoint.replace('api.php?q=', '');
        fetch(`${APIEndpoint}presto/tokens/index.php?id=${this.props.match.params.id}&cacheBuster=${cacheBuster}`)
            .then((response) => response.json())
            .then((jsonData) => {
                console.log('jsonData', jsonData);
                localStorage.setItem('prestoSite', 'true');
                localStorage.setItem('eventid', jsonData.eventId);
                localStorage.setItem('saveurl', jsonData.saveUrl);
                localStorage.setItem('cancelurl', jsonData.cancelUrl);
                localStorage.setItem('categoryid', jsonData.categoryId);
                localStorage.setItem('imageurl', jsonData.imageUrl);
                localStorage.setItem('bearer', jsonData.bearer);
                localStorage.setItem('teamids', jsonData.teamIds);
                localStorage.setItem('siteId', jsonData.siteId);
                if (jsonData.eventId === 'none' && jsonData.teamIds === '') {
                    dashboardState = true;
                }
                const prestoAPIEndpoint = process.env.REACT_APP_PS_GAMEDAY_API_ENDPOINT;
                if (jsonData.eventId && jsonData.eventId !== '' && jsonData.eventId !== 'none') {
                    fetch(`${prestoAPIEndpoint}events/${jsonData.eventId}`, {
                        headers: {
                            Authorization: `Bearer ${jsonData.bearer}`,
                        },
                    })
                        .then((response) => response.json())
                        .then((jsonDataEvent) => {
                            if (jsonDataEvent && jsonDataEvent.data && jsonDataEvent.data.teams && jsonDataEvent.data.teams.awayTeam && jsonDataEvent.data.teams.awayTeam.sportId) {
                                localStorage.setItem('sportid', jsonDataEvent.data.teams.awayTeam.sportId);
                            } else if (jsonDataEvent && jsonDataEvent.data && jsonDataEvent.data.teams && jsonDataEvent.data.teams.homeTeam && jsonDataEvent.data.teams.homeTeam.sportId) {
                                localStorage.setItem('sportid', jsonDataEvent.data.teams.homeTeam.sportId);
                            }
                        })
                        .catch((error) => {
                            console.error('error', error);
                        });
                }
                this.setState({
                    id: this.props.match.params.id,
                    dashboard: dashboardState,
                    loading: false,
                });
            })
            .catch((error) => {
                console.error('error', error);
            });
    }

    render() {
        const userID = localStorage.getItem('user_id');

        // if (this.state.loading) return <FullscreenLoader/>;
        if ((!this.props.auth.isAuthenticated(true) || userID !== this.state.id) && this.state.id !== '') {
            this.props.auth.login(this.state.id, 'jduewi83jc82kf9', null, this.handleDone, null);
            this.props.auth.getClient(this.state.id, true);
        } else if (this.props.auth.isAuthenticated(true) && this.state.id !== '' && this.state.dashboard === false) {
            history.push('/create/new');
        } else if (this.props.auth.isAuthenticated(true) && this.state.id !== '' && this.state.dashboard === true) {
            history.push('/');
        }
        return null;
    }
}

export default (Presto);
