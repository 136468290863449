
// -------------------------------------------------------------------------- //

import * as React from 'react';

import {
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControl,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  ChevronDown,
} from 'mdi-material-ui';

import { compose, graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';

import {
  PALETTE_PERMISSION,
  PERMISSIONS,
  ROLES_QUERY,
  UserPermissions,
} from '../user/Permissions';

// -------------------------------------------------------------------------- //

const CLIENT_ROLES = gql`
  query {
    clientRoles {
      ${ROLES_QUERY}
    }
  }
`;

const ADD_ROLE = gql`
  mutation addRoleToClient($name: String!) {
    addRoleToClient(
      name: $name
    ) {
      id
    }
  }
`;

const RENAME_ROLE = gql`
  mutation renameRole($roleId: ID!, $name: String!) {
    renameRole(
      roleId: $roleId
      name: $name
    ) {
      id
    }
  }
`;

const DELETE_ROLE = gql`
  mutation deleteRole($roleId: ID!) {
    deleteRole(
      roleId: $roleId
    ) {
      id
    }
  }
`;

const ASSIGN_ROLE = gql`
  mutation assignRole($userId: ID!, $roleId: ID!) {
    assignRole(
      userId: $userId,
      roleId: $roleId,
    ) {
      id
    }
  }
`;

const UPDATE_ROLE = gql`
  mutation (
    $roleId: ID!
    $restrictCategoryFlag: Boolean!
    $allowCustomFlag: Boolean!
    $allowCustomSaveFlag: Boolean!
    $allowCustomFolderSaveFlag: Boolean!
    $restrictCustomFlag: Boolean!
    $categories: [ID!]
    $customFolders: [ID!]
    $restrictSocialFlag: Boolean!
    $twitterAccounts: [ID!]
    $facebookPages: [ID!]
    $manageSocialAccounts: Boolean!
    $manageTeamColors : Boolean!
    $restrictTeamColors : String!
    $restrictMoveCanvas : Boolean!
    $restrictRemoveBG : Boolean!
    $restrictGeneralSettings : Boolean!
    $restrictFonts: Boolean!
    $manageFonts: Boolean!
    $fontsAllowed: [ID!]
  ) {
    updateRole(
      roleId: $roleId,
      restrictCategoryFlag: $restrictCategoryFlag,
      allowCustomFlag: $allowCustomFlag,
      allowCustomSaveFlag: $allowCustomSaveFlag,
      allowCustomFolderSaveFlag: $allowCustomFolderSaveFlag,
      restrictCustomFlag: $restrictCustomFlag,
      categories: $categories,
      customFolders: $customFolders,
      restrictSocialFlag: $restrictSocialFlag,
      twitterAccounts: $twitterAccounts,
      facebookPages: $facebookPages,
      manageSocialAccounts: $manageSocialAccounts,
      manageTeamColors: $manageTeamColors,
      restrictTeamColors: $restrictTeamColors,
      restrictMoveCanvas: $restrictMoveCanvas,
      restrictRemoveBG: $restrictRemoveBG,
      restrictGeneralSettings: $restrictGeneralSettings,
      restrictFonts: $restrictFonts,
      manageFonts: $manageFonts,
      fontsAllowed: $fontsAllowed
    ) {
      id
    }
  }
`;

const USERS_QUERY = gql`
  query {
    me {
      id
      isActive
      type
      client {
        id
        users {
          id
          email
          firstname
          lastname
          type
          isActive
          role {
            id
          }
        }
        fbPages {
          id
          name
        }
        twitterAccounts {
          id
          name
          screen_name
        }
      }
      role {
        id
        name
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const GET_SPORTS = gql`
  query {
    categories {
      id,
      name
    }
  }
`;

// -------------------------------------------------------------------------- //

const GET_CUSTOM_FOLDERS = gql`
  query getOrphanedFolders {
    getOrphanedFolders {
      id
      name
    }
  }
`;

// -------------------------------------------------------------------------- //

const GET_MY_FONTS = gql`
  query {
    myFonts {
      id
      fontFamily
      type
      bucket
      key
    }
  }
`;


// the transform prop here is to fix a known chrome issue
// see mui-org/material-ui/commit/9b9421c for details
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MENU_PROPS = {
  PaperProps: {
    style: {
      maxHeight: (ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP),
      transform: 'translate3D(0,0,0)',
    },
  },
};

// -------------------------------------------------------------------------- //

function gatherPermissions(permission, permissions) {
  let copy = { ...permission };

  copy.subpermission = (
    permissions
      .filter(p => p.subpermission === permission.key)
      .map(p => gatherPermissions(p, permissions))
  );

  return copy;
}

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  multi_select: {
    width: '33%',
  },
  paper: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  permission_header: {
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
    },
  },
  permission_list: {
    marginBottom: theme.spacing(2),
  },
  permission_root: {
    display: 'block',
    width: '100%',
  },
  permission_actions: {
    margin: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: '0 0 auto',
  },
  placeholder: {
    opacity: 0.42,
  },
});

// -------------------------------------------------------------------------- //

class SettingRoles extends React.Component {

  state = {
    sports: null,
    custom_folders: null,
    fonts_allowed: null,
    permission_roles: null,
    permission_dirty: null,
    permission_categories: [],
    add_role_dialog_open: false,
    delete_role_dialog_open: false,
    rename_role_dialog_open: false,
    selected_role: -1,
    new_role_name: '',
    deleteId: null,
    renameId: null,
  }

  componentDidMount() {
    const unique_filter = (item, index, self) => (
      self.indexOf(item) === index
    );

    const categories = (
      PERMISSIONS
      .filter(permission => Boolean(permission.category))
      .map(permission => permission.category)
      .filter(unique_filter)
    );

    let permission_categories = [];

    for (let i = 0; i < categories.length; ++i) {
      let category = {
        name: categories[i]
      };

      const permissions = (
        PERMISSIONS
          .filter(permission => permission.category === categories[i])
          .filter(permission => !permission.subpermission)
      );

      category.permissions = (
        permissions
          .map(permission => gatherPermissions(permission, PERMISSIONS))
      );

      permission_categories.push(category);
    }

    this.setState({ permission_categories });
    const { get_sports, get_custom_folders, get_fonts } = this.props;

    get_fonts.refetch().then(response => {
      const { data } = response;
      if (data === null) {
        this.setState({ fonts_allowed: [] });
        return;
      }

      const fonts_allowed = data.myFonts.map(font => ({
        name: font.fontFamily + " " + font.type.charAt(0).toUpperCase() + font.type.toLowerCase().slice(1),
        id: font.id,
      }));
      fonts_allowed.sort((a, b) => (a.name > b.name) ? 1 : -1)

      this.setState({ fonts_allowed });
    });

    get_sports.refetch().then(response => {
      const { data } = response;

      if (data === null) {
        this.setState({ sports: [] });
        return;
      }

      const sports = data.categories.map(category => ({
        name: category.name,
        id: category.id,
      }));

      this.setState({ sports });
    });

    get_custom_folders.refetch().then(response => {
      const { data } = response;

      if (data === null) {
        this.setState({ custom_folders: [] });
        return;
      }

      const custom_folders = data.getOrphanedFolders.map(folder => ({
        name: folder.name,
        id: folder.id,
      }));

      this.setState({ custom_folders });
    });

    this.props.usersQuery.refetch();
  }

  componentDidUpdate() {
    let { permission_roles } = this.state;

    if (permission_roles) {
      return;
    }

    const roles = this.getClientRoleData();

    if (roles) {
      permission_roles = roles.map(role => new UserPermissions(role));

      this.setState({
        permission_roles,
        permission_dirty: roles.map(() => false),
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { permission_roles } = this.state;
    const me = this.getDataMe();
    const prestoSite = localStorage.getItem('prestoSite');
    if (me && me.role && me.role.name && prestoSite !== 'true' && prestoSite !== '0' && prestoSite !== '1') {
      if (me.role.name === 'Superuser') {
        return (
          <Paper className={classes.paper}>
            <Typography variant="h4" component="h2" gutterBottom>
              User Permissions
            </Typography>
            <Typography variant="subtitle1" component="h3" gutterBottom>
              By default, a user will get access to your custom templates and media library.
              Other permissions are available; you can customize them here.
            </Typography>
            {
              permission_roles
                ? this.renderRoles()
                : <CircularProgress size={48}/>
            }
            <div className={classes.permission_actions}>
              <Button
                color="primary"
                onClick={() => this.openAddRoleDialog()}
              >
                Add Permission
              </Button>
            </div>
            {this.renderAddRoleDialog()}
            {this.renderRenameRoleDialog()}
            {this.renderDeleteRoleDialog()}
          </Paper>
        );
      }
    }
    return null;
  }

  renderRoles = () => {
    const roles = this.getClientRoleData();
    return roles.map((role, role_index) => (
      this.renderRole(role, role_index)
    ));
  }

  renderRole = (role, role_index) => {
    const { classes } = this.props;
    const { permission_categories, permission_dirty, selected_role } = this.state;
    const tab_index = ((selected_role === role_index) ? 0 : -1);

    return (
      <ExpansionPanel
        key={role_index}
        disabled={role_index < 2}
        expanded={selected_role === role_index}
        onChange={(_, expanded) => this.setExpandedRole(role_index, expanded)}
      >
        <ExpansionPanelSummary expandIcon={<ChevronDown/>}>
          <Typography className={classes.heading}>
            {role.name}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <div className={classes.permission_root}>
            {permission_categories.map((category, category_index) => {
              let permissions = [];

              for (let i = 0; i < category.permissions.length; ++i) {
                this.renderRolePermission(
                  permissions,
                  category.permissions[i],
                  role_index
                );
              }

              return (
                <React.Fragment key={category_index}>
                  <div className={classes.permission_header}>
                    <Typography variant="caption">
                      {category.name}
                    </Typography>
                  </div>
                  <List className={classes.permission_list}>
                    {permissions}
                  </List>
                </React.Fragment>
              );
            })}
            <div className={classes.permission_actions}>
              <Button
                tabIndex={tab_index}
                disabled={selected_role < 2}
                onClick={() => this.openDeleteRoleDialog(role.id)}
              >
                Delete Permission
              </Button>
              <Button
                tabIndex={tab_index}
                disabled={selected_role < 2}
                onClick={() => this.openRenameRoleDialog(role.id, role.name)}
              >
                Rename Permission
              </Button>
              <Button
                tabIndex={tab_index}
                disabled={(selected_role < 2) || !permission_dirty[role_index]}
                onClick={() => this.runUpdateRole()}
              >
                Save Changes
              </Button>
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }

  renderRolePermission = (permissions, permission, role_index, open = true) => {
    const { classes } = this.props;
    const { selected_role } = this.state;
    const tab_index = ((selected_role === role_index && open) ? 0 : -1);
    let secondary_action;

    switch (permission.type) {
      case 'boolean': {
        secondary_action = (
          <ListItemSecondaryAction>
            <Switch
              color="primary"
              inputProps={{ tabIndex: tab_index }}
              checked={this.getPermissionValue(permission, role_index)}
              onChange={(e) => this.setPermissionValue(
                permission, role_index, e.target.checked
              )}
            />
          </ListItemSecondaryAction>
        );

        break;
      }
      case 'enum-team-palette': {
        secondary_action = (
          <FormControl className={classes.multi_select}>
            <Select
              value={this.getPermissionValue(permission, role_index)}
              tabIndex={tab_index}
              inputProps={{ tabIndex: tab_index }}
              onChange={(e) => this.setPermissionValue(
                permission, role_index, e.target.value
              )}
            >
            {Object.keys(PALETTE_PERMISSION).map((key, index) => (
              <MenuItem key={index} value={key}>
                {PALETTE_PERMISSION[key].name}
              </MenuItem>
            ))}
            </Select>
          </FormControl>
        );

        break;
      }
      case 'select-fonts-allowed': {
        const { fonts_allowed } = this.state;
        const values = this.getPermissionValue(permission, role_index);
        let menu_items;

        if (fonts_allowed !== null) {
          menu_items = fonts_allowed.map((font, index) => (
            <MenuItem key={index} value={font.id}>
              <Checkbox color="primary" checked={values.indexOf(font.id) >= 0}/>
              <ListItemText primary={font.name}/>
            </MenuItem>
          ));
        } else {
          menu_items = (
            <CircularProgress size={48}/>
          );
        }

        secondary_action = (
          <FormControl className={classes.multi_select}>
            <Select
              multiple
              displayEmpty
              value={values}
              tabIndex={tab_index}
              inputProps={{ tabIndex: tab_index }}
              onChange={(e) => {
                this.setPermissionValue(
                  permission, role_index, e.target.value
                );
              }}
              renderValue={selected => {
                const value = (
                  (fonts_allowed || [])
                    .filter(font => selected.includes(font.id))
                    .map(font => font.name)
                );

                if (value.length > 0) {
                  return value.join(', ');
                }

                return (
                  <div className={classes.placeholder}>
                    Select fonts...
                  </div>
                );
              }}
              MenuProps={MENU_PROPS}
            >
              {menu_items}
            </Select>
          </FormControl>
        );

        break;
      }
      case 'select-template-sports': {
        const { sports } = this.state;
        const values = this.getPermissionValue(permission, role_index);
        let menu_items;

        if (sports !== null) {
          menu_items = sports.map((sport, index) => (
            <MenuItem key={index} value={sport.id}>
              <Checkbox color="primary" checked={values.indexOf(sport.id) >= 0}/>
              <ListItemText primary={sport.name}/>
            </MenuItem>
          ));
        } else {
          menu_items = (
            <CircularProgress size={48}/>
          );
        }

        secondary_action = (
          <FormControl className={classes.multi_select}>
            <Select
              multiple
              displayEmpty
              value={values}
              tabIndex={tab_index}
              inputProps={{ tabIndex: tab_index }}
              onChange={(e) => {
                this.setPermissionValue(
                  permission, role_index, e.target.value
                );
              }}
              renderValue={selected => {
                const value = (
                  (sports || [])
                    .filter(sport => selected.includes(sport.id))
                    .map(sport => sport.name)
                );

                if (value.length > 0) {
                  return value.join(', ');
                }

                return (
                  <div className={classes.placeholder}>
                    Select sports...
                  </div>
                );
              }}
              MenuProps={MENU_PROPS}
            >
              {menu_items}
            </Select>
          </FormControl>
        );

        break;
      }
      case 'select-custom-folders': {
        const { custom_folders } = this.state;
        const values = this.getPermissionValue(permission, role_index);
        let menu_items;

        if (custom_folders !== null) {
          menu_items = custom_folders.map((folder, index) => (
            <MenuItem key={index} value={folder.id}>
              <Checkbox color="primary" checked={values.indexOf(folder.id) >= 0}/>
              <ListItemText primary={folder.name}/>
            </MenuItem>
          ));
        } else {
          menu_items = (
            <CircularProgress size={48}/>
          );
        }

        secondary_action = (
          <FormControl className={classes.multi_select}>
            <Select
              multiple
              displayEmpty
              value={values}
              tabIndex={tab_index}
              inputProps={{ tabIndex: tab_index }}
              onChange={(e) => {
                this.setPermissionValue(
                  permission, role_index, e.target.value
                )}}
              renderValue={selected => {
                const value = (
                  (custom_folders || [])
                  .filter(folder => selected.includes(folder.id))
                  .map(folder => folder.name)
                );

                if (value.length > 0) {
                  return value.join(', ');
                }

                return (
                  <div className={classes.placeholder}>
                    Select custom folders...
                  </div>
                );
              }}
              MenuProps={MENU_PROPS}
            >
              {menu_items}
            </Select>
          </FormControl>
        );

        break;
      }
      case 'select-facebook-accounts': {
        const { usersQuery } = this.props;
        const values = this.getPermissionValue(permission, role_index);
        let facebook_accounts = null;
        let menu_items = null;

        if (usersQuery && usersQuery.me && usersQuery.me.client) {
          facebook_accounts = usersQuery.me.client.fbPages;

          if (facebook_accounts) {
            menu_items = facebook_accounts.map((account, index) => (
              <MenuItem key={index} value={account.id}>
                <Checkbox color="primary" checked={values.indexOf(account.id) >= 0}/>
                <ListItemText primary={account.name}/>
              </MenuItem>
            ));
          }
        }

        if (menu_items === null) {
          menu_items = (
            <CircularProgress size={48}/>
          );
        }

        secondary_action = (
          <FormControl className={classes.multi_select}>
            <Select
              multiple
              displayEmpty
              value={values}
              tabIndex={tab_index}
              inputProps={{ tabIndex: tab_index }}
              onChange={(e) => {
                this.setPermissionValue(
                  permission, role_index, e.target.value
                )}}
              renderValue={selected => {
                const value = (
                  (facebook_accounts || [])
                  .filter(account => selected.includes(account.id))
                  .map(account => account.name)
                );

                if (value.length > 0) {
                  return value.join(', ');
                }

                return (
                  <div className={classes.placeholder}>
                    Select Facebook accounts...
                  </div>
                );
              }}
              MenuProps={MENU_PROPS}
            >
              {menu_items}
            </Select>
          </FormControl>
        );

        break;
      }
      case 'select-twitter-accounts': {
        const { usersQuery } = this.props;
        const values = this.getPermissionValue(permission, role_index);
        let twitter_accounts = null;
        let menu_items = null;

        if (usersQuery && usersQuery.me && usersQuery.me.client) {
          twitter_accounts = usersQuery.me.client.twitterAccounts;

          if (twitter_accounts) {
            menu_items = twitter_accounts.map((account, index) => (
              <MenuItem key={index} value={account.id}>
                <Checkbox color="primary" checked={values.indexOf(account.id) >= 0}/>
                <ListItemText
                  primary={account.name}
                  secondary={`@${account.screen_name}`}
                />
              </MenuItem>
            ));
          }
        }

        if (menu_items === null) {
          menu_items = (
            <CircularProgress size={48}/>
          );
        }

        secondary_action = (
          <FormControl className={classes.multi_select}>
            <Select
              multiple
              displayEmpty
              value={values}
              tabIndex={tab_index}
              inputProps={{ tabIndex: tab_index }}
              onChange={(e) => {
                this.setPermissionValue(
                  permission, role_index, e.target.value
                )}}
              renderValue={selected => {
                const value = (
                  (twitter_accounts || [])
                  .filter(account => selected.includes(account.id))
                  .map(account => account.name)
                );

                if (value.length > 0) {
                  return value.join(', ');
                }

                return (
                  <div className={classes.placeholder}>
                    Select Twitter accounts...
                  </div>
                );
              }}
              MenuProps={MENU_PROPS}
            >
              {menu_items}
            </Select>
          </FormControl>
        );

        break;
      }
      default: {
        secondary_action = null;
        break;
      }
    }

    permissions.push(
      <ListItem key={permissions.length} divider>
        <ListItemText
          primary={permission.name}
          secondary={permission.description}
        />
        {secondary_action}
      </ListItem>
    );

    if (permission.subpermission) {
      const open = (
        permission.type !== 'boolean' ||
        Boolean(this.getPermissionValue(permission, role_index))
      );

      let subpermissions = [];

      for (let i = 0; i < permission.subpermission.length; ++i) {
        this.renderRolePermission(
          subpermissions,
          permission.subpermission[i],
          role_index,
          open
        );
      }

      permissions.push(
        <Collapse in={open}>
          {subpermissions}
        </Collapse>
      );
    }
  }

  renderDeleteRoleDialog = () => {
    const { delete_role_dialog_open } = this.state;

    return (
      <Dialog
        open={delete_role_dialog_open}
        onClose={() => this.closeDeleteRoleDialog(false)}
      >
        <DialogTitle>
          Permanently remove permission?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This action will remove this permission permanently.
            Any users currently assigned to this permission will be reassigned
            the default permission.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => this.closeDeleteRoleDialog(false)}>
            Cancel
          </Button>
          <Button color="primary" onClick={() => this.closeDeleteRoleDialog(true)}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  openDeleteRoleDialog = (deleteId) => {
    this.setState({ delete_role_dialog_open: true, deleteId: deleteId });
  }

  closeDeleteRoleDialog = (doWork) => {
    const { deleteId, selected_role } = this.state;
    const { getRoles, setMessage } = this.props;
    const { clientRoles } = getRoles;
    let defaultRoleId = null;
    let defaultRole = null;
    if (doWork && deleteId && selected_role >= 2) {
      defaultRole = clientRoles
        .find((item) => item.name === 'Default');
      if (defaultRole) {
        defaultRoleId = defaultRole.id;
      }
      this.props.usersQuery.refetch().then((r) => {
        if (r && r.data && r.data.me && r.data.me.client && r.data.me.client.users) {
          const users = r.data.me.client.users;
          users.forEach((user) => {
            if (user && user.role) {
              if (user.role.id === deleteId) {
                this.props.assignRole({
                  variables: {
                    userId: user.id,
                    roleId: defaultRoleId,
                  },
                });
              }
            }
          });
        }
      });
      this.props.deleteRole({
        variables: {
          roleId: deleteId,
        },
        refetchQueries: [{ query: CLIENT_ROLES }],
      })
        .then(() => {
          setMessage('User role deleted', 3000);
        });
      this.setState({ delete_role_dialog_open: false, deleteId: null });
    } else {
      this.setState({ delete_role_dialog_open: false, deleteId: null });
    }
  }

  runUpdateRole = () => {
    const { setMessage } = this.props;
    const { permission_roles, selected_role } = this.state;
    if (selected_role >= 2) {
      const updatedPermissions = permission_roles[selected_role];
      let categories = null;
      let customFolders = null;
      let twitter_accounts = null;
      let facebook_pages = null;
      let fontsAllowed = null;

      if (updatedPermissions.get('fonts:fonts_allowed')) {
        fontsAllowed = updatedPermissions.get('fonts:fonts_allowed').map((font) => (
          font
        ));
      }

      if (updatedPermissions.get('template_library:sports')) {
        categories = updatedPermissions.get('template_library:sports').map((category) => (
          category
        ));
      }

      if (updatedPermissions.get('custom_template:folders')) {
        customFolders = updatedPermissions.get('custom_template:folders').map((customFolder) => (
          customFolder
        ));
      }

      if (updatedPermissions.get('social_posting:twitter_accounts')) {
        twitter_accounts = [...updatedPermissions.get('social_posting:twitter_accounts')];
      }

      if (updatedPermissions.get('social_posting:facebook_accounts')) {
        facebook_pages = [...updatedPermissions.get('social_posting:facebook_accounts')];
      }


      this.props.updateRole({
        variables: {
          roleId: updatedPermissions.id,
          restrictCategoryFlag: updatedPermissions.get('template_library:restricted'),
          allowCustomFlag: updatedPermissions.get('custom_template:read'),
          allowCustomSaveFlag: updatedPermissions.get('custom_template:write'),
          allowCustomFolderSaveFlag: updatedPermissions.get('custom_template:write_folders'),
          restrictCustomFlag: updatedPermissions.get('custom_template:restricted'),
          categories,
          customFolders,
          restrictSocialFlag: updatedPermissions.get('social_posting:read'),
          manageSocialAccounts: updatedPermissions.get('social_posting:write'),
          twitterAccounts: twitter_accounts,
          facebookPages: facebook_pages,
          manageTeamColors: updatedPermissions.get('team_palette:write'),
          restrictTeamColors: updatedPermissions.get('team_palette:read'),
          restrictMoveCanvas: updatedPermissions.get('editor:lock_movement'),
          restrictRemoveBG: updatedPermissions.get('editor:remove_bg'),
          restrictGeneralSettings: updatedPermissions.get('team:remove_general_settings'),
          restrictFonts: updatedPermissions.get('fonts:read'),
          manageFonts: updatedPermissions.get('fonts:write'),
          fontsAllowed,
        },
      })
        .then(() => {
          setMessage('User role updated', 3000);
          this.props.getRoles.refetch().then(() => {
            this.setExpandedRole(selected_role, false);
          });
        });
    }
  }

  renderAddRoleDialog = () => {
    const { add_role_dialog_open, new_role_name } = this.state;

    return (
      <Dialog
        open={add_role_dialog_open}
        onClose={() => this.closeAddRoleDialog(false)}
      >
        <form onSubmit={(e) => {
          e.preventDefault();
          this.closeAddRoleDialog(true);
        }}>
          <DialogTitle>
            Add User Permission
          </DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              autoFocus
              label="Permission Name"
              value={new_role_name}
              onFocus={(e) => e.target.select()}
              onChange={(e) => this.setState({
                new_role_name: e.target.value
              })}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.closeAddRoleDialog(false)}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              type="submit"
              disabled={new_role_name.trim() === ''}
            >
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }

  openAddRoleDialog = () => {
    this.setState({
      add_role_dialog_open: true,
      new_role_name: 'New Role',
    });
  }

  closeAddRoleDialog = (doWork) => {
    if (doWork) {
      this.props.addRole({
        variables: {
          name: this.state.new_role_name,
        },
        refetchQueries: [{ query: CLIENT_ROLES }],
      })
        .then(() => {
          this.setState({
            permission_roles: null,
            add_role_dialog_open: false,
            new_role_name: '',
          });
        });
    } else {
      this.setState({
        add_role_dialog_open: false,
        new_role_name: '',
      });
    }
  }

  renderRenameRoleDialog = () => {
    const { rename_role_dialog_open, new_role_name } = this.state;

    return (
      <Dialog
        open={rename_role_dialog_open}
        onClose={() => this.closeRenameRoleDialog(false)}
      >
        <form onSubmit={(e) => {
          e.preventDefault();
          this.closeRenameRoleDialog(true);
        }}>
          <DialogTitle>
            Rename User Permission
          </DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              autoFocus
              label="Permission Name"
              value={new_role_name}
              onFocus={(e) => e.target.select()}
              onChange={(e) => this.setState({
                new_role_name: e.target.value,
              })}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.closeRenameRoleDialog(false)}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              type="submit"
              disabled={new_role_name.trim() === ''}
            >
              Rename
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }

  openRenameRoleDialog = (renameId, name) => {
    this.setState({ rename_role_dialog_open: true, renameId: renameId, new_role_name: name, });
  }

  closeRenameRoleDialog = (doWork) => {
    if (doWork) {
      this.props.renameRole({
        variables: {
          roleId: this.state.renameId,
          name: this.state.new_role_name,
        },
        refetchQueries: [{ query: CLIENT_ROLES }],
      })
        .then(() => {
          this.setState({
            rename_role_dialog_open: false,
            new_role_name: '',
            renameId: null,
          });
        });
    } else {
      this.setState({
        rename_role_dialog_open: false,
        new_role_name: '',
        renameId: null,
      });
    }
  }

  getDataMe = () => {
    const { usersQuery } = this.props;
    return ((usersQuery && usersQuery.me) || null);
  }

  getPermissionValue = (permission, role_index) => {
    const { permission_roles } = this.state;

    if (!permission_roles[role_index]) {
      const roles = this.getClientRoleData();
      permission_roles[role_index] = new UserPermissions(roles[role_index]);
    }

    return permission_roles[role_index].get(permission.key);
  }

  setPermissionValue = (permission, role_index, value) => {
    let { permission_roles, permission_dirty } = this.state;
    permission_roles[role_index].set(permission.key, value);
    permission_dirty[role_index] = true;
    this.forceUpdate();
  }

  getClientRoleData = () => {
    const { data, loading } = this.props;

    if (!data || loading) {
      return null;
    }

    const { clientRoles: client_roles } = data;

    if (!client_roles) {
      return null;
    }

    return client_roles;
  }

  setExpandedRole = (role_index, expanded) => {
    if (expanded) {
      this.setState({ selected_role: role_index });
    } else {
      this.setState({ selected_role: -1 });
    }
  }

}

// -------------------------------------------------------------------------- //

export default compose(
  graphql(ADD_ROLE, { name: 'addRole' }),
  graphql(ASSIGN_ROLE, { name: 'assignRole' }),
  graphql(UPDATE_ROLE, { name: 'updateRole' }),
  graphql(DELETE_ROLE, { name: 'deleteRole' }),
  graphql(RENAME_ROLE, { name: 'renameRole' }),
  graphql(CLIENT_ROLES, { name: 'getRoles', options: { fetchPolicy: 'network-only' } }),
  graphql(GET_SPORTS, { name: 'get_sports', options: { fetchPolicy: 'network-only' } }),
  graphql(GET_MY_FONTS, { name: 'get_fonts', options: { fetchPolicy: 'network-only' } }),
  graphql(GET_CUSTOM_FOLDERS, { name: 'get_custom_folders', options: { fetchPolicy: 'network-only' } }),
  graphql(USERS_QUERY, { name: 'usersQuery', options: { fetchPolicy: 'network-only' } }),
  graphql(CLIENT_ROLES, { options: { fetchPolicy: 'network-only' } }),
  withRouter,
  withStyles(STYLES),
)(SettingRoles);

// -------------------------------------------------------------------------- //
