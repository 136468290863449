import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Logo from '../svg/Logo';
import MessageContainer from '../../unstated/MessageContainer';
import { Subscribe } from 'unstated';
import Status from './Status';
import history from '../../history';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import PersonIcon from '@material-ui/icons/Person';


const styles = theme => ({
  background: {
    backgroundColor: theme.palette.background.paper,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  login: {
    maxWidth: 480,
    backgroundColor: '#FFF',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
  },
  logo: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(4),
    textAlign: 'center',
  },
  logoSVG: {
    fill: '#FFF',
    maxWidth: 280,
  },
  button: {
    marginTop: theme.spacing(1),
  },
  buttonProgress: {
    marginLeft: 30,
  },
});

const loginTheme = createMuiTheme({
  typography: {
    fontFamily: 'Ubuntu,Roboto,sans-serif',
  },
  palette: {
    primary: {
      main: '#6b2587',
    },
    secondary: {
      main: '#4fd4c4',
    },
  },
});

const RESET_PASSWORD = gql`
  mutation resetPassword($email: String!){
    resetPassword (
      email: $email
    )
  }
`;


class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      message: '',
      dialogOpen: false,
      loading: false,
      dialogType: 0,
      dialogTittle: '',
      dialogMessage: '',
      users: null,
      loginFn: null
    };
  }

  handleClickOpen = () => {
    this.setState({ dialogOpen: true,dialogType: 0 });
  };

  handleClose = () => {
    if (this.state.loading !== true ) {
      this.setState({ dialogOpen: false });
    }
  };

  handleChange = name => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleDone = () => {
    this.setState({ loading: false });
  }

  resetPassword = reset => () => {
    // send to server
    reset({
      variables: {
        email: this.state.email,
      },
    }).then((response) => {
      this.setState({ dialogOpen: false, email: '' });
      window.Intercom("trackEvent","reset-password",{via:"login-page"});
    });
  }

  login = (messages, location) => (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    const { login } = this.props.auth
    login(this.state.email, this.state.password, messages.setMessage, this.handleDone, location.pathname, (response) => {
      if (response?.error) {
        messages.setMessage(response.errorMessage ? response.errorMessage : 'Invalid Credentials');
        this.setState({ loading: false });
      } else if (response?.multiclient) {
        this.setState({ loading: false, dialogOpen: true, dialogType: 2, users: response.users, loginFn: response.loginFn } );
      }
    })
  }

  goToTrial = ()=> history.push('/trial');

  render() {
    const { classes, location } = this.props;
    const userAgent = navigator.userAgent.toLowerCase();
    let topMessage = (
      <>
        Don't have an account? <a href="https://scoreshots.com/" rel="noopener noreferrer" target="_blank">Click here for information</a>
      </>
    )
    if (userAgent.indexOf(' mobile app/1.01') > -1) {
       topMessage = (
        <>
          PrestoShots mobile app is only available to current licensed users. <div></div> For more information, please go to <a href="https://www.prestosports.com/prestoshots" target="_blank" rel="noopener noreferrer">https://www.prestosports.com/prestoshots</a>.
        </>
      )
    }
    return (
      <Subscribe to={[MessageContainer]}>
        { messages => (
          <div className={classes.background}>
            <div className={classes.logo}>
              <Logo className={classes.logoSVG} />
              <Status />
            </div>
            <form className={classes.login} onSubmit={this.login(messages, location)}>
              <Typography variant="h2">Login</Typography>
              <Typography variant="body1" gutterBottom>
                {topMessage}
              </Typography>
              <TextField
                id="email"
                label="Email address"
                value={this.state.email}
                onChange={this.handleChange('email')}
                fullWidth
                margin="dense"
              />
              <TextField
                id="password"
                label="Password"
                value={this.state.password}
                onChange={this.handleChange('password')}
                fullWidth
                type="password"
                autoComplete="current-password"
                margin="dense"
              />
              <div style={{ position: 'relative' }}>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={this.props.auth.loading}
                  className={this.props.classes.button}
                >
                  Login
                  { this.state.loading && <CircularProgress size={20} className={this.props.classes.buttonProgress} /> }
                </Button>
              </div>
              <Button color="primary" className={this.props.classes.button} onClick={this.handleClickOpen} fullWidth>
                Forgot Password
              </Button>

              <Button style={{"display":"none"}} color="primary" className={this.props.classes.button} onClick={this.goToTrial} fullWidth>
                Register
               </Button>
            </form>

            <Dialog
              open={this.state.dialogOpen}
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
            >
              {
                this.state.dialogType === 0 ? (
                    <>

                       <DialogTitle id="form-dialog-title">Forgot Password</DialogTitle>
                       <DialogContent>
                          <DialogContentText>
                            {'If the email you provided matches one we have on file, you\'ll receive a temporary password momentarily. If you do not receive the message within a few minutes, please check your Spam/Junk E-mail folder just in case the email got delivered there instead of your inbox.'}
                          </DialogContentText>
                          <TextField
                              autoFocus
                              margin="dense"
                              id="name"
                              label="Email Address"
                              type="email"
                              fullWidth
                              value={this.state.email}
                              onChange={this.handleChange('email')}
                          />
                       </DialogContent>
                       <DialogActions>
                       <Button onClick={this.handleClose} color="primary">
                       Cancel
                        </Button>
                        <Mutation mutation={RESET_PASSWORD}>
                        {reset => (
                          <Button  onClick={this.resetPassword(reset)} color="primary">
                            Confirm
                          </Button>
                        )}
                        </Mutation>
                      </DialogActions>
                    </>
                ) : this.state.dialogType === 2 ? (
                    <>
                      <DialogTitle>Choose your account</DialogTitle>
                      <List sx={{ pt: 0 }}>
                        {this.state.users.map((user) => (
                            <ListItem button key={user.email} onClick={() => {
                              this.state.loginFn(user.email);
                              this.setState({ loading: true });
                            }}>
                              <ListItemAvatar>
                                <Avatar>
                                  <PersonIcon />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText primary={`${user.client?.name}`} />
                            </ListItem>
                        ))}
                      </List>
                      <DialogActions style={{alignSelf: 'center'}}>
                        <Button disabled={this.state.loading} onClick={this.handleClose} color="primary">
                          Cancel
                        </Button>
                      </DialogActions>
                    </>
                ) :(
                    <>

                      <DialogTitle id="form-dialog-title">{this.state.dialogTittle}</DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            {this.state.dialogMessage}
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={() => this.setState({dialogOpen: false})} color="primary">
                            Close
                          </Button>
                      </DialogActions>
                    </>
                )
              }

            </Dialog>

          </div>

        )}
      </Subscribe>
    );
  }
}


const StyledLogin = withRouter(withStyles(styles)(Login));

const LoginWithProvider = props => (
  <MuiThemeProvider theme={loginTheme}>
    <StyledLogin {...props} />
  </MuiThemeProvider>
);

export default LoginWithProvider;
