
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from '@material-ui/core';

import { compose, graphql, Mutation } from 'react-apollo';
import { matchPath, withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import queryString from 'query-string';

import '../css/sizing.css';
import { AppContainer } from './AppContainer';
import { BetaNav } from '../nav/BetaNav';
import { STEPS, STEPS_MOBILE } from './Steps';
import { VirtualTourContext } from './tour';
import BetaNavMobile from '../nav/BetaNavMobile';
import FullscreenLoader from '../loading/FullscreenLoader';
import Tour from '../../tour/Tour';

// -------------------------------------------------------------------------- //

const GET_CLIENT_COLORS = gql`
  query {
    me {
      id
      firstname
      lastname
      email
      onboardingFlags
      client {
        id
        name
        color1
        color2
        color3
        logoBucket
        logoKey
        subscription_start
        subscription_end
        fonts {
          id
          fontFamily
          type
          bucket
          key
        }
        users {
          id
          role {
            id
          }
        }
        roles {
          id
          name
        }
      }
      role {
        id
        name
        restrictCategoryFlag
        categories {
          id
          name
        }
        allowCustomFlag
        allowCustomSaveFlag
        allowCustomFolderSaveFlag
        restrictCustomFlag
        customFolders {
          id
          name
        }
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const GET_ME = gql`
  query {
    me {
      id
      firstname
      lastname
      email
      onboardingFlags
      type
      client {
        id
        name
        color1
        color2
        color3
        logoBucket
        logoKey
        subscription_start
        subscription_end
        fonts {
          id
          fontFamily
          type
          bucket
          key
        }
        users {
          id
          type
          role {
            id
            name
          }
        }
        roles {
          id
          name
        }
      }
      role {
        id
        name
        restrictCategoryFlag
        categories {
          id
          name
        }
        allowCustomFlag
        allowCustomSaveFlag
        allowCustomFolderSaveFlag
        restrictCustomFlag
        customFolders {
          id
          name
        }
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const ADD_ROLE = gql`
  mutation addRoleToClient($name: String!) {
    addRoleToClient(
      name: $name,
    ) {
      id
    }
  }
`;

// -------------------------------------------------------------------------- //

const ASSIGN_ROLE = gql`
  mutation assignRole($userId: ID!, $roleId: ID!) {
    assignRole(
      userId: $userId,
      roleId: $roleId,
    ) {
      id
    }
  }
`;

// -------------------------------------------------------------------------- //

const MARK_TOUR_COMPLETE = gql`
  mutation {
    addUserFlag (
      flag: FIRST_LOGIN
    )
  }
`;

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'start',
  },
  marginDiv: {
    width: '100%',
    flexBasis: '100%',
    marginTop: 64,
    paddingBottom: 8,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 120,
    },
  },
  nav: {
    zIndex: 999999999,
    width: '100%',
    position: 'fixed',
    bottom: 0,
    boxShadow: theme.shadows[6],
  },
  tour: {
    maxWidth: 440,
    outline: 'none',
  },
});

// -------------------------------------------------------------------------- //

class App extends React.Component {

  static getDerivedStateFromProps(props, state) {
    if (props.data && props.data.me && state.showTutorial === null) {
      const newState = {};
      if (props.data.me.onboardingFlags.indexOf('FIRST_LOGIN') === -1) {
        newState.showTutorial = true;
      } else {
        newState.showTutorial = false;
      }
      return newState;
    }
    return null;
  }

  state = {
    showTutorial: null,
    steps: null,
    accountHasExpired: false,
  }

  componentDidMount() {
    let steps = window.screen.width > 900 ? STEPS : STEPS_MOBILE;
    this.setState({ steps });
    this.initialRoleCreation();
  }

  getAttributes = () => {
    let page = 'home';

    if (matchPath(this.props.location.pathname, { path: '/media' }) != null) {
      page = 'media';
    }

    if (matchPath(this.props.location.pathname, { path: '/team' }) != null) {
      page = 'team';
    }

    if (matchPath(this.props.location.pathname, { path: '/resources' }) != null) {
      page = 'resources';
    }

    if (
      matchPath(this.props.location.pathname, { path: '/create/:type?' }) !== null &&
      (matchPath(this.props.location.pathname, { path: '/create/new', exact: true }) === null ||
        matchPath(this.props.location.pathname, { path: '/create/eventfilter', exact: true }) === null ||
        matchPath(this.props.location.pathname, { path: '/create/schedulefilter', exact: true }) === null)
    ) {
      page = 'create';
    }

    if (matchPath(this.props.location.pathname, { path: '/user' }) != null) {
      page = 'user';
    }

    return { page };
  }

  initialRoleClientSetup = (r) => {
    if (r && r.data && r.data.me && r.data.me.client && r.data.me.client.users) {
      const { me } = r.data;
      const { client } = me;
      const { users } = client;
      let superuserRoleId = null;
      let defaultRoleId = null;
      if (client.roles) {
        client.roles
          .forEach((item) => {
            if (item.name === 'Superuser') {
              superuserRoleId = item.id;
            }
            if (item.name === 'Default') {
              defaultRoleId = item.id;
            }
          });
        users.forEach((user) => {
          if (user.role === null) {
            if (user.type === 'DEFAULT' && defaultRoleId) {
              this.props.assignRole({
                variables: {
                  userId: user.id,
                  roleId: defaultRoleId,
                },
              });
            }
            if (user.type === 'SUPERUSER' && superuserRoleId) {
              this.props.assignRole({
                variables: {
                  userId: user.id,
                  roleId: superuserRoleId,
                },
              });
            }
          }
        });
      }
      this.props.getMe.refetch();
    }
  }

  initialRoleCreation = () => {
    this.props.getMe.refetch().then( r => {
      if (r && r.data && r.data.me && r.data.me.client) {
        const { me } = r.data;
        const { client } = me;
        if (client.roles && client.roles.length > 0) {
          this.initialRoleClientSetup(r);
        } else {
          this.props.addRole({
            variables: {
              name: 'Superuser',
            },
          })
            .then(() => {
              this.props.addRole({
                variables: {
                  name: 'Default',
                },
              })
                .then(() => {
                  this.props.getMe.refetch().then( r => {
                    this.initialRoleClientSetup(r);
                  });
                });
            });
        }
      }
    });
  }

  currentStep = (index) => {
    const { steps } = this.state;
    const step = steps[index];

    if (step.goTo && step.goTo !== this.props.history.location.pathname) {
      this.props.history.push(step.goTo);
    }
  }

  setTourStep = (step) => {
    const { steps } = this.state;
    const path = this.props.history.location.pathname;

    if (steps[step].goTo && steps[step].goTo !== path) { 
      this.props.history.push(steps[step].goTo);
    }


    this.forceUpdate();
  }

  showTour = () => {
    this.setState({
      showTutorial: false,
    });
  }

  closeTour = markComplete => () => {
    markComplete();
    this.setState({
      showTutorial: false,
    });
  }

  render() {
    const { steps } = this.state;
    const { classes, data } = this.props;
    const divStatus = document.getElementById('intercom-container');
    if (divStatus) {
      divStatus.style.display = '';
    }

    const userTheme = {
      typography: {
        fontFamily: 'Ubuntu,Roboto,sans-serif',
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 1024,
          lg: 1440,
          xl: 1920,
        },
      },
    };

    // let primColor = '#6b2587';

    if (data.me) {
      if (!localStorage.getItem('isImpersonator')) {
        window.Intercom('update', {
          name: `${data.me.firstname} ${data.me.lastname}`,
          email: data.me.email,
          user_id: data.me.id,
          company: {
            id: data.me.client.id,
            name: data.me.client.name
          }
        });
      }

      /* global Raven */
      Raven.setUserContext({
        id: data.me.id,
        email: data.me.email,
      });
      // primColor = data.me.client.color1;
      userTheme.palette = {
        primary: {
          main: data.me.client.color1,
        },
        secondary: {
          main: data.me.client.color2,
        },
      };
    } else {
      return <FullscreenLoader/>;
    }

    const theme = createMuiTheme(userTheme);
    const attributes = this.getAttributes();

    return (
      <div className={classes.root}>
        <MuiThemeProvider theme={theme}>
          <div className={"fullscreen"}>
            <VirtualTourContext.Consumer>
            {({ start_tour }) => (
              <BetaNav
                history={this.props.history}
                page={attributes.page}
                key="nav"
                showTour={() => start_tour()}
                userid={localStorage.getItem('user_id')}
              />
            )}
            </VirtualTourContext.Consumer>
          </div>
          <div className={"mobile"}>
            <VirtualTourContext.Consumer>
            {({ start_tour }) => (
              <BetaNavMobile
                showTour={() => start_tour()}
                history={this.props.history}
                page={this.getAttributes().page}
              />
            )}
            </VirtualTourContext.Consumer>
          </div>
          <div
            className={classes.marginDiv}
            key="app"
          >
            <AppContainer me={data.me} />
          </div>
          <Mutation mutation={MARK_TOUR_COMPLETE}>
            {markComplete => (
              <VirtualTourContext.Consumer>
                {(context) => {
                  if (!steps) {
                    return null;
                  }
                  if (this.state.showTutorial === true) {
                    context.start_tour();
                    this.setState({ showTutorial: false })
                  }

                  if (context.tour_open) {
                    const params = queryString.parse(this.props.location.search);
                    if (params && params.tourcomplete === '1') {
                      markComplete();
                      context.close_tour();
                      this.setState({ showTutorial: false })
                      return null;
                    }
                  }

                  context.set_step = (step) => {
                    context.tour_step = step;
                    this.setTourStep(step);
                  };

                  return (
                    <Tour
                      current={context.tour_step}
                      onStepChange={(step) => {
                        context.set_step(step);
                      }}
                      accentColor={theme.palette.primary.main}
                      className={classes.tour}
                      steps={steps} startAt={0}
                      isOpen={context.tour_open}
                      showNumber={false}
                      showButtons={false}
                      rounded={12}
                      closeWithMask={false}
                      showNavigationNumber={false}
                      showCloseButton={true}
                      showNavigation={false}
                      onRequestClose={() => {
                        markComplete();
                        context.close_tour();
                      }}
                      disableDotsNavigation
                    />
                  );
                }}
              </VirtualTourContext.Consumer>
            )}
          </Mutation>

        </MuiThemeProvider>
      </div>
    );
  }

}

// -------------------------------------------------------------------------- //

export default compose(
  graphql(GET_CLIENT_COLORS, { options: { fetchPolicy: 'network-only' }, }),
  graphql(GET_ME, { name: 'getMe' }),
  graphql(ADD_ROLE, { name: 'addRole' }),
  graphql(ASSIGN_ROLE, { name: 'assignRole' }),
  withRouter,
  withStyles(STYLES)
)(App);

// -------------------------------------------------------------------------- //
