
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  ButtonBase,
  Fade,
  withStyles,
} from '@material-ui/core';

import { compose } from 'react-apollo';
import { withRouter } from 'react-router';

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  button: {
    borderRadius: 4,
    boxShadow: theme.shadows[1],
    margin: theme.spacing(1),
    transition: theme.transitions.create('box-shadow'),
    '&:focus': {
      boxShadow: `0 0 0 4px ${theme.palette.primary.main}`,
    },
    '&:hover': {
      boxShadow: theme.shadows[3],
    },
  },
  preview: {
    borderRadius: 4,
    height: theme.spacing(20),
  },
});

// -------------------------------------------------------------------------- //

class TemplatePreview_ extends React.Component {

  state = {
    loaded: false,
    hide_video: false,
  }

  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  handleImageLoaded = () => {
    // const interval = Math.floor(Math.random() * 501);
    // this.timeout = setTimeout(() => { }, interval);
    this.setState({ loaded: true });
  }

  render() {
    const { classes, history, template } = this.props;
    const { hide_video } = this.state;

    // eslint-disable-next-line no-nested-ternary
    const url = `/editor/${template.isSlideShow ? 'slideshow/' :
      template.isQuickCreate ? 'quickcreate/' : ''}template/${template.id}`;
    let component = null, display = 'none';

    if (this.ref.current && this.ref.current.parentElement) {
      let element = this.ref.current;
      let parent = element.parentElement;

      if (parent) {
        const my_rect = element.getBoundingClientRect();
        const your_rect = parent.getBoundingClientRect();

        if (my_rect.right < your_rect.right) {
          display = 'block';
        }
      }
    }
    if (template.isSlideShow && !hide_video) {
      component = (
        <video
          muted
          autoPlay
          loop
          crossOrigin="anonymous"
          className={classes.preview}
          src={`https://s3.us-east-2.amazonaws.com/ss3-templates/${template.id}.mp4`}
          key={`slideshow-preview-${template.id}`}
          onLoadedData={() => { this.setState({ loaded: true })}}
          onError={() => { this.setState({ hide_video: true })}}
        />
      );
    } else {
      component = (
        <img
          onLoad={this.handleImageLoaded}
          className={classes.preview}
          src={`https://s3.us-east-2.amazonaws.com/ss3-templates/${template.id}.jpg`}
          alt="Template preview"
        />
      );
    }

    return (
      <Fade in={this.state.loaded && display !== 'none'}>
        <div ref={this.ref}> {/* keep for <Fade> */}
          <ButtonBase
            className={classes.button}
            onClick={() => history.push(url)}
            tabIndex={display !== 'none' ? 0 : -1}
            role="link"
          >
            {component}
          </ButtonBase>
        </div>
      </Fade>
    );
  }
}

// -------------------------------------------------------------------------- //

export const TemplatePreview = compose(
  withStyles(STYLES),
  withRouter,
)(TemplatePreview_);

// -------------------------------------------------------------------------- //
