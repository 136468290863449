
// -------------------------------------------------------------------------- //

import * as React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Select,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  Delete as DeleteIcon,
  Pencil as PencilIcon,
  ReorderHorizontal as ReorderHorizontalIcon,
} from 'mdi-material-ui';

import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';

import Autosuggest from 'react-autosuggest';

import * as Binding from '../../../canvas/lib/databinding';
import * as MetaData from '../../../canvas/lib/metadata';
import * as Sports from '../../sport/Sports';

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  root: {
    overflowX: 'hidden',
  },
  editor: {
    flexDirection: 'row',
    display: 'flex',
    width: '100%',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeOut,
    }),
  },
  page: {
    width: '100%',
    overflow: 'auto',
    flexShrink: 0,
  },
  container: {
    position: 'absolute',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestions_list: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  operation: {
    marginTop: theme.spacing(1),
  },
  valueList: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    userSelect: 'none',
  },
  valueHandle: {
    cursor: 'row-resize',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  value_ghost: {
    listStyleType: 'none',
    zIndex: (theme.zIndex.modal + 1), // I hate this as much as you do
  },
});

// -------------------------------------------------------------------------- //

function escapeRegexCharacters(value) {
  return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

// -------------------------------------------------------------------------- //

function getSuggestionValue(suggestion) {
  return suggestion.getDisplayName();
}

// -------------------------------------------------------------------------- //

function fetchSuggestions(sport, value) {
  if (sport === null || sport === undefined) {
    return [];
  }

  value = escapeRegexCharacters(value.trim());

  if (value.length === 0) {
    return [];
  }

  const pattern = [...value].join('.*');
  const regex = new RegExp(`.*${pattern}.*`, 'i');
  const MAXIMUM = 5;
  let count = 0;

  return sport.variables.filter((variable) => {
    if (count >= MAXIMUM) {
      return false;
    }

    if (!regex.test(getSuggestionValue(variable))) {
      return false;
    }

    ++count;
    return true;
  });
}

// -------------------------------------------------------------------------- //

function renderSuggestionInput(props) {
  const { classes, inputRef = () => {}, ref, ...other } = props;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  )
}

// -------------------------------------------------------------------------- //

function renderSuggestion(suggestion, { isHighlighted }) {
  return (
    <MenuItem selected={isHighlighted}>
      <Typography variant="body1">
        {getSuggestionValue(suggestion)}
      </Typography>
    </MenuItem>
  );
}

// -------------------------------------------------------------------------- //

const BindingTermHandle = SortableHandle(({ classes }) => (
  <ReorderHorizontalIcon className={classes.valueHandle} />
));

// -------------------------------------------------------------------------- //

const BindingTermItem = SortableElement(({ classes, sortIndex, values, sport, onDelete }) => {
  const term = values[sortIndex];
  const { type } = term;
  let primary = term;

  switch (type) {
    case 'number': {
      primary = term.value.toString();
      break;
    }
    case 'string': {
      primary = String(term.value);
      break;
    }
    case 'stat': {
      const stat = Sports.GetVariableById(sport, term.value);

      if (stat) {
        primary = stat.getDisplayName();
      } else {
        primary = (term.value || '(null)');
      }

      break;
    }
    case 'operator': {
      const operator = Binding.OPERATOR_MAP[term.value];

      if (operator) {
        primary = operator.name;
      } else {
        primary = (term.value || '(null');
      }

      break;
    }
    default: {
      primary = '(null)';
      break;
    }
  }

  return (
    <ListItem key={sortIndex} dense>
      {
        (values.length > 1) &&
        <ListItemIcon>
          <BindingTermHandle classes={classes}/>
        </ListItemIcon>
      }
      <ListItemText primary={primary}/>
      <ListItemSecondaryAction>
        <IconButton onClick={() => (onDelete && onDelete(sortIndex))}>
          <DeleteIcon/>
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
});

// -------------------------------------------------------------------------- //

const BindingTermList = SortableContainer(({ classes, values, sport, onDelete }) => (
  <List className={classes.valueList}>
  {values.map((_, index) => (
    <BindingTermItem
      classes={classes}
      key={index}
      index={index}
      sortIndex={index}
      values={values}
      sport={sport}
      onDelete={onDelete}
      disabled={values.length === 1}
    />
  ))}
  </List>
));

// -------------------------------------------------------------------------- //

const FORMATS = [
  { key: '##%', value: '%d%%' },
  { key: '#.#', value: '%1.01f' },
  { key: '#.##', value: '%1.02f' },
  { key: '.###', value: '%.03f' },
  { key: '## - ##', value: '%d - %d' },
  { key: '## / ##', value: '%d / %d' },
];

// -------------------------------------------------------------------------- //

class DataBinder extends React.Component {

  state = {
    typing: '',
    suggestions: [],
    menu: false,
    anchor: null,
    point_index: -1,
    page_index: 0,
    operator_anchor: null,
  }

  render() {
    const { classes, active, open } = this.props;
    const { page_index, point_index } = this.state;
    const sport = this.getTemplateSport();

    if (!sport) {
      return null;
    }

    Binding.UpdateBindings(active, sport);
    Sports.UpdateSport(active, sport);
    const transform = `translate(-${100 * page_index}%, 0px)`;
    let title;

    switch (page_index) {
      case 0: {
        title = 'Edit Stats';
        break;
      }
      case 1: {
        const points = this.getPoints();
        title = `Edit ${points[point_index].name}`;
        break;
      }
      case 2: {
        title = 'Edit Players';
        break;
      }
      default: {
        title = '(No title)';
        break;
      }
    }

    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={this.handleClose}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <div className={classes.editor} style={{ transform }}>
              {this.renderBindPoints()}
              {this.renderPointEditor()}
              {this.renderPlayerEditor()}
            </div>
          </div>
        </DialogContent>
        {
          ((page_index > 0) || null) &&
          <DialogActions>
            <Button onClick={() => this.setState({ page_index: (page_index - 1) })}>Back</Button>
          </DialogActions>
        }
      </Dialog>
    );
  }

  renderPlayerEditor = () => {
    const { classes } = this.props;
    const { page_index: page } = this.state;
    const players = this.getPlayers();
    const sport = this.getTemplateSport();
    const positions = (sport ? sport.getPlayerPositions() : []);

    const onChangePlayerName = (index) => (e) => {
      this.changePlayerName(index, e.target.value);
    };

    const onDeletePlayer = (index, forceUpdate = true) => () => {
      this.deletePlayer(index, forceUpdate);
    };

    return (
      <div className={classes.page}>
        {page >= 2 && players.map((player, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'flex-end' }}>
            <TextField
              className={classes.operation}
              style={{ flex: 1, paddingRight: 8 }}
              label="Name"
              value={player.name}
              onChange={onChangePlayerName(index)}
            />
            {
              (positions.length > 0) &&
              <FormControl style={{ paddingRight: 8 }}>
                <InputLabel htmlFor="player-position-label">Pos.</InputLabel>
                <Select
                  name="player-position"
                  input={<Input name="player-position" id="player-position-label"/>}
                  value={player.position || ''}
                  renderValue={value => value}
                  onChange={e => this.changePlayerPosition(index, e.target.value)}
                >
                  <MenuItem value=""><em>None</em></MenuItem>
                  {positions.map((position, index) => (
                    <MenuItem key={index} value={position.key}>{position.name} ({position.key})</MenuItem>
                  ))}
                </Select>
              </FormControl>
            }
            <IconButton onClick={onDeletePlayer(index)}>
              <DeleteIcon/>
            </IconButton>
          </div>
        ))}
        {
          players.length < 26 &&
          <Button fullWidth className={classes.operation} onClick={() => this.createPlayer()}>Add Player</Button>
        }
      </div>
    );
  }

  getBindingPoint = () => {
    const { active: object } = this.props;
    const { point_index } = this.state;
    const points = Binding.GetBindingPoints(object);

    if (point_index < 0 || point_index >= points.length) {
      return null;
    }

    return Binding.GetBinding(object, points[point_index], true);
  }

  handleClose = () => {
    this.setState({
      typing: '',
      suggestions: [],
      point_index: -1,
      page_index: 0,
    }, () => {
      const { onClose } = this.props;

      if (onClose) {
        onClose();
      }
    });
  }

  getTemplateCategory = () => {
    const { template } = this.props;

    if (
      (template) &&
      (template.categories) &&
      (template.categories.length === 1)
    ) {
      return template.categories[0].name;
    }

    return 'default';
  }

  getTemplateSport = () => {
    const { template } = this.props;
    return Sports.CreateTemplateSport(template);
  }

  fetchSuggestions = (e) => {
    const sport = this.getTemplateSport();
    const suggestions = fetchSuggestions(sport, e.value);
    this.setState({ suggestions });
  }

  clearSuggestions = () => {
    this.setState({ suggestions: [] });
  }

  selectSuggestion = ({ suggestion }) => {
    const { active } = this.props;
    const { point_index } = this.state;
    const points = this.getPoints();

    let term = {
      type: 'stat',
      value: suggestion.id,
    };

    if (suggestion.player) {
      term.player = Binding.GetPlayerIndex(active, points[point_index]);
    }

    this.addBindingTerm(term, false);
    this.setState({ typing: '', suggestions: [] });
  }

  selectBindPoint = (index) => () => {
    this.setState({
      point_index: index,
      suggestions: [],
      typing: '',
      page_index: 1,
    })
  }

  addBindingStat = (stat) => {
    const { active } = this.props;
    const { point_index } = this.state;
    const points = this.getPoints();

    let term = {
      type: 'stat',
      value: stat.id,
    };

    if (stat.player) {
      term.player = Binding.GetPlayerIndex(active, points[point_index]);
    }

    this.addBindingTerm(term, false);
    this.setState({ typing: '', menu: false });
  }

  getPoints = () => {
    const { active } = this.props;
    return Binding.GetBindingPoints(active);
  }

  getBindings = () => {
    const { active } = this.props;
    const sport = this.getTemplateSport();
    Binding.UpdateBindings(active, sport);
    Sports.UpdateSport(active, sport);
    return Binding.GetBindings(active);
  }

  getPlayers = () => {
    let canvas = this.props.active.canvas;

    if (!MetaData.hasMetaData(canvas, 'players')) {
      MetaData.setMetaData(canvas, 'players', []);
    }

    return MetaData.getMetaData(canvas, 'players');
  }

  createPlayer = (force_update = true) => {
    let players = this.getPlayers();
    const name = `Player ${String.fromCharCode(65 + players.length)}`;
    players.push({ name, filters: [] });

    if (force_update) {
      this.forceUpdate();
    }
  }

  deletePlayer = (index, force_update = true) => {
    let players = this.getPlayers();

    if (index < 0 || index >= players.length) {
      return;
    }

    players.splice(index, 1);

    if (force_update) {
      this.forceUpdate();
    }
  }

  changePlayerName = (index, name, force_update = true) => {
    let players = this.getPlayers();

    if (index < 0 || index >= players.length) {
      return;
    }

    players[index].name = name;

    if (force_update) {
      this.forceUpdate();
    }
  }

  changePlayerPosition = (index, position, force_update = true) => {
    let players = this.getPlayers();

    if (index < 0 || index >= players.length) {
      return;
    }

    players[index].position = position;

    if (force_update) {
      this.forceUpdate();
    }
  }

  addBindingTerm = (term, force_update = true) => {
    let binding = this.getBindingPoint();
    binding.terms.push(term);

    if (force_update) {
      this.forceUpdate();
    }
  }

  removeBindingTerm = (index, force_update = true) => {
    let binding = this.getBindingPoint();

    if (binding !== null) {
      binding.terms.splice(index, 1);
    }

    if (force_update) {
      this.forceUpdate();
    }
  }

  sortBindingTerms = ({ oldIndex, newIndex }, force_update = true) => {
    let binding = this.getBindingPoint();

    if (binding !== null) {
      binding.terms = arrayMove(binding.terms, oldIndex, newIndex);
    }

    if (force_update) {
      this.forceUpdate();
    }
  }

  submitBindingTerm = (e) => {
    e.preventDefault();
    const { typing } = this.state;
    let value = typing.trim();

    if (value === '') {
      return;
    }

    let type = 'string';

    if (/^[-+]?\d+(\.\d+)?$/.test(value)) {
      value = Number(value);
      type = 'number';
    }

    this.addBindingTerm({ type, value }, false);
    this.setState({ typing: '', suggestions: [] });
  }

  changeBindingFormat = (format, force_update = true) => {
    let binding = this.getBindingPoint();

    if (format !== 'None') {
      binding.format = format;
    } else {
      binding.format = null;
    }

    if (force_update) {
      this.forceUpdate();
    }
  }

  changeBindingPlayer = (player, force_update = true) => {
    let { active } = this.props;
    const { point_index } = this.state;
    const points = this.getPoints();

    if (player >= 0) {
      Binding.SetPlayerIndex(active, player, points[point_index]);
    } else {
      Binding.SetPlayerIndex(active, null, points[point_index]);
    }

    if (force_update) {
      this.forceUpdate();
    }
  }

  renderBindPoints = () => {
    const { classes, active } = this.props;
    const points = this.getPoints();
    const bindings = this.getBindings();
    const sport = this.getTemplateSport();

    return (
      <div className={classes.page}>
      {
        points.map((point, index) => {
          const secondary = (bindings && Binding.ToString(active, point, sport));

          return (
            <ListItem key={point.key} button onClick={this.selectBindPoint(index)}>
              <ListItemIcon><PencilIcon /></ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="subtitle1">
                    {point.name}
                  </Typography>
                }
                secondary={
                  <Typography color="textSecondary" variant="body1" noWrap>
                    {secondary}
                  </Typography>
                }
              />
            </ListItem>
          );
        })
      }
      </div>
    );
  }

  renderPointEditor = () => {
    const { classes, active } = this.props;

    const {
      typing,
      page_index,
      point_index,
      suggestions,
      menu,
      operator_anchor,
    } = this.state;

    const width = (this.anchor ? this.anchor.clientWidth : null);
    const sport = this.getTemplateSport();
    const players = this.getPlayers();
    const points = Binding.GetBindingPoints(active);
    const binding = this.getBindingPoint();
    let terms = [], format = 'None', player = -1;

    if (binding) {
      terms = binding.terms;

      if (
        (FORMATS.findIndex(({ value }) => (value === binding.format)) >= 0) &&
        (binding.format !== null)
      ) {
        format = binding.format;
      }

      player = Binding.GetPlayerIndex(active, points[point_index]);

      if (player === null) {
        player = -1;
      }
    }

    return (
      <div className={classes.page}>
        <form onSubmit={(e) => this.submitBindingTerm(e)}>
          <Autosuggest
            highlightFirstSuggestion
            suggestions={suggestions}
            getSuggestionValue={getSuggestionValue}
            onSuggestionsFetchRequested={(e) => this.fetchSuggestions(e)}
            onSuggestionsClearRequested={() => this.clearSuggestions()}
            onSuggestionSelected={(_, data) => this.selectSuggestion(data)}
            renderInputComponent={renderSuggestionInput}
            renderSuggestion={renderSuggestion}
            inputProps={{
              classes,
              value: typing,
              placeholder: 'Type number, text, or stat...',
              inputRef: (node) => { this.anchor = node; },
              onDoubleClick: () => { this.setState({ menu: true }); },
              onChange: ((e) => this.setState({ typing: e.target.value })),
            }}
            theme={{
              suggestion: classes.suggestion,
              suggestionsList: classes.suggestions_list,
            }}
            renderSuggestionsContainer={(options) => (
              <Popover
                disableAutoFocus
                disableEnforceFocus
                disableRestoreFocus
                open={Boolean(options.children)}
                anchorEl={this.anchor}
                anchorOrigin={{
                  horizontal: 'left',
                  vertical: 'bottom',
                }}
              >
                <Paper square {...options.containerProps} style={{ width }}>
                  {options.children}
                </Paper>
              </Popover>
            )}
          />
        </form>
        <Menu
          fullWidth
          open={menu}
          anchorEl={this.anchor}
          onClose={() => { this.setState({ menu: false }); }}
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'bottom',
          }}
        >
        {
          sport.variables.map((variable, index) => (
            <MenuItem
              key={index}
              onClick={() => this.addBindingStat(variable)}
            >
              {variable.getDisplayName()}
            </MenuItem>
          ))
        }
        </Menu>
        {
          (terms.length > 0 && page_index > 0) &&
          <BindingTermList
            classes={classes}
            helperClass={classes.value_ghost}
            sport={sport}
            values={terms}
            onSortEnd={this.sortBindingTerms}
            onDelete={(index) => this.removeBindingTerm(index)}
            useDragHandle
            lockAxis="y"
          />
        }
        {
          (page_index > 0) &&
          <React.Fragment>
            <Button fullWidth onClick={(e) => this.setState({ operator_anchor: e.target })}>
              Add Operation
            </Button>
            <Menu
              open={Boolean(operator_anchor)}
              anchorEl={operator_anchor}
              onClose={() => this.setState({ operator_anchor: null })}
            >
            {
              Object.keys(Binding.OPERATOR_MAP).map((key, index) => (
                <MenuItem key={index} onClick={() => this.addBindingOperator(key)}>
                  {Binding.OPERATOR_MAP[key].name}
                </MenuItem>
              ))
            }
            </Menu>
          </React.Fragment>
        }
        {
          (terms.length > 0 && points[point_index].type === 'string' && page_index > 0) &&
          <FormControl fullWidth className={classes.operation}>
            <InputLabel>Format</InputLabel>
            <Select
              value={format}
              onChange={e => this.changeBindingFormat(e.target.value)}
            >
              <MenuItem key={0} value="None">
                <em>None</em>
              </MenuItem>
              {FORMATS.map((format, index) => (
                <MenuItem key={1 + index} value={format.value}>
                  {format.key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        }
        {
          (terms.some(term => term.type === 'stat' && /^player\./g.test(term.value)) && page_index > 0) &&
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <FormControl className={classes.operation} style={{ flex: 1 }}>
              <InputLabel>Player</InputLabel>
              <Select
                value={player}
                onChange={(e) => this.changeBindingPlayer(e.target.value)}
              >
                <MenuItem key={-1} value={-1}>
                  <em>None</em>
                </MenuItem>
                {players.map((p, index) => (
                  <MenuItem key={index} value={index}>
                    {p.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <IconButton onClick={() => this.setState({ page_index: 2 })}>
              <PencilIcon/>
            </IconButton>
          </div>
        }
      </div>
    );
  }

  setOperationAnchor = (anchor) => {
    this.setState({ operator_anchor: anchor });
  }

  addBindingOperator = (key) => {
    let binding = this.getBindingPoint();

    binding.terms.push({
      type: 'operator',
      value: key,
    });

    this.setState({ operator_anchor: null });
  }

}

// -------------------------------------------------------------------------- //

export default withStyles(STYLES)(DataBinder);

// -------------------------------------------------------------------------- //
