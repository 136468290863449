
// ------------------------------------------------------------------------- //

import BaseballSport from './BaseballSport';
import BasketballSport from './BasketballSport';
import DefaultSport from './Sport';
import FootballSport from './FootballSport';
import HockeySport from './HockeySport';
import LacrosseSport from './LacrosseSport';
import SoccerSport from './SoccerSport';
import VolleyballSport from './VolleyballSport';

import SCBaseballSportImporter from './SCBaseballImporter';
import SCBasketballImporter from './SCBasketballImporter';
import SCFootballImporter from './SCFootballImporter';
import SCHockeyImporter from './SCHockeyImporter';
import SCLacrosseImporter from './SCLacrosseImporter';
import SCSoccerImporter from './SCSoccerImporter';
import SCVolleyballImporter from './SCVolleyballImporter';

import * as Bindings from '../../canvas/lib/databinding';

// -------------------------------------------------------------------------- //

export const LATEST_VERSION = 6;

// ------------------------------------------------------------------------- //

export const sports = {
  'Baseball': BaseballSport,
  'Basketball': BasketballSport,
  'Football': FootballSport,
  'Field Hockey': SoccerSport,
  'Ice Hockey': HockeySport,
  'Lacrosse': LacrosseSport,
  'Soccer': SoccerSport,
  'Softball': BaseballSport,
  'Volleyball': VolleyballSport,
};

// ------------------------------------------------------------------------- //

export const importers = {
  'Baseball': [
    SCBaseballSportImporter,
  ],
  'Basketball': [
    SCBasketballImporter,
  ],
  'Football': [
    SCFootballImporter,
  ],
  'Field Hockey': [
    SCSoccerImporter,
  ],
  'Ice Hockey': [
    SCHockeyImporter,
  ],
  'Lacrosse': [
    SCLacrosseImporter,
  ],
  'Soccer': [
    SCSoccerImporter,
  ],
  'Softball': [
    SCBaseballSportImporter,
  ],
  'Volleyball': [
    SCVolleyballImporter,
  ],
};

// ------------------------------------------------------------------------- //

export function CreateSport(key = 'default') {
  if (!(key in sports)) {
    return new DefaultSport();
  }

  return new sports[key]();
}

// -------------------------------------------------------------------------- //
/**
 * Finds the key of the sport most relevant to a given
 * template's set of assigned categories (i.e. sports).
 * 
 * @param {any[]} categories - array of categories (from a query)
 * @returns {string}
 * The key of the sport relevant to the given set of categories, or 'default'
 * if none were found.
 */
// -------------------------------------------------------------------------- //

export function GetCategorySport(categories) {
  if (categories && categories.length > 0) {
    const first = categories[0].name;

    if (categories.length === 1) {
      return first;
    }

    if (categories.every(category => sports[category.name] === sports[first])) {
      return first;
    }
  }

  return 'default';
}

// -------------------------------------------------------------------------- //
/**
 * Creates the sport assigned to a given template.
 * @param {any} template - target template (from a query)
 * @return {Sport}
 * A new Sport instance whose derived type matches the one
 * assigned to the given template.
 */
// -------------------------------------------------------------------------- //

export function CreateTemplateSport(template) {
  let key = 'default';

  if (template) {
    key = GetCategorySport(template.categories);
  }

  return CreateSport(key);
}

// -------------------------------------------------------------------------- //
/**
 * Gets a sport variable for a given sport by variable ID.
 * @param {string|Sport} sport - target sport instance or sport key
 * @param {string} id - id of target variable
 * @returns {Variable}
 * A variable object under the given sport, or null if none exists,
 */
// -------------------------------------------------------------------------- //

export function GetVariableById(sport, id) {
  if (typeof sport === 'string') {
    sport = CreateSport(sport);
  }

  return sport.getVariableById(id);
}

// ------------------------------------------------------------------------- //

export function getVarNameFromId(sport, id) {
  const variable = GetVariableById(sport, id);

  if (variable === null) {
    return null;
  }

  return variable.name;
}

// ------------------------------------------------------------------------- //

export function getVarIdFromName(sport, name) {
  if (typeof sport === 'string') {
    sport = CreateSport(sport);
  }

  const variable = sport.getVariableByName(name);
  return (variable ? variable.id : null);
}

// -------------------------------------------------------------------------- //
/**
 * Splits the full name of a player into separate
 * first, last, long, etc. forms.
 * @param {string} full_name - full name to split
 * @returns {any}
 * Object with keys for split names (e.g. 'first', 'last').
 */
// -------------------------------------------------------------------------- //

export function SplitPlayerName(full_name) {
  let name = {
    long: String(full_name),
    first: '',
    last: '',
  };

  if (name.long.includes(',')) {
    let splits = name.long.split(',').map(i => i.trim());

    if (splits.length >= 2) { // (Last), (First)[, (Suffix)]
      name.first = splits.splice(1, 1).join(' ');
      name.last = splits.join(',');
    }
  } else if (name.long.includes(' ')) {
    let splits = name.long.split(' ').map(i => i.trim());

    if (splits.length >= 2) { // (First) (Last)[ (Suffix)]
      name.first = splits[0];
      name.last = splits.splice(1).join(' ');
    }
  }

  return name;
}

// -------------------------------------------------------------------------- //

export function UpdateSport(object, sport) {
  if (sport === null) {
    return false;
  }

  const points = Bindings.GetBindingPoints(object);
  let bindings = Bindings.GetBindings(object);

  if (points.length === 0 || bindings === null) {
    return false;
  }

  if (bindings.sport_version >= LATEST_VERSION) {
    return true;
  }

  points.forEach((point) => {
    let binding = Bindings.GetBinding(object, point);

    if (binding === null) {
      return;
    }

    binding.terms = binding.terms.map((term) => {
      if (term.type !== 'stat') {
        return term;
      }

      const remapping = sport.update(term.value, bindings.sport_version);

      switch (remapping.type) {
        case 'rename': {
          term.value = remapping.value;
          break;
        }
        case 'remove': {
          term.value = null;
          break;
        }
        default: {
          break;
        }
      }

      return term;
    }).filter((term) => term.value !== null);
  });

  bindings.sport_version = LATEST_VERSION;
  return true;
}

// ------------------------------------------------------------------------- //
