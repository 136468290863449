
import AttributePath from './AttributePath';
import SCSportImporter from './SCSportImporter';
import { SplitPlayerName } from './Sports';

class SCBasketballSportImporter extends SCSportImporter {

  constructor() {
    super('bbgame');
  }

  import(sport, xml, options = {}) {
    if (!super.import(sport, xml)) {
      return false;
    }

    this.importTeam('home', this.ids[0]);
    this.importTeam('visiting', this.ids[1]);

    for (let i = 0; i < 2; ++i) {
      this.importTeamStats(i);
    }

    if (options.players) {
      for (let i = 0; i < options.players.length; ++i) {
        this.importPlayerStats(options.players[i], i);
      }
    }
  }

  importTeam(key, id) {
    const team_id = `team.${key}`;
    const team_path = `/${this.game}/team[@id='${id}']`;

    let team = {};
    team.name = this.get(`${team_id}.name`); // the team name has been imported by SCSportImporter
    team.players = [];
    team.key = id;

    for (let i = 0;; ++i) {
      const player_path = `${team_path}/player[*][@name!='TEAM'][${i + 1}]`;

      if (!this.test(new AttributePath(player_path, 'name'))) {
        break;
      }

      team.players.push({
        name: SplitPlayerName(this.peek(new AttributePath(player_path, 'name'), { type: 'string' })),
        key: this.peek(new AttributePath(player_path, 'uni')),
        position: '', // TAS doesn't appear to supply (coherent) positions
      });
    }

    this.sport.teams.push(team);
  }

  importTeamStats(team) {
    const vh = (team === 0 ? 'H' : 'V');
    const path = `/${this.game}/team[@id='${this.ids[team]}']`;
    const linescore = `${path}/linescore`;
    const totals_stats = `${path}/totals/stats`;
    const totals_special = `${path}/totals/special`;
    const team_id = `team.${(team === 0 ? 'home' : 'visiting')}`;
    this.select(`${team_id}.score`, new AttributePath(linescore, 'score'));

    this.select(`${team_id}.field_goals.made`, new AttributePath(totals_stats, 'fgm'));
    this.select(`${team_id}.field_goals.attempts`, new AttributePath(totals_stats, 'fga'));
    this.select(`${team_id}.three_pointers.made`, new AttributePath(totals_stats, 'fgm3'));
    this.select(`${team_id}.three_pointers.attempts`, new AttributePath(totals_stats, 'fga3'));
    this.select(`${team_id}.free_throws.made`, new AttributePath(totals_stats, 'ftm'));
    this.select(`${team_id}.free_throws.attempts`, new AttributePath(totals_stats, 'fta'));
    this.select(`${team_id}.blocks`, new AttributePath(totals_stats, 'blk'));
    this.select(`${team_id}.steals`, new AttributePath(totals_stats, 'stl'));
    this.select(`${team_id}.assists`, new AttributePath(totals_stats, 'ast'));
    this.select(`${team_id}.rebounds.offensive`, new AttributePath(totals_stats, 'oreb'));
    this.select(`${team_id}.rebounds.defensive`, new AttributePath(totals_stats, 'dreb'));
    this.select(`${team_id}.rebounds.total`, new AttributePath(totals_stats, 'treb'));
    this.select(`${team_id}.turnovers`, new AttributePath(totals_stats, 'to'));
    this.select(`${team_id}.points.turnovers`, new AttributePath(totals_special, 'pts_to'));
    this.select(`${team_id}.points.second_chance`, new AttributePath(totals_special, 'pts_ch2'));
    this.select(`${team_id}.points.paint`, new AttributePath(totals_special, 'pts_paint'));
    this.select(`${team_id}.points.fastbreaks`, new AttributePath(totals_special, 'pts_fastb'));
    this.select(`${team_id}.points.bench`, new AttributePath(totals_special, 'pts_bench'));

    this.setSum(
      `${team_id}.shots.attempts`, 0,
      `${team_id}.free_throws.attempts`,
      `${team_id}.field_goals.attempts`,
      `${team_id}.three_pointers.attempts`
    );

    this.setPercent(
      `${team_id}.field_goals.percent`,
      `${team_id}.field_goals.made`,
      `${team_id}.field_goals.attempts`
    );

    this.setPercent(
      `${team_id}.three_pointers.percent`,
      `${team_id}.three_pointers.made`,
      `${team_id}.three_pointers.attempts`
    );

    this.setPercent(
      `${team_id}.free_throws.percent`,
      `${team_id}.free_throws.made`,
      `${team_id}.free_throws.attempts`
    );

    this.setPercent(
      `${team_id}.shots.percent`,
      `${team_id}.shots.made`,
      `${team_id}.shots.attempts`
    );

    for (let period = 0; period < 4; ++period) {
      const prd = (period + 1);
      const period_id = `period${prd}`;
      const lineprd = `${linescore}/lineprd[@prd='${prd}']`;
      const statsbyprd = `${path}/totals/statsbyprd[@prd='${prd}']`;
      const byprdsummary = `/bbgame/byprdsummaries/byprdsummary[@prd='${prd}']/summary[@vh='${vh}']`;
      const byprdspecial = `/bbgame/byprdsummaries/byprdsummary[@prd='${prd}']/special[@vh='${vh}']`;
      this.select(`${team_id}.score.${period_id}`, new AttributePath(lineprd, 'score'));
      this.select(`${team_id}.field_goals.made.${period_id}`, new AttributePath(statsbyprd, 'fgm'));
      this.select(`${team_id}.field_goals.attempts.${period_id}`, new AttributePath(statsbyprd, 'fga'));
      this.select(`${team_id}.field_goals.percent.${period_id}`, new AttributePath(byprdsummary, 'fgpct'));
      this.select(`${team_id}.three_pointers.made.${period_id}`, new AttributePath(statsbyprd, 'fgm3'));
      this.select(`${team_id}.three_pointers.attempts.${period_id}`, new AttributePath(statsbyprd, 'fga3'));
      this.select(`${team_id}.three_pointers.percent.${period_id}`, new AttributePath(byprdsummary, 'fg3pct'));
      this.select(`${team_id}.free_throws.made.${period_id}`, new AttributePath(statsbyprd, 'ftm'));
      this.select(`${team_id}.free_throws.attempts.${period_id}`, new AttributePath(statsbyprd, 'fta'));
      this.select(`${team_id}.free_throws.percent.${period_id}`, new AttributePath(byprdsummary, 'ftpct'));
      this.select(`${team_id}.blocks.${period_id}`, new AttributePath(statsbyprd, 'blk'));
      this.select(`${team_id}.assists.${period_id}`, new AttributePath(statsbyprd, 'ast'));
      this.select(`${team_id}.steals.${period_id}`, new AttributePath(statsbyprd, 'stl'));
      this.select(`${team_id}.rebounds.offensive.${period_id}`, new AttributePath(statsbyprd, 'rreb'));
      this.select(`${team_id}.rebounds.defensive.${period_id}`, new AttributePath(statsbyprd, 'dreb'));
      this.select(`${team_id}.rebounds.total.${period_id}`, new AttributePath(statsbyprd, 'treb'));
      this.select(`${team_id}.turnovers.${period_id}`, new AttributePath(statsbyprd, 'to'));
      this.select(`${team_id}.points.turnovers.${period_id}`, new AttributePath(byprdspecial, 'pts_to'));
      this.select(`${team_id}.points.second_chance.${period_id}`, new AttributePath(byprdspecial, 'pts_ch2'));
      this.select(`${team_id}.points.paint.${period_id}`, new AttributePath(byprdspecial, 'pts_paint'));
      this.select(`${team_id}.points.fastbreaks.${period_id}`, new AttributePath(byprdspecial, 'pts_fastb'));
      this.select(`${team_id}.points.bench.${period_id}`, new AttributePath(byprdspecial, 'pts_bench'));

      this.setSum(
        `${team_id}.shots.attempts.${period_id}`, 0,
        `${team_id}.free_throws.attempts.${period_id}`,
        `${team_id}.field_goals.attempts.${period_id}`,
        `${team_id}.three_pointers.attempts.${period_id}`
      );

      this.setPercent(
        `${team_id}.shots.percent.${period_id}`,
        `${team_id}.shots.made.${period_id}`,
        `${team_id}.shots.attempts.${period_id}`
      );
    }
  }

  importPlayerStats(player, index) {
    const id = this.ids[player.team];
    const team = this.sport.teams[player.team];
    const uni = team.players[player.player].key;
    const path = `/${this.game}/team[@id='${id}']/player[@uni='${uni}']`;

    if (!this.test(new AttributePath(path, 'name'))) {
      return false;
    }

    const options = { player: index };
    const stats = `${path}/stats`;
    const player_name = SplitPlayerName(this.peek(new AttributePath(path, 'name')));
    this.set('player.name.long', player_name.long, { index });
    this.set('player.name.first', player_name.first, { index });
    this.set('player.name.last', player_name.last, { index });
    this.set('player.position', '', index);
    this.select('player.number', new AttributePath(path, 'uni'));
    this.select('player.field_goals.made', new AttributePath(stats, 'fgm'), { index });
    this.select('player.field_goals.attempts', new AttributePath(stats, 'fga'), { index });
    this.select('player.three_pointers.made', new AttributePath(stats, 'fgm3'), { index });
    this.select('player.three_pointers.attempts', new AttributePath(stats, 'fga3'), { index });
    this.select('player.free_throws.made', new AttributePath(stats, 'ftm'), { index });
    this.select('player.free_throws.attempts', new AttributePath(stats, 'fta'), { index });
    this.select('player.blocks', new AttributePath(stats, 'blk'), { index });
    this.select('player.assists', new AttributePath(stats, 'ast'), { index });
    this.select('player.steals', new AttributePath(stats, 'stl'), { index });
    this.select('player.rebounds.offensive', new AttributePath(stats, 'oreb'), { index });
    this.select('player.rebounds.defensive', new AttributePath(stats, 'dreb'), { index });
    this.select('player.rebounds.total', new AttributePath(stats, 'treb'), { index });
    this.select('player.turnovers', new AttributePath(stats, 'to'), { index });

    this.setSum(
      'player.shots.made', index,
      'player.free_throws.made',
      'player.field_goals.made',
      'player.three_pointers.made'
    );

    this.setSum(
      'player.shots.attempts', index,
      'player.free_throws.attempts',
      'player.field_goals.attempts',
      'player.three_pointers.attempts'
    );

    this.setPercent(
      'player.free_throws.percent',
      'player.free_throws.made',
      'player.free_throws.attempts',
      index
    );

    this.setPercent(
      'player.field_goals.percent',
      'player.field_goals.made',
      'player.field_goals.attempts',
      index
    );

    this.setPercent(
      'player.three_pointers.percent',
      'player.three_pointers.made',
      'player.three_pointers.attempts',
      index
    );

    this.setPercent(
      'player.shots.percent',
      'player.shots.made',
      'player.shots.attempts',
      index
    );

    for (let period = 0; period < 4; ++period) {
      const period_id = `period${period + 1}`;
      const statsbyprd = `${path}/statsbyprd[@prd='${period + 1}']`;
      this.select(`player.field_goals.made.${period_id}`, new AttributePath(statsbyprd, 'fgm'), options);
      this.select(`player.field_goals.attempts.${period_id}`, new AttributePath(statsbyprd, 'fga'), options);
      this.select(`player.three_pointers.made.${period_id}`, new AttributePath(statsbyprd, 'fgm3'), options);
      this.select(`player.three_pointers.attempts.${period_id}`, new AttributePath(statsbyprd, 'fga3'), options);
      this.select(`player.free_throws.made.${period_id}`, new AttributePath(statsbyprd, 'ftm'), options);
      this.select(`player.free_throws.attempts.${period_id}`, new AttributePath(statsbyprd, 'fta'), options);
      this.select(`player.blocks.${period_id}`, new AttributePath(statsbyprd, 'blk'), options);
      this.select(`player.assists.${period_id}`, new AttributePath(statsbyprd, 'ast'), options);
      this.select(`player.steals.${period_id}`, new AttributePath(statsbyprd, 'stl'), options);
      this.select(`player.rebounds.offensive.${period_id}`, new AttributePath(statsbyprd, 'oreb'), options);
      this.select(`player.rebounds.defensive.${period_id}`, new AttributePath(statsbyprd, 'dreb'), options);
      this.select(`player.rebounds.total.${period_id}`, new AttributePath(statsbyprd, 'treb'), options);
      this.select(`player.turnovers.${period_id}`, new AttributePath(statsbyprd, 'to'), options);

      this.setSum(
        `player.shots.made.${period_id}`, index,
        `player.free_throws.made.${period_id}`,
        `player.field_goals.made.${period_id}`,
        `player.three_pointers.made.${period_id}`
      );

      this.setSum(
        `player.shots.attempts.${period_id}`, index,
        `player.free_throws.attempts.${period_id}`,
        `player.field_goals.attempts.${period_id}`,
        `player.three_pointers.attempts.${period_id}`
      );

      this.setPercent(
        `player.free_throws.percent.${period_id}`,
        `player.free_throws.made.${period_id}`,
        `player.free_throws.attempts.${period_id}`,
        index
      );

      this.setPercent(
        `player.field_goals.percent.${period_id}`,
        `player.field_goals.made.${period_id}`,
        `player.field_goals.attempts.${period_id}`,
        index
      );

      this.setPercent(
        `player.three_pointers.percent.${period_id}`,
        `player.three_pointers.made.${period_id}`,
        `player.three_pointers.attempts.${period_id}`,
        index
      );

      this.setPercent(
        `player.shots.percent.${period_id}`,
        `player.shots.made.${period_id}`,
        `player.shots.attempts.${period_id}`,
        index
      );
    }

    return true;
  }

}

export default SCBasketballSportImporter;
