import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import ResponsiveGrid from '../layout/ResponsiveGrid';

const queryString = require('query-string');

const styles = theme => ({
  grid: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(4),
    margin: theme.spacing(2),
  },
  buttonContainer: {
    textAlign: 'right',
  },
  button: {
    minWidth: '30%',
  },

});

const GET_EXISTING_PAGES = gql`
  query getExistingPages {
    me {
      id
      client {
        id
        fbPages {
          id
          name
          fbId
        }
      }
    }
  }
`;

const GET_PAGES = gql`
  mutation getPages($code: String!, $redirect: String!) {
    getPages(
      code: $code
      redirect: $redirect
    ) {
      access_token
      category
      name
      id

    }
  }
`;

const ADD_PAGES = gql`
  mutation addToPages($pages: Json!) {
    addToMyPages(
      pages: $pages
    ) {
      id
      fbPages {
        id
        name
        accessToken
      }
    }
  }
`;

class FbCallback extends Component {
  componentDidMount() {
    this.props.getPages({
      variables: {
        code: queryString.parse(this.props.location.search).code,
        redirect: `${process.env.REACT_APP_DOMAIN}fbcallback`,
      },
    })
      .then((response) => {
        if (response.data && response.data.getPages) {
          this.setState({
            pages: response.data.getPages,
            active: response.data.getPages.map(page => (page.id)),
          });
        } else {
          this.props.history.push('/team#social');
        }
      })
      .catch((error) => {
        this.props.history.push('/team#social');
      });
  }

  handleSelect = id => () => {
    if (this.state.active.indexOf(id) === -1) {
      this.state.active.push(id);
      this.setState({
        active: this.state.active,
      });
    } else {
      this.state.active.splice(this.state.active.indexOf(id), 1);
      this.setState({
        active: this.state.active,
      });
    }
  }

  addPages = self => () => {
    const output = [];
    const existing = self.props.data.me.client.fbPages.map(page => (page.fbId));
    self.state.pages.forEach((page) => {
      const active = existing.indexOf(page.id) !== -1;
      if (self.state.active.indexOf(page.id) !== -1 && !active) {
        output.push(page);
      }
    });
    self.props.addToPages({
      variables: {
        pages: JSON.stringify(output),
      },
    })
      .then(() => {
        window.Intercom('trackEvent','add-facebook');
        this.props.history.push('/team#social');
      });
  }


  render() {
    const { classes, data } = this.props;
    const { code } = queryString.parse(this.props.location.search);
    if (!code) {
      this.props.history.push('/team#social');
      return <h1>Something has gone wrong</h1>;
    }
    if (this.state && this.state.pages && data && data.me) {
      const existing = data.me.client.fbPages.map(page => (page.fbId));
      const output = [];
      this.state.pages.forEach((page) => {
        const active = existing.indexOf(page.id) !== -1;
        output.push((
          <ListItem
            key={page.id}
            button
            disabled={active}
            onClick={this.handleSelect(page.id)}
          >
            <Checkbox
              checked={!active && this.state.active.indexOf(page.id) !== -1}
              tabIndex={-1}
              disableRipple
              disabled={active}
            />
            <Avatar src={`https://graph.facebook.com/v2.12/${page.id}/picture?height=40`} />
            <ListItemText primary={page.name} secondary={active ? 'Already Authorized' : null} />
          </ListItem>));
      });
      return (
        <ResponsiveGrid className={classes.grid}>
          <Paper className={classes.paper}>
            <Typography variant="h4" > 
              Available Pages
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {'We\'re connected to facebook, but only want to add to ScoreShots the facebook pages you want to post from. Select them below:'}
            </Typography>
            <List>
              {output}
            </List>
            <div className={classes.buttonContainer}>
              <Button variant="raised" className={classes.button} color="primary" onClick={this.addPages(this)}>
                Add Pages
              </Button>
            </div>
          </Paper>
        </ResponsiveGrid>
      );
    }
    return <CircularProgress />;
  }
}

export default withRouter(compose(
  graphql(GET_EXISTING_PAGES),
  graphql(ADD_PAGES, { name: 'addToPages' }),
  graphql(GET_PAGES, { name: 'getPages' }),
)(withStyles(styles)(FbCallback)));
