
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Grid,
  Paper,
  Typography,
  withStyles,
  Divider,
} from '@material-ui/core';

import { graphql, compose, Query } from 'react-apollo';
import { withRouter } from 'react-router';
import gql from 'graphql-tag';

import { PageTitle } from '../title';

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  crumbs: {
    cursor: 'pointer',
  },
  imageHolder: {
    paddingTop: 'calc( 100% - 52px )',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    cursor: 'pointer',
  },
  templateLabel: {
    lineHeight: '52px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1) + 32,
    color: 'inherit',
  },
  grid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
 });

// -------------------------------------------------------------------------- //

const GET_CLIENT = gql`
  query {
    me {
      client {
        id
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const GET_TEMPLATES = gql`
  query getTemplates(
    $first: Int
    $after: String
    $where: TemplateWhereInput
    $orderBy: TemplateOrderByInput
  ) {
    templates (
      first: $first
      after: $after
      orderBy: $orderBy
      where: $where
    ) {
      id
      name
      width
      height
      image
    }
  }
`;

// -------------------------------------------------------------------------- //

class PremiumTemplates_ extends React.Component {

  launchTemplate = id => () => {
    this.props.history.push(`/editor/template/${id}`);
  }

  handleKey = func => (e) => {
    if (e.keyCode !== 9) {
      func(e);
    }
  }

  getTemplates = (templates) => {
    const { classes } = this.props;
    const outputTemplates = [];
    if (templates && templates.length > 0) {
      templates.forEach((template, index) => {
        outputTemplates.push((
          <Grid
            item
            xs={12}
            sm={4}
            md={3}
            lg={3}
            xl={3}
            key={template.id}
          >
            <Paper
              style={{
                position: 'relative',
              }}
            >
              <div
                style={{ backgroundImage: `url('${template.image}')` }}
                className={classes.imageHolder}
                onClick={this.launchTemplate(template.id)}
                draggable
                tabIndex="0"
                role="link"
                onKeyDown={this.handleKey(this.launchTemplate(template.id))}
              />
              <Typography
                variant="body1"
                noWrap
                className={classes.templateLabel}
              >
                {template.name}
              </Typography>
            </Paper>
          </Grid>
        ));
      });
    } else if (templates && templates.length === 0) {
      return (
        <Paper style={{padding:'1em'}}>
          <Typography variant="caption">
            You don't have any premium templates. Contact us for more information.
          </Typography>
        </Paper>
      );
    }
    return outputTemplates;
  }

  render() {
    const { classes } = this.props;

    let clientId = null;

    if (this.props.data && this.props.data.me.client) {
      clientId = this.props.data.me.client.id;
    }

    if (!clientId) {
      return null;
    }

    const variables = {
      where: {
        visible: 'CLIENT',
        isSlide: false,
        clients_some: {
          id: clientId
        }
      }
    };

    return (
      <React.Fragment>
        <div
          style={{ marginTop: 16, marginBottom: 16 }}
        >
          <PageTitle title="Premium Templates"/>
          <Typography
            id="main-content"
            className={classes.crumbs}
            variant="h4"
            gutterBottom
          >
            My Premium Templates
          </Typography>
          <Typography variant="body2" gutterBottom>
            Premium templates are specially made to meet your needs.
          </Typography>
          <Divider/>
        </div>
        <Grid spacing={2} container className={classes.grid}>
          <Query query={GET_TEMPLATES} variables={variables}>
            {
              ({data}) => this.getTemplates(data ? data.templates : null)
            }
          </Query>
        </Grid>
      </React.Fragment>
    );
  }

}

// -------------------------------------------------------------------------- //

export const PremiumTemplates = compose(
  graphql(GET_CLIENT),
  withRouter,
  withStyles(STYLES),
)(PremiumTemplates_);

// -------------------------------------------------------------------------- //
