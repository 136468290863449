
// -------------------------------------------------------------------------- //

import * as React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from '@material-ui/core';

import {
  Delete as DeleteIcon,
  DotsVertical as DotsVerticalIcon,
  Download as DownloadIcon,
  FolderMove as FolderMoveIcon,
  FolderPlus as FolderPlusIcon,
  RenameBox as RenameBoxIcon,
  Upload as UploadIcon,
} from 'mdi-material-ui';

import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';

import { s3factory } from '../../aws/aws';

// -------------------------------------------------------------------------- //

const ADD_FOLDER = gql`
  mutation ($name: String!, $folderId: ID, $type: MEDIA_TYPE!){
    createFolder(
      name: $name,
      folderId: $folderId,
      type: $type
    ) {
      name
    }
  }
`;

const DELETE_FILE = gql`
  mutation ($files: [ID!]!) {
    deleteFiles(
      files: $files
    ) {
      count
    }
  }
`;

const RENAME_FILE = gql`
  mutation ($id: ID!, $name: String!) {
    renameFile(
      fileId: $id
      name: $name
    ) {
      id
    }
  }
`;

const RENAME_FOLDER = gql`
  mutation ($id: ID!, $name: String!) {
    renameFolder(
      folderId: $id
      name: $name
    ) {
      id
    }
  }
`;

const DELETE_FOLDER = gql`
  mutation ($folders: [ID!]!) {
    deleteFolders(
      folders: $folders
    ) {
      count
    }
  }
`;

const GET_FILE = gql`
  query ($fileId: ID!) {
    file (
      fileId: $fileId
    ) {
      bucket
      extension
      id
      name
      client {
        id
      }
    }
  }
`;

const GET_FOLDER = gql`
  query ($folderId: ID!) {
    folder (
      folderId: $folderId
    ) {
      id
      name
    }
  }
`;

// -------------------------------------------------------------------------- //

const ITEMS = [
  {
    key: 'download',
    title: 'Download',
    icon: <DownloadIcon/>,
    condition: ({ selected, type }) => (selected.length === 1 && type === 'file'),
    callback: ({ self }) => {
      self.download();
    },
  },
  {
    key: 'rename',
    title: 'Rename',
    icon: <RenameBoxIcon/>,
    condition: ({ selected }) => (selected.length === 1),
    callback: ({ self }) => {
      self.rename();
    },
  },
  {
    key: 'upload',
    title: 'Upload',
    icon: <UploadIcon/>,
    condition: ({ searching }) => (!searching),
    callback: ({ self }) => (self.props.uploader()),
  },
  {
    key: 'add-folder',
    title: 'Add Folder',
    icon: <FolderPlusIcon/>,
    condition: ({ searching }) => (!searching),
    callback: ({ self, media_type, parent_folder }) => {
      self.openFolderNamer(media_type, parent_folder);
    }
  },
  {
    key: 'go-to-folder',
    title: 'Go To Folder',
    icon: <FolderMoveIcon/>,
    condition: ({ searching, selected }) => (searching && selected.length === 1),
    callback: ({ self }) => {
      self.closeOverflowMenu(self.props.onGoToFolder);
    }
  },
  {
    key: 'delete',
    title: 'Delete',
    icon: <DeleteIcon/>,
    condition: ({ selected }) => (selected.length > 0),
    callback: ({ self }) => {
      self.delete();
    }
  },
];

// -------------------------------------------------------------------------- //

class ItemControls extends React.Component {

  state = {
    overflow_anchor: null,
    searching: false,
    renaming: false,
    currentName: '',
    folderType: false,
    folderId: null,
    folderNaming: false,
    folderName: '',
  }

  constructor() {
    super();
    this.s3 = s3factory();
  }

  openOverflowMenu = (anchor) => {
    this.setState({ overflow_anchor: anchor });
  }

  closeOverflowMenu = (callback = null) => {
    this.setState({ overflow_anchor: null }, () => {
      if (callback) {
        callback();
      }
    });
  }

  openFolderNamer = (type, folderId) => {
    this.setState({
      folderNaming: true,
      folderId,
      folderType: type,
      folderName: '',
    });
  }

  folderNaming = self => (e) => {
    self.setState({
      folderName: e.target.value,
    });
  }

  handleNewFolderClose = (self, perform) => () => {
    self.setState({
      folderNaming: false,
    });
    if (perform) {
      this.newFolder();
    }
  }

  newFolder() {
    const folderId = this.state.folderId;
    const name = this.state.folderName;
    const type = this.state.folderType;

    this.props.addFolder({
      variables: {
        name,
        folderId,
        type,
      },
      refetchQueries: ['getContents'],
    });
  }

  download = () => {
    const { data } = this.props;

    if (data.file && !data.loading) {
      window.location.replace(`https://${data.file.bucket}.s3.us-east-2.amazonaws.com/${data.file.client.id}/${data.file.id}.${data.file.extension}`);
    }
  }

  rename = () => {
    let name = 'Rename';

    if (this.props.type === 'file' && this.props.data.file) {
      name = this.props.data.file.name;
    }

    if (this.props.type === 'folder' && this.props.data.folder) {
      name = this.props.data.folder.name;
    }

    this.setState({
      renaming: true,
      currentName: name,
    });
  }

  updateName = self => (e) => {
    self.setState({
      currentName: e.target.value,
    });
  }

  handleClose = (self, perform) => () => {
    if (perform) {
      let renamer;
      let id;
      if (self.props.type === 'file') {
        renamer = self.props.renameFile;
        id = self.props.data.file.id;
      } else if (self.props.type === 'folder') {
        renamer = self.props.renameFolder;
        id = self.props.data.folder.id;
      }
      renamer({
        variables: {
          id,
          name: self.state.currentName,
        },
        refetchQueries: ['getContents'],
      });
    }
    self.setState({
      currentName: '',
      renaming: false,
    });
  }

  delete = (e) => {
    const {
      deleteFolder, deleteFile, selected, setSelected, type,
    } = this.props;

    if (type === 'folder') {
      deleteFolder({
        variables: {
          folders: selected,
        },
        refetchQueries: ['getContents'],
      });
    } else if (type === 'file') {

      deleteFile({
        variables: {
          files: selected,
        },
        refetchQueries: ['getContents'],
      })
        .then(() => {
          selected.forEach((select) => {
          // gotta find the extension, sadly.
            let extension;
            let bucket;
            let i = 0;
            while (!extension && i < this.props.files.length) {
              if (this.props.files[i].id === select) {
                extension = this.props.files[i].extension;
                bucket = this.props.files[i].bucket;
              }
              i += 1;
            }
            if (extension && bucket) {

            }
            const params = {
              Bucket: bucket,
              Key: `${localStorage.getItem('client_id')}/${select}.${extension}`,
            };
            this.s3.deleteObject(params, () => {
              // dunno what to do here.
            });
          });


        });
    }

    this.props.changeUserStorage();
    setSelected(false, false)(e);
  }

  render() {
    const {
      selected, type, mediaType, parentFolder, searching = false
    } = this.props;

    const options = ITEMS.filter(o => o.condition({
      type,
      selected,
      searching,
    }));

    const parameters = {
      media_type: mediaType,
      parent_folder: parentFolder,
    };

    return (
      <React.Fragment>
        {
          options.slice(0, 2).map((option) => (
            <Tooltip title={option.title} id={`${option.key}-tooltip`} key={`${option.key}-tooltip`}>
              <IconButton
                color="inherit"
                aria-labelledby={`${option.key}-tooltip`}
                onClick={() => option.callback({ ...parameters, self: this })}
              >
                {option.icon}
              </IconButton>
            </Tooltip>
          ))
        }
        {
          (options.length > 2) &&
          <React.Fragment>
            <IconButton
              color="inherit"
              onClick={(e) => this.openOverflowMenu(e.target)}
            >
              <DotsVerticalIcon/>
            </IconButton>
            <Menu
              open={this.state.overflow_anchor !== null}
              anchorEl={this.state.overflow_anchor}
              onClose={() => this.closeOverflowMenu()}
            >
              {
                options.slice(2).map((option) => (
                  <MenuItem onClick={() => option.callback({ ...parameters, self: this })}>
                    <ListItemIcon>{option.icon}</ListItemIcon>
                    <ListItemText>{option.title}</ListItemText>
                  </MenuItem>
                ))
              }
            </Menu>
          </React.Fragment>
        }
        <Dialog
          open={this.state.renaming}
          onClose={this.handleClose(this, false)}
          key="renaming"
        >
          <DialogTitle id="form-dialog-title">Rename</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              title="Rename"
              label="name"
              type="text"
              value={this.state.currentName}
              onChange={this.updateName(this)}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose(this, false)} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleClose(this, true)} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.folderNaming}
          onClose={this.handleNewFolderClose(this, false)}
          key="addFolder"
        >
          <DialogTitle id="form-dialog-title">Add Folder</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              title="Add Folder"
              label="Folder Name"
              type="email"
              value={this.state.folderName}
              onChange={this.folderNaming(this)}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleNewFolderClose(this, false)} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleNewFolderClose(this, true)} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

// -------------------------------------------------------------------------- //

export default compose(
  graphql(GET_FILE, {
    skip: ({ selected, type }) => (type !== 'file' || selected.length !== 1),
    options: ({ selected }) => ({ variables: { fileId: selected[0] } }),
  }),
  graphql(GET_FOLDER, {
    skip: ({ selected, type }) => (type !== 'folder' || selected.length !== 1),
    options: ({ selected }) => ({ variables: { folderId: selected[0] } }),
  }),
  graphql(ADD_FOLDER, { name: 'addFolder' }),
  graphql(RENAME_FILE, { name: 'renameFile' }),
  graphql(RENAME_FOLDER, { name: 'renameFolder' }),
  graphql(DELETE_FOLDER, { name: 'deleteFolder' }),
  graphql(DELETE_FILE, { name: 'deleteFile' }),
)(ItemControls);

// -------------------------------------------------------------------------- //
