/* eslint react/jsx-no-duplicate-props: "off" */
// -------------------------------------------------------------------------- //

import * as React from 'react';

import {
  Button,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  InputAdornment,
  ListSubheader,
  Menu,
  MenuItem,
  Paper,
  Popover,
  TextField,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  Import as ImportIcon,
  MenuDown as MenuDownIcon,
  MenuDownOutline as MenuDownOutlineIcon,
  Refresh as RefreshIcon,
} from 'mdi-material-ui';

import { compose, graphql } from 'react-apollo';
import Autosuggest from 'react-autosuggest';
import classNames from 'classnames';
import gql from 'graphql-tag';

import * as Binding from '../../../canvas/lib/databinding';
import * as MetaData from '../../../canvas/lib/metadata';
import * as Sports from '../../sport/Sports';
import Crumbs from './Crumbs';
import Tip from './Tip';

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  root: { },
  button: {
    marginBottom: theme.spacing(1),
  },
  button_icon: {
    position: 'absolute',
    left: theme.spacing(2),
    opacity: 0.87,
  },
  content: {
    margin: theme.spacing(2),
  },
  expand_icon_collapsed: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expand_icon_expanded: {
    transform: 'rotate(180deg)',
  },
  suggestion: {
    display: 'block',
  },
  suggestions_list: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  container: {
    flex: 1,
  },
});

// -------------------------------------------------------------------------- //

function gatherBoundObjects(canvas) {
  return canvas.getObjects().filter(Binding.HasBindings);
};

// -------------------------------------------------------------------------- //

function updateBoundObjects(objects, sport) {
  objects.forEach(object => Binding.UpdateBindings(object, sport));
  return objects;
};

// -------------------------------------------------------------------------- //

function getVariableText(variable, index = 0) {
  const value = variable.getValue(index);

  if (value === undefined || value === null) {
    return '';
  }

  if (variable.type === 'number' && isNaN(value)) {
    return '';
  }

  return String(value);
}

// -------------------------------------------------------------------------- //

// the transform prop here is to fix a known chrome issue
// see mui-org/material-ui/commit/9b9421c for details
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MENU_PROPS = {
  PaperProps: {
    style: {
      maxHeight: (ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP),
      transform: 'translate3D(0,0,0)',
    },
  },
};

// -------------------------------------------------------------------------- //

const GET_ROSTERS = gql`
  query {
    rosterlist {
      id
      name
      category
      members {
        id
        name
        firstname
        shortname
        number
        position
        year
        height
        weight
        avatar
        starter
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

function getSuggestionValue(suggestion) {
  return suggestion.name;
}

// -------------------------------------------------------------------------- //

function fetchSuggestions(roster_members, value) {
  const MAXIMUM = 5;
  let count = 0;

  return roster_members.filter(member => {
    if (count >= MAXIMUM) {
      return false;
    }

    const lhs = getSuggestionValue(member).toUpperCase();
    const rhs = value.toUpperCase();

    if (!lhs.includes(rhs)) {
      return false;
    }

    ++count;
    return true;
  });
}

// -------------------------------------------------------------------------- //

function renderSuggestionInput(props) {
  const { classes, onSuggest, inputRef, ref, ...other } = props;
  return (
    <TextField
      {...other}
      inputRef={(node) => {
        if (inputRef) {
          inputRef(node);
        }

        if (ref) {
          ref(node);
        }
      }}
    />
  )
}

// -------------------------------------------------------------------------- //

function renderSuggestion(suggestion, { isHighlighted }) {
  return (
    <MenuItem selected={isHighlighted}>
      <Typography variant="body1">{getSuggestionValue(suggestion)}</Typography>
    </MenuItem>
  );
}

// -------------------------------------------------------------------------- //

class Stat extends React.Component {

  state = {
    text: '',
    focused: false,
    suggestions: [],
  }

  constructor(props) {
    super(props);
    this.anchor = null; // this.anchor = React.createRef();
  }

  handleTyping = (e) => {
    const { variable, index = 0, onChange } = this.props;
    const text = e.target.value;
    const value = variable.convertValue(text);
    this.setState({ text, value });
    (onChange && onChange(variable, value, index));
  }

  handleFocusChange = (focused) => {
    if (focused) {
      const { variable, index = 0 } = this.props;
      const text =  getVariableText(variable, index);
      this.setState({ text, focused });
    } else {
      this.setState({ focused });
    }
  }

  fetchSuggestions = (e) => {
    const { roster_members } = this.props;
    const suggestions = fetchSuggestions(roster_members, e.value);
    this.setState({ suggestions });
  }

  clearSuggestions = () => {
    this.setState({ suggestions: [] });
  }

  selectSuggestion = (_, data) => {
    this.setState({ text: '', suggestions: [] }, () => {
      const { onSuggest } = this.props;

      if (onSuggest) {
        onSuggest(data.suggestion);
      }
    });
  }

  render() {
    const {
      classes,
      variable,
      index = 0,
      ...rest_props
    } = this.props;

    const { suggestions } = this.state;
    const value = getVariableText(variable, index);
    const error = (variable.type === 'number' && isNaN(value.trim()));
    const width = (this.anchor ? this.anchor.clientWidth : null);
    const is_name = (variable.short_name === 'Name');

    if (is_name) {
      return (
        <Autosuggest
          id={`autosuggest.${variable.id}.${index}`}
          highlightFirstSuggestion
          suggestions={suggestions}
          getSuggestionValue={getSuggestionValue}
          onSuggestionsFetchRequested={this.fetchSuggestions}
          onSuggestionsClearRequested={this.clearSuggestions}
          onSuggestionSelected={this.selectSuggestion}
          renderInputComponent={renderSuggestionInput}
          renderSuggestion={renderSuggestion}
          inputProps={{
            ...rest_props,
            value,
            inputRef: (node) => { this.anchor = node; },
            onChange: this.handleTyping,
          }}
          theme={{
            container: classes.container,
            suggestion: classes.suggestion,
            suggestionsList: classes.suggestions_list,
          }}
          renderSuggestionsContainer={options => (
            <Popover
              disableAutoFocus
              disableEnforceFocus
              disableRestoreFocus
              open={Boolean(options.children)}
              anchorEl={this.anchor}
              anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
              }}
            >
              <Paper square {...options.containerProps} style={{ width }}>
                {options.children}
              </Paper>
            </Popover>
          )}
        />
      );
    } else {
      return (
        <TextField
          {...rest_props}
          value={value}
          error={error}
          onChange={(e) => this.handleTyping(e)}
          onFocus={() => this.handleFocusChange(true)}
          onBlur={() => this.handleFocusChange(false)}
        />
      );
    }
  }

};

// -------------------------------------------------------------------------- //

class Lineup extends React.Component {

  state = {
    collapse_index: -1,
    import_menu_anchor: null,
    member_menu_anchor: null,
    member_menu_index: -1,
  }

  constructor() {
    super();
    this.sports = {};

    for (let key in Sports.sports) {
      this.sports[key] = Sports.CreateSport(key);
    }

    this.sports.default = Sports.CreateSport();
    let prestoSite = localStorage.getItem('prestoSite');
    if (prestoSite) {
      this.importPrestoRosterMembers();
    }
  }

  render() {
    const { classes } = this.props;

    let prestoSite = localStorage.getItem('prestoSite');
    if (prestoSite === 'true') {
      return (
        <div className={classes.root}>
          <Crumbs title="Lineup"/>
          <div className={classes.content}>
            {this.renderLineup()}
            {this.renderImportMenu()}
            {this.renderMemberMenu()}
          </div>
        </div>
      );
    }
    return (
      <div className={classes.root}>
        <Crumbs title="Lineup"/>
        <Tip heading="Tip:" text="You can fill out your lineups using rosters created in the Media Library."/>
        <div className={classes.content}>
          <Button
            fullWidth
            className={classes.button}
            onClick={(e) => this.openImportMenu(e.currentTarget)}
          >
            <ImportIcon className={classes.button_icon}/>
            Import Roster
          </Button>
          <Button
            fullWidth
            className={classes.button}
            onClick={() => this.resetLineup()}
          >
            <RefreshIcon className={classes.button_icon}/>
            Reset Lineup
          </Button>
          {this.renderLineup()}
          {this.renderImportMenu()}
          {this.renderMemberMenu()}
        </div>
      </div>
    );
  }

  getRosterMembers = () => {
    const { canvas } = this.props;
    return MetaData.getMetaData(canvas, 'roster', []);
  }

  openImportMenu = (anchor_el) => {
    this.setState({
      import_menu_anchor: anchor_el,
    });
  }

  closeImportMenu = () => {
    this.setState({
      import_menu_anchor: null,
    });
  }

  renderImportMenu = () => {
    const { roster_query } = this.props;
    const { import_menu_anchor } = this.state;

    const unique_filter = (value, index, self) => (
      self.indexOf(value) === index
    );

    const categories = (
      roster_query.loading ? [] :
        roster_query.rosterlist
          .map(roster => roster.category)
          .filter(unique_filter)
    );

    return (
      <Menu
        {...MENU_PROPS}
        anchorEl={import_menu_anchor}
        open={Boolean(import_menu_anchor)}
        onClose={() => this.closeImportMenu()}
      >
        {categories.map((category, category_index) => (
          <div key={category_index}>
            <ListSubheader disableSticky>{category}</ListSubheader>
            {
              roster_query.rosterlist
                .filter(roster => roster.category === category)
                .map((roster, roster_index) => (
                  <MenuItem
                    key={roster_index}
                    onClick={() => this.importRosterMembers(roster)}
                  >
                    {roster.name}
                  </MenuItem>
                ))
            }
          </div>
        ))}
        {
          roster_query.loading &&
          <CircularProgress size={48}/>
        }
      </Menu>
    );
  }

  openMemberMenu = (anchor_el, index) => {
    this.setState({
      member_menu_anchor: anchor_el,
      member_menu_index: index,
    });
  }

  closeMemberMenu = () => {
    this.setState({
      member_menu_anchor: null
    });
  }

  renderMemberMenu = () => {
    const { member_menu_anchor, member_menu_index } = this.state;
    const variable = this.getCanvasVariable('player.name.long');
    let roster_members = this.getRosterMembers();

    return (
      <Menu
        {...MENU_PROPS}
        anchorEl={member_menu_anchor}
        open={Boolean(member_menu_anchor)}
        onClose={() => this.closeMemberMenu()}
      >
        {roster_members.map((member, index) => (
          <MenuItem
            key={index}
            selected={variable && variable.getValue(member_menu_index) === member.name}
            onClick={() => {
              this.loadRosterMember(member, member_menu_index);
              this.closeMemberMenu();
            }}
          >
            {member.name}
          </MenuItem>
        ))
        }
      </Menu>
    );
  }

  renderLineup = () => {

    const players = this.getCanvasPlayers();

    return players.map((_, index) => {
      const element = this.renderPlayer(index);

      if (element === null) {
        return null;
      }

      return (
        <React.Fragment key={index}>
          <Divider/>
          {element}
        </React.Fragment>
      );
    });
  }

  renderPlayer = (index) => {
    const { classes } = this.props;
    const { collapse_index } = this.state;
    let roster_members = this.getRosterMembers();
    let extra_toolbar = [];

    const main_toolbar_table = [
      { id: 'player.name.long', label: 'Name' },
      { id: 'player.name.first', label: 'First' },
      { id: 'player.name.last', label: 'Last' },
    ];

    const main_toolbar_count = (
      (main_toolbar_table.filter(v => this.isStatOnCanvas(v.id, index))).length
    );

    let main_toolbar = main_toolbar_table
    .filter(v => this.isStatOnCanvas(v.id, index))
    .map(({ id, label }, main_toolbar_index) => {
      const variable = this.getCanvasVariable(id);
      return (
        <Stat
          classes={classes}
          key={id}
          variable={variable}
          index={index}
          label={label}
          style={{ flex: 1, paddingRight: 8, width: '100%' }}
          onChange={(_, v, i) => this.setCanvasVariable(id, v, i)}
          onSuggest={(member) => this.loadRosterMember(member, index)}
          roster_members={roster_members}
          InputProps={{
            endAdornment: (
              (roster_members.length > 0) &&
              (main_toolbar_index === main_toolbar_count - 1) &&
              <InputAdornment
                position="end"
                style={{ marginLeft: 0 }}
              >
                <IconButton
                  edge="start"
                  size='small'
                  onClick={(e) => this.openMemberMenu(e.currentTarget, index)}
                >
                  <MenuDownIcon/>
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputProps={{
          }}
        />
      );
    });

    if (this.isStatOnCanvas('player.number')) {
      const variable = this.getCanvasVariable('player.number');

      extra_toolbar.push(
        <Stat
          key={variable.id}
          fullWidth
          label="No."
          classes={classes}
          roster_members={roster_members}
          style={{ flex: 1 }}
          variable={variable}
          onChange={(_, v, i) => this.setCanvasVariable(variable.id, v, i)}
          index={index}
        />
      );
    }

    if (this.isStatOnCanvas('player.position')) {
      const variable = this.getCanvasVariable('player.position');

      if (extra_toolbar.length > 0) {
        extra_toolbar.push(<div style={{ width: 8 }} key={extra_toolbar}/>);
      }

      extra_toolbar.push(
        <Stat
          key={variable.id}
          fullWidth
          label="Pos."
          style={{ flex: 1 }}
          variable={variable}
          onChange={(_, v, i) => this.setCanvasVariable(variable.id, v, i)}
          index={index}
        />
      );
    }

    if (this.isStatOnCanvas('player.height')) {
      const variable = this.getCanvasVariable('player.height');

      if (extra_toolbar.length > 0) {
        extra_toolbar.push(<div style={{ width: 8 }}/>);
      }

      extra_toolbar.push(
        <Stat
          fullWidth
          label="Ht."
          style={{ flex: 1 }}
          variable={variable}
          onChange={(_, v, i) => this.setCanvasVariable(variable.id, v, i)}
          index={index}
        />
      );
    }

    if (this.isStatOnCanvas('player.weight')) {
      const variable = this.getCanvasVariable('player.weight');

      if (extra_toolbar.length > 0) {
        extra_toolbar.push(<div style={{ width: 8 }}/>);
      }

      extra_toolbar.push(
        <Stat
          fullWidth
          label="Wt."
          style={{ flex: 1 }}
          variable={variable}
          onChange={(_, v, i) => this.setCanvasVariable(variable.id, v, i)}
          index={index}
        />
      );
    }

    if (this.isStatOnCanvas('player.year')) {
      const variable = this.getCanvasVariable('player.year');

      if (extra_toolbar.length > 0) {
        extra_toolbar.push(<div style={{ width: 8 }}/>);
      }

      extra_toolbar.push(
        <Stat
          fullWidth
          label="Yr."
          style={{ flex: 1 }}
          variable={variable}
          onChange={(_, v, i) => this.setCanvasVariable(variable.id, v, i)}
          index={index}
        />
      );
    }

    if (main_toolbar.length === 0 && extra_toolbar.length === 0) {
      return null;
    }

    return (
      <React.Fragment>
        <Toolbar disableGutters>
          {main_toolbar}
          {
            (extra_toolbar.length > 0) &&
            <IconButton
              onClick={() => this.changeCollapseIndex(index)}
              className={classNames(
                classes.expand_icon_collapsed,
                { [classes.expand_icon_expanded]: (collapse_index === index) }
              )}
            >
              <MenuDownOutlineIcon/>
            </IconButton>
          }
        </Toolbar>
        {
          (extra_toolbar.length > 0) &&
          <Collapse in={collapse_index === index}>
            <Toolbar disableGutters>
              {extra_toolbar}
              <div style={{ width: 48 }}/>
            </Toolbar>
          </Collapse>
        }
      </React.Fragment>
    );
  }

  resetLineup = () => {
    const { canvas, editor } = this.props;
    const sport = this.getCanvasSport();

    if (sport) {
      sport.reset(variable => variable.lineup);
      editor.performBinding(true);
    }

    MetaData.setMetaData(canvas, 'roster', []);
  }

  changeCollapseIndex = (index) => {
    let { collapse_index } = this.state;

    if (collapse_index === index) {
      collapse_index = -1;
    } else {
      collapse_index = index;
    }

    this.setState({ collapse_index });
  }

  importPrestoRosterMembers = () => {
    const teamids = localStorage.getItem('teamids');
    const bearer = localStorage.getItem('bearer');
    let prestoAPIEndpoint = process.env.REACT_APP_PS_GAMEDAY_API_ENDPOINT;
    if (teamids && teamids !== '') {
      fetch(prestoAPIEndpoint + 'teams/' + teamids + '/players', {
        headers: {
          'Authorization': 'Bearer ' + bearer
        }
      })
        .then((response) => response.json())
        .then((jsonData) => {
          if (jsonData && jsonData.data) {
            let members = [];
            jsonData.data.forEach((member_record) => {
              members = [
                ...members,
                {
                  'name': (member_record.firstName + ' ' + member_record.lastName),
                  'firstname': member_record.firstName,
                  'shortname': member_record.lastName,
                  'position': member_record.position,
                  'number': member_record.uniform,
                  'year': member_record.name,
                  'height': member_record.height,
                  'weight': member_record.weight,
                  'starter': false,
                  'avatar': member_record.headshot,
                  'id': member_record.playerId,
                },
              ];
            });
            members.sort((a, b) => {
              const firstNameA = a.name.toUpperCase();
              const firstNameB = b.name.toUpperCase();

              let comparison = 0;
              if (firstNameA > firstNameB) {
                comparison = 1;
              } else if (firstNameA < firstNameB) {
                comparison = -1;
              }
              return comparison;
            });
            const players = this.getCanvasPlayers();
            const player_count = ((players && players.length) || 0);
            let player = 0;

            for (let i = 0; i < members.length && player < player_count; ++i) {
              const member = members[i];

              if (member.starter) {
                this.loadRosterMember(member, player++, false);
              }
            }

            let { editor } = this.props;
            editor.performBinding(true);

            let { canvas } = this.props;
            MetaData.setMetaData(canvas, 'roster', members);
            this.setState({ import_menu_anchor: null });
          }
          this.forceUpdate();
        })
        .catch((error) => {
          console.error('error', error);
        });
    }

    /*const { members } = roster;
    const players = this.getCanvasPlayers();
    const player_count = ((players && players.length) || 0);
    let player = 0;

    for (let i = 0; i < members.length && player < player_count; ++i) {
      const member = members[i];

      if (member.starter) {
        this.loadRosterMember(member, player++, false);
      }
    }

    let { editor } = this.props;
    editor.performBinding(true);

    let { canvas } = this.props;
    MetaData.setMetaData(canvas, 'roster', members);
    this.setState({ import_menu_anchor: null });*/
  }


  importRosterMembers = (roster) => {
    const { members } = roster;
    const players = this.getCanvasPlayers();
    const player_count = ((players && players.length) || 0);
    let player = 0;

    for (let i = 0; i < members.length && player < player_count; ++i) {
      const member = members[i];

      if (member.starter) {
        this.loadRosterMember(member, player++, false);
      }
    }

    let { editor } = this.props;
    editor.performBinding(true);

    let { canvas } = this.props;
    MetaData.setMetaData(canvas, 'roster', members);
    this.setState({ import_menu_anchor: null });
  }

  loadRosterMember = (member, index, refresh = true) => {
    this.setCanvasVariable('player.name.long', member.name, index, false);
    this.setCanvasVariable('player.name.first', member.firstname, index, false);
    this.setCanvasVariable('player.name.last', member.shortname, index, false);
    this.setCanvasVariable('player.position', member.position, index, false);
    this.setCanvasVariable('player.number', member.number, index, false);
    this.setCanvasVariable('player.height', member.height, index, false);
    this.setCanvasVariable('player.weight', member.weight, index, false);
    this.setCanvasVariable('player.year', member.year, index, false);
    if (member.avatar !== 'http://placekitten.com/256/256') {
      this.setCanvasVariable('player.image.head', member.avatar, index, false);
    } else {
      this.setCanvasVariable('player.image.head', '', index, false);
    }

    if (refresh) {
      let { editor } = this.props;
      editor.performBinding(true);
      this.forceUpdate();
    }
  }

  getSportCategory = () => {
    const { template } = this.props;
    return Sports.GetCategorySport(template && template.categories);
  }

  getTemplateSport = () => {
    return this.sports[this.getSportCategory()];
  }

  getCanvasSport = () => {
    const { sports } = this.props;
    return sports[this.getSportCategory()];
  }

  gatherVariables = (player = null) => {
    const { CanvasUtil } = this.props;
    const sport = this.getCanvasSport();
    const objects = gatherBoundObjects(CanvasUtil.canvas);
    return Binding.GatherStats(updateBoundObjects(objects, sport), player);
  }

  isStatOnCanvas = (id, player = null) => {
    return this.gatherVariables(player).some(variable => variable === id);
  }

  getTemplateVariable = (id) => {
    const sport = this.getTemplateSport();

    if (!sport) {
      return null;
    }

    return sport.getVariableById(id);
  }

  getCanvasVariable = (id) => {
    const sport = this.getCanvasSport();

    if (!sport) {
      return null;
    }

    return sport.getVariableById(id);
  }

  setCanvasVariable = (id, value, index = 0, refresh = true) => {
    const { editor } = this.props;
    const sport = this.getCanvasSport();

    if (!sport) {
      return;
    }

    const variable = sport.getVariableById(id);

    if (!variable) {
      return;
    }

    variable.setValue(value, index);

    if (refresh) {
      editor.performBinding();
      this.forceUpdate();
    }
  }

  getCanvasPlayers = () => {
    const { CanvasUtil } = this.props;
    return CanvasUtil.getCanvasMetadata('players', []);
  }

}

// -------------------------------------------------------------------------- //
export default compose(
  graphql(GET_ROSTERS, { name: 'roster_query' }),
  withStyles(STYLES, { withTheme: true }),
)(Lineup);

// -------------------------------------------------------------------------- //
