
// -------------------------------------------------------------------------- //

import * as React from 'react';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControl,
  Hidden,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  ChevronDown as ChevronDownIcon,
} from 'mdi-material-ui';

import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';

import { ROLES_QUERY } from '../user/Permissions';

// -------------------------------------------------------------------------- //

const USERS_QUERY = gql`
  query {
    me {
      id
      isActive
      type
      client {
        id
        users {
          id
          email
          firstname
          lastname
          type
          isActive
          role {
            id
          }
        }
      }
      role {
        ${ROLES_QUERY}
      }
    }
  }
`;

const ASSIGN_ROLE = gql`
  mutation assignRole($userId: ID!, $roleId: ID!) {
    assignRole(
      userId: $userId,
      roleId: $roleId,
    ) {
      id
    }
  }
`;


// -------------------------------------------------------------------------- //

const ADD_USER = gql`
  mutation addUserToClient($email: String!, $firstname: String!, $lastname: String!) {
    addUserToClient(
      email: $email,
      firstname: $firstname,
      lastname: $lastname
    ) {
      id
      users {
        id
        email
        firstname
        lastname
        type
        permissions
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const CHANGE_PERMISSIONS = gql`
  mutation changeUserPermissions($userId: ID!, $permissions: [USER_PERMISSIONS!]!) {
    changeUserPermissions(
      userId: $userId,
      permissions: $permissions
    ) {
      id
      permissions
    }
  }
`;

// -------------------------------------------------------------------------- //

const DELETE_USER = gql`
  mutation deleteUserOnClient($userId: ID!) {
    deleteUserOnClient(
      userId: $userId
    ) {
      id
      users {
        id
        email
        firstname
        lastname
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const SET_USER_IS_ACTIVE = gql`mutation setUserIsActive($id : ID!, $isActive : Boolean!){
  setUserIsActive( id : $id, isActive : $isActive ){
      id
  }
}`;

// -------------------------------------------------------------------------- //

const CLIENT_ROLES = gql`
  query {
    clientRoles {
      id
      name
    }
  }
`;

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  check_box: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1rem',
    fontWeight: 400,
    fontFamily: 'Ubuntu,Roboto,sans-serif',
    lineHeight: '1.5em',
  },
  column: {
    flexBasis: '33%',
    flexShrink: 1,
    marginRight: theme.spacing(2),
  },
  field_holder: {
    maxWidth: 300,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  paper: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  permissions_box: {
    width: 220,
  },
  secondary_heading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  select_field: {
    width: '33%',
  },
  settings_header: {
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
    },
  },
  settings_list: {
    marginBottom: theme.spacing(2),
  },
  settings_root: {
    display: 'block',
    width: '100%',
  },
  user_actions: {
    margin: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: '0 0 auto',
  },
  user_list: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});

// -------------------------------------------------------------------------- //

class SettingUsers extends React.Component {
  state = {
    adding_user: false,
    user_active_dialog: false,
    save_changes: false,
    userfname: '',
    userlname: '',
    useremail: '',
    user_roles: null,
    user_data: null,
    selected_user: -1,
  }

  componentDidMount() {
    const { roles_query } = this.props;

    roles_query.refetch().then((roles) => {
      const user_roles = (
        (roles.data && roles.data.clientRoles) || []
      );

      this.setState({ user_roles });
    });
  }

  componentDidUpdate() {
    let { user_data } = this.state;

    if (user_data) {
      return;
    }

    const users = this.getDataUsers();

    if (users) {
      user_data = users.map((user) => ({
        role: ((user.role && user.role.id) || null),
        dirty: false,
      }));
      this.setState({ user_data });
    }
  }

  render() {
    const { classes } = this.props;
    const { user_data } = this.state;
    const me = this.getDataMe();
    const prestoSite = localStorage.getItem('prestoSite');
    console.log(`main render ${user_data}`)

    if (me && me.role && me.role.name) {
      if (me?.role?.name === 'Superuser' || prestoSite === 'true') {
        return (
          <Paper className={classes.paper}>
            <Typography variant="h4" component="h2" gutterBottom>
              User Accounts
            </Typography>
            <Typography variant="subtitle1" component="h3" gutterBottom>
              {
                prestoSite === 'true' ? (
                  <>You can edit the users of your ScoreShots team.</>
                ) : (
                  <>You can add additional users to your ScoreShots team.</>
                )
              }
            </Typography>
            <div className={classes.user_list}>
              {
                (!user_data)
                && <CircularProgress size={48} />
              }
              {
                (user_data)
                && (
                  <>
                    {this.renderUserList((user) => user.isActive)}
                    {this.renderUserList((user) => !user.isActive)}
                  </>
                )
              }
            </div>
            {
              prestoSite !== 'true' && (
              <div className={classes.user_actions}>
                <Button
                  color="primary"
                  onClick={() => this.openAddUserDialog()}
                >
                      Add New User
                </Button>
              </div>
              )
            }
            {this.renderAddUserDialog()}
            {this.renderUserActiveDialog()}
          </Paper>
        );
      }
    }
    return null;
  }

  getDataMe = () => {
    const { data } = this.props;
    console.log(data)
    return ((data && data.me) || null);
  }

  getDataUsers = () => {
    const { data } = this.props;

    return (
      (data && data.me && data.me.client && data.me.client.users) || null
    );
  }

  renderUserList = (filter = null) => {
    const me = this.getDataMe();
    const users = this.getDataUsers();

    if (!users) {
      return null;
    }

    const user_list = [];

    for (let i = 0; i < users.length; ++i) {
      if (filter && !filter(users[i])) {
        continue;
      }

      user_list.push(this.renderUser(users[i], i, me));
    }

    return user_list;
  }

  renderUser = (user, user_index, self) => {
    const { classes } = this.props;
    const { selected_user, user_data, user_roles } = this.state;
    const self_superuser_flag = (self.type === 'SUPERUSER');
    const user_superuser_flag = (user.type === 'SUPERUSER');
    const prestoSite = localStorage.getItem('prestoSite');
    const myself_flag = (self.id === user.id);
    let menu_items;

    if (user_roles) {
      menu_items = user_roles.map((role, role_index) => (
        <MenuItem key={role_index} value={role.id}>
          <ListItemText>{role.name}</ListItemText>
        </MenuItem>
      ));
    } else {
      menu_items = (
        <MenuItem disabled value="">
          <CircularProgress />
        </MenuItem>
      );
    }
    if (user_data[user_index]) {
      return (
        <ExpansionPanel
          key={user.id}
          disabled={myself_flag || user_superuser_flag}
          expanded={selected_user === user_index}
          onChange={(_, expanded) => this.setSelectedUser(user_index, expanded)}
        >
          <ExpansionPanelSummary expandIcon={<ChevronDownIcon />}>
            <div className={classes.column}>
              <Typography className={classes.heading} component="h4">
                {user.firstname}
                {' '}
                {user.lastname}
                {' '}
                {myself_flag && '(me)'}
              </Typography>
            </div>
            <Hidden xsDown>
              <div className={classes.column}>
                <Typography className={classes.secondary_heading}>
                  {user.email}
                </Typography>
              </div>
            </Hidden>
          </ExpansionPanelSummary>
          <Hidden xsDown>
            <ExpansionPanelDetails className={classes.details}>
              <div className={classes.settings_root}>
                <div className={classes.settings_header}>
                  <Typography variant="caption">
                    Settings
                  </Typography>
                </div>
                <List className={classes.settings_list}>
                  <ListItem divider>
                    <ListItemText
                      primary="User Role"
                      secondary="Changes what permissions or restrictions this user has across the platform."
                    />
                    <FormControl className={classes.select_field}>
                      <Select
                        value={user_data[user_index].role}
                        onChange={(e) => {
                          user_data[user_index].role = e.target.value;
                          user_data[user_index].dirty = true;
                          this.forceUpdate();
                        }}
                      >
                        {menu_items}
                      </Select>
                    </FormControl>
                  </ListItem>
                </List>
                <div className={classes.user_actions}>
                  {
                  prestoSite !== 'true' && (
                      <Button
                        disabled={!self_superuser_flag}
                        onClick={() => this.openUserActiveDialog()}
                      >
                        {user.isActive ? 'Deactivate' : 'Activate'}
                        {' '}
                        User
                      </Button>
                    )
                  }
                  <Button
                    disabled={!user_data[user_index].dirty}
                    onClick={() => this.updateUserRole(user.id,
                      user_data[user_index].role, user_index)}
                  >
                    Save Changes
                  </Button>
                </div>
              </div>
            </ExpansionPanelDetails>
          </Hidden>
        </ExpansionPanel>
      );
    }
    return (
      <CircularProgress size={48} />
    );
  }

  deleteUser = (self, userId) => () => {
    self.props.deleteUserOnClient({
      variables: {
        userId,
      },
    })
      .then(() => {
        self.props.setMessage('User Deleted', 3000);
      });
  }

  updateUserRole = (id, role, user_index) => {
    const { assignRole: mutation, setMessage } = this.props;

    const variables = {
      userId: id,
      roleId: role,
    };

    mutation({ variables })
      .then(() => {
        this.setSelectedUser(user_index, false);
        setMessage('User updated', 3000);
      });
  }

  openAddUserDialog = () => {
    this.setState({
      adding_user: true,
      userfname: '',
      userlname: '',
      useremail: '',
    });
  }

  closeAddUserDialog = (do_work = false) => {
    if (do_work) {
      const { userfname, userlname, useremail } = this.state;
      const email = useremail.toLowerCase();

      this.props.addUser({
        variables: {
          firstname: userfname,
          lastname: userlname,
          email,
        },
        refetchQueries: [{ query: USERS_QUERY }],
      })
        .then(() => {
          const users = this.getDataUsers();
          let user_data = null;
          if (users) {
            user_data = users.map((user) => ({
              role: ((user.role && user.role.id) || null),
              dirty: false,
            }));
          }
          this.setState({
            adding_user: false,
            user_data,
          });
          window.Intercom('trackEvent', 'add-user-to-team', { email });
        });
    } else {
      this.setState({
        adding_user: false,
        userfname: '',
        userlname: '',
        useremail: '',
      });
    }
  }

  setUserActive = async (user_id, active) => {
    const { setUserIsActive: mutation } = this.props;

    const variables = {
      id: user_id,
      isActive: !active,
    };

    return mutation({ variables });
  }

  renderAddUserDialog = () => {
    const { classes } = this.props;
    const {
      adding_user, useremail, userfname, userlname,
    } = this.state;

    return (
      <Dialog
        open={adding_user}
        onClose={() => this.closeAddUserDialog()}
      >
        <DialogTitle>Add User</DialogTitle>
        <DialogContent className={classes.field_holder}>
          <DialogContentText>
            We'll send an email to your team member, and set them up with an account.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            value={useremail}
            onChange={(e) => this.setState({ useremail: e.target.value })}
            fullWidth
          />
          <TextField
            margin="dense"
            id="fname"
            label="First Name"
            type="text"
            value={userfname}
            onChange={(e) => this.setState({ userfname: e.target.value })}
            fullWidth
          />
          <TextField
            margin="dense"
            id="lname"
            label="Last Name"
            type="text"
            value={userlname}
            onChange={(e) => this.setState({ userlname: e.target.value })}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => this.closeAddUserDialog()}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => this.closeAddUserDialog(true)}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  setSelectedUser = (user_index, expanded) => {
    const { roles_query, usersQuery } = this.props;
    let { user_data } = this.state;
    if (expanded) {
      this.setState({ user_roles: null });
      roles_query.refetch().then((roles) => {
        const user_roles = (
          (roles.data && roles.data.clientRoles) || []
        );
        usersQuery.refetch().then(() => {
          const users = this.getDataUsers();
          if (users) {
            user_data = users.map((user) => ({
              role: ((user.role && user.role.id) || null),
              dirty: false,
            }));
          }
          this.setState({ user_roles, user_data });
          this.setState({ selected_user: user_index });
        });
      });
    } else {
      this.setState({ selected_user: -1 });
    }
  }

  openUserActiveDialog = () => {
    this.setState({ user_active_dialog: true });
  }

  closeUserActiveDialog = (do_work = false) => {
    if (do_work) {
      const { data } = this.props;
      const { selected_user } = this.state;
      const users = this.getDataUsers();
      const user_id = users[selected_user].id;
      const user_active = (users[selected_user].isActive);
      this.setUserActive(user_id, user_active)
        .then(() => data.refetch())
        .then(() => {
          this.setState({ user_active_dialog: false });
        });
    } else {
      this.setState({ user_active_dialog: false });
    }
  }

  renderUserActiveDialog = () => {
    const { user_active_dialog, selected_user } = this.state;
    const users = this.getDataUsers();

    if (!users) {
      return null;
    }

    const user = users[selected_user];

    if (!user) {
      return null;
    }

    return (
      <Dialog
        open={user_active_dialog}
        onClose={() => this.closeUserActiveDialog()}
      >
        <DialogTitle>
          { user.isActive ? 'Deactivate' : 'Activate' }
          {' '}
user account?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            The user will
            {' '}
            { user.isActive && 'not' }
            {' '}
be able to log in to his or her account.
            { user.isActive && 'You can reactivate the account at a later time.' }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => this.closeUserActiveDialog()}>
            Cancel
          </Button>
          <Button color="primary" onClick={() => this.closeUserActiveDialog(true)}>
            { user.isActive ? 'Deactivate' : 'Activate' }
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

// -------------------------------------------------------------------------- //

export default compose(
  graphql(ADD_USER, { name: 'addUser' }),
  graphql(CHANGE_PERMISSIONS, { name: 'changePermissions' }),
  graphql(ASSIGN_ROLE, { name: 'assignRole' }),
  graphql(DELETE_USER, { name: 'deleteUserOnClient' }),
  graphql(USERS_QUERY, { name: 'usersQuery' }),
  graphql(USERS_QUERY),
  graphql(CLIENT_ROLES, { name: 'roles_query', options: { fetchPolicy: 'network-only' } }),
  graphql(SET_USER_IS_ACTIVE, { name: 'setUserIsActive' }),
  withStyles(STYLES),
)(SettingUsers);

// -------------------------------------------------------------------------- //
