
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Hidden,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  withStyles,
} from '@material-ui/core';

import {
  FolderMultipleImage as FolderMultipleImageIcon,
  Home as HomeIcon,
  MessageText as MessageTextIcon,
  Pencil as PencilIcon,
  Settings as SettingsIcon,
} from 'mdi-material-ui';

import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import { UserMenu } from './UserMenu';
import { VirtualTourContext } from '../app/tour';
import Logo from '../svg/Logo';
import ResponsiveGrid from '../layout/ResponsiveGrid';

// -------------------------------------------------------------------------- //

const GQL_USER_INFO = gql`
  query getUserInfo{
    me {
      id
      firstname,
      lastname,
      client {
        id
        name
        type
        color2
        logoBucket
        logoKey
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const PAGES = [
  'home',
  'create',
  'media',
  'team',
  'resources',
];

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  root: {
    zIndex: 555,
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    height: 64,
  },
  logo: {
    width: 128,
    height: 64,
    fill: theme.palette.primary.contrastText,
    padding: 8,
    paddingLeft: theme.spacing(2),
  },
  clientLogo: {
    height: 48,
    objectFit: 'contain',
    margin: 8,
    marginRight: 0,
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    transition: '200ms width',
  },
  support: {
    margin: 8,
  },
  nav: {
    zIndex: 999999999,
    width: '100%',
    position: 'fixed',
    bottom: 0,
    boxShadow: theme.shadows[6],
  },
  tab: {
    height: theme.spacing(8),
    maxWidth: 160,
    minWidth: 0,
    width: '25%',
  },
  tab_group: {
    flex: 1,
  },
  grow: {
    flexGrow: 1,
  },
});

// -------------------------------------------------------------------------- //

const BetaNav_ = (props) => {
  const { classes } = props;
  return (
    <AppBar color="primary" className={classes.root}>
      <Query
        query={GQL_USER_INFO}
        fetchPolicy="network-only"
      >
        {({ data }) => (
          <ResponsiveGrid className={classes.flex}>
            <div role="banner" style={{display: 'flex'}}>
              {
                (data.me) &&
                <img
                  alt="Client Logo"
                  src={`https://${data.me.client.logoBucket}.s3.us-east-2.amazonaws.com/${data.me.client.logoKey}`}
                  className={classes.clientLogo}
                  key="clientLogo"
                  width={64}
                />
              }
              <Logo className={classes.logo} key="logo" style={{ transition: 'none'}}/>
            </div>
            <div className={classes.flex} style={{width: '100%'}} role="navigation">
            <Hidden xsDown key="tabs" id="navBar">
              <Tabs
                variant="fullWidth"
                id="navBar"
                value={PAGES.indexOf(props.page)}
                className={classes.tab_group}
              >
                <Tab
                  id="homeLink"
                  label="Home"
                  tabIndex="0"
                  aria-label="Home"
                  component={Link}
                  className={classes.tab}
                  to="/"
                />
                <Tab
                  id="createLink"
                  label="Feed"
                  tabIndex="0"
                  aria-label="Feed"
                  component={Link}
                  className={classes.tab}
                  to="/create"
                />
                <Tab
                  id="mediaLink"
                  label="Media"
                  tabIndex="0"
                  aria-label="Media"
                  component={Link}
                  className={classes.tab}
                  to="/media/photo"
                />
                <VirtualTourContext.Consumer>
                {(context) => (
                  context.tour_open ? (
                    <Tab
                      id="teamLink"
                      data-tour="step-1"
                      label="Team"
                      tabIndex="0"
                      aria-label="Team"
                      className={classes.tab}
                      onClick={() => {
                        context.set_step(2);
                      }}
                    />
                  ) : (
                    <Tab
                      id="teamLink"
                      data-tour="step-1"
                      label="Team"
                      tabIndex="0"
                      aria-label="Team"
                      className={classes.tab}
                      component={Link}
                      to="/team"
                    />
                  )
                )}
                </VirtualTourContext.Consumer>
                <Tab
                  id="resourcesLink"
                  label="Resources"
                  aria-label="Resources"
                  tabIndex="0"
                  component={Link}
                  className={classes.tab}
                  to="/resources"
                />
              </Tabs>
            </Hidden>
            <Hidden smUp key="navBlocker">
              <div className={classes.grow}/>
            </Hidden>
            <Tooltip title="Support / Feedback" aria-label="Support / Feedback" key="support">
              <IconButton
                id="supportButton"
                onClick={() => { window.open("https://clubessential.my.salesforce-sites.com/contactus", "_blank"); }}
                color="inherit"
                className={classes.support}
                aria-label="Support / Feedback"
              >
                <MessageTextIcon/>
              </IconButton>
            </Tooltip>
            <UserMenu showTour={props.showTour} key="user" user={data.me}/>

            <Hidden smUp key="mobileNav">
              <BottomNavigation
                value={PAGES.indexOf(props.page)}
                className={classes.nav}
              >
                <BottomNavigationAction
                  showLabel
                  component={Link}
                  to="/"
                  id="homeLink"
                  label="Home"
                  icon={<HomeIcon/>}
                />
                <BottomNavigationAction
                  showLabel
                  component={Link}
                  to="/create"
                  id="createLink"
                  label="Create"
                  icon={<PencilIcon/>}
                />
                <BottomNavigationAction
                  showLabel
                  component={Link}
                  to="/media/photo"
                  id="mediaLink"
                  label="Media"
                  icon={<FolderMultipleImageIcon/>}
                />
                <BottomNavigationAction
                  showLabel
                  component={Link}
                  to="/team"
                  data-tour="step-1"
                  id="teamLink"
                  label="Team"
                  icon={<SettingsIcon/>}
                />
              </BottomNavigation>
            </Hidden>
            </div>
          </ResponsiveGrid>
        )}
      </Query>
    </AppBar>
  );
};

// -------------------------------------------------------------------------- //

export const BetaNav = withStyles(STYLES)(BetaNav_);

// -------------------------------------------------------------------------- //
