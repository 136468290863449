
class ChromaKey {
  constructor(fabric) {
    this.class = fabric.util.createClass(fabric.Image.filters.BaseFilter, {
      type: 'ChromaKey',
      color: '#00ff00',
      smoothing: 0,
      threshold: 0,

      /**
       * Fragment source for the brightness program
       */
      fragmentSource: 'precision highp float;\n' +
        'varying vec2 vTexCoord;\n' +
        'uniform float thresholdSensitivity;\n' +
        'uniform float smoothing;\n' +
        'uniform vec3 colorToReplace;\n' +
        'uniform sampler2D uTexture;\n' +
        'void main() {\n' +
          'vec4 textureColor = texture2D(uTexture, vTexCoord);\n' +
          'float maskY = 0.2989 * colorToReplace.r + 0.5866 * colorToReplace.g + 0.1145 * colorToReplace.b;\n' +
          'float maskCr = 0.7132 * (colorToReplace.r - maskY);\n' +
          'float maskCb = 0.5647 * (colorToReplace.b - maskY);\n' +
          'float Y = 0.2989 * textureColor.r + 0.5866 * textureColor.g + 0.1145 * textureColor.b;\n' +
          'float Cr = 0.7132 * (textureColor.r - Y);\n' +
          'float Cb = 0.5647 * (textureColor.b - Y);\n' +
          'float blendValue = smoothstep(thresholdSensitivity, thresholdSensitivity + smoothing, distance(vec2(Cr, Cb), vec2(maskCr, maskCb)));\n' +
          'gl_FragColor = vec4(textureColor.rgb, textureColor.a * blendValue);\n' +
        '}',
      /*
      fragmentSource: 'precision highp float;\n' +
      'varying vec2 vTexCoord;\n' +
      'uniform float smoothing;\n' +
      'uniform sampler2D uTexture;\n' +
      'void main() {\n' +
        'vec4 textureColor = texture2D(uTexture, vTexCoord);\n' +
        'float mask = mix(1,textureColor.g,1);\n' +
        'gl_FragColor = vec4(textureColor.rgb, mask);\n' +
      '}',
      */

      /**
       * Constructor
       * @memberOf fabric.Image.filters.RemoveWhite.prototype
       * @param {Object} [options] Options object
       * @param {Number} [options.color=#RRGGBB] Threshold value
       * @param {Number} [options.distance=10] Distance value
       */

      /**
       * Applies filter to canvas element
       * @param {Object} canvasEl Canvas element to apply filter to
       */
      applyTo2d: (options) => {
        console.log('trying to 2d the greenscreen filter');
      },


      getUniformLocations(gl, program) {
        return {
          thresholdSensitivity: gl.getUniformLocation(program, 'thresholdSensitivity'),
          smoothing: gl.getUniformLocation(program, 'smoothing'),
          colorToReplace: gl.getUniformLocation(program, 'colorToReplace'),
        };
      },


      sendUniformData(gl, uniformLocations) {
        const { smoothing, threshold, color } = this;
        const colorInt = parseInt(color.slice(1), 16);
        const r = ((colorInt >> 16) & 0xFF) / 255.0;
        const g = ((colorInt >> 8) & 0xFF) / 255.0;
        const b = (colorInt & 0xFF) / 255.0;
        gl.uniform3fv(uniformLocations.colorToReplace, [r, g, b]);
        gl.uniform1f(uniformLocations.smoothing, smoothing);
        gl.uniform1f(uniformLocations.thresholdSensitivity, threshold);
      },

      /**
       * Returns object representation of an instance
       * @return {Object} Object representation of an instance
       */
      toObject() {
        return fabric.util.object.extend(this.callSuper('toObject'), {
          smoothing: this.smoothing,
          threshold: this.threshold,
          color: this.color,
        });
      },
    });
  }
}

export default ChromaKey;
