
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  DotsVertical,
  ChevronRight as ChevronRightIcon,
  Delete as DeleteIcon,
  Folder as FolderIcon, RenameBox as RenameBoxIcon,
} from 'mdi-material-ui';

import { graphql, compose, Mutation } from 'react-apollo';
import { withRouter } from 'react-router';
import gql from 'graphql-tag';

// -------------------------------------------------------------------------- //

const DELETE_CUSTOM_TEMPLATE = gql`
  mutation deleteCustomTemplate($id: ID!) {
    deleteCustomTemplate(
      id: $id
    ) {
      id
    }
  }
`;

// -------------------------------------------------------------------------- //

const MAKE_TEMPLATE_FOLDER = gql`
  mutation createTemplateFolder($name: String!, $folderId: ID){
    createTemplateFolder(name : $name, folderId : $folderId){
      id
      parent {
        id
        name
        children {
          id
          name
        }
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const DELETE_TEMPLATE_FOLDER = gql`
  mutation deleteTemplateFolder($id: ID!) {
    deleteTemplateFolder(
      id: $id
    ) {
      id
    }
  }
`;

// -------------------------------------------------------------------------- //

const MOVE_TEMPLATE = gql`
  mutation moveCustomTemplate($id: ID!, $folderId: ID) {
    moveCustomTemplate(
      id: $id
      folderId: $folderId
    ) {
      id
    }
  }
`;

const RENAME_CUSTOM_TEMPLATE = gql`
  mutation renameCustomTemplate($id: ID!, $name: String) {
    renameCustomTemplate(
      id: $id
      name: $name
    ) {
      id,name
    }
  }
`;

const RENAME_FOLDER = gql`
    mutation renameFolder(
      $folderId: ID!,
      $name: String!
    ) {
      renameCustomTemplateFolder(folderId:$folderId,name:$name){
        id,name
      }
    }
`;

// -------------------------------------------------------------------------- //

const ROLES_QUERY = gql`
  query {
    me {
      id
      role {
        id
        name
        restrictCategoryFlag
        categories {
          id
          name
        }
        allowCustomFlag
        allowCustomSaveFlag
        allowCustomFolderSaveFlag
        restrictCustomFlag
        customFolders {
          id
          name
        }
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  folderPaper: {
    height: 52,
    position: 'relative',
    cursor: 'pointer',
    userSelect: 'none',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  folder: {
    position: 'absolute',
    left: 8,
    top: 14,
    color: '#8f8f8f',
  },
  flex: {
    flex: 1,
  },
  folderLabel: {
    lineHeight: '52px',
    textIndent: 40,
    color: 'inherit',
  },
  templateLabel: {
    lineHeight: '52px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(5),
    color: 'inherit',
  },
  imageHolder: {
    paddingTop: 'calc( 100% - 52px )',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    cursor: 'pointer',
  },
  heading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    flexGrow: 1,
  },
  crumbs: {
    cursor: 'pointer',
    display: 'inline',
  },
  deleteButton: {
    position: 'absolute',
    right: 4,
    bottom: 0,
  },
  toolbar: {
    display: 'flex',
  },
  progress: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    left: '50%',
    top: '50%',
  },
});

// -------------------------------------------------------------------------- //

class CustomTemplateContents extends React.Component {
  state = {
    openDialog: false,
    folderId: null,
    addedFolderName: '',
    deleting_busy: false,
    deleting_type: 'template',
    deleting_id: null,
    menu: false,
    menuAnchor: null,
    menuDeleteFunction: null,
    editTemplateDialog: false,
    templateName: '',
    templateId: null,
    openRenameTemplateDialog: null,
    renameValidation: '',
    editFolderDialog: false,
    renameFolderValidation: '',
    folderName: '',
    editFolderID: null
  }

  /**
   *
   * @param {*} e anchor
   * @param {*} f deleteTemplateFunction
   * @param {*} t templateName
   */
  openMenu = (anchor, deleteFunction, name, id,type) => {
    if (type === 'folder') {
      this.setState({
        menu: true,
        menuAnchor: anchor,
        menuDeleteFunction: deleteFunction,
        folderName: name,
        editFolderID: id,
        openRenameTemplateDialog: () => this.setState({ editFolderDialog: true }),
      });
    } else {
      this.setState({
        menu: true,
        menuAnchor: anchor,
        menuDeleteFunction: deleteFunction,
        templateName: name,
        templateId: id,
        openRenameTemplateDialog: () => this.setState({ editTemplateDialog: true }),
      });
    }
  }

  closeMenu = (type) => {
    if (type === 'folder') {
      this.setState({ menu: false, editFolderDialog: false });
    } else {
      this.setState({ menu: false, editTemplateDialog: false });
    }
  }

  editTemplateDialog = (id) => (
      <Dialog
          open={this.state.editTemplateDialog}
          onClose={() => this.setState({ editTemplateDialog: false, renameValidation: '', menu: false })}
      >
        <DialogTitle>
          Rename
        </DialogTitle>
        <DialogContent>
          <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Template name"
              type="text"
              fullWidth
              error={this.state.renameValidation.length !== 0}
              helperText={this.state.renameValidation}
              value={this.state.templateName || ''}
              onChange={(e) => this.setState({ templateName: e.target.value })}
          />
          <Button onClick={() => this.setState({ editTemplateDialog: false, menu: false })} color="primary">
            Cancel
          </Button>
          <Button onClick={() => this.rename(this.state.templateId, this.state.templateName)} color="primary">
            Confirm
          </Button>
        </DialogContent>
      </Dialog>

  )

  folderDialog = () => (
      <Dialog
          open={this.state.editFolderDialog}
          onClose={() => this.setState({ editFolderDialog: false, renameFolderValidation: '', menu: false })}
      >
        <DialogTitle>
          Rename Folder
        </DialogTitle>
        <DialogContent>
          <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Folder name"
              type="text"
              fullWidth
              error={this.state.renameFolderValidation.length !== 0}
              helperText={this.state.renameFolderValidation}
              value={this.state.folderName || ''}
              onChange={(e) => this.setState({ folderName: e.target.value })}
          />
          <Button onClick={() => this.setState({ editFolderDialog: false, renameFolderValidation: '', menu: false })} color="primary">
            Cancel
          </Button>
          <Button onClick={() => this.renameFolder(this.state.editFolderID, this.state.folderName)} color="primary">
            Confirm
          </Button>
        </DialogContent>
      </Dialog>

  )

  addNewFolder = (folderId, templateFolder) => {
    if (this.state.addedFolderName !== '') {
      const folderInfo = {
        name: this.state.addedFolderName,
        folderId: folderId || null,
      };

      templateFolder({ variables: folderInfo })
          .then((res) => {
            this.setState({ openDialog: false, addedFolderName: '' });
          });
    }
  }

  getDataMe = () => {
    const { data } = this.props;
    return ((data && data.me) || null);
  }

  getFolders = () => {
    const { classes, eventStateChange, parent } = this.props;
    const me = this.getDataMe();
    let deleteCustomIcon = null;
    let allowCustomFlag = true;
    let allowCustomFolderSaveFlag = true;
    let restrictCustomFlag = false;
    const allowedCustomFolders = [];

    if (me && me.role && me.role.name) {
      allowCustomFlag = me.role.allowCustomFlag;
      allowCustomFolderSaveFlag = me.role.allowCustomFolderSaveFlag;
      restrictCustomFlag = me.role.restrictCustomFlag;
      if (me.role.customFolders) {
        me.role.customFolders
            .forEach((cat) => {
              allowedCustomFolders.push(cat.id);
            });
      }
    }

    const outputFolders = [];

    if (this.props.folders) {
      this.props.folders.forEach((folder) => {
        console.log(folder)
        if (allowCustomFlag && allowCustomFolderSaveFlag) {
          deleteCustomIcon = (
              <>
                <IconButton
                    className={classes.deleteButton}
                    tabIndex="0"
                    onClick={(e) => this.openMenu(e.currentTarget, this.openDeleteDialog('folder', folder.id),folder.name,folder.id,'folder')}
                >
                  <DotsVertical />
                </IconButton>
              </>
          );
        }

        if (parent) {
          outputFolders.push((
              <Grid
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  lg={3}
                  xl={3}
                  key={folder.id}
              >
                <Paper
                    draggable
                    onDragOver={this.dragOver}
                    onDrop={this.drop(folder.id)}
                    className={classes.folderPaper}
                >
                  <div
                      role="link"
                      onKeyDown={
                        this.handleKey(eventStateChange('folderId', folder.id))
                      }
                      tabIndex="0"
                      onClick={eventStateChange('folderId', folder.id)}
                  >
                    <FolderIcon className={classes.folder} />
                    <Typography
                        variant="body2"
                        noWrap
                        className={classes.folderLabel}
                    >
                      {folder.name}
                    </Typography>
                  </div>
                  {deleteCustomIcon}
                </Paper>
              </Grid>
          ));
        } else if (restrictCustomFlag) {
          if (allowedCustomFolders.includes(folder.id)) {
            outputFolders.push((
                <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={3}
                    key={folder.id}
                >
                  <Paper
                      draggable
                      onDragOver={this.dragOver}
                      onDrop={this.drop(folder.id)}
                      className={classes.folderPaper}
                  >
                    <div
                        role="link"
                        onKeyDown={
                          this.handleKey(eventStateChange('folderId', folder.id))
                        }
                        tabIndex="0"
                        onClick={eventStateChange('folderId', folder.id)}
                    >
                      <FolderIcon className={classes.folder} />
                      <Typography
                          variant="body1"
                          noWrap
                          className={classes.folderLabel}
                      >
                        {folder.name}
                      </Typography>
                    </div>
                    {deleteCustomIcon}
                  </Paper>
                </Grid>
            ));
          }
        } else {
          outputFolders.push((
              <Grid
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  lg={3}
                  xl={3}
                  key={folder.id}
              >
                <Paper
                    draggable
                    onDragOver={this.dragOver}
                    onDrop={this.drop(folder.id)}
                    className={classes.folderPaper}
                >
                  <div
                      role="link"
                      onKeyDown={
                        this.handleKey(eventStateChange('folderId', folder.id))
                      }
                      tabIndex="0"
                      onClick={eventStateChange('folderId', folder.id)}
                  >
                    <FolderIcon className={classes.folder} />
                    <Typography
                        variant="body1"
                        noWrap
                        className={classes.folderLabel}
                    >
                      {folder.name}
                    </Typography>
                  </div>
                  {deleteCustomIcon}
                </Paper>
              </Grid>
          ));
        }
      });
      //here
    }
    return outputFolders;
  }

  getTemplates = () => {
    const { classes } = this.props;
    const outputTemplates = [];
    const me = this.getDataMe();
    let deleteCustomIcon = null;
    let allowCustomFlag = true;
    let allowCustomSaveFlag = true;

    if (me && me.role && me.role.name) {
      allowCustomFlag = me.role.allowCustomFlag;
      allowCustomSaveFlag = me.role.allowCustomSaveFlag;
    }

    if (this.props.templates) {
      this.props.templates.forEach((template, index) => {
        if (allowCustomFlag && allowCustomSaveFlag) {
          deleteCustomIcon = (
              <>
                <IconButton
                    className={classes.deleteButton}
                    tabIndex="0"
                    onClick={(e) => this.openMenu(e.currentTarget, this.openDeleteDialog('template', template.id), template.name, template.id)}
                >
                  <DotsVertical />
                </IconButton>
              </>
          );
        }
        outputTemplates.push((
            <Grid
                item
                xs={12}
                sm={4}
                md={3}
                lg={3}
                xl={3}
                key={template.id}
            >
              <Paper
                  draggable
                  onDragStart={this.drag(template.id)}
                  onDragEnd={this.dragEnd}
                  onDrag={this.scrollIfDragging}
                  style={{
                    position: 'relative',
                  }}
              >
                <div
                    style={{ backgroundImage: `url('${template.template.image}')` }}
                    className={classes.imageHolder}
                    onClick={this.launchTemplate(template.id, template.template.url, template)}
                    draggable
                    tabIndex="0"
                    role="link"
                    onKeyDown={this.handleKey(this.launchTemplate(template.id, template.template.url))}
                />
                <Typography
                    variant="body1"
                    noWrap
                    className={classes.templateLabel}
                >
                  {template.name}
                </Typography>
                {deleteCustomIcon}
              </Paper>
            </Grid>
        ));
      });
    }
    return outputTemplates;
  }

  handleKey = (func) => (e) => {
    if (e.keyCode !== 9) {
      func(e);
    }
  }

  launchTemplate = (id, url, template) => () => {
    // with no data on the template object to indicate slideshow, we have to load
    // the json blob attached to the template and detect which type it is from that
    fetch(url)
        .then((response) => response.json())
        .then((json) => {
          const { history } = this.props;
          if (json && json.data && json.data.length > 0) {
            // eslint-disable-next-line no-unused-expressions
            template.template.isQuickCreate === true ? history.push(`/editor/quickcreate/custom/${id}`) :
                history.push(`/editor/slideshow/custom/${id}`);
          } else {
            history.push(`/editor/custom/${id}`);
          }
        });
  }

  dragOver = (e) => {
    e.preventDefault();
  }

  drag = (id) => (e) => {
    e.dataTransfer.setData('text/plain', JSON.stringify({
      id,
    }));

    this.setState({
      dragging: true,
    });
  }

  drop = (destination) => (e) => {
    const data = JSON.parse(e.dataTransfer.getData('text/plain'));
    this.props.moveTemplate({
      variables: {
        id: data.id,
        folderId: destination,
      },
      refetchQueries: [
        'getFolderContents',
        'getOrphanContent',
      ],
    });

    if (this.props.refresh) {
      this.props.refresh();
    }
  }

  rename = (id, name) => {
    if (name.length > 0) {
      this.props.renameTemplate({
        variables: {
          id,
          name,
        },
      });

      this.closeMenu();
    } else {
      this.setState({ renameValidation: 'Name cannot be empty' });
    }
  }

  renameFolder = (folderId, name) => {
    if (name.length > 0) {
      this.props.renameFolder({
        variables: {
          folderId,
          name,
        },
      });

      this.closeMenu('folder');
    } else {
      this.setState({ renameFolderValidation: 'Name cannot be empty' });
    }
  }

  dragEnd = (e) => {
    this.setState({
      dragging: false,
    });
  }

  scrollIfDragging = (e) => {
    if (this.state.dragging) {
      if (e.screenY < window.outerHeight / 3) {
        window.scrollBy(0, -20);
      } else if (e.screenY > window.outerHeight / (2 / 3)) {
        window.scrollBy(0, 20);
      }
    }
  }

  openDeleteDialog = (type, id) => () => {
    this.setState({
      deleting_busy: false,
      deleting_type: type,
      deleting_id: id,
      menu: false,
      menuAnchor: null,

    });
  }

  deleteContent = (mutation) => {
    this.setState({
      deleting_busy: true,
    }, () => {
      mutation().then(() => {
        this.setState({
          deleting_busy: false,
          deleting_id: null,
        });
      });
    });
  }

  closeDeleteDialog = () => {
    this.setState({
      deleting_busy: false,
      deleting_id: null,
    });
  }

  renderDeleteDialog() {
    const { classes } = this.props;
    const { deleting_busy, deleting_type, deleting_id } = this.state;
    let title; let text; let
        mutation;

    switch (deleting_type) {
      case 'template': {
        title = 'Delete custom template?';
        text = 'Deleting this template will remove it from your entire team. This action cannot be undone.';
        mutation = DELETE_CUSTOM_TEMPLATE;
        break;
      }
      case 'folder': {
        title = 'Delete template folder?';
        text = 'Deleting this folder will also delete all of its contents. This action cannot be undone.';
        mutation = DELETE_TEMPLATE_FOLDER;
        break;
      }
      default: {
        title = '(no title)';
        text = '(no text)';
        // this is a dummy value so <Mutation> below
        // doesn't complain about a null mutation prop
        mutation = DELETE_CUSTOM_TEMPLATE;
        break;
      }
    }

    return (
        <Dialog open={deleting_id !== null}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {text}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
                color="primary"
                disabled={deleting_busy}
                onClick={() => this.closeDeleteDialog()}
            >
              Cancel
            </Button>
            <Mutation
                awaitRefetchQueries
                mutation={mutation}
                variables={{
                  id: deleting_id,
                }}
                refetchQueries={[
                  'getFolderContents',
                  'getOrphanContent',
                ]}
            >
              {(mutation) => (
                  <Button
                      color="primary"
                      disabled={deleting_busy}
                      onClick={() => this.deleteContent(mutation)}
                  >
                    Delete
                    {deleting_busy && <div className={classes.progress}><CircularProgress size={32} /></div>}
                  </Button>
              )}
            </Mutation>
          </DialogActions>
        </Dialog>
    );
  }

  renderMenuDialog = () => (
      <Menu
          id="long-menu"
          anchorEl={this.state.menuAnchor}
          open={this.state.menu}
          onClose={this.closeMenu}
      >
        <MenuItem onClick={this.state.menuDeleteFunction}>
          <DeleteIcon />
          {' '}
          Remove
        </MenuItem>
        <MenuItem onClick={this.state.openRenameTemplateDialog}>
          <RenameBoxIcon />
          {' '}
          Rename
        </MenuItem>
      </Menu>
  )

  renderCreateFolderDialog() {
    const { classes, folderId } = this.props;
    const { openDialog, addedFolderName } = this.state;

    return (
        <Dialog
            open={openDialog}
            onClose={() => this.setState({ openDialog: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Add New Folder</DialogTitle>
          <DialogContent>
            <TextField
                id="name"
                label="Folder name"
                className={classes.textField}
                value={addedFolderName}
                onChange={(e) => this.setState({ addedFolderName: e.target.value })}
                margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ openDialog: false })} color="primary">
              Cancel
            </Button>
            <Mutation
                mutation={MAKE_TEMPLATE_FOLDER}
                refetchQueries={[
                  'getFolderContents',
                  'getOrphanContent',
                ]}
            >
              {(makeTemplateFolder) => (
                  <Button onClick={() => this.addNewFolder(folderId, makeTemplateFolder)} color="primary" autoFocus>
                    Save
                  </Button>
              )}
            </Mutation>

          </DialogActions>
        </Dialog>
    );
  }

  render() {
    const {
      classes, name, parent, eventStateChange,
    } = this.props;
    const me = this.getDataMe();
    let allowCustomFlag = true;
    let allowCustomFolderSaveFlag = true;

    if (me && me.role && me.role.name) {
      allowCustomFlag = me.role.allowCustomFlag;
      allowCustomFolderSaveFlag = me.role.allowCustomFolderSaveFlag;
    }

    if (allowCustomFlag) {
      return (
          <>
            {this.renderCreateFolderDialog()}
            {this.renderDeleteDialog()}
            {this.renderMenuDialog()}
            {this.editTemplateDialog()}
            {this.folderDialog()}
            <div
                id="main-content"
                style={{ marginTop: 16, marginBottom: 16 }}
                onDragOver={this.dragOver}
                onDrop={this.drop(parent ? parent.id : null)}
            >
              {parent && [
                <Typography
                    className={classes.crumbs}
                    variant="h4"
                    gutterBottom
                    onClick={eventStateChange('folderId', parent.id)}
                    key="name"
                >
                  {parent.name}
                </Typography>,
                <ChevronRightIcon key="divider" />,
              ]}
              <Typography
                  className={classes.crumbs}
                  variant="h4"
                  gutterBottom
              >
                {name || 'My Custom Templates'}
              </Typography>
              <Typography variant="body2">
                Custom templates let you customize one of our templates to exactly where you'd like to start in the
                future.
              </Typography>
              <Typography variant="body2" gutterBottom>
                Add logos, text, and any other customizations, and then hit File Save as Custom Template to add it
                here.
              </Typography>
              <Divider />
            </div>
            <Toolbar>
              <Typography variant="subtitle1" className={classes.flex}>
                Folders
              </Typography>
              {
                (allowCustomFlag && allowCustomFolderSaveFlag)
                && (
                    <Button onClick={() => this.setState({ openDialog: true })}>
                      Add Folder
                    </Button>
                )
              }
            </Toolbar>
            <Grid container spacing={2}>
              {this.getFolders()}
            </Grid>
            <Toolbar>
              <Typography variant="subtitle1">
                Templates
              </Typography>
            </Toolbar>
            <Grid container spacing={2}>
              {this.getTemplates()}
            </Grid>
          </>
      );
    }
    return (
        <>
          I'm sorry, you do not have access to this page.
        </>
    );
  }
}

// -------------------------------------------------------------------------- //

export default compose(
    graphql(MOVE_TEMPLATE, { name: 'moveTemplate' }),
    graphql(RENAME_CUSTOM_TEMPLATE, { name: 'renameTemplate' }),
    graphql(RENAME_FOLDER,{name: 'renameFolder'}),
    graphql(ROLES_QUERY),
    withRouter,
    withStyles(STYLES),
)(CustomTemplateContents);

// -------------------------------------------------------------------------- //
