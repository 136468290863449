
// -------------------------------------------------------------------------- //

import React from 'react';

import Helmet from 'react-helmet';

// -------------------------------------------------------------------------- //

const TITLE_SUFFIX = {
  development: 'Development',
  staging: 'Staging',
  production: 'Next',
  dev: 'Next',
};

// -------------------------------------------------------------------------- //

function render(props) {
  let title = '';

  if (Boolean(props.title)) {
    title = String(props.title);
  }

  return (
    <Helmet>
      <title>
        {title}
        {title && ' - '}
        ScoreShots {TITLE_SUFFIX[process.env.NODE_ENV]}
      </title>
    </Helmet>
  );
};

// -------------------------------------------------------------------------- //

export const PageTitle = render;

// -------------------------------------------------------------------------- //
