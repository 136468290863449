export default (fabric) => {
  const f = fabric.Image.filters;
  return {
    static: [
      {
        filter: f.Technicolor,
        name: 'Technicolor',
      },
      {
        filter: f.Brownie,
        name: 'Brownie',
      },
      {
        filter: f.Grayscale,
        name: 'Grayscale',
      },
      {
        filter: f.Vintage,
        name: 'Vintage',
      },
      {
        filter: f.Kodachrome,
        name: 'Kodachrome',
      },
      {
        filter: f.Polaroid,
        name: 'Polaroid',
      },
      {
        filter: f.Invert,
        name: 'Invert',
      },
      {
        filter: f.Sepia,
        name: 'Sepia',
      },
      {
        filter: f.BlackWhite,
        name: 'Black/White',
      },
    ],
    dynamic: [
      {
        name: 'Spotify',
        variables: {
          lightColor: {
            name: 'Light Color',
            type: 'color',
            default: 'secondary',
          },
          darkColor: {
            name: 'Dark Color',
            type: 'color',
            default: 'primary',
          },
        },
        filters: [
          {
            filter: f.Grayscale,
          },
          {
            filter: f.Contrast,
            options: {
              contrast: 0.2,
            },
          },
          {
            filter: f.BlendColor,
            options: {
              mode: 'multiply',
              color: {
                id: 'lightColor',
              },
            },
          },
          {
            filter: f.BlendColor,
            options: {
              mode: 'add',
              color: {
                id: 'darkColor',
              },
            },
          },
        ],
      },
      {
        name: 'Colorize',
        variables: {
          color: {
            name: 'Color',
            type: 'color',
            default: 'secondary',
          },
        },
        filters: [
          {
            filter: f.Grayscale,
          },
          {
            filter: f.BlendColor,
            options: {
              mode: 'multiply',
              color: {
                id: 'color',
              },
            },
          },
        ],
      },
      {
        name: 'Overlay',
        variables: {
          color: {
            name: 'Color',
            type: 'color',
            default: 'secondary',
          },
        },
        filters: [
          {
            filter: f.BlendColor,
            options: {
              mode: 'multiply',
              color: {
                id: 'color',
              },
            },
          },
        ],
      },
    ],
  };
};
