import ClippingBox from './types/ClippingBox';
import BackgroundBox from './types/BackgroundBox';
import LogoBox from './types/LogoBox';
import SSLogo from './types/SSLogo';
import BarGraph from './types/BarGraph';
import SliderBar from './types/SliderBar';
import CutoutBox from './types/CutoutBox';
import PolygonTexture from './types/PolygonTexture';
import CanvasTexture from './types/CanvasTexture';
import Effect from './types/Effect';
import Widget from './types/Widget';
import WidgetLayout from './types/WidgetLayout';
import ChromaKey from './filters/ChromaKey';
import ParText from './types/ParText';
import { WidgetBox } from '../widgets/widget-box';
import { WidgetPiece } from '../widgets/widget-piece';

export default {
  ClippingBox,
  BackgroundBox,
  LogoBox,
  SSLogo,
  CutoutBox,
  PolygonTexture,
  CanvasTexture,
  Effect,
  Widget,
  WidgetLayout,
  BarGraph,
  SliderBar,
  ParText,
  WidgetBox,
  WidgetPiece,
};

export const Filters = {
  ChromaKey,
};
