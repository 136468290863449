
// -------------------------------------------------------------------------- //

import { IWidgetFlow } from '../flow';
import { SpacingOption } from '../options/spacing';

// -------------------------------------------------------------------------- //

export class FillHorizontalFlow extends IWidgetFlow {

  _spacing = null
  _hspacing = null
  _vspacing = null
  _widgetWidth = 0
  _widgetHeigh = 0
  _count = 0
  _widgetMinCount = -1
  _widgetMaxCount = -1
  _widgetScaleX = 1
  _widgetScaleY = 1
  _map = [];

  constructor(name) {
    super(name);
    this._hspacing = new SpacingOption('hspacing', 'Horizontals Spacing');
    this._vspacing = new SpacingOption('vspacing', 'Vertical Spacing');
    this._spacing = new SpacingOption('spacing', 'Spacing');
  }

  get options() {
    return [ ...super.options, this._hspacing, this._vspacing];
  }

  get(key) {
    switch (key) {
        case 'spacing': {
          return this._spacing.value;
        }
        case 'vspacing': {
            return this._vspacing.value
        }
        case 'widgetScaleX': {
            return this._widgetScaleX
        }
        case 'widgetScaleY': {
            return this._widgetScaleX
        }
        case '_widgetWidth': {
            return this._widgetWidth
        }
        case 'widgetHeigh': {
            return this._widgetHeigh
        }
        case 'widgetMinCount': {
            return this._widgetMinCount
        }
        case 'widgetMaxCount': {
            return this._widgetMaxCount
        }
        case('count'): {
          return this._count 
        }
        default: {
          return super.get(key);
        }
      }
  }

  set(key, value) {
    switch (key) {
        case 'spacing': {
          return this._spacing.value = Number(value)
        }
        case 'vspacing': {
            return this._vspacing.value = Number(value)
        }
        case 'widgetScaleX': {
            return this._widgetScaleX = Number(value)
        }
        case 'widgetScaleY': {
            return this._widgetScaleX = Number(value)
        }
        case '_widgetWidth': {
            return this._widgetWidth = Number(value)
        }
        case 'widgetHeigh': {
            return this._widgetHeigh = Number(value)
        }
        case 'widgetMinCount': {
            return this._widgetMinCount = Number(value)
        }
        case 'widgetMaxCount': {
            return this._widgetMaxCount = Number(value)
        }
        case('count'): {
          return this._count = Number(value)
        }
        default: {
          return super.set(key,value);
        }
      }
  }

  reset(widgets, size, area) {
    this._hspacing.min = 0.0;
    this._hspacing.max = 0.0;
    this._hspacing.value = 0.0;
    this._vspacing.min = 0.0;
    this._vspacing.max = 0.0;
    this._vspacing.value = 0.0;
    this._map = [];

    if (widgets.length >= 1) {
      let vspacing_max = 0.0;
      let hspacing_max = 0.0;
      let max_rows = null, max_cols = null;
      let rows = 1;
  
      for (; rows <= widgets.length; ++rows) {
        const cols = Math.ceil(widgets.length / rows);
        const fit_x = (area.width >= size.x * cols);
        const fit_y = (area.height >= size.y * rows);
  
        if (!fit_x && !fit_y) {
          break;
        } else if (!fit_x || !fit_y) {
          continue;
        }

        if (max_rows === null || max_rows < rows) {
          max_rows = rows;
        }
  
        if (max_cols === null || max_cols < cols) {
          max_cols = cols;
        }
  
        let vspacing = 0;
        let hspacing = 0

        if (rows > 1) {
          vspacing = Math.floor((area.height - size.y * rows) / (rows - 1));
        }
  
        if (vspacing >= size.y) {
          // continue;
        }
        if(cols > 1){

          hspacing = Math.floor((area.width - size.x * cols) / (cols - 1));
        }
        this._map.push({ cols, rows, hspacing, vspacing });
        vspacing_max += vspacing;
      }
  
      if (this._map.length !== 0) {
        this._hspacing.max = hspacing_max;
        this._vspacing.max = vspacing_max;
    
        this._hspacing.value = Math.max(0.0, (
          (this._hspacing.min + this._hspacing.max) * 0.5
        ));

        if(this._hspacing.max === 0){
          //this._hspacing.max = 5
        }
    
        this._vspacing.value = Math.max(0.0, (
          (this._vspacing.min + this._vspacing.max) * 0.5
        ));
      }
    }

    if (this._map.length === 0) {
      const cols = widgets.length;
      this._map.push({ cols, rows: 1, hspacing: 0.0, vspacing: 0.0 });
    }
  }


  apply(widgets, size, area) {
        if (this._count < 0) {
            this._count = 0;
        }
    
        if (this._widgetMinCount > 0) {
            this._count = Math.max(this._count, this._widgetMinCount);
        }
    
        if (this._widgetMaxCount > 0) {
            this._count = Math.min(this._count, this._widgetMaxCount);
        }

        if (this._hspacing.value=== -10) { this._hspacing.value = this._spacing.value; }
        if (this._vspacing.value === -10) { this._vspacing.value = this._spacing.value; }

        let vspacing = this._vspacing.value;
        let hspacing = this._hspacing.value;
        let index = 0
        let update = false

        for (; index < this._map.length; ++index) {
            if (vspacing <= this._map[index].vspacing) {
            break;
            }
    
            vspacing -= this._map[index].vspacing;
        }

        if (index >= this._map.length) {
            index = (this._map.length - 1);
        }

        if (this._hspacing.max !== this._map[index].hspacing) {
            //this._hspacing.max = this._map[index].hspacing;
            update = true;
        }


        const rows = this._map[index].rows;
        
        const top = (area.center.y -
          (this._vspacing.value * (rows - 1) + size.y * rows) * 0.5
        );
        
        const verticalSpacing = this._vspacing * this._scaleY;

        let layoutWidth = area.width
        
        let x = area.left
        let y = 0

        
        
        
        for (let i = 0; i < widgets.length; ++i) {
          const widget = widgets[i];
          
          let elementWidth = Math.max(0, ( layoutWidth  / this._widgetScaleX) / widgets.length);
          let elementHeight = widget.height;
          
          elementWidth *= this._widgetScaleX

          if(this._hspacing.max === 0 || this._hspacing.max === 1){
            //this._hspacing.max = 5
            hspacing *= 10
          }
          elementWidth -= hspacing 
          

          elementHeight *= this._widgetScaleY;
            widget.move(
                (x + (hspacing * 0.5)),
                (y + top),
                elementWidth,
                elementHeight
            )
            widget.resize(elementWidth, elementHeight,false,widgets.length)


            x += elementWidth + hspacing
            if (x > layoutWidth) {
                x = 0;
                y += elementHeight + verticalSpacing;
            }

        }

        return update
    }

};

// -------------------------------------------------------------------------- //

