
// -------------------------------------------------------------------------- //

import * as React from 'react';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withStyles,
} from '@material-ui/core';

// -------------------------------------------------------------------------- //

const styles = (theme) => ({
  progress: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
});

// -------------------------------------------------------------------------- //

export default withStyles(styles)((props) => {
  const { classes, open, failure, onClose } = props;
  let title = '', text = '';

  if (failure) {
    title = 'Upload failed.';
    text = `
Seems a problem occured while uploading your files.
Be sure to check your internet connection for any issues.
If problems persist, reach out to support for help.
`;
  } else {
    title = 'Uploading your files...';
    text = `
We're uploading your files to our servers.
Hang tight; this should take only a few seconds.
`;
  }

  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {text}
        </DialogContentText>
        {(!failure) &&
        <div className={classes.progress}>
          <CircularProgress size={48}/>
        </div>}
      </DialogContent>
      {(failure) &&
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Return
        </Button>
      </DialogActions>}
    </Dialog>
  );
});

// -------------------------------------------------------------------------- //
