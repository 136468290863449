
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  FormControl,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  withStyles,
} from '@material-ui/core';

import {
  ShapeOutline as ShapeOutlineIcon,
  Texture as TextureIcon,
} from 'mdi-material-ui';

import parseColor from 'parse-color';

import { ElementsShapes } from './ElementsShapes';
import { ElementsTexture } from './ElementsTexture';
import { ElementsState } from './ElementsStates'
import { ShapeEditor } from './ShapeEditor';
import ColorPicker from '../../color/ColorPicker';
import { ColorButton } from '../../color/ColorButton';
import Crumbs from './Crumbs';
import { isMobileOnly } from 'react-device-detect';
import { UserRoleProvider, UserRoleConsumer } from '../../user/Permissions';
import MapSharpIcon from '@material-ui/icons/MapSharp';

// -------------------------------------------------------------------------- //

const ELEMENT_TYPES = [
  {
    key: 'shape',
    title: 'Shapes',
    icon: ShapeOutlineIcon,
    component: ElementsShapes,
  },
  {
    key: 'texture',
    title: 'Textures',
    icon: TextureIcon,
    component: ElementsTexture,
  },{
    key: 'states',
    title: ' 50 States',
    icon: MapSharpIcon,
    component: ElementsState,
  }
];

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  icon: {
    color: theme.palette.action.active,
  },
  palette: {
    // paddingTop: theme.spacing(1),
  },
  palette_caption: {
    display: 'block',
  },
  root: {
    padding: theme.spacing(2),
  },
});

// -------------------------------------------------------------------------- //

class Elements extends React.Component {

  state = {
    text_colors: {},
    other_colors: {},
    element_type: ELEMENT_TYPES[0].key,
    picker_open: false,
    anchor_position: null,
    active_color: null,
  }

  componentDidMount() {
    this.createPalette();
  }

  componentDidUpdate(old_props) {
    if (!this.props.active && old_props.active !== this.props.active) {
      this.createPalette();
    }
  }

  render() {
    const { active, client } = this.props;
    let preset_colors = [];

    if (client) {
      preset_colors = [
        ...preset_colors,
        client.color1,
        client.color2,
        client.color3,
      ];
    }

    if (active !== null) {
      return this.renderEditor(preset_colors);
    } else {
      return (
        <>
          {this.renderPalette(preset_colors)}
          <UserRoleProvider>
            <UserRoleConsumer>
            {(permissions) => (
              (!isMobileOnly) &&
              (permissions !== null) &&
              (!permissions.get('editor:lock_movement')) &&
              this.renderFilter()
            )}
            </UserRoleConsumer>
          </UserRoleProvider>
        </>
      );
    }
  }

  renderEditor = (preset_colors) => {
    const {
      active, activeGroup, canvas,
      fabric, slideshow, CanvasUtil,
      fonts,
    } = this.props;

    switch (active.type) {
      /*case 'image': {
        return (
          <>
            <Crumbs title="Editing Element" onBack={() => {
              canvas.discardActiveObject();
              canvas.requestRenderAll();
            }}/>
            <ImageEditor
              active={active}
              activeGroup={activeGroup}
              presetColors={preset_colors}
              canvas={canvas}
              fabric={fabric}
              slideshow={slideshow}
              CanvasUtil={CanvasUtil}
              fonts={fonts}
            />
          </>
        );
      }*/
      default: {
        return (
          <ShapeEditor
            active={active}
            activeGroup={activeGroup}
            presetColors={preset_colors}
            canvas={canvas}
            fabric={fabric}
            slideshow={slideshow}
            CanvasUtil={CanvasUtil}
            fonts={fonts}
          />
        );
      }
    }
  }

  createPalette = () => {
    const { objects } = this.props;
    let text_colors = {};
    let other_colors = {};

    objects.forEach((object) => {
      if (object.type === 'backgroundBox') {
        return;
      }

      if (
        object.getColors && (
          !object.excludeFromExport ||
          (object.widget && object.widget_index !== undefined)
        )
      ) {
        object.getColors().forEach((color) => {
          let colors_map;

          switch (object.type) {
            case 'i-text':
            case 'textbox': {
              colors_map = text_colors;
              break;
            }
            case 'QuickCreate': {
              if (color.property === 'fontColor') {
                colors_map = text_colors;
              } else {
                colors_map = other_colors;
              }

              break;
            }
            case 'SliderBar': {
              if (color.property === 'fontColor') {
                colors_map = text_colors;
              } else {
                colors_map = other_colors;
              }

              break;
            }
            default: {
              colors_map = other_colors;
              break;
            }
          }

          // avoid treating synonyms as unique colors (#f00 / rgb(255, 0, 0))
          const parsed_color = parseColor(color.color.toLowerCase());

          if (parsed_color.hex !== undefined) {
            const key = parsed_color.hex;

            if (!colors_map[key]) {
              colors_map[key] = [];
            }

            colors_map[key].push(color);
          }
        });
      }
    });

    this.setState({ other_colors });
  }

  renderPalette = () => {
    const { classes } = this.props;
    const { other_colors } = this.state;

    const other_color_table = (
      Object.keys(other_colors).map(key => other_colors[key])
    );

    const color_array = [...Object.keys(other_colors)];

    return (
      <>
        <Crumbs title="Color Palette"/>
        <div className={classes.root}>
        {
          (color_array.length > 0) &&
          <div className={classes.palette}>
          {color_array.map((color, index) => (
            <ColorButton
              key={index}
              color={color}
              onMouseOver={() => {
                this.mouseOverColor(other_color_table, index);
              }}
              onClick={(e) => {
                const x = 218.0; // (218.0 * ratio);
                const y = e.clientY; // (e.clientY * ratio);

                this.setState({
                  picker_open: true,
                  active_color: index,
                  anchor_position: { left: x, top: y },
                });
              }}
            />
          ))}
            <ColorPicker
              disableAlpha canvas={this.props.canvas}
              open={this.state.picker_open}
              anchorReference="anchorPosition"
              anchorPosition={this.state.anchor_position}
              color={color_array[this.state.active_color]}
              onClose={() => this.setState({ picker_open: false })}
              onChangeComplete={(color) => this.handleColorChange(
                other_color_table, color.hex, this.state.active_color
              )}
            />
          </div>
        }

        </div>
      </>
    );
  }

  renderFilter = () => {
    const { classes, canvas, fabric } = this.props;
    const { element_type } = this.state;
    const type_info = ELEMENT_TYPES.find(t => t.key === element_type);

    return (
      <>
      <Crumbs title="Add Elements"/>
        <div className={classes.root}>
          <FormControl fullWidth className={classes.filter_field}>
            <Select
              value={element_type}
              onChange={(e) => this.setState({ element_type: e.target.value })}
              renderValue={(value) => ELEMENT_TYPES.find(i => i.key === value).title}
              startAdornment={(
                <InputAdornment position="start">
                  <type_info.icon className={classes.icon}/>
                </InputAdornment>
              )}
              inputProps={{
                name: 'type',
                id: 'element-type',
              }}
            >
            {
              ELEMENT_TYPES.map((element, index) => (
                <MenuItem key={index} value={element.key}>
                  <ListItemIcon>
                    <element.icon className={classes.icon}/>
                  </ListItemIcon>
                  <ListItemText>{element.title}</ListItemText>
                </MenuItem>
              ))
            }
            </Select>
          </FormControl>
          <type_info.component
            filter={element_type}
            canvas={canvas}
            fabric={fabric}
          />
        </div>
      </>
    );
  }

    changeAllObjectColors = (objects, from, to, duration, callback = () => {}) => {
    const { fabric, canvas } = this.props;

    fabric.util.animateColor(from, to, duration, {
      onChange: (value) => {
        objects.forEach(object => {
          object.method(object.property, value);
        });

        canvas.requestRenderAll();
      },
      onComplete: () => {
        objects.forEach(object => {
          object.method(object.property, to);
        });

        if (callback) {
          callback();
        }
      },
    });
  }

  mouseOverColor = (info, index) => {
    const objects = info[index];

    if (objects.length > 0) {
      // assume valid output here because it was validated when it was added
      const from_color = objects[0].color.toLowerCase();
      const parsed_color = parseColor(from_color);
      let to_color = '#FFF';

      if (parsed_color.hsl[2] >= 75) {
        to_color = '#000';
      }

      this.changeAllObjectColors(objects, from_color, to_color, 200, () => {
        this.changeAllObjectColors(objects, to_color, from_color, 200);
      });
    }
  }

  handleColorChange = (info, color, index) => {
    this.changeAllObjectColors(info[index], info[index][0].color, color, 180, () => {
      this.createPalette();
      this.props.canvas.trigger('object:modified');
    });
  }

}

// -------------------------------------------------------------------------- //

export default withStyles(STYLES)(Elements);

// -------------------------------------------------------------------------- //
