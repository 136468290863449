
// -------------------------------------------------------------------------- //

import Sport from './Sport';

// -------------------------------------------------------------------------- //

const TEAMS = [
  'Home',
  'Visiting',
];

const POSITIONS = [
  { key: 'P', name: 'Pitcher' },
  { key: 'C', name: 'Catcher' },
  { key: '1B', name: 'First Baseman' },
  { key: '2B', name: 'Second Baseman' },
  { key: '3B', name: 'Third Baseman' },
  { key: 'SS', name: 'Shortstop' },
  { key: 'LF', name: 'Left Fielder' },
  { key: 'CF', name: 'Center Fielder' },
  { key: 'RF', name: 'Right Fielder' },
  { key: 'DP', name: 'Designated Player' },
  { key: 'PH', name: 'Pinch Hitter' },
];

// -------------------------------------------------------------------------- //

class BaseballSport extends Sport {

  constructor() {
    super();

    for (let t = 0; t < 2; ++t) {
      const team = `[${TEAMS[t]}]`;
      const team_id = `team.${TEAMS[t].toLowerCase()}`;
      this.push(`${team_id}.id`, `${team} Team ID`, { type: 'string' });
      this.push(`${team_id}.name`, `${team} Team Name`, { type: 'string' });
      this.push(`${team_id}.atbats`, `${team} Total At Bats`);
      this.push(`${team_id}.runs`, `${team} Total Runs`);
      this.push(`${team_id}.hits`, `${team} Total Hits`);
      this.push(`${team_id}.rbis`, `${team} Total RBIs`);
      this.push(`${team_id}.strikeouts`, `${team} Total Strikeouts`);
      this.push(`${team_id}.lob`, `${team} Total LOB`);
      this.push(`${team_id}.errors`, `${team} Total Errors`);
      this.push(`${team_id}.ba`, `${team} Total Batting Average`);
      this.push(`${team_id}.obp`, `${team} Total On Base Percentage`);
      this.push(`${team_id}.ip`, `${team} Total Innings Pitched`);
      this.push(`${team_id}.walks`, `${team} Total Walks`);

      for (let i = 0; i < 11; ++i) {
        const inning = `inning${i + 1}`;
        this.push(`${team_id}.score.${inning}`, `${team} Score`);
      }
    }
    
    const player = `[Player]`;
    this.push('player.atbats', `${player} At Bats`);
    this.push('player.runs', `${player} Runs`);
    this.push('player.runs.earned', `${player} Runs (earned)`);
    this.push('player.runs.unearned', `${player} Runs (unearned)`);
    this.push('player.ip', `${player} Innings Pitched`);
    this.push('player.era', `${player} ERA`);
    this.push('player.pitches', `${player} Pitches`);
    this.push('player.walks', `${player} Walks`);
    this.push('player.hits', `${player} Hits`);
    this.push('player.rbis', `${player} RBIs`);
    this.push('player.lobs', `${player} LOB`);
    this.push('player.strikes', `${player} Strikes`);
    this.push('player.strikeouts', `${player} Strikeouts`);
    this.push('player.errors', `${player} Errors`);
    this.push('player.ba', `${player} Batting Average`);
    this.push('player.obp', `${player} On Base Percentage`);
    this.push('player.tbf', `${player} Batters Faced`);
    this.push('player.whip', `${player} WHIP`);
    this.push('player.k9', `${player} K/9`);
  }

  getPlayerPositions() {
    return POSITIONS;
  }

};

// -------------------------------------------------------------------------- //

export default BaseballSport;

// -------------------------------------------------------------------------- //
