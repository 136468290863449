// -------------------------------------------------------------------------- //

import React from 'react';

import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Snackbar, SvgIcon,
  Switch,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  Account,
  CameraEnhance,
  ChartBar,
  Close,
  FileImage,
  FormatColorText,
  Group,
  Image,
  ImageFilterFrames,
  Minus,
  Numeric5BoxOutline,
  Numeric8BoxOutline,
  Plus,
  Shape,
  Textbox,
  Ungroup,
  VectorPolygon,
  Widgets,
} from 'mdi-material-ui';

import 'whatwg-fetch';
import { graphql, compose, Query } from 'react-apollo';
import classNames from 'classnames';
import gql from 'graphql-tag';

import { DopeSheet } from '../animations_views/index';
import { SHAPES } from '../editor/dict/shapes';
import * as Scoreshots from '../../scoreshotsapi/v1';
import CanvasUtil from '../../canvas/CanvasUtil';
import EditorSidebar from './EditorSidebar';
import FullscreenLoader from '../loading/FullscreenLoader';
import GraphicSelect from '../editor/sidebar/GraphicSelect';
import ScoreshotsPut from '../../scoreshotsapi/Put';
import SlidePreviewDialog from '../share/SlidePreviewDialog';
import TemplateGridSlides from './TemplateGridSlides';
import TemplateSwapper from './TemplateSwapper';

// -------------------------------------------------------------------------- //

const templateBucket = 'ss3-templates';
const templateRegion = 'us-east-2';
const CurvedTextIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 450 450">
      <path className="st0" d="M380.3,310.4" />
      <text transform="matrix(0.8753 0 0 1 144.1777 417.7722)" style={{ fontFamily: 'Georgia-Bold', fontSize: '312.2523px' }}>T</text>
      <path
          className="st0"
          d="M405.8,298.6l24.7,8.2l0.2-0.7l-0.1,0.1c21.4-99.6-38-198.7-135.9-226.9C191.9,49.8,84.5,109.2,54.9,212
  c-2.7,9.5-4.8,19.3-6.1,29.1l27.5,3.6c1.1-8.5,2.8-16.8,5.2-25c23.7-81,104.3-131.4,187.5-117.1
  c90.5,15.5,151.3,101.3,135.8,191.8l-0.1-0.2"
      />
      <path className="st0" d="M58,329.4c3.3,9.4,7.3,18.5,11.9,27.3l24.4-13c-4-7.5-7.4-15.4-10.2-23.4L58,329.4z" />
      <path
          className="st0"
          d="M75,270.3L47.3,271c0.3,9.9,1.3,19.9,3.1,29.6l27.2-4.9C76.1,287.3,75.2,278.8,75,270.3z"
      />
      <polygon className="st0" points="361.9,280.4 399.4,356.7 475.7,319.2 					" />
    </SvgIcon>
);

// https://s3.us-east-2.amazonaws.com/ss3-templates/cjt8slvbo78xx09850mqhgtg3.json

const GET_TEMPLATE_S3 = (templateId) => {
  const cache_buster = `${+new Date()}`; return fetch(`https://s3.us-east-2.amazonaws.com/ss3-templates/${templateId}.json?${cache_buster}`)
      .then((res) => res.json());
};

const GET_SLIDESHOW_S3 = (templateId) => (setJsonFunct) => {
  const cache_buster = `${+new Date()}`; return fetch(`https://s3.us-east-2.amazonaws.com/ss3-slides/${templateId}.json?${cache_buster}`)
      .then((res) => res.json())
      .then((data) => setJsonFunct(data))
      .catch((err) => { 'Yeah there was an error.'; });
};

// -------------------------------------------------------------------------- //

const GET_TEMPLATE = gql`
  query ($id: ID!) {
    template(id: $id) {
      id
      width
      height
      url
      isSlide
      isQuickCreate
      isSlideShow
      categories {
        name
      }
      clients {
        id
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const SAVE_TEMPLATE = gql`
  mutation updateTemplate($id: ID!, $url: String) {
    updateTemplate(
      id: $id
      url: $url
    ) {
      id
    }
  }
`;

const GET_FONTS = gql`
  query clientFonts($clients: [ID!]!) {
    clientFonts(
      clients: $clients
    ) {
      id
      fullName
      fontFamily
      type
      bucket
      key
    }
  }
`;

export function createRenderJob(canvas, slides, transitions, fonts) {
  const { workingArea } = canvas;
  const texts = canvas.getObjects('i-text');
  const used_fonts = [...new Set(texts.map((text) => text.fontFamily))];
  const textboxes = canvas.getObjects('textbox');
  if (textboxes && textboxes.length > 0) {
    for (let j = 0; j < textboxes.length; ++j) {
      const { fontFamily } = textboxes[j];
      if (used_fonts.indexOf(fontFamily) < 0) {
        used_fonts.push(fontFamily);
      }
    }
  }

  // force integers to be integers
  return {
    width: +workingArea.width,
    height: +workingArea.height,
    duration: (
        slides.map((i) => +i.duration || 5).reduce((x, y) => (x + y), 0)
        + transitions.map((i) => +i.duration || 1).reduce((x, y) => (x + y), 0)
    ),
    transitions: transitions.map((transition) => ({
      type: transition.type,
      duration: +transition.duration,
    })),
    scenes: slides.map((slide) => ({
      data: slide.canvas,
      duration: +slide.duration,
    })),
    fonts: fonts.filter((i) => used_fonts.includes(i.fontFamily)).map((font) => ({
      name: font.fontFamily,
      type: font.type.toLowerCase(),
      id: font.id,
      key: font.key,
      bucket: font.bucket,
    })),
  };
}

function fitObject(container, image_width, image_height) {
  if (container.height === 0 || image_height === 0) {
    return {
      left: container.left,
      top: container.top,
      width: container.width,
      height: container.height,
      scaleX: container.scaleX,
      scaleY: container.scaleY,
    };
  }

  const min_scale = Math.min(container.scaleX, container.scaleY);
  const container_aspect = (container.width / container.height);
  const image_aspect = (image_width / image_height);

  if (container.type === 'backgroundBox' ? (container_aspect < image_aspect) : (container_aspect > image_aspect)) {
    image_width = (image_width * container.height / image_height);
    image_height = container.height;
  } else {
    image_height = (image_height * container.width / image_width);
    image_width = container.width;
  }

  return {
    left: (container.left + container.width * container.scaleX / 2),
    top: (container.top + container.height * container.scaleY / 2),
    width: image_width,
    height: image_height,
    scaleX: min_scale,
    scaleY: min_scale,
  };
}

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  root: {
    height: '100%',
    flexGrow: 1,
  },
  editor: {
    width: '100vw',
    height: 'calc( 100vh - 64px)',
    backgroundColor: '#f5f5f5',
  },
  canvas: {
    width: '95%',
    height: '95%',
    overflow: 'hidden',
    marginTop: 64,
    marginLeft: 32,
  },
  bar: {
    position: 'absolute',
    left: 0,
    top: 0,
  },
  fabBar: {
    position: 'absolute',
    left: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  fabBarContents: {
    height: theme.spacing(96),
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fab: {
    margin: theme.spacing(1),
  },
  flex: {
    flex: 1,
  },
  grid_container: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  grid_item: {
    backgroundColor: 'inherit',
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
    transition: theme.transitions.create(['background-color', 'color']),
  },
  button: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    transition: theme.transitions.create('box-shadow'),
    '&:focus': {
      boxShadow: `0 0 0 4px ${theme.palette.primary.light}`,
    },
    '&:hover': {
      boxShadow: theme.shadows[3],
    },
  },
  button_icon: {
    backgroundColor: 'inherit',
    color: theme.palette.primary.main,
    width: '90%',
    height: 'auto',
    margin: '5%',
  },
});

// -------------------------------------------------------------------------- //

class CanvasEditor extends React.Component {
  state = {
    addOpen: false,
    selectGraphicOpen: false,
    canvasActive: null,
    canvasActiveGroup: null,
    canvasObjects: [],
    oldId: '',
    dopeSheetOpen: false,
    addSlideDialog: false,
    slides: [],
    transitions: [],
    rendering: false,
    progress: 0,
    url: null,
    snackbar: '',
    svg_open: false,
  }

  CanvasUtil = {}

  uploadImage = {}

  componentDidMount() {
    let { id } = this.props.template;
    if (this.props.isSlideShow || this.props.isQuickCreate) {
      id = this.props.s3slideshowid;
    }
    GET_SLIDESHOW_S3(id)((j) => this.setState({ slides: j.slides, transitions: j.transitions }));
    this.initCanvas();
  }

  addGraphic = () => {
    this.setState({
      selectGraphicOpen: true,
    });
  }

  performAddGraphic = (id, image, name) => {
    const { canvas, fabric } = this.CanvasUtil;

    const graphic = new window.Image();
    graphic.onload = () => {
      canvas.add(new fabric.Image(graphic, {
        crossOrigin: 'anonymous',
        name,
        x: 100,
        y: 100,
        width: graphic.width,
        height: graphic.height,
        selectable: true,
      }));
      canvas.requestRenderAll();
    };
    graphic.crossOrigin = 'Anonymous';
    graphic.src = `${image}?1`;

    this.setState({
      selectGraphicOpen: false,
    });
  }

  curvedText = () => {
    const { canvas, fabric } = this.CanvasUtil;

    const curvedText = new fabric.CurvedText('Your text is here');

    canvas.add(curvedText);

    canvas.setActiveObject(curvedText);

    canvas.requestRenderAll();
  }

  getControls() {
    const { classes } = this.props;
    const controls = [
      {
        title: 'Add Shape',
        key: 'addSVG',
        icon: <Shape />,
      }, {
        title: 'Add Polygon',
        key: 'addPoly',
        icon: <VectorPolygon />,
      }, {
        title: 'Add Polyline',
        key: 'addPolyLine',
        icon: <VectorPolygon />,
      }, {
        title: 'Add Text',
        key: 'addText',
        icon: <FormatColorText />,
      }, {
        title: 'Add Textarea',
        key: 'addTextarea',
        icon: <Textbox />,
      }, {
        title: 'Add Background',
        key: 'addBackground',
        icon: <Image />,
      }, {
        title: 'Add Graphic',
        key: 'addGraphic',
        icon: <FileImage />,
      }, {
        title: 'Add Logo',
        key: 'addLogo',
        icon: <ImageFilterFrames />,
      }, {
        title: 'Add Cutout',
        key: 'addCutout',
        icon: <Account />,
      }, {
        title: 'Add Widget',
        key: 'addWidget',
        icon: <Widgets />,
      }, {
        title: 'Add Bar Graph',
        key: 'addBarGraph',
        icon: <ChartBar />,
      }, {
        title: 'Add Slider Bar',
        key: 'addSliderBar',
        icon: <Numeric8BoxOutline />,
      }, {
        title: 'Add Par Text',
        key: 'addParText',
        icon: <Numeric5BoxOutline />,
      }, {
        title: 'Group Objects',
        key: 'groupObjects',
        icon: <Group />,
      }, {
        title: 'Ungroup Objects',
        key: 'ungroupObjects',
        icon: <Ungroup />,
      },
    ];
    const output = [];
    controls.forEach((control) => {
      output.push((
          <Tooltip id={`tooltip-${control.key}`} title={control.title} placement="right" key={control.key}>
            <Button variant="fab" color="default" mini aria-label="add" className={classes.fab} onClick={this.handleAddButton(this, control.key)}>
              {control.icon}
            </Button>
          </Tooltip>
      ));
    });

    output.push((
        <Tooltip id="tooltip-9999" title="Add Preview Image" placement="right" key={9999}>
          <Button variant="fab" color="default" mini aria-label="add" className={classes.fab} onClick={() => { this.uploadImage.click(); }}>
            <CameraEnhance />
          </Button>
        </Tooltip>
    ));

    output.push((
        <Tooltip id="tooltip-10000" title="Add Curved Text" placement="right" key={10000}>
          <Button variant="fab" color="default" mini aria-label="add" className={classes.fab} onClick={() => { this.curvedText(); }}>
            <CurvedTextIcon />
          </Button>
        </Tooltip>
    ));

    return output;
  }

  initCanvas() {
    this.CanvasUtil = new CanvasUtil();
    this.CanvasUtil.updateActive = this.updateActive(this);
    this.CanvasUtil.updateObjects = this.updateObjects(this);
    this.CanvasUtil.onLoad = () => {
      this.CanvasUtil.loadFromTemplate(this.props.template, false, () => {
        this.CanvasUtil.setStep('admin');
      });
    };
    const options = {
      id: 'c',
      initStep: 'admin',
      admin_editor: true,
    };
    this.CanvasUtil.init(options);
  }

  handleAddButton = (self, func) => () => {
    const defaultFunc = self.CanvasUtil.adders[func];
    if (defaultFunc) {
      defaultFunc();
    } else {
      const customFunc = self[func];
      if (customFunc) {
        customFunc();
      }
    }
  }

  addSVG = () => {
    this.setState({ svg_open: true });
  }

  renderSVGDialog = () => {
    const { classes } = this.props;
    const { svg_open } = this.state;
    const { canvas, fabric } = this.CanvasUtil;

    return (
        <Dialog
            open={svg_open}
            fullWidth
            maxWidth="sm"
            onClose={() => this.setState({ svg_open: false })}
        >
          <DialogTitle>Add Shape</DialogTitle>
          <DialogContent>
            <Grid
                container
                columns={3}
                spacing={1}
                className={classes.grid_container}
            >
              {Object.keys(SHAPES).map((key) => {
                const Icon = SHAPES[key].icon;

                return (
                    <Grid
                        item
                        xs={4}
                        key={key}
                        className={classNames(classes.grid_item)}
                    >
                      <IconButton
                          aria-label={SHAPES[key].name}
                          key={SHAPES[key].name}
                          className={classes.button}
                          onClick={() => {
                            const object = SHAPES[key].adder({
                              canvas, fabric, colors: { primary: '#6B2587' },
                            });

                            if (object) {
                              canvas.setActiveObject(object);
                            }

                            this.setState({ svg_open: false });
                          }}
                      >
                        <Icon className={classes.button_icon} />
                      </IconButton>
                      <Typography
                          variant="caption"
                          color="inherit"
                          className={classes.caption}
                      >
                        {SHAPES[key].name}
                      </Typography>
                    </Grid>
                );
              })}
            </Grid>
          </DialogContent>
        </Dialog>
    );
  }

  updateActive = (self) => (e) => {
    let target = null;

    if (e.target && e.target.canvas) {
      target = e.target.canvas.getActiveObjects();
    }

    if (target && target.length > 0) {
      let targetIndex = 0;

      if (this.state.active) {
        targetIndex = Math.max(target.findIndex((el) => el === this.state.active), 0);
      }

      self.setState({
        canvasActive: target[targetIndex],
        canvasActiveGroup: target,
      });
    } else {
      self.setState({
        canvasActive: null,
        canvasActiveGroup: null,
      });
    }
  }

  updateObjects = (self) => () => {
    self.setState({
      canvasObjects: self.CanvasUtil.canvas.getObjects(),
    });
  }

  swapTemplate = (templateId) => () => {
    const variables = { id: templateId };
    this.props.refetchTemplate(variables).then((j) => {
      this.CanvasUtil.swapTemplate(j.data.template);
      const chosenSlide = j.data.template.id;
      const slideShowId = this.props.s3slideshowid;
      const _route = `/admin/templates/editor/${chosenSlide}/${slideShowId}`;
      this.props.history.push(_route);
    });
  }

  toggleBar = (self) => () => {
    self.setState({
      addOpen: !self.state.addOpen,
    });
  }

  toggleDopeSheet = (self) => () => {
    self.setState({
      dopeSheetOpen: !self.state.dopeSheetOpen,
    });
  }

  toggleAddSlideDialog = () => {
    this.setState({
      addSlideDialog: !this.state.addSlideDialog,
    });
  }

  setTransitions = (index) => (transitionObj) => () => {
    const myTransitions = this.state.transitions;
    myTransitions[index] = transitionObj;
    this.setState({ transitions: myTransitions });
  }

  swapTransitionIndex = (oldIndex, newIndex) => {
    const myTransitions = this.state.transitions;
    if (myTransitions.length === 0) { return; }
    const _old = myTransitions[oldIndex];
    const _new = myTransitions[newIndex];
    myTransitions[newIndex] = _old;
    myTransitions[oldIndex] = _new;
    this.setState({ transitions: myTransitions });
  }

  saveSelectedSlides = (slides) => {
    const key = `${this.props.s3slideshowid}.json`;

    const ids = slides;
    if (ids.length === 0) {
      alert('No slides add. Saving cancelled.');
      return;
    }
    const data = [];

    if (this.props.template.isSlideShow || this.props.isSlideShow || this.props.isQuickCreate) {
      const allSlides = [];
      for (let i = 0; i < ids.length; i++) {
        allSlides.push(GET_TEMPLATE_S3(ids[i]));
      }

      Promise.all(allSlides).then((j) => {
        for (let x = 0; x < j.length; x++) {
          data.push({ canvas: j[x], duration: j[x].duration });
        }
        const transArray = this.state.transitions;
        if (transArray.length === 0) {
          for (let x = 0; x < j.length; x++) {
            transArray.push({ type: 'None', duration: 0 });
          }
        }
        // send to s3 bucket.
        const file = new Blob([JSON.stringify({ slides, data, transitions: transArray })],
            { type: 'application/json' });
        const action = new ScoreshotsPut('ss3-slides', key, '');
        action.put(file, 'application/json', () => {});
      });
    }
  }

  cloneTemplate = async () => new Promise((resolve, _) => {
    const json = this.CanvasUtil.toJSON();

    json.objects.forEach((object) => {
      switch (object.type) {
        case 'backgroundBox': {
          object.media = {
            type: 'image',
            src: 'https://s3.us-east-2.amazonaws.com/ss3-assets/testing/ball1.jpg',
            alphaMaskBlendMode: 'multiply',
            alphaMaskScaleX: 1,
            alphaMaskScaleY: 1,
            filters: [],
            attributes: {
              ...fitObject(object, 2000, 1300),
            },
          };

          break;
        }
        case 'cutoutBox': {
          object.media = {
            type: 'image',
            originX: 'left',
            originY: 'top',
            src: 'https://s3.us-east-2.amazonaws.com/ss3-assets/testing/ball2.png',
            alphaMaskBlendMode: 'multiply',
            alphaMaskScaleX: 1,
            alphaMaskScaleY: 1,
            filters: [],
            attributes: {
              ...fitObject(object, 4982, 6650),
            },
          };

          break;
        }
        case 'logoBox': {
          object.media = {
            type: 'image',
            originX: 'left',
            originY: 'top',
            src: 'https://s3.us-east-2.amazonaws.com/ss3-assets/testing/logo.png',
            alphaMaskBlendMode: 'multiply',
            alphaMaskScaleX: 1,
            alphaMaskScaleY: 1,
            filters: [],
            attributes: {
              ...fitObject(object, 295, 268),
            },
          };

          break;
        }
        default: {
          break;
        }
      }
    });

    resolve(json);
  })

  previewTemplate = (fonts) => {
    const { canvas } = this.CanvasUtil;
    this.cloneTemplate()
        .then((clone) => {
          const slide = { canvas: clone, duration: canvas.duration };
          const job = createRenderJob(canvas, [slide], [], fonts);
          return Scoreshots.SubmitRender(job);
        }).then((url) => {
      this.setState({
        url,
        progress: 0,
        rendering: true,
      });

      return url;
    }).then((url) => {
      const options = {
        period: 5000,
        progress: (progress) => {
          this.setState({ progress });
        },
      };

      return Scoreshots.PollRender(url, options);
    })
        .then(() => {
          this.setState({ rendering: false });
        })
        .catch(() => {
          this.setState({
            url: null,
            progess: 0,
            rendering: false,
            snackbar: 'Failed to create preview',
          });
        });
  }

  saveTemplate = (self) => () => {
    const key = `${self.props.template.id}.json`;
    const bucket = templateBucket;
    const url = `https://s3.${templateRegion}.amazonaws.com/${bucket}/${key}`;
    console.log(this.CanvasUtil.toJSON(true))
    const file = new Blob(
        [JSON.stringify(this.CanvasUtil.toJSON(true))],
        { type: 'application/json' },
    );
    const action = new ScoreshotsPut(bucket, key, '');
    action.put(file, 'application/json', () => {
      if (self.props.template.url !== url) {
        self.props.saveTemplate({
          variables: {
            id: self.props.template.id,
            url,
          },
        });
      }
    });
  }

  uploadPreviewImage = (self) => (e) => {
    const url = URL.createObjectURL(e.target.files[0]);

    const { canvas } = this.CanvasUtil;
    const { fabric } = this.CanvasUtil;

    fabric.Image.fromURL(url, (image) => {
      image.set({
        originX: 'left',
        originY: 'top',
        left: 0,
        top: 0,
        scaleX: self.props.template.width / image.width,
        scaleY: self.props.template.height / image.height,
        excludeFromExport: true,
        minimumScaleTrigger: 1,
      });
      canvas.add(image);
      canvas.sendToBack(image);
      canvas.renderAll();
    }, { crossOrigin: 'anonymous' });

    this.forceUpdate();

    e.target.value = null;
  }

  handleChange = (prop) => (e) => {
    const state = {};
    state[prop] = e.target.value;
    this.setState(state);
  }

  loadOldTemplate = () => {
    this.CanvasUtil.loadOldTemplate(this.state.oldId);
  }

  setSlides = (slides) => this.setState({ slides });

  setDefaultView = () => {
    const { canvas } = this.CanvasUtil;
    this.CanvasUtil.canvas.defaultview = canvas;
  }

  render() {
    const { classes, template } = this.props;
    const { dopeSheetOpen } = this.state;
    const clients = template.clients.map((client) => client.id);

    const { location } = this.props;
    const divStatus = document.getElementById('intercom-container');
    if (location.pathname.match(/admin/)) {
      if (divStatus) {
        divStatus.style.display = 'none';
      }
    }

    let addControls = null;
    if (this.state.addOpen) {
      addControls = this.getControls();
    }

    let disableDopesheetBtn = true;
    if (template.isSlide || template.isSlideShow) {
      disableDopesheetBtn = !disableDopesheetBtn;
    }

    const msg = dopeSheetOpen ? 'Close Dopesheet' : 'Open Dopesheet';

    return (
        <Query query={GET_FONTS} variables={{ clients }}>
          {({ data }) => (
              <div className={classes.editor}>
                <AppBar position="static" className={classes.bar}>
                  <Toolbar>
                <span className={classes.flex}>
                  <FormControlLabel
                      control={(
                          <Switch
                              checked={this.state.checkedB}
                              onChange={() => (null)}
                              value="checkedB"
                              color="secondary"
                          />
                      )}
                      label="Preview"
                  />
                  <Button style={{ marginLeft: 16 }} variant="contained" color="secondary" onClick={this.CanvasUtil.undo}>
                    Undo (Ctrl/⌘ + Z)
                  </Button>
                  <Button style={{ marginLeft: 16 }} variant="contained" color="secondary" onClick={this.CanvasUtil.redo}>
                    Redo (Ctrl/⌘ + Y)
                  </Button>
                  <Button disabled={disableDopesheetBtn} style={{ marginLeft: 16 }} variant="contained" color="secondary" onClick={this.toggleDopeSheet(this)}>
                    {msg}
                  </Button>
                  <Button disabled={(template.isSlideShow && this.props.isSlideShow) || this.props.isQuickCreate ? false : true} style={{ marginLeft: 16 }} variant="contained" color="secondary" onClick={this.toggleAddSlideDialog}>
                    Add A Slide
                  </Button>
                  <Button
                      variant="contained"
                      color="secondary"
                      style={{ marginLeft: 16 }}
                      onClick={this.setDefaultView}
                  >
                    Set Default View
                  </Button>
                </span>
                    <TextField
                        id="oldId"
                        label="Old ID"
                        className={classes.textField}
                        value={this.state.oldId}
                        onChange={this.handleChange('oldId')}
                        margin="dense"
                    />
                    <Button onClick={this.loadOldTemplate}>
                      Import
                    </Button>
                    <Button onClick={() => this.previewTemplate(data.clientFonts ? data.clientFonts : [])}>
                      Preview
                    </Button>
                    <Button variant="raised" color="secondary" onClick={this.saveTemplate(this)}>
                      Save
                    </Button>
                  </Toolbar>
                </AppBar>
                <div className={classes.canvas}>
                  { template.isSlideShow || this.props.isSlideShow || this.props.isQuickCreate ? (
                      <TemplateSwapper
                          swapTemplateFunct={this.swapTemplate}
                          transitions={this.state.transitions}
                          list={this.state.slides}
                          saveSelectedSlidesFunct={this.saveSelectedSlides}
                          setSlidesFunct={this.setSlides}
                          setTransitionsFunct={this.setTransitions}
                          swapTransitionIndexFunct={this.swapTransitionIndex}
                      />
                  ) : null}
                  {this.state.dopeSheetOpen ? <DopeSheet canvasUtil={this.CanvasUtil} setSlidesFunct={this.setSlides} /> : null}
                  <Grid container className={classes.root}>
                    <Grid item xs={6} sm={7} md={8} lg={9} xl={10}>
                      <canvas id="c" />
                    </Grid>
                    <Grid item xs={6} sm={5} md={4} lg={3} xl={2}>
                      <div style={{ height: '90%', overflowY: 'auto', overflow: 'scroll' }}>
                        <EditorSidebar
                            active={this.state.canvasActive}
                            canvasUtil={this.CanvasUtil}
                            objects={this.state.canvasObjects}
                            fonts={data.clientFonts ? data.clientFonts : []}
                            template={template}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.fabBar}>
                  <div className={classes.fabBarContents}>
                    {addControls}
                  </div>
                  <Tooltip id="tooltip-right" title="Open Toolbar" placement="right">
                    <Button variant="fab" color="secondary" aria-label="add" className={classes.fab} onClick={this.toggleBar(this)}>
                      {this.state.addOpen ? <Minus /> : <Plus />}
                    </Button>
                  </Tooltip>
                  <form>
                    <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        title=" upload image"
                        id="upload-image"
                        ref={(ref) => this.uploadImage = ref}
                        type="file"
                        name="file"
                        onChange={this.uploadPreviewImage(this)}
                    />
                  </form>
                </div>
                {this.renderSVGDialog()}
                <Dialog open={this.state.selectGraphicOpen}>
                  <DialogTitle id="form-select-graphic-title">Add Graphic</DialogTitle>
                  <DialogContent>
                    <GraphicSelect onSelect={this.performAddGraphic} />
                  </DialogContent>
                  <DialogActions>
                    <Button
                        variant="raised"
                        color="primary"
                        onClick={() => this.setState({ selectGraphicOpen: false })}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog open={this.state.addSlideDialog}>
                  <DialogTitle id="form-select-graphic-title">Add Slide</DialogTitle>
                  <DialogContent>
                    <TemplateGridSlides
                        saveSelectedSlidesFunct={this.saveSelectedSlides}
                        setSlidesFunct={this.setSlides}
                        templateData={{ list: this.state.slides, width: template.width, height: template.height }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                        variant="raised"
                        color="primary"
                        onClick={() => this.setState({ addSlideDialog: false })}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
                {this.renderPreviewDialog()}
                {this.renderSnackbar()}
              </div>
          )}
        </Query>
    );
  }

  closeSnackbar = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ snackbar: '' });
  }

  renderSnackbar() {
    const { classes } = this.props;
    const { snackbar } = this.state;
    const open = (snackbar !== '');

    return (
        <Snackbar
            open={open}
            onClose={this.closeSnackbar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            autoHideDuration={6000}
            message={snackbar}
            action={[
              <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={this.closeSnackbar}
              >
                <Close />
              </IconButton>,
            ]}
        />
    );
  }

  renderPreviewDialog = () => {
    const { url, rendering, progress } = this.state;

    return (
        <SlidePreviewDialog
            open={url !== null}
            video={url}
            rendering={rendering}
            progress={progress}
            onClose={() => this.setState({ url: null })}
        />
    );
  }
}

const CanvasLoader = (props) => {
  let s3slideshowid = props.match.params.template;
  let isSlideShow = false;

  if ('s3slideshowid' in props.match.params) {
    s3slideshowid = props.match.params.s3slideshowid;
    isSlideShow = true;
  }

  let template_store = null;

  return (
      <Query query={GET_TEMPLATE} fetchPolicy="network-only" variables={{ id: props.match.params.template }}>
        {({ data, refetch }) => {
          if (!data.template && !template_store) {
            return <FullscreenLoader />;
          }

          if (data.template) {
            template_store = data.template;
          }

          return (
              <CanvasEditor
                  refetchTemplate={refetch}
                  isSlideShow={isSlideShow}
                  isQuickCreate={data.template.isQuickCreate}
                  s3slideshowid={s3slideshowid}
                  template={template_store}
                  {...props}
              />
          );
        }}
      </Query>
  );
};

export default compose(
    graphql(SAVE_TEMPLATE, { name: 'saveTemplate' }),
    withStyles(STYLES),
)(CanvasLoader);
