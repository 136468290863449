import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import FolderList from './FolderList.js';
import FileList from './FileList.js';


class FolderViewer extends Component {
  constructor() {
    super();
    this.dropzone = React.createRef();
  }

  render() {
    const {
      drag, drop, dragOver, dragEnd, files, folders, current_folder, type,
    } = this.props;
    let folderList;
    let fileList;
    const output = []
    if (folders) {
      if (folders.length > 0 || current_folder !== null) {
        if(this.props.paramDir === undefined) {
          this.props.folders.forEach(folder => {
            if(folder.parent === null) {
              output.push(folder)
              folderList = <FolderList type={type} folder_id={current_folder} drag={drag} dragEnd={dragEnd} drop={drop} dragOver={dragOver} folders={output} selected={this.props.selected} setSelected={this.props.setSelected} />;
          }
        })
        } else {
          folderList = <FolderList type={type} folder_id={current_folder} drag={drag} dragEnd={dragEnd} drop={drop} dragOver={dragOver} folders={folders} selected={this.props.selected} setSelected={this.props.setSelected} />;
        }
      }
      if (files.length > 0) {
        fileList = <FileList drag={drag} dragEnd={dragEnd} folder_id={current_folder} files={files} selected={this.props.selected} setSelected={this.props.setSelected} setSelectedFiles={this.props.setSelectedFiles}/>;
      }
      return (
        <React.Fragment>
          {folderList}
          {fileList}
        </React.Fragment>
      );
    }
    return <CircularProgress />;
    }
  }
  export default FolderViewer;
