/* eslint no-underscore-dangle: "off" */

// -------------------------------------------------------------------------- //

import * as DataBinding from '../../lib/databinding';
import * as MetaData from '../../lib/metadata';
import RenderJob from "../../../scoreshotsapi/RenderJob";

// -------------------------------------------------------------------------- //

class Widget {
  constructor(fabric) {
    this.class = fabric.util.createClass(fabric.Object, {
      type: 'widget',
      selectable: false,
      originX: 'left',
      originY: 'top',
      visible: true,
      widget: '',
      index: 0,

      initialize(options = {}) {
        this.callSuper('initialize', options);
        this.stroke = 'transparent';
        this.fill = 'transparent';
        this.index = options.index;

        this.on('modified', () => {
          if (this.children && this.canvas) {
            let angle = 0;
            if (this.previousAngle || this.previousAngle === 0) {
              angle = this.angle - this.previousAngle;
            }
            this.previousAngle = this.angle;

            let scaleX = 0;
            if (this.previousScaleX || this.previousScaleX === 0) {
              scaleX = this.previousScaleX - this.scaleX;
            }
            this.previousScaleX = scaleX;

            let scaleY = 0;
            if (this.previousScaleY || this.previousScaleY === 0) {
              scaleY = this.previousScaleY - this.scaleY;
            }
            this.previousScaleY = scaleY;

            this.children.forEach(obj => {
              obj.scaleX += scaleX;
              obj.scaleY += scaleY;
              obj.angle += angle;
              obj.dirty = true;
            });

            this.canvas.requestRenderAll();
          }
        });

        this.on('added', () => {
          if (this.children && typeof(this.children) === 'string') {
            this.enliven({ objects: JSON.parse(this.children) }, true);
          }
          this.off('added');
        });
      },

      _clipTo: (self, obj) => (ctx) => {
        let multiplier = 1;

        if (obj.canvas) {
          if (obj.canvas.enableRetinaScaling) {
            multiplier = obj.canvas.getRetinaScaling();
          }

          const view = obj.canvas.viewportTransform;
          ctx.setTransform(
            view[0] * multiplier,
            view[1],
            view[2],
            view[3] * multiplier,
            view[4] * multiplier,
            view[5] * multiplier);

          const aabb = self.getBoundingRect(true, false);

          ctx.rect(
            aabb.left, aabb.top,
            aabb.width, aabb.height
          );

          const viewObject = fabric.util.multiplyTransformMatrices(obj.canvas.viewportTransform, obj.calcTransformMatrix());

          ctx.setTransform(
            viewObject[0] * multiplier,
            viewObject[1],
            viewObject[2],
            viewObject[3] * multiplier,
            viewObject[4] * multiplier,
            viewObject[5] * multiplier);
        }
      },

      enliven(json, existing) {
        this.canvas.ignoreUndo = true;

        fabric.util.enlivenObjects(
          json.objects,
          (objects) => {
            this.children = [...objects];

            objects.forEach((object, index) => {
              DataBinding.SetPlayerIndex(object, this.index);
              MetaData.setMetaData(object, 'widget', this.widget);
              MetaData.setMetaData(object, 'widget_index', this.index);
              object.parent = this;

              this.canvas.insertAt(
                object, (this.canvas.getObjects().indexOf(this) + index + 1)
              );
            });

            if (this.pendingResize) {
              this.resize(this.pendingResize.width, this.pendingResize.height);
              delete this.pendingResize;
            }

            if (this.pendingMove) {
              this.move(this.pendingMove.left, this.pendingMove.top);
              delete this.pendingMove;
            }

            this.canvas.ignoreUndo = false;
            this.canvas.trigger('widget:loaded');
          },
          null,
          (object) => {
            if (!existing) {
              object.top += this.top;
              object.left += this.left;
              object.scaleX *= this.scaleX;
              object.scaleY *= this.scaleY;
              object.clipTo = this._clipTo(this, object);
            }
          }
        );
      },

      reinstantiate() {
        let objects = this.canvas.getObjects();
        this.children = [];

        for (let i = 0; i < objects.length; ++i) {
          if (
              MetaData.getMetaData(objects[i], 'widget') === this.widget &&
              MetaData.getMetaData(objects[i], 'widget_index') === this.index
          ) {
            this.children.push(objects[i]);
            objects[i].clipTo = this._clipTo(this, objects[i]);
          }
        }
      },

      instantiate() {
        if (typeof(this.children) === 'string') {
          this.enliven(JSON.parse(this.children));
        } else if (this.widget && (!this.children || !this.children.length)) {
          fetch(this.widget).then((response) => {
            response.json().then(json => {
              RenderJob.remapCanvasColors(json, this.canvas.colors);
              this.enliven(json);
            });
          });
        } else {
          this.reinstantiate();
        }
      },

      remove() {
        let canvas = this.canvas;

        if (!canvas) {
          return;
        }

        canvas
        .getObjects()
        .filter((object) => (
          MetaData.getMetaData(object, 'widget') === this.widget &&
          MetaData.getMetaData(object, 'widget_index') === this.index
        ))
        .forEach((object) => {
          if (object.media && object.media.element) {
            canvas.remove(object.media.element);
          }

          canvas.remove(object);
        });

        canvas.remove(this);
        canvas.requestRenderAll();
      },
      move(left, top) {
        if (!this.children) {
          this.pendingMove = {
            left, top
          }

          return;
        }

        let differenceLeft = (left - this.left);
        let differenceTop = (top - this.top);

        if (this.canvas && this.children) {
          for (let i = 0; i < this.children.length; ++i) {
            let child = this.children[i];

            child.left += differenceLeft;
            child.top += differenceTop;

            if (child.media && child.media.element) {
              child.media.element.left += differenceLeft;
              child.media.element.top += differenceTop;
              child.media.element.setCoords();
              child.media.element.dirty = true;
            }

            child.setCoords();
            child.clipTo = this._clipTo(this, child);
            child.dirty = true;
          }
        }

        this.left = left;
        this.top = top;
        this.setCoords();
      },
      resize(width, height) {
        if (!this.children) {
          this.pendingResize = {
            width, height
          }

          return;
        }

        width = Math.floor(width);
        height = Math.floor(height);

        const scaledWidth = Math.floor(this.width * this.scaleX);
        const scaledHeight = Math.floor(this.height * this.scaleY);

        const scaleX = width / scaledWidth;
        const scaleY = height / scaledHeight;

        if (this.canvas && this.children) {
          for (let i = 0; i < this.children.length; ++i) {
            let child = this.children[i];

            const relativeX = (child.left - this.left) / scaledWidth;
            const relativeY = (child.top - this.top) / scaledHeight;

            child.left = this.left + relativeX * width;
            child.top = this.top + relativeY * height;

            switch(child.type) {
              case 'image':
                if (child.parent && child.parent.type === 'backgroundBox') {
                  child.scaleX *= scaleX;
                  child.scaleY *= scaleY;
                }
                break;
              case 'backgroundBox':
              case 'logoBox':
              case 'cutoutBox':
                child.scaleX *= scaleX;
                child.scaleY *= scaleY;
                if (child.media && child.media.element) {
                  child.setMedia({
                    src: child.media.element.getSrc()
                  });
                }
                break;
              case 'line':
              case 'circle':
              case 'triangle':
              case 'polygon':
              case 'polyline':
                if ((child.angle > 45 && child.angle < 135) || (child.angle > 225 && child.angle < 315)) {
                  child.scaleX *= scaleY;
                  child.scaleY *= scaleX;
                } else {
                  child.scaleX *= scaleX;
                  child.scaleY *= scaleY;
                }
                break;
              case 'rect':
              default:
                break;
            }

            child.setCoords();
          }
        }

        this.width = width;
        this.height = height;

        this.setCoords();

        if (this.canvas && this.children) {
          for (let i = 0; i < this.children.length; ++i) {
            let child = this.children[i];
            child.clipTo = this._clipTo(this, child);
          }
        }
      },
      toObject(propertiesToInclude) {
        propertiesToInclude = [
          ...propertiesToInclude,
          'widget',
          'index',
          'children'
        ];

        return fabric.util.object.extend(
          this.callSuper('toObject', propertiesToInclude),
          {
            children: (this.children && this.children.length)
          });
      },
      _render(ctx) {
        if (this.visible) {
          ctx.rect(0, 0, this.width, this.height);
          ctx.stroke();
        }
      }
    });

    this.fromObject = (object, callback) => fabric.Object._fromObject('Widget', object, callback);
  }
}

// -------------------------------------------------------------------------- //

export default Widget;

// -------------------------------------------------------------------------- //
