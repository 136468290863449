
// -------------------------------------------------------------------------- //

import * as React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';

import Crumbs from './Crumbs';
import LayersEditor from '../LayersEditor';

// -------------------------------------------------------------------------- //

class Layers extends React.Component {

  state = {
    rename_object: false,
    new_object_name: '',
  }

  render() {
    let { active, CanvasUtil, objects } = this.props;

    return (
      <React.Fragment>
          <Crumbs title="Layers"/>
          <LayersEditor
            active={active}
            objects={objects}
            canvasUtil={CanvasUtil}
            modifyLayer={(obj) => this.openRenameDialog(obj)}
            setActiveLayer={(obj) => {
              if (obj.media) {
                obj = obj.media.element;
              }

              CanvasUtil.canvas.setActiveObject(obj);
              CanvasUtil.canvas.renderAll();
            }}
          />
          {this.renderRenameDialog()}
      </React.Fragment>
    );
  }

  openRenameDialog = (object) => {
    this.setState({
      rename_object: object,
      new_object_name: ((object && object.name) || ''),
    });
  }

  closeRenameDialog = () => {
    this.setState({ rename_object: null });
  }

  renderRenameDialog = () => {
    const { rename_object, new_object_name } = this.state;

    return (
      <Dialog
        open={Boolean(rename_object)}
        onClose={() => this.closeRenameDialog()}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const { new_object_name } = this.state;
            let { rename_object } = this.state;

            if (rename_object) {
              rename_object.set('name', new_object_name);
            }

            this.closeRenameDialog();
          }}
        >
          <DialogTitle>
            Rename Layer
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              fullWidth
              onFocus={(e) => e.target.select()}
              value={new_object_name}
              onChange={(e) => {
                this.setState({ new_object_name: e.target.value });
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.closeRenameDialog()}>
              Cancel
            </Button>
            <Button type="submit">
              Rename
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }

}

// -------------------------------------------------------------------------- //

export default Layers;

// -------------------------------------------------------------------------- //
