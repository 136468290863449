import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Logo from '../svg/Logo';

const styles = (theme) => ({
  background: {
    backgroundColor: theme.palette.background.paper,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  login: {
    maxWidth: 480,
    backgroundColor: '#FFF',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    textAlign: 'center',
  },
  logo: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(4),
    textAlign: 'center',
  },
  logoSVG: {
    fill: '#FFF',
    maxWidth: 280,
  },
  button: {
    marginTop: theme.spacing(1),
  },
  buttonProgress: {
    marginLeft: 30,
  },
});

const notFoundTheme = createMuiTheme({
  typography: {
    fontFamily: 'Ubuntu,Roboto,sans-serif',
  },
  palette: {
    primary: {
      main: '#6b2587',
    },
    secondary: {
      main: '#4fd4c4',
    },
  },
});

class PrestoNotFound extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.background}>
        <div className={classes.logo}>
          <Logo className={classes.logoSVG} />
        </div>
        <div className={classes.login}>
          <Typography variant="body1" gutterBottom>
            I'm sorry, there seems to be an issue with finding your account in the system. Please contact support at
            {' '}
            <a href="https://clubessential.my.salesforce-sites.com/contactus" rel="noopener noreferrer" target="_blank">https://clubessential.my.salesforce-sites.com/contactus</a>
            .
          </Typography>
        </div>
      </div>
    );
  }
}


const StyledPrestoNotFound = withRouter(withStyles(styles)(PrestoNotFound));

const PrestoNotFoundWithProvider = (props) => (
  <MuiThemeProvider theme={notFoundTheme}>
    <StyledPrestoNotFound {...props} />
  </MuiThemeProvider>
);

export default PrestoNotFoundWithProvider;
