/* eslint no-underscore-dangle: "off" */

class BarGraph {
  constructor(fabric) {
    this.class = fabric.util.createClass(fabric.Rect, {
      type: 'barGraph',
      originX: 'left',
      originY: 'top',
      barGraphLayout: 'vertical',
      barGraphScale: 100,
      stateProperties: fabric.Rect.prototype.stateProperties.concat(
        'barGraphLayout',
        'barGraphScale',
      ),
      initialize(options = {}) {
        this.callSuper('initialize', options);

        this.on('object:modified', () => {
          if (this.canvas && this.canvas.step === 'admin') {
            this.originalWidth = this.width;
            this.originalHeight = this.height;
          }
        });

        this.barGraphLayout = options.barGraphLayout || 'vertical';
        this.barGraphScale = options.barGraphScale || 100;
      },
      toObject(propertiesToInclude) {
        propertiesToInclude = [
          ...propertiesToInclude,
          'barGraphLayout',
          'barGraphScale',
          'originalWidth',
          'originalHeight'
        ];

        return fabric.util.object.extend(
          this.callSuper('toObject', propertiesToInclude),
          {
            barGraphLayout: this.barGraphLayout || 'horizontal',
            barGraphScale: this.barGraphScale || 100.0,
            width: this.width * this.scaleX,
            originalWidth: this.width * this.scaleX,
            height: this.height * this.scaleY,
            originalHeight: this.height * this.scaleY,
            scaleX: 1,
            scaleY: 1
          }
        );
      }
    });

    this.fromObject = (object, callback) => fabric.Object._fromObject('BarGraph', object, callback);
  }
}

export default BarGraph;
