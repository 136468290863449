import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Form, Field } from 'react-final-form';
import Button from '@material-ui/core/Button';
import TextField from '../material/TextField';

const GET_USER = gql`
  query {
    me {
      id
      email
      firstname
      lastname
    }
  }
`;
const UPDATE_USER = gql`
  mutation ($firstname: String, $lastname: String, $email: String) {
    updateUser(
      firstname: $firstname,
      lastname: $lastname,
      email: $email
    ) {
      id
      email
      firstname
      lastname
    }
  }
`;

const validateUser = (val) => {
  const output = {};
  if (!val.firstname) {
    output.firstname = 'First Name Required';
  }
  if (!val.lastname) {
    output.lastname = 'Last Name Required';
  }
  if (!val.email) {
    output.email = 'Email Address Required';
  }
  return output;
};


class General extends Component {
  saveSettings = updateUser => (val) => {
    updateUser({
      variables: val,
    }).then((response) => {
      if (response.errors) {
        this.props.sendMessage(response.errors[0].message);
      } else {
        this.props.sendMessage('Changed Successfully');
      }
    }).catch((error) => {
      this.props.sendMessage(error.toString());
    });
  };

  render() {
    const classes = {};
    const { sendMessage, ...restProps } = this.props;
    return (
      <Paper {...restProps}>
        <Typography variant="h4" component="h1" gutterBottom>
          User Profile
        </Typography>
        <Mutation mutation={UPDATE_USER}>
          {updateUser => (
            <Query query={GET_USER}>
              {({ data }) => (
                <Form
                  onSubmit={this.saveSettings(updateUser)}
                  initialValues={data.me}
                  validate={validateUser}
                  render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} style={{ maxWidth: 400 }}>
                      <Field
                        name="firstname"
                        className={classes.formField}
                        component={TextField}
                        id="firstname"
                        label="First Name"
                        style={{ marginTop: 10 }}
                      />
                      <Field
                        name="lastname"
                        className={classes.formField}
                        component={TextField}
                        id="lastname"
                        label="Last Name"
                        style={{ marginTop: 10 }}
                      />
                      <Field
                        name="email"
                        className={classes.formField}
                        component={TextField}
                        id="email"
                        label="Email Address"
                        type="email"
                        style={{ marginTop: 10 }}
                      />


                      <Button
                        variant="raised"
                        color="primary"
                        onClick={handleSubmit}
                        style={{ marginTop: 10 }}
                      >
                        Save
                      </Button>
                    </form>
                  )}
                />
              )}
            </Query>
          )}
        </Mutation>


      </Paper>
    );
  }
}

export default General;
