import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Subscribe } from 'unstated';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Query } from 'react-apollo';
import ExpiredAccount from './components/login/ExpiredAccount';
import gql from 'graphql-tag';
import App from './components/app/App';
import Auth from './components/auth/auth';
import Login from './components/login/Login';
import PrestoNotFound from './components/login/PrestoNotFound';
import Presto from './components/login/Presto';

import { CreateSearchContext, CreateSearchParameters } from './components/create/search';
import { VirtualTourContext } from './components/app/tour';
import { SelectPlan } from './components/stripe';
import Admin from './components/admin';
import CustomTemplateLoader from './components/editor/CustomTemplateLoader';
import DraftLoader from './components/editor/DraftLoader';
import Editor from './components/editor/Editor';
import FontLoader from './components/fonts/FontLoader';
import FullMotionEditor from './components/fullmotion/FullMotionEditor';
import history from './history';
import MediaTransfer from './views/MediaTransfer';
import MessageContainer from './unstated/MessageContainer';
import OptIn from './views/OptIn';
import PostLoader from './components/editor/PostLoader';
import TemplateLoader from './components/editor/TemplateLoader';
import Trial from './components/login/Trial';

const auth = new Auth();

const GET_MY_FONTS = gql`
  query {
    myFonts {
      id
      fontFamily
      type
      bucket
      key
    }
  }
`;

class Routes extends Component {
  state = {
    search_parameters: CreateSearchParameters(),
    tour_open: false,
    tour_step: 0,
    start_tour: () => {
      window.addEventListener('popstate', () => {
        this.setState({ tour_open: false });
      });
      this.setState({ tour_open: true, tour_step: 0 });
    },
    close_tour: () => {
      this.setState({ tour_open: false });
    },
    set_step: (step) => { this.setState({ tour_step: step }); },
  }

  render() {

    // otherwise show the app
    let updateNotice = null;
    if (this.props.showUpdate) {
      updateNotice = (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          key="updateMessage"
          open
          message={(
            <span id="message-id">
              A new update to ScoreShots is available. Please refresh to receive it.
            </span>
          )}
          action={(
            <Button
              color="secondary"
              size="small"
              onClick={
                () => {
                  window.location.reload();
                }
              }
            >
              Refresh
            </Button>
          )}
        />
      );
    }
    return (
      <Subscribe to={[MessageContainer]}>
        {(messages) => {
          const snackbar = (
            messages.state.message
              ? (
                <Snackbar
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  key="message"
                  open
                  autoHideDuration={messages.state.duration}
                  onClose={messages.unsetMessage}
                  message={<span id="message-id">{messages.state.message}</span>}
                />
              )
              : null
          );
          if (!auth.isAuthenticated()) {
            return (
              <>
                <Switch key="switch">
                  <Route exact path="/trial" render={() => <Trial handleClose={() => { history.push('/login'); }} />} />
                  <Route exact path="/trial/:notes" render={({ match }) => <Trial match={match} handleClose={() => { history.push('/login'); }} />} />
                  <Route exact path="/presto/:id" render={({ match }) => <Presto auth={auth} match={match} key="login" />} />
                  <Route path="/optin" component={OptIn} />
                  <Route path="/notfound" render={() => <PrestoNotFound />} />
                  <Route render={() => <Login auth={auth} key="login" />} />
                </Switch>
                {snackbar}
                {updateNotice}
              </>
            );
          }

          return (
            <>
              <Query query={GET_MY_FONTS} key="query">
                {({ data }) => (
                  <FontLoader fonts={(data && data.myFonts) || []}>
                    <VirtualTourContext.Provider value={this.state}>
                      <CreateSearchContext.Provider value={this.state.search_parameters}>
                        <Switch key="switch">
                          <Route path="/admin" component={Admin} />
                          <Route path="/logout" render={() => <CircularProgress />} />
                          <Route exact path="/presto/:id" render={({ match }) => <Presto auth={auth} match={match} key="login" />} />
                          <Route exact path="/editor/:method(custom)/:id/:sharing(share)?" component={CustomTemplateLoader} />
                          <Route exact path="/editor/:method(template)/:id/:sharing(share)?" component={TemplateLoader} />
                          <Route exact path="/editor/:method(post)/:id/:bool/:sharing(share)?" component={PostLoader} />
                          <Route exact path="/editor/:method(draft)/:id/:sharing(share)?" component={DraftLoader} />
                          <Route exact path="/editor/:method(blank)/:width/:height/:sharing(share)?" component={Editor} />
                          <Route exact path="/editor/:method(slideshow)/:submethod(custom)/:id/:sharing(share)?" component={CustomTemplateLoader} />
                          <Route exact path="/editor/:method(slideshow)/:submethod(template)/:id/:sharing(share)?" component={TemplateLoader} />
                          <Route exact path="/editor/:method(slideshow)/:submethod(post)/:id/:bool/:sharing(share)?" component={PostLoader} />
                          <Route exact path="/editor/:method(slideshow)/:submethod(draft)/:id/:sharing(share)?" component={DraftLoader} />
                          <Route exact path="/editor/:method(slideshow)/:submethod(blank)/:width/:height/:sharing(share)?" component={Editor} />
                          <Route exact path="/editor/:method(quickcreate)/:submethod(custom)/:id/:sharing(share)?" component={CustomTemplateLoader} />
                          <Route exact path="/editor/:method(quickcreate)/:submethod(template)/:id/:sharing(share)?" component={TemplateLoader} />
                          <Route exact path="/editor/:method(quickcreate)/:submethod(post)/:id/:bool/:sharing(share)?" component={PostLoader} />
                          <Route exact path="/editor/:method(quickcreate)/:submethod(draft)/:id/:sharing(share)?" component={DraftLoader} />
                          <Route exact path="/editor/:method(quickcreate)/:submethod(blank)/:width/:height/:sharing(share)?" component={Editor} />
                          <Route exact path="/fullmotion/:id" component={FullMotionEditor} />
                          <Route exact path="/mediatransfer" component={MediaTransfer} />
                          <Route exact path="/expiredaccount" component={ExpiredAccount} />
                          <Route exact path="/updateaccount" render={({ match }) => <SelectPlan clientId="" title="Update Account" />} />
                          <Route exact path="/pay/:plantype/:clientid/:planid" render={({ match }) => <div><h1>Purchase</h1></div>} />
                          <Route path="/" component={App} />
                          <Route path="/reload" component={null} key="reload" />
                        </Switch>
                      </CreateSearchContext.Provider>
                    </VirtualTourContext.Provider>
                  </FontLoader>
                )}
              </Query>
              {snackbar}
              {updateNotice}
            </>
          );
        }}
      </Subscribe>
    );
  }
}

export default Routes;
