import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import Logo from '../svg/Logo';
const loginTheme = createMuiTheme({
  typography: {
    fontFamily: 'Ubuntu,Roboto,sans-serif',
  },
  palette: {
    primary: {
      main: '#6b2587',
    },
    secondary: {
      main: '#4fd4c4',
    },
  },
});

const styles = theme => ({
  background: {
    backgroundColor: theme.palette.background.paper,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  login: {
    maxWidth: 600,
    backgroundColor: '#FFF',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
  },
  logo: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(4),
    textAlign: 'center',
  },
  logoSVG: {
    fill: '#FFF',
    maxWidth: 280,
  },
  button: {
    marginTop: theme.spacing(1),
  },
  buttonProgress: {
    marginLeft: 30,
  },
});

class TrialWrapper extends Component {

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.background}>
          <div className={classes.logo}>
            <Logo className={classes.logoSVG} />
          </div>
          <div className={classes.login}>{this.props.children}</div>
         </div>
      </React.Fragment>
    );
  }
}

const TW = withStyles(styles)(TrialWrapper);

const TrialWrapperProvider = props => (
  <MuiThemeProvider theme={loginTheme}>
    <TW {...props} />
  </MuiThemeProvider>
);

export default TrialWrapperProvider
