
// -------------------------------------------------------------------------- //

import * as React from 'react';


import format from 'date-fns/format';

import * as Scoreshots from '../../scoreshotsapi/v1';
// import SendToPhoneDialog from './SendToPhoneDialog';

import {
  CircularProgress,
  IconButton,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  Download,
} from 'mdi-material-ui';

// -------------------------------------------------------------------------- //

const styles = theme => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

// -------------------------------------------------------------------------- //

function downloadImage(image) {
  const filename = `${format(new Date(), 'YYYY-MM-DDTHH:mm:ss.SSSZ')} Graphic.png`;

  return (
    Scoreshots.S3GenerateDownloadLink(image, 'image/png', filename)
    .then(response => response.json())
    .then(json => {
       window.open(json.link);
    })
  );
}

// -------------------------------------------------------------------------- //

function downloadVideo(url) {
  const filename = `${format(new Date(), 'YYYY-MM-DDTHH:mm:ss.SSSZ')} Graphic.mp4`;

  return (
    Scoreshots.S3GenerateDownloadLink(url, 'video/mp4', filename)
    .then(response => response.json())
    .then(json => {
      window.open(json.link);


    })
  );
}

// -------------------------------------------------------------------------- //

class OtherOptions extends React.Component {

  state = {
    downloading: false,
    phone: false,
  }

  openSendToPhone = () => {
    this.setState({ phone: true });
  }

  closeSendToPhone = () => {
    this.setState({ phone: false });
  }

  onDownloadClick = () => {
    const { video, image } = this.props;
    const mode = this.getRenderMode();

    this.setState({ downloading: true }, () => {
      const promise = (
        mode === 'video'
        ? downloadVideo(video)
        : downloadImage(image)
      );

      promise.then(() => {
        this.setState({ downloading: false });
        window.Intercom("trackEvent","download",{via:"share-page"});
      }).catch(() => {
        this.setState({ downlaoding: false });
      });
    });
  }

  getRenderMode() {
    const { mode = 'image' } = this.props;
    return mode;
  }

  render() {
    const { classes, disabled, disableDownload } = this.props;
    const { downloading } = this.state;

    return (
      <div className={classes.root}>
        <Typography variant="caption">Other Sharing and Export Options</Typography>
        {
          !disableDownload && (downloading ? (
            <CircularProgress color="primary" size={48}/>
          ) : (
            <IconButton disabled={disabled} onClick={this.onDownloadClick}>
              <Download />
            </IconButton>
          ))
        }
      </div>
    );
  }
}
// -------------------------------------------------------------------------- //


export default withStyles(styles)(OtherOptions);

// -------------------------------------------------------------------------- //
