import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Lottie from 'react-lottie';
import * as animationData from '../../lottie/sslogo.json';

const styles = theme => ({
  placeholder: {
    boxShadow: theme.shadows[4],
    borderRadius: 2,
    textAlign: 'center',
  },
});

class RenderPlaceholder extends Component {
  state = {
    width: 0,
    height: 0,
  }
  componentDidMount() {
    this.setState({
      width: this.divElement.clientWidth,
      height: this.divElement.clientHeight,
    });
  }
  render() {
    const { classes } = this.props;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData,
      rendererSettings: {},
    };
    const width = Math.min(
      ((this.state.height / this.props.height) * this.props.width),
      this.state.width,
    );
    return (
      <div
        style={{ width: '100%', height: '100%' }}
        ref={(divElement) => { this.divElement = divElement; }}
      >
        <div
          className={classes.placeholder}
          style={{
            height: this.state.height,
            width,
          }}
        >
          <Lottie
            options={defaultOptions}
            width={400}
            height={this.state.height * 0.7}
          />
          <Typography variant="h5">
            Rendering your Video Graphic
          </Typography>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(RenderPlaceholder);
