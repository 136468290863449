import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import QuickLayerEdit from './QuickLayerEdit';
import LayersEditor from '../editor/LayersEditor';

const styles = theme => ({
  // provided as an example, remove panels
  sidebar: {
    height: '80%',
    width: '100%',
    backgroundColor: '#f5f5f5',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  paper: {
    margin: theme.spacing(2),
  },
  paperSelector: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  layerHeader: {
    marginLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  list: {
    height: '45vh',
    overflowY: 'scroll',
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 4,
    cursor: 'pointer',
  },
  button: {
    height: 32,
    width: 32,
  },
});

class EditorSidebar extends Component {
  constructor() {
    super();
    this.state = {
      nameLayer: false,
    };
  }
  componentDidMount() {
  }

  changeActive = (self) => (object) => {
    self.props.canvasUtil.canvas.setActiveObject(object).requestRenderAll();
  }

  changeActiveName = self => (e) => {
    self.setState({
      activeName: e.target.value,
    });
  }

  renameLayer = (self, perform) => () => {
    const { active } = this.props;
    if (perform) {
      active.set({ name: self.state.activeName, dirty: true });
    }
    self.setState({
      nameLayer: false,
    });
  }

  openModal = self => () => {
    const { active } = this.props;
    self.setState({
      nameLayer: true,
      activeName: (active.get('name') ? active.get('name') : ''),
    });
  }

  render() {
    const { classes, active, objects } = this.props;
    const { canvasUtil, fonts, template } = this.props;

    return (
      <div className={classes.sidebar}>
        {
          active &&
          <QuickLayerEdit
            active={active}
            template={template}
            fonts={fonts}
            canvasUtil={canvasUtil}
          />
        }
        <Paper className={classes.paper} elevation={4}>
          <Typography variant="h5" component="h3" className={classes.layerHeader}>
            Layers
          </Typography>
          <div className={classes.list}>
            <LayersEditor
              active={active}
              objects={objects}
              canvasUtil={canvasUtil}
              setActiveLayer={this.changeActive(this)}
              modifyLayer={this.openModal(this)} />
          </div>
        </Paper>
        <Dialog
          open={this.state.nameLayer}
        >
          <DialogTitle id="form-dialog-title">Rename Layer</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="New Layer Name"
              type="text"
              value={this.state.activeName}
              onChange={this.changeActiveName(this)}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.renameLayer(this, false)} color="default">
              Cancel
            </Button>
            <Button onClick={this.renameLayer(this, true)} color="primary">
              Rename
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(EditorSidebar);
