
// -------------------------------------------------------------------------- //

import { convertValue } from './Variable';

/* -------------------------------------------------------------------------- *\
  The import options include:

  {
    players: Mapping[], // player mappings (see below)
  }

  Player mappings follow the format:

  {
    team: number, // index of player's team
    player: number, // index of team's player
  }
\* -------------------------------------------------------------------------- */

class SportImporter {

  constructor() {
    this.sport = null;
    this.xml = null;
  }

  import(sport, xml, options = {}) {
    if (!sport || !xml) {
      return false;
    }

    this.sport = sport;
    this.xml = xml;
    return true;
  }

  test(path) {
    return (path.get(this.xml) !== null);
  }

  peek(path, options = {}) {
    return this.select(null, path, options);
  }

  select(id, path, options = {}) {
    let value = path.get(this.xml);

    if (value === null && options.default !== undefined) {
      value = options.default;
    }

    let type = null, variable = null;

    if (options.type !== undefined) {
      type = options.type;
    }

    if (id !== null) {
      variable = this.sport.getVariableById(id);

      if (variable !== null && type === null) {
        type = variable.type;
      }
    }

    if (type !== null) {
      value = convertValue(value, type);

      switch (type) {
        case 'number': {
          let round = true;

          if (options.round !== undefined) {
            round = options.round;
          }

          if (round) {
            value = Math.round(value);
          }

          break;
        }
        case 'string': {
          switch (options.text_transform) {
            case 'upper': {
              value = (value || '').toUpperCase();
              break;
            }
            case 'lower': {
              value = (value || '').toLowerCase();
              break;
            }
            default: {
              break;
            }
          }

          break;
        }
        default: {
          break;
        }
      }
    }

    if (variable !== null) {
      let index = 0;

      if (options.index !== undefined) {
        index = options.index;
      }

      variable.setValue(value, index);
    }

    return value;
  }

  get(id, index = 0) {
    const variable = this.sport.getVariableById(id);

    if (variable === null) {
      return null;
    }

    return variable.getValue(index);
  }

  set(id, value, index = 0) {
    const variable = this.sport.getVariableById(id);

    if (variable === null) {
      return false;
    }

    variable.setValue(value, index);
    return true;
  }

  setSum(id, index, ...operands) {
    const values = operands.map(operand => this.get(operand, id));
    this.set(id, values.reduce(((lhs, rhs) => lhs + rhs), 0), index);
  }

  setPercent(id, n, d, index = 0) {
    const numerator = this.get(n, index);
    const denominator = this.get(d, index);

    if (denominator > 0) {
      this.set(id, Math.round(numerator / denominator * 100), index);
    } else {
      this.set(id, 0, index);
    }
  }

}

// -------------------------------------------------------------------------- //

export default SportImporter;

// -------------------------------------------------------------------------- //
