
// -------------------------------------------------------------------------- //

import * as React from 'react';

import { graphql, compose } from 'react-apollo';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';

import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  withStyles,
  Button,
} from '@material-ui/core';

import { withRouter } from 'react-router';
import GraphicPreview from './GraphicPreview';
import OtherOptions from './OtherOptions';
import RenderPreview from './RenderPreview';
import ScoreshotsPut from '../../scoreshotsapi/Put';
import SendToPhoneDialog from './SendToPhoneDialog';
import StepNavigator from './StepNavigator';
import { ROLES_QUERY, UserPermissions } from '../user/Permissions';

const ROLES = gql`
  query {
    me {
      id
      client {
        id
        fbPages {
          id
          name
          accessToken
          fbId
        }
        twitterAccounts {
          id
          name
          screen_name
          twitterId
          oauthToken
          oauthTokenSecret
        }
      }
      role {
        ${ROLES_QUERY}
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const style = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 200,
  },
  break: {
    margin: theme.spacing(1),
    width: 200,
  },
  flex: {
    flex: 1,
  },
  noselect: {
    userSelect: 'none',
  },
  stack_content: {
    maxWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  stack_preview: {
    boxShadow: theme.shadows[4],
    textAlign: 'center',
    borderRadius: 2,
    marginTop: theme.spacing(1),
  },
  stack_description: {
    padding: theme.spacing(1),
  },
  stack_toolbar: {
    paddingTop: theme.spacing(1),
  },
});

// -------------------------------------------------------------------------- //

const SAVE_DRAFT = gql`
  mutation saveDraft($id: ID, $templateId: ID, $bucket: String!, $height: Int!, $width: Int!) {
    draft(
      id: $id,
      templateId: $templateId,
      bucket: $bucket,
      height: $height,
      width: $width
    ) {
      id
    }
  }
`;

// -------------------------------------------------------------------------- //

function convertDataUrlToBlob(data_url) {
  const splits = data_url.split(',');
  const mime = splits[0].match(/:(.*?);/)[1];
  const bstr = atob(splits[1]);
  const n = bstr.length;
  const array = new Uint8Array(n);

  for (let i = 0; i < bstr.length; ++i) {
    array[n] = bstr.charCodeAt(n);
  }

  return new Blob([array], { type: mime });
}

// -------------------------------------------------------------------------- //

function saveDraft(mutation, canvas_util, template_id = null) {
  const BUCKET = 'ss3-drafts';

  const variables = {
    bucket: BUCKET,
    width: canvas_util.canvas.workingArea.width,
    height: canvas_util.canvas.workingArea.height,
  };

  if (template_id) {
    variables.templateId = template_id;
  }

  return (
    canvas_util.serializeToJSON()
      .then(() => mutation({ variables }))
      .then((response) => {
        const body = convertDataUrlToBlob(canvas_util.toDataURL());
        const key = `${response.data.draft.id}.png`;
        const action = new ScoreshotsPut(BUCKET, key);
        action.draft_id = response.data.draft.id;
        return action.put(body, 'image/png');
      })
      .then(({ action: response }) => {
        const json = canvas_util.toJSON();
        const body = new Blob([JSON.stringify(json)], { type: 'application/json' });
        const key = `${response.draft_id}.json`;
        const action = new ScoreshotsPut(BUCKET, key);
        action.draft_id = response.draft_id;
        return action.put(body, 'application/json');
      })
      .then(({ action: response }) => response.draft_id)
  );
}

// -------------------------------------------------------------------------- //

class AddExtrasPreview extends React.Component {
  state = {
    forceVideo: false,
    duration: 10,
    backgroundEffect: '',
    overlayEffect: '',
    rendering: false,
    renderFailed: false,
    backupDraftId: null,
    jobId: null,
    phone: false,
    templateDownload: 'AllTemplates'
  }

  getOverlayEffects() {
    const { classes } = this.props;
    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="bgeffects">Overlay Effects</InputLabel>
        <Select
          value={this.state.overlayEffect}
          onChange={this.handleStateChangeEvent('overlayEffect')}
          inputProps={{
            name: 'overlayeffect',
            id: 'overlayeffect',
          }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="fadein">Fade In</MenuItem>
          <MenuItem value="fadeout">Fade Out</MenuItem>
          <MenuItem value="fadeinout">Fade In and Out</MenuItem>
        </Select>
      </FormControl>
    );
  }

  constructor(props) {
    super(props);

    this.openPhoneDialog = this.openPhoneDialog.bind(this)
    this.selectDownload = this.selectDownload.bind(this)
  }

  handleCheckEvent = (prop) => (e) => {
    const state = {};
    if (e.target && e.target.checked !== undefined) {
      state[prop] = e.target.checked;
    }
    this.setState(state);
  }

  handleStateChangeEvent = (prop) => (e) => {
    const state = {};
    if (e.target && e.target.value) {
      state[prop] = e.target.value;
    } else {
      state[prop] = e;
    }
    this.setState(state);
  }

  handleStateChange = (state) => {
    this.setState(state);
  }

  openPhoneDialog = (e) => {
    this.setState({ phone: true, phone_mode: e.mode });
  }

  closePhoneDialog = () => {
    this.setState({ phone: false });
  }

  isRendering() {
    const { slideshow } = this.props;

    return (
      slideshow
        ? this.props.rendering
        : this.state.rendering
    );
  }

  componentDidUpdate(_, { renderFailed }) {
    const { saveDraft: mutation, CanvasUtil, templateId } = this.props;

    if (!renderFailed && this.state.renderFailed) {
      // save draft on render failure
      saveDraft(mutation, CanvasUtil, templateId)
        .then((draft_id) => {
          this.setState({
            backupDraftId: draft_id,
          });
        });
    }
  }

  renderFailureDialog() {
    const { renderFailed, backupDraftId } = this.state;
    const draft_url = `/editor/draft/${backupDraftId}`;

    return (
      <Dialog open={Boolean(renderFailed && backupDraftId)}>
        <DialogTitle>Something went wrong.</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Whoops. Fortunately, your work has already been backed up as a draft. You can continue from the editor and try sharing again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" component={Link} to={draft_url}>Continue</Button>
        </DialogActions>
      </Dialog>
    );
  }

  selectDownload(e){
    this.setState({templateDownload: e})
  }

  renderStandard() {
    const { classes } = this.props;
    const backgroundEffect = this.state.backgroundEffect !== ''
      ? this.state.backgroundEffect : false;
    const overlayEffect = this.state.overlayEffect !== ''
      ? this.state.overlayEffect : false;
    let duration = false;
    if (this.state.forceVideo) {
      ({ duration } = this.state);
    } else if (!this.props.isVideo && this.props.hasEffect) {
      ({ duration } = this.state);
    }

    const { image } = this.props;
    const { jobId, renderFailed } = this.state;
    const video = `https://ss3-video.s3.us-east-2.amazonaws.com/output/${jobId}.mp4`;
    const rendering = this.isRendering();

    if (this.props.isVideo) {
      return (
        <>
          <Typography>
            {
              `Your graphic will render below. Here are a few options
              to give it some extra pizzaz.`
            }
          </Typography>
          {this.getOverlayEffects()}
          {this.renderFailureDialog()}
          <RenderPreview
            CanvasUtil={this.props.CanvasUtil}
            duration={duration}
            isVideo={this.props.isVideo}
            hasEffect={this.props.hasEffect}
            forceVideo={false}
            backgroundEffect={backgroundEffect}
            overlayEffect={overlayEffect}
            setStepState={this.handleStateChange}
            setShareState={this.props.parentStateUpdate}
            rendering={rendering}
            renderFailed={renderFailed}
            width={this.props.width}
            height={this.props.height}
            image={image.png}
            jobId={jobId}
          />
          <OtherOptions
            video={video}
            image={image.png}
            mode={jobId ? 'video' : 'image'}
            disabled={rendering}
          />
          <StepNavigator
            onNext={this.props.onNext}
            onPrev={this.props.onPrev}
            hidePrev={this.props.first}
            disableNext={rendering || renderFailed}
          />
        </>
      );
    }
    return (
      <>
        <Typography>
          {
            this.props.hasEffect
              ? `Since you're using a motion effect, we render your image as a video.
            Aren't we nice?`
              : `Your graphic is ready to go as an image, but we could
            potentially add some spice to it and turn it, alchemy-like,
            into a video. Up to you, we won't judge.`
          }
        </Typography>
        {
          this.props.hasEffect
            ? null
            : (
              <FormGroup>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={this.state.forceVideo}
                      onChange={this.handleCheckEvent('forceVideo')}
                      value="forceVideo"
                    />
              )}
                  label="Render Image as Video"
                />
              </FormGroup>
            )
        }
        {this.state.forceVideo || this.props.hasEffect
          ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                className={classes.break}
                id="duration"
                label="Duration (seconds)"
                type="number"
                value={this.state.duration}
                onChange={this.handleStateChangeEvent('duration')}
                margin="none"
              />
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="bgeffects">Background Effects</InputLabel>
                <Select
                  value={this.state.backgroundEffect}
                  onChange={this.handleStateChangeEvent('backgroundEffect')}
                  inputProps={{
                    name: 'bgeffects',
                    id: 'bgeffects',
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="zoomPanLeft">Zoom In (Left)</MenuItem>
                  <MenuItem value="zoomPanRight">Zoom In (Right)</MenuItem>
                  <MenuItem value="zoomPanCenter">Zoom In (Center)</MenuItem>
                </Select>
              </FormControl>
              {this.getOverlayEffects()}
              {this.renderFailureDialog()}
            </div>
          )
          : null}
        <RenderPreview
          CanvasUtil={this.props.CanvasUtil}
          duration={duration}
          isVideo={this.props.isVideo}
          hasEffect={this.props.hasEffect}
          forceVideo={this.state.forceVideo}
          backgroundEffect={backgroundEffect}
          overlayEffect={overlayEffect}
          setStepState={this.handleStateChange}
          setShareState={this.props.parentStateUpdate}
          rendering={rendering}
          renderFailed={renderFailed}
          width={this.props.width}
          height={this.props.height}
          image={image.png}
          jobId={jobId}
        />
        <OtherOptions
          video={video}
          image={image.png}
          disabled={rendering}
          mode={jobId ? 'video' : 'image'}
        />
        <StepNavigator
          onNext={this.props.onNext}
          onPrev={this.props.onPrev}
          hidePrev={this.props.first}
          disableNext={rendering || renderFailed}
        />
        {this.renderFailureDialog()}
      </>
    );
  }

  renderNewStandard() {
    const {
      video, image, rendering, progress, data, method
    } = this.props;
    const { first, onNext, onPrev } = this.props;
    const { phone } = this.state;
    const { hasEffect, isVideo } = this.props;
    const video_enabled = (hasEffect || isVideo);

    if (!(data && data.me && data.me.role)) {
      return (
        <Typography variant="h6" gutterBottom>
          Loading...
        </Typography>
      );
    }
    const user_permissions = new UserPermissions(data.me.role);
    let twitter_ids = []; let
      facebook_ids = [];

    if (user_permissions.get('social_posting:read')) {
      twitter_ids = user_permissions.get('social_posting:twitter_accounts', []);
      facebook_ids = user_permissions.get('social_posting:facebook_accounts', []);
    } else {
      if (data.me.client.twitterAccounts) {
        twitter_ids = data.me.client.twitterAccounts.map((i) => i.id);
      }
      if (data.me.client.fbPages) {
        facebook_ids = data.me.client.fbPages.map((i) => i.id);
      }
    }
    if (facebook_ids.length > 0 || twitter_ids.length > 0) {
      return [
        <div style={{ display: 'flex' }}>
          <GraphicPreview
            align="left"
            hideMode={!video_enabled}
            mode={video && video_enabled ? 'video' : 'image'}
            video={video}
            image={image.png}
            method={method}
            templateId={this.props.match.params.id}
            rendering={rendering}
            progress={progress}
            maxHeight="calc(100vh - 304px)"
            onPhoneClick={this.openPhoneDialog}
            key="graphic-preview"
          />
          <div style={{ flex: 1 }} />
        </div>,
        <StepNavigator
          onNext={onNext}
          onPrev={onPrev}
          hidePrev={first}
          disableNext={rendering}
          key="step-navigator"
        />,
        <SendToPhoneDialog
          open={phone}
          video={video}
          image={image.png}
          onCancel={this.closePhoneDialog}
          key="send-to-phone"
          mode={this.state.phone_mode}
        />,
      ];
    }
    return [
      <div style={{ display: 'flex' }}>
        <GraphicPreview
          align="left"
          disableMode={!video_enabled}
          hideMode={!video_enabled}
          mode={video && video_enabled ? 'video' : 'image'}
          video={video}
          method={method}
          templateId={this.props.match.params.id}
          image={image.png}
          rendering={rendering}
          progress={progress}
          maxHeight="calc(100vh - 304px)"
          onPhoneClick={this.openPhoneDialog}
          key="graphic-preview"
        />
        <div style={{ flex: 1 }} />
      </div>,
      <SendToPhoneDialog
        open={phone}
        video={video}
        image={image.png}
        onCancel={this.closePhoneDialog}
        key="send-to-phone"
        mode={this.state.phone_mode}
      />,
    ];
  }

  renderQuickCreate() {
    const {
      video, image, rendering, progress, data, hasEffect, isVideo, first, onNext, onPrev
    } = this.props;

    const { phone } = this.state;
    const video_enabled = (hasEffect || isVideo);

    if (!(data && data.me && data.me.role)) {
      return (
        <Typography variant="h6" gutterBottom>
            Loading...
        </Typography>
      );
    }

    const user_permissions = new UserPermissions(data.me.role);
    let twitter_ids = []; let
      facebook_ids = [];

    if (user_permissions.get('social_posting:read')) {
      twitter_ids = user_permissions.get('social_posting:twitter_accounts', []);
      facebook_ids = user_permissions.get('social_posting:facebook_accounts', []);
    } else {
      if (data.me.client.twitterAccounts) {
        twitter_ids = data.me.client.twitterAccounts.map((i) => i.id);
      }
      if (data.me.client.fbPages) {
        facebook_ids = data.me.client.fbPages.map((i) => i.id);
      }
    }

    if (facebook_ids.length > 0 || twitter_ids.length > 0) {
      return [
        <div style={{ display: 'flex' }}>
          <GraphicPreview
            align="left"
            hideMode={!video_enabled}
            mode={video && video_enabled ? 'video' : 'image'}
            video={video}
            image={image.png}
            rendering={rendering}
            progress={progress}
            quickCreate
            json={this.props.json}
            templateId={this.props.match.params.id}
            maxHeight="calc(100vh - 304px)"
            onPhoneClick={this.openPhoneDialog}
            selectDownload={this.selectDownload}
            template={this.state.templateDownload}
            key="graphic-preview"
          />
          <div style={{ flex: 1 }} />
        </div>,
        <StepNavigator
          onNext={onNext}
          onPrev={onPrev}
          hidePrev={first}
          disableNext={rendering}
          key="step-navigator"
        />,
        <SendToPhoneDialog
            open={phone}
            video={video}
            image={image.png}
            onCancel={this.closePhoneDialog}
            key="send-to-phone"
            quickCreate={this.props.quickCreate}
            template={this.state.templateDownload}
            json={this.props.json}
            mode={this.state.phone_mode}
        />
      ];
    }
    return [
      <div style={{ display: 'flex' }}>
        <GraphicPreview
          align="left"
          disableMode={!video_enabled}
          hideMode={!video_enabled}
          mode={video && video_enabled ? 'video' : 'image'}
          video={video}
          image={image.png}
          rendering={rendering}
          json={this.props.json}
          templateId={this.props.match.params.id}
          quickCreate
          progress={progress}
          maxHeight="calc(100vh - 304px)"
          selectDownload={this.selectDownload}
          template={this.state.templateDownload}
          onPhoneClick={this.openPhoneDialog}
          key="graphic-preview"
        />
        <div style={{ flex: 1 }} />
      </div>,
      <div>
        <FormControl variant="filled">
          <InputLabel id="demo-simple-select-filled-label">Age</InputLabel>
          <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
          </Select>
        </FormControl>
      </div>,
      <SendToPhoneDialog
          open={phone}
          video={video}
          image={image.png}
          onCancel={this.closePhoneDialog}
          key="send-to-phone"
          mode={this.state.phone_mode}
          quickCreate={this.props.quickCreate}
          template={this.state.templateDownload}
          json={this.props.json}
      />,
    ];
  }

  renderSlideshow() {
    const {
      video, rendering, progress, data,
    } = this.props;
    const { first, onNext, onPrev } = this.props;
    const { phone } = this.state;

    if (!(data && data.me && data.me.role)) {
      return (
        <Typography variant="h6" gutterBottom>
          Loading...
        </Typography>
      );
    }
    const user_permissions = new UserPermissions(data.me.role);
    let twitter_ids = []; let
      facebook_ids = [];

    if (user_permissions.get('social_posting:read')) {
      twitter_ids = user_permissions.get('social_posting:twitter_accounts', []);
      facebook_ids = user_permissions.get('social_posting:facebook_accounts', []);
    } else {
      if (data.me.client.twitterAccounts) {
        twitter_ids = data.me.client.twitterAccounts.map((i) => i.id);
      }
      if (data.me.client.fbPages) {
        facebook_ids = data.me.client.fbPages.map((i) => i.id);
      }
    }

    if (facebook_ids.length > 0 || twitter_ids.length > 0) {
      return (
        <>
          <div style={{ display: 'flex' }}>
            <GraphicPreview
              slideshow
              disableMode
              align="left"
              mode="video"
              video={video}
              rendering={rendering}
              progress={progress}
              maxHeight="calc(100vh - 304px)"
              onPhoneClick={this.openPhoneDialog}
              key="graphic-preview"
            />
            <div style={{ flex: 1 }} />
          </div>
          <StepNavigator
            onNext={onNext}
            onPrev={onPrev}
            hidePrev={first}
            disableNext={rendering}
            key="step-navigator"
          />
          <SendToPhoneDialog
            open={phone}
            video={video}
            onCancel={this.closePhoneDialog}
            key="send-to-phone"
            mode="video"
          />
        </>
      );
    }
    return (
      <>
        <div style={{ display: 'flex' }}>
          <GraphicPreview
            slideshow
            disableMode
            align="left"
            mode="video"
            video={video}
            rendering={rendering}
            progress={progress}
            maxHeight="calc(100vh - 304px)"
            onPhoneClick={this.openPhoneDialog}
            key="graphic-preview"
          />
          <div style={{ flex: 1 }} />
        </div>
        <SendToPhoneDialog
          open={phone}
          video={video}
          onCancel={this.closePhoneDialog}
          key="send-to-phone"
          mode="video"
        />
      </>
    );
  }

  render() {
    const { slideshow, quickCreate } = this.props;

    return (
      quickCreate ? this.renderQuickCreate()
        : slideshow
          ? this.renderSlideshow()
          : this.renderNewStandard()
    );
  }
}

// -------------------------------------------------------------------------- //

export default compose(
  graphql(SAVE_DRAFT, { name: 'saveDraft' }),
  withStyles(style),
  withRouter,
  graphql(ROLES),
)(AddExtrasPreview);

// -------------------------------------------------------------------------- //
