
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
  withStyles,
  Collapse,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from "@material-ui/core";

import {
  DotsVertical as DotsVerticalIcon, ChevronDown,
} from 'mdi-material-ui';

import { compose, graphql } from 'react-apollo';
import { format, formatRelative, differenceInCalendarDays } from 'date-fns';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import gql from 'graphql-tag';
  
import Facebook from '../../share/Facebook';

// -------------------------------------------------------------------------- //

const GQL_UNSCHEDULE_POST = gql`
  mutation unschedulePost(
    $postId: ID!
  ) {

    unschedulePost (
      postId: $postId
    ) {
      id
    }

    updatePost(
      data : {scheduled:false}
      where:{id:$postId}
    )
    {
      id
    }



  }
`;

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  button: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    }),
  },
  button_expand: {
    transform: 'rotate(180deg)',
  },
  img: {
    display: 'block',
    width: '100%',
  },
  list_item: {
    alignItems: 'flex-start',
  },
  paper: {
    overflow: 'hidden',
    marginBottom: theme.spacing(1),
    position: 'relative',
  },
  actions: {
    display: 'flex',
    paddingLeft: theme.spacing(2),
  },
  expiration: {
    flexGrow: 1,
    lineHeight: '48px',
  },
  details: {
    padding: theme.spacing(1),
  },
});

// -------------------------------------------------------------------------- //

function myFormatRelative(lhs, rhs) {
  const diff = differenceInCalendarDays(lhs, rhs);

  if (Math.abs(diff) > 6) {
    return `${format(lhs, 'MM/dd')} at ${format(lhs, 'h:mm b')}`;
  } else {
    let [ first, ...rest ] = formatRelative(lhs, rhs);
    return [ first.toLocaleUpperCase(navigator.language), ...rest ].join('');
  }
}

// -------------------------------------------------------------------------- //

class ScheduledPost_ extends React.Component {

  state = {
    anchor_el: null,
    unschedule_dialog_open: false,
    unschedule_dialog_post: null,
    details: false,
  }

  render() {
    const { post, classes } = this.props;
    const { anchor_el } = this.state;

    const future = new Date();
    future.setDate(future.getDate() + 30);

    if (post.job) {
      return (
        <Paper
          className={classes.paper}
        >
          {post.job.media.mp4 ?
            <video
              autoPlay
              muted
              src={post.job.media.mp4}
              className={classes.img}
            /> :
            <img
              className={classes.img}
              src={post.job.media.png}
              alt="ScoreShots Draft"
            />
          }
          <div className={classes.actions}>
            <Typography variant="caption" className={classes.expiration} noWrap>
              {myFormatRelative(Date.parse(post.postTime), new Date())}
            </Typography>
            <IconButton
              className={classNames(
                classes.button,
                { [classes.button_expand]: this.state.details },
              )}
              onClick={() => this.setState({ details: !this.state.details })}
            >
              <ChevronDown/>
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="More"
              onClick={(e) => this.handleClick(e.currentTarget)}
            >
              <DotsVerticalIcon/>
            </IconButton>
            <Menu
              anchorEl={anchor_el}
              open={Boolean(anchor_el)}
              onClose={this.handleClose}
            >
              <MenuItem onClick={() => this.openUnscheduleDialog(post)}>
                Unschedule
              </MenuItem>
            </Menu>
          </div>
          <Collapse in={this.state.details}>
            <div className={classes.details}>
              <Typography variant="h6">
                Scheduled By:
              </Typography>
              <Typography variant="body1">
                {post.user.firstname} {post.user.lastname}
              </Typography>
              <Typography variant="h6">
                Scheduled To:
              </Typography>
              <List disablePadding dense>
              {post.facebookPosts.map((facebook, index) => (
                <ListItem key={index} className={classes.list_item}>
                  <ListItemAvatar>
                    <Avatar src={`https://graph.facebook.com/v2.12/${facebook.page.fbId}/picture?height=40`}/>
                  </ListItemAvatar>
                  <ListItemText
                    primary={facebook.page.name}
                    secondary={facebook.message || post.message}
                  />
                </ListItem>
              ))}
              {
                (post.job.twitterAccount) &&
                <ListItem className={classes.list_item}>
                  <ListItemAvatar>
                    <Avatar src={`https://twitter-avatar.now.sh/${post.job.twitterAccount.screen_name}`}/>
                  </ListItemAvatar>
                  <ListItemText
                    primary={post.job.twitterAccount.name}
                    secondary={post.job.twitterAccount.message || post.job.message}
                  />
                </ListItem>
              }
              {
                (post.job.twitterRetweet) &&
                post.job.twitterRetweet.map((tweet, index) => (
                <ListItem key={index} className={classes.list_item}>
                  <ListItemAvatar>
                    <Avatar src={`https://twitter-avatar.now.sh/${tweet.screen_name}`}/>
                  </ListItemAvatar>
                  <ListItemText
                    primary={tweet.name}
                    secondary={tweet.message || post.job.message}
                  />
                </ListItem>
              ))}
              </List>
            </div>
          </Collapse>
          {this.renderUnscheduleDialog()}
        </Paper>
      );
    }

    return null;
  }

  async unscheduleTweetAPI(post, endpoint = "prod") {
    let endpoint_tweet_data = { body: { post: post } };
    const backend_endpoint = `${process.env.REACT_APP_SS_API_ENDPOINT}v1/__Twitter_Scheduling__c/unschedule/${endpoint}/`;

    fetch(backend_endpoint, {
      method: 'POST',
      body: JSON.stringify(endpoint_tweet_data),
      mode: 'cors'
    }).then(response => response.json());
  }

  handleClick = (anchor) => {
    this.setState({ anchor_el: anchor });
  };

  handleClose = () => {
    this.setState({ anchor_el: null });
  };

  unschedulePost = unschedule => () => {
    this.unscheduleTweetAPI(this.props.post.id);

    this.props.post.facebookPosts.forEach(post => {
      const id = post.postId;
      const token = post.page.accessToken;
      Facebook.unschedulePost(token, id);
    });
  
    unschedule({
      variables: {
        postId: this.props.post.id,
      },
      refetchQueries: [
        'getScheduled',
        'getDrafts',
      ],
    }).then((response) => {
      this.props.history.push(`/editor/post/${response.data.updatePost.id}/0`);
    });
  }

  openUnscheduleDialog = (self) => {
    this.setState({
      unschedule_dialog_open: true,
      anchor_el: null,
      unschedule_dialog_post: self,
    });
  }

  renderUnscheduleDialog = () => {
    const { unschedule_dialog_open: open } = this.state;

    return (
      <Dialog open={open} onClose={() => this.closeUnscheduleDialog(this, false)}>
        <DialogTitle>
          Unschedule Post?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to unschedule this post?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => this.closeUnscheduleDialog(this, false)}>
            Cancel
          </Button>
          <Button color="primary" onClick={() => this.closeUnscheduleDialog(this, true)}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  closeUnscheduleDialog = (self, doWork) => {
    if (doWork) {
      const { post } = this.props;
      this.unscheduleTweetAPI(post.id);

      post.facebookPosts.map(fb => {
        let facebookPost = fb.postId;
        let facebookPageToken = fb.page.accessToken;
        Facebook.unschedulePost(facebookPageToken, facebookPost);
        return fb;
      });

      self.props.unschedule({
        variables: { postId: post.id },
      }).then(() => (
        fetch(`https://ss3-posts.s3.us-east-2.amazonaws.com/${post.client.id}/${post.id}.json`)
      )).then(response => response.json())
      .then(json => {
        let { history } = this.props;

        if (json && json.data && json.data.length > 0) {
          history.push(`/editor/slideshow/post/${post.id}/0`);
        } else {
          history.push(`/editor/post/${post.id}/0`);
        }
      }).then(() => {
        self.setState({
          roster_delete_open: false,
          unschedule_dialog_post: null,
        });
      });

      self.setState({
        unschedule_dialog_open: false,
        unschedule_dialog_post: null,
      });
    } else {
      self.setState({
        unschedule_dialog_open: false,
        unschedule_dialog_post: null,
      });
    }
  }

}

// -------------------------------------------------------------------------- //

export const ScheduledPost = compose(
  graphql(GQL_UNSCHEDULE_POST, { name: 'unschedule' }),
  withRouter,
  withStyles(STYLES),
)(ScheduledPost_);

// -------------------------------------------------------------------------- //
