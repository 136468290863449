
export default {
  'i-text': true,
  'circle': true,
  'barGraph': true,
  'SliderBar': true,
  'ParText': true,
  'logoBox': true,
  'cutoutBox': true,
  'backgroundBox': true,
};
