import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Subscribe } from 'unstated';
import { PageTitle } from '../../title';
import ResponsiveGrid from '../layout/ResponsiveGrid';
import SidebarGrid from '../layout/SidebarGrid';
import TeamNav from './TeamNav';
import MessageContainer from '../../unstated/MessageContainer';
import SettingGeneral from './SettingGeneral';
import SettingSocial from './SettingSocial';
import SettingPalette from './SettingPalette';
import SettingFonts from './SettingFonts';
import SettingUsers from './SettingUsers';
//import PhoneBook from './PhoneBook';
import SettingRoles from './SettingRoles';
const styles = theme => ({
  grid: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  spacer: {
    paddingTop: theme.spacing(8),
  },
  superSpacer: {
    paddingTop: theme.spacing(5),
    marginTop: -theme.spacing(8),
  },
});
/*
<div id="phonebook" className={classes.spacer}>
              <PhoneBook />
            </div>
 */
const Team = (props) => {
  const { classes } = props;
  // window.scrollTo(0, 0);
  return (
    <React.Fragment>
      <PageTitle title="Team"/>
      <Subscribe to={[MessageContainer]}>
      {messages => (
        <ResponsiveGrid className={classes.grid}>
          <SidebarGrid
            sidebar={(
              <TeamNav />
            )}
          >
            <div id="general" className={classes.superSpacer}>
              <SettingGeneral setMessage={messages.setMessage} />
            </div>
            <SettingSocial />
            <SettingPalette />
            <div id="fonts" className={classes.spacer}>
              <SettingFonts setMessage={messages.setMessage} />
            </div>
            <div id="accounts" className={classes.spacer}>
              <SettingUsers setMessage={messages.setMessage} />
            </div>
            <div id="roles" className={classes.spacer}>
              <SettingRoles setMessage={messages.setMessage}/>
            </div>
          </SidebarGrid>
        </ResponsiveGrid>
      )}
    </Subscribe>
    </React.Fragment>

  );
};

export default withStyles(styles)(Team);
