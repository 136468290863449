
// -------------------------------------------------------------------------- //

import parseColor from 'parse-color';

// -------------------------------------------------------------------------- //

export const ParseColor = (color) => {
  let parsed = parseColor(color);

  if (!parsed.rgba) {
    parsed = {
      rgb: [ 0, 0, 0 ],
      hsl: [ 0, 0, 0 ],
      hsv: [ 0, 0, 0 ],
      cmyk: [ 0, 0, 0, 0 ],
      keyword: 'transparent',
      hex: '#00000000',
      rgba: [ 0, 0, 0, 0 ],
      hsla: [ 0, 0, 0, 0 ],
      hsva: [ 0, 0, 0, 0 ],
      cmyka: [ 0, 0, 0, 0, 0 ],
    };
  }

  return parsed;
}

// -------------------------------------------------------------------------- //
