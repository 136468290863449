import React from 'react';
import Clock from 'mdi-material-ui/Clock';
import CalendarRange from 'mdi-material-ui/CalendarRange';
import ChevronLeft from 'mdi-material-ui/ChevronLeft';
import Checkbox from '@material-ui/core/Checkbox';
import ChevronRight from 'mdi-material-ui/ChevronRight';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import StepNavigator from './StepNavigator';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';

export default props => {

  return (
    <React.Fragment>
      <FormControlLabel
        style={{ display: 'flex' }}
        control={
          <Checkbox
            checked={props.postLater}
            onChange={props.toggleState('postLater')}
            value="postLater"
            color="primary"
          />
        }
        label="Post this at a later date and time."
      />
      {props.postLater ?
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateTimePicker
          value={props.selectedDate}
          onChange={props.handleDateChange}
          dateRangeIcon={<CalendarRange />}
          timeIcon={<Clock />}
          leftArrowIcon={<ChevronLeft />}
          rightArrowIcon={<ChevronRight />}
        />
      </MuiPickersUtilsProvider> :
        null
      }
      <StepNavigator
        onNext={props.onNext}
        onPrev={props.onPrev}
      />
    </React.Fragment>
  );
};
