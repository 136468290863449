import React from 'react';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';

// the transform prop here is to fix a known chrome issue
// see mui-org/material-ui/commit/9b9421c for details
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      transform: 'translate3D(0,0,0)',
      width: 250,
    },
  },
};

class SelectMultiple extends React.Component {

  handleSelectButton = (options, values) => () => {
    if (values.length > 0) {
      values.length = 0;
    } else {
      values.length = 0;
      options.forEach((opt) => {
        values.push(opt.value);
      });
    }

    this.setState({});
  }

  render() {
    const { fullWidth = true, showSelectButton = false, id, label, options, ...rest } = this.props;
    let { input: { onChange, value } } = this.props;
    const buttonHandler = this.handleSelectButton(options, value);
    const optDict = {};

    options.forEach((val) => {
      optDict[val.value] = val.name;
    });

    if (!value.length) {
      value = [];
    }

    return (
      <FormControl fullWidth={fullWidth} {...rest}>
        <InputLabel htmlFor={id}>{label}</InputLabel>
        <Select
          multiple
          value={value}
          onChange={onChange}
          MenuProps={MenuProps}
          renderValue={selected => (
            selected.map(select => (optDict[select])).join(', ')
          )}
        >
          {
            options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox checked={value.indexOf(option.value) > -1} color="primary" />
                <ListItemText primary={option.name} />
              </MenuItem>
            ))
          }
        </Select>
        {
          showSelectButton ?
          <Button color="primary" style={{marginTop:10}} onClick={buttonHandler}>
            {value.length > 0 ? 'Select None' : 'Select All' }
          </Button>
          : null
        }
      </FormControl>
    );
  };

}

export default SelectMultiple;
