import React, { Component } from 'react';

class ResizedImage extends Component {
	constructor() {
		super();

		this.state = {
			image: null
		};
	}

	setImage = (url, width, height) => {
			let image = new Image();

			image.onerror = () => {
				image.setAttribute('src', `${url}?1`);
				this.setState({
					image
				});

				image.onerror = () => {};
			}

			image.onload = () => {
				this.setState({
					image
				});
			}

			image.setAttribute('crossorigin', 'anonymous');

		if (width && height) {
			image.setAttribute('src', `${url}`);
		} else if (width || height) {
			if (width) {
				image.setAttribute('src', `${url}`);
			} else if (height) {
				image.setAttribute('src', `${url}`)
			}
		} else {
			image.setAttribute('src', `${url}`);
		}
		image.setAttribute('src', `${url}`);

	}

	componentDidMount() {
		let { width, height, src } = this.props;
		this.setImage(src, width, height);
	}

	render() {
		if (!this.state.image) {
			return null;
		} else {
			return <img alt="Resized Graphic" {...this.props} src={this.state.image.src} />
		}
	}
}

export default ResizedImage;
