
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  CircularProgress,
  withTheme,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import { Query } from 'react-apollo';
import Columns from 'react-columns';
import gql from 'graphql-tag';
import InfiniteScroll from 'react-infinite-scroller';
import { addDays, subDays } from 'date-fns';
import { Draft } from './Draft';
import { DraftHint } from './DraftHint';

import ScoreshotsPut from '../../../scoreshotsapi/Put';
// -------------------------------------------------------------------------- //

const GET_DRAFTS = gql`
  query get_drafts (
    $where: DraftWhereInput
    $orderBy: DraftOrderByInput
    $first: Int
    $skip: Int
  ) {
    draftSearch (
      where: $where
      orderBy: $orderBy
      first: $first
      skip: $skip
    ) {
      id
      bucket
      height
      width
      updatedAt
      template {
        id
        isQuickCreate
      }
    }
  }
`;

const GET_DRAFT_INFO = gql`
  query draft($id: ID!){
    draft(
      id: $id
    ) {
      id
      width
      height
      bucket
      template {
        id
        disableXMLImport
        categories {
          id
          name
        }
        types {
          id
          name
        }
        types {
          name
        }
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const QUERIES = [{
  columns: 1,
  query: 'min-width: 450px',
}, {
  columns: 2,
  query: 'min-width: 600px',
}, {
  columns: 3,
  query: 'min-width: 900px',
}, {
  columns: 4,
  query: 'min-width: 1200px',
}];

// -------------------------------------------------------------------------- //

const DraftsList_ = (props) => (
    <>
      <DraftHint />
      <Query
          query={GET_DRAFTS}
          fetchPolicy="cache-and-network"
          variables={{
            where: {
              user: { id: localStorage.getItem('user_id') },
              updatedAt_gte: subDays(new Date(), 45)
            },
            orderBy: 'updatedAt_DESC',
            first: 15,
          }}
      >
        {({ data, fetchMore }) => (
            <InfiniteScroll
                pageStart={0}
                loadMore={() => fetchMore({
                  variables: {
                    first: 10,
                    skip: (data && data.draftSearch ? data.draftSearch.length : 0),
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) {
                      return prev;
                    }

                    return {
                      ...prev,
                      draftSearch: [
                        ...prev.draftSearch,
                        ...fetchMoreResult.draftSearch,
                      ],
                    };
                  },
                })}
                loader={() => <CircularProgress key="draft-loading" size={48} />}
                initialLoad={false}
                useWindow
                hasMore
            >
              {
                (data && data.draftSearch)
                && (
                    <>
                      <RenderExpiringDraftsDialog draftArray={data.draftSearch} />
                      <Columns
                          dimensions={
                            data.draftSearch.map((draft) => ({
                              width: draft.width,
                              height: draft.height,
                            }))
                          }
                          gap={`${props.theme.spacing(1)}px`}
                          queries={QUERIES}
                      >
                        {
                          data.draftSearch.map((draft) => (
                              <Query
                                  query={GET_DRAFT_INFO}
                                  fetchPolicy="cache-and-network"
                                  variables={{
                                    id: draft.id,
                                  }}
                              >
                                {(Data) => (
                                    <Draft
                                        key={draft.id}
                                        ascension={convertToCustom}
                                        draft={draft}
                                        draftData={Data}
                                    />
                                )}
                              </Query>
                          ))
                        }
                      </Columns>
                    </>
                )
              }
            </InfiniteScroll>
        )}
      </Query>
    </>
);


const convertToCustom = (draft, mutation, name, callback, category, types) => {
  const client_id = localStorage.getItem('client_id');

  let pngBlob; let id;
  if (name === '') {
    name = 'New Template';
  }
  fetch(`https://${draft.bucket}.s3.us-east-2.amazonaws.com/${client_id}/${draft.id}.png?${+new Date().getTime()}`)
      .then((resp) => resp.blob())
      .then((blob) => {
        pngBlob = blob;
        return new Promise((resolve) => {
          const png = new Image();
          png.onload = () => {
            resolve({
              width: png.width,
              height: png.height,
            });
          };

          png.src = `https://${draft.bucket}.s3.us-east-2.amazonaws.com/${client_id}/${draft.id}.png?${+new Date().getTime()}`;
        });
      })
      .then((data) => mutation({
        variables: {
          name,
          folderId: null,
          width: data.width,
          height: data.height,
          categoryIds: category,
          typeIds: types,
          isQuickCreate: draft.template.isQuickCreate,
        },
      }))
      .then((response) => {
        id = response.data.createCustomTemplate.id;

        return fetch(`https://${draft.bucket}.s3.us-east-2.amazonaws.com/${client_id}/${draft.id}.json?${+new Date().getTime()}`);
      })
      .then((resp) => resp.json())
      .then((json) => sendTemplateToS3(id, json, pngBlob))
      .then(() => {
        callback();
      })
      .catch((err) => console.log(err));
};

const sendTemplateToS3 = async (id, json, blob) => {
  const bucket = 'ss3-clientmedia';
  const png_key = `templates/${id}.png`;

  return (
      new ScoreshotsPut(bucket, png_key).put(blob, 'image/png')
          .then(() => {
            const json_key = `templates/${id}.json`;
            const json_blob = new Blob([JSON.stringify(json)], { type: 'application/json' });
            return new ScoreshotsPut(bucket, json_key).put(json_blob, 'application/json');
          })
          .then(() => id) // pass on id to next thenable
  );
};

class RenderExpiringDraftsDialog extends React.Component {
  state = {
    showDialog: false,
    alreadyShowedDialog: false,
    shouldDialogBeVisible: false,
  }

  closeDialog() {
    this.setState({ alreadyShowedDialog: true, shouldDialogBeVisible: false });
  }

  componentDidMount() {
    let a = subDays(new Date(), 45);
    console.log(a)
  }

  render() {
    const { draftArray } = this.props;
    const expireDateArray = [];

    if (this.state.alreadyShowedDialog === false && this.state.showDialog === false) {
      if (draftArray.length > 0) {
        draftArray.map((draft) => {
          expireDateArray.push(draft.updatedAt);
          return draft;
        });

        expireDateArray.forEach((date) => {
          const minimumDate = addDays(new Date(), 45);
          const draftDate = addDays(new Date(date), 45);

          if (draftDate.getTime() <= minimumDate.getTime()) {
            this.setState({ showDialog: true });
          }
        });
      }
    }
    if (this.state.alreadyShowedDialog === false && this.state.showDialog === true && this.state.shouldDialogBeVisible !== true) {
      this.setState({ shouldDialogBeVisible: true });
    }

    const dialog = (
        <Dialog open={this.state.shouldDialogBeVisible} onClose={() => this.closeDialog()}>
          <DialogTitle>
            You have draft(s) that will expire soon
          </DialogTitle>
          <DialogContent>
            <DialogContentText>You must move them to your Custom Templates folder within 45 days of creation if you wish to keep them. Just click on the three dots and select "Convert to Custom." </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => this.closeDialog()}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
    );
    return dialog;
  }
}
// -------------------------------------------------------------------------- //

export const DraftsList = withTheme(DraftsList_);
