import React, { Component } from 'react';
import classNames from 'classnames';
import { isMobileOnly } from 'react-device-detect';

import {
  withStyles,
} from '@material-ui/core';

const APPBAR_HEIGHT = 64;
const TIMELINE_HEIGHT_COZY = 136;
const TIMELINE_HEIGHT_COMPACT = 64;
const TOOLBAR_WIDTH = (isMobileOnly ? 0 : 80);
const SIDEBAR_WIDTH = (isMobileOnly ? 0 : 280);

const styles = theme => ({
  root: {
    zIndex: -11,
    backgroundImage: "url('https://ss3-assets.s3.us-east-2.amazonaws.com/grid.png')",
    backgroundRepeat: 'repeat',
    position: 'absolute',
    right: 0,
  },
  closed: {
    left: (TOOLBAR_WIDTH + 1),
  },
  opened: {
    left: (TOOLBAR_WIDTH + SIDEBAR_WIDTH),
  },
  standard: {
    height: `calc(100vh - ${APPBAR_HEIGHT}px)`,
    top: APPBAR_HEIGHT,
  },
  cozy: {
    height: `calc(100vh - ${APPBAR_HEIGHT + TIMELINE_HEIGHT_COZY}px)`,
    // top: (APPBAR_HEIGHT + TIMELINE_HEIGHT_COZY),
  },
  compact: {
    height: `calc(100vh - ${APPBAR_HEIGHT + TIMELINE_HEIGHT_COMPACT}px)`,
    // top: (APPBAR_HEIGHT + TIMELINE_HEIGHT_COMPACT),
  },
});

class Canvas extends Component {

  drop = self => (ev) => {
    self.dragText.setCoords();
    ev.preventDefault();
  }

  dragOver = (ev) => {
    if (!this.blockDragOver) {
      this.blockDragOver = true;
      ev.preventDefault();
      // Set the dropEffect to move
      // ev.dataTransfer.dropEffect = 'move';
      const { canvas } = this.props.CanvasUtil;
      const horizOffset = canvas.scaledHorizOffset + (this.dragText.width / 2);
      const vertOffset = canvas.scaledVertOffset + (this.dragText.height / 2);
      this.dragText.set({
        left: ((ev.clientX - 360) / canvas.baseZoom) - horizOffset,
        top: ((ev.clientY - 64) / canvas.baseZoom) - vertOffset,
      });
      this.dragText.canvas.requestRenderAll(true);
      setTimeout(() => { this.blockDragOver = false; }, 100);
    }
  }

  dragEnter = self => (ev) => {
    const { CanvasUtil, dragging } = this.props;
    const { fabric, canvas } = CanvasUtil;
    const family = dragging;

    self.dragText = new fabric.IText('Sample Text', {
      fontFamily: family,
    });

    const horizOffset = canvas.scaledHorizOffset + (self.dragText.width / 2);
    const vertOffset = canvas.scaledVertOffset + (self.dragText.height / 2);

    self.dragText.set({
      left: ((ev.clientX - 360) / canvas.baseZoom) - horizOffset,
      top: ((ev.clientY - 64) / canvas.baseZoom) - vertOffset,
    });
    CanvasUtil.canvas.add(this.dragText);
  }

  dragLeave = self => () => {
    self.props.CanvasUtil.canvas.remove(self.dragText);
    self.props.CanvasUtil.canvas.requestRenderAll();
  }

  tabIsVisible = (funct) => {
    var stateKey, eventKey, keys = {
      hidden: "visibilitychange",
      webkitHidden: "webkitvisibilitychange",
      mozHidden: "mozvisibilitychange",
      msHidden: "msvisibilitychange"
    };

    for (stateKey in keys) {
      if (stateKey in document) {
        eventKey = keys[stateKey];
        break;
      }
    }

    if (funct) {
      document.addEventListener(eventKey, funct);
    }
  }

  addSSLogo = () => {
    let clientFlag = localStorage.getItem('clientFlags');
    let flag = clientFlag.indexOf('SS_LOGO') >= 0;
    let hasLogo = this.props.CanvasUtil.canvas.getObjects('ssLogo').length <= 0;

    if(flag && hasLogo){
      let logo = new this.props.CanvasUtil.fabric.SSLogo();
      this.props.CanvasUtil.canvas.add(logo).setActiveObject(logo);
      logo._render();
      logo.initialize();

    }

    if (!flag && !hasLogo) {
      // remove ss logo
      let ssLogo = this.props.CanvasUtil.canvas.getObjects('ssLogo');
      this.props.CanvasUtil.canvas.remove(ssLogo);
    }
  }

  componentDidMount() {
    let self = this;
    //this.addSSLogo();
    this.tabIsVisible(function(){
      if (self && self.props && self.props.CanvasUtil) {
        self.props.CanvasUtil.toDataURL({}, false, true);
      }
    });
  }

  render() {
    const { classes, open, variant } = this.props;

    return (
      <div
        className={classNames(
          classes.root,
          (open ? classes.opened : classes.closed),
          classes[variant]
        )}
        data-tour="canvas"
      >
        <canvas id="c" ref={ref => {this.drawn = ref}}/>
      </div>
    );
  }
}

export default withStyles(styles)(Canvas);
