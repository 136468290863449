
// -------------------------------------------------------------------------- //

import React from 'react';

import { Route, Switch } from 'react-router-dom';

import { BetaHome } from '../../views/BetaHome';
import PrestoDashboard from '../home/PrestoDashboard';
import Create from '../../views/Create';
import CreateEvent from '../../views/CreateEvent';
import CreateSchedule from '../../views/CreateSchedule';
import FbCallback from '../social/FbCallback';
import Main from '../create/Main';
import { Media } from '../media/Media';
import Team from '../team/Team';
import Resources from '../resources/Resources';
import TwitterCallback from '../social/TwitterCallback';
import User from '../../views/User';
import Legal from '../../views/Legal';
import Auth from "../auth/auth"
import { isMobileOnly } from 'react-device-detect';

const auth = new Auth();
// -------------------------------------------------------------------------- //

export const AppContainer = (props) => {
  const { me } = props;

  const prestoSite = localStorage.getItem('prestoSite');

  let dashboard = (
    <Route path="/" component={BetaHome}/>
  );

  if (prestoSite && prestoSite === 'true') {
    dashboard = (
      <Route path="/" component={PrestoDashboard}/>
    )
  }

  auth.isAuthenticated();

  return (
    <Switch>
      <Route path="/media/:type?/:directory?" component={Media}/>
      <Route exact path="/create/new/skip" component={Create}/>
      <Route exact path="/create/eventfilter" render={() => <CreateEvent me={me}/>}/>
      <Route exact path="/create/schedulefilter" render={() => <CreateSchedule me={me}/>}/>
      <Route exact path="/create/new" render={() => <Create me={me}/>}/>
      <Route path="/create/:type?" component={Main}/>
      <Route path="/team" component={Team}/>
      <Route path="/resources" component={Resources}/>
      <Route path="/fbcallback" component={FbCallback}/>
      <Route path="/twittercallback" component={TwitterCallback}/>
      <Route path="/user" component={User}/>
      <Route path="/legal" component={Legal}/>
      {
        isMobileOnly
        ? <Route path="/" render={() => <Create me={me}/>}/>
        : dashboard
      }
    </Switch>
  );
}

// -------------------------------------------------------------------------- //
