import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import {
  Button, Collapse, FormControl, InputLabel, MenuItem, Select,
} from '@material-ui/core';
import {
  CalendarRange as CalendarRangeIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Clock as ClockIcon,
  ArrowRightBoldCircle as ArrowRightBoldCircleIcon,
} from 'mdi-material-ui';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import history from '../../../history';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  {
    id: 'seasonName', numeric: false, disablePadding: false, label: 'Season',
  },
  {
    id: 'startDateTime', numeric: false, disablePadding: true, label: 'Start Date',
  },
  {
    id: 'endDateTime', numeric: false, disablePadding: true, label: 'End Date',
  },
  {
    id: 'sportName', numeric: false, disablePadding: false, label: 'Sport',
  },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      transform: 'translate3D(0,0,0)',
      width: 236,
      textAlign: 'left',
    },
  },
};

class EnhancedTableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    const { onRequestSort } = this.props;
    onRequestSort(event, property);
  };

  render() {
    const { order, orderBy } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" />
          {rows.map(
            (row) => (
              <TableCell
                key={row.id}
                align={row.numeric ? 'right' : 'left'}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this,
          )}
          <TableCell> </TableCell>
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = (props) => {
  const {
    numSelected, classes, toggleFilter, cancel,
  } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        <Button onClick={() => cancel()}>
          <ChevronLeftIcon />
          Cancel
        </Button>
      </div>
      <div className={classes.spacer}>
        <Typography variant="h5" id="tableTitle">
          Schedule List
        </Typography>
      </div>
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list" onClick={() => toggleFilter()}>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
    overflowY: 'auto',
    maxHeight: '100%',
    flex: 1,
    borderTop: '1px solid #E0E0E0',
  },
  clickable: {
    cursor: 'pointer',
  },
  tableImg: {
    height: '11px',
    width: 'auto',
  },
  pagination: {
    borderTop: '1px solid #E0E0E0',
    width: '100%',
  },
  inputSelect: {
    width: 200,
    textAlign: 'left',
  },
  filterBox: {
    textAlign: 'right',
    paddingRight: '20px',
    paddingBottom: '10px',
  },
  filter_control: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: theme.spacing(20),
  },
});

class EnhancedTable extends React.Component {
  constructor() {
    super();
    this.state = {
      order: 'desc',
      orderBy: 'startDateTime',
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: 50,
      filterOpen: false,
      sportsArray: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.getSportsArray();
    this.getData();
  }

  getData() {
    const { data } = this.props;
    this.setState({
      data,
      loading: false,
    });
  }

  getSportsArray() {
    const { orgData } = this.props;
    const sportsArray = [];
    if (orgData) {
      orgData.map((row) => {
        if (row && row.sportName && !sportsArray.includes(row.sportName)) {
          sportsArray.push(row.sportName);
        }
        return null;
      });
    }
    this.setState({
      sportsArray,
    });
  }

  handleOnFilterChange = (filterId, value) => {
    const { onFilterChange } = this.props;
    onFilterChange(filterId, value);
    this.setState({
      loading: true,
    });
  }


  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';
    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      this.setState((state) => ({ selected: state.data.map((n) => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id, n) => {
    const { onOpenTeamSelect } = this.props;
    onOpenTeamSelect(n);
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  toggleFilter = () => {
    const { filterOpen } = this.state;
    this.setState({ filterOpen: !filterOpen });
  }

  cancel = () => {
    history.push('/');
  }

  formatDate = (date) => {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return `${ye}-${mo}-${da}`;
  };

  render() {
    const { classes, filtersArray } = this.props;
    const {
      data,
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      filterOpen,
      sportsArray,
      loading,
    } = this.state;
    const sportValue = filtersArray.find((x) => x.id === 'sport').value;
    const startDate = filtersArray.find((x) => x.id === 'startDate').value;
    const endDate = filtersArray.find((x) => x.id === 'endDate').value;

    if (loading) {
      this.getSportsArray();
      this.getData();
    }

    let tableBody = (
      <TableBody>
        <TableRow>
          <TableCell colSpan={6} align="center">
            <Typography variant="h6" id="No results found">
              No results found. Please adjust your filters.
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    );
    if (data && data.length >= 1) {
      tableBody = (
        <TableBody>
          {
            stableSort(data, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((n) => {
                const isSelected = this.isSelected(n.seasonId);
                if (n) {
                  return (
                    <TableRow
                      hover
                      onClick={(event) => this.handleClick(event, n.seasonId, n)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.seasonId}
                      selected={isSelected}
                      className={classes.clickable}
                    >
                      <TableCell padding="checkbox" />
                      <TableCell align="left">{n.seasonName}</TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        {this.formatDate(n.startDateTime)}
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        {this.formatDate(n.endDateTime)}
                      </TableCell>
                      <TableCell align="left">{n.sportName}</TableCell>
                      <TableCell align="center">
                        <Tooltip title="Select This Season">
                          <IconButton aria-label="Select This Season" onClick={(event) => this.handleClick(event, n.seasonId, n)}>
                            <ArrowRightBoldCircleIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                }
                return null;
              })
          }
        </TableBody>
      );
    }
    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          toggleFilter={this.toggleFilter}
          cancel={this.cancel}
        />
        <Collapse
          appear
          in={filterOpen}
          className={classes.filterBox}
        >
          <FormControl className={classes.filter_control}>
            <InputLabel htmlFor="sport-input">Sport</InputLabel>
            <Select
              MenuProps={MenuProps}
              value={sportValue || 'none'}
              onChange={(e) => this.handleOnFilterChange('sport', e.target.value)}
              inputProps={{ id: 'sport-input' }}
              label="Sport"
              style={{ textAlign: 'left' }}
            >
              <MenuItem value="none"><em>All Sports</em></MenuItem>
              {
                sportsArray.map((sport) => (
                  <MenuItem key={sport} value={sport}>{sport}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              label="Start Date"
              className={classes.filter_control}
              value={startDate}
              onChange={(e) => this.handleOnFilterChange('startDate', e)}
              dateRangeIcon={<CalendarRangeIcon />}
              timeIcon={<ClockIcon />}
              leftArrowIcon={<ChevronLeftIcon />}
              rightArrowIcon={<ChevronRightIcon />}
            />
            <DatePicker
              label="End Date"
              className={classes.filter_control}
              value={endDate}
              onChange={(e) => this.handleOnFilterChange('endDate', e)}
              dateRangeIcon={<CalendarRangeIcon />}
              timeIcon={<ClockIcon />}
              leftArrowIcon={<ChevronLeftIcon />}
              rightArrowIcon={<ChevronRightIcon />}
            />
          </MuiPickersUtilsProvider>
        </Collapse>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data ? data.length : 0}
            />
            {tableBody}
          </Table>
        </div>
        <TablePagination
          className={classes.pagination}
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={data ? data.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnhancedTable);
