import { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router-dom';

const queryString = require('query-string');

const styles = theme => ({
  grid: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(4),
    margin: theme.spacing(2),
  },

});

const SET_TOKEN = gql`
  mutation setOauthToken($token: String!, $verifier: String!) {
    setOauthToken(
      token: $token
      verifier: $verifier
    ) {
      id
    }
  }
`;


class TwitterCallback extends Component {
  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    if (query.oauth_token && query.oauth_verifier) {
      this.props.setOauthToken({
        variables: {
          token: query.oauth_token,
          verifier: query.oauth_verifier,
        },
      })
        .then(() => {
          window.Intercom('trackEvent','add-twitter');
          this.props.history.push('/team#social');
        });
    } else {
      this.props.history.push('/team#social');
    }
  }


  render() {
    return null;
  }
}

export default withRouter(graphql(SET_TOKEN, { name: 'setOauthToken' })(withStyles(styles)(TwitterCallback)));
