import React, { Component } from 'react';
import { graphql, compose, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Form, Field } from 'react-final-form';
import TextField from '../material/TextField';
import SelectSingle from '../material/SelectSingle';
import TopBar from './TopBar';
import ClientsSelector from './Clients/ClientsSelector';
import Grid from '@material-ui/core/Grid';
import ScoreshotsPut from '../../scoreshotsapi/Put';

const styles = theme => ({
  paper: {
    padding: 16,
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },

  button: {
    margin: theme.spacing(1),
  }
});

const GET_TEXTURE = gql`
	query getTexture($id: ID!) {
		getTexture(id: $id) {
			id
			name
			image
			visible
			clients {
				id
				name
			}
		}
	}
`;

const UPDATE_TEXTURE = gql`
	mutation (
		$id: ID!
		$name: String
		$image: String
		$clients: [ID!]
		$visible: TEXTURE_VISIBILITY
	) {
		updateTexture(
			id: $id
			name: $name
			image: $image
			clients: $clients
      visible: $visible) {
				id
		}
	}
`;

const DELETE_TEXTURE = gql`
  mutation ($id: ID!) {
    removeTexture(id: $id) {
      id
    }
  }
`;

const visibility_types = [
  {
    name: 'Visible',
    value: 'GLOBAL',
  },
  {
    name: 'Premium',
    value: 'PREMIUM',
  },
  {
    name: 'Hidden',
    value: 'HIDDEN',
  },
];

class TextureEditor extends Component {
  static getDerivedStateFromProps(newProps, prevState) {
    let newState = null;
    if (newProps.data && newProps.data.getTexture && prevState.clients === null) {
      newState = { ...prevState };
      newState.clients = [...newProps.data.getTexture.clients];
    }
    return newState;
  }

  handleRemoveClient = (index) => {
    const newState = {
      clients: this.state.clients,
    };
    newState.clients.splice(index, 1);
    this.setState(newState);
  }

  handleAddClient = (client) => {
    const newState = {
      clients: [...this.state.clients, client],
    };

    this.setState(newState);
  }

  saveTexture = async (values) => {
  	this.props.saveTexture({
  		variables: {
  			...values,
  			id: this.props.id,
  			clients: this.state.clients.map((client) => client.id)
  		}
  	});
  }

	constructor() {
    super();

		this.state = {
			clients: null
		};

    this.action = new ScoreshotsPut("ss3-assets");
	}

  uploadTexture = (e) => {
    let file = e.target.files[0];
    const params = {
      Body: file,
      Key: `textures/${this.props.id}.png`,
      ACL: 'public-read'
    };

    this.action.put(params.Body, "image/png", () => {
      const image = `https://ss3-assets.s3.us-east-2.amazonaws.com/${params.Key}`
      this.props.saveTexture({
        variables: { id: this.props.id, image }
      }).then(() => {
        this.props.data.refetch({
          id: this.props.id
        });
      });
    }, params.Key, "");

    e.target.value = null;
  }

  getButtons() {
    const { classes } = this.props;

    return (
      <Mutation mutation={DELETE_TEXTURE} variables={{id: this.props.id}}>
        {
          (deleteTexture) => (
            <Button 
              variant="raised"
              color="secondary"
              className={classes.button}
              onClick={deleteTexture}>
              Delete
            </Button>
          )
        }
      </Mutation>
    );
  }

	getForm() {
    const { clients } = this.state;
    const dataProp = this.props.data;
    const texture = dataProp.getTexture;
    return (
      <Form
      	onSubmit={this.saveTexture}
      	initialValues={{
      		name: texture.name,
      		visible: texture.visible
      	}}
      	render={({ handleSubmit }) => (
      		<form onSubmit={handleSubmit}>
  		      <Field name="name" component={TextField} id="name" label="Name" style={{ marginTop: 10 }} />
  		      <Field
  		        name="visible"
  		        component={SelectSingle}
  		        id="visible"
  		        label="Visibility"
  		        options={visibility_types}
  		        style={{ marginTop: 10 }}
  		      />
            <div style={{marginTop: 20 }}>
              <Typography>Clients</Typography>
    		      <ClientsSelector
    		      	clients={clients}
    		      	addClient={this.handleAddClient}
    		      	removeClient={this.handleRemoveClient} />
            </div>
            <Button
              variant="raised"
              color="secondary"
              onClick={handleSubmit}
              fullWidth
              style={{ marginTop: 10 }}>
              Save
            </Button>
  		    </form>
  	   )} />
    );
	}

	render() {
    const { classes, data } = this.props;
    return (
      <React.Fragment>
        <TopBar title="Edit Texture" rightComponent={this.getButtons()} />
        <Grid container spacing={3} >
          <Grid item xs={3}>
            <Paper className={classes.paper}>
              {data && data.getTexture ? this.getForm() : null}
            </Paper>
         	</Grid>
         	<Grid item xs={9}>
         		{
         			data && data.getTexture ?
         				<Paper className={classes.paper}>
         					<img
         						src={data.getTexture.image}
                    style={{backgroundColor: 'black'}}
                    width='100%'
         						alt="texture" />
                  <input
                    accept="image/png"
                    style={{display:'none'}}
                    id="upload-texture"
                    type="file"
                    name="file"
                    onChange={this.uploadTexture}
                  />
                  <label htmlFor="upload-texture">
                    <Button component="span" size="small">
                      Upload Texture
                    </Button>
                  </label>
         				</Paper> : null
         		}
         	</Grid>
       	</Grid>
      </React.Fragment>
    );
	}
}

export default compose(
  graphql(GET_TEXTURE),
  graphql(UPDATE_TEXTURE, { name: 'saveTexture' }),
  withStyles(styles, { withTheme: true }),
)(TextureEditor);
