/* eslint react/jsx-no-duplicate-props: "off" */
// -------------------------------------------------------------------------- //

import * as React from 'react';

import {
  Collapse,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Popover,
  TextField,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  MenuDownOutline as MenuDownOutlineIcon,
} from 'mdi-material-ui';

import {compose} from 'react-apollo';
import Autosuggest from 'react-autosuggest';
import classNames from 'classnames';

import * as Binding from '../../../canvas/lib/databinding';
import * as MetaData from '../../../canvas/lib/metadata';
import * as Sports from '../../sport/Sports';
import Crumbs from './Crumbs';

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  root: {},
  button: {
    marginBottom: theme.spacing(1),
  },
  button_icon: {
    position: 'absolute',
    left: theme.spacing(2),
    opacity: 0.87,
  },
  content: {
    margin: theme.spacing(2),
  },
  expand_icon_collapsed: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expand_icon_expanded: {
    transform: 'rotate(180deg)',
  },
  suggestion: {
    display: 'block',
  },
  suggestions_list: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  container: {
    flex: 1,
  },
  textField: {
    width: '100%',
  }
});

// -------------------------------------------------------------------------- //

function gatherBoundObjects(canvas) {
  return canvas.getObjects().filter(Binding.HasBindings);
};

// -------------------------------------------------------------------------- //

function updateBoundObjects(objects, sport) {
  objects.forEach(object => Binding.UpdateBindings(object, sport));
  return objects;
};

// -------------------------------------------------------------------------- //

function getVariableText(variable, index = 0) {
  const value = variable.getValue(index);

  if (value === undefined || value === null) {
    return '';
  }

  if (variable.type === 'number' && isNaN(value)) {
    return '';
  }

  return String(value);
}

// -------------------------------------------------------------------------- //


function getSuggestionValue(suggestion) {
  return suggestion.name;
}

// -------------------------------------------------------------------------- //

function fetchSuggestions(roster_members, value) {
  const MAXIMUM = 5;
  let count = 0;

  return roster_members.filter(member => {
    if (count >= MAXIMUM) {
      return false;
    }

    const lhs = getSuggestionValue(member).toUpperCase();
    const rhs = value.toUpperCase();

    if (!lhs.includes(rhs)) {
      return false;
    }

    ++count;
    return true;
  });
}

// -------------------------------------------------------------------------- //

function renderSuggestionInput(props) {
  const {classes, onSuggest, inputRef, ref, ...other} = props;
  return (
    <TextField
      {...other}
      inputRef={(node) => {
        if (inputRef) {
          inputRef(node);
        }

        if (ref) {
          ref(node);
        }
      }}
    />
  )
}

// -------------------------------------------------------------------------- //

function renderSuggestion(suggestion, {isHighlighted}) {
  return (
    <MenuItem selected={isHighlighted}>
      <Typography variant="body1">{getSuggestionValue(suggestion)}</Typography>
    </MenuItem>
  );
}

// -------------------------------------------------------------------------- //

class Stat extends React.Component {

  state = {
    text: '',
    focused: false,
    suggestions: [],
  }

  constructor(props) {
    super(props);
    this.anchor = null; // this.anchor = React.createRef();
  }

  handleTyping = (e) => {
    const {variable, index = 0, onChange} = this.props;
    const text = e.target.value;
    const value = variable.convertValue(text);
    this.setState({text, value});
    (onChange && onChange(variable, value, index));
  }

  handleFocusChange = (focused) => {
    if (focused) {
      const {variable, index = 0} = this.props;
      const text = getVariableText(variable, index);
      this.setState({text, focused});
    } else {
      this.setState({focused});
    }
  }

  fetchSuggestions = (e) => {
    const {roster_members} = this.props;
    const suggestions = fetchSuggestions(roster_members, e.value);
    this.setState({suggestions});
  }

  clearSuggestions = () => {
    this.setState({suggestions: []});
  }

  selectSuggestion = (_, data) => {
    this.setState({text: '', suggestions: []}, () => {
      const {onSuggest} = this.props;

      if (onSuggest) {
        onSuggest(data.suggestion);
      }
    });
  }

  render() {
    const {
      classes,
      variable,
      index = 0,
      ...rest_props
    } = this.props;

    const {suggestions} = this.state;
    const value = getVariableText(variable, index);
    const error = (variable.type === 'number' && isNaN(value.trim()));
    const width = (this.anchor ? this.anchor.clientWidth : null);
    const is_name = (variable.name === '[Home] Team Schedule Name' || variable.name === '[Visiting] Team Schedule Name');

    if (is_name) {
      return (
        <Autosuggest
          id={`autosuggest.${variable.id}.${index}`}
          highlightFirstSuggestion
          suggestions={suggestions}
          getSuggestionValue={getSuggestionValue}
          onSuggestionsFetchRequested={this.fetchSuggestions}
          onSuggestionsClearRequested={this.clearSuggestions}
          onSuggestionSelected={this.selectSuggestion}
          renderInputComponent={renderSuggestionInput}
          renderSuggestion={renderSuggestion}
          inputProps={{
            ...rest_props,
            value,
            inputRef: (node) => {
              this.anchor = node;
            },
            onChange: this.handleTyping,
          }}
          theme={{
            container: classes.container,
            suggestion: classes.suggestion,
            suggestionsList: classes.suggestions_list,
          }}
          style={{ width: '100%', marginLeft: '5px', marginRight: '5px'  }}
          renderSuggestionsContainer={options => (
            <Popover
              disableAutoFocus
              disableEnforceFocus
              disableRestoreFocus
              open={Boolean(options.children)}
              anchorEl={this.anchor}
              anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
              }}
            >
              <Paper square {...options.containerProps} style={{width}}>
                {options.children}
              </Paper>
            </Popover>
          )}
        />
      );
    } else {
      return (
        <TextField
          {...rest_props}
          value={value}
          error={error}
          onChange={(e) => this.handleTyping(e)}
          onFocus={() => this.handleFocusChange(true)}
          onBlur={() => this.handleFocusChange(false)}
          style={{ width: '100%', clear: 'both', marginLeft: '5px', marginRight: '5px' }}
        />
      );
    }
  }

};

// -------------------------------------------------------------------------- //

class Schedule extends React.Component {

  state = {
    collapse_index: -1,
    import_menu_anchor: null,
    member_menu_anchor: null,
    member_menu_index: -1,
    original_members: null,
    selectedStartDate: null,
    selectedEndDate: null
  }

  constructor() {
    super();
    this.sports = {};

    for (let key in Sports.sports) {
      this.sports[key] = Sports.CreateSport(key);
    }

    this.sports.default = Sports.CreateSport();
    let prestoSite = localStorage.getItem('prestoSite');
    if (prestoSite) {
      this.importPrestoRosterMembers();
    }
  }

  render() {
    const {classes} = this.props;
    const {selectedStartDate, selectedEndDate} = this.state;

    return (
      <div className={classes.root}>
        <Crumbs title="Schedule Filter"/>
        <div className={classes.content}>

          <TextField
            id="date"
            label="Start Date"
            type="date"
            defaultValue="2021-05-31"
            className={classes.textField}
            value={selectedStartDate}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="date"
            label="End Date"
            type="date"
            defaultValue="2021-08-31"
            className={classes.textField}
            value={selectedEndDate}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <Divider/>
        <div className={classes.content}>
          <Crumbs title="Schedule List"/>
          {this.renderSchedule()}
        </div>
      </div>
    );
  }


  renderSchedule = () => {
    const schedule = this.getSchedule();
    return schedule.map((_, index) => {
      const element = this.renderPlayer(index);

      if (element === null) {
        return null;
      }

      return (
        <React.Fragment key={index}>

          <Divider/>
          {element}
        </React.Fragment>
      );
    });
  }

  renderPlayer = (index) => {
    const {classes} = this.props;
    const {collapse_index} = this.state;
    let extra_toolbar = [];
    const main_toolbar_table = [
      {id: 'team.home.schedule.name', label: 'Home Name'},
      {id: 'team.visiting.schedule.name', label: 'Away Name'},
    ];

    const main_toolbar_count = (
      (main_toolbar_table.filter(v => this.isStatOnCanvas(v.id, index))).length
    );
    const members = this.getSchedule();

    let main_toolbar = main_toolbar_table
      .filter(v => this.isStatOnCanvas(v.id, index))
      .map(({id, label}, main_toolbar_index) => {
        const variable = this.getCanvasVariable(id);
        return (
          <React.Fragment key={main_toolbar_index}>
            <Stat
              classes={classes}
              key={id}
              variable={variable}
              index={index}
              label={label}
              onChange={(_, v, i) => this.setCanvasVariable(id, v, i)}
              onSuggest={(member) => this.loadSchedule(member, index)}
              roster_members={members}
              InputProps={{
                endAdornment: (
                  (main_toolbar_index === main_toolbar_count - 1) &&
                  <InputAdornment
                    position="end"
                    style={{marginLeft: 0}}
                  >
                  </InputAdornment>
                ),
              }}
              inputProps={{}}
            />
            <Divider/>
          </React.Fragment>
        );
      });

    if (main_toolbar.length === 0 && extra_toolbar.length === 0) {
      return null;
    }

    return (
      <React.Fragment>
        <Toolbar disableGutters>
          {main_toolbar}
          {
            (extra_toolbar.length > 0) &&
            <IconButton
              onClick={() => this.changeCollapseIndex(index)}
              className={classNames(
                classes.expand_icon_collapsed,
                {[classes.expand_icon_expanded]: (collapse_index === index)}
              )}
            >
              <MenuDownOutlineIcon/>
            </IconButton>
          }
        </Toolbar>
        {
          (extra_toolbar.length > 0) &&
          <Collapse in={collapse_index === index}>
            <Toolbar disableGutters>
              {extra_toolbar}
              <div style={{width: 48}}/>
            </Toolbar>
          </Collapse>
        }
      </React.Fragment>
    );
  }


  changeCollapseIndex = (index) => {
    let {collapse_index} = this.state;

    if (collapse_index === index) {
      collapse_index = -1;
    } else {
      collapse_index = index;
    }

    this.setState({collapse_index});
  }

  importPrestoRosterMembers = () => {
    const teamids = localStorage.getItem('teamids');
    const bearer = localStorage.getItem('bearer');
    let prestoAPIEndpoint = process.env.REACT_APP_PS_GAMEDAY_API_ENDPOINT;
    if (teamids && teamids !== '') {
      fetch(prestoAPIEndpoint + 'teams/' + teamids + '/events', {
        headers: {
          'Authorization': 'Bearer ' + bearer
        }
      })
        .then((response) => response.json())
        .then((jsonData) => {
          if (jsonData && jsonData.data) {
            let members = [];
            jsonData.data.forEach((record) => {
              members = [
                ...members,
                {
                  'id': record.eventId,
                  'name': (record.teams.homeTeam.teamName + ' VS ' + record.teams.awayTeam.teamName),
                  'homeName': record.teams.homeTeam.teamName,
                  'homeLogo': record.teams.homeTeam.logo,
                  'awayName': record.teams.awayTeam.teamName,
                  'awayLogo': record.teams.awayTeam.logo,
                },
              ];
            });
            const players = this.getSchedule();
            const player_count = ((players && players.length) || 0);
            let player = 0;

            for (let i = 0; i < members.length && player < player_count; ++i) {
              //const member = members[i];

                //this.loadRosterMember(member, player++, false);
            }

            let {editor} = this.props;
            editor.performBinding(true);

            let {canvas} = this.props;
            MetaData.setMetaData(canvas, 'schedule', members);
            this.setState({import_menu_anchor: null, original_members: members});
          }
          this.forceUpdate();
        })
        .catch((error) => {
          console.error('error', error);
        });
    }
  }


  getSportCategory = () => {
    const {template} = this.props;
    return Sports.GetCategorySport(template && template.categories);
  }

  getTemplateSport = () => {
    return this.sports[this.getSportCategory()];
  }

  getCanvasSport = () => {
    const {sports} = this.props;
    return sports[this.getSportCategory()];
  }

  gatherVariables = (player = null) => {
    const {CanvasUtil} = this.props;
    const sport = this.getCanvasSport();
    const objects = gatherBoundObjects(CanvasUtil.canvas);
    return Binding.GatherStats(updateBoundObjects(objects, sport), player);
  }

  isStatOnCanvas = (id, player = null) => {
    return this.gatherVariables(player).some(variable => variable === id);
  }

  getTemplateVariable = (id) => {
    const sport = this.getTemplateSport();

    if (!sport) {
      return null;
    }

    return sport.getVariableById(id);
  }

  getCanvasVariable = (id) => {
    const sport = this.getCanvasSport();

    if (!sport) {
      return null;
    }

    return sport.getVariableById(id);
  }

  setCanvasVariable = (id, value, index = 0, refresh = true) => {
    const {editor} = this.props;
    const sport = this.getCanvasSport();

    if (!sport) {
      return;
    }

    const variable = sport.getVariableById(id);

    if (!variable) {
      return;
    }

    variable.setValue(value, index);

    if (refresh) {
      editor.performBinding();
      this.forceUpdate();
    }
  }

  getSchedule = () => {
    const {CanvasUtil} = this.props;
    const objects = gatherBoundObjects(CanvasUtil.canvas);
    let widgetMax = 0;
    let {canvas} = this.props;
    if (!MetaData.hasMetaData(canvas, 'schedule')) {
      objects.forEach((object) => {
        if (object && object.widget_piece && object.widget_piece.widget_box && object.widget_piece.widget_box.widget_max_count) {
          widgetMax = object.widget_piece.widget_box.widget_max_count;
        }
      });
      let schedule = [];
      for (let j = 0; j < widgetMax; ++j) {
        schedule = [
          ...schedule,
          {
            'homeName': '',
            'homeLogo': '',
            'awayName': '',
            'awayLogo': '',
          },
        ];
      }
      MetaData.setMetaData(canvas, 'schedule', schedule);
    }

    return CanvasUtil.getCanvasMetadata('schedule', []);
  }

}

// -------------------------------------------------------------------------- //
export default compose(
  withStyles(STYLES, {withTheme: true}),
)(Schedule);

// -------------------------------------------------------------------------- //
