import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import Grid from '@material-ui/core/Grid';
import LazyLoad from 'react-lazyload';
import Button from '@material-ui/core/Button';
import TemplatePoster from './TemplatePoster';

import 'whatwg-fetch';


const GET_TEMPLATES = gql`
query getTemplates {
  templateSearch(
    where: {
      customDetails: null
      isSlide : true
    }
    orderBy: visible_DESC
  ) {
    id
    image
    visible
    width
    height
    isSlide
    categories {
      id
    }
    types {
      id
    }
    clients {
      id
    }
  }
}
`;

class TemplateGridSlides extends Component {
  state = {
    viewChosen: true,
  }

  addRemoveSlide = (templateid, slidesArray, index) => () => {
    const obj = {};
    const _slidesArray = slidesArray;
    for (let x = 0; x < _slidesArray.length; x++) {
      obj[_slidesArray[x]] = '';
    }
    if (templateid in obj) {
      _slidesArray.splice(index, 1);
    } else {
      _slidesArray.push(templateid);
    }

    this.props.setSlidesFunct(_slidesArray);
  }

  myGrid = (filtered, slides) => filtered.map((thisTemplate, _index) => (
    <Grid item xs={4} md={2} key={thisTemplate.id} onClick={this.addRemoveSlide(thisTemplate.id, slides, _index)}>

      <LazyLoad height={200} once overflow offset={500}>
        <TemplatePoster
          template={thisTemplate}
        />
      </LazyLoad>
    </Grid>
  ));

  render() {
    const { data, templateData } = this.props;
    const { viewChosen } = this.state;

    if (!data || data.loading) {
      return null;
    }

    const filtered = data.templateSearch;
    const btnTitle = viewChosen ? 'Chosen Slides' : 'Slide Selection';

    let chosenslides = [];

    const { list } = templateData;

    if (viewChosen) {
      for (let j = 0; j < list.length; j++) {
        for (let x = 0; x < filtered.length; x++) {
          if (filtered[x].id === list[j]) {
            chosenslides.push(filtered[x]);
          }
        }
      }
    } else {
      // view only slides that are the same width and height as the slideshow template
      // eslint-disable-next-line max-len
      chosenslides = filtered.filter((_template) => _template.width === templateData.width && _template.height === templateData.height);
    }

    return (
      <div style={{ height: '70%' }}>
        <Button style={{ marginBottom: '1%' }} onClick={() => this.setState({ viewChosen: !viewChosen })}>{btnTitle}</Button>
        <Button
          style={{ marginBottom: '1%' }}
          disabled={!viewChosen}

          onClick={() => this.props.saveSelectedSlidesFunct(list)}
        >
      Save Selected Slides
        </Button>
        <br />
        <Grid container spacing={1}>
          {this.myGrid(chosenslides, list)}

        </Grid>
      </div>
    );
  }
}

export default graphql(GET_TEMPLATES, { options: { fetchPolicy: 'network-only' } })(TemplateGridSlides);
