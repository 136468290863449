import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import Grid from '@material-ui/core/Grid';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';
import TemplatePoster from './TemplatePoster';

const GET_TEMPLATES = gql`
  query getTemplates {
    templateSearch(
      where: {
        customDetails: null
      }
      orderBy: visible_DESC
    ) {
      id
      image
      visible
      categories {
        id
      }
      types {
        id
      }
      clients {
        id
      }
    }
  }
`;
class TemplateGrid extends Component {
  render() {
    const { data, typeFilter, catFilter, isWidget } = this.props;
    if (data.loading) {
      return null;
    }
    const filtered = data.templateSearch.filter((template) => {
      if (typeFilter !== 0 && template.types.findIndex(t => t.id === typeFilter) === -1) {
        return false;
      }
      if (catFilter !== 0 && template.categories.findIndex(c => c.id === catFilter) === -1) {
        return false;
      }

      if (this.props.isPremium) {
        if (template.clients.length === 0) {
          return false;
        }
      }

      if ((isWidget && template.visible !== 'WIDGET') ||
          (!isWidget && template.visible === 'WIDGET')) {
        return false;
      }

      return true;
    });

    return (
      <Grid container spacing={0}>
        {
          filtered.map(thisTemplate => (
            <Grid item xs={4} md={2} key={thisTemplate.id}>
              <LazyLoad height={200} once overflow offset={500}>
                <Link to={`/admin/templates/${thisTemplate.id}`} >
                  <TemplatePoster
                    template={thisTemplate}
                  />
                </Link>
              </LazyLoad>
            </Grid>
          ))
        }
      </Grid>
    );
  }
}

export default graphql(GET_TEMPLATES)(TemplateGrid);
