/* eslint no-underscore-dangle: "off" */

class SSLogo {
  constructor(fabric) {
    this.class = fabric.util.createClass(fabric.Image, {

      type: 'SSLogo',
      name: 'ScoreShots Logo',
      // these are dependent on the image, change them maybe.
      imgWidth: 149,
      imgHeight: 54,
      perPixelTargetFind: false,
      initialize(options) {
        this.callSuper('initialize', options);
        this.image = new Image();
        this.image.onload = () => {
          this.width = this.image.width;
          this.height = this.image.height;
          this.loaded = true;
          this.setCoords();

          //this._render(this.canvas.contextContainer);
          //this.canvas.renderAndResetBound();
        };
        this.image.crossOrigin = 'anonymous';
        this.image.src = `${process.env.REACT_APP_DOMAIN}/images/logo.png`;
        this.on('added', this.handleSteps);
      },
      handleSteps() {
        this.off('added');
      this.stepChange(this.canvas.currentStep);
       this.canvas.on('stepChange', this.stepChange);



      },
      stepChange(step) {
        const newOptions = {};
        switch (step) {
          case 'admin':
            break;
          default:
            break;
        }
        this.set(newOptions);
      },
      _render(ctx) {

        if (this.loaded) {

          ctx.drawImage(this.image, -this.width / 2, -this.height / 2);

        }
      },
    });

    this.fromObject = (object, callback) => fabric.Object._fromObject('SSLogo', object, callback, 'points');

  }


}


export default SSLogo;
