
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Avatar,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  withStyles,
  ListItemAvatar,
} from '@material-ui/core';

import {
  ChevronDown as ChevronDownIcon,
} from 'mdi-material-ui';

import { Link } from 'react-router-dom';

import Auth from '../auth/auth';

// -------------------------------------------------------------------------- //

const auth = new Auth();

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  list: {
    padding: 0,
  },
  list_item: {
    height: '100%',
    paddingBottom: 0,
    paddingTop: 0,
  },
  list_item_container: {
    height: '100%',
  },
  list_item_icon: {
    pointerEvents: 'none',
  },
  secondary: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  typography: {
    color: 'inherit',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
  },
});

// -------------------------------------------------------------------------- //

class UserMenu_ extends React.Component {

  state = {
    anchor: null,
  }

  render() {
    const { classes, user } = this.props;
    const { anchor } = this.state;

    if (!user) {
      return null;
    }

    return (
      <React.Fragment>
        <List className={classes.list}>
          <ListItem
            button
            className={classes.list_item}
            classes={{ container: classes.list_item_container }}
            onClick={({ target }) => this.openMenu(target)}
            aria-label="User Profile Drop Down Menu"
          >
            <ListItemAvatar>
              <Avatar
                alt={`${user.firstname} ${user.lastname}`}
                className={classes.avatar}
              >
                {user.firstname.substring(0, 1)}
                {user.lastname.substring(0, 1)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={user.client.name}
              secondary={`${user.firstname} ${user.lastname}`}
              primaryTypographyProps={{ color: "inherit" }}
              secondaryTypographyProps={{ color: "inherit" }}
            />
            <ListItemSecondaryAction className={classes.list_item_icon}>
              <ChevronDownIcon/>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <Menu
          id="acct"
          anchorEl={anchor}
          open={Boolean(anchor)}
          onClose={() => this.closeMenu()}
          aria-hidden
        >
          {
            ((user.client && user.client.type === 'ADMIN') || null) &&
            <MenuItem
              to="/admin"
              component={Link}
            >
              Admin
            </MenuItem>
          }
          <MenuItem
            onClick={() => this.showTour()}
          >
            Show Tour
          </MenuItem>
          <MenuItem
            to="/user"
            component={Link}
            onClick={() => this.closeMenu()}
          >
            User Settings
          </MenuItem>
          <MenuItem onClick={() => auth.logout()}>
            Logout
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }

  openMenu = (anchor) => {
    this.setState({ anchor });
  }

  closeMenu = () => {
    this.setState({ anchor: null });
  }

  showTour = () => {
    const { showTour } = this.props;
    (showTour && showTour());
    this.closeMenu();
  }

}

// -------------------------------------------------------------------------- //

export const UserMenu = withStyles(STYLES)(UserMenu_);

// -------------------------------------------------------------------------- //
