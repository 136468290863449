import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { formatRelative } from 'date-fns';

const styles = theme => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: 'left',
    margin: 'auto',
    marginTop: theme.spacing(1),
    maxWidth: 520
  },
  details: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  oldDetails: {
    opacity: '.4',
  },
  green: {
    borderTop: '5px solid #2fcc66',
  },
  yellow: {
    borderTop: '5px solid #f1c40f',
  },
  red: {
    borderTop: '5px solid #e74c3c',
  },
  maintenance: {
    borderTop: '5px solid #3498db',
  },
  crit: {
    color: '#FFF',
    backgroundColor: '#e74c3c',
  },
});

class Status extends Component {
  state = {
    status: null,
  }
  componentDidMount() {
    fetch('https://api.statuspage.io/v1/pages/f5x9f27f7893/incidents/unresolved.json', {
      headers: {
        Authorization: 'OAuth 377872bf-e372-43f3-abf8-ee167807b5f5',
      },
    }).then(response => response.json())
      .then(json => this.setState({ status: json }));
  }
  getUpdates(incident) {
    const { classes } = this.props;
    const output = [];
    incident.incident_updates.forEach((update, index) => {
      const detailsclass = index !== 0 ?
        classnames(classes.details, classes.oldDetails) :
        classes.details;

      if (update.status === 'in_progress') {
        update.status = 'In Progress';
      }
      output.push(<div
        key={incident.id + index}
        className={detailsclass}
      >
        <InheritColorTypography>
          <b>
            {update.status.charAt(0).toUpperCase() + update.status.slice(1)}
          </b>
          {` - ${formatRelative(update.created_at, new Date())}`}
        </InheritColorTypography>
        <InheritColorTypography>
          {update.body}
        </InheritColorTypography>
                  </div>);
      if (index !== incident.incident_updates.length - 1) {
        output.push(<Divider key={`Divider${index}`} />);
      }
    });
    return output;
  }
  gotoStatus() {
    document.location.href = 'https://scoreshots.statuspage.io/';
  }
  getIncidents() {
    const { classes } = this.props;
    const output = [];
    if (this.state.status && this.state.status.length > 0) {
      this.state.status.forEach((incident) => {
        let color;
        switch (incident.impact) {
          case 'major':
            color = classes.red;
            break;
          case 'critical':
            color = classes.crit;
            break;
          case 'maintenance':
            color = classes.maintenance;
            break;
          default:
            color = classes.yellow;
            break;
        }
        output.push(<Paper
          key={incident.id}
          className={classnames(color, classes.paper)}
          onClick={this.gotoStatus}
        >
          <InheritColorTypography variant="h6">{incident.name}</InheritColorTypography>
          {this.getUpdates(incident)}
        </Paper>);
      });
    } 
    return output;
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.paper}>
        {this.getIncidents()}
      </div>
    );
  }
}

const InheritColorTypography = props => <Typography color="inherit" {...props} />;

export default withStyles(styles)(Status);
