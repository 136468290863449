import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink, concat } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import decode from 'jwt-decode';
import gql from 'graphql-tag';

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_ENDPOINT });

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = localStorage.getItem('access_token');
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : null,
    },
  });
  return forward(operation).map((response) => {
    // if a refresh token is sent, update the access token.
    const context = operation.getContext();
    const refresh = context.response.headers.get('x-refresh-token');
    if (refresh) {
      const token = decode(refresh);
      localStorage.setItem('access_token', refresh);
      localStorage.setItem('expires_at', token.exp);
    }
    return response;
  });
});

const link = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`));
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const client = new ApolloClient({
  link: concat(authMiddleware, httpLink, link),
  cache: new InMemoryCache(),
  defaultOptions: {
    mutate: {
      errorPolicy: 'all',
    },
  },
});


const LOGIN_USER = gql`
  mutation ($email: String!, $password: String!) {
    login(
      email: $email,
      password: $password
    ) {
      token
      user {
        id
      }
      aws_id
      aws_secret
      clientId
    }
  }
`;
const GET_CLIENT = gql`query ($id : ID!){
  client(id : $id){
    id
    clientFlags
    subscription_end
  }
}`;


const REQUEST_S3 = gql`
  mutation {
    requestS3Access {
      token
    }
  }
`;

const IMPERSONATE_USER = gql`
  mutation ($userId: ID!) {
    impersonateUser(
      userId: $userId
    ) {
      token
      user {
        id
      }
      aws_id
      aws_secret
      clientId
    }
  }
`;

export default class Auth {
  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.loading = false;
    if (localStorage.getItem('access_token') && !localStorage.getItem('s3_token')) {
      this.logout();
    }
  }

  getClient(id,comesFromPresto){
    client.query({
      query: GET_CLIENT,
      variables: {
        id
      }
    })
        .then((response) => {

          if (response.data) {
            localStorage.setItem('clientFlags', response.data.client.clientFlags);
            localStorage.setItem('subscription_end', response.data.client.subscription_end);

            const currentDate = new Date(); // now
            const expirationDate = new Date(response.data.client.subscription_end); // expiration date YYYY-MM-DD
            expirationDate.setTime(expirationDate.getTime() + (20 * 1000 * 60 * 60));

            if (currentDate > expirationDate) {
              this.logout(comesFromPresto);
            }
          }else {
            console.log('response: ',response)
          }
        });

  }
  login(email, password, errorHandler, doneHandler, location = '/') {
    const self = this;
    this.loading = true;
    client.mutate({
      mutation: LOGIN_USER,
      variables: {
        email,
        password,
      },
      refetchQueries: ['myFonts'],
    })
        .then((loginResponse) => {
          console.log(loginResponse);
          if (loginResponse.data) {
            localStorage.removeItem('isImpersonator');
            self.setSession(loginResponse.data.login);
            window.Intercom("trackEvent","login",{success:true});
          }

          if (loginResponse.errors) {
            if (errorHandler) {
              errorHandler(loginResponse.errors[0].message);
            }
            this.loading = false;
            window.Intercom("trackEvent","login",{success:false});
            return false;
          }

          client.mutate({
            mutation: REQUEST_S3
          }).then((s3Response) => {
            if (doneHandler) {
              doneHandler();
            }
            this.loading = false;
            if (s3Response && s3Response.data && s3Response.data.requestS3Access && s3Response.data.requestS3Access.token) {
              localStorage.setItem('s3_token', s3Response.data.requestS3Access.token)
            }

            window.location.reload();


          });
        });
  }

  impersonateUser(userId, errorHandler, doneHandler) {
    const self = this;
    this.loading = true;
    client.mutate({
      mutation: IMPERSONATE_USER,
      variables: {
        userId,
      },
    })
        .then((loginResponse) => {
          if (loginResponse.data) {
            self.setSession(loginResponse.data.impersonateUser);
          }

          if (loginResponse.errors) {
            errorHandler(loginResponse.errors[0].message);
            this.loading = false;
          }

          client.mutate({
            mutation: REQUEST_S3
          }).then((s3Response) => {
            if (doneHandler) {
              doneHandler();
            }
            this.loading = false;
            if (s3Response.data) {
              localStorage.setItem('s3_token', s3Response.data.requestS3Access.token)
            }
            window.location.href = '/';
          });
        });
  }

  setSession(response, location = null) {
    const token = decode(response.token);
    localStorage.setItem('endpoint', process.env.REACT_APP_GRAPHQL_ENDPOINT);
    localStorage.setItem('access_token', response.token);
    localStorage.setItem('expires_at', token.exp);
    localStorage.setItem('user_id', response.user.id);
    localStorage.setItem('client_id', response.clientId);

    this.getClient(response.clientId);
    if (location) {
      window.location.reload();
    }
  }

  logout(redirectToExpiredAccount) {
    localStorage.removeItem('prestoSite');
    localStorage.removeItem('eventid');
    localStorage.removeItem('saveurl');
    localStorage.removeItem('cancelurl');
    localStorage.removeItem('categoryid');
    localStorage.removeItem('imageurl');
    localStorage.removeItem('bearer');
    localStorage.removeItem('teamids');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('client_id');
    localStorage.removeItem('subscription_end');
    localStorage.removeItem('isImpersonator');
    localStorage.removeItem('clientFlags');
    localStorage.removeItem('s3_token');
    localStorage.removeItem('access_token');
    localStorage.removeItem('user_id');

    /* global Raven */
    window.Intercom("trackEvent","logout");

    Raven.setUserContext();
    window.location.href = '/';
  }

  isAuthenticated(comesFromPresto) {
    if (
        localStorage.getItem('endpoint') !== process.env.REACT_APP_GRAPHQL_ENDPOINT
    ) {
      localStorage.clear();
      return false;
    }
    if (localStorage.getItem('client_id')) {
      this.getClient(localStorage.getItem('client_id'),comesFromPresto);
    }
    if (localStorage.getItem('subscription_end')) {
      const currentDate = new Date(); // now
      const expirationDate = new Date(localStorage.getItem('subscription_end')); // expiration date YYYY-MM-DD
      expirationDate.setTime(expirationDate.getTime() + (20 * 1000 * 60 * 60));

      if (currentDate > expirationDate) {
        return false;
      }
    }
    const expiresAt = JSON.parse(localStorage.getItem('expires_at')) * 1000;
    return new Date().getTime() < expiresAt;
  }
}

