import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowLeft from 'mdi-material-ui/ArrowLeft';

const styles = theme => ({
  header: {
    padding: theme.spacing(2),
    paddingBottom: 0,
    height: 58,
  },
  title: {
    display: 'inline-flex',
    lineHeight: '35px',
    fontWeight: 300,
  },
  iconButton: {
    marginTop: -6,
    marginLeft: theme.spacing(1) * -2,
  },
});

const Crumbs = (props) => {
  const {
    classes,
    title,
    onBack,
    onBackIcon,
    onBackTooltip,
  } = props;

  let BackComponent = ArrowLeft;
  if (onBackIcon) {
    BackComponent = onBackIcon;
  }

  let back = null;

  if (onBack) {
    back = (
      <IconButton className={classes.iconButton} onClick={onBack}>
        <BackComponent />
      </IconButton>
    );

    if (onBackTooltip) {
      back = (
        <Tooltip id="back-icon" title={onBackTooltip}>
          {back}
        </Tooltip>
      );
    }
  }

  return (
    <React.Fragment>
      <div className={classes.header}>
        {back}
        <Typography className={classes.title} variant="h6">
          {title}
        </Typography>
      </div>
      <Divider />
    </React.Fragment>
  );
};

export default withStyles(styles)(Crumbs);
