
// -------------------------------------------------------------------------- //

import React from 'react';
import PropTypes from 'prop-types';

// -------------------------------------------------------------------------- //

function IsPassiveSupported() {
  let supported = false;

  const test = {
    get passive() {
      supported = true;
      return false;
    }
  };

  try {
    document.addEventListener('test', null, test);
    document.removeEventListener('test', null, test);
  } catch (e) {}

  return supported;
}

// -------------------------------------------------------------------------- //


// -------------------------------------------------------------------------- //

function CalculateTopPosition(element) {
  if (!element) {
    return 0;
  }

  return (
    element.offsetTop +
    CalculateTopPosition(element.offsetParent)
  );
}

// -------------------------------------------------------------------------- //

function CalculateOffset(element, scroll_top) {
  if (!element) {
    return 0;
  }

  return (
    CalculateTopPosition(element) +
    (element.offsetHeight - scroll_top - window.innerHeight)
  );
}

// -------------------------------------------------------------------------- //

class Component extends React.Component {

  static propTypes = {
    children: PropTypes.node,
    element: PropTypes.node,
    getScrollParent: PropTypes.func,
    more: PropTypes.bool,
    onLoadPage: PropTypes.func.isRequired,
    page: PropTypes.number,
    preload: PropTypes.bool,
    ref: PropTypes.func,
    reverse: PropTypes.bool,
    threshold: PropTypes.number,
    useCapture: PropTypes.bool,
    useWindow: PropTypes.bool,
  };

  static defaultProps = {
    element: 'div',
    getScrollParent: null,
    more: false,
    page: 0,
    preload: false,
    ref: null,
    reverse: false,
    threshold: 250,
    useCapture: false,
    useWindow: false,
  };

  componentDidMount() {
    const { more, preload } = this.props;

    this.loading = false;
    this.options = this.createListenerOptions();

    if (more) {
      this.attachListeners();
    }

    if (preload) {
      this.load();
    }
  }

  componentWillUnmount() {
    this.detachListeners();
  }

  componentDidUpdate(old_props) {
    if (this.props.reverse && this.loading) {
      let parent = this.getParentElement();

      if (parent !== null) {
        parent.scrollTop = (
          parent.scrollHeight -
          this.old_scroll_height +
          this.old_scroll_top
        );
      }
    }

    if (this.props.more && !old_props.more) {
      this.attachListeners();
    } else if (!this.props.more && old_props.more) {
      this.detachListeners();
    }

    if (this.props.page < old_props.page && this.props.more) {
      this.load();
    }
  }

  render() {
    const {
      children,
      element,
      ref,
      useWindow,
      useCapture,
      reverse,
      preload,
      getScrollParent,
      threshold,
      page,
      onLoadPage,
      more,
      ...rest_props
    } = this.props;

    return React.createElement(element, {
      ...rest_props,
      ref: (node) => {
        this.scroll_component = node;
        if (ref) {
          ref(node);
        }
      },
    }, children);
  }

  createListenerOptions = () => {
    const { useCapture } = this.props;

    if (!IsPassiveSupported()) {
      // the event listener API doesn't support the options object;
      // instead, it uses the useCapture boolean parameter only.
      return useCapture;
    }

    return {
      capture: useCapture,
      passive: true,
    };
  }

  attachListeners = () => {
    const { more } = this.props;
    let scroller = this.getScrollElement();

    if (!more || scroller === null) {
      return;
    }

    scroller.addEventListener('wheel', this.load, this.options);
    scroller.addEventListener('scroll', this.load, this.options);
    scroller.addEventListener('resize', this.load, this.options);
  }

  detachListeners = () => {
    let scroller = this.getScrollElement();

    if (scroller === null) {
      return;
    }

    scroller.removeEventListener('wheel', this.load, this.options);
    scroller.removeEventListener('scroll', this.load, this.options);
    scroller.removeEventListener('resize', this.load, this.options);
  }

  getParentElement = () => {
    const { getScrollParent } = this.props;
    let parent = null;

    if (getScrollParent) {
      parent = getScrollParent();
    }

    if (parent !== null) {
      return parent;
    }

    if (this.scroll_component != null) {
      if (this.scroll_component.parentNode !== null) {
        return this.scroll_component.parentNode;
      }
    }

    return null;
  }

  getScrollElement = () => {
    const { useWindow } = this.props;
    return (useWindow ? window : this.getParentElement());
  }

  load = () => {
    const { more } = this.props;
    if (!more) {
      return;
    }

    const { useWindow, reverse, threshold, onLoadPage, page } = this.props;
    const parent = this.getParentElement();
    let offset;

    if (useWindow) {
      const root_element = (
        document.documentElement ||
        document.body.parentNode ||
        document.body
      );

      const scroll_top = (
        window.pageYOffset !== undefined
        ? window.pageYOffset
        : root_element.scrollTop
      );

      if (reverse) {
        offset = scroll_top;
      } else {
        offset = CalculateOffset(this.scroll_component, scroll_top);
      }
    } else if (reverse) {
      offset = parent.scrollTop;
    } else {
      offset = (
        this.scroll_component.scrollHeight -
        parent.scrollTop - parent.clientHeight
      );
    }

    if (
      (offset < threshold) &&
      (this.scroll_component !== null) &&
      (this.scroll_component.offsetParent !== null)
    ) {
      this.detachListeners();
      this.old_scroll_height = parent.scrollHeight;
      this.old_scroll_top = parent.scrollTop;
      this.loading = true;

      onLoadPage(page).then(() => {
        this.attachListeners();
        this.loading = false;
      });
    }
  }

}

// -------------------------------------------------------------------------- //

export const InfiniteScroller = Component;

// -------------------------------------------------------------------------- //
