import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

class ResponsiveGrid extends Component {
  render() {
    return (
      <Grid container spacing={0}>
        <Hidden smDown>
          <Grid
            item
            md={1}
            lg={1}
            xl={2}
          />
        </Hidden>
        <Grid
          item
          xs={12}
          md={10}
          lg={10}
          xl={8}
          {...this.props}
        >
          {this.props.children}
        </Grid>
        <Hidden mdDown>
          <Grid
            item
            md={1}
            lg={1}
            xl={2}
          />
        </Hidden>
      </Grid>
    );
  }
}

export default ResponsiveGrid;
