
// -------------------------------------------------------------------------- //

import SCSportImporter from './SCSportImporter';
import AttributePath from './AttributePath';
import { SplitPlayerName } from './Sports';

// -------------------------------------------------------------------------- //

class SCFootballImporter extends SCSportImporter {

  constructor() {
    super('fbgame');
  }

  import(sport, xml, options = {}) {
    if (!super.import(sport, xml, options)) {
      return false;
    }

    this.importTeam('home', this.ids[0]);
    this.importTeam('visiting', this.ids[1]);

    for (let i = 0; i < 2; ++i) {
      this.importTeamStats(i);
    }

    if (options.players) {
      for (let i = 0; i < options.players.length; ++i) {
        this.importPlayerStats(options.players[i], i);
      }
    }

    return true;
  }

  importTeam(key, id) {
    const team_id = `team.${key}`;
    const team_path = `/${this.game}/team[@id='${id}']`;

    let team = {};
    team.name = this.get(`${team_id}.name`);
    team.players = [];
    team.key = id;

    for (let i = 0;; ++i) {
      const player_path = `${team_path}/player[*][${i + 1}]`;

      if (!this.test(new AttributePath(player_path, 'name'))) {
        break;
      }

      team.players.push({
        name: SplitPlayerName(this.peek(new AttributePath(player_path, 'name'))),
        position: this.peek(new AttributePath(player_path, 'pos'), { type: 'string', default: '', text_transform: 'upper' }),
        key: this.peek(new AttributePath(player_path, 'uni'), { type: 'number' }),
      });
    }

    this.sport.teams.push(team);
  }

  importTeamStats(team) {
    const path = `/${this.game}/team[@id='${this.ids[team]}']`;
    const linescore = `${path}/linescore`;
    const totals = `${path}/totals`;
    const team_id = `team.${(team === 0 ? 'home' : 'visiting')}`;
    this.select(`${team_id}.score`, new AttributePath(linescore, 'score'));
    this.select(`${team_id}.record`, new AttributePath(path, 'record'));

    const rushing_yards = this.select(
      `${team_id}.yards.rush`,
      new AttributePath(`${totals}/rush`, 'yds')
    );

    const passing_yards = this.select(
      `${team_id}.yards.pass`,
      new AttributePath(`${totals}/pass`, 'yds')
    );

    this.set(`${team_id}.yards`, (rushing_yards + passing_yards));
    this.select(`${team_id}.fumbles`, new AttributePath(`${totals}/fumbles`, 'no'));
    this.select(`${team_id}.fumbles.lost`, new AttributePath(`${totals}/fumbles`, 'lost'));
    this.select(`${team_id}.touchdowns`, new AttributePath(`${totals}/scoring`, 'td'));
    this.select(`${team_id}.firstdowns`, new AttributePath(`${totals}/firstdowns`, 'no'));

    const possession_time = this.select(
      `${team_id}.possession`,
      new AttributePath(`${totals}/misc`, 'top')
    );

    const possession_minutes = Number(possession_time.substr(0, 2));
    const possession_seconds = Number(possession_time.substr(3, 2));
    this.set(`${team_id}.possession.seconds`, (60 * possession_minutes + possession_seconds));
    this.select(`${team_id}.penalties`, new AttributePath(`${totals}/penalties`, 'no'));

    const passes_complete = this.select(
      `${team_id}.passes.complete`,
      new AttributePath(`${totals}/pass`, 'comp')
    );

    const passes_attempts = this.select(
      `${team_id}.passes.attempts`,
      new AttributePath(`${totals}/pass`, 'att')
    );

    this.set(
      `${team_id}.passes.percent`,
      (passes_complete / passes_attempts * 100)
    );

    this.select(`${team_id}.passes.intercepted`, new AttributePath(`${totals}/pass`, 'int'));
    this.select(`${team_id}.receptions`, new AttributePath(`${totals}/rcv`, 'no'));
    this.select(`${team_id}.kickoffs`, new AttributePath(`${totals}/ko`, 'no'));

    const field_goals_made = this.select(
      `${team_id}.fieldgoals.made`,
      new AttributePath(`${totals}/fg`, 'made')
    );

    const field_goals_attempts = this.select(
      `${team_id}.fieldgoals.attempts`,
      new AttributePath(`${totals}/fg`, 'att')
    );

    this.set(
      `${team_id}.fieldgoals.percent`,
      (field_goals_made / field_goals_attempts * 100)
    );

    this.set(
      `${team_id}.turnovers`,
      (this.get(`${team_id}.passes.intercepted`) + this.get(`${team_id}.fumbles.lost`))
    );

    for (let p = 0; p < 4; ++p) {
      const period = (p + 1);
      const lineprd = `${linescore}/lineprd[@prd='${period}']`;
      const path = new AttributePath(lineprd, 'score');

      if (this.test(path)) {
        this.select(`${team_id}.score.period${period}`, path);
      } else {
        this.set(`${team_id}.score.period${period}`, null);
      }
    }
  }

  importPlayerStats(player, index) {
    const id = this.ids[player.team];
    const team = this.sport.teams[player.team];
    const uni = team.players[player.player].key;
    const path = `/${this.game}/team[@id='${id}']/player[@uni='${uni}']`;

    if (!this.test(new AttributePath(path, 'name'))) {
      return false;
    }

    const player_name = SplitPlayerName(
      this.peek(new AttributePath(path, 'name'))
    );

    this.set('player.name.long', player_name.long);
    this.set('player.name.first', player_name.first);
    this.set('player.name.last', player_name.last);
    this.select('player.position', new AttributePath(path, 'pos'), { index, text_transform: 'upper' });
    this.select('player.number', new AttributePath(path, 'uni'), { index });

    const passes_complete = this.select(
      'player.passes.completed',
      new AttributePath(`${path}/pass`, 'comp'),
      { index }
    );

    const passes_attempts = this.select(
      'player.passes.attempts',
      new AttributePath(`${path}/pass`, 'att'),
      { index }
    );

    this.set(
      'player.passes.percent',
      (passes_complete / passes_attempts * 100)
    );

    this.select('player.passes.longest', new AttributePath(`${path}/pass`, 'long'), { index });
    this.select('player.passes.intercepts', new AttributePath(`${path}/pass`, 'int'), { index });
    this.select('player.yards.pass', new AttributePath(`${path}/pass`, 'yds'), { index });

    const pass_td = this.select(
      'player.touchdowns.pass',
      new AttributePath(`${path}/pass`, 'td'),
      { index }
    );

    const rush_td = this.select(
      'player.touchdowns.rush',
      new AttributePath(`${path}/rush`, 'td'),
      { index }
    );

    this.set('player.touchdowns', (pass_td + rush_td), index);
  }

}

// -------------------------------------------------------------------------- //

export default SCFootballImporter;

// -------------------------------------------------------------------------- //
