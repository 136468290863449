
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Button, ButtonBase, CircularProgress,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Grid, IconButton, Menu, MenuItem,
  Paper, TextField,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  Delete as DeleteIcon, DotsVertical,
  Folder as FolderIcon, RenameBox as RenameBoxIcon,
  SubdirectoryArrowLeft as SubdirectoryArrowLeftIcon,
} from 'mdi-material-ui';

import {graphql, compose, Mutation} from 'react-apollo';
import classNames from 'classnames';
import gql from 'graphql-tag';

// -------------------------------------------------------------------------- //

const GET_FOLDER = gql`
  query ($folder_id: ID) {
    folder(
      folderId: $folder_id
    ) {
      parent {
        name
        id
      }
    }
  }
`;

const DELETE_FOLDER = gql`
    mutation deleteFolders($id: [ID!]!) {
      deleteFolders(folders: $id){
        count
      }
    }
`;

const RENAME_FOLDER=gql`
    mutation renameFolder($folderId:ID!,$name:String!) {
      renameFolder(folderId:$folderId,name:$name){
        name
      }
    }
`;

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  folder_paper: {
    height: theme.spacing(7),
    position: 'relative',
    cursor: 'cursor',
    userSelect: 'none',
    width: '14vw',
    borderRadius:'7px'
  },
  active_folder: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  folder: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(2),
    color: '#8F8F8F',
  },
  folder_label: {
    lineHeight: `${theme.spacing(7)}px`,
    textIndent: theme.spacing(5),
    color: 'inherit',
    fontSize: 12,
  },
  grid: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  menuButton: {
    position: 'absolute',
    right: 4,
    bottom: 0,
  }
});

// -------------------------------------------------------------------------- //

class FolderList extends React.Component {

  state = {
    menuAnchor: null,
    menu: false,
    editFolderDialog: false,
    renameFolderValidation: '',
    folderName: '',
    folderId: null,
    busy: false,
    openDialog: false,
    type: '',
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Typography id="main-content" key="header" variant="caption" gutterBottom>
          Folders
        </Typography>
        <Grid spacing={2} container key="folders" className={classes.grid}>
          {this.renderFolders()}
          {this.renderMenuDialog()}
          {this.renderDialog()}
        </Grid>
      </React.Fragment>
    );
  }

  renderDialog() {
    const {classes} = this.props;
    const {busy, openDialog, folderId,type,folderName, renameFolderValidation} = this.state;
    let tittle,text,_mutation,params,doAction,btn;
    switch (type) {
      case 'delete':
        tittle = 'Delete folder';
        text = 'Deleting this folder will also delete all of its contents. This action cannot be undone.';
        _mutation = DELETE_FOLDER;
        params = {
          id: [folderId],
        };
        doAction = this.deleteContent();
        btn = 'Delete'
        break
      case 'rename':
      default:
        tittle = 'Rename Folder';
        text = '';
        _mutation = RENAME_FOLDER;
        params = {
          folderId: [folderId],
          name: folderName,
        };
        doAction = this.renameFolder();
        btn = 'Confirm';
    }

    return (
        <Dialog open={openDialog && folderId !== null}>
          <DialogTitle>{tittle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {text}
            </DialogContentText>
            {
              type === 'rename' && (
                  <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Folder name"
                      type="text"
                      fullWidth
                      error={renameFolderValidation.length !== 0}
                      helperText={renameFolderValidation}
                      value={folderName || ''}
                      onChange={(e) => this.setState({ folderName: e.target.value })}
                  />
              )
            }
          </DialogContent>
          <DialogActions>
            <Button
                color="primary"
                disabled={busy}
                onClick={() => this.closeDeleteDialog()}
            >
              Cancel
            </Button>
            <Mutation
                awaitRefetchQueries
                mutation={_mutation}
                variables={params}
                refetchQueries={[
                  'getContents',
                ]}
            >
              {(mutation) => (
                  <Button
                      color="primary"
                      disabled={busy}
                      onClick={() => doAction(mutation)}
                  >
                    {btn}
                    {busy && <div className={classes.progress}><CircularProgress size={32} /></div>}
                  </Button>
              )}
            </Mutation>
          </DialogActions>
        </Dialog>
    );
  }

  renameFolder = () => (mutation) => {
    const { folderName } = this.state;
    if (folderName.length > 20) {
      this.setState({ renameFolderValidation: 'Folder name have a max character limit of 20' });
    } else if (folderName.length > 0) {
      this.setState({
        busy: true,
      },() => {
        mutation().then(() => {
          this.setState({
            busy: false,
            openDialog: false,
            menu: false,
            renameFolderValidation: '',
          });
        });
      });
    } else {
      this.setState({ renameFolderValidation: 'Name cannot be empty' });
    }
  }

  deleteContent = () => (mutation) => {
    this.setState({
      busy: true,
    }, () => {
      mutation().then(() => {
        this.setState({
          busy: false,
          openDialog: false,
          menu: false
        });
      });
    });
  }

  closeMenu = () => {
    this.setState({menu: false})
  }
  openMenu = (anchor,id,folderName) => {
    this.setState({
      menuAnchor:anchor,
      menu:true,
      folderId: id,
      folderName
    })
  }
  renderMenuDialog = () => (
      <Menu
          id="long-menu"
          anchorEl={this.state.menuAnchor}
          open={this.state.menu}
          onClose={this.closeMenu}
      >
        <MenuItem onClick={() => this.setState({
          openDialog: true, type: 'delete',
        })}>
          <DeleteIcon />
          {' '}
          Remove
        </MenuItem>
        <MenuItem onClick={() => this.setState({openDialog: true, type: 'rename'})}>
          <RenameBoxIcon />
          {' '}
          Rename
        </MenuItem>
      </Menu>
  )

  renderFolders = () => {
    const {
      classes,
      data,
      drag,
      dragEnd,
      dragOver,
      drop,
      folders,
      setSelected,
      type,
    } = this.props;

    let output = [];

    if (!data.loading && data.folder) {
      const parent_folder = {
        id: (data.folder.parent ? data.folder.parent.id : '')
      };

      output.push((
        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          lg={3}
          xl={3}
          key="parent-folder"
        >
            <Paper
              onDragOver={dragOver}
              onDragEnd={dragEnd}
              onDrop={(e) => {
                if (parent_folder.id) {
                  drop(parent_folder.id)(e);
                } else {
                  drop(null, type)(e);
                }
              }}
              className={classNames(
                classes.folder_paper,
                { [classes.active_folder]: (this.props.selected.indexOf(parent_folder.id) !== -1) }
              )}
              onClick={setSelected(parent_folder, 'folder')}
            >
              <SubdirectoryArrowLeftIcon className={classes.folder}/>
              <Typography variant="body1" noWrap className={classes.folder_label}>
                Parent Folder
              </Typography>
            </Paper>
        </Grid>
      ));
    }

    folders.forEach((folder) => {
      const active = this.props.selected.indexOf(folder.id) !== -1;
      let classname;

      if (!active) {
        classname = classes.folder_paper;
      } else {
        classname = classNames(classes.folder_paper, classes.active_folder);
      }

      const on_drag_start = (e) => {
        drag(folder, 'folder')({ ...e, parent_folder: ((data.folder && data.folder.parent && data.folder.parent.id) || null) });
      };

      output.push((
        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          lg={3}
          xl={3}
          key={folder.id}
        >
          <Paper draggable onDragStart={on_drag_start} component={ButtonBase} onDragEnd={dragEnd} onDrop={drop(folder.id)} onDragOver={dragOver} className={classname} onDoubleClick={setSelected(folder, 'folder')}>
            <div style={{display:'flex'}}>
              <div>
                <FolderIcon className={classes.folder} />
                <Typography variant="body1" noWrap className={classes.folder_label}>
                  {folder.name}
                </Typography>
              </div>
              <>
                <IconButton
                    className={classes.menuButton}
                    tabIndex="0"
                    onClick={e => this.openMenu(e.currentTarget,folder.id,folder.name)}
                >
                  <DotsVertical />
                </IconButton>
              </>
            </div>
          </Paper>
        </Grid>
      ));
    });
    return output;
  }

}

// -------------------------------------------------------------------------- //

export default compose(
  graphql(GET_FOLDER),
  withStyles(STYLES),
)(FolderList);

// -------------------------------------------------------------------------- //
