
export default {

  None: {
    type: 'None',
    name: 'None',
    preview: '',
  },

  FadeOut: {
    type: 'FadeOut',
    name: 'Fade Out',
    preview: '',
  },

  PushUp: {
    type: 'PushUp',
    name: 'Push Up',
    preview: '',
  },

  Split: {
    type: 'Split',
    name: 'Split',
    preview: '',
  },

  WipeLeft: {
    type: 'WipeLeft',
    name: 'Wipe Left',
    preview: '',
  },

};
