import React, { Component } from 'react';
import { Query } from 'react-apollo'
import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';

const styles = theme => ({
  img: {
  	width: '100%',
    backgroundImage: "url('https://ss3-assets.s3.us-east-2.amazonaws.com/grid.png')",
    backgroundRepeat: 'repeat',
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
  }
});

const GET_WIDGETS = gql`
	query {
		templateSearch(
			where: {
				visible: WIDGET
			}
		) {
			id
			url
			name
			image
			width
			height
		}
	}
`;

class GraphicSelect extends Component {
	select = (id, widget) => (e) => {
		const { onSelect } = this.props;

		if (onSelect) {
			onSelect(id, widget);
		}
	}

	render() {
		const { classes } = this.props;
		return (
			<Query query={GET_WIDGETS}>
				{
					({ data }) => {
						return data && data.templateSearch ?
							data.templateSearch.map((template, index) => (
								<div key={template.id} style={{padding: 16 }}>
									<img
										alt={template.name}
										className={classes.img}
										crossOrigin="anonymous"
										onClick={this.select(template.id, template)}
										src={`https://ss3-templates.s3.us-east-2.amazonaws.com/${template.id}.jpg`} />
								</div>
							)) : null
					}
				}
			</Query>
		);
	}
}

export default withStyles(styles)(GraphicSelect);
