
import * as React from 'react';

import {
  withStyles,
} from '@material-ui/core';

const style = () => ({
  container: {
    position: 'relative',
    overflow: 'hidden',
    height: 0,
  },
  content: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
  },
});

const AspectContainer = (props) => {
  const { classes, aspect, children } = props;

  const style = {
    paddingTop: `${aspect * 100}%`,
  };

  return (
    <div className={classes.container} style={style} {...props}>
      <div className={classes.content}>
        {children}
      </div>
    </div>
  );
};

export default withStyles(style)(AspectContainer);
