

// -------------------------------------------------------------------------- //

import Sport from './Sport';

// -------------------------------------------------------------------------- //

const TEAMS = [
  'Home',
  'Visiting',
];

// -------------------------------------------------------------------------- //

class HockeySport extends Sport {

  constructor() {
    super();

    this.push('venue.faceoffs', 'Total Faceoffs');

    for (let p = 0; p < 3; ++p) {
      const period = `period${p + 1}`;
      this.push(`venue.faceoffs.${period}`, `Faceoffs`);
    }

    for (let t = 0; t < 2; ++t) {
      const team = `[${TEAMS[t]}]`;
      const team_id = `team.${TEAMS[t].toLowerCase()}`;
      this.push(`${team_id}.id`, `${team} Team ID`, { type: 'string' });
      this.push(`${team_id}.name`, `${team} Team Name`, { type: 'string' });
      this.push(`${team_id}.goals`, `${team} Total Goals`);
      this.push(`${team_id}.shots`, `${team} Total Shots Taken`);
      this.push(`${team_id}.saves`, `${team} Total Goals Saved`);
      this.push(`${team_id}.saves.percent`, `${team} Total Goal Saves %`);
      this.push(`${team_id}.powerplays.goals`, `${team} Total Power Play Goals`);
      this.push(`${team_id}.powerplays.attempts`, `${team} Total Power Play Attempts`);
      this.push(`${team_id}.powerplays.percent`, `${team} Total Power Play %`);
      this.push(`${team_id}.faceoffs.won`, `${team} Total Face Offs Won`);
      this.push(`${team_id}.faceoffs.lost`, `${team} Total Face Offs Lost`);
      this.push(`${team_id}.faceoffs.percent`, `${team} Total Face Offs %`);
      this.push(`${team_id}.penalties`, `${team} Total Penalties`);
      this.push(`${team_id}.penalties.minutes`, `${team} Total Penalty Minutes`);

      for (let p = 0; p < 3; ++p) {
        const period = `period${p + 1}`;
        this.push(`${team_id}.goals.${period}`, `${team} Goals`);
        this.push(`${team_id}.shots.${period}`, `${team} Shots Taken`);
        this.push(`${team_id}.saves.${period}`, `${team} Goals Saved`);
        this.push(`${team_id}.saves.percent.${period}`, `${team} Goal Saves %`);
        this.push(`${team_id}.powerplays.goals.${period}`, `${team} Power Play Goals`);
        this.push(`${team_id}.powerplays.attempts.${period}`, `${team} Power Play Attempts`);
        this.push(`${team_id}.powerplays.percent.${period}`, `${team} Power Play %`);
        this.push(`${team_id}.faceoffs.won.${period}`, `${team} Face Offs Won`);
        this.push(`${team_id}.faceoffs.lost.${period}`, `${team} Face Offs Lost`);
        this.push(`${team_id}.faceoffs.percent.${period}`, `${team} Face Offs %`);
        this.push(`${team_id}.penalties.${period}`, `${team} Penalties`);
        this.push(`${team_id}.penalties.minutes.${period}`, `${team} Penalty Minutes`);
      }
    }
  }

  update(id, version) {
    if (version < 3) {
      // venue-scope stats are now in 'venue.' rather than 'location.'
      if (/^location\./.test(id)) {
        return { type: 'rename', value: id.replace(/^location\./, 'venue.') }
      }
    }

    // these remaps use 'visit' for the opposing team, not 'visiting'
    let remaps = {
      'team1': 'team.home.name',
      'team2': 'team.visiting.name',
      'team.home': 'team.home.name',
      'team.home_id': 'team.home.id',
      'team.visit': 'team.visiting.name',
      'team.visit_id': 'team.visiting.id',
    };

    if (remaps[id]) {
      return { type: 'rename', value: remaps[id] };
    }

    for (let t = 0; t < 2; ++t) {
      const team =  TEAMS[t].toLowerCase();
      const old_id = `${team}`;
      const new_id = `team.${team}`;

      remaps = {
        [`goals.${old_id}.total`]: `${new_id}.goals`,
        [`goals.${old_id}.period1`]: `${new_id}.goals.period1`,
        [`goals.${old_id}.period2`]: `${new_id}.goals.period2`,
        [`goals.${old_id}.period3`]: `${new_id}.goals.period3`,

        [`shots_taken.${old_id}.total`]: `${new_id}.shots`,
        [`shots_taken.${old_id}.period1`]: `${new_id}.shots.period1`,
        [`shots_taken.${old_id}.period2`]: `${new_id}.shots.period2`,
        [`shots_taken.${old_id}.period3`]: `${new_id}.shots.period3`,

        [`goals_saved.${old_id}.total`]: `${new_id}.saves`,
        [`goals_saved.${old_id}.period1`]: `${new_id}.saves.period1`,
        [`goals_saved.${old_id}.period2`]: `${new_id}.saves.period2`,
        [`goals_saved.${old_id}.period3`]: `${new_id}.saves.period3`,

        [`goalkeeping_percent.${old_id}.total`]: `${new_id}.saves.percent`,
        [`goalkeeping_percent.${old_id}.period1`]: `${new_id}.saves.percent.period1`,
        [`goalkeeping_percent.${old_id}.period2`]: `${new_id}.saves.percent.period2`,
        [`goalkeeping_percent.${old_id}.period3`]: `${new_id}.saves.percent.period3`,

        [`power_play_goals.${old_id}.total`]: `${new_id}.powerplays.goals`,
        [`power_play_goals.${old_id}.period1`]: `${new_id}.powerplays.goals.period1`,
        [`power_play_goals.${old_id}.period2`]: `${new_id}.powerplays.goals.period2`,
        [`power_play_goals.${old_id}.period3`]: `${new_id}.powerplays.goals.period3`,

        [`power_play_attempts.${old_id}.total`]: `${new_id}.powerplays.attempts`,
        [`power_play_attempts.${old_id}.period1`]: `${new_id}.powerplays.attempts.period1`,
        [`power_play_attempts.${old_id}.period2`]: `${new_id}.powerplays.attempts.period2`,
        [`power_play_attempts.${old_id}.period3`]: `${new_id}.powerplays.attempts.period3`,

        [`power_play_kills_percent.${old_id}.total`]: `${new_id}.powerplays.killpercent`,
        [`power_play_kills_percent.${old_id}.period1`]: `${new_id}.powerplays.killpercent.period1`,
        [`power_play_kills_percent.${old_id}.period2`]: `${new_id}.powerplays.killpercent.period2`,
        [`power_play_kills_percent.${old_id}.period3`]: `${new_id}.powerplays.killpercent.period3`,

        [`face_offs_won.${old_id}.total`]: `${new_id}.faceoffs.won`,
        [`face_offs_won.${old_id}.period1`]: `${new_id}.faceoffs.won.period1`,
        [`face_offs_won.${old_id}.period2`]: `${new_id}.faceoffs.won.period2`,
        [`face_offs_won.${old_id}.period3`]: `${new_id}.faceoffs.won.period3`,

        [`penalties.${old_id}.total`]: `${new_id}.penalties`,
        [`penalties.${old_id}.period1`]: `${new_id}.penalties.period1`,
        [`penalties.${old_id}.period2`]: `${new_id}.penalties.period2`,
        [`penalties.${old_id}.period3`]: `${new_id}.penalties.period3`,

        [`penalty_minutes.${old_id}.total`]: `${new_id}.penalties.minutes`,
        [`penalty_minutes.${old_id}.period1`]: `${new_id}.penalties.minutes.period1`,
        [`penalty_minutes.${old_id}.period2`]: `${new_id}.penalties.minutes.period2`,
        [`penalty_minutes.${old_id}.period3`]: `${new_id}.penalties.minutes.period3`,
      };

      if (remaps[id]) {
        return { type: 'rename', value: remaps[id] };
      }
    }

    return super.update(id, version);
  }

}

// -------------------------------------------------------------------------- //

export default HockeySport;

// -------------------------------------------------------------------------- //
