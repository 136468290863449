import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
  poster: {
    width: '100%',
  },
  image: {
    width: '100%',
  },
  nonVisible: {
    width: '100%',
    opacity: 0.2,
  },
});

class TemplatePoster extends Component {
  constructor() {
    super();
    this.state = {
      componentWidth: 0,
    };
  }

  componentDidMount() {
    this.setDimensions();
  }

  setDimensions() {
    this.setState({
      componentWidth: this.component.offsetWidth,
    });
  }
//src={`https://resizer.scoreshots.com/${componentWidth}/${template.image}`}
  render() {
    const { template, classes } = this.props;
    const { componentWidth } = this.state;
    const myUrl = `https://ss3-templates.s3.us-east-2.amazonaws.com/${template.id}.jpg`
    let image;
    if (componentWidth !== 0) {
      image = (
        <img
          className={
            template.visible === 'VISIBLE' ?
            classes.image :
            classes.nonVisible
          }
          src={`${myUrl}`}
          alt="Template preview"
        />
      );
    }

    return (
      <div
        className={classes.poster}
        ref={(component) => { this.component = component; }}
        style={{
          height: componentWidth,
        }}
      >
        {image}
      </div>
    );
  }
}

TemplatePoster.propTypes = {
  template: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(TemplatePoster);
