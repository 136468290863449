
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Slider from  'rc-slider';
import Draggable from 'react-draggable';
import EditKeyFrameProps from './EditKeyFrameProps';
import {ANIMATION_KEYS} from './AnimationHelpers';

const ToolTip = Slider.createSliderWithTooltip
const ESlider = ToolTip(Slider);
const styles = theme => ({

  filterHolder: {
    padding : '1%',
    width : '30%',
    backgroundColor : '#fff',
    marginTop : '4%',
    marginLeft : '1%',
    position:'absolute',
    zIndex : 500
  },
  button: {
    height : '36px',
    marginLeft : '1%'
  },
  keyFrameDots : {
    width : '10px',
    height : '10px',
    borderRadius : '50%',
    border : 'solid 1px blue',
    display : 'inline-block',
    marginLeft : '10px'
  },
  currentKeyFrameDots : {
    width : '10px',
    height : '10px',
    borderRadius : '50%',
    border : 'solid 1px red',
    backgroundColor : 'blue',
    display : 'inline-block',
    marginLeft : '10px'
  },
  slider : {zIndex : 1000}
});

class DopeSheet extends Component {
  state = {
    slideDuration : 0, // current duration of the slide attach to the canvas.
    min : 0, // the min for both sliders
    max : 120, // the max for both sliders.
    keyFrames : [], // key frames for the object
    updateIndex : 0, // index being updated.
    active_id: null,
    startTime : 0,
    top : -1000,
    left : -1000,
    keyFrameStep : 0,
    isAnimating : false,
    open : false,
  }

  _startsWith = (_needle,_hayStack) => {
        let needle = _needle.length > _hayStack.length ? _hayStack : _needle;
        let hayStack = _hayStack.length > _needle.length   ? _hayStack : _needle;

        for (let i = 0; i < needle.length; i++){
            let _needleChar = needle.charAt(i);
            let _hayStackChar = hayStack.charAt(i);
            if(_needleChar !== _hayStackChar){
              return false
            }
        }

        return true;
  }

  setColor = (active, prop ) => {

      let newProp = "#000000";
      if(active[prop]){
        newProp = active[prop];
        if(this._startsWith('rgb(',active[prop]) || this._startsWith('rgba(',active[prop])){
           newProp = active[prop];
        }else{
          let hashIndex = active[prop].indexOf("#");
          hashIndex = active[prop].slice(hashIndex);
          let regex = /[^0-9a-f]/gi;
           newProp = '#'+ hashIndex.replace(regex,'');
       }
      }
      return newProp;
  }

  addKeyFrame = () => {
    const { canvasUtil } = this.props;
    const { keyFrames } = this.state;
    const makeAnimObject = this.makeAnimObject;
    const canvasDuration = canvasUtil.canvas.duration;
    if(canvasDuration === 0 ){ return; }
    let _keyFrames = keyFrames;
    // get the active object from the canvas.
    let keyframe = Object.assign({},canvasUtil.canvas.getActiveObject());
    let keyFrameProps = makeAnimObject(keyframe);
    if( 'animation' in keyframe ){
      // get the last key frame start time.
      let _the_frames = (keyframe.animation.animations || []);
      let _startTime = _the_frames.length > 0 ? _the_frames[_the_frames.length - 1 ].startTime + .5 : 0;
      if(_startTime > canvasDuration ){

        this.setState({ open : true });
        return;
      }
      // remove the animation from the key frame.
      delete keyframe['animation'];

      // add the duration.
      keyframe = Object.assign(keyFrameProps,{ startTime : _startTime});
      _keyFrames.push(keyframe);
      // add keyframes to canvas
      canvasUtil.canvas.getActiveObject().animation.animations = _keyFrames;
    }else{
      // add the duration.
      keyframe = Object.assign(keyFrameProps,{ startTime : 0});
      _keyFrames.push(keyframe);
      // add keyframe to canvas
      canvasUtil.canvas.getActiveObject().animation = { version : 1, animations : _keyFrames };
    }

    let theNewIndex = _keyFrames.length - 1;
    // add key frame to state.
    this.setState( { keyFrames : _keyFrames, updateIndex : theNewIndex, keyFrameStep : theNewIndex } );

  }

  setSlideDuration = (e) => {
    const { canvasUtil } = this.props;
    canvasUtil.canvas.duration = e;
    this.setState({ slideDuration : e});
  }

  showToolTip = (_val) => {
    let _value = _val + " secs";
    if(_val >= 60 ){

      _value = Math.floor(_val / 60) + " mins ";
      let remainder = (_val % 60);
      if(remainder > 0){
        _value += remainder + " secs"
      }
    }


    return {
      placement: 'top',
      prefixCls: 'rc-slider-tooltip',
      overlay: value => (value = _value),
      zIndex : 1000
    }
  }

  showKeyFrames = (keyFramesArray) => {
    return keyFramesArray.map( (o,i) => o.startTime );
  }

  makeAnimObject = (_fromObject, exclude = {}) => {

    let animObj = {};
    for( let i = 0; i < ANIMATION_KEYS.length; i++){
      let currentKey = ANIMATION_KEYS[i];
      if( currentKey in _fromObject ){
        if(_fromObject[currentKey] === null || currentKey in exclude ){continue;}

        if(currentKey === 'fill'){
          _fromObject[currentKey] = this.setColor( _fromObject,currentKey )
        }
        animObj[currentKey] = _fromObject[currentKey];
      }
    }

    return animObj;
  }

  viewAnimation = () => {
    const { canvasUtil } = this.props;
    const { updateIndex } = this.state;
    const objToAnimate = canvasUtil.canvas.getActiveObject();
    const canvas = canvasUtil.canvas;
    const makeAnimObject = this.makeAnimObject;
    // get the objects key frames
    const keyFrames = objToAnimate.animation.animations;
    let positionA = keyFrames[updateIndex - 1 ];
    let positionB = keyFrames[updateIndex];
    let dontAnimate = {"fill" : "","shadow" : "","stroke" : "","fillOverride" : "", "strokeOverride" : ""};
    let moveA = makeAnimObject(positionA,dontAnimate);
    let moveB = makeAnimObject(positionB,dontAnimate);

    this.setState({isAnimating : true});
    objToAnimate.animate(moveA, {
      onChange: canvas.renderAll.bind(canvas),
      onComplete : () => {
        objToAnimate.animate(moveB, {
          onChange: canvas.renderAll.bind(canvas),
          duration: 300,
          onComplete : () => {
            objToAnimate.setCoords();
            this.setState({isAnimating : false});
          }
        })
      }
    });

  }

  keyFrameOnClick = (index, _object) => {
    const { canvasUtil } = this.props;
    const active = canvasUtil.canvas.getActiveObject();

    if (index >= 0) {
      for (let key in _object) {
        active.set(key, _object[key]);
      }
    }

    if (active.animation) {
      active.animation.last_key_frame = index;
    }

    this.setState({
      keyFrameStep: index,
      updateIndex: index,
      startTime: _object.startTime,
    }, () => canvasUtil.canvas.requestRenderAll());
  }

  showKeyFrameDots = keyFramesArray => {
    const {classes} = this.props;
    const { keyFrameStep } = this.state;
    const dots = keyFramesArray.map( (o,i) => {
      let curstepStyle = keyFrameStep === i ? classes.currentKeyFrameDots : classes.keyFrameDots;
      let editFunction = () => this.keyFrameOnClick(i,o);
      return <span
      onClick={editFunction}
      key={i}
      className={curstepStyle}>
      </span>
    });
    return <div>{dots}</div>
  }

  updateKeyFrameViaProps = ( index , obj ) => {
    const { keyFrames, startTime} = this.state;
    const { canvasUtil } = this.props;
    obj['startTime'] = startTime;

    // make keyframe from array
    keyFrames[index] = obj;
    canvasUtil.canvas.getActiveObject().animation.animations[index] = obj;
    this.setState({keyFrames});

  }
  shouldSetCoords = ( property ) => {
    const _needCoordsSet = {
      top : "",
      left : "",
      width : "",
      height : "",
      scaleX : "",
      scaleY : "",
      skewX : "",
      skewY : "",
      padding : "",
      angle : "",
      strokeWidth : ""
    }
    if( property in _needCoordsSet ){
      return true;
    }

    return false;
  }
  changeActiveProp = ( prop,value ) => {
      const {  canvasUtil } = this.props;
      const active = canvasUtil.canvas.getActiveObject();

      let newProp = value;
      active.set(prop, newProp);
      // some properties need setCoords to function right
      // see : https://github.com/fabricjs/fabric.js/wiki/When-to-call-setCoords

      if(this.shouldSetCoords(prop)){
        active.setCoords();
      }
      canvasUtil.canvas.requestRenderAll();
      //this.forceUpdate();
  }
  updateKeyFrame = () => {
    const { keyFrames, updateIndex, startTime} = this.state;
    const { canvasUtil } = this.props;
    const makeAnimObject = this.makeAnimObject;
    // get keyframes
    let _keyFrames = keyFrames;

    // get the current canvas obj from canvasUtil remove its animation key and add starttime key.
    let keyframe = canvasUtil.canvas.getActiveObject();
    //delete keyframe['animation'];

    let keyframeProps = Object.assign(makeAnimObject(keyframe),{ startTime : startTime});
    // at the updateIndex index add the current object onto keyFrames.
    _keyFrames[updateIndex] = keyframeProps;

    // re-add all animations to the current canvas object
    canvasUtil.canvas.getActiveObject().animation.animations = _keyFrames;

    // set the keyFrames back to state.
    this.setState({ keyFrames : _keyFrames , updateIndex: -100},() => this.setState({updateIndex}));
  }

  updateKeyFrameFromSlider =(e) =>{
    const { keyFrames, updateIndex } = this.state;
    const { canvasUtil } = this.props;
    let _kf = keyFrames;
     _kf[updateIndex]['startTime'] = e;
    canvasUtil.canvas.getActiveObject().animation.animations = _kf;
    this.setState({ keyFrames : _kf, startTime : e });

  }

  deleteKeyFrame = () => {
    const { keyFrames, updateIndex} = this.state;
    const { canvasUtil } = this.props;
    let _keyFrames = keyFrames;
    _keyFrames.splice(updateIndex,1);
    canvasUtil.canvas.getActiveObject().animation.animations = _keyFrames;
    this.setState({ keyFrames : _keyFrames, updateIndex : -1000});
  }

  editKeyFrame = () => {
    const { slideDuration, min, startTime,isAnimating,updateIndex,keyFrames} = this.state;
    const { canvasUtil } = this.props;
    let canvas = canvasUtil.canvas.getActiveObject();
    if(!canvas){return <span></span>}
    if(!canvas.animation){return <span></span>}
    if(!canvas.animation.animations){return <span></span>}
    let currentAnimation = updateIndex > -100 ? canvasUtil.canvas.getActiveObject().animation.animations[updateIndex] : {};
    const showToolTip = this.showToolTip;
    const updateKeyFrame = this.updateKeyFrame;
    const deleteKeyFrame = this.deleteKeyFrame;
    const viewAnimation = this.viewAnimation;
    const updateKeyFrameViaProps = this.updateKeyFrameViaProps;
    const showKeyFrameDots = this.showKeyFrameDots(keyFrames);
    const addKeyFrame = this.addKeyFrame;
    const resetUpdateIndex = () => this.setState({ updateIndex : -100 });
    return <React.Fragment>
    Set Start Time:<br/>
    <ESlider
    step={.1}
    dots={true}
    tipProps={showToolTip(startTime)}
    min={min}
    max={slideDuration}
    value={startTime}
    onChange={this.updateKeyFrameFromSlider} />
    <br/>


    <Button disabled={ (slideDuration === 0 )} onClick={addKeyFrame}>Add</Button>
    <Button disabled={isAnimating} onClick={updateKeyFrame}>Update</Button>
    <Button disabled={isAnimating} onClick={ () => this.setState({ startTime : 0, updateIndex : -100})}>Cancel</Button>
    {updateIndex >0 ? <Button disabled={isAnimating} onClick={viewAnimation}>View</Button> : null}
    <Button disabled={isAnimating} onClick={deleteKeyFrame}>Delete</Button>
    {showKeyFrameDots}
    <EditKeyFrameProps
          keyFrameIndex={updateIndex}
          allProps={ANIMATION_KEYS}
          currentAnimation={currentAnimation}
          updateKeyFrameViaProps={updateKeyFrameViaProps}
          activeObject={canvasUtil.canvas.getActiveObject()}
          changeActiveProp={this.changeActiveProp}
          resetUpdateIndex={resetUpdateIndex}
          />

    </React.Fragment>

  }

  deleteAllKeyFrames = () => {
    const { canvasUtil } = this.props;
    if( !('animation' in canvasUtil.canvas.getActiveObject()) ){return;}
    let willDeleteAll = window.confirm('Are you sure you want to delete all key frames?');
    if(!willDeleteAll){return;}
    delete canvasUtil.canvas.getActiveObject()['animation'];
    this.setState({keyFrames : [] });
  }

  componentDidMount(){
    const canvas = this.props.canvasUtil.canvas;
    const active = canvas.getActiveObject();

    if(!active){
      this.setState({updateIndex : -100});
      return;}
    if(!('animation' in active)){
      this.setState({updateIndex : -100});
      return;}
    if(!('animations' in active.animation)){
      this.setState({updateIndex : -100});
      return;}

    if(!("duration" in canvas)){
       canvas['duration'] = 0;
    }

    let state = {
      keyFrames : active.animation.animations,
      top : active.top,
      left : active.left,
      slideDuration : canvas.duration,
    };

    if (active) {
      const { active_id } = this.state;

      if (active_id !== active.uuid) {
        const { last_key_frame = 0 } = active.animation;

        state = {
          ...state,
          active_id: active.uuid,
          keyFrameStep: last_key_frame,
          updateIndex: last_key_frame,
        };
      }
    }

    this.setState(state);
  }

  componentDidUpdate(prev){
    const canvas = this.props.canvasUtil.canvas;

    if(!("duration" in canvas)){
       canvas['duration'] = 0;
    }
    const activeObject = this.props.canvasUtil.canvas.getActiveObject();
    const { top , left} = this.state;
    const makeAnimObject = this.makeAnimObject;
    if(activeObject){
      if( activeObject.left !== left || activeObject.top !== top ){
        let _kf = []
        if( 'animation' in activeObject){
          if ('animations' in activeObject.animation) {
            // get key frames if animation in object
            _kf = activeObject.animation.animations;
          }
        }else{
          // add the duration.
          let keyframeProps = Object.assign(makeAnimObject(activeObject),{ startTime : 0});
          _kf.push(keyframeProps);
          // add keyframe to canvas
          activeObject.animation = { animations : _kf };

        }
        this.setState({keyFrames : _kf, top : activeObject.top, left : activeObject.left, slideDuration : canvas.duration});
      }
    }
  }

  stepThroughKeyFrames = () => {
    this.setState({isAnimating : true});
    const { keyFrameStep } = this.state;
    const { canvasUtil } = this.props;
    const canvas = canvasUtil.canvas;
    const activeObject = canvasUtil.canvas.getActiveObject();
    const keyFrames = activeObject.animation.animations;
    const makeAnimObject = this.makeAnimObject;
    let step = keyFrameStep + 1  > keyFrames.length - 1;
    if( step ){
      step = 0;
    }else{
      step = keyFrameStep + 1;
    }
    const objToAnimate = makeAnimObject(keyFrames[step],{"fill" : "","shadow" : "", "stroke" : ""});
    activeObject.animate(objToAnimate, {
      onChange: canvas.renderAll.bind(canvas),
      onComplete : () => {
        activeObject.setCoords();
        this.setState({ isAnimating : false, keyFrameStep : step });
      }
    }
  );

}

render() {
  const { min, max, keyFrames,updateIndex,isAnimating} = this.state;

  const { classes, canvasUtil } = this.props;
  const slideDuration = canvasUtil.canvas.duration;
  const activeObject = canvasUtil.canvas.getActiveObject();
  const showToolTip = this.showToolTip;
  const setSlideDuration = this.setSlideDuration;
  const showKeyFrames = this.showKeyFrames(keyFrames);
  const showKeyFrameDots = this.showKeyFrameDots(keyFrames);
  const deleteAllKeyFrames = this.deleteAllKeyFrames;
  const addKeyFrame = this.addKeyFrame;
  const editKeyFrame = this.editKeyFrame();
  const stepThroughKeyFrames = this.stepThroughKeyFrames;
  if(!activeObject){ return <Draggable handle="strong"><div className={classes.filterHolder}><strong style={{ cursor : 'grab', paddingLeft : '1%'}}>::::</strong><br/><br/><h3>Select and object from the canvas...</h3></div></Draggable>}
  if(updateIndex >= 0 ){ return <Draggable handle="strong"><div className={classes.filterHolder}><strong style={{ cursor : 'grab', paddingLeft : '1%'}}>::::</strong><br/><br/>{editKeyFrame}</div></Draggable> }
  return (
    <Draggable handle="strong">

    <div className={classes.filterHolder}>
    <strong style={{ cursor : 'grab', paddingLeft : '1%'}}>::::</strong><br/><br/>
    {/*key frame button*/}
    <Button
    disabled={ (slideDuration === 0 )}
    variant="contained"
    color="primary"
    className={classes.button}
    onClick={addKeyFrame}>
    Add KeyFrame
    </Button>

    <Button
    disabled={showKeyFrames.length === 0}
    variant="contained"
    color="secondary"
    className={classes.button}
    onClick={deleteAllKeyFrames}>
    Delete All KeyFrames
    </Button>

    {keyFrames.length > 1 ? <Button
    disabled={isAnimating}
    variant="contained"
    color="secondary"
    className={classes.button}
    onClick={stepThroughKeyFrames}>
    Play
    </Button> : null}

    <br/><br/>
    KeyFrames

    {showKeyFrameDots}<br/>

    {/*Sliders below*/}

    Slide Duration:
    <ESlider
    className={classes.slider}
    dots={true}
    tipProps={showToolTip(slideDuration)}
    min={min}
    max={max}
    value={slideDuration}
    onChange={setSlideDuration} />

    <Dialog
      open={this.state.open}
      onClose={() => this.setState({ open : false })}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Key Frame Not Added"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          The Start time of this frame is greater than the duration of the slide.
          Please change key frames so that they fit within duration of your slide or
          increase the duration of your slide.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => this.setState({ open : false })} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>

</div>

    </Draggable>

  );
}
}

DopeSheet.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DopeSheet);
