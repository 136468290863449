import React, { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

class Controls extends Component {
  getItems(category) {
    const { variables, classes, handleVariableChange } = this.props;
    const { items } = category;
    const output = [];
    items.forEach((item) => {
      if (variables[item]) {
        const variable = variables[item];
        switch (variable.type) {
          case 'text':
            output.push((
              <TextField
                key={item}
                id={item}
                label={variable.name}
                className={classes.textField}
                value={variable.value ? variable.value : ''}
                onChange={handleVariableChange(item)}
                margin="dense"
              />
            ));
            break;
          case 'number':
            output.push((
              <TextField
                key={item}
                id={item}
                label={variable.name}
                className={classes.textField}
                value={variable.value ? variable.value : ''}
                onChange={handleVariableChange(item)}
                margin="dense"
              />
            ));
            break;
          default:
            break;
        }
      }
    });
    return output;
  }
  getCategories() {
    const { categories } = this.props;
    const output = [];
    categories.forEach((category) => {
      output.push((
        <div key={category.name}>
          <Typography>
            {category.name}
          </Typography>
          {this.getItems(category)}
        </div>
      ));
    });
    return output;
  }
  render() {
    return (
      <Drawer
        open
        classes={{
          paper: this.props.classes.drawer,
        }}
        variant="persistent"
      >
        {this.getCategories()}
      </Drawer>
    );
  }
}

export default Controls;
