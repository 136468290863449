
// -------------------------------------------------------------------------- //

import React, { Component } from 'react';

import {
  Button,
  CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  withStyles, Checkbox
} from '@material-ui/core';

import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import EnhancedTable from './PrestoScheduleTable';
import { PageTitle } from '../../../title';
import history from '../../../history';
import Typography from "@material-ui/core/Typography";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

// -------------------------------------------------------------------------- //


const STYLES = (theme) => ({
  button: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: '100%', // because we can't use fullWidth on the ButtonGroup
  },
  flex: {
    flex: 1,
  },
  grid: {
    height: 'calc(100% - 72px)',
    textAlign: 'center',
  },
  loading: {
    outline: 'none', // disable keyboard focus outline
    textAlign: 'center',
  },
  modal_button: {
    boxShadow: theme.shadows[3],
    transition: theme.transitions.create('box-shadow'),
    '&:hover': {
      boxShadow: theme.shadows[6],
    },
  },
  root_desktop: {
    height: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
  },
  root_mobile: {
    height: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    width: '100%',
  },
  card: {
    width: '30%',
    backgroundColor: '#dbdbdb',
    textAlign: 'center',
    marginTop: theme.spacing(1),
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  cardHeader: {
    fontSize: '12px',
    backgroundColor: '#c9c9c9',
    textAlign: 'center',
  },
  loading_icon: {
    left: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  tableImg: {
    height: '12px',
    width: 'auto',
    marginRight: '5px',
  },
});

// -------------------------------------------------------------------------- //


class PrestoSchedule extends Component {
  constructor() {
    super();

    const oneMonthAgoDate = new Date();
    oneMonthAgoDate.setMonth(oneMonthAgoDate.getMonth() - 1);
    const oneMonthAgo = this.formatDate(oneMonthAgoDate);

    const monthsAheadDate = new Date();
    monthsAheadDate.setMonth(monthsAheadDate.getMonth() + 12);
    const monthsAhead = this.formatDate(monthsAheadDate);

    this.state = {
      prestoApiBearer: localStorage.getItem('bearer'),
      prestoEvents: null,
      filteredPrestoEvents: null,
      loading: true,
      filtersArray: [
        { id: 'startDate', value: `${oneMonthAgo}` },
        { id: 'endDate', value: `${monthsAhead}` },
        { id: 'sport', value: 'none' },
      ],
      teamSelectOpen: false,
      seasonSelected: [],
      prestoSeasonTeams: [],
      teamsSelected: [],
    };
  }

  componentDidMount() {
    this.getSchedules();
  }

  async getSchedules() {
    const { filtersArray } = this.state;
    const startDate = filtersArray.find((x) => x.id === 'startDate').value;
    const endDate = filtersArray.find((x) => x.id === 'endDate').value;
    this.setState({
      loading: true,
      prestoEvents: null,
    });
    const promises = [];
    promises.push(this.seasonsAPIPull(
      this.formatDate(startDate),
      this.formatDate(endDate),
    ));
    Promise.all(promises).then((arrOfResults) => {
      let finalResult = [];
      arrOfResults.forEach((result) => {
        if (result) {
          const tempArray = [];
          result.forEach((row) => {
            if (row) {
              tempArray.push(
                {
                  seasonId: row.seasonId,
                  sportName: row.sport.sportName,
                  sportId: row.sport.sportId,
                  seasonName: row.seasonName,
                  startDateTime: row.startDate,
                  endDateTime: row.endDate,
                },
              );
            }
          });
          finalResult = tempArray;
        }
      });
      this.setState({
        prestoEvents: finalResult,
      });
      this.filterEvents();
    });
  }

  seasonsAPIPull = async (startDate, endDate) => {
    const { prestoApiBearer } = this.state;
    const prestoAPIEndpoint = process.env.REACT_APP_PS_GAMEDAY_API_ENDPOINT;
    return new Promise((resolve) => {
      fetch(`${prestoAPIEndpoint}me/seasons?pageSize=100&startDate=${startDate}&endDate=${endDate}`, {
        headers: {
          Authorization: `Bearer ${prestoApiBearer}`,
        },
      })
        .then((response) => response.json())
        .then((jsonData) => {
          if (jsonData && jsonData.data && jsonData.data.length >= 1) {
            return resolve(jsonData.data);
          }
          return resolve();
        })
        .catch((error) => {
          console.error('error', error);
          return resolve();
        });
    });
  }


  teamsAPIPull = async (seasonId) => {
    const { prestoApiBearer } = this.state;
    const prestoAPIEndpoint = process.env.REACT_APP_PS_GAMEDAY_API_ENDPOINT;
    return new Promise((resolve) => {
      fetch(`${prestoAPIEndpoint}seasons/${seasonId}/teams`, {
        headers: {
          Authorization: `Bearer ${prestoApiBearer}`,
        },
      })
        .then((response) => response.json())
        .then((jsonData) => {
          if (jsonData && jsonData.data && jsonData.data.length >= 1) {
            return resolve(jsonData.data);
          }
          return resolve();
        })
        .catch((error) => {
          console.error('error', error);
          return resolve();
        });
    });
  }

  openTeamSelectDialog = (season) => {
    this.setState({
      teamSelectOpen: true,
      seasonSelected: season,
      seasonTeamsLoading: true,
    });
    const promises = [];
    promises.push(this.teamsAPIPull(season.seasonId));
    Promise.all(promises).then((arrOfResults) => {
      if (arrOfResults[0].length === 1) {
        this.handleOneTeam(season, arrOfResults[0][0].teamId);
        return null;
      }
      let finalResult = [];
      arrOfResults.forEach((result) => {
        if (result) {
          const tempArray = [];
          result.forEach((row) => {
            if (row) {
              tempArray.push(
                {
                  label: row.teamName,
                  logo: row.logo,
                  value: row.teamId,
                },
              );
            }
          });
          finalResult = tempArray;
          finalResult.sort((a,b)=> (a.label > b.label ? 1 : -1))
        }
      });
      this.setState({
        prestoSeasonTeams: finalResult,
        seasonTeamsLoading: false,
      });
    });
  }

  handleOneTeam = (season, teamId) => {
    localStorage.setItem('eventid', '');
    localStorage.setItem('seasonid', season.seasonId);
    localStorage.setItem('sportid', season.sportId);
    localStorage.setItem('teams', teamId);
    history.push('/create/new');
  }

  handleMultiTeams = () => {
    const { teamsSelected, seasonSelected } = this.state;
    const teamids = teamsSelected.join();
    localStorage.setItem('eventid', '');
    localStorage.setItem('seasonid', seasonSelected.seasonId);
    localStorage.setItem('sportid', seasonSelected.sportId);
    localStorage.setItem('teamids', teamids);
    history.push('/create/new');
  }

  closeTeamSelectDialog = () => {
    this.setState({
      teamSelectOpen: false,
      seasonSelected: [],
      prestoSeasonTeams: [],
    });
  }

  setSelectedTeams = (teamId) => {
    const { teamsSelected } = this.state;
    const index = teamsSelected.indexOf(teamId);
    if (index > -1) {
      teamsSelected.splice(index, 1);
    } else {
      teamsSelected.push(teamId);
    }
    this.setState({
      teamsSelected,
    });
  }

  renderTeamSelectDialog = () => {
    const { teamSelectOpen: open, seasonSelected, prestoSeasonTeams, seasonTeamsLoading, teamsSelected } = this.state;
    const { classes } = this.props;
    let dialogTitle = 'Select Teams';
    if (seasonSelected && seasonSelected.seasonName) {
      dialogTitle = seasonSelected.seasonName;
    }
    let selectTeamOptions = '';
    if (!seasonTeamsLoading) {
      if (prestoSeasonTeams && prestoSeasonTeams.length === 0) {
        selectTeamOptions = (
          <Typography component="p">
            You do not have access to any teams in this season.
          </Typography>
        );
      } else {
        selectTeamOptions = (
          <>
            {
              prestoSeasonTeams.map((team) => {
                return (
                  <ListItem key={team.value}>
                    <Checkbox
                      checked={teamsSelected.indexOf(team.value) > -1}
                      onChange={() => this.setSelectedTeams(team.value)}
                      color={'primary'}
                    />
                    <ListItemText onClick={() => this.setSelectedTeams(team.value)}>
                      <img alt="{team.label} Team Logo" className={classes.tableImg} src={`${process.env.REACT_APP_SS_API_ENDPOINT.replace('api.php?q=', '')}presto/tokens/index.php?url=${team.logo}`} />
                      {team.label}
                    </ListItemText>
                  </ListItem>
                );
              })
            }
          </>
        )
      }
    }
    return (
      <Dialog open={open} onClose={() => this.closeTeamSelectDialog()}>
        <DialogTitle>
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select which team's schedules you would like to use.
          </DialogContentText>
          <div style={{ height:"300px", overflowY: 'auto' }}>
            {seasonTeamsLoading ?
              <div className={classes.loading_icon}>
                <CircularProgress size={48} />
              </div> : selectTeamOptions
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => this.closeTeamSelectDialog()}>
            Cancel
          </Button>
          <Button color="primary" disabled={teamsSelected.length === 0} onClick={() => this.handleMultiTeams()}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    );
  }


  handleOnFilterChange = (filterId, value) => {
    this.setState({
      loading: true,
    });

    let { filtersArray } = this.state;
    filtersArray = this.changeFilterValue(filtersArray, filterId, value);
    this.setState({
      filtersArray,
    });

    if (filterId === 'startDate' || filterId === 'endDate') {
      this.getSchedules();
    } else {
      this.filterEvents();
    }
  }

  changeFilterValue = (array, id, value) => {
    array.forEach((row) => {
      if (row.id === id) {
        row.value = value;
      }
    });
    return array;
  }

  formatDate = (date) => {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    return `${ye}-${mo}-${da}`;
  }

  filterEvents() {
    const { prestoEvents, filtersArray } = this.state;
    let filteredPrestoEvents = prestoEvents;
    const sportValue = filtersArray.find((x) => x.id === 'sport').value;
    if (sportValue !== 'none') {
      const tempFilterArray = [];
      filteredPrestoEvents.forEach((row) => {
        if (row.sportName === sportValue) {
          tempFilterArray.push(row);
        }
      });
      filteredPrestoEvents = tempFilterArray;
    }
    this.setState({
      loading: false,
      filteredPrestoEvents,
    });
  }

  render() {
    const { classes } = this.props;
    const {
      loading, filteredPrestoEvents, filtersArray, prestoEvents,
    } = this.state;
    if (loading) {
      return (
        <div className={classes.root_desktop} style={{ height: '100%' }}>
          <PageTitle title="Create Dashboard" />
          <div className={classes.loading_icon}>
            <CircularProgress size={48} />
          </div>
        </div>
      );
    }
    return (
      <div className={classes.root_desktop} style={{ height: '100%' }}>
        <PageTitle title="Create Dashboard" />
        <div className={classes.grid}>
          <EnhancedTable
            data={filteredPrestoEvents}
            orgData={prestoEvents}
            onFilterChange={this.handleOnFilterChange}
            filtersArray={filtersArray}
            onOpenTeamSelect={this.openTeamSelectDialog}
          />
        </div>
        {this.renderTeamSelectDialog()}
      </div>
    );
  }
}

// -------------------------------------------------------------------------- //


export default compose(
  withRouter,
  withStyles(STYLES, { withTheme: true }),
)(PrestoSchedule);


// -------------------------------------------------------------------------- //
