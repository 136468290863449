// this is the parent of a background box, cutoutbox, and clipping box.
/* eslint no-underscore-dangle: "off" */
const MAX_DIMENSION = 2048;

// https://stackoverflow.com/a/32490603
function getOrientation(file, callback) {
  const reader = new FileReader();
  reader.onload = function (e) {
    const view = new DataView(e.target.result);
    if (view.getUint16(0, false) !== 0xFFD8) {
      return callback(-2);
    }
    const length = view.byteLength; let
      offset = 2;
    while (offset < length) {
      if (view.getUint16(offset + 2, false) <= 8) return callback(-1);
      const marker = view.getUint16(offset, false);
      offset += 2;
      if (marker === 0xFFE1) {
        if (view.getUint32(offset += 2, false) !== 0x45786966) {
          return callback(-1);
        }

        const little = view.getUint16(offset += 6, false) === 0x4949;
        offset += view.getUint32(offset + 4, little);
        const tags = view.getUint16(offset, little);
        offset += 2;
        for (let i = 0; i < tags; i++) {
          if (view.getUint16(offset + (i * 12), little) === 0x0112) {
            return callback(view.getUint16(offset + (i * 12) + 8, little));
          }
        }
      } else if ((marker & 0xFF00) !== 0xFF00) {
        break;
      } else {
        offset += view.getUint16(offset, false);
      }
    }
    return callback(-1);
  };
  reader.readAsArrayBuffer(file);
}


class ClippingBox {
  constructor(fabric) {
    this.class = fabric.util.createClass(fabric.Polygon, {
      type: 'clippingBox',
      stepMatrix: {
        default: {
          selectable: false,
          lockMovementX: true,
          lockMovementY: true,
          lockScalingX: true,
          lockScalingY: true,
          lockRotation: true,
          hasControls: false,
          fill: 'transparent',
        },
        admin: {
          selectable: true,
          lockMovementX: false,
          lockMovementY: false,
          lockScalingX: false,
          lockScalingY: false,
          lockRotation: false,
          hasControls: true,
          fill: '#555',
        },
      },
      stateProperties: [
        ...fabric.Polygon.prototype.stateProperties,
        'clipMedia',
        'forceHide',
        'fillType',
        'textureBlendMode',
        'textureSource',
        'textureScaleX',
        'textureScaleY',
      ],
      clipMedia: false,
      forceHide: false,
      textureScaleX: 1,
      textureScaleY: 1,
      initialize(points, options = {}) {
        const newScaleX = options.scaleX || 1;
        const newScaleY = options.scaleY || 1;
        const newLeft = options.left || 0;
        const newTop = options.top || 0;
        let newPoints = [];
        if (options.scaleX !== 1 || options.scaleY !== 1) {
          points.forEach((point) => {
            const newPoint = {};
            if (options.scaleX !== 1) {
              newPoint.x = (point.x * newScaleX) + newLeft;
            } else {
              newPoint.x = point.x;
            }

            if (options.scaleY !== 1) {
              newPoint.y = (point.y * newScaleY) + newTop;
            } else {
              newPoint.y = point.y;
            }
            newPoints.push(newPoint);
          });
        } else {
          newPoints = [...points];
        }
        this.callSuper(
          'initialize',
          newPoints,
          {
            ...this.stepMatrix.default,
            ...options,
            points: newPoints,
            scaleX: 1,
            scaleY: 1,
          },
        );
        // if a media flag exists, create a media object as a child.
        if (options.media) {
          this.setMedia(options.media, true);
        }

        // Need to add the media objects with the box when it enters the canvas.
        this.on('added', () => {
          this.off('added');
          if (this.media && this.media.element) {
            this.canvas.insertAt(
              this.media.element,
              this.canvas.getObjects().indexOf(this) + 1,
            );
            this.media.element.trigger('modified');
            this.canvas.requestRenderAll();
          }
          // sometimes may need to respond to canvas step changes.
          this.canvas.on('stepChange', () => {
            this._handleStepChange();
          });
          this._handleStepChange(this.canvas.step);
        });
      },
      refreshMedia(canvas) {
        if (this.media && this.media.element) {
          const index = this.canvas._objects.indexOf(this.media.element);
          if (index) {
            this.canvas._objects.splice(index, 1);
          }

          this.canvas.insertAt(
            this.media.element,
            this.canvas.getObjects().indexOf(this) + 1,
          );
          this.canvas.requestRenderAll();
        }
      },
      setMedia(media, ignoreUndo = false, skip_select = false) {
        if (media.type === 'video') {
          this.setVideo(media, ignoreUndo);
        } else {
          this.setImage(media, ignoreUndo, skip_select);
        }
      },
      clearMedia(oldMedia) {
        if (this.canvas && oldMedia) {
          this.canvas.remove(oldMedia.element);
        }
      },
      notifyMediaSet(element) {
        this.applyOrientation();
      },
      setVideo() {
        this.clearMedia();
        throw new Error('Clipping Box does not implement Video handling.');
      },
      applyOrientation() {
        const { element } = this.media;
        if (!element) {
          return;
        }

        switch (this.orientation) {
          /* Clock-wise rotations. */
          case 1:
          default:
            break;
          case 8:
            element.angle = -90;
            break;
          case 3:
            element.angle = -180;
            break;
          case 6:
            element.angle = 90;
            break;
          /* Counter-clockwise rotations. */
          case 2:
            element.flipX = true;
            break;
          case 7:
            element.flipX = true;
            element.scaleY *= (element.height / element.width);
            element.scaleX *= (element.width / element.height);
            break;
          case 4:
            element.flipX = true;
            break;
          case 5:
            element.flipX = true;
            element.scaleY *= (element.height / element.width);
            element.scaleX *= (element.width / element.height);
            break;
        }

        if (!this.clipMedia) {
          this.width = element.width;
          this.height = element.height;
          this.scaleX = element.scaleX;
          this.scaleY = element.scaleY;
        }

        element.setCoords();

        element.dirty = true;
        if (this.canvas) {
          this.canvas.requestRenderAll();
        }
      },
      setImage(media, ignoreUndo = false, skip_select = false) {
        delete this.orientation;

        const { src, attributes, filters } = media;
        const oldMedia = this.media;
        this.media = media;

        if (!this.media.src) {
          if (oldMedia) {
            this.clearMedia(oldMedia);
          }

          return;
        }

        const imageEl = new Image();
        let imageWidth; let
          imageHeight;
        let resized_flag = false;

        imageEl.onload = () => {
          const isURL = /^(f|ht)tps?:\/\//i.test(src);

          if (!this.orientation && !isURL) {
            fetch(src)
              .then((response) => response.blob())
              .then((blob) => getOrientation(blob, (orientation) => {
                if (!this.orientation) {
                  this.orientation = orientation;
                  this.applyOrientation();
                }
              }));
          }

          if (imageEl.width > 4000 || imageEl.height > 4000) {
            resized_flag = true;
          }

          if ((imageEl.width > MAX_DIMENSION || imageEl.height > MAX_DIMENSION) && isURL && false) {
            /* let w, h;
            if (imageEl.width > imageEl.height) {
              w = MAX_DIMENSION;
              h = MAX_DIMENSION / imageEl.width * imageEl.height;
            } else {
              w = MAX_DIMENSION / imageEl.height * imageEl.width;
              h = MAX_DIMENSION;
            }
            w = Math.max(Math.floor(w), 15);
            h = Math.max(Math.floor(h), 15);
            imageWidth = imageEl.width;
            imageHeight = imageEl.height;
            imageEl.onerror = () => {
              imageEl.setAttribute('src', `${src}`);
            }
            imageEl.setAttribute('src', `${src}`); */
          } else {
            imageEl.onload = () => {};
            const image = new fabric.Image(imageEl, {});
            if (oldMedia) {
              this.clearMedia(oldMedia);
            }
            image.set({
              width: imageWidth || imageEl.width,
              height: imageHeight || imageEl.height,
            });
            this.media = {
              type: 'image',
              element: image,
              alphaMask: media.alphaMask || this.textureSource,
              alphaMaskBlendMode: media.alphaMaskBlendMode || this.textureBlendMode || 'multiply',
              alphaMaskScaleX: media.alphaMaskScaleX || this.textureScaleX,
              alphaMaskScaleY: media.alphaMaskScaleY || this.textureScaleY,
            };
            if (this.media.alphaMask) {
              this.media.alphaMaskElement = new Image();
              this.media.alphaMaskElement.onload = () => {
                image.render = (ctx) => {
                  const drawImageElement = (element, scaleX, scaleY) => {
                    const width = image.width * scaleX;
                    const height = image.height * scaleY;

                    ctx.drawImage(
                      element,
                      0, 0,
                      element.width,
                      element.height,
                      image.left - width / 2, image.top - height / 2,
                      width, height,
                    );
                  };

                  const drawPath = () => {
                    ctx.beginPath();
                    ctx.moveTo(this.points[0].x, this.points[0].y);
                    for (let i = 1; i < this.points.length; ++i) {
                      ctx.lineTo(this.points[i].x, this.points[i].y);
                    }
                  };

                  ctx.save();
                  if (this.media.alphaMaskBlendMode === 'multiply') {
                    const oldFilleStyle = ctx.fillStyle;
                    ctx.fillStyle = 'black';
                    drawPath();
                    ctx.fill();
                    ctx.fillStyle = oldFilleStyle;
                  }
                  drawPath();
                  ctx.closePath();
                  ctx.clip();

                  if (this.media.alphaMaskBlendMode !== 'multiply') {
                    ctx.globalCompositeOperation = 'copy';
                  }

                  drawImageElement(
                    this.media.alphaMaskElement,
                    this.media.alphaMaskScaleX * image.scaleX,
                    this.media.alphaMaskScaleY * image.scaleY,
                  );
                  ctx.globalCompositeOperation = this.media.alphaMaskBlendMode;
                  if (!image.filterApplied) {
                    image.applyResizeFilters();
                    image.filterApplied = true;
                  }
                  drawImageElement(image._filteredEl || imageEl, image.scaleX, image.scaleY);
                  ctx.restore();
                };

                if (image.canvas) {
                  image.canvas.requestRenderAll();
                }

                if (!this.media.alphaMaskScaleX && this.media.alphaMaskScaleX !== 0) {
                  this.media.alphaMaskScaleX = 1.0;
                }


                if (!this.media.alphaMaskScaleY && this.media.alphaMaskScaleY !== 0) {
                  this.media.alphaMaskScaleY = 1.0;
                }
              };
              this.media.alphaMaskElement.crossOrigin = 'Anonymous';
              this.media.alphaMaskElement.src = `${this.media.alphaMask}?1`;
            }


            const ratioX = (imageWidth || image.width) / imageEl.width;
            const ratioY = (imageHeight || image.height) / imageEl.height;
            const minScale = this._determineMinScale();
            const scale = Math.max(
              ratioX * (attributes ? attributes.scaleX : minScale),
              ratioY * (attributes ? attributes.scaleY : minScale),
            );
            if (oldMedia && oldMedia.element && oldMedia.element.opacity) {
              this.opacity = oldMedia.element.opacity;
            }
            let _parentOpacity = this.opacity;
            if (_parentOpacity === 0.2) {
              _parentOpacity = 1.0;
            }
            this.opacity = 1.0;
            const opacity = (attributes ? attributes.opacity : _parentOpacity);


            const center_point = this.getCenterPoint();

            image.set({
              ...attributes,
              parent: this,
              excludeFromExport: true,
              originX: 'center',
              originY: 'center',
              left: attributes ? attributes.left : center_point.x,
              top: attributes ? attributes.top : center_point.y,
              lockUniScaling: true,
              centeredScaling: true,
              lockRotation: false,
              scaleX: scale,
              scaleY: scale,
              opacity,
              width: imageEl.width,
              height: imageEl.height,
              minScale,
              angle: this.angle,
              clipTo: this.clipMedia ? this._clipTo : null,
              lockScalingFlip: true,
              evented: true,
              selectable: true,
              resizeFilter: 'pica',
            });

            if (resized_flag) {
              this.borderColor = 'red';
              image.borderColor = 'red';
            } else {
              this.borderColor = 'black';
              image.borderColor = 'black';
            }

            this.notifyMediaSet(image);

            // return true src if asked.
            if (src.split(':')[0] !== 'blob') {
              image.getSrc = () => image._originalElement.getAttribute('src');
            }

            if (filters) {
              filters.forEach((filter) => {
                image.filters.push(fabric.Image.filters.BaseFilter.fromObject(filter));
              });
              image.applyFilters();
            }


            this._setChildEventHandlers();
            // gotta mark us for rerender.
            this.dirty = true;
            if (this.canvas) {
              this.canvas.addingImageEl = true;
              this.canvas.insertAt(
                image,
                this.canvas.getObjects().indexOf(this) + 1,
              );
              this.canvas.addingImageEl = false;

              if (!ignoreUndo && !skip_select) {
                this.canvas.setActiveObject(image);
              }

              image.trigger('modified');
              this.canvas.trigger('object:modified', { target: this, ignoreUndo });
              this.canvas.trigger('media:send', { target: this, image, resized_flag });
              image.applyResizeFilters();
            }

            if (this.orientation) {
              this.applyOrientation();
            }
          }
        };

        imageEl.setAttribute('crossorigin', 'anonymous');
        imageEl.setAttribute('src', src);
      },
      _setChildEventHandlers() {
        if (this.media) {
          this.media.element.on('moving', this._constrainImageMovement);
          this.media.element.on('scaling', this._constrainScaling);
          this.media.element.on('removed', this._mediaRemoved);
        }
      },
      _handleStepChange() {
        if (this.canvas) {
          if (this.stepMatrix[this.canvas.step]) {
            this.dirty = true;
            this.set(this.stepMatrix[this.canvas.step]);
          } else {
            this.set(this.stepMatrix.default);
          }
        }
      },
      _constrainScaling() {
        if (this.parent.constrainScaling) {
          const { minScale } = this;
          if (this.scaleX < minScale) {
            this.scaleX = minScale;
            this.scaleY = minScale;
          }
        }
      },
      _constrainImageMovement(e) {
        const { parent } = e.target;
        if (parent.constrainMovement) {
          const parentCoords = parent.calcCoords(true);
          // restrict left
          if (this.left - ((this.width * this.scaleX) / 2) > parentCoords.tl.x) {
            this.left = parentCoords.tl.x + ((this.width * this.scaleX) / 2);
          }
          // restrict top
          if (this.top - ((this.height * this.scaleY) / 2) > parentCoords.tl.y) {
            this.top = parentCoords.tl.y + ((this.height * this.scaleY) / 2);
          }
          // restrict right
          if (this.left + ((this.width * this.scaleX) / 2) < parentCoords.tr.x) {
            this.left = parentCoords.tr.x - ((this.width * this.scaleX) / 2);
          }
          // restrict bottom
          if (this.top + ((this.height * this.scaleY) / 2) < parentCoords.bl.y) {
            this.top = parentCoords.bl.y - ((this.height * this.scaleY) / 2);
          }
        }
      },
      _mediaRemoved() {
        if (this.parent && this.parent.canvas) {
          this.parent.media = null;
          this.parent.dirty = true;
          // console.log('>>> mediaRemoved');
          // this.parent.canvas.setActiveObject(this.parent);
        }
      },
      _determineMinScale() {
        const parentCoords = this.calcCoords(true);
        let minX = parentCoords.tr.x - parentCoords.tl.x;
        let minY = parentCoords.br.y - parentCoords.tr.y;
        if (this.points && (minX < 10 && minY < 10)) {
          minX = (Math.min(...this.points.map((p) => p.x)) - this.left);
          minY = (Math.min(...this.points.map((p) => p.y)) - this.top);
        }
        const { element } = this.media;
        const minXScale = minX / element.width;
        const minYScale = minY / element.height;
        const minScale = (minXScale > minYScale ? minYScale : minXScale).toFixed(2);

        return Math.max(minScale, 0.01);
      },
      getColors() {
        // dont participate in the palette step.
        return [];
      },
      _clipTo(ctx) {
        const { parent } = this;
        let { points } = parent;
        const parentTransform = parent.calcTransformMatrix();
        const thisTransform = this.calcTransformMatrix();
        const inverseThisTransform = fabric.util.invertTransform(thisTransform);
        const t = fabric.util.multiplyTransformMatrices(inverseThisTransform, parentTransform);

        let left; let
          top;
        points.forEach((p) => {
          left = Math.min(!left && left !== 0 ? p.x : left, p.x);
          top = Math.min(!top && top !== 0 ? p.y : top, p.y);
        });

        left = parent.pathOffset.x;
        top = parent.pathOffset.y;

        points = points.map((p) => fabric.util.transformPoint({
          x: p.x - left,
          y: p.y - top,
        }, t));

        ctx.beginPath();
        ctx.moveTo(points[0].x, points[0].y);
        for (let i = 1; i < points.length; ++i) {
          ctx.lineTo(points[i].x, points[i].y);
        }
        ctx.closePath();
      },
      toObject(propertiesToInclude) {
        const newPropertiesToInclude = [
          'fillType',
          'textureSource',
          'textureBlendMode',
          'textureScaleX',
          'textureScaleY',
          'clipMedia',
          ...propertiesToInclude];
        if (this.media && this.media.element) {
          const {
            width,
            height,
            left,
            top,
            filters,
            startTime,
            currentDuration,
            muted,
            opacity,
          } = this.media.element;
          const {
            scaleX,
            scaleY,
          } = this.media.element;
          const serializedFilters = [];
          filters.forEach((filter) => {
            serializedFilters.push(filter.toObject());
          });


          return fabric.util.object.extend(
            this.callSuper('toObject', newPropertiesToInclude),
            {
              media: {
                attributes: {
                  width,
                  height,
                  left,
                  top,
                  scaleX,
                  scaleY,
                  startTime,
                  currentDuration,
                  muted,
                  opacity,
                },
                filteredSrc: this.media.filteredSrc,
                filters: serializedFilters,
                src: this.media.element.getSrc(),
                type: this.media.type,
                alphaMask: this.media.alphaMask,
                alphaMaskBlendMode: this.media.alphaMaskBlendMode,
                alphaMaskScaleX: this.media.alphaMaskScaleX,
                alphaMaskScaleY: this.media.alphaMaskScaleY,
              },
            },
          );
        } if (this.media) {
          // fringe case in which we want a serialization while images are loading.
          return fabric.util.object.extend(
            this.callSuper('toObject', newPropertiesToInclude),
            {
              media: this.media,
            },
          );
        }

        return fabric.util.object.extend(
          this.callSuper('toObject', newPropertiesToInclude),
          {},
        );
      },
      _render(ctx) {
        if (
          !this.forceHide
          && (
            !this.media
            || !this.media.element
          )
        ) {
          this.callSuper('_render', ctx);
        }
      },
    });

    this.fromObject = (object, callback) => fabric.Object._fromObject('ClippingBox', object, callback, 'points');
  }
}


export default ClippingBox;
