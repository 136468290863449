import ScoreshotsPut from '../scoreshotsapi/Put'
import uuid from 'uuid4';

function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const _ia = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    _ia[i] = byteString.charCodeAt(i);
  }

  const dataView = new DataView(arrayBuffer);
  const blob = new Blob([dataView], { type: mimeString });
  return blob;
}

class CanvasVideo {
  constructor(CanvasUtil) {
    this.CanvasUtil = CanvasUtil;
    this.bucket = 'ss3-video';
  }


  isVideo = () => {
    let video = false;
    this.CanvasUtil.canvas.getObjects('backgroundBox').forEach((box) => {
      if (box.media && box.media.type === 'video') {
        video = true;
      }
    });
    return video;
  }

  getOverlays = async (jobId, isImage = false) => {
    // TODO: Multiple overlay support
    const { CanvasUtil } = this;

    const key = `input/overlays/${jobId}.png`;
    let action = new ScoreshotsPut(this.bucket, key);

    await action.put(dataURItoBlob(CanvasUtil.toDataURL({}, isImage)), "image/png");

    return [
      {
        src: action.getUrl(),
        effects: [],
      },
    ];
  }

  createRenderJob = async (
    backgroundEffect = false,
    overlayEffect = false,
    duration = false,
    isImage = false,
  ) => {
    const { CanvasUtil } = this;
    const output = {};
    // create new job id
    const jobId = uuid();
    output.jobId = jobId;
    output.clientId = localStorage.getItem('client_id');
    output.dimensions = {
      width: CanvasUtil.canvas.workingArea.width,
      height: CanvasUtil.canvas.workingArea.height,
    };

    output.overlays = await this.getOverlays(jobId, isImage);

    // calculate coordinates for each present background
    const backgroundBoxes = CanvasUtil.canvas.getObjects('backgroundBox');
    const backgrounds = [];
    for (let i = 0; i < backgroundBoxes.length; i += 1) {
      const background = backgroundBoxes[i];
      if (background.media) {
        const corners = background.getCorners();
        if (background.media.type === 'video') {
          const video = background.media.element;
          const w = video.getScaledWidth() / 2;
          const h = video.getScaledHeight() / 2;
          const videoLeft = video.left - w;
          const videoRight = video.left + w;
          const videoTop = video.top - h;
          const videoBottom = video.top + h;

          const left = Math.max(videoLeft, corners.left) - videoLeft;
          const right = Math.min(videoRight, corners.right) - videoLeft;
          const top = Math.max(videoTop, corners.top) - videoTop;
          const bottom = Math.min(videoBottom, corners.bottom) - videoTop;
          backgrounds.push({
            src: video.videoEl.currentSrc,
            width: video.getScaledWidth(),
            height: video.getScaledHeight(),
            muted: video.muted,
            start: video.startTime,
            duration: video.currentDuration,
            x: videoLeft,
            y: videoTop,
            left, right, top, bottom,
            effects: [],
            type: 'video',
          });
        } else {
          const image = background.media.element;
          let src = image.getSrc();
          if (image.filteredSrc) {
            src = image.filteredSrc;
          }
          const w = image.getScaledWidth() / 2;
          const h = image.getScaledHeight() / 2;
          const imageLeft = image.left - w;
          const imageRight = image.left + w;
          const imageTop = image.top - h;
          const imageBottom = image.top + h;

          const left = Math.max(imageLeft, corners.left) - imageLeft;
          const right = Math.min(imageRight, corners.right) - imageLeft;
          const top = Math.max(imageTop, corners.top) - imageTop;
          const bottom = Math.min(imageBottom, corners.bottom) - imageTop;

          backgrounds.push({
            src,
            width: image.getScaledWidth(),
            height: image.getScaledHeight(),
            x: background.left,
            y: background.top,
            left, right, top, bottom,
            effects: [],
            type: 'image',
          });
        }
      }
    }
    output.backgrounds = backgrounds;

    if (backgroundEffect) {
      for (let i = 0; i < output.backgrounds.length; i += 1) {
        output.backgrounds[i].effects.push(backgroundEffect);
      }
    }
    if (overlayEffect && output.overlays) {
      for (let i = 0; i < output.overlays.length; i += 1) {
        let effectArray = [];
        switch (overlayEffect) {
          case 'fadein':
          case 'fadeout':
            effectArray = [overlayEffect];
            break;
          case 'fadeinout':
            effectArray = ['fadein', 'fadeout'];
            break;
          default:
            break;
        }
        output.overlays[i].effects = output.overlays[i].effects.concat(effectArray);
      }
    }
    if (duration) {
      output.duration = duration;
    }

    // check for effects.
    const videoEffects = CanvasUtil.canvas.getObjects('effect');
    output.underEffects = [];
    output.overEffects = [];
    videoEffects.forEach((effect) => {
      const point = effect.getPointByOrigin('left', 'top');
      if (effect.renderBelow) {
        output.underEffects.push({
          src: effect.effect.full,
          width: effect.getScaledWidth(),
          height: effect.getScaledHeight(),
          x: point.x,
          y: point.y,
        });
      } else {
        output.overEffects.push({
          src: effect.effect.full,
          width: effect.getScaledWidth(),
          height: effect.getScaledHeight(),
          x: point.x,
          y: point.y,
        });
      }
    });
    const taskParams = {
      Body: new Blob([JSON.stringify(output)], { type: "application/json" }),
      Bucket: this.bucket,
      Key: `input/tasks/${jobId}.json`,
      ACL: 'public-read',
    };
    const action = new ScoreshotsPut(this.bucket, taskParams.Key)
    await action.put(taskParams.Body, "application/json");

    // return single js object output
    return output;
  }
}

export default CanvasVideo;
