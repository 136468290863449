
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  List,
  MenuItem,
  Select,
  SvgIcon,
  Typography,
  withStyles,
  TextField,
  Tooltip,
} from '@material-ui/core';

import {
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatItalic,
  FormatUnderline,
  RotateLeft,
  RotateRight
} from 'mdi-material-ui';
import { graphql, compose } from 'react-apollo';
import parseColor from 'parse-color';
import { isMobileOnly } from 'react-device-detect';
import 'rc-slider/assets/index.css';

import { ColorAnimation } from '../../animations_views/index';
import { STROKE_SYMBOLS } from '../../../canvas/extensions/types/ParText';
import * as MetaData from './../../../canvas/lib/metadata';
import ColorPicker from '../../color/ColorPicker';
import Crumbs from './Crumbs';
import FontPicker from './FontPicker';
import { ShadowEditor } from './ShadowEditor';
import SliderWithInput from '../SliderWithInput';
import SliderNoInput from '../SliderNoInput';
import websafefonts from '../../../font/websafe';
import { UserRoleProvider, UserRoleConsumer } from '../../user/Permissions';
import { CalcDefaultCanvasFont, GetTextPropAtSelection, SetTextPropAtSelection } from '../../../canvas/lib/text';
import { ColorButton } from '../../color/ColorButton';
import { ROLES_QUERY, UserPermissions } from '../../user/Permissions';
import gql from "graphql-tag";


const CurvedTextIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 450 450">
    <path className="st0" d="M380.3,310.4"/>
    <text transform="matrix(0.8753 0 0 1 144.1777 417.7722)" style={{ fontFamily: 'Georgia-Bold', fontSize: '312.2523px' }}>T</text>
    <path className="st0" d="M405.8,298.6l24.7,8.2l0.2-0.7l-0.1,0.1c21.4-99.6-38-198.7-135.9-226.9C191.9,49.8,84.5,109.2,54.9,212
  c-2.7,9.5-4.8,19.3-6.1,29.1l27.5,3.6c1.1-8.5,2.8-16.8,5.2-25c23.7-81,104.3-131.4,187.5-117.1
  c90.5,15.5,151.3,101.3,135.8,191.8l-0.1-0.2"/>
    <path className="st0" d="M58,329.4c3.3,9.4,7.3,18.5,11.9,27.3l24.4-13c-4-7.5-7.4-15.4-10.2-23.4L58,329.4z"/>
    <path className="st0"
          d="M75,270.3L47.3,271c0.3,9.9,1.3,19.9,3.1,29.6l27.2-4.9C76.1,287.3,75.2,278.8,75,270.3z"/>
    <polygon className="st0" points="361.9,280.4 399.4,356.7 475.7,319.2 					"/>
  </SvgIcon>
);

// -------------------------------------------------------------------------- //

const FormatLetterCaseUpperIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M20.06,18C20,17.83 19.91,17.54 19.86,17.11C19.19,17.81 18.38,18.16 17.45,18.16C16.62,18.16 15.93,17.92 15.4,17.45C14.87,17 14.6,16.39 14.6,15.66C14.6,14.78 14.93,14.1 15.6,13.61C16.27,13.12 17.21,12.88 18.43,12.88H19.83V12.24C19.83,11.75 19.68,11.36 19.38,11.07C19.08,10.78 18.63,10.64 18.05,10.64C17.53,10.64 17.1,10.76 16.75,11C16.4,11.25 16.23,11.54 16.23,11.89H14.77C14.77,11.46 14.92,11.05 15.22,10.65C15.5,10.25 15.93,9.94 16.44,9.71C16.95,9.5 17.5,9.36 18.13,9.36C19.11,9.36 19.87,9.6 20.42,10.09C20.97,10.58 21.26,11.25 21.28,12.11V16C21.28,16.8 21.38,17.42 21.58,17.88V18H20.06M17.66,16.88C18.11,16.88 18.54,16.77 18.95,16.56C19.35,16.35 19.65,16.07 19.83,15.73V14.16H18.7C16.93,14.16 16.04,14.63 16.04,15.57C16.04,16 16.19,16.3 16.5,16.53C16.8,16.76 17.18,16.88 17.66,16.88M5.46,13.71H9.53L7.5,8.29L5.46,13.71M6.64,6H8.36L13.07,18H11.14L10.17,15.43H4.82L3.86,18H1.93L6.64,6M2,20H13V22H2V20Z"/>
  </SvgIcon>
);

// -------------------------------------------------------------------------- //

const GET_USER = gql`
  query getMe {
    me {
      id
      client {
        id
        fonts {
          id
          fullName
          fontFamily
          type
          bucket
          key
        }
      }
      role {
        ${ROLES_QUERY}
      }
    }
  }
`;

const STYLES = (theme) => ({
  autotext: {
    fontFamily: 'inherit',
    color: 'inherit',
  },
  highlightedAutotext: {
    color: theme.palette.primary.light,
    fontFamily: 'inherit',
  },
  subheading: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  smallLabel: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    color: '#8E8E8E',
  },
  shadowEditor: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  highlight: {
    color: theme.palette.primary.light,
  },
  selected: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  fill: {
    display: 'inline-block',
    width: '40%',
    textAlign: 'left',
  },
  stroke: {
    display: 'inline-block',
    width: '40%',
    textAlign: 'left',
    marginLeft: theme.spacing(2),
  },
  strokeSlider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  stroke_symbol_select: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  addtext: {
    cursor: 'pointer',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  font_picker: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  }
});

class Text extends React.Component {

  state = {
    text_colors: {},
    other_colors: {},
    font_size: null,
    picker_open: false,
    active_picker: null,
    anchor_position: null,
    text: null,
  }

  componentDidMount() {
    this.createPalette();
  }

  componentDidUpdate(old_props) {
    if (!this.props.active && old_props.active !== this.props.active) {
      this.createPalette();
    }
  }

  onModified = () => {
    let { active } = this.props;
    active.canvas.trigger('object:modified');
  }

  setIndex = (prop,index) => {
    let stateObj = this.state;
    stateObj[prop] = index;
    this.setState(stateObj);
  }

  addText = (size, family = null, rerender = true) => {
    let { fabric, canvas } = this.props;

    if (family === null) {
      family = CalcDefaultCanvasFont(canvas);
    }

    let text = new fabric.IText('Sample Text', {
      left: 20, top: 20, fill: '#AAA',
      fontFamily: family, fontSize: size,
    });

    canvas.add(text);
    canvas.setActiveObject(text);

    if (rerender) {
      canvas.requestRenderAll();
    }
  }

  handleColorChangeSingle = (prop, color) => {
    let { activeGroup, canvas } = this.props;
    let force_update = false;

    activeGroup.forEach((object) => {
      SetTextPropAtSelection(object, prop, color.rgba);

      if (object.strokeWidth === 0.0) {
        if (color.color[3] !== 0.0) {
          object.set('strokeWidth', 1.0);
          force_update = true;
        }
      }
    });

    canvas.requestRenderAll();

    if (force_update) {
      this.forceUpdate();
    }
  }

  changeFontSize = (value) => {
    let clamped_value = Math.max(value, 6);
    let { CanvasUtil, canvas, activeGroup } = this.props;
    activeGroup.forEach((obj) => {
      obj.scaleY = clamped_value/40;
      obj.scaleX = clamped_value/40;
      obj.fontSize = 40;
      obj.dirty = true;
      CanvasUtil.dirtyCanvas();
      obj.setCoords();
    });
    canvas.requestRenderAll();
    this.setState({ font_size: String(value) });
  }

  setText = (value) => {
    let { CanvasUtil, canvas, activeGroup } = this.props;
    activeGroup.forEach((obj) => {
      obj.set('text', value);
      obj.dirty = true;
      obj.setCoords();
      if (obj.type === 'curvedText') obj.getCircularText();
      CanvasUtil.dirtyCanvas();
      canvas.fire('object:modified', { target: obj, ignoreUndo: false});
    });
    canvas.requestRenderAll();
    this.forceUpdate();
    this.setState({ text: value });
  }

  drag = (self, font) => (event) => {
    const clone = event.currentTarget.cloneNode(true);
    clone.style.display = 'none';
    document.body.appendChild(clone);
    const dt = event.dataTransfer;
    dt.setData('text/plain', font);
    dt.setDragImage(clone, 25, 25);
    self.props.onDragStart(event);
  }

  handleChange = (selectedFont) => {
    this.changeFont(selectedFont);
  }

  getFonts(family) {
    const { classes, fonts, theme, objects, data } = this.props;
    const fontList = [];
    const usedFontList = [];
    if (objects) {
      objects.forEach((canvasObject) => {
        if (canvasObject.fontFamily !== '' && usedFontList.indexOf(canvasObject.fontFamily) === -1) {
          if (canvasObject.type === 'i-text') {
            usedFontList.push(canvasObject.fontFamily);
          }
        }
      });
    }
    if (data && data.me && data.me.role) {
      const user_permissions = new UserPermissions(data.me.role);

      if (user_permissions.get('fonts:read')) {
        const fontsAllowed = user_permissions.get('fonts:fonts_allowed');
        fonts.forEach((font) => {
          if (font.fontFamily !== '' && fontList.indexOf(font.fontFamily) === -1 && usedFontList.indexOf(font.fontFamily) === -1 && fontsAllowed.indexOf(font.id) >= 0) {
            fontList.push(font.fontFamily);
          }
        });
      } else {
        fonts.forEach((font) => {
          if (font.fontFamily !== '' && fontList.indexOf(font.fontFamily) === -1 && usedFontList.indexOf(font.fontFamily) === -1) {
            fontList.push(font.fontFamily);
          }
        });

        websafefonts.forEach((font) => {
          if (font.fontFamily !== '' && fontList.indexOf(font.fontFamily) === -1 && usedFontList.indexOf(font.fontFamily) === -1) {
            fontList.push(font.fontFamily);
          }
        });
      }
    } else {
      fonts.forEach((font) => {
        if (font.fontFamily !== '' && fontList.indexOf(font.fontFamily) === -1 && usedFontList.indexOf(font.fontFamily) === -1) {
          fontList.push(font.fontFamily);
        }
      });

      websafefonts.forEach((font) => {
        if (font.fontFamily !== '' && fontList.indexOf(font.fontFamily) === -1 && usedFontList.indexOf(font.fontFamily) === -1) {
          fontList.push(font.fontFamily);
        }
      });
    }

    fontList.sort((a, b) => (a > b) ? 1 : -1)
    return (
      <div className={classes.font_picker}>
        <FontPicker
          label="Choose Font"
          fonts={fontList}
          usedfonts={usedFontList}
          previews
          activeColor={theme.palette.primary.light}
          value={family}
          onChange={this.handleChange}
        />
      </div>
    );
  }

  toggleStyle = (key, value) => {
    const { activeGroup, canvas } = this.props;

    switch (key) {
      case 'fontWeight': {
        activeGroup.forEach((object) => {
          SetTextPropAtSelection(object, key,
            (value ? 'normal' : 'bold')
          );
        });

        break;
      }
      case 'fontStyle': {
        activeGroup.forEach((object) => {
          SetTextPropAtSelection(object, key,
            (value ? 'normal' : 'italic')
          );
        });

        break;
      }
      case 'underline': {
        activeGroup.forEach((object) => {
          SetTextPropAtSelection(object, key, !value);
        });

        break;
      }
      default: {
        return;
      }
    }

    canvas.requestRenderAll();
    this.setState({ expanded : -100, strokeExpanded : -100 });
  }

  changeStrokeSymbol = (value) => {
    const { canvas, activeGroup } = this.props;

    activeGroup.forEach((obj) => {
      obj.strokeSymbol = value;
      obj.dirty = true;
    });

    canvas.requestRenderAll();
    this.forceUpdate();
  }

  changeDesign = () => {
    const { canvas, activeGroup, fabric } = this.props;
    activeGroup.forEach((obj) => {
      if (obj.type !== 'curvedText') {
        if (obj.fontSize !== 40) {
          obj.scaleX = obj.fontSize / 40;
          obj.scaleY = obj.fontSize / 40;
          obj.fontSize = 40;
          obj.strokeWidth = Math.ceil(obj.strokeWidth * obj.scaleX);
        }
        const defaultDiameter = 300;
        const curvedText = new fabric.CurvedText(obj.text, {
          name: obj.name,
          diameter: defaultDiameter,
          fontSize: obj.fontSize,
          fontFamily: obj.fontFamily,
          left: obj.left,
          top: obj.top,
          fill: obj.fill,
          angle: 0,
          scaleX: obj.scaleX,
          scaleY: obj.scaleY,
          fontStyle: obj.fontStyle,
          fontWeight: obj.fontWeight,
          opacity: obj.opacity,
          shadow: obj.shadow,
          stroke: obj.stroke,
          strokeWidth: obj.strokeWidth,
          textAlign: obj.textAlign,
          originX: obj.originX,
          originY: obj.originY,
        });
        canvas.remove(obj);
        canvas.add(curvedText);
        canvas.setActiveObject(curvedText);
      } else {
        const regText = new fabric.IText(obj.text, {
          name: obj.name,
          fontSize: obj.fontSize,
          fontFamily: obj.fontFamily,
          left: obj.left,
          top: obj.top,
          fill: obj.fill,
          angle: 0,
          scaleX: obj.scaleX,
          scaleY: obj.scaleY,
          fontStyle: obj.fontStyle,
          fontWeight: obj.fontWeight,
          opacity: obj.opacity,
          shadow: obj.shadow,
          stroke: obj.stroke,
          strokeWidth: obj.strokeWidth,
          textAlign: obj.textAlign,
          originX: obj.originX,
          originY: obj.originY,

        });
        canvas.remove(obj);
        canvas.add(regText);
        canvas.setActiveObject(regText);
      }
    });
    canvas.requestRenderAll();
  }

  changeFont = (family) => {
    const { activeGroup } = this.props;
    activeGroup.forEach((obj) => {
      obj.set('fontFamily', family);
      obj.setCoords();
    });
    this.props.canvas.requestRenderAll();
    this.setState({expanded : -100,strokeExpanded : -100});
  }

  setTracking = (tracking) => {
    const { canvas, activeGroup } = this.props;

    activeGroup.forEach((object) => {
      object.set('charSpacing', tracking);
      object.setCoords();
    });

    canvas.requestRenderAll();
    this.forceUpdate();
  }

  setAngle = (angle) => {
    const { canvas, activeGroup } = this.props;

    activeGroup.forEach((object) => {
      object.rotate(angle % 360.0);
      object.setCoords();
    });

    canvas.requestRenderAll();
    this.forceUpdate();
  }

  setAngleLeft = () => {
    const { canvas, activeGroup } = this.props;

    activeGroup.forEach((object) => {
      object.rotate((object.angle + (360.0 - 45.0)) % 360.0);
      object.setCoords();
    });

    canvas.requestRenderAll();
    this.forceUpdate();
  }

  setAngleRight = () => {
    const { canvas, activeGroup } = this.props;

    activeGroup.forEach((object) => {
      object.rotate((object.angle + 45.0) % 360.0);
      object.setCoords();
    });

    canvas.requestRenderAll();
    this.forceUpdate();
  }

  setLeading = (leading) => {
    const { canvas, activeGroup } = this.props;

    activeGroup.forEach((object) => {
      object.set('lineHeight', leading);
      object.setCoords();
    });

    canvas.requestRenderAll();
    this.forceUpdate();
  }

  setDiameter = (diameter) => {
    const { canvas, activeGroup } = this.props;
    if (diameter < 5) diameter = 5;
    activeGroup.forEach((object) => {
      object.set('diameter', diameter);
      object.getCircularText();
    });
    canvas.requestRenderAll();
    this.forceUpdate();
  }

  setStroke = (width) => {
    const { canvas, activeGroup } = this.props;
    activeGroup.forEach((obj) => {
      const stroke = ({
        strokeWidth: width,
      });
      if (!obj.stroke) {
        stroke.stroke = '#000';
      }
      obj.set(stroke);
      obj.setCoords();
    });
    canvas.requestRenderAll();
    this.forceUpdate();
  }

  setAlign = (alignment) => () => {
    const { canvas, activeGroup } = this.props;
    activeGroup.forEach((obj) => {
      obj.set('textAlign', alignment);
      obj.set('originX', alignment);
      obj.setCoords();
    });
    canvas.requestRenderAll();
    this.forceUpdate();
  }

  flip = (prop) => {
    const { active, activeGroup } = this.props;
    activeGroup.forEach(object => {
      object.set(prop, !object[prop]);
      object.setCoords();
    });
    active.canvas.requestRenderAll();
  }

  colorAnimation = (prop) => {
    const { canvas, CanvasUtil } = this.props;
    let obj = this.state;

    const expand_change_handler = (index) => {
      let m = (index === obj[prop] ? -100 : index);
      this.setIndex(prop, m);
    };

    return (
      <ColorAnimation
        prop={prop}
        canvas={canvas}
        canvasUtil={CanvasUtil}
        expanded={obj[prop]}
        onExpandChange={expand_change_handler}
        onChange={() => this.forceUpdate()}
      />
    );
  }

  toggleTextTransform = () => {
    let { active, CanvasUtil } = this.props;

    if (MetaData.getMetaData(active, 'text_transform') === 'upper') {
      MetaData.clearMetaData(active, 'text_transform');
    } else {
      MetaData.setMetaData(active, 'text_transform', 'upper');
    }

    CanvasUtil.updateTextCase(active);
    active.canvas.renderAll();
    this.forceUpdate();
  }

  handleColorChange = (info, color, index) => {
    this.changeAllObjectColors(info[index], info[index][0].color, color, 180, () => {
      this.createPalette();
      this.props.canvas.trigger('object:modified');
    });
  }

  mouseOverColor = (info, index) => {
    const objects = info[index];

    if (objects.length > 0) {
      // assume valid output here because it was validated when it was added
      const from_color = objects[0].color.toLowerCase();
      const parsed_color = parseColor(from_color);
      let to_color = '#FFF';

      if (parsed_color.hsl[2] >= 75) {
        to_color = '#000';
      }

      this.changeAllObjectColors(objects, from_color, to_color, 200, () => {
        this.changeAllObjectColors(objects, to_color, from_color, 200);
      });
    }
  }

  createPalette = () => {
    const { objects } = this.props;
    let text_colors = {};
    let other_colors = {};

    objects.forEach(object => {
      if (
        object.getColors && (
          !object.excludeFromExport ||
          (object.widget && object.widget_index !== undefined)
        )
      ) {
        object.getColors().forEach(color => {
          let colors_map;

          switch (object.type) {
            case 'i-text':
            case 'textbox': {
              colors_map = text_colors;
              break;
            }
            case 'SliderBar': {
              if (color.property === 'fontColor') {
                colors_map = text_colors;
              } else {
                colors_map = other_colors;
              }

              break;
            }
            default: {
              colors_map = other_colors;
              break;
            }
          }

          // avoid treating synonyms as unique colors (#f00 / rgb(255, 0, 0))
          const parsed_color = parseColor(color.color.toLowerCase());

          if (parsed_color.hex !== undefined) {
            const key = parsed_color.hex;

            if (!colors_map[key]) {
              colors_map[key] = [];
            }

            colors_map[key].push(color);
          }
        });
      }
    });

    this.setState({ text_colors });
  }

  renderPalette = () => {
    const { classes } = this.props;
    const { text_colors } = this.state;

    const text_color_table = (
      Object.keys(text_colors).map(key => text_colors[key])
    );

    return (
      <>
        <div className={classes.root}>
          <Crumbs
            title="Text Colors"
          />
          {Object.keys(text_colors).map((key, index) => (
            <React.Fragment key={index}>
              <ColorButton
                color={key}
                onMouseOver={() => this.mouseOverColor(text_color_table, index)}
                onClick={(e) => {
                  const x = 218.0;
                  const y = e.clientY;

                  this.setState({
                    picker_open: true,
                    active_picker: index,
                    anchor_position: { left: x, top: y },
                  })
                }}
              />
              <ColorPicker
                open={
                  Boolean(this.state.picker_open) &&
                  (this.state.active_picker === index)
                }
                anchorReference="anchorPosition"
                anchorPosition={this.state.anchor_position}
                canvas={this.props.canvas}
                color={key} disableAlpha
                onClose={() => {
                  this.setState({ picker_open: false });
                }}
                onChangeComplete={(color) => {
                  this.handleColorChange(text_color_table, color.rgba, index);
                }}
              />
            </React.Fragment>
          ))}
        </div>
      </>
    );
  }

  changeAllObjectColors = (objects, from, to, duration, callback = null) => {
    const { fabric, canvas } = this.props;

    fabric.util.animateColor(from, to, duration, {
      onChange: (value) => {
        objects.forEach(object => {
          object.method(object.property, value);
        });

        canvas.requestRenderAll();
      },
      onComplete: () => {
        objects.forEach(object => {
          object.method(object.property, to);
        });

        if (callback) {
          callback();
        }
      },
    });
  }

  renderAddTextButton = (text, variant, size) => {
    const { classes } = this.props;

    return (
      <Typography
        className={classes.addtext} variant={variant}
        onClick={() => this.addText(size)}
      >
        {text}
      </Typography>
    );
  }

  renderAddText = (palette) => {
    return (
      <div> {/* keep this for proper layout for buttons */}
        {this.renderPalette(palette)}
        <UserRoleProvider>
          <UserRoleConsumer>
          {(permissions) => (
            (!permissions.get('editor:lock_movement')) &&
            <>
              <Crumbs title="Add Text"/>
              <List style={{ paddingBottom: 30 }}>
                {this.renderAddTextButton('Add a heading', 'h4', 80)}
                {this.renderAddTextButton('Add a subheading', 'h6', 40)}
                {this.renderAddTextButton('Add a small body of text', 'body2', 25)}
              </List>
            </>
          )}
          </UserRoleConsumer>
        </UserRoleProvider>
      </div>
    );
  }

  renderTextEditor = (palette) => {
    let { active, activeGroup, classes, theme, canvas, slideshow } = this.props;

    const bold = (
      GetTextPropAtSelection(active, 'fontWeight') === 'bold'
    );

    const italic = (
      GetTextPropAtSelection(active, 'fontStyle') === 'italic'
    );

    const alignment = active.get('textAlign');
    const underline = GetTextPropAtSelection(active, 'underline');
    const fill = GetTextPropAtSelection(active, 'fill');
    const stroke = GetTextPropAtSelection(active, 'stroke');
    const font = GetTextPropAtSelection(active, 'fontFamily');

    let _colors = active.animation && slideshow ? this.colorAnimation( 'fill' ) : null;
    let _strokes = active.animation && slideshow ? this.colorAnimation( 'stroke' ) : null;

    const is_upper = (
      MetaData.getMetaData(active, 'text_transform') === 'upper'
    );

    let actual_font_size = (
      Math.floor(active.fontSize * Math.max(active.scaleX, active.scaleY))
    );

    if (isNaN(actual_font_size)) {
      actual_font_size = 1;
    }

    let font_size, text;

    if (this.state.font_size !== null) {
      font_size = this.state.font_size;
    } else {
      font_size = actual_font_size;
    }

    if (this.state.text) {
      text = this.state.text;
    } else {
      text = active.text;
    }
    if (!active.design) {
      active.design = "None";
    }
    const key_frames = (
      (active && active.animation && active.animation.animations) || []
    );

    let lineSpacingBlock = (
      <>
        <Typography
          variant="caption"
          style={{ display: 'block', marginTop: 8 }}
        >
          Line Spacing
        </Typography>
        <SliderWithInput
          disableRounding
          value={active.lineHeight}
          onChange={this.setLeading}
          min={0.5} max={3} step={0.1}
          trackStyle={[{ backgroundColor: theme.palette.primary.light }]}
          handleStyle={[{ borderColor: theme.palette.primary.light }]}
        />
      </>
    );
    let underlineBlock = (
      <IconButton
        color={underline ? 'primary' : 'default'}
        onClick={() => this.toggleStyle('underline', underline)}
      >
        <FormatUnderline/>
      </IconButton>
    );

    let diameterBlock = null
    let is_curvedText = false;
    if (active.type === 'curvedText') {
      is_curvedText = true;
      lineSpacingBlock = null;
      const diameterMinBase = (active.fontSize / 40) * 130;
      let minDiameter = Math.ceil(diameterMinBase / 5) * 5;
      if (minDiameter < 5) minDiameter = 5;
      const diameterMaxBase = (active.fontSize / 40) * 720;
      const maxDiameter = Math.ceil(diameterMaxBase / 5) * 5;
      diameterBlock = (
        <>
          <div style={{ marginLeft: 16, marginRight: 16, marginTop: 3 }}>
            <Divider/>
            <Typography
              variant="caption"
              style={{ display: 'block', marginTop: 8 }}
            >
              Arch Diameter
            </Typography>
            <SliderNoInput
              disableRounding
              value={active.diameter}
              onChange={this.setDiameter}
              min={minDiameter}
              max={maxDiameter}
              step={5}
              trackStyle={[{ backgroundColor: theme.palette.primary.light }]}
              handleStyle={[{ borderColor: theme.palette.primary.light }]}
            />
          </div>
        </>
      );
      underlineBlock = null;
    }

    return (
      <div> {/* keep this for proper layout of buttons */}
        <Crumbs
          title="Edit Text" onBack={() => {
          canvas.discardActiveObject();
          canvas.requestRenderAll();
        }}
        />
        <>
          <Typography
            variant="caption"
            style={{ marginLeft: 16, marginRight: 16, color: '#8E8E8E', display: 'block', marginTop: 8 }}
          >
            Text
          </Typography>
          <TextField
            fullWidth
            type="text"
            margin="normal"
            style={{ paddingLeft: 16, paddingRight: 16, color: '#8E8E8E', display: 'block', marginTop: 0 }}
            value={text}
            onChange={e => this.setText(e.target.value)}
            onFocus={e => {
              e.target.select();
              this.setState({ text: text });
            }}
            onBlur={() => {
              this.onModified();
              this.setState({ text: null });
            }}
          />
          <Typography
            variant="caption"
            style={{ marginLeft: 16, marginRight: 16, color: '#8E8E8E', display: 'block', marginTop: 8 }}
          >
            Font Family
          </Typography>
          {this.getFonts(font)}
        </>
        <div style={{ marginLeft: 16, marginRight: 16, marginTop: 3 }}>
          {
            (active.type === 'ParText') &&
            <div className={classes.stroke_symbol_select}>
              <FormControl fullWidth>
                <InputLabel>Scorecard Key</InputLabel>
                <Select
                  value={active.strokeSymbol}
                  renderValue={(key) => STROKE_SYMBOLS[key]}
                  onChange={(e) => this.changeStrokeSymbol(+e.target.value)}
                >
                {STROKE_SYMBOLS.map((name, index) => (
                  <MenuItem key={index} value={index}>
                    {name}
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
            </div>
          }
          {
            <>
              <Typography variant="caption" style={{ display: 'block', marginTop: 8 }}>
                Font Size
              </Typography>
              <TextField
                fullWidth
                type="number"
                margin="normal"
                inputProps={{ min: 6 }}
                style={{ marginTop: 0, paddingTop: 0 }}
                value={font_size}
                onChange={e => this.changeFontSize(e.target.value)}
                onFocus={e => {
                  e.target.select();
                  this.setState({ font_size: String(actual_font_size) });
                }}
                onBlur={() => {
                  this.onModified();
                  this.setState({ font_size: null });
                }}
              />
              <Typography
                variant="caption"
                style={{ display: 'block', marginTop: 8 }}
              >
                Font Spacing
              </Typography>
              <SliderWithInput
                value={active.charSpacing}
                onChange={this.setTracking}
                min={-200} max={1000} step={1}
                trackStyle={[{ backgroundColor: theme.palette.primary.light }]}
                handleStyle={[{ borderColor: theme.palette.primary.light }]}
              />
              {lineSpacingBlock}
              <Typography
                variant="caption"
                style={{ display: 'block', marginTop: 8 }}
              >
                Rotate (Degrees)
              </Typography>
              <TextField
                type="number"
                margin="normal"
                inputProps={{ min: 0 }}
                value={Math.round(active.angle)}
                onChange={(e) => this.setAngle(+e.target.value)}
                onBlur={(e) => this.setAngle(+e.target.value)}
                style={{ marginTop: 0, paddingTop: 0, width: "50%" }}
              />
              <IconButton
                color={'primary'}
                onClick={() => this.setAngleLeft()}
              >
                <RotateLeft/>
              </IconButton>
              <IconButton
                color={'primary'}
                onClick={() => this.setAngleRight()}
              >
                <RotateRight/>
              </IconButton>
            </>
          }
        </div>
        <Divider/>
        {
          (!isMobileOnly) &&
          <>
            <IconButton
              color={bold ? 'primary' : 'default'}
              onClick={() => this.toggleStyle('fontWeight', bold)}
            >
              <FormatBold/>
            </IconButton>
            <IconButton
              color={italic ? 'primary' : 'default'}
              onClick={() => this.toggleStyle('fontStyle', italic)}
            >
              <FormatItalic/>
            </IconButton>
            {underlineBlock}
            <Tooltip title="Uppercase">
              <IconButton
                color={is_upper ? 'primary' : 'default'}
                onClick={() => this.toggleTextTransform()}
              >
                <FormatLetterCaseUpperIcon/>
              </IconButton>
            </Tooltip>
            <Tooltip title="Arched Text">
              <IconButton
                color={is_curvedText ? 'primary' : 'default'}
                onClick={() => this.changeDesign()}
              >
                <CurvedTextIcon/>
              </IconButton>
            </Tooltip>
            {diameterBlock}
            <Divider/>
            <IconButton
              color={alignment === 'left' ? 'primary' : 'default'}
              onClick={this.setAlign('left')}
            >
              <FormatAlignLeft/>
            </IconButton>
            <IconButton
              color={alignment === 'center' ? 'primary' : 'default'}
              onClick={this.setAlign('center')}
            >
              <FormatAlignCenter/>
            </IconButton>
            <IconButton
              color={alignment === 'right' ? 'primary' : 'default'}
              onClick={this.setAlign('right')}
            >
              <FormatAlignRight/>
            </IconButton>
            <Divider/>
          </>
        }
        <Typography variant="subtitle1" className={classes.subheading} style={{ display: 'inline-block', width: '40%' }}>
          Font Color
        </Typography>
        {
          (key_frames.length <= 1) &&
          <Typography variant="subtitle1" className={classes.subheading} style={{ display: 'inline-block', width: '40%' }}>
            Stroke
          </Typography>
        }
        {key_frames.length > 1 ? _colors : (
          <div className={classes.fill}>
            <ColorButton
              color={fill}
              onClick={(e) => {
                const x = 218.0; // (218.0 * ratio);
                const y = e.clientY; // (e.clientY * ratio);

                this.setState({
                  picker_open: true,
                  active_picker: 'fill',
                  anchor_position: { left: x, top: y },
                });
              }}
            />
            <ColorPicker
              canvas={canvas} color={fill}
              open={
                Boolean(this.state.picker_open) &&
                (this.state.active_picker === 'fill')
              }
              anchorReference="anchorPosition"
              anchorPosition={this.state.anchor_position}
              onClose={() => this.setState({ picker_open: false })}
              onChangeComplete={(color) => (
                this.handleColorChangeSingle('fill', color)
              )}
            />
          </div>
        )}
        {
          (key_frames.length > 1) &&
          <Typography variant="subtitle1" className={classes.subheading} style={{ display: 'inline-block', width: '40%' }}>
            Stroke
          </Typography>
        }
        {key_frames.length > 1 ? _strokes : (
          <div className={classes.stroke}>
            <ColorButton
              color={stroke || 'rgba(0, 0, 0, 0)'}
              onClick={(e) => {
                const x = 218.0; // (218.0 * ratio);
                const y = e.clientY; // (e.clientY * ratio);

                this.setState({
                  picker_open: true,
                  active_picker: 'stroke',
                  anchor_position: { left: x, top: y },
                });
              }}
            />
            <ColorPicker
              canvas={canvas} color={stroke || 'rgba(0, 0, 0, 0)'}
              open={
                Boolean(this.state.picker_open) &&
                (this.state.active_picker === 'stroke')
              }
              anchorReference="anchorPosition"
              anchorPosition={this.state.anchor_position}
              onClose={() => this.setState({ picker_open: false })}
              onChangeComplete={(color) => (
                this.handleColorChangeSingle('stroke', color)
              )}
            />
          </div>
        )}
        <div style={{ marginLeft: 16, marginRight: 16, clear: 'both' }}>
          <Typography variant="caption">
            Stroke Width
          </Typography>
          <SliderWithInput
            value={active.strokeWidth}
            onChange={this.setStroke}
            min={0} max={10} step={0.1}
            trackStyle={[{ backgroundColor: theme.palette.primary.light }]}
            handleStyle={[{ borderColor: theme.palette.primary.light }]}
          />
        </div>
        {
          (!isMobileOnly) &&
          <>
            <Typography variant="subtitle1" className={classes.subheading}>
              Drop Shadow
            </Typography>
            <div className={classes.shadowEditor}>
              <ShadowEditor
                active={active} activeGroup={activeGroup}
                color={theme.palette.primary.light}
              />
            </div>
            <Divider/>
          </>
        }
      </div>
    );
  }

  render() {
    let { client } = this.props;
    let palette = [];

    if (client) {
      palette = [
        client.color1,
        client.color2,
        client.color3,
      ];
    }

    if (this.props.active) {
      return this.renderTextEditor(palette);
    } else {
      return this.renderAddText(palette);
    }
  }
}

// -------------------------------------------------------------------------- //

export default compose(
  graphql(GET_USER),
)(withStyles(STYLES, { withTheme: true })(Text));

// -------------------------------------------------------------------------- //
