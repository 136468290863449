import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import moment from 'moment'
const PLANS = [
  {
    plan : 'STARTER',
    pretty : 'Starter'
  },
  {
    plan : 'PRO',
    pretty : 'Pro'
  },
  {
    plan : 'ALLSTAR',
    pretty : 'All-Star'
  },
  {
    plan : 'ELITE',
    pretty : 'Elite'
  }
];

const ACCOUNT_STATUS = [
  {
    plan : 'ACTIVE',
    pretty : 'Active'
  },
  {
    plan : 'INACTIVE',
    pretty : 'In Active'
  },
  {
    plan : 'CANCELLED',
    pretty : 'Cancelled'
  }
];
const styles = theme => ({
  button: {
    margin: 10,
  },
  controlContainer : { width : '40%', display :'inline-block'},
  grid: {
    width: '60%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
});


const SET_SUB = gql`mutation ex( $id : ID!, $plan_type : String,$subscription_start : String,$subscription_end : String, $credits : Int ,$total_users_allowed : Int, $account_status : String, $total_allowed_storage : Float){
  updateSubcriptionData(id : $id ,
                        plan_type: $plan_type,
                        subscription_start : $subscription_start,
                        subscription_end : $subscription_end,
                        credits : $credits,
                        total_users_allowed : $total_users_allowed,
                        account_status : $account_status,
                        total_allowed_storage : $total_allowed_storage){
    id
  }
}`

class Subscription extends Component {
  state = {
     id : null,
     plan_type : "PRO",
     subscription_start: Date.now(),
     subscription_end: Date.now(),
     credits : 0,
     total_users_allowed : 5,
     account_status : 'ACTIVE',
     total_allowed_storage : 5

   };
   componentDidMount(){
     const { client } = this.props;
     const { id, plan_type, credits,account_status,total_users_allowed,total_allowed_storage } = client;
     let { subscription_start, subscription_end } = client;

     this.setState({
       id,
       plan_type,
       subscription_start: (subscription_start && new Date(subscription_start)),
       subscription_end: (subscription_end && new Date(subscription_end)),
       credits,
       account_status,
       total_users_allowed,
       total_allowed_storage: (total_allowed_storage / 1073741824),
     });
   }

   handleDateChange = name => e => {
     this.setState({ [name]: new Date(e.target.value) });
   }

   handleTextChange = name => e =>{
      this.setState({ [name]: e.target.value });
   }

   handleStorageChange = name => e =>{
      this.setState({ [name]: e.target.value });
   }

   handleSave = ()=> {
         const {setSubscription} = this.props;
         const { id,plan_type, subscription_start, subscription_end, credits,account_status,total_users_allowed,total_allowed_storage } = this.state;
         const _variables = {
                id,
                plan_type,
                subscription_start: moment.utc(subscription_start).format('YYYY-MM-DD'),
                subscription_end : moment.utc(subscription_end).format('YYYY-MM-DD'),
                credits,
                account_status,
                total_users_allowed,
                total_allowed_storage : (total_allowed_storage * 1073741824)
         }
         setSubscription({variables : _variables}).then( json => console.log("JSON: ",json));
   }

	render() {
    const { classes } = this.props;
    const { plan_type, subscription_start, subscription_end, credits,account_status,total_users_allowed,total_allowed_storage } = this.state;
    
		return (
      <React.Fragment>
		   <Grid container className={classes.grid} justify="space-around">
            <div className={ classes.controlContainer}>
              <FormControl className={classes.formControl}>
              <InputLabel htmlFor="age-simple">Plan</InputLabel>
                  <Select
                  value={plan_type || ''}
                  onChange={this.handleTextChange('plan_type')}
                  inputProps={{
                    name: 'Plan Type',
                    id: 'age-simple',
                  }}
                >
                  { PLANS.map( (o,i) => <MenuItem key={o.plan} value={o.plan}>{o.pretty}</MenuItem>)}
                </Select>
              </FormControl>
              <br/><br/>
              <TextField
                 id="date"
                 label="Subscription Start"
                 type="date"
                 value={subscription_start ? moment.utc(subscription_start).format('YYYY-MM-DD') : ''}
                 onChange={this.handleDateChange('subscription_start')}
                 InputLabelProps={{
                   shrink: true,
                 }}
               /><br/><br/>
              <TextField
                 id="date2"
                 label="Subscription End"
                 type="date"
                 value={subscription_end ? moment.utc(subscription_end).format('YYYY-MM-DD') : ''}
                 onChange={this.handleDateChange('subscription_end')}
                 InputLabelProps={{
                   shrink: true,
                 }}
               />
               <br/><br/>
               <TextField
                  id="credits"
                  label="Credits"
                  type="number"
                  className={classes.textField}
                  value={credits || 0}
                  onChange={this.handleTextChange('credits')}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
            </div>
            <div className={ classes.controlContainer}>
             <TextField
                id="credits"
                label="Seats"
                type="number"
                className={classes.textField}
                value={total_users_allowed || 0}
                onChange={this.handleTextChange('total_users_allowed')}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            <br/><br/>
            <FormControl fullWidth className={classes.formControl}>
            <InputLabel htmlFor="status">Account Status</InputLabel>
                <Select
                value={account_status || ''}
                onChange={this.handleTextChange('account_status')}
                inputProps={{
                  name: 'Account Status',
                  id: 'status',
                }}
              >
                { ACCOUNT_STATUS.map( (o,i) => <MenuItem key={o.plan} value={o.plan}>{o.pretty}</MenuItem>)}
              </Select>
            </FormControl>

           <br/><br/>
            <TextField
               id="credits"
               label="Storage in GB"
               type="number"
               step={1000000}
               className={classes.textField}
               value={total_allowed_storage}
               onChange={this.handleStorageChange('total_allowed_storage')}
               margin="normal"
               InputLabelProps={{
                shrink: true,
              }}
             />
            </div>
       </Grid>
       <React.Fragment>
       <Button fullWidth onClick={this.handleSave}variant="contained" color="secondary" className={classes.button}>
     Save
   </Button>

       </React.Fragment>
      </React.Fragment>
		);
	}
}

export default compose(
  graphql(SET_SUB, { name: 'setSubscription' }),
)(withStyles(styles)(Subscription));
