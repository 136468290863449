import React from 'react';
import logo from '../../assets/prestoShots.png'


const Logo = props => (
    <img alt="PrestoShots Logo" style={{height: '75%'}} className={props.className} onClick={props.onClick} src={logo} />
)
export default Logo;
// const Logo = props => (
//   <svg
//     version="1.1"
//     id="Layer_1"
//     xmlns="http://www.w3.org/2000/svg"
//     x="0px"
//     y="0px"
//     viewBox="0 0 1024 360"
//     className={props.className}
//     onClick={props.onClick}
//   >
//     <title> ScoreShots Logo </title>
//     <g>
//       <path d="M109.4,246c-1.6,1.8-3.9,2.5-5.8,4.3c-1,0.4-1.3,0.7-1.6,1.4l-0.6,1.4c0.3,0.3,0.7,0.3,0.7,0.3l6.2-4
//         c1.6-0.8,3-1.1,3.6-1.8c2.6-2.5,14.2-10.1,16.5-10.5c0,0,0.4,0.7,0.4,1c-1,0.7-1.2,2.1-2.2,2.8c-1.2,2.1-12.2,9.6-13.1,11.7
//         c-0.2,2-1.8,3.8-3.4,4.9l-8.6,8.1c-0.9,1.4-1.9,2.8-4.2,3.2l-0.7,0c-0.3,0-0.3,0.3-0.6,0.7c0,0.3-4.5,3.2-4.5,3.6
//         c-1.3,1.1-1.9,1.4-2.2,2.5c0,0.3,0,0.7,0.4,1.3c0.7,1.6,1.5,3.6,1.6,5.9c-0.2,3.4-0.3,7-0.1,10.7l-0.3,0.7
//         c-1.2,2.4-1.5,4.4-1.7,6.1c-0.9,2.1-2.1,4.5-4,6.9c-2.2,2.1-3.8,4.5-5.7,6.6c-3.5,2.8-7.4,5.7-10.6,9.2c-2.6,0.8-3.9,2.2-5.9,2.6
//         l-11.1,4.9c-5.3,1.9-9.2,3.1-13.5,4l-2,0.1c-1.7,0.1-4-0.1-6.4-0.4c-0.3,0-0.7-1-1.7-1.3c-0.4-2-1.8-2.9-3.6-4.2
//         c-0.7,0-1-0.6-1.1-1c-0.4-0.7-1.1-1-1.4-1.6l1-0.7c0-0.3,0-1,0.3-1.7c0-0.3,0-1-0.1-1.3l0-0.3c-0.1-1.3-0.4-2.3-0.2-3.7
//         c0-0.7-0.1-1.7-0.1-2.3l0-0.7c0-1,1.1-5.4,1.4-6.1c0.6-1.4,2.2-3.1,3.5-4.2c1.2-2.1,2.4-5.1,3.7-6.9c2.9-2.5,5.8-4.3,7.7-6.7
//         c0,0,0.3,0,0.3-0.7c3-0.8,4.8-3.6,7.1-5.7c3.2-2.8,7.5-3.4,11.1-6.2l1.9-1.4c4.2-3.6,6.5-4,6.8-4.7c0-0.7-1.1-1.3-3.5-3.5
//         c-3.5-2.8-6.3-5.4-10.4-7.5c-2.8-2.2-6.2-4-9.7-6.5c-3.8-3.2-7.7-7.6-10-12.2c-0.8-2.6-0.9-5-0.7-7.3c0-1,0.2-2.4,0.5-3.4
//         c0.4-4.7,2.2-10.1,6-14l2.5-4.1c0.3-0.3,0.6-1,1.3-1.4c4.2-3.9,7.6-8.4,11.8-11.6c3.9-2.9,7.1-6,10.3-8.9
//         c7.4-5.7,15.5-10.5,23.3-16.5l1.7-0.1c2.9-2.8,6.9-3.3,9.9-3.5l2.7-0.1c2.3-0.1,3.7-0.2,5.3-0.6c1-0.7,2.3-1.1,3.3-1.2
//         c2-0.1,3.4,0.5,4.7,1.1c0.3,0,0.3,0,1.1,1.3c0.4,0.3,0.1,1.7,0.5,2.7c1,0,2.4,0.2,2.7,1.2c1.4,0.6,1.4,1.6,2.1,2.2
//         c0.5,2.7,0.9,4.6,2.3,5.9c0.3,0,0.4,0.7,0.4,0.7c-0.9,1.7-2.9,2.5-3.8,3.9c-3.7,0.2-6.5,3-10.5,3.2c-0.7,0-1.3,0.4-2,0.4
//         c-3.9,3.2-9.5,4.5-13,7c-0.3,0.3-0.7,0.4-1,0.4l-0.3,0c-4.1,4.6-11.1,5.6-14.9,10.4c-0.3,1-2.3,1.8-3.2,2.8
//         c-3.6,1.2-5.8,3.6-8.7,5.8c-5.5,3.6-9.6,8.8-13.7,14.1c-1,0.4-1.3,0.7-1.3,1.7c0,0.3-0.6,0.7-0.6,1c0,0.3,0.4,1,0.7,1.3
//         c1.7,1.3,3.2,3.5,4.9,4.4c4.5,2.8,9.3,5.2,12.5,8.4l12.6,10.8c0.4,0.3,0.4,0.7,0.7,0.6c0.3,0,0.7-0.4,1-0.4
//         c1.7-0.4,2.9-1.8,4.2-2.6c3.2-2.5,6.8-5.4,10-6.9c2.9-2.2,4.5-4.2,7.4-6.4c1.3-0.4,13-7,15-7.8c0-0.3,0.3-0.4,0.3-0.4
//         c0.3,0,1,0.3,1.4,0.6C121.1,239.1,110,245.6,109.4,246z M71.1,290l-0.4-0.3c-0.3,0-2,0.1-2.4-0.2l-0.6,0.7
//         c-5.5,4.6-11.9,9.6-18.7,13.6l-7,8c-2,0.8-2.3,1.8-2.2,2.5l0,0.7c-0.3,1.4,1.1,2,2.1,1.9c0.3-0.3,0.3-0.7,0.6-0.7
//         c0,0,0.3,0.3,0.7,0.3c6.5-4,9.4-5.1,15.9-10.5c1.3-1.1,2.6-1.5,2.9-2.5c3.8-3.9,6.4-6.7,8.5-10.8C70.5,292.1,71.1,291,71.1,290z
//       M123.3,235.6l0.3,0l0,1l-1.3,1.1L123.3,235.6z"
//       />
//       <path d="M101.4,281.2c2.1-5.1,3.8-10.6,4.9-16c0.3-1.4,0.2-3,0.8-4.7c1.5-3.4,3.4-6.2,4.2-9.9c0.3-0.7,0.6-1.4,0.9-1.4
//         c2.8-4.5,4.6-8.9,6.1-13c1.9-2.8,3.1-5.5,4.9-8.3c1-0.7,1.2-2.1,1.9-3.1c1.2-2.4,1.8-5.1,4-7.2l1.3-1.7c2.2-3.8,4-6.9,6.2-9.7
//         c1.2-2.7,3.8-4.9,5.7-7c0.6-1.4,1.2-2.4,2.2-3.1c3.2-2.5,6.4-6,9.6-8.2c1-1.1,1.9-1.8,3.6-2.5c0.6-0.7,1.3-1.1,1.9-1.4
//         c1.6-1.8,2.9-2.5,4.5-2.9c2-0.4,3.6-0.8,5.3-1.6c1-0.4,1.3-0.4,2.3-0.4c1,0,2.3-0.1,3.6-0.8c1,0,2-0.1,3,0.2l7.2,4
//         c1.7,0.6,3.1,1.5,4.5,2.5c2.4,1.2,3.2,2.9,4.3,4.8c1.2,3.6,1.3,7,0.9,10.7l-0.2,3.4c-0.2,3.4-1.4,6.4-1.9,9.8l0,0.7
//         c0,0.7-0.3,1.7-0.6,2.4c-0.7,5.7-2.8,11.2-4.9,16.6l0,1c-1.3,1.7-1.2,3.7-2.7,5.8c-0.6,1.4-1.5,3.4-1.8,5.1
//         c-0.9,1.7-1.8,3.4-3.1,4.8c-0.9,1.4-2.2,2.1-3.6,2.5c-2,0.4-4,0.9-5.9,1.6c-0.3,0.4-0.7,0.4-1,0.4c-1,0-2,0.1-3-0.5
//         c-1.4-1.3-2.4-1.9-3.5-3.2c-1.1-1.6-2.2-3.2-1.9-5.6l0-1c0.9-2.1,1.5-4.1,2-6.5c0-0.7,0.6-1.4,0.9-1.4c1.8-4.1,3.6-7.9,5.8-11.7
//         c1.5-4.1,3.3-7.9,3.4-11.9c-0.1-1.3,0.6-2.4,0.9-3.1l0.9-3.1c0.9-2.4,1.1-5.1,1.6-7.8c0-0.7-0.4-1.3-1.1-1.6
//         c-0.7-0.3-1-0.3-1.4-0.3c-0.7,0-1.3,0.4-2,0.8c-1.6,0.7-2.6,1.5-3.6,2.5c-3.5,3.2-7,6.4-8.8,10.8c-1.3,1.1-1.2,2.7-2.8,3.8
//         l-2.2,2.8c-1,0.7-1.3,1.7-1.2,2.7c-1.3,1.4-1.9,3.1-2.8,4.8c-2.5,2.8-3.7,6.2-5.9,9.3c0,0.7-0.3,1.7-0.9,2.7
//         c-1.2,2.1-3.1,4.5-4.3,6.9c-1.5,3.1-2.7,6.2-4.6,9.3l-4.8,11.6c-1.8,4.4-3.9,9.6-5,14.6c-0.7,0.4-0.6,0.7-0.6,1l0,1
//         c-0.9,1.4-0.9,3.1-0.8,4.4c-0.3,1-0.6,1.7-0.6,2.4c-0.6,1.4-0.9,2.7-0.8,4.1c-0.3,1.4,0.1,2.7,0.2,4c0.4,2,0.5,4,0.6,5.3
//         c-0.3,0.7,0.4,1.3,0.8,2c0.4,1,1.4,1.3,2.4,1.2l4-0.2c2-0.1,3.3-1.2,4.9-2.2c5.2-2.6,9.4-5.8,13.9-9.1c3.2-2.8,6.4-5,10.3-7.5
//         l5.5-4.6c3.2-2.8,5.8-4.6,8.3-7.4l12.1-12c0.6-0.7,1.9-1.8,1.9-2.8c0.9-1.4,2.2-2.4,3.2-3.2c0.3-0.4,21.7-20.5,22.1-20.5
//         c0.7,1-19.7,21.4-19.3,22c0.1,1.3,18.9-17.3,19.9-17.4l0.1,1.3c0,0.3,0,0.3-0.3,0.7c-0.9,1.7-18.9,23.3-19.5,25
//         c-0.6,1-1.2,2.1-2.9,3.2c0,0,0,0.3,0.4,0.3l0.3,0.3c1.3-0.1,2.9-2.8,3.8-3.5c0-0.3,14.6-16.4,14.9-16.5c0,0-14.4,19.4-14.4,19.8
//         l-0.6,1c-0.6,0.7-1.2,2.1-1.9,3.1c-0.9,1.4-2.2,2.1-2.2,3.5c-0.3,1-1.3,1.4-1.9,2.1c-0.3,0.7-0.6,1.7-0.6,2c-0.6,1-0.9,2.1-1.5,2.8
//         c-1.3,1.1-2.6,2.5-2.8,3.8l0,0.3l-1,0.7c-1.3,1.1-2.2,2.5-3.5,3.2c-2.2,2.8-5.4,5-9,7.1l-6.4,5.7c-3.2,3.2-6.8,5.4-10,7.9
//         c-0.7,0.4-1.3,1.1-2,1.1c-2.3,0.8-5.2,2.3-7.8,3.7c-1.6,1.4-6.2,2.3-9.6,2.8c-1,0.4-2,0.4-3,0.5c-1,0-2.3,0.1-3.4-0.5
//         c-0.7,0-1.7-0.3-1.4-0.6l-2.3,0.1c-3.3,0.2-8.1-1.3-10.5-2.8c-0.7-0.3-1.4-0.9-2.1-0.9l-5.9-5.1c-1.4-1.3-2.1-2.6-2.6-4.2
//       c0.2-2-0.2-4.3-1.6-5.9L101.4,281.2z"
//       />
//       <path d="M191.4,285.5c0.2-3.7,0.3-6.7,0.5-10.1l0.1-5c0.3-0.7,0.3-1.4,0.2-2l0.1-4.4l0.6-2c0-0.7,0.6-2.4,0.8-3.7
//         c1-1.1,1.2-2.1,1.2-3.1c0-0.7,1-0.7,1.2-2.1c0.8-3.4,2.4-6.5,3.2-9.2c-0.1-1.7-0.1-2.3,1.5-3.1c0.3,0,0.6-1.4,0.6-1.7
//         c0.3-0.4,0.6-1.4,0.6-2c0-0.7-0.1-1.3,0.3-1.7c1.3-0.7,1.9-1.4,1.9-2.8c0.3-0.7,1-1.1,1-1.1c2-0.8,2.9-1.8,2.9-3.2
//         c0.6-1.4,1.2-2.7,2.2-2.8l0.3,0c0.3,0,0.3-0.7,0.3-0.7l0.3-0.3c0-1,4.1-5.2,13.3-14.4l0.4,0.3c1,0,2.9-3.2,4.2-3.2l2.7-0.1
//         c3-0.1,5.9,3.7,9.7,7.5c0,1,0.4,1.3,0.4,1.3c-1,0-1,0.4-1,0.7l-0.3,0.7c0.7,0.6,0.5,3,1.8,2.9c0.3,0,0.7,0.3,0.7,0.3
//         c0.4,0.7,0,0.3,0,0.3c-0.7,0-1,0-1.7,0.4l0,0.7c-0.6,1.7-0.6,1.7-2.3,1.8c-1-0.3-2-0.2-3-0.2c-1.6,0.7-2.2,2.1-3.2,3.2
//         c-0.7,0-1,0.7-1,1c-2.3,0.4-3.2,2.5-4.2,3.6c-0.6,1-1.9,1.8-2.2,2.5c0,0.3,0.4,0.3,0.4,0.7c0,0.7-0.3,1-0.6,1l-0.2,2.7
//         c-1.5,3.1-3.1,5.2-4.9,8.6c-0.9,1.7-2.5,4.1-3.3,6.9c-2.1,5.1-3.8,11.9-4.8,17.6c-0.3,0.7-0.6,1-0.6,1.4l0.1,1.3
//         c0.4,0.7,0.4,1,0.4,1.3l-0.5,4c0,1,0.1,2-0.5,3l0,0.3c0,1,0.4,2,0.5,2.7l0.7,6.7l0.1,2.3c0,1,0.1,1.3,0.7,1.3c0.3,0,1,0,1.7-0.4
//         c1.3-0.7,2.7-0.5,3-0.8l1,0c0.3,0,0.7,0,1.3-0.4l0.3,0c0.3-1.7,2.3-1.8,3.3-1.8l4.7-6.3c1.3-0.1,0.9-1.7,2.3-1.8l0.3,0
//         c0.3,0,0.6-0.7,0.6-0.7l-0.1-1.3c0.3-1.7,1.6-2.4,2.5-4.1c0,0,1-0.7,1.3-0.7c0.3,0,0.3,0.3,0.7,0.3c1,0,1.3-1.1,1.5-2.8
//         c0.7-0.4,1.3-1.4,1.9-2.4c0.3-0.4,0.3-0.7,0.6-0.7c0,0,0.3,0,0.7,0.3c0.3,0,0.7,0,0.7-0.4c0.6-0.7,1.6-1.1,1.6-1.8
//         c0.3-1.7,0.5-2.7,1.2-3.1c0.3-0.4,0.3-0.7,0.3-1l0-1c0.6-1,0.9-3.1,1.8-3.8l0-0.3c0,0,0-0.3,0.3-0.3l0.7-0.4l0-0.7
//         c0.3-1.7,1.2-2.1,1.8-3.8l1.6-2.4c0-0.3,0.6-1,0.6-1.7c0.3-0.3,0-0.7,0-1l-0.1-1.7c1.6-2.1,1.4-4.8,2.3-6.8
//         c1.5-3.8,2.3-8.1,2.7-12.5c0.5-2.7,0.8-4.7,1-7.4l0.2-3c-0.2-4-2.3-5.6-3.8-8.5c-0.4-0.7-0.8-2-2.5-2.9c-1.4-1.3-2.2-2.9-3.5-3.2
//         l-1.4-1.6c-0.4-0.3-0.7-0.6-1-0.6c-0.3,0-0.3,0.7-0.3,1l-0.3,0.7c-0.3,0-0.7-0.3-0.7-0.3c0-0.7-0.4-1-1.1-1.3c-0.3-0.3-0.4-1-0.7-1
//         c-0.3,0-0.3,0.3-0.6,0.7c0,0-0.4-0.3-0.7-0.3c0.3-1,0.2-3,1.2-3.1c0,0,0.7-0.4,1-0.4l0.3,0c0.3,0.3,0.7,0.3,1,0.3
//         c0.3,0,0.3-0.4,0.6-0.7l1.3-1.1l-1-0.3l-0.3,0c-0.7,0-0.7-0.3-0.7-0.3c0-0.7,0.6-1.4,0.6-1.7c0,0,0.3-0.3,0.7-0.4
//         c0,0,0,0.3,0.4,0.3c0.7,0,1,0.3,1.4,0.3c1,0,1.7-0.4,2.9-1.8c0.4,0.7,0.4,0.7,0.7,0.6c1.7-0.1,2.9-1.8,3.9-2.5l1.3-0.1
//         c2.7-0.1,5.3-1.3,7.9-2.1c2.6-1.1,5.2-3.3,8.5-3.8c0.3-0.4,0.7-0.4,1-0.4l1.3-0.1c1,0,1.7-0.4,2.6-1.1l1,0c1.6-1.1,3.2-2.5,4.2-3.9
//         c0.6-1.4,2-0.4,2.3-1.8l7.1-5c2.6-1.5,3.5-3.9,5.1-5.3c0.7-0.4,0.6-1,1-1.1c0,0,0.4,0.3,0.7,0.3l0.3,0c0.3,0,0.3,0,0.3-0.3
//         c0.6-1,1.6-1.1,1.6-2.1l0-0.3c0-0.3,0.6-1,1-1c0.4,0.3,0.4,0.3,0.7,0.3c1,0,1.6-1.8,2.6-1.8c0.7,0,1-1,1.3-1.4
//         c0.7-0.4,1.3-0.4,1.3-0.4c0,0.3,0.4,0.7,0.4,0.7l0.3-1c0.3-1.4,0.2-2.4,0.6-2.4c0.3,0,0.7,0.3,0.7,0.3c0.7,0,0.3-1,1.3-1.7
//         c1.6-0.7,3.3-1.8,4.2-3.9c0.3-1.4,1.6-1.4,1.6-1.8c1.6-0.7,1.9-1.8,1.9-2.8l-0.1-1.7c0.5-2.7,1.8-3.4,3.1-5.5c0,0,0.7-0.4,1-0.4
//         l-0.3,0.3l-0.3,1l0,1l-0.3,0c-1,0.4-2.3,1.1-2.2,2.1c0,0.3,0.4,0.3,0.4,0.7c0,0.3,0.4,1.3,0.4,1.3c0.3-0.7,1.2-2.1,1.5-3.1l0.1,2
//         l0.6-1l0.2-3.7l0.6-1.4l0-0.3l2.6-2.5l2.1-4.5c-0.3,0-0.4-0.3-0.4-0.7c0-0.3,0-0.3,0.3-0.7c0.4,1,0.7,1.3,1.4,1.9l-0.3,1.4
//         c0.7,0,1,0,1.3-0.4c0.6-1,0.9-1.7,0.9-2.1c0-0.3-0.4-0.7-0.7-1.3l0.3-1c0.3,0,0.6-0.7,0.7-0.4c0.7,1,1.1,1.6,1.8,1.9l0.7,0.6
//         c0.3,0,0.3-0.4,0.6-0.7c1.3-0.7,3.2-2.2,4.9-2.6c0.6-0.7,1-0.7,1.3-0.7c0.3,0,0.7,0,1.4,0.3l4.5,2.8c2,0.6,3.1,2.5,3.9,3.5
//         c0.4,0.3,0.7,0.6,0.7,1c-1,0.7-0.9,1.7-0.9,2.7c-0.9,1.7-1.8,4.4-2.7,5.8c-2.2,3.1-3.7,6.2-5.2,9.6c-1.2,3.4-2,7.5-3.8,10.6
//         c-1.9,2.4-2.7,5.5-3.9,8.2c-0.3,0.3-0.6,1.4-0.9,2.4c-1.8,5.1-2.5,9.8-5.3,14.6c-1.2,3.1-2,6.8-3.5,9.9l-3.1,10.9
//         c-2.4,4.8-4.2,9.2-5.7,14.3c-1.2,3.4-2.4,6.5-3.2,9.9l0,0.3c-0.3,0-0.7-0.3-0.7-0.3l-0.2,3.7c-1.8,5.1-2.5,9.8-4.3,15.3l0.1,1.7
//         c0,0.3,0,0.7,0.4,0.7l0,1c-0.9,1.7-1.8,3.8-2,6.1c0,0.3,0.4,0.3,0.7,0.6l0.6-0.7c0.6-1.7,1.5-2.8,2.1-4.5l0-0.3
//         c0,0,0.7,0.6,0.7,0.3l0-0.3c0-0.7,0.3-1.4,0.9-2.4l1.2-2.1c0-0.7,1-1.1,0.9-2.1c0.3-1,1.3-1.7,1.9-2.4c0.3-0.4,1.3-0.7,1.9-1.4
//         c0.2-2.7,2.4-5.5,3.3-8.2l0.9-1.7c1.3-0.4,2-0.4,2-1.1l0-0.7l0.6-1.4l0.7,0c0.7,0,0.7-0.4,0.6-0.7l0.2-2l0.6-1
//         c1.3-0.7,2.3-1.8,3.2-3.8l1.9-1.8l0-0.3c0,0,0-0.3,0.3-0.7l0.3-1.4l1.1,1l0.3-1c0.3,0,0.7-0.4,1-0.4c0.3-0.4,0.7-0.4,0.6-1
//         c0-0.3-0.4-0.7-0.4-1c-0.3,0-0.3,0-0.4-0.3c0.3-0.3,0.6-1,0.6-1.7c1.7-0.4,2.6-1.8,3.8-3.5c0.3-0.4,0.6-1,0.6-1.4
//         c1-0.7,0.3-1.7,1.3-1.7c0.7,0,0.6-1.4,1.2-2.4c1.3-1.4,1.5-3.1,3.2-3.5c0.6-1.4,1.2-2.1,1.9-2.4c1.3-1.4,2.9-2.8,3.5-4.2
//         c0-0.7,0-1,0.3-1s0.7,0.3,1.1,1l0.3,0c0.3,0,0.3,0,0.3-0.3l0-0.7c1-0.7,1.6-1.8,1.9-1.8c1,0,0.6-1.7,1.2-2.4
//         c0.3-0.7,1.3-1.1,2.3-1.1c1-0.4,1.6-1.1,1.9-1.8c1-1.1,1.6-1.8,2.6-1.8c0,0,0.3,0,0.7,0.3l-0.3,0.3l-0.3,0
//         c-0.6,0.7-1.6,0.7-1.6,1.1l0.4,0.3l0,0.7l0.3,0c0.6-0.7,1.6-0.7,1.6-2.1l0.3-0.7c0-0.7,0.3-0.7,0.3-0.7c0.3,0,0.7,0.6,1,0.6
//         c0.3,0,0.3,0,0.6-1c-0.1-1.3,1-1,1.3-1.4l1,0c0,0.3,0,0.3-0.6,0.7c0,0.3,0,0.7-0.3,1l1.3-0.1c0.7-0.4,1-1.1,0.9-1.4l0-0.3
//         c0.3-0.7,0.6-1,1-1.1c0.3,0,0.3,0.3,0.7,0.3c0,0.3,0.4,0.3,0.7,0.3c0.3,0,1-0.4,1.6-0.7c1-0.7,2-0.1,2.6-1.5c0.3-0.4,0.7-0.4,1-0.4
//         c0.7,0,1.7,0.3,2,0.6l2.3-0.1c2.7-0.1,5,0.4,6.8,1.3c1,0.3,1.4,0.3,1.4,1.3l0,0.3c0,0,0.3-0.3,0.7-0.4s1.4,0.6,1.7,1.3
//         c-0.3,1,1.1,1,1.8,1.9c1.4,0.6,2.4,1.2,2.2,3.2c0.3,0.3,0.7,1,1.1,1c0.4,0.7,0.4,1.7,0.5,3l-0.3,1.4c-0.3,0.3-0.3,0.3-0.3,0.7
//         l0,0.3c-0.6,1-1.2,2.7-1.2,3.4l0,0.3c0,0.3,0,0.3-0.3,0.3l-0.3,0l-0.3-0.3c-0.3,0-0.3,0.3-0.3,1c-1-0.3-1.4-0.6-1.7-0.6
//         c-0.3,0-0.3,0-0.7,0.4c-0.3,0.7-0.6,1-1,1.1c-0.3,0-0.7-0.3-1.1-1c-1-0.6-1.4-1.3-2.1-1.2c-0.3,0-0.7,0-1,0.4
//         c-0.6,0.7-1.3,1.1-1.6,1.1c-0.3,0-1-0.3-1.7-0.9c-0.3,0-0.4-0.3-0.4-0.3c-0.3,0-0.3-0.3-0.3-0.3c-0.3,0.3-0.3,0.3-0.3,0.7
//         c-0.3,0.3-0.3,0.3-0.7,0.4l-0.3,0c-0.4-0.3-0.4-1-0.4-1.7c-0.3-0.3-0.3-0.3-0.7-0.3s-0.7,0-0.7,0.4c0,0-0.7-0.3-1-0.3
//         c0.3-0.3,0-0.3-0.3-0.3c-3.3,1.8-5.8,3.6-8.4,5.4c-0.3,0-0.3,0.4-0.3,0.4s-0.3,0-0.4-0.3l-0.3-0.3c-0.3,0-0.3,0.3-0.6,1.4
//         c-0.2,2-2.5,2.8-4.2,3.6c0.4,0.3-0.3,0,0,0.3c-2.2,3.5-4.8,3.9-5.7,6c-0.6,2-2.2,3.5-3.1,4.8l-6.6,8.4l-1.8,3.4l-0.3,0.4l-0.3,0
//         c-0.3,0-0.7,0.4-0.6,0.7l-1.9,2.4c-1,0.7-0.9,1.7-0.9,2.1c-0.3,1-0.6,1-1,1.1c-0.3,0-0.7,0-0.7,0.4l-0.3,1c0,0.7-1,0.4-1.6,1.1
//         c0,0,0.4,0.7,0.4,1c-0.6,0.7-0.3,1.7-1.3,1.7l-1,0l0.1,1.7c-0.7,0.4-1,0.7-0.9,1.4l-3.1,5.5l-0.3,0c0,0.7-1.3,0.4-1.3,1.1
//         c0,0.3,0,0.7,0.4,1c-0.6,1-1.6,1.4-1.6,2.1l-2.2,3.1c0,0.7-0.6,1.7-1.2,3.1l-0.9,2.7c-0.6,1.4-0.9,2.4-2.2,3.5
//         c-1.8,4.4-3.9,9.6-6,13.7c0,0.3-0.3,1-0.6,2c-0.6,0.7-1.3,1.1-1.3,1.4c0,0.3,0.3,0.3,0.7,0.6l0,0.3c0,0.3-0.3,0.7-0.3,0.7l-3.7,6.5
//         c-0.6,1.4-0.9,2.7-1.8,3.8c-0.9,3.1-2.4,6.1-3.9,8.9c-0.6,2.4-2.1,4.5-3.4,6.5c-0.6,0.7-2,0.8-2.6,1.5c-0.3,1-0.6,1-1,1.1l-1.3,0.1
//         c-2,0.1-4,0.9-5.9,2.3l-0.3,0.3c-0.3,0-0.7,0-0.4-0.7c-0.4-1-0.7-1.3-1.1-1.3c-0.3,0-0.3,0-0.7,0.4c-0.3,0.4-0.6,0.7-1,0.7
//         c0,0-0.3,0-0.4-0.3l-1.7-0.9c-0.4-0.7-0.4-1-0.4-1.3c0.3-1.7-1.8-2.3-2.2-3.6c-0.3-0.3-1-0.6-1.4-0.6l-0.3,0c0.3,0,0.3-0.3,0.7-0.4
//         l-0.8-2.3c-0.4-1-0.8-2.3-0.5-3.7c0-0.3,0.3-0.3,0.3-0.7c0-0.7-0.7-1-1.7-0.9l0-0.3c1.3-1.4,1.3-1.7,1.2-2.4l0.4-6
//         c0-0.3,0-0.7,0.3-1.4c0.3-1,0-1-0.1-2l2.2-16.2c0.5-3,0.4-5.4,1.3-8.4l0-1c-0.3,0-0.7,0-0.7-0.3c0,0.3-0.3,0.4-0.3,0.4l-0.6,1l0-1
//         c0.3-1,0.3-1.7,0.9-2.7c1-0.7,1.6-2.1,1.5-3.1c0-0.7-0.4-1.7-0.1-2.3c-0.4-0.3-0.4-1-0.4-1.3c0-0.3,0-0.7,0.6-1.4
//         c0.6-1,0.5-3,1.8-3.8c0.3-0.3,0.6-1.4,0.6-2c0-0.3,0.3-1,0-1c-0.7-0.6-0.7-1.3-0.7-1.6c0-0.3,0-0.7,0.3-1c0.7-0.4,1-1.1,0.9-1.7
//         l-0.1-1.7c0.2-2.4,1.2-3.7,2.1-5.8c-0.3,0,0-0.3,0.7-0.4c0.7-0.4,1-0.7,1-1c0-0.3,0-1-0.4-1.3l0-1c0.2-2.4,0.8-3.4,1.4-5.4
//         c1.8-3.8,3-7.8,3.8-11.6l2.6-7.5c1.5-4.4,3.9-8.9,4.7-13.3l0-0.7c0-0.7,0.6-1.4,0.9-2.4c0.6-1,1.2-2.7,1.5-4.1l0.3-0.4l0.3,0l1-1.1
//         l-0.4-1l-1.3,0.1c-3,0.8-4.5,3.2-4.7,7.3c0,0.3-0.3,0.7-1,1.1l-0.3,0.4c0,0-0.4-0.3,0-0.7c0.3-0.7,0.3-1.4,0.2-2.4l0.3-1.7l-1,0
//         c-1,1.1-2.5,3.1-3.8,3.5c-1.6,1.1-4.2,2.2-6.5,4c-0.3,0.4-1.3,0.7-2,0.8c-0.3,0-1,0-1-0.3l-0.7,0.4c0.4,0.3,0.4,0.7,0.4,0.7
//         c0,0.3-1,0-1.3,0.7c-0.3,0.7-1.3,1.4-2.2,2.1l-0.3,0.4c-0.3,0-0.7-0.3-0.7-1l-0.3,0c-1.3,1.7-3.2,2.2-4.9,2.6
//         c-1.6,0.7-2.3,1.1-2.6,1.8c0,0.7,0.4,1.3,1.1,2.6c0.4,1,0.8,2.6,0.5,3.7l0,0.7c0,0.7,0.7,1.6,0.4,2.3c-0.3,0-0.3,0.3-0.3,0.7
//         c0,0.7,0.1,1.7,0.4,2c-0.3,0.3,0,1,0.1,1.3c0.1,1.3-0.9,2.7-0.8,4.4l0.1,1.7c0.7,1.3,0.8,2.6,0.9,4l-0.8,4.4
//         c-0.8,4.4-1.2,9.1-3,12.9c-0.3,0.7-0.6,1-0.6,1.7l0,0.3c0,1-0.3,1.7-0.6,2.4c-0.9,2.1-0.7,5.4-3.6,7.5l0,1c-0.5,3.7-2.1,5.8-3,7.8
//         c-0.7,0-0.9,1.4-0.9,2.1c-0.9,2.1-1.8,4.8-3,7.2c-0.6,1-1.8,3.4-2.5,4.1c-1.3,1.7-1.1,4.7-3.7,6.2c-0.2,2-1.5,2.8-2.5,4.1l-5.3,8.3
//         l-1.6,1.8l-1.7,0.1l0,0.7c-0.3,1-0.6,1-1.3,1.7c-1.6,1.8-4.1,5.2-7.3,7.1c-1.6,1.4-4.9,2.9-7.2,3l-0.7,0c-0.3,0.7-0.3,0.7-0.3,1
//         c-1,0.4-1.7,0.4-2.7,0.5c-2,0.8-5.3,0.9-6.3,1.3l-1.7-0.6l-1-0.6c-0.7-0.3-1-0.3-1-0.3l-0.3,0c-0.3,0-0.3,0.4-0.7,0.4
//         c-0.3,0-1-0.3-1.4-0.9c0-0.3-0.4-0.3-0.4-0.3l-0.7,0c0,0-1,0-1.4-0.3c-0.4-2.3-2.8-2.5-3.2-3.5c-1-0.3-1.4-0.6-1.7-0.6
//         c-0.3,0-0.3,0.4-0.6,1c0,0.3-0.3,0.4-0.3,0.4c-0.3,0-0.3,0-0.3-0.3c-1.4-1.6-2.6-4.6-4.6-4.5c-1-0.3-1.1-1.3-1.7-1.3
//         c-0.4-0.3-0.7-1.3-1.1-2.6c-0.3,0-0.4-1-0.7-1.6l-3.6-12.2L191.4,285.5z M321,146.3l-0.3,1l-0.3,0.7l-0.1-1.7l0.9-1.4L321,146.3z"
//       />
//       <path d="M402.2,304.5l0-0.3l-6.6,2.7l-1.3,0.1c-1,0-2.3,0.4-3.6,0.8l-5.6,0.9c-0.7,0-0.4-0.3-1.4-0.3l-0.7,0
//         c-2.7,0.1-7.4-0.6-9.8-1.2c-1.4-1.3-2.8-1.9-5.1-2.4c-1,0-1.7-0.6-2.4-1.2c-1.4-0.9-3.8-1.8-4.9-3.4l-2.2-3.2l0.2-2.7l-1.7-7.6
//         l0-0.3l0.6-1.4l1-0.4l0.2-2l-1,0.4l-1,1.1l-0.6-12l0.5-2.7c0.3,0,0.3-0.7,0.6-1l-0.1-3c0.5-3,1.7-6.4,2.5-9.8
//         c0.3-1.4,0.2-2.7,1.2-3.1l0.2-2.4c0.8-3.7,3-6.8,4.2-10.6c1.3-1.7,1.5-3.4,2.4-4.8l5.2-10c0-0.7,0.6-1.4,1.6-2.4l2.5-3.8
//         c0.6-0.7,1.2-2.4,2.5-3.5l0.9-1.7l4.4-6.6l0.3-0.3c0.9-1.4,1.2-2.7,2.5-3.5l3.5-3.5c0.6-1,1.3-1.4,1.6-2.1c0.6-1,1.6-1.8,1.9-2.4
//         c1-0.4,2.3-1.8,2.9-3.2c-0.1-1.3,1.2-2.4,2.2-2.8c2.6-1.1,5.2-3.3,7.8-5.1l0.3,0c0.3,0,0.7-0.4,0.7-0.4l1.6-0.7
//         c0.7,0,1.3-0.1,1.7-0.4c1-0.4,2-0.8,3-0.8c1.3-0.1,2.3-0.1,3.4,0.5l2.3-0.1c1,0,1.7,0.3,1.7,0.6l3.1,0.9c1.7,0.6,3.7,1.2,4.8,2.8
//         c0.4,0.7,1.1,1.3,1.8,1.6c1,0.3,1.4,1.3,2.5,2.9c1,0.3,0.7,1.3,1.1,2c0.4,1.7,0.8,2.6,1.5,3.6c1.1,1,1.1,1.6,1.1,3l-0.2,2.7l0.1,3
//         l-0.9,2.1c-0.3,0.7-0.2,2-0.5,3c-0.3,1.7-0.5,3.7-1.1,5.7l-0.3,1.4c-1.2,2.1-2.1,4.8-3,6.8l-2.8,4.8l0,1c-0.3,0.4-0.3,1-0.9,1.4
//         l-1.5,2.8c0,0.3-0.3,1-0.6,1.7c-1.6,1.8-2.8,3.8-5.1,4.9c-1.9,2.4-4.5,3.9-6.8,5.4l-2.9,2.2c-1.9,2.1-4.2,3.2-6.1,4.3
//         c-1.9,1.4-3.9,2.5-5.2,3.9l0,0.3l-0.7,0c-2,0.8-3.3,1.8-4.6,2.6c-1,0.7-2.9,1.5-3.9,2.5l-3.6,1.2c-1.3,0.1-2.3,0.4-3.3,0.5
//         c-0.7,0-1,0.4-2,0.8c-3.2,2.2-3.2,2.2-4.4,5.6c-0.3,0.7-0.6,1.4-0.6,2l-1,0l-0.1,4.4c0,0.3-0.3,1.4-0.6,2c-0.3,0.4-0.6,1.4-0.6,2
//         c-0.3,0.7,0,0.7,0.1,1.3c0,0.7,0.4,1,0.4,1.7c-0.3,0.7-0.6,1.4-0.6,1.7c0.1,1.7,1.5,2.6,2.6,4.2c0.4,1,1.8,1.6,3.1,1.9l0.7,0
//         l3.3-0.8c0.7,0,1.4,0.3,2,0.2c0.7,0,1-0.4,1.6-1.1c0.3-0.3,0.7-0.4,1-0.4c0.3,0,0.7,0,0.7,0.3l1,0c0.7,0,1.7-0.4,2.3-0.8
//         c0.3-0.7,1.6-1.4,2.6-1.5c0.7,0,1-0.4,1.3-0.7l1.3-0.7l0-0.3l0.3-1l5.3-1.6c1.9-1.8,3.9-2.5,5.1-4.3l4.2-2.9l4.1-4.2
//         c0.3,0,0.7,0,1-0.4c0.9-2.1,1.8-3.4,3.2-3.5c1.3-1.4,1.9-2.8,3.5-2.8l0.3,0c0.3-0.3,0.3-0.7,0.3-1c0.3-0.4,0.3-0.4,0.3-0.7l0-0.3
//         c1-1.1,2.9-2.2,3.5-2.9c2-1.1,2.1-4.1,4.1-4.9c1.3-1.1,3.2-2.5,4.4-5.6c1.6-1.1,2.6-1.5,3.2-2.8l6.6-7.7c1.7-0.4,2.6-2.1,4.2-3.9
//         c1.9-1.8,2.3-1.4,4.2-2.9l0-0.3c-0.1-1.3,3.2-2.5,4.2-3.2c0.7-0.4,0.2-2,1.6-2.1c0.3,0,9.7-7.8,10-7.9c0.7,0-7.6,7.7-7.6,7.7
//         c-0.6,0.7-0.6,1.4-0.6,2c0.6-0.7-16.9,16.2-26.2,25.4c4.8-4.3,14.1-12.7,21.9-17.8c0.3-0.3,3.7-6.2,3.7-5.9
//         c1,0.6,11.2-9.9,11.2-9.6c0,0.7-9.2,9.5-15.2,16.8c-5.1,4.9-26.3,29.4-26.3,29.4l35.9-37.9c-4.4,5.9-20.9,24.8-20.8,25.1
//         c0,0.3,14.6-15.4,15-15.1l0,0.3c-6.3,7.7-13.8,18.1-14.1,19.1c0,0.3,17.1-19.6,17.1-19.2c0,0.3-4.4,6.2-4.4,6.2l0,1
//         c-1.6,2.1-2.5,4.5-5,6.3c-0.6,0.7-1,1.1-1.2,2.4l0,0.7c0,0.3-0.3,1.4-0.9,2.4c-1,0.7-1.2,2.1-2.2,2.8c-1,0.7-0.6,1.4-1.6,2.4
//         c-1.6,1.8-3.5,2.9-3.5,4.2l-1.9,2.4c-0.3,0.4-3.8,3.5-3.8,3.9c-1,0-2,1.1-2.6,1.8c-0.3,0.7-1,1.1-1.6,1.4l-1.2,2.1
//         c-0.9,2.4-1.2,3.1-2.8,4.5l-1,0c-0.3,0-0.7,0-1,0.7l-8.3,7.4l-1.3,0.7c-2.2,2.8-4.8,4.6-7.1,5.7l-8.4,5.8L402.2,304.5z
//         M393.4,235.4c2.9-1.5,5.2-2.9,6.8-4.7c0.9-1.4,2.9-2.5,4.8-3.9l3.1-4.2c1.6-1.4,2.9-3.2,3.4-5.5c0-0.3,0.3-1,0.6-1.4
//         c1.9-1.8,3.4-4.9,4.3-7.6c0.6-1.7,0.9-3.1,2.5-4.1l-0.1-1.7c0-0.3,0.3-1-0.4-1.3c-0.4-0.7-0.7-1-1.4-0.9l-1,0l-1.9,1.8
//         c-1.3,1.4-2.8,3.5-4.5,4.2l0,1c-1.3,1.1-2.6,2.5-3.5,3.9c-0.6,0.7-1.6,1.1-1.6,2.1l-2.5,3.1l-2.5,3.5c0,0.7-0.6,1.4-0.9,1.7
//         c-1,1.1-1.3,1.4-1.2,2.4l0,0.7c-0.3,1.4-0.9,1.7-1.9,2.4c-0.3,0-0.7,0.4-0.6,0.7c-0.3,0.7-1.6,1.4-1.6,2.4l0,0.3l-1.5,3.8l-0.3,1
//         c0.3,0,0.4,0.3,0.4,0.3L393.4,235.4z M467.3,233.7c1.6-2.1,3.5-4.2,3.5-3.9L467.3,233.7z M471,226.5c-0.3,0.3-0.6,1-1.9,1.8
//       L471,226.5z"
//       />
//       <path d="M531.9,225.2c-1.6,1.8-3.9,2.5-5.8,4.3c-1,0.4-1.3,0.7-1.6,1.4l-0.6,1.4c0.4,0.3,0.7,0.3,0.7,0.3l6.2-4
//         c1.6-0.7,3-1.1,3.6-1.8c2.6-2.5,14.2-10.1,16.5-10.5c0,0,0.4,0.7,0.4,1c-1,0.7-1.2,2.1-2.2,2.8c-1.2,2.1-12.2,9.6-13.1,11.7
//         c-0.2,2-1.8,3.8-3.4,4.9l-8.6,8.1c-0.9,1.4-1.9,2.8-4.2,3.2l-0.7,0c-0.3,0-0.3,0.4-0.6,0.7c0,0.3-4.5,3.2-4.5,3.6
//         c-1.3,1.1-1.9,1.4-2.2,2.5c0,0.3,0,0.7,0.4,1.3c0.7,1.6,1.5,3.6,1.6,5.9c-0.2,3.4-0.3,7-0.1,10.7l-0.3,0.7
//         c-1.2,2.4-1.5,4.4-1.7,6.1c-0.9,2.1-2.1,4.5-4,6.9c-2.2,2.1-3.8,4.5-5.7,6.6c-3.5,2.8-7.4,5.7-10.6,9.2c-2.6,0.8-3.9,2.2-5.9,2.6
//         l-11.1,4.9c-5.3,1.9-9.2,3.1-13.5,4l-2,0.1c-1.7,0.1-4-0.1-6.4-0.4c-0.3,0-0.7-1-1.7-1.3c-0.4-2-1.8-2.9-3.6-4.2
//         c-0.7,0-1-0.6-1.1-1c-0.4-0.7-1.1-1-1.4-1.6l1-0.7c0-0.3,0-1,0.3-1.7c0-0.3,0-1-0.1-1.3l0-0.3c-0.1-1.3-0.4-2.3-0.2-3.7
//         c0-0.7-0.1-1.7-0.1-2.3l0-0.7c0-1,1.1-5.4,1.4-6.1c0.6-1.4,2.2-3.1,3.5-4.2c1.2-2.1,2.4-5.1,3.7-6.9c2.9-2.5,5.8-4.3,7.7-6.7
//         c0,0,0.3,0,0.3-0.7c3-0.8,4.8-3.6,7.1-5.7c3.2-2.8,7.5-3.4,11.1-6.2l1.9-1.4c4.2-3.6,6.5-4,6.8-4.7c0-0.7-1.1-1.3-3.5-3.5
//         c-3.5-2.8-6.3-5.4-10.4-7.5c-2.8-2.2-6.2-4-9.7-6.5c-3.8-3.2-7.7-7.6-10-12.2c-0.8-2.6-0.9-5-0.7-7.3c0-1,0.2-2.4,0.5-3.4
//         c0.4-4.7,2.2-10.1,6-14l2.5-4.1c0.3-0.3,0.6-1,1.3-1.4c4.2-3.9,7.6-8.4,11.8-11.6c3.9-2.9,7.1-6,10.3-8.9
//         c7.4-5.7,15.5-10.5,23.3-16.5l1.7-0.1c2.9-2.8,6.9-3.3,9.9-3.5l2.7-0.1c2.3-0.1,3.7-0.2,5.3-0.6c1-0.7,2.3-1.1,3.3-1.2
//         c2-0.1,3.4,0.5,4.7,1.1c0.3,0,0.3,0,1.1,1.3c0.4,0.3,0.1,1.7,0.5,2.7c1,0,2.4,0.2,2.7,1.2c1.4,0.6,1.4,1.6,2.1,2.2
//         c0.5,2.7,0.9,4.6,2.3,5.9c0.3,0,0.4,0.7,0.4,0.7c-0.9,1.7-2.9,2.5-3.8,3.9c-3.7,0.2-6.5,3-10.5,3.2c-0.7,0-1.3,0.4-2,0.4
//         c-3.9,3.2-9.5,4.5-13,7c-0.3,0.3-0.7,0.4-1,0.4l-0.3,0c-4.1,4.6-11.1,5.6-14.9,10.4c-0.3,1-2.3,1.8-3.2,2.8
//         c-3.6,1.2-5.8,3.6-8.7,5.8c-5.5,3.6-9.6,8.8-13.7,14.1c-1,0.4-1.3,0.7-1.3,1.7c0,0.3-0.6,0.7-0.6,1c0,0.3,0.4,1,0.7,1.3
//         c1.7,1.3,3.2,3.5,4.9,4.4c4.5,2.8,9.3,5.2,12.5,8.4l12.6,10.8c0.4,0.3,0.4,0.7,0.7,0.6c0.3,0,0.7-0.4,1-0.4
//         c1.7-0.4,2.9-1.8,4.2-2.6c3.2-2.5,6.8-5.4,10-6.9c2.9-2.2,4.5-4.2,7.4-6.4c1.3-0.4,13-7,15-7.8c0-0.3,0.3-0.4,0.3-0.4
//         c0.3,0,1,0.3,1.4,0.6C543.6,218.3,532.5,224.8,531.9,225.2z M493.6,269.2l-0.4-0.3c-0.3,0-2,0.1-2.4-0.2l-0.6,0.7
//         c-5.5,4.6-11.9,9.6-18.7,13.6l-7,8c-2,0.8-2.3,1.8-2.2,2.5l0,0.7c-0.3,1.4,1.1,2,2.1,1.9c0.3-0.4,0.3-0.7,0.6-0.7
//         c0,0,0.3,0.3,0.7,0.3c6.5-4,9.4-5.1,15.9-10.5c1.3-1.1,2.6-1.5,2.9-2.5c3.8-3.9,6.4-6.7,8.5-10.8
//       C493,271.3,493.6,270.2,493.6,269.2z M545.8,214.8l0.3,0l0,1l-1.3,1.1L545.8,214.8z"
//       />
//       <path d="M663.8,284.3c-1.9,2.1-3.8,4.5-6.7,6l-2.9,2.2c-1.6,0.7-2.9,1.5-2.5,2.8l-3.3,1.2c-1.6,1.4-1.9,1.8-4.6,2.6
//         c-0.3,0-1,0.7-2.3,1.1c-1,0.4-2.3,0.4-4,1.2l-3.3,0.2c-0.7,0-1,0-1.7-0.3l-3,0.1c-2-0.6-4.1-1.5-5.8-2.4c-2.8-1.9-5.5-2.4-6.6-5.4
//         c-4.1-2.5-5.4-7.1-6.6-11.7l-0.1-2.7c0-1,0.2-2-0.1-2.7c-0.5-2.7-0.6-5.3-0.4-8c-0.1-2,0.5-3.7,0.7-5.4l2.2-16.5
//         c0.6-1.4,2.8-11.2,3.4-12.5l1.1-4.7l-0.1-2c0.3-1.4,1.2-3.4,2.4-5.5l0.5-2.7l-1.4-0.6c-0.3,0-1,0-1.3,0.7l-7.7,7.4
//         c-0.6,0.7-0.3,1.7-0.9,2.4c-2.3,1.8-3.5,3.5-5.1,4.6c-2.2,2.5-4.1,5.2-5.6,7.6c-3.2,2.8-5.4,5.3-7.3,8.7
//         c-4.7,6.3-8.8,12.5-14.2,17.1c-4.7,5.9-9.1,12.2-14.2,17.1c-0.9,1.4-1.8,3.4-3.1,5.2c-2,1.1-2.6,2.1-3.9,2.2l0-1l-4.5,3.9
//         c-2.2,2.1-5.2,3.3-7.5,3.4c-5.7,0.3-9.9-4.9-10.1-8.9c-0.8-3.6-0.5-17.7-0.1-29.1c-0.4-0.3-0.4-1-0.4-2.3c0.3-1.4,0.2-3,0.7-5.7
//         l0.7-5.4c0-0.3,0-0.7,0.3-1.4l-0.7-0.6l2-6.1l-0.4-0.3l1.6-14.1c0-1,0.6-1.7,0.9-2.7l0.4-5c0.6-2,3.8-3.5,4.1-5.6l0-1
//         c0.7-0.4,0.6-0.7,0.9-1.4c0-1-0.4-2-0.5-3l1.4-5.1l-0.2-3.7c0-0.7,0.3-1,0.6-1.7c0.3,0,0.3-0.3,0.3-0.3c0.2-3.7,2.5-3.1,3.4-6.2
//         c0.9-2.7,1.1-5.7,2.6-8.5c1.8-4.8,2.5-10.2,4.6-14.6c0.3-1.7,0.2-3,1.1-4.7c1.2-2.1,2.1-4.1,2.7-6.5l1.8-4.8c0-0.3,0-0.3,0.3-0.4
//         l0.7,0c0.3-0.3,0.7-0.4,1-0.4c0.3,0-0.4-1-0.4-1c-0.3,0-0.7,0-0.7-0.3l0.6-1c0.3-0.3,0.3-0.3,0.3-0.7l0-0.3c0.2-2,1.5-4.4,3-6.5
//         c0.9-2.4,1.7-6.1,4.2-8.9c0.3-0.3,0.3-0.7,0.3-1c-0.3-0.3-0.3-0.3-0.4-0.7c0.3-1,1.9-2.4,2.2-3.8c0.2-2,1.5-4.1,2.4-5.8
//         c0-0.3,1.3-1.1,1.3-1.4c0.6-2,2.5-3.8,3-6.2c0.8-4.4,3.6-7.9,6.1-11.3l0.9-1.7c0-0.3-0.3-0.3-0.4-0.7c0-0.3,0.3-0.7,0.6-1l3.4-6.2
//         c2.5-3.8,4.6-8.9,7.4-12.7c1.6-2.4,3.7-5.5,5.6-9c1-1.1,1.3-1.7,1.9-3.1c0.3,0,0.3-1,0.6-2l0-0.7c0.3-1,0.9-2.4,1.8-3.4
//         c1.6-0.7,2.3-1.4,2.2-2.8l0.7-0.4c2-0.8,2.2-2.5,3.1-4.2l0-0.3c0.3,0,0.7,0.3,1,0.3c0.7,0,0.7-0.4,0.6-0.7l-0.1-1.7l2.2-3.1
//         c0.3-0.4,1-0.4,1-0.4l0,0.7c0,0.3,0.4,0.7,0.4,0.7c0.3,0,1-0.7,1.3-1.1l2.3-1.4c0.3-0.4,0.7-0.4,0.6-0.7c0-0.3-0.4-0.3-1-0.6
//         c-0.4-0.3-0.4-1.3-0.4-1.3l2.5-2.8c0.7-0.4,1.3-1.1,1.2-2.1l0-0.7l0.6-0.7l1.3-1.7c0.6-0.7,0.6-1.4,2.3-1.5
//         c1.3-0.4,1.6-1.4,2.3-1.8l1,0c-0.3,0.3,0,0.3-0.3,0.3l-5.1,4.6c-0.3,0.4-0.3,0.7-0.3,0.7c-0.3,0.3-0.3,1-0.3,1l1,0
//         c3-1.1,4.9-2.9,6.4-5c0.3-0.3,0.6-0.7,0.6-1c0-0.3-0.4-0.7-0.4-1c0-0.3-0.4-0.7-0.4-1c0.3-1.4,1.5-2.8,3.9-2.9
//         c1.3-0.1,3.3-0.2,4-0.5c0.3-0.4,0.7-0.4,1-0.4c0.3,0,0.3,0,0.7,0.3c0.4,1,1.4,1.3,2.4,1.2c0.3,0,1-0.4,1.3-0.4
//         c2.3-0.1,4.7-0.2,5.5,2.1c0.3,0.3,1.4,0.6,1.4,0.6c0.3-0.4,1-0.4,1.3-0.4c1,0,1.7,1.3,2.4,1.9c2.1,1.6,3.9,3.8,4.4,6.8l0,0.3
//         c0,0,0,0.3,0.3,0c3.2,2.9,3.3,6.2,3.1,9.5L665,49c-0.3,1.4,0.1,2.3-0.2,3.7c0.1,1.7-0.5,3.4-0.7,5.4c-0.2,3.7-0.7,6.7-1.5,10.4
//         c-0.5,3.7-2,6.8-3.5,10.2c-1.8,5.1-3.5,10.5-5.6,15l-3.9,8.2c-1.5,2.8-2.1,5.5-3.6,8.5c-0.6,1-1.3,1.4-1.6,2.1
//         c-1.6,1.4-1.9,2.8-2.4,4.8c-0.3,1.4-1.5,3.1-2.4,4.8c-1.5,3.1-3.3,7.9-6.1,11l-3.1,5.2c-0.3,0.7-0.9,2.1-1.9,2.8
//         c-1.5,2.8-2.4,5.1-4.6,7.6l0,1l-7.8,12.1c0.1,1.3-1.2,2.1-1.9,3.1l-1.5,3.1c-1,0.7-2.3,1.8-3.8,3.9c-0.3,1.4-0.9,2.7-2.2,3.8
//         c0.4,0.3,0,0.3,0,0.3c-2.5,4.5-5.7,7.3-7.8,11.8c-1.2,3.1-1.9,2.1-3.5,4.5l-2.5,3.8c-0.9,2.1-3.1,4.2-5,6.9l-7.5,10.7
//         c-5.4,6.6-9.7,13.9-15.4,19.5l-3.1,5.8c-1.6,2.4-2.1,4.1-2.1,5.5c-0.3,1.7,0.5,3,0.5,4c-0.3,1.4-1.2,2.4-1.2,3.1
//         c0,0.3,0.4,0.3,0.7,0.3c2.7-0.1,6.3-2,9.5-4.1l0-0.7l0.6-0.7l0.6-1l1.3-1.4l0.3-1l0-0.3l0.6-0.7l0.3,0c-0.3,0.3,0.3,0,0.3,0
//         c0.7-0.4,1-1.1,1.3-1.1c2.8-4.5,6.9-8.7,10.8-12.6c0.6-1.4,1.6-2.1,3.5-3.5l3.8-4.2l2.8-4.2c4.1-4.2,7.6-9.1,12.4-12.7l12.6-9.3
//         c3.2-2.8,6.1-4.3,8.5-4.4c8-0.4,12.3,11.8,15.1,21.7c-2,6.8-1,14.4-3.3,20.9c-0.6,2-3.4,12.9-3.3,14.5l-0.6,2
//         c-0.2,2.4-0.1,5.4-1.6,7.8l-2.3,14.8l0.1,1.3c-0.3,1.4-0.2,2.4,0.2,3.7l0.1,3l-0.3,1.7c0.4,1.3,0.8,2.3,1.8,2.3
//         c0.7,0,1.3-0.1,2.3-0.4l0.7-0.4c0.7-0.4,1.7-0.4,2.3-0.4l2-0.1c0.7,0,1.3-0.1,2.3-0.4c1.3-0.4,2.6-1.1,4.3-1.5
//         c3.5-2.9,7.1-5.7,9.6-8.5c3.9-2.9,8.4-6.4,12.6-10c1.3-0.7,2.3-1.4,3.5-2.9l-0.9,2.4c0,0.3-0.3,0.7-0.6,1c0,0.7-5.2,3.9-4.8,4.3
//         l0.3,0c1.3-0.1,6.6-1.7,7.3-1.7c0-0.3,0.3,0,0.3,0l-0.3,0.7l-1.6,1.4c-0.3,0.4-8.2,3.7-8.2,4.1c0,0.3,0,0.3,0.4,0.3
//         c1,0,4.7-0.6,5.3-0.6l0,0.3c-0.3,1-8.1,4.4-8.1,5.8l0,0.7c0,0.3,0.4,0.3,0.7,0.3c1.3-0.1,5.6-2.3,6.6-2.3l0.3,0
//         c0,0.3-6.4,5-6.7,5.7c-0.3,0-0.3,0.4-0.3,0.4l-0.3,0.7c0,0.3-1.3,1.4-1.3,1.7c0.4,0.3,0.4,0.3,0.7,0.3L663.8,284.3z M566.3,138.2
//         l0.2,3.7c0,0.7,0.1,1.3-0.6,2c-0.6,2.4-2.1,4.5-2.7,6.8c-1.1,4.4-2.9,8.2-4.8,11.6c-0.9,3.1-1.1,5.7-1.6,8.4
//         c-0.3,0.7-0.6,1-0.6,1.4c0,0.3,0,1,0.7,1c0,0.3,0.3,0,0.7-0.4c0.7-0.4,0.6-0.7,0.6-1l-0.1-1.3c0.2-2,0.8-3.4,1.8-4.8
//         c0.9-1.4,1.9-3.1,1.8-4.1l1.8-5.1l2.9-9.5c2.5-4.1,4.2-8.9,6.1-12c0.6-1.4,0.9-3.1,1.5-4.1c1.6-2.1,2.5-4.1,3.4-5.5
//         c0.2-2.4,1.1-5.7,3-7.5c1.2-2.7,1.7-5.8,4.6-8.6c0.2-3,3.1-5.2,3.9-8.2c0-0.3,0.3-0.7,0.3-1c0-0.7,0.3-1,0.6-1.4
//         c1.6-2.1,2.8-4.8,3.3-6.9l2.2-2.5c0-0.3,1-1,1-1l0-0.7c0-0.3,0.3-0.7,0.6-0.7c0.3,0,0.3-0.4,0.3-0.7c0-0.3-0.4-0.7-0.7-1.3l-0.7,0
//         c-0.7,0-1,0.4-1.3,0.7l0,1c-0.6,2.4-1.1,4.4-3,6.2c-1.3,1.1-1.9,3.1-2.5,4.5l-3.2,3.2l-1.2,2.4l-3.3,8.5l-3.9,8.2l-2.4,4.8
//         c-1.6,2.1-2.5,4.1-3.3,6.9c0,0.3,0,0.7-0.3,1c-0.7,0.4-1,0.7-1,1.1l0.1,1.3l-0.6,1.7c0,0.3-0.3,0.7-0.6,1l-0.4-0.7
//         c0-0.3-0.4-0.7-0.4-1c-0.3,0.7-0.6,0.7-0.6,1L566.3,138.2z M644.6,43c-1.7,0.4-4,1.2-5.2,3.3l-1,0.7l-6.3,7
//         c-2.3,1.8-3.5,4.5-5.4,6.3c-0.3,0-1,0.7-0.9,1.4l-9.7,14.2c-2.5,3.1-5.4,6.3-6.5,10c-1.8,3.8-3.3,7.2-5.5,11l-4.1,5.9
//         c-2.1,4.1-4.6,8.6-7.1,12.4c-1.8,4.8-5.2,10.6-7.9,16.1c-0.8,4.1-3.6,7.5-5.1,12c-1.1,4.1-3.7,6.9-4.1,11.2c-1.9,2.1-1.8,4.8-4,7.9
//         l-0.3,7c0,1,0.1,1.7-0.2,2.4l-2.9,9.5c-1.5,3.1-2,6.5-2.5,9.8l-1.5,3.1l0.4,1.3l2.3-1.5c1-0.7,2.2-2.1,3.2-3.8
//         c0.6-1.7,2.2-2.8,2.8-3.8c1.8-3.4,3.7-5.5,6-7.3l0-0.7c0.3-1.4,1.2-2.7,1.8-3.4c1.3-1.1,1.9-3.1,4.1-4.6c0.3-1,0.9-2.1,2.2-3.1
//         c0-0.7,0.6-2.4,1.5-2.8l0.9-2.1c1.9-2.8,4.1-5.9,6-7.7l7.4-12.1c1.5-3.4,4-6.6,5.6-8.6c0.3-1.7,0.5-4,2.8-5.2
//         c0.2-3,2.5-4.1,2.7-5.8c2.8-4.8,5.6-8.6,7.1-12.7c2.1-4.8,4.2-9.6,5.3-14l2.3-1.4c1.9-2.4,3.5-4.2,3.7-7.2c0.3-1.4,0.5-3.7,2.5-4.5
//         c0-0.3,0.6-0.7,0.6-1l0.2-3c-0.1-1.3,1.9-1.8,1.9-2.8l0.9-2.7l5.4-19.3c0.3-0.7,0.6-2,0.5-3.4c0.3-1,0.2-2-0.1-3
//       c0.3-1.4,0.2-2.4,1.5-3.1c0.3,0,0.6-0.7,1-1.1C646.3,43.3,645.6,43,644.6,43z"
//       />
//       <path d="M748.7,221.9c-1.3,0.1-4.2,2.2-6.2,2.3c-0.4-0.3-2.7,0.1-3-0.5c-0.7-0.3-2.4-0.2-2.4-0.2
//         c-1,0.4-2.3,1.1-2.9,1.8L734,228l-0.6,7.7c-0.2,2.7-1.7,6.1-2.5,10.2c-1,1.1-1.2,2.4-3.1,4.8l0.1,2c-0.3,1-0.6,1.4-1.2,2.1
//         l-0.6,1.4c-0.9,3.1-1.8,4.1-3.1,5.5c0,0.3-0.3,0.7-0.3,1l0,0.3c-0.3,0.7-0.3,1.4-0.9,1.7c-1.5,3.1-3.4,6.2-5.2,9l-2.8,3.5
//         c-0.3,1-1.3,1.7-2.5,2.8l-0.3,0.7c-2.3,1.1-4.5,3.2-6.4,6c-2.9,2.2-6.1,4.3-9.4,5.5c-3,0.8-3.9,2.2-5.6,2.6
//         c-0.7,0.4-1.6,0.7-2.3,0.8c-0.7,0-1-0.3-1.4-0.3l-4-0.5c-1.7,0.1-4.3,0.2-6-0.4l-5.4-1.4c-0.7-0.3-1.4-0.3-2.1-0.9
//         c-0.7-1.3-2.4-1.6-2.5-2.2c-1.7-1.3-3.2-3.9-4.3-5.5c-2.1-1.6-3.9-3.8-4.7-6.5c-0.4-0.7-0.1-2-0.5-3.3c-1.1-3-1.3-7-2.2-10.6
//         l-0.1-1.7c0.6-0.7,0.6-1.7,0.8-3.7c-0.4-0.7-0.4-1.3-0.1-2.3l0.2-2.7c-0.1-1.3,0.5-3.4,0.5-4l-0.1-2l0.5-2.7
//         c0.6-1.7,0.5-3.7,1.4-5.4l0-0.7l1.9-8.1l2.1-5.1c0.3-1.4,0.9-1.7,0.9-2.4c1.2-3.1,2.1-5.5,3.6-8.2c0-0.3,0-0.7,0.9-1.7l10.4-20.6
//         c2.2-2.1,4.1-5.9,6.3-8c1-0.4,0.9-1.4,1.6-2.1l4.4-6.2c1.9-1.8,2.2-2.8,4.1-4.9c0.3-0.7,0.3-1,0.3-1.7c0.3-0.7,1.3-1.7,2.3-1.8
//         c0.3,0,0.7,0,1-0.7l1.3-1.7c0.6-0.7,1.3-0.7,2-0.8l5.3-0.3c0,0,0.3-0.4,0.7-0.4c0.3,0,1,0.3,1.7,0.3c0.4,0.3,0.7,0.3,1,0.3
//         c0.3-0.3,1-0.4,1.3-0.4c0.7,0,1.3-0.1,2,0.2l1,0c0.7,0,1.4,0.6,1.7,1.3l0.7,1.6l-0.3,0.7c0,0.7,0.4,1.3,0.4,2l0,0.3
//         c-0.3,1-0.9,2.7-0.8,3.7c-1.6,1.4-2.5,3.5-3.1,5.8c-0.9,1.4-1.9,2.1-1.8,3.4l0,0.3l0.3,0c0.7,0,1-0.4,1.3-0.4c0,0,0.3-0.4,0.3-0.7
//         c0.3-0.3,0.7-0.4,1-0.4c0.3,0,0.3,0,1,0.3c1.4,0.9,2.1,0.9,3.4,0.8l1.7-0.1c0.4,0.7,1.4,0.6,2,0.6c0.7,0,1.3-0.1,2.6-0.8
//         c0.3,0,0.3,0,0.7,0.3l1.7,1.3c3.1,0.9,5.2,3.1,6,6.4c0.7,1,0.4,2.3,1.8,2.6c0,0.3,0.1,1.7,0.8,2.6c-0.3,0-0.3,0.3-0.3,0.3
//         c0.8,2,0.2,4.3,1.3,6l0.1,1.3c0,0.3,0,0.7,0.4,1l-0.3,1.4c0.4,1,0.5,2.7,2.5,2.6c1,0,2-0.1,2.4,0.2c1,0.3,1.5,2.3,3.1,2.2
//         c2.3-0.1,5.4,0.4,6.7,1l2-0.1c1.3-0.4,2.3-0.4,3-0.5c1.3-0.1,3-0.1,4-0.5l2,0.2l3.3-0.2c0.7,0,1.9-2.4,2.2-2.5l1.7,0.9
//         c0.3,0,0.7,0.3,0.4,1c0,1,0.4,1.3,0.4,2l0,0.7l-1.5,3.1c-0.3,0.7-1.6,1.4-1.9,1.8c-0.3,0.7-1.3,0.7-2,0.8c0,0.7-1.3,1.4-1.3,1.4
//         c-0.7,0-1.7-0.3-2-0.2c-0.3,0-1.7,0.4-2.3,0.8s-1.6,0.7-3,0.8L748.7,221.9z M674.1,263.4c-0.3,1-0.6,2.4-0.5,3.4
//         c0.1,1.3-0.2,2.4,0.8,3l0.1,1.7c0.1,2.3,1.2,4.6,2,5.9c0.4,0.7,0.7,1,1.1,1c0.7,0,1.3-0.1,2-0.4c1.3-1.1,2.6-0.8,3.6-1.5
//         c1.6-1.4,2.9-3.2,5.2-3.9c1.9-1.8,4.1-4.6,6.3-7.7l4.8-4.9l1.9-2.8l5.9-9.7c1.6-1.4,1.8-4.8,4-7.6c0.6-2,2.1-5.1,2.3-8.1
//         c0.6-1,0.8-3.7,1.4-5.1l0.3-1.7c0-0.7-0.1-2.3,0.8-3.4l0.1-5c0-0.3,0-1-0.4-2c-0.4-1-1.1-3-2.9-3.9c-1.7-0.9-2.5-3.2-3.9-4.8
//         c-1.4-0.6-2.1-2.6-2.6-4.6c0.3-1.7-0.4-2-1.1-2.3l-1.1-1l0.6-1c0-0.3-0.4-1-1.1-1c-1,0-1.3,0.1-1.3,0.7l-1.6,1.1l-1.5,2.8
//         l-7.2,11.1l0,0.3l-1.5,3.1l-2.7,5.8l0,1c-1.2,3.1-2.7,5.5-4.3,7.9c-0.3,0.3,0,1,0.1,1.3c0,0.3-0.3,1-0.3,1l-0.9,1.7
//         c-0.3,1.4-1.2,2.1-1.5,3.4l0,0.7c-0.6,0.7-0.6,1-0.6,1.7l-0.3,1.7c-1.6,1.8-2.2,2.8-2.7,6.2l0,0.7c-0.6,1-0.9,2.7-1.8,4.4
//       c-0.3,0.4,0,0.7,0,1c0,0.7-0.3,1.4-0.9,2.1L674.1,263.4z"
//       />
//       <path d="M857.6,180.4c0.4,0.3,1.1,1,1.1,1.6c0,0.3-0.3,0.7-0.6,1c-0.7,0.4-0.6,0.7-0.6,1l0,0.7c0,0.3,0,0.7-0.3,0.7
//         c-1,1-1.6,2.1-1.5,2.8c0,0.3,0,0.7,0.4,1.3l-0.6,0.7c0,0.7,0.1,1.3-0.6,2c-0.3,0.7-0.6,1.4-0.6,2c0.4,0.3,0.4,0.3,0.4,0.7
//         c0,0.7-0.6,1-1.3,1.4l-0.9,1.4c-0.7,0.4-0.7,0.4-0.6,1c0.4,0.3,0.4,0.7,0.4,0.7c-1,1.1-1.2,2.7-1.5,4.4l0,0.3c0,0.3-0.3,0.7-1,1.1
//         l-0.6,2.4c0.4,0.7,0.7,1,0.4,1.7c0,0.3-0.3,0.7-0.3,1c-0.3,1.4-1.2,2.4-1.5,3.4l-0.9,1.4c-2.2,2.8-3.7,5.5-5.3,8.6l-0.9,1.7
//         l-0.9,1.7c-0.7,0.4-0.9,1.4-1.2,2.1l0,0.3l-0.6,1.4c-0.3,1-0.6,2-1.2,2.4l0,0.7c0,0.3,0,0.7,0.4,1l-0.3,1.7l-6.2,9.7
//         c-2.8,4.5-6.2,9-9,13.8l-0.3,0c0,0,0,0.3-0.3,0.7l-7.6,8.7l-2.2,3.5l-4.7,5.9c-0.3,0.7-0.9,1.4-1.9,2.1c-1.3,0.7-2,1.1-2.3,1.8
//         c-1,0.7-1.9,1.8-2.5,2.8l-1.6,1.1c-0.6,1-1.9,3.1-2.8,4.2l-7.8,3.7l-6.5,3.7c-1.3,0.4-2.3,1.1-3.6,1.2l-0.3,0c-2,0.8-3.6,1.2-5,1.2
//         c-1,0-1.7-0.3-2.4-0.2l-1.7,0.1c-1,0-3,0.1-5.7-0.1c-1.4-0.6-2.7-0.9-3.4-1.5c-1-0.3-2-0.2-2.7-0.2c-1,0-2.4-0.6-2.7-1.2l-2.4-1.2
//         c-2.7-1.2-3.8-2.8-5.6-4.4c-0.3,0-0.3,0-0.4-0.3l0-0.3c-0.4-2.3-0.2-4.3-0.3-6.7l1.4-25.8l0.9-8.4l0-0.7c0-0.3,0.3-0.7,0.6-1
//         c0.6-0.7,0.6-1.4,0.6-2l0.3-1.7l0.8-3.4c0.3-0.7,0.9-2.4,0.8-3.4l0.7-5.4c0-0.7-0.1-1.7,0.2-2.4c0.3-1.4,0.2-2.7,1.2-3.7l2-6.8
//         l0.9-3.1c-0.1-1.7,0.8-3.7,1.4-5.4c1.2-2.4,2.1-5.5,3.3-7.9l0.2-3.7l1.2-3.1c0.9-2.1,1.8-4.1,2.1-5.8c0-0.7,0.6-1.7,0.9-2.1
//         c0.9-1.4,1.5-3.1,1.8-4.8c0-1,0.6-2,1.2-3.4c0-1,0.2-2.4,0.5-3.7c1.2-3.7,3-7.2,4.5-11.3l2.3-6.8c0.3-1,0.3-1.7,0.9-2.4
//         c0.2-2,1.8-3.8,2.4-5.5c1.2-2.4,1.1-5.1,2.6-7.5c0.3-1,0.9-1.7,0.9-3.1c0.6-1.4,1.2-2.4,1.1-4.1c0.9-2.7,2.4-5.1,3.6-7.9
//         c0.9-1.7,1.2-3.4,1.4-5.4c1.1-4.4,1.4-5.1,3.3-8.5l-1.3,0.1c-0.7,0.4-1,0.4-1.7,0.4c-0.7,0-1,0-1.7-0.3c-0.3,0-0.7,0-0.7,0.4
//         c-1,0.7-2.3,1.1-3.3,1.2c-0.7,0-1.4-0.3-1.7-0.3c-2.7,0.1-4.9,1.6-7.6,2c-1.7,0.4-3.3,1.2-5.6,1.6L766,120c-1.4-0.3-2-0.6-3-0.5
//         c-0.7,0-1,0.4-1.7,0.4c-1.3,0.7-2.3,1.1-3.6,1.2l-1,0c-1,0-2,0.4-2.3,0.8c-0.3,0.7-0.3,1-1.3,1.1l-7.3,1.7l-7.6,1
//         c-1.3,0.7-2,1.1-3,1.1c-1,0.4-2,0.4-3,0.5c-2.7,0.1-5.7-0.7-7.9-3.6l-0.1-1.7c0-0.3-0.4-1-1.8-1.6l-0.1-1.3c-0.4-1-0.4-2.3-0.2-3.7
//         c0,0,0-0.3,0.6-0.7c0.7-0.4,1-0.7,1.3-1.4c0-0.3-0.4-0.7-0.4-1c0-0.3,0.3-1,0.6-1c1.6-0.7,2.2-2.8,2.8-4.5c0-0.7,0-1,0.6-1l0.3-0.7
//         c0.7-0.4,1-0.7,1.6-0.7c0.7,0,1.4,0.3,2.4,1.2c0.7,0,1-1.1,1.6-1.8c0.3-0.7,0.3-1,0.6-1l0.3,0c1-0.7,1.6-1.4,3.3-1.8
//         c0.3,0,0.7-0.4,1-0.4c0.3,0,0.7,0.3,1,0.6c2-0.4,3.6-0.8,5.9-2.3l2-0.1c0.7,0,1.7-0.1,2.7,0.2l1,0c1.3-0.7,2.6-1.5,3.9-1.5l2-0.1
//         c1,0,2-0.1,2.6-0.8c0.3-0.3,1.3-0.4,2.3-0.4c0.3,0,0.7,0.3,1.4,0.3s1.7-0.4,2.3-0.8c1-0.7,1.6-0.7,2.6-1.1l1.7-0.1
//         c2.3-0.1,4.1,0.8,7.4,0.6l2-0.1c0.7,0,1.7-0.4,2-0.8l2.9-1.5c1.3-1.1,2.3-0.8,4.3-1.2c0.7,0,1,0.3,1.7,0.3c0,0,1,0,1,0.3
//         c0.4,1,0.7,0.6,1.4,0.9c1-0.4,2.7-0.5,3.7-0.5c0.7,0,0.6-0.7,1.3-1.4l0.3-0.7c0,0,0-0.3,0.3-0.3l1,0l0.3-0.3l-0.1-1.3l0.3-0.4
//         l0-0.3l0.7-0.4l0.3-0.7l0-0.7l0.3-0.3l0-0.3l0.3-0.3l0.3-1l0.3-0.3l0-0.7l0.3,0l0-1l0.3-0.3l0.3-1l1-0.7c0.6-0.7,0.9-1.7,2.2-3.1
//         c1.2-2.7,2.7-5.8,3.6-8.9c0-0.7,0.6-1.7,0.6-2l6.8-12.4l0-1c0.3-1.7,1.2-3.1,2.1-4.5c0.3-0.7,0.9-1.7,1.9-2.4
//         c1-1.1,1.9-1.4,2.9-2.2l2.3-1.1l2-0.1l5.8,2.4l1.3-0.1c1.3-0.1,1.7,0.3,2.7,1.2c0.7,0,1.4,0.6,2.1,1.2l0.4,1l2.8,3.2
//         c0,0.3,0.1,1.3,0.4,1.7l0.1,1.7c0,0.7-0.3,1.7-0.9,2.4c-2.1,5.1-4.2,9.6-6.7,14c-1.8,4.8-2.8,11.8-6.6,16l-0.3,0.3l-0.3,0.7l0,0.3
//         c0.7-0.4,1.3-0.4,2-0.4l1.7-0.1c0,0,0.3,0,0.4,0.3c0,0-0.7,0.4-1.3,0.4c-0.7,0-1.3,0.1-2,0.4c0,0-0.6,0.7-0.6,1l0,0.3
//         c0,0,0,0.3,0.4,0.3l1.3-0.1c1.3-0.4,2.3-0.4,3.7-0.5l3.7-0.2c0.7,0,1.3-0.1,2,0.6l0.3,0c0.3-1,1-0.4,2.3-1.1l2-0.1
//         c1.7-0.1,3-0.5,5-1.2c0.3,0,1-0.4,1-0.4c0.3,0,0.4,1,0.7,1l0.7-0.4l0.7,0c0.7,0,1,0.3,1.4,0.3c1.3-0.1,2-0.1,3.7-0.5
//         c0.3,0,0.7-0.4,0.7-0.4c0.7,0,0.7,1,1.4,0.9c0.7-0.4,2-0.8,2.6-0.8l1,0c0.7,0,1.3-0.1,2-0.4c1-0.4,2.3-0.4,3.7-0.5
//         c1,0,1.7-0.1,2.7,0.2l0.3,0c2.6-1.1,5.3-0.9,8-1.4c0.7,0,2-0.1,2.7,0.2l1,0c1.3-0.1,2.3-0.4,3.7-0.5l9-0.4c0.3,0,1.3-0.4,2-0.4
//         s1.4,0.3,2,0.2l2.7-0.1c0.3,0,0.7,0.6,1.7,0.6l0.7,0l1.6-1.1c0.3,0,0.7,0,1.4,0.3l0,0.3c0,0.3,0,0.7,0.7,0.6l0.7-0.4
//         c0-0.7-0.7-0.6-1.4-0.6c0.7-0.4,1-0.4,1.7-0.4c0.7,0,1,0,1.7,0.3l1,0l0.1,1.3l1.6-1.4l1,0c1.3-0.1,2.7-0.5,4-0.5
//         c0.7,1.3,2.4,0.9,3.4,1.2l-0.3,1c1.6-0.7,2.3-1.1,3-1.1c0.7,0,1.4,0.3,2,0.2c0.3,0,0.3-0.3,0.3-0.3c0.3,0,0.4,0.3,0,1l0.1,2
//         c0,1,0.8,1.6,1.1,1.6s0.3,0,0.7-0.4c1-0.4,1.6-0.7,1.6-0.7c0.7,0,1.3-0.1,2,0.2c0.3,0,0.7,0.3,1,0.3s0.3-0.7,0.6-1.7
//         c0.3-0.4,0.3-0.7,0.6-0.7c0,0,0.3,0,0.4,0.3c0,0.7-0.3,1.7,0.4,1.7c0.3,0,0.3-0.3,0.6-0.7l0-1c-0.3,0-0.4-0.3-0.4-0.3l0.7,0
//         c1,0.3,1.7,0.3,2.4,0.9c0.3-0.3,0.3-0.7,0.3-1c0-0.3,0.3-0.3,0.3-0.7c0-0.3-0.4-0.3-1-0.6c-0.7,0-1,0-1.7-0.3l0-1l0.7,0
//         c0.7,0,1,0,1-0.4l1.7-0.1c0,0.3,0.4,0.7,1.4,0.6c0.3,0,0.7-0.4,1-0.4c1,0,1.7,0.6,2.7,0.5l-0.3,0.7l-1.3,0.1c0-0.3-0.4-0.3-0.7-0.3
//         c-0.7,0-1.3,0.4-1.7,0.4l-1,1.1c0,0.3,1,0.6,1.4,0.6c0,0.3,0.4,0.3,0.4,0.3l0.3,0c0.3,0,0.7,0,1,0.6c0.1,1.3,0.4,1.7,1.8,1.6
//         c0.7,0,1.3-0.4,2-0.4c1,0,1.7,0.3,2.4,0.6l3-0.1c2-0.1,4.3-0.5,5.2-2.3l1-1.1c0.3,0,0.3,0.3,0.3,0.3l-0.2,2c0,0.3,0.7,0.3,0.7,0.3
//         c0.3-0.3,0.7-0.4,0.6-0.7l0.3-1.7c0-0.3,0.3-0.4,0.3-0.7c0-0.3-0.4-0.7-0.7-0.6l-0.3-0.3c0.3,0,0.3-0.3,0.7-0.4
//         c1,0,2.7,0.9,3.8,1.5l1.1,2.6c0,0.3,0.7,1,0.7,1c1,0,1.4,0.3,1.1,1l0,0.7c0,0.3,0.7,0.6,0.7,0.6c1-0.7,1.6-0.7,2.3-0.8
//         c0.7,0,1,0,1.4,0.3c0.3-0.4,0.3-0.4,0.7-0.4c0.3,0,1,0.3,1.4,0.3l2-0.1c0.3,0,0.3,0,0.3-1c0.3-0.3,0.6-0.7,1-0.7l0.3,0
//         c0.3,0.3,0.3,0.3,0,0.7l0,0.7c0,0.7-1,0.7-0.9,1.4c0,0.3,0.3,0.3,0.4,0.7l0.3,0c0.3,0,0.3-1,1-1.1l1.3-0.1c0.7,0,1,0.3,1,0.6
//         c0,0.3-0.6,0.7-0.6,1l1.7-0.1c0.7,0,1.3-0.1,2,0.2l0.3,0c0.7,0,1.7-0.4,2-1.1l0-0.7c0-1,0.3-1,1-1c0.7-0.4,0.7-0.4,1.3-0.4
//         c0,0,0.3,0.3,0.7,0.3l0.3,0c-0.6,0.7-0.6,0.7-1,0.7l-0.7,0c-0.3,0.3-0.7,0.4-0.6,1c-0.3,0.3-0.3,0.7,0,1c0,0.3,0,0.3,0.4,0.3l0.3,0
//         c0.6-0.7,1.3-1.1,1.6-1.1s0.7,0.3,1.1,1l0.3,0c3.4,0.2,6.4,1,9.4,0.9l1,0c0.3,0,0.7,0.3,0.3,0.3l-0.3,0.7c-0.3,0.4-0.7,0.4-1,0.4
//         c-2-0.6-4.7-0.1-6.4-1l-1.3,0.1c-0.3,0-1,0-1.3,0.4c-1,1.1-2.3,1.1-3.3,1.2l-2.3,0.1c-0.7,0-1,0-1.1-1c0,0-0.4-0.3-0.7-0.3
//         c-0.3,0.7-1,1-1.3,1.1c-0.7,0-1-0.3-1.7-0.6c-0.3,0-0.3-0.3-0.3-0.3l0-0.3c0,0-0.3,0.4-0.7,0.4c-0.7,0.4-1.3,0.7-2,0.8
//         c-0.7,0-1-0.3-1.4-0.6c-0.3,0-0.7-0.3-1-0.3c-0.3,0-0.3,0.4-0.3,0.4c-0.6,0.7-2.3,0.1-3.6,1.2c0,0.3-0.7,0.4-0.7,0.4
//         c-0.4-0.3-0.4-0.7-0.7-0.6s-0.7,0.4-1,0.7c-0.3,0-0.7,0.4-0.7,0.4c-1-0.3-2.4-0.2-3.4-0.2l-3,0.1c-1,0-1.7,0.1-2.4-0.2
//         c-0.3-0.3-0.7-0.3-1.4-0.3s-1.7,0.1-2.7,0.5c-1.3,0.1-2.4-0.2-3.4-0.2c-1.7,0.4-2.6,0.8-4,0.9c-1,0-2-0.2-2.7-0.2
//         c-1.3,0.4-2.3,0.8-3.6,0.8c-0.7,0-1.7,0.1-2.7-0.5c0,0-1,0.4-1.3,0.7c-0.7,0.4-1.3,0.7-1.6,0.7c-0.3,0-0.7-0.3-0.7-0.3
//         c-1-0.6-2.4-0.9-3.4-0.8c-0.7,0-1,0-1.7,0.4c-0.3,0.3-1.3,0.4-2,0.4c-0.7-0.3-1.7-0.6-3-0.5c-0.3,0-0.7,0-1,0.4
//         c-1,0.4-1.3,0.4-2,0.4s-1-0.3-1.4-0.3c-0.3,0-0.7,0.4-1,0.4c0,0.3-0.6,0.7-0.7,0.4c-0.4-0.7-1.1-1-1.7-0.9c-0.3,0-0.3,0.4-0.7,0.4
//         c-1,0-1.3,0.1-2-0.2l-0.3,0l-1.6,1.4c-0.3,0-0.7-0.3-1.4-0.3l-0.3,0c-0.7,0.4-1.3,0.7-2,0.8c-0.7,0-1.4-0.3-2-0.6l-1.7,0.1
//         c-0.3,0-1,0-1.4-0.3l-0.3,0c-0.7,0-1.7,0.4-2.3,0.8c-0.3,0-0.7,0.4-1,0.4c-0.3,0-0.4-0.3-0.4-1c-0.7-0.3-1-0.6-1.4-0.6
//         c-0.3,0-0.3,0.4-0.6,0.7c0,0.7-0.3,1-1,1.1l-0.7,0c-0.7-0.3-1.4-0.3-1.7-0.3c-0.3,0-0.7,0-1,0.4l-0.7,0c-2.3,0.4-5.3,0.6-7.7,0.7
//         c-1,0.4-2,0.4-3,0.5l-4.3,0.2c-0.7-0.3-0.7-0.3-1-0.3c-0.3,0-0.7,0-0.7,0.4l-1.7,0.1c-1.7,0.1-3.6,1.2-5.3,1.3
//         c-1,0-1.7-0.3-2.4-0.2c-0.7,0-1.7,0.4-2,0.4c-0.7,0-1.7-0.3-2.7-0.2c-0.7,0.4-1.6,0.7-2.3,0.8c-1,0-2-0.2-3-0.2l-1,0l-0.3,0.3
//         c0,0-0.3,0-0.7-0.3l-1.3,0.1c-1,0.7-2,0.8-3.3,0.8l-2.7,0.1c-0.3-0.3-1-0.3-1-0.3c-3,0.8-5.3,1.6-7.9,2.1l-0.7,0
//         c-1,0-2.3,0.4-3.3,0.8l-2.7,0.1c-0.7-0.3-1.4-0.6-1.4-0.3c-0.6,0.7-1.6,0.7-2.3,0.8l-1.7,0.1c-1.3,0.1-2-0.2-2.7-0.2l-0.3,0
//         c-0.7,0.4-1,0.4-1.3,0.4l-1,0l-0.3,0l-0.3,0c-0.6,0.7-1.3,1.1-2,1.1c-1.5,3.4-3,6.8-3.9,9.9l0.1,1.7c-2.3,1.5-2.2,3.1-2.4,4.8
//         l-1.8,4.4c-1.2,3.7-3,7.5-4.5,11.3c-1.2,2.7-1.4,4.8-2.7,6.8c-0.3,1.7-1.8,3.8-3.1,5.5l0,0.7c0,0.7-0.3,1.7-1.2,2.4
//         c-0.3,0.7-0.3,1-0.3,1.7l-0.3,1.7c-0.3,0.7-0.3,1.4-0.6,1.7c0,0.7-0.6,1.7-0.6,2.4c-0.3,1.4-0.9,3.1-1.8,4.1
//         c-0.3,0.3-1.3,1.4-1.2,2.1c0.1,1.7-0.2,2.7-0.8,4.1c-0.2,2.4-1.5,4.4-2,6.8l-0.9,1.7l0,0.7c0,0.7,0.1,1.3-0.2,2
//         c-1.2,2.4-1.8,5.1-3.3,7.5c0.1,2-1.5,4.1-2.1,5.8c0,0.7-0.6,1.4-0.9,2.1c-0.3,0.3-0.3,1-0.6,1.7c0.1,1.7-0.5,3-1.1,4.7l-0.8,4.1
//         l-2.9,9.5c-0.9,2.1-1.1,4.1-2.4,6.1c-0.3,0.3-0.6,1-0.6,1.7l-0.6,7.7c-0.3,1.7-0.5,3.4-1.1,4.7c-0.3,1.4-0.9,2.4-1.1,4.1
//         c0.1,1.7-0.5,4-0.7,6.1l-1.1,4.4l-1,1.1c0,0.3,0.4,0.7,0.4,1c0,0.7,0.4,1,0.4,1.7l-0.9,1.7l-0.2,2.4c0,1-0.2,2-1.2,3.4l0.1,2.3
//         c-0.3,0.7-0.3,1.4,0.1,2c0.4,0.7,0.1,1.7,0.1,2.7c-0.2,3-0.1,5.7,0.8,8.7l0,0.7c0.3,0,0.7,0.3,0.7,0.3l0.7,0c0.6-0.7,1.6-1.1,2-1.1
//         c2.9-1.8,4.8-4.3,7-6.4l7.5-9.7c1.9-2.1,2.8-3.5,5.1-5.6c0.9-1.4,1.6-2.4,3.2-3.5c1.2-2.1,2.9-2.8,3.4-4.9l2.2-2.8l3.7-5.9
//         c2.2-2.5,4.8-5.3,5.6-8c0.6-0.7,1.3-1.4,1.9-1.4l2.8-4.8l1-1.1c-0.1-1.7,0.9-2.4,1.8-3.8l2.8-3.5c0.3-1.4,0.9-1.7,1.9-3.1
//         c0.9-1.7,2.5-2.8,2.5-4.1c0.3-0.7,0.9-1.4,1.9-1.8c0.6-1,1.6-2.1,2.2-3.8c0.6-2,2.8-4.5,4.4-6.6c0.6-2,1.8-3.8,3.4-5.2
//         c1-0.7,1.3-1.4,1.3-1.4c0.3-0.7,0.3-1.4,0.3-1.7c1.3-1.7,2.2-3.8,4.1-4.9c0.3-0.4,0.6-1,1-1.1l1.3-1.4c0.6-2,1.8-4.1,3.4-6.2
//         c0.9-1.7,1.5-3.4,2.8-4.8l-0.1-1.3c0-0.3,0.3,0,0.7,0l0.3,0c0.3,0,0.7,0.3,0.7,0.3c0,0.3-0.3,0.7-0.3,1c0,0.3,0.4,1,0.4,1.3
//         c0.3,0,0.4,0.3,0.4,0.7c0,0.3-0.3,1-0.3,1.4c0,0.3,0.7,0.6,0.7,0.6c1-0.4,1.3-0.4,1.7-0.4C857.6,179.4,857.6,180.1,857.6,180.4z
//         M795.5,94.8l0-1l-5.6,0.9l-1,0l0.1,1.3l0.7,0.6c0.3-0.4,0.6-0.7,1-0.7l0.3,0c0.3,0,0.7,0.3,1,0.3c1.3-0.1,2-1.1,3.3-1.5
//         L795.5,94.8z M834.7,87.8c0.3-0.4,0.3-0.7,0.3-0.7l2.3-0.1c0,0,0,0.3,0.4,0.3c-0.3,0-0.3,0.4-0.3,0.4L834.7,87.8z M842,87.1
//         c-0.3,0-1,0.4-1.7,0.4c0-0.3-0.4-0.7-1-0.6l1-0.7c0,0.3,0.3,0.3,1,0.3l0.7,0L842,87.1z M844.6,86.4c-0.3,0.4-0.3,0.4-0.7,0.4
//         c0-0.3,0-0.3-0.4-0.3l0.3-0.3l0.7,0l0.4,0.7L844.6,86.4z M846.3,86.3c-0.3,0-0.3,0-0.4-0.3c0,0,0-0.3,0.3-0.4l1.3-0.1
//         c0.3,0.3,1.4,0.3,1.4,0.6c0,0.7-0.6,0.7-1,0.7c-0.4-0.3-0.4-0.3-0.7-0.3C847.3,86.6,846.6,86.6,846.3,86.3z M854,85.9
//         c-0.7,0-1.3,0.1-2-0.2l-0.3,0c0-0.3,0.3-0.4,0.3-0.4c0.7-0.4,1.3-0.4,2-0.4l2.3-0.1c0,0.3-0.3,0.7-0.6,0.7
//         C855,85.8,854.6,85.9,854,85.9z M857.8,83c-0.3,0-0.7,0-1-0.3c-0.3,0-0.3,0-0.4-0.3l0.3,0c0.3-0.3,0.7-0.4,1-0.4l0.7,0.3l0.7,0
//         c0,0.3-0.3,0.4-0.3,0.4C858.5,83,858.2,83,857.8,83z M857.3,84.7l-0.7,0l0.7-0.4c0.3,0,0.3,0,0.3,0.3
//         C857.6,85,857.3,84.7,857.3,84.7z M869.9,84.1c-1.3,0.4-2.6,0.8-4,0.9l-0.4-0.7c0,0,0.3-0.4,0.3,0c0.7-0.4,1.3-0.4,2-0.4l2.3-0.1
//         c0,0,0.3,0,0,0.3L869.9,84.1z M866.8,102.3l-0.3,0l0.7,0.6L866.8,102.3z M871.6,84l-0.3,0c-0.3,0,0-0.3,0-0.3s0.3,0,0.7-0.4
//         L871.6,84z M873.9,82.9c0.3,0,0.7,0.3,1,0.3l2.7-0.1c0,0,0.3,0,0.3,0.3l-0.3,0.4l-3,0.1c-0.3,0.3-0.3,0.3-0.7,0.4
//         c-0.3,0-0.7,0-0.7-0.3l-0.3,0c0,0,0.3-0.4,0.7-0.4C873.6,83.3,873.6,82.9,873.9,82.9z M885.9,82.6c0,0,0-0.3-0.4-0.3
//         c0.3,0,0.3-0.3,0.3-0.3l3-0.1c0.3,0,0.7,0,0.7,0.3l-0.3,0.4c-1,0-1.3,0.4-2.3,0.4C886.6,82.9,886.3,82.6,885.9,82.6z M890.6,82.1
//         c0-0.7,1.3-0.7,2.3-1.1c3-0.1,6,0,8.7-0.1c0.4,0.3,1,0.3,1.4,0.3c0.3,0,0.7,0,1-0.4l1,0c1,0,1.7,0.6,2.7,0.5
//         c-0.3,0.4-0.7,0.4-0.7,0.4l-3.7,0.2c-3.3,0.2-7,0.7-10.6,1.2C892.3,82.7,891.3,83,890.6,82.1z M908.3,81.2l-0.7,0
//         c0-0.3,0-0.7,0.3-0.7c0.3,0,0.7,0.3,1,0.3C908.6,81.2,908.6,81.2,908.3,81.2z M911.3,81.1c-0.3,0-1,0-1.7-0.3l0-0.3
//         c0.7,0.3,1,0.3,1.7,0.3L911.3,81.1z M912.2,80.3l1,0c0,0,0.4,0.3,0.4,0.7l-1,0L912.2,80.3z M916,80.8c-0.3,0.4-0.7,0.4-0.7,0.4
//         c-0.3,0-0.3,0-0.4-0.3c0-0.3,0-0.3-0.7-0.6c0,0,0.3-0.3,0.7-0.4l1,0c0.3,0,1,0,2-0.4c0.3,0,0.3-0.3,0.3-0.7l0-1
//         c0-0.3,0-0.3,0.3-0.3c0,0,0.4,0.3,0.7,0.3l2-0.1c0.7,0.3,0.7,1,0.7,1l0.3,0c0.3,0,0.7-0.4,0.6-0.7l0.7,0c-0.3,1.4,0.1,2,1.8,1.9
//         l0,0.3c0,0,0.4,0.3,0,0.3c-1.7-0.3-2.7,0.1-4.4-0.5c0,0.3-0.7,0.4-1,0.4c-0.7,0-1,0-1.7-0.3c-1,0-1.6,0.7-2,0.8L916,80.8z
//         M930.7,80.4c-1,0-1.7-0.3-3.4-0.2c-0.3,0-1-0.3-1-0.6c0.3-0.3,0.6-0.7,1-0.7c0.3,0,0.3,0,1,0.3c0.4,0.3,0.4,0.3,0.7,0.3
//         c0,0,0.3,0,0.3-0.7c0-0.3,0-0.7,0.3-0.7s0.7,0.3,0.7,0.3l0.7,1.3c0,0.3,0.4,0.3,0.4,0.3l-0.3,0.4L930.7,80.4z M934.6,79.9
//         c-0.4-0.3-0.4-0.3-0.4-0.7c0,0,0-0.3,0.3-0.3l1.4,0.9L934.6,79.9z M937.3,79.8c0,0-0.3,0-0.7-0.3l1,0c0-0.3,0.3,0,0.3,0
//         c0.3,0,0.4,0.3,0.4,0.3c0,0.3,0,0.3-0.3,0.4C937.7,80.1,937.7,80.1,937.3,79.8z M941.7,86.6c0-0.7-0.7-0.6-1-0.6l-1,1.1
//         c0,0.3,0,0.3,0.4,0.3c0,0.3,0.4,0.3,0.7,0.3C940.7,87.3,941.4,87.3,941.7,86.6z M942.7,80.2c-0.7,0-1,0-1.4-0.3
//         c-0.3,0-0.7-0.3-1-0.6c0.3,0,0.7-0.4,0.7-0.4c0.3,0,1,0.3,2,0.2c0.3,0,0.7,0,1,0.3c0.3,0,0.3-0.3,0.7-0.4c0,0,0.3,0,0.4,0.3
//         c0,0.3-0.6,0.7-1,0.7L942.7,80.2z M948.6,85.2l0,0.7c-0.3,0.4-0.3,0.4-0.7,0.4c-0.3,0-0.7,0-0.7-0.3c-1,0-1.4-0.3-2-0.2
//         c-0.3,0-0.4-0.3-0.4-0.3L948.6,85.2z M946,79.4l0-0.7c0.7,0,1.4,0.3,2.4,0.6l0.3,0c0,0,0.3,0,0.4,0.3l-0.3,0.3l-0.7,0
//         c-0.7,0-1,0-1.4-0.3C946,79.7,946,79.4,946,79.4z M952,79.7c0,0-0.4-0.3-1-0.3l0-0.7c0.7,0.3,1.7,0.3,2.7,0.2c0.7,0,1,0,1.4,0.3
//         l0.3,0c0,0.3,0,0.3-0.3,0.3L952,79.7z M962.8,86.9c0,0,0.3-0.4,0.6-1l-0.4-1.3l-1,1.1L962.8,86.9z M963.4,79.2
//         c0.3-0.3,0.3-0.7,0.6-0.7c0.3,0,1.7-0.1,2.4,0.2l2.7,0.9l-3,0.1c-0.7-0.3-2-0.2-2.7-0.2C963.4,79.5,963.7,79.1,963.4,79.2z
//       M974.2,87.7c0,0.3,0.4,0.7,0.7,0.6l-4.3,0.2c0,0-0.3,0-0.7-0.3l0-0.3L974.2,87.7z"
//       />
//       <path d="M910.5,206.6c-1.6,1.8-3.9,2.5-5.8,4.3c-1,0.4-1.3,0.7-1.6,1.4l-0.6,1.4c0.3,0.3,0.7,0.3,0.7,0.3l6.2-4
//         c1.6-0.7,3-1.1,3.6-1.8c2.6-2.5,14.2-10.1,16.5-10.5c0,0,0.4,0.7,0.4,1c-1,0.7-1.2,2.1-2.2,2.8c-1.2,2.1-12.2,9.6-13.1,11.7
//         c-0.2,2-1.8,3.8-3.4,4.9l-8.6,8.1c-0.9,1.4-1.9,2.8-4.2,3.2l-0.7,0c-0.3,0-0.3,0.3-0.6,0.7c0,0.3-4.5,3.2-4.5,3.6
//         c-1.3,1.1-1.9,1.4-2.2,2.5c0,0.3,0,0.7,0.4,1.3c0.7,1.6,1.5,3.6,1.6,5.9c-0.2,3.4-0.3,7-0.1,10.7l-0.3,0.7
//         c-1.2,2.4-1.5,4.4-1.7,6.1c-0.9,2.1-2.1,4.5-4,6.9c-2.2,2.1-3.8,4.5-5.7,6.6c-3.5,2.9-7.4,5.7-10.6,9.2c-2.6,0.8-3.9,2.2-5.9,2.6
//         l-11.1,4.9c-5.3,1.9-9.2,3.1-13.5,4l-2,0.1c-1.7,0.1-4-0.1-6.4-0.4c-0.3,0-0.7-1-1.7-1.3c-0.4-2-1.8-2.9-3.6-4.2
//         c-0.7,0-1-0.6-1.1-1c-0.4-0.7-1.1-1-1.4-1.6l1-0.7c0-0.3,0-1,0.3-1.7c0-0.3,0-1-0.1-1.3l0-0.3c-0.1-1.3-0.4-2.3-0.2-3.7
//         c0-0.7-0.1-1.7-0.1-2.3l0-0.7c0-1,1.1-5.4,1.4-6.1c0.6-1.4,2.2-3.1,3.5-4.2c1.2-2.1,2.4-5.1,3.7-6.9c2.9-2.5,5.8-4.3,7.7-6.7
//         c0,0,0.3,0,0.3-0.7c3-0.8,4.8-3.6,7.1-5.7c3.2-2.8,7.5-3.4,11.1-6.2l1.9-1.4c4.2-3.6,6.5-4,6.8-4.7c0-0.7-1.1-1.3-3.5-3.5
//         c-3.5-2.8-6.3-5.4-10.4-7.5c-2.8-2.2-6.2-4-9.7-6.5c-3.8-3.2-7.7-7.6-10-12.2c-0.8-2.6-0.9-5-0.7-7.3c0-1,0.2-2.4,0.5-3.4
//         c0.4-4.7,2.2-10.1,6-14l2.5-4.1c0.3-0.3,0.6-1,1.3-1.4c4.2-3.9,7.6-8.4,11.8-11.6c3.9-2.9,7.1-6,10.3-8.9
//         c7.4-5.7,15.5-10.5,23.3-16.5l1.7-0.1c2.9-2.8,6.9-3.3,9.9-3.5l2.7-0.1c2.3-0.1,3.7-0.2,5.3-0.6c1-0.7,2.3-1.1,3.3-1.2
//         c2-0.1,3.4,0.5,4.7,1.1c0.3,0,0.3,0,1.1,1.3c0.4,0.3,0.1,1.7,0.5,2.7c1,0,2.4,0.2,2.7,1.2c1.4,0.6,1.4,1.6,2.1,2.2
//         c0.5,2.7,0.9,4.6,2.3,5.9c0.3,0,0.4,0.7,0.4,0.7c-0.9,1.7-2.9,2.5-3.8,3.9c-3.7,0.2-6.5,3-10.5,3.2c-0.7,0-1.3,0.4-2,0.4
//         c-3.9,3.2-9.5,4.5-13,7c-0.3,0.3-0.7,0.4-1,0.4l-0.3,0c-4.1,4.6-11.1,5.6-14.9,10.4c-0.3,1-2.3,1.8-3.2,2.8
//         c-3.6,1.2-5.8,3.6-8.7,5.8c-5.5,3.6-9.6,8.8-13.7,14.1c-1,0.4-1.3,0.7-1.3,1.7c0,0.3-0.6,0.7-0.6,1c0,0.3,0.4,1,0.7,1.3
//         c1.7,1.3,3.2,3.5,4.9,4.4c4.5,2.8,9.3,5.2,12.5,8.4l12.6,10.8c0.4,0.3,0.4,0.7,0.7,0.6c0.3,0,0.7-0.4,1-0.4
//         c1.7-0.4,2.9-1.8,4.2-2.6c3.2-2.5,6.8-5.4,10-6.9c2.9-2.2,4.5-4.2,7.4-6.4c1.3-0.4,13-7,15-7.8c0-0.3,0.3-0.4,0.3-0.4
//         c0.3,0,1,0.3,1.4,0.6C922.2,199.6,911.1,206.2,910.5,206.6z M872.1,250.6l-0.4-0.3c-0.3,0-2,0.1-2.4-0.2l-0.6,0.7
//         c-5.5,4.6-11.9,9.6-18.7,13.6l-7,8c-2,0.8-2.3,1.8-2.2,2.5l0,0.7c-0.3,1.4,1.1,2,2.1,1.9c0.3-0.4,0.3-0.7,0.6-0.7
//         c0,0,0.4,0.3,0.7,0.3c6.5-4,9.4-5.1,15.9-10.5c1.3-1.1,2.6-1.5,2.9-2.5c3.8-3.9,6.4-6.7,8.5-10.8
//       C871.6,252.6,872.2,251.6,872.1,250.6z M924.3,196.2l0.3,0l0,1l-1.3,1.1L924.3,196.2z"
//       />
//     </g>
//   </svg>
// );
//

