
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slide,
  withStyles,
  Button,
  FormControlLabel,
} from '@material-ui/core';

import {
  Close as CloseIcon,
} from 'mdi-material-ui';
import {compose, graphql} from "react-apollo";
import {withRouter} from "react-router";
import gql from "graphql-tag";

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  root: {},
});

const UPDATE_LOGIN_MESSAGE = gql`
  mutation ($mobileLoginMessageSend: Boolean!) {
    updateLoginMessage(mobileLoginMessageSend: $mobileLoginMessageSend) {
      id
    }
  }
`;


// -------------------------------------------------------------------------- //
class LoginPopup extends React.Component {

  state = {
    open: true,
    checked: false
  }

  dontShowAgain = (checkbox) => {
    this.props.updateLoginMessage({
      variables: { mobileLoginMessageSend: checkbox }
    });
    this.setState({checked: checkbox});
  }

  render() {
    const { open, checked } = this.state;

    return (
      <Dialog
        open={open}
        onClose={() => this.setState({ open: false})}
        TransitionComponent={Slide}
        TransitionProps={{direction: 'up'}}
      >
        <DialogActions>
          <IconButton
            size="small"
            onClick={() => this.setState({ open: false })}
          >
            <CloseIcon/>
          </IconButton>
        </DialogActions>
        <DialogTitle>Welcome to ScoreShots Mobile!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Our template selection will showcase only the templates that are
            mobile friendly and aren't too complex to edit.
          </DialogContentText>
          <FormControlLabel
            label="Don't show this again"
            control={
              <Checkbox
                color="primary" checked={checked}
                onChange={(e) => this.dontShowAgain(e.target.checked)}
                value="dontshowagain"
              />
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => this.setState({open: false})}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
// -------------------------------------------------------------------------- //
export default compose(
  graphql(UPDATE_LOGIN_MESSAGE, { name: 'updateLoginMessage' }),
  withRouter,
  withStyles(STYLES),
)(LoginPopup);

// -------------------------------------------------------------------------- //