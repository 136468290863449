
// -------------------------------------------------------------------------- //

import React from 'react';
import ReactDOM from 'react-dom';
import GA4React from "ga-4-react";

import {
  CssBaseline,
} from '@material-ui/core';

import { ApolloProvider } from 'react-apollo';
import { isMobileOnly } from 'react-device-detect';
import { Provider as UnstatedProvider } from 'unstated';
import { Router } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import * as serviceWorker from './serviceWorker';
import { client } from './components/auth/auth';
import Routes from './routes';
import history from './history';
import { PageTitle } from './title';
import { Skipper } from './skipper';

// -------------------------------------------------------------------------- //
//let prestoSite = localStorage.getItem('prestoSite');

let intercomCheck = true;
//if (!isMobileOnly && prestoSite === 'false')
if (!isMobileOnly)
{
  intercomCheck = false;
}

window.Intercom('boot', {
  app_id: process.env.REACT_APP_INTERCOM_APP_ID,
  hide_default_launcher: intercomCheck,
});

// -------------------------------------------------------------------------- //



const renderApplication = (showUpdate = false) => {
  const ga4react = new GA4React(
    'G-DQS84TR6FG',
    { /* ga custom config, optional */ },
    [ /* additional code, optional */ ],
    5000, /* timeout, optional, defaults is 5000 */
  );
  ga4react.initialize().then((ga4) => {
    ga4.pageview('path')
    ga4.gtag('event','pageview','path') // or your custom gtag event
  },(err) => {
    console.error(err)
  });

  const root = (
    <React.Fragment>
      <CssBaseline/>
      <PageTitle/>
      <Skipper/>
      <ApolloProvider client={client}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <UnstatedProvider>
            <Router history={history}>
              <Routes showUpdate={showUpdate}/>
            </Router>
          </UnstatedProvider>
        </MuiPickersUtilsProvider>
      </ApolloProvider>
    </React.Fragment>
  );

  ReactDOM.render(root, document.getElementById('root'));
};

// -------------------------------------------------------------------------- //

let version = 'DEVELOPMENT';

if (process.env.REACT_APP_COMMIT) {
  version = process.env.REACT_APP_COMMIT;
}

if (process.env.NODE_ENV !== 'development') {
  /* global Raven */
  Raven.config(process.env.REACT_APP_SENTRY_ENDPOINT, {
    release: version,
    environment: process.env.NODE_ENV,
  }).install();
}

renderApplication();

if (isMobileOnly) {
  serviceWorker.register();
} else {
  serviceWorker.unregister();
}

// -------------------------------------------------------------------------- //
