/* eslint no-underscore-dangle: "off" */
class CanvasTexture {
  constructor(fabric) {
    this.class = fabric.util.createClass(fabric.Image, {
      selectable: false,
      type: 'canvasTexture',
      evented: false,
      originX: 'center',
      originY: 'center',
      crossOrigin: 'anonymous',
      stateProperties: fabric.Image.prototype.stateProperties.concat('renderBelow', 'src'),
      visible: true,
      initialize(element, options = {}) {
        this.callSuper('initialize', element, options);

        this.src = element;

        let self = this;
        this.setSrc(element, function(image) {
          if (image) {
            self.width = image.width;
            self.height = image.height;

            self.position();
          }
        }, { crossOrigin: 'anonymous' });

        this.globalCompositeOperation = 'overlay';

        this.on('added', () => {
          this.off('added');
          this.position();
        });
        this.on('modified', this.position);
      },

      // same as Effect
      position() {
        if (!this.canvas) {
          return;
        }

        this.left = this.canvas.workingArea.width / 2;
        this.top = this.canvas.workingArea.height / 2;

        // determine minimum scale
        const scale = Math.max(
          this.canvas.workingArea.height / this.height,
          this.canvas.workingArea.width / this.width,
        );
        this.scaleX = scale;
        this.scaleY = scale;

        // determine where to place the object.
        if (this.renderBelow) {
          let i = 0;
          this.canvas.getObjects().forEach((obj, index) => {
            if (
              obj.type === 'backgroundBox' ||
              (
                obj.parent &&
                obj.parent.type === 'backgroundBox'
              )
            ) {
              i = index;
            }
          });
          this.canvas.moveTo(this, i + 1);
        } else {
          this.canvas.moveTo(this, this.canvas.getObjects().length - 1);
        }

        this.dirty = true;
        this.canvas.requestRenderAll();
      },
      toObject(propertiestoInclude) {
        propertiestoInclude = [
          'renderBelow',
          'src',
          ...propertiestoInclude
        ];
        
        return this.callSuper('toObject', propertiestoInclude)
      }
    });
    this.fromObject = (object, callback) => fabric.Object._fromObject('CanvasTexture', object, callback, 'src');
  }
}
export default CanvasTexture;
