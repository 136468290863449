
// -------------------------------------------------------------------------- //

import * as React from 'react';

import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';

import { Form, Field } from 'react-final-form';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';

import { TierSearchContext } from './search';
import TierTable from './TierTable';
import TextField from '../../material/TextField';

// -------------------------------------------------------------------------- //

const ADD_TIER = gql`
  mutation addTier($name: String!) {
    createTier (
      name: $name
    ) {
      id
      name
    }
  }
`;

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  app_bar: {
    flex: 0,
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  root: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(3),
  },
});

// -------------------------------------------------------------------------- //

class Tiers extends React.Component {

  state = {
    create_dialog: false,
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        {this.renderToolbar()}
        <div className={classes.root}>
          {this.renderTierTable()}
        </div>
        {this.renderCreateDialog()}
      </React.Fragment>
    );
  }

  renderToolbar = () => {
    const { classes } = this.props;

    return (
      <AppBar color="primary" elevation={0} className={classes.app_bar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Tiers
          </Typography>
          <Button color="inherit" onClick={() => this.openCreateDialog()}>
            Create
          </Button>
        </Toolbar>
      </AppBar>
    );
  }

  renderTierTable = () => {
    return (
      <Paper>
        <TierSearchContext.Consumer>
          {(search_parameters) => (
            <TierTable search_parameters={search_parameters}/>
          )}
        </TierSearchContext.Consumer>
      </Paper>
    );
  }

  renderCreateDialog = () => {
    const { classes } = this.props;
    const { create_dialog } = this.state;

    return (
      <Dialog
        open={create_dialog}
        onClose={() => this.closeCreateDialog()}
      >
        <DialogTitle>Add New Tier</DialogTitle>
        <DialogContent>
          <Form
            onSubmit={data => this.createTier(data)}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name="name"
                  id="name"
                  label="Tier Name"
                  component={TextField}
                  className={classes.field}
                />
                <Button
                  fullWidth
                  variant="raised"
                  color="secondary"
                  onClick={handleSubmit}
                  className={classes.field}
                >
                  Create
                </Button>
              </form>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.closeCreateDialog()} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  openCreateDialog = () => {
    this.setState({ create_dialog: true });
  }

  closeCreateDialog = () => {
    this.setState({ create_dialog: false });
  }

  createTier = (data) => {
    const { addTier: mutation, history } = this.props;

    mutation({ variables: {
        name: data.name,
      }}).then(({ data }) => {
      history.replace(`/admin/tiers/${data.createTier.id}`);
    });
  }

}

// -------------------------------------------------------------------------- //

export default compose(
  graphql(ADD_TIER, { name: 'addTier' }),
  withRouter,
  withStyles(STYLES),
)(Tiers);

// -------------------------------------------------------------------------- //
