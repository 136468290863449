
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Hidden,
} from '@material-ui/core';

import { BetaNews } from '../components/home/BetaNews';
import { MobileNews } from '../components/home/MobileNews';
import { PageTitle } from '../title';
import BetaHeader from '../components/home/BetaHeader';
import NewTemplates from '../components/home/NewTemplates';
import ResponsiveGrid from '../components/layout/ResponsiveGrid';

// -------------------------------------------------------------------------- //

export const BetaHome = () => (
  <React.Fragment>
    <PageTitle title="Home"/>
    <BetaHeader/>
    <ResponsiveGrid style={{ padding: 16 }}>
      <NewTemplates/>
      <Hidden only="xs">
        <BetaNews/>
      </Hidden>
      <Hidden smUp>
        <MobileNews/>
      </Hidden>
    </ResponsiveGrid>
  </React.Fragment>
);

// -------------------------------------------------------------------------- //
