
// -------------------------------------------------------------------------- //

import * as React from 'react';
import { compose, graphql } from 'react-apollo';
import { withRouter } from 'react-router';
import gql from 'graphql-tag';

import {
  Step,
  StepContent,
  StepLabel,
  Stepper,
  withStyles,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import FullscreenDialog from './FullscreenDialog';
import ResponsiveGrid from '../layout/ResponsiveGrid';
import steps from './dict/steps';


import { ROLES_QUERY, UserPermissions } from '../user/Permissions';

// -------------------------------------------------------------------------- //
const ROLES = gql`
  query {
    me {
      id
      client {
        id
        fbPages {
          id
          name
          accessToken
          fbId
        }
        twitterAccounts {
          id
          name
          screen_name
          twitterId
          oauthToken
          oauthTokenSecret
        }
      }
      role {
        ${ROLES_QUERY}
      }
    }
  }
`;

const styles = (theme) => ({
  appBar: {
    position: 'static',
  },
  flex: {
    flex: 1,
  },
  imagePreview: {
    maxHeight: '70vh',
    maxWidth: '100%',
    boxShadow: theme.shadows[4],
    borderRadius: 2,
    marginTop: theme.spacing(2),
  },
  stepContent: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  grid: {
    overflowY: 'auto',
    paddingTop: theme.spacing(1),
  },
  actionsContainer: {
    marginTop: theme.spacing(2),
  },
  formControlLabel: {
    display: 'flex',
  },
  textField: {
    width: 350,
    maxWidth: '100%',
  },
  multiTextField: {
    width: 350,
    maxWidth: '100%',
    marginTop: 0,
    marginBottom: theme.spacing(2),
    marginLeft: 58,
  },
  messageListItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

// -------------------------------------------------------------------------- //

class ShareDialog extends React.PureComponent {
  state = {
    step: 0,
    fbPages: [],
    twitterAccount: null,
    twitterAccounts: [],
    postLater: false,
    isVideo: false,
    hasEffect: false,
    videoURL: null,
    selectedDate: new Date(),
    message: false,
    uniformMessage: true,
    uniformTextMessage: '',
    textMessages: {
      facebook: {},
      twitter: {},
    },
    selectedIndex: 0,
    slideState: [
      {
        selectedSocial: [],
        selectAllTemplates: false,
        captionText: '',
        captionDisabled: false,
        selectTwitter: false,
        selectFacebook: false,
      },
      {
        selectedSocial: [],
        selectAllTemplates: false,
        captionText: '',
        captionDisabled: false,
        selectTwitter: false,
        selectFacebook: false,
      },
      {
        selectedSocial: [],
        selectAllTemplates: false,
        captionText: '',
        captionDisabled: false,
        selectTwitter: false,
        selectFacebook: false,
      },
    ],
  }

  constructor(props) {
    super(props);

    this.onQuickCreateTextChange = this.onQuickCreateTextChange.bind(this);
    this.onQuickCreateCaptionStatusChange = this.onQuickCreateCaptionStatusChange.bind(this);
  }

  setSelected = (type) => (selected) => {
    const state = {};
    state[type] = selected;
    this.setState(state);
  }

  setSelectedEvent = (type) => (selected) => () => {
    const state = {
      twitterAccounts: [],
    };
    state[type] = selected;
    this.setState(state);
  }

  quickCreateHandleSelectChange = (e, i) => {
    this.setState((prevState) => {
      const slideState = [...prevState.slideState];
      // let selectedState = slideState[prevState.selectedIndex]

      const hasValue = slideState[prevState.selectedIndex].selectedSocial.includes(i.props.value);
      slideState[prevState.selectedIndex] = {
        ...slideState[prevState.selectedIndex],
        selectedSocial: hasValue === false ? [...slideState[prevState.selectedIndex]
          .selectedSocial, i.props.value]
          : [...slideState[prevState.selectedIndex].selectedSocial
            .filter((social) => social !== i.props.value)],
        selectAllTemplates: slideState[prevState.selectedIndex]
        // eslint-disable-next-line no-unneeded-ternary
          .selectAllTemplates === true ? false : false,
        selectTwitter: i.props.value.__typename === 'TwitterAccount' && slideState[prevState.selectedIndex].selectTwitter === true ? false
          : slideState[prevState.selectedIndex].selectTwitter,
        selectFacebook: i.props.value.__typename === 'FacebookPage' && slideState[prevState.selectedIndex].selectFacebook === true ? false
          : slideState[prevState.selectedIndex].selectFacebook,
      };
      return { slideState };
    });
  }

  quickCreateHandleSelectMultiple = (select, array, state) => {
    this.setState({ fullAccounts: array });
    if (select) {
      // const filter = array.filter((selected) => !this.state.slideState[this.state.selectedIndex]
      //   .selectedSocial.includes(selected));
      const filter = [];
      array.forEach((value) => {
        if (this.state.slideState[this.state.selectedIndex]
          .selectedSocial.filter((el) => el.id === value.id).length === 0) {
          filter.push(value);
        }
      });
      this.setState((prevState) => {
        const slideState = [...prevState.slideState];
        slideState[prevState.selectedIndex] = {
          ...slideState[prevState.selectedIndex],
          selectedSocial: [...prevState.slideState[prevState.selectedIndex]
            .selectedSocial, ...filter],
          selectAllTemplates: state === 'selectAllTemplates' ? true : slideState[prevState.selectedIndex].selectAllTemplates,
          selectTwitter: state === 'selectTwitter' || state === 'selectAllTemplates' ? true : slideState[prevState.selectedIndex].selectTwitter,
          selectFacebook: state === 'selectFacebook' || state === 'selectAllTemplates' ? true : slideState[prevState.selectedIndex].selectFacebook,
        };
        return { slideState };
      });
    } else {
      this.setState((prevState) => {
        const filter = [];
        this.state.slideState[this.state.selectedIndex]
          .selectedSocial.forEach((value) => {
            if (array.filter((el) => el.id === value.id).length === 0) {
              filter.push(value);
            }
          });
        const slideState = [...prevState.slideState];
        slideState[prevState.selectedIndex] = {
          ...slideState[prevState.selectedIndex],
          selectedSocial: [...filter],
          selectAllTemplates: state === 'selectAllTemplates' ? false : slideState[prevState.selectedIndex].selectAllTemplates,
          selectTwitter: state === 'selectTwitter' || state === 'selectAllTemplates' ? false : slideState[prevState.selectedIndex].selectTwitter,
          selectFacebook: state === 'selectFacebook' || state === 'selectAllTemplates' ? false : slideState[prevState.selectedIndex].selectFacebook,
        };
        return { slideState };
      });
    }
  }

  nextQuickCreateSlide = () => {
    const { selectedIndex } = this.state;

    if (selectedIndex < 2) {
      this.setState({ selectedIndex: selectedIndex + 1 });
    } else {
      this.handleNext();
    }
  }

  previousQuickCreateSlide = () => {
    const { selectedIndex } = this.state;

    if (selectedIndex > 0) {
      this.setState({ selectedIndex: selectedIndex - 1 });
    } else {
      this.handlePrev();
    }
  }

  onQuickCreateTextChange(e) {
    const { value } = e.target;
    this.setState((prevState) => {
      const slideState = [...prevState.slideState];
      slideState[prevState.selectedIndex] = {
        ...slideState[prevState.selectedIndex],
        captionText: value,
      };
      return { slideState };
    });
  }

  onQuickCreateCaptionStatusChange(e) {
    const value = e.target.checked;
    this.setState((prevState) => {
      const slideState = [...prevState.slideState];
      slideState[prevState.selectedIndex] = {
        ...slideState[prevState.selectedIndex],
        captionDisabled: value,
      };
      return { slideState };
    });
  }

  stateUpdate = (prop, val) => {
    const state = {};
    state[prop] = val;
    this.setState(state);
  }

  handlePrev = () => {
    this.setState({
      step: this.state.step - 1,
    });
  }

  handleNext = () => {
    this.setState({
      step: this.state.step + 1,
    });
  }

  handleChange = (prop) => (e) => {
    const newState = {};
    newState[prop] = e.target.value;
    this.setState(newState);
  }

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  }

  toggleState = (value) => () => {
    const newState = {};
    if (this.state[value]) {
      newState[value] = false;
    } else {
      newState[value] = true;
    }
    this.setState(newState);
  }

  textMessageChange = (type, entityId, text) => {
    const newState = {
      textMessages: this.state.textMessages,
    };
    newState.textMessages[type][entityId] = text;
    this.setState(newState);
  }

  componentDidUpdate(previous) {
    const { CanvasUtil, open } = this.props;
    if (open && !previous.open && CanvasUtil) {
      this.setState({
        step: 0,
        fbPages: [],
        twitterAccount: null,
        twitterAccounts: [],
        postLater: false,
        videoURL: null,
        selectedDate: new Date(),
        isVideo: CanvasUtil.CanvasVideo.isVideo(),
        hasEffect: (CanvasUtil.hasEffect() || CanvasUtil.hasBgm()),
        message: false,
        uniformMessage: true,
        uniformTextMessage: '',
        textMessages: {
          facebook: {},
          twitter: {},
        },
      });
    }
  }

  renderSteps() {
    // eslint-disable-next-line max-len
    const {
      classes, CanvasUtil, templateId, slideshow,
      json, rendering, progress, video, image, data, method
    } = this.props;

    if (!(data && data.me && data.me.role)) {
      return (
        <Typography variant="h6" gutterBottom>
          Loading...
        </Typography>
      );
    }
    const user_permissions = new UserPermissions(data.me.role);
    let twitter_ids = []; let
      facebook_ids = [];

    if (user_permissions.get('social_posting:read')) {
      twitter_ids = user_permissions.get('social_posting:twitter_accounts', []);
      facebook_ids = user_permissions.get('social_posting:facebook_accounts', []);
    } else {
      if (data.me.client.twitterAccounts) {
        twitter_ids = data.me.client.twitterAccounts.map((i) => i.id);
      }
      if (data.me.client.fbPages) {
        facebook_ids = data.me.client.fbPages.map((i) => i.id);
      }
    }



    let _lastStep = steps.length - 1;
    let sortedStepsArray = [];
    if (this.props.quickCreate) sortedStepsArray = steps.filter((el) => el.name !== 'Add a Message');
    else sortedStepsArray = steps;
    return sortedStepsArray.map((step, index) => {
      const StepComponent = step.component;
      if (facebook_ids.length === 0 && twitter_ids.length === 0 && index > 0) {
        return null;
      }
      if (facebook_ids.length === 0 && twitter_ids.length === 0) {
        _lastStep = 0;
      }
      return (
        <Step key={step.id}>
          <StepLabel>{step.name}</StepLabel>
          <StepContent classes={{ transition: classes.stepContent }}>
            <StepComponent
              parentStateUpdate={this.stateUpdate}
              isVideo={this.state.isVideo}
              CanvasUtil={CanvasUtil}
              first={index === 0}
              last={index === _lastStep}
              onNext={this.handleNext}
              onPrev={this.handlePrev}
              hasEffect={this.state.hasEffect}
              width={this.props.width}
              height={this.props.height}
              selectedFb={this.state.fbPages}
              setSelectedFb={this.setSelected('fbPages')}
              selectedTwitter={this.state.twitterAccount}
              retweets={this.state.twitterAccounts}
              setRetweets={this.setSelected('twitterAccounts')}
              setSelectedTwitter={this.setSelectedEvent('twitterAccount')}
              selectedDate={this.state.selectedDate}
              handleDateChange={this.handleDateChange}
              // start quick create methods
              quickCreateHandleSelectChange={this.quickCreateHandleSelectChange}
              quickCreateHandleSelectMultipleChange={this.quickCreateHandleSelectMultiple}
              nextQuickCreateSlide={this.nextQuickCreateSlide}
              previousQuickCreateSlide={this.previousQuickCreateSlide}
              onQuickCreateTextChange={this.onQuickCreateTextChange}
              onQuickCreateCaptionStatusChange={this.onQuickCreateCaptionStatusChange}
              // end quick create methods
              postLater={this.state.postLater}
              toggleState={this.toggleState}
              message={this.state.message}
              uniformMessage={this.state.uniformMessage}
              uniformTextMessage={this.state.uniformTextMessage}
              handleChange={this.handleChange}
              textMessages={this.state.textMessages}
              textMessageChange={this.textMessageChange}
              clientId={this.props.clientId}
              {...this.state}

              video={video}
              image={image}
              rendering={rendering}
              progress={progress}
              slideshow={slideshow}
              json={json}
              templateId={templateId}
              method={method}
              quickCreate={this.props.quickCreate}
            />
          </StepContent>
        </Step>
      );
    });
  }

  render() {
    const { classes, open, onClose } = this.props;
    const { step } = this.state;

    return (
      <FullscreenDialog open={open} title="Share" onClose={onClose}>
        <div className={classes.grid}>
          <ResponsiveGrid>
            <Stepper
              className={classes.stepper}
              orientation="vertical"
              activeStep={step}
            >
              {this.renderSteps()}
            </Stepper>
          </ResponsiveGrid>
        </div>
      </FullscreenDialog>
    );
  }
}

// -------------------------------------------------------------------------- //

export default compose(
  withStyles(styles),
  withRouter,
  graphql(ROLES),
)(ShareDialog);


// -------------------------------------------------------------------------- //
