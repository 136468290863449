
// -------------------------------------------------------------------------- //

import * as React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  withStyles,
} from '@material-ui/core';

import { } from 'mdi-material-ui';

import {compose, graphql, Mutation} from 'react-apollo';
import gql from 'graphql-tag';

import CustomFolderView from '../customTemplates/CustomFolderView';

// -------------------------------------------------------------------------- //

const styles = (theme) => ({
  flex: {
    flex: 1,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
});

// -------------------------------------------------------------------------- //

const MAKE_TEMPLATE_FOLDER = gql`
  mutation createTemplateFolder($name: String!, $folderId: ID) {
    createTemplateFolder(name : $name, folderId : $folderId) {
      id
      parent {
        id
        name
        children {
          id
          name
        }
      }
    }
  }
`;

const ROLES_QUERY = gql`
  query {
    me {
      id
      role {
        id
        name
        restrictCategoryFlag
        categories {
          id
          name
        }
        allowCustomFlag
        allowCustomSaveFlag
        allowCustomFolderSaveFlag
        restrictCustomFlag
        customFolders {
          id
          name
        }
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

class CustomFolderDialog extends React.Component {

  state = {
    // state for add folder dialogue
    create_dialog_open: false,
    create_folder_name: '',
    custom_template_name: 'New Template',
    current_folder: null,
  }

  componentDidUpdate(prev_props) {
    if (!prev_props.open && this.props.open) {
      this.setState({ custom_template_name: 'New Template' });
    }
  }

  openAddDialog = () => {
    this.setState({
      create_dialog_open: true,
      create_folder_name: 'New Folder',
    });
  }

  closeAddDialog = () => {
    this.setState({ create_dialog_open: false });
  }

  createFolder = (templateFolder) => {
    const { current_folder } = this.state;

    if (this.state.create_folder_name !== '') {
      const variables = {
        name: this.state.create_folder_name,
        folderId: current_folder,
      };

      templateFolder({ variables }).then((res) => {
        this.setState({
          current_folder: res.data.createTemplateFolder.id,
          create_dialog_open: false,
        });
      });
    }
  }

  render() {
    const { classes, rolesQuery } = this.props;
    const { custom_template_name } = this.state;
    let allowCustomFlag = true;
    let allowCustomFolderSaveFlag = true;
    let addNewCustomFolder = null;

    const disabled = (custom_template_name.trim() === '');

    if (rolesQuery && rolesQuery.me && rolesQuery.me.role) {
      allowCustomFlag = rolesQuery.me.role.allowCustomFlag;
      allowCustomFolderSaveFlag = rolesQuery.me.role.allowCustomFolderSaveFlag;
    }

    if (allowCustomFlag && allowCustomFolderSaveFlag) {
      addNewCustomFolder = (
        <React.Fragment>
          <Button fullWidth variant="contained" onClick={this.openAddDialog}>
            Add New Folder Here
          </Button>
          <Dialog
            open={this.state.create_dialog_open}
            onClose={this.openAddDialog}
          >
            <DialogTitle>Add New Folder</DialogTitle>
            <DialogContent>
              <TextField
                id="name"
                label="Folder name"
                className={classes.textField}
                value={this.state.create_folder_name}
                onChange={e => this.setState({ create_folder_name: e.target.value })}
                margin="normal"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.openAddDialog} color="primary">
                Cancel
              </Button>
              <Mutation mutation={MAKE_TEMPLATE_FOLDER} refetchQueries={['getCustomTemplateFolder']}>
                {mutation => (
                  <Button onClick={() => this.createFolder(mutation)} color="primary" autoFocus>
                    Save
                  </Button>)}
              </Mutation>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      );
    }


    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        open={Boolean(this.props.open)}
        onClose={() => this.closeTemplateDialog()}
      >
        <DialogTitle>
          Save As Custom Template
        </DialogTitle>
        <DialogContent>
          <CustomFolderView
            folderId={this.state.current_folder}
            changeFolder={(id) => this.setState({ current_folder: id })}
          />
          {addNewCustomFolder}
          <TextField
            fullWidth
            margin="normal"
            label="Template Name"
            value={this.state.custom_template_name}
            onChange={(e) => this.setState({ custom_template_name: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => this.closeTemplateDialog()}>
            Cancel
          </Button>
          <Button
            color="primary" disabled={disabled}
            onClick={() => this.createCustomTemplate()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  createCustomTemplate = () => {
    const { onSave } = this.props;

    if (onSave) {
      onSave({
        folder: this.state.current_folder,
        name: this.state.custom_template_name,
      });
    }
  }

  closeTemplateDialog = () => {
    const { onClose } = this.props;
    (onClose && onClose());
  }

}

// -------------------------------------------------------------------------- //

export default compose(
  graphql(ROLES_QUERY, { name: 'rolesQuery' }),
  withStyles(styles),
)(CustomFolderDialog);

// -------------------------------------------------------------------------- //
