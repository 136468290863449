import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import ImageMultiple from 'mdi-material-ui/ImageMultiple';
import Upload from 'mdi-material-ui/Upload';
import Divider from '@material-ui/core/Divider';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Crumbs from './Crumbs';
import MediaLibrary2 from './MediaLibrary2';
import ImageEditor from './ImageEditor';

import * as MetaData from '../../../canvas/lib/metadata';
import * as Object from '../../../canvas/lib/object';
import { Star as StarIcon } from 'mdi-material-ui';
import { UserRoleProvider, UserRoleConsumer } from '../../user/Permissions';

const styles = (theme) => ({
  addButton: {
    width: '90%',
    margin: '0 5%',
    height: theme.spacing(5),
  },
  buttonBox: {
    height: theme.spacing(6),
  },
});

const blobToDataURL = (blob, callback) => {
  var a = new FileReader();
  a.onload = function(e) {callback(e.target.result);}
  a.readAsDataURL(blob);
}

class GraphicsTab extends Component {
  constructor() {
    super();
    this.state = {
      page: 'picker',
      mediaFolder: null,
      mediaType: '',
      mediaParent: null,
      forcePick: false,
      forcePickLogo: false,
      forcePickCutout: false,
    };
  }

  setPage = (self, page, forcePick = false) => () => {
    if (this.props.active && this.props.active.parent && this.props.active.parent.type && this.props.active.parent.type === 'logoBox') {
      self.setState({
        page,
        forcePick,
        forcePickLogo: forcePick
      });
    } else if (this.props.active && this.props.active.parent && this.props.active.parent.type && this.props.active.parent.type === 'cutoutBox') {
      self.setState({
        page,
        forcePick,
        forcePickCutout: forcePick
      });
    } else {
      self.setState({
        page,
        forcePick
      });
    }
  }

  mediaNavigate = self => (mediaFolder = null) => () => {
    self.setState({
      mediaFolder,
    });
  }

  mediaSelect = (file) => {
    this.mediaSend(`https://${file.bucket}.s3.us-east-2.amazonaws.com/${file.client.id}/${file.id}.${file.extension}`);
  }

  getMediaButtons = (activeGroup = null, show_stock = false) => {
    return (
      <>
        <ListItem button onClick={this.setPage(this, 'media', true)}>
          <ListItemAvatar>
            <Avatar>
              <ImageMultiple/>
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Browse Media Library"/>
        </ListItem>
        {
          Boolean(show_stock) &&
          <ListItem button onClick={this.setPage(this, 'stock', true)}>
          <ListItemAvatar>
            <Avatar>
              <StarIcon/>
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Browse Stock Library"/>
        </ListItem>
        }
        <ListItem
          component="label"
          button
          htmlFor="bgUploader"
        >
          <input
            accept="image/jpeg,image/png,video/mp4"
            style={{ display: 'none' }}
            id="bgUploader"
            type="file"
            name="file"
            onChange={this.uploadFile(this)}
          />
          <ListItemAvatar>
            <Avatar>
              <Upload/>
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Upload from Device"/>
        </ListItem>
        <Divider/>
      </>
    );
  }

  mediaSend = (url) => {
    const { activeGroup } = this.props;
    let cache_buster = '';

    if (/^(f|ht)tps?:\/\//i.test(url)) {
      cache_buster = `?t=` + new Date().getTime();
    }

    activeGroup.forEach((obj) => {
      let target = null;

      if (obj.setMedia) {
        target = obj;
      } else if (obj.parent && obj.parent.setMedia) {
        target = obj.parent;
      }

      if (target !== null) {
        MetaData.clearMetaData(target, 'cropper_src');
        target.setMedia({ src: (url + cache_buster) });
      }
    });

    this.setState({ forcePick: false });
  }

  uploadFile = self => (e) => {
    const video = e.target.files[0].type === 'video/mp4' || e.target.files[0].type === 'video/quicktime';
    blobToDataURL(e.target.files[0],(finalUrl) => {
      self.mediaSend(finalUrl, video);
    })
    e.target.value = null;

  }

  getEditor() {
    let { active, activeGroup, CanvasUtil } = this.props;

    if (active.media) {
      active = active.media.element;
      activeGroup = activeGroup.map(i => i.media.element);
    }
    if (!active || !active.parent) {
      return;
    }
    return {
      content: (
        <>
          <List>
            {this.getMediaButtons(activeGroup, (active.parent.type === 'logoBox'))}
          </List>
          <ImageEditor
            active={active}
            activeGroup={activeGroup}
            CanvasUtil={CanvasUtil}
            fabric={this.props.fabric}
          />
        </>
      ),
      title: 'Editing Image',
      back: () => {
        this.props.canvas.discardActiveObject();
        this.setState({
          page: 'picker',
          mediaFolder: null,
          mediaParent: null,
          forcePick: false,
        });
      },
    };
  }

  handleChange = (e) => {
    this.setState({
      mediaType: e.target.value,
      mediaFolder: null,
    });
  }

  getMediaLogo(type) {
    return {
      content: (
        <>
          <MediaLibrary2
            media_type={type}
            scroll_element={this.props.sidebar_root}
            onSelect={(file) => this.mediaSelect(file)}
          />
        </>
      ),
      title: (type !== 'STOCK' ? 'Media Library' : 'Stock Library'),
      back: this.state.mediaFolder ? this.mediaNavigate(this)(this.state.mediaParent) : this.setPage(this, 'picker'),
    };
  }

  getMediaCutout() {
    return {
      content: (
        <React.Fragment>
          <MediaLibrary2 media_type="CUTOUT" onSelect={(file) => this.mediaSelect(file)}/>
        </React.Fragment>
      ),
      title: 'Media Library',
      back: this.state.mediaFolder ? this.mediaNavigate(this)(this.state.mediaParent) : this.setPage(this, 'picker'),
    };
  }

  setActive = (self, object) => () => {
    if (object.media && object.media.element) {
      self.props.canvas.setActiveObject(object.media.element);
    } else {
      self.props.canvas.setActiveObject(object);
    }
    self.props.canvas.requestRenderAll();
    self.setState({
      page: 'media'
    });
  }

  getPickerLogo() {
    return {
      content: (
        <React.Fragment>
          <List>
            {
              this.state.forcePick ?
                <ListItem
                  button
                  onClick={() => { this.setState({ forcePick: false }); }}
                >
                  <ListItemAvatar>
                    <Avatar src={
                      this.props.active.getSrc ?
                        this.props.active.getSrc() :
                        this.props.active.media.element.getSrc()
                    }
                    />
                  </ListItemAvatar>
                  <ListItemText primary="Edit Existing"/>
                </ListItem> :
                null
            }
            { this.getMediaButtons(this.props.activeGroup, true) }
          </List>
        </React.Fragment>
      ),
      title: 'Logo Source',
      back: () => {
        this.props.canvas.discardActiveObject();
        this.setState({
          mediaFolder: null,
          mediaParent: null,
        });
      },
    };
  }

  getPickerCutout() {
    return {
      content: (
        <React.Fragment>
          <List>
            {
              this.state.forcePick ?
                <ListItem
                  button
                  onClick={() => { this.setState({ forcePick: false }); }}
                >
                  <ListItemAvatar>
                    <Avatar src={
                      this.props.active.getSrc ?
                        this.props.active.getSrc() :
                        this.props.active.image.getSrc()
                    }
                    />
                  </ListItemAvatar>
                  <ListItemText primary="Edit Existing" />
                </ListItem> :
                null
            }
            { this.getMediaButtons() }
          </List>
        </React.Fragment>
      ),
      title: 'Cutout Source',
      back: () => {
        this.props.canvas.discardActiveObject();
        this.setState({
          mediaFolder: null,
          mediaParent: null,
        });
      },
    };
  }

  createNewLogo = () => {
    const { canvas, fabric } = this.props;

    // Create a new logo box to the canvas
    const newLogo = new fabric.LogoBox([
      { x: 100, y: 100 }, { x: 200, y: 100 }, { x: 200, y: 200 }, { x: 100, y: 200 }], {
      forceHide: true,
      name: 'Custom Logo',
      hasControls: false,
      hasBorders: false,
    });

    // Add logobox to canvas and set active
    canvas.add(newLogo);
    canvas.setActiveObject(newLogo);
    canvas.requestRenderAll();
  }

  createNewCutout = () => {
    const { canvas, fabric } = this.props;
    const { width, height } = canvas.workingArea;

    // Create a new cutout box to the canvas
    const newCutout = new fabric.CutoutBox([
      {
        x: width / 3,
        y: height,
      },
      {
        x: (width / 3) * 2,
        y: height,
      },
      {
        x: (width / 3) * 2,
        y: height / 3,
      },
      {
        x: width / 3,
        y: height / 3,
      },
    ], {
      forceHide: true,
      name: 'Custom Cutout',
      hasControls: false,
      hasBorders: false,
    });

    // Add cutout box to canvas and set active
    canvas.add(newCutout);
    canvas.setActiveObject(newCutout);
    canvas.requestRenderAll();
  }

  render() {
    const { classes, active, canvas } = this.props;
    const { forcePickLogo, forcePickCutout, page } = this.state;
    let content = null;
    let title = 'Graphics';
    let back = null;

    if (active) {
      if (
        (active.type === 'logoBox' && !active.image)
        || forcePickLogo
      ) {
        switch (page) {
          case 'picker': {
            let picker = this.getPickerLogo();
            content = picker.content;
            title = picker.title;
            back = picker.back;
            break;
          }
          case 'media': {
            let media = this.getMediaLogo('LOGO');
            content = media.content;
            title = media.title;
            back = media.back;
            break;
          }
          case 'stock': {
            let media = this.getMediaLogo('STOCK');
            content = media.content;
            title = media.title;
            back = media.back;
            break;
          }
          default: {
            break;
          }
        }
      } else if (
        (active.type === 'cutoutBox' && !active.image)
        || forcePickCutout
      ) {
        switch (page) {
          case 'picker': {
            let picker = this.getPickerCutout();
            content = picker.content;
            title = picker.title;
            back = picker.back;
            break;
          }
          case 'media': {
            let media = this.getMediaCutout();
            content = media.content;
            title = media.title;
            back = media.back;
            break;
          }
          default: {
            break;
          }
        }
      } else {
        let editor = this.getEditor();

        if (editor) {
          content = editor.content;
          title = editor.title;
          back = editor.back;
        }
      }

      return (
        <>
          <Crumbs
            title={title}
            onBack={back}
          />
          {content}
        </>
      );
    }

    const outputLogo = [];
    const outputwidgetsLogo = [];
    const boxesLogo = canvas ? canvas.getObjects('logoBox') : [];
    const outputCutOuts = [];
    const boxesCutOuts = canvas ? canvas.getObjects('cutoutBox') : [];

    boxesLogo.forEach((box, index) => {
      let avatar = (
        <ListItemAvatar>
          <Avatar>
            <ImageMultiple/>
          </Avatar>
        </ListItemAvatar>
      );

      if (box.media && box.media.type === 'image' && box.media.element && box.media.element.getSrc) {
        avatar = (
          <ListItemAvatar>
            <Avatar src={box.media.element.getSrc()}/>
          </ListItemAvatar>
        );
      }

      if (MetaData.hasMetaData(box, 'widget_index')) {
        outputwidgetsLogo.push((
          <ListItem key={`logobox${index}`} button onClick={this.setActive(this, box)}>
            {avatar}
            <ListItemText primary={Object.getNiceName(box, index)}/>
          </ListItem>
        ));
      } else {
        outputLogo.push((
          <ListItem key={`logobox${index}`} button onClick={this.setActive(this, box)}>
            {avatar}
            <ListItemText primary={box.name ? box.name : `Logo ${index + 1}`}/>
          </ListItem>
        ));
      }
    });

    boxesCutOuts.forEach((box, index) => {
      let avatar = (
        <ListItemAvatar>
          <Avatar>
            <ImageMultiple />
          </Avatar>
        </ListItemAvatar>
      );
      if (box.media && box.media.type === 'image' && box.media.element && box.media.element.getSrc) {
        avatar = (
          <ListItemAvatar>
            <Avatar src={box.media.element.getSrc()}/>
          </ListItemAvatar>
        );
      }
      outputCutOuts.push((
        <ListItem key={`box${index}`} button onClick={this.setActive(this, box)}>
          {avatar}
          <ListItemText primary="CutoutBox"/>
        </ListItem>
      ));
    });

    return (
      <UserRoleProvider>
        <UserRoleConsumer>
        {(permissions) => (
          <>
            <Crumbs title="Logos"/>
            <List>
              {outputLogo}
              {outputwidgetsLogo}
            </List>
            {
              (permissions !== null) &&
              (!permissions.get('editor:lock_movement')) &&
              <div className={classes.buttonBox}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.addButton}
                  onClick={this.createNewLogo}
                >
                  Add A Logo
                </Button>
              </div>
            }
            {
              (permissions !== null) &&
              (!permissions.get('editor:lock_movement')) &&
              (outputCutOuts.length !== 0) &&
              <Crumbs
                title="Cutouts"
              />
            }
            <List>
              {outputCutOuts}
            </List>
            {
              (permissions !== null) &&
              (!permissions.get('editor:lock_movement')) &&
              <div className={classes.buttonBox} style={{ paddingBottom: 40 }}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.addButton}
                  onClick={this.createNewCutout}
                >
                  Add A Cutout
                </Button>
              </div>
            }
          </>
        )}
        </UserRoleConsumer>
      </UserRoleProvider>
    );
  }
}

export default withStyles(styles)(GraphicsTab);
