
// -------------------------------------------------------------------------- //

export function GatherCanvasUsedFonts(canvas) {
  let map = {};

  canvas.getObjects('i-text').forEach((object) => {
    const key = object.fontFamily;

    if (!(key in map)) {
      map[key] = 0;
    }

    ++map[key];
  });

  return map;
}

// -------------------------------------------------------------------------- //

export function CalcDefaultCanvasFont(canvas, map = null) {
  if (map === null) {
    map = GatherCanvasUsedFonts(canvas);
  }

  let font = null, freq;

  for (let key in map) {
    if (font === null || map[key] > freq) {
      freq = map[key];
      font = key;
    }
  }

  if (font === null) {
    font = 'Arial'; // stinky font
  }

  return font;
}

// -------------------------------------------------------------------------- //

export function GetTextPropAtSelection(text, key, mixed = undefined) {
  const main = text.get(key);

  if (mixed === undefined) {
    mixed = main;
  }

  if (text.selectionStart === text.selectionEnd) {
    return main;
  }

  const styles = text.getSelectionStyles();

  if (
    (styles.length === 0) ||
    (styles.length !== text.getSelectedText().length)
  ) {
    return main;
  }

  let style = null;

  for (let i = 0; i < styles.length; ++i) {
    if (key in styles[i]) {
      if (style !== null && style !== styles[i][key]) {
        return mixed;
      }

      style = styles[i][key];
    } else if (style !== null) {
      return mixed;
    }
  }

  if (style === null) {
    return main;
  }

  return style;
}

// -------------------------------------------------------------------------- //

export function SetTextPropAtSelection(text, key, value) {
  const length = text.text.length;
  const start = text.selectionStart;
  const end = text.selectionEnd;

  if (
    (start === end) ||
    (start === 0 && end === length)
  ) {
    if (text.type !== 'curvedText') text.removeStyle(key);
    text.set(key, value);
  } else {
    text.setSelectionStyles({ [key]: value });
    text.cleanStyle(key);
  }
}

// -------------------------------------------------------------------------- //
