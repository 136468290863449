import Animation from './Animation';

class EmptyAnimation extends Animation {

  constructor() {
    super();
    this.setOption('duration', 2);
  }

	apply() { }

}

export default EmptyAnimation;
