// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Grid,
  Typography,
  withStyles,
  IconButton,
} from '@material-ui/core';

import classNames from 'classnames';

import { SHAPES } from '../dict/shapes';

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  button: {
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    fill: theme.palette.primary.main,
    transition: theme.transitions.create('box-shadow'),
    '&:focus': {
      boxShadow: `0 0 0 4px ${theme.palette.primary.light}`,
    },
    '&:hover': {
      boxShadow: theme.shadows[3],
    },
  },
  buttonIcon: {
    backgroundColor: 'inherit',
    color: theme.palette.primary.main,
    width: '90%',
    height: 'auto',
    margin: '5%',
    '& svg': {
      '&:nth-child(2)': {
        fill: 'red'
      }
    },
  },
  caption: {
    display: 'block',
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  grid_container: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  grid_item: {
    backgroundColor: 'inherit',
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
    transition: theme.transitions.create([ 'background-color', 'color' ]),
  },
  image: {
    borderRadius: theme.shape.borderRadius,
    height: theme.spacing(10),
    objectFit: 'cover',
    width: '100%',
  },
  loading: {
    margin: theme.spacing(2),
    textAlign: 'center',
  },
  root: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
});

// -------------------------------------------------------------------------- //

function Component(props) {
  const { classes, canvas, fabric } = props;

  return (
    <div className={classes.root}>
      <Grid
        container
        columns={3}
        spacing={1}
        className={classes.grid_container}
      >
        {Object.keys(SHAPES).map((key) => {
          const Icon = SHAPES[key].icon;

          return (
            <Grid
              item xs={4} key={key}
              className={classNames( classes.grid_item )}
            >
              <IconButton
                aria-label={SHAPES[key].name} key={SHAPES[key].name}
                className={classes.button}
                onClick={() => SHAPES[key].adder({
                  canvas, fabric, colors: canvas.colors
                })}
              >
                <Icon className={classes.buttonIcon}/>
              </IconButton>
              <Typography
                variant="caption"
                color="inherit"
                className={classes.caption}
              >
                {SHAPES[key].name}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

// -------------------------------------------------------------------------- //

export const ElementsShapes = withStyles(STYLES)(Component);

// -------------------------------------------------------------------------- //
