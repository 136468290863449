import React, { Component } from 'react';
import { graphql, compose, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ScoreshotsPut from '../../scoreshotsapi/Put';

const ADD_GRAPHIC = gql`
	mutation {
		addGraphic {
			id
		}
	}
`;

const DELETE_GRAPHIC = gql`
	mutation ($id: ID!) {
		removeGraphic(id: $id) {
			id
		}
	}
`;

const UPDATE_GRAPHIC = gql`
	mutation (
		$id: ID!
		$name: String
		$image: String
	) {
		updateGraphic(
			id: $id
			name: $name
			image: $image) {
				id
		}
	}
`;

class NewGraphicDialog extends Component {
	constructor() {
		super();

		this.state = {
			newGraphicName: "New Graphic",
			newGraphicID: null,
			newGraphicImageURL: null
		};

		this.action = new ScoreshotsPut("ss3-assets");
	}

	closeNewGraphicDialog = () => {
		this.setState({
			newGraphicName: "New Graphic",
			newGraphicID: null,
			newGraphicImageURL: null
		});

		if (this.props.close) {
			this.props.close();
		}
	}

	cancelNewGraphicDialog = () => {
		if (this.state.newGraphicID) {
			this.props.deleteGraphic({
				id: this.state.newGraphicID
			});
		}

		if (this.state.newGraphicImageURL) {
			/* TODO: Delete object. */
			this.closeNewGraphicDialog();
		} else {
			this.closeNewGraphicDialog();
		}
	}

	updateGraphicName = (e) => {
		this.setState({
			newGraphicName: e.target.value
		});
	}

	addGraphic = (func) => {
		if (!this.state.newGraphicID) {
			this.props.addGraphic({
				data: { name: this.state.newGraphicName }
			}).then(({data}) => {
				if (data && data.addGraphic) {
					this.setState({
						newGraphicID: data.addGraphic.id
					});

					func(true, data.addGraphic.id);
				} else {
					func(false);
				}
			});
		} else {
			func(true, this.state.newGraphicID);
		}
	}

	uploadGraphic = (e) => {
		let file = e.target.files[0];
		this.addGraphic((success, newGraphicID) => {
			if (success) {
				const params = {
					Body: file,
					Key: `graphics/${newGraphicID}.png`,
					ACL: 'public-read'
				};

				this.action.put(params.Body, "image/png", () => {
					const image = `https://ss3-assets.s3.us-east-2.amazonaws.com/${params.Key}`
					this.props.updateGraphic({
						variables: { id: newGraphicID, image }
					});
				}, params.Key, "");
			}
		});
	}

	createGraphic = () => {
		this.addGraphic((success, newGraphicID) => {
			if (success) {
				this.props.updateGraphic({
					variables: { id: newGraphicID, name: this.state.newGraphicName }
				});
			}

			this.closeNewGraphicDialog();
		});
	}

	render() {
		return (
			<Dialog
				open={this.props.open}
				onClose={this.closeNewGraphicDialog}>
				<Mutation mutation={ADD_GRAPHIC}>
					{
						(addGraphic) => (
							<React.Fragment>
								<DialogTitle>New Graphic</DialogTitle>
								<DialogContent>
									<TextField
										autoFocus
										margin="dense"
										label="Name"
										type="text"
										fullWidth
										onChange={this.updateGraphicName}
										value={this.state.newGraphicName} />
			            <input
			              accept="image/png"
			              style={{display:'none'}}
			              id="upload-graphic"
			              type="file"
			              name="file"
			              onChange={this.uploadGraphic}
			            />
	                <label htmlFor="upload-graphic">
	                  <Button component="span" size="small">
	                    Upload Graphic
	                  </Button>
	                </label>
	                <DialogActions>
		                <Button onClick={this.closeNewGraphicDialog} color="primary">
		                  Cancel
		                </Button>
		                <Button onClick={this.createGraphic} color="primary">
		                  Create
		                </Button>
		               </DialogActions>
								</DialogContent>
							</React.Fragment>
						)
					}
				</Mutation>
			</Dialog>
		);
	}
}

export default compose(
	graphql(ADD_GRAPHIC, { name: 'addGraphic' }),
	graphql(UPDATE_GRAPHIC, { name: 'updateGraphic' }),
	graphql(DELETE_GRAPHIC, { name: 'deleteGraphic' })
)(NewGraphicDialog);
