import React, { Component } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Editor from './Editor';

const GET_DRAFT_INFO = gql`
  query draft($id: ID!){
    draft(
      id: $id
    ) {
      id
      width
      height
      bucket
      template {
        id
        isQuickCreate
        disableXMLImport
        categories {
          id
          name
        }
        types {
          id
          name
        }
        types {
          name
        }
      }
    }
  }
`;

class DraftLoader extends Component {
  render() {
    return (
      <Query
        query={GET_DRAFT_INFO}
        variables={{
          id: this.props.match.params.id,
        }}
      >
        {({ data }) => (
          (data && data.draft) ?
            <Editor
              draft={data.draft}
              match={this.props.match}
              quickCreate={data.draft.template ? data.draft.template.isQuickCreate : false}
            /> :
            <div />
        )}
      </Query>
    );
  }
}

export default DraftLoader;
