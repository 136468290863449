
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  Button,
  DialogActions,
  Typography,
} from '@material-ui/core';

import {
  ChevronDoubleRight,
} from 'mdi-material-ui';

import { Link } from 'react-router-dom';

// -------------------------------------------------------------------------- //

export const STEPS = [
  { // 0
    goTo: '/',
    content: ({ goTo, step }) => (
      <>
        <Typography variant="h6" gutterBottom color="primary">
          Welcome to ScoreShots!
        </Typography>
        <Typography gutterBottom>
          Create <strong>custom, branded</strong> content for your sports teams in just minutes with our <strong>pre-designed templates</strong>. To get started, take a quick tour of our platform to learn how to maximize your ScoreShots account.
        </Typography>
        <DialogActions>
          <Button onClick={() => goTo(step)}>
            Fill out team info
            <ChevronDoubleRight color="inherit"/>
          </Button>
        </DialogActions>
      </>
    ),
  },
  { // 2
    selector: '[data-tour="step-2"]',
    stepInteraction: true,
    position: 'left',
    goTo: '/team',
    content: ({ goTo, step }) => (
      <>
        <Typography variant="h6" gutterBottom color="primary">
          Build Your Brand
        </Typography>
        <Typography gutterBottom>
          Upload a png of your organization’s <strong>logo</strong> and enter your <strong>team name</strong> so that every template will come <strong>branded for you</strong>.
        </Typography>
        <Typography gutterBottom>
          Click <strong>“Save” and click "Next"</strong> to add your team colors.
        </Typography>
        <DialogActions>
          <Button onClick={() => goTo(step)}>
            Next
            <ChevronDoubleRight color="inherit"/>
          </Button>
        </DialogActions>
      </>
    ),
  },
  { // 3
    selector: '[data-tour="step-3"]',
    position: 'left',
    content: ({ goTo, step }) => (
      <>
        <Typography variant="h6" gutterBottom color="primary">
          Team Colors
        </Typography>
        <Typography gutterBottom>
          Enter the <strong>hex codes</strong> of your team colors so that they auto-populate into every template, just like your logo.
        </Typography>
        <Typography gutterBottom>
          When done click <strong>"Next"</strong> to head to the Homepage to create your graphic.
        </Typography>
        <DialogActions>
          <Button onClick={() => goTo(step)}>
            Next
            <ChevronDoubleRight color="inherit"/>
          </Button>
        </DialogActions>
      </>
    ),
  },
  { // 4
    selector: '[data-tour="step-4"]',
    goTo: '/',
    content: ({ goTo, step }) => (
      <>
        <Typography variant="h6" gutterBottom color="primary">
          Create A Post
        </Typography>
        <Typography gutterBottom>
          <strong>Time to get creative!</strong> Choose from our extensive template library, <strong>customize</strong> your selected template, and <strong>share</strong> it in just minutes.
        </Typography>
        <Typography gutterBottom>
          Click <strong>“Create a Post”</strong> to head to our template library.
        </Typography>
      </>
    ),
  },
  { // 5
    stepInteraction: false,
    selector: '[data-tour="step-5"]',
    goTo: '/create/new',
    content: ({ goTo, step }) => (
      <>
        <Typography variant="h6" gutterBottom color="primary">
          Filter and Sort
        </Typography>
        <Typography gutterBottom>
          With so many options to choose from, it's easy to feel a bit overwhelmed in all that we have to offer! This is where you can <strong>apply filters</strong> to find exactly what you’re looking for. You can search by <strong>shape</strong>, <strong>sport</strong>, or chronologically through a game.
        </Typography>
        <Typography gutterBottom>
          For now, click <strong>"Next."</strong>
        </Typography>
        <DialogActions>
          <Button onClick={() => goTo(step)}>
            Next
            <ChevronDoubleRight color="inherit"/>
          </Button>
        </DialogActions>
      </>
    ),
  },
  { // 6
    stepInteraction: false,
    selector: '[data-tour="step-6"]',
    content: ({ goTo, step }) => (
      <>
        <Typography variant="h6" gutterBottom color="primary">
          Select Template
        </Typography>
        <Typography gutterBottom>
          Customizing our <strong>pre-designed templates</strong> is as easy as 1, 2, 3. With your <strong>team name</strong>, <strong>logo</strong>, and <strong>colors</strong> already entered in your <strong>Team page</strong>, find out how fast it is to create your content.
        </Typography>
        <Typography gutterBottom>
          <strong>Click "Go to Editor."</strong>
        </Typography>
        <DialogActions>
          <Button onClick={() => goTo(step)}>
            Go to Editor
            <ChevronDoubleRight color="inherit"/>
          </Button>
        </DialogActions>
      </>
    ),
  },
  { // 7
    selector: '[data-tour="editor"]',
    goTo: '/editor/template/ck2q9ktz1mejn0a94ecah6knb',
    forceStep: 'background',
    position: [350, 70],

    condition: ({ canvas }) => {
      const boxes = canvas.getObjects('backgroundBox');

      if (boxes.length === 0) {
        return;
      }

      return boxes.every((box) => (box.media && box.media.element));
    },
    content: ({ goTo, step }) => (
      <>
        <Typography variant="h6" gutterBottom color="primary">
          Add a Background
        </Typography>
        <Typography gutterBottom>
          Choose a photo from your <strong>media library</strong>, upload one right from the <strong>device</strong> you’re working on, or select one from the <strong>stock library</strong>. Edit your photo using filters and other adjustments.
        </Typography>
        <Typography gutterBottom>
          Go ahead and add in an image from the Stock Library then click <strong>"Next"</strong> when you are ready to move on.
        </Typography>
        <DialogActions>
          <Button onClick={() => goTo(step+1)}>
            Next
            <ChevronDoubleRight color="inherit"/>
          </Button>
        </DialogActions>
      </>
    ),
  },
  { // 8
    selector: '[data-tour="editor"]',
    forceStep: 'background',
    position: [350, 70],

    content: ({ goTo, step }) => (
      <>
        <Typography variant="h6" gutterBottom color="primary">
          Add a Background
        </Typography>
        <Typography gutterBottom>
          Choose a photo from your <strong>media library</strong>, upload one right from the <strong>device</strong> you’re working on, or select one from the <strong>stock library</strong>. Edit your photo using filters and other adjustments.
        </Typography>
        <Typography gutterBottom>
          Go ahead and add in an image from the Stock Library then click <strong>"Next"</strong> when you are ready to move on.
        </Typography>
        <DialogActions>
          <Button onClick={() => goTo(step)}>
            Next
            <ChevronDoubleRight color="inherit"/>
          </Button>
        </DialogActions>
      </>
    ),
  },
  { // 9
    selector: '[data-tour="editor"]',
    forceStep: 'graphicstab',
    position: [350, 70],

    content: ({ goTo, step }) => (
      <>
        <Typography variant="h6" gutterBottom color="primary">
          Choosing Your Graphics
        </Typography>
        <Typography gutterBottom>
          If you saved your logo in the <strong>Team Tab</strong>, it will automatically load into every template. All you have to do is select your <strong>opponent</strong> and <strong>sponsor logos</strong> from our stock library or upload them from your device.
        </Typography>
        <Typography gutterBottom>
          After adding the remaining logos, click <strong>"Next."</strong>
        </Typography>
        <DialogActions>
          <Button onClick={() => goTo(step)}>
            Next
            <ChevronDoubleRight color="inherit"/>
          </Button>
        </DialogActions>
      </>
    ),
  },
  { // 10
    selector: '[data-tour="editor"]',
    forceStep: 'text',
    position: [350, 290],

    content: ({ goTo, step }) => (
      <>
        <Typography variant="h6" gutterBottom color="primary">
          Customize Your Text
        </Typography>
        <Typography gutterBottom>
          Select the <strong>text box</strong> you’d like to edit and type right onto the canvas. Use the <strong>editing panel</strong> on the left to change your <strong>font</strong>, <strong>color</strong>, <strong>size</strong>, and add <strong>extra customization</strong>!
        </Typography>
        <Typography gutterBottom>
          Once you’ve customized your text, click <strong>"Next"</strong> to see some additional features.
        </Typography>
        <DialogActions>
          <Button onClick={() => goTo(step)}>
            Next
            <ChevronDoubleRight color="inherit"/>
          </Button>
        </DialogActions>
      </>
    ),
  },
  { // 11
    selector: '[data-tour="more-tab"]',
    content: ({ goTo, step }) => (
      <>
        <Typography variant="h6" gutterBottom color="primary">
          Explore More
        </Typography>
        <Typography gutterBottom>
          Click the <strong>"More"</strong> tab. The More tab gives you access to some additional features like <strong>textures</strong>, <strong>motion effects</strong>, and <strong>shapes</strong>.
        </Typography>
        <Typography gutterBottom>
          This optional step will give you the ability to add some extra elements to your graphic.
        </Typography>
        <DialogActions>
          <Button onClick={() => goTo(step)}>
            Next
            <ChevronDoubleRight color="inherit"/>
          </Button>
        </DialogActions>
      </>
    ),
  },
  { // 12
    selector: '[data-tour="share"]',
    content: () => (
      <>
        <Typography variant="h6" gutterBottom color="primary">
          Ready to Post
        </Typography>
        <Typography gutterBottom>
          Click the <strong>"Share"</strong> button to <strong>preview</strong> your finished <strong>graphic</strong>.
        </Typography>
      </>
    ),
  },
  { // 13
    selector: '[data-tour="download"]',
    position: [10, 10],
    content: ({ goTo, step }) => (
      <>
        <Typography variant="h6" gutterBottom color="primary">
          All Done?
        </Typography>
        <Typography gutterBottom>
          <strong>Download it</strong>, send it to a <strong>phone</strong>, or post right to your <strong>social
          accounts</strong> from ScoreShots! Add captions and even <strong>schedule
          posts</strong> to go out at a later date and time.
        </Typography>
        <DialogActions>
          <Button onClick={() => goTo(step)}>
            Next
            <ChevronDoubleRight color="inherit"/>
          </Button>
        </DialogActions>
      </>
    ),
  },
  { // 14
    content: () => (
      <>
        <Typography variant="h6" gutterBottom color="primary">
          Thank You!
        </Typography>
        <Typography gutterBottom>
          Let's finish setting up your <strong>Team Page</strong> and enter your <strong>social accounts</strong> so that you can start sharing your graphics! In the future you can find this tour by clicking on your initials in the <strong>top right</strong>.
        </Typography>
        <DialogActions>
          <Button component={Link} to="/team?tourcomplete=1">
            Finish
            <ChevronDoubleRight color="inherit"/>
          </Button>
        </DialogActions>
      </>
    ),
  },
];

// -------------------------------------------------------------------------- //

export const STEPS_MOBILE = [
  {
    content: (
      <>
        <Typography variant="h6" gutterBottom>
          Welcome to ScoreShots Next!
        </Typography>
        <Typography gutterBottom>
          {`
            Thanks for helping us beta test the new version of ScoreShots! You
            may run into some rough edges as we prepare for an official release,
            so pardon our mess.
          `}
        </Typography>
        <Typography gutterBottom>
          {`
            We're hard at work loading our full template library into the engine, but until then you may notice a lack of templates for your sport.
          `}
        </Typography>
        <Typography>
          {`
            Now, let’s take a quick tour to help you get your bearings. A lot has changed!
          `}
        </Typography>
      </>
    ),
  },
  {

    content: (
      <>
        <Typography variant="h6" gutterBottom>
          Support and Feedback
        </Typography>
        <Typography>
          {`
            Click here at any time to contact us with questions or feedback. During this early beta period your impressions are extremely important.
          `}
        </Typography>
      </>
    ),
    position: 'bottom',
  },
  {

    content: (
      <>
        <Typography variant="h6" gutterBottom>
          Navigation
        </Typography>
        <Typography>
          {`
            Next is split into 4 main sections. Lets go into them in detail.
          `}
        </Typography>
      </>
    ),
    position: 'bottom',
  },
  {

    goTo: '/',
    content: (
      <>
        <Typography variant="h6" gutterBottom>
          Home
        </Typography>
        <Typography>
          {`
            Your home page gives you a general overview of your account. From this view you can start a new graphic, see the latest news, and get a quick overview of how your graphics are performing.
          `}
        </Typography>
      </>
    ),
  },
  {

    content: (
      <>
        <Typography variant="h6" gutterBottom>
          Latest News
        </Typography>
        <Typography>
          {`
            We’ve made a centralized hub where you can see all of our recent updates, including patch notes and new template releases. Stay tuned here to see what’s new!
          `}
        </Typography>
      </>
    ),
  },
  {

    content: (
      <>
        <Typography variant="h6" gutterBottom>
          Social Media Metrics
        </Typography>
        <Typography gutterBottom>
          {`
            Soon, you’ll be able to view advanced metrics for your social media posts here. We’re still working out the details with Facebook and Twitter, but it won’t be long!
          `}
        </Typography>
        <Typography>
          {`
            We’ll be going a bit more in-depth with them this time, to help you demonstrate your brand’s value to potential sponsors.
          `}
        </Typography>
      </>
    ),
  },
  {

    goTo: '/create',
    content: (
      <>
        <Typography variant="h6" gutterBottom>
          Create
        </Typography>
        <Typography>
          {`
            Where the magic happens! Here you can view your published graphics, as well as create a new graphic, and organize your custom templates.
          `}
        </Typography>
      </>
    ),
  },
  {

    goTo: '/create/posts',
    content: (
      <>
        <Typography variant="h6" gutterBottom>
          Published Posts
        </Typography>
        <Typography>
          {`
            Here’s your new Published Post Feed! You’ll be able to see who posted what and when, and there are lots of new tools built in here.
          `}
        </Typography>
        <Typography>
          {`
            Once you’ve created your first post, you’ll be able to expand it here to see exactly what pages it was sent to! It’s now possible to create from a copy of a post you’ve already published, too.
          `}
        </Typography>
      </>
    ),
  },
  {

    goTo: '/create/drafts',
    content: (
      <>
        <Typography variant="h6" gutterBottom>
          Drafts
        </Typography>
        <Typography>
          {`
            We thought that drafts were getting a little buried. Some folks missed the buttons entirely! So we’ve broken them out into their own menu.
          `}
        </Typography>
        <Typography>
          {`
            They’ll automatically delete themselves after 30 days, or after being published. This will help keep your drafts clean so you can always find what you wanted to work on.
          `}
        </Typography>
      </>
    ),
  },
  {

    goTo: '/media',
    content: (
      <>
        <Typography variant="h6" gutterBottom>
          Media
        </Typography>
        <Typography>
          {`
            Your Media Library lets you store all your teams assets. Photos, cutouts, documents, and more can be stored, organized and dowloaded here, and used instantly in graphics.
          `}
        </Typography>
        <Typography>
          {`
              Psst! You can now download your files from the Media Library at any time.
          `}
        </Typography>
      </>
    ),
  },
  {

    goTo: '/team',
    content: (
      <>
        <Typography variant="h6" gutterBottom>
          Team
        </Typography>
        <Typography>
          {`
            All of your team settings, including team colors, users, fonts and more.
          `}
        </Typography>
      </>
    ),
  },
  {

    content: (
      <>
        <Typography variant="h6" gutterBottom>
          Team Colors
        </Typography>
        <Typography>
          {`
            We’ve expanded the custom palette section! Your team colors will still be automatically filled into templates when possible, but now the theme of ScoreShots will change to match, too!
          `}
        </Typography>
        <Typography>
          {`
            You can also store an unlimited number of color shortcuts, without interrupting your team color settings. Thanks for the suggestion!
          `}
        </Typography>
      </>
    ),
  },
  {

    goTo: '/home',
    content: (
      <>
        <Typography variant="h6" gutterBottom>
          Create a Graphic
        </Typography>
        <Typography>
          {`
            That's all we have to show you right now. When you're ready, click here and start creating! We’ve built a handy new Template Wizard to help you find exactly what you’re looking for. Or, you can jump right into template selection. You can even start from a blank template!
          `}
        </Typography>
        <Typography>
          {`
            In the future you can find this tour by clicking on your initials in the top right.
          `}
        </Typography>
      </>
    ),
  },
];

// -------------------------------------------------------------------------- //
