import React, { Component } from 'react';
import {compose, graphql, Query} from 'react-apollo';
import gql from 'graphql-tag';
import { withStyles } from '@material-ui/core/styles';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import {withRouter} from "react-router-dom";

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    marginTop: 0,
    width: `calc( 50% - ${theme.spacing(2)}px )`,
  },
});

const ROLES_QUERY = gql`
  query {
    me {
      id
      role {
        id
        name
        restrictCategoryFlag
        categories {
          id
          name
        }
        allowCustomFlag
        allowCustomSaveFlag
        allowCustomFolderSaveFlag
        restrictCustomFlag
        customFolders {
          id
          name
        }
      }
    }
  }
`;

const GET_TEMPLATES = gql`
  query {
    types{
      id
      name
    }

    categories{
      id
      name
    }
  }
`;

class DropDowns extends Component {
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };


  render() {
    const { classes, category, types, rolesQuery } = this.props;

    return (
      <Query query={GET_TEMPLATES}>
        {({ data }) => {
          const allowedCategories = [];
          const { me } = rolesQuery;
          let restrictCategoryFlag = false;
          let showTemplate = false;
          if (me && me.role && me.role.categories) {
            me.role.categories
              .forEach((cat) => {
                allowedCategories.push(cat.id);
              });
            restrictCategoryFlag = me.role.restrictCategoryFlag;
          }
          const dropDowns = (
            <React.Fragment>
              <FormControl className={classes.formControl}>

                <InputLabel htmlFor="category">
                  Category
                </InputLabel>

                <Select
                  value={category}
                  onChange={this.props.handleChange}
                  input={
                    <Input
                      classes={{
                      }}
                      name="category"
                      id="category"
                    />
                  }
                  autoWidth
                  name="category"
                >
                  <MenuItem key={-1} value={-1}>All Categories</MenuItem>
                  {
                    data.categories ? data.categories.map((category, index) => {
                      if (restrictCategoryFlag) {
                        showTemplate = false;
                        if (allowedCategories.includes(category.id)) {
                          showTemplate = true;
                        }
                        if (showTemplate) {
                          return (<MenuItem key={`MenuItem${index}`} value={category.id}>{category.name}</MenuItem>);
                        }
                        return null;
                      }
                      return (<MenuItem key={`MenuItem${index}`} value={category.id}>{category.name}</MenuItem>);
                    }) : null
                  }
                </Select>

              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="type">Types</InputLabel>
                <Select
                  multiple
                  value={types}
                  onChange={this.props.handleChange}
                  input={<Input name="types" id="type" value={types} />}
                  autoWidth
                  name="types"
                >
                  <MenuItem key={-1} value={-1}>All Types</MenuItem>
                  {
                    data.types ? data.types.map((type, index) => (<MenuItem key={`MenuItemTpes${index}`} value={type.id}>{type.name}</MenuItem>)) : null
                  }
                </Select>
              </FormControl>
            </React.Fragment>
          );

          return (
            <div className={classes.templates}>
              {dropDowns}
            </div>
          );
        }}
      </Query>

    );
  }
}
/**
 Props Required
 category sports category
 types   array of types
 handleChange function

 * */

export default compose(
  graphql(ROLES_QUERY, { name: 'rolesQuery' }),
  withRouter,
  withStyles(styles),
)(DropDowns);
