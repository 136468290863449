
// -------------------------------------------------------------------------- //

import React from 'react';

// -------------------------------------------------------------------------- //

export class Once extends React.Component {

  state = { spent: false }

  componentDidUpdate(old_props) {
    if (!this.state.spent && !old_props.if && this.props.if) {
      this.setState({ spent: true }, () => {
        this.props.do();
      });
    }
  }

  render() {
    return null;
  }

};

// -------------------------------------------------------------------------- //
