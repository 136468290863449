// -------------------------------------------------------------------------- //

// const ANIM_ENDPOINT = 'http://172.17.0.2:5000/api/v1';
const ANIM_ENDPOINT = `${process.env.REACT_APP_SS_ANIMATION_ENDPOINT}api/v1`;
const S3_ENDPOINT = `${process.env.REACT_APP_SS_API_ENDPOINT}v1/S3`;
const TWILIO_ENDPOINT = `${process.env.REACT_APP_SS_API_ENDPOINT}v1/Twilio`;
const REMOVEBG_ENDPOINT = `${process.env.REACT_APP_SS_API_ENDPOINT}v1/RemoveBG`;

// -------------------------------------------------------------------------- //

function getS3Token() {
  return localStorage.getItem('s3_token');
}

// -------------------------------------------------------------------------- //

export function ToDataURL(blob) {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = () => {
      reject(new Error());
    }

    reader.readAsDataURL(blob);
  });
}

// -------------------------------------------------------------------------- //

export function S3Download(file, type) {
  const request = {
    token: getS3Token(),
    file,
    type,
  };

  return fetch(`${S3_ENDPOINT}/download-blob/dev`, {
    mode: 'cors',
    method: 'POST',
    body: JSON.stringify({ body: request }),
  });
}

export function S3GenerateDownloadLink(file, type, name) {
  const request = {
    token: getS3Token(),
    file,
    type,
    name
  };

  return fetch(`${S3_ENDPOINT}/generate-download-blob-link/dev`, {
    mode: 'cors',
    method: 'POST',
    body: JSON.stringify({ body: request }),
  });
}

// -------------------------------------------------------------------------- //

export function S3Put(blob, type, bucket, filename, folder = null) {
  const request = {
    token: getS3Token(),
    object: filename,
    bucket,
    type,
  };

  if (folder) {
    request.folder = folder;
  }

  return (
    ToDataURL(blob)
    .then(data => {
      request.file = data;
      return fetch(`${S3_ENDPOINT}/put-object/dev`, {
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify({ body: request }),
      });
    })
    .then(response => response.json())
    .then(json => new Promise((resolve, reject) => {
      if (json.ok) {
        resolve(json);
      } else {
        reject(new Error(json));
      }
    }))
  );
}

// -------------------------------------------------------------------------- //

export function PollRender(url, options = {}) {
  const callback = (options.progress || null);
  const period = (options.period || 5000);
  return new Promise((resolve, reject) => {
    const handle = setInterval(() => {
      fetch(`${ANIM_ENDPOINT}/status`, {
        method: 'POST',
        body: JSON.stringify({ id: url }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          (callback && callback(response.progress));
        } else {
          clearInterval(handle);
          fetch(url)
              .then(response => {
                if (response.status === 200) {
                  resolve();
                } else {
                  reject(new Error('Render failed'));
                }
              }).catch(error => {

            console.log(error)
            reject(error);
          });
        }
        }).catch(error => {
          console.log(error)
          clearInterval(handle);
          reject(error);
      });
    }, period);
  });
}

// -------------------------------------------------------------------------- //

export function SubmitRender(job) {
  return (
    fetch(`${ANIM_ENDPOINT}/submit`, {
      mode: 'cors',
      method: 'POST',
      body: JSON.stringify(job),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.json())
    .then(json => json.url)
  );
}

// -------------------------------------------------------------------------- //

export function SendTwilio(numbers, type, data_url) {
  const request = {
    numbers: numbers,
    attachment: {
      type: type,
      blob: data_url,
    }
  };

  return (
    fetch(`${TWILIO_ENDPOINT}/send-sms/prod`, {
      mode: 'cors',
      method: 'POST',
      body: JSON.stringify({ body: request }),
    })
      .then(response => response.json())
      .then(json => new Promise((resolve, reject) => {
        if (json.success) {
          resolve();
        } else {
          reject(new Error('failed to send to twilio'));
        }
      }))
  );
}

// -------------------------------------------------------------------------- //

export function SendRemoveBG(photo_url) {
  const request = {
    photourl: photo_url,
  };

  return (
    fetch(`${REMOVEBG_ENDPOINT}/remove-bg/prod`, {
      mode: 'cors',
      method: 'POST',
      body: JSON.stringify({ body: request }),
    })
      .then(response => response.json())
      .then(json => new Promise((resolve, reject) => {
        if (json.success) {
          resolve();
        } else {
          reject(new Error('failed to send to api server'));
        }
      }))
  );
}

// -------------------------------------------------------------------------- //
