
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  BottomNavigation,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  Typography,
  withStyles,
  BottomNavigationAction,
} from '@material-ui/core';

import { isMobileOnly } from 'react-device-detect';
import classNames from 'classnames';

import TABS from './dict';

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  drawerPaper: {
    height: 'calc( 100vh - 64px )',
    top: 64,
    zIndex: -1,
  },
  bottom_navigation: {
    bottom: 0,
    boxShadow: theme.shadows[4],
    position: 'fixed',
    width: '100%',
    zIndex: 9999999,
  },
  buttonNav: {
    padding: 0,
  },
  button: {
    width: 80,
    height: 72,
    borderRadius: 0,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  active: {
    composes: 'button',
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  buttonLabel: {
    flexWrap: 'wrap',
  },
  buttonList: {
    paddingTop: 0,
  },
  title: {
    flexBasis: '100%',
    color: 'inherit',
  },
});

// -------------------------------------------------------------------------- //

class DrawerContainer extends React.Component {

  renderTabs = (advanced, slideshow) => {
    const { CanvasUtil, permissions, template } = this.props;

    return Object.keys(TABS).map((key) => {
      const step = TABS[key];

      const options = {
        canvasUtil: CanvasUtil,
        canvas: (CanvasUtil && CanvasUtil.canvas),
        template, advanced, slideshow,
        mobile: isMobileOnly,
        permissions,
      };

      if (step.filter && !step.filter(options)) {
        return null;
      }

      return this.renderTab(key);
    });
  }

  renderTab = (key) => {
    const { classes, currentStep, setStep } = this.props;
    const step = TABS[key];

    if (isMobileOnly) {
      return (
        <BottomNavigationAction
          key={key} value={key} label={step.name}
          icon={<step.icon/>}
          onClick={setStep(key, step.fixed)}
        />
      )
    } else {
      return (
        <React.Fragment key={key}>
          <ListItem className={classes.buttonNav} {...(step.props || {})}>
            <IconButton
              classes={{
                root: classNames(
                  classes.button,
                  { [classes.active]: (key === currentStep) },
                ),
                label: classes.buttonLabel,
              }}
              onClick={setStep(key, step.fixed)}
            >
              <step.icon/>
              <Typography variant="caption" className={classes.title}>
                {step.name}
              </Typography>
            </IconButton>
          </ListItem>
          <Divider/>
        </React.Fragment>
      );
    }
  }

  render() {
    const { classes, advanced, slideshow, currentStep, open } = this.props;

    if (isMobileOnly) {
      return (
        <BottomNavigation
          className={classes.bottom_navigation}
          value={open ? currentStep : ''}
        >
          {this.renderTabs(advanced, slideshow)}
        </BottomNavigation>
      );
    } else {
      return (
        <Drawer
          variant="persistent"
          anchor="left"
          open
          onClose={this.handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <List className={classes.buttonList} data-tour="tabs">
            {this.renderTabs(advanced, slideshow)}
          </List>
        </Drawer>
      );
    }
  }
}

// -------------------------------------------------------------------------- //

export default withStyles(STYLES)(DrawerContainer);

// -------------------------------------------------------------------------- //
