
// -------------------------------------------------------------------------- //

import React from 'react';

import {
  AppBar,
  Button,
  ButtonBase,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
  Avatar,
} from '@material-ui/core';

import {
  AccountMultiplePlus as AccountMultiplePlusIcon,
  AccountPlus as AccountPlusIcon,
  Camera as CameraIcon,
  ChevronRight as ChevronRightIcon,
  DotsVertical as DotsVerticalIcon,
  FolderImage as FolderImageIcon,
  MenuDown as MenuDownIcon,
  ChevronUp as ChevronUpIcon,
  ChevronDown as ChevronDownIcon,
} from 'mdi-material-ui';

import gql from 'graphql-tag';
import { compose, graphql, Query } from 'react-apollo';
import { isMobileOnly } from 'react-device-detect';
import { Route, Switch as RouteSwitch } from 'react-router-dom';

import { withRouter } from 'react-router';
import { MEDIA_TYPES } from './mediatypes';
import { MediaNav } from './MediaNav';
import dict from './dict';
import ResponsiveGrid from '../layout/ResponsiveGrid';
import SidebarGrid from '../layout/SidebarGrid';
import ScoreshotsPut from '../../scoreshotsapi/Put';

// -------------------------------------------------------------------------- //

function BlobToDataURL(blob) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();

    try {
      fr.onload = (e) => { resolve(e.target.result); };
      fr.readAsDataURL(blob);
    } catch (error) {
      reject(error);
    }
  });
}

// -------------------------------------------------------------------------- //

const ROUTE_TYPE = 'ROSTER';

// -------------------------------------------------------------------------- //

// the transform prop here is to fix a known chrome issue
// see mui-org/material-ui/commit/9b9421c for details
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MENU_PROPS = {
  PaperProps: {
    style: {
      maxHeight: (ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP),
      transform: 'translate3D(0,0,0)',
    },
  },
};

// -------------------------------------------------------------------------- //

const GQL_GET_ROSTERS = gql`
  query {
    rosterlist {
      id
      name
      category
    }
  }
`;

const GET_CATEGORIES = gql`
  query {
    categories {
      id
      name
    }
  }
`;

// -------------------------------------------------------------------------- //

const GQL_GET_ROSTER_MEMBERS = gql`
  query($id: ID) {
    getRoster(rosterId: $id) {
      id
      name
      category
      members {
        id
        name
        firstname
        shortname
        number
        position
        year
        height
        weight
        avatar
        starter
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const GQL_GET_ROSTER = gql`
  query getRoster(
    $rosterId: ID
  ) {
    getRoster(
      rosterId: $rosterId
    ) {
      name
      category
      members {
        id
        avatar
        name
        firstname
        shortname
        number
        position
        year
        height
        weight
        starter
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const GQL_GET_ROSTER_MEMBER = gql`
  query($id: ID) {
    getRosterMember(id: $id) {
      id
      avatar
      name
      firstname
      shortname
      number
      position
      year
      height
      weight
      starter
    }
  }
`;

// -------------------------------------------------------------------------- //

const ADD_ROSTER = gql`
  mutation addRosterToClient(
    $name: String!,
    $category: String!
  ) {
    addRosterToClient(
      name: $name,
      category: $category
    ) {
      id
      rosters {
        id
        name
        category
    }
    }
  }
`;

// -------------------------------------------------------------------------- //

const UPDATE_ROSTER = gql`
  mutation updateRoster(
    $name: String!,
    $category: String!,
    $rosterId: ID
  ) {
    updateRoster(
      name: $name,
      category: $category,
      rosterId: $rosterId
    ) {
      id
      name
      category
    }
  }
`;

// -------------------------------------------------------------------------- //

const GQL_ADD_ROSTER_MEMBER = gql`
  mutation(
    $name: String!,
    $number: Int!,
    $avatar: String,
    $shortname: String,
    $firstname: String,
    $position: String!,
    $year: String,
    $height: String,
    $weight: String,
    $starter: Boolean,
    $rosterId: ID
  ) {
    addRosterMemberToRoster(
      name: $name,
      number: $number,
      avatar: $avatar,
      shortname: $shortname,
      firstname: $firstname,
      position: $position,
      year: $year,
      height: $height,
      weight: $weight,
      starter: $starter,
      rosterId: $rosterId
    ) {
      id
      members {
        id
        name
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const GQL_UPDATE_ROSTER_MEMBER = gql`
  mutation(
    $name: String!,
    $number: Int!,
    $avatar: String,
    $shortname: String,
    $firstname: String,
    $position: String!,
    $year: String,
    $height: String,
    $weight: String,
    $starter: Boolean,
    $id: ID
  ) {
    updateRosterMember(
      name: $name,
      number: $number,
      avatar: $avatar,
      shortname: $shortname,
      firstname: $firstname,
      position: $position,
      year: $year,
      height: $height,
      weight: $weight,
      starter: $starter,
      rosterMemberId: $id
    ) {
      id
      name
      number
      avatar
      position
      year
      height
      weight
      starter
    }
  }
`;

// -------------------------------------------------------------------------- //

const DELETE_ROSTER = gql`
  mutation deleteRosterOnClient(
    $rosterId: ID!
  ) {
    deleteRosterOnClient(
      rosterId: $rosterId
    ) {
      id
      rosters {
        id
        name
        category
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const DELETE_ROSTER_MEMBER = gql`
  mutation deleteRosterMember(
    $id: String!
  ) {
    deleteRosterMember(
      id: $id
    ) {
      id
    }
  }
`;

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  app_bar: {
    backgroundColor: theme.palette.primary.light,
    top: 64,
  },
  avatar: {
    width: '80%',
    height: '60%',
    margin: `${theme.spacing(1)}px auto`,
  },
  flex: {
    flex: 1,
  },
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(4),
  },
});

// -------------------------------------------------------------------------- //

function CreateNewRosterMember() {
  return {
    name: '',
    firstname: '',
    shortname: '',
    position: '',
    number: '',
    year: '',
    height: '',
    weight: '',
    avatar: '',
    starter: false,
  };
}

// -------------------------------------------------------------------------- //

class RosterMemberEditor_class extends React.Component {
  state = {
    roster_member: CreateNewRosterMember(),
    old_roster_member_name: null,
    roster_avatar_blob: null,
    roster_changed: false,
    loading: false,
  }

  componentDidMount() {
    const id = this.getRosterMemberID();

    if (id !== null && !this.isCreateRosterMember()) {
      this.setState({ loading: true }, () => {
        this.props.gql_get_roster_member.refetch({ id }).then((response) => {
          const roster_member = { ...response.data.getRosterMember };
          if (roster_member.avatar === 'http://placekitten.com/256/256') {
            roster_member.avatar = '';
          }
          this.setState({
            roster_member,
            old_roster_member_name: roster_member.name,
            loading: false,
          });
        });
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { roster_member } = this.state;

    const errant_full_name = ((roster_member.name || '').length === 0);
    const errant_first_name = ((roster_member.firstname || '').length === 0);
    const errant_last_name = ((roster_member.shortname || '').length === 0);

    const errant = (
      errant_full_name
      || errant_first_name
      || errant_last_name
    );

    const loading = (!this.isCreateRosterMember() && this.state.loading);

    return (
      <>
        <Paper elevation={2} className={classes.paper}>
          {
            (loading)
            && <CircularProgress size={48} />
          }
          {
            (!loading)
            && (
              <>
                <Typography gutterBottom variant="h5" component="h1">
                  {
                this.isCreateRosterMember()
                  ? 'Create Roster Member'
                  : `Editing ${this.state.old_roster_member_name}`
              }
                </Typography>
                <Grid container spacing={1}>
                  {
                  !this.isCreateRosterMember()
                  && (
                  <Grid item xs={12} sm={3} style={{ padding: 8 }}>
                    <Avatar
                      variant="square"
                      className={classes.avatar}
                      src={roster_member.avatar}
                      alt="Avatar"
                    />
                    <Button
                      fullWidth
                      color="primary"
                      variant="outlined"
                      htmlFor="image_input"
                      component="label"
                    >
                      <input
                        type="file"
                        id="image_input"
                        accept="image/jpeg,image/png"
                        style={{ display: 'none' }}
                        onChange={(e) => this.uploadImage(e.target)}
                      />
                      <CameraIcon style={{ marginRight: 8 }} />
                      Upload Image
                    </Button>
                  </Grid>
                  )
                }
                  <Grid item xs={12} sm={this.isCreateRosterMember() ? 12 : 9}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          required
                          label="Full Name"
                          value={roster_member.name}
                          error={errant_full_name}
                          onChange={(e) => this.changeRosterMember('name', e.target.value)}
                          autoFocus
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          required
                          label="First Name"
                          value={roster_member.firstname}
                          error={errant_first_name}
                          onChange={(e) => this.changeRosterMember('firstname', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          required
                          label="Last Name"
                          value={roster_member.shortname}
                          error={errant_last_name}
                          onChange={(e) => this.changeRosterMember('shortname', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={2}>
                        <TextField
                          fullWidth
                          label="Position"
                          value={roster_member.position}
                          onChange={(e) => this.changeRosterMember('position', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={2}>
                        <TextField
                          fullWidth
                          label="Number"
                          value={roster_member.number}
                          onChange={(e) => this.changeRosterMember('number', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          fullWidth
                          label="Year"
                          value={roster_member.year}
                          onChange={(e) => this.changeRosterMember('year', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={2}>
                        <TextField
                          fullWidth
                          label="Height"
                          value={roster_member.height}
                          onChange={(e) => this.changeRosterMember('height', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={2}>
                        <TextField
                          fullWidth
                          label="Weight"
                          value={roster_member.weight}
                          onChange={(e) => this.changeRosterMember('weight', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <List>
                          <ListItem
                          button={!isMobileOnly}
                          onClick={() => (
                !isMobileOnly
                            && this.changeRosterMember('starter', !roster_member.starter)
              )}
                        >
                          <Hidden only="xs">
                <ListItemIcon color="primary">
                              <Checkbox
                                edge="start"
                                color="primary"
                                checked={roster_member.starter}
                                tabIndex={-1}
                                disableRipple
                              />
                            </ListItemIcon>
              </Hidden>
                          <ListItemText>Starter</ListItemText>
                          <ListItemSecondaryAction>
                <Hidden smUp>
                              <FormControlLabel
                                label=""
                                checked={roster_member.starter}
                                control={(
                                  <Switch
                                    color="primary"
                                    id="starter"
                                    onChange={(e) => this.changeRosterMember('starter', e.target.checked)}
                                  />
                                )}
                              />
                            </Hidden>
              </ListItemSecondaryAction>
                        </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button
                    color="primary"
                    onClick={() => this.changePage('roster', this.getRosterID())}
                  >
                  Cancel
                  </Button>
                  <Button
                    color="primary"
                    disabled={!this.state.roster_changed || errant}
                    onClick={() => this.saveRosterMember()}
                  >
                  Save
                  </Button>
                </DialogActions>
              </>
            )
          }
        </Paper>
        <Menu
          anchorEl={this.state.anchor_el}
          open={this.isAnchorMenu('roster-upload')}
          onClose={() => this.closeAnchorMenu()}
        >
          <MenuItem>
            <ListItemIcon><CameraIcon /></ListItemIcon>
            <ListItemText onClick={() => this.closeAnchorMenu()}>Upload from Device</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemIcon><FolderImageIcon /></ListItemIcon>
            <ListItemText onClick={() => this.closeAnchorMenu()}>Open Media Library</ListItemText>
          </MenuItem>
        </Menu>
      </>
    );
  }

  getRosterID = () => {
    const { match } = this.props;
    let id = null;

    if (match.params.roster) {
      id = match.params.roster;
    }

    return id;
  }

  getRosterMemberID = () => {
    const { match } = this.props;
    let id = null;

    if (match.params.member) {
      if (match.params.member !== 'create') {
        id = match.params.member;
      }
    }

    return id;
  }

  isCreateRosterMember = () => {
    const { match } = this.props;
    return (match.params.member === undefined);
  }

  uploadImage = (target) => {
    if (!target || !target.files || target.files.length === 0) {
      return;
    }

    this.changeRosterMember('avatar', target.files[0]);
  }

  changeRosterMember = (key, value) => {
    const { roster_member } = this.state;
    const state = { roster_changed: true };
    let promise;

    if (key === 'avatar') {
      promise = BlobToDataURL(value).then((url) => {
        state.roster_avatar_blob = value;
        roster_member[key] = url;
      });
    } else {
      promise = Promise.resolve();
      roster_member[key] = value;
    }

    promise.then(() => {
      this.setState(state);
    });
  }

  saveRosterMember = () => {
    const {
      roster_member,
      roster_avatar_blob,
    } = this.state;

    let { avatar } = roster_member;
    let promise;

    if (roster_avatar_blob !== null && !this.isCreateRosterMember()) {
      let extension = null;

      switch (roster_avatar_blob.type) {
        default:
        case 'image/png': {
          extension = 'png';
          break;
        }
        case 'image/jpeg': {
          extension = 'jpg';
          break;
        }
      }

      const action = new ScoreshotsPut('ss3-clientmedia', `${this.getRosterMemberID()}.${extension}`);
      promise = action.put(roster_avatar_blob, roster_avatar_blob.type);

      if (avatar === null || !avatar.startsWith('https://ss3-clientmedia.s3.us-east-2.amazonaws.com/')) {
        avatar = `https://ss3-clientmedia.s3.us-east-2.amazonaws.com/${localStorage.getItem('client_id')}/${this.getRosterMemberID()}.${extension}`;
      }
    } else {
      promise = Promise.resolve();
    }

    promise.then(() => {
      if (this.isCreateRosterMember()) {
        return this.props.gql_add_roster_member({
          variables: {
            rosterId: this.getRosterID(),
            name: roster_member.name,
            firstname: roster_member.firstname,
            shortname: roster_member.shortname,
            position: roster_member.position,
            number: (roster_member.number || '0'),
            year: roster_member.year,
            height: roster_member.height,
            weight: roster_member.weight,
            starter: roster_member.starter,
            avatar: '',
          },
        });
      }
      return this.props.gql_update_roster_member({
        variables: {
          id: this.getRosterMemberID(),
          name: roster_member.name,
          firstname: roster_member.firstname,
          shortname: roster_member.shortname,
          position: roster_member.position,
          number: (roster_member.number || '0'),
          year: roster_member.year,
          height: roster_member.height,
          weight: roster_member.weight,
          starter: roster_member.starter,
          avatar,
        },
      });
    }).then(() => {
      this.setState({
        roster_member: CreateNewRosterMember(),
      }, () => {
        this.changePage('roster', this.getRosterID());
      });
    });
  }

  isAnchorMenu = (type) => {
    const { anchor_open, anchor_type } = this.state;
    return (anchor_open && anchor_type === type);
  }

  openAnchorMenu = (element, type, id, name = null, category = null) => {
    this.setState({
      anchor_el: element,
      anchor_type: type,
      anchor_id: id,
      anchor_name: name,
      anchor_category: category,
      anchor_open: true,
    });
  }

  closeAnchorMenu = (url = null) => {
    this.setState({ anchor_open: false }, () => {
      if (url !== null) {
        const { history } = this.props;
        history.push(url);
      }
    });
  }

  changePage = (menu, ...rest) => {
    const { history } = this.props;

    switch (menu) {
      default: {
        history.push('/media/roster');
        break;
      }
      case 'roster': {
        history.push(`/media/roster/${rest[0]}`);
        break;
      }
      case 'member': {
        history.push(`/media/roster/${this.getRosterID()}/${rest[0]}`);
        break;
      }
      case 'addmember': {
        history.push(`/media/roster/${this.getRosterID()}/create`);
        break;
      }
    }
  }
}

const RosterMemberEditor = compose(
  graphql(GQL_GET_ROSTER_MEMBER, {
    name: 'gql_get_roster_member',
    options: { fetchPolicy: 'no-cache' },
  }),
  graphql(GQL_ADD_ROSTER_MEMBER, { name: 'gql_add_roster_member' }),
  graphql(GQL_UPDATE_ROSTER_MEMBER, { name: 'gql_update_roster_member' }),
  withRouter,
  withStyles(STYLES),
)(RosterMemberEditor_class);

// -------------------------------------------------------------------------- //

class RosterEditor_class extends React.Component {
  state = {
    anchor_el: null,
    roster_dialog_open: false,
    roster_dialog_type: '',
    roster_dialog_id: null,
    roster_dialog_name: null,
    roster_dialog_category: null,
    loading: true,
    editing: false,
    members: [],
  }

  componentDidMount() {
    this.props.gql_get_roster_members.refetch({ id: this.getRosterID() })
      .then((result) => {
        const members = result.data.getRoster.members.map((member) => (
          { ...member }
        ));

        this.setState({
          loading: false,
          members,
        // members: [...members].sort((a, b) => {
        //   if (a.order !== null && b.order !== null) {
        //     return (a.order - b.order);
        //   } else {
        //     return (+a.starter - +b.starter);
        //   }
        // }),
        });
      });
  }

  render() {
    return (
      <>
        <Menu
          anchorEl={this.state.anchor_el}
          open={this.isAnchorMenu('roster')}
          onClose={() => this.closeAnchorMenu()}
        >
          <MenuItem onClick={() => this.deleteRosterMember(this.state.anchor_id)}>
            Remove
          </MenuItem>
        </Menu>
        <List>
          {(this.state.loading) && (
            <>
              <ListItem>
                <ListItemText
                  primary="Loading.... Please wait."
                />
              </ListItem>
              <Divider />
            </>
          )}
          {(!this.state.loading && this.state.members.length === 0) && (
            <>
              <ListItem>
                <ListItemText
                  primary="Roster members have not been added yet."
                />
              </ListItem>
              <Divider />
            </>
          )}
          {(!this.state.loading) && this.state.members.map((member, key) => (
            <React.Fragment key={key}>
              <ListItem button>
                <ListItemText
                  key={member.id}
                  primary={member.name}
                  secondary={`${member.position} • ${member.number} ${(member.starter && ' • STARTER') || ''}`}
                  onClick={() => this.changePage('member', member.id, member.name, member)}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    disabled={this.state.editing}
                    onClick={() => this.swapRosterMember(key, (key - 1))}
                  >
                    <ChevronUpIcon />
                  </IconButton>
                  <IconButton
                    disabled={this.state.editing}
                    onClick={() => this.swapRosterMember(key, (key + 1))}
                  >
                    <ChevronDownIcon />
                  </IconButton>
                  <IconButton
                    disabled={this.state.editing}
                    onClick={(e) => this.openAnchorMenu(
                      e.currentTarget, 'roster', member.id,
                    )}
                  >
                    <DotsVerticalIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </>
    );
  }

  swapRosterMember = (lhs, rhs) => {
    if (lhs === rhs || lhs < 0 || rhs < 0) {
      return;
    }

    const { members } = this.state;

    if (lhs >= members.length || rhs >= members.length) {
      return;
    }

    [members[lhs], members[rhs]] = [members[rhs], members[lhs]];
    const a = members[lhs].id;
    const b = members[rhs].id;
    members[lhs].id = b;
    members[rhs].id = a;


    this.setState({ editing: true }, () => {
      this.props.gql_update_roster_member({
        variables: {
          name: members[lhs].name,
          number: members[lhs].number,
          avatar: members[lhs].avatar,
          shortname: members[lhs].shortname,
          firstname: members[lhs].firstname,
          position: members[lhs].position,
          year: members[lhs].year,
          height: members[lhs].height,
          weight: members[lhs].weight,
          starter: members[lhs].starter,
          id: members[lhs].id,
        },
      }).then(() => (
        this.props.gql_update_roster_member({
          variables: {
            name: members[rhs].name,
            number: members[rhs].number,
            avatar: members[rhs].avatar,
            shortname: members[rhs].shortname,
            firstname: members[rhs].firstname,
            position: members[rhs].position,
            year: members[rhs].year,
            height: members[rhs].height,
            weight: members[rhs].weight,
            starter: members[rhs].starter,
            id: members[rhs].id,
          },
        })
      )).then(() => {
        this.setState({ editing: false });
      });
    });
  }

  getRosterID = () => {
    const { match } = this.props;
    let id = null;

    if (match.params.roster) {
      id = match.params.roster;
    }

    return id;
  }

  isAnchorMenu = (type) => {
    const { anchor_open, anchor_type } = this.state;
    return (anchor_open && anchor_type === type);
  }

  openAnchorMenu = (element, type, id, name = null, category = null) => {
    this.setState({
      anchor_el: element,
      anchor_type: type,
      anchor_id: id,
      anchor_name: name,
      anchor_category: category,
      anchor_open: true,
    });
  }

  closeAnchorMenu = (url = null) => {
    this.setState({ anchor_open: false }, () => {
      if (url !== null) {
        const { history } = this.props;
        history.push(url);
      }
    });
  }

  openRosterDialog = (type, id = null, name = '', category = '') => {
    this.setState({
      roster_dialog_open: true,
      roster_dialog_type: type,
      roster_dialog_name: name,
      roster_dialog_category: category,
      roster_dialog_id: id,
      anchor_open: false,
    });
  }

  /**
   * deletes a member by its id
   * @param {id of member} id
   */
  deleteRosterMember = async (id) => {
    await this.props.deleteRosterMember({
      variables: { id },
    });

    const currentMembers = this.state.members;

    this.setState({
      members: currentMembers.filter((el) => {
        if (el.id !== id) {
          return el;
        }
        return null;
      }),
    });
  }

  closeRosterDialog = (work_type = null) => {
    const state = { roster_dialog_open: false };
    let promise;

    switch (work_type) {
      case 'delete-member': {
        promise = this.props.deleteRosterMember({
          variables: { id: this.state.roster_dialog_id },
          refetchQueries: [{
            query: GQL_GET_ROSTER_MEMBERS,
            variables: { id: this.getRosterID() },
          }],
        });

        break;
      }
      default: {
        promise = Promise.resolve();
        break;
      }
    }

    promise.then(() => {
      this.setState(state);
    });
  }

  changePage = (menu, ...rest) => {
    const { history } = this.props;

    switch (menu) {
      default: {
        history.push('/media/roster');
        break;
      }
      case 'roster': {
        history.push(`/media/roster/${rest[0]}`);
        break;
      }
      case 'member': {
        history.push(`/media/roster/${this.getRosterID()}/${rest[0]}`);
        break;
      }
      case 'addmember': {
        history.push(`/media/roster/${this.getRosterID()}/create`);
        break;
      }
    }
  }
}

const RosterEditor = compose(
  graphql(GQL_GET_ROSTER_MEMBERS, {
    name: 'gql_get_roster_members',
    options: { fetchPolicy: 'no-cache' },
  }),
  graphql(GQL_UPDATE_ROSTER_MEMBER, {
    name: 'gql_update_roster_member',
  }),
  graphql(DELETE_ROSTER_MEMBER, { name: 'deleteRosterMember' }),
  withRouter,
  withStyles(STYLES),
)(RosterEditor_class);

// -------------------------------------------------------------------------- //

class Component extends React.Component {
  state = {
    roster_dialog_open: false,
    roster_dialog_type: '',
    roster_dialog_id: null,
    roster_dialog_name: null,
    roster_dialog_category: null,
  }

  render() {
    return (
      <>
        {this.renderAppBar()}
        <ResponsiveGrid key="nav">
          <SidebarGrid
            sidebar={(
              <MediaNav drop={() => {}} />
            )}
            xsFallback={null}
          >
            {this.renderRoster()}
          </SidebarGrid>
        </ResponsiveGrid>
      </>
    );
  }

  renderAppBar = () => {
    const { classes } = this.props;
    const {
      roster_menu: type, work_roster_id, roster_name, roster_member,
    } = this.state;
    const toolbar_actions = [];

    switch (type) {
      case 'addmember': {
        toolbar_actions.push(
          <>
            <ButtonBase onClick={() => this.changePage('rosterlist')}>
              <Typography color="inherit" variant="h5">Rosters</Typography>
            </ButtonBase>
            <ChevronRightIcon />
            <ButtonBase onClick={() => this.changePage('roster', work_roster_id, roster_name)}>
              <Typography color="inherit" variant="h5">{roster_name}</Typography>
            </ButtonBase>
            <ChevronRightIcon />
            <Typography color="inherit" variant="h5">Add Member</Typography>
          </>,
        );
        break;
      }
      case 'member': {
        toolbar_actions.push(
          <>
            <ButtonBase onClick={() => this.changePage('rosterlist')}>
              <Typography color="inherit" variant="h5">Rosters</Typography>
            </ButtonBase>
            <ChevronRightIcon />
            <ButtonBase onClick={() => this.changePage('roster', work_roster_id, roster_name)}>
              <Typography color="inherit" variant="h5">{roster_name}</Typography>
            </ButtonBase>
            <ChevronRightIcon />
            <Typography color="inherit" variant="h5">{roster_member.name}</Typography>
          </>,
        );
        break;
      }
      case 'roster': {
        toolbar_actions.push(
          <>
            <ButtonBase onClick={() => this.changePage('rosterlist')}>
              <Typography color="inherit" variant="h5">Rosters</Typography>
            </ButtonBase>
            <ChevronRightIcon />
            <Typography color="inherit" variant="h5">{roster_name}</Typography>
          </>,
        );

        break;
      }
      case 'rosterlist': {
        toolbar_actions.push(
          <>
            <Typography color="inherit" variant="h5">Rosters</Typography>
          </>,
        );

        break;
      }
      default: {
        toolbar_actions.push(
          <>
            <Typography color="inherit" variant="h5">Rosters</Typography>
          </>,
        );

        break;
      }
    }

    return (
      <AppBar
        position="sticky"
        className={classes.app_bar}
      >
        <Hidden only="xs">
          {' '}
          {/* desktop */}
          <ResponsiveGrid>
            <SidebarGrid disablePaddingTop>
              <Toolbar disableGutters>
                {toolbar_actions}
                <div className={classes.flex} />
                <Route exact path="/media/roster">
                  <Tooltip title="Add Roster" id="add-roster-tooltip">
                    <IconButton
                      color="inherit"
                      aria-labelledby="add-roster-tooltip"
                      onClick={() => this.openRosterDialog('add-roster')}
                    >
                      <AccountMultiplePlusIcon />
                    </IconButton>
                  </Tooltip>
                </Route>
                <Route exact path="/media/roster/:roster">
                  <Tooltip title="Add Member" id="add-member-tooltip">
                    <IconButton
                      color="inherit"
                      aria-labelledby="add-member-tooltip"
                      onClick={() => this.changePage('addmember')}
                    >
                      <AccountPlusIcon />
                    </IconButton>
                  </Tooltip>
                </Route>
              </Toolbar>
            </SidebarGrid>
          </ResponsiveGrid>
        </Hidden>
        <Hidden smUp>
          {' '}
          {/* mobile */}
          <Toolbar>
            <Button
              color="inherit"
              onClick={(e) => this.openAnchor(
                e.currentTarget, 'mobile',
              )}
            >
              {dict[ROUTE_TYPE].prettyName}
              <MenuDownIcon />
            </Button>
            {/* toolbar_button */}
          </Toolbar>
          <Menu
            anchorEl={this.state.anchor_el}
            open={this.isAnchorMenu('mobile')}
            onClose={() => this.closeAnchorMenu()}
          >
            {Object.keys(MEDIA_TYPES).map((key, index) => {
              const Icon = MEDIA_TYPES[key].icon;

              return (
                <MenuItem
                  key={index}
                  selected={MEDIA_TYPES[key].upper_type === ROUTE_TYPE}
                  onClick={() => this.closeAnchorMenu(MEDIA_TYPES[key].url)}
                >
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText>
                    {MEDIA_TYPES[key].pretty_name}
                  </ListItemText>
                </MenuItem>
              );
            })}
          </Menu>
        </Hidden>
      </AppBar>
    );
  }

  renderRoster = () => {
    const { classes } = this.props;

    return (
      <div className={classes.roster_menu}>
        <RouteSwitch>
          <Route exact path="/media/roster">
            {() => this.renderRosterList()}
          </Route>
          <Route exact path="/media/roster/:roster">
            {() => <RosterEditor />}
          </Route>
          <Route exact path="/media/roster/:roster/create">
            {() => <RosterMemberEditor />}
          </Route>
          <Route exact path="/media/roster/:roster/:member">
            {() => <RosterMemberEditor />}
          </Route>
        </RouteSwitch>
        {this.renderRosterDialog()}
      </div>
    );
  }

  getRosterID = () => {
    const { match } = this.props;
    let id = null;

    if (match.params.roster) {
      id = match.params.roster;
    }

    return id;
  }

  isAnchorMenu = (type) => {
    const { anchor_open, anchor_type } = this.state;
    return (anchor_open && anchor_type === type);
  }

  openAnchorMenu = (element, type, id, name = null, category = null) => {
    this.setState({
      anchor_el: element,
      anchor_type: type,
      anchor_id: id,
      anchor_name: name,
      anchor_category: category,
      anchor_open: true,
    });
  }

  closeAnchorMenu = (url = null) => {
    this.setState({ anchor_open: false }, () => {
      if (url !== null) {
        const { history } = this.props;
        history.push(url);
      }
    });
  }

  openRosterDialog = (type, id = null, name = '', category = '') => {
    this.setState({
      roster_dialog_open: true,
      roster_dialog_type: type,
      roster_dialog_name: name,
      roster_dialog_category: category,
      roster_dialog_id: id,
      anchor_open: false,
    });
  }

  closeRosterDialog = (work_type = null) => {
    const state = { roster_dialog_open: false };
    let promise;

    switch (work_type) {
      case 'add-roster': {
        promise = this.props.addRoster({
          variables: {
            name: this.state.roster_dialog_name,
            category: this.state.roster_dialog_category,
          },
          refetchQueries: [{ query: GQL_GET_ROSTERS }],
        });

        break;
      }
      case 'update-roster': {
        promise = this.props.updateRoster({
          variables: {
            name: this.state.roster_dialog_name,
            rosterId: this.state.roster_dialog_id,
            category: this.state.roster_dialog_category,
          },
        });

        break;
      }
      case 'delete-roster': {
        promise = this.props.deleteRoster({
          variables: { rosterId: this.state.roster_dialog_id },
          refetchQueries: [{ query: GQL_GET_ROSTERS }],
        });

        break;
      }
      case 'delete-member': {
        promise = this.props.deleteRosterMember({
          variables: { id: this.state.roster_dialog_id },
          refetchQueries: [{
            query: GQL_GET_ROSTER_MEMBERS,
            variables: { id: this.getRosterID() },
          }],
        });
        break;
      }
      default: {
        promise = Promise.resolve();
        break;
      }
    }

    promise.then(() => {
      this.setState(state);
    });
  }

  changePage = (menu, ...rest) => {
    const { history } = this.props;

    switch (menu) {
      default: {
        history.push('/media/roster');
        break;
      }
      case 'roster': {
        history.push(`/media/roster/${rest[0]}`);
        break;
      }
      case 'member': {
        history.push(`/media/roster/${this.getRosterID()}/${rest[0]}`);
        break;
      }
      case 'addmember': {
        history.push(`/media/roster/${this.getRosterID()}/create`);
        break;
      }
    }
  }

  renderRosterDialog = () => {
    const { category_list } = this.props;
    let title_text = '';
    let title_description = '';
    let button_text = '';

    switch (this.state.roster_dialog_type) {
      case 'add-roster': {
        title_text = 'Create Roster';
        title_description = 'Enter your team name and sports category.';
        button_text = 'Create';
        break;
      }
      case 'update-roster': {
        title_text = 'Edit Roster';
        title_description = '';
        button_text = 'Save';
        break;
      }
      case 'delete-roster': {
        title_text = 'Delete this roster?';
        title_description = 'The roster and all its member information will be removed. This action cannot be undone.';
        button_text = 'Delete';
        break;
      }
      case 'delete-member': {
        title_text = 'Delete this member?';
        title_description = 'This member will be removed from the roster. This action cannot be undone.';
        button_text = 'Delete';
        break;
      }
      default: {
        title_text = '(null)';
        title_description = '(null)';
        break;
      }
    }

    return (
      <Dialog
        open={this.state.roster_dialog_open}
        onClose={() => this.closeRosterDialog()}
      >
        <DialogTitle>
          {title_text}
        </DialogTitle>
        <DialogContent>
          {
            (title_description !== '')
            && (
            <DialogContentText>
              {title_description}
            </DialogContentText>
            )
          }
          {
            !(this.state.roster_dialog_type.startsWith('delete-'))
            && (
              <>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Roster Name"
                  type="text"
                  fullWidth
                  value={this.state.roster_dialog_name || ''}
                  onChange={(e) => this.setState({ roster_dialog_name: e.target.value })}
                />
                <FormControl fullWidth>
                  <InputLabel htmlFor="roster-category-label">
                  Sport
                  </InputLabel>
                  <Select
                    value={this.state.roster_dialog_category || ''}
                    onChange={(e) => this.setState({ roster_dialog_category: e.target.value })}
                    MenuProps={MENU_PROPS}
                    inputProps={{
                      name: 'roster-category',
                      id: 'roster-category-label',
                    }}
                  >
                    {
                  (category_list.categories)
                  && category_list.categories.map((category, index) => (
                    <MenuItem key={index} value={category.name}>
                      {category.name}
                    </MenuItem>
                  ))
                }
                    {
                  (category_list.loading)
                  && <CircularProgress size={48} />
                }
                  </Select>
                </FormControl>
              </>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => this.closeRosterDialog()}>
            Cancel
          </Button>
          {
            (button_text !== '')
            && (
            <Button
              color="primary"
              onClick={() => this.closeRosterDialog(
                this.state.roster_dialog_type,
              )}
              disabled={this.state.roster_dialog_type === 'add-roster' ? (
                !this.state.roster_dialog_name.length > 0
              ) : false}
            >
              {button_text}
            </Button>
            )
          }
        </DialogActions>
      </Dialog>
    );
  }

  renderRosterList = () => (
    <>
      <Menu
        anchorEl={this.state.anchor_el}
        open={this.isAnchorMenu('rosterlist')}
        onClose={() => this.closeAnchorMenu()}
      >
        <MenuItem
          onClick={() => this.openRosterDialog(
            'update-roster',
            this.state.anchor_id,
            this.state.anchor_name,
            this.state.anchor_category,
          )}
        >
            Edit
        </MenuItem>
        <MenuItem
          onClick={() => this.openRosterDialog(
            'delete-roster', this.state.anchor_id,
          )}
        >
            Remove
        </MenuItem>
      </Menu>
      <List>
        <Query
          query={GQL_GET_ROSTERS}
          fetchPolicy="cache-and-network"
        >
          {({ data, loading, error }) => {
            if (loading) {
              return (
                <>
                  <ListItem>
                    <ListItemText
                      primary="Loading.... Please wait."
                    />
                  </ListItem>
                  <Divider />
                </>
              );
            }

            if (error) {
              return (
                <>
                  <ListItem>
                    <ListItemText
                      primary="Sorry an error occurred"
                    />
                  </ListItem>
                  <Divider />
                </>
              );
            }

            if (data.rosterlist.length === 0) {
              return (
                <>
                  <ListItem>
                    <ListItemText
                      primary="Rosters have not been added yet."
                    />
                  </ListItem>
                  <Divider />
                </>
              );
            }

            return data.rosterlist.map((roster) => (
              <>
                <ListItem button>
                  <ListItemText
                    key={roster.id}
                    primary={roster.name}
                    secondary={`${roster.category}`}
                    onClick={() => this.changePage(
                      'roster', roster.id,
                    )}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={(e) => this.openAnchorMenu(
                          e.currentTarget, 'rosterlist',
                          roster.id, roster.name, roster.category,
                        )}
                      aria-label={`${roster.name} menu`}
                    >
                      <DotsVerticalIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </>
            ));
          }}
        </Query>
      </List>
    </>
  )
}

// -------------------------------------------------------------------------- //

export const MediaRosters = compose(
  graphql(GQL_GET_ROSTERS, { name: 'getRosters' }),
  graphql(GQL_GET_ROSTER_MEMBERS, { name: 'gql_get_roster_members' }),
  graphql(GQL_GET_ROSTER, { name: 'getRoster' }),
  graphql(ADD_ROSTER, { name: 'addRoster' }),
  graphql(UPDATE_ROSTER, { name: 'updateRoster' }),
  graphql(DELETE_ROSTER, { name: 'deleteRoster' }),
  graphql(DELETE_ROSTER_MEMBER, { name: 'deleteRosterMember' }),
  graphql(GET_CATEGORIES, { name: 'category_list' }),
  withRouter,
  withStyles(STYLES),
)(Component);

// -------------------------------------------------------------------------- //
