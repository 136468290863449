import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Editor from './Editor';

const GET_TEMPLATE_INFO = gql`
  query getTemplate($id: ID!){
    template(
      id: $id
    ) {
      id
      width
      height
      url
      isSlideShow
      isQuickCreate
      disableXMLImport
      startingStep
      categories {
        id
        name
      }
      types {
        id
        name
      }
    }
  }
`;

export default (props) => (
  <Query
    query={GET_TEMPLATE_INFO}
    key="getInfo"
    variables={{
      id: props.match.params.id,
    }}
  >
    {({ data }) => (
      data && data.template
        ? (
          <Editor
            template={data.template}
            match={props.match}
            quickCreate={data.template.isQuickCreate}
          />
        )
        : <div />
    )}
  </Query>
);
