
import * as React from 'react';

import 'rc-slider/assets/index.css';
import { isMobileOnly, isMobile } from 'react-device-detect';
import Slider from 'rc-slider';
import TextField from '@material-ui/core/TextField';
import Cropper from 'react-easy-crop';
import {removeBackgroundFromImageBase64 } from 'remove.bg';
import ColorPicker from '../../color/ColorPicker';
import { ColorButton } from '../../color/ColorButton';
import FilterPreview from './FilterPreview';
import filtersList from '../../../canvas/filters';
import SliderWithInput from '../SliderWithInput';

import {
  Button,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  withStyles,
  Grid,
  Dialog,
  DialogActions,
  Grow,
  Collapse,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Checkbox,
  Tooltip,
  IconButton,
} from '@material-ui/core';

import { ShadowEditor } from './ShadowEditor';
import { UserRoleConsumer } from '../../user/Permissions';
import {compose, graphql} from "react-apollo";
import * as MetaData from '../../../canvas/lib/metadata';
import gql from "graphql-tag";
import ScoreshotsPut from "../../../scoreshotsapi/Put";
import {RotateLeft, RotateRight} from "mdi-material-ui";

const GET_USER_INFO = gql`
  query getUserInfo {
    me {
      id
      client {
        id
        type
        hasBackgroundRemoval
        credits
      }
    }
  }
`;

const UPLOAD_FILE = gql`
    mutation (
        $contentType: String!,
        $name: String!,
        $size: Int!,
        $bucket: String!,
        $type: MEDIA_TYPE!,
        $extension: String!,
        $folderId: ID,
        $parentId: ID
    ) {
        createFile(
            contentType: $contentType
            name: $name
            size: $size
            bucket: $bucket
            extension: $extension
            type: $type
            folderId: $folderId
            parentId: $parentId
        ) {
            id
            client {
                id
            }
        }
    }
`;

const IMAGE_SEARCH = gql`
  query file($id: ID) {
    fileIndiv(fileId: $id) {
      contentType
      id
      name
      size
      extension
      bucket
      type
      folder {
        id
      }
      parent {
        id
      }
      children {
        contentType
        id
        name
        size
        extension
        bucket
        type
      }
    }
  }
`;

const ADD_LABELS = gql`
  mutation (
    $bucket: String!
    $key: String!
    $fileId: ID!
  ) {
    addLabels(
      bucket: $bucket
      key: $key
      fileId: $fileId
    ) {
      name
      confidence
    }
  }
`;

const SET_SUB = gql`mutation ex( $id : ID!, $plan_type : String,$subscription_start : String,$subscription_end : String, $credits : Int ,$total_users_allowed : Int, $account_status : String, $total_allowed_storage : Float){
  updateSubcriptionData(id : $id ,
    plan_type: $plan_type,
    subscription_start : $subscription_start,
    subscription_end : $subscription_end,
    credits : $credits,
    total_users_allowed : $total_users_allowed,
    account_status : $account_status,
    total_allowed_storage : $total_allowed_storage){
    id
  }
}`

const STYLES = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  heading: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    fontSize: '12px',
    textTransform: 'uppercase',
  },
  filterPreview: {
    textAlign: 'center',
  },
  button: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonSmall: {
    marginTop: 15,
    marginBottom: 15,
  },
  shadowEditor: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  cropperRoot: {
    minWidth: theme.spacing(64),
    maxWidth: theme.spacing(64),
    minHeight: theme.spacing(48),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  backgroundRemovalRoot: {
    minWidth: theme.spacing(92),
    maxWidth: theme.spacing(92),
    minHeight: theme.spacing(32),
    maxHeight: theme.spacing(48),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  divBackgroundRemoval: {
    display: 'flex',
  },
  divBackgroundRemovalContainer: {
    flexDirection: 'row',
    padding: '3px',
    width: '50%',
  },
  divBackgroundRemovalImg: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  cropContainer: {
    position: 'relative',
    height: 240,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: 400,
    },
  },
  cropControls: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
  },
  sliderLabel: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: '0px 0px',
    marginLeft: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },
});

class ImageEditor extends React.Component {

  state = {
    customFilter: null,
    filterVariables: {},
    number: 0,
    width: 10,
    height: 10,
    originalWidth: 10, // the original width of the image
    originalHeight: 10, // the original height;
    cropper_open: false,
    cropper_crop: { x: 0, y: 0 },
    cropper_rotation: 0,
    cropper_zoom: 1,
    cropper_area_pixels: null,
    backgroundRemoval_open: false,
    backgroundRemoval_src: '',
    backgroundRemoval_loading: false,
    backgroundRemoval_needsApproval: true,
    backgroundRemoval_credits: -1,
    originalImageSrc: '',
    originalImageId: '',
    backgroundRemoval_save: true,
    backgroundRemoval_originalImage: {},
    image_file_size: null,
    picker_open: false,
    active_picker: null,
    anchor_position: null,
    mediaType: "PHOTO",
  }

  componentDidMount() {
    this.Filters = filtersList(this.props.fabric);
    const { active } = this.props;
    let originalImageSrc;
    let pathname;
    if (active) {
      originalImageSrc = active.getSrc();
      if (originalImageSrc) {
        const isURL = /^(f|ht)tps?:\/\//i.test(originalImageSrc);
        if (isURL) {
          const myURL = new URL(originalImageSrc);
          pathname = myURL.pathname.replace('.jpg', '').replace('.png', '');
        }
      }
    }
    let mediaType = "PHOTO";
    let originalWidth = active.width;
    let originalHeight = active.height;
    let width = Math.floor(originalWidth * active.scaleX);
    let height = Math.floor(originalHeight * active.scaleY);
    let number = width+height;
    if (active.parent.type === "cutoutBox") {
      mediaType = "CUTOUT";
    }

    this.setState({
      width,
      height,
      number,
      originalWidth,
      originalHeight,
      originalImageSrc,
      originalImageId: pathname,
      image_file_size: null,
      mediaType,
    }, () => {
      this.getFileSize(originalImageSrc).then((size) => {
        if (this.state.originalImageSrc === originalImageSrc) {
          this.setState({ image_file_size: size });
        }
      });
    });
  }

  componentDidUpdate(prevProps) {
    let originalWidth = this.props.active.width;
    let originalHeight = this.props.active.height;
    let width = Math.floor(originalWidth * this.props.active.scaleX);
    let height = Math.floor(originalHeight * this.props.active.scaleY);
    let number = width+height;
    // Typical usage (don't forget to compare props)
    let change = this.props.active.scaleX !== prevProps.active.scaleX || this.props.active.scaleY !== prevProps.active.scaleY;
    change = this.state.number !== number;
    if (change) {
      this.setState({width,height,originalWidth,originalHeight,number});
    }
  }

  onModified = () => {
    const { active } = this.props;
    active.canvas.trigger('object:modified');
  }

  filterSet = (name, prop, scale) => (val) => {
    const { active, activeGroup, CanvasUtil, fabric } = this.props;
    activeGroup.forEach((obj) => {
      const newFilters = [];
      let noFilter = true;
      for (let i = 0; i < obj.filters.length; i += 1) {
        const filter = obj.filters[i];
        if (filter.type === name) {
          filter[prop] = val / scale;
          newFilters.push(filter);
          noFilter = false;
        } else {
          newFilters.push(filter);
        }
      }
      if (noFilter) {
        const props = {};
        props[prop] = val / scale;
        const filter = new fabric.Image.filters[name](props);
        newFilters.push(filter);
      }
      obj.filters = newFilters;
      obj.applyFilters();
      CanvasUtil.dirtyCanvas();
    });
    active.canvas.requestRenderAll();
    this.forceUpdate();
  }

  backgroundColorChange = self => (colors) => {
    const { active, activeGroup } = self.props;
    activeGroup.forEach((obj) => {
      obj.parent.set('fill', colors[0]);
    });
    active.canvas.requestRenderAll();
  }

  scale = (e) => {
    const { active, activeGroup, CanvasUtil } = this.props;
    activeGroup.forEach((obj) => {
      const minScale = obj.minScale ? obj.minScale : 1;
      const scale = ((e / 50) + 1) * minScale;
      obj.set({
        scaleX: scale,
        scaleY: scale,
      });
      obj.setCoords();
    });
    active.canvas.requestRenderAll();
    CanvasUtil.dirtyCanvas();
    this.forceUpdate();
  }

  rotate = (e) => {
    const { active, activeGroup } = this.props;
    activeGroup.forEach((obj) => {
      obj.set('angle', e);
      obj.setCoords();
    });
    active.canvas.requestRenderAll();
    this.forceUpdate();
  }

  flip = (prop) => {
    const { active, activeGroup } = this.props;
    activeGroup.forEach(object => {
      object.set(prop, !object[prop]);
      object.setCoords();
    });
    active.canvas.requestRenderAll();
  }

  getStaticFilters() {
    const { fabric, active, activeGroup, classes } = this.props;
    const filters = [];

    if (this.Filters && this.Filters.static) {
      this.Filters.static.forEach((filter, index) => {
        filters.push(
          <Grid item xs={4} key={'grid'+index}>
            <FilterPreview
              key={index}
              filter={filter}
              fabric={fabric}
              active={active}
              activeGroup={activeGroup}
              onClick={this.addFilter(filter)}
              className={classes.filterPreview}
            />
          </Grid>
        );
      });
    }
    return (
      <Grid container>
        {filters}
      </Grid>
    );
  }

  getDynamicFilters() {
    const { classes, fabric, active, activeGroup } = this.props;
    let dynamicFilters = [];

    if (this.Filters && this.Filters.dynamic) {
      this.Filters.dynamic.forEach((filter, index) => {
        dynamicFilters.push(
          <Grid item xs={4} key={index}>
            <FilterPreview
              filter={filter}
              fabric={fabric}
              active={active}
              activeGroup={activeGroup}
              onClick={this.addFilter(filter)}
              className={classes.filterPreview}
            />
          </Grid>
        );
      });
    }
    return (
      <Grid container>
        {dynamicFilters}
        {this.getFilterControls()}
      </Grid>
    );
  }

  setFilterVariable = (filter, varName, val) => {
    const vars = this.state.filterVariables;
    vars[varName] = val.hex;
    this.addFilter(filter, vars)();
  }

  getFilterControls() {
    const { classes, CanvasUtil } = this.props;
    const output = [];

    // does the selected filter have controls?
    if (this.state.customFilter && this.state.customFilter.variables) {
      const variables = { ...this.state.customFilter.variables };

      // build controls.
      Object.keys(variables).forEach((key, index) => {
        let variable = variables[key];

        switch (variable.type) {
          case 'color': {
            switch (variable.default) {
              case 'primary':
              case 'secondary':
              case 'tertiary': {
                variable.default = CanvasUtil.canvas.colors[variable.default];
                break;
              }
              default: {
                break;
              }
            }

            output.push((
              <Grid
                item xs={6}
                className={classes.filterPreview}
                key={index}
              >
                <Typography style={{ marginTop: 4 }}>
                  {variable.name}
                </Typography>
                <ColorButton
                  opaque color={
                  this.state.filterVariables[key]
                    ? this.state.filterVariables[key]
                    : variable.default
                }
                  onClick={(e) => {
                    const x = 218.0; // (218.0 * ratio);
                    const y = e.clientY; // (e.clientY * ratio);

                    this.setState({
                      picker_open: true,
                      active_picker: key,
                      anchor_position: { left: x, top: y },
                    });
                  }}
                />
                <ColorPicker
                  disableAlpha
                  open={
                    Boolean(this.state.picker_open) &&
                    (this.state.active_picker === key)
                  }
                  canvas={CanvasUtil.canvas}
                  anchorReference="anchorPosition"
                  anchorPosition={this.state.anchor_position}
                  onClose={() => this.setState({ picker_open: false })}
                  color={
                    this.state.filterVariables[key]
                      ? this.state.filterVariables[key]
                      : variable.default
                  }
                  onChangeComplete={(color) => (
                    this.setFilterVariable(this.state.customFilter, key, color)
                  )}
                />
              </Grid>
            ));

            break;
          }
          default: {
            break;
          }
        }
      });
    }
    return (
      <Grid container>
        {output}
      </Grid>
    );
  }

  addFilter = (filter, variables = {}) => () => {
    // get active object, set output array.
    const { active, activeGroup, CanvasUtil } = this.props;
    const newFilters = [];

    if (filter.filter) {
      // if the filter is simple, work is done.
      newFilters.push(new filter.filter());
    } else if (filter.filters) {
      // if it's not
      filter.filters.forEach((subFilter) => {
        let options = { };

        if (subFilter.options) {
          Object.keys(subFilter.options).forEach(key => {
            // for each configurable option.
            let option = subFilter.options[key];

            if (option.id) {
              if (variables[option.id]) {
                options[key] = variables[option.id];
              } else {
                if (filter.variables[option.id].type === 'color') {
                  switch (filter.variables[option.id].default) {
                    case 'primary':
                    case 'secondary':
                    case 'tertiary': {
                      filter.variables[option.id].default = (
                        CanvasUtil.canvas.colors[filter.variables[option.id].default]
                      );

                      break;
                    }
                    default: {
                      break;
                    }
                  }
                }

                options[key] = filter.variables[option.id].default;
              }
            } else {
              options[key] = option;
            }
          });
        }
        newFilters.push(new subFilter.filter(options));
      });
    }
    activeGroup.forEach((obj) => {
      obj.filters = newFilters;
      obj.applyFilters();
      obj.setCoords();
      CanvasUtil.dirtyCanvas();
    });
    active.canvas.requestRenderAll();
    CanvasUtil.canvas.fire('object:modified', { target: activeGroup[0].parent, ignoreUndo: false,filter: true});
    this.setState({
      customFilter: filter,
      filterVariables: variables,
    });
    this.onModified();
  }

  clearFilter = () => {
    const { active, activeGroup, CanvasUtil } = this.props;
    activeGroup.forEach((obj) => {
      obj.filters = [];
      obj.applyFilters();
      CanvasUtil.dirtyCanvas();
    });
    active.canvas.requestRenderAll();
    this.setState({
      customFilter: null,
      filterVariables: {},
    });
  }

  setLogoWidth = (e) => {

    const { active } = this.props;

    const { height, originalWidth } = this.state;
    let value = e.target.value;
    if(Number(value) || value === 0 || value === "0"){
      value = Math.floor(value);
      let scale = 0;
      let scaleX = 0;
      scale = ( value >= height ) ? ( height / value ) : ( value / height );
      scaleX = ( value >= originalWidth ) ? ( originalWidth / value ) : ( value / originalWidth )

      this.setState({ width : value});
      active.set("scale",scale);
      active.set("scaleX",scaleX);
      active.setCoords();
      active.canvas.requestRenderAll();
      this.forceUpdate();
    }

    this.onModified();
  }

  setLogoHeight = (e) => {
    const { active } = this.props;
    const { width, originalHeight } = this.state;
    let value = e.target.value;
    if(Number(value) || value === 0 || value === "0"){
      value = Math.floor(value);
      let scale = 0;
      let scaleY = 0;
      scale = ( value >= width ) ?   ( width / value ) : ( value / width );
      scaleY = ( value >= originalHeight ) ?   ( originalHeight / value ) : ( value / originalHeight )

      this.setState({ height : value});
      active.set("scale",scale);
      active.set("scaleY",scaleY);
      active.setCoords();
      active.canvas.requestRenderAll();
      this.forceUpdate();
    }

    this.onModified();
  }

  setWidthOrHeight = who => e => {
    let value = e.target.value;
    if(!Number(value)){return;}
    if(who === "W"){
      this.setState({ width : value});
    }else{
      this.setState({ height : value});
    }

  }

  setOpacity = (value) => {
    const { active, activeGroup } = this.props;

    activeGroup.forEach((obj) => {
      obj.set('opacity', (value / 100.0));
      obj.setCoords();
    });

    this.forceUpdate();
    active.canvas.requestRenderAll();
    this.onModified();
  }

  setChromaKeyColor = (e) => {
    const { active, activeGroup, CanvasUtil, fabric } = this.props;
    activeGroup.forEach((obj) => {
      let hasFilter = false;
      for (let i = 0; i < obj.filters.length; ++i) {
        const filter = obj.filters[i];
        if (filter.type === 'ChromaKey') {
          filter.color = e.target.value;
          hasFilter = true;
          break;
        }
      }

      if (!hasFilter) {
        const props = { color: e.target.value };
        const filter = new fabric.Image.filters.ChromaKey(props);
        obj.filters.push(filter);
      }

      obj.applyFilters();
      CanvasUtil.dirtyCanvas();
    });
    this.forceUpdate();
    active.canvas.requestRenderAll();
    this.onModified();
  }

  openCropper = () => {
    const { active } = this.props;
    let state = { cropper_open: true };

    if (MetaData.hasMetaData(active.parent, 'cropper_src')) {
      state = {
        ...state,
        cropper_crop: {
          x: +MetaData.getMetaData(active.parent, 'cropper_x'),
          y: +MetaData.getMetaData(active.parent, 'cropper_y'),
        },
        cropper_rotation: +MetaData.getMetaData(active.parent, 'cropper_rotate'),
        cropper_zoom: +MetaData.getMetaData(active.parent, 'cropper_scale'),
      };
    } else {
      state = {
        ...state,
        cropper_crop: { x: 0, y: 0 },
        cropper_rotation: 0,
        cropper_zoom: 1,
        cropper_area_pixels: null,
      };
    }
    this.setState(state);
  }

  closeCropper = () => {
    this.setState({
      cropper_open: false,
    });
  }

  createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', error => reject(error));
      image.setAttribute('crossOrigin', 'anonymous');
      image.src = url;
    })

  getRadianAngle = (degreeValue) => {
    return (degreeValue * Math.PI) / 180;
  }

  async getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
    const image = await this.createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));
    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(this.getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5,
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
      0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y,
    );
    return canvas.toDataURL('image/png');
  }

  resetCropper = () => {
    let active = this.getActiveParent();

    if (!MetaData.hasMetaData(active, 'cropper_src')) {
      return;
    }

    const filters = active.media.element.filters;
    const src = MetaData.getMetaData(active, 'cropper_src');
    MetaData.clearMetaData(active, 'cropper_src');
    MetaData.clearMetaData(active, 'cropper_x');
    MetaData.clearMetaData(active, 'cropper_y');
    MetaData.clearMetaData(active, 'cropper_rotate');
    MetaData.clearMetaData(active, 'cropper_scale');
    active.setMedia({ src, filters }, false, true);

    this.setState({
      cropper_crop: { x: 0, y: 0 },
      cropper_rotation: 0,
      cropper_zoom: 1,
      cropper_area_pixels: null,
    });
  }

  getActiveParent = () => {
    // we could be selecting either the image or clipping box.
    let { active } = this.props;

    if (!active) {
      return null;
    }

    if (active.parent) {
      return active.parent;
    }

    return null;
  }

  applyCropper = async () => {
    let active = this.getActiveParent();

    try {
      const filters = active.media.element.filters;
      let src;

      if (MetaData.hasMetaData(active, 'cropper_src')) {
        src = MetaData.getMetaData(active, 'cropper_src');
      } else {
        src = active.media.element.getSrc();
        MetaData.setMetaData(active, 'cropper_src', src);
      }

      const croppedImage = await this.getCroppedImg(
        src, this.state.cropper_area_pixels,
        this.state.cropper_rotation,
      );

      MetaData.setMetaData(
        active, 'cropper_x',
        this.state.cropper_crop.x
      );

      MetaData.setMetaData(
        active, 'cropper_y',
        this.state.cropper_crop.y
      );

      MetaData.setMetaData(
        active, 'cropper_rotate',
        this.state.cropper_rotation
      );

      MetaData.setMetaData(
        active, 'cropper_scale',
        this.state.cropper_zoom
      );

      active.setMedia({ src: croppedImage, filters }, false, true);
      active.canvas.requestRenderAll();
      this.closeCropper();
    } catch (e) {
      console.error(e);
    }
  }

  getFileSize = async (url) => {
    if (url.startsWith('data:')) {
      const key = 'base64;';
      const start = url.indexOf(key);
      const length = (url.length - (start + key.length));
      return Promise.resolve(length * 3 / 4);
    }

    return new Promise((resolve, reject) => {
      let request = new XMLHttpRequest();
      request.open('HEAD', url, true);

      request.onload = () => {
        let length = request.getResponseHeader('content-length');
        return resolve(Number(length));
      };

      request.onerror = () => {
        return reject(request);
      }

      request.send();
    });
  }

  openBackgroundRemoval = async () => {
    let backgroundRemovalCredits = this.state.backgroundRemoval_credits;
    if (backgroundRemovalCredits === -1) {
      if (this.props && this.props.data && this.props.data.me && this.props.data.me.client && this.props.data.me.client.credits > 0) {
        backgroundRemovalCredits = this.props.data.me.client.credits;
      } else {
        backgroundRemovalCredits = 0;
      }
    }

    let originalImageId = this.state.originalImageId;
    let backgroundRemoval_originalImage = { };
    if (originalImageId) {
      const originalImageIdArray = originalImageId.split("/");
      originalImageId = originalImageIdArray[originalImageIdArray.length - 1];
      const originalImageIdArray2 = originalImageId.split(".");
      originalImageId = originalImageIdArray2[0];
      this.props.imageSearch.refetch({
        id: originalImageId,
      }).then((response) => {
        if (response && response.data && response.data.fileIndiv) {
          backgroundRemoval_originalImage = response.data.fileIndiv;
          if (backgroundRemoval_originalImage && backgroundRemoval_originalImage.children && backgroundRemoval_originalImage.children[0] && backgroundRemoval_originalImage.children[0].id) {
            this.setState({
              backgroundRemoval_open: true,
              backgroundRemoval_loading: false,
              backgroundRemoval_needsApproval: false,
              backgroundRemoval_src: `https://${backgroundRemoval_originalImage.children[0].bucket}.s3.us-east-2.amazonaws.com/${this.props.data.me.client.id}/${backgroundRemoval_originalImage.children[0].id}.${backgroundRemoval_originalImage.children[0].extension}`,
              backgroundRemoval_credits: backgroundRemovalCredits,
              backgroundRemoval_originalImage,
              originalImageId,
            });

          } else {
            this.setState({
              backgroundRemoval_open: true,
              backgroundRemoval_loading: false,
              backgroundRemoval_needsApproval: true,
              backgroundRemoval_src: '',
              backgroundRemoval_credits: backgroundRemovalCredits,
              backgroundRemoval_originalImage,
              originalImageId,
            });
          }
        } else {
          this.setState({
            backgroundRemoval_open: true,
            backgroundRemoval_loading: false,
            backgroundRemoval_needsApproval: true,
            backgroundRemoval_src: '',
            backgroundRemoval_credits: backgroundRemovalCredits,
            backgroundRemoval_originalImage,
            originalImageId,
          });
        }
      });
    } else {
      this.setState({
        backgroundRemoval_open: true,
        backgroundRemoval_loading: false,
        backgroundRemoval_needsApproval: true,
        backgroundRemoval_src: '',
        backgroundRemoval_credits: backgroundRemovalCredits,
        backgroundRemoval_originalImage,
        originalImageId,
      });
    }
  }


  closeBackgroundRemoval = () => {
    this.setState({
      backgroundRemoval_open: false,
      backgroundRemoval_needsApproval: true,
    });
  }

  applyBackgroundRemoval = () => {
    const { createFile, addLabels } = this.props;
    const active = this.getActiveParent();
    const filters = active.media.element.filters;
    if (this.state.backgroundRemoval_save && this.state.originalImageId) {
      if (this.state.backgroundRemoval_originalImage && this.state.backgroundRemoval_originalImage.children && this.state.backgroundRemoval_originalImage.children[0] && this.state.backgroundRemoval_originalImage.children[0].id) {
        active.setMedia({src: this.state.backgroundRemoval_src, filters}, false, true);
        active.canvas.requestRenderAll();
        this.setState({
          backgroundRemoval_open: false,
          backgroundRemoval_needsApproval: true,
        });
      } else {
        const parentId = this.state.originalImageId;
        let folderId = null;
        if (this.state.backgroundRemoval_originalImage.folder && this.state.backgroundRemoval_originalImage.folder.id) {
          folderId = this.state.backgroundRemoval_originalImage.folder.id
        }
        createFile({
          variables: {
            contentType: "image/png",
            name: this.state.backgroundRemoval_originalImage.name + '_RMBG',
            size: this.state.backgroundRemoval_originalImage.size,
            bucket: this.state.backgroundRemoval_originalImage.bucket,
            type: this.state.mediaType,
            extension: 'png',
            folderId,
            parentId,
          },
        }).then((response) => {
          const newFile = response.data.createFile;
          let action = new ScoreshotsPut(
            this.state.backgroundRemoval_originalImage.bucket,
            `${newFile.id}.png`
          );
          fetch(this.state.backgroundRemoval_src)
            .then((res) => res.blob())
            .then((blob) => {
              action.put(blob, "image/png", () => {
                addLabels({
                  variables: {
                    bucket: this.state.backgroundRemoval_originalImage.bucket,
                    key: `${this.props.data.me.client.id}/${newFile.id}.png`,
                    fileId: newFile.id,
                  },
                });
              });
              active.setMedia({src:this.state.backgroundRemoval_src, filters}, false, true);
              active.canvas.requestRenderAll();
              this.setState({
                backgroundRemoval_open: false,
                backgroundRemoval_needsApproval: true,
              });
            });
        });
      }
    } else {
      active.setMedia({src: this.state.backgroundRemoval_src, filters}, false, true);
      active.canvas.requestRenderAll();
      this.setState({
        backgroundRemoval_open: false,
        backgroundRemoval_needsApproval: true,
      });
    }
  }

  async myRemoveBgFunction(dataurl) {
    const result = await removeBackgroundFromImageBase64({
      base64img: dataurl,
      apiKey: 'mdDhzXMb9X9s4rnq1RtVTnio',
      size: 'auto',
      type: 'person',
    });
    return result.base64img;
  }
  makeid(length) {
    var result = [];
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result.push(characters.charAt(Math.floor(Math.random() *
        charactersLength)));
    }
    return result.join('');
  }

  async doBackgroundRemoval(src) {
    const { createFile, addLabels } = this.props
    this.setState({
      backgroundRemoval_loading: true,
      backgroundRemoval_needsApproval: false,
    });
    if(src.substring(0,4) !== 'data'){
      src += `?(+${new Date().getDate()})`
    }
    const image = await this.createImage(src);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = image.width;
    canvas.height = image.height;

    ctx.drawImage(
      image,
      0,
      0,
    );

    const dataurl = await canvas.toDataURL('image/png');
    const removed_dataurl = await this.myRemoveBgFunction(dataurl);
    const new_credit_count = this.state.backgroundRemoval_credits - 1;

    let settings = {}

    if(Object.keys(this.state.backgroundRemoval_originalImage).length !== 0){
      this.props.setSubscription({
        variables: {
          id: this.props.data.me.client.id,
          credits: new_credit_count,
        },
      })
        .then(() => {
          this.setState({
            backgroundRemoval_loading: false,
            backgroundRemoval_needsApproval: false,
            backgroundRemoval_src: 'data:image/png;base64,' + removed_dataurl,
            backgroundRemoval_credits: new_credit_count,
          });
        });
    } else {
      settings = {
        bucket: 'ss3-clientmedia',
        name: this.makeid(10) + '_',
        contentType: 'image/png',
        type: this.state.mediaType,
        extension: 'png',
        size: await this.getFileSize('data:image/png;base64,' + removed_dataurl)
      }
      createFile({
        variables:{
          name: settings.name,
          contentType: settings.contentType,
          size: settings.size,
          extension: settings.extension,
          bucket: settings.bucket,
          type: settings.type
        }
      }).then(response => {
        const newFile = response.data.createFile;
        let action = new ScoreshotsPut(
          'ss3-clientmedia',
          `${newFile.id}.png`
        )
        fetch('data:image/png;base64,' + removed_dataurl)
          .then(res => res.blob())
          .then(blob => {
            action.put(blob, "image/png", () => {
              addLabels({
                variables: {
                  bucket: 'ss3-clientmedia',
                  key: `${this.props.data.me.client.id}/${newFile.id}.png`,
                  fileId: newFile.id,
                },
              });
              this.props.setSubscription({
                variables: {
                  id: this.props.data.me.client.id,
                  credits: new_credit_count,
                },
              })
                .then(() => {
                  this.setState({
                    backgroundRemoval_loading: false,
                    backgroundRemoval_needsApproval: false,
                    backgroundRemoval_src: `https://${settings.bucket}.s3.us-east-2.amazonaws.com/${this.props.data.me.client.id}/${newFile.id}.png?${new Date().getDate()}`,
                    backgroundRemoval_credits: new_credit_count,
                  });
                });
            })
          })
      })
    }
  }

  setBackgroundRemovalSave = () => {
    const { backgroundRemoval_save } = this.state;
    let _hasBackgroundRemoval = backgroundRemoval_save ? false : true;
    this.setState({ backgroundRemoval_save: _hasBackgroundRemoval });
  }

  renderBackgroundRemoval = () => {
    const { classes } = this.props;
    let active = this.getActiveParent();

    let src;

    if (active && active.media && active.media.element) {
      src = active.media.element.getSrc();
    }

    let disableRemoveBGButton = false;
    if (this.state.backgroundRemoval_credits <= 0) {
      disableRemoveBGButton = true;
    }
    let newsrc = String(src)
    if(newsrc.includes('?t=')){
      let srcarr = newsrc.split('t=')
      newsrc = srcarr[0] + srcarr[1]
    }


    if (this.state.backgroundRemoval_needsApproval) {
      return (
        <Dialog open={this.state.backgroundRemoval_open}>
          <DialogTitle>Remove Background</DialogTitle>
          <DialogContent className={classes.backgroundRemovalRoot}>
            <div className={classes.divBackgroundRemoval}>
              <div className={classes.divBackgroundRemovalContainer}>
                <img src={src} alt="Original" className={classes.divBackgroundRemovalImg} />
              </div>
              <div className={classes.divBackgroundRemovalContainer}>
                <Typography variant="subtitle1" style={{textAlign: 'center', marginTop: '30px'}}>
                  You have {this.state.backgroundRemoval_credits} credits left.
                </Typography>
                <Typography variant="subtitle1" style={{textAlign: 'center', marginTop: '10px'}}>
                  Would you like to use one?
                </Typography>
                <Typography variant="subtitle1" style={{textAlign: 'center', marginTop: '20px'}}>
                  <Button
                    color="primary"
                    variant='contained'
                    disabled={disableRemoveBGButton}
                    onClick={() => this.doBackgroundRemoval(newsrc)}
                  >
                    Remove Background
                  </Button>
                </Typography>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={this.closeBackgroundRemoval}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )
    }

    if (this.state.backgroundRemoval_loading) {
      return (
        <Dialog open={this.state.backgroundRemoval_open}>
          <DialogTitle>Remove Background</DialogTitle>
          <DialogContent className={classes.backgroundRemovalRoot}>
            <div className={classes.divBackgroundRemoval}>
              <div className={classes.divBackgroundRemovalContainer}>
                <img src={src} alt="Original" className={classes.divBackgroundRemovalImg} />
              </div>
              <div className={classes.divBackgroundRemovalContainer}>
                <Typography variant="h6" style={{textAlign: 'center', marginTop: '30px'}}>
                  <CircularProgress size={56} />
                </Typography>
                <Typography variant="h6" style={{textAlign: 'center', marginTop: '10px'}}>
                  Please wait, your image is processing...
                </Typography>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant='contained'
              onClick={this.closeBackgroundRemoval}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )
    }

    let saveToMedia = null;
    if (this.state.originalImageId) {
      if (this.state.backgroundRemoval_originalImage && this.state.backgroundRemoval_originalImage.children && this.state.backgroundRemoval_originalImage.children[0] && this.state.backgroundRemoval_originalImage.children[0].id) {
        saveToMedia = null;
      } else {
        saveToMedia = (
          <FormControlLabel
            label="Save to your Media Library"
            control={
              <Checkbox
                color="primary"
                checked={this.state.backgroundRemoval_save}
                onChange={() => this.setBackgroundRemovalSave()}
              />
            }
          />
        )
      }
    }


    return (
      <Dialog open={this.state.backgroundRemoval_open}>
        <DialogTitle>Remove Background</DialogTitle>
        <DialogContent className={classes.backgroundRemovalRoot}>
          <div className={classes.divBackgroundRemoval}>
            <div className={classes.divBackgroundRemovalContainer}>
              <img src={src} alt="Original" className={classes.divBackgroundRemovalImg} />
            </div>
            <div className={classes.divBackgroundRemovalContainer}>
              <img src={this.state.backgroundRemoval_src} alt="Edited" className={classes.divBackgroundRemovalImg} />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {saveToMedia}
          <Button
            color="primary"
            variant="contained"
            onClick={this.closeBackgroundRemoval}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={this.applyBackgroundRemoval}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderCropper = () => {
    const { classes } = this.props;
    let active = this.getActiveParent();

    if (active === null) {
      return null;
    }

    let src;

    if (MetaData.hasMetaData(active, 'cropper_src')) {
      src = MetaData.getMetaData(active, 'cropper_src');
    } else if (active && active.media && active.media.element) {
      src = active.media.element.getSrc();
    }

    return (
      <Dialog open={this.state.cropper_open}>
        <DialogTitle>Crop Image</DialogTitle>
        <DialogContent className={classes.cropperRoot}>
          <div className={classes.cropContainer}>
            <Cropper
              image={src}
              crop={this.state.cropper_crop}
              rotation={this.state.cropper_rotation}
              zoom={this.state.cropper_zoom}
              aspect={ active.width / active.height }
              onCropChange={(e) => this.setState({ cropper_crop: e })}
              onRotationChange={(e) => this.setState({ cropper_rotation: e })}
              onCropComplete={(_, pixels) => this.setState({ cropper_area_pixels: pixels })}
              onZoomChange={(e) => this.setState({ cropper_zoom: e })}
            />
          </div>
          <Grid container spacing="1">
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.heading}>
                Zoom
              </Typography>
              <div style={{ margin: 8 }}>
                <Slider
                  value={this.state.cropper_zoom}
                  min={1} max={3} step={0.1}
                  aria-label="Zoom"
                  color="primary"
                  onChange={(e) => this.setState({ cropper_zoom: e })}
                  trackStyle={[{
                    backgroundColor: this.props.theme.palette.primary.light,
                  }]}
                  handleStyle={[{
                    borderColor: this.props.theme.palette.primary.light,
                  }]}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.heading}>
                Rotation
              </Typography>
              <div style={{ margin: 8 }}>
                <Slider
                  value={this.state.cropper_rotation}
                  min={0} max={360} step={1}
                  aria-label="Rotation"
                  color="primary"
                  onChange={(e) => this.setState({ cropper_rotation: e })}
                  trackStyle={[{
                    backgroundColor: this.props.theme.palette.primary.light,
                  }]}
                  handleStyle={[{
                    borderColor: this.props.theme.palette.primary.light,
                  }]}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={this.closeCropper}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => this.applyCropper()}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  setAngle = (angle) => {
    const { activeGroup, active } = this.props;

    activeGroup.forEach((object) => {
      object.rotate(angle);
      object.setCoords();
    });

    active.canvas.requestRenderAll();
    this.forceUpdate();
  }

  setAngleLeft = () => {
    const { activeGroup, active } = this.props;

    activeGroup.forEach((object) => {
      object.rotate((object.angle + (360.0 - 45.0)) % 360.0);
      object.setCoords();
    });

    active.canvas.requestRenderAll();
    this.forceUpdate();
  }

  setAngleRight = () => {
    const { activeGroup, active } = this.props;

    activeGroup.forEach((object) => {
      object.rotate((object.angle + 45.0) % 360.0);
      object.setCoords();
    });

    active.canvas.requestRenderAll();
    this.forceUpdate();
  }


  render() {
    const { classes } = this.props;
    let { active, activeGroup } = this.props;
    const { width , height} = this.state;

    if (active.element) {
      active = active.element;
    }

    const filters = this.getStaticFilters();
    const dynamicFilters = this.getDynamicFilters();
    const minScale = active.minScale ? active.minScale : 1;
    const setWidth = this.setWidthOrHeight("W");
    const setHeight = this.setWidthOrHeight("H");
    // compute filter values;
    const filterVal = {
      Brightness: {
        brightness: 0,
        scale: 100,
      },
      Contrast: {
        contrast: 0,
        scale: 100,
      },
      Saturation: {
        saturation: 0,
        scale: 100,
      },
      Blur: {
        blur: 0,
        scale: 100,
      },
      ChromaKey: {
        threshold: 0,
        smoothing: 0,
        scale: 100,
        color: '#00ff00'
      },
    };
    if (active.filters) {
      for (let i = 0; i < active.filters.length; i += 1) {
        const filter = active.filters[i];
        // for each filter, update the type object with the available props.
        const { type } = filter;
        Object.keys(filter).forEach((prop) => {
          if (filterVal[type]) {
            if (typeof(filterVal[type][prop]) === 'number') {
              const scale = filterVal[type].scale ?
                filterVal[type].scale :
                100;
              filterVal[type][prop] = filter[prop] * scale;
            } else {
              filterVal[type][prop] = filter[prop];
            }
          }
        });
      }
    }

    const isBackground = (this.props.active.parent && this.props.active.parent.type === "backgroundBox");
    let textStyle = (isBackground ?  { display : "none" } : { display : "" });

    // <<< OPACITY HACK >>>
    let active_opacity = (active.opacity);

    if (active_opacity === undefined) {
      active_opacity = 1.0;
    }

    let removeBackgroundButton;
    if (this && this.props && this.props.data && this.props.data.me && this.props.data.me.client && this.props.data.me.client.hasBackgroundRemoval === true) {
      const MEGABYTES = (1024 * 1024);

      if (
        (active.width > 4000) ||
        (active.height > 4000) ||
        (this.state.image_file_size === null) ||
        (this.state.image_file_size > MEGABYTES * 10)
      ) {
        removeBackgroundButton = (
          <Tooltip
            aria-label="notsupported"
            title={
              "Image is too large. Images may not be over 10 megapixels or 10MB."
            }
          >
            <Button
              color="primary"
              variant="outlined"
              className={classes.button}
            >
              Remove Background
            </Button>
          </Tooltip>
        );
      } else {
        removeBackgroundButton = (
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={this.openBackgroundRemoval}
          >
            Remove Background
          </Button>
        );
      }
    }
    return (
      <div className={classes.root}>
        {(!isMobile) &&
        <>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={this.openCropper}
          >
            Crop Image
          </Button>
          <UserRoleConsumer>
            {(permissions) => (
              (permissions !== null) &&
              (!permissions.get('editor:remove_bg')) &&
              removeBackgroundButton
            )}
          </UserRoleConsumer>
          <Collapse in={
            (MetaData.hasMetaData(this.getActiveParent(), 'cropper_src'))
          }>
            <Grow in={
              (MetaData.hasMetaData(this.getActiveParent(), 'cropper_src'))
            }>
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={() => this.resetCropper()}
              >
                Clear Crop
              </Button>
            </Grow>
          </Collapse>
        </>
        }
        <Typography variant="h6" className={classes.heading}>
          Scale
        </Typography>
        <Slider
          value={((active.scaleX / minScale) - 1) * 50}
          onChange={this.scale}
          onAfterChange={this.onModified}
          trackStyle={[{
            backgroundColor: this.props.theme.palette.primary.light,
          }]}
          handleStyle={[{
            borderColor: this.props.theme.palette.primary.light,
          }]}
        />
        {
          isMobileOnly &&
          <React.Fragment>
            <Typography variant="h6" className={classes.heading}>
              Rotation
            </Typography>
            <Slider
              value={active.angle}
              min={0}
              max={360}
              onChange={this.rotate}
              trackStyle={[{
                backgroundColor: this.props.theme.palette.primary.light,
              }]}
              handleStyle={[{
                borderColor: this.props.theme.palette.primary.light,
              }]}
            />
          </React.Fragment>
        }
        <Typography variant="h6" className={classes.heading}>
          Adjustments
        </Typography>
        <Typography variant="caption">
          Opacity
        </Typography>
        <SliderWithInput
          min={0}
          max={100}
          value={active_opacity * 100}
          onChange={(e) => this.setOpacity(e)}
          onAfterChange={this.onModified}
          trackStyle={[{
            backgroundColor: this.props.theme.palette.primary.light,
          }]}
          handleStyle={[{
            borderColor: this.props.theme.palette.primary.light,
          }]}
        />
        <Typography variant="caption">
          Brightness
        </Typography>
        <SliderWithInput
          value={filterVal.Brightness.brightness}
          onChange={this.filterSet('Brightness', 'brightness', 100)}
          onAfterChange={this.onModified}
          min={-100}
          max={100}
          trackStyle={[{
            backgroundColor: this.props.theme.palette.primary.light,
          }]}
          handleStyle={[{
            borderColor: this.props.theme.palette.primary.light,
          }]}
        />
        <Typography variant="caption">
          Contrast
        </Typography>
        <SliderWithInput
          value={filterVal.Contrast.contrast}
          onChange={this.filterSet('Contrast', 'contrast', 100)}
          onAfterChange={this.onModified}
          min={-100}
          max={100}
          trackStyle={[{
            backgroundColor: this.props.theme.palette.primary.light,
          }]}
          handleStyle={[{
            borderColor: this.props.theme.palette.primary.light,
          }]}
        />
        <Typography variant="caption">
          Saturation
        </Typography>
        <SliderWithInput
          value={filterVal.Saturation.saturation}
          onChange={this.filterSet('Saturation', 'saturation', 100)}
          onAfterChange={this.onModified}
          min={-100}
          max={100}
          trackStyle={[{
            backgroundColor: this.props.theme.palette.primary.light,
          }]}
          handleStyle={[{
            borderColor: this.props.theme.palette.primary.light,
          }]}
        />
        <Typography variant="caption">
          Blur
        </Typography>
        <SliderWithInput
          value={filterVal.Blur.blur}
          onChange={this.filterSet('Blur', 'blur', 100)}
          onAfterChange={this.onModified}
          min={0}
          max={100}
          trackStyle={[{
            backgroundColor: this.props.theme.palette.primary.light,
          }]}
          handleStyle={[{
            borderColor: this.props.theme.palette.primary.light,
          }]}
        />
        <Divider/>

        <Typography
          variant="caption"
          style={{ display: 'block', marginTop: 8 }}
        >
          Rotate (Degrees)
        </Typography>
        <TextField
          type="number"
          margin="normal"
          inputProps={{ min: 0 }}
          value={Math.round(active.angle)}
          onChange={(e) => this.setAngle(+e.target.value)}
          onBlur={(e) => this.setAngle(+e.target.value)}
          style={{ marginTop: 0, paddingTop: 0, width: "50%" }}
        />
        <IconButton
          color={'primary'}
          onClick={() => this.setAngleLeft()}
        >
          <RotateLeft/>
        </IconButton>
        <IconButton
          color={'primary'}
          onClick={() => this.setAngleRight()}
        >
          <RotateRight/>
        </IconButton>
        <Divider/>
        {
          (!isMobileOnly) &&
          <>
            <Typography variant="h6" className={classes.heading}>
              Remove Background
            </Typography>
            <RadioGroup
              style={{display: 'flex', flexDirection: 'row'}}
              name="chroma-color-source"
              value={filterVal.ChromaKey.color}
              onChange={this.setChromaKeyColor}
            >
              <FormControlLabel value="#00ff00" control={<Radio />} label="Green" />
              <FormControlLabel value="#0000ff" control={<Radio />} label="Blue" />
            </RadioGroup>
            <Typography variant="caption">
              Intensity
            </Typography>
            <SliderWithInput
              value={filterVal.ChromaKey.threshold}
              onChange={this.filterSet('ChromaKey', 'threshold', 100)}
              onAfterChange={this.onModified}
              min={0}
              max={100}
              trackStyle={[{
                backgroundColor: this.props.theme.palette.primary.light,
              }]}
              handleStyle={[{
                borderColor: this.props.theme.palette.primary.light,
              }]}
            />
            <Typography variant="caption">
              Smoothing
            </Typography>
            <SliderWithInput
              value={filterVal.ChromaKey.smoothing}
              onChange={this.filterSet('ChromaKey', 'smoothing', 100)}
              onAfterChange={this.onModified}
              min={0}
              max={100}
              trackStyle={[{
                backgroundColor: this.props.theme.palette.primary.light,
              }]}
              handleStyle={[{
                borderColor: this.props.theme.palette.primary.light,
              }]}
            />
          </>
        }
        <TextField
          style={textStyle}
          id="standard-name-width"
          label="Width"
          value={width}
          onChange={setWidth}
          onBlur={this.setLogoWidth}
          margin="normal"
        />
        <TextField
          style={textStyle}
          id="standard-name-height"
          label="Height"
          value={height}
          onChange={setHeight}
          onBlur={this.setLogoHeight}
          margin="normal"
        />
        <Typography variant="h6" className={classes.heading}>
          Configurable Filters
        </Typography>
        {dynamicFilters}
        <Typography variant="h6" className={classes.heading}>
          Filter Presets
        </Typography>
        {filters}
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={this.clearFilter}
        >
          Clear Filter
        </Button>
        {
          (!isMobileOnly) &&
          <div className={classes.shadowEditor}>
            <Divider/>
            <Typography variant="h6" className={classes.subheading}>
              Drop Shadow
            </Typography>
            <ShadowEditor active={active} activeGroup={activeGroup} color={this.props.theme.palette.primary.light} />
          </div>
        }
        {this.renderCropper()}
        {this.renderBackgroundRemoval()}
      </div>

    );
  }
}

export default compose(
  graphql(GET_USER_INFO),
  graphql(SET_SUB, { name: 'setSubscription' }),
  graphql(ADD_LABELS, { name: 'addLabels' }),
  graphql(UPLOAD_FILE, { name: 'createFile' }),
  graphql(IMAGE_SEARCH, { name: 'imageSearch' }),
  withStyles(STYLES, { withTheme: true }),
)(ImageEditor);
