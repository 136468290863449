
// -------------------------------------------------------------------------- //

import * as React from 'react';

import {
  Typography,
} from '@material-ui/core';

// -------------------------------------------------------------------------- //

const StripeDict = {
  proplans: [
    {
      plan: 'STARTER',
      tiers: [
        {
          name: 'YEARLY',
          id: 'plan_Eu43gdVpK49Kn0',
          price: 595,
        },
        {
          name: 'MONTHLY',
          id: 'plan_Eu438S6D3SEBmi',
          price: 99,
        },
      ],
      description: [
        <Typography>5 User Accounts</Typography>,
        <Typography>100 Pre-Selected Templates</Typography>,
        <Typography>Unlimited Posts</Typography>,
        <Typography>Annual license</Typography>,
        <Typography>5GB Storage</Typography>,
      ],
    },
    {
      plan: 'PRO',
      tiers: [
        {
          name: 'YEARLY',
          id: 'plan_Eu45OdmFxjaBgE',
          price: 1500,
        },
        {
          name: 'MONTHLY',
          id: 'plan_Eu44vjHp6lKfSQ',
          price: 149,
        },
      ],
      description: [
        <Typography>10 User Accounts</Typography>,
        <Typography>50+ Image Based Templates</Typography>,
        <Typography>Background Removal Tool (<strike>50</strike>  100 Credits)</Typography>,
        <Typography>Unlimited Posts</Typography>,
        <Typography>Annual license</Typography>,
        <Typography>5GB Storage</Typography>,
      ],
    },
    {
      plan: 'ALL-STAR',
      tiers: [{
          name: 'YEARLY',
          id: 'plan_Eu45JxsG9aybKh',
          price: 2400,
        },
        {
          name: 'MONTHLY',
          id: 'plan_Eu45fuVzzvbE2S',
          price: 199,
        },
      ],
      description: [
        <Typography>Unlimited User Accounts</Typography>,
        <Typography>500+ Image,Video and Animated Templates</Typography>,
        <Typography>Background Removal Tool (<strike>500</strike> Unlimited Credits)</Typography>,
        <Typography>Unlimited Posts</Typography>,
        <Typography>Annual license</Typography>,
        <Typography>10GB Storage</Typography>,
      ],
    },
  ],
  high : [],
}

// -------------------------------------------------------------------------- //

export default StripeDict;

// -------------------------------------------------------------------------- //
