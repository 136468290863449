
// -------------------------------------------------------------------------- //

import * as React from 'react';

import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';

import { Form, Field } from 'react-final-form';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';

import { ClientSearchContext } from './search';
import ClientTable from './ClientTable';
import TextField from '../../material/TextField';

// -------------------------------------------------------------------------- //

const ADD_CLIENT = gql`
  mutation addClient($name: String!, $firstname: String!, $lastname: String!, $email: String!) {
    createClient (
      name: $name
      firstname: $firstname
      lastname: $lastname
      email: $email
    ) {
      id
      users {
        id
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  app_bar: {
    flex: 0,
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  root: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(3),
  },
});

// -------------------------------------------------------------------------- //

class Clients extends React.Component {

  state = {
    create_dialog: false,
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        {this.renderToolbar()}
        <div className={classes.root}>
          {this.renderClientTable()}
        </div>
        {this.renderCreateDialog()}
      </React.Fragment>
    );
  }

  renderToolbar = () => {
    const { classes } = this.props;

    return (
      <AppBar color="primary" elevation={0} className={classes.app_bar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            Clients
          </Typography>
          <Button color="inherit" onClick={() => this.openCreateDialog()}>
            Create
          </Button>
        </Toolbar>
      </AppBar>
    );
  }

  renderClientTable = () => {
    return (
      <Paper>
        <ClientSearchContext.Consumer>
        {(search_parameters) => (
          <ClientTable search_parameters={search_parameters}/>
        )}
        </ClientSearchContext.Consumer>
      </Paper>
    );
  }

  renderCreateDialog = () => {
    const { classes } = this.props;
    const { create_dialog } = this.state;

    return (
      <Dialog
        open={create_dialog}
        onClose={() => this.closeCreateDialog()}
      >
        <DialogTitle>Add New User</DialogTitle>
        <DialogContent>
          <Form
            onSubmit={data => this.createUser(data)}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name="name"
                  id="name"
                  label="Client Name"
                  component={TextField}
                  className={classes.field}
                />
                <Field
                  name="firstname"
                  id="firstname"
                  label="User First Name"
                  component={TextField}
                  className={classes.field}
                />
                <Field
                  name="lastname"
                  id="lastname"
                  label="User Last Name"
                  component={TextField}
                  className={classes.field}
                />
                <Field
                  name="email"
                  id="email"
                  label="User Email"
                  type="email"
                  component={TextField}
                  className={classes.field}
                />
                <Button
                  fullWidth
                  variant="raised"
                  color="secondary"
                  onClick={handleSubmit}
                  className={classes.field}
                >
                  Save
                </Button>
              </form>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.closeCreateDialog()} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  openCreateDialog = () => {
    this.setState({ create_dialog: true });
  }

  closeCreateDialog = () => {
    this.setState({ create_dialog: false });
  }

  createUser = (data) => {
    const { addClient: mutation, history } = this.props;

    mutation({ variables: {
        name: data.name,
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
    }}).then(({ data }) => {
      history.replace(`/admin/clients/${data.createClient.id}`);
    });
  }

}

// -------------------------------------------------------------------------- //

export default compose(
  graphql(ADD_CLIENT, { name: 'addClient' }),
  withRouter,
  withStyles(STYLES),
)(Clients);

// -------------------------------------------------------------------------- //
