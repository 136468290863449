
// -------------------------------------------------------------------------- //

import * as React from 'react';

import {
  AppBar,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  Delete,
  Pencil,
  Plus,
  ChevronLeft as ChevronLeftIcon,
} from 'mdi-material-ui';

import { Form, Field } from 'react-final-form';
import { graphql, compose, Mutation } from 'react-apollo';
import gql from 'graphql-tag';

import { withRouter } from 'react-router';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import SelectMultiple from '../../material/SelectMultiple';
import Auth from '../../auth/auth';
import { Subscription } from '../subscription';
// import ScoreshotsPut from "../../../scoreshotsapi/Put";

// -------------------------------------------------------------------------- //

const auth = new Auth();

// -------------------------------------------------------------------------- //

const STYLES = (theme) => ({
  grid: {
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(1),
    position: 'relative',

  },
  formField: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  form_field_half: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: '50%',
  },
  form_field_full: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: '100%',
  },
  formControl: {
    marginLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    marginTop: 10,
    width: '50%',
  },
  selectField: {
    width: '100%',
    marginTop: 10,
  },
  addSub: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  root: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(3),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  flex: {
    flex: 1,
  },
});

// -------------------------------------------------------------------------- //

const GET_CLIENT = gql`
  query loadClient($id: ID!) {
    client(id: $id) {
      id
      address
      city
      clientFlags
      hasVideo
      hasBackgroundRemoval
      plan_type
      prestosportsId
      subscription_start
      subscription_end
      credits
      total_users_allowed
      account_status
      total_allowed_storage
      executive {
        firstname
        lastname
      }
      nickName
      name
      notes
      phone
      state
      subscriptions {
        id
        categories {
          id
          name
        }
        expiration
      }
      url
      users {
        id
        firstname
        lastname
        email
        type
      }
      zip
      division
      is_trial
    }
    admins {
      firstname
      lastname
    }
    categories {
      id
      name
    }
  }
`;

// -------------------------------------------------------------------------- //

const SET_HAS_VIDEO = gql`
  mutation ex( $id: ID!, $hasVideo: Boolean!) {
    setHasVideo(id: $id, hasVideo: $hasVideo) {
      id
      name
      hasVideo
    }
  }
`;

// -------------------------------------------------------------------------- //

const SET_HAS_BACKGROUND_REMOVAL = gql`
  mutation extwo( $id: ID!, $hasBackgroundRemoval: Boolean!) {
    setHasBackgroundRemoval(id: $id, hasBackgroundRemoval: $hasBackgroundRemoval) {
      id
      name
      hasBackgroundRemoval
    }
  }
`;

// -------------------------------------------------------------------------- //

const SET_IS_TRIAL = gql`
  mutation exthree( $id: ID!, $isTrial: Boolean!) {
    setIsTrial(id: $id, isTrial: $isTrial) {
      id
      name
      hasBackgroundRemoval
    }
  }
`;

// -------------------------------------------------------------------------- //

const ADD_SUBSCRIPTION = gql`
  mutation addSubscription($clientId: ID!, $expiration: DateTime!, $categories: [ID!]) {
    subscribe (
      expiration: $expiration
      clientId: $clientId
      categoryIds: $categories
    ) {
      id
      subscriptions {
        categories {
          id
        }
        expiration
        id
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const EDIT_SUBSCRIPTION = gql`
  mutation editSubscription($subId: ID!,$expiration: DateTime!,$categories: [ID!]) {
    updateSubscription (
      id: $subId
      expiration: $expiration
      categoriesIds: $categories
    ) {
      id
    }
  }
`;

// -------------------------------------------------------------------------- //

const DELETE_SUBSCRIPTION = gql`
  mutation deleteSubscription($clientId: ID!, $subId: ID!) {
    deleteSubscription (
      clientId: $clientId
      subId: $subId
    ) {
      id
      subscriptions {
        id
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

const EDIT_CLIENT = gql`
  mutation editClient($id: ID!, $name: String, $nickName: String, $address: String, $city: String, $zip: String, $state: String, $phone: String, $notes: String, $division: String, $prestosportsId: String) {
    updateClient (
      id: $id
      name: $name
      nickName: $nickName
      address: $address
      city: $city
      zip: $zip
      state: $state
      phone: $phone
      notes: $notes
      division: $division
      prestosportsId: $prestosportsId
    ) {
      id
    }
  }
`;

// -------------------------------------------------------------------------- //

const EDIT_CLIENT2 = gql`
  mutation addClientFlags($id: ID!, $clientFlags: [CLIENT_FLAGS]!) {
    addClientFlags (
      id: $id
      clientflags : $clientFlags
    ) {
      id
    }
  }
`;

// -------------------------------------------------------------------------- //

const RESET_PASSWORD = gql`
  mutation resetPassword($email: String!) {
    resetPassword (
      email: $email
    )
  }
`;

// const UPDATE_ACCOUNT_ID = gql`
//   mutation setUserAccountId($id: ID!,$accountId: String!) {
//     setUserAccountId (
//       id: $id,
//       accountId:$accountId
//     )
//   }
// `;

// -------------------------------------------------------------------------- //

const columnData = [
  {
    id: 'name', numeric: false, disablePadding: true, label: 'Name',
  },
  {
    id: 'email', numeric: false, disablePadding: true, label: 'Email',
  },
  {
    id: 'type', numeric: false, disablePadding: true, label: 'User Type',
  },
  {
    id: 'admin', numeric: false, disablePadding: true, label: 'Administrative',
  },
];

const DIVISIONS = [
  {
    value: '',
    name: 'Nothing Selected',
  },
  {
    value: 'Division 1',
    name: 'Division 1',
  },
  {
    value: 'Division 2',
    name: 'Division 2',
  },
  {
    value: 'Division 3',
    name: 'Division 3',
  },
  {
    value: 'NAIA',
    name: 'NAIA',
  },
  {
    value: 'Junior College',
    name: 'Junior College',
  },
  {
    value: 'High School',
    name: 'High School',
  },
  {
    value: 'Pro',
    name: 'Pro',
  },
  {
    value: 'Other',
    name: 'Other',
  },
];

// -------------------------------------------------------------------------- //

class ClientEditor extends React.Component {
  state = {
    subDialog: false,
    subId: null,
    clientFlags: undefined,
    resetIndex: -1,
    hasVideo: false,
    count: 0,
    loading: true,
    name: '',
    nickName: '',
    phone: '',
    state: '',
    zip: '',
    city: '',
    address: '',
    notes: '',
    division: '',
    prestosportsid: '',
    hasBackgroundRemoval: false,
    isTrial: false,
    accIdDialogOpen: false,
    accountId: null,
    accountIdMutation: null,
    currentUserId: null,
    updatingAccountId: false
  }

  componentDidMount() {
    const { getClient: fetch, match } = this.props;
    const { client } = match.params;

    fetch.refetch({ id: client }).then((response) => {
      if (response && response.data && response.data.client) {
        const { client } = response.data;

        this.setState({
          loading: false,
          hasVideo: client.hasVideo,
          name: client.name,
          nickName: client.nickName,
          phone: client.phone,
          state: client.state,
          zip: client.zip,
          city: client.city,
          address: client.address,
          notes: client.notes,
          division: client.division,
          clientflags: client.clientflags,
          prestosportsid: client.prestosportsId,
          hasBackgroundRemoval: client.hasBackgroundRemoval,
          isTrial: client.is_trial,
        });
      }
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        {this.renderToolbar()}
        <div className={classes.root}>
          {this.renderClientEditor()}
        </div>
        {this.renderSubDialog()}
        {/*{this.renderAccIdDialog()}*/}
      </>
    );
  }

  setHasVideo = () => {
    const { hasVideo } = this.state;
    const { id, setHasVideo } = this.props;
    const _hasVideo = !hasVideo;
    setHasVideo({ variables: { id, hasVideo: _hasVideo } });
    this.setState({ hasVideo: _hasVideo });
  }

  setIsTrial = () => {
    const { isTrial } = this.state;
    const { id, setIsTrial } = this.props;
    const _isTrial = !isTrial;
    setIsTrial({ variables: { id, isTrial: _isTrial } });
    this.setState({ isTrial: _isTrial });
  }

  setHasBackgroundRemoval = () => {
    const { hasBackgroundRemoval } = this.state;
    const { id, setHasBackgroundRemoval } = this.props;
    const _hasBackgroundRemoval = !hasBackgroundRemoval;
    setHasBackgroundRemoval({ variables: { id, hasBackgroundRemoval: _hasBackgroundRemoval } });
    this.setState({ hasBackgroundRemoval: _hasBackgroundRemoval });
  }

  saveClient = (id, e = null) => {
    if (e) {
      e.preventDefault();
    }

    this.props.editClient({
      variables: {
        id,
        name: this.state.name,
        nickName: this.state.nickName,
        address: this.state.address,
        city: this.state.city,
        zip: this.state.zip,
        state: this.state.state,
        phone: this.state.phone,
        notes: this.state.notes,
        division: this.state.division,
        prestosportsId: this.state.prestosportsid,
      },
    });
  }

  saveClientFlags = (id, clientFlags, e) => {
    let _clientFlags = JSON.parse(JSON.stringify(clientFlags));
    const addFlag = e.target.checked;

    if (addFlag) {
      _clientFlags.push('SS_LOGO');
    } else {
      const temp = [];
      for (let index = 0; index < _clientFlags.length; index++) {
        const logo = _clientFlags[index];
        if (logo !== 'SS_LOGO') {
          temp.push(logo);
        }
      }

      _clientFlags = temp;
    }

    this.setState({ clientFlags: addFlag });
    this.props.addClientFlags({
      variables: {
        id,
        clientFlags: _clientFlags,
      },
    });
  }

  handleTextChange = (name) => (e) => {
    this.setState({ [name]: e.target.value });
  }

  loginAsUser = (user) => {
    auth.impersonateUser(user.id);
  }

  makeSuperuser = (user) => {
    const confirmAction = window.confirm('Are you sure you want to make this user the Superuser?');
    if (!confirmAction) { }
  }

  resetPassword = (user, index, mutation) => {
    this.setState({ resetIndex: index });
    mutation({
      variables: { email: user.email },
    }).then(() => {
      this.setState({ resetIndex: -1 });
    });
  }

  renderUserList = () => {
    const { users } = this.props.getClient.client;
    const { classes } = this.props;
    const rows = [];
    let i = 0;

    while (i < users.length) {
      const user = users[i];
      const disableFlag = (this.state.resetIndex === i);
      const index = i;
      let makeSUText = null;

      if (user.type !== 'SUPERUSER') {
        makeSUText = (
          <Button color="primary" variant="raised" size="small" onClick={() => this.makeSuperuser(user)}>
            Make Superuser
          </Button>
        );
      }

      rows.push(
        <TableRow
          key={user.id}
        >
          <TableCell padding="dense">{`${user.firstname} ${user.lastname}`}</TableCell>
          <TableCell padding="dense">{user.email}</TableCell>
          <TableCell padding="dense">{user.type}</TableCell>
          <TableCell padding="dense">
            <Button color="primary" variant="raised" size="small" onClick={() => this.loginAsUser(user)}>
              Login
            </Button>
            <Mutation mutation={RESET_PASSWORD}>
              {(reset) => (
                <Button
                  style={{ marginLeft: 8 }}
                  disabled={disableFlag}
                  color="primary"
                  variant="raised"
                  size="small"
                  onClick={() => this.resetPassword(user, index, reset)}
                >
                  Reset Password
                  {
                    disableFlag
                    && <CircularProgress size={24} className={classes.buttonProgress} />
                  }
                </Button>
              )}
            </Mutation>
            {/*<Mutation mutation={UPDATE_ACCOUNT_ID}>*/}
            {/*  {(updateAccountId) => (*/}
            {/*      <Button*/}
            {/*          style={{ marginLeft: 8 }}*/}
            {/*          disabled={disableFlag}*/}
            {/*          color="primary"*/}
            {/*          variant="raised"*/}
            {/*          size="small"*/}
            {/*          onClick={() => this.openAccIdDialog(user.accountId,user.id,updateAccountId)}*/}
            {/*      >*/}
            {/*        Account Id*/}
            {/*        {*/}
            {/*          disableFlag*/}
            {/*          && <CircularProgress size={24} className={classes.buttonProgress} />*/}
            {/*        }*/}
            {/*      </Button>*/}
            {/*  )}*/}
            {/*</Mutation>*/}
            {makeSUText}
          </TableCell>
        </TableRow>,
      );

      ++i;
    }

    return rows;
  }

  renderUserTable = () => {
    const { classes } = this.props;

    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {columnData.map((column) => (
              <TableCell
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding ? 'dense' : 'default'}
              >
                {column.label}
              </TableCell>
            ), this)}
          </TableRow>
        </TableHead>
        <TableBody>
          {this.renderUserList()}
        </TableBody>
      </Table>
    );
  }

  renderForm = () => {
    const { getClient, classes } = this.props;
    const { client } = getClient;
    const { hasVideo, hasBackgroundRemoval, isTrial } = this.state;
    let checked = false;
    const flags = JSON.parse(JSON.stringify(client.clientFlags));

    for (let x = 0; x < flags.length; ++x) {
      const index = flags[x];

      if (index === 'SS_LOGO') {
        checked = true;
        break;
      }
    }

    return (
      <form onSubmit={(e) => this.saveClient(client.id, e)}>
        <TextField
          name="name"
          className={classes.form_field_full}
          id="name"
          label="Name"
          value={this.state.name}
          onChange={(e) => this.setState({ name: e.target.value })}
        />
        <TextField
          name="nickName"
          className={classes.form_field_full}
          id="nickName"
          label="Nickname"
          value={this.state.nickName}
          onChange={(e) => this.setState({ nickName: e.target.value })}
        />
        <TextField
          name="address"
          className={classes.form_field_full}
          id="address"
          label="Address"
          value={this.state.address}
          onChange={(e) => this.setState({ address: e.target.value })}
        />
        <TextField
          name="city"
          className={classes.form_field_half}
          id="city"
          label="City"
          value={this.state.city}
          onChange={(e) => this.setState({ city: e.target.value })}
        />
        <TextField
          name="zip"
          className={classes.form_field_half}
          id="zip"
          label="Zip"
          value={this.state.zip}
          onChange={(e) => this.setState({ zip: e.target.value })}
        />
        <TextField
          name="state"
          className={classes.form_field_half}
          id="state"
          label="State"
          value={this.state.state}
          onChange={(e) => this.setState({ state: e.target.value })}
        />
        <TextField
          name="phone"
          className={classes.form_field_half}
          id="phone"
          label="Phone"
          value={this.state.phone}
          onChange={(e) => this.setState({ phone: e.target.value })}
          type="tel"
        />
        <FormControl className={classes.form_field_half}>
          <InputLabel htmlFor="division">
            Division
          </InputLabel>
          <Select
            value={this.state.division || ''}
            onChange={(e) => this.setState({ division: e.target.value })}
            inputProps={{
              name: 'Division',
              id: 'division',
            }}
            className={classes.selectField}
            label="Division"
            name="division"
            id="division"
          >
            {DIVISIONS.map((o, i) => (
              <MenuItem key={o.name} value={o.value}>
                {o.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          name="prestosportsid"
          className={classes.form_field_half}
          id="prestosportsid"
          label="PrestoSports ID"
          value={this.state.prestosportsid}
          onChange={(e) => this.setState({ prestosportsid: e.target.value })}
        />
        <TextField
          name="notes"
          className={classes.form_field_full}
          id="notes"
          label="Notes"
          value={this.state.notes}
          onChange={(e) => this.setState({ notes: e.target.value })}
          multiline
        />
        <Checkbox
          checked={this.state.clientFlags || checked}
          onChange={(e) => this.saveClientFlags(client.id, client.clientFlags, e)}
        />
        Add SS Logo
        <Checkbox
          checked={hasVideo}
          onChange={() => this.setHasVideo()}
        />
        Video enabled
        <Checkbox
          checked={hasBackgroundRemoval}
          onChange={() => this.setHasBackgroundRemoval()}
        />
        Background Removal Enabled
        <Checkbox
          checked={isTrial}
          onChange={() => this.setIsTrial()}
        />
        Is Trial
        <Button
          type="submit"
          variant="raised"
          color="secondary"
          fullWidth
          style={{ marginTop: 10 }}
        >
          Save
        </Button>
      </form>
    );
  }

  getSubscriptions = () => {
    const { getClient, classes } = this.props;
    const { subscriptions } = getClient.client;
    let subs;
    if (subscriptions.length === 0) {
      subs = (
        <Typography variant="caption">
          No Subscriptions
        </Typography>
      );
    } else {
      subs = (
        subscriptions.map((subscription) => {
          const date = new Date(subscription.expiration);
          let cats = 'All Categories';
          if (subscription.categories.length !== 0) {
            cats = subscription.categories.map((cat) => (cat.name)).join(', ');
          }
          return (
            <ListItem
              key={subscription.id}
              role={undefined}
              dense
              className={classes.listItem}
            >
              <ListItemText
                primary={cats}
                secondary={`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`}
              />
              <ListItemSecondaryAction>
                <IconButton onClick={() => this.openSubDialog(subscription)}>
                  <Pencil />
                </IconButton>
                <IconButton onClick={() => this.deleteSubscription(subscription.id)}>
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })
      );
    }
    return (
      <>
        <Typography variant="h5" gutterBottom>
          Subscriptions
        </Typography>
        <IconButton className={classes.addSub} onClick={() => this.openSubDialog()}>
          <Plus />
        </IconButton>
        <List>
          {subs}
        </List>
      </>
    );
  }

  getClientData = () => {
    const { getClient } = this.props;

    if (getClient && getClient.client) {
      return getClient.client;
    }

    return null;
  }

  renderToolbar = () => {
    const { classes, history } = this.props;
    const client = this.getClientData();

    return (
      <AppBar position="static">
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={() => history.push('/admin/clients')}
          >
            <ChevronLeftIcon />
          </IconButton>
          {
            (client != null)
            && (
            <Typography variant="h6" color="inherit" noWrap className={classes.flex}>
              Editing
              {' '}
              {client.name}
            </Typography>
            )
          }
        </Toolbar>
      </AppBar>
    );
  }

  renderClientEditor = () => {
    const { classes } = this.props;
    const client = this.getClientData();

    if (!client) {
      return null;
    }

    return (
      <Grid container spacing={3} className={classes.grid}>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            {this.renderForm()}
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <h4>Account Details</h4>
            <Subscription client={client} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            {this.renderUserTable()}
          </Paper>
        </Grid>
      </Grid>
    );
  }
  // closeAccIdDialog = () => {
  //   if (!this.state.updatingAccountId) {
  //     this.setState({accIdDialogOpen: false,accountId: null, accountIdMutation: null, currentUserId: null})
  //   }
  // }
  // openAccIdDialog = (accountId,id,mutation) => {
  //   this.setState({accIdDialogOpen: true, accountId, currentUserId: id ,accountIdMutation: mutation})
  // }
  // updateAccountId = () => {
  //   const { accountIdMutation, currentUserId, accountId } = this.state
  //   this.setState({updatingAccountId: true})
  //   accountIdMutation({
  //     variables: {
  //       id: currentUserId,
  //       accountId
  //     }
  //   }).then(resp => {
  //     this.setState({updatingAccountId: false})
  //     console.log(resp)
  //   }).catch((err) => {
  //     this.setState({updatingAccountId: false})
  //     console.log(err)
  //   })
  // }
  // renderAccIdDialog = () => {
  //   const { accIdDialogOpen, accountId, currentUserId, updatingAccountId } = this.state;
  //   return (
  //     <div>
  //       <Dialog open={accIdDialogOpen} onClose={() => this.closeAccIdDialog()}>
  //         <DialogTitle>Subscribe</DialogTitle>
  //         <DialogContent>
  //           <DialogContentText>
  //             Update Account ID {accountId} of user {currentUserId}
  //           </DialogContentText>
  //           <TextField
  //               autoFocus
  //               margin="dense"
  //               id="name"
  //               type="Text"
  //               value={accountId}
  //               onChange={e => this.setState({accountId: e.target.value})}
  //               fullWidth
  //               variant="standard"
  //           />
  //         </DialogContent>
  //         <DialogActions>
  //           {updatingAccountId !== true ? (
  //               <>
  //                 <Button onClick={() => this.updateAccountId()} >Update Account Id</Button>
  //                 <Button onClick={() => this.closeAccIdDialog()}>Cancel</Button>
  //               </>
  //             ) : (
  //                 <CircularProgress/>
  //             )
  //           }
  //         </DialogActions>
  //       </Dialog>
  //     </div>
  //   )
  // }

  renderSubDialog = () => {
    const { data } = this.props;

    if (!data) {
      return null;
    }

    const yearDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    const categories = data.categories.map((category) => ({
      name: category.name,
      value: category.id,
    }));

    let initData = {
      expiration: (`${yearDate.getFullYear()}-${yearDate.getMonth() + 1}-${yearDate.getDate()}`),
      categories: ['-1'],
    };

    if (this.state.subId) {
      // convert graph time to good time
      const graphExpiration = new Date(this.state.subId.expiration);
      const graphCats = this.state.subId.categories.length !== 0
        ? this.state.subId.categories.map((cat) => (cat.id))
        : ['-1'];
      initData = {
        expiration: (`${graphExpiration.getFullYear()}-${graphExpiration.getMonth() + 1}-${graphExpiration.getDate()}`),
        categories: graphCats,
      };
    }

    return (
      <Dialog
        open={this.state.subDialog}
        onClose={() => this.closeSubDialog()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{this.state.subId ? 'Edit Subscription' : 'Add Subscription'}</DialogTitle>
        <DialogContent>
          <Form
            onSubmit={(e) => (this.state.subId ? this.editSubscription(this.state.subId.id, e) : this.addSubscription(e))}
            initialValues={initData}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name="expiration"
                  component={TextField}
                  id="expiration"
                  label="Expiration"
                  style={{ marginTop: 10 }}
                />
                <Field
                  name="categories"
                  component={SelectMultiple}
                  id="categories"
                  label="Categories"
                  options={categories}
                  style={{ marginTop: 10 }}
                />
                <Button
                  variant="raised"
                  color="secondary"
                  onClick={handleSubmit}
                  fullWidth
                  style={{ marginTop: 10 }}
                >
                  Save
                </Button>
              </form>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.closeSubDialog()} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  openSubDialog = (id = null) => {
    this.setState({
      subDialog: true,
      subId: id,
    });
  }

  closeSubDialog = () => {
    this.setState({
      subDialog: false,
      subId: null,
    });
  }

  addSubscription = (e) => {
    const clientId = this.props.getClient.client.id;
    const expiration = new Date(e.expiration).toISOString();
    let { categories } = e;

    if (categories.indexOf('-1') !== -1) {
      // all categories are declared, discard the rest.
      categories = null;
    }

    this.props.addSubscription({
      variables: {
        clientId,
        expiration,
        categories,
      },
    });

    this.closeSubDialog();
    this.props.getClient.refetch();
  }

  editSubscription = (id, e) => {
    let { categories } = e;
    if (categories.indexOf('-1') !== -1) {
      // all categories are declared, discard the rest.
      categories = null;
    }
    this.props.editSubscription({
      variables: {
        subId: id,
        expiration: new Date(e.expiration).toISOString(),
        categories,
      },
    });
    this.closeSubDialog();
    this.props.getClient.refetch();
  }

  deleteSubscription = (subId) => {
    this.props.deleteSubscription({
      variables: {
        subId,
        clientId: this.props.id,
      },
    });
    this.props.getClient.refetch();
  }
}

// -------------------------------------------------------------------------- //

export default compose(
  graphql(GET_CLIENT, { name: 'getClient' }),
  // graphql(UPDATE_ACCOUNT_ID, { name: 'updateAccountId' }),
  graphql(ADD_SUBSCRIPTION, { name: 'addSubscription' }),
  graphql(EDIT_CLIENT, { name: 'editClient' }),
  graphql(EDIT_CLIENT2, { name: 'addClientFlags' }),
  graphql(EDIT_SUBSCRIPTION, { name: 'editSubscription' }),
  graphql(DELETE_SUBSCRIPTION, { name: 'deleteSubscription' }),
  graphql(RESET_PASSWORD, { name: 'resetPassword' }),
  graphql(SET_HAS_VIDEO, { name: 'setHasVideo' }),
  graphql(SET_HAS_BACKGROUND_REMOVAL, { name: 'setHasBackgroundRemoval' }),
  graphql(SET_IS_TRIAL, { name: 'setIsTrial' }),
  withRouter,
  withStyles(STYLES),
)(ClientEditor);

// -------------------------------------------------------------------------- //
