
// -------------------------------------------------------------------------- //

import React from 'react';

import { withTheme } from '@material-ui/core';

import parseColor from 'parse-color';

// -------------------------------------------------------------------------- //

const Component = (props) => {
  const { theme } = props;
  let { color = 'transparent' } = props;

  if (typeof color === 'string') {
    color = parseColor(color);

    if (color.rgba) {
      color = color.rgba;
    } else {
      color = [ 0, 0, 0, 0 ];
    }
  }

  if (!color || color.length !== 4) {
    color = [ 0, 0, 0, 0 ];
  }

  const red   = color[0];
  const green = color[1];
  const blue  = color[2];

  const { size, variant = 'square' } = props;
  let border_radius = 0;

  switch (variant) {
    case 'squircle': {
      border_radius = 3;
      break;
    }
    case 'circle': {
      border_radius = '50%';
      break;
    }
    default: {
      break;
    }
  }

  const { elevation = 0, style = {}, divProps = {} } = props;
  const box_shadow = theme.shadows[elevation];

  return (
    <div {...divProps} style={{
      width: size, height: size,
      borderRadius: border_radius,
      boxShadow: box_shadow,
      background: `linear-gradient(rgba(${red}, ${green}, ${blue}, ${color[3]}), rgba(${red}, ${green}, ${blue}, ${color[3]})), url("https://ss3-assets.s3.us-east-2.amazonaws.com/grid.png")`,
      ...style
    }}/>
  );
}

// -------------------------------------------------------------------------- //

export const ColorSwatch = withTheme(Component);

// -------------------------------------------------------------------------- //
