import { Facebook as FB } from 'fb';
import moment from 'moment';

class Facebook {
  constructor() {
    this.fb = new FB({
      version: 'v2.12',
      appId: process.env.REACT_APP_FACEBOOK_APP_ID,
    });
  }
  async postPhoto(accessToken, pageId, url, message = null) {
    const photoParams = {
      url,
      access_token: accessToken,
      fields: 'link',
    };
    if (message) { photoParams.caption = message; }
    const photo = await this.fb.api(`${pageId}/photos`, 'post', photoParams);
    return photo;
  }
  async postVideo(accessToken, pageId, url, message = null) {
    const videoParams = {
      file_url: url,
      access_token: accessToken,
    };
    if (message) { videoParams.description = message; }
    const video = await this.fb.api(`${pageId}/videos`, 'post', videoParams);
    return video;
  }
  async schedulePost(accessToken, pageId, url, media, selectedDate, message,type) {

    let selectedDate_m = moment(selectedDate);
    let now_m = moment();

    let minutes_difference = selectedDate_m.diff(now_m,"minutes");

    if(minutes_difference <= 10){
      let addition = 15 - minutes_difference;
      selectedDate_m.add(addition,"minutes");


    }
    let postParams = {};
    if(!media){
      postParams = {
        published: false,
        unpublished_content_type: "SCHEDULED",
        url,
        scheduled_publish_time: selectedDate_m.unix(),
        access_token: accessToken,
      };
    }
    else{
      postParams = {
        published: false,
        file_url : media,
        scheduled_publish_time:  selectedDate_m.unix(),
        unpublished_content_type: "SCHEDULED",
        access_token: accessToken,
      };
    }
    if (message) {
      if(type==="videos"){
       postParams.description = message;
     }else{
       postParams.caption = message;
     }
     }
    const post = await this.fb.api(`${pageId}/${type}`, 'post', postParams);
    return post;
  }

  async unschedulePost(accessToken,post_id){

    const post = await this.fb.api(`/${post_id}`,'DELETE',{access_token:accessToken});
    return post;
  }
}

export default new Facebook();
