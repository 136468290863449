import React, { Component } from 'react';
import Fade from '@material-ui/core/Fade';
import DynamicLogo from '../../assets/_dynamic_.png';
import 'whatwg-fetch';
const IMAGE_CHECK = url => (goodData) => fetch(url)
                                                    .then( (res) => {

                                                       if(res.ok && res.status === 200){
                                                          goodData();
                                                        }
                                                    })
                                                    .catch( err => console.log("ERROR"));
class TemplatePreview2 extends Component {
  state = {
    loaded: false,
    category : "",
    imageUrl : ""
  }
  handleImageLoaded = () => {
    const interval = Math.floor(Math.random() * 501);
    this.timeout = setTimeout(() => {
      this.setState({
        loaded: true
      });
    }, interval);
  }

  componentDidMount(){
     this.initialization();
  }

  initialization = () => {
    const { category , template } = this.props;
    let url = `https://ss3-templates.s3.us-east-2.amazonaws.com/${template.id}_${category}.jpg`;
    let good = () => this.setState({imageUrl : url});
    IMAGE_CHECK(url)(good);
  }


  render() {

    const {
      className, style, onClick,template,dynamicTemplatesHash,alternateOnClick
    } = this.props;

    const fallBackUrl = `https://ss3-templates.s3.us-east-2.amazonaws.com/${template.id}.jpg`;
    const isDynamic = (  template.id in dynamicTemplatesHash) ?   <Fade in={this.state.loaded}><img  src={DynamicLogo}  style={{pointerEvents : 'none',width : 30, height : 30, float : 'right',position: 'relative' , bottom : '3.5em',marginRight : '.5em'  }}alt="Template preview"/></Fade> : null;
    let imageURL = this.state.imageUrl || fallBackUrl;
    let onclick = () => { console.log('Doesn\'t work')}
    if(alternateOnClick){
      onclick = onClick;
    }else{
      onclick = onClick(imageURL,template);
    }
    return (

      <React.Fragment>
      <Fade in={this.state.loaded}>
      <img
         id={template.id}
         onClick={onclick}
         onLoad={this.handleImageLoaded}
         className={className}
         src={imageURL}
         style={style}
         alt="Template preview"
       />
       </Fade>
        {isDynamic}
      </React.Fragment>


    );
  }
}

export default TemplatePreview2;
