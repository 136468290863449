
import Sport from './Sport';

const TEAMS= [
  'Home',
  'Visiting',
];

const POSITIONS = [
  { key: 'PG', name: 'Point Guard' },
  { key: 'SG', name: 'Shooting Guard' },
  { key: 'SF', name: 'Small Forward' },
  { key: 'PF', name: 'Power Forward' },
  { key: 'C', name: 'Center' },
];

class BasketballSport extends Sport {

  constructor() {
    super();

    for (let t = 0; t < 2; ++t) {
      const team = `[${TEAMS[t]}]`;
      const team_id = `team.${TEAMS[t].toLowerCase()}`;
      this.push(`${team_id}.id`, `${team} Team ID`, { type: 'string', sort: 2 });
      this.push(`${team_id}.name`, `${team} Team Name`, { type: 'string', sort: 2 });
      this.push(`${team_id}.score`, `${team} Final Score`, { sort: 1 });
      this.push(`${team_id}.field_goals.made`, `${team} Total Field Goals`);
      this.push(`${team_id}.field_goals.attempts`, `${team} Total Field Goal Attempts`);
      this.push(`${team_id}.field_goals.percent`, `${team} Total Field Goal %`);
      this.push(`${team_id}.three_pointers.made`, `${team} Total 3-Pointers`);
      this.push(`${team_id}.three_pointers.attempts`, `${team} Total 3-Pointer Attempts`);
      this.push(`${team_id}.three_pointers.percent`, `${team} Total 3-Pointer %`);
      this.push(`${team_id}.free_throws.made`, `${team} Total Free Throws`);
      this.push(`${team_id}.free_throws.attempts`, `${team} Total Free Throw Attempts`);
      this.push(`${team_id}.free_throws.percent`, `${team} Total Free Throw %`);
      this.push(`${team_id}.shots.made`, `${team} Total Shots Made`);
      this.push(`${team_id}.shots.attempts`, `${team} Total Shots Attempted`);
      this.push(`${team_id}.shots.percent`, `${team} Total Shooting %`);
      this.push(`${team_id}.blocks`, `${team} Total Blocks`);
      this.push(`${team_id}.assists`, `${team} Total Assists`);
      this.push(`${team_id}.steals`, `${team} Total Steals`);
      this.push(`${team_id}.rebounds.offensive`, `${team} Total Offensive Rebounds`);
      this.push(`${team_id}.rebounds.defensive`, `${team} Total Defensive Rebounds`);
      this.push(`${team_id}.rebounds.total`, `${team} Total Rebounds`);
      this.push(`${team_id}.turnovers`, `${team} Total Turnovers`);
      this.push(`${team_id}.points.turnovers`, `${team} Turnover Points`);
      this.push(`${team_id}.points.second_chance`, `${team} Second Chance Points`);
      this.push(`${team_id}.points.paint`, `${team} Points in the Paint`);
      this.push(`${team_id}.points.fastbreaks`, `${team} Fastbreak Points`);
      this.push(`${team_id}.points.bench`, `${team} Bench Points`);

      for (let p = 0; p < 4; ++p) {
        const period = `period${p + 1}`;
        this.push(`${team_id}.score.${period}`, `${team} Score`, { sort: 1 });
        this.push(`${team_id}.field_goals.made.${period}`, `${team} Field Goals`);
        this.push(`${team_id}.field_goals.attempts.${period}`, `${team} Field Goal Attempts`);
        this.push(`${team_id}.field_goals.percent.${period}`, `${team} Field Goal %`);
        this.push(`${team_id}.three_pointers.made.${period}`, `${team} 3-Pointers`);
        this.push(`${team_id}.three_pointers.attempts.${period}`, `${team} 3-Pointer Attempts`);
        this.push(`${team_id}.three_pointers.percent.${period}`, `${team} 3-Pointer %`);
        this.push(`${team_id}.free_throws.made.${period}`, `${team} Free Throws`);
        this.push(`${team_id}.free_throws.attempts.${period}`, `${team} Free Throw Attempts`);
        this.push(`${team_id}.free_throws.percent.${period}`, `${team} Free Throw %`);
        this.push(`${team_id}.shots.made.${period}`, `${team} Shots Made`);
        this.push(`${team_id}.shots.attempts.${period}`, `${team} Shots Attempted`);
        this.push(`${team_id}.shots.percent.${period}`, `${team} Shooting %`);
        this.push(`${team_id}.blocks.${period}`, `${team} Blocks`);
        this.push(`${team_id}.assists.${period}`, `${team} Assists`);
        this.push(`${team_id}.steals.${period}`, `${team} Steals`);
        this.push(`${team_id}.rebounds.offensive.${period}`, `${team} Offensive Rebounds`);
        this.push(`${team_id}.rebounds.defensive.${period}`, `${team} Defensive Rebounds`);
        this.push(`${team_id}.rebounds.total.${period}`, `${team} Total Rebounds`);
        this.push(`${team_id}.turnovers.${period}`, `${team} Turnovers`);
      }
    }

    const player = `[Player]`;
    this.push('player.field_goals.made', `${player} Field Goals`);
    this.push('player.field_goals.attempts', `${player} Field Goal Attempts`);
    this.push('player.field_goals.percent', `${player} Field Goal %`);
    this.push('player.three_pointers.made', `${player} 3-Pointers`);
    this.push('player.three_pointers.attempts', `${player} 3-Pointer Attempts`);
    this.push('player.three_pointers.percent', `${player} 3-Pointer %`);
    this.push('player.free_throws.made', `${player} Free Throws`);
    this.push('player.free_throws.attempts', `${player} Free Throw Attempts`);
    this.push('player.free_throws.percent', `${player} Free Throw %`);
    this.push('player.shots.made', `${player} Shots Made`);
    this.push('player.shots.attempts', `${player} Shots Attempted`);
    this.push('player.shots.percent', `${player} Shooting %`);
    this.push('player.blocks', `${player} Blocks`);
    this.push('player.assists', `${player} Assists`);
    this.push('player.steals', `${player} Steals`);
    this.push('player.rebounds.offensive', `${player} Offensive Rebounds`);
    this.push('player.rebounds.defensive', `${player} Defensive Rebounds`);
    this.push('player.rebounds.total', `${player} Total Rebounds`);
    this.push('player.turnovers', `${player} Turnovers`);

    for (let p = 0; p < 4; ++p) {
      const period = `period${p + 1}`;
      this.push(`player.field_goals.made.${period}`, `${player} Field Goals`);
      this.push(`player.field_goals.attempts.${period}`, `${player} Field Goal Attempts`);
      this.push(`player.field_goals.percent.${period}`, `${player} Field Goal %`);
      this.push(`player.three_pointers.made.${period}`, `${player} 3-Pointers`);
      this.push(`player.three_pointers.attempts.${period}`, `${player} 3-Pointer Attempts`);
      this.push(`player.three_pointers.percent.${period}`, `${player} 3-Pointer %`);
      this.push(`player.free_throws.made.${period}`, `${player} Free Throws`);
      this.push(`player.free_throws.attempts.${period}`, `${player} Free Throw Attempts`);
      this.push(`player.free_throws.percent.${period}`, `${player} Free Throw %`);
      this.push(`player.blocks.${period}`, `${player} Blocks`);
      this.push(`player.assists.${period}`, `${player} Assists`);
      this.push(`player.steals.${period}`, `${player} Steals`);
      this.push(`player.rebounds.offensive.${period}`, `${player} Offensive Rebounds`);
      this.push(`player.rebounds.defensive.${period}`, `${player} Defensive Rebounds`);
      this.push(`player.rebounds.total.${period}`, `${player} Total Rebounds`);
      this.push(`player.turnovers.${period}`, `${player} Turnovers`);
    }
  }

  getPlayerPositions() {
    return POSITIONS;
  }

  update(id, version) {
    if (version < 3) {
      // venue-scope stats are now in 'venue.' rather than 'location.'
      if (/^location\./.test(id)) {
        return { type: 'rename', value: id.replace(/^location\./, 'venue.') };
      }

      let remaps = {
        // these remaps use 'visit' for the opposing team, not 'visiting'
        'team1': 'team.home.name',
        'team2': 'team.visiting.name',
        'team.home': 'team.home.name',
        'team.home_id': 'team.home.id',
        'team.visit': 'team.visiting.name',
        'team.visit_id': 'team.visiting.id',

        // 'visiting' uses singular while 'home' uses plural
        'field_goals_percent.home.final': 'team.home.field_goals.percent',
        'field_goal_percent.visiting.final': 'team.visiting.field_goals.percent',
      };

      if (remaps[id]) {
        return { type: 'rename', value: remaps[id] };
      }

      for (let t = 0; t < 2; ++t) {
        const team =  TEAMS[t].toLowerCase();
        const old_id = `${team}`;
        const new_id = `team.${team}`;

        remaps = {
          [`score.${old_id}.final`]: `${new_id}.score`,
          [`score.${old_id}.period1`]: `${new_id}.score.period1`,
          [`score.${old_id}.period2`]: `${new_id}.score.period2`,

          [`free_throws_made_pt3.${old_id}.final`]: `${new_id}.free_throws.made`,
          [`free_throws_made_pt3.${old_id}.period1`]: `${new_id}.free_throws.made.period1`,
          [`free_throws_made_pt3.${old_id}.period2`]: `${new_id}.free_throws.made.period2`,
          [`free_throws_attempted_pt3.${old_id}.final`]: `${new_id}.free_throws.attempts`,
          [`free_throws_attempted_pt3.${old_id}.period1`]: `${new_id}.free_throws.attempts.period1`,
          [`free_throws_attempted_pt3.${old_id}.period2`]: `${new_id}.free_throws.attempts.period2`,
          [`free_throws_percent.${old_id}.final`]: `${new_id}.free_throws.percent`,
          [`free_throws_percent.${old_id}.period1`]: `${new_id}.free_throws.percent.period1`,
          [`free_throws_percent.${old_id}.period2`]: `${new_id}.free_throws.percent.period2`,
          [`free_throws_percent_normalized.${old_id}.final`]: `${new_id}.free_throws.percent`,
          
          [`field_goals_made.${old_id}.final`]: `${new_id}.field_goals.made`,
          [`field_goals_made.${old_id}.period1`]: `${new_id}.field_goals.made.period1`,
          [`field_goals_made.${old_id}.period2`]: `${new_id}.field_goals.made.period2`,
          [`field_goals_attempted.${old_id}.final`]: `${new_id}.field_goals.attempts`,
          [`field_goals_attempted.${old_id}.period1`]: `${new_id}.field_goals.attempts.period1`,
          [`field_goals_attempted.${old_id}.period2`]: `${new_id}.field_goals.attempts.period2`,
          [`field_goals_percent.${old_id}.period1`]: `${new_id}.field_goals.percent.period1`,
          [`field_goals_percent.${old_id}.period2`]: `${new_id}.field_goals.percent.period2`,
          [`field_goals_percent_normalized.${old_id}.final`]: `${new_id}.field_goals.percent`,

          [`field_goals_made_pt3.${old_id}.final`]: `${new_id}.three_pointers.made`,
          [`field_goals_made_pt3.${old_id}.period1`]: `${new_id}.three_pointers.made.period1`,
          [`field_goals_made_pt3.${old_id}.period2`]: `${new_id}.three_pointers.made.period2`,
          [`field_goals_attempted_pt3.${old_id}.final`]: `${new_id}.three_pointers.attempts`,
          [`field_goals_attempted_pt3.${old_id}.period1`]: `${new_id}.three_pointers.attempts.period1`,
          [`field_goals_attempted_pt3.${old_id}.period2`]: `${new_id}.three_pointers.attempts.period2`,
          [`field_goal_percent_pt3.${old_id}.final`]: `${new_id}.three_pointers.percent`,
          [`field_goal_percent_pt3.${old_id}.period1`]: `${new_id}.three_pointers.percent.period1`,
          [`field_goal_percent_pt3.${old_id}.period2`]: `${new_id}.three_pointers.percent.period2`,
          [`field_goals_made_pt3_percent_normalized.${old_id}.final`]: `${new_id}.three_pointers.percent`,

          [`blocks.${old_id}.final`]: `${new_id}.blocks`,
          [`blocks.${old_id}.period1`]: `${new_id}.blocks.period1`,
          [`blocks.${old_id}.period2`]: `${new_id}.blocks.period2`,

          [`assists.${old_id}.final`]: `${new_id}.assists`,
          [`assists.${old_id}.period1`]: `${new_id}.assists.period1`,
          [`assists.${old_id}.period2`]: `${new_id}.assists.period2`,

          [`steals.${old_id}.final`]: `${new_id}.steals`,
          [`steals.${old_id}.period1`]: `${new_id}.steals.period1`,
          [`steals.${old_id}.period2`]: `${new_id}.steals.period2`,

          [`offensive_rebounds.${old_id}.final`]: `${new_id}.rebounds.offensive`,
          [`offensive_rebounds.${old_id}.period1`]: `${new_id}.rebounds.offensive.period1`,
          [`offensive_rebounds.${old_id}.period2`]: `${new_id}.rebounds.offensive.period2`,

          [`defensive_rebounds.${old_id}.final`]: `${new_id}.rebounds.defensive`,
          [`defensive_rebounds.${old_id}.period1`]: `${new_id}.rebounds.defensive.period1`,
          [`defensive_rebounds.${old_id}.period2`]: `${new_id}.rebounds.defensive.period2`,

          [`total_rebounds.${old_id}.final`]: `${new_id}.rebounds.total`,
          [`total_rebounds.${old_id}.period1`]: `${new_id}.rebounds.total.period1`,
          [`total_rebounds.${old_id}.period2`]: `${new_id}.rebounds.total.period2`,

          [`turnovers.${old_id}.final`]: `${new_id}.turnovers`,
          [`turnovers.${old_id}.period1`]: `${new_id}.turnovers.period1`,
          [`turnovers.${old_id}.period2`]: `${new_id}.turnovers.period2`,

          [`points.${old_id}.from_turnovers`]: `${new_id}.points.turnovers`,
          [`points.${old_id}.from_2nd_chance`]: `${new_id}.points.second_chance`,
          [`points.${old_id}.from_in_the_paint`]: `${new_id}.points.paint`,
          [`points.${old_id}.from_breakpoints`]: `${new_id}.points.fastbreaks`,
          [`points.${old_id}.from_bench`]: `${new_id}.points.bench`,
        };

        if (remaps[id]) {
          return { type: 'rename', value: remaps[id] };
        }
      }
    }

    return super.update(id, version);
  }

}

export default BasketballSport;
