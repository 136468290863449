
// -------------------------------------------------------------------------- //

import * as React from 'react';

import {
  AppBar,
  Button, Checkbox,
  Grid,
  IconButton,
  Paper,
  TextField,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';

import {
  ChevronLeft as ChevronLeftIcon,
} from 'mdi-material-ui';

import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';

import { withRouter } from 'react-router';
// import ScoreshotsPut from "../../../scoreshotsapi/Put";

// -------------------------------------------------------------------------- //


const STYLES = (theme) => ({
  grid: {
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    position: 'relative',
  },
  restrictTemplatePaper: {
    padding: theme.spacing(2),
    position: 'relative',
    marginTop: 20,
  },
  formField: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  form_field_half: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: '50%',
  },
  form_field_full: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: '100%',
  },
  formControl: {
    marginLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    marginTop: 10,
    width: '50%',
  },
  selectField: {
    width: '100%',
    marginTop: 10,
  },
  addSub: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  root: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(3),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  flex: {
    flex: 1,
  }
});

// -------------------------------------------------------------------------- //

const GET_TIER = gql`
  query loadTier($id: ID!) {
    accountTier(id: $id) {
      id
      name
    }
    templates {
      id
      name
    }
  }
`;

const EDIT_TIER = gql`
  mutation editTier($id: ID!, $name: String, $restrictTemplates: Boolean) {
    editTier (
      id: $id
      name: $name
      restrictTemplates: $restrictTemplates
    ) {
      id
    }
  }
`;


// -------------------------------------------------------------------------- //


// -------------------------------------------------------------------------- //

class TierEditor extends React.Component {

  state = {
    loading: true,
    name: '',
    restrictTemplate: false,
  }

  componentDidMount() {
    const { getTier: fetch, match }  = this.props;
    const { tier } = match.params;

    fetch.refetch({ id: tier }).then(response => {
      if (response && response.data && response.data.accountTier) {
        const tier = response.data.accountTier;

        this.setState({
          loading: false,
          name: tier.name,
          restrictTemplates: tier.restrictTemplates,
        });
      }
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        {this.renderToolbar()}
        <div className={classes.root}>
          {this.renderTierEditor()}
        </div>
      </React.Fragment>
    );
  }

  saveTier = (id, e = null) => {
    if (e) {
      e.preventDefault();
    }

    this.props.editTier({
      variables: {
        id,
        name: this.state.name,
        restrictTemplate: this.state.restrictTemplate,
      },
    });
  }

  handleTextChange = name => e =>{
    this.setState({ [name]: e.target.value });
  }

  setRestrictTemplates = () => {
    const { restrictTemplate } = this.state;
    let _restrictTemplate = restrictTemplate ? false : true;
    this.setState({ restrictTemplate : _restrictTemplate });
  }

  renderForm = () => {
    const { getTier, classes } = this.props;
    const { accountTier } = getTier;

    return (
      <form onSubmit={(e) => this.saveTier(accountTier.id, e)}>
        <TextField
          name="name"
          className={classes.form_field_full}
          id="name"
          label="Name"
          value={this.state.name}
          onChange={(e) => this.setState({ name: e.target.value })}
        />
        <Typography variant="body1" style={{paddingTop: 30}}>Options</Typography>
        <Checkbox
          name="restrictTemplate"
          id="restrictTemplate"
          checked={this.state.restrictTemplate}
          onChange={() => this.setRestrictTemplates()}
        />
        Restrict Template selection
        <div></div>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          style={{ marginTop: 20 }}
        >
          Save
        </Button>
      </form>
    );
  }


  getTierData = () => {
    const { getTier } = this.props;

    if (getTier && getTier.accountTier) {
      return getTier.accountTier;
    }

    return null;
  }

  renderToolbar = () => {
    const { classes, history } = this.props;
    const tier = this.getTierData();

    return (
      <AppBar position="static">
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={() => history.push('/admin/tiers')}
          >
            <ChevronLeftIcon/>
          </IconButton>
          {
            (tier != null) &&
            <Typography variant="h6" color="inherit" noWrap className={classes.flex}>
              Editing {tier.name}
            </Typography>
          }
        </Toolbar>
      </AppBar>
    );
  }

  renderTemplateRestrictBox = () => {
    const { classes } = this.props;
    return (
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Button
          type="button"
          variant="contained"
          color="secondary"
          style={{ float: "right" }}
        >
          Add Template
        </Button>
          <Typography variant="h6">Restricted Templates</Typography>
          <Paper className={classes.restrictTemplatePaper}>
            No templates selected
          </Paper>

        </Paper>
      </Grid>
    );
  }

  renderTierEditor = () => {
    const { classes } = this.props;
    const tier = this.getTierData();
    let templateRestrictBox = null;

    if (!tier) {
      return null;
    }

    if (this.state.restrictTemplate) {
      templateRestrictBox = this.renderTemplateRestrictBox();
    }

    return (
      <Grid container spacing={3} className={classes.grid}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {this.renderForm()}
          </Paper>
        </Grid>
        {templateRestrictBox}
      </Grid>
    );
  }


}

// -------------------------------------------------------------------------- //

export default compose(
  graphql(GET_TIER,{ name: 'getTier' }),
  graphql(EDIT_TIER, { name: 'editTier' }),
  withRouter,
  withStyles(STYLES),
)(TierEditor);

// -------------------------------------------------------------------------- //
