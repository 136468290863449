
// -------------------------------------------------------------------------- //

import React from 'react';

import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

// -------------------------------------------------------------------------- //

export const ROLES_QUERY = `
  id
  name
  restrictCategoryFlag
  categories {
    id
    name
  }
  allowCustomFlag
  allowCustomSaveFlag
  allowCustomFolderSaveFlag
  restrictCustomFlag
  customFolders {
    id
    name
  }
  restrictSocialFlag
  manageSocialAccounts
  twitterAccounts {
    id
  }
  facebookPages {
    id
  }
  manageTeamColors
  restrictTeamColors
  restrictMoveCanvas
  restrictRemoveBG
  restrictGeneralSettings
  restrictFonts
  manageFonts
  fontsAllowed {
    id
  }
`;

// -------------------------------------------------------------------------- //

const GQL_GET_ROLE = gql`
  query {
    me {
      id
      role {
        id
        name
        restrictCategoryFlag
        categories {
          id
          name
        }
        allowCustomFlag
        allowCustomSaveFlag
        allowCustomFolderSaveFlag
        restrictCustomFlag
        customFolders {
          id
          name
        }
        restrictSocialFlag
        manageSocialAccounts
        twitterAccounts {
          id
        }
        facebookPages {
          id
        }
        manageTeamColors
        restrictTeamColors
        restrictMoveCanvas
        restrictRemoveBG
        restrictGeneralSettings
        restrictFonts
        manageFonts
        fontsAllowed {
          id
          fontFamily
        }
      }
    }
  }
`;

// -------------------------------------------------------------------------- //

export const PALETTE_PERMISSION = {
  'ALL': { key: 'ALL', name: 'All' },
  'TEAM': { key: 'TEAM', name: 'Team Colors' },
  'PALETTE': { key: 'PALETTE', name: 'Team Colors and Quick Palette' },
}

// -------------------------------------------------------------------------- //

export const PERMISSIONS = [
  {
    key: 'template_library:restricted',
    category: 'Template Library',
    type: 'boolean',
    name: 'Restrict template sports',
    description: 'User has limited access to the template library.',
    default_value: false,
  },
  {
    key: 'template_library:sports',
    name: 'Allowed template sports',
    type: 'select-template-sports',
    description: 'User has access only to these sports in the template library:',
    subpermission: 'template_library:restricted',
    default_value: [],
  },
  {
    key: 'custom_template:read',
    category: 'Custom Templates',
    type: 'boolean',
    name: 'Use custom templates',
    description: 'User will be allowed to view and use custom templates.',
    default_value: true,
  },
  {
    key: 'custom_template:write',
    type: 'boolean',
    name: 'Manage custom templates',
    description: 'User will be able to save, save as, and delete custom templates in the editor.',
    subpermission: 'custom_template:read',
    default_value: true,
  },
  {
    key: 'custom_template:write_folders',
    type: 'boolean',
    name: 'Manage custom template folders',
    description: 'User will be able to add new and delete custom template folders.',
    subpermission: 'custom_template:read',
    default_value: true,
  },
  {
    key: 'custom_template:restricted',
    type: 'boolean',
    name: 'Restrict custom template folders',
    description: 'User has limited access to custom templates and may access only specific folders.',
    subpermission: 'custom_template:read',
    default_value: false,
  },
  {
    key: 'custom_template:folders',
    type: 'select-custom-folders',
    name: 'Allowed custom template folders',
    description: 'User can access only these custom template folders:',
    subpermission: 'custom_template:restricted',
    default_value: [],
  },
  {
    key: 'social_posting:write',
    category: 'Social Posting',
    type: 'boolean',
    name: 'Manage social accounts',
    description: 'User will be able to add and remove social accounts on the team page.',
    default_value: true,
  },
  {
    key: 'social_posting:read',
    category: 'Social Posting',
    type: 'boolean',
    name: 'Restrict social media accounts',
    description: 'User has limited access to which social media accounts they may post.',
    default_value: false,
  },
  {
    key: 'social_posting:twitter_accounts',
    type: 'select-twitter-accounts',
    name: 'Allowed Twitter accounts',
    description: 'User can post to only these Twitter accounts:',
    subpermission: 'social_posting:read',
    default_value: [],
  },
  {
    key: 'social_posting:facebook_accounts',
    type: 'select-facebook-accounts',
    name: 'Allowed Facebook accounts',
    description: 'User can post to only these Facebook accounts:',
    subpermission: 'social_posting:read',
    default_value: [],
  },
  {
    key: 'team_palette:write',
    type: 'boolean',
    category: 'Team Palette',
    name: 'Manage team palette',
    description: 'User can change the team colors and palette.',
    default_value: true,
  },
  {
    key: 'team_palette:read',
    type: 'enum-team-palette',
    category: 'Team Palette',
    name: 'Color palette',
    description: 'User has access to only these colors on templates:',
    default_value: 'ALL',
  },
  {
    key: 'editor:lock_movement',
    type: 'boolean',
    category: 'Editor',
    name: 'Lock Movement',
    description: 'Lock the movement of all elements on the canvas',
    default_value: false,
  },
  {
    key: 'editor:remove_bg',
    type: 'boolean',
    category: 'Editor',
    name: 'Remove Backgrounds',
    description: 'Restrict user\'s access to the Remove Background feature.',
    default_value: false,
  },
  {
    key: 'team:remove_general_settings',
    type: 'boolean',
    category: 'Team Settings',
    name: 'General Team Information',
    description: 'Restrict user\'s ability to save the General Team Information on the Team page.',
    default_value: false,
  },
  {
    key: 'fonts:read',
    type: 'boolean',
    category: 'Fonts',
    name: 'Restrict Fonts',
    description: 'Restrict user\'s ability to use all fonts.',
    default_value: false,
  },
  {
    key: 'fonts:write',
    type: 'boolean',
    category: 'Fonts',
    name: 'Manage Fonts',
    description: 'User can upload and remove fonts.',
    default_value: true,
  },
  {
    key: 'fonts:fonts_allowed',
    type: 'select-fonts-allowed',
    category: 'Fonts',
    name: 'Fonts Allowed',
    description: 'User can only use these fonts.',
    subpermission: 'fonts:read',
    default_value: [],
  },
];

// -------------------------------------------------------------------------- //

export class UserPermissions {

  id = null;
  name = null;
  permissions = { };

  constructor(role = null) {
    PERMISSIONS.forEach((permission) => {
      this.permissions[permission.key] = {
        ...permission,
        value: permission.default_value,
      }
    });

    if (role) {
      this.setId(role.id);
      this.setName(role.name);

      if ('restrictCategoryFlag' in role) {
        this.set('template_library:restricted', role.restrictCategoryFlag);
      }

      if ('categories' in role) {
        this.set('template_library:sports', role.categories.map(c => c.id));
      }

      if ('allowCustomFlag' in role) {
        this.set('custom_template:read', role.allowCustomFlag);
      }

      if ('allowCustomSaveFlag' in role) {
        this.set('custom_template:write', role.allowCustomSaveFlag);
      }

      if ('allowCustomFolderSaveFlag' in role) {
        this.set('custom_template:write_folders', role.allowCustomFolderSaveFlag);
      }

      if ('restrictCustomFlag' in role) {
        this.set('custom_template:restricted', role.restrictCustomFlag);
      }

      if ('customFolders' in role) {
        this.set('custom_template:folders', role.customFolders.map(c => c.id));
      }

      if ('restrictSocialFlag' in role) {
        this.set('social_posting:read', role.restrictSocialFlag);
      }

      if ('manageSocialAccounts' in role) {
        this.set('social_posting:write', role.manageSocialAccounts);
      }

      if ('facebookPages' in role) {
        this.set('social_posting:facebook_accounts', role.facebookPages.map(i => i.id));
      }

      if ('twitterAccounts' in role) {
        this.set('social_posting:twitter_accounts', role.twitterAccounts.map(i => i.id));
      }

      if ('manageTeamColors' in role) {
        this.set('team_palette:write', role.manageTeamColors);
      }

      if ('restrictTeamColors' in role) {
        this.set('team_palette:read', role.restrictTeamColors);
      }

      if ('restrictMoveCanvas' in role) {
        this.set('editor:lock_movement', role.restrictMoveCanvas);
      }

      if ('restrictRemoveBG' in role) {
        this.set('editor:remove_bg', role.restrictRemoveBG);
      }

      if ('restrictGeneralSettings' in role) {
        this.set('team:remove_general_settings', role.restrictGeneralSettings);
      }

      if ('restrictFonts' in role) {
        this.set('fonts:read', role.restrictFonts);
      }

      if ('manageFonts' in role) {
        this.set('fonts:write', role.manageFonts);
      }

      if ('fontsAllowed' in role) {
        this.set('fonts:fonts_allowed', role.fontsAllowed.map(c => c.id));
      }
    }
  }

  get(key, value = null) {
    if (!(key in this.permissions)) {
      return value;
    }

    return this.permissions[key].value;
  }

  canAccessSport(id) {
    if (!this.get('template_library:restricted')) {
      return true;
    }

    return this.get('template_library:sports').includes(id);
  }

  set(key, value) {
    let permission = this.permissions[key];

    if (!permission) {
      return;
    }

    if (value === null) {
      value = permission.default_value;
    }

    permission.value = value;
  }

  setId(value) {
    this.id = value;
  }

  setName(value) {
    this.name = value;
  }

}

// -------------------------------------------------------------------------- //

export const UserRoleContext = React.createContext(new UserPermissions());

// -------------------------------------------------------------------------- //

const DefaultPermissions = new UserPermissions(null);

// -------------------------------------------------------------------------- //

class UserRoleProvider_ extends React.Component {

  state = {
    permissions: null,
  }

  componentDidMount() {
    const { gql_get_role } = this.props;

    gql_get_role.refetch().then((response) => {
      this.setState({
        permissions: new UserPermissions(response.data.me.role)
      });
    });
  }

  render() {
    const { children } = this.props;
    let { permissions } = this.state;

    if (permissions === null) {
      permissions = DefaultPermissions;
    }

    return (
      <UserRoleContext.Provider value={permissions}>
        {children}
      </UserRoleContext.Provider>
    );
  }

};

// -------------------------------------------------------------------------- //

export const UserRoleProvider = graphql(
  GQL_GET_ROLE, {
    name: 'gql_get_role',
    options: { fetchPolicy: 'network-only' },
  }
)(UserRoleProvider_);

export const UserRoleConsumer = UserRoleContext.Consumer;

// -------------------------------------------------------------------------- //

export const Permissible = (props) => {
  const { children, condition } = props;

  return (
    <UserRoleConsumer>
    {(permissions) => {
      if (condition && !condition(permissions)) {
        return null;
      }

      return children;
    }}
    </UserRoleConsumer>
  );
};

// -------------------------------------------------------------------------- //
