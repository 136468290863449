import { makeStyles } from "@material-ui/styles"
import { useMediaQuery } from "@material-ui/core"

const useStyles = () => {
  const isDeviceWidthLessThan600 = useMediaQuery("(max-width: 600px)")
  const isDeviceHeightLessThan600 = useMediaQuery("(max-height: 600px)")
  const isDeviceHeightLessThan650 = useMediaQuery("(max-height: 650px)")

  return makeStyles((theme) => ({
    StripeButton: {
      border: "1px solid #592570",
      backgroundColor: "#592570",
      color: "#FFF",
      fontSize: "1.5vmin",
      borderRadius: "50px",
      height: "4vmin",
      marginTop: "8%",
      marginBottom: "10%",
      width: "15vmin",
      "&:hover": {
        backgroundColor: "#592570",
      },
    },
    closeButton: {
      position: "absolute",
      right: "20px",
      top: "20px",
      color: 'grey',
    },
    checkout: {
      backgroundColor: "#f8fbfd",
      padding: "1vmin",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      "@media(min-width: 900px)": {
        "@media(min-height: 1300px)": {
          "@media(max-width: 1025px)": {
            minWidth: "600px",
            height: "65vh",
          },
          "@media(min-width: 1025px)": {
            minWidth: "600px",
            height: "85vh",
          },
        },
        "@media(max-height: 1300px)": {
          "@supports (-webkit-touch-callout: none)": {
            minWidth: "600px",
            height: "65vh",
          },
          "@supports not (-webkit-touch-callout: none)": {
            minWidth: "600px",
            height: "85vh",
          },
        },
      },
      "@media(max-width: 900px)": {
        "@media(max-width: 450px)": {
          "@media(min-height: 780px)": {
            width: "83vw",
            height: "65vh",
          },
          "@media(max-height: 780px)": {
            "@supports (-webkit-touch-callout: none)": {
              "@media(max-width: 380px)": {
                width: "80vw",
                height: isDeviceHeightLessThan600 === true ? "80vh" : "70vh",
              },
              "@media(min-width: 380px)": {
                width: isDeviceWidthLessThan600 === true ? "80vw" : "85vw",
                height:
                  // eslint-disable-next-line no-nested-ternary
                  isDeviceWidthLessThan600 === true
                    ? isDeviceHeightLessThan650 === true
                      ? "80vh"
                      : "70vh"
                    : "75vh",
              },
            },
            "@supports not (-webkit-touch-callout: none)": {
              width: isDeviceWidthLessThan600 === true ? "80vw" : "85vw",
              height: isDeviceWidthLessThan600 === true ? "85vh" : "75vh",
            },
          },
        },
        "@media(min-width: 450px)": {
          "@supports (-webkit-touch-callout: none)": {
            width: "70vw",
            height: "65vh",
          },
          "@supports not (-webkit-touch-callout: none)": {
            width: "70vw",
            height: "65vh",
          },
        },
      },
    },
    checkoutForm: {
      marginTop: "2vmin",
      height: "90%",
      padding: "10px",
    },
    billingTitle: {
      color: "#32325d",
      "text-transform": "uppercase",
      margin: "15px 10px",
      fontWeight: 400,
    },
    billingInformation: {
      border: "1px solid #e8e8fb",
      borderRadius: "5px",
      boxShadow:
        "0 1px 3px 0 rgb(50 50 93 / 15%), 0 4px 6px 0 rgb(112 157 199 / 15%)",
    },
    checkoutField: {
      backgroundColor: "#FFF",
      paddingLeft: "3vmax",
      paddingRight: "3vmax",
      borderBottom: "1px solid #e8e8fb",
      display: "flex",
      "@media(min-width: 700px)": {
        height: "5vmin",
      },
      "@media(max-width: 700px)": {
        height: "7vmin",
      },
      "& div": {
        "& input": {
          fontSize: "2vmin",
        },
      },
    },
    checkoutFieldLast: {
      backgroundColor: "#FFF",
      paddingLeft: "3vmax",
      paddingRight: "3vmax",
      display: "flex",
      "@media(min-width: 700px)": {
        height: "5vmin",
      },
      "@media(max-width: 700px)": {
        height: "7vmin",
      },
      "& div": {
        "& input": {
          fontSize: "2vmin",
        },
      },
    },
    checkoutFieldError: {
      backgroundColor: "#F9A4B4",
      paddingLeft: "3vmax",
      paddingRight: "3vmax",
      borderBottom: "1px solid #e8e8fb",
      height: "5vmin",
      display: "flex",
    },
    label: {
      fontWeight: "400",
      alignSelf: "center",
      "@media(min-width: 700px)": {
        marginRight: "1vmax",
        width: "45%",
        marginLeft: "0vmax",
        fontSize: "2vmin",
      },
      "@media(max-width: 700px)": {
        marginRight: "2vmax",
        width: "40%",
        marginLeft: "0vmax",
        fontSize: "2.5vmin",
      },
    },
    cardElement: {
      border: "1px solid #e8e8fb",
      boxShadow:
        "0 1px 3px 0 rgb(50 50 93 / 15%), 0 4px 6px 0 rgb(112 157 199 / 15%)",
      paddingLeft: "3vmin",
      paddingRight: "3vmin",
      backgroundColor: "#FFF",
      "@media(min-width: 700px)": {
        height: "5vmin",
        paddingTop: "1.5vmin",
      },
      "@media(max-width: 700px)": {
        height: "9vmin",
        paddingTop: "2vmin",
      },
    },
    cardElementError: {
      border: "1px solid #e8e8fb",
      boxShadow:
        "0 1px 3px 0 rgb(50 50 93 / 15%), 0 4px 6px 0 rgb(112 157 199 / 15%)",
      paddingLeft: "3vmin",
      paddingRight: "3vmin",
      backgroundColor: "#F9A4B4",

      "@media(min-width: 700px)": {
        height: "5vmin",
        paddingTop: "1.5vmin",
      },
      "@media(max-width: 700px)": {
        height: "9vmin",
        paddingTop: "2vmin",
      },
    },
    payButton: {
      border: "1px solid #e8e8fb",
      boxShadow:
        "0 1px 3px 0 rgb(50 50 93 / 15%), 0 4px 6px 0 rgb(112 157 199 / 15%)",
      display: "flex",
      background: "#592570",
      width: "100%",
      color: "#e8e8fb",
      marginTop: "3vmin",
      "&:hover": {
        background: "#592570",
      },
    },
    payButtonDisabled: {
      border: "1px solid #e8e8fb",
      boxShadow:
          "0 1px 3px 0 rgb(50 50 93 / 15%), 0 4px 6px 0 rgb(112 157 199 / 15%)",
      display: "flex",
      background: "gray",
      width: "100%",
      color: "#e8e8fb",
      marginTop: "3vmin",
      "&:hover": {
        background: "#592570",
      },
    },
    termsAndCondition : {
      width: "100%",
      display: 'flex',
      marginTop: '1vmin'
    },
    termsAndConditionsText : {
      fontWeight: "400",
      alignSelf: "center",
      width: '100%',
      "@media(min-width: 700px)": {
        marginRight: "1vmax",
        marginLeft: "0vmax",
        fontSize: "2vmin",
      },
      "@media(max-width: 700px)": {
        marginRight: "2vmax",
        marginLeft: "0vmax",
        fontSize: "3.5vmin",
      },
    },
    successIncon: {
      "&.MuiSvgIcon-fontSizeLarge": {
        fontSize: "20vmin",
      },
    },
    successForm: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      padding: "15vmin",
      textAlign: "center",
    },
  }))()
}

export default useStyles
