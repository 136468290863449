
// -------------------------------------------------------------------------- //

import * as React from 'react';

import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Radio from '@material-ui/core/Radio';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import Check from '@material-ui/icons/Check';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import StepNavigator from './StepNavigator';
import {
  withStyles,
  TextField,
  Select,
  MenuItem,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  FormControl,
  CircularProgress,
  Input,
} from '@material-ui/core';

import { ROLES_QUERY, UserPermissions } from '../user/Permissions';

// -------------------------------------------------------------------------- //

// @@change: Removed comment to add FB list

const GET_SOCIAL = gql`
  query getTwitter {
    me {
      id
      client {
        id
        fbPages {
          id
          name
          accessToken
          fbId
        }
        twitterAccounts {
          id
          name
          screen_name
          twitterId
          oauthToken
          oauthTokenSecret
        }
      }
      role {
        ${ROLES_QUERY}
      }
    }
  }
`;

const STYLE = (theme) => ({
  selectedTemplateButton: {
    borderBottom: '2px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: 0,
    marginRight: '25px',
    '&:hover': {
      backgroundColor: '#fff'
    }
  },
  unselectedTemplateButton: {
    borderRadius: 0,
    marginRight: '25px',
    '&:hover': {
      backgroundColor: '#fff'
    }
  },
  container: {
    marginTop: '30px',
    display: 'flex',
    marginLeft: '20px'
  },
  stepContent: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  iconRoot: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 25,
    height: 25,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconActive: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff'
  },
  iconCircle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  iconCompleted: {
    backgroundColor: theme.palette.primary.main,
    zIndex: 1,
    color: '#fff',
    width: 25,
    height: 25,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  caption: {
    color: theme.palette.primary.main,
  },
  socialOutletBanner: {
    backgroundColor:  theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: '16px',
    fontWeight: 600,
    textAlign: "center",
    paddingTop: "4px",
    paddingBottom: "4px",
  }
});


class FacebookSelector extends React.Component {
  getRetweets(accounts) {
    if (!this.props.selectedTwitter || accounts.length === 1) {
      return null;
    }

    const output = [];

    accounts.forEach((account) => {
      if (this.props.selectedTwitter.id !== account.id) {
        const checked = this.props.retweets
          ? this.props.retweets.map((retweetAccount) => retweetAccount.id).indexOf(account.id) !== -1
          : false;
        output.push((
          <ListItem
            key={`retweet${account.twitterId}`}
            dense
          >
            <Checkbox
              checked={checked}
              tabIndex={-1}
              onClick={this.toggleTWChecked(account)}
            />
            <ListItemAvatar>
              <Avatar src={`https://twitter-avatar.now.sh/${account.screen_name}`} />
            </ListItemAvatar>
            <ListItemText
              primary={account.name}
              secondary={`@${account.screen_name}`}
            />
          </ListItem>));
      }
    });

    return [
      <ListSubheader key="retweetHeader">Select which accounts will retweet your Post.</ListSubheader>,
      output,
    ];
  }

  getAccounts(accounts) {
    const output = [
      <ListItem
        key="none"
        dense
      >
        <Radio
          checked={!this.props.selectedTwitter}
          onChange={this.props.setSelectedTwitter(null)}
          value=""
        />
        <ListItemText
          primary="Do Not Post to Twitter"
        />
      </ListItem>,
    ];

    accounts.forEach((account) => {
      const selected = this.props.selectedTwitter ? this.props.selectedTwitter : { id: '' };
      const checked = selected.id === account.id;
      output.push((
        <ListItem
          key={account.twitterId}
          dense
        >
          <Radio
            checked={checked}
            value={account.id}
            onChange={this.props.setSelectedTwitter(account)}
          />
          <ListItemAvatar>
            <Avatar src={`https://twitter-avatar.now.sh/${account.screen_name}`} />
          </ListItemAvatar>
          <ListItemText
            primary={account.name}
            secondary={`@${account.screen_name}`}
          />
        </ListItem>));
    });

    return output;
  }

  getPages(pages) {
    const output = [];

    pages.forEach((page) => {
      const checked = this.props.selectedFb
        ? this.props.selectedFb.map((selectedPage) => selectedPage.id).indexOf(page.id) !== -1
        : false;

      output.push((
        <ListItem
          key={page.id}
          button
          dense
          onClick={this.toggleFBChecked(page)}
        >
          <Checkbox
            checked={checked}
            tabIndex={-1}
            disableRipple
          />
          <ListItemAvatar>
            <Avatar src={`https://graph.facebook.com/v2.12/${page.fbId}/picture?height=40`} />
          </ListItemAvatar>
          <ListItemText
            primary={page.name}
          />
        </ListItem>));
    });

    return output;
  }

  toggleFBChecked = (page) => () => {
    const { selectedFb, setSelectedFb } = this.props;
    const index = selectedFb.map((selectedPage) => selectedPage.id).indexOf(page.id);

    if (index !== -1) {
      setSelectedFb([...selectedFb.slice(0, index), ...selectedFb.slice(index + 1)]);
    } else {
      setSelectedFb([...selectedFb, page]);
    }
  }

  toggleTWChecked = (account) => () => {
    const { retweets, setRetweets } = this.props;
    const index = retweets.map((retweetAccount) => retweetAccount.id).indexOf(account.id);

    if (index !== -1) {
      setRetweets([...retweets.slice(0, index), ...retweets.slice(index + 1)]);
    } else {
      setRetweets([...retweets, account]);
    }
  }

  fbMessaging = () => {
    if (this.props.postLater === true) {
      return 'Choose which pages you would like to share your post to. Your post will be published to all pages simultaneously.';
      // return 'Facebook posting is not available in the current Beta version...'
    }
    return 'Choose which pages you would like to share your post to. Your post will be published to all pages simultaneously.';
  }

  render() {
    return (
      <Query query={GET_SOCIAL}>
        {({ data, loading }) => {
          if (!(data && data.me && data.me.client) || loading) {
            return (
              <Typography variant="h6" gutterBottom>
                Loading Social Pages
              </Typography>
            );
          }

          const user_permissions = new UserPermissions(data.me.role);
          let twitter_ids = []; let
            facebook_ids = [];

          if (user_permissions.get('social_posting:read')) {
            twitter_ids = user_permissions.get('social_posting:twitter_accounts', []);
            facebook_ids = user_permissions.get('social_posting:facebook_accounts', []);
          } else {
            if (data.me.client.twitterAccounts) {
              twitter_ids = data.me.client.twitterAccounts.map((i) => i.id);
            }
            if (data.me.client.fbPages) {
              facebook_ids = data.me.client.fbPages.map((i) => i.id);
            }
          }

          const twitter_accounts = twitter_ids.map((id) => data.me.client.twitterAccounts.find((i) => i.id === id) || null).filter(Boolean);

          const facebook_accounts = facebook_ids.map((id) => data.me.client.fbPages.find((i) => i.id === id) || null).filter(Boolean);

          return (
            <>
              <Typography variant="h6" key="fbheading">
                Facebook Pages
              </Typography>
              <Typography variant="body1" key="fbinfo">
                {this.fbMessaging()}
              </Typography>
              <List>
                {this.getPages(facebook_accounts)}
              </List>
              <Typography variant="h6" key="twheading" style={{ marginTop: 16 }}>
                Twitter Accounts
              </Typography>
              <Typography variant="body1" key="twinfo">
                Twitter only allows you to share identical content to one account.
                We will publish your post to a single account, and then retweet it
                to as many other accounts as you'd like.
                <a
                  rel="noopener noreferrer"
                  href="http://help.scoreshots.com/technical-support/why-cant-i-post-to-multiple-twitter-accounts"
                  target="_blank"
                >
                  Read More
                </a>
              </Typography>
              <List>
                <ListSubheader>Select one account that will publish your Post.</ListSubheader>
                {this.getAccounts(twitter_accounts)}
                {this.getRetweets(twitter_accounts)}
              </List>
              <StepNavigator
                onNext={this.props.onNext}
                onPrev={this.props.onPrev}
              />
            </>
          );
        }}
      </Query>
    );
  }
}

class QuickCreateSelector extends React.Component {
  state = {
    selectedIndex: 0,
    noCaption: false,
    selectedSocial: [],
    fullAccounts: [],
    selectTwitter: false,
    selectFacebook: false,
  }



  renderSlideStep(){
    const {classes,json,selectedIndex, slideState} = this.props
    let selectedSocial = slideState[selectedIndex].selectedSocial
    const slideWidth = ['18vmax','12vmax','8vmax']
    return (
        <div className={classes.container}>
          <div>
            <img
                style={{ width: slideWidth[selectedIndex],height: '10vmax'}}
                src={json.data[selectedIndex].preview.jpg}
                alt="preview"
            />
            <StepNavigator
                onNext={() => this.props.nextQuickCreateSlide()}
                onPrev={() => this.props.previousQuickCreateSlide()}
            />
          </div>
          <div style={{marginLeft: '35px',display: 'flex',flexDirection: 'column'}}>
            <TextField
                id="outlined-multiline-static"
                multiline
                rows={6}
                placeholder="Caption Here (Optional)"
                variant="outlined"
                style={{width: '250px'}}
                value={slideState[selectedIndex].captionText}
                onChange={this.props.onQuickCreateTextChange}
            />
            <Query query={GET_SOCIAL}>
              {({ data, loading }) => {
                if (!(data && data.me && data.me.client) || loading) {
                  return (
                      <CircularProgress color="primary" size={18}/>
                  );
                }else{
                  const user_permissions = new UserPermissions(data.me.role);
                  let twitter_ids = [], facebook_ids = [];

                  if (user_permissions.get('social_posting:read')) {
                    twitter_ids = user_permissions.get('social_posting:twitter_accounts', []);
                    facebook_ids = user_permissions.get('social_posting:facebook_accounts', []);
                  } else {
                    if (data.me.client.twitterAccounts) {
                      twitter_ids = data.me.client.twitterAccounts.map((i) => i.id);
                    }
                    if (data.me.client.fbPages) {
                      facebook_ids = data.me.client.fbPages.map((i) => i.id);
                    }
                  }

                  const twitterAccounts = twitter_ids.map((id) => data.me.client.twitterAccounts
                      .find((i) => i.id === id) || null).filter(Boolean)

                  const facebookAccounts = facebook_ids.map((id) => data.me.client.fbPages
                      .find((i) => i.id === id) || null).filter(Boolean)


                  const mergedAccountsArray = [...twitterAccounts,...facebookAccounts]


                  const renderValue = (array) => {
                    let result = []
                    let filterArray = array.slice().filter(value => value !== 'All Accounts')
                    if(filterArray.length === mergedAccountsArray.length)
                      result = ['All Accounts']
                    else if(filterArray.length >= 2)
                      result = filterArray.slice(0,2).map(el => el.name).join()
                    else if(filterArray.length === 0)
                      result = ['Select accounts']
                    else
                      result = filterArray.map(el => el.name)
                    return result
                  }

                  return(
                      <FormControl variant="outlined" style={{marginTop: '1vh'}}>
                        <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            multiple
                            input={<Input />}
                            renderValue={(selected) => renderValue(selected)}
                            value={selectedSocial}
                            displayEmpty
                            onChange={this.props.quickCreateHandleSelectChange}
                            style={{width: '200px',height: '40px',position: 'fixed'}}
                            MenuProps={{variant: 'menu'}}
                        >
                          <MenuItem value={"All Accounts"} key={0}>
                            <Checkbox
                                onChange={() =>
                                    this.props.quickCreateHandleSelectMultipleChange(
                                        !slideState[selectedIndex].selectAllTemplates,
                                        mergedAccountsArray,
                                        'selectAllTemplates'
                                    )
                                }
                                checked={
                                  slideState[selectedIndex].selectAllTemplates
                                }
                            />
                            <Typography style={{fontSize: '16px',fontWeight: 600}}>
                              All Accounts ({mergedAccountsArray.length})
                            </Typography>
                          </MenuItem>
                          <MenuItem value={"Twitter"} key={'Twitter'}>
                            <Checkbox
                                onChange={() =>
                                    this.props.quickCreateHandleSelectMultipleChange(
                                        !slideState[selectedIndex].selectTwitter,
                                        twitterAccounts,
                                        'selectTwitter'
                                    )
                                }
                                checked={
                                  slideState[selectedIndex].selectTwitter
                                }
                            />
                            <Typography style={{fontSize: '16px',fontWeight: 600}}>Twitter</Typography>
                          </MenuItem>
                          {twitterAccounts.map(account => (
                              <MenuItem value={account} key={account.name}>
                                <Checkbox checked={selectedSocial.filter(e => e.id === account.id).length > 0} />
                                <ListItemAvatar>
                                  <Avatar src={`https://twitter.com/${account.screen_name}/profile_image?size=mini`} />
                                </ListItemAvatar>
                                <ListItemText primary={account.name} />
                              </MenuItem>
                          ))}
                          <MenuItem value={"Facebook"} key={'Facebook'}>
                            <Checkbox
                                onChange={() =>
                                    this.props.quickCreateHandleSelectMultipleChange(
                                        !slideState[selectedIndex].selectFacebook,
                                        facebookAccounts,
                                        'selectFacebook'
                                    )
                                }
                                checked={
                                  slideState[selectedIndex].selectFacebook
                                }
                            />
                            <Typography style={{fontSize: '16px',fontWeight: 600}}>Facebook</Typography>
                          </MenuItem>
                          {facebookAccounts.map(account => (
                              <MenuItem value={account} key={account.name}>
                                <Checkbox checked={selectedSocial.filter(e => e.id === account.id).length > 0} />
                                <ListItemAvatar>
                                  <Avatar src={`https://graph.facebook.com/v2.12/${account.fbId}/picture?height=40`} />
                                </ListItemAvatar>
                                <ListItemText primary={account.name} />
                              </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                  )
                }
              }}
            </Query>
          </div>
        </div>
    )
  }
  AbcComponentIcon(index,selectedIndex) {
    const {classes} = this.props
    const abc = ['A','B','C']
    return(
        <div className={clsx(selectedIndex > index? classes.iconCompleted :
            classes.iconRoot,index === selectedIndex && classes.iconActive)}>
          {
            selectedIndex > index? (
                <Check />
            ) : (
                abc[index]
            )
          }
        </div>
    )
  }

  render() {
    const {classes,slideState,selectedIndex} = this.props
    const labels = ['Landscape','Square','Portrait']
    const abc = ['A','B','C']

    return (
      <div style={{display: 'flex',height: '425px'}}>
        <Stepper
            className={classes.stepper}
            orientation="vertical"
            activeStep={selectedIndex}
        >
          {slideState.map((_,i) => {
            return(
                <Step key={abc[selectedIndex]}>
                  <StepLabel StepIconComponent={() => this.AbcComponentIcon(i,selectedIndex)}>{labels[i]}</StepLabel>
                  <StepContent>
                    <div style={{height: '200px'}}></div>
                  </StepContent>
                </Step>
            )
          })}
        </Stepper>
        {this.renderSlideStep()}
      </div>
    );
  }
}



class DowloadSelector extends React.Component {
  render() {

    return (
      (this.props.quickCreate ? (
        <QuickCreateSelector {...this.props} />
      ) : (
        <FacebookSelector {...this.props} />
      )
      )
    );
  }
}
// -------------------------------------------------------------------------- //

export default withStyles(STYLE)(DowloadSelector);

// -------------------------------------------------------------------------- //
